import { useOutsideClick } from "@/hooks"
import classNames from "classnames"
import { AnimatePresence, motion } from "framer-motion"
import { useRef, useState } from "react"
import IconButton from "../../IconButton"
import subDays from "date-fns/subDays"
import { useReplaceUrlQuery } from "@/hooks/useQuery"
import { googleAnalytics } from "@/lib/gtag"
import { FilterIcon, GridViewIcon, ListViewIcon } from "@/components/shared/icons"

export const filtersRecent = ["Today", "All", "Last 7 days", "Last 30 days", "Older"]

interface RecentFilterProps {
  filter: { toDate?: Date; fromDate?: Date; type: (typeof filtersRecent)[number] } | undefined
  setFilter: (v: { toDate?: Date; fromDate?: Date; type: (typeof filtersRecent)[number] } | undefined) => void
  layout: "grid" | "list"
  setLayout: (v: "grid" | "list") => void
}

const RecentFilter = ({ filter, setFilter, layout, setLayout }: RecentFilterProps) => {
  const [open, setOpen] = useState<"Filter" | "Sort" | null>(null)
  const popupRef = useRef<HTMLDivElement>(null)
  useOutsideClick({
    enabled: !!open,
    ref: popupRef,
    handler: () => {
      setOpen(null)
    },
  })
  const replaceUrlQuery = useReplaceUrlQuery()

  return (
    <div className="relative mb-2 py-2">
      <div className="flex space-x-0 lg:space-x-4 justify-end">
        <div ref={popupRef}>
          <IconButton
            title="Filter"
            className={classNames("rounded-2xl border h-full border-transparent hover:bg-atherGray-850", {
              "bg-atherGray-850": open,
              "border-atherPurple-500": filter && Object.values(filter).some(v => v !== undefined),
            })}
            colorScheme="transparent"
            onClick={() => {
              setOpen(prev => (prev === "Filter" ? null : "Filter"))
            }}
          >
            <FilterIcon />
          </IconButton>
          <AnimatePresence>
            {!!open && (
              <motion.div
                initial={{ opacity: 0.5, height: 0 }}
                animate={{ opacity: 1, height: "auto" }}
                exit={{ opacity: 0.5, height: 0 }}
                className="absolute top-full mt-1 overflow-hidden right-2 w-[10rem] z-[20]"
              >
                <div className="p-2 rounded-2xl space-y-2 bg-atherGray-850 border border-atherGray-800 flex w-full flex-col">
                  {filtersRecent.map(item => (
                    <IconButton
                      colorScheme="secondary"
                      className={classNames(
                        "flex items-center justify-start bg-transparent p-2 w-full cursor-pointer rounded-xl",
                        {
                          "bg-atherGray-800 text-atherGray-0": filter?.type === item.toLowerCase(),
                          "text-atherGray-300": filter?.type !== item.toLowerCase(),
                        },
                      )}
                      key={item}
                      onClick={() => {
                        googleAnalytics.event({
                          action: "click",
                          category: "filter",
                          label: "recent_filter",
                          params: {
                            filter_name: item.toLowerCase(),
                          },
                        })

                        if (item.toLowerCase() === "today") {
                          setFilter({
                            type: "today",
                            fromDate: subDays(new Date(), 1),
                            toDate: new Date(),
                          })
                        }
                        if (item.toLowerCase() === "all") {
                          setFilter({
                            type: "all",
                            fromDate: undefined,
                            toDate: undefined,
                          })
                        }
                        if (item.toLowerCase() === "last 7 days") {
                          setFilter({
                            type: "last 7 days",
                            fromDate: subDays(new Date(), 7),
                            toDate: new Date(),
                          })
                        }
                        if (item.toLowerCase() === "last 30 days") {
                          setFilter({
                            type: "last 30 days",
                            fromDate: subDays(new Date(), 30),
                            toDate: new Date(),
                          })
                        }
                        if (item.toLowerCase() === "older") {
                          setFilter({
                            type: "Older",
                            fromDate: undefined,
                            toDate: subDays(new Date(), 30),
                          })
                        }
                        replaceUrlQuery({
                          filter: item.toLowerCase(),
                        })
                        setOpen(null)
                      }}
                    >
                      <p>{item}</p>
                    </IconButton>
                  ))}
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
        <div className="flex items-center bg-atherGray-900 p-1 rounded-2xl">
          <IconButton
            colorScheme="transparent"
            title="Grid"
            className={classNames("flex items-center font-semibold text-sm")}
            onClick={() => {
              googleAnalytics.event({
                action: "click",
                category: "filter",
                label: "recent_layout_grid",
              })

              if (layout === "grid") return
              setLayout("grid")
            }}
          >
            <GridViewIcon
              className={classNames({
                "text-atherGray-0": layout === "grid",
                "text-atherGray-500": layout !== "grid",
              })}
            />
          </IconButton>
          <IconButton
            title="List"
            colorScheme="transparent"
            className={classNames("flex items-center font-semibold text-sm")}
            onClick={() => {
              googleAnalytics.event({
                action: "click",
                category: "filder",
                label: "recent_layout_list",
              })
              if (layout === "list") return
              setLayout("list")
            }}
          >
            <ListViewIcon
              className={classNames({
                "text-atherGray-0": layout === "list",
                "text-atherGray-500": layout !== "list",
              })}
            />
          </IconButton>
        </div>
      </div>
    </div>
  )
}

export default RecentFilter
