import client from "@/api/client"
import { createMutation } from "react-query-kit"

export const useGetTaskRunInfo = createMutation({
  mutationFn: ({ taskId, imageId, captchaToken }: { taskId: string; imageId?: string; captchaToken?: string }) =>
    client.api
      .recipeTaskControllerGetRecipeTaskRunInfo(taskId, {
        imageId,
        captchaToken,
      })
      .then(res => res.data),
})
