import client from "@/api/client"
import { CreatorProfileItem, UpdateUserProfileDto, UserDetail } from "@/api/sdk"
import { createMutation } from "react-query-kit"
import { actionMiddleware } from "./middlewares/actionMiddleware"
import { useGetProfileByName } from "@/components/Layouts/ProfileLayout"

export const useMutateUpdateUser = createMutation({
  mutationFn: ({ data }: { data: UpdateUserProfileDto; userUid: string }) =>
    client.api.userControllerUpdateUserProfile(data),

  use: [
    actionMiddleware({
      onMutate: ({ queryClient, variables: { data, userUid } }) => {
        const userKey = ["user-info", userUid]

        const userQueryData = queryClient.getQueryData<UserDetail>(userKey)

        if (userQueryData) {
          queryClient.setQueryData<UserDetail>(userKey, {
            ...userQueryData,
            ...data,
            skippedTutorials: data.skippedTutorials ?? userQueryData.skippedTutorials ?? [],
          })
        }
      },
      onSuccess: async ({ queryClient, variables: { userUid } }) => {
        const userKey = ["user-info", userUid]
        const userQueryData = await queryClient.getQueryData<UserDetail>(userKey)

        const profileKey = useGetProfileByName.getKey({ username: userQueryData?.username })

        queryClient.invalidateQueries({ queryKey: profileKey })
      },
    }),
  ],
})
