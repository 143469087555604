import client from "@/api/client"
import { UserRanking } from "@/api/sdk"
import { createAuthenticatedQuery } from "../createAuthenticatedQuery"
import { enableQueryMiddleware } from "../middlewares/enableQueryMiddleware"

export const useLeaderboardUserRankingQuery = createAuthenticatedQuery<UserRanking, { leaderboardId: string }, Error>({
  primaryKey: "user-leaderboard-ranking",
  queryFn: ({ queryKey: [_primaryKey, variables] }) =>
    client.api.leaderboardControllerGetSelfLatestRanking(variables.leaderboardId).then(res => res.data),
  use: [enableQueryMiddleware(v => !!v.leaderboardId)],
})
