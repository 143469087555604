import { Comment, EntityType, GetCommentsResult, User } from "@/api/sdk"
import LexicalCustomEditor, { EMPTY_CONTENT_LEXICAL } from "@/components/LexicalEditor/LexicalCustomEditor"
import { MentionDataV2 } from "@/components/LexicalEditor/MentionPlugin"
import { $isBeautifulMentionNode } from "@/components/LexicalEditor/plugin/nodes/MentionNode"
import UserBadge from "@/components/UserBadge"
import { SpinnerIcon } from "@/components/shared/icons"
import { useDeleteCommentImage, useLikeCommentImage, useToast } from "@/hooks"
import { googleAnalytics } from "@/lib/gtag"
import { useAuth } from "@/providers/authContext"
import { useAddCommentMutation } from "@/queries"
import { useMutateTipWowPoints } from "@/queries/useTipWowPoints"
import { InfiniteData, useQueryClient } from "@tanstack/react-query"
import classNames from "classnames"
import { motion } from "framer-motion"
import { $getRoot, EditorState, LexicalEditor } from "lexical"
import React, { MutableRefObject, memo, useEffect, useRef, useState } from "react"
import { wowTippingRegex } from "./AddComment"
import ItemComment from "./ItemComment"
import LoadingComment from "./LoadingComment"
import ViewOtherComment from "./ViewOtherComment"
import useCommentReply from "./useCommentReply"

interface ChildItemCommentProps {
  selectedComment?: Comment | null
  mentions: MentionDataV2[]
  itemId: string
  itemOwnerUid?: string
  setChildCommentsCount: React.Dispatch<React.SetStateAction<number>>
  isDisabledComment?: boolean
  otherClassName?: string
  setTotalNoti?: React.Dispatch<React.SetStateAction<number>>
  baseChildParam: number | null
  prevBaseChildParam: MutableRefObject<number | null>
  nextBaseChildParam: MutableRefObject<number | null>
  setIsSuccessChild?: React.Dispatch<React.SetStateAction<boolean>>
  childTakePage: number
  childTotalPage: MutableRefObject<number>
  onClearMention?: () => void
  setSelectedComment?: (v: Comment | null) => void
  setIsFocus: (isFocus: boolean) => void
  typeComment: EntityType
  isMentionsLoading: boolean
  selectedMention: User | null
  setSearchMention: (search: string) => void
  nodeId?: string | null
}

const ChildItemsComment = ({
  selectedComment,
  mentions,
  itemId,
  itemOwnerUid,
  nodeId,
  setChildCommentsCount,
  isDisabledComment,
  setTotalNoti,
  setSearchMention,
  onClearMention,
  selectedMention,
  baseChildParam,
  nextBaseChildParam,
  otherClassName,
  isMentionsLoading,
  setIsSuccessChild,
  prevBaseChildParam,
  childTakePage,
  childTotalPage,
  typeComment,
  setIsFocus,
}: ChildItemCommentProps) => {
  const [editorState, setEditorState] = useState<EditorState>()
  const { userInfoQuery } = useAuth()

  const qc = useQueryClient()
  const editorRef = useRef<LexicalEditor>(null)
  const [replyComment, setReplyComment] = useState<User | null>(null)
  const [commentItem, setCommentItem] = useState<string>()

  const {
    mappedChildComment,
    hasNextPage,
    hasPreviousPage,
    fetchNextPage,
    fetchPreviousPage,
    isLoading,
    isFetchingNextPage,
    isFetchingPreviousPage,
    queryCommentsChildImageKey,
    refetchChildComments,
    upComingComment,
  } = useCommentReply({
    itemId,
    selectedCommentId: selectedComment?.id,
    setIsSuccessChild,
    baseChildParam,
    nextBaseChildParam,
    prevBaseChildParam,
    totalReplyComment: selectedComment?.childCommentsCount ?? 0,
    childTakePage,
    childTotalPage,
    typeComment,
  })

  useEffect(() => {
    if (selectedMention) {
      const timer = setTimeout(() => {
        handleReplyClick(undefined, selectedMention)
      }, 50)

      return () => clearTimeout(timer)
    }
  }, [selectedMention])

  const { mutate: mutateLike } = useLikeCommentImage(queryCommentsChildImageKey)

  const { mutate: mutateDeleteComment } = useDeleteCommentImage(queryCommentsChildImageKey, {
    onMutate: () => setChildCommentsCount(v => v - 1),
    onError: () => setChildCommentsCount(v => v + 1),
  })

  const clearEditor = () => {
    // clear lexical editor
    const initialEmpty = editorRef.current?.parseEditorState(EMPTY_CONTENT_LEXICAL)
    if (!initialEmpty) return

    editorRef.current?.setEditorState(initialEmpty)
    setEditorState(initialEmpty)

    setTimeout(() => {
      editorRef.current?.focus()
    }, 150)
  }

  const handleReplyClick = (commentId: string | undefined, userMention: User | null) => {
    if (!commentId) {
      setCommentItem(undefined)
    }

    if (commentId) {
      onClearMention?.()
    }
    clearEditor()
    setCommentItem(commentId)
    if (userMention?.uid === userInfoQuery?.data?.uid) return

    setReplyComment(userMention)
  }

  const toast = useToast()

  const { mutate: mutateTip } = useMutateTipWowPoints({
    onSuccess: data => {
      googleAnalytics.handleCategoryEvent({
        action: "click",
        params: {
          action: "Send Tip",
          profile_uid: data.recipient.uid,
          profile_name: data.recipient.name,
          wow_value: data.amount,
        },
      })

      toast({
        title: "Tip successfully",
        status: "success",
        message: [`Your have successfully tipped ${data.amount} $WOW to ${data.recipient.name}`],
      })
    },
    onError: (err: any) => {
      toast({
        title: "Tip failed",
        status: "error",
        message: [err?.message ?? "An error occurred"],
      })
    },
  })

  const { mutate: mutateComment } = useAddCommentMutation({
    onMutate: async ({ data: { id, value, formatValue, uidMentions, nodeId } }) => {
      await qc.cancelQueries({ queryKey: queryCommentsChildImageKey })

      const previousComments = qc.getQueryData(queryCommentsChildImageKey) as
        | InfiniteData<GetCommentsResult, number>
        | undefined

      if (previousComments) {
        qc.setQueryData(queryCommentsChildImageKey, {
          ...previousComments,
          pages: [
            {
              comments: [
                {
                  id,
                  value,
                  formatValue,
                  nodeId,
                  mentions: mentions.filter(i => uidMentions?.includes(i.uid)) as any[],
                  commenter: {
                    ...userInfoQuery?.data,
                    uid: userInfoQuery?.data?.uid ?? "",
                    email: (userInfoQuery?.data?.email ?? "") as any,
                    name: userInfoQuery?.data?.name ?? "",
                    picture: userInfoQuery?.data?.picture ?? "",
                    createdAt: new Date().toISOString(),
                    updatedAt: new Date().toISOString(),
                    newNotificationsCount: 0,
                    storageSize: 0,
                    availableGeneration: 0,
                    deletedAt: "",
                  },
                  isRead: false,
                  createdAt: new Date().toISOString(),
                  updatedAt: new Date().toISOString(),
                  likeCount: 0,
                  commenterUid: userInfoQuery?.data?.uid ?? "",
                  image: null,
                  parentComment: null,
                  parentCommentId: "",
                  childCommentsCount: 0,
                  likedBy: [],
                },
              ],
              totalParentComment: previousComments.pages[0].totalParentComment,
              total: previousComments.pages[0].total + 1,
            },
            ...previousComments.pages,
          ],
        })
      }

      // clear lexical editor
      clearEditor()

      return { previousComments }
    },
    onSuccess: (data, { data: { id, value } }) => {
      const previousComments = qc.getQueryData(queryCommentsChildImageKey) as
        | InfiniteData<GetCommentsResult, number>
        | undefined

      if (previousComments) {
        qc.setQueryData(queryCommentsChildImageKey, {
          ...previousComments,
          pages: previousComments.pages.map(page => ({
            ...page,
            comments: page.comments.map(comment => {
              if (comment.id === id) {
                return {
                  ...comment,
                  id: data.id,
                }
              }
              return comment
            }),
          })),
        })
      }

      // baseChildParam.current = 0
      refetchChildComments()
      setChildCommentsCount(v => v + 1)

      const tipMatches = value.match(wowTippingRegex)

      if (itemOwnerUid && tipMatches && userInfoQuery?.data?.uid !== itemOwnerUid) {
        const amount = parseInt(tipMatches[0])

        if (!Number.isInteger(amount)) return

        if (!amount || amount < 1) {
          toast({
            title: "Tip failed",
            status: "error",
            message: ["Minimum tipping is 1 $WOW"],
          })
          return
        } else {
          mutateTip({
            data: {
              amount,
              recipientUid: itemOwnerUid,
              metadata: {
                entityId: itemId,
                entityType: typeComment,
              },
            },
          })
        }
      }
    },
    onError: (_, __, context) => {
      if (context?.previousComments) {
        qc.setQueryData(queryCommentsChildImageKey, context.previousComments)
        setChildCommentsCount(v => v - 1)
      }
    },
  })

  const onAddComment = (data?: EditorState) => {
    const JSONEditorState = JSON.stringify(data?.toJSON())
    const getMentionsAdded = data?.read(() =>
      [...data?._nodeMap.values()].reduce((obj, value) => {
        if (!$isBeautifulMentionNode(value)) return obj
        const valueMention = value.__data as MentionDataV2

        if (obj.map(item => item.uid).includes(valueMention.uid)) return obj
        obj.push(valueMention)
        return obj
      }, [] as MentionDataV2[]),
    )

    const getText = data?.read(() => $getRoot()?.getTextContent())
    mutateComment({
      data: {
        id: new Date().getTime().toString(),
        formatValue: JSONEditorState ?? "",
        parentCommentId: selectedComment?.id ?? null,
        uidMentions: getMentionsAdded?.map(i => i.uid) ?? [],
        value: getText ?? "",
        nodeId: selectedComment?.nodeId,
      },
      itemId,
      typeComment,
    })
    setCommentItem(undefined)
    onClearMention?.()
  }

  if (isLoading)
    return (
      <div className="flex items-center justify-center flex-1 w-full text-gray-600">
        <motion.div animate={{ rotate: 360 }} transition={{ duration: 1, repeat: Infinity, ease: "linear" }}>
          <SpinnerIcon width={16} height={16} />
        </motion.div>
      </div>
    )

  if (!selectedComment) return null
  return (
    <motion.div
      initial={{ opacity: 0, height: 0 }}
      animate={{ opacity: 1, height: "auto" }}
      exit={{ opacity: 0, height: 0 }}
      className="w-full pl-10 mt-2"
    >
      {!isDisabledComment && !commentItem && selectedMention && (
        <div className="flex items-start w-full pb-4 mb-4 border-b border-atherGray-800">
          {userInfoQuery?.data ? (
            <UserBadge
              className="mr-2"
              isDisabledInfo
              picture={userInfoQuery.data?.picture}
              name={userInfoQuery.data.name}
              email={userInfoQuery.data.email}
              size="sm"
            />
          ) : null}
          <LexicalCustomEditor
            onSearchMention={setSearchMention}
            setIsFocus={setIsFocus}
            editorKey="add-comment-child"
            editorRef={editorRef}
            editorState={editorState}
            setEditorState={setEditorState}
            mentions={mentions}
            onAddComment={onAddComment}
            selectedMention={{
              uid: selectedMention?.uid ?? "",
              email: selectedMention?.email ?? "",
              name: selectedMention?.name ?? "",
              picture: selectedMention?.picture ?? "",
              itemValue: selectedMention?.name ?? "",
              label: selectedMention?.name ?? "",
              value: selectedMention?.name ?? "",
            }}
            triggerInsertMention={!!replyComment}
            onTriggerInsertMention={isTrigger => {
              if (!isTrigger) {
                setReplyComment(null)
              }
            }}
          >
            <></>
          </LexicalCustomEditor>
        </div>
      )}

      {(hasPreviousPage || isFetchingPreviousPage) && (
        <ViewOtherComment
          className={classNames("mb-4", otherClassName)}
          isFetchingPage={isFetchingPreviousPage}
          fetchPage={() => {
            if (prevBaseChildParam.current !== null) {
              fetchPreviousPage()
              prevBaseChildParam.current -= 1
            }
          }}
          content=" View previous relies"
        />
      )}

      <div
        className={classNames("space-y-2 flex flex-col items-start", {
          "mb-4": true,
        })}
      >
        {upComingComment && (
          <LoadingComment
            isReplyComment
            picture={userInfoQuery?.data?.picture ?? ""}
            name={userInfoQuery?.data?.name ?? ""}
            content={upComingComment}
          />
        )}
        {mappedChildComment.comments.map(comment => (
          <div key={comment.id} className="w-full">
            <ItemComment
              isMentionsLoading={isMentionsLoading}
              typeComment={typeComment}
              isChild
              setSearchMention={setSearchMention}
              isDisabledComment={isDisabledComment}
              setIsFocus={setIsFocus}
              queryCommentsImageKey={queryCommentsChildImageKey}
              baseChildParam={baseChildParam}
              childTakePage={childTakePage}
              childTotalPage={childTotalPage}
              nextBaseChildParam={nextBaseChildParam}
              prevBaseChildParam={prevBaseChildParam}
              refetchComments={refetchChildComments}
              shouldOpenedComment={false}
              user={userInfoQuery?.data}
              mentions={mentions}
              itemId={itemId}
              isOwner={userInfoQuery?.data?.uid === comment.commenter?.uid}
              userId={userInfoQuery?.data?.uid}
              onSelected={() => handleReplyClick(comment.id, comment.commenter ?? null)}
              onDeleted={() => mutateDeleteComment(comment.id)}
              comment={comment}
              onLike={() => mutateLike(comment.id)}
            />
            {!isDisabledComment && commentItem === comment.id && (
              <div className="flex items-start w-full pb-4 mb-4 border-b border-atherGray-800">
                {userInfoQuery?.data ? (
                  <UserBadge
                    className="mr-2"
                    isDisabledInfo
                    picture={userInfoQuery.data.picture}
                    name={userInfoQuery.data.name}
                    email={userInfoQuery.data.email}
                    size="sm"
                  />
                ) : null}
                <LexicalCustomEditor
                  onSearchMention={setSearchMention}
                  setIsFocus={setIsFocus}
                  editorKey={`add-comment-child-${comment.id}`}
                  editorRef={editorRef}
                  editorState={editorState}
                  setEditorState={setEditorState}
                  mentions={mentions}
                  onAddComment={onAddComment}
                  selectedMention={{
                    uid: replyComment?.uid ?? "",
                    email: replyComment?.email ?? "",
                    name: replyComment?.name ?? "",
                    picture: replyComment?.picture ?? "",
                    itemValue: replyComment?.name ?? "",
                    label: replyComment?.name ?? "",
                    value: replyComment?.name ?? "",
                  }}
                  triggerInsertMention={!!replyComment}
                  onTriggerInsertMention={isTrigger => {
                    if (!isTrigger) {
                      setReplyComment(null)
                    }
                  }}
                >
                  <></>
                </LexicalCustomEditor>
              </div>
            )}
          </div>
        ))}
        {(hasNextPage || isFetchingNextPage) && (
          <ViewOtherComment
            className={classNames("mt-4", otherClassName)}
            isFetchingPage={isFetchingNextPage}
            fetchPage={() => {
              if (nextBaseChildParam.current !== null) {
                fetchNextPage()
                nextBaseChildParam.current += 1
              }
            }}
            content="View more replies"
          />
        )}
      </div>
    </motion.div>
  )
}

export default memo(ChildItemsComment)
