import client from "@/api/client"
import IconButton from "@/components/IconButton"
import TextCopy from "@/components/TextCopy"
import { DeleteIcon, PlusIcon2 } from "@/components/shared/icons"
import { createAuthenticatedQuery } from "@/queries/createAuthenticatedQuery"
import { useSignInStore } from "@/stores"
import { warpNameLength } from "@/utils/parser"
import React, { useEffect, useState } from "react"
import RemoveWalletModal from "./RemoveWalletModal"
import { useWallet } from "@solana/wallet-adapter-react"
import { cn } from "@/lib/utils"
import { useAccount } from "wagmi"
import { useAuth } from "@/providers/authContext"
import { googleAnalytics } from "@/lib/gtag"

export const useWalletOwned = createAuthenticatedQuery({
  primaryKey: "wallet-owned",
  queryFn: () => client.api.walletControllerGetOwnedWallets().then(res => res.data),
})

const WalletInfo = () => {
  const setSignInModal = useSignInStore(state => state.setSignInModal)
  const [modal, setModal] = useState<string | null>(null)
  const [provider, setProvider] = useState<"Solana" | "Ethereum">("Solana")
  const { data: walletOwned } = useWalletOwned()
  const { address } = useAccount()
  const wallet = useWallet()

  const publicKey = wallet.publicKey?.toString()

  return (
    <div className="w-full">
      <p className="mb-1 font-semibold">Connected Web3 wallet</p>
      <p className="font-300 text-atherGray-300 mb-6 text-sm">
        To delete (unlink) a wallet address with an GAIA account, you must connect that wallet to a browser extension
        (Chrome) and sign an authentication message to complete.
      </p>
      <div className="mb-6 space-y-2">
        {walletOwned?.wallets.map(item => (
          <div
            key={item.id}
            className={cn("px-4 py-2.5 rounded-2xl flex items-center border border-atherGray-850", {
              "bg-atherGray-850 font-semibold": item.id === address || item.id === publicKey,
            })}
          >
            <div className="flex items-center flex-1">
              <p className="mr-2">{warpNameLength(item.id)}</p>
              <TextCopy width={16} height={16} value={item.id} />
            </div>
            {(item.id === address || item.id === publicKey) && (
              <IconButton
                onClick={() => {
                  setModal(item.id)
                  setProvider(item.id === address ? "Ethereum" : "Solana")
                }}
                colorScheme="transparent"
                className="p-0 min-h-0"
              >
                <DeleteIcon className="text-red-500" width={20} height={20} />
              </IconButton>
            )}
          </div>
        ))}
      </div>
      <div className="flex gap-4">
        <IconButton onClick={() => setSignInModal({ linkWallet: true })} className="w-1/2 py-2.5 rounded-xl">
          <PlusIcon2 width={20} height={20} /> {"Solana wallet"}
        </IconButton>
        <IconButton onClick={() => setSignInModal({ linkWalletEvm: true })} className="w-1/2 py-2.5 rounded-xl">
          <PlusIcon2 width={20} height={20} /> {"Ethereum wallet"}
        </IconButton>
      </div>

      <RemoveWalletModal
        walletId={modal}
        provider={provider}
        onClose={() => {
          setModal(null)
        }}
      />
    </div>
  )
}

export default WalletInfo
