import { cn } from "@/utils/cn"
import { DetailedHTMLProps, TextareaHTMLAttributes, forwardRef } from "react"

export interface TextareaProp
  extends DetailedHTMLProps<TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> {
  containerClassName?: string
  rightIcon?: React.ReactNode
  leftIcon?: React.ReactNode
}

const Textarea = forwardRef<HTMLTextAreaElement, TextareaProp>(
  ({ containerClassName, rightIcon, leftIcon, className, ...props }, ref) => {
    return (
      <div className={cn("relative", containerClassName)}>
        <textarea
          className={cn(
            "bg-atherGray-800 rounded-2xl h-10 px-4 w-full resize-none text-atherGray-300 disabled:text-atherGray-400 disabled:placeholder:text-atherGray-500 placeholder:text-atherGray-400",
            {
              "pr-12": !!rightIcon,
              "pl-12": !!leftIcon,
            },
            className,
          )}
          {...props}
          ref={ref}
        />
        {leftIcon && <div className="absolute left-4 top-1/2 transform -translate-y-1/2">{leftIcon}</div>}
        {rightIcon && <div className="absolute right-4 top-1/2 transform -translate-y-1/2">{rightIcon}</div>}
      </div>
    )
  },
)

export default Textarea
