import client from "@/api/client"
import { createAuthenticatedQuery } from "../createAuthenticatedQuery"
import { CalculateSubscriptionAmountResult, SubscribeDto } from "@/api/sdk"

export const useGetSubscriptionPreviewAmount = createAuthenticatedQuery<
  CalculateSubscriptionAmountResult,
  SubscribeDto,
  Error
>({
  primaryKey: "subscription-preview-amount",
  queryFn: ({ queryKey: [_primaryKey, variables] }) =>
    client.api.paymentControllerGetSubscriptionPreviewAmount(variables).then(res => res.data),
})
