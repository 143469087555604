import { create } from "zustand"
import { devtools, persist } from "zustand/middleware"

type GridSize = "grid" | "list"

interface GridStore {
  layout: GridSize
  setLayout: (layout: GridSize) => void
}

export const useGridStore = create<GridStore>()(
  devtools(
    persist(
      set => ({
        layout: "grid",
        setLayout: layout => set({ layout }),
      }),
      {
        name: "layout-storage",
      },
    ),
  ),
)
