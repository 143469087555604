import { useOutsideClick, useScreen } from "@/hooks"
import useClientStore from "@/lib/clientStore"
import { googleAnalytics } from "@/lib/gtag"
import { cn } from "@/lib/utils"
import { useBrowserRouter } from "@/providers/BrowserRouterProvider"
import { useAuth } from "@/providers/authContext"
import { useRecipeImageStore } from "@/stores"
import { useHasClientHistory } from "@/stores/ClientHistoryStore"
import classNames from "classnames"
import { AnimatePresence, motion } from "framer-motion"
import Link from "next/link"
import useCustomRouter from "@/hooks/useCustomRouter"
import { Fragment, ReactNode, useRef, useState } from "react"
import { FaChevronLeft } from "react-icons/fa"
import { MdKeyboardArrowDown } from "react-icons/md"
import { twMerge } from "tailwind-merge"
import SignInRequiredButton from "../Explore/SignInRequiredButton"
import IconButton from "../IconButton"
import { NotificationDrawer } from "../NotificationDrawer"
import NotificationBell from "../NotificationDrawer/NotificationBell"
import AppLogo from "../Workspace/Sidebar/AppLogo"
import CreditsInfo from "../Workspace/Sidebar/CreditsInfo"
import PointsInfo from "../Workspace/Sidebar/PointsInfo"
import SidebarDrawerMobile from "../Workspace/Sidebar/SidebarDrawerMobile"
import TaskTracker from "../Workspace/Sidebar/TaskTracker"
import UserInfo from "../Workspace/Sidebar/UserInfo"
import { MenuIcon } from "../shared/icons"
import RoomButton from "../Workspace/ChatRooms/RoomButton"

interface NavbarProps {
  name?: string | ReactNode
  isBackButton?: boolean
  className?: string
  containerClassName?: string
  isHaveSidebarToggle?: boolean
  titleClassName?: string
  backTo?: string
  hiddenNavbar?: boolean
  navItems?: {
    name: string
    path: string
    disabled?: boolean
    isExternal?: boolean
    child?: { name: string; isExternal?: boolean; path: string }[]
  }[]
}

export const Links = ({
  name,
  links,
  className,
}: {
  className?: string
  name: string
  links: { name: string; path: string }[]
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const router = useCustomRouter()

  const ref = useRef<HTMLDivElement>(null)

  useOutsideClick({
    enabled: isOpen,
    ref,
    handler: () => setIsOpen(false),
  })

  return (
    <div className="relative" ref={ref}>
      <div
        onClick={() => setIsOpen(prev => !prev)}
        className={cn("flex items-center cursor-pointer text-[#718096]", className)}
      >
        <p className="mr-2 font-semibold">{name}</p>
        <MdKeyboardArrowDown />
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            className="absolute top-full left-0 bg-atherGray-900 rounded-2xl shadow-md p-2 min-w-[10rem] flex flex-col z-[1]"
            initial={{ opacity: 0, y: 0 }}
            animate={{ opacity: 1, y: 10 }}
            exit={{ opacity: 0, y: 0 }}
          >
            {links.map(link => (
              <Link
                className={classNames(
                  "py-2 px-4 hover:text-atherGray-100 hover:bg-atherGray-850 rounded-2xl font-semibold text-sm",
                  {
                    "bg-atherGray-850 text-atherGray-100": router.asPath.includes(link.path),
                    "text-atherGray-300": !router.asPath.includes(link.path),
                  },
                )}
                href={link.path}
                key={link.name}
              >
                {link.name}
              </Link>
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

const CommonNavbar = ({
  hiddenNavbar,
  containerClassName,
  name,
  isBackButton,
  className,
  titleClassName,
  backTo,
  navItems,
}: NavbarProps) => {
  const [recipeImage, setRecipeImage] = useRecipeImageStore(state => [state.recipeImage, state.setRecipeImage])
  const { user } = useAuth()
  const router = useCustomRouter()
  const { back } = useBrowserRouter()
  const hasHistory = useHasClientHistory()
  const [isMenuOpen, setIsMenuOpen] = useClientStore(state => [state.isMenuOpen, state.setIsMenuOpen])
  const { width } = useScreen()

  const handleGoBack = () => {
    // check if last visited path is on the our website
    // if yes, go back to that path
    googleAnalytics.event({
      action: "click",
      category: "navbar",
      label: `go_back`,
      value: 1,
    })

    if (recipeImage) {
      setRecipeImage(null)
    }

    if (hasHistory) {
      back()
    } else {
      router.push(backTo ?? "/workspace")
    }
  }

  const handleToggleSidebar = () => {
    googleAnalytics.event({
      action: "click",
      category: "sidebar",
      label: `toggle_sidebar`,
      value: 1,
    })

    setIsMenuOpen(!isMenuOpen)
  }

  return (
    <nav
      className={twMerge(
        "hidden md:flex sticky top-0 right-0 max-w-full justify-center w-[100vw] bg-black px-0 pt-2 sm:w-full z-[21]",
        containerClassName,
      )}
    >
      <div id="navbar-inside" className={twMerge("flex w-full relative h-[4rem] ", className)}>
        <div className="flex items-center pl-4 md:px-2 xl:px-4 py-4">
          {navItems && (
            <button
              type="button"
              className={classNames("flex lg:hidden items-center px-2", {
                "sidebar-homespace": width <= 1024,
              })}
              onClick={handleToggleSidebar}
            >
              <MenuIcon width={20} height={20} />
            </button>
          )}
          <AppLogo backTo={backTo} className="h-full px-2" />
          {isBackButton && (
            <IconButton onClick={handleGoBack} className="flex items-center ml-0 mr-2 z-[1] lg:mr-0 lg:ml-2">
              <FaChevronLeft />
              <p className="hidden ml-2 lg:block">Back</p>
            </IconButton>
          )}
        </div>
        {navItems && (
          <div className="items-center hidden xl:ml-8 space-x-4 xl:space-x-8 lg:flex text-sm xl:text-base">
            {navItems
              .filter(i => (user ? true : i.path !== "/my-creations" && i.path !== "/workspace"))
              .map(item => {
                if (item.child) {
                  return <Links key={item.name} name={item.name} links={item.child} />
                }

                return (
                  <Fragment key={item.name}>
                    <div className="relative flex items-center h-full">
                      <Link
                        shallow
                        target={item.isExternal ? "_blank" : "_self"}
                        href={item.path}
                        className={classNames("font-semibold flex items-center", {
                          "text-atherGray-400": router.pathname.split("/")[1] !== item.path.split("/")[1],
                          "text-atherGray-0": router.pathname.split("/")[1] === item.path.split("/")[1],
                        })}
                      >
                        {item.name}
                      </Link>
                      <div
                        className={classNames("h-[4px] w-full absolute bottom-0 left-0", {
                          "bg-atherPurple-500": router.pathname.split("/")[1] === item.path.split("/")[1],
                          "bg-transparent": router.pathname.split("/")[1] !== item.path.split("/")[1],
                        })}
                      />
                    </div>
                  </Fragment>
                )
              })}
          </div>
        )}
        {name && (
          <h1 className={classNames("flex-1 mr-2 md:mr-4 text-base lg:text-xl font-semibold truncate", titleClassName)}>
            {name}
          </h1>
        )}
        <AnimatePresence mode="wait">
          {user ? (
            <motion.div
              key={"signed-in"}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{
                duration: 0.25,
                delay: 0.35,
              }}
              className={classNames("flex items-center justify-end flex-1 p-2 xl:p-4")}
            >
              <div className="md:space-x-1 flex items-center">
                <RoomButton />
                <TaskTracker />
                <NotificationBell />
                <CreditsInfo className="hidden md:flex" />
                <PointsInfo className="hidden md:flex" />
              </div>
              <div className="w-[1px] bg-atherGray-800 h-8 ml-4 mr-2" />
              <div className="">
                <UserInfo />
              </div>
            </motion.div>
          ) : (
            <motion.div
              key={"not-signed"}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{
                duration: 0.25,
                delay: 0.35,
              }}
              className={classNames("flex items-center justify-end flex-1 p-4")}
            >
              <div className="md:space-x-1 flex items-center">
                <SignInRequiredButton
                  className="w-[10rem] rounded-xl bg-atherPurple-500 p-2 font-semibold text-[#EFE8FD]"
                  text="Let’s Imagine"
                />
              </div>
            </motion.div>
          )}
        </AnimatePresence>
        <SidebarDrawerMobile />
        <NotificationDrawer />
      </div>
    </nav>
  )
}

export default CommonNavbar
