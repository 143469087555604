import { IconProps } from "."

export const SparklesIcon = (props: IconProps) => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M15.6683 2L16.2071 4.39157C16.664 6.42015 18.2482 8.00431 20.2768 8.46127L22.6683 9L20.2768 9.53873C18.2482 9.99569 16.664 11.5798 16.2071 13.6084L15.6683 16L15.1296 13.6084C14.6726 11.5798 13.0885 9.99569 11.0599 9.53873L8.66833 9L11.0599 8.46127C13.0885 8.00431 14.6726 6.42015 15.1296 4.39158L15.6683 2Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M7.66834 12L8.05314 13.7083C8.37954 15.1572 9.51108 16.2888 10.9601 16.6152L12.6683 17L10.9601 17.3848C9.51108 17.7112 8.37954 18.8427 8.05314 20.2917L7.66834 22L7.28353 20.2917C6.95713 18.8427 5.82559 17.7112 4.3766 17.3848L2.66833 17L4.3766 16.6152C5.82558 16.2888 6.95713 15.1573 7.28353 13.7083L7.66834 12Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  )
}
