import { forwardRef } from "react"

export interface HiddenFileInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  onUpload: (files: File[]) => void
  accept?: string
}

const HiddenFileInput = forwardRef<HTMLInputElement, HiddenFileInputProps>(({ onUpload, accept, ...rest }, ref) => {
  const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const files = Array.from(event.target.files)
      onUpload(files)
    }
  }

  return (
    <input
      type="file"
      ref={ref}
      onClick={e => (e.currentTarget.value = "")}
      onChange={handleFileInputChange}
      style={{ display: "none" }}
      accept={accept ?? "image/jpeg,image/png,image/gif,image/heic,image/webp"} // Accept only images
      multiple
      {...rest}
    />
  )
})

export default HiddenFileInput
