import client from "@/api/client"
import { createAuthenticatedQuery } from "../createAuthenticatedQuery"
import { SubscriptionGroup, SubscriptionPackageItem } from "@/api/sdk"

export const useGetListSubscriptionQuery = createAuthenticatedQuery<SubscriptionPackageItem[], unknown, Error>({
  primaryKey: "subscriptions-payments",
  queryFn: ({ queryKey: [_primaryKey, variables] }) =>
    client.api.paymentControllerGetSubscriptionPackages({ group: SubscriptionGroup.Regular }).then(res => res.data),
})

export const useGetListModelStorageSubscriptionQuery = createAuthenticatedQuery<
  SubscriptionPackageItem[],
  unknown,
  Error
>({
  primaryKey: "subscriptions-payments-model-storage",
  queryFn: ({ queryKey: [_primaryKey, variables] }) =>
    client.api
      .paymentControllerGetSubscriptionPackages({ group: SubscriptionGroup.ModelStorage })
      .then(res => res.data),
})
