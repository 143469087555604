import client from "@/api/client"
import { ChatRoomItem } from "@/api/sdk"
import { createAuthenticatedInfiniteQuery } from "@/queries/createAuthenticatedQuery"
import { enableInfiniteQueryMiddleware } from "@/queries/middlewares/enableQueryMiddleware"

export const useWorkspaceSearchRoomsInfiniteQuery = createAuthenticatedInfiniteQuery<
  ChatRoomItem[],
  { searchTerm: string; isPrivate?: boolean },
  Error,
  ChatRoomItem
>({
  primaryKey: "infinite-workspace-search-rooms",
  queryFn: ({ queryKey: [_primaryKey, variables], pageParam = 0 }) =>
    client.api
      .chatRoomControllerSearchChatRooms({
        skip: pageParam * 50,
        take: 50,
        searchTerm: variables.searchTerm ? variables.searchTerm : undefined,
        isPrivate: variables.isPrivate,
      })
      .then(res => res.data),
  getNextPageParam: (lastPage, pages) => (lastPage && lastPage.length < 50 ? undefined : pages.length),
  initialPageParam: 0,
  flattenFn: data => data,
  use: [enableInfiniteQueryMiddleware(v => !!v.searchTerm)],
})
