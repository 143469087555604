import client from "@/api/client"
import { GetWorkflowsResult, PinnedItemType, PinnedItems, RecentWorkflowItem, SharingRole } from "@/api/sdk"
import { InfiniteData, useQueryClient } from "@tanstack/react-query"
import { createMutation } from "react-query-kit"
import { actionMiddleware } from "../middlewares/actionMiddleware"
import { useWorkspaceWorkflowInfiniteQuery } from "../workspace/useWorkspaceWorkflowInfiniteQuery"
import { useRecentWorkflowInfiniteQuery } from "@/components/Workspace/RecentV2/RecentWorkflow"
import { useWorkspacePinInfiniteQuery } from "../workspace"

export interface UsePublicTaskMutationVariables {
  ids: string[]
  role: SharingRole
  isDiscoverable: boolean
}

export const usePublicTaskMutation = createMutation({
  mutationFn: ({ ids, isDiscoverable, role }: UsePublicTaskMutationVariables) =>
    client.api.workflowControllerUpdateWorkflowsDiscovery({
      isDiscoverable,
      role,
      workflowIds: ids,
    }),
  use: [
    actionMiddleware({
      onSuccess: ({ queryClient, variables }) => {
        // Update workflow list
        const workflowListKey = useWorkspaceWorkflowInfiniteQuery.getKey()

        const workflowListQueriesDataEntries = queryClient.getQueriesData<InfiniteData<GetWorkflowsResult, number>>({
          queryKey: workflowListKey,
        })

        if (workflowListQueriesDataEntries) {
          workflowListQueriesDataEntries.forEach(([key, workflowListData]) => {
            if (workflowListData) {
              const updatedData = {
                ...workflowListData,
                pages: workflowListData.pages.map(page => {
                  return {
                    ...page,
                    workflows: page.workflows.map(workflow => {
                      if (variables.ids.includes(workflow.id)) {
                        return {
                          ...workflow,
                          discoverableAt: variables.isDiscoverable ? new Date() : null,
                        }
                      }

                      return workflow
                    }),
                  }
                }),
              }

              queryClient.setQueryData(key, updatedData)
            }
          })
        }

        // Update workflow recent list

        const workflowRecentListKey = useRecentWorkflowInfiniteQuery.getKey()

        const workflowRecentListQueriesDataEntries = queryClient.getQueriesData<
          InfiniteData<RecentWorkflowItem[], number>
        >({
          queryKey: workflowRecentListKey,
        })

        if (workflowRecentListQueriesDataEntries) {
          workflowRecentListQueriesDataEntries.forEach(([key, workflowRecentListData]) => {
            if (workflowRecentListData) {
              const updatedData = {
                ...workflowRecentListData,
                pages: workflowRecentListData.pages.map(page => {
                  return [
                    ...page.map(item => {
                      if (variables.ids.includes(item.workflow.id)) {
                        return {
                          ...item,
                          workflow: {
                            ...item.workflow,
                            discoverableAt: variables.isDiscoverable ? new Date() : null,
                          },
                        }
                      }

                      return item
                    }),
                  ]
                }),
              }

              queryClient.setQueryData(key, updatedData)
            }
          })
        }

        // Update workflow pinned list
        const workflowPinnedListKey = useWorkspacePinInfiniteQuery.getKey({
          type: PinnedItemType.WORKFLOW,
        })

        const workflowPinnedListQueriesDataEntries = queryClient.getQueriesData<InfiniteData<PinnedItems[], number>>({
          queryKey: workflowPinnedListKey,
        })

        if (workflowPinnedListQueriesDataEntries) {
          workflowPinnedListQueriesDataEntries.forEach(([key, workflowPinnedListData]) => {
            if (workflowPinnedListData) {
              const updatedData = {
                ...workflowPinnedListData,
                pages: workflowPinnedListData.pages.map(page => {
                  return [
                    ...page.map(item => {
                      if (variables.ids.includes(item.workflow?.id || "")) {
                        return {
                          ...item,
                          workflow: {
                            ...item.workflow,
                            discoverableAt: variables.isDiscoverable ? new Date() : null,
                          },
                        }
                      }

                      return item
                    }),
                  ]
                }),
              }

              queryClient.setQueryData(key, updatedData)
            }
          })
        }
      },
    }),
    useMutationNext => {
      return (options, queryClient) => {
        const qc = useQueryClient(queryClient)

        return useMutationNext({
          ...options,
          onSettled: (_, __, {}) => {},
        })
      }
    },
  ],
})
