import React, { memo, useRef } from "react"
import classNames from "classnames"
import { motion } from "framer-motion"
import LoadingIcon from "@/components/LoadingIcon"
import { cn } from "@/lib/utils"
import { TaskProgressSocketEvent } from "@/providers/ProgressingProvider"

const ImageLoadingItem = memo(
  ({
    image,
    height,
    progressData,
    loadingClassName,
    className,
    children,
  }: {
    image: {
      id: string
      name: string | null
      url?: string
      blurHash: string | null
    }
    className?: string
    loadingClassName?: string
    progressData?: TaskProgressSocketEvent
    height?: string
    children?: React.ReactNode
  }) => {
    const imageItemRef = useRef<HTMLDivElement>(null)

    let stepCompleted = ((progressData?.node ?? 1) - 1) / (progressData?.total_nodes ?? 1)
    const totalCurrentStepCompleted = stepCompleted * (1 / (progressData?.total_steps ?? 1))
    const totalPreviousStepsCompleted = ((progressData?.step ?? 1) - 1) / (progressData?.total_steps ?? 1)

    let totalStepCompleted = totalPreviousStepsCompleted + totalCurrentStepCompleted

    stepCompleted = Math.round(stepCompleted * 100)
    totalStepCompleted = Math.round(totalStepCompleted * 100)

    return (
      <div
        className={cn(
          "relative shrink w-full group overflow-hidden h-full",
          {
            "cursor-pointer": image.url,
          },
          className,
        )}
      >
        <div
          ref={imageItemRef}
          className={classNames("image-item relative")}
          style={{
            height: height || "10rem",
          }}
        >
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.25 }}
            style={{
              height: height || "10rem",
            }}
          >
            <div
              className={cn(
                "relative group h-full flex items-center justify-center overflow-hidden bg-black",
                loadingClassName,
              )}
            >
              <div className="flex justify-center items-center space-x-2">
                <LoadingIcon color="#7828FB" width={20} height={20} />
                {totalStepCompleted > 0 && <p className="text-sm">{totalStepCompleted}%</p>}
              </div>
            </div>
          </motion.div>
        </div>
        {children}
      </div>
    )
  },
)

export default memo(ImageLoadingItem)
