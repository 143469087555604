import client from "@/api/client"
import { GetModelsResponse, SDBaseModel, SDModelVariant } from "@/api/sdk"
import ImageView from "@/components/ImageUpload/ImageView"
import { ChevronDownIcon, InfoIcon, XIcon } from "@/components/shared/icons"
import { useDebounce } from "@/hooks"
import { createAuthenticatedQuery } from "@/queries/createAuthenticatedQuery"
import { css } from "@emotion/css"
import classNames from "classnames"
import { AnimatePresence } from "framer-motion"
import { FC, useEffect, useMemo, useState } from "react"
import ReactSlider from "react-slider"
import DrawerSelect from "./DrawerSelect"
import { FormDetailComponentProps } from "./types"

export type FormDetailLoraComponentProps = FormDetailComponentProps & {
  baseModel?: SDBaseModel
  drawerClassName?: string
  loraIds?: string[]
}

interface SelectedLora {
  hash: string
  name: string
  fileName: string
  strength: number
  thumbnail?: string
}

export const useLoraQuery = createAuthenticatedQuery<
  GetModelsResponse,
  { baseModel?: SDBaseModel; ids?: string[] },
  Error
>({
  primaryKey: "list_loras_support",
  queryFn: ({ queryKey: [_primaryKey, variables] }) =>
    client.api
      .sdModelControllerList({
        baseModel: variables?.baseModel,
        variant: SDModelVariant.Lora,
        ids: variables?.ids,
      })
      .then(res => res.data),
})

const FormDetailLoraComponent: FC<FormDetailLoraComponentProps> = props => {
  const { input, value, onChange, baseModel, drawerClassName, loraIds } = props

  const [selectedLoras, setSelectedLoras] = useState<SelectedLora[]>([])
  const [isOpen, setIsOpen] = useState(false)
  const [search, setSearch] = useState("")
  const searchDebounce = useDebounce(search, 350)

  const { data: loraInitialData, isLoading: isLoadingInitialData } = useLoraQuery({
    variables: { baseModel, ids: loraIds },
    enabled: !!loraIds?.length,
  })

  const { data, isLoading } = useLoraQuery({
    variables: { baseModel },
  })

  const models = useMemo(
    () => data?.models?.filter(i => i.name.toLowerCase().includes(searchDebounce.toLowerCase())) ?? [],
    [data, searchDebounce],
  )

  useEffect(() => {
    if (isLoadingInitialData || isLoading || !data?.models.length) return

    if (!value) {
      setSelectedLoras([])
    } else {
      try {
        const parsed = JSON.parse(value)

        if (Array.isArray(parsed)) {
          const loras = parsed.map((l: any) => {
            const model = data.models.find(m => m.fileName === l.name)

            if (!model) {
              return undefined
            }

            return {
              hash: model.id,
              name: model.name,
              fileName: model.fileName,
              thumbnail: model.thumbnailUrl,
              strength: l.strength,
            }
          })

          setSelectedLoras(loras.filter(l => l !== undefined) as SelectedLora[])
        }
      } catch (e) {
        console.error(e)
      }
    }
  }, [data, value, isLoading, isLoadingInitialData])

  useEffect(() => {
    if (!!loraIds?.length && loraInitialData?.models && loraInitialData?.models.length > 0) {
      setSelectedLoras(
        loraInitialData.models.map(m => ({
          hash: m.id,
          name: m.name,
          thumbnail: m.thumbnailUrl,
          fileName: m.fileName,
          strength: 1,
        })),
      )
    }
  }, [loraIds, loraInitialData?.models])

  const [selectedCount, setSelectedCount] = useState(0)

  useEffect(() => {
    if (selectedCount === 0) return

    onChange({
      key: input.key,
      value:
        selectedLoras.length === 0
          ? ""
          : JSON.stringify(selectedLoras.map(l => ({ name: l.fileName, strength: l.strength, displayName: l.name }))),
    })
  }, [selectedCount])

  const handleAddLora = (hash: string) => {
    if (!hash) return

    const lora = models.find(m => m.id === hash)

    if (!lora) return

    const loraAlreadySelected = selectedLoras?.find(l => l.hash === hash)
    if (loraAlreadySelected) {
      setSelectedLoras(prev => {
        return prev.filter(l => l.hash !== hash)
      })

      setSelectedCount(prev => prev + 1)

      return
    }

    if (selectedLoras.length === 2) {
      setIsOpen(false)
    }

    if (selectedLoras.length >= 3) {
      return
    }

    setSelectedLoras(prev => {
      const current = [
        ...(prev ?? []),
        {
          hash: lora.id,
          name: lora.name,
          fileName: lora.fileName,
          strength: 1,
        },
      ]

      return current
    })

    setSelectedCount(prev => prev + 1)
  }

  const handleUpdateLoraStrength = (loraHash: string, strength: number, inputKey) => {
    setSelectedLoras(prev => {
      const current = prev.map(l => {
        if (l.hash === loraHash) {
          return {
            ...l,
            strength,
          }
        }

        return l
      })

      onChange({
        key: inputKey,
        value:
          current.length === 0 ? "" : JSON.stringify(current.map(l => ({ name: l.fileName, strength: l.strength }))),
      })

      return current
    })
  }

  const handleDeleteLora = (e: any, loraHash, inputKey) => {
    setSelectedLoras(prev => {
      const current = [...(prev ?? []).filter(l => l.hash !== loraHash)]

      onChange({
        key: inputKey,
        value:
          current.length === 0 ? "" : JSON.stringify(current.map(l => ({ name: l.fileName, strength: l.strength }))),
      })

      return current
    })
  }

  return (
    <div>
      <div className="max-w-[24rem] flex">
        <div className="flex flex-col flex-1">
          <p className="text-sm text-atherGray-300 font-semibold">Lora Model</p>
          <p className="mb-2 text-sm text-atherGray-400">{input.description}</p>
          <div className="flex items-start">
            <div
              className={classNames(
                "bg-atherGray-800 p-2 text-sm flex-1 rounded-lg flex items-center overflow-hidden cursor-pointer border",
                {
                  "border-atherGray-800": !isOpen,
                  "border-atherPurple-500": isOpen,
                },
              )}
              onClick={() => setIsOpen(prev => !prev)}
            >
              <p className="line-clamp-1 flex-1">{`Select maximum ${selectedLoras.length}/3 Loras`}</p>
              <ChevronDownIcon className="-rotate-90 w-3 h-3 text-atherGray-300 ml-1" />
            </div>
          </div>
        </div>
        <AnimatePresence mode="wait">
          {isOpen && (
            <DrawerSelect
              isOpen={isOpen}
              className={drawerClassName}
              mappedData={models.map(m => ({
                id: m.id,
                name: m.name,
                thumbnailUrl: m.thumbnailUrl,
                category: m.styles,
              }))}
              onClose={() => setIsOpen(false)}
              descriptionNode={
                <div className="flex items-center text-xs mt-2">
                  <InfoIcon className="text-atherGray-600 mr-1" width={16} height={16} />
                  <p className="text-atherGray-300">
                    You can choose maximum <span className="text-atherGray-0 font-semibold">3</span> Loras
                  </p>
                </div>
              }
              search={search}
              setSearch={setSearch}
              title={`Lora`}
              multiple
              values={selectedLoras.map(l => l.hash)}
              setSelected={v => {
                if (!v) return
                handleAddLora(v)
              }}
            />
          )}
        </AnimatePresence>
      </div>
      {selectedLoras.length > 0 && (
        <div className="mt-4 flex flex-col gap-3">
          {selectedLoras.map((lora, idx) => {
            return (
              <div key={lora.hash} className="flex h-12 items-center relative">
                <ImageView
                  className="!h-12 min-w-[3rem] rounded-lg overflow-hidden"
                  imgClassName="w-12 object-cover"
                  url={lora.thumbnail ?? ""}
                />
                <div className="pl-2 pr-0 flex-1 w-[calc(100%-3rem)]">
                  <div className="flex gap-2 items-center mb-2">
                    <p className="text-atherGray-300 text-xs flex-1 truncate" title={lora.name}>
                      {lora.name}
                    </p>
                    <XIcon
                      className="flex-0 text-red-500 w-3 h-3 cursor-pointer"
                      onClick={e => handleDeleteLora(e, lora.hash, input.key)}
                    />
                  </div>
                  <div
                    className={css({
                      position: "relative",
                      maxWidth: "24rem",
                      ".track-slider-0": {
                        backgroundColor: "#5E17EB",
                      },
                      ".track-slider-1": {
                        backgroundColor: "#E2E8F0",
                      },
                    })}
                  >
                    <div className={classNames("relative items-center flex w-full")}>
                      <ReactSlider
                        value={lora.strength ?? 0}
                        onChange={v => {
                          handleUpdateLoraStrength(lora.hash, v, input.key)
                        }}
                        min={-1}
                        max={2}
                        step={0.05}
                        className="h-1 horizontal-slider w-full"
                        thumbClassName="h-4 w-4 bg-white top-1/2 -translate-y-1/2 rounded-full cursor-pointer"
                        trackClassName="h-1 track-slider"
                        renderThumb={(props, state) => (
                          <div {...props}>
                            <div className="bg-atherPurple-500 w-2.5 h-2.5 absolute top-1/2 left-1/2 -translate-x-[50%] -translate-y-[50%] rounded-full" />
                          </div>
                        )}
                      />
                      <div className="py-0.5 w-[4rem] flex justify-center bg-atherGray-700 ml-2 rounded-md items-center">
                        <p className="text-xs">{lora.strength ?? 0}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default FormDetailLoraComponent
