import Modal from "@/components/Modal"
import React, { useEffect, useState } from "react"
import { RecipeTaskChainParams } from "../../Workspace/Workflows/WorkflowsChaining"
import { Radio } from "@/components/Alpha/View/GalleryFilter"
import IconButton from "@/components/IconButton"

interface SelectPrevTaskImageModalProps {
  isOpen: boolean
  onClose: () => void
  chains?: RecipeTaskChainParams[]
  value: string
  onChange: (value: string) => void
  index: string
}

const SelectPrevTaskImageModal = ({
  isOpen,
  onClose,
  chains,
  value,
  onChange,
  index,
}: SelectPrevTaskImageModalProps) => {
  const [tempValue, setTempValue] = useState("0")

  useEffect(() => {
    setTempValue(value?.includes("$$prev.") ? value?.replace("$$prev.", "") : "0")
  }, [value])

  const mappedChains = chains?.filter((chain, idx) => idx < Number(index))

  return (
    <Modal
      title="Select image from previous step"
      isOpen={isOpen}
      onClose={onClose}
      bodyClassName="overflow-hidden p-0"
      className="relative w-full max-w-xl  overflow-hidden rounded-2xl bg-atherGray-900"
      containerClassName={"md:backdrop-blur-xl overflow-hidden"}
    >
      <div className="flex flex-col space-y-2">
        {mappedChains?.map((chain, index) => (
          <Radio
            key={index}
            className="w-full"
            name={`Step ${index + 1}: ${chain.recipeName ?? chain.recipeId}`}
            value={index.toString()}
            checked={tempValue === index.toString()}
            onChange={v => {
              setTempValue(v)
            }}
          />
        ))}
      </div>
      <div className="flex justify-end items-center space-x-2 mt-6 border-t border-atherGray-800 pt-4">
        <IconButton onClick={onClose} colorScheme="secondary">
          Cancel
        </IconButton>
        <IconButton
          onClick={() => {
            onChange(`$$prev.${tempValue}`)
          }}
        >
          Select step
        </IconButton>
      </div>
    </Modal>
  )
}

export default SelectPrevTaskImageModal
