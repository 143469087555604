import { create } from "zustand"

interface DeleteItemStore {
  leaveFolder: {
    id: string
    onClose?: () => void
    [key: string]: any
  } | null

  setLeaveFolder: (item: DeleteItemStore["leaveFolder"]) => void
}

export const useLeaveFolderStore = create<DeleteItemStore>(set => ({
  leaveFolder: null,
  setLeaveFolder: leaveFolder => set({ leaveFolder }),
}))
