import client from "@/api/client"
import { actionMiddleware } from "@/queries/middlewares/actionMiddleware"
import { createMutation } from "react-query-kit"
import { useFolderInfoQuery } from "./useFolderInfoQuery"
import { useSubFoldersInfiniteQuery, useSubFoldersQuery } from "./useSubFoldersInfiniteQuery"
import { GetSubFoldersResponse } from "@/api/sdk"
import { InfiniteData } from "@tanstack/react-query"

export const useMoveFolderMutation = createMutation({
  mutationFn: ({ folderIds, newParentId }: { folderIds: string[]; oldParentId: string; newParentId: string }) =>
    client.api.folderControllerMoveFolder({ folderIds, parentFolderId: newParentId }),
  use: [
    actionMiddleware({
      onSuccess: ({ queryClient, variables }) => {
        if (variables.oldParentId) {
          const parentSubFoldersKey = useSubFoldersInfiniteQuery.getKey({
            folderId: variables.oldParentId,
          })

          const parentSubFoldersQueriesDataEntries = queryClient.getQueriesData<
            InfiniteData<GetSubFoldersResponse, number>
          >({
            queryKey: parentSubFoldersKey,
          })
          if (parentSubFoldersQueriesDataEntries) {
            parentSubFoldersQueriesDataEntries.forEach(([key, parentSubFoldersData]) => {
              if (parentSubFoldersData) {
                const updatedData = {
                  ...parentSubFoldersData,
                  pages: parentSubFoldersData.pages.map(page => {
                    return {
                      ...page,
                      total: page.total - variables.folderIds.length,
                      folders: page.folders.filter(folder => !variables.folderIds.includes(folder.id)),
                    }
                  }),
                }

                queryClient.setQueryData(key, updatedData)
              }
            })
          }
        }
      },
      onSettled({ queryClient, variables: { folderIds, oldParentId, newParentId } }) {
        queryClient.invalidateQueries({
          queryKey: useSubFoldersInfiniteQuery.getKey({ folderId: newParentId }),
        })
        queryClient.invalidateQueries({
          queryKey: useSubFoldersQuery.getKey({ folderId: newParentId }),
        })
        queryClient.invalidateQueries({
          queryKey: useSubFoldersInfiniteQuery.getKey({ folderId: oldParentId }),
        })
        queryClient.invalidateQueries({
          queryKey: useSubFoldersQuery.getKey({ folderId: oldParentId }),
        })
        if (folderIds.length === 1) {
          queryClient.invalidateQueries({
            queryKey: useFolderInfoQuery.getKey({ folderId: folderIds[0] }),
          })
        }
      },
    }),
  ],
})
