import { FolderDetail } from "@/api/sdk"
import { useAuth } from "@/providers/authContext"
import { cn } from "@/utils/cn"
import { Url } from "next/dist/shared/lib/router/router"
import { memo, useEffect, useRef } from "react"
import { useDrag } from "react-dnd"
import FolderItemGrid from "../RecentV2/RecentFolders/FolderItemGrid"
import FolderItemList from "../RecentV2/RecentFolders/FolderItemList"
import useDragAndDrop from "./useDragAndDrop"

export interface GalleryItemProps {
  folder: FolderDetail
  onSelect?: () => void
  className?: string
  layout?: "grid" | "list"
  accessedAt?: string
  selectionMode?: boolean
  multiSelectedItems?: FolderDetail[]
  clearMultiSelectedItems?: () => void
  onDropSuccess?: () => void
  href: Url
  hiddenMenu?: boolean
  isChecked?: boolean
  onCheck?: (folderId: string) => void
  onRef?: (ref: HTMLDivElement | null) => void
  onDragOver?: () => void
  onDragLeave?: () => void
}

const FolderItem = ({
  folder,
  onSelect,
  className,
  layout,
  hiddenMenu,
  onRef,
  clearMultiSelectedItems,
  accessedAt,
  selectionMode,
  multiSelectedItems,
  href,
  onDropSuccess,
  isChecked,
  onCheck,
  onDragLeave,
  onDragOver,
}: GalleryItemProps) => {
  const { userInfoQuery } = useAuth()
  const selectionModeRef = useRef(selectionMode)
  const multiSelectedItemsRef = useRef(multiSelectedItems)
  const imageRef = useRef<HTMLImageElement>(null)

  useEffect(() => {
    selectionModeRef.current = selectionMode
  }, [selectionMode])

  useEffect(() => {
    multiSelectedItemsRef.current = multiSelectedItems
  }, [multiSelectedItems])

  const handleOnDrag = () => {
    let result

    if (!multiSelectedItemsRef.current) return
    // when selection mode is off, we can only drag one image at a time
    if (!selectionModeRef.current) result = { folders: [folder] }
    // when selection mode is on, we can drag multiple images
    // cancel when current image is not selected
    else if (!multiSelectedItemsRef.current.find(item => item.id === folder.id)) result = null
    else if (multiSelectedItemsRef.current.length === 0) return null
    else result = { folders: multiSelectedItemsRef.current }

    return result
  }

  const [{ isDragging }, dragRef, preview] = useDrag(() => ({
    type: "folder",
    item: () => handleOnDrag(),
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
    end: (item, monitor) => {
      const result = monitor.getDropResult()
      if (item && result) {
        onDropSuccess?.()
      }
    },
  }))

  const { dropRef, isOver } = useDragAndDrop({
    data: folder,
    onSuccessDrop: () => {
      clearMultiSelectedItems?.()
    },
  })

  useEffect(() => {
    if (isDragging) {
      onDragOver?.()
    } else {
      onDragLeave?.()
    }
  }, [isDragging])

  useEffect(() => {
    preview(imageRef, {
      captureDraggingState: true,
    })
  }, [])

  return (
    <div
      key={folder.id}
      data-id={folder.id}
      className={cn("group shrink relative gallery-item select-none", className)}
      ref={e => {
        dropRef(e)
        onRef?.(e)
      }}
      style={{
        transform: "none",
      }}
    >
      {layout === "grid" ? (
        <FolderItemGrid
          href={href}
          imageRef={imageRef}
          dragRef={dragRef}
          selectionMode={selectionMode}
          onClick={onSelect}
          hiddenMenu={hiddenMenu}
          user={userInfoQuery?.data}
          folder={folder}
          isChecked={isChecked}
          onCheck={onCheck}
        />
      ) : (
        <FolderItemList
          selectionMode={selectionMode}
          href={href}
          onClick={onSelect}
          user={userInfoQuery?.data}
          folder={folder}
          accessedAt={accessedAt}
        />
      )}
      <div
        className={cn(
          "hidden absolute text-sm top-0 z-[1] bg-blackAlpha-700 left-0 pointer-events-none w-full h-full",
          {
            "flex items-center justify-center":
              isOver &&
              !multiSelectedItemsRef?.current?.map(item => item.id).includes(folder.id) &&
              (multiSelectedItemsRef?.current?.some(item => item.capabilities.canMove) ||
                (folder.capabilities.canMove && !isDragging)),
          },
        )}
      >
        Drop
      </div>
    </div>
  )
}

export default memo(
  FolderItem,
  ({ ...prevProps }, { ...nextProps }) => JSON.stringify({ prevProps }) === JSON.stringify(nextProps),
)
