import get from "lodash/get"

const halfBatchSizes = ["text-to-gif", "image-to-gif"]

export const getStepSizes = (params: Array<Record<string, any>>) => {
  const sizeByStep = Array.from({ length: params?.length ?? 0 }).fill(1) as number[]

  params.forEach((p, step) => {
    let prevStepOutput = 1

    const hasPrevStep = Object.values(p.params).find(i => typeof i === "string" && i.startsWith("$$prev")) as string
    if (hasPrevStep) {
      const prevStep = parseFloat(hasPrevStep.split(".")[1] || (step - 1).toString())
      const prevStepParams = params[prevStep]
      if (halfBatchSizes.includes(get(prevStepParams, "recipeId"))) {
        prevStepOutput = (sizeByStep[prevStep] || 2) / 2
      } else {
        prevStepOutput = sizeByStep[prevStep] || 1
      }
    }

    let batchSize = get(p, "params.batch_size", 1)

    if (halfBatchSizes.includes(p.recipeId)) {
      batchSize *= 2
    }

    sizeByStep[step] = prevStepOutput * batchSize
  })

  return sizeByStep
}

export const getBatchSize = (params: Array<Record<string, any>>) => {
  const sizeByStep = getStepSizes(params)

  return sizeByStep.reduce((acc, curr) => acc + curr, 0)
}

export function debounce<T extends (...args: any[]) => any>(
  func: T,
  duration: number,
): (...args: Parameters<T>) => () => void {
  let timeout: NodeJS.Timeout | null = null

  return function (this: any, ...args: Parameters<T>): () => void {
    const effect = () => {
      timeout = null
      func.apply(this, args)
    }

    if (timeout) {
      clearTimeout(timeout)
    }

    timeout = setTimeout(effect, duration)

    return () => timeout && clearTimeout(timeout)
  }
}
