import { create } from "zustand"

interface CreateNewStyleStore {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

export const useCreateNewStyleStore = create<CreateNewStyleStore>(set => ({
  isOpen: false,
  setIsOpen: isOpen => set({ isOpen: isOpen }),
}))
