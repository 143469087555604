import client from "@/api/client"
import { useToast } from "@/hooks"
import { useAuth } from "@/providers/authContext"
import { useTempTaskStore } from "@/stores/useTempTaskStore"
import { useTempUploadImagesStore } from "@/stores/useTempUploadImagesStore"
import generateCaptchaToken from "@/utils/generateCaptchaToken"
import { useMutation } from "@tanstack/react-query"
import useCustomRouter from "@/hooks/useCustomRouter"
import { isMobileOnly } from "react-device-detect"

const useUploadImage = (recipeId: string) => {
  const { user } = useAuth()
  const tempTask = useTempTaskStore(state => state.tempTask)
  const { tempUploadImages, setTempUploadImages } = useTempUploadImagesStore()
  const toast = useToast({
    position: !isMobileOnly ? "top-center" : "bottom-center",
  })
  const router = useCustomRouter()

  const mutate = useMutation({
    mutationFn: async (files: File[]) => {
      if (!user) {
        if (tempTask && tempTask.length >= 3) {
          toast({
            status: "error",
            title: "You have reached the limit of free tasks.",
            message: ["Sign-In to remove watermark and get 20 FREE credits!"],
          })

          router.replace(
            {
              query: {
                signIn: true,
              },
            },
            undefined,
            {
              shallow: true,
            },
          )

          return ""
        }

        const token = await generateCaptchaToken()
        return await client.api
          .recipeTaskControllerUploadImagesForFreeTask({ captchaToken: token, files })
          .then(res => res.data.images?.[0]?.url)
      }
      return await client.api.recipeTaskControllerUpload({ files }).then(res => res.data.images?.[0]?.url)
    },
    onSuccess: data => {
      if (!data) return
      setTempUploadImages([
        ...(tempUploadImages ?? []),
        {
          recipeId,
          url: data,
        },
      ])
    },
  })

  return mutate
}

export default useUploadImage
