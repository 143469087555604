import { ContentModerationRating } from "@/api/sdk"
import React from "react"
import IconButton from "./IconButton"
import { googleAnalytics } from "@/lib/gtag"
import { EyeCloseIcon, EyeIcon } from "./shared/icons"
import { cn } from "@/lib/utils"

interface NsfwBlurImageProps {
  moderationRating: ContentModerationRating
  isShow: boolean
  onToggleShow: (isShow: boolean) => void
  buttonCloseClassName?: string
  className?: string
  size?: number
  gaEvent?: {
    tab_name: string
    params: Record<string, string>
  }
}

const NsfwBlurImage: React.FC<NsfwBlurImageProps> = ({
  moderationRating,
  isShow,
  onToggleShow,
  className,
  gaEvent,
  buttonCloseClassName,
  size = 16,
}) => {
  const handleToggle = (newState: boolean) => (e: React.MouseEvent) => {
    e.stopPropagation()
    e.preventDefault()
    onToggleShow(newState)
    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        tab_name: gaEvent?.tab_name ?? "",
        action: "Toggle Image NSFW",
        ...gaEvent?.params,
      },
    })
  }

  if (isShow) {
    return (
      <div
        className={cn(
          "font-semibold rounded-lg p-4 flex items-center flex-col text-[0.65rem] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-[1]",
          className,
        )}
      >
        <span className="bg-red-500 py-1 px-2 rounded-md mb-2">
          <p className="text-center">{moderationRating === ContentModerationRating.HardcoreNsfw ? "NSFW" : "sNSFW"}</p>
        </span>
        <IconButton
          className="p-1.5 min-h-0 bg-blackAlpha-700 hover:bg-blackAlpha-600"
          onClick={handleToggle(false)}
          colorScheme="transparent"
        >
          <EyeIcon width={size} height={size} />
        </IconButton>
      </div>
    )
  }

  return (
    <IconButton
      className={cn("absolute top-2 left-2 p-1 min-h-0 opacity-70 hover:opacity-100 z-[1]", buttonCloseClassName)}
      colorScheme="red"
      onClick={handleToggle(true)}
    >
      <EyeCloseIcon width={size} height={size} />
    </IconButton>
  )
}

export default NsfwBlurImage
