import { IconProps } from "."

export const ShareIcon8 = (props: IconProps) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M14 4.33398C14 5.43855 13.1046 6.33398 12 6.33398C10.8954 6.33398 10 5.43855 10 4.33398C10 3.22941 10.8954 2.33398 12 2.33398C13.1046 2.33398 14 3.22941 14 4.33398Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M6 8C6 9.10457 5.10457 10 4 10C2.89543 10 2 9.10457 2 8C2 6.89543 2.89543 6 4 6C5.10457 6 6 6.89543 6 8Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M14 11.668C14 12.7725 13.1046 13.668 12 13.668C10.8954 13.668 10 12.7725 10 11.668C10 10.5634 10.8954 9.66797 12 9.66797C13.1046 9.66797 14 10.5634 14 11.668Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M5.81836 7.16761L10.1517 5.16797M5.81836 8.83464L10.1517 10.8343"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </svg>
  )
}

export default ShareIcon8
