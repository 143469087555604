import client from "@/api/client"
import { ShareByWorkspaceMode, SharingRole } from "@/api/sdk"
import { createMutation } from "react-query-kit"
import { actionMiddleware } from "../../middlewares/actionMiddleware"
import { useFolderInfoQuery } from "./useFolderInfoQuery"

export interface UseShareFolderByWorkspaceVariables {
  folderId: string
  mode: ShareByWorkspaceMode
  workspaceId?: string
  role?: SharingRole
}

export const useShareFolderByWorkspaceMutation = createMutation({
  mutationFn: ({ folderId, mode, workspaceId, role }: UseShareFolderByWorkspaceVariables) =>
    client.api.folderControllerShareFolderByWorkspace(folderId, {
      mode,
      workspaceId,
      role,
    }),
  use: [
    actionMiddleware({
      onSettled: ({ queryClient, variables }) => {
        const folderId = variables.folderId

        queryClient.invalidateQueries({
          queryKey: useFolderInfoQuery.getKey({ folderId }),
        })
      },
    }),
  ],
})

export interface UseUpdateWorkspaceRoleVariables {
  folderId: string
  role: SharingRole
}

export const useUpdateWorkspaceRoleMutation = createMutation({
  mutationFn: ({ folderId, role }: UseUpdateWorkspaceRoleVariables) =>
    client.api.folderControllerSwitchShareFolderByWorkspaceMode(folderId, {
      role,
    }),
  use: [
    actionMiddleware({
      onSettled: ({ queryClient, variables }) => {
        const folderId = variables.folderId

        queryClient.invalidateQueries({
          queryKey: useFolderInfoQuery.getKey({ folderId }),
        })
      },
    }),
  ],
})
