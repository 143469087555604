import HiddenFileInput from "@/components/HiddenFileInput"
import LoadingIcon from "@/components/LoadingIcon"
import { useFileDrop } from "@/hooks"
import { cn } from "@/lib/utils"
import { type UploadProgress } from "@/stores/useUploadProgressStore"
import { motion } from "framer-motion"
import React, { FC, HTMLAttributes, ReactNode, useState, useCallback } from "react"

interface FileUploadProps extends Omit<HTMLAttributes<HTMLDivElement>, "onChange"> {
  upload: (files: File[]) => Promise<void>
  multiple?: boolean
  disabled?: boolean
  children?: ReactNode
  accept?: string // Supported file types
  fileInputRef: React.RefObject<HTMLInputElement>
  isLoading?: boolean
  progress?: UploadProgress
}

const FileUpload: FC<FileUploadProps> = ({
  children,
  multiple,
  isLoading,
  upload,
  disabled,
  fileInputRef,
  accept,
  progress,
  ...props
}) => {
  const [isUploading, setUploading] = useState(false)

  const triggerFileSelect = useCallback(() => {
    if (isUploading || !fileInputRef?.current) return
    fileInputRef.current.click()
  }, [isUploading, fileInputRef])

  const handleUpload = async (files: File[]) => {
    setUploading(true)
    return upload(files)
      .catch(() => {})
      .finally(() => {
        setUploading(false)
      })
  }

  const { isOver: isDroppingImage, bind } = useFileDrop({
    onDrop: async files => {
      if (files.length > 0) {
        await handleUpload(files)
      }
    },
    enabled: !disabled && !isUploading && !isLoading,
  })

  return (
    <>
      <div
        {...props}
        className={cn(
          "relative bg-atherGray-950 overflow-hidden h-[10rem] border-dashed border border-atherGray-700 rounded-xl font-normal text-sm cursor-pointer",
          {
            "bg-atherGray-850": isDroppingImage,
          },
          props.className,
        )}
        onClick={triggerFileSelect}
        {...bind}
        aria-disabled={disabled}
        aria-busy={isUploading || isLoading}
      >
        {children}
        {!disabled && isDroppingImage && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="absolute top-0 left-0 flex items-center justify-center w-full h-full bg-blackAlpha-800 rounded-xl pointer-events-none select-none"
          >
            <p className="text-atherOrange-500">Drop to upload</p>
          </motion.div>
        )}
        {(isUploading || isLoading) && (
          <>
            {progress && (
              <div className="w-full bg-atherGray-800 rounded-full h-1 absolute top-0 left-0 z-10">
                <div
                  className="bg-atherPurple-500 h-1 rounded-full transition-all duration-300"
                  style={{ width: `${progress.percent}%` }}
                />
              </div>
            )}
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="absolute top-0 left-0 flex items-center justify-center w-full h-full bg-blackAlpha-800 rounded-xl"
            >
              <LoadingIcon width={20} height={20} />
            </motion.div>
          </>
        )}
      </div>
      <HiddenFileInput
        multiple={multiple}
        disabled={disabled}
        accept={accept}
        onUpload={files => {
          if (!fileInputRef?.current) return

          if (files.length > 0) {
            handleUpload(files).then(() => {
              if (fileInputRef.current) {
                fileInputRef.current.value = ""
              }
            })
          }
        }}
        ref={fileInputRef}
      />
    </>
  )
}

export default FileUpload
