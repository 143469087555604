import client from "@/api/client"
import { RecipeTaskItem, RecipeTaskStatus } from "@/api/sdk"
import { createAuthenticatedInfiniteQuery } from "@/queries/createAuthenticatedQuery"
import { enableInfiniteQueryMiddleware } from "@/queries/middlewares/enableQueryMiddleware"

export const useWorkspaceRoomRecipeTasksInfiniteQuery = createAuthenticatedInfiniteQuery<
  RecipeTaskItem[],
  {
    statuses?: RecipeTaskStatus[]
    recipeId?: string
    roomId: string
    take?: number
    creatorUid?: string
    searchTerm?: string
  },
  Error,
  RecipeTaskItem
>({
  primaryKey: "infinite-workspace-recipe-tasks-room",
  queryFn: ({ queryKey: [_primaryKey, variables], pageParam = 0 }) =>
    client.api
      .chatRoomControllerGetRoomRecipeTasks(variables.roomId, {
        skip: pageParam * (variables.take ?? 40),
        take: variables.take ?? 40,
        recipeId: variables.recipeId,
        creatorUid: variables.creatorUid,
        statuses: variables.statuses,
        searchTerm: variables.searchTerm ? variables.searchTerm : undefined,
      })
      .then(res => res.data),
  getNextPageParam: (lastPage, pages) => (lastPage && lastPage.length < 40 ? undefined : pages.length),
  initialPageParam: 0,
  flattenFn: data => data,
  use: [enableInfiniteQueryMiddleware(v => !!v.roomId)],
})
