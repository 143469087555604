import client from "@/api/client"
import { UpdateSDStyleDto } from "@/api/sdk"
import { useStylesQuery } from "@/components/Workspace/Recipes/RecipeDetail/FormDetail/FormDetailStyle"
import { actionMiddleware } from "@/queries/middlewares/actionMiddleware"
import { createMutation } from "react-query-kit"

interface UpdateSDStyleDtoType extends UpdateSDStyleDto {
  fileIds?: string[]
}

export const useCreateNewStyleMutation = createMutation({
  mutationFn: async (data: UpdateSDStyleDtoType) => client.api.sdStyleControllerCreateDraft(data).then(res => res.data),
})
