import client from "@/api/client"
import { GetRecipesResult, PinnedItemType, PinnedItems, RecentRecipeItem } from "@/api/sdk"
import { InfiniteData, useQueryClient } from "@tanstack/react-query"
import { createMutation } from "react-query-kit"
import { actionMiddleware } from "../../middlewares/actionMiddleware"
import { useGetRecipesInfiniteQuery } from "./getRecipeQueries"
import { useWorkspacePinInfiniteQuery } from "../useWorkspacePinInfiniteQuery"
import { useRecentRecipesInfiniteQuery } from "@/components/Workspace/RecentV2/RecentRecipes"

export interface PublicStyleMutationVariables {
  ids: string[]
  isDiscoverable: boolean
}

export const usePublicRecipeMutation = createMutation({
  mutationFn: ({ ids, isDiscoverable }: PublicStyleMutationVariables) =>
    client.api.recipeControllerUpdateRecipeDiscovery({
      isDiscoverable,
      recipeIds: ids,
    }),
  use: [
    actionMiddleware({
      onSuccess: ({ queryClient, variables }) => {
        // Update recipe list
        const recipesListKey = useGetRecipesInfiniteQuery.getKey()

        const recipesListQueriesDataEntries = queryClient.getQueriesData<InfiniteData<GetRecipesResult, number>>({
          queryKey: recipesListKey,
        })

        if (recipesListQueriesDataEntries) {
          recipesListQueriesDataEntries.forEach(([key, recipeListData]) => {
            if (recipeListData) {
              const updatedData = {
                ...recipeListData,
                pages: recipeListData.pages.map(page => {
                  return {
                    ...page,
                    recipes: page.recipes.map(recipes => {
                      if (variables.ids.includes(recipes.id)) {
                        return {
                          ...recipes,
                          discoverableAt: variables.isDiscoverable ? new Date() : null,
                        }
                      }

                      return recipes
                    }),
                  }
                }),
              }

              queryClient.setQueryData(key, updatedData)
            }
          })
        }

        // Update pinned items
        const recipesPinnedListKey = useWorkspacePinInfiniteQuery.getKey({
          type: PinnedItemType.RECIPE,
        })

        const recipesPinnedListQueriesDataEntries = queryClient.getQueriesData<InfiniteData<PinnedItems[], number>>({
          queryKey: recipesPinnedListKey,
        })

        if (recipesPinnedListQueriesDataEntries) {
          recipesPinnedListQueriesDataEntries.forEach(([key, recipePinnedListData]) => {
            if (recipePinnedListData) {
              const updatedData = {
                ...recipePinnedListData,
                pages: recipePinnedListData.pages.map(page => {
                  return page.map(item => {
                    if (variables.ids.includes(item.recipe?.id ?? "")) {
                      return {
                        ...item,
                        recipe: {
                          ...item.recipe,
                          discoverableAt: variables.isDiscoverable ? new Date() : null,
                        },
                      }
                    }
                    return item
                  })
                }),
              }

              queryClient.setQueryData(key, updatedData)
            }
          })
        }

        // Update recipes recent list
        const recipesRecentListKey = useRecentRecipesInfiniteQuery.getKey()

        const recipesRecentListQueriesDataEntries = queryClient.getQueriesData<
          InfiniteData<RecentRecipeItem[], number>
        >({
          queryKey: recipesRecentListKey,
        })

        if (recipesRecentListQueriesDataEntries) {
          recipesRecentListQueriesDataEntries.forEach(([key, recipesRecentListData]) => {
            if (recipesRecentListData) {
              const updatedData = {
                ...recipesRecentListData,
                pages: recipesRecentListData.pages.map(page => {
                  return page.map(item => {
                    if (variables.ids.includes(item.recipe.id)) {
                      return {
                        ...item,
                        recipe: {
                          ...item.recipe,
                          discoverableAt: variables.isDiscoverable ? new Date() : null,
                        },
                      }
                    }
                    return item
                  })
                }),
              }

              queryClient.setQueryData(key, updatedData)
            }
          })
        }
      },
    }),
    useMutationNext => {
      return (options, queryClient) => {
        const qc = useQueryClient(queryClient)

        return useMutationNext({
          ...options,
          onSettled: (_, __, {}) => {},
        })
      }
    },
  ],
})
