import { ChatRoomItem } from "@/api/sdk"
import { create } from "zustand"

interface CreateRoomStore {
  createRoom: {
    isOpen: boolean
    roomData?: ChatRoomItem
  } | null

  setCreateRoom: (item: CreateRoomStore["createRoom"]) => void
}

export const useCreateRoomStore = create<CreateRoomStore>(set => ({
  createRoom: null,
  setCreateRoom: createRoom => set({ createRoom }),
}))
