import { FolderDetail, FolderWithChildren, ImageSimpleDetail } from "@/api/sdk"
import { useToast } from "@/hooks"
import { useMoveFolderMutation } from "@/queries"
import { useDrag, useDrop } from "react-dnd"

const useDragAndDrop = ({ data, onSuccessDrop }: { data: FolderDetail; onSuccessDrop?: () => void }) => {
  const toast = useToast()

  const { mutate: mutateMoveFolders } = useMoveFolderMutation({
    onSuccess: () => {
      onSuccessDrop?.()
      toast({
        status: "success",
        title: "Folder moved successfully",
      })
    },
  })

  const [{ isDragging }, dragRef] = useDrag(() => ({
    type: "folder",
    item: { folder: data },
    canDrag: data.capabilities?.canMove,
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  }))

  const transverseFolderChildren = (folder: FolderWithChildren, id: string) => {
    if (folder.children?.some(child => child.id === id)) return true
    return folder.children?.some(child => transverseFolderChildren(child, id)) ?? false
  }

  const [{ isOver }, dropRef] = useDrop(() => ({
    accept: ["folder", "image"],
    canDrop(item: { folder?: FolderWithChildren; images?: ImageSimpleDetail[]; folders?: FolderDetail[] }) {
      if (data.deletedAt) return false
      // * FUTURE TODO: allow moving folders and images to the trash.
      // For now, we don't allow moving folders and images to the trash.

      if (item?.folder) {
        if (!item.folder.capabilities?.canMove) return false

        if (!data.capabilities?.canCreate) return false

        // return false when the folder is being dragged over itself
        if (item.folder.id === data.id) return false

        // return false when the folder is being dragged to its parent
        if (item.folder.parentFolder?.id === data.id) return false

        // return false when the folder is being dragged to its child
        if (item.folder.children?.some(child => child.id === data.id)) return false

        if (transverseFolderChildren(item.folder, data.id)) return false
      }

      if (item?.folders) {
        if (item.folders.some(folder => !folder.capabilities.canMove)) return false
        if (!data.capabilities?.canCreate) return false
        if (item.folders.some(folder => folder.id === data.id)) return false

        if (item.folders.some(folder => folder.parentFolder?.id === data.id)) return false
        if (item.folders.some(folder => transverseFolderChildren(folder as any, data.id))) return false
      }

      if (item.images) {
        if (item.images.some(image => !image.capabilities?.canMove)) return false

        // return false when one of the images is already in the folder
        if (item.images.some(image => image.folder?.id === data.id)) return false
      }

      return true
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
    }),
    drop: async item => {
      if (item.folder) {
        if (!data.id) return
        // mutateMoveFolder({ folderId: item.folder.id, newParentId: data.id })
      }

      if (item.folders) {
        if (!data.id) return
        await mutateMoveFolders({
          folderIds: item.folders.map(i => i.id),
          newParentId: data.id,
          oldParentId: data.parentFolder?.id ?? "",
        })
      }

      if (item.images) {
        // mutateMoveImages({ imageIds: item.images.map(image => image.id), folderId: data.id })
      }
    },
  }))

  return {
    dragRef,
    dropRef,
    isDragging,
    isOver,
  }
}

export default useDragAndDrop
