import { FolderDetail, RecipeItem, RecipeTaskStatus, WildcardDetail, WorkflowDetail } from "@/api/sdk"
import IconButton from "@/components/IconButton"
import {
  ArrowLeftRoundIcon,
  ArrowSquareLeftIcon,
  ChevronDownIcon,
  DashboardSquareAdd,
  InfoIcon,
} from "@/components/shared/icons"
import { useScreen } from "@/hooks"
import { googleAnalytics } from "@/lib/gtag"
import { useAuth } from "@/providers/authContext"
import { cn } from "@/utils/cn"
import classNames from "classnames"
import { motion } from "framer-motion"
import dynamic from "next/dynamic"
import React, { useLayoutEffect, useRef, useState } from "react"
import { UseFormReturn } from "react-hook-form"
import { RemoveScroll } from "react-remove-scroll"
import { RecipeTaskChainParams, SelectableRecipeInputField } from "."
import ChainingFormDetailImageComponent from "./ChainingFormDetailImageComponent"
import DrawerSelectFolder from "./DrawerSelectFolder"
import RecipeSelect from "./RecipeSelect"
import { useBrowserRouter } from "@/providers/BrowserRouterProvider"
import { getHasClientHistory } from "@/stores/ClientHistoryStore"
import useCustomRouter from "@/hooks/useCustomRouter"
import BackButton from "@/components/BackButton"
import { RecipeCreateType } from "@/utils/task"

const DynamicFormRecipe = dynamic(() => import("../../Recipes/RecipeDetail/FormDetail"), { ssr: false })

interface SettingRecipeProps {
  form: UseFormReturn<RecipeCreateType, any, undefined>
  isEnabled: boolean
  isOpenSideBar: boolean
  setIsOpenSidebar: React.Dispatch<React.SetStateAction<boolean>>
  selectedRecipe: RecipeItem | undefined
  setSelectedRecipe: (recipe: RecipeItem, params?: Record<string, any>) => void
  recipeInputs: SelectableRecipeInputField[]
  setRecipeInputs: React.Dispatch<React.SetStateAction<SelectableRecipeInputField[]>>
  folder?: FolderDetail
  setFolder: React.Dispatch<React.SetStateAction<FolderDetail | undefined>>
  selectedItem: number | undefined
  chains?: RecipeTaskChainParams[]
  chainRecipe: () => void
  workflowState?: WorkflowDetail
  isLoading: boolean
  wildcardsState: WildcardDetail[]
}

const WorkflowSetting = ({
  form,
  isEnabled,
  isOpenSideBar,
  selectedRecipe,
  setSelectedRecipe,
  recipeInputs,
  chainRecipe,
  setRecipeInputs,
  wildcardsState,
  isLoading,
  folder,
  setFolder,
  chains,
  selectedItem,
  setIsOpenSidebar,
  workflowState,
}: SettingRecipeProps) => {
  const { width } = useScreen()
  const sidebarRef = useRef<HTMLDivElement>(null)
  const [isOpenRecipes, setIsOpenRecipes] = useState(false)
  const { user } = useAuth()

  const textareaRef = useRef<HTMLTextAreaElement>(null)
  const recipeContainerRef = useRef<HTMLDivElement>(null)
  const { back } = useBrowserRouter()
  const router = useCustomRouter()

  const handleBack = () => {
    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        action: "Back",
      },
    })

    const hasHistory = getHasClientHistory()

    if (hasHistory) {
      back()
      return
    }

    if (router.query.shared) {
      router.push("/workspace/macros?tab=shared-with-me")
      return
    }

    if (router.query.draft || workflowState?.status === RecipeTaskStatus.DRAFT) {
      router.push("/workspace/macros?tab=drafts")
      return
    }

    router.push("/workspace/macros")
  }

  useLayoutEffect(() => {
    // Reset height - important to shrink on delete
    if (!textareaRef.current) return
    textareaRef.current.style.height = "inherit"
    // Set height
    textareaRef.current.style.height = `${Math.max(textareaRef.current.scrollHeight, 24)}px`
  }, [selectedRecipe])

  const handleFormChange = ({ key, value }: { key: string; value: any }) => {
    googleAnalytics.event({
      action: "click",
      category: "recipe_setting",
      label: `recipe_setting_${selectedRecipe?.id}`,
      params: {
        recipe_id: selectedRecipe?.id ?? "",
        recipe_name: selectedRecipe?.name ?? "",
        key,
        value: value.toString(),
      },
    })
  }

  const {
    formState: { errors },
  } = form

  if (!isEnabled || !workflowState) return null

  return (
    <>
      <motion.div
        className={cn(
          "border-l border-r border-atherGray-800 bg-atherGray-900",
          "fixed top-[0] lg:sticky md:top-[4.5rem] left-0 overflow-hidden flex flex-col z-[24] md:z-[2] pb-[5.15rem] md:pb-0 height-full-screen md:h-[calc((var(--vh,1vh)*100)-4.5rem-1px)]",
          "transition-all duration-300 ease-in-out",
          {
            "w-0": !isOpenSideBar,
            "w-[88vw] md:w-80": isOpenSideBar,
          },
        )}
      >
        <div className="flex flex-col w-[88vw] md:w-80 bg-atherGray-900 overflow-hidden flex-1">
          <RemoveScroll
            enabled={width < 768 && isOpenSideBar}
            style={{
              animationDelay: "0s",
              overflow: "hidden",
              flex: 1,
              display: "flex",
              flexDirection: "column",
            }}
            removeScrollBar
          >
            <div className="absolute top-0 left-0 pointer-events-none w-full h-full p-4">
              <div className="workflow-settings w-full h-full" />
            </div>
            <div className="flex flex-col flex-1 overflow-hidden ">
              <div className="overflow-auto relative py-4 flex-1 flex flex-col z-[1]">
                <div className="px-4 pb-4 border-b border-atherGray-700" ref={recipeContainerRef}>
                  <div className="w-full mb-4 flex items-center space-x-2">
                    <BackButton
                      className="rounded-lg text-sm py-1.5 px-3 min-h-0"
                      icon={<ArrowLeftRoundIcon className="text-atherGray-300" width={16} height={16} />}
                      label="Back"
                      onClick={() => handleBack()}
                    />
                  </div>
                  <p className="mb-1 text-sm text-atherGray-300">Select a recipe</p>
                  <div
                    className={classNames(
                      "bg-atherGray-800 p-2 text-sm rounded-lg flex items-center overflow-hidden cursor-pointer border",
                      {
                        "border-atherGray-800": !isOpenRecipes,
                        "border-atherPurple-500": isOpenRecipes,
                      },
                    )}
                    onClick={() => setIsOpenRecipes(prev => !prev)}
                  >
                    <p className="line-clamp-1 flex-1">{selectedRecipe?.name ?? ""}</p>
                    <div className="text-atherGray-300">
                      <ChevronDownIcon className="-rotate-90" width={14} height={14} />
                    </div>
                  </div>
                  {selectedRecipe?.description && (
                    <div className="flex items-start mt-2">
                      <InfoIcon className="text-atherGray-600 mr-2" width={14} height={14} />
                      <textarea
                        ref={textareaRef}
                        disabled={true}
                        readOnly={true}
                        value={selectedRecipe?.description}
                        className={classNames("resize-none text-xs text-atherGray-300 flex-1 bg-transparent")}
                      />
                    </div>
                  )}

                  <RecipeSelect
                    isOpen={isOpenRecipes}
                    selectedRecipe={selectedRecipe}
                    setSelectedRecipe={(recipe, params) => setSelectedRecipe(recipe as RecipeItem, params)}
                    onClose={() => setIsOpenRecipes(false)}
                  />
                </div>
                {selectedRecipe && (
                  <div className="p-4 pb-0 flex-1 flex flex-col">
                    <DynamicFormRecipe
                      wildcardsState={wildcardsState}
                      recipeInputs={recipeInputs}
                      setRecipeInputs={setRecipeInputs}
                      chains={chains}
                      form={form}
                      recipe={selectedRecipe}
                      hideOutputFolder
                      componentMap={{
                        image: ChainingFormDetailImageComponent,
                      }}
                      onChange={handleFormChange}
                    />
                  </div>
                )}
              </div>

              <div
                className={classNames("border-t border-atherGray-700 mt-auto px-4 pt-4", {
                  hidden: !user,
                })}
              >
                <h2 className="mb-2 font-semibold text-base">Save the output in</h2>
                <div id="folderId">
                  <DrawerSelectFolder
                    title="Save the output in"
                    selected={folder}
                    setSelected={setFolder}
                    placeHolder="Recipe Images"
                  />
                  {errors.folderId?.message && (
                    <span className="mt-1 text-xs text-red-500">{errors.folderId?.message}</span>
                  )}
                </div>
              </div>

              <div className="flex p-4 items-center space-x-4 text-sm">
                <IconButton
                  onClick={chainRecipe}
                  isLoading={isLoading}
                  className="bg-atherPurple-500 hover:bg-atherPurple-500 w-full active:bg-atherPurple-500 px-6 font-semibold"
                >
                  <div className="flex items-center justify-center">
                    {selectedItem !== undefined
                      ? `${chains?.[selectedItem]?.recipeId !== "add-a-recipe" ? "Update" : "Add Macro"}`
                      : "Add Macro"}
                    <DashboardSquareAdd width={14} height={14} className="ml-1" />
                  </div>
                </IconButton>
              </div>
            </div>
          </RemoveScroll>
        </div>
      </motion.div>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        key={isOpenSideBar ? "open" : "close"}
        transition={{ duration: 0.5, delay: 0.15 }}
        onClick={e => {
          e.stopPropagation()
          setIsOpenSidebar(prev => !prev)
        }}
        className={classNames("fixed flex top-[4.75rem] z-[24] cursor-pointer rounded-md", {
          "rotate-180 left-2": !isOpenSideBar,
          "left-[88vw] md:left-80 lg:left-[18.25rem]": isOpenSideBar,
        })}
      >
        <ArrowSquareLeftIcon width={24} height={24} />
      </motion.div>
    </>
  )
}

export default WorkflowSetting
