import { cn } from "@/lib/utils"
import React from "react"

interface CircularProgressProps {
  color?: string
  progress?: number
  showPercentage?: boolean
  className?: string
  borderWidth?: number
}

const CircularProgress = ({
  color,
  progress = 1,
  showPercentage = false,
  className,
  borderWidth = 2,
}: CircularProgressProps) => {
  return (
    <div className={cn("relative size-5", className)}>
      <svg className="size-full -rotate-90" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
        <circle
          cx="18"
          cy="18"
          r="16"
          fill="none"
          className="stroke-current text-gray-200"
          strokeWidth={borderWidth}
        ></circle>
        <circle
          cx="18"
          cy="18"
          r="16"
          fill="none"
          className="stroke-current text-blue-600 transition-all ease-linear duration-300"
          style={{ color }}
          strokeWidth={borderWidth}
          strokeDasharray="100"
          strokeDashoffset={100 - progress}
          strokeLinecap="round"
        ></circle>
      </svg>
      {showPercentage && (
        <div className="absolute top-1/2 start-1/2 transform -translate-y-1/2 -translate-x-1/2">
          <span className="text-center text-2xl font-bold text-blue-600" style={{ color }}>
            {progress}%
          </span>
        </div>
      )}
    </div>
  )
}

export default CircularProgress
