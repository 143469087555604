import { EntityType, ExploreSDStyleDetail, SDStyleDetail } from "@/api/sdk"
import { googleAnalytics } from "@/lib/gtag"
import classNames from "classnames"
import { useEffect, useState } from "react"
import useCustomRouter from "@/hooks/useCustomRouter"
import MetadataStyle from "./MetadataStyle"
import CommentsStyle from "./CommentsStyle"

interface TabStyleProps {
  style: ExploreSDStyleDetail | SDStyleDetail
  typeComment: EntityType
  onTagFilter?: (tag: number) => void
  onClose: () => void
}

const dataTabs = [
  {
    id: "metadata",
    name: "Metadata",
  },
  {
    id: "comments",
    name: "Comments",
  },
]

type TabType = "comments" | "metadata"

const TabStyle = ({ style, onClose, onTagFilter, typeComment }: TabStyleProps) => {
  const [tab, setTab] = useState<TabType>("metadata")
  const router = useCustomRouter()
  const { notificationId } = router.query

  useEffect(() => {
    if (notificationId) {
      setTab("comments")
    }
  }, [notificationId])

  const renderTab = () => {
    if (tab === "metadata") {
      return <MetadataStyle style={style} onTagFilter={onTagFilter} />
    }

    if (tab === "comments") {
      return <CommentsStyle style={style} typeComment={typeComment} />
    }

    return null
  }

  return (
    <div className="flex flex-col flex-1 w-full overflow-hidden">
      <div className="flex space-x-8 mb-4 w-full border-b-[2px] border-atherGray-800">
        {dataTabs.map(item => (
          <div
            className={classNames(
              "relative font-semibold whitespace-nowrap select-none cursor-pointer active:bg-transparent text-sm md:text-base text-center pb-1",
            )}
            key={item.id}
            onClick={() => {
              googleAnalytics.event({
                action: "click",
                category: "image_detail",
                label: `tab_${item.id}_${item.name}`,
                value: 1,
              })
              setTab(item.id as TabType)
            }}
          >
            <p
              className={classNames({
                "text-atherGray-100": tab === item.id,
                "text-atherGray-500 hover:text-atherGray-300": tab !== item.id,
              })}
            >
              {item.name}
            </p>
            {tab === item.id && (
              <div className="absolute bottom-[-2px] h-[2px] w-[115%] left-1/2 -translate-x-1/2 bg-atherPurple-500" />
            )}
          </div>
        ))}
      </div>
      <div className="flex-1 overflow-y-auto overflow-x-hidden flex flex-col pb-2 min-h-[10rem] w-full">
        {renderTab()}
      </div>
    </div>
  )
}

export default TabStyle
