import { CreatedByOptions, PickTypeClass, SortOptions } from "@/api/sdk"
import ScrollHorizontalContainer from "@/components/Alpha/ScrollHorizontalContainer"
import IconButton from "@/components/IconButton"
import { tabDataRecipesWithIcon } from "@/components/Workspace/Recipes"
import { useClearUrlQuery, useReplaceUrlQuery } from "@/hooks/useQuery"
import { useAuth } from "@/providers/authContext"
import { useExploreRecipesInfiniteQuery } from "@/queries"
import { useGetRecipeCategories } from "@/queries/workspace/recipe"
import classNames from "classnames"
import { motion } from "framer-motion"
import useCustomRouter from "@/hooks/useCustomRouter"
import { Fragment, memo, useEffect, useMemo, useState } from "react"
import InfiniteScroll from "react-infinite-scroller"
import GalleryNotFound from "./GalleryNotFound"
import RecipeItemCard from "./RecipeItemCard"
import LimitUserNotAuth from "@/components/shared/LimitUserNotAuth"
import GridBreakpointColsContainer from "@/components/GridBreakpointColsContainer"
import { useDebounce } from "@/hooks"
import ErrorUI from "@/components/ErrorUI"

interface GalleryRecipesProps {
  containerWidth: number
  sort: {
    sortBy?: string
    sortOrder?: "ASC" | "DESC"
  }
  selectTags: PickTypeClass[]
  category: string[] | null
  createdBy: string | null
  setIsLoadingInside: (value: boolean) => void
}

export const BASICS_RECIPE_ID = 8

const GalleryRecipes = ({ category, selectTags, sort, setIsLoadingInside, createdBy }: GalleryRecipesProps) => {
  const router = useCustomRouter()
  const { search, categoryId: categoryIdQuery } = router.query
  const { user, loading } = useAuth()
  const [categoryId, setCategoryId] = useState<number | undefined>(BASICS_RECIPE_ID)

  const { data: recipeCategories } = useGetRecipeCategories()
  const replaceUrlQuery = useReplaceUrlQuery()
  const clearUrl = useClearUrlQuery()

  const variables = useMemo(() => {
    return {
      creatorUid: createdBy === "Me" ? user?.uid : undefined,
      onlyCurrentWorkspace: createdBy === "Workspace",
      onlyFavorite: createdBy === "My favorites",
      createdBy: category?.includes("By GAIA")
        ? CreatedByOptions.Gaia
        : category?.includes("By Community")
          ? CreatedByOptions.Community
          : undefined,
      sortBy: sort.sortBy as SortOptions,
      tagIds: selectTags.map(i => i.id),
      searchTerm: search as string,
      categoryId: categoryId,
    }
  }, [category, selectTags, sort, search, user, categoryId, createdBy])

  const debounceVariables = useDebounce(variables, 150)

  const {
    flattenData: mappedRecipes,
    fetchNextPage,
    hasNextPage,
    length,
    isFetching,
    isLoading,
    isError,
  } = useExploreRecipesInfiniteQuery({
    variables: debounceVariables,
    enabled: !loading,
  })

  useEffect(() => {
    setIsLoadingInside(isLoading)
  }, [isLoading])

  useEffect(() => {
    if (router.isReady) {
      if (categoryIdQuery) {
        const id =
          typeof parseInt(categoryIdQuery as string) === "number" ? parseInt(categoryIdQuery as string) : undefined
        setCategoryId(id)
      } else {
        setCategoryId(BASICS_RECIPE_ID)
      }
    }
  }, [router.isReady])

  const renderBody = () => {
    if (isLoading) return <div className="flex items-center justify-center flex-1 w-full text-gray-600"></div>

    if (isError) return <ErrorUI />

    if (!mappedRecipes || mappedRecipes.length === 0) return <GalleryNotFound text="No items" />
    return (
      <InfiniteScroll
        loadMore={() => fetchNextPage()}
        hasMore={!!hasNextPage && !isFetching}
        useWindow={true}
        threshold={800}
        style={{
          display: "flex",
          flexDirection: "column",
          maxWidth: "100%",
          position: "relative",
        }}
        loader={
          <div key="loader" className="flex items-center justify-center py-4">
            Loading...
          </div>
        }
      >
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.35, delay: 0.35 }}
          className={"relative w-full pb-16 md:pb-0"}
        >
          <GridBreakpointColsContainer>
            {mappedRecipes?.map(recipe => <RecipeItemCard key={recipe.id} recipe={recipe} />)}
          </GridBreakpointColsContainer>
        </motion.div>
      </InfiniteScroll>
    )
  }

  return (
    <Fragment>
      <div className="w-full relative overflow-hidden flex justify-center py-4">
        <div className="overflow-hidden max-w-full">
          <ScrollHorizontalContainer className="flex w-full h-full pb-4 space-x-2">
            {tabDataRecipesWithIcon
              .filter(i => i.id !== "All" && i.id !== "my-recipes")
              .map((tab, index) => (
                <IconButton
                  key={index}
                  onClick={() => {
                    const id = recipeCategories?.categories?.find(i => i.name === tab.title)?.id

                    if (id === parseInt(categoryIdQuery as string)) {
                      return
                    }

                    if (id) {
                      replaceUrlQuery({
                        categoryId: id.toString(),
                      })
                    } else {
                      clearUrl("categoryId")
                    }

                    setCategoryId(recipeCategories?.categories?.find(i => i.name === tab.title)?.id)
                  }}
                  colorScheme="transparent"
                  className="flex items-center flex-col cursor-pointer shrink-0 group min-w-[7rem] p-0"
                >
                  <div
                    className={classNames(
                      "bg-atherGray-850 group-hover:bg-atherPurple-500  w-16 h-16 flex items-center justify-center rounded-3xl",
                      {
                        "bg-atherPurple-500":
                          categoryId === recipeCategories?.categories?.find(i => i.name === tab.title)?.id,
                      },
                    )}
                  >
                    {tab.icon && <tab.icon width={24} height={24} />}
                  </div>
                  <p className="text-sm font-semibold mt-2">{tab.title}</p>
                </IconButton>
              ))}
          </ScrollHorizontalContainer>
        </div>
      </div>

      {renderBody()}
      {length >= 100 && <LimitUserNotAuth />}
    </Fragment>
  )
}

export default memo(GalleryRecipes)
