import client from "@/api/client"
import {
  CreateFeedbackDto,
  EntityType,
  ImageFullDetail,
  RecipeTaskWithFullImages,
  SDWorkflowDetail,
  WorkflowDetail,
} from "@/api/sdk"
import { createMutation } from "react-query-kit"
import { actionMiddleware } from "../middlewares/actionMiddleware"
import { useWorkspaceDetailImageQuery } from "./useWorkspaceDetailImageQuery"
import { useGetHistoryTaskInfiniteQuery } from "../task/useGetTaskInfiniteQuery"
import { InfiniteData } from "@tanstack/react-query"
import { useWorkspaceWorkflowDetailQuery } from "./workflow"
import { useComfyUiWorkflowDetailQuery } from "../tools/comfyui-recipe/getComfyUiRecipeQueries"

export const useFeedbackMutation = createMutation({
  mutationFn: (data: CreateFeedbackDto & { userUid?: string }) => client.api.feedbackControllerCreateFeedback(data),
  use: [
    actionMiddleware({
      onSuccess: ({ queryClient, variables }) => {
        if (variables.entityType === EntityType.IMAGE) {
          const queryDetailImageKey = useWorkspaceDetailImageQuery.getKey({
            imageId: variables.entityId ?? "",
            userUid: variables?.userUid ?? "",
          })

          const workspaceDetailImageData = queryClient.getQueryData<ImageFullDetail>(queryDetailImageKey)
          if (workspaceDetailImageData) {
            queryClient.setQueryData<ImageFullDetail>(queryDetailImageKey, {
              ...workspaceDetailImageData,
              userFeedback: {
                id: workspaceDetailImageData.userFeedback?.id ?? 0,
                rating: variables.rating,
              },
            })
          }
        }

        if (variables.entityType === EntityType.RECIPE_TASK) {
          const historyTasksKey = useGetHistoryTaskInfiniteQuery.getKey()

          const workspaceDetailImageDataEntries = queryClient.getQueriesData<
            InfiniteData<RecipeTaskWithFullImages[], number>
          >({ queryKey: historyTasksKey })

          if (workspaceDetailImageDataEntries) {
            workspaceDetailImageDataEntries.forEach(([key, entryListData]) => {
              if (entryListData) {
                const updateData = {
                  ...entryListData,
                  pages: entryListData.pages.map(page => {
                    return [
                      ...page.map(task => {
                        if (task.id === variables.entityId) {
                          return {
                            ...task,
                            userFeedback: {
                              id: task.userFeedback?.id ?? 0,
                              rating: variables.rating,
                            },
                          }
                        }
                        return task
                      }),
                    ]
                  }),
                }

                queryClient.setQueryData<InfiniteData<RecipeTaskWithFullImages[], number>>(key, updateData)
              }
            })
          }
        }

        if (variables.entityType === EntityType.WORKFLOW) {
          const queryDetailWorkflowKey = useWorkspaceWorkflowDetailQuery.getKey({
            workflowId: variables.entityId ?? "",
          })

          const workspaceDetailWorkflowData = queryClient.getQueryData<WorkflowDetail>(queryDetailWorkflowKey)
          if (workspaceDetailWorkflowData) {
            queryClient.setQueryData<WorkflowDetail>(queryDetailWorkflowKey, {
              ...workspaceDetailWorkflowData,
              userFeedback: {
                id: workspaceDetailWorkflowData.userFeedback?.id ?? 0,
                rating: variables.rating,
              },
            })
          }
        }

        if (variables.entityType === EntityType.SD_WORKFLOW) {
          const queryDetailComfyUIKey = useComfyUiWorkflowDetailQuery.getKey({
            workflowId: variables.entityId ?? "",
          })

          const workspaceDetailComfyUIData = queryClient.getQueryData<SDWorkflowDetail>(queryDetailComfyUIKey)
          if (workspaceDetailComfyUIData) {
            queryClient.setQueryData<SDWorkflowDetail>(queryDetailComfyUIKey, {
              ...workspaceDetailComfyUIData,
              userFeedback: {
                id: workspaceDetailComfyUIData.userFeedback?.id ?? 0,
                rating: variables.rating,
              },
            })
          }
        }
      },
    }),
  ],
})
