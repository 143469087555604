import { create } from "zustand"

export type RunningRecipesImagesType = {
  id?: string
  url?: string
  recipeTaskId?: string
}

export type RunningRecipesType = {
  runningTasks: RunningRecipesImagesType[]
}

type OnRunningTaskRecipesStore = {
  runningRecipeImages: Record<string, RunningRecipesType>
  setRunningRecipeImages: (
    valueOrCallback:
      | Record<string, RunningRecipesType>
      | ((state: Record<string, RunningRecipesType> | null) => Record<string, RunningRecipesType>)
  ) => void
}

const useOnRunningTaskRecipesStore = create<OnRunningTaskRecipesStore>(set => ({
  runningRecipeImages: {},
  setRunningRecipeImages: valueOrCallback =>
    set(store => {
      if (typeof valueOrCallback === "function") {
        const partialState = valueOrCallback(store.runningRecipeImages)

        const newState = {
          ...store.runningRecipeImages,
          ...partialState,
        }

        return {
          runningRecipeImages: newState,
        }
      }

      const newState = {
        ...store.runningRecipeImages,
        ...valueOrCallback,
      }

      return {
        runningRecipeImages: newState,
      }
    }),
}))

// Custom hook to get state and setState
export const useStateOnRunningTaskRecipesStore = () => {
  const { runningRecipeImages, setRunningRecipeImages } = useOnRunningTaskRecipesStore()

  return [runningRecipeImages, setRunningRecipeImages] as const
}
