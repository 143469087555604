import client from "@/api/client"
import { actionMiddleware } from "@/queries/middlewares/actionMiddleware"
import { createMutation } from "react-query-kit"
import { useWorkspaceRoomsInfiniteQuery } from "./useWorkspaceRoomsInfiniteQuery"
import { GetChatRoomsResult, RecipeItem } from "@/api/sdk"
import { InfiniteData } from "@tanstack/react-query"
import { useGetRoomRecipesInfiniteQuery } from "./useGetRoomRecipesInfiniteQuery"

export const useDeleteRoomMutation = createMutation({
  mutationFn: async ({ roomId, deleteAt }: { roomId: string; deleteAt: string | null }) => {
    if (deleteAt === "leave") {
      return await client.api.chatRoomControllerLeaveChatRoom(roomId).then(res => res.data)
    }

    return await client.api.chatRoomControllerDeleteChatRoom(roomId).then(res => res.data)
  },
  use: [
    actionMiddleware({
      onSuccess: ({ queryClient, variables: { roomId } }) => {
        const workspaceRoomsKey = useWorkspaceRoomsInfiniteQuery.getKey()

        const entities = queryClient.getQueriesData<InfiniteData<GetChatRoomsResult, number>>({
          queryKey: workspaceRoomsKey,
        })

        entities?.forEach(([key, entitiesListData]) => {
          if (entitiesListData) {
            const updatedEntities = {
              ...entitiesListData,
              pages: entitiesListData.pages.map(page => {
                return {
                  ...page,
                  rooms: page.rooms.filter(room => room.id !== roomId),
                }
              }),
            }

            queryClient.setQueryData(key, updatedEntities)
          }
        })
      },
    }),
  ],
})

export const useDeleteRoomRecipesMutation = createMutation({
  mutationFn: async ({ roomId, recipeIds }: { recipeIds: string[]; roomId: string }) => {
    return await client.api.chatRoomControllerRemoveRoomRecipes(recipeIds, roomId).then(res => res.data)
  },
  use: [
    actionMiddleware({
      onSuccess: ({ queryClient, variables: { recipeIds, roomId } }) => {
        const roomRecipesRoomsKey = useGetRoomRecipesInfiniteQuery.getKey()

        const entities = queryClient.getQueriesData<InfiniteData<RecipeItem[], number>>({
          queryKey: roomRecipesRoomsKey,
        })

        entities?.forEach(([key, entitiesListData]) => {
          if (!key.includes(roomId)) return
          console.log(key)

          if (entitiesListData) {
            const updatedEntities = {
              ...entitiesListData,
              pages: entitiesListData.pages.map(item => {
                return item.filter(recipe => !recipeIds.includes(recipe.id))
              }),
            }

            queryClient.setQueryData(key, updatedEntities)
          }
        })
      },
    }),
  ],
})
