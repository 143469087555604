import { ImageSortByColumn } from "@/api/sdk"
import { useOutsideClick } from "@/hooks"
import classNames from "classnames"
import { AnimatePresence, motion } from "framer-motion"
import React, { useEffect, useRef, useState } from "react"
import { FaCheck, FaSlidersH } from "react-icons/fa"
import IconButton from "../../IconButton"
import { useReplaceUrlQuery } from "@/hooks/useQuery"
import { MdCheck, MdSort } from "react-icons/md"
import { options } from "../Recipes/FormAnimeRecipe"
import useCustomRouter from "@/hooks/useCustomRouter"
import { googleAnalytics } from "@/lib/gtag"
import { QueryKey } from "@tanstack/react-query"
import { cn } from "@/utils/cn"

interface GalleryFilterProps {
  sort: {
    sortBy: ImageSortByColumn
    sortOrder: "ASC" | "DESC"
  }
  search: string
  setSort: (value: { sortBy: ImageSortByColumn; sortOrder: "ASC" | "DESC" }) => void
  selectionMode: boolean
  setSelectionMode: React.Dispatch<React.SetStateAction<boolean>>
  setSearch: React.Dispatch<React.SetStateAction<string>>
  recipeIds: string[]
  setRecipeIds: React.Dispatch<React.SetStateAction<string[]>>
  queryListKey: QueryKey
}

const radioOptions = [
  {
    label: "All",
    value: "All",
  },
  {
    label: "Anime",
    value: "anime-style-transformer",
  },
  {
    label: "Art Toy",
    value: "reskin-art-toy",
  },
  {
    value: "qrcode-art",
    label: "QR Code",
  },
  {
    value: "commercial-photoshoot",
    label: "Photoshoot",
  },
  {
    value: "logo-art",
    label: "Logo Art",
  },
  {
    value: "spookifyme",
    label: "SpookifyMe",
  },
  {
    value: "miss-universe",
    label: "Miss Universe",
  },
]

const sortOptions = [
  {
    label: "Name (A-Z)",
    sortby: "name",
    sortOrder: "ASC",
  },
  {
    label: "Name (Z-A)",
    sortby: "name",
    sortOrder: "DESC",
  },
  {
    label: "Oldest",
    sortby: "createdAt",
    sortOrder: "ASC",
  },
  {
    label: "Newest",
    sortby: "createdAt",
    sortOrder: "DESC",
  },
]

export const Radio = ({
  value,
  name,
  checked,
  onChange,
  labelClassName,
  className,
  containerClassName,
  leftIcon,
}: {
  value: string
  name: string
  checked: boolean
  leftIcon?: React.ReactNode
  containerClassName?: string
  onChange: (value: string) => void
  className?: string
  labelClassName?: string
}) => {
  return (
    <div
      className={cn("relative flex items-center cursor-pointer", containerClassName)}
      onClick={() => {
        onChange(value)
      }}
    >
      <div
        className={cn("flex items-center justify-center w-4 h-4 rounded-full border-[2px]", {
          "bg-[#7053FF] border-[#7053FF]": checked,
          "border-[#EAE4D4]": !checked,
          className,
        })}
      >
        <div
          className={classNames("w-1.5 h-1.5 rounded-full", {
            "bg-white": checked,
          })}
        />
      </div>
      {leftIcon}
      <span className={cn("ml-2", labelClassName)}>{name}</span>
    </div>
  )
}

const GalleryFilter = ({
  search,
  sort,
  setSort,
  selectionMode,
  setSelectionMode,
  setSearch,
  recipeIds,
  setRecipeIds,
}: GalleryFilterProps) => {
  const [open, setOpen] = useState<"Filter" | "Sort" | null>(null)
  const popupRef = useRef<HTMLDivElement>(null)
  const replaceUrlQuery = useReplaceUrlQuery()
  useOutsideClick({
    enabled: !!open,
    ref: popupRef,
    handler: () => {
      setOpen(null)
    },
  })
  const router = useCustomRouter()

  useEffect(() => {
    if (router.isReady) {
      const { search, sortBy, sortOrder } = router.query
      if (search) {
        setSearch(search as string)
      }

      if (sortBy && sortOrder) {
        setSort({
          sortBy: sortBy as ImageSortByColumn,
          sortOrder: sortOrder as "ASC" | "DESC",
        })
      }
    }
  }, [router.isReady])

  const renderFilter = () => {
    return (
      <div className="flex-1 px-2 w-[20rem]">
        <p className="mb-2">Recipes</p>
        <div className="grid grid-cols-2 gap-2 text-sm">
          {radioOptions.map(option => (
            <Radio
              key={option.value}
              value={option.value}
              name={option.label}
              checked={recipeIds.includes(option.value)}
              onChange={value => {
                googleAnalytics.event({
                  action: "click",
                  category: "gallery_alpha",
                  label: "filter",
                  params: {
                    filter_by: value,
                  },
                })

                replaceUrlQuery({
                  recipeId: value,
                })

                setRecipeIds([value])
                setSearch("")
              }}
            />
          ))}
        </div>
        {recipeIds.includes("anime") && (
          <div className="mt-4 pt-4 border-t border-[#2D3748] flex flex-wrap select-none">
            {[
              {
                display: "All",
                value: "All",
              },
              ...options,
            ].map(option => (
              <div
                className={classNames("cursor-pointer m-1 flex-shrink-0  border py-1 px-2 rounded-lg", {
                  "border-[#EAE4D4]":
                    (search?.toLowerCase() ?? "").includes(option.display?.toLowerCase()) ||
                    (option.value === "All" && !search),
                  "border-[#464646]": !(search?.toLowerCase() ?? "").includes(option.display?.toLowerCase()),
                })}
                key={option.value}
                onClick={() => {
                  if (option.value === "All") {
                    setSearch("")

                    return
                  }
                  setSearch(option.display)
                }}
              >
                <p className={classNames("text-sm font-[300] text-center")}>{option.display}</p>
              </div>
            ))}
          </div>
        )}
      </div>
    )
  }

  const renderSort = () => {
    return (
      <div className="flex-1 px-2  w-[10rem]">
        <div className="text-sm space-y-2">
          {sortOptions.map(option => (
            <button
              key={option.label}
              className="flex items-center w-full cursor-pointer text-left rounded-md hover:bg-[#313131] p-2"
              onClick={() => {
                setSort({
                  sortBy: option.sortby === "name" ? ImageSortByColumn.Name : ImageSortByColumn.CreatedAt,
                  sortOrder: option.sortOrder as "ASC" | "DESC",
                })

                googleAnalytics.event({
                  action: "click",
                  category: "gallery_alpha",
                  label: "sort",
                  params: {
                    sort_by: option.sortby,
                    sort_order: option.sortOrder,
                  },
                })

                replaceUrlQuery({
                  sortBy: option.sortby,
                  sortOrder: option.sortOrder,
                })
              }}
            >
              <p className="w-full">{option.label}</p>
              {sort.sortBy === option.sortby && sort.sortOrder === option.sortOrder && <MdCheck size="1.2rem" />}
            </button>
          ))}
        </div>
      </div>
    )
  }

  return (
    <div ref={popupRef} className="">
      <div className={classNames("flex space-x-0 lg:space-x-4 -mr-2 md:-mr-0 text-[#EAE4D4]")}>
        <IconButton
          title="Selection Mode"
          onClick={() => {
            googleAnalytics.event({
              action: "click",
              category: "gallery_alpha",
              label: "selection_mode",
              params: {
                selection_mode: selectionMode ? "off" : "on",
              },
            })

            setSelectionMode(prev => !prev)
            setOpen(null)
          }}
          className={classNames(
            "flex items-center bg-transparent hover:bg-transparent active:bg-transparent font-semibold text-sm p-2",
            {
              "text-[#7053FF]": selectionMode,
            },
          )}
        >
          <FaCheck className="mr-0 lg:mr-2" />
          <p className="hidden lg:block">Multi-Select</p>
        </IconButton>
        <IconButton
          title="Filter"
          className={classNames(
            "flex items-center bg-transparent hover:bg-transparent active:bg-transparent font-semibold text-sm p-2",
            {
              "text-[#7053FF]": open === "Filter",
            },
          )}
          onClick={() => {
            googleAnalytics.event({
              action: "click",
              category: "gallery_alpha",
              label: "filter",
            })

            setOpen(prev => (prev === "Filter" ? null : "Filter"))
          }}
        >
          <FaSlidersH className="mr-0 lg:mr-2" />
          <p className="hidden lg:block">Filter</p>
        </IconButton>
        <IconButton
          title="Sort"
          className={classNames(
            "flex items-center bg-transparent hover:bg-transparent active:bg-transparent font-semibold text-sm",
            {
              "text-[#7053FF]": open === "Sort",
            },
          )}
          onClick={() => {
            googleAnalytics.event({
              action: "click",
              category: "gallery_alpha",
              label: "sort",
            })
            setOpen(prev => (prev === "Sort" ? null : "Sort"))
          }}
        >
          <MdSort className="mr-0 lg:mr-2" size="1.2rem" />
          <p className="hidden lg:block">Sort</p>
        </IconButton>
      </div>
      <AnimatePresence>
        {!!open && (
          <motion.div
            key={open}
            initial={{ opacity: 0.5, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0.5, height: 0 }}
            className="absolute top-full mt-1 overflow-hidden right-2 z-[20]"
          >
            <div
              className="py-2 rounded-xl bg-[#171923]  border border-[#2D3748] flex w-full flex-col md:flex-row shadow-md
            space-y-4 md:space-y-0 md:space-x-8
            "
            >
              {open === "Filter" ? renderFilter() : open === "Sort" ? renderSort() : null}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default GalleryFilter
