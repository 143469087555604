import { imageExtensionRegex } from "@/constants"
import React, { useState } from "react"

export function DownloadImage({
  children,
  src,
  typeUrl,
  ...options
}: {
  src: string
  typeUrl?: string
  name?: string
  children: (props: { onClick: () => void; isLoading: boolean; progress: number }) => React.ReactElement
}) {
  const [isLoading, setIsLoading] = useState(false)
  const [progress, setProgress] = useState(0)

  async function onClick() {
    try {
      const type = typeUrl ?? src.match(imageExtensionRegex)?.[0] ?? ".png"

      setIsLoading(true)
      const xhr = new XMLHttpRequest()
      xhr.responseType = "blob"
      const blob = await new Promise<Blob>((resolve, reject) => {
        xhr.addEventListener("progress", ({ loaded, total }) => {
          setProgress((loaded / total) * 100)
        })
        xhr.addEventListener("loadend", () => {
          if (xhr.readyState === 4 && xhr.status === 200) resolve(xhr.response)
        })
        xhr.addEventListener("error", reject)
        xhr.open("GET", src)
        xhr.send()
      })

      const a = document.createElement("a")
      const href = URL.createObjectURL(blob)
      a.href = href
      a.download = `${options.name}${type}` ?? (src.split("/").pop() as string)
      a.target = "_blank"
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
      URL.revokeObjectURL(href)
    } catch {}
    setTimeout(() => {
      setIsLoading(false)
      setProgress(0)
    }, 300)
  }

  return children({ onClick, isLoading, progress })
}
