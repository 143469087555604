import { UserBasicInfo } from "@/api/sdk"
import { create } from "zustand"
import { persist } from "zustand/middleware"

const HISTORY_POOL_SIZE = 10

interface SearchHistoryItem {
  uid: string
  room: {
    id: string
    name: string
    description: string
    isMember: boolean
    isRecent: boolean
    isPrivate: boolean
    thumbnailUrl: string
    creator?: UserBasicInfo
  }
  location: string
}

interface HistorySearchStore {
  history: SearchHistoryItem[]
  addHistory: (item: SearchHistoryItem) => void
  removeHistory: (item: SearchHistoryItem) => void
}

export const useSearchRecentRoomHistory = create(
  persist<HistorySearchStore>(
    set => ({
      history: [],
      addHistory: item => {
        set(state => {
          let history = [...state.history]

          // If different user, remove all old history
          if (history.some(i => i.uid !== item.uid)) {
            history = []
          }

          // remove existing search item if exists
          history = history.filter(
            i => !(i.room.id === item.room.id && i.location === item.location && i.uid === item.uid),
          )

          // add new search item to the top of the list
          history.unshift(item)

          // keep only the latest 10 items for each location
          const locationCount = history.reduce((acc, item) => {
            acc[item.location] = (acc[item.location] || 0) + 1
            return acc
          }, {})

          history = history.filter((item, index) => {
            if (locationCount[item.location] > HISTORY_POOL_SIZE) {
              if (locationCount[item.location] === index + 1) {
                locationCount[item.location]--
                return false
              }
            }
            return true
          })

          return { history }
        })
      },
      removeHistory: item => {
        set(state => {
          const history = state.history.filter(i => i.room.id !== item.room.id || i.location !== item.location)
          return { history }
        })
      },
    }),
    {
      name: "rooms-search-history",
    },
  ),
)
