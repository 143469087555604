import { IconProps } from "."

export const UploadIcon = (props: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M4.9375 8C4.24657 8.00511 3.83081 8.02628 3.49965 8.11397C1.99243 8.51307 0.960532 9.86391 1.00116 11.3847C1.01293 11.8252 1.18062 12.3696 1.516 13.4585C2.32314 16.079 3.67963 18.3539 6.71845 18.8997C7.27704 19 7.90561 19 9.16274 19L10.8373 19C12.0944 19 12.723 19 13.2816 18.8997C16.3204 18.3539 17.6769 16.079 18.484 13.4585C18.8194 12.3696 18.9871 11.8252 18.9988 11.3847C19.0395 9.86391 18.0076 8.51307 16.5004 8.11397C16.1692 8.02628 15.7534 8.00511 15.0625 8"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M10 1L10 12M10 1C10.4684 1 10.8244 1.4381 11.5364 2.3143L12.5 3.5M10 1C9.53163 1 9.1756 1.4381 8.46356 2.3143L7.5 3.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
