import { create } from "zustand"

interface ShortcutKeyBoardProps {
  isOpen: boolean
  setIsOpen: (item: ShortcutKeyBoardProps["isOpen"]) => void
}

export const useShortcutKeyboardStore = create<ShortcutKeyBoardProps>(set => ({
  isOpen: false,
  setIsOpen: isOpen => set({ isOpen }),
}))
