import client from "@/api/client"
import { WorkspaceRole } from "@/api/sdk"
import { MenuSetting } from "@/constants"
import { googleAnalytics } from "@/lib/gtag"
import { useAuth } from "@/providers/authContext"
import { useGetUserPlanQuery } from "@/queries"
import { useQuery } from "@tanstack/react-query"
import useCustomRouter from "@/hooks/useCustomRouter"
import { createContext, ReactNode, useContext, useEffect } from "react"
import { useWalletOwned } from "./Profile/WalletInfo"
import { useWorkspaceContext } from "@/providers/WorkspaceProvider"

const useSettingInside = () => {
  const router = useCustomRouter()
  const currentTab = (router.query?.tab as string) || MenuSetting.about
  const { userInfoQuery, bearerToken } = useAuth()
  const {
    currentWorkspaceQuery: {
      data: currentWorkspaceDetail,
      isLoading: isLoadingCurrentWorkspace,
      refetch: refetchCurrentWorkspace,
    },
  } = useWorkspaceContext()

  const queryKey = ["user-workspaces-profile"]
  const fetchData = () => client.api.workspaceControllerGetWorkspaces().then(res => res.data.workspaces)

  const { data } = useQuery({ queryKey, queryFn: fetchData, enabled: !!bearerToken })

  const findMyWorkspace = data?.find(
    i => i.userWorkspace.role === WorkspaceRole.Owner && i.id === userInfoQuery?.data?.currentWorkspace.id,
  )

  const findMySharedWorkspace = data?.filter(i => i.userWorkspace.role !== WorkspaceRole.Owner)

  const { data: walletOwned } = useWalletOwned()

  const {
    data: userPlan,
    isLoading: isLoadingUserPlan,
    isSuccess: isSuccessUserPlan,
    refetch: refetchUserPlan,
  } = useGetUserPlanQuery({
    variables: { workspaceId: currentWorkspaceDetail?.id ?? "" },
  })

  useEffect(() => {
    if (!walletOwned || !userInfoQuery?.data) return
    googleAnalytics.handleCategoryEvent({
      action: "view",
      params: {
        action: "Account Settings",
        profile_username: userInfoQuery?.data?.username,
        profile_id: userInfoQuery.data?.uid,
        is_private: userInfoQuery.data?.isPrivate ? "true" : "false",
        tab_name: currentTab.toLowerCase(),
        user_name: userInfoQuery.data?.name,
        user_email: userInfoQuery.data?.email,
        user_about: userInfoQuery.data?.description,
        ...walletOwned.wallets.reduce((acc, item, index) => {
          acc[`wallet_address_${index}`] = `E${item.id}`
          acc[`wallet_type_${index}`] = item.provider
          return acc
        }, {}),
      },
    })
  }, [walletOwned, currentTab])

  return {
    currentTab,
    bearerToken,
    userPlan,
    findMyWorkspace,
    isLoadingUserPlan,
    currentWorkspaceDetail,
    isSuccessUserPlan,
    isLoadingCurrentWorkspace,
    findMySharedWorkspace,
    queryKey,
    refetchCurrentWorkspace,
    refetchUserPlan,
  }
}

const SettingContext = createContext<ReturnType<typeof useSettingInside> | null>(null)

export const useSetting = () => {
  const context = useContext(SettingContext)

  if (context === undefined || context === null) {
    throw new Error("useSetting must be used within a SettingProvider")
  }

  return context
}

export const SettingProvider = ({ children }: { children: ReactNode }) => {
  const value = useSettingInside()
  return <SettingContext.Provider value={value}>{children}</SettingContext.Provider>
}
