import { auth } from "@/lib/firebaseClient"
import { AxiosError } from "axios"
import { Api } from "./sdk"

export const BASE_URL = process.env.NEXT_PUBLIC_API_URL ?? "https://artventure-api.sipher.gg"

export const WEBSOCKET_URL = BASE_URL.replace(/^http/, "ws")

const client = new Api({
  format: "json",
  baseURL: BASE_URL,
})

// Set the Firebase ID token in the Authorization header for all requests
client.instance.interceptors.request.use(async config => {
  const user = auth.currentUser

  if (user) {
    const token = await user.getIdToken()
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
})

// Handle token expiration errors by refreshing the token and retrying the request
let isRefreshing = false
let refreshSubscribers: ((token: string) => void)[] = []

const onRefreshed = (token: string) => {
  refreshSubscribers.forEach(callback => callback(token))
  refreshSubscribers = []
  isRefreshing = false
}

const refreshToken = () => {
  const user = auth.currentUser
  if (user) {
    return user.getIdToken(true).then(token => {
      return token
    })
  } else {
    throw new Error("Error token. Please refresh the page.")
  }
}

client.instance.interceptors.response.use(
  response => response,
  (error: AxiosError) => {
    const originalRequest = error.config as any
    const statusCode = error.response?.status

    if (statusCode === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        new Promise(resolve => {
          refreshSubscribers.push(token => {
            originalRequest.headers.Authorization = `Bearer ${token}`
            resolve(client.instance(originalRequest))
          })
        })

        throw (error as any).response?.data || error
      }

      originalRequest._retry = true
      isRefreshing = true

      return refreshToken()
        .then(token => {
          originalRequest.headers.Authorization = `Bearer ${token}`
          return client.instance(originalRequest)
        })
        .finally(() => {
          onRefreshed(localStorage.getItem("token") || "")
        })
    }

    throw (error as any).response?.data || error
  },
)

export default client
