import { IconProps } from "."

export const Info = (props: IconProps) => {
  return (
    <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12 13C9.70833 19 3.29167 13 1 19"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5 13H16.0013C18.3583 13 19.5368 13 20.2691 12.2678C21.0013 11.5355 21.0013 10.357 21.0013 8V6C21.0013 3.64298 21.0013 2.46447 20.2691 1.73223C19.5368 1 18.3583 1 16.0013 1L12.0013 1C9.64428 1 8.46576 1 7.73353 1.73223C7.11312 2.35264 7.01838 3.29344 7.00391 5"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="6.5"
        cy="10.5"
        r="2.5"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M11 5L17 5M17 9L14 9" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
