import client, { WEBSOCKET_URL } from "@/api/client"
import { useAuthenticatedQuery } from "../../../hooks/useAuthenticatedQuery"
import { CreateRecipeTaskDto, RecipeTaskWithFullImages, TurboSession, TurboSessionStatus } from "@/api/sdk"
import React, { FC, ReactNode, createContext, useContext, useEffect, useMemo, useState } from "react"
import { useToast } from "@/hooks"
import { Socket, io } from "socket.io-client"
import { useAuth } from "@/providers/authContext"

export type TurboTaskResult = {
  recipeTask: RecipeTaskWithFullImages
  success: boolean
}

export const TURBO_ENABLED = process.env.NEXT_PUBLIC_TURBO_ENABLED === "true"

const useTurboModeStore = () => {
  const toast = useToast()
  const { bearerToken } = useAuth()

  const [enabled, setEnabled] = useState(false)
  const [session, setSession] = useState<TurboSession>()
  const socketRef = React.useRef<Socket>()
  const [connected, setConnected] = useState(false)
  const pending = useMemo(() => session?.status === TurboSessionStatus.Pending, [session])
  const activated = useMemo(() => session?.status === TurboSessionStatus.Active, [session])

  const isReady = enabled && connected && activated
  const isWaiting = enabled && pending

  const { data: turboSession, refetch } = useAuthenticatedQuery(
    ["get-active-turbo-session"],
    () => client.api.turboModeControllerGetLastActiveSession().then(res => res.data),
    {
      refetchInterval: activated ? 10000 : enabled ? 30000 : false,
      refetchIntervalInBackground: enabled,
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      enabled: TURBO_ENABLED,
    },
  )

  const createSession = async () => {
    const session = await client.api
      .turboModeControllerGetLastActiveSessionOrCreateNew()
      .then(res => res.data)
      .catch((error: any) => {
        toast({
          status: "error",
          title: "Error",
          message: [error?.message || "Something went wrong."],
        })
      })

    if (session) {
      setSession(session)
      socketRef.current?.emit("join", session.id)
    }
  }

  const terminateSession = async () => {
    if (!session) return

    return client.api
      .turboModeControllerTerminateSession(session.id)
      .catch((error: any) => {
        toast({
          status: "error",
          title: "Error",
          message: [error?.message || "Something went wrong."],
        })
      })
      .finally(() => {
        setSession(undefined)
      })
  }

  const queueTask = async (data: CreateRecipeTaskDto & { sdWorkflowId?: string }) => {
    if (!session) return

    return client.api
      .turboModeControllerAddTask({ ...data, sessionId: session.id, saveTask: true })
      .then(res => res.data)
  }

  useEffect(() => {
    if (!TURBO_ENABLED) return
    if (!bearerToken) return

    socketRef.current = io(`${WEBSOCKET_URL}/api/turbo`, {
      reconnectionDelayMax: 10000,
      extraHeaders: {
        Authorization: `Bearer ${bearerToken}`,
      },
      withCredentials: true,
      forceNew: true,
    })

    socketRef.current.on("connect", () => {
      console.log("turbo socket connected")
      setConnected(true)
      refetch()
    })

    socketRef.current.on("disconnect", () => {
      console.log("turbo socket disconnect")
      setConnected(false)
      refetch()
    })

    socketRef.current.on("activated", async () => {
      refetch()
    })

    socketRef.current.on("terminated", async () => {
      refetch()
    })

    return () => {
      socketRef.current?.off("connect")
      socketRef.current?.off("disconnect")
      socketRef.current?.off("activated")
      socketRef.current?.off("terminated")
      socketRef.current?.disconnect()
      setConnected(false)
    }
  }, [bearerToken])

  useEffect(() => {
    setSession(turboSession)
    if (turboSession) {
      socketRef.current?.emit("join", turboSession.id)
      setEnabled(true)
    }
  }, [turboSession])

  return {
    enabled,
    setEnabled,
    session,
    isWaiting,
    isReady,
    createSession,
    terminateSession,
    queueTask,
    socketRef,
    connected,
  }
}

export type TurboModeContext = ReturnType<typeof useTurboModeStore>

const context = createContext<TurboModeContext | null>(null)

const Provider = context.Provider

export const TurboModeProvder: FC<{ children: ReactNode }> = ({ children }) => {
  const value = useTurboModeStore()

  return <Provider value={value}>{children}</Provider>
}

export const useTurboMode = () => {
  const value = useContext(context)
  if (!value) {
    throw new Error("useTurboMode must be used inside TurboModeProvder")
  }

  return value
}
