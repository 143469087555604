import classNames from "classnames"
import React from "react"
import { twMerge } from "tailwind-merge"

interface ToggleProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string
  boxClassName?: string
  sizeToggle?: "sm" | "md"
  labelClassName?: string
}

const Toggle = ({ label, boxClassName, labelClassName, sizeToggle, ...rest }: ToggleProps) => {
  const size =
    sizeToggle === "sm"
      ? "h-4 w-7 after:top-1/2 after:-translate-y-1/2 after:left-[0.125rem] after:h-3 after:w-3 peer-checked:after:translate-x-[0.75rem]"
      : "h-6 w-12 after:top-1/2 after:-translate-y-1/2 after:left-[0.125rem] after:h-5 after:w-5 peer-checked:after:translate-x-[1.5rem]"

  return (
    <label
      className={classNames("relative inline-flex items-center cursor-pointer", {
        "pointer-events-none": rest.disabled,
      })}
    >
      <input type="checkbox" className="sr-only peer" {...rest} />
      <div
        className={twMerge(
          classNames(
            "bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-atherGray-800 peer-checked:after:border-atherGray-100 after:content-[''] after:absolute  after:bg-atherGray-100 after:border-gray-300 after:border after:rounded-full after:transition-all dark:border-gray-600 peer-checked:bg-atherPurple-500",
            size,
          ),
          boxClassName,
        )}
      />
      {label && <span className={twMerge("ml-2 text-sm font-medium", labelClassName)}>{label}</span>}
    </label>
  )
}

export default Toggle
