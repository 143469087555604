import { ResourceRole } from "@/api/sdk"
import { cn } from "@/utils/cn"
import classNames from "classnames"
import { twMerge } from "tailwind-merge"
import { UserSquareIcon } from "./shared/icons"
import UserCreated from "./Explore/Gallery/UserCreated"

interface UserBadgeProps extends React.HTMLAttributes<HTMLDivElement> {
  picture?: string | null
  name?: string | null
  email?: string | null
  className?: string
  size?: "sm" | "md" | "xs" | "xss"
  isDisabledInfo?: boolean
  role?: ResourceRole
  isOwner?: boolean
  isGuest?: boolean
  username?: string
  lightText?: boolean
}

const UserBadge = ({
  picture,
  name,
  email,
  className,
  username,
  isGuest,
  size = "md",
  isDisabledInfo = false,
  role,
  isOwner,
  lightText = false,
  ...rest
}: UserBadgeProps) => {
  const textSize = size === "sm" ? "text-xs" : "text-sm"

  return (
    <div
      className={twMerge(classNames("flex min-w-[2rem] overflow-hidden items-center", textSize, className))}
      {...rest}
    >
      <UserCreated size={size} hiddenName picture={picture ?? ""} name={name || username || "U"} />
      {!isDisabledInfo ? (
        <div className="flex-1 overflow-hidden ml-2">
          <div className="overflow-hidden flex items-center">
            <p
              className={cn("font-semibold truncate", {
                "font-normal": lightText,
              })}
            >
              {isOwner ? name + " (me)" : name || "Unknown"}
            </p>
            {isGuest && (
              <span className="border border-atherGray-800 py-0.5 px-1 ml-2 rounded-[4px] font-semibold text-[0.65rem]">
                Guest
              </span>
            )}
          </div>
          <p
            className={cn("text-xs truncate text-whiteAlpha-700", {
              "font-normal": lightText,
            })}
          >
            {email}
          </p>
        </div>
      ) : null}
      {role && <p className="ml-1 text-sm capitalize">{role}</p>}
    </div>
  )
}

export default UserBadge
