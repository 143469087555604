import React, { useCallback, useEffect, useRef, useState } from "react"
import { DragHandlerData, DragHandlerProps } from "./types"

interface ResizeHandlerProps extends DragHandlerProps {}

const ResizeHandler = ({ children, dir, className, onEnd, onStart, onUpdate, style }: ResizeHandlerProps) => {
  const [isDragging, setIsDragging] = useState(false)
  const listenersRef = useRef<DragHandlerData["listenersRef"]>(null)

  const handleMouseMove = useCallback(
    e => {
      onUpdate?.(e)
    },
    [onUpdate],
  )

  const cleanMouseListeners = useCallback(() => {
    const oldRef = listenersRef.current
    if (oldRef) {
      window.removeEventListener("mousemove", oldRef.handleMouseMove)
      window.removeEventListener("touchmove", oldRef.handleMouseMove)
      window.removeEventListener("mouseup", oldRef.handleMouseUp)
      window.removeEventListener("touchend", oldRef.handleMouseUp)
    }
  }, [])

  const handleMouseUp = useCallback(
    e => {
      setIsDragging(false)
      cleanMouseListeners()
      onEnd?.(e)
    },
    [cleanMouseListeners, onEnd],
  )

  const handleMouseDown = useCallback(
    e => {
      setIsDragging(true)
      cleanMouseListeners()

      listenersRef.current = {
        handleMouseMove,
        handleMouseUp,
      }

      window.addEventListener("mousemove", handleMouseMove)
      window.addEventListener("touchmove", handleMouseMove)
      window.addEventListener("mouseup", handleMouseUp)
      window.addEventListener("touchend", handleMouseUp)

      onStart?.(e)
    },
    [cleanMouseListeners, handleMouseMove, handleMouseUp, onStart],
  )

  useEffect(() => {
    return () => {
      cleanMouseListeners()
    }
  }, [])

  return (
    <div
      onMouseDown={handleMouseDown}
      onTouchStart={handleMouseDown}
      className={className}
      style={{
        cursor: `${dir}-resize`,
        ...style,
      }}
    >
      {isDragging && (
        <style>{`
        * {
          cursor: ${dir}-resize !important;
          -webkit-user-select: none !important;
        }
        `}</style>
      )}
      {children}
    </div>
  )
}

export default ResizeHandler
