import client from "@/api/client"
import { CreatedByOptions, ExploreRecipeItem, GetExploreRecipesResult, SortOptions } from "@/api/sdk"
import { createAuthenticatedInfiniteQuery } from "../createAuthenticatedQuery"

export interface UseExploreRecipesInfiniteQueryVariables {
  sortBy?: SortOptions
  searchTerm?: string
  creatorUid?: string
  onlyFavorite?: boolean
  tagIds?: number[]
  userUid?: string
  onlyCurrentWorkspace?: boolean
  createdBy?: CreatedByOptions
  categoryId?: number
}

export const useExploreRecipesInfiniteQuery = createAuthenticatedInfiniteQuery<
  GetExploreRecipesResult,
  UseExploreRecipesInfiniteQueryVariables,
  Error,
  ExploreRecipeItem
>({
  auth: false,
  primaryKey: "infinite-explore-recipes",
  queryFn: ({ queryKey: [_primaryKey, variables], pageParam = 0 }) =>
    client.api
      .exploreRecipeControllerGetExploreRecipes({
        skip: pageParam * 50,
        take: 50,
        sortBy: variables.sortBy,
        searchTerm: variables.searchTerm,
        categoryId: variables.categoryId,
        onlyCurrentWorkspace: variables.onlyCurrentWorkspace,
        creatorUid: variables.creatorUid,
        onlyFavorite: variables.onlyFavorite,
        createdBy: variables.createdBy,
        tagIds: variables.tagIds,
      })
      .then(res => res.data),
  getNextPageParam: (lastPage, pages) => (lastPage && lastPage.recipes.length < 50 ? undefined : pages.length),
  initialPageParam: 0,
  flattenFn: data => data.recipes,
})
