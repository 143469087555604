import client from "@/api/client"
import { SDModel } from "@/api/sdk"
import { ComfyUINodeInfos } from "@/components/ComfyUI/types/node"
import { createAuthenticatedQuery } from "@/queries/createAuthenticatedQuery"

const cacheKey = "gaia:comfyui:node_infos"
const lastUpdateCacheKey = "gaia:comfyui:node_infos_update"

export const useComfyUiNodeInfosQuery = createAuthenticatedQuery<ComfyUINodeInfos, {}, Error>({
  primaryKey: "comfyui-node-infos",
  queryFn: async () => {
    const lastUpdate = await client.api.comfyUiControllerGetNodeInfosLastUpdate().then(res => res.data)

    const lastUpdateCache = Number(localStorage.getItem(lastUpdateCacheKey))
    if (isNaN(lastUpdateCache) || lastUpdateCache < lastUpdate || !localStorage.getItem(cacheKey)) {
      const data = await client.api.comfyUiControllerGetNodeInfos().then(res => res.data)
      localStorage.setItem(cacheKey, JSON.stringify(data))
      localStorage.setItem(lastUpdateCacheKey, String(lastUpdate))
    }

    return JSON.parse(localStorage.getItem(cacheKey)!)
  },
  refetchOnWindowFocus: false,
})

export const useComfyUiModelsQuery = createAuthenticatedQuery<
  Pick<SDModel, "id" | "fileName" | "variant">[],
  {},
  Error
>({
  primaryKey: "comfyui-models",
  queryFn: async () => {
    const modelNodeInfos = await client.api.comfyUiControllerGetModels().then(res => res.data)
    return modelNodeInfos
  },
  refetchOnWindowFocus: false,
})
