import client from "@/api/client"
import {
  GetWorkflowsResult,
  PinnedItemType,
  PinnedItems,
  RecentStyleItem,
  RecentWorkflowItem,
  SearchSDStyleResponse,
  SharingRole,
} from "@/api/sdk"
import { InfiniteData, useQueryClient } from "@tanstack/react-query"
import { createMutation } from "react-query-kit"
import { actionMiddleware } from "../../middlewares/actionMiddleware"
import { useWorkspaceWorkflowInfiniteQuery } from "../../workspace/useWorkspaceWorkflowInfiniteQuery"
import { useRecentWorkflowInfiniteQuery } from "@/components/Workspace/RecentV2/RecentWorkflow"
import { useWorkspaceStyleInfiniteQuery } from "./useGetStyleInfiniteQuery"
import { useRecentStylesInfiniteQuery } from "@/components/Workspace/RecentV2/RecentStyles"
import { useWorkspacePinInfiniteQuery } from "@/queries/workspace"

export interface PublicStyleMutationVariables {
  ids: string[]
  isDiscoverable: boolean
}

export const usePublicStyleMutation = createMutation({
  mutationFn: ({ ids, isDiscoverable }: PublicStyleMutationVariables) =>
    client.api.sdStyleControllerUpdateDiscoveryStyle({
      isDiscoverable,
      styleIds: ids,
    }),
  use: [
    actionMiddleware({
      onSuccess: ({ queryClient, variables }) => {
        // Update style list
        const styleListKey = useWorkspaceStyleInfiniteQuery.getKey()

        const styleListQueriesDataEntries = queryClient.getQueriesData<InfiniteData<SearchSDStyleResponse, number>>({
          queryKey: styleListKey,
        })

        if (styleListQueriesDataEntries) {
          styleListQueriesDataEntries.forEach(([key, styleListData]) => {
            if (styleListData) {
              const updatedData = {
                ...styleListData,
                pages: styleListData.pages.map(page => {
                  return {
                    ...page,
                    styles: page.data.map(style => {
                      if (variables.ids.includes(style.id)) {
                        return {
                          ...style,
                          discoverableAt: variables.isDiscoverable ? new Date() : null,
                        }
                      }

                      return style
                    }),
                  }
                }),
              }

              queryClient.setQueryData(key, updatedData)
            }
          })
        }

        // Update style recent list
        const styleRecentListKey = useRecentStylesInfiniteQuery.getKey()

        const styleRecentListQueriesDataEntries = queryClient.getQueriesData<InfiniteData<RecentStyleItem[], number>>({
          queryKey: styleRecentListKey,
        })

        if (styleRecentListQueriesDataEntries) {
          styleRecentListQueriesDataEntries.forEach(([key, styleRecentListData]) => {
            if (styleRecentListData) {
              const updatedData = {
                ...styleRecentListData,
                pages: styleRecentListData.pages.map(page => {
                  return [
                    ...page.map(item => {
                      if (variables.ids.includes(item.style.id)) {
                        return {
                          ...item,
                          style: {
                            ...item.style,
                            discoverableAt: variables.isDiscoverable ? new Date() : null,
                          },
                        }
                      }

                      return item
                    }),
                  ]
                }),
              }

              queryClient.setQueryData(key, updatedData)
            }
          })
        }

        // Update style pinned list
        const stylePinnedListKey = useWorkspacePinInfiniteQuery.getKey({
          type: PinnedItemType.STYLE,
        })

        const stylePinnedListQueriesDataEntries = queryClient.getQueriesData<InfiniteData<PinnedItems[], number>>({
          queryKey: stylePinnedListKey,
        })

        if (stylePinnedListQueriesDataEntries) {
          stylePinnedListQueriesDataEntries.forEach(([key, stylePinnedListData]) => {
            if (stylePinnedListData) {
              const updatedData = {
                ...stylePinnedListData,
                pages: stylePinnedListData.pages.map(page => {
                  return [
                    ...page.map(item => {
                      if (variables.ids.includes(item.style?.id || "")) {
                        return {
                          ...item,
                          style: {
                            ...item.style,
                            discoverableAt: variables.isDiscoverable ? new Date() : null,
                          },
                        }
                      }

                      return item
                    }),
                  ]
                }),
              }

              queryClient.setQueryData(key, updatedData)
            }
          })
        }
      },
    }),
    useMutationNext => {
      return (options, queryClient) => {
        const qc = useQueryClient(queryClient)

        return useMutationNext({
          ...options,
          onSettled: (_, __, {}) => {},
        })
      }
    },
  ],
})
