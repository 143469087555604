import client from "@/api/client"
import { SearchWildcardResponse } from "@/api/sdk"
import { InfiniteData } from "@tanstack/react-query"
import { createMutation } from "react-query-kit"
import { actionMiddleware } from "@/queries/middlewares/actionMiddleware"
import { useWorkspaceWildcardsInfiniteQuery } from "./useWorkspaceWildcardsInfiniteQuery"

interface RemoveStylesMutationVariables {
  ids: string[]
}

export const useAddWildcardsMutation = createMutation({
  mutationFn: async ({ ids }: RemoveStylesMutationVariables) => {
    return client.api
      .wildcardControllerAddWildcardsToLibrary({
        wildcardIds: ids,
      })
      .then(res => res.data)
  },
  use: [
    actionMiddleware({
      onSuccess: ({ queryClient, variables: { ids } }) => {
        // Update wildcard list
        const wildcardListKey = useWorkspaceWildcardsInfiniteQuery.getKey()
        const wildcardListQueriesDataEntries = queryClient.getQueriesData<InfiniteData<SearchWildcardResponse, number>>(
          {
            queryKey: wildcardListKey,
          },
        )

        wildcardListQueriesDataEntries?.forEach(([key, data]) => {
          if (!data) return

          const updatedData = {
            ...data,
            pages: data.pages.map(page => {
              const updatedPage = {
                ...page,
                wildcards: page.wildcards.map(style => {
                  if (ids.includes(style.id)) {
                    return {
                      ...style,
                      capabilities: {
                        ...style.capabilities,
                        canAddToLibrary: false,
                        canRemoveFromLibrary: true,
                      },
                    }
                  }
                  return style
                }),
              }
              updatedPage.total = updatedPage.wildcards.length
              return updatedPage
            }),
          }

          queryClient.setQueryData(key, updatedData)
        })
      },
    }),
  ],
})
