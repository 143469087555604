import { create } from "zustand"

interface GuestStore {
  shareProfileModal: boolean
  setShareProfileModal: (isOpen: boolean) => void
}

export const useShareProfileStore = create<GuestStore>(set => ({
  shareProfileModal: false,
  setShareProfileModal: shareProfileModal => set({ shareProfileModal }),
}))
