import { useAuth } from "@/providers/authContext"
import { css } from "@emotion/css"
import React, { useMemo, useState } from "react"
import AddComment from "../../ImageDetail/TabImage/CommenstImage/AddComment"
import { ExploreSDStyleDetail, GetMentionableUsersResult, EntityType, SDStyleDetail } from "@/api/sdk"
import client from "@/api/client"
import { enableQueryMiddleware } from "@/queries/middlewares/enableQueryMiddleware"
import useComment from "../../ImageDetail/TabImage/CommenstImage/useComment"
import SignInRequiredButton from "../../SignInRequiredButton"
import ViewComment from "../../ImageDetail/TabImage/CommenstImage/ViewComment"
import { createAuthenticatedQuery } from "@/queries/createAuthenticatedQuery"
import { motion } from "framer-motion"
import { SpinnerIcon } from "@/components/shared/icons"

interface CommentsStyleProps {
  style: ExploreSDStyleDetail | SDStyleDetail
  typeComment: EntityType
}

const useExploreStyleMentions = createAuthenticatedQuery<
  GetMentionableUsersResult,
  { styleId: string; searchQuery: string },
  Error
>({
  primaryKey: "users-mentions-style-explore",
  queryFn: ({ queryKey: [_primaryKey, variables] }) =>
    client.api
      .exploreSdStyleControllerGetExploreMentionableUsers(variables.styleId, {
        search: variables.searchQuery,
        skip: 0,
        take: 12,
      })
      .then(res => res.data),
  refetchOnWindowFocus: false,
  use: [enableQueryMiddleware(v => !!v.styleId)],
})

export const useWorkspaceStyleMentions = createAuthenticatedQuery<
  GetMentionableUsersResult,
  { styleId: string; searchQuery: string },
  Error
>({
  primaryKey: "users-mentions-style-workspace",
  queryFn: ({ queryKey: [_primaryKey, variables] }) =>
    client.api
      .sdStyleControllerGetSdStyleMentionableUsers(variables.styleId, {
        search: variables.searchQuery,
        skip: 0,
        take: 12,
      })
      .then(res => res.data),
  refetchOnWindowFocus: false,
  use: [enableQueryMiddleware(v => !!v.styleId)],
})

const CommentsStyle = ({ style, typeComment }: CommentsStyleProps) => {
  const { user } = useAuth()
  const [searchMention, setSearchMention] = useState("")

  const { data: exploreStyleMentions, isFetching: isExploreMentionsLoading } = useExploreStyleMentions({
    variables: {
      styleId: style.id,
      searchQuery: searchMention,
    },
    enabled: typeComment === EntityType.EXPLORE_STYLE,
  })

  const { data: workspaceStyleMentions, isFetching: isMentionsLoading } = useWorkspaceStyleMentions({
    variables: {
      styleId: style.id,
      searchQuery: searchMention,
    },
    enabled: typeComment === EntityType.STYLE,
  })

  const styleMentions = typeComment === EntityType.EXPLORE_STYLE ? exploreStyleMentions : workspaceStyleMentions

  const mentions = useMemo(() => {
    return (
      styleMentions?.users
        ?.filter(u => user?.uid !== u.uid)
        .map(us => ({
          ...us,
          picture: us.picture ?? "",
          name: us.name ?? "",
          uid: us.uid,
          label: `${us.name}`,
          value: `${us.name}`,
          itemValue: `${us.name}`,
          email: us.email ?? "",
        })) ?? []
    )
  }, [styleMentions, user?.uid])

  const {
    states: {
      hasNextPage,
      hasPreviousPage,
      isLoading,
      isFetchingNextPage,
      isFetchingPreviousPage,
      isCommentsSuccess,
      isCommentFromNoti,
    },
    functions: { fetchNextPage, fetchPreviousPage, refetchComments, resetParams },
    contants: { childTakePage, baseChildParam, queryCommentsImageKey, childTotalPage },
    data: { mappedComments },
    paginations: { nextBaseChildParam, prevBaseChildParam, nextBaseParentParam, prevBaseParentParam },
  } = useComment(style.id, typeComment)

  if (isLoading)
    return (
      <div className="flex items-center justify-center flex-1 w-full text-gray-600">
        <motion.div animate={{ rotate: 360 }} transition={{ duration: 1, repeat: Infinity, ease: "linear" }}>
          <SpinnerIcon width={16} height={16} />
        </motion.div>
        <p className="ml-2 text-lg">Loading ...</p>
      </div>
    )

  if (style.deletedAt)
    return (
      <div className="flex items-center justify-center flex-1 w-full text-gray-600">
        <p className="text-lg text-center">Comment is disabled</p>
      </div>
    )

  return (
    <div
      className={css({
        flex: 1,
        display: "flex",
        flexDirection: "column",
        position: "relative",
        padding: "0 0.25rem",
      })}
    >
      {user ? (
        <AddComment
          isMentionsLoading={isMentionsLoading || isExploreMentionsLoading}
          itemOwnerUid={style.creator.uid}
          setIsFocus={() => {}}
          queryCommentsImageKey={queryCommentsImageKey}
          mentions={mentions}
          onSearchMention={setSearchMention}
          itemId={style.id}
          resetParams={resetParams}
          typeComment={typeComment}
          isCommentFromNoti={isCommentFromNoti}
        />
      ) : (
        <div className="w-full mb-2">
          <SignInRequiredButton
            earlyAccessModal
            className="bg-atherGray-800"
            text={
              <p className="text-xs font-normal text-atherGray-300">
                You must <span className="font-semibold text-atherGray-0">Sign In</span> to add a comment
              </p>
            }
          />
        </div>
      )}
      <ViewComment
        isMentionsLoading={isMentionsLoading || isExploreMentionsLoading}
        setSearchMention={setSearchMention}
        setIsFocus={() => {}}
        queryCommentsImageKey={queryCommentsImageKey}
        isCommentsSuccess={isCommentsSuccess}
        refetchComments={refetchComments}
        mappedComments={mappedComments}
        hasNextPage={hasNextPage}
        hasPreviousPage={hasPreviousPage}
        fetchNextPage={fetchNextPage}
        fetchPreviousPage={fetchPreviousPage}
        isFetchingNextPage={isFetchingNextPage}
        isFetchingPreviousPage={isFetchingPreviousPage}
        typeComment={typeComment}
        mentions={mentions}
        image={{
          id: style.id,
          canComment: !!user,
        }}
        prevBaseParentParam={prevBaseParentParam}
        nextBaseParentParam={nextBaseParentParam}
        baseChildParam={baseChildParam}
        nextBaseChildParam={nextBaseChildParam}
        prevBaseChildParam={prevBaseChildParam}
        childTakePage={childTakePage}
        childTotalPage={childTotalPage}
      />
    </div>
  )
}

export default CommentsStyle
