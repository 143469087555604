import { imageExtensionRegex } from "@/constants"
import { watermark } from "@/utils/watermark"

export const useDownloadImage = (
  inputUrl: string,
  name: string,
  onSuccess?: () => void,
  mark = false,
  typeUrl?: string,
) => {
  const downloadImage = async () => {
    try {
      const type = typeUrl ?? inputUrl.match(imageExtensionRegex)?.[0] ?? ".png"

      const response = await fetch(inputUrl)

      if (!response.ok) {
        throw new Error("Failed to download")
      }
      const blob = await response.blob()
      const url = URL.createObjectURL(blob)

      const link = document.createElement("a")
      link.href = mark ? await watermark(url) : url
      link.download = `${name}${type}` // Replace with the desired file name and extension
      link.style.display = "none"
      document.body.appendChild(link)
      link.click()
      onSuccess && onSuccess()
      document.body.removeChild(link)
      URL.revokeObjectURL(url)
    } catch (error) {
      console.error("Error downloading:", error)
    }
  }

  return downloadImage
}
