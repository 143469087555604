import React from "react"
import * as Checkbox from "@radix-ui/react-checkbox"
import { TickIcon } from "./shared/icons"
import { cn } from "@/utils/cn"

interface CheckboxProps {
  checked: boolean
  onChange: (checked: boolean) => void
  label: string
  className?: string
  labelClassName?: string
  showTick?: boolean
  tickClassName?: string
}

const CheckboxComponent = ({
  checked,
  label,
  onChange,
  className,
  labelClassName,
  showTick,
  tickClassName,
}: CheckboxProps) => {
  return (
    <div className="flex">
      <Checkbox.Root
        className={cn(
          "CheckboxRoot w-5 h-5 rounded-[4px] flex items-center justify-center",
          {
            "bg-atherPurple-500": checked,
            "bg-atherGray-700": !checked,
          },
          className,
        )}
        checked={checked}
        onCheckedChange={onChange}
        id={label}
      >
        <Checkbox.Indicator>
          <TickIcon className="text-white" />
        </Checkbox.Indicator>
        {showTick && !checked && <TickIcon className={tickClassName} />}
      </Checkbox.Root>
      {label && (
        <label htmlFor={label} className={cn("Label ml-2", labelClassName)}>
          {label}
        </label>
      )}
    </div>
  )
}

export default CheckboxComponent
