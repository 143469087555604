import { IconProps } from "."

export const FolderSelectIcon = (props: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 16 14" fill="none" {...props}>
      <path
        d="M4.66683 3.66667H11.1668C12.5713 3.66667 13.2735 3.66667 13.778 4.00373C13.9964 4.14964 14.1839 4.33715 14.3298 4.55553C14.6668 5.05997 14.6668 5.7622 14.6668 7.16667C14.6668 9.50744 14.6668 10.6778 14.1051 11.5186C13.8619 11.8825 13.5494 12.195 13.1854 12.4382C12.3447 13 11.1743 13 8.8335 13H8.00016C4.85747 13 3.28612 13 2.30981 12.0237C1.3335 11.0474 1.3335 9.47603 1.3335 6.33333V4.29618C1.3335 3.0852 1.3335 2.47971 1.58704 2.02538C1.76778 1.70151 2.03501 1.43428 2.35887 1.25354C2.8132 1 3.4187 1 4.62968 1C5.40551 1 5.79343 1 6.133 1.12734C6.90831 1.41808 7.22802 2.12238 7.57787 2.82208L8.00016 3.66667"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  )
}
