import client from "@/api/client"
import { EntityType } from "@/api/sdk"
import IconButton from "@/components/IconButton"
import Modal from "@/components/Modal"
import { useToast } from "@/hooks"
import { googleAnalytics } from "@/lib/gtag"
import { useAuth } from "@/providers/authContext"
import { useSwitchWorkspaceMutation, useUserWorkspacesInfiniteQuery } from "@/queries"
import { useMutation } from "@tanstack/react-query"
import { Fragment } from "react"

interface RequestModalProps {
  isOpenRequest: boolean
  isModal?: boolean
  onCloseRequestModal: () => void
  onRefetch?: () => void
  dataRequest: {
    entityId: string
    entityType: EntityType
    discoverableAt?: string | null
    workspace?: {
      id: string
      name: string
      picture?: string
    }
  }
}

const isWorkspaceSwitchable = ({
  currentWorkspaceId,
  userWorkspaceIds,
  itemWorkspaceId,
  entityType,
  isDiscoverable,
}: {
  currentWorkspaceId?: string
  userWorkspaceIds?: string[]
  itemWorkspaceId?: string
  entityType: EntityType
  isDiscoverable: boolean
}) => {
  if (!(userWorkspaceIds && itemWorkspaceId)) {
    return
  }
  if (entityType !== EntityType.STYLE) {
    return currentWorkspaceId !== itemWorkspaceId && userWorkspaceIds.includes(itemWorkspaceId)
  }
  return currentWorkspaceId !== itemWorkspaceId && userWorkspaceIds.includes(itemWorkspaceId) && !isDiscoverable
}

const RequestModal = ({ isOpenRequest, onCloseRequestModal, dataRequest, isModal, onRefetch }: RequestModalProps) => {
  const toast = useToast()
  const { user, userInfoQuery } = useAuth()
  const { flattenData } = useUserWorkspacesInfiniteQuery()

  const { mutate: mutateRequest, isPending: isLoadingRequest } = useMutation({
    mutationFn: () =>
      client.api.accessRequestControllerRequestAccess({
        entityId: dataRequest.entityId,
        entityType: dataRequest.entityType,
      }),
    onSuccess: () => {
      toast({
        status: "success",
        title: "Request access",
        message: ["Your request has been sent."],
      })
      onCloseRequestModal()
    },
    onError: (err: any) => {
      if (err?.message === "Access request has already been sent") {
        onCloseRequestModal()
      }

      toast({
        status: "error",
        title: "Request access",
        message: [`${err?.message}`],
      })
    },
  })

  const canSwitchWorkspace = isWorkspaceSwitchable({
    entityType: dataRequest.entityType,
    currentWorkspaceId: userInfoQuery?.data?.currentWorkspace.id,
    itemWorkspaceId: dataRequest.workspace?.id,
    userWorkspaceIds: flattenData?.map(i => i.id),
    isDiscoverable: !!dataRequest.discoverableAt,
  })

  const { mutate: mutateSwitchWorkspace, isPending } = useSwitchWorkspaceMutation({
    onSuccess: () => {
      window.location.reload()
    },
  })

  const renderBody = () => {
    if (canSwitchWorkspace) {
      return (
        <Fragment>
          <p className="text-atherGray-300 text-center">You need to switch workspace to open the image</p>
          <div className="h-[4rem] bg-atherGray-950 rounded-2xl flex items-center justify-center mt-6">
            <img className="h-10 w-10 rounded-full mr-2" src={dataRequest.workspace?.picture} alt="" />
            <p className="font-semibold">{dataRequest.workspace?.name}</p>
          </div>
          <div className="flex items-center justify-center mt-6 space-x-2 text-sm">
            <IconButton colorScheme="secondary" onClick={onCloseRequestModal} className="px-4 font-semibold">
              Cancel
            </IconButton>
            <IconButton
              isLoading={isPending}
              onClick={() => {
                if (!flattenData?.find(workspace => workspace.id === dataRequest.workspace?.id)) return

                googleAnalytics.event({
                  action: "click",
                  category: `${dataRequest.entityType}_detail`,
                  label: "switch_workspace",
                  params: {
                    [`${dataRequest.entityType}_id`]: dataRequest.entityId,
                    type: dataRequest.entityType,
                    workspace_id: dataRequest.workspace?.id ?? "",
                    pathname: window.location.pathname,
                  },
                })

                mutateSwitchWorkspace(flattenData?.find(workspace => workspace.id === dataRequest.workspace?.id)!)
              }}
              className="px-4 font-semibold "
            >
              Switch workspace
            </IconButton>
          </div>
        </Fragment>
      )
    }
    return (
      <Fragment>
        <p className="text-atherGray-400 text-center">
          Want in? Ask for access or switch to an account with permission.
        </p>
        <div className="flex items-center justify-center mt-6 space-x-2 text-sm">
          <IconButton colorScheme="secondary" onClick={onCloseRequestModal} className="px-4 font-semibold">
            Cancel
          </IconButton>
          <IconButton
            isLoading={isLoadingRequest}
            onClick={() => {
              googleAnalytics.event({
                action: "click",
                category: `${dataRequest.entityType}_detail`,
                label: "request_access",
                params: {
                  [`${dataRequest.entityType}_id`]: dataRequest.entityId,
                  type: dataRequest.entityType,
                  workspace_id: dataRequest.workspace?.id ?? "",
                  pathname: window.location.pathname,
                },
              })
              mutateRequest()
            }}
            className="px-4 font-semibold "
          >
            Request access
          </IconButton>
        </div>
      </Fragment>
    )
  }

  if ((!isOpenRequest && !canSwitchWorkspace) || !dataRequest || !user) return null

  return (
    <Modal
      closeOnEscape={!isModal}
      containerClassName="md:backdrop-blur-xl bg-blackAlpha-800"
      isOpen={true}
      className="max-w-lg"
      onClose={onCloseRequestModal}
    >
      <div>
        <p className="text-base mb-2 text-center font-semibold">You need access</p>

        {renderBody()}
      </div>
    </Modal>
  )
}

export default RequestModal
