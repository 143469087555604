import { cdnPublicFolderUrl } from "@/constants"
import Image from "next/image"
import React, { forwardRef } from "react"

interface FolderIconProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  width?: number
  height?: number
}

const FolderIcon = forwardRef<HTMLImageElement, FolderIconProps>(({ width = 24, height = 24, ...props }, ref) => {
  return (
    <Image
      ref={ref}
      width={24}
      height={24}
      src={`${cdnPublicFolderUrl}/images/folders/folder.png`}
      alt="Folder"
      {...props}
    />
  )
})

export default FolderIcon
