import { RecipeFilterMode } from "@/api/sdk"
import ErrorUI from "@/components/ErrorUI"
import LoadingIcon from "@/components/LoadingIcon"
import NotFoundItems from "@/components/Workspace/Pinned/NotFoundItems"
import RecipeCard from "@/components/Workspace/Recipes/RecipeCard"
import useCustomRouter from "@/hooks/useCustomRouter"
import { useGetRecipesInfiniteQuery } from "@/queries/workspace/recipe"
import { motion } from "framer-motion"
import { memo, useEffect, useRef } from "react"
import InfiniteScroll from "react-infinite-scroller"

interface ComfyUIRecipesPublishedProps {
  sdWorkflowId?: string
  isOpen?: boolean
}

const ComfyUIRecipesPublished = ({ sdWorkflowId, isOpen }: ComfyUIRecipesPublishedProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const { openNewTab } = useCustomRouter()
  const {
    flattenData = [],
    isLoading,
    isError,
    fetchNextPage,
    hasNextPage,
    refetch,
    isFetching,
  } = useGetRecipesInfiniteQuery({
    variables: {
      mode: RecipeFilterMode.Owned,
      sdWorkflowId,
    },
    enabled: !!sdWorkflowId,
  })

  useEffect(() => {
    if (isOpen) {
      refetch()
    }
  }, [refetch, isOpen])

  const renderBody = () => {
    if (isLoading)
      return (
        <div className="flex items-center justify-center w-full flex-1 text-gray-600">
          <LoadingIcon width={20} height={20} />
        </div>
      )

    if (isError) return <ErrorUI />

    if (flattenData.length === 0) return <NotFoundItems />

    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.35, delay: 0.35 }}
        className={"relative flex-1 list-recipes"}
      >
        <InfiniteScroll
          loadMore={() => fetchNextPage()}
          hasMore={!!hasNextPage && !isFetching}
          useWindow={false}
          getScrollParent={() => ref.current!}
          threshold={600}
        >
          <div className="w-full gap-2 grid grid-cols-[repeat(auto-fill,minmax(8rem,1fr))]">
            {flattenData.map((recipe, idx) => (
              <RecipeCard
                onClick={() => {
                  setTimeout(() => {
                    openNewTab(`/workspace/tools/recipes/${recipe.id}`)
                  }, 1)
                }}
                hiddenAttribute
                className={`recipe-${idx + 1}`}
                recipe={recipe}
                key={recipe.id}
              />
            ))}
          </div>
        </InfiniteScroll>
      </motion.div>
    )
  }

  return (
    <div className="flex-1 flex flex-col overflow-hidden w-full pt-4">
      <div className="px-4">
        <p className="font-semibold text-base text-atherGray-100">Published Recipes</p>
      </div>
      <div className="flex-1 overflow-auto flex flex-col p-4" ref={ref}>
        {renderBody()}
      </div>
    </div>
  )
}

export default memo(ComfyUIRecipesPublished)
