import { useWindowEvent } from "@/hooks"
import { useEffect } from "react"
import { constSelector } from "recoil"
import { create } from "zustand"
import { immer } from "zustand/middleware/immer"

declare global {
  interface Window {
    navigation: any
  }
}
const isClient = typeof window !== "undefined"

var navigation: { currentEntry: { index: number } } = isClient ? window.navigation : null

const hasNavigation = isClient && !!window.navigation
const sessionKey = "hist_keys"

type ClientNavigationStore = {
  index: number
  keys: any[]
  setDefault: (key: string) => void
  pushKey: (key: string) => void
  setKey: (key: string) => void
  urls: (string | URL)[]
  pushUrl: (url: string | URL | null | undefined) => void
  setUrl: (url: string | URL | null | undefined) => void
}

export const useClientHistoryStore = create<ClientNavigationStore>()(
  immer(set => ({
    index: 0,
    keys: [],
    urls: [],
    setDefault: key => {
      set(state => {
        state.keys = [key]
      })
    },
    pushKey: key => {
      set(state => {
        state.keys = state.keys.slice(0, state.index + 1).concat([key])
        state.index = state.keys.length - 1
      })
    },
    setKey: key => {
      set(state => {
        const index = state.keys.indexOf(key)
        if (index > -1) {
          state.index = index
        } else if (index === -1) {
          state.keys = state.keys.slice(0, index).concat([key])
          state.index = state.keys.length - 1
        }
      })
    },

    pushUrl: url => {
      set(state => {
        state.urls = state.urls.slice(0, state.index + 1).concat([url as string])
        state.index = state.urls.length - 1
      })
    },

    setUrl: url => {
      set(state => {
        const index = state.urls.indexOf(url as string)
        if (index > -1) {
          state.index = index
        } else if (index === -1) {
          state.urls = state.urls.slice(0, index).concat([url as string])
          state.index = state.urls.length - 1
        }
      })
    },
  })),
)

export function ClientHistoryStore() {
  const [keys, pushKey, setDefault, setKey, setUrl, pushUrl] = useClientHistoryStore(state => [
    state.keys,
    state.pushKey,
    state.setDefault,
    state.setKey,
    state.setUrl,
    state.pushUrl,
  ])

  useEffect(() => {
    sessionStorage.setItem(sessionKey, keys.join(","))
  }, [keys])

  useEffect(() => {
    if (!keys.length) {
      setDefault(history.state.key)
    }
  }, [keys, setDefault])

  useEffect(() => {
    const pushState = history.pushState

    history.pushState = function (data, unused, url) {
      pushKey(data.key)
      return pushState.apply(history, [data, unused, url])
    }
    return () => {
      history.pushState = pushState
    }
  }, [pushKey])

  const handlePopstate = (e: any) => {
    if (!e.state) return

    setKey(e.state.key)
    setUrl(e.state.url)
  }
  useWindowEvent("popstate", handlePopstate)

  return null
}

export function useHasClientHistory() {
  const index = useClientHistoryStore(state => state.index ?? 0)

  if (hasNavigation) {
    return navigation?.currentEntry.index > 0 ?? index > 0
  } else {
    return index > 0
  }
}

export const getHasClientHistory = () => {
  if (!hasNavigation) {
    const keys = sessionStorage.getItem(sessionKey)?.split(",") ?? []
    const current = history.state.key
    const index = keys.indexOf(current)
    return index > 0
  } else return navigation?.currentEntry.index > 0
}
