import classNames from "classnames"
import React, { useState } from "react"
import { ImageLoadedComponent, WorkflowPublishType, formatInputKey } from "./WorkFlowDetailStep"
import TextCopy from "@/components/TextCopy"
import { RecipeTaskChainParams } from "../WorkflowsChaining"
import {
  formatValueParams,
  getModelName,
  humanizeInputParam,
  replaceHumanizePromptParam,
} from "../WorkflowsChaining/ChainItem"
import { SDModel } from "@/api/sdk"
import IconButton from "@/components/IconButton"
import { ArrowDownIcon } from "@/components/shared/icons"
import { motion } from "framer-motion"
import ButtonSavePrompt from "@/components/Explore/ImageDetail/TabImage/MetadataImage/ButtonSavePrompt"

interface WorkflowParamsProps {
  workflow: WorkflowPublishType
  param: RecipeTaskChainParams
  models: SDModel[]
  isAlwayOpen?: boolean
}

const WorkflowParams = ({ workflow, param, models, isAlwayOpen = false }: WorkflowParamsProps) => {
  const [isOpen, setIsOpen] = useState(isAlwayOpen)
  if (!workflow.capabilities.canUpdate) return null

  const renderPrompt = (keyOutside: string) => {
    return (
      <div
        className={classNames({
          "mb-2":
            Object.entries(param.params).filter(
              ([key, value]) => key === keyOutside || param.recipeInputStep?.find(i => i.key === key)?.type === "text",
            ).length > 0,
        })}
      >
        {Object.entries(param.params).map(([key, value]) => {
          if (key !== keyOutside) return
          if (key === keyOutside || param.recipeInputStep?.find(i => i.key === key)?.type === "text") {
            if (typeof value === "object") return null

            return (
              <div key={key} className="w-full mb-2">
                <div className="mb-1 flex items-center">
                  <p className="text-left text-atherGray-500 uppercase font-semibold text-xs flex-1">
                    {formatInputKey(key)}:
                  </p>
                  <div className="ml-1 space-x-2 flex items-center">
                    <TextCopy width={16} height={16} value={value} className="text-atherGray-300" />
                  </div>
                </div>
                <div className="flex flex-wrap gap-1 bg-atherGray-950 p-2 rounded-lg">
                  {typeof value === "string" &&
                    value &&
                    value.split(",").map((item, index) => {
                      if (item.trim())
                        return (
                          <span
                            key={index}
                            className="text-atherGray-300 flex items-center text-sm border border-atherGray-700 py-0.5 px-2 font-normal rounded-lg"
                          >
                            {replaceHumanizePromptParam(item, workflow.params as RecipeTaskChainParams[])}
                          </span>
                        )
                    })}
                </div>
              </div>
            )
          }
        })}
      </div>
    )
  }

  return (
    <div className="hidden md:block flex-1 flex-col mt-2">
      {renderPrompt("prompt")}
      {!isAlwayOpen && (
        <div
          className={classNames("flex justify-center mt-2", {
            hidden: workflow.outputText && workflow.fullDataImages?.length === 0,
          })}
        >
          <IconButton
            onClick={() => setIsOpen(prev => !prev)}
            colorScheme="transparent"
            className="pt-1 pb-0 w-full min-h-0 bg-transparent"
          >
            <ArrowDownIcon
              className={classNames("text-atherGray-300", {
                "transform rotate-180": isOpen,
              })}
              width={12}
              height={12}
            />
          </IconButton>
        </div>
      )}
      <motion.div
        className={classNames("flex-1 overflow-hidden step-generated-settings", {
          // "mt-4": workflow.outputType?.every(type => type !== "text"),
        })}
        initial={{ height: 0, opacity: 0, marginTop: 0 }}
        animate={{
          height: isOpen ? "auto" : 0,
          marginTop: !workflow.outputText && isOpen ? "0.5rem" : 0,
          opacity: isOpen ? 1 : 0,
        }}
      >
        {renderPrompt("negative_prompt")}
        <div className="grid grid-cols-[repeat(auto-fill,minmax(16rem,_1fr))] w-full gap-2">
          {Object.entries(param.params)
            .sort((a, b) => a[0].localeCompare(b[0]))
            .map(([key, value]) => {
              if (key.includes("image") && !value.toString().includes("$$prev")) {
                return null
              }

              if (
                (key.includes("referenceImage") || key.includes("reference_image")) &&
                !value.toString().includes("$$prev")
              ) {
                return null
              }

              if (
                key === "prompt" ||
                key === "negative_prompt" ||
                param.recipeInputStep?.find(i => i.key === key)?.type === "text"
              ) {
                return null
              }

              if (
                key === "model_hash" ||
                key === "modelHash" ||
                param.recipeInputStep?.find(i => i.key === key)?.type === "model"
              ) {
                return (
                  <div key={key} className="bg-atherGray-950 px-2 py-1 flex items-center rounded-lg">
                    <div className="flex items-center font-normal text-atherGray-300 text-xs w-full">
                      <p className="text-atherGray-500 whitespace-nowrap uppercase font-semibold text-xs">
                        {formatInputKey(key)}:
                      </p>{" "}
                      <div className="flex-1 text-sm ml-2 flex justify-end overflow-hidden">
                        <p
                          className="line-clamp-1 break-words"
                          title={getModelName(models, param.params.modelHash || param.params.model_hash || value)}
                        >
                          {getModelName(models, param.params.modelHash || param.params.model_hash || value)}
                        </p>
                      </div>
                    </div>
                  </div>
                )
              }

              if (key.endsWith("_wildcard")) return null

              if (param.params?.["styleName"] && key === "style") {
                return null
              }

              return (
                <div key={key} className="bg-atherGray-950 px-2 py-1 flex items-center rounded-lg">
                  <div className="flex items-center font-normal text-atherGray-300 text-xs w-full">
                    <p className="text-atherGray-500 whitespace-nowrap uppercase font-semibold text-xs">
                      {formatInputKey(key, param)}:
                    </p>
                    <div className="flex-1 text-sm ml-2 flex justify-end overflow-hidden">
                      <p
                        className="line-clamp-1 break-words"
                        title={
                          value?.toString().includes("$$prev")
                            ? humanizeInputParam(value, workflow.params as RecipeTaskChainParams[])
                            : formatValueParams(key, value)
                        }
                      >
                        {value?.toString().includes("$$prev")
                          ? humanizeInputParam(value, workflow.params as RecipeTaskChainParams[])
                          : formatValueParams(key, value)}
                      </p>
                    </div>
                  </div>
                </div>
              )
            })}
        </div>
        <div
          className={classNames("mt-2 grid grid-cols-[repeat(auto-fill,minmax(10rem,_1fr))] gap-2", {
            hidden:
              Object.entries(param.params).filter(([key, value]) => {
                if (key.includes("image") && !value.toString().includes("$$prev")) return false
                if (
                  key === "prompt" ||
                  key === "negative_prompt" ||
                  param.recipeInputStep?.find(i => i.key === key)?.type === "text"
                )
                  return false
                if (
                  (key.includes("referenceImage") || key.includes("reference_image")) &&
                  !value.toString().includes("$$prev")
                )
                  return false
                return true
              }).length === 0,
          })}
        >
          {Object.entries(param.params).map(([key, value]) => {
            if (
              (key.includes("referenceImage") || key.includes("reference_image")) &&
              !value.toString().includes("$$prev")
            ) {
              return (
                <div key={key} className="">
                  <p className="text-left text-atherGray-500 uppercase font-semibold text-xs mb-1">
                    {formatInputKey(key)}:
                  </p>
                  <div className="w-full">
                    <ImageLoadedComponent className="rounded-none max-h-[10rem]" key={key} url={value} />
                  </div>
                </div>
              )
            }

            if (
              (key === "image" || param.recipeInputStep?.find(i => i.key === key)?.type === "image") &&
              !value.toString().includes("$$prev")
            ) {
              return (
                <div key={key} className="w-full">
                  <p className="text-left text-atherGray-500 uppercase font-semibold text-xs mb-1">
                    {formatInputKey(key)}:
                  </p>
                  <div className="w-full">
                    <ImageLoadedComponent className="rounded-none max-h-[10rem]" key={key} url={value} />
                  </div>
                </div>
              )
            }
          })}
        </div>
      </motion.div>
    </div>
  )
}

export default WorkflowParams
