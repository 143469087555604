import IconButton from "@/components/IconButton"
import Popover from "@/components/Popover"
import { ArrowDownIcon, BuildingIcon, RefreshIcon, ReloadIcon } from "@/components/shared/icons/"
import { useAuth } from "@/providers/authContext"
import { useSwitchWorkspaceMutation, useUserWorkspacesInfiniteQuery } from "@/queries"
import { cn } from "@/utils/cn"
import classNames from "classnames"
import { useEffect, useRef, useState } from "react"
import InfiniteScroll from "react-infinite-scroller"

const WorkspaceSelector = ({
  isMobile,
  isHide,
  setIsHide,
}: {
  isMobile?: boolean
  isHide?: boolean
  setIsHide?: (isHide: boolean) => void
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const scrollRef = useRef<HTMLDivElement>(null)
  const [triggerRefWidth, setTriggerRefWidth] = useState(0)
  const triggerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (triggerRef.current) {
      setTriggerRefWidth(triggerRef.current.getBoundingClientRect().width)
    }
  }, [isOpen])

  const { userInfoQuery } = useAuth()

  const { flattenData, hasNextPage, fetchNextPage } = useUserWorkspacesInfiniteQuery()

  const {
    mutate: mutateSwitchWorkspace,
    variables,
    isPending,
  } = useSwitchWorkspaceMutation({
    onSuccess: () => {
      window.location.href = "/workspace"
    },
  })

  const currentWorkspacePhotoUrl =
    isPending || userInfoQuery?.isRefetching ? variables?.photoUrl : userInfoQuery?.data?.currentWorkspace?.photoUrl
  const currentWorkspaceName =
    isPending || userInfoQuery?.isRefetching ? variables?.name : userInfoQuery?.data?.currentWorkspace?.name ?? "..."

  if (!userInfoQuery?.data) return null

  return (
    <div className="relative">
      {isMobile ? null : <p className="text-atherGray-500 font-semibold text-xs mb-2">WORKSPACE</p>}
      <Popover
        isOpen={isOpen}
        setIsOpen={open => {
          if (isMobile) {
            return
          }
          setIsOpen(open)
        }}
        className="origin-top"
        trigger={
          <div
            ref={triggerRef}
            onClick={e => {
              if (isMobile) {
                e.stopPropagation()
                setIsHide?.(!isHide)
              }
            }}
            className={classNames("p-2 flex items-center rounded-2xl bg-atherGray-850 cursor-pointer select-none", {
              "bg-atherGray-900": isMobile,
            })}
          >
            <div title={currentWorkspaceName} className="flex-1 flex items-center overflow-hidden">
              <WorkspaceImage
                itemClassName={isMobile ? "w-12 h-12" : ""}
                photoUrl={currentWorkspacePhotoUrl}
                name={currentWorkspaceName}
              />
              <p className="font-semibold ml-2 truncate">{currentWorkspaceName ?? "User"}</p>
            </div>
            <div className="ml-2 flex items-center space-x-2">
              {isMobile && (
                <IconButton
                  title="User Info"
                  colorScheme={isMobile ? "secondary" : "transparent"}
                  className={classNames("p-2 min-h-0 rounded-full", {
                    "bg-atherGray-700": isOpen,
                  })}
                  onClick={e => {
                    e.stopPropagation()
                    setIsOpen(!isOpen)
                  }}
                >
                  <ReloadIcon width={16} height={16} />
                </IconButton>
              )}
              <IconButton
                title="User Info"
                onClick={e => {
                  if (isMobile) {
                    e.stopPropagation()
                    setIsHide?.(!isHide)
                  }
                }}
                colorScheme={"transparent"}
                className={classNames("p-2 min-h-0 rounded-full", {
                  "bg-atherGray-700 hover:bg-atherGray-700": !isHide && isMobile,
                })}
              >
                <ArrowDownIcon width={16} height={16} />
              </IconButton>
            </div>
          </div>
        }
      >
        <div
          ref={scrollRef}
          className="bg-atherGray-900 text-atherGray-0 p-2 border border-atherGray-850 rounded-2xl overflow-y-auto overflow-x-hidden max-h-[20rem] workspace-selecter-item"
          style={{ width: triggerRefWidth, WebkitOverflowScrolling: "touch" }}
        >
          {(flattenData?.length ?? 0 > 0) && (
            <InfiniteScroll
              getScrollParent={() => scrollRef.current}
              pageStart={0}
              useWindow={false}
              loadMore={() => fetchNextPage()}
              hasMore={!!hasNextPage}
              className="space-y-2 w-full workspace-selecter-item"
            >
              <div className="w-full py-2 workspace-selecter-item">
                <p className="font-semibold px-2 mb-2 text-xs text-atherGray-500 uppercase workspace-selecter-item">
                  My Workspace
                </p>
                {flattenData
                  ?.filter(i => i.userWorkspace.role === "owner")
                  .map(workspace => (
                    <div
                      className={cn(
                        "p-2 hover:bg-atherGray-850 bg-atherGray-900 rounded-2xl flex items-center overflow-hidden cursor-pointer select-none text-atherGray-300 workspace-selecter-item",
                        {
                          "bg-atherGray-850 text-atherGray-0":
                            workspace.id === userInfoQuery?.data?.currentWorkspace?.id,
                        },
                      )}
                      key={workspace.id}
                      onClick={e => {
                        e.stopPropagation()
                        mutateSwitchWorkspace(workspace)
                        setIsOpen(false)
                      }}
                    >
                      <WorkspaceImage
                        className="workspace-selecter-item"
                        photoUrl={workspace.photoUrl}
                        name={workspace.name}
                      />
                      <p className={cn("ml-2 truncate font-semibold workspace-selecter-item")}>
                        {workspace.name ?? "User"}
                      </p>
                    </div>
                  ))}
              </div>
              <div className="w-full py-2 workspace-selecter-item">
                <p className="font-semibold px-2 mb-2 text-xs text-atherGray-500 uppercase workspace-selecter-item">
                  Shared workspaces
                </p>
                {flattenData
                  ?.filter(i => i.userWorkspace.role !== "owner")
                  .map(workspace => (
                    <div
                      className={cn(
                        "p-2 hover:bg-atherGray-850 bg-atherGray-900 rounded-2xl flex items-center overflow-hidden cursor-pointer select-none text-atherGray-300 workspace-selecter-item",
                        {
                          "bg-atherGray-850 text-atherGray-0":
                            workspace.id === userInfoQuery?.data?.currentWorkspace?.id,
                        },
                      )}
                      key={workspace.id}
                      onClick={e => {
                        e.stopPropagation()
                        mutateSwitchWorkspace(workspace)
                        setIsOpen(false)
                      }}
                    >
                      <WorkspaceImage
                        className="workspace-selecter-item"
                        photoUrl={workspace.photoUrl}
                        name={workspace.name}
                      />
                      <p className={cn("ml-2 truncate font-semibold workspace-selecter-item")}>
                        {workspace.name ?? "User"}
                      </p>
                    </div>
                  ))}
              </div>
            </InfiniteScroll>
          )}
        </div>
      </Popover>
    </div>
  )
}

export default WorkspaceSelector

export const WorkspaceImage = ({
  photoUrl,
  name,
  className,
  itemClassName,
}: {
  photoUrl?: string
  name?: string
  className?: string
  itemClassName?: string
}) => {
  const [isError, setIsError] = useState(false)

  return (
    <div className={cn("flex items-center", className)}>
      {!isError && photoUrl ? (
        <div className={cn("w-8 h-8 rounded-full overflow-hidden", itemClassName)}>
          <img
            className={cn("w-full h-full object-cover", itemClassName)}
            src={photoUrl ?? ""}
            alt={name ?? ""}
            onLoad={() => setIsError(false)}
            onError={() => setIsError(true)}
          />
        </div>
      ) : (
        <div
          className={cn(
            "w-8 h-8 rounded-full flex items-center justify-center text-atherGray-0 bg-atherGray-700",
            itemClassName,
          )}
        >
          <BuildingIcon width={20} height={20} />
        </div>
      )}
    </div>
  )
}
