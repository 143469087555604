import { ContentModerationRating, SDModelItem } from "@/api/sdk"
import { useAuth } from "@/providers/authContext"
import { useInView } from "react-intersection-observer"
import { useState } from "react"
import { googleAnalytics } from "@/lib/gtag"
import ImageWebpComponent from "@/components/ImageWebpComponent"
import Skeleton from "@/components/Skeleton"
import classNames from "classnames"
import { format } from "date-fns"
import UserCreated from "@/components/Explore/Gallery/UserCreated"
import { PinIcon } from "@/components/shared/icons"
import ModelActionButtons from "@/components/Tools/Model/ModelItem/ModelActionButtons"

interface ModelItemListProps {
  model: SDModelItem
  accessedAt?: string
  onClick?: () => void
  hiddenMenu?: boolean
}

const ModelItemList = ({ model, accessedAt, onClick, hiddenMenu }: ModelItemListProps) => {
  const [imageLoaded, setImageLoaded] = useState(false)
  const { user } = useAuth()
  const { ref, inView } = useInView({
    threshold: 0,
    rootMargin: "800px 0px 800px 0px",
  })

  const [isShow, setIsShow] = useState(
    model?.thumbnailModerationRating === ContentModerationRating.HardcoreNsfw ||
      model?.thumbnailModerationRating === ContentModerationRating.SemiNsfw,
  )

  const handleClick = () => {
    googleAnalytics.event({
      action: "click",
      category: "model_item",
      label: `view_model_detail`,
      params: {
        model_id: model.id,
        model_name: model.name ?? "",
        pathname: window.location.pathname,
      },
    })

    if (onClick) {
      onClick()

      return
    }
  }

  return (
    <div className="w-full flex items-center text-sm text-atherPrimary-100 bg-atherGray-900 rounded-lg" ref={ref}>
      <div className="flex-1 md:flex-none md:w-[30%] py-2 pr-0 pl-2 md:pr-2 cursor-pointer" onClick={handleClick}>
        <div className="flex items-center overflow-hidden">
          <Skeleton
            className="mr-2 h-[3rem] w-[3rem] flex items-center overflow-hidden justify-center bg-atherGray-950"
            isLoaded={imageLoaded && !isShow}
          >
            {inView && (
              <ImageWebpComponent
                src={model.thumbnailUrl ?? ""}
                alt={model.name ?? ""}
                className={classNames("block max-h-full object-contain", {
                  "blur-sm": isShow,
                })}
                onLoad={() => setImageLoaded(true)}
              />
            )}
          </Skeleton>
          <div className="overflow-hidden flex flex-col flex-1">
            <p className="font-semibold flex-1 line-clamp-1">{model.name}</p>
            <div className="block md:hidden truncate text-atherGray-500">
              Opened - {accessedAt ? format(new Date(accessedAt), "HH:mm dd/MM/yyyy") : ""}
            </div>
          </div>
        </div>
      </div>
      <div className="hidden md:block md:w-[15%] p-2 whitespace-nowrap truncate">Model</div>
      <div className="hidden md:block md:w-[15%] p-2 whitespace-nowrap truncate">
        {accessedAt ? format(new Date(accessedAt), "HH:mm dd/MM/yyyy") : ""}
      </div>
      <div className="hidden md:block md:w-[15%] p-2">
        <UserCreated
          size="sm"
          picture={model.creator?.picture ?? ""}
          name={model.creator?.name ?? ""}
          isMe={user?.uid === model.creator?.uid}
        />
      </div>
      <div
        className="hidden md:block md:w-[20%] p-2 overflow-hidden cursor-pointer"
        onClick={e => {
          e.stopPropagation()
        }}
      ></div>
      <div className="md:w-[5%] pr-2 md:p-2 flex justify-end">
        {model.pinned !== undefined && model.pinned && (
          <div className="p-0.5">
            <PinIcon width={16} height={16} />
          </div>
        )}
        {!hiddenMenu && (
          <ModelActionButtons layout="list" model={model} className="bg-transparent hover:bg-transparent" />
        )}
      </div>
    </div>
  )
}

export default ModelItemList
