import { QueryClient, useQueryClient } from "@tanstack/react-query"
import { Middleware, MutationHook } from "react-query-kit"

export const actionMiddleware =
  <TResponse = unknown, TVariables = unknown>(
    actions: {
      onMutate?: (params: { queryClient: QueryClient; variables: TVariables }) => void
      onError?: (params: { queryClient: QueryClient; error: Error; variables: TVariables; context?: unknown }) => void
      onSuccess?: (params: {
        queryClient: QueryClient
        data: TResponse
        variables: TVariables
        context?: unknown
      }) => void
      onSettled?: (params: {
        queryClient: QueryClient
        data: TResponse | undefined
        error: Error | null
        variables: TVariables
        context?: unknown
      }) => void
    } = {},
  ): Middleware<MutationHook<TResponse, Error, TVariables, unknown>> =>
  useMutationNext => {
    return (options, client) => {
      const queryClient = useQueryClient(client)

      return useMutationNext({
        ...options,
        onMutate: variables => {
          actions?.onMutate?.({ queryClient, variables })
          options.onMutate?.(variables)
        },
        onError: (error, variables, context) => {
          actions?.onError?.({
            queryClient,
            error,
            variables,
            context,
          })
          options.onError?.(error, variables, context)
        },
        onSuccess: (data, variables, context) => {
          actions?.onSuccess?.({
            queryClient,
            data,
            variables,
            context,
          })
          options.onSuccess?.(data, variables, context)
        },
        onSettled: (data, error, variables, context) => {
          actions?.onSettled?.({
            queryClient,
            data,
            error,
            variables,
            context,
          })
          options.onSettled?.(data, error, variables, context)
        },
      })
    }
  }
