import client from "@/api/client"
import {
  FeedbackRating,
  RecipeInputField,
  RecipeInputType,
  RecipeTaskStatus,
  RecipeTaskWithFullImages,
  SDModel,
} from "@/api/sdk"
import { COMFY_RECIPE_ID } from "@/components/Tools/ComfyUIRecipe/ComfyUIRecipeBuilder/ComfyUIDetail"
import { TRY_STYLE_RECIPE } from "@/components/Tools/Style/StyleDetail/StyleDetail"
import {
  ArrowMoveUpRightIcon,
  CopyIcon,
  DeleteIcon,
  EditIcon,
  RepeatIcon,
  SaveIcon,
  ThumbUpFillIcon,
  ThumbUpIcon,
  XIcon,
} from "@/components/shared/icons"
import { useToast } from "@/hooks"
import useCustomRouter from "@/hooks/useCustomRouter"
import { googleAnalytics } from "@/lib/gtag"
import { TaskProgressSocketEvent } from "@/providers/ProgressingProvider"
import { useAuth } from "@/providers/authContext"
import { useCancelTaskMutation, useCreateTaskMutation, useCreateTaskNotAuthMutation } from "@/queries"
import { useDeleteItemStore, useManagementErrorsStore, useSignInStore, useTempTaskStoreV2 } from "@/stores"
import { cn } from "@/utils/cn"
import generateCaptchaToken from "@/utils/generateCaptchaToken"
import { InfiniteData, QueryKey, useMutation, useQueryClient } from "@tanstack/react-query"
import classNames from "classnames"
import { AnimatePresence, motion } from "framer-motion"
import React, { memo, useEffect, useRef, useState } from "react"
import WorkFlowDetailStep from "../../../Workflows/WorkflowDetailStep/WorkFlowDetailStep"
import { RecipeTaskChainParams } from "../../../Workflows/WorkflowsChaining"
import { MultiselectImagesProps } from "../ListRecipes"
import WorkspaceContainer from "../../../WorkspaceContainer"
import { AccordionHeader } from "./AccordionHeader"
import { AccordionActions } from "./AccordionActions"

interface AccordionRecipeProps extends MultiselectImagesProps {
  task: RecipeTaskWithFullImages
  containerWidth?: number
  refetchHistory: () => void
  setValues?: (params: Record<string, any>) => void
  recipeInputs: (RecipeInputField & { isChecked?: boolean })[]
  setRecipeInputs?: React.Dispatch<React.SetStateAction<(RecipeInputField & { isChecked?: boolean })[]>>
  onCreateTask?: (newParams: Record<string, any>) => void
  recipeHistoryKey?: QueryKey
  models?: SDModel[]
  onSelectedRatingItem?: (id: string) => void
  isLoadingCreateTask?: boolean
  recipeName?: string
  recipeId?: string
  className?: string
  recipeOngoingKey?: QueryKey
  hiddenActions?: string[]
  progressDatas?: TaskProgressSocketEvent[] | null
}

const AccordionRecipe = ({
  task,
  containerWidth,
  selectionMode,
  recipeHistoryKey,
  recipeId,
  recipeOngoingKey,
  recipeInputs,
  className,
  hiddenActions,
  isLoadingCreateTask,
  onSelectedRatingItem,
  models,
  recipeName,
  refetchHistory,
  setValues,
  setRecipeInputs,
  onCreateTask,
  progressDatas,
  selectedItems,
  onCheck,
}: AccordionRecipeProps) => {
  const [isOpen, setIsOpen] = useState(true)
  const [isActive, setIsActive] = useState(false)
  const [isActive1, setIsActive1] = useState(false)

  const [input, setInput] = useState<string>("")
  const [isRenaming, setIsRenaming] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)
  const toast = useToast()
  const qc = useQueryClient()

  const { user, handleSignIn } = useAuth()
  const setDeletingItem = useDeleteItemStore(s => s.setDeletingItem)
  const tempTask = useTempTaskStoreV2(state => state.tempTask)
  const { openNewTab } = useCustomRouter()
  const setSignInModal = useSignInStore(state => state.setSignInModal)

  useEffect(() => {
    setInput(task.name ?? task.id)
    setIsRenaming(false)
  }, [task.name, task.id])

  const { mutate: mutateRename } = useMutation({
    mutationFn: () =>
      client.api.recipeTaskControllerUpdateTask(task.id, {
        name: input,
      }),
    onMutate: async () => {
      let previousHistory

      if (recipeHistoryKey) {
        await qc.cancelQueries({ queryKey: recipeHistoryKey })
        previousHistory = qc.getQueryData<InfiniteData<RecipeTaskWithFullImages[]> | undefined>(recipeHistoryKey)
        if (previousHistory) {
          qc.setQueryData(recipeHistoryKey, () => {
            return {
              ...previousHistory,
              pages: previousHistory.pages.map(page => {
                return [
                  ...page.map(item => {
                    if (item.id === task.id) {
                      return {
                        ...item,
                        name: input,
                      }
                    }
                    return item
                  }),
                ]
              }),
            }
          })
        }
      }

      setIsRenaming(false)
      return { previousHistory }
    },
    onError: (err, _, context: any) => {
      if (context?.previousHistory && recipeHistoryKey) {
        qc.setQueryData(recipeHistoryKey, context.previousHistory)
      }
    },
  })

  const handleRename = () => {
    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        action: "Rename Task",
        task_id: task.id,
        task_name: task.name ?? "",
      },
    })

    if (!user) {
      return
    }

    setIsRenaming(prev => !prev)

    if (!isRenaming) {
      // select the text
      setTimeout(() => {
        inputRef.current?.focus()
      }, 150)
    } else {
      if (input === task.name) {
        return
      }

      mutateRename()
    }
  }

  const handleSendRecipe = (isToast: boolean) => {
    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        action: "Send Params to Recipe",
        task_id: task.id,
        task_name: task.name ?? "",
        ...task.params,
      },
    })

    const newParams = {
      ...task.params,
    }

    setValues?.(newParams)

    setRecipeInputs?.(prev => {
      return prev.map(item => {
        return {
          ...item,
          isChecked: task.params[item.key] ? true : false,
        }
      })
    })

    if (isToast) {
      toast({
        status: "success",
        title: "Recipe copied and ready for re-generation",
        message: ["You can adjust any fields as desired"],
      })
    } else {
      onCreateTask?.(newParams)
    }
  }
  const setErrorState = useManagementErrorsStore(state => state.setErrorState)

  const { mutateAsync: mutateCreateTask, isPending: isLoadingCreate } = useCreateTaskMutation({
    onSuccess: data => {
      setTimeout(() => {
        openNewTab(`/workspace/macros/${data.id}?draft=true`)
      }, 1)
    },
    onError: (err: any) => {
      if (
        err?.message.startsWith("Guest user does not have permission") ||
        err?.message.startsWith("Your subscription has ended")
      ) {
        setErrorState({
          isOpen: true,
          message: err.message,
        })
        return
      }

      if (err?.error === "Bad Request") {
        toast({ title: "Cannot Complete Request", message: [err.message], status: "error" })
      } else {
        toast({ title: "Error", message: [err.message], status: "error" })
      }
    },
  })

  const { mutateAsync: mutateCreateTaskNotAuth, isPending: isLoadingCreateNotAuth } = useCreateTaskNotAuthMutation({
    onSuccess: data => {
      setTimeout(() => {
        openNewTab(`/workspace/macros/${data.id}?draft=true`)
      }, 1)
    },
    onError: (err: any) => {
      if (
        err?.message.startsWith("Guest user does not have permission") ||
        err?.message.startsWith("Your subscription has ended")
      ) {
        setErrorState({
          isOpen: true,
          message: err.message,
        })
        return
      }

      if (err?.error === "Bad Request") {
        toast({ title: "Cannot Complete Request", message: [err.message], status: "error" })
      } else {
        toast({ title: "Error", message: [err.message], status: "error" })
      }
    },
  })

  const [selectedStep, setSelectedStep] = useState<RecipeTaskChainParams | undefined>(undefined)

  const handleSendNewWorkflow = async () => {
    const isSignIn = await handleSignIn()

    if (!isSignIn) return

    const recipeInputStep: any = recipeInputs.map(step => ({
      key: step.key,
      name: step.name,
      optional: step.optional,
      type: step.type,
      allowWildcard: step.allowWildcard,
      depends: step.depends,
      private: step.private,
    }))

    const params = Object.entries(task.params).map(([key, value]) => {
      if (recipeInputStep?.find(step => step.key === key)?.type === RecipeInputType.Image && value.includes("$$prev")) {
        return {
          [key]: "",
        }
      }

      return {
        [key]: value,
      }
    })

    const newParams = params.reduce((acc, curr) => {
      return {
        ...acc,
        ...curr,
      }
    }, {})

    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        action: "Send Task to New Macro",
        task_id: task.id,
        task_name: task.name ?? "",
        ...newParams,
      },
    })

    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        action: "Send Task to New Macro",
        task_id: task.id,
        task_name: task.name ?? "",
        ...task.params,
      },
    })

    const newChainSendNew = {
      id: new Date().getTime().toString(),
      params: newParams,
      recipeId: task.recipeId,
      recipeInputStep: recipeInputStep,
      recipeName: recipeName,
    }

    if (!user) {
      if (tempTask && tempTask?.length >= 5) {
        toast({
          status: "error",
          title: "Limit reached",
          message: ["Please sign in to generate more"],
        })

        setSignInModal({
          signIn: true,
        })

        return
      }

      const captchaToken = await generateCaptchaToken()

      mutateCreateTaskNotAuth({
        recipeId: "recipe-to-recipe",
        params: [newChainSendNew],
        name: "New Macro",
        status: RecipeTaskStatus.DRAFT,
        captchaToken,
      })

      return
    }

    mutateCreateTask({
      recipeId: "recipe-to-recipe",
      params: [newChainSendNew],
      name: "New Macro",
      status: RecipeTaskStatus.DRAFT,
    })
  }

  const { mutate: mutateCancelTask, isPending: isCancelTaskPending } = useCancelTaskMutation({
    onSuccess: async () => {
      await qc.cancelQueries({ queryKey: recipeOngoingKey })

      qc.invalidateQueries({ queryKey: recipeOngoingKey })

      toast({
        status: "success",
        title: "Task cancelled",
        message: ["The task has been cancelled"],
      })
    },
  })

  const handleFeedback = async () => {
    onSelectedRatingItem?.(task.id)
  }

  const actions = [
    {
      title: "Rating",
      icon: task.userFeedback ? (
        <ThumbUpFillIcon
          width={16}
          height={16}
          className={classNames({
            "text-red-500 rotate-180": task.userFeedback?.rating === FeedbackRating.NotGood,
            "text-atherPurple-500": task.userFeedback?.rating === FeedbackRating.Good,
          })}
        />
      ) : (
        <ThumbUpIcon width={16} height={16} />
      ),
      onClick: () => {
        handleFeedback()
      },
      visible:
        (hiddenActions?.includes("feedback") ? false : true) && user && task.status === RecipeTaskStatus.COMPLETED,
      disabled: !!task.userFeedback,
    },
    {
      title: "Rename",
      icon: isRenaming ? <SaveIcon width={16} height={16} /> : <EditIcon width={16} height={16} />,
      onClick: () => {
        handleRename()
      },
      isDropdown: true,
      visible: (hiddenActions?.includes("rename") ? false : true) && !!user,
    },
    {
      title: "Send to New Macro",
      icon: <ArrowMoveUpRightIcon width={16} height={16} />,
      isLoading: isLoadingCreate || isLoadingCreateNotAuth,
      onClick: () => {
        handleSendNewWorkflow()
      },
      visible:
        (hiddenActions?.includes("send-to-new-macro") ? false : true) &&
        user &&
        task.recipeId !== COMFY_RECIPE_ID &&
        task.recipeId !== TRY_STYLE_RECIPE,
    },
    {
      title: "Copy recipe",
      icon: <CopyIcon width={16} height={16} />,
      onClick: () => {
        handleSendRecipe(true)
      },
      visible:
        (hiddenActions?.includes("copy-recipe") ? false : true) &&
        task.recipeId !== COMFY_RECIPE_ID &&
        task.recipeId !== TRY_STYLE_RECIPE,
    },
    {
      title: "Run again",
      icon: <RepeatIcon width={16} height={16} />,
      isLoading: isLoadingCreateTask,
      onClick: () => {
        googleAnalytics.handleCategoryEvent({
          action: "click",
          params: {
            action: "Run Task Again",
            task_id: task.id,
            task_name: task.name ?? "",
            ...task.params,
          },
        })

        handleSendRecipe(false)
      },
      visible: hiddenActions?.includes("run-again") ? false : true,
    },
    {
      title: "Cancel task",
      icon: <XIcon className="text-red-500" width={16} height={16} />,
      onClick: () => {
        mutateCancelTask(task.id)
      },
      isLoading: isCancelTaskPending,
      isDropdown: true,

      visible:
        (hiddenActions?.includes("cancel-task") ? false : true) &&
        (task.status === RecipeTaskStatus.RUNNING || task.status === RecipeTaskStatus.QUEUED) &&
        !!user,
    },
    {
      title: "Delete task",
      icon: <DeleteIcon className="text-red-500" width={16} height={16} />,
      onClick: () => {
        setDeletingItem({
          type: "task",
          ids: [task.id],
          id: task.id,
          recipeParams: {
            recipeId: task.recipeId,
          },
          deletedAt: null,
        })
      },
      isDropdown: true,
      visible:
        (hiddenActions?.includes("delete-task") ? false : true) &&
        task.status !== RecipeTaskStatus.RUNNING &&
        task.status !== RecipeTaskStatus.QUEUED &&
        !!user,
    },
  ]

  return (
    <WorkspaceContainer className={cn("pt-2 mb-2 border-t border-atherGray-700", className)}>
      <div className="flex items-start md:items-center w-full px-2">
        <AccordionHeader
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          isRenaming={isRenaming}
          input={input}
          setInput={setInput}
          task={task}
          handleRename={handleRename}
          mutateRename={mutateRename}
          inputRef={inputRef}
        />
        <AccordionActions
          actions={actions}
          isActive={isActive}
          setIsActive={setIsActive}
          isActive1={isActive1}
          setIsActive1={setIsActive1}
          onSelectedStep={() =>
            setSelectedStep({
              ...task,
              recipeName: recipeName ?? "",
            })
          }
        />
      </div>
      {task.executionDuration ? (
        <p className={classNames("pl-8 pr-2 mt-1 md:mt-0 text-xs text-atherGray-400")}>
          Running time: {(task.executionDuration / 1000).toFixed(1)}s
        </p>
      ) : null}
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.div
            initial={{ height: 0 }}
            animate={{ height: "auto" }}
            exit={{ height: 0 }}
            className={cn("w-full mt-2 overflow-hidden")}
          >
            <div>
              <WorkFlowDetailStep
                className="p-2 md:p-2"
                progressData={progressDatas?.find(progress => progress.task_id === task.id)}
                selectionMode={selectionMode}
                selectedItems={selectedItems}
                onCheck={onCheck}
                selectedStepProp={selectedStep}
                models={models ?? []}
                containerWidth={containerWidth}
                mode="RECIPE"
                recipeId={recipeId}
                refetchHistory={refetchHistory}
                workflow={{
                  capabilities: {
                    canUpdate: true,
                  },
                  id: task.id,
                  creator: task.creator ?? undefined,
                  params: [
                    {
                      id: task.id,
                      params: task.params,
                      recipeId: task.recipeId,
                      recipeName: task.name,
                      recipeInputStep: recipeInputs,
                    },
                  ],
                  executionDuration: task.executionDuration,
                  isHtml: task.isHtml,
                  status: task.status,
                  fullDataImages: task.fullDataImages,
                  createdAt: task.createdAt,
                  outputText: task.outputText,
                  outputType: task.outputType,
                }}
              />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </WorkspaceContainer>
  )
}

export default memo(AccordionRecipe)
