import { EntityType } from "@/api/sdk"
import IconButton from "@/components/IconButton"
import { FavoriteIcon, FavoriteFillIcon } from "@/components/shared/icons"
import { googleAnalytics } from "@/lib/gtag"
import { useAuth } from "@/providers/authContext"
import { useFavoriteStyleMutation } from "@/queries"
import { useSignInStore } from "@/stores"
import { cn } from "@/utils/cn"
import useCustomRouter from "@/hooks/useCustomRouter"
import React from "react"

interface StyleReactionProps {
  className?: string
  style: {
    id: string
    favoritedByUser: boolean
    name?: string
    metric: {
      favoriteCount: number
    } | null
  }
}

const StyleReaction = ({ className, style }: StyleReactionProps) => {
  const { mutate: mutateFavoriteStyle } = useFavoriteStyleMutation()
  const { user, handleSignIn } = useAuth()

  return (
    <IconButton
      onClick={async e => {
        e.preventDefault()
        e.stopPropagation()

        const isSignedIn = await handleSignIn()

        if (!isSignedIn) return

        googleAnalytics.handleCategoryEvent({
          action: "click",
          params: {
            action: "Favorite style",
            tab_name: "styles",
            style_id: style.id,
            style_name: style?.name ?? "",
          },
        })

        mutateFavoriteStyle({
          id: style.id,
          userUid: user?.uid,
        })
      }}
      className={cn("flex items-center p-1 text-sm bg-transparent", className)}
      colorScheme="secondary"
    >
      {!style.favoritedByUser ? (
        <FavoriteIcon className="text-atherGray-300" />
      ) : (
        <FavoriteFillIcon className="text-atherGray-300" />
      )}
      <p className="text-atherGray-300">{Math.max(style.metric?.favoriteCount || 0, 0)}</p>
    </IconButton>
  )
}

export default StyleReaction
