import client from "@/api/client"
import { SDStyleFilterMode, SearchSDStyleResponse } from "@/api/sdk"
import ImageView from "@/components/ImageUpload/ImageView"
import { XIcon } from "@/components/shared/icons"
import { useCreateNewStyleStore } from "@/stores"
import { css } from "@emotion/css"
import classNames from "classnames"
import { FC, useEffect, useMemo, useRef } from "react"
import { createQuery } from "react-query-kit"
import ReactSlider from "react-slider"
import FormDetailThumbnailSelectComponent, {
  FormDetailThumbnailSelectProps,
  RenderSelectorFn,
  RenderThumbnailSelectorProps,
  renderSelectorAsSelect,
} from "./FormDetailSelectWithThumbnail"
import { useWorkspaceStyleInfiniteQuery } from "@/queries/tools/style/useGetStyleInfiniteQuery"
import _uniqBy from "lodash/uniqBy"

export type FormDetaiStyleComponentProps = Omit<FormDetailThumbnailSelectProps, "data"> & {
  styleIntensity?: number
}

export type RenderStyleSelectorProps = RenderThumbnailSelectorProps & {
  styleIntensity?: number
}

export const useStylesQuery = createQuery<SearchSDStyleResponse, { ids: string[] }, Error>({
  primaryKey: "list-styles",
  queryFn: ({ queryKey: [_primaryKey, _variables] }) =>
    client.api.sdStyleControllerList({ mode: SDStyleFilterMode.Owned, ids: _variables.ids }).then(res => res.data),
})

const FormDetailStyleComponent: FC<FormDetaiStyleComponentProps> = props => {
  const {
    isLoading,
    flattenData = [],
    hasNextPage,
    isFetching,
    fetchNextPage,
  } = useWorkspaceStyleInfiniteQuery({
    variables: {
      mode: SDStyleFilterMode.Owned,
    },
  })

  const { styleIntensity, value } = props
  const inputRef = useRef<HTMLInputElement>(null)
  const isFetchedRef = useRef(false)

  const { flattenData: initStyle = [] } = useWorkspaceStyleInfiniteQuery({
    variables: {
      ids: [value],
      mode: SDStyleFilterMode.Owned,
    },
    enabled: !!value && !isFetchedRef.current,
  })

  useEffect(() => {
    if (value && isFetchedRef.current) {
      isFetchedRef.current = false
    }
  }, [value])

  const initialData = useMemo(
    () =>
      _uniqBy(
        [
          ...flattenData.map(m => ({ ...m, name: m.name || m.id.slice(0, 8) })),
          ...initStyle.map(i => ({ ...i, name: i.name || i.id.slice(0, 8) })),
        ],
        r => r.id,
      ),
    [flattenData, initStyle],
  )

  const isOpenModal = useCreateNewStyleStore(state => state.isOpen)

  const renderSelector: RenderSelectorFn = renderProps => {
    const { input, value, data, clearable, onChange } = renderProps
    const item = data.find(i => i.id === value)

    return (
      <div>
        {renderSelectorAsSelect(renderProps)}
        {item && (
          <div className="w-full cursor-pointer flex h-12 items-center relative mt-4">
            <ImageView
              className="!h-12 w-12 rounded-lg overflow-hidden"
              imgClassName="w-12 object-cover"
              url={item.thumbnailUrl ?? ""}
            />

            <div className="pl-2 pr-0 flex-1 w-[calc(100%-3rem)] flex flex-col gap-2">
              <div className="flex gap-2 items-center">
                <p className="text-atherGray-300 text-xs flex-1 truncate" title={item.name}>
                  {item.name}
                </p>
                <XIcon
                  className="flex-0 text-red-500 w-3 h-3 cursor-pointer"
                  onClick={() =>
                    onChange({
                      key: input.key,
                      value: "",
                      clearTriggerKey: input.clearTriggerKey,
                      checked: false,
                    })
                  }
                />
              </div>
              <div
                className={css({
                  position: "relative",
                  maxWidth: "24rem",
                  ".track-slider-0": {
                    backgroundColor: "#5E17EB",
                  },
                  ".track-slider-1": {
                    backgroundColor: "#E2E8F0",
                  },
                })}
              >
                <div className={classNames("relative items-center flex w-full")}>
                  <ReactSlider
                    value={styleIntensity}
                    onChange={v => {
                      onChange({
                        key: `${input.key}Intensity`,
                        value: v,
                      })
                    }}
                    min={0}
                    max={1}
                    step={0.01}
                    className="h-1 horizontal-slider w-full"
                    thumbClassName="h-4 w-4 bg-white top-1/2 -translate-y-1/2 rounded-full cursor-pointer"
                    trackClassName="h-1 track-slider"
                    renderThumb={(props, state) => (
                      <div {...props}>
                        <div className="bg-atherPurple-500 w-2.5 h-2.5 absolute top-1/2 left-1/2 -translate-x-[50%] -translate-y-[50%] rounded-full" />
                      </div>
                    )}
                  />
                  <div className="py-0.5 w-[4rem] flex justify-center bg-atherGray-800 ml-2 rounded-md items-center">
                    <input
                      ref={inputRef}
                      className="bg-transparent w-full text-center !text-xs !font-normal"
                      value={styleIntensity ?? 0.5}
                      type="number"
                      min={0}
                      max={1}
                      step={0.01}
                      onClick={e => inputRef.current?.select()}
                      onChange={e => {
                        const valueFormat = Math.max(0, Math.min(1, Number(e.target.value)))
                        onChange({ key: `${input.key}Intensity`, value: valueFormat as any })
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }

  return (
    <FormDetailThumbnailSelectComponent
      {...props}
      onChange={value => {
        isFetchedRef.current = true
        props.onChange(value)
      }}
      hasNextPage={hasNextPage}
      fetchNextPage={fetchNextPage}
      isFetching={isFetching}
      data={initialData.map(i => ({ ...i, thumbnailUrl: i.thumbnailUrl ?? undefined }))}
      renderSelector={renderSelector}
      isLoadingData={isLoading}
      modalOpened={isOpenModal}
    />
  )
}

export default FormDetailStyleComponent
