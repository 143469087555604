import { ContentModerationRating, SDWorkflowDetail } from "@/api/sdk"
import UserCreated from "@/components/Explore/Gallery/UserCreated"
import IconButton from "@/components/IconButton"
import ImageWebpComponent from "@/components/ImageWebpComponent"
import ComfyUIItemList from "@/components/Workspace/RecentV2/RecentComfyUI/ComfyUIItemList"
import { FlowSquareIcon, PinIcon, TickIcon } from "@/components/shared/icons"
import { googleAnalytics } from "@/lib/gtag"
import { cn } from "@/lib/utils"
import classNames from "classnames"
import Link from "next/link"
import { useInView } from "react-intersection-observer"
import ActionButtonsComfyUI from "./ActionButtonsComfyUI"
import { useState } from "react"
import NsfwBlurImage from "@/components/NsfwBlurImage"
import AspectRatio from "@/components/AspectRatio"

interface ComfyUIRecipeCardProps {
  workflow: SDWorkflowDetail & { pinned?: boolean }
  accessedAt?: string
  href: string
  selectionMode?: boolean
  isChecked?: boolean
  hiddenMenu?: boolean
  onClick?: () => void
  onCheck?: () => void
  layout?: "grid" | "list"
  onRef?: (ref: HTMLDivElement | null) => void
}

const ComfyUIRecipeCard = ({
  workflow: recipe,
  onClick,
  href,
  selectionMode,
  layout = "grid",
  onRef,
  isChecked,
  onCheck,
  hiddenMenu,
  accessedAt,
}: ComfyUIRecipeCardProps) => {
  const { thumbnailUrl, name, description, thumbnailModerationRating } = recipe
  const [isShow, setIsShow] = useState(
    thumbnailModerationRating === ContentModerationRating.HardcoreNsfw ||
      thumbnailModerationRating === ContentModerationRating.SemiNsfw,
  )

  const { ref, inView } = useInView({
    threshold: 0,
    rootMargin: "800px 0px 800px 0px",
  })

  const handleClick = e => {
    if (selectionMode) {
      e.preventDefault()
      e.stopPropagation()
      onCheck?.()

      return
    }

    if (onClick) {
      e.preventDefault()
      e.stopPropagation()
      onClick()
    }

    googleAnalytics.event({
      action: "click",
      category: "comfyui_workflows",
      label: `view_comfyui_macro_detail`,
      params: {
        comfyui_id: recipe.id,
        comfyui_name: recipe.name ?? "",
        pathname: window.location.pathname,
      },
    })
  }

  return (
    <div
      ref={r => {
        ref(r)
        onRef?.(r)
      }}
      className={"group cursor-pointer shrink relative recipe-item"}
    >
      {layout === "grid" ? (
        <div className="w-full h-full">
          <Link onClick={handleClick} href={href} className="h-full flex flex-col" shallow>
            <div className="relative group overflow-hidden rounded-2xl transform-gpu bg-atherGray-900 shadow-sm shadow-whiteAlpha-200">
              {selectionMode && (
                <div
                  className={classNames(
                    "absolute w-full h-full top-0 left-0 border-[2px] pointer-events-none rounded-2xl",
                    {
                      "border-atherPurple-500": isChecked,
                      "border-[transparent]": !isChecked,
                      "z-0": !selectionMode,
                      "z-[1]": selectionMode,
                    },
                  )}
                />
              )}

              <AspectRatio
                ratio={197 / 96}
                className={"relative select-none overflow-hidden flex items-center justify-center"}
              >
                {inView &&
                  (thumbnailUrl ? (
                    <ImageWebpComponent
                      src={thumbnailUrl}
                      alt={name}
                      className={classNames("h-full w-full rounded-xl object-cover", {
                        "blur-2xl": isShow,
                      })}
                    />
                  ) : (
                    <div className="flex h-full w-full items-center justify-center">
                      <FlowSquareIcon className="text-atherGray-800" />
                    </div>
                  ))}
              </AspectRatio>

              {inView &&
                isShow &&
                (thumbnailModerationRating === ContentModerationRating.HardcoreNsfw ||
                  thumbnailModerationRating === ContentModerationRating.SemiNsfw) && (
                  <NsfwBlurImage
                    isShow={isShow}
                    moderationRating={thumbnailModerationRating}
                    onToggleShow={setIsShow}
                    gaEvent={{
                      tab_name: "comfyui",
                      params: {
                        action: "Toggle Image NSFW",
                        comfyui_id: recipe.id,
                        comfyui_name: recipe.name ?? "",
                      },
                    }}
                  />
                )}

              {inView && (
                <>
                  <div
                    className={classNames(
                      "absolute bottom-0 left-0 transition-all pointer-events-none hidden lg:block group-hover:bg-blackAlpha-400 group-hover:h-full group-hover:w-full",
                      {
                        "h-full w-full bg-blackAlpha-600": selectionMode,
                      },
                    )}
                  />
                  {!hiddenMenu && recipe.discoverableAt && (
                    <div className="absolute flex items-center top-2 left-0 z-[1]">
                      {recipe.discoverableAt && (
                        <span className="font-semibold bg-atherPurple-500 px-1.5 py-0.5 rounded-r-lg text-[0.65rem]">
                          E
                        </span>
                      )}
                      {!isShow &&
                        inView &&
                        (thumbnailModerationRating === ContentModerationRating.HardcoreNsfw ||
                          thumbnailModerationRating === ContentModerationRating.SemiNsfw) && (
                          <NsfwBlurImage
                            buttonCloseClassName="relative top-auto left-auto ml-1 px-1 py-0.5 min-h-5"
                            isShow={isShow}
                            size={14}
                            moderationRating={thumbnailModerationRating}
                            onToggleShow={setIsShow}
                            gaEvent={{
                              tab_name: "comfyui",
                              params: {
                                action: "Toggle Image NSFW",
                                comfyui_id: recipe.id,
                                comfyui_name: recipe.name ?? "",
                              },
                            }}
                          />
                        )}
                    </div>
                  )}
                </>
              )}
            </div>
            <div className="p-2 flex flex-col flex-1 overflow-hidden">
              <div className="flex overflow-hidden mb-1">
                <p className="flex-1 line-clamp-1 font-semibold break-all text-sm">{recipe.name}</p>
                {recipe.pinned !== undefined && recipe.pinned && (
                  <div className="p-0.5">
                    <PinIcon width={16} height={16} />
                  </div>
                )}
              </div>
              <p className="text-atherGray-300 text-sm line-clamp-1 mb-2">{description}</p>
              <div className="mt-auto">
                <UserCreated size="xs" name={recipe.creator?.name} picture={recipe.creator?.picture} />
              </div>
            </div>
          </Link>

          {!hiddenMenu &&
            (selectionMode ? (
              <IconButton
                onClick={e => {
                  e.stopPropagation()
                  e.preventDefault()
                  onCheck?.()
                }}
                className={cn(
                  "absolute top-2 right-2 shadow-sm shadow-blackAlpha-500 bg-atherGray-700 p-0 w-7 h-7 min-h-0 rounded-full",
                  {
                    "text-white bg-atherPurple-500 hover:bg-atherPurple-400": isChecked,
                    "text-atherGray-300 hover:bg-atherGray-600": !isChecked,
                  },
                )}
              >
                <TickIcon />
              </IconButton>
            ) : (
              <ActionButtonsComfyUI recipe={recipe} />
            ))}
        </div>
      ) : (
        <ComfyUIItemList
          workflow={recipe}
          accessedAt={accessedAt}
          hiddenMenu={hiddenMenu}
          onClick={onClick}
          href={href}
        />
      )}
    </div>
  )
}

export default ComfyUIRecipeCard
