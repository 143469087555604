import IconButton from "@/components/IconButton"
import { FavoriteIcon, FavoriteFillIcon } from "@/components/shared/icons"
import { googleAnalytics } from "@/lib/gtag"
import { useAuth } from "@/providers/authContext"
import { useFavoriteTaskMutation } from "@/queries"
import { cn } from "@/utils/cn"
import React from "react"

interface WorkflowReactionProps {
  className?: string
  workflow: {
    id: string
    favoritedByUser: boolean
    name?: string
    metric: {
      favoriteCount: number
    } | null
  }
}

const WorkflowReaction = ({ className, workflow }: WorkflowReactionProps) => {
  const { mutate: mutateFavoriteWorkflow } = useFavoriteTaskMutation()
  const { user, handleSignIn } = useAuth()

  return (
    <IconButton
      onClick={async e => {
        e.stopPropagation()
        e.preventDefault()

        const isSignedIn = await handleSignIn()

        if (!isSignedIn) return

        googleAnalytics.handleCategoryEvent({
          action: "click",
          params: {
            action: "Favorite macro",
            tab_name: "macros",
            macro_id: workflow.id,
            macro_name: workflow?.name ?? "",
          },
        })

        mutateFavoriteWorkflow({
          id: workflow.id,
          userUid: user?.uid,
        })
      }}
      className={cn("flex items-center p-1 text-sm bg-transparent", className)}
      colorScheme="secondary"
    >
      {!workflow.favoritedByUser ? (
        <FavoriteIcon className="text-atherGray-300" />
      ) : (
        <FavoriteFillIcon className="text-atherGray-300" />
      )}
      <p className="text-atherGray-300">{Math.max(workflow.metric?.favoriteCount || 0, 0)}</p>
    </IconButton>
  )
}

export default WorkflowReaction
