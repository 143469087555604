import { create } from "zustand"
import { persist, createJSONStorage } from "zustand/middleware"

interface ClientStore {
  isOpenSidebar: boolean
  setIsOpenSidebar: (isOpenSidebar: boolean) => void
  toggleSidebar: () => void
  isMenuOpen: boolean
  setIsMenuOpen: (isMenuOpen: boolean) => void
}

const useClientStore = create(
  persist<ClientStore>(
    set => ({
      isOpenSidebar: true,
      setIsOpenSidebar: isOpenSidebar => set({ isOpenSidebar }),
      toggleSidebar: () => set(state => ({ isOpenSidebar: !state.isOpenSidebar })),
      isMenuOpen: false,
      setIsMenuOpen: isMenuOpen => set({ isMenuOpen }),
    }),
    {
      name: "gaia",
      storage: createJSONStorage(() => localStorage),
    },
  ),
)

export default useClientStore
