import { RefObject, useEffect } from "react"

export const useOutsideClickSimple = <T extends HTMLElement>(
  ref: RefObject<T>,
  callback: () => void,
  enabled = true,
) => {
  useEffect(() => {
    if (!enabled) return

    const handleClick = e => {
      if (!ref.current || ref.current.contains(e.target)) {
        return
      }

      callback()
    }

    document.addEventListener("mousedown", handleClick)
    document.addEventListener("touchstart", handleClick)

    return () => {
      document.removeEventListener("mousedown", handleClick)
      document.removeEventListener("touchstart", handleClick)
    }
  }, [ref, callback, enabled])
}
