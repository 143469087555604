import { encode } from "blurhash"

const MAX_DIMENSION = 400

export const getBlurHash = (file: File, width = MAX_DIMENSION): Promise<string> => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    const canvas = document.createElement("canvas")
    const context = canvas.getContext("2d", { alpha: false })

    img.onload = () => {
      const aspectRatio = img.width / img.height
      const newWidth = img.width < width ? img.width : width
      const newHeight = Math.round(newWidth * aspectRatio)

      canvas.width = newWidth
      canvas.height = newHeight
      context?.drawImage(img, 0, 0, newWidth, newHeight)
      const imageData = context?.getImageData(0, 0, newWidth, newHeight)
      if (imageData) {
        const blurHash = encode(imageData.data, newWidth, newHeight, 4, 4)

        resolve(blurHash)
      }
    }

    img.onerror = reject
    img.src = URL.createObjectURL(file)
  })
}
