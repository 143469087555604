import { IconProps } from "."

export const VideoIcon = (props: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 16 14" fill="none" {...props}>
      <path d="M7.33398 4.33594L8.66732 4.33594" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
      <path
        d="M1.33398 6.33464C1.33398 4.13475 1.33398 3.0348 2.0174 2.35139C2.70082 1.66797 3.80076 1.66797 6.00065 1.66797H6.66732C8.86721 1.66797 9.96715 1.66797 10.6506 2.35139C11.334 3.0348 11.334 4.13475 11.334 6.33464V7.66797C11.334 9.86786 11.334 10.9678 10.6506 11.6512C9.96715 12.3346 8.86721 12.3346 6.66732 12.3346H6.00065C3.80076 12.3346 2.70082 12.3346 2.0174 11.6512C1.33398 10.9678 1.33398 9.86786 1.33398 7.66797V6.33464Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M11.334 4.93984L11.4179 4.87058C12.8284 3.70676 13.5337 3.12485 14.1005 3.40582C14.6673 3.68679 14.6673 4.6183 14.6673 6.48133V7.52388C14.6673 9.38691 14.6673 10.3184 14.1005 10.5994C13.5337 10.8804 12.8284 10.2985 11.4179 9.13463L11.334 9.06537"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  )
}
