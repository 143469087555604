import client from "@/api/client"
import { CreateFolderDto } from "@/api/sdk"
import { createMutation } from "react-query-kit"
import { actionMiddleware } from "../../middlewares/actionMiddleware"
import { useFolderInfoQuery } from "./useFolderInfoQuery"
import { useSubFoldersInfiniteQuery, useSubFoldersQuery } from "./useSubFoldersInfiniteQuery"

export const useCreateFolderMutation = createMutation({
  mutationFn: ({ parentFolderId, name }: CreateFolderDto) =>
    client.api.folderControllerCreateFolder({ parentFolderId, name }).then(res => res.data),
  use: [
    actionMiddleware({
      onSettled({ queryClient, variables: { parentFolderId } }) {
        queryClient.invalidateQueries({
          queryKey: useSubFoldersInfiniteQuery.getKey({
            folderId: parentFolderId,
          }),
        })
        queryClient.invalidateQueries({
          queryKey: useSubFoldersQuery.getKey({
            folderId: parentFolderId,
          }),
        })
        queryClient.invalidateQueries({
          queryKey: useFolderInfoQuery.getKey({
            folderId: parentFolderId,
          }),
        })
      },
    }),
  ],
})
