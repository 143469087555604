import { RecentStyleItem as RecentStyleItemType } from "@/api/sdk"
import React, { memo } from "react"
import RecentAccordion from "../RecentAccordion"
import StyleItem from "@/components/Tools/Style/StyleItem"
import useCustomRouter from "@/hooks/useCustomRouter"
import { useAuth } from "@/providers/authContext"

interface RecentStyleItemProps {
  name: string
  recent: RecentStyleItemType[]
  containerWidth: number
  layout: "grid" | "list"
}

const RecentStyleItem = ({ name, containerWidth, recent, layout }: RecentStyleItemProps) => {
  const router = useCustomRouter()
  const { user } = useAuth()

  if (recent.length === 0) return null
  return (
    <RecentAccordion name={name} layout={layout}>
      {recent?.map(item => {
        return (
          <div key={item.id} className={layout === "grid" ? "h-[16rem]" : ""}>
            <StyleItem
              onClick={
                user?.uid === item.style.creator.uid
                  ? () => router.push(`/workspace/tools/styles/${item.style.id}`)
                  : undefined
              }
              containerWidth={containerWidth}
              accessedAt={item.accessedAt}
              layout={layout}
              stretch
              className="h-full"
              style={item.style}
            />
          </div>
        )
      })}
    </RecentAccordion>
  )
}

export default memo(RecentStyleItem)
