import client from "@/api/client"
import {
  ExploreRecipeItem,
  GetRecipesResult,
  PinnedItemType,
  PinnedItems,
  RecentRecipeItem,
  RecipeFilterMode,
} from "@/api/sdk"
import { InfiniteData } from "@tanstack/react-query"
import { createMutation } from "react-query-kit"
import { actionMiddleware } from "@/queries/middlewares/actionMiddleware"
import { useGetRecipesInfiniteQuery } from "./getRecipeQueries"
import { useExploreDetailRecipeQuery } from "@/queries/explore"
import { useRecentRecipesInfiniteQuery } from "@/components/Workspace/RecentV2/RecentRecipes"
import { useWorkspacePinInfiniteQuery } from "../useWorkspacePinInfiniteQuery"

interface RemoveRecipesMutationVariables {
  ids: string[]
  userUid?: string
  view?: RecipeFilterMode
}

export const useRemoveRecipesMutation = createMutation({
  mutationFn: async ({ ids }: RemoveRecipesMutationVariables) => {
    return client.api
      .recipeControllerRemoveRecipesFromLibrary({
        recipeIds: ids,
      })
      .then(res => res.data)
  },
  use: [
    actionMiddleware({
      onSuccess: ({ queryClient, variables: { ids, userUid, view } }) => {
        // Update recipe list
        const recipesListKey = useGetRecipesInfiniteQuery.getKey()
        const recipesListQueriesDataEntries = queryClient.getQueriesData<InfiniteData<GetRecipesResult, number>>({
          queryKey: recipesListKey,
        })

        recipesListQueriesDataEntries?.forEach(([key, data]) => {
          if (!data) return
          let updatedData

          updatedData = {
            ...data,
            pages: data.pages.map(page => {
              const updatedPage = {
                ...page,
                recipes: page.recipes.map(recipe => {
                  if (ids.includes(recipe.id)) {
                    return {
                      ...recipe,
                      capabilities: {
                        ...recipe.capabilities,
                        canAddToLibrary: true,
                        canRemoveFromLibrary: false,
                      },
                    }
                  }
                  return recipe
                }),
              }
              updatedPage.total = updatedPage.recipes.length
              return updatedPage
            }),
          }

          if (view !== RecipeFilterMode.Shared) {
            updatedData = {
              ...data,
              pages: data.pages.map(page => {
                const updatedPage = {
                  ...page,
                  recipes: page.recipes.filter(recipe => {
                    return !ids.includes(recipe.id)
                  }),
                }
                updatedPage.total = updatedPage.recipes.length
                return updatedPage
              }),
            }
          }

          queryClient.setQueryData(key, updatedData)
        })

        // Update recipes recent list
        const recipeRecentListKey = useRecentRecipesInfiniteQuery.getKey()

        const recipeRecentListQueriesDataEntries = queryClient.getQueriesData<InfiniteData<RecentRecipeItem[], number>>(
          {
            queryKey: recipeRecentListKey,
          },
        )

        if (recipeRecentListQueriesDataEntries) {
          recipeRecentListQueriesDataEntries.forEach(([key, recipeRecentListData]) => {
            if (recipeRecentListData) {
              const updatedData = {
                ...recipeRecentListData,
                pages: recipeRecentListData.pages.map(page => {
                  return [...page.filter(recent => !ids.includes(recent.recipe.id))]
                }),
              }

              queryClient.setQueryData(key, updatedData)
            }
          })
        }

        // Update recipe pinned list
        const recipePinnedListKey = useWorkspacePinInfiniteQuery.getKey({
          type: PinnedItemType.RECIPE,
        })

        const recipePinnedListQueriesDataEntries = queryClient.getQueriesData<InfiniteData<PinnedItems[], number>>({
          queryKey: recipePinnedListKey,
        })

        if (recipePinnedListQueriesDataEntries) {
          recipePinnedListQueriesDataEntries.forEach(([key, recipePinnedListData]) => {
            if (recipePinnedListData) {
              const updatedData = {
                ...recipePinnedListData,
                pages: recipePinnedListData.pages.map(page => {
                  return [...page.filter(item => !ids.includes(item.recipe?.id ?? ""))]
                }),
              }

              queryClient.setQueryData(key, updatedData)
            }
          })
        }

        const queryDetailKey = useExploreDetailRecipeQuery.getKey({
          recipeId: ids[0] ?? "",
          userUid,
        })

        // Update recipe detail explore
        const recipeDetailQueryData = queryClient.getQueryData<ExploreRecipeItem>(queryDetailKey)

        if (recipeDetailQueryData) {
          queryClient.setQueryData(queryDetailKey, {
            ...recipeDetailQueryData,
            capabilities: {
              ...recipeDetailQueryData.capabilities,
              canAddToLibrary: true,
              canRemoveFromLibrary: false,
            },
          })
        }
      },
    }),
  ],
})
