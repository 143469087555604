import { cdnUrl } from "@/constants"
import { cn } from "@/lib/utils"

const LoadingLogo = ({ className }: { className?: string }) => {
  return (
    <div className={cn("mix-blend-lighten transform-gpu w-[6rem]", className)}>
      <video preload="auto" autoPlay playsInline muted loop className={cn("h-full w-full")}>
        <source src={"/loading.mp4"} type="video/mp4" />
        <img
          src={`https://${cdnUrl}/resources/loading-op.gif`}
          className="block max-h-[10rem]"
          alt="Loading Logo"
          loading="lazy"
        />
      </video>
    </div>
  )
}

export default LoadingLogo
