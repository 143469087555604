import client from "@/api/client"
import { useAuth } from "@/providers/authContext"
import { useTempTaskStore } from "@/stores/useTempTaskStore"
import { useQuery } from "@tanstack/react-query"

const useGetListTask = ({ recipeId }: { recipeId: string }) => {
  const tempTask = useTempTaskStore(state => state.tempTask)

  const { user, userInfoQuery } = useAuth()

  const {
    data: onGoingRecipes,
    isLoading: isLoadingOnGoing,
    isError: isErrorOnGoing,
  } = useQuery({
    queryKey: ["recipe-ongoing", user?.uid, recipeId, tempTask?.map(t => t.id).length],
    queryFn: () =>
      client.api
        .recipeTaskControllerGetOngoingTasksPost({
          recipeIds: [recipeId],
          statuses: ["RUNNING", "QUEUED"],
          taskIds: !userInfoQuery?.data && tempTask ? tempTask.filter(i => !i.isTransfer).map(t => t.id) : undefined,
        })
        .then(res => res.data),
    enabled: userInfoQuery?.data ? !!recipeId : !!recipeId && !!(tempTask && tempTask.length > 0),
  })

  return {
    infoUser: userInfoQuery?.data,
    onGoingRecipes,
    isLoadingOnGoing,
    isErrorOnGoing,
  }
}

export default useGetListTask
