import { create } from "zustand"

interface SignInStore {
  signInModal: {
    signIn?: boolean
    signUp?: boolean
    forgotPassword?: boolean
    linkWallet?: boolean
    linkWalletEvm?: boolean
    newUser?: boolean
  }
  setSignInModal: (item: SignInStore["signInModal"]) => void
}

export const useSignInStore = create<SignInStore>(set => ({
  signInModal: {
    sinIn: false,
    signUp: false,
    forgotPassword: false,
    linkWallet: false,
    linkWalletEvm: false,
    newUser: false,
  },
  setSignInModal: signInModal => set({ signInModal }),
}))
