import { useState } from "react"
import classNames from "classnames"
import IconButton from "../../../IconButton"
import Popover from "../../../Popover"
import { PlusIcon } from "../../../shared/icons"
import { RecipeInputType } from "@/api/sdk"

export type AddInputPopoverProps = {
  onAddInput: (type: RecipeInputType) => void
}

export const workflowInputTypes = [
  { name: "Prompt", type: RecipeInputType.Prompt, dataTypes: ["string-multiline"] },
  { name: "Image", type: RecipeInputType.Image, dataTypes: ["image"] },
  { name: "Text", type: RecipeInputType.Text, dataTypes: ["string", "string-multiline"] },
  { name: "Select", type: RecipeInputType.Select, dataTypes: ["string", "select"] },
  { name: "Radio", type: RecipeInputType.Radio, dataTypes: ["string", "select", "number"] },
  { name: "Number", type: RecipeInputType.Number, dataTypes: ["number"] },
  { name: "Boolean", type: RecipeInputType.Boolean, dataTypes: ["boolean"] },
  { name: "Pose", type: RecipeInputType.Pose, dataTypes: ["image"] },
  { name: "Model", type: RecipeInputType.Model, dataTypes: ["model"] },
  { name: "Style", type: RecipeInputType.Style, dataTypes: ["style"] },
  { name: "Lora", type: RecipeInputType.Lora, dataTypes: ["lora"] },
  // { name: "Single Lora", type: RecipeInputType.LoraSimple, dataTypes: ["lora-simple"] },
  { name: "Aspect Ratio", type: RecipeInputType.Ratio, dataTypes: ["ratio"] },
]

const AddInputPopover: React.FC<AddInputPopoverProps> = ({ onAddInput }) => {
  const [showAddOptions, setShowAddOptions] = useState(false)

  return (
    <Popover
      isOpen={showAddOptions}
      setIsOpen={setShowAddOptions}
      trigger={
        <IconButton
          colorScheme="secondary"
          className="flex-1 max-w-[36rem]"
          leftIcon={<PlusIcon height={12} width={12} />}
        >
          Add Input
        </IconButton>
      }
      align="center"
      className="origin-top-right"
    >
      <div className="p-3 overflow-auto max-h-[15rem] rounded-xl bg-atherGray-850 border border-atherGray-800 flex w-[18rem] flex-col space-y-2">
        {workflowInputTypes.map(option => (
          <IconButton
            key={option.type}
            className={classNames(
              "flex items-center text-left text-atherGray-300 hover:bg-atherGray-800 py-2 hover:text-atherGray-0 justify-start px-4 active:bg-atherGray-800 bg-transparent w-full cursor-pointer rounded-xl",
            )}
            onClick={() => {
              setShowAddOptions(false)
              onAddInput(option.type)
            }}
          >
            {option.name}
          </IconButton>
        ))}
      </div>
    </Popover>
  )
}

export default AddInputPopover
