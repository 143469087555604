import client from "@/api/client"
import { actionMiddleware } from "@/queries/middlewares/actionMiddleware"
import { createMutation } from "react-query-kit"
import { useQuestInfiniteQuery } from "./useQuestInfiniteQuery"
import { InfiniteData } from "@tanstack/react-query"
import { GetUserQuestsResult, QuestStatus, QuestType } from "@/api/sdk"

export const useClaimQuestMutation = createMutation({
  mutationFn: async ({ questId }: { questId: number }) => {
    return await client.api.questControllerClaimQuest(questId).then(res => res.data)
  },
  use: [
    actionMiddleware({
      onSuccess: ({ queryClient, variables }) => {
        const listKey = useQuestInfiniteQuery.getKey()

        const questsQueriesDataEntries = queryClient.getQueriesData<InfiniteData<GetUserQuestsResult, number>>({
          queryKey: listKey,
        })

        if (questsQueriesDataEntries) {
          questsQueriesDataEntries.forEach(([key, parentQuestData]) => {
            if (parentQuestData) {
              const updatedData = {
                ...parentQuestData,
                pages: parentQuestData.pages.map(page => {
                  return {
                    ...page,
                    quests: page.quests.map(quest => {
                      if (quest.id === variables.questId) {
                        return {
                          ...quest,
                          status: QuestStatus.Completed,
                          streak: quest.type === QuestType.DailyCheckin ? quest.streak + 1 : 0,
                        }
                      }
                      return quest
                    }),
                  }
                }),
              }
              queryClient.setQueryData(key, updatedData)
            }
          })
        }
        queryClient.invalidateQueries({ queryKey: listKey })
        queryClient.invalidateQueries({ queryKey: ["user-info"] })
      },
    }),
  ],
})
