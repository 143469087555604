import client from "@/api/client"
import { actionMiddleware } from "@/queries/middlewares/actionMiddleware"
import { createMutation } from "react-query-kit"
import { useWorkspaceRoomRecipeTasksInfiniteQuery } from "./useWorkspaceRoomRecipeTasksInfiniteQuery"
import { InfiniteData } from "@tanstack/react-query"
import { RecipeTaskItem } from "@/api/sdk"

export const useFavoriteRoomTaskMutation = createMutation({
  mutationFn: async ({ recipeTaskId }: { recipeTaskId: string }) => {
    return await client.api.recipeTaskControllerToggleFavoriteRecipeTask(recipeTaskId).then(res => res.data)
  },
  use: [
    actionMiddleware({
      onSuccess: ({ queryClient, variables }) => {
        const tasksKey = useWorkspaceRoomRecipeTasksInfiniteQuery.getKey()

        const tasksEntities = queryClient.getQueriesData<InfiniteData<RecipeTaskItem[], number>>({ queryKey: tasksKey })

        tasksEntities?.forEach(([key, tasksEntityList]) => {
          if (tasksEntityList) {
            const updatedTasksEntitiesListData = {
              ...tasksEntityList,
              pages: tasksEntityList.pages.map(page => {
                return page.map(task => {
                  if (task.id === variables.recipeTaskId) {
                    return {
                      ...task,
                      favoritedByUser: !task.favoritedByUser,
                    }
                  }
                  return task
                })
              }),
            }

            queryClient.setQueryData(key, updatedTasksEntitiesListData)
          }
        })
      },
    }),
  ],
})
