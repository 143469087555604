import { ChevronDownIcon } from "@/components/shared/icons"
import { cn } from "@/utils/cn"
import { AnimatePresence, motion } from "framer-motion"
import { useState } from "react"

interface AccordionProps {
  title: string
  className?: string
  titleClassName?: string
  children: (props: { isOpen: boolean; setIsOpen: (open: boolean) => void }) => React.ReactElement
  action?: React.ReactNode
  disabledTitleClick?: boolean
  hiddenButton?: boolean
  isOpen?: boolean
  setIsOpen?: (open: boolean) => void
  defaultOpen?: boolean
}

const Accordion = ({
  title,
  className,
  children,
  disabledTitleClick,
  action,
  titleClassName,
  defaultOpen = false,
  hiddenButton,
  isOpen: propIsOpen,
  setIsOpen: propSetIsOpen,
}: AccordionProps) => {
  const [isOpen, setIsOpen] = useState(propIsOpen ?? defaultOpen)

  return (
    <div className={className}>
      <div
        className="flex items-center w-full text-atherGray-400 cursor-pointer"
        onClick={
          disabledTitleClick
            ? undefined
            : () => {
                setIsOpen(!isOpen)
                if (propSetIsOpen) {
                  propSetIsOpen(!isOpen)
                }
              }
        }
      >
        <div className="flex items-center">
          {!hiddenButton && (
            <span
              className={cn("transition-transform mr-2", {
                "-rotate-90": !isOpen,
              })}
            >
              <ChevronDownIcon width={16} height={16} />
            </span>
          )}
          <p className={cn("font-semibold", titleClassName)}>{title}</p>
        </div>
        {action && (
          <div
            onClick={
              disabledTitleClick
                ? e => {
                    e.stopPropagation()
                    setIsOpen(!isOpen)
                    if (propSetIsOpen) {
                      propSetIsOpen(!isOpen)
                    }
                  }
                : undefined
            }
            className="ml-auto"
          >
            {action}
          </div>
        )}
      </div>
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.div
            initial={{ height: 0 }}
            animate={{ height: "auto" }}
            exit={{ height: 0 }}
            className="overflow-hidden"
          >
            <div className="mt-2">
              {children({
                isOpen,
                setIsOpen,
              })}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default Accordion
