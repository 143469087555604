import { IconProps } from "."

export const BookmarkIcon1 = (props: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="16" viewBox="0 0 13 16" fill="none" {...props}>
      <path
        d="M1.16797 11.9869V6.47136C1.16797 4.04912 1.16797 2.838 1.94902 2.0855C2.73007 1.33301 3.98714 1.33301 6.5013 1.33301C9.01546 1.33301 10.2725 1.33301 11.0536 2.0855C11.8346 2.838 11.8346 4.04912 11.8346 6.47136V11.9869C11.8346 13.5241 11.8346 14.2927 11.3194 14.5679C10.3217 15.1006 8.4501 13.3231 7.5613 12.7879C7.04583 12.4776 6.7881 12.3224 6.5013 12.3224C6.21451 12.3224 5.95677 12.4776 5.4413 12.7879C4.5525 13.3231 2.68095 15.1006 1.6832 14.5679C1.16797 14.2927 1.16797 13.5241 1.16797 11.9869Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M1.16797 4.66602H11.8346" stroke="currentColor" strokeWidth="1.5" />
    </svg>
  )
}
