import { cdnPublicFolderUrl } from "@/constants"
import classNames from "classnames"
import { AnimatePresence, motion } from "framer-motion"
import React from "react"
import { Blurhash } from "react-blurhash"
import { twMerge } from "tailwind-merge"

interface SkeletonImageProps {
  className?: string
  isLoaded: boolean
  children: React.ReactNode
  blurHash?: string
}

const Skeleton = ({ className, isLoaded, children, blurHash }: SkeletonImageProps) => {
  return (
    <div
      className={twMerge(
        classNames(`relative`, {
          "cursor-not-allowed pointer-events-none overflow-hidden transform-gpu": !isLoaded,
        }),
        className,
      )}
    >
      {children}
      <AnimatePresence initial={false} mode="wait">
        {!isLoaded && (
          <motion.div
            className="absolute top-0 left-0 flex w-full h-full overflow-hidden"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            {!blurHash ? (
              <div className="flex flex-col items-center bg-atherGray-900 justify-center flex-1 h-full">
                <img className="w-[3rem]" src={`${cdnPublicFolderUrl}/not-found-image.png`} alt="skeleton" />
              </div>
            ) : (
              <Blurhash hash={blurHash} width={"100%"} height="100%" punch={1} />
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default Skeleton
