import client from "@/api/client"
import { useOutsideClick } from "@/hooks"
import { googleAnalytics } from "@/lib/gtag"
import { InfiniteData, useMutation, useQueryClient } from "@tanstack/react-query"
import { AnimatePresence, motion } from "framer-motion"
import { useRef, useState } from "react"
import { FaEllipsisV } from "react-icons/fa"
import IconButton from "../IconButton"
import { ThreeDotHorizontalIcon } from "../shared/icons"
import { GetNotificationsResponse } from "@/api/sdk"

interface NotificationActionsProps {}

const NotificationActions = ({}: NotificationActionsProps) => {
  const qc = useQueryClient()
  const [isOpen, setIsOpen] = useState(false)

  const popUpRef = useRef<HTMLDivElement>(null)
  useOutsideClick({
    enabled: isOpen,
    ref: popUpRef,
    handler: () => {
      setIsOpen(false)
    },
  })

  const { mutate: mutateReadAll } = useMutation({
    mutationFn: () => client.api.notificationControllerReadNotifications(),
    onMutate: async () => {
      await qc.cancelQueries({ queryKey: ["notifications"] })

      const previousNotifications = qc.getQueriesData<InfiniteData<GetNotificationsResponse, number>>({
        queryKey: ["notifications"],
      })

      if (previousNotifications) {
        previousNotifications.forEach(([key, data]) => {
          if (data) {
            qc.setQueryData(key, {
              ...data,
              pages: data.pages.map(page => ({
                ...page,
                notifications: page.notifications.map(notification => ({
                  ...notification,
                  status: {
                    ...notification.status,
                    isRead: true,
                  },
                })),
              })),
            })
          }
        })
      }
    },
    onSuccess: () => {
      qc.invalidateQueries({ queryKey: ["notifications"] })
    },
  })

  const { mutate: mutateClearAll } = useMutation({
    mutationFn: () => client.api.notificationControllerDeleteNotifications(),
    onMutate: async () => {
      await qc.cancelQueries({ queryKey: ["notifications"] })

      const previousNotifications = qc.getQueriesData<InfiniteData<GetNotificationsResponse, number>>({
        queryKey: ["notifications"],
      })

      if (previousNotifications) {
        previousNotifications.forEach(([key, data]) => {
          if (data) {
            qc.setQueryData(key, {
              ...data,
              pages: [],
            })
          }
        })
      }
    },
    onSuccess: () => {
      qc.invalidateQueries({ queryKey: ["notifications"] })
    },
  })

  return (
    <div className="relative" ref={popUpRef}>
      <IconButton colorScheme="transparent" onClick={() => setIsOpen(!isOpen)}>
        <ThreeDotHorizontalIcon className="w-4 h-4" />
      </IconButton>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0.5, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0.5, height: 0 }}
            className="absolute text-sm top-full mt-1 overflow-hidden right-0 w-[6rem] z-50 bg-atherGray-850 rounded-lg"
          >
            <div className="p-2">
              <button
                type="button"
                className="p-2  w-full text-left text-atherGray-300 hover:text-white hover:font-semibold hover:bg-whiteAlpha-100 rounded-md"
                onClick={() => {
                  googleAnalytics.event({
                    action: "click",
                    category: "notification",
                    label: `read_all`,
                    value: 1,
                  })
                  mutateReadAll()
                  setIsOpen(false)
                }}
              >
                Read All
              </button>
              <button
                type="button"
                className="p-2  w-full text-left text-atherGray-300 hover:text-white hover:font-semibold hover:bg-whiteAlpha-100 rounded-md"
                onClick={() => {
                  googleAnalytics.event({
                    action: "click",
                    category: "notification",
                    label: `clear_all`,
                    value: 1,
                  })
                  mutateClearAll()
                  setIsOpen(false)
                }}
              >
                Clear All
              </button>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default NotificationActions
