import client from "@/api/client"
import { ChatRoomItem } from "@/api/sdk"
import { createAuthenticatedQuery } from "@/queries/createAuthenticatedQuery"
import { enableQueryMiddleware } from "@/queries/middlewares/enableQueryMiddleware"

export const useWorkspaceDetailRoomQuery = createAuthenticatedQuery<
  ChatRoomItem,
  { roomId: string; search?: string },
  Error
>({
  primaryKey: "workspace-detail-room",
  queryFn: ({ queryKey: [_primaryKey, variables] }) =>
    client.api.chatRoomControllerGetChatRoomById(variables.roomId).then(res => res.data),
  use: [enableQueryMiddleware(i => !!i.roomId)],
})
