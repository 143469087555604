import { IconProps } from "."

export const BlenderIcon = (props: IconProps) => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M18.7976 4H8.54827M18.7976 4L17.7726 17H9.33496L9.06073 11M18.7976 4H20.335M8.54827 4H6.69115C5.42952 4 4.7987 4 4.49132 4.40307C4.18395 4.80614 4.36522 5.39566 4.72775 6.5747L5.00282 7.4693C5.23524 8.22521 5.35146 8.60316 5.59681 8.90436C5.84216 9.20555 6.19332 9.40133 6.89561 9.79288L9.06073 11M8.54827 4L9.06073 11"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M15.335 2H12.335" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M17.8018 17H9.38775C9.0951 17.9995 7.79139 20.7076 8.59055 21.634C8.90631 22 9.50019 22 10.688 22H16.8675C18.1628 22 18.8105 22 19.1261 21.5969C19.9183 20.5851 18.2323 17.9653 17.8018 17Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.335 8H15.835M17.835 11H15.835M17.835 14H15.835"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
