import { IconProps } from "."

export const UserAddIcon = (props: IconProps) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_6501_162367)">
        <path
          d="M8.33203 14.6666H4.39261C3.36236 14.6666 2.5429 14.1653 1.80714 13.4644C0.300941 12.0294 2.7739 10.8826 3.71708 10.321C5.11761 9.4871 6.75659 9.18124 8.33203 9.40344C8.9037 9.48407 9.46045 9.63423 9.9987 9.85392"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11 4.33331C11 5.99017 9.65685 7.33331 8 7.33331C6.34315 7.33331 5 5.99017 5 4.33331C5 2.67646 6.34315 1.33331 8 1.33331C9.65685 1.33331 11 2.67646 11 4.33331Z"
          stroke="currentColor"
          strokeWidth="1.5"
        />
        <path
          d="M12.3333 14.6667L12.3333 10M10 12.3333H14.6667"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_6501_162367">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
