import classNames from "classnames"
import GalleryFilter from "./GalleryFilter"
import { ExploreGalleryTabType } from "../GalleryInspiration"
import { useEffect, useRef, useState } from "react"
import { useScreen } from "@/hooks"
import GalleryActionsV2 from "./GalleryActionsV2"
import GalleryTab from "./GalleryTab"
import { useRouter } from "next/router"
import { useExploreContext } from "@/providers/ExploreProvider"
import IconButton from "@/components/IconButton"
import { ArrowDownIcon, ArrowSquareLeftIcon, SearchIcon, XIcon } from "@/components/shared/icons"

const GalleryHeader = () => {
  const router = useRouter()
  const {
    category,
    isBookmarked,
    selectTags,
    setCategory,
    setIsBookmarked,
    setSelectTags,
    setSort,
    sort,
    createdBy,
    setCreatedBy,
  } = useExploreContext()
  const tab = (router.pathname.split("/")[2] as ExploreGalleryTabType) || "images"
  const [isHidden, setIsHidden] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)
  const currentScroll = useRef(0)
  const { width } = useScreen()
  const isMobileOnly = width < 768
  const [isActiveButton, setIsActiveButton] = useState(false)

  useEffect(() => {
    const handleScroll = e => {
      if (isDisabled) return

      //check if the scroll is at the top

      if (window.scrollY < 100 && isMobileOnly) {
        setIsHidden(false)
        setIsActiveButton(false)
        return
      }

      if (currentScroll.current > 100 && window.scrollY > currentScroll.current) {
        setIsHidden(true)
        setIsActiveButton(true)
      } else {
        if (isHidden && isMobileOnly) {
          return
        }
        setIsHidden(false)
      }

      currentScroll.current = window.scrollY
    }

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [isDisabled, isHidden, isMobileOnly])

  return (
    <>
      <div
        className={classNames(
          "w-full bg-black transition-transform items-center ease-in-out duration-300 flex-0 flex flex-col z-[3] md:justify-between px-2 py-2 sticky top-0 md:top-[4.45rem] left-0",
          {
            "-translate-y-full": isHidden,
          },
        )}
      >
        <div className="md:px-12 w-full max-w-[85rem] 2xl:max-w-[105rem] 3xl:max-w-[135rem] 5xl:max-w-[155rem]">
          <div className="relative flex flex-col w-full">
            <GalleryTab tab={tab} />
            <div className="flex items-center w-full relative space-x-4">
              <GalleryActionsV2
                onOpen={open => setIsDisabled(open)}
                onFocus={focus => {
                  setIsDisabled(focus)

                  if (focus) {
                    setIsHidden(false)
                  }
                }}
              />
              <GalleryFilter
                tab={tab}
                setSelectTags={setSelectTags}
                setCreatedBy={setCreatedBy}
                createdBy={createdBy}
                setCategory={setCategory}
                setSort={setSort}
                setIsBookmarked={setIsBookmarked}
                isBookmarked={isBookmarked}
                selectTags={selectTags}
                category={category}
                sort={sort}
              />
            </div>
          </div>
        </div>
      </div>
      <IconButton
        onClick={() => {
          setIsHidden(prev => !prev)
        }}
        colorScheme="primary"
        className={classNames("flex md:hidden w-[2rem] h-[2rem] p-0 rounded-xl fixed top-2 right-2 z-[1]", {
          "top-[9rem]": !isHidden,
          hidden: !isActiveButton,
        })}
      >
        {isHidden ? <SearchIcon width={20} height={20} /> : <XIcon width={16} height={16} />}
      </IconButton>
    </>
  )
}

export default GalleryHeader
