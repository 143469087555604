import { IconProps } from "."

export const TagIcon = (props: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none" {...props}>
      <circle
        cx="1.5"
        cy="1.5"
        r="1.5"
        transform="matrix(1 0 0 -1 15 7)"
        stroke="#C8C4B5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.77423 10.1439C0.771085 11.2643 0.749503 12.9546 1.67016 14.1437C3.49711 16.5033 5.49674 18.5029 7.85633 20.3298C9.0454 21.2505 10.7357 21.2289 11.8561 20.2258C14.8979 17.5022 17.6835 14.6559 20.3719 11.5279C20.6377 11.2187 20.8039 10.8397 20.8412 10.4336C21.0062 8.63798 21.3452 3.46467 19.9403 2.05974C18.5353 0.654811 13.362 0.993766 11.5664 1.15876C11.1603 1.19608 10.7813 1.36233 10.472 1.62811C7.34412 4.31646 4.49781 7.10211 1.77423 10.1439Z"
        stroke="#C8C4B5"
        strokeWidth="1.5"
      />
      <path d="M6 13L9 16" stroke="#C8C4B5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
