import client from "@/api/client"
import { actionMiddleware } from "@/queries/middlewares/actionMiddleware"
import { createMutation } from "react-query-kit"
import {
  UpdateWildcardDto,
  SearchWildcardResponse,
  WildcardDetail,
  RecentWildcardItem,
  PinnedItemType,
  PinnedItems,
} from "@/api/sdk"
import { useWorkspaceWildcardsInfiniteQuery } from "./useWorkspaceWildcardsInfiniteQuery"
import { InfiniteData } from "@tanstack/react-query"
import { useWorkspaceWildcardDetailQuery } from "./useWorkspaceWildcardDetailQuery"
import { useRecentWildcardsInfiniteQuery } from "@/components/Workspace/RecentV2/RecentWildcards"
import { useWorkspacePinInfiniteQuery } from "@/queries/workspace"

export interface UseRenameWildcardMutationVariables {
  wildcardId: string
  data: UpdateWildcardDto
}

export const useUpdateWildcardMutation = createMutation({
  mutationFn: async (props: UseRenameWildcardMutationVariables) => {
    return client.api.wildcardControllerSaveWildcard(props.wildcardId, props.data).then(res => res.data)
  },
  use: [
    actionMiddleware({
      onSuccess: ({ queryClient, variables }) => {
        // Update style list
        const wildcardListKey = useWorkspaceWildcardsInfiniteQuery.getKey()

        const wildcardListQueriesDataEntries = queryClient.getQueriesData<InfiniteData<SearchWildcardResponse, number>>(
          {
            queryKey: wildcardListKey,
          },
        )

        wildcardListQueriesDataEntries?.forEach(([key, data]) => {
          if (!data) return

          const updatedData = {
            ...data,
            pages: data.pages.map(page => {
              return {
                ...page,
                wildcards: page.wildcards.map(style => {
                  if (style.id === variables.wildcardId) {
                    return {
                      ...style,
                      name: variables.data.name ?? style.name,
                      wildcards: variables.data.wildcards ?? style.wildcards,
                    }
                  }

                  return style
                }),
              }
            }),
          }
          queryClient.setQueryData(key, updatedData)
        })

        // Update wildcard recent list
        const wildcardRecentKey = useRecentWildcardsInfiniteQuery.getKey()

        const wildcardsRecentListQueriesDataEntries = queryClient.getQueriesData<
          InfiniteData<RecentWildcardItem[], number>
        >({
          queryKey: wildcardRecentKey,
        })

        if (wildcardsRecentListQueriesDataEntries) {
          wildcardsRecentListQueriesDataEntries.forEach(([key, wildcardsRecentListData]) => {
            if (wildcardsRecentListData) {
              const updatedData = {
                ...wildcardsRecentListData,
                pages: wildcardsRecentListData.pages.map(page => {
                  return page.map(item => ({
                    ...item,
                    wildcard:
                      item.wildcard.id === variables.wildcardId
                        ? { ...item.wildcard, name: variables.data.name }
                        : item.wildcard,
                  }))
                }),
              }

              queryClient.setQueryData(key, updatedData)
            }
          })
        }

        // Update wildcard pinned list
        const wildcardPinnedListKey = useWorkspacePinInfiniteQuery.getKey({
          type: PinnedItemType.WILDCARD,
        })

        const wildcardPinnedListQueriesDataEntries = queryClient.getQueriesData<InfiniteData<PinnedItems[], number>>({
          queryKey: wildcardPinnedListKey,
        })

        if (wildcardPinnedListQueriesDataEntries) {
          wildcardPinnedListQueriesDataEntries.forEach(([key, wildcardPinnedListData]) => {
            if (wildcardPinnedListData) {
              const updatedData = {
                ...wildcardPinnedListData,
                pages: wildcardPinnedListData.pages.map(page => {
                  return page.map(item => ({
                    ...item,
                    wildcard:
                      item.wildcard?.id === variables.wildcardId
                        ? { ...item.wildcard, name: variables.data.name }
                        : item.wildcard,
                  }))
                }),
              }

              queryClient.setQueryData(key, updatedData)
            }
          })
        }

        //update wildcard detail
        const wildcardDetailKey = useWorkspaceWildcardDetailQuery.getKey({
          wildcardId: variables.wildcardId,
        })

        const wildcardDetailKeyQueriesDataEntries = queryClient.getQueriesData<WildcardDetail>({
          queryKey: wildcardDetailKey,
        })

        wildcardDetailKeyQueriesDataEntries?.forEach(([key, data]) => {
          if (!data) return

          const updatedData = {
            ...data,
            name: variables.data.name ?? data.name,
            wildcards: variables.data.wildcards ?? data.wildcards,
          }

          queryClient.setQueryData(key, updatedData)
        })
      },
    }),
  ],
})
