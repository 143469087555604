import { IconProps } from "."

export const AddSquareIcon = (props: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
      <path
        d="M1.08398 9.00065C1.08398 5.2687 1.08398 3.40272 2.24335 2.24335C3.40272 1.08398 5.2687 1.08398 9.00065 1.08398C12.7326 1.08398 14.5986 1.08398 15.7579 2.24335C16.9173 3.40272 16.9173 5.2687 16.9173 9.00065C16.9173 12.7326 16.9173 14.5986 15.7579 15.7579C14.5986 16.9173 12.7326 16.9173 9.00065 16.9173C5.2687 16.9173 3.40272 16.9173 2.24335 15.7579C1.08398 14.5986 1.08398 12.7326 1.08398 9.00065Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M9.0013 5.66797V12.3346M12.3346 9.0013L5.66797 9.0013"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
