import { FeedbackRating, RecipeTaskStatus, RecipeTaskWithFullImages } from "@/api/sdk"
import React, { Fragment, memo } from "react"
import { AnimatePresence, motion } from "framer-motion"
import ResultImages from "./ResultImages"
import ProgressbarTask from "@/components/Workspace/Recipes/RecipeDetail/ProgressbarTask"
import { convertInfinityToZero } from "@/components/Workspace/Workflows/WorkflowDetailStep/WorkFlowDetailStep"
import { TaskProgressSocketEvent } from "@/providers/ProgressingProvider"
import IconButton from "@/components/IconButton"
import { useCancelTaskMutation } from "@/queries"
import { useToast } from "@/hooks"
import classNames from "classnames"
import { DeleteIcon, ThumbUpFillIcon, ThumbUpIcon, TickIcon, XIcon } from "@/components/shared/icons"
import { useDeleteItemStore } from "@/stores"

interface AccordionRecipeProps {
  task: RecipeTaskWithFullImages
  containerWidth: number
  progressData?: TaskProgressSocketEvent
  index: number
  modal: string | null
  setModal: (modal: string | null) => void
  selectionMode: boolean
  isChecked?: boolean
  onCheck: (image: RecipeTaskWithFullImages) => void
}

const RecipeTask = ({
  task,
  isChecked,
  containerWidth,
  progressData,
  index,
  setModal,
  modal,
  onCheck,
  selectionMode,
}: AccordionRecipeProps) => {
  const toast = useToast()
  const setDeletingItem = useDeleteItemStore(state => state.setDeletingItem)

  let stepCompleted = ((progressData?.node ?? 1) - 1) / (progressData?.total_nodes ?? 1)
  const totalCurrentStepCompleted = stepCompleted * (1 / (progressData?.total_steps ?? 1))
  const totalPreviousStepsCompleted = ((progressData?.step ?? 1) - 1) / (progressData?.total_steps ?? 1)

  let totalStepCompleted = totalPreviousStepsCompleted + totalCurrentStepCompleted

  stepCompleted = Math.round(stepCompleted * 100)
  totalStepCompleted = Math.round(totalStepCompleted * 100)

  const { mutate: mutateCancelTask, isPending } = useCancelTaskMutation({
    onSuccess: () => {
      toast({
        title: "Task is canceled",
        status: "success",
      })
    },
  })

  return (
    <AnimatePresence mode="wait">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.15 }}
        className="relative"
      >
        <div className="overflow-hidden mb-2 flex items-center">
          <p className="text-sm font-semibold line-clamp-1 flex-1 mr-2">Task #{index}: </p>
          {task.status === RecipeTaskStatus.QUEUED || task.status === RecipeTaskStatus.RUNNING ? (
            <IconButton
              isLoading={isPending}
              onClick={() => mutateCancelTask(task.id)}
              colorScheme="transparent"
              className={classNames("min-h-0 p-0.5", {
                "bg-atherGray-900": isPending,
              })}
            >
              <XIcon width={12} height={12} />
            </IconButton>
          ) : (
            <div className="flex items-center space-x-2">
              {selectionMode ? (
                <IconButton
                  onClick={e => {
                    e.stopPropagation()
                    e.preventDefault()
                    onCheck?.(task)
                  }}
                  className={classNames("shadow-blackAlpha-500 bg-atherGray-700 p-0 w-7 h-7 min-h-0 rounded-full", {
                    "text-white bg-atherPurple-500 hover:bg-atherPurple-400": isChecked,
                    "text-atherGray-300 hover:bg-atherGray-600": !isChecked,
                  })}
                >
                  <TickIcon />
                </IconButton>
              ) : (
                <>
                  <IconButton
                    title={"Delete Task"}
                    colorScheme="transparent"
                    className={classNames("p-1.5 min-h-0")}
                    onClick={e => {
                      e.stopPropagation()
                      setDeletingItem({
                        type: "task",
                        id: task.id,
                        ids: [task.id],
                        recipeParams: {
                          recipeId: task.recipeId,
                        },
                        deletedAt: task.deletedAt ?? null,
                      })
                    }}
                  >
                    <DeleteIcon width={16} height={16} className="text-red-500" />
                  </IconButton>
                  <IconButton
                    title={"Rating"}
                    colorScheme="transparent"
                    className={classNames("p-1.5 min-h-0", {
                      "!cursor-default": !!task.userFeedback,
                    })}
                    onClick={e => {
                      e.stopPropagation()

                      if (task.userFeedback) return

                      if (modal) {
                        setModal(null)
                      } else {
                        setModal(task.id)
                      }
                    }}
                  >
                    {task.userFeedback ? (
                      <ThumbUpFillIcon
                        width={16}
                        height={16}
                        className={classNames({
                          "text-red-500 rotate-180": task.userFeedback?.rating === FeedbackRating.NotGood,
                          "text-atherPurple-500": task.userFeedback?.rating === FeedbackRating.Good,
                        })}
                      />
                    ) : (
                      <ThumbUpIcon width={16} height={16} />
                    )}
                  </IconButton>
                </>
              )}
            </div>
          )}
        </div>
        {(task.status === RecipeTaskStatus.QUEUED || task.status === RecipeTaskStatus.RUNNING) && (
          <div className="my-2 flex w-full">
            <ProgressbarTask
              className="flex-1"
              isLoading={!progressData || task.id !== progressData?.task_id}
              progress={convertInfinityToZero(totalStepCompleted)}
              description={`Generating ${convertInfinityToZero(totalStepCompleted)}%`}
            />
          </div>
        )}
        <ResultImages
          containerWidth={containerWidth}
          fullDataImages={task.fullDataImages}
          refetchHistory={() => {}}
          workflow={task}
        >
          <div
            className={classNames("absolute w-full h-full top-0 left-0 border-[2px] pointer-events-none rounded-xl", {
              "border-atherPurple-500": isChecked,
              "border-[transparent]": !isChecked,
              "z-0": !selectionMode,
              "z-[1]": selectionMode,
            })}
          />
        </ResultImages>
      </motion.div>
    </AnimatePresence>
  )
}

export default memo(RecipeTask)
