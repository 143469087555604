import client from "@/api/client"
import { ExploreImageFullDetail } from "@/api/sdk"
import { createQuery } from "react-query-kit"
import { enableQueryMiddleware } from "../middlewares/enableQueryMiddleware"

export interface ExploreImageDetailQueryVariables {
  imageId: string
  userUid?: string
}

export const useExploreDetailImageQuery = createQuery<ExploreImageFullDetail, ExploreImageDetailQueryVariables, Error>({
  primaryKey: "explore-detail-image",
  queryFn: ({ queryKey: [_primaryKey, variables] }) =>
    client.api.imageControllerGetInspirationBoardImage(variables.imageId).then(res => res.data),
  use: [enableQueryMiddleware(variables => !!variables.imageId)],
})
