import { SortOptions, PickTypeClass } from "@/api/sdk"
import IconButton from "@/components/IconButton"
import { FilterIcon, XIcon } from "@/components/shared/icons"
import { useClearUrlQuery, useReplaceUrlQuery } from "@/hooks/useQuery"
import { googleAnalytics } from "@/lib/gtag"
import { capitalize } from "@/utils"
import classNames from "classnames"
import { AnimatePresence, motion } from "framer-motion"
import useCustomRouter from "@/hooks/useCustomRouter"
import { Fragment, useEffect, useState } from "react"
import TagSearch from "./TagSearch"
import { useSearchTagInfiniteQuery } from "@/queries"
import { ExploreGalleryTabType } from "../GalleryInspiration"
import ReactPortal from "@/components/ReactPortal"
import { Radio } from "@/components/Alpha/View/GalleryFilter"

interface GalleryFilterProps {
  isSearch?: boolean
  tab: ExploreGalleryTabType
  containerFilterRef?: React.RefObject<HTMLDivElement>
  sort: {
    sortBy?: string
    sortOrder?: "ASC" | "DESC"
  }
  setSort: React.Dispatch<React.SetStateAction<{ sortBy?: string; sortOrder?: "ASC" | "DESC" }>>
  selectTags: PickTypeClass[]
  setSelectTags: React.Dispatch<React.SetStateAction<PickTypeClass[]>>
  category: string[] | null
  setCategory: React.Dispatch<React.SetStateAction<string[] | null>>
  isBookmarked: boolean
  setIsBookmarked: React.Dispatch<React.SetStateAction<boolean>>
  createdBy: string | null
  setCreatedBy: React.Dispatch<React.SetStateAction<string | null>>
}

const GalleryFilter = ({
  isSearch,
  tab,
  containerFilterRef,
  category,
  createdBy,
  setCreatedBy,
  isBookmarked,
  selectTags,
  setCategory,
  setIsBookmarked,
  setSelectTags,
  setSort,
  sort,
}: GalleryFilterProps) => {
  const router = useCustomRouter()
  const [open, setOpen] = useState<"Filter" | "Sort" | null>(null)
  const replaceUrlQuery = useReplaceUrlQuery()
  const clearUrlQuery = useClearUrlQuery()
  const [tempFilter, setTempFilter] = useState<{
    sortBy?: string
    tags?: PickTypeClass[]
    category?: string[]
    bookmarked?: boolean
    createdBy?: string
  }>({})

  const [isFetched, setIsFetched] = useState(true)

  const {
    data: tags,
    isFetchedAfterMount,
    isSuccess,
  } = useSearchTagInfiniteQuery({
    variables: {
      tagIds: router.query.tags
        ?.toString()
        .split(",")
        .map(i => parseInt(i)),
    },
    enabled: !!router.query.tags && !isFetched,
  })

  useEffect(() => {
    if (isSuccess) {
      setIsFetched(true)
    }
  }, [isSuccess])

  useEffect(() => {
    if (router.isReady && router.query.tags) {
      setIsFetched(false)
    }
  }, [router.query.tags, router.isReady])

  useEffect(() => {
    if (tags && router.isReady && isFetchedAfterMount) {
      setTempFilter(prev => ({
        ...prev,
        tags: tags.pages.map(i => i.tags).flat(),
      }))
      setSelectTags(tags.pages.map(i => i.tags).flat())
    }
  }, [isFetchedAfterMount, router.isReady, tags])

  useEffect(() => {
    setTempFilter({})
    setSelectTags([])
    setSort({
      sortBy: undefined,
      sortOrder: "DESC",
    })
    setCategory(null)
    setIsBookmarked(false)
    setIsBookmarked(false)
    setCreatedBy(null)
    clearUrlQuery(["sortBy", "category", "tags", "bookmarked", "createdBy"])
    setOpen(null)
  }, [tab])

  useEffect(() => {
    if (router.isReady) {
      const { category, sortBy, bookmarked, createdBy } = router.query

      if (createdBy) {
        setCreatedBy(createdBy as string)
        setTempFilter(prev => ({
          ...prev,
          createdBy: createdBy as string,
        }))
      }

      if (category) {
        const mappedCategory = category.toString().split(",")

        setTempFilter(prev => ({
          ...prev,
          category: mappedCategory,
        }))
        setCategory(mappedCategory)
      } else {
        setCategory(null)
      }

      if (bookmarked) {
        setIsBookmarked(bookmarked === "true")
        setTempFilter(prev => ({
          ...prev,
          bookmarked: bookmarked === "true",
        }))
      } else {
        setIsBookmarked(false)
      }

      if (sortBy) {
        setTempFilter(prev => ({
          ...prev,
          sortBy: sortBy as string,
        }))
        setSort(prev => {
          return {
            ...prev,
            sortBy: sortBy as string,
          }
        })
      } else {
        setSort({
          sortBy: undefined,
          sortOrder: "DESC",
        })
      }
    }
  }, [router.isReady])

  const renderFilter = () => {
    if (tab === "articles")
      return (
        <div className="">
          <TagSearch type="articles" tempFilter={tempFilter} setTempFilter={setTempFilter} />
        </div>
      )

    if (tab === "images")
      return (
        <div className="">
          <TagSearch type="images" tempFilter={tempFilter} setTempFilter={setTempFilter} />
        </div>
      )

    if (tab === "macros" || tab === "recipes" || tab === "comfyui" || tab === "styles")
      return (
        <div className="">
          <TagSearch type={tab} tempFilter={tempFilter} setTempFilter={setTempFilter} />
        </div>
      )
  }

  return (
    <Fragment>
      <div ref={containerFilterRef}>
        <div
          className={classNames("flex space-x-0 md:space-x-2", {
            hidden: isSearch,
          })}
        >
          {tab !== "creators" && (
            <IconButton
              title="Filter"
              colorScheme="transparent"
              className={classNames(
                "flex items-center font-semibold border border-transparent text-sm p-2 rounded-2xl",
                {
                  "border-atherPurple-500":
                    (category && category?.length > 0) ||
                    selectTags.length > 0 ||
                    isBookmarked ||
                    sort.sortBy ||
                    createdBy,
                },
              )}
              onClick={() => {
                googleAnalytics.handleCategoryEvent({
                  action: "click",
                  params: {
                    action: "Filter by",
                    tab_name: router.pathname.split("/")[2],
                  },
                })

                setOpen(prev => (prev === "Filter" ? null : "Filter"))
              }}
            >
              <FilterIcon
                width={24}
                height={24}
                className={classNames("", {
                  "text-atherGray-0": open === "Filter",
                  "text-atherGray-300": open !== "Filter",
                })}
              />
            </IconButton>
          )}
        </div>
      </div>
      <AnimatePresence mode="wait">
        {!!open && (
          <ReactPortal wrapperId="layout">
            <motion.div
              initial={{ opacity: 0.5 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0.5 }}
              transition={{ duration: 0.2, ease: "easeInOut" }}
              className="fixed overflow-hidden border-l top-0 right-0 w-80 shadow-lg flex flex-col z-[26] h-full-screen bg-atherGray-900 border-atherGray-800 "
            >
              <div className="flex flex-col w-full overflow-auto flex-1 p-4">
                <p className="text-xl font-semibold mb-4">Sort & Filter</p>
                <div className="mb-4">
                  <p className="font-semibold mb-2 text-atherGray-100">Sort</p>
                  <div className="space-y-2 text-sm">
                    <>
                      {Object.values(SortOptions)
                        .filter(
                          i =>
                            (i !== SortOptions.MOST_VIEWS || tab === "articles") &&
                            i !== SortOptions.MOSTREACTION &&
                            (i !== SortOptions.MOSTCOMMENTS || tab !== "creators") &&
                            (i !== SortOptions.MOST_USED ||
                              (tab !== "creators" && tab !== "articles" && tab !== "images" && tab !== "comfyui")) &&
                            (i !== SortOptions.AZ || tab !== "images"),
                        )
                        .sort((a, b) => b.localeCompare(a))
                        .map(column => (
                          <Radio
                            key={column}
                            value={column}
                            checked={tempFilter.sortBy === column}
                            onChange={() => {
                              googleAnalytics.handleCategoryEvent({
                                action: "click",
                                params: {
                                  action: "Sort by",
                                  tab_name: router.pathname.split("/")[2],
                                  sort_by: column,
                                },
                              })

                              setTempFilter(prev => ({
                                ...prev,
                                sortBy: column,
                              }))
                            }}
                            name={
                              column === SortOptions.MOST_VIEWS
                                ? "Most Views"
                                : column === SortOptions.MOSTFAVORITE
                                  ? "Most Favourited"
                                  : column === SortOptions.AZ
                                    ? "A-Z"
                                    : column === SortOptions.MOST_USED
                                      ? "Most used"
                                      : capitalize(column.toLowerCase())
                            }
                          />
                        ))}
                    </>
                  </div>
                </div>
                <hr className="border-atherGray-800 mb-4" />
                {renderFilter()}
              </div>
              <div className="px-4">
                <hr className="border-atherGray-800" />
              </div>
              <div className="flex items-center space-x-4 p-4">
                <IconButton
                  onClick={() => {
                    setTempFilter({})
                    setSort({
                      sortBy: undefined,
                    })
                    setSelectTags([])
                    setCategory(null)
                    setCreatedBy(null)
                    setIsBookmarked(false)

                    clearUrlQuery(["sortBy", "category", "tags", "bookmarked", "createdBy"])
                  }}
                  colorScheme="transparent"
                  className="w-full"
                >
                  Clear All
                </IconButton>
                <IconButton
                  className="w-full"
                  onClick={() => {
                    if (!tempFilter) return

                    if (tempFilter.sortBy) {
                      setSort({
                        sortBy: tempFilter.sortBy,
                      })
                    }

                    if (tempFilter.category) {
                      setCategory?.(tempFilter.category)
                    }
                    if (tempFilter.tags) {
                      setSelectTags(tempFilter.tags)
                    }

                    if (tempFilter.bookmarked) {
                      setIsBookmarked(true)
                    }

                    if (tempFilter.createdBy) {
                      setCreatedBy(tempFilter.createdBy)
                    }

                    const filter = {
                      sortBy: tempFilter.sortBy ?? "",
                      category:
                        tempFilter.category && tempFilter.category?.map(i => i).join(",")?.length > 0
                          ? tempFilter.category?.map(i => i).join(",")
                          : "",
                      tags: [...(tempFilter?.tags ?? [])].map(i => i.id).join(","),
                      bookmarked: tempFilter.bookmarked ? "true" : "",
                      createdBy: tempFilter.createdBy ?? "",
                    }

                    replaceUrlQuery(filter)

                    setOpen(null)

                    googleAnalytics.event({
                      action: "filter",
                      category: "gallery",
                      label: "apply explore filter",
                      params: {
                        select_tags: selectTags.toString(),
                        category: category?.toString() ?? "",
                        sort: JSON.stringify(sort),
                      },
                    })
                  }}
                >
                  Apply
                </IconButton>
              </div>
              <IconButton
                onClick={() => setOpen(null)}
                className="absolute top-0 right-0 p-2"
                colorScheme="transparent"
              >
                <XIcon className="text-atherGray-300" width={16} height={16} />
              </IconButton>
            </motion.div>
          </ReactPortal>
        )}
      </AnimatePresence>
    </Fragment>
  )
}

export default GalleryFilter
