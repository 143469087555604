import { useAuth } from "@/providers/authContext"
import { createContext, useContext, useEffect, useState } from "react"

export type TaskProgressSocketEvent = {
  node: number
  total_nodes: number
  step: number
  total_steps: number
  task_id: string
  completed: boolean
  user_id: string
}

export type ProgressingContext = {
  tasksProgress: TaskProgressSocketEvent[] | undefined
  setTasksProgress: React.Dispatch<React.SetStateAction<TaskProgressSocketEvent[] | undefined>>
}

const ProgressingContext = createContext<ProgressingContext | null>(null)
export const useProgressingContext = () => {
  const context = useContext(ProgressingContext)
  if (!context) throw new Error("missing ImagesContext")
  return context
}

export function ProgressingProvider({ children }: { children: React.ReactNode }) {
  const { socketRef, socketConnected } = useAuth()
  const [tasksProgress, setTasksProgress] = useState<TaskProgressSocketEvent[] | undefined>(undefined)

  useEffect(() => {
    if (!socketConnected || !socketRef.current) return
    const currentSocketRef = socketRef.current

    const onTaskProgress = (data: TaskProgressSocketEvent) => {
      setTasksProgress(prev => {
        if (!prev) return [data]

        if (!prev.find(t => t.task_id === data.task_id)) {
          return [...prev, data]
        }

        return prev.map(task => {
          if (task.task_id === data.task_id) {
            return {
              ...data,
              node: data.step === task.step ? (data.node > task.node ? data.node : task.node) : data.node,
            }
          }
          return task
        })
      })
    }

    currentSocketRef?.on("task-progress", onTaskProgress)

    return () => {
      currentSocketRef?.off("task-progress", onTaskProgress)
    }
  }, [socketConnected, socketRef])

  return (
    <ProgressingContext.Provider
      value={{
        tasksProgress,
        setTasksProgress,
      }}
    >
      {children}
    </ProgressingContext.Provider>
  )
}
