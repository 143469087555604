import { create } from "zustand"
import { persist } from "zustand/middleware"

const HISTORY_POOL_SIZE = 5

export interface SearchHistoryItem {
  uid: string
  text: string
  location: string
}

export interface HistorySearchStore {
  history: SearchHistoryItem[]
  addHistory: (item: SearchHistoryItem) => void
  removeHistory: (item: SearchHistoryItem) => void
}

export const useWorkspaceSearchHistory = create(
  persist<HistorySearchStore>(
    set => ({
      history: [],
      addHistory: item => {
        set(state => {
          let history = [...state.history]

          // If different user, remove all old history
          if (history.some(i => i.uid !== item.uid)) {
            history = []
          }

          // remove existing search item if exists
          history = history.filter(i => !(i.text === item.text && i.location === item.location && i.uid === item.uid))

          // add new search item to the top of the list
          history.unshift(item)

          // keep only the latest 10 items for each location
          const locationCount = history.reduce((acc, item) => {
            acc[item.location] = (acc[item.location] || 0) + 1
            return acc
          }, {})

          history = history.filter((item, index) => {
            if (locationCount[item.location] > HISTORY_POOL_SIZE) {
              if (locationCount[item.location] === index + 1) {
                locationCount[item.location]--
                return false
              }
            }
            return true
          })

          return { history }
        })
      },
      removeHistory: item => {
        set(state => {
          const history = state.history.filter(i => i.text !== item.text || i.location !== item.location)
          return { history }
        })
      },
    }),
    {
      name: "workspace-search-history",
    },
  ),
)
