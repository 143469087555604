import client from "@/api/client"
import { actionMiddleware } from "@/queries/middlewares/actionMiddleware"
import { createMutation } from "react-query-kit"
import { useQuestInfiniteQuery } from "./useQuestInfiniteQuery"

export const useClaimAllQuestMutation = createMutation({
  mutationFn: async () => {
    return await client.api.questControllerClaimAllQuest().then(res => res.data)
  },
  use: [
    actionMiddleware({
      onSuccess: ({ queryClient, variables }) => {
        const listKey = useQuestInfiniteQuery.getKey()

        queryClient.invalidateQueries({ queryKey: listKey })
        queryClient.invalidateQueries({ queryKey: ["user-info"] })
      },
    }),
  ],
})
