import { RecipeTaskChainParams } from "@/components/Workspace/Workflows/WorkflowsChaining"
import { create } from "zustand"

interface PromptInputStore {
  promptInput: {
    modalType: "SuggestPromptModal" | "SelectPrevTaskPromptModal" | "PromptsLibraryModal"
    examples?: string[]
    onChange: (value: string) => void
    chains?: RecipeTaskChainParams[]
    value?: string
    index?: string
  } | null

  setPromptInput: (item: PromptInputStore["promptInput"]) => void
}

export const usePromptInputStore = create<PromptInputStore>(set => ({
  promptInput: null,
  setPromptInput: promptInput => set({ promptInput }),
}))
