import { FolderDetail, UserDetail } from "@/api/sdk"
import GalleryFolderMenu from "@/components/Workspace/Gallery/GalleryFolderMenu"
import { googleAnalytics } from "@/lib/gtag"
import classNames from "classnames"
import format from "date-fns/format"
import { Url } from "next/dist/shared/lib/router/router"
import useCustomRouter from "@/hooks/useCustomRouter"
import { useState } from "react"
import Popover from "@/components/Popover"
import IconButton from "@/components/IconButton"
import { MoreHorizontalIcon, PinIcon } from "@/components/shared/icons"
import { cn } from "@/utils/cn"
import FolderIcon from "../../Folder/FolderIcon"
import UserCreated from "@/components/Explore/Gallery/UserCreated"

interface FolderItemListProps {
  user: UserDetail | undefined
  onClick?: () => void
  selectionMode?: boolean
  folder: FolderDetail & { isChecked?: boolean; onCheck?: () => void }
  accessedAt?: string
  href: Url
}

const FolderItemList = ({ folder, accessedAt, user, onClick, href, selectionMode }: FolderItemListProps) => {
  const router = useCustomRouter()

  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className="flex items-center w-full text-sm rounded-lg text-atherPrimary-100 bg-atherGray-900">
      <div
        className="flex-1 md:flex-none md:w-[30%] py-2 pr-0 pl-2 md:pr-2 cursor-pointer"
        onClick={() => {
          if (selectionMode) {
            folder.onCheck?.()

            googleAnalytics.event({
              action: "click",
              category: "folder_item",
              label: "folder_checked",
              params: {
                folder_id: folder.id,
                folder_name: folder.name,
                pathname: window.location.pathname,
              },
            })
          } else {
            googleAnalytics.event({
              action: "click",
              category: "folder_item",
              label: "view_folder_detail",
              params: {
                folder_id: folder.id,
                folder_name: folder.name,
                pathname: window.location.pathname,
              },
            })

            onClick?.()

            router.push(href, undefined, {
              shallow: true,
            })
          }
        }}
      >
        <div className="flex items-center overflow-hidden">
          <div className="h-[3rem] flex justify-center items-center mr-2">
            <FolderIcon width={24} height={24} />
          </div>
          <div className="overflow-hidden flex flex-col flex-1">
            <p className="font-semibold flex-1 line-clamp-1">{folder.name}</p>
            <div className="block md:hidden truncate text-atherGray-500">
              Opened - {accessedAt ? format(new Date(accessedAt), "HH:mm dd/MM/yyyy") : ""}
            </div>
          </div>
        </div>
      </div>
      <div className="hidden md:block md:w-[15%] p-2 whitespace-nowrap truncate">Folder</div>
      <div className="hidden md:block md:w-[15%] p-2 whitespace-nowrap truncate">
        {accessedAt ? format(new Date(accessedAt ?? ""), "HH:mm dd/MM/yyyy") : ""}
      </div>

      <div className="hidden md:block md:w-[15%] p-2">
        <UserCreated
          size="sm"
          picture={folder.creator.picture}
          name={folder.creator.name}
          isMe={user?.uid === folder.creator.uid}
        />
      </div>
      <div
        className="hidden md:block md:w-[20%] p-2 overflow-hidden cursor-pointer"
        onClick={e => {
          e.stopPropagation()
          googleAnalytics.event({
            action: "click",
            category: "gallery",
            label: "folder_view",
            params: {
              folder_id: folder.id,
              folder_name: folder.name,
            },
          })
          if (!folder.parentFolder) return

          router.push(`/workspace/folders?folderId=${folder.parentFolder.id}`, undefined, { shallow: true })
        }}
      >
        {folder.parentFolder && (
          <div className="flex items-center overflow-hidden">
            <div className="mr-2">
              <FolderIcon width={24} height={24} draggable={false} />
            </div>
            <p className="flex-1 truncate">
              {folder.parentFolder?.name ?? (user?.uid === folder.creator.uid ? "My Folders" : "Unclassified Images")}
            </p>
          </div>
        )}
      </div>
      <div className="md:w-[5%] pr-2 md:p-2 flex items-center justify-end">
        {folder.pinned !== undefined && folder.pinned && (
          <div className={classNames("", {})}>
            <PinIcon width={16} height={16} />
          </div>
        )}
        <Popover
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          align="end"
          trigger={
            <IconButton
              colorScheme="transparent"
              onClick={e => e.stopPropagation()}
              className={cn("rounded-2xl p-0 w-7 h-7 min-h-0")}
            >
              <MoreHorizontalIcon width={14} height={14} />
            </IconButton>
          }
        >
          <GalleryFolderMenu folderInfo={folder} onClose={() => setIsOpen(false)} />
        </Popover>
      </div>
    </div>
  )
}

export default FolderItemList
