import { PromptItem } from "@/api/sdk"
import IconButton from "@/components/IconButton"
import Tooltip from "@/components/Tooltip"
import { DeleteIcon, TickIcon } from "@/components/shared/icons"
import { googleAnalytics } from "@/lib/gtag"
import { useDeleteItemStore } from "@/stores"
import { AnimatePresence, motion } from "framer-motion"
import { Fragment, useCallback, useMemo } from "react"
import ContextMenuZone from "@/components/Workspace/FoldersV2/ContextMenuZone"
import MultiModeActions from "@/components/Workspace/FoldersV2/TabFolder/MultiModeActions"

interface MultiPromptsSelectBarProps {
  selectedItems: PromptItem[]
  offsetLeft?: number
  clear: () => void
  selectionMode?: boolean
  children?: React.ReactNode
}

const MultiPromptsSelectBar = ({
  selectedItems,
  offsetLeft = 0,
  clear,
  selectionMode,
  children,
}: MultiPromptsSelectBarProps) => {
  const setDeletingItem = useDeleteItemStore(s => s.setDeletingItem)

  const handleDeletePrompt = useCallback(() => {
    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        action: "Multi Wildcards Delete",
        ...selectedItems.reduce((acc, item, index) => {
          acc[`wildcard_id_${index}`] = item.id
          return acc
        }, {}),
      },
    })

    setDeletingItem({
      type: "prompt",
      ids: selectedItems.map(item => item.id),
      id: "",
      deletedAt: null,
      onClose: clear,
    })
  }, [selectedItems, setDeletingItem, clear])

  const renderWCActions = useMemo(() => {
    return (
      <MultiModeActions
        onDelete={{
          fn: handleDeletePrompt,
          enabled: selectedItems.length > 0,
          visible: true,
          title: `Delete`,
        }}
      />
    )
  }, [handleDeletePrompt, selectedItems.length])

  return (
    <Fragment>
      <AnimatePresence>
        {selectedItems.length > 0 && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed -translate-x-1/2 top-[3rem] md:top-32 z-20"
            style={{
              left: `calc(50% + ${offsetLeft}px)`,
            }}
          >
            <div className="rounded-2xl bg-atherGray-850 py-2 px-2 md:px-4 text-xs font-semibold text-atherGray-300 h-12 flex items-center space-x-4">
              <div className="pl-2 flex items-center">
                {selectedItems.length} <span className="hidden md:inline-block ml-2">selected</span>
                <span className="inline-block ml-2 md:hidden">
                  <TickIcon />
                </span>
              </div>
              <hr className="w-[1px] h-6 border border-atherGray-800" />
              {/* <button>Select all</button> */}
              <div className="flex items-center space-x-2">
                <Tooltip
                  trigger={
                    <IconButton
                      className="w-8 h-8 p-0 hover:bg-atherGray-700 active:bg-atherGray-800 text-red-500"
                      colorScheme="transparent"
                      onClick={() => {
                        handleDeletePrompt
                      }}
                    >
                      <DeleteIcon width={16} height={16} />
                    </IconButton>
                  }
                >
                  Delete
                </Tooltip>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      {children && (
        <ContextMenuZone isDisabled={!selectionMode} className="flex-1 flex flex-col" contextMenu={renderWCActions}>
          {children}
        </ContextMenuZone>
      )}
    </Fragment>
  )
}

export default MultiPromptsSelectBar
