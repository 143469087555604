import client from "@/api/client"
import { PinnedItemType, PinnedItems, RecentStyleItem, SearchSDStyleResponse } from "@/api/sdk"
import { InfiniteData } from "@tanstack/react-query"
import { createMutation } from "react-query-kit"
import { actionMiddleware } from "@/queries/middlewares/actionMiddleware"
import { useWorkspaceStyleInfiniteQuery } from "./useGetStyleInfiniteQuery"
import { useRecentStylesInfiniteQuery } from "@/components/Workspace/RecentV2/RecentStyles"
import { useWorkspacePinInfiniteQuery } from "@/queries/workspace"

interface RemoveStylesMutationVariables {
  ids: string[]
}

export const useAddStylesMutation = createMutation({
  mutationFn: async ({ ids }: RemoveStylesMutationVariables) => {
    return client.api
      .sdStyleControllerAddStylesToLibrary({
        sdStyleIds: ids,
      })
      .then(res => res.data)
  },
  use: [
    actionMiddleware({
      onSuccess: ({ queryClient, variables: { ids } }) => {
        // Update style list
        const styleListKey = useWorkspaceStyleInfiniteQuery.getKey()
        const styleListQueriesDataEntries = queryClient.getQueriesData<InfiniteData<SearchSDStyleResponse, number>>({
          queryKey: styleListKey,
        })

        styleListQueriesDataEntries?.forEach(([key, data]) => {
          if (!data) return

          const updatedData = {
            ...data,
            pages: data.pages.map(page => {
              const updatedPage = {
                ...page,
                styles: page.data.map(style => {
                  if (ids.includes(style.id)) {
                    return {
                      ...style,
                      capabilities: {
                        ...style.capabilities,
                        canAddToLibrary: false,
                        canRemoveFromLibrary: true,
                      },
                    }
                  }
                  return style
                }),
              }
              updatedPage.total = updatedPage.styles.length
              return updatedPage
            }),
          }

          queryClient.setQueryData(key, updatedData)
        })

        // update style recent list
        const recentListStyles = useRecentStylesInfiniteQuery.getKey()

        const recentListQueriesDataEntries = queryClient.getQueriesData<InfiniteData<RecentStyleItem[], number>>({
          queryKey: recentListStyles,
        })

        if (recentListQueriesDataEntries) {
          recentListQueriesDataEntries.forEach(([key, data]) => {
            if (!data) return

            const updatedData = {
              ...data,
              pages: data.pages.map(page => {
                return [
                  ...page.map(item => {
                    if (ids.includes(item.style.id)) {
                      return {
                        ...item,
                        style: {
                          ...item.style,
                          capabilities: {
                            ...item.style.capabilities,
                            canAddToLibrary: false,
                            canRemoveFromLibrary: true,
                          },
                        },
                      }
                    }
                    return item
                  }),
                ]
              }),
            }

            queryClient.setQueryData(key, updatedData)
          })
        }

        // Update style pinned list
        const stylePinnedListKey = useWorkspacePinInfiniteQuery.getKey({
          type: PinnedItemType.STYLE,
        })

        const stylePinnedListQueriesDataEntries = queryClient.getQueriesData<InfiniteData<PinnedItems[], number>>({
          queryKey: stylePinnedListKey,
        })

        if (stylePinnedListQueriesDataEntries) {
          stylePinnedListQueriesDataEntries.forEach(([key, pinnedListData]) => {
            if (pinnedListData) {
              const updatedData = {
                ...pinnedListData,
                pages: pinnedListData.pages.map(page => {
                  return [
                    ...page.map(item => {
                      if (ids.includes(item.style?.id ?? "")) {
                        return {
                          ...item,
                          style: {
                            ...item.style,
                            capabilities: {
                              ...item.style?.capabilities,
                              canAddToLibrary: false,
                              canRemoveFromLibrary: true,
                            },
                          },
                        }
                      }

                      return item
                    }),
                  ]
                }),
              }

              queryClient.setQueryData(key, updatedData)
            }
          })
        }
      },
    }),
  ],
})
