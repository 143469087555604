import client from "@/api/client"
import { UserRanking } from "@/api/sdk"
import { createAuthenticatedInfiniteQuery } from "../createAuthenticatedQuery"

export interface UseLeaderboardRankingsInfiniteQueryVariables {
  leaderboardId: string
}

export const useLeaderboardRankingsInfiniteQuery = createAuthenticatedInfiniteQuery<
  UserRanking[],
  UseLeaderboardRankingsInfiniteQueryVariables,
  Error,
  UserRanking
>({
  auth: false,
  primaryKey: "infinite-explore-images",
  queryFn: ({ queryKey: [_primaryKey, { leaderboardId }], pageParam = 0 }) =>
    client.api
      .leaderboardControllerGetLatestRankingByLeadboard(leaderboardId, {
        skip: pageParam * 50,
        take: 50,
      })
      .then(res => res.data),
  getNextPageParam: (lastPage, pages) => (lastPage && lastPage.length < 50 ? undefined : pages.length),
  initialPageParam: 0,
  flattenFn: data => data,
})
