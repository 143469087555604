import client from "@/api/client"
import { CommentImageDto, EntityType } from "@/api/sdk"
import { createMutation } from "react-query-kit"

export interface UseAddCommentMutationVariables {
  typeComment: EntityType
  itemId: string
  data: CommentImageDto & { id: string; nodeId?: number }
}

export const useAddCommentMutation = createMutation({
  mutationFn: async ({ data, itemId, typeComment }: UseAddCommentMutationVariables) => {
    if (typeComment === EntityType.ARTICLE) {
      return await client.api.articleControllerCreateArticleComment(itemId, data).then(res => res.data)
    }

    if (typeComment === EntityType.INSPIRATION_IMAGE) {
      return await client.api.imageControllerCreateInspirationBoardImageComment(itemId, data).then(res => res.data)
    }

    if (typeComment === EntityType.EXPLORE_STYLE) {
      return await client.api.exploreSdStyleControllerCreateExploreSdStyleComment(itemId, data).then(res => res.data)
    }

    if (typeComment === EntityType.EXPLORE_WORKFLOW) {
      return await client.api.exploreWorkflowControllerCreateExploreWorkflowComment(itemId, data).then(res => res.data)
    }

    if (typeComment === EntityType.EXPLORE_RECIPE) {
      return await client.api.exploreRecipeControllerCreateExploreRecipeComment(itemId, data).then(res => res.data)
    }

    if (typeComment === EntityType.EXPLORE_SD_WORKFLOW) {
      return await client.api
        .exploreSdWorkflowControllerCreateExploreSdWorkflowComment(itemId, {
          formatValue: data.formatValue,
          parentCommentId: data.parentCommentId ?? null,
          uidMentions: data.uidMentions,
          value: data.value,
          nodeId: data.nodeId ?? 1,
        })
        .then(res => res.data)
    }

    if (typeComment === EntityType.STYLE) {
      return await client.api.sdStyleControllerCreateSdStyleComment(itemId, data).then(res => res.data)
    }

    if (typeComment === EntityType.WORKFLOW) {
      return await client.api.workflowControllerCreateWorkflowComment(itemId, data).then(res => res.data)
    }

    if (typeComment === EntityType.SD_WORKFLOW) {
      return await client.api
        .sdWorkflowControllerCreateComment(itemId, {
          formatValue: data.formatValue,
          parentCommentId: data.parentCommentId ?? null,
          uidMentions: data.uidMentions,
          value: data.value,
          nodeId: data.nodeId ?? 1,
        })
        .then(res => res.data)
    }

    if (typeComment === EntityType.SD_MODEL) {
      return await client.api.sdModelControllerCreateSdModelComment(itemId, data).then(res => res.data)
    }

    return await client.api
      .imageControllerCommentImage(itemId, {
        formatValue: data.formatValue,
        parentCommentId: data.parentCommentId ?? null,
        uidMentions: data.uidMentions,
        value: data.value,
      })
      .then(res => res.data)
  },
})
