import client from "@/api/client"
import { GetPromptsResult } from "@/api/sdk"
import { InfiniteData } from "@tanstack/react-query"
import { createMutation } from "react-query-kit"
import { actionMiddleware } from "@/queries/middlewares/actionMiddleware"
import { useWorkspacePromptsInfiniteQuery } from "./useWorkspacePromptsInfiniteQuery"

interface DeletePromptMutationVariables {
  ids: string[]
  deleteAt: string | null
}

export const useDeletePromptMutation = createMutation({
  mutationFn: async ({ ids, deleteAt }: DeletePromptMutationVariables) => {
    if (deleteAt) {
      return client.api.promptLibraryControllerFullyDeletePrompts(ids)
    }

    return client.api.promptLibraryControllerSoftDeletePrompts(ids).then(res => res.data)
  },
  use: [
    actionMiddleware({
      onSuccess: ({ queryClient, variables: { ids } }) => {
        // Update prompt list
        const promptListKey = useWorkspacePromptsInfiniteQuery.getKey()
        const promptListQueriesDataEntries = queryClient.getQueriesData<InfiniteData<GetPromptsResult, number>>({
          queryKey: promptListKey,
        })

        promptListQueriesDataEntries?.forEach(([key, data]) => {
          if (!data) return

          const updatedData = {
            ...data,
            pages: data.pages.map(page => {
              const updatedPage = {
                ...page,
                prompts: page.prompts.filter(style => {
                  return !ids.includes(style.id)
                }),
              }
              updatedPage.total = updatedPage.prompts.length
              return updatedPage
            }),
          }
          queryClient.setQueryData(key, updatedData)
        })
      },
    }),
  ],
})
