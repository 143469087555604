import React from "react"
import Modal from "@/components/Modal"
import { useAuth } from "@/providers/authContext"
import { useSetting } from "@/components/Setting/SettingProvider"
import { useGetListModelStorageSubscriptionQuery } from "@/queries"
import LoadingLogo from "@/components/LoadingLogo"
import classNames from "classnames"
import IconButton from "@/components/IconButton"
import { SubscriptionPackageItem } from "@/api/sdk"
import useCustomRouter from "@/hooks/useCustomRouter"
import { bytesToSize } from "@/utils/bytes-to-size"
import { useScreen } from "@/hooks"

interface NotEnoughModelStorageModalProps {
  isOpen: boolean
  onClose: () => void
}

const NotEnoughModelStorageModal: React.FC<NotEnoughModelStorageModalProps> = ({ isOpen, onClose }) => {
  const router = useCustomRouter()
  const { user } = useAuth()
  const { currentWorkspaceDetail } = useSetting()
  const { width } = useScreen()

  const isMobileOnly = width < 768

  const { data: listModelStorageSubscription = [], isLoading: isLoadingModelStorageSubscriptions } =
    useGetListModelStorageSubscriptionQuery()

  const isOwner = currentWorkspaceDetail?.owner?.uid === user?.uid

  const handleClick = (subscription: SubscriptionPackageItem) => {
    router.push(`/settings/upgrade-account?subscriptionId=${subscription.id}&tab=Monthly`, undefined, {
      shallow: true,
      scroll: true,
    })
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Not Enough Model Storage">
      {isOwner ? (
        isLoadingModelStorageSubscriptions ? (
          <div className="flex items-center justify-center h-64">
            <LoadingLogo />
          </div>
        ) : (
          <div className="flex flex-col gap-4">
            <p>
              You do not have enough storage to add the Model.
              <br /> Please purchase additional storage for your workspace.
            </p>
            <div className="flex flex-col gap-4 px-2 md:px-0">
              {listModelStorageSubscription.map(subscription => (
                <div
                  key={subscription.id}
                  onClick={() => {
                    if (!isMobileOnly) return
                    handleClick(subscription)
                  }}
                  className={classNames("bg-atherGray-850 p-4 rounded-2xl flex items-center")}
                >
                  <div className="flex-1">
                    <div className="flex items-center">
                      <h3 className="text-sm md:text-xl">{`${bytesToSize(subscription.maxModelStorage, 0, " ")}/workspace/month`}</h3>
                    </div>
                  </div>
                  <h3 className="mx-0 md:mx-6 text-xl font-semibold">${subscription.monthlyCost}</h3>
                  <IconButton onClick={() => handleClick(subscription)} className="text-xs md:text-sm hidden md:flex">
                    Buy Now
                  </IconButton>
                </div>
              ))}
            </div>
          </div>
        )
      ) : (
        <div>
          <p className="mb-2">You do not have enough model storage to add the Model.</p>
          <p>Please ask your workspace owner to purchase additional model storage.</p>
        </div>
      )}
    </Modal>
  )
}

export default NotEnoughModelStorageModal
