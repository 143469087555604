import { IconProps } from "."

export const UnPinIcon = (props: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M5.5 6C4.95863 6.1281 4.49932 6.14239 3.99268 6.45891C3.07234 7.03388 2.85108 7.71674 3.08821 8.76116C3.94028 12.5139 7.48599 16.0596 11.2388 16.9117C12.2834 17.1489 12.9661 16.928 13.5416 16.0077C13.8411 15.5288 13.8716 15.0081 14 14.5"
        stroke="#C8C4B5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 5.79915C10.1776 5.77794 10.3182 5.74034 10.4295 5.68235C11.3997 5.17686 11.9291 3.53361 12.4498 2.60009C12.9311 1.73715 13.1718 1.30567 13.7379 1.10227C14.3041 0.898876 14.6448 1.02205 15.3262 1.26839C16.9197 1.8445 18.1555 3.08032 18.7316 4.6738C18.9779 5.35521 19.1011 5.69591 18.8977 6.26204C18.6943 6.82817 18.2628 7.06884 17.3999 7.55018C16.4608 8.07396 14.7954 8.6108 14.2905 9.5898C14.2345 9.69832 14.1978 9.83274 14.1769 10"
        stroke="#C8C4B5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M1 19L6 14" stroke="#C8C4B5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M1 1L19 19" stroke="#C8C4B5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
