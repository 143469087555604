import InfiniteScroll from "react-infinite-scroller"
import { motion } from "framer-motion"
import { EntityType, FolderDetail, RecipeTaskWithFullImages } from "@/api/sdk"
import LoadingLogo from "@/components/LoadingLogo"
import { useGetHistoryTaskInfiniteQuery, useGetOngoingTaskQuery } from "@/queries/task/useGetTaskInfiniteQuery"
import { FC, Fragment, useEffect, useMemo, useRef, useState } from "react"
import RecipeTask from "./RecipeTask"
import useContainerWidth from "@/hooks/useContainerWidth"
import { useAuth } from "@/providers/authContext"
import { CheckCircleIcon, InfoIcon } from "@/components/shared/icons"
import FeedbackPopup from "@/components/Workspace/Feedback/FeedbackPopup"
import { useTurboMode } from "@/components/Workspace/TurboMode/useTurboMode"
import { useProgressingContext } from "@/providers/ProgressingProvider"
import { RecipeSocketData } from "@/components/Workspace/Recipes/RecipeDetail/ListRecipes"
import classNames from "classnames"
import IconButton from "@/components/IconButton"
import { useMultiTasksSelect } from "./useMultiTasksSelect"
import MultiTasksSelectBar from "./MultiTasksSelectBar"
import LoadingIcon from "@/components/LoadingIcon"
import NotFoundItems from "@/components/Workspace/Pinned/NotFoundItems"
import ErrorUI from "@/components/ErrorUI"

const COMFY_RECIPE_ID = "comfyui"

type ComfyUIRecipeTasksProps = {
  workflowId?: string
  folder?: FolderDetail
  setFolder: (folder?: FolderDetail) => void
  onGenerate: () => void
  onCompletedTask: (data: RecipeSocketData | RecipeTaskWithFullImages) => void
  isCreatingTask: boolean
  onTaskOnGoing: (running: boolean) => void
}

const ComfyUIRecipeTasks: FC<ComfyUIRecipeTasksProps> = props => {
  const { workflowId, folder, setFolder, isCreatingTask, onGenerate, onCompletedTask, onTaskOnGoing } = props
  const { loading } = useAuth()

  const {
    data: onGoingRecipes,
    isLoading: isLoadingOnGoing,
    isError: isErrorOnGoing,
    refetch: refetchOngoing,
  } = useGetOngoingTaskQuery({
    variables: {
      recipeIds: [COMFY_RECIPE_ID],
      sdWorkflowId: workflowId,
    },
  })

  const {
    hasNextPage,
    fetchNextPage,
    isLoading: isLoadingHistory,
    isFetching,
    isSuccess,
    isError: isErrorHistory,
    refetch: refetchHistory,
    data,
  } = useGetHistoryTaskInfiniteQuery({
    variables: {
      recipeIds: [COMFY_RECIPE_ID],
      sdWorkflowId: workflowId,
      statuses: ["COMPLETED"],
    },
    enabled: !loading,
  })

  const mappedHistoryRecipes = useMemo(() => data?.pages.flatMap(i => i), [data])

  const isLoading = isLoadingOnGoing || isLoadingHistory
  const isError = isErrorOnGoing || isErrorHistory

  const mappedOngoingRecipes = useMemo(
    () =>
      [...(onGoingRecipes?.running ?? []), ...(onGoingRecipes?.queued ?? [])].sort(
        (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
      ) as RecipeTaskWithFullImages[],
    [onGoingRecipes?.running, onGoingRecipes?.queued],
  )

  const mappedRecipes = useMemo(
    () =>
      [...mappedOngoingRecipes, ...(mappedHistoryRecipes ?? [])].map(r => ({
        ...r,
        fullDataImages: r.fullDataImages ?? [
          {
            url: "",
            id: `${r.id}`,
          },
        ],
      })),
    [mappedOngoingRecipes, mappedHistoryRecipes],
  )

  const { socketRef, socketConnected } = useAuth()
  const { tasksProgress } = useProgressingContext()
  const { socketRef: turboSocketRef, connected: turboSocketConnected } = useTurboMode()

  useEffect(() => {
    onTaskOnGoing(mappedOngoingRecipes.length > 0)
  }, [mappedOngoingRecipes.length, onTaskOnGoing])

  useEffect(() => {
    if (!socketConnected || !socketRef.current) return
    const currentSocket = socketRef.current
    const onRecipeComplete = async (data: RecipeSocketData) => {
      if (data.recipeId === COMFY_RECIPE_ID) {
        refetchOngoing()
        refetchHistory()
        onCompletedTask(data)
      }
    }

    currentSocket.on("recipe-task-complete", onRecipeComplete)
    return () => {
      currentSocket?.off("recipe-task-complete", onRecipeComplete)
    }
  }, [socketRef, socketConnected])

  useEffect(() => {
    if (!turboSocketConnected || !turboSocketRef.current) return

    const onTaskCompleted = async (task: RecipeTaskWithFullImages) => {
      if (task.recipeId === COMFY_RECIPE_ID) {
        refetchOngoing()
        refetchHistory()
        onCompletedTask(task)
      }
    }

    turboSocketRef.current.on("task-complete", onTaskCompleted)
    turboSocketRef.current.on("task-completed", onTaskCompleted)

    return () => {
      turboSocketRef.current?.off("task-complete")
      turboSocketRef.current?.off("task-completed")
    }
  }, [turboSocketConnected, turboSocketRef])

  const containerRef = useRef<HTMLDivElement>(null)
  const containerWidth = useContainerWidth(containerRef, isSuccess)
  const scrollRef = useRef<HTMLDivElement>(null)
  const [modal, setModal] = useState<string | null>(null)
  const { selectedItems, addSelection, clear, selectionMode, toggleSelectionMode } = useMultiTasksSelect()

  const renderBody = () => {
    if (isLoading)
      return (
        <div className="flex items-center justify-center flex-1 w-full text-gray-600">
          <LoadingIcon width={20} height={20} />
        </div>
      )

    if (!mappedRecipes?.length || isError) {
      return <NotFoundItems />
    }

    return (
      <InfiniteScroll
        loadMore={() => fetchNextPage()}
        hasMore={!!hasNextPage && !isFetching}
        useWindow={false}
        threshold={400}
        getScrollParent={() => scrollRef.current}
        style={{
          display: "flex",
          flexDirection: "column",
          maxWidth: "100%",
          position: "relative",
          width: "100%",
          alignItems: "center",
        }}
        loader={
          <div key="loader" className="flex items-center justify-center py-4">
            Loading...
          </div>
        }
      >
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.35, delay: 0.35 }}
          className="relative gap-2 flex flex-col w-full"
        >
          {mappedRecipes?.map((task, index) => (
            <RecipeTask
              index={index + 1}
              progressData={tasksProgress?.find(p => p.task_id === task.id)}
              key={task.id}
              task={task as RecipeTaskWithFullImages}
              containerWidth={containerWidth}
              modal={modal}
              setModal={setModal}
              selectionMode={selectionMode}
              isChecked={selectedItems.some(i => i.id === task.id)}
              onCheck={image => addSelection(image)}
            />
          ))}
          {mappedRecipes?.length === 0 && (
            <div className="flex-1 flex items-center justify-center">
              <p className="text-lg text-gray-600">No data</p>
            </div>
          )}
        </motion.div>
      </InfiniteScroll>
    )
  }

  return (
    <Fragment>
      <div ref={containerRef} className="relative flex flex-col w-full flex-1 pt-4 overflow-hidden bg-atherGray-900">
        <div className="px-4 mb-4">
          <p className="font-semibold text-base text-atherGray-100 mb-2">Generated Images</p>
          <div className="flex items-start">
            <div className="mr-2 pt-0.5">
              <InfoIcon className="text-atherGray-600" width={14} height={14} />
            </div>
            <p className="text-xs text-atherGray-300">These are images generated by using this ComfyUI workflow</p>
          </div>
        </div>
        <hr className=" border-atherGray-800" />
        <MultiTasksSelectBar selectedItems={selectedItems} clear={clear} />
        <div
          className={classNames("flex items-center px-4 py-2 justify-end", {
            hidden: mappedRecipes?.length === 0,
          })}
        >
          <IconButton
            colorScheme="transparent"
            className={classNames(
              "rounded-2xl p-1 px-2 hover:bg-atherGray-850 flex items-center text-atherGray-300 transition-all",
              {
                "bg-atherGray-850 text-atherGray-0": selectionMode,
              },
            )}
            onClick={() => toggleSelectionMode()}
          >
            <CheckCircleIcon width={20} height={20} checked={selectionMode} />
            <span className="font-normal ml-1 text-sm hidden md:block">Multi-select</span>
          </IconButton>
        </div>
        <div className="overflow-auto flex-1 flex flex-col px-4 w-full items-center" ref={scrollRef}>
          {renderBody()}
        </div>
      </div>
      {!!modal && (
        <FeedbackPopup
          debounceIsOpen={!!modal}
          isModal
          onClose={() => setModal(null)}
          className="fixed top-[4rem] right-4 z-[9]"
          entityId={modal}
          entityType={EntityType.RECIPE_TASK}
        />
      )}
    </Fragment>
  )
}

export default ComfyUIRecipeTasks
