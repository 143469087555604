import { IconProps } from "."

export const ShareWorkspace = (props: IconProps) => {
  return (
    <svg width="22" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M20.774 15C21.5233 15 22.1193 14.5285 22.6545 13.8691C23.7499 12.5194 21.9513 11.4408 21.2654 10.9126C20.568 10.3756 19.7894 10.0714 19 10M18 8C19.3807 8 20.5 6.88071 20.5 5.5C20.5 4.11929 19.3807 3 18 3"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M3.22596 15C2.47666 15 1.88067 14.5285 1.34555 13.8691C0.250089 12.5194 2.04867 11.4408 2.73465 10.9126C3.43197 10.3756 4.21058 10.0714 5 10M5.5 8C4.11929 8 3 6.88071 3 5.5C3 4.11929 4.11929 3 5.5 3"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M8.0838 12.1112C7.06203 12.743 4.38299 14.0331 6.0147 15.6474C6.81178 16.436 7.69952 17 8.81563 17H15.1844C16.3005 17 17.1882 16.436 17.9853 15.6474C19.617 14.0331 16.938 12.743 15.9162 12.1112C13.5201 10.6296 10.4799 10.6296 8.0838 12.1112Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5 4.5C15.5 6.433 13.933 8 12 8C10.067 8 8.5 6.433 8.5 4.5C8.5 2.567 10.067 1 12 1C13.933 1 15.5 2.567 15.5 4.5Z"
        stroke="white"
        strokeWidth="1.5"
      />
    </svg>
  )
}
