import { IconProps } from "."

export const FolderMoveIcon = (props: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18" fill="none" {...props}>
      <path
        d="M5.83366 4.83333H13.9587C15.7142 4.83333 16.592 4.83333 17.2226 5.25466C17.4956 5.43706 17.7299 5.67143 17.9123 5.94441C18.3337 6.57497 18.3337 7.45276 18.3337 9.20833C18.3337 9.42451 18.3337 9.6327 18.3334 9.83333M10.0003 4.83333L9.47246 3.7776C9.03515 2.90298 8.63551 2.0226 7.66637 1.65917C7.24191 1.5 6.75701 1.5 5.78722 1.5C4.27349 1.5 3.51663 1.5 2.94871 1.81693C2.54388 2.04285 2.20984 2.37689 1.98392 2.78172C1.66699 3.34964 1.66699 4.1065 1.66699 5.62023V8.16667C1.66699 12.095 1.66699 14.0592 2.88738 15.2796C4.01824 16.4105 5.78777 16.4934 9.16699 16.4995"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M16.2507 12.333C16.6721 12.7426 18.334 13.8328 18.334 14.4163M16.2507 16.4997C16.6721 16.0901 18.334 14.9999 18.334 14.4163M18.334 14.4163L11.6673 14.4163"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
