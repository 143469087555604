import client from "@/api/client"
import { ConfirmWalletRequest } from "@/api/sdk"
import IconButton from "@/components/IconButton"
import Modal from "@/components/Modal"
import { googleAnalytics } from "@/lib/gtag"
import { useAuth } from "@/providers/authContext"
import { warpNameLength } from "@/utils/parser"
import { useWallet } from "@solana/wallet-adapter-react"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import base58 from "bs58"
import React from "react"
import { useAccount, useSignMessage } from "wagmi"

interface RemoveWalletModalProps {
  walletId: string | null
  provider: "Solana" | "Ethereum"
  onClose: () => void
}

const RemoveWalletModal = ({ walletId, provider, onClose }: RemoveWalletModalProps) => {
  const wallet = useWallet()
  const qc = useQueryClient()
  const { address, connector, isConnected, isConnecting, isDisconnected, isReconnecting, status } = useAccount()
  const { signMessageAsync } = useSignMessage()
  const { userInfoQuery } = useAuth()

  const { mutateAsync: mutateDisconnectWallet, isPending: isDisconnecting } = useMutation({
    mutationFn: (walletId: string) =>
      client.api
        .walletControllerDisconnectWallet({
          address: walletId,
        })
        .then(res => res.data),
  })

  const { mutate: mutateDisconnectWalletConfirm, isPending: isConfirming } = useMutation({
    mutationFn: (data: ConfirmWalletRequest) =>
      client.api.walletControllerConfirmDisconnectWallet(data).then(res => res.data),
    onSuccess: () => {
      qc.invalidateQueries({
        queryKey: ["wallet-owned"],
      })
      onClose()
    },
  })

  const handleDisconnectWalletSolana = async () => {
    if (!walletId || !wallet.connected || !wallet.publicKey || !wallet.signMessage) return

    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        tab_name: "profile",
        action: "Remove wallet",
        user_name: userInfoQuery?.data?.name ?? "",
        user_username: userInfoQuery?.data?.username ?? "",
        user_id: userInfoQuery?.data?.uid ?? "",
        wallet_id: `E${walletId}`,
      },
    })

    try {
      const result = await mutateDisconnectWallet(walletId)

      const data = new TextEncoder().encode(result.message)
      const signature = await wallet.signMessage(data)
      const serializedSignature = base58.encode(signature)

      mutateDisconnectWalletConfirm({
        signature: serializedSignature,
        address: result.address,
        timestamp: result.timestamp,
      })
    } catch (error) {
      console.error(error)
    }
  }

  const handleDisconnectWalletEvm = async () => {
    if (!walletId || !isConnected || !address || !signMessageAsync) return

    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        tab_name: "profile",
        action: "Disconnect wallet",
        user_name: userInfoQuery?.data?.name ?? "",
        user_username: userInfoQuery?.data?.username ?? "",
        user_id: userInfoQuery?.data?.uid ?? "",
        wallet_id: `E${walletId}`,
      },
    })

    try {
      const result = await mutateDisconnectWallet(walletId)
      const signature = await signMessageAsync({ account: address, message: result.message })

      mutateDisconnectWalletConfirm({
        signature,
        address: result.address,
        timestamp: result.timestamp,
      })
    } catch (error) {
      console.error(error)
    }
  }

  const handleDisconnectWallet = () => {
    if (provider === "Solana") {
      handleDisconnectWalletSolana()
    } else {
      handleDisconnectWalletEvm()
    }
  }

  return (
    <Modal
      closeOnOutsideClick={isConfirming || isDisconnecting}
      className="max-w-md"
      title="Remove wallet"
      isOpen={!!walletId}
      onClose={onClose}
    >
      <p className="text-atherGray-300">
        By clicking <span className="text-atherGray-0 font-semibold">Agree</span> button, your GAIA account will be
        unlinked with this wallet{" "}
        <span className="text-atherGray-0 font-semibold">{warpNameLength(walletId ?? "")}</span>
      </p>
      <div className="mt-6 flex items-center space-x-2 justify-end">
        <IconButton disabled={isDisconnecting || isConfirming} onClick={onClose} colorScheme="secondary">
          Cancel
        </IconButton>
        <IconButton isLoading={isDisconnecting || isConfirming} onClick={() => handleDisconnectWallet()}>
          Agree
        </IconButton>
      </div>
    </Modal>
  )
}

export default RemoveWalletModal
