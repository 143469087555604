import { useState } from "react"

export const useImageSearch = () => {
  const [searchQuery, setSearchQuery] = useState("")

  const handleUpdateSearch = (
    key: string | null,
    operator: "<" | ">" | "=" | "<=" | ">=" | "!" | null,
    value: string | number
  ): string => {
    // If the key is null, the value is a prompt
    if (key === null) {
      const regex = /"[^"]*"/g
      const newPrompt = `"${value}"`
      const existingPromptMatch = searchQuery.match(regex)

      if (existingPromptMatch?.some(prompt => prompt === newPrompt)) {
        // If the prompt already exists and is the same as the new prompt, remove it
        const updatedQuery = searchQuery.replace(newPrompt, "").trim()
        setSearchQuery(updatedQuery)

        return updatedQuery
      } else {
        // If the prompt is different, add it to the search query
        const updatedQuery = `${searchQuery} ${newPrompt}`.trim()
        setSearchQuery(updatedQuery)

        return updatedQuery
      }
    }

    // Prepare the new search term
    const valueStr = typeof value === "string" && value.includes(" ") ? `"${value}"` : value
    const newSearchTerm = operator !== "=" ? `${key}:${operator}${valueStr}` : `${key}:${valueStr}`

    // Check if the key already exists in the search query
    const operators = ["<", ">", "=", "<=", ">=", "!"]
    const regex = new RegExp(`\\b${key}:(?:${operators.join("|")})?(?:\\"[^"]*\\"|\\S*)`, "g")
    const existingKeyMatch = searchQuery.match(regex)

    if (existingKeyMatch) {
      // If the key exists and is the same as the new search term, remove it
      if (existingKeyMatch.some(match => match === newSearchTerm)) {
        const replacedSearchTerm = existingKeyMatch.find(match => match === newSearchTerm)!
        const updatedQuery = searchQuery.replace(replacedSearchTerm, "").trim()
        setSearchQuery(updatedQuery)

        return updatedQuery
      } else {
        // If the key exists, but the search term is different, if the key is tag or negativePrompt, add new, otherwise replace
        if (key === "tag" || key === "negativePrompt" || key === "prompt") {
          const updatedQuery = `${searchQuery} ${newSearchTerm}`
          setSearchQuery(updatedQuery)

          return updatedQuery
        } else {
          const updatedQuery = searchQuery.replace(regex, newSearchTerm)
          setSearchQuery(updatedQuery)

          return updatedQuery
        }
      }
    } else {
      // If the key doesn't exist, add the new search term to the search query
      const updatedQuery = searchQuery.length > 0 ? `${searchQuery} ${newSearchTerm}` : newSearchTerm
      setSearchQuery(updatedQuery)

      return updatedQuery
    }
  }

  return {
    searchQuery,
    setSearchQuery,
    handleUpdateSearch,
  }
}
