import { PinnedItemType, SDStyleDetail } from "@/api/sdk"
import IconButton from "@/components/IconButton"
import { useState } from "react"

import FolderActionRow from "@/components/Workspace/FolderActions/FolderActionRow"
import {
  AddSquareIcon,
  DeleteIcon,
  EditIcon,
  LayerAddIcon,
  OpenNewTabIcon,
  PinIcon,
  RefreshIcon,
  RemoveSquareIcon,
  ShareIcon3,
  ShareIcon8,
  ThreeDotHorizontalIcon,
  UnPinIcon,
} from "@/components/shared/icons"
import { useToast } from "@/hooks"
import { useAuth } from "@/providers/authContext"
import { googleAnalytics } from "@/lib/gtag"
import useModalStore from "@/lib/store"
import { usePinMutation } from "@/queries"
import {
  useAddStyleToLibraryMutation,
  useDuplicateStyleMutation,
  useRemoveStyleFromLibraryMutation,
} from "@/queries/tools/style/useUpdateStyleMutation"
import { useDeleteItemStore, useRecoverItemStore, useRenameItemStore } from "@/stores"
import { cn } from "@/utils/cn"
import classNames from "classnames"
import useCustomRouter from "@/hooks/useCustomRouter"
import Popover from "@/components/Popover"

interface ActionButtonsProps {
  style: SDStyleDetail
  className?: string
  layout?: "grid" | "list"
}

const StyleCardActionButtons = (props: ActionButtonsProps) => {
  const { style, className, layout } = props

  const toast = useToast()
  const { user } = useAuth()
  const [isOpen, setIsOpen] = useState(false)
  const router = useCustomRouter()
  const setRenameStyle = useRenameItemStore(state => state.setRenamingItem)
  const setSharingData = useModalStore(state => state.setSharingData)
  const setDeleteItem = useDeleteItemStore(state => state.setDeletingItem)
  const setRecoverItem = useRecoverItemStore(state => state.setRecoverItem)

  const { mutate: duplicateStyle, isPending: isDuplicating } = useDuplicateStyleMutation({
    onSuccess: data => {
      if (router.query.tab === "shared-with-me") {
        router.push(`/workspace/tools/styles?tab=my-styles`, undefined, { shallow: true })
      }
    },
  })

  const { mutate: addToLibrary, isPending: isAddingToLibrary } = useAddStyleToLibraryMutation({
    onSuccess() {
      googleAnalytics.handleCategoryEvent({
        action: "click",
        params: {
          action: "Add style to library",
          tab_name: "styles",
          style_id: style.id,
          style_name: style.name ?? "",
        },
      })

      toast({
        status: "success",
        title: "Style added",
        message: ["Style has been added to your library"],
      })
    },
  })

  const { mutate: removeFromLibrary, isPending: isRemovingFromLibrary } = useRemoveStyleFromLibraryMutation({
    onSuccess() {
      googleAnalytics.handleCategoryEvent({
        action: "click",
        params: {
          action: "Remove style to library",
          tab_name: "styles",
          style_id: style.id,
          style_name: style.name ?? "",
        },
      })

      toast({
        status: "success",
        title: "Style removed",
        message: ["Style has been removed from your library"],
      })
    },
  })

  const handleNewTab = () => {
    setTimeout(() => {
      router.openNewTab(`/workspace/tools/styles/${style.id}`)
    }, 150)
  }

  const handleRename = () => {
    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        action: "Rename style",
        tab_name: "styles",
        style_id: style.id,
        style_name: style.name ?? "",
      },
    })

    setRenameStyle({
      type: "style",
      id: style.id,
      name: style.name || "My Style",
    })
  }

  const handleShare = () => {
    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        action: "Share style",
        tab_name: "styles",
        style_id: style.id,
        style_name: style.name ?? "",
      },
    })

    setSharingData({
      id: style.id,
      name: style.name ?? style.id,
      link: `${window.location.origin}/workspace/tools/styles/${style.id}`,
      type: "style",
      creator: style.creator,
      workspaceId: style.workspace.id,
      url: "",
    })
  }

  const { mutate: mutatePinTask } = usePinMutation({
    onSuccess: () => {
      toast({
        status: "success",
        title: `Style ${style.pinned ? "unpinned" : "pinned"}`,
      })
    },
  })

  const handlePin = () => {
    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        action: style.pinned ? "Unpin style" : "Pin style",
        tab_name: "styles",
        style_id: style.id,
        style_name: style.name ?? "",
      },
    })

    mutatePinTask({ ids: [style.id], type: PinnedItemType.STYLE, isPinned: !style.pinned })
  }

  const handleRecovery = () => {
    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        action: "Recover style",
        tab_name: "styles",
        style_id: style.id,
        style_name: style.name ?? "",
      },
    })

    setRecoverItem({
      ids: [style.id],
      type: "style",
      deletedAt: style.deletedAt,
    })
  }

  const isMutating = isDuplicating || isAddingToLibrary || isRemovingFromLibrary

  const actions = [
    {
      label: "Duplicate",
      icon: <LayerAddIcon width={16} height={16} />,
      onClick: () => duplicateStyle({ styleId: style.id }),
      visible: (!!style.discoverableAt || style.capabilities?.canUpdate) && !style.isDraft && !style.deletedAt,
      enabled: !isMutating,
      isLoading: isDuplicating,
    },
    {
      label: "Rename",
      icon: <EditIcon width={16} height={16} />,
      onClick: handleRename,
      visible: style.capabilities?.canUpdate && !style.deletedAt && style.creator.uid === user?.uid,
      enabled: !isMutating,
    },
    {
      label: "Share",
      icon: <ShareIcon8 width={16} height={16} />,
      onClick: handleShare,
      visible: style.capabilities?.canShare && !style.isDraft && !style.deletedAt,
      enabled: true,
    },
    {
      label: "Add to My Styles",
      icon: <AddSquareIcon width={16} height={16} />,
      onClick: () => addToLibrary({ styleId: style.id }),
      visible: style.capabilities?.canAddToLibrary && !style.deletedAt,
      enabled: !isMutating,
      isLoading: isAddingToLibrary,
    },
    {
      label: "Remove from My Styles",
      icon: <RemoveSquareIcon width={16} height={16} />,
      onClick: () => removeFromLibrary({ styleId: style.id }),
      visible: style.capabilities?.canRemoveFromLibrary && !style.deletedAt,
      enabled: !isMutating,
      isLoading: isRemovingFromLibrary,
    },
    {
      label: style.pinned ? "Unpin" : "Pin",
      icon: style.pinned ? <UnPinIcon width={16} height={16} /> : <PinIcon width={16} height={16} />,
      onClick: handlePin,
      visible: style.capabilities?.canView && !style.deletedAt && !style.isDraft,
      enabled: style.capabilities?.canView,
    },
    {
      label: "Open in new tab",
      icon: <OpenNewTabIcon width={16} height={16} />,
      onClick: handleNewTab,
      visible: style.capabilities?.canUpdate && !style.deletedAt,
      enabled: true,
    },
    {
      label: "Recover",
      icon: <RefreshIcon className="rotate-180" width={16} height={16} />,
      onClick: handleRecovery,
      enabled: style.capabilities?.canRecover,
      visible: !!style.deletedAt,
    },
    {
      label: "Delete",
      icon: <DeleteIcon className="text-red-500" width={16} height={16} />,
      onClick: () => {
        googleAnalytics.handleCategoryEvent({
          action: "click",
          params: {
            action: "Delete style",
            tab_name: "styles",
            style_id: style.id,
            style_name: style.name ?? "",
          },
        })

        setDeleteItem({
          id: style.id,
          type: "style",
          deletedAt: style.deletedAt,
        })
      },
      visible: style.capabilities?.canDelete,
      enabled: !isMutating,
    },
  ]

  return (
    <div
      className={cn(
        "transition-opacity duration-300",
        {
          "opacity-100 lg:opacity-100": isOpen,
          "absolute top-1.5 right-1.5 opacity-100 lg:opacity-0 group-hover:opacity-100": layout === "grid",
        },
        className,
      )}
    >
      <Popover
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        trigger={
          <IconButton
            colorScheme="secondary"
            className={classNames("bg-blackAlpha-700 px-2 min-h-[1.5rem]", {
              "bg-transparent hover:bg-transparent": className?.includes("bg-transparent"),
            })}
          >
            <ThreeDotHorizontalIcon className="w-3 h-3" />
          </IconButton>
        }
        align="end"
      >
        <div
          className={
            "text-sm w-[16rem] text-atherGray-300 bg-atherGray-900 border border-atherGray-850 p-2 rounded-2xl space-y-1"
          }
        >
          {actions
            .filter(a => a.visible)
            .map(action => (
              <FolderActionRow
                key={action.label}
                isLoading={action.isLoading}
                iconLeft={action.icon}
                onClick={e => {
                  e.stopPropagation()
                  action.onClick?.()
                  setIsOpen(false)
                }}
                isDisabled={!action.enabled}
              >
                {action.label}
              </FolderActionRow>
            ))}
        </div>
      </Popover>
    </div>
  )
}

export default StyleCardActionButtons
