"use client"

import HiddenFileInput from "@/components/HiddenFileInput"
import IconButton from "@/components/IconButton"
import { useImageDrop, useToast } from "@/hooks"
import getCroppedImg, { resizeImage } from "@/utils/resize-image"
import classNames from "classnames"
import { Fragment, forwardRef, useCallback, useEffect, useRef, useState } from "react"
import { isMobileOnly } from "react-device-detect"
import Cropper from "react-easy-crop"
import { FaCheck, FaCropAlt, FaTrash } from "react-icons/fa"
import { MdClose } from "react-icons/md"
import ReactSlider from "react-slider"
import { twMerge } from "tailwind-merge"
import { SearchIcon } from "../shared/icons/SearchIcon"
import { UploadIcon } from "../shared/icons/UploadIcon"
import { handleConvertHEICtoJPG } from "@/utils/convert"

interface UploadRecipeImageProps {
  onChange: (file?: File) => void
  value: File | undefined
  error?: string
  description?: string
  title?: string
  className?: string
  preview?: string
}
const UploadRecipeImage = forwardRef<HTMLInputElement, UploadRecipeImageProps>(
  ({ value, onChange, error, description, title, className, preview: previewOutside }, ref) => {
    const fileInputRef = useRef<HTMLInputElement>(null)
    const [preview, setPreview] = useState<string | null>(null)
    const toast = useToast({
      position: !isMobileOnly ? "top-center" : "bottom-center",
    })

    //image crop
    const [activeCrop, setActiveCrop] = useState(false)
    const [activeZoom, setActiveZoom] = useState(false)
    const [aspect, setAspect] = useState(1 / 1)
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [croppedImage, setCroppedImage] = useState<string | null>(null)

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => setCroppedAreaPixels(croppedAreaPixels), [])

    const showCroppedImage = useCallback(async () => {
      try {
        const croppedImage = await getCroppedImg(preview, croppedAreaPixels)
        if (!croppedImage) return
        setCroppedImage(croppedImage.url)
        onChange(croppedImage.file)
      } catch (error) {}

      setActiveCrop(false)
      setActiveZoom(false)
    }, [croppedAreaPixels])

    const handleUploadFromDevice = () => {
      if (fileInputRef.current) {
        fileInputRef.current.click()
      }
    }

    useEffect(() => {
      if (previewOutside) {
        setPreview(previewOutside)
      }
    }, [previewOutside])

    const { isOver: isDroppingImage, bind } = useImageDrop(async files => {
      // check file size < 5MB
      if (files.length > 0 && files[0].size > 10 * 1000 * 1000) {
        toast({
          status: "error",
          title: "File size too large",
          message: ["Input image exceeds 10MB, please scale down the image and try again"],
        })
        return
      }
      if (files.length > 0) {
        let file: Blob = files[0]

        let newFile = files[0]

        if (newFile.type === "image/heic" || newFile.name.endsWith(".heic")) {
          newFile = (await handleConvertHEICtoJPG(newFile)) as File
        }

        newFile = await resizeImage(newFile)

        onChange(newFile)
        setPreview(URL.createObjectURL(newFile))
        return
      }
      return null
    }, undefined)

    return (
      <div className={twMerge("w-full", className)} ref={ref}>
        {title && <p className="text-sm">{title}</p>}
        <p className="mb-2 text-sm text-atherGray-400">{description}</p>
        <div
          className={classNames(
            "relative bg-[#1E1E1E] border border-dashed border-[#52504C] rounded-xl font-normal text-sm cursor-pointer w-full flex flex-col items-center justify-center",
            {
              "bg-atherGray-800": isDroppingImage,
              "h-[17.5rem] px-4 py-6": !preview,
              "h-[17.5rem] border-transparent": preview,
            },
          )}
          onClick={() => {
            if (preview) return
            handleUploadFromDevice()
          }}
          {...bind}
        >
          {preview ? (
            <>
              {croppedImage ? (
                <Fragment>
                  <img src={croppedImage ?? ""} alt="Image Recipe" className="object-contain h-full" />
                </Fragment>
              ) : (
                <Fragment>
                  {activeCrop ? (
                    <>
                      <Cropper
                        classes={{
                          containerClassName: "w-full h-[17.5rem]",
                        }}
                        image={preview}
                        crop={crop}
                        zoom={zoom}
                        aspect={aspect}
                        onCropChange={setCrop}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                      />
                      <div className="flex flex-col items-end absolute bottom-14 right-4 z-[1] space-y-2">
                        <IconButton
                          colorScheme="secondary"
                          className="flex justify-center"
                          onClick={() => setActiveZoom(prev => !prev)}
                        >
                          <SearchIcon
                            className={classNames("", {
                              "text-[#A670FF]": activeZoom,
                            })}
                          />
                        </IconButton>
                        <IconButton
                          colorScheme="secondary"
                          className="text-[0.6rem] font-semibold w-[1.8rem] h-[1.8rem] flex items-center justify-center p-0"
                          onClick={() => {
                            if (aspect === 1 / 1) {
                              setAspect(3 / 2)
                            }
                            if (aspect === 3 / 2) {
                              setAspect(2 / 3)
                            }
                            if (aspect === 2 / 3) {
                              setAspect(16 / 9)
                            }
                            if (aspect === 16 / 9) {
                              setAspect(9 / 16)
                            }
                            if (aspect === 9 / 16) {
                              setAspect(1 / 1)
                            }
                          }}
                        >
                          {aspect === 1 / 1
                            ? "1:1"
                            : aspect === 3 / 2
                              ? "3:2"
                              : aspect === 2 / 3
                                ? "2:3"
                                : aspect === 16 / 9
                                  ? "16:9"
                                  : "9:16"}
                        </IconButton>
                        <IconButton colorScheme="secondary" className="flex justify-center" onClick={showCroppedImage}>
                          <FaCheck size="0.8rem" />
                        </IconButton>
                      </div>
                      <div
                        className={classNames(
                          "z-[1] absolute bottom-1 left-1/2 -translate-x-1/2 w-1/4 bg-blackAlpha-700 p-2",
                          {
                            hidden: !activeZoom,
                          },
                        )}
                      >
                        <ReactSlider
                          value={zoom}
                          onChange={v => setZoom(v)}
                          min={1}
                          max={3}
                          step={0.5}
                          className="w-full h-1 horizontal-slider"
                          thumbClassName="h-4 w-4 bg-white top-1/2 -translate-y-1/2 rounded-full cursor-pointer"
                          trackClassName="h-1 track-slider"
                          renderThumb={(props, _) => (
                            <div {...props}>
                              <div className="bg-[#A670FF] w-2.5 h-2.5 absolute top-1/2 left-1/2 -translate-x-[50%] -translate-y-[50%] rounded-full" />
                            </div>
                          )}
                        />
                      </div>
                    </>
                  ) : (
                    <Fragment>
                      <img src={preview} alt="Image Recipe" className="object-contain h-full" />
                      <IconButton
                        colorScheme="secondary"
                        className="absolute flex justify-center bottom-14 right-4"
                        onClick={() => setActiveCrop(true)}
                      >
                        <FaCropAlt size="0.8rem" />
                      </IconButton>
                    </Fragment>
                  )}
                </Fragment>
              )}
              <div className="absolute bottom-4 right-4 z-[1]">
                <IconButton
                  colorScheme="secondary"
                  onClick={e => {
                    e.stopPropagation()

                    setActiveZoom(false)
                    setZoom(1)
                    setAspect(1 / 1)

                    if (activeCrop) {
                      setActiveCrop(false)

                      return
                    }

                    setPreview(null)
                    onChange && onChange(undefined)
                    setCroppedImage(null)
                  }}
                  className=""
                >
                  {activeCrop ? <MdClose className="fill-red-500" /> : <FaTrash className="fill-red-500" />}
                </IconButton>
              </div>
            </>
          ) : (
            <>
              <div className="p-2 mb-2 rounded-full bg-atherPurple-500">
                <UploadIcon />
              </div>
              {isDroppingImage ? (
                <p className="z-20 text-atherOrange-500">Drop here</p>
              ) : (
                <p className="z-20 text-center text-atherGray-300">
                  Upload an image or drag and drop.
                  <br /> (Maximum size: 1024px and less than 10MB)
                </p>
              )}
              <p className="absolute bottom-0 left-0 py-1 px-2 text-xs text-[#EAE4D480]">
                *Uploading high res images will generate better results
              </p>
            </>
          )}
        </div>
        <HiddenFileInput
          multiple={false}
          onUpload={async files => {
            if (files.length > 0) {
              if (files[0].size > 10 * 1000 * 1000) {
                toast({
                  status: "error",
                  title: "File size too large",
                  message: ["Input image exceeds 10MB, please scale down the image and try again"],
                })
                return
              }

              if (preview) {
                URL.revokeObjectURL(preview)
              }

              let newFile = files[0]

              if (newFile.type === "image/heic" || newFile.name.endsWith(".heic")) {
                newFile = (await handleConvertHEICtoJPG(newFile)) as File
              }

              newFile = await resizeImage(newFile)

              onChange(newFile)
              setPreview(URL.createObjectURL(newFile))
            }
          }}
          ref={fileInputRef}
        />
        {error && <p className="mt-2 text-xs text-red-500">{error}</p>}
      </div>
    )
  },
)

export default UploadRecipeImage
