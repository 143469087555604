import { create } from 'zustand'
import { useEffect } from 'react'

type Callback = () => void
type CallbackId = string

interface CanvasClickStore {
  callbacks: Map<CallbackId, Callback>
  registerCallback: (callback: Callback) => CallbackId
  unregisterCallback: (id: CallbackId) => void
  triggerCallbacks: () => void
}

const generateId = () => Math.random().toString(36).substring(2, 9)

export const useCanvasClickStore = create<CanvasClickStore>((set, get) => ({
  callbacks: new Map(),

  registerCallback: (callback) => {
    const id = generateId()
    set(state => {
      const newCallbacks = new Map(state.callbacks)
      newCallbacks.set(id, callback)
      return { callbacks: newCallbacks }
    })
    return id
  },

  unregisterCallback: (id) => {
    set(state => {
      const newCallbacks = new Map(state.callbacks)
      newCallbacks.delete(id)
      return { callbacks: newCallbacks }
    })
  },

  triggerCallbacks: () => {
    const { callbacks } = get()
    callbacks.forEach(callback => callback())
  }
}))

// Hook for components to easily register/unregister callbacks
export const useCanvasClick = (callback: Callback) => {
  const { registerCallback, unregisterCallback } = useCanvasClickStore()

  useEffect(() => {
    const id = registerCallback(callback)
    return () => unregisterCallback(id)
  }, [])
}
