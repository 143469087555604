import { IconProps } from "."

export const EditIcon = (props: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M9.38117 2.59028C9.87796 2.05203 10.1264 1.78291 10.3903 1.62593C11.0272 1.24715 11.8114 1.23537 12.4589 1.59486C12.7273 1.74385 12.9833 2.00539 13.4954 2.52849C14.0075 3.05158 14.2635 3.31313 14.4093 3.58726C14.7612 4.24873 14.7497 5.04984 14.3789 5.70045C14.2252 5.97008 13.9618 6.22383 13.4349 6.73132L7.16578 12.7695C6.16728 13.7312 5.66803 14.2121 5.04407 14.4558C4.42011 14.6995 3.73417 14.6816 2.36227 14.6457L2.17562 14.6408C1.75797 14.6299 1.54914 14.6244 1.42776 14.4867C1.30637 14.3489 1.32294 14.1362 1.35608 13.7108L1.37408 13.4798C1.46737 12.2823 1.51401 11.6836 1.74784 11.1455C1.98166 10.6073 2.38499 10.1703 3.19165 9.29631L9.38117 2.59028Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path d="M8.66406 2.66663L13.3307 7.33329" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round" />
      <path
        d="M9.33203 14.6666L14.6654 14.6666"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
