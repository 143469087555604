import { SDStyleDetail, SDStyleTag, StyleImage, UpdateSDStyleDto } from "@/api/sdk"
import ImageUpload from "@/components/ImageUpload"
import { ImageViewWithDelete } from "@/components/ImageUpload/ImageView"
import { UploadIcon, XIcon } from "@/components/shared/icons"
import classNames from "classnames"
import { AnimatePresence } from "framer-motion"
import React, { Fragment, useRef, useState } from "react"
import AddTagInput from "@/components/Explore/Gallery/GalleryFeatures/AddTagInput"
import { googleAnalytics } from "@/lib/gtag"
import { useToast } from "@/hooks"
import { uploadStyleFiles } from "./StyleDetail"
import { useWorkspaceStyleDetailQuery } from "@/queries/tools/style/useGetStyleInfiniteQuery"
import { useTagsStyleMutation, useUnTagsStyleMutation } from "@/queries"
import { useQueryClient } from "@tanstack/react-query"

interface StyleFormInputProps {
  style: SDStyleDetail
  tags: SDStyleTag[]
  disabledInput?: boolean
  description: string
  setDescription: (description: string) => void
  updateStyle: (data: UpdateSDStyleDto) => void
  images: StyleImage[]
  setTags: React.Dispatch<React.SetStateAction<SDStyleTag[]>>
  onSave?: () => void
}

const StyleFormInput = ({
  style,
  disabledInput,
  tags,
  onSave,
  images,
  setTags,
  description,
  setDescription,
  updateStyle,
}: StyleFormInputProps) => {
  const [isUploading, setIsUploading] = useState(false)
  const fileInputThumbnailRef = useRef<HTMLInputElement>(null)
  const toast = useToast()
  const qc = useQueryClient()

  const { mutate: mutateTags } = useTagsStyleMutation()

  const { mutate: mutateUntags } = useUnTagsStyleMutation()

  const handleUploadThumbnail = async (file: Blob) => {
    if (!style) return

    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        action: "Upload Thumbnail",
        style_id: style.id,
        style_name: style.name ?? "",
      },
    })

    if (file.size > 10 * 1000 * 1000) {
      toast({
        status: "error",
        title: "File size too large",
        message: ["Input image exceeds 10MB, please scale down the image and try again"],
      })
      return
    }

    setIsUploading(true)

    const [uploaded] = await uploadStyleFiles([file])

    setIsUploading(false)

    return updateStyle({
      thumbnailId: uploaded.id,
      images,
      thumbnailHeight: (uploaded as any).metadata.height,
      thumbnailWidth: (uploaded as any).metadata.width,
    })
  }

  const handleRemoveThumbnail = async () => {
    if (!style) return

    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        action: "Remove Thumbnail",
        style_id: style.id,
        style_name: style.name ?? "",
      },
    })

    return updateStyle({
      thumbnailId: null,
      images,
    })
  }

  const onAddTagSuccess = (tag: SDStyleTag) => {
    mutateTags({
      tagIds: [tag.id],
      sdStyleIds: [style.id],
    })
    const key = useWorkspaceStyleDetailQuery.getKey({ styleId: style.id })

    const prevTags = qc.getQueryData(key) as SDStyleDetail | undefined

    if (prevTags) {
      qc.setQueryData(key, {
        ...prevTags,
        tags: [...prevTags.tags, tag],
      })
    }

    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        action: "Add Tag",
        style_id: style.id,
        style_name: style.name ?? "",
        tag_id: tag.id,
        tag_name: tag.name,
      },
    })
  }

  const handleDeleteTag = (tag: SDStyleTag) => {
    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        action: "Delete Tag",
        style_id: style.id,
        style_name: style.name ?? "",
        tag_id: tag.id,
        tag_name: tag.name,
      },
    })

    mutateUntags({
      tagIds: [tag.id],
      sdStyleIds: [style.id],
    })
    const key = useWorkspaceStyleDetailQuery.getKey({ styleId: style.id })

    const prevTags = qc.getQueryData(key) as SDStyleDetail | undefined

    if (prevTags) {
      qc.setQueryData(key, {
        ...prevTags,
        tags: prevTags.tags.filter(t => t.id !== tag.id),
      })
    }

    setTags(tags.filter(t => t.id !== tag.id))
  }

  return (
    <Fragment>
      <p className="font-semibold text-whiteAlpha-900 text-sm pb-1">Style thumbnail</p>
      <ImageUpload
        isLoading={isUploading}
        fileInputRef={fileInputThumbnailRef}
        upload={files => handleUploadThumbnail(files[0])}
        className={classNames({
          "border-transparent": style.thumbnailUrl,
          "!cursor-not-allowed": disabledInput,
        })}
        disabled={disabledInput}
      >
        {style.thumbnailUrl ? (
          <ImageViewWithDelete
            className={classNames("w-full", {
              "cursor-default": disabledInput,
            })}
            url={style.thumbnailUrl}
            alt="Recipe Image"
            onDelete={handleRemoveThumbnail}
            preferThumbnail
            disabled={disabledInput}
          />
        ) : (
          <div className="flex flex-col items-center justify-center h-full">
            <div
              className={classNames("p-2 mb-2 rounded-full", {
                "bg-atherPurple-500": !disabledInput,
                "bg-atherGray-850": disabledInput,
              })}
            >
              <UploadIcon />
            </div>
            <p className="text-atherGray-300 text-center">
              Upload images or Drag & Drop <br />
              (max 2048px and less than 10MB)
            </p>
          </div>
        )}
      </ImageUpload>

      <p className="font-semibold text-whiteAlpha-900 text-sm pb-1 mt-2">Tags</p>
      <div
        className={classNames("rounded-md flex flex-wrap w-full min-h-[2.25rem] p-2 gap-1", {
          "bg-atherGray-850": !disabledInput,
          "cursor-not-allowed bg-atherGray-850": disabledInput,
        })}
      >
        {tags?.map(tag => (
          <div key={tag.id} className="flex items-center px-2 py-0.5 border rounded-lg border-atherGray-800">
            <p className="mr-1 text-atherGray-300 text-sm">{tag.name}</p>
            <button
              className={classNames("flex items-center justify-center", {
                "cursor-not-allowed": disabledInput,
                "cursor-pointer": !disabledInput,
              })}
              type="button"
              disabled={disabledInput}
              onClick={() => {
                handleDeleteTag(tag)
              }}
            >
              <XIcon width={10} height={10} className="text-atherGray-300" />
            </button>
          </div>
        ))}
        <AnimatePresence mode="wait">
          {style?.capabilities?.canUpdate && (
            <AddTagInput
              className="bg-atherGray-700"
              tags={tags}
              setTags={setTags}
              onTag={tag => {
                onAddTagSuccess(tag)
              }}
              disabled={disabledInput}
            />
          )}
        </AnimatePresence>
      </div>

      <p className="font-semibold text-whiteAlpha-900 text-sm pb-1 mt-2">Description</p>
      <div
        className={classNames("relative overflow-hidden rounded-md p-1", {
          "bg-atherGray-850": !disabledInput,
          "bg-atherGray-800": disabledInput,
        })}
      >
        <textarea
          value={description}
          onChange={e => {
            onSave?.()
            setDescription(e.target.value)
          }}
          className="bg-transparent w-full pr-8 h-[5rem] py-1 px-1.5 text-sm"
          placeholder={"Describe your style"}
          disabled={disabledInput}
        />
      </div>
    </Fragment>
  )
}

export default StyleFormInput
