import { FileAssociatedResource, UpdatePromptDto } from "@/api/sdk"
import LoadingLogo from "@/components/LoadingLogo"
import Modal from "@/components/Modal"
import { useFileUpload, useToast } from "@/hooks"
import { useAddPromptMutation, useUpdatePromptMutation, useWorkspacePromptDetailQuery } from "@/queries"
import useCustomRouter from "@/hooks/useCustomRouter"
import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import PromptDetailView from "./PromptDetailView"
import PromptForm from "./PromptForm"

interface DetailPromptModalProps {
  isOpen: boolean
  onClose: () => void
  promptData?: UpdatePromptDto & {
    promptId?: string
    thumbnailUrl?: string
  }
}

const DetailPromptModal = ({ isOpen, onClose, promptData }: DetailPromptModalProps) => {
  const router = useCustomRouter()
  const { promptId, isUpdate } = router.query
  const [defaultPromptData, setDefaultPromptData] = useState(promptData)
  const [file, setFile] = useState<File | null>(null)
  const [preview, setPreview] = useState<string | null>(null)
  const { mutateAsync: mutateUpload, isPending: isUploading } = useFileUpload()

  useEffect(() => {
    if (!isOpen && !promptData) return

    setDefaultPromptData(promptData)
  }, [promptData, isOpen])

  const { data: promptDetail, isLoading } = useWorkspacePromptDetailQuery({
    variables: {
      promptId: promptId as string,
    },
  })

  const form = useForm<{
    thumbnailId: string
    description?: string
    prompt: string
  }>()
  const toast = useToast()

  const handleClose = () => {
    if (promptId) {
      router.push("/workspace/tools/prompts", undefined, {
        scroll: false,
        shallow: true,
      })
    } else {
      onClose()
    }
    setTimeout(() => {
      setDefaultPromptData(undefined)
      setFile(null)
      setPreview(null)
      form.reset({
        description: "",
        thumbnailId: "",
        prompt: "",
      })
    }, 1)
  }
  const { mutate: mutateAdd, isPending: isAdding } = useAddPromptMutation({
    onSuccess: () => {
      toast({
        title: "Prompt added successfully",
        status: "success",
      })

      handleClose()
    },
    onError: () => {
      toast({
        title: "Failed to add prompt",
        status: "error",
      })
    },
  })

  const { mutate: mutateUpdate, isPending: isEditing } = useUpdatePromptMutation({
    onSuccess: () => {
      toast({
        title: "Prompt updated successfully",
        status: "success",
      })

      handleClose()
    },
    onError: () => {
      toast({
        title: "Failed to update prompt",
        status: "error",
      })
    },
  })

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const valid = await form.trigger()

    if (!valid) return

    const values = form.getValues()
    let thumbnailId: string | undefined = values.thumbnailId || undefined

    if (file) {
      const result = await mutateUpload({
        files: [file],
        resourceType: FileAssociatedResource.PROMPT_LIBRARY,
      })

      thumbnailId = result[0].id
    }

    if (defaultPromptData && defaultPromptData.promptId) {
      mutateUpdate({
        promptId: defaultPromptData.promptId,
        data: {
          description: values.description,
          prompt: values.prompt,
          thumbnailId,
        },
      })
      return
    }

    mutateAdd({
      ...values,
      thumbnailId,
    })
  }

  const handleOpenUpdate = () => {
    setDefaultPromptData({ ...promptDetail!, promptId: promptDetail!.id })
  }

  useEffect(() => {
    if (!defaultPromptData) return

    const { promptId, thumbnailUrl, ...values } = defaultPromptData
    setPreview(thumbnailUrl || null)

    form.reset(values)
  }, [defaultPromptData, form])

  useEffect(() => {
    if (!promptDetail) return
    if (isUpdate && promptId) {
      setDefaultPromptData({
        ...promptDetail,
        promptId: promptDetail?.id,
      })
    }
  }, [isUpdate, promptId, promptDetail])

  const renderBody = () => {
    if (isLoading)
      return (
        <div className="flex items-center justify-center flex-1 w-full px-0 text-gray-600 md:px-6">
          <LoadingLogo />
        </div>
      )

    if (!promptDetail && promptId) return null

    if (promptDetail && !defaultPromptData)
      return <PromptDetailView promptDetail={promptDetail} onClose={handleClose} onOpenUpdate={handleOpenUpdate} />

    return (
      <PromptForm
        form={form}
        defaultPromptData={defaultPromptData}
        file={file}
        setFile={setFile}
        preview={preview}
        setPreview={setPreview}
        isUploading={isUploading}
        isAdding={isAdding}
        isEditing={isEditing}
        onSubmit={handleSubmit}
        onClose={handleClose}
      />
    )
  }

  return (
    <Modal
      bodyClassName="p-0"
      className="overflow-visible max-w-md modal-content-child"
      isOpen={isOpen || !!promptId}
      onClose={handleClose}
      title={
        promptId
          ? `Prompt: ${promptDetail?.name ?? ""}`
          : defaultPromptData && defaultPromptData.promptId
            ? "Edit to Prompt library"
            : "Add to Prompt library"
      }
    >
      {renderBody()}
    </Modal>
  )
}

export default DetailPromptModal
