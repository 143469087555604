import { IconProps } from "."

export const MoveToIcon = (props: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path d="M5 1L15 1" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M1 1H1.00898" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M1 7H1.00898" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M1 13H1.00898" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5 7L15 7" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M5 13L15 13" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
      <path
        d="M14.1716 14L14.6585 14.5705L14.6585 14.5705L14.1716 14ZM12.5131 14.4295C12.198 14.6985 12.1606 15.1719 12.4295 15.4869C12.6985 15.802 13.1719 15.8394 13.4869 15.5705L12.5131 14.4295ZM13.4869 10.4295C13.1719 10.1606 12.6985 10.198 12.4295 10.5131C12.1606 10.8281 12.198 11.3015 12.5131 11.5705L13.4869 10.4295ZM14.1716 12L14.6585 11.4295L14.6585 11.4295L14.1716 12ZM13.6847 13.4295L12.5131 14.4295L13.4869 15.5705L14.6585 14.5705L13.6847 13.4295ZM12.5131 11.5705L13.6847 12.5705L14.6585 11.4295L13.4869 10.4295L12.5131 11.5705ZM14.6585 14.5705C14.9178 14.3491 15.1721 14.1342 15.3521 13.9329C15.5416 13.7209 15.75 13.4155 15.75 13H14.25C14.25 12.8774 14.3204 12.8363 14.2337 12.9332C14.1375 13.0408 13.9776 13.1795 13.6847 13.4295L14.6585 14.5705ZM13.6847 12.5705C13.9776 12.8205 14.1375 12.9592 14.2337 13.0668C14.3204 13.1637 14.25 13.1226 14.25 13H15.75C15.75 12.5845 15.5416 12.2791 15.3521 12.0671C15.1721 11.8658 14.9178 11.6509 14.6585 11.4295L13.6847 12.5705Z"
        fill="currentColor"
      />
    </svg>
  )
}
