import { SDWorkflowFilterMode, SDWorkflowSortOption } from "@/api/sdk"
import IconButton from "@/components/IconButton"
import ImageWebpComponent from "@/components/ImageWebpComponent"
import Popover from "@/components/Popover"
import { CheckCircleIcon, FilterIcon, SortIcon } from "@/components/shared/icons"
import { useDebounce } from "@/hooks"
import { useAuth } from "@/providers/authContext"
import { useWorkspaceSearchHistory } from "@/stores"
import { cn } from "@/utils/cn"
import classNames from "classnames"
import Link from "next/link"
import { useState } from "react"
import SearchBar from "../../Workspace/FoldersV2/SearchBar"
import UserCreated from "@/components/Explore/Gallery/UserCreated"
import { googleAnalytics } from "@/lib/gtag"
import { useComfyUiWorkflowInfiniteQuery } from "@/queries/tools/comfyui-recipe/getComfyUiRecipeQueries"
import { filterDiscoverAtOptions } from "@/components/Workspace/Workflows/WorkflowTabActions"
import CheckboxComponent from "@/components/Checkbox"
import TrashDeleteAllButton from "@/components/Workspace/FoldersV2/TrashDeleteAllButton"

interface ComfyUITabActionsProps {
  mode: SDWorkflowFilterMode
  sort: SDWorkflowSortOption
  setSort: (sort: SDWorkflowSortOption) => void
  className?: string
  onConfirmSearch?: (search: string) => void
  onRemoveHistory?: (text: string) => void
  selectionMode?: boolean
  itemLength?: number
  toggleSelectionMode?: (v: boolean) => void
  filter: {
    shared?: string[]
  } | null
  setFilter: React.Dispatch<React.SetStateAction<{ shared?: string[] } | null>>
}

export const sdWorkflowSortOptions: { sortBy: SDWorkflowSortOption; label: string }[] = [
  {
    sortBy: SDWorkflowSortOption.NEWEST,
    label: "Newest",
  },
  {
    sortBy: SDWorkflowSortOption.OLDEST,
    label: "Oldest",
  },
  {
    sortBy: SDWorkflowSortOption.A_TO_Z,
    label: "Name A - Z",
  },
  {
    sortBy: SDWorkflowSortOption.Z_TO_A,
    label: "Name Z - A",
  },
]

const ComfyUITabActions = ({
  mode,
  sort: sortBy,
  setSort,
  className,
  itemLength,
  onConfirmSearch,
  onRemoveHistory,
  filter,
  setFilter,
  selectionMode,
  toggleSelectionMode,
}: ComfyUITabActionsProps) => {
  const { user } = useAuth()

  const history = useWorkspaceSearchHistory(s =>
    s.history.filter(item => item.uid === user?.uid && item.location === "tools/comfyui"),
  )

  const [search, setSearch] = useState("")
  const debouncedSearch = useDebounce(search, 350)
  const [filterDropdown, setFilterDropdown] = useState(false)

  const [sortDropdown, setSortDropdown] = useState(false)

  const {
    flattenData: previewResult,
    isLoading,
    total,
  } = useComfyUiWorkflowInfiniteQuery({
    variables: {
      mode,
      take: 5,
      searchTerm: debouncedSearch,
      sortBy,
    },
    enabled: !!debouncedSearch && !!user?.uid,
  })

  const buildPreviewResult = (search: string) => {
    if (isLoading) {
      return (
        <div className="p-1">
          <p className="text-atherGray-300 text-sm">Searching...</p>
        </div>
      )
    }

    if (!isLoading && previewResult?.length === 0) {
      return (
        <div className="p-1">
          <p className="text-atherGray-300 text-sm">No results found</p>
        </div>
      )
    }
    if (previewResult && previewResult.length > 0) {
      const resultLeft = total - previewResult.length

      return (
        <div
          className="flex flex-col space-y-2  overflow-y-auto overflow-x-hidden max-h-[55vh]"
          onMouseEnter={e => {
            e.stopPropagation()
            e.preventDefault()
          }}
        >
          {previewResult?.map(comfyui => (
            <Link
              key={comfyui.id}
              className="flex w-full rounded-2xl items-center hover:bg-atherGray-850 p-1 px-2 cursor-pointer"
              href={`/workspace/tools/comfyui/${comfyui.id}`}
            >
              <ImageWebpComponent
                className="w-12 h-12 rounded-lg object-cover"
                src={comfyui.thumbnailUrl ?? ""}
                alt={comfyui.name}
              />
              <div className="ml-2 overflow-hidden flex-1">
                <p className="text-sm line-clamp-1 font-semibold mb-1">{comfyui.name}</p>
                <UserCreated
                  username={comfyui.creator?.username ?? ""}
                  name={comfyui.creator?.name ?? ""}
                  picture={comfyui.creator?.picture ?? ""}
                  size="xs"
                />
              </div>
            </Link>
          ))}
          {resultLeft > 0 && (
            <button
              className="text-xs font-semibold text-atherGray-300 hover:text-atherGray-0 hover:underline text-left"
              onClick={() => onConfirmSearch?.(search)}
            >
              ...and {resultLeft} other {resultLeft > 1 ? "results" : "result"}
            </button>
          )}
        </div>
      )
    }

    return null
  }

  const handleSetSort = (newSortBy: SDWorkflowSortOption) => {
    if (newSortBy === sortBy) return

    setSort(newSortBy)
    setSortDropdown(false)
  }

  return (
    <div className={cn("flex flex-1 justify-end md:space-x-2", className)}>
      <SearchBar
        search={search}
        setSearch={setSearch}
        inputClassName="md:w-80"
        onConfirmSearch={onConfirmSearch}
        onClear={() => onConfirmSearch?.("")}
        history={history}
        previewResultBuilder={buildPreviewResult}
        onClearSearchHistory={value => onRemoveHistory?.(value.text)}
      />
      {toggleSelectionMode && (
        <IconButton
          colorScheme="transparent"
          className={cn(
            "rounded-2xl p-2 px-3 hover:bg-atherGray-850 flex items-center text-atherGray-300 transition-all",
            {
              "bg-atherGray-850 text-atherGray-0": selectionMode,
            },
          )}
          onClick={() => {
            googleAnalytics.event({
              action: "click",
              category: "filter",
              label: `comfyui_multi_select`,
            })
            toggleSelectionMode(!selectionMode)
          }}
        >
          <CheckCircleIcon width={20} height={20} checked={selectionMode} />
          <span className="font-normal ml-1 text-sm hidden md:block">Multi-select</span>
        </IconButton>
      )}
      {mode === SDWorkflowFilterMode.Trash && (
        <TrashDeleteAllButton type="comfyui-workflow" disabled={itemLength === 0} />
      )}
      <Popover
        isOpen={filterDropdown}
        setIsOpen={setFilterDropdown}
        trigger={
          <IconButton
            colorScheme="transparent"
            className={cn("rounded-2xl px-2 hover:bg-atherGray-85", {
              "bg-atherGray-850": filterDropdown,
              "border border-atherPurple-500": !!filter?.shared?.length,
              hidden: mode === SDWorkflowFilterMode.Trash,
            })}
          >
            <FilterIcon />
          </IconButton>
        }
        align="end"
      >
        <div className="p-3 rounded-2xl bg-atherGray-900 border border-atherGray-800 flex min-w-40 flex-col space-y-2">
          <div className="flex flex-col">
            <div className="flex w-full items-center justify-between mb-2">
              <p className="text-xs text-atherGray-100 font-semibold">Filter</p>
            </div>
            <div className={"flex items-center space-x-4 text-sm"}>
              {filterDiscoverAtOptions.map(option => (
                <CheckboxComponent
                  key={option.value}
                  label={option.display}
                  checked={filter?.shared?.includes(option.value) ?? false}
                  onChange={value => {
                    setFilter(prev => {
                      if (prev?.shared?.includes(option.value)) {
                        return {
                          shared: prev.shared?.filter(i => i !== option.value),
                        }
                      }
                      return {
                        shared: [...(prev?.shared ?? []), option.value],
                      }
                    })
                  }}
                />
              ))}
            </div>
          </div>
        </div>
      </Popover>
      <Popover
        isOpen={sortDropdown}
        setIsOpen={setSortDropdown}
        trigger={
          <IconButton
            colorScheme="transparent"
            className={cn("rounded-2xl px-2 hover:bg-atherGray-85", {
              "bg-atherGray-850": sortDropdown,
              "border border-atherPurple-500": sortBy !== SDWorkflowSortOption.NEWEST,
            })}
          >
            <SortIcon />
          </IconButton>
        }
        align="end"
        className="origin-top-right"
      >
        <div className="p-3 rounded-2xl bg-atherGray-900 border border-atherGray-800 flex w-40 flex-col space-y-2">
          {sdWorkflowSortOptions.map(option => (
            <IconButton
              onClick={() => handleSetSort(option.sortBy)}
              key={option.label}
              className={classNames(
                "flex items-center text-left hover:bg-atherGray-850 py-2 justify-start px-4 active:bg-atherGray-800 bg-transparent w-full cursor-pointer rounded-xl",
                {
                  "bg-atherGray-850 text-atherGray-0": sortBy === option.sortBy,
                  "text-atherGray-300": sortBy !== option.sortBy,
                },
              )}
            >
              {option.label}
            </IconButton>
          ))}
        </div>
      </Popover>
    </div>
  )
}

export default ComfyUITabActions
