import { IconProps } from "."

export const UploadIcon5 = (props: IconProps) => {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8.5026 3.33333L8.5026 10M8.5026 3.33333C8.03579 3.33333 7.16362 4.66287 6.83594 5M8.5026 3.33333C8.96942 3.33333 9.84158 4.66287 10.1693 5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.83594 12.6666H13.1693"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
