import client from "@/api/client"
import { CheckIfWhiteListedEmailsResult } from "@/api/sdk"
import { createQuery } from "react-query-kit"
import { enableQueryMiddleware } from "./middlewares/enableQueryMiddleware"

export const useWhitelistUserQuery = createQuery<CheckIfWhiteListedEmailsResult, { emails: string[] }, Error>({
  primaryKey: "whitelist-users",
  queryFn: ({ queryKey: [_primaryKey, variables] }) =>
    client.api
      .userControllerCheckIfWhitelisted({
        emails: variables.emails,
      })
      .then(res => res.data),

  use: [enableQueryMiddleware(v => !!v.emails && v.emails.length > 0)],
})
