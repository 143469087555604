import client from "@/api/client"
import { ExploreRecipeItem } from "@/api/sdk"
import { createMutation, createQuery } from "react-query-kit"
import { enableQueryMiddleware } from "../middlewares/enableQueryMiddleware"

export interface ExploreRecipeDetailQueryVariables {
  recipeId: string
  userUid?: string
}

export const useExploreDetailRecipeQuery = createQuery<ExploreRecipeItem, ExploreRecipeDetailQueryVariables, Error>({
  primaryKey: "explore-detail-recipe",
  queryFn: ({ queryKey: [_primaryKey, variables] }) =>
    client.api.exploreRecipeControllerGetExploreRecipeById(variables.recipeId).then(res => res.data),
  use: [enableQueryMiddleware(variables => !!variables.recipeId)],
})

export const useGetExploreDetailRecipeMutation = createMutation({
  mutationFn: (recipeId: string) =>
    client.api.exploreRecipeControllerGetExploreRecipeById(recipeId).then(res => res.data),
})
