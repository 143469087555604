import { PickTypeClass } from "@/api/sdk"
import { createContext, useContext, useEffect, useState } from "react"

export type ExploreContext = {
  setCreatedBy: React.Dispatch<React.SetStateAction<string | null>>
  createdBy: string | null
  selectTags: PickTypeClass[]
  setSelectTags: React.Dispatch<React.SetStateAction<PickTypeClass[]>>
  category: string[] | null
  setCategory: React.Dispatch<React.SetStateAction<string[] | null>>
  isBookmarked: boolean
  setIsBookmarked: React.Dispatch<React.SetStateAction<boolean>>
  sort: {
    sortBy?: string
    sortOrder?: "ASC" | "DESC"
  }
  setSort: React.Dispatch<
    React.SetStateAction<{
      sortBy?: string
      sortOrder?: "ASC" | "DESC"
    }>
  >
}

const ExploreContext = createContext<ExploreContext | null>(null)

export const useExploreContext = () => {
  const context = useContext(ExploreContext)
  if (!context) throw new Error("missing ExploreProvider")
  return context
}

export function ExploreProvider({ children }: { children: React.ReactNode }) {
  const [selectTags, setSelectTags] = useState<PickTypeClass[]>([])
  const [category, setCategory] = useState<string[] | null>(null)
  const [isBookmarked, setIsBookmarked] = useState(false)
  const [createdBy, setCreatedBy] = useState<string | null>(null)

  const [sort, setSort] = useState<{
    sortBy?: string
    sortOrder?: "ASC" | "DESC"
  }>({
    sortBy: undefined,
    sortOrder: "DESC",
  })

  return (
    <ExploreContext.Provider
      value={{
        createdBy,
        setCreatedBy,
        selectTags,
        setSelectTags,
        category,
        setCategory,
        isBookmarked,
        setIsBookmarked,
        sort,
        setSort,
      }}
    >
      {children}
    </ExploreContext.Provider>
  )
}
