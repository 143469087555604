import client from "@/api/client"
import { createMutation } from "react-query-kit"
import { actionMiddleware } from "../middlewares/actionMiddleware"
import { useGetBackupStatusQuery } from "./useGetBackupStatusQuery"
import { BackupItem, BackupRequestStatus } from "@/api/sdk"

export const useRequestBackupMutation = createMutation({
  mutationFn: () => client.api.backupControllerRequestBackup(),

  use: [
    actionMiddleware({
      onMutate: ({ queryClient }) => {
        const backupKey = useGetBackupStatusQuery.getKey()

        const backupData = queryClient.getQueryData<BackupItem>(backupKey)

        if (backupData) {
          queryClient.setQueryData<BackupItem>(backupKey, {
            ...backupData,
            status: BackupRequestStatus.Processing,
          })
        }
      },
      onSuccess: ({ queryClient, variables }) => {
        const backupKey = useGetBackupStatusQuery.getKey()

        queryClient.invalidateQueries({ queryKey: backupKey })
      },
      onError: ({ queryClient }) => {
        const backupKey = useGetBackupStatusQuery.getKey()

        const backupData = queryClient.getQueryData<BackupItem>(backupKey)

        if (backupData) {
          queryClient.setQueryData<BackupItem>(backupKey, {
            ...backupData,
            status: BackupRequestStatus.Failed,
          })
        }
      },
    }),
  ],
})
