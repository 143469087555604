import client from "@/api/client"
import { ExploreSDStyleDetail } from "@/api/sdk"
import { createQuery } from "react-query-kit"
import { enableQueryMiddleware } from "../middlewares/enableQueryMiddleware"

export interface ExploreStyleDetailQueryVariables {
  styleId: string
  userUid?: string
}

export const useExploreStyleDetailQuery = createQuery<ExploreSDStyleDetail, ExploreStyleDetailQueryVariables, Error>({
  primaryKey: "explore-style-detail",
  queryFn: ({ queryKey: [_primaryKey, variables] }) =>
    client.api.exploreSdStyleControllerGetStyle(variables.styleId!).then(res => res.data),
  use: [enableQueryMiddleware(variables => !!variables.styleId)],
})
