import { FileAssociatedResource, UploadFile } from "@/api/sdk"
import AspectRatio from "@/components/AspectRatio"
import HiddenFileInput from "@/components/HiddenFileInput"
import IconButton from "@/components/IconButton"
import { useFileUpload, useImageDrop, useToast } from "@/hooks"
import { useAuth } from "@/providers/authContext"
import { useMutateUpdateUser } from "@/queries"
import { resizeImage } from "@/utils/resize-image"
import classNames from "classnames"
import { motion } from "framer-motion"
import { forwardRef, useEffect, useRef, useState } from "react"
import { MdAddCircle, MdOutlineImage } from "react-icons/md"
import { twMerge } from "tailwind-merge"
import AttachDragTo from "../Explore/Gallery/GalleryFeatures/Article/AttachDragTo"
import { ArrowExpandIcon, CameraIcon, DeleteIcon, UploadIcon } from "../shared/icons"
import { googleAnalytics } from "@/lib/gtag"
import { handleConvertHEICtoJPG } from "@/utils/convert"

interface UploadImagesProps {
  url?: string
  positionY?: string
  className?: string
  ratio?: number
  disabled?: boolean
}

const UploadImageCover = forwardRef<HTMLInputElement, UploadImagesProps>(
  ({ url, positionY, className, ratio = 16 / 8, disabled }, ref) => {
    const fileInputRef = useRef<HTMLInputElement>(null)
    const { mutateAsync: mutateUpload, isPending: isLoadingUpload } = useFileUpload()
    const { user, userInfoQuery } = useAuth()

    const [preview, setPreview] = useState<{ url: string; positionY: string } | null>(null)
    const [file, setFile] = useState<File | null>(null)

    const [mode, setMode] = useState<"EDIT" | "UPLOAD" | null>(null)

    const toast = useToast()

    const handleUploadFromDevice = () => {
      googleAnalytics.handleCategoryEvent({
        action: "click",
        params: {
          action: "Upload cover image",
          profile_username: userInfoQuery?.data?.username ?? "",
          profile_id: userInfoQuery?.data?.uid ?? "",
        },
      })

      if (fileInputRef.current) {
        fileInputRef.current.click()
      }
    }

    useEffect(() => {
      if (url) {
        setPreview(prev => ({
          url,
          positionY: prev?.positionY ?? positionY ?? "0",
        }))
      }
    }, [url, positionY])

    const { isOver: isDroppingImage, bind } = useImageDrop(async files => {
      // check file size < 5MB

      if (!files.length) return

      let newFile = files[0]

      if (newFile.type === "image/heic" || newFile.name.endsWith(".heic")) {
        newFile = (await handleConvertHEICtoJPG(newFile)) as File
      }

      newFile = await resizeImage(newFile, 1920)

      preview?.url && URL.revokeObjectURL(preview.url)

      setFile(newFile)
      setPreview({
        url: URL.createObjectURL(newFile),
        positionY: "",
      })

      return null
    }, undefined)

    const { mutate: mutateUpdateUser, isPending: isMutatingUpdate } = useMutateUpdateUser({
      onSuccess: () => {
        toast({
          status: "success",
          title: "Update cover image successfully",
        })
        setMode(null)
        setFile(null)
      },
    })

    const handleSave = async () => {
      let imageUpload: UploadFile | undefined

      if (file) {
        const result = await mutateUpload({
          resourceType: FileAssociatedResource.USER_COVER_IMAGE,
          files: [file],
          onProgress: () => {},
          onError: () => {
            toast({
              status: "error",
              title: "Error",
              message: ["Failed to upload image"],
            })
          },
        }).catch(() => {
          toast({
            status: "error",
            title: "Error",
            message: ["Failed to upload image"],
          })
        })

        imageUpload = result[0]
      }

      googleAnalytics.handleCategoryEvent({
        action: "click",
        params: {
          action: "Save cover image",
          profile_username: userInfoQuery?.data?.username ?? "",
          profile_id: userInfoQuery?.data?.uid ?? "",
        },
      })

      mutateUpdateUser({
        data: {
          coverImageY: preview?.positionY,
          coverImageFileId: imageUpload?.id,
        },
        userUid: user?.uid ?? "",
      })
    }

    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3 }}
        className={twMerge("w-full", className)}
        ref={ref}
      >
        <AspectRatio
          ratio={ratio}
          containerStyle={{
            paddingBottom: ratio === 0 ? "0" : `${100 / ratio}%`,
            height: ratio === 0 ? "100%" : `auto`,
          }}
          className={classNames("relative font-normal text-sm cursor-pointer w-full flex flex-col overflow-hidden", {
            "bg-atherGray-800": isDroppingImage,
          })}
          onClick={() => {
            if (preview) return
            handleUploadFromDevice()
          }}
          {...bind}
        >
          {preview ? (
            mode ? (
              <div className="w-full h-full relative">
                <AttachDragTo
                  value={preview.positionY}
                  onChange={y => {
                    if (preview.positionY !== y) {
                      setPreview(prev => ({
                        url: prev?.url ?? "",
                        positionY: y,
                      }))
                    }
                  }}
                  imageUrl={preview.url}
                />
                <motion.div
                  initial={{ opacity: 1 }}
                  animate={{ opacity: 0 }}
                  transition={{ duration: 0.35, delay: 3.5 }}
                  className="hidden absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 pointer-events-none md:flex items-center space-x-2 bg-blackAlpha-500 text-atherGray-100 py-1 px-2"
                >
                  <ArrowExpandIcon className="rotate-45" width={14} height={14} />
                  <div>
                    <p className="text-xs">Drag to move cover photo</p>
                    <p className="text-[0.65rem] font-semibold">Optimal dimensions: 2560 x 328px</p>
                  </div>
                </motion.div>
              </div>
            ) : (
              <>
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.3, delay: 0.5 }}
                  className="h-full relative w-full"
                >
                  <AspectRatio ratio={ratio} className="relative w-full flex flex-col overflow-hidden">
                    <div className="relative w-full h-full overflow-hidden">
                      <div className="absolute w-full top-0" style={{ top: `${parseInt(positionY ?? "0")}%` }}>
                        <img
                          src={preview.url}
                          alt="Cover Image"
                          className="object-cover w-full min-h-[146px]"
                          draggable={false}
                        />
                      </div>
                    </div>
                  </AspectRatio>
                </motion.div>
              </>
            )
          ) : (
            <>
              <div className="flex flex-col items-center text-center mb-2">
                <div className="relative">
                  <MdOutlineImage className="text-6xl fill-atherGray-300" />
                  <MdAddCircle className="absolute right-0 bottom-0 translate-x-4 text-xl fill-atherGray-300" />
                </div>
              </div>
              {isDroppingImage ? (
                <p className="text-atherOrange-500 z-20">Drop here</p>
              ) : (
                <p className="text-atherGray-300 text-center z-20">Upload cover image</p>
              )}
            </>
          )}
        </AspectRatio>

        {preview ? (
          mode ? (
            <div className="absolute bottom-2 right-2 flex items-center space-x-2 p-2 md:px-6">
              {file ? (
                <IconButton
                  disabled={isMutatingUpdate || isLoadingUpload}
                  onClick={() => {
                    if (file) {
                      preview?.url && URL.revokeObjectURL(preview.url)
                    }
                    setMode(null)
                    setFile(null)

                    googleAnalytics.handleCategoryEvent({
                      action: "click",
                      params: {
                        action: "Delete cover image",
                        profile_username: userInfoQuery?.data?.username ?? "",
                        profile_id: userInfoQuery?.data?.uid ?? "",
                      },
                    })

                    setPreview({
                      url: url ?? "",
                      positionY: positionY ?? "0",
                    })
                  }}
                  colorScheme="secondary"
                  className="min-h-0 p-2 text-xs"
                >
                  <DeleteIcon className="text-red-500" />
                </IconButton>
              ) : (
                <>
                  <IconButton
                    onClick={() => {
                      if (file) {
                        preview?.url && URL.revokeObjectURL(preview.url)
                      }

                      googleAnalytics.handleCategoryEvent({
                        action: "click",
                        params: {
                          action: "Cancel cover image",
                          profile_username: userInfoQuery?.data?.username ?? "",
                          profile_id: userInfoQuery?.data?.uid ?? "",
                        },
                      })

                      setMode(null)
                      setFile(null)
                    }}
                    colorScheme="secondary"
                    className="min-h-0 p-2 text-xs"
                  >
                    Cancel
                  </IconButton>
                  <IconButton
                    title="Upload from device"
                    onClick={() => {
                      handleUploadFromDevice()
                    }}
                    className="min-h-0 p-2 text-xs"
                  >
                    <UploadIcon width={14} height={14} />
                  </IconButton>
                </>
              )}
              <IconButton
                isLoading={isMutatingUpdate || isLoadingUpload}
                onClick={() => {
                  handleSave()
                }}
                className="min-h-0 p-2 text-xs"
              >
                Save
              </IconButton>
            </div>
          ) : (
            <>
              {!disabled && (
                <div className="absolute bottom-2 right-2 items-center space-x-2 p-2 md:px-6 hidden md:flex">
                  <IconButton
                    onClick={() => {
                      googleAnalytics.handleCategoryEvent({
                        action: "click",
                        params: {
                          action: "Edit cover image",
                          profile_username: userInfoQuery?.data?.username ?? "",
                          profile_id: userInfoQuery?.data?.uid ?? "",
                        },
                      })

                      setMode("EDIT")
                    }}
                    colorScheme="secondary"
                    className="min-h-0 p-2"
                  >
                    <CameraIcon width={16} height={16} />
                  </IconButton>
                </div>
              )}
            </>
          )
        ) : null}
        <HiddenFileInput
          multiple={false}
          onUpload={async files => {
            if (!files.length) return

            let newFile = files[0]

            if (newFile.type === "image/heic" || newFile.name.endsWith(".heic")) {
              newFile = (await handleConvertHEICtoJPG(newFile)) as File
            }

            newFile = await resizeImage(newFile, 2560)

            preview?.url && URL.revokeObjectURL(preview.url)
            const newUrl = URL.createObjectURL(newFile)

            let img = new Image()
            img.src = newUrl

            img.onload = () => {
              if (img.width < 1200) {
                toast({
                  status: "error",
                  title: "Image too small",
                  message: ["Please choose an image that is at least 1200px wide"],
                })

                URL.revokeObjectURL(newUrl)
              } else {
                setFile(newFile)
                setPreview({
                  url: newUrl,
                  positionY: "0",
                })
              }
            }
          }}
          ref={fileInputRef}
        />
      </motion.div>
    )
  },
)

export default UploadImageCover
