import { SIMPLE_GENERATE_RECIPE_ID } from "@/components/Explore/Gallery/GalleryHeader/GalleryGeneratedImage"
import { AddToPromptIcon, CheckCircleIcon } from "@/components/shared/icons"
import { useTempState } from "@/hooks"
import useCustomRouter from "@/hooks/useCustomRouter"
import { googleAnalytics } from "@/lib/gtag"
import { cn } from "@/lib/utils"
import { useImageParamStore, useRecipeImageStore } from "@/stores"
import { AnimatePresence, motion } from "framer-motion"

interface ButtonCopyPromptProps {
  prompt: string
  className?: string
  size?: number
  taskId?: string
  onClick?: () => void
}

const ButtonCopyPrompt = ({ prompt, className, size = 20, taskId, onClick }: ButtonCopyPromptProps) => {
  const [isCopying, setIsCopying] = useTempState(false, 750)
  const router = useCustomRouter()
  const setParams = useImageParamStore(state => state.setParams)
  const setRecipeImage = useRecipeImageStore(state => state.setRecipeImage)

  return (
    <div className="relative overflow-hidden flex items-center justify-center">
      <AnimatePresence mode="popLayout" key={isCopying ? "btn-copy" : "btn-action"}>
        {isCopying ? (
          <motion.span
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
              duration: 0.5,
              ease: "easeInOut",
            }}
            key="btn-copy"
            exit={{ opacity: 0 }}
            className={cn("p-0 min-h-0 flex items-center justify-center", className)}
          >
            <CheckCircleIcon background="#C8C4B5" className="text-[#C8C4B5]" width={size} height={size} />
          </motion.span>
        ) : (
          <motion.button
            aria-label="Copy prompt"
            title="Copy prompt"
            key="btn-action"
            transition={{
              duration: 0.5,
              ease: "easeInOut",
            }}
            className={cn("p-0 min-h-0", className)}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={async e => {
              e.stopPropagation()
              e.preventDefault()
              setIsCopying(true)

              if (window.location.pathname.includes("/explore")) {
                googleAnalytics.event({
                  action: "explore_click",
                  category: "explore",
                  label: `click`,
                  params: {
                    tab_name: "image",
                    action: "copy_prompt",
                    prompt: prompt,
                    task_id: taskId || "",
                    page_id: window.location.pathname,
                  },
                })
              } else {
                googleAnalytics.event({
                  action: "click",
                  category: "image",
                  label: "copy_prompt",
                  params: {
                    prompt: prompt,
                    task_id: taskId || "",
                    pathname: window.location.pathname,
                  },
                })
              }

              if (onClick) {
                onClick()

                return
              }

              if (router.pathname.includes("/workspace/tools/recipe")) {
                setRecipeImage({
                  id: SIMPLE_GENERATE_RECIPE_ID,
                  isModal: false,
                  params: {
                    prompt,
                  },
                })

                return
              }
              setParams({ prompt })
            }}
          >
            <AddToPromptIcon className="text-atherGray-300" width={size} height={size} />
          </motion.button>
        )}
      </AnimatePresence>
    </div>
  )
}

export default ButtonCopyPrompt
