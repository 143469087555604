import { RecipeTask, RecipeTaskSortByColumn, RecipeTasksWithTotalResponseForAgentScheduler } from "@/api/sdk"
import { SortIcon } from "@/components/shared/icons"
import { useOutsideClick } from "@/hooks"
import { googleAnalytics } from "@/lib/gtag"
import { useGetTaskInfiniteQuery } from "@/queries/task/useGetTaskInfiniteQuery"
import { useQueryClient } from "@tanstack/react-query"
import classNames from "classnames"
import { AnimatePresence, motion } from "framer-motion"
import capitalize from "lodash/capitalize"
import { memo, useEffect, useMemo, useRef, useState } from "react"
import { FaSortAlphaDown, FaSortAlphaDownAlt, FaSortAmountDown, FaSortAmountDownAlt } from "react-icons/fa"
import IconButton from "../../IconButton"
import useMutateRowScheduler from "./useMutateRowScheduler"
import { TaskProgressSocketEvent } from "@/providers/ProgressingProvider"
import RowListItem from "./RowListItem"

export const colorStatusRecipeTask = {
  QUEUED: "#805AD5",
  RUNNING: "#38A169",
  COMPLETED: "#3182CE",
  FAILED: "#E53E3E",
  CANCELED: "#718096",
  DELETED: "#E53E3E",
  DRAFT: "#718096",
}

const RowScheduler = memo(
  ({
    statuses,
    filter,
    setIsLoading,
    tasksProgress,
    setSelectedRecipe,
  }: {
    statuses: ("QUEUED" | "RUNNING" | "COMPLETED" | "FAILED" | "CANCELED")[] | undefined
    filter: {
      status: string[]
      recipe: string[]
    }
    tasksProgress: TaskProgressSocketEvent[] | undefined
    isLoading: {
      QUEUED: boolean
      RUNNING: boolean
      COMPLETED: boolean
      CANCELED: boolean
      FAILED: boolean
    }
    setIsLoading: React.Dispatch<
      React.SetStateAction<{
        QUEUED: boolean
        RUNNING: boolean
        COMPLETED: boolean
        CANCELED: boolean
        FAILED: boolean
      }>
    >
    setSelectedRecipe: React.Dispatch<React.SetStateAction<RecipeTask | null>>
  }) => {
    const [orderTasks, setOrderTasks] = useState<RecipeTask[]>([])
    const scrollRef = useRef(null)
    const status = statuses?.join(" ").toLowerCase() ?? ""
    const [open, setOpen] = useState<"FILTER" | "SORT" | null>(null)
    const sortRef = useRef<HTMLDivElement>(null)
    const [sort, setSort] = useState<{
      sortBy: RecipeTaskSortByColumn
      sortOrder: "ASC" | "DESC"
    }>({
      sortBy: RecipeTaskSortByColumn.CreatedAt,
      sortOrder: "DESC",
    })
    const qc = useQueryClient()
    useOutsideClick({
      enabled: !!open,
      ref: sortRef,
      handler: () => {
        setOpen(null)
      },
    })

    const query = useGetTaskInfiniteQuery({
      primaryKey: "board-view-task",
      getNextPageParam: (lastPage, pages) => {
        if (lastPage[status].list.length < 20) {
          return undefined
        }
        return pages.length
      },
    })

    const queryKey = query.getKey({
      statuses,
      sortBy: sort.sortBy,
      sortOrder: sort.sortOrder,
      recipeIds: filter.recipe,
    })

    const {
      data,
      hasNextPage,
      fetchNextPage,
      isFetching,
      flattenData,
      refetch,
      isSuccess,
      isError,
      isLoading: isLoadingQuery,
    } = query({
      variables: {
        statuses,
        sortBy: sort.sortBy,
        sortOrder: sort.sortOrder,
        recipeIds: filter.recipe,
      },
    })

    const tasks = useMemo(
      () => data?.pages.reduce((acc, page) => [...acc, ...page[status].list], [] as RecipeTask[]) ?? [],
      [data, status],
    )

    useEffect(() => {
      if (isSuccess) {
        setOrderTasks(tasks)

        setSelectedRecipe(prev => {
          if (!prev) return null
          const newRecipe = tasks.find(i => i.id === prev.id)
          if (newRecipe?.status !== prev.status) {
            return tasks.find(i => i.id === prev.id) ?? null
          }
          return prev
        })
      }
    }, [tasks, isSuccess, setSelectedRecipe])

    const {
      isLoadingDeleteTask,
      isLoadingReorderTask,
      mutateDelete,
      mutateReorder,
      mutateRestart,
      mutateCreateTask,
      mutateCancel,
      isLoadingCancel,
    } = useMutateRowScheduler({
      queryKey: queryKey[1],
      refetch,
    })

    useEffect(() => {
      setIsLoading(prev => ({ ...prev, [status.toUpperCase()]: isLoadingQuery }))
    }, [isLoadingQuery, status])

    const mappedData = data?.pages.reduce(
      (acc: RecipeTasksWithTotalResponseForAgentScheduler, page) => ({
        total: page[status].total,
        list: [...acc.list, ...page[status].list],
      }),
      { total: 0, list: [] },
    )

    return (
      <div className="w-[20rem] inline-block h-full scroll-m-2 align-top whitespace-nowrap py-1">
        <div
          className="flex items-center justify-between rounded-lg mb-2"
          style={{
            backgroundColor: colorStatusRecipeTask[status.toUpperCase()],
          }}
        >
          <div className="flex items-center">
            <p className="py-0.5 px-2 rounded-full text-sm font-semibold">
              {capitalize(status)} - {mappedData?.total}
            </p>
          </div>
          <div className="flex items-center ml-2">
            <div className="flex items-center relative" ref={sortRef}>
              <IconButton
                onClick={() => {
                  googleAnalytics.event({
                    action: "click",
                    category: "agent_scheduler",
                    label: "sort",
                  })
                  setOpen(prev => {
                    if (prev === "FILTER") return null
                    return "FILTER"
                  })
                }}
                title="Sort"
                className={classNames(
                  "flex items-center bg-transparent hover:bg-transparent active:bg-transparent font-semibold text-sm",
                )}
              >
                <SortIcon width={16} height={16} />
              </IconButton>
              <AnimatePresence>
                {!!open && (
                  <motion.div
                    initial={{ opacity: 0.5, height: 0 }}
                    animate={{ opacity: 1, height: "auto" }}
                    exit={{ opacity: 0.5, height: 0 }}
                    className="absolute top-full mt-1 overflow-hidden right-2 w-[10rem] z-[20]"
                  >
                    <div className="p-2 px-4 rounded-md bg-atherGray-850 border border-atherGray-800 flex w-full flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-8">
                      <div className="flex-1">
                        <div className="text-sm space-y-2">
                          <div
                            className="flex items-center w-full cursor-pointer"
                            onClick={() => {
                              googleAnalytics.event({
                                action: "click",
                                category: "agent_scheduler",
                                label: `sort_by_status`,
                              })
                              setSort({
                                sortBy: RecipeTaskSortByColumn.Name,
                                sortOrder:
                                  sort.sortBy === RecipeTaskSortByColumn.Name
                                    ? sort.sortOrder === "ASC"
                                      ? "DESC"
                                      : "ASC"
                                    : "DESC",
                              })
                            }}
                          >
                            <IconButton
                              title="Sort by Name"
                              colorScheme="transparent"
                              className={classNames("p-0 text-atherGray-500", {
                                "text-atherGray-0": sort.sortBy === RecipeTaskSortByColumn.Name,
                              })}
                            >
                              {sort.sortBy === RecipeTaskSortByColumn.Name && sort.sortOrder === "ASC" ? (
                                <FaSortAlphaDown size="0.8rem" />
                              ) : (
                                <FaSortAlphaDownAlt size="0.8rem" />
                              )}
                            </IconButton>
                            <p className="ml-2 flex-1">
                              Name{" "}
                              {sort.sortBy === RecipeTaskSortByColumn.Name && sort.sortOrder === "ASC"
                                ? "(A-Z)"
                                : "(Z-A)"}
                            </p>
                          </div>
                          <div
                            className="flex items-center w-full cursor-pointer"
                            onClick={() => {
                              googleAnalytics.event({
                                action: "click",
                                category: "agent_scheduler",
                                label: `sort_by_upload_date`,
                              })
                              setSort({
                                sortBy: RecipeTaskSortByColumn.CreatedAt,
                                sortOrder:
                                  sort.sortBy === RecipeTaskSortByColumn.CreatedAt
                                    ? sort.sortOrder === "ASC"
                                      ? "DESC"
                                      : "ASC"
                                    : "DESC",
                              })
                            }}
                          >
                            <IconButton
                              colorScheme="transparent"
                              title="Upload date"
                              className={classNames("p-0 text-atherGray-500", {
                                "text-atherGray-0": sort.sortBy === RecipeTaskSortByColumn.CreatedAt,
                              })}
                            >
                              {sort.sortBy === RecipeTaskSortByColumn.CreatedAt && sort.sortOrder === "ASC" ? (
                                <FaSortAmountDownAlt size="0.8rem" />
                              ) : (
                                <FaSortAmountDown size="0.8rem" />
                              )}
                            </IconButton>
                            <p className="ml-2 flex-1">Upload date</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </div>
        </div>
        <RowListItem
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
          isFetching={isFetching}
          isLoading={isLoadingReorderTask || isLoadingDeleteTask || isLoadingCancel}
          orderTasks={orderTasks}
          setSelectedRecipe={setSelectedRecipe}
          tasksProgress={tasksProgress}
          scrollRef={scrollRef}
          setOrderTasks={setOrderTasks}
          onDelete={mutateDelete}
          onReorder={mutateReorder}
          onRestart={mutateRestart}
          onCreate={mutateCreateTask}
          onCancel={mutateCancel}
          statuses={statuses}
          mappedData={mappedData}
        />
      </div>
    )
  },
)

export default RowScheduler
