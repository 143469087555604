import { QueryKey } from "@tanstack/react-query"
import { useAuthenticatedInfinityQuery } from "./useAuthenticatedInfinityQuery"

export interface UseBaseInfinityQuery<TData> {
  queryFn: (skip: number, take: number) => Promise<TData[]>
  recordPerPage?: number // default 20
  enabled?: boolean
  queryKey: QueryKey
}

export function useBaseInfinityQuery<TData>(
  queryKey: QueryKey,
  queryFn: (skip: number, take: number) => Promise<TData[]>,
  options?: {
    recordPerPage?: number
    enabled?: boolean
    keepPreviousData?: boolean
    refetchOnWindowFocus?: boolean
    staleTime?: number
  }
): { data: TData[] } & Omit<ReturnType<typeof useAuthenticatedInfinityQuery>, "data">
export function useBaseInfinityQuery<TData>(
  params: UseBaseInfinityQuery<TData>
): { data: TData[] } & Omit<ReturnType<typeof useAuthenticatedInfinityQuery>, "data">
export function useBaseInfinityQuery<TData>(
  queryKeyOrParams: QueryKey | UseBaseInfinityQuery<TData>,
  queryFn?: (skip: number, take: number) => Promise<TData[]>,
  options?: {
    recordPerPage?: number
    enabled?: boolean
    keepPreviousData?: boolean
    refetchOnWindowFocus?: boolean
    staleTime?: number
  }
): { data: TData[] } & Omit<ReturnType<typeof useAuthenticatedInfinityQuery>, "data"> {
  let queryKey: QueryKey
  let recordPerPage: number
  let _queryFn: (skip: number, take: number) => Promise<TData[]>

  const enabled = options?.enabled ?? true

  if (typeof queryKeyOrParams === "object" && "queryFn" in queryKeyOrParams) {
    queryKey = queryKeyOrParams.queryKey
    recordPerPage = queryKeyOrParams.recordPerPage ?? 20
    _queryFn = queryKeyOrParams.queryFn
  } else {
    queryKey = queryKeyOrParams as QueryKey
    recordPerPage = options?.recordPerPage ?? 20
    _queryFn = queryFn!
  }

  const getDataWithRange = async ({ pageParam = 0 }) => _queryFn(pageParam * recordPerPage, recordPerPage)

  const { data, ...rest } = useAuthenticatedInfinityQuery(queryKey, getDataWithRange, {
    getNextPageParam: (lastPage, pages) => (lastPage && lastPage.length < recordPerPage ? undefined : pages.length),
    enabled,
    keepPreviousData: options?.keepPreviousData ?? false,
    refetchOnWindowFocus: options?.refetchOnWindowFocus ?? false,
    staleTime: options?.staleTime ?? 0,
    retry: 1,
  })

  const mappedData = data?.pages?.reduce((acc, page) => [...acc, ...page], []) ?? []

  return {
    data: mappedData,
    ...rest,
  }
}
