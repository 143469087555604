import client from "@/api/client"
import { GetUserReferralsResult, QuestFrequency, QuestStatus, ReferredUser } from "@/api/sdk"
import { createAuthenticatedInfiniteQuery } from "@/queries/createAuthenticatedQuery"

export const useGetUserReferralsInfiniteQuery = createAuthenticatedInfiniteQuery<
  GetUserReferralsResult,
  {
    status?: QuestStatus
    frequency?: QuestFrequency
  },
  Error,
  ReferredUser
>({
  primaryKey: "infinite-user-referrals",
  queryFn: ({ queryKey: [_primaryKey, variables], pageParam = 0 }) =>
    client.api
      .referralControllerGetUserReferrals({
        skip: pageParam * 20,
        take: 20,
      })
      .then(res => res.data),
  getNextPageParam: (lastPage, pages) => (lastPage && lastPage.users.length < 20 ? undefined : pages.length),
  initialPageParam: 0,
  flattenFn: data => data.users,
  getTotalFn: data => data.total,
})
