import { cdnPublicFolderUrl } from "@/constants"
import { googleAnalytics } from "@/lib/gtag"
import classNames from "classnames"
import Link from "next/link"
import { twMerge } from "tailwind-merge"

interface AppLogoProps {
  className?: string
  backTo?: string
  onClick?: () => void
}

const AppLogo = ({ className, backTo = "/landing", onClick }: AppLogoProps) => {
  const handleLogoClick = () => {
    googleAnalytics.event({
      action: "click",
      category: "sidebar",
      label: "logo",
      value: 1,
    })
    onClick?.()
  }

  return (
    <div
      onClick={handleLogoClick}
      className={twMerge(classNames("flex items-center w-full justify-start px-6 cursor-pointer", className))}
    >
      <Link href={backTo} target="_parent" className="relative p-1 rounded-full block">
        <img
          src={`${cdnPublicFolderUrl}/text-logo.png`}
          alt="Ather Logo"
          width={158}
          height={48}
          className="z-10 block"
        />
      </Link>
    </div>
  )
}

export default AppLogo
