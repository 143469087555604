import { SDWorkflowDetail, SDWorkflowFilterMode, SDWorkflowSortOption } from "@/api/sdk"
import IconButton from "@/components/IconButton"
import TabsList from "@/components/Workspace/FoldersV2/TabsList"
import NotFoundItems from "@/components/Workspace/Pinned/NotFoundItems"
import { PlusIcon } from "@/components/shared/icons"
import { useAuth } from "@/providers/authContext"
import { useComfyUiWorkflowInfiniteQuery } from "@/queries/tools/comfyui-recipe/getComfyUiRecipeQueries"
import { useWorkspaceSearchHistory } from "@/stores"
import { motion } from "framer-motion"
import useCustomRouter from "@/hooks/useCustomRouter"
import { useEffect, useMemo, useRef, useState } from "react"
import InfiniteScroll from "react-infinite-scroller"
import LoadingLogo from "../../LoadingLogo"
import ComfyUIRecipeCard from "./ComfyUIRecipeCard"
import ComfyUITabActions from "./ComfyUITabActions"
import MultiComfyUISelectBar from "./MultiComfyUISelectBar"
import { useMultiComfyUIsSelect } from "./useMultiComfyUIsSelect"
import { useDebounce, useDragSelection } from "@/hooks"
import dynamic from "next/dynamic"
import WorkspaceContainer from "@/components/Workspace/WorkspaceContainer"
import GridBreakpointColsContainer from "@/components/GridBreakpointColsContainer"
import { googleAnalytics } from "@/lib/gtag"
import ErrorUI from "@/components/ErrorUI"

const CreateComfyUIModal = dynamic(() => import("./CreateComfyUIModal"), { ssr: false })

export const comfyUItabs = [
  {
    title: "My ComfyUI",
    id: "owned",
  },
  {
    title: "Shared with me",
    id: "shared-with-me",
  },
  {
    title: "Drafts",
    id: "drafts",
  },
]

export const useComfyUiWorkflows = (
  currentMode: SDWorkflowFilterMode,
  searchTerm: string,
  sortBy: SDWorkflowSortOption,
  filter?: { shared?: string[]; pinned?: boolean } | null,
) => {
  const [mode, setMode] = useState(currentMode)

  const variables = useMemo(() => {
    return {
      mode,
      searchTerm,
      sortBy,
      onlyDiscoverable: filter?.shared?.includes("explore") ? true : undefined,
      onlyShared: filter?.shared?.includes("workspace") ? true : undefined,
      isPinned: filter?.pinned ? true : undefined,
    }
  }, [mode, searchTerm, sortBy, filter])

  const debounceVariables = useDebounce(variables, 50)

  const { flattenData: workflows = [], ...query } = useComfyUiWorkflowInfiniteQuery({
    variables: debounceVariables,
  })

  return {
    workflows,
    ...query,
    setMode,
  }
}

const ComfyUIRecipes = () => {
  const router = useCustomRouter()
  const searchQuery = (router.query.search as string | undefined) ?? ""
  const [addHistory, removeHistory] = useWorkspaceSearchHistory(s => [s.addHistory, s.removeHistory])
  const { user } = useAuth()

  const tab = (router.query.tab as string) || comfyUItabs[0].id
  const mode =
    tab === comfyUItabs[0].id
      ? SDWorkflowFilterMode.Owned
      : tab === comfyUItabs[1].id
        ? SDWorkflowFilterMode.Shared
        : SDWorkflowFilterMode.Draft

  const [sortBy, setSort] = useState<SDWorkflowSortOption>(SDWorkflowSortOption.NEWEST)
  const [filter, setFilter] = useState<{
    shared?: string[]
  } | null>(null)

  const { workflows, isLoading, isFetching, isSuccess, isError, hasNextPage, fetchNextPage, setMode, data } =
    useComfyUiWorkflows(mode, searchQuery, sortBy, {
      shared: filter?.shared,
      pinned: true,
    })
  const total = data?.pages?.[0]?.total ?? 0

  useEffect(() => setMode(mode), [mode])

  const [isOpen, setIsOpen] = useState(false)

  const containerRef = useRef<HTMLDivElement>(null)
  const [containerLeft, setContainerLeft] = useState(0)

  useEffect(() => {
    const containerObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        setContainerLeft(entry.target.getBoundingClientRect().x)
      }
    })

    containerObserver.observe(mainContainerRef.current!)

    return () => {
      containerObserver.disconnect()
    }
  }, [])

  const mainContainerRef = useRef<HTMLDivElement>(null)

  const handleConfirmSearch = (search: string) => {
    router.push({ query: { ...router.query, search } }, undefined, { shallow: true })

    if (search) {
      addHistory({
        uid: user?.uid ?? "",
        location: "tools/comfyui",
        text: search,
      })
    }
  }

  const handleRemoveSearchHistory = (text: string) => {
    removeHistory({
      location: "tools/comfyui",
      text,
      uid: user?.uid ?? "",
    })
  }

  const { addSelection, clear, selectedItems, selectionMode, toggleSelectionMode, selectAll } = useMultiComfyUIsSelect()

  const { DragSelection, itemRefs } = useDragSelection(workflows, selectAll, selectionMode)

  const handleToggleSelectionMode = () => {
    toggleSelectionMode()
    googleAnalytics.event({
      action: "click",
      category: "comfyui",
      label: "toggle_multi_select",
      params: {
        type: "comfyui",
        value: selectionMode ? "false" : "true",
      },
    })
  }

  const handleAddSelection = (data: SDWorkflowDetail) => {
    addSelection(data)
    googleAnalytics.event({
      action: "click",
      category: "comfyui",
      label: "select_comfyui",
      params: {
        comfyui_id: data.id,
      },
    })
  }

  useEffect(() => {
    toggleSelectionMode(false)
    setFilter(null)
    setSort(SDWorkflowSortOption.NEWEST)
  }, [mode])

  const renderBody = () => {
    if (isLoading)
      return (
        <div className="flex items-center justify-center w-full flex-1 text-gray-600">
          <LoadingLogo />
        </div>
      )

    if (isError) return <ErrorUI />

    if (workflows?.length === 0) return <NotFoundItems />

    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.35, delay: 0.35 }}
        className={"relative flex-1"}
      >
        <InfiniteScroll
          loadMore={() => fetchNextPage()}
          hasMore={!!hasNextPage && !isFetching}
          useWindow={true}
          threshold={600}
          style={{
            display: "flex",
            flexDirection: "column",
            maxWidth: "100%",
            position: "relative",
          }}
          loader={
            <div key="loader" className="flex items-center justify-center py-4">
              Loading...
            </div>
          }
        >
          <GridBreakpointColsContainer>
            {workflows?.map(workflow => (
              <ComfyUIRecipeCard
                onRef={ref => (itemRefs.current[workflow.id] = ref)}
                isChecked={selectedItems.some(item => item.id === workflow.id)}
                onCheck={() => handleAddSelection(workflow)}
                selectionMode={selectionMode}
                key={workflow.id}
                workflow={workflow}
                href={`/workspace/tools/comfyui/${workflow.id}`}
              />
            ))}
          </GridBreakpointColsContainer>
        </InfiniteScroll>
      </motion.div>
    )
  }

  return (
    <div className="flex-1 p-4 px-6 flex flex-col relative w-full" ref={mainContainerRef}>
      <WorkspaceContainer className="flex-1">
        <div className="flex w-full justify-between items-center mb-4">
          <h3 className="text-xl font-semibold">ComfyUI Workflows</h3>
          <IconButton onClick={() => setIsOpen(true)} leftIcon={<PlusIcon height={16} width={16} />}>
            New <span className="hidden md:inline-block">comfyUI</span>
          </IconButton>
        </div>
        <div className="flex-1 flex flex-col w-full">
          <TabsList
            tabQuery={tab}
            tabsData={comfyUItabs}
            onChange={tab => {
              router.push({ query: { tab } }, undefined, { shallow: true })
            }}
          />
          <div className="flex-1 mt-2 relative w-full flex flex-col" ref={containerRef}>
            <MultiComfyUISelectBar
              view={mode}
              clear={clear}
              selectedItems={selectedItems}
              offsetLeft={containerLeft / 2}
              selectionMode={selectionMode}
            >
              <div className="flex-1 flex-col flex" key={mode}>
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  className="flex items-center py-2 sticky top-0 md:top-[4.25rem] left-0 bg-black z-[2] mb-2"
                >
                  <p className="text-sm text-atherGray-300 mr-4">
                    {total} {total > 1 ? "results" : "result"}
                  </p>
                  <ComfyUITabActions
                    filter={filter}
                    setFilter={setFilter}
                    mode={mode}
                    sort={sortBy}
                    setSort={setSort}
                    onConfirmSearch={handleConfirmSearch}
                    onRemoveHistory={handleRemoveSearchHistory}
                    selectionMode={selectionMode}
                    toggleSelectionMode={handleToggleSelectionMode}
                  />
                </motion.div>
                {renderBody()}
                <DragSelection />
              </div>
            </MultiComfyUISelectBar>
          </div>
        </div>
      </WorkspaceContainer>

      <CreateComfyUIModal isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </div>
  )
}

export default ComfyUIRecipes
