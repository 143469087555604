import { ModalShareType } from "@/lib/store"
import { QueryKey } from "@tanstack/react-query"
import { create } from "zustand"

interface RecoverItemStore {
  recoverItem: {
    type: ModalShareType
    deletedAt: string | null
    isRedirect?: boolean
    onClose?: () => void
    ids: string[]
    queryKey?: QueryKey
    parentFolderId?: string
    [key: string]: any
  } | null

  setRecoverItem: (item: RecoverItemStore["recoverItem"]) => void
}

export const useRecoverItemStore = create<RecoverItemStore>(set => ({
  recoverItem: null,
  setRecoverItem: recoverItem => set({ recoverItem }),
}))
