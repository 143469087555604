import { useState } from "react"

import FolderActionRow from "@/components/Workspace/FolderActions/FolderActionRow"
import {
  AddSquareIcon,
  DeleteIcon,
  EditIcon,
  MoreHorizontalIcon,
  PinIcon,
  RefreshIcon,
  RemoveSquareIcon,
  ScienceIcon,
  ShareIcon8,
  UnPinIcon,
} from "@/components/shared/icons"
import useModalStore from "@/lib/store"
import { cn } from "@/utils/cn"
import { useAddModelsMutation, usePinMutation, useRemoveModelsMutation } from "@/queries"
import { useToast } from "@/hooks"
import { useRouter } from "next/router"
import IconButton from "@/components/IconButton"
import { PinnedItemType, SDBaseModel, SDModelRoleCapabilities } from "@/api/sdk"
import { googleAnalytics } from "@/lib/gtag"
import { useDeleteItemStore, useRecipeImageStore, useRecoverItemStore } from "@/stores"
import { triggerRoutedDialog } from "@/providers/RoutedDialogProvider"
import Popover from "@/components/Popover"

interface ModelItem {
  id: string
  name?: string
  capabilities?: SDModelRoleCapabilities
  creator: { uid: string } | null
  workspace: { id: string }
  deletedAt: string | null
  pinned?: boolean
  thumbnailUrl?: string
  baseModel?: SDBaseModel
}

interface ActionButtonsProps {
  model: ModelItem
  className?: string
  layout?: "grid" | "list"
}

const ModelActionButtons = (props: ActionButtonsProps) => {
  const { model, className, layout } = props

  const [isOpen, setIsOpen] = useState(false)
  const setSharingData = useModalStore(state => state.setSharingData)
  const setDeleteItem = useDeleteItemStore(state => state.setDeletingItem)
  const setRecoverItem = useRecoverItemStore(state => state.setRecoverItem)
  const setRecipeImage = useRecipeImageStore(state => state.setRecipeImage)
  const toast = useToast()
  const router = useRouter()

  const { mutate: addToLibrary, isPending: isAddingToLibrary } = useAddModelsMutation({
    onSuccess() {
      toast({
        status: "success",
        title: "Model added",
        message: ["Model has been added to your library"],
      })
    },
  })

  const { mutate: removeFromLibrary, isPending: isRemovingFromLibrary } = useRemoveModelsMutation({
    onSuccess() {
      toast({
        status: "success",
        title: "Model removed",
        message: ["Model has been removed from your library"],
      })
    },
  })

  const handleShare = () => {
    setSharingData({
      id: model.id,
      name: model.name ?? model.id,
      link: `${window.location.origin}/workspace/tools/models/${model.id}`,
      type: "model",
      creator: model.creator ?? undefined,
      workspaceId: model.workspace.id,
      url: model.thumbnailUrl ?? "",
    })
  }

  const { mutate: mutatePinTask } = usePinMutation({
    onSuccess: () => {
      toast({
        status: "success",
        title: `Model ${model.pinned ? "unpinned" : "pinned"}`,
      })
    },
  })

  const handlePin = () => {
    googleAnalytics.event({
      action: "click",
      category: "model_item",
      label: `${model.pinned ? "unpin" : "pin"}_model`,
      params: {
        model_id: model.id,
        model_name: model.name ?? model.id,
        pathname: window.location.pathname,
      },
    })

    mutatePinTask({ ids: [model.id], type: PinnedItemType.MODEL, isPinned: !model.pinned })
  }

  const handleRecovery = () => {
    googleAnalytics.event({
      action: "click",
      category: "model_item",
      label: "select_recover_model",
      params: {
        model_id: model.id,
        model_name: model.name ?? model.id,
        pathname: window.location.pathname,
      },
    })

    setRecoverItem({
      ids: [model.id],
      type: "model",
      deletedAt: model.deletedAt,
    })
  }

  const handleRecipe = () => {
    googleAnalytics.event({
      action: "click",
      category: "model_item",
      label: "send_to_recipe",
      params: {
        model_id: model.id,
        model_name: model.name ?? model.id,
        pathname: window.location.pathname,
      },
    })

    const params = {
      baseModel: model.baseModel,
      modelIds: [model.id],
    }

    setRecipeImage({
      id: model.id,
      isModal: true,
      type: "lora",
      params: {
        ...params,
      },
    })
  }

  const isMutating = isAddingToLibrary || isRemovingFromLibrary

  const actions = [
    {
      label: "Send to recipe",
      icon: <ScienceIcon width={16} height={16} />,
      onClick: () => {
        handleRecipe()
      },
      visible: !model.deletedAt,
      enabled: model.capabilities?.canView,
    },
    {
      label: "Edit Info",
      icon: <EditIcon width={16} height={16} />,
      onClick: () => {
        triggerRoutedDialog({
          name: "modelDetailWorkspace",
          state: {
            modelId: model.id,
            filters: {
              name: "modelDetailWorkspace",
              editMode: true,
            },
          },
          type: "push",
        })
      },
      visible: model.capabilities?.canUpdate && !model.deletedAt,
      enabled: !isMutating,
    },
    {
      label: "Add to My models",
      icon: <AddSquareIcon width={16} height={16} />,
      onClick: () => addToLibrary({ ids: [model.id] }),
      visible: model.capabilities?.canAddToLibrary,
      enabled: !isMutating,
      isLoading: isAddingToLibrary,
    },
    {
      label: "Remove from My models",
      icon: <RemoveSquareIcon width={16} height={16} />,
      onClick: () =>
        removeFromLibrary({ ids: [model.id], view: router.query.tab === "shared-with-me" ? "shared" : "owned" }),
      visible: model.capabilities?.canRemoveFromLibrary,
      enabled: !isMutating,
      isLoading: isRemovingFromLibrary,
    },
    {
      label: "Share",
      icon: <ShareIcon8 width={16} height={16} />,
      onClick: handleShare,
      visible: model.capabilities?.canShare,
      enabled: true,
      isLoading: false,
    },
    {
      label: model.pinned ? "Unpin" : "Pin",
      icon: model.pinned ? <UnPinIcon width={16} height={16} /> : <PinIcon width={16} height={16} />,
      onClick: handlePin,
      visible: model.capabilities?.canView && !model.deletedAt,
      enabled: model.capabilities?.canView,
    },
    {
      label: "Recover",
      icon: <RefreshIcon className="rotate-180" width={16} height={16} />,
      onClick: handleRecovery,
      enabled: model.capabilities?.canRecover,
      visible: !!model.deletedAt,
    },
    {
      label: "Delete",
      icon: <DeleteIcon className="text-red-500" width={16} height={16} />,
      onClick: () => {
        googleAnalytics.event({
          action: "click",
          category: "model_item",
          label: "delete_model",
          params: {
            model_id: model.id,
            model_name: model.name ?? model.id,
            pathname: window.location.pathname,
          },
        })

        setDeleteItem({
          id: model.id,
          type: "model",
          deletedAt: model.deletedAt,
        })
      },
      visible: model.capabilities?.canDelete,
      enabled: !isMutating,
    },
  ]

  return (
    <div
      className={cn(
        "transition-opacity duration-300",
        {
          "opacity-100 lg:opacity-100": isOpen,
          "absolute top-1.5 right-1.5 opacity-100 lg:opacity-0 group-hover:opacity-100": layout === "grid",
        },
        className,
      )}
    >
      <Popover
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        trigger={
          <IconButton
            colorScheme="transparent"
            className={cn("rounded-2xl p-0 w-7 h-7 min-h-0 hover:bg-atherGray-700 active:bg-atherGray-700", {
              "bg-atherGray-700": isOpen,
            })}
          >
            <MoreHorizontalIcon />
          </IconButton>
        }
        align="end"
      >
        <div
          className={
            "text-sm w-[16rem] text-atherGray-300 bg-atherGray-900 border border-atherGray-850 p-2 rounded-2xl space-y-1"
          }
        >
          {actions
            .filter(a => a.visible)
            .map(action => (
              <FolderActionRow
                key={action.label}
                isLoading={action.isLoading}
                iconLeft={action.icon}
                onClick={e => {
                  e.stopPropagation()
                  action.onClick?.()
                  setIsOpen(false)
                }}
                isDisabled={!action.enabled}
              >
                {action.label}
              </FolderActionRow>
            ))}
        </div>
      </Popover>
    </div>
  )
}

export default ModelActionButtons
