import client from "@/api/client"
import { CreateRecipeTaskDto, ReOrderRecipeTaskDto, RecipeTaskSortByColumn } from "@/api/sdk"
import { useToast } from "@/hooks"
import { useCancelTaskMutation, useDeleteTaskMutation } from "@/queries"
import { useManagementErrorsStore } from "@/stores"
import { QueryKey, useMutation, useQueryClient } from "@tanstack/react-query"

const useMutateRowScheduler = ({
  refetch,
  queryKey,
}: {
  refetch: () => void
  queryKey: {
    statuses: ("QUEUED" | "RUNNING" | "COMPLETED" | "FAILED" | "CANCELED")[] | undefined
    sortBy: RecipeTaskSortByColumn
    sortOrder: "ASC" | "DESC"
  }
}) => {
  const toast = useToast()
  const qc = useQueryClient()

  const { mutate: mutateReorder, isPending: isLoadingReorderTask } = useMutation({
    mutationFn: ({ data }: { data: ReOrderRecipeTaskDto; status: string }) =>
      client.api.recipeTaskControllerReorderTask(data),
    onSuccess: (_, { status }) => {
      if (status.toLowerCase() === "queued") {
        refetch()
      }
      toast({
        status: "success",
        title: "Task updated",
        message: ["The task has been reorder successfully"],
      })
    },
  })
  const setErrorState = useManagementErrorsStore(state => state.setErrorState)

  const { mutate: mutateCreateTask, isPending: isLoadingCreate } = useMutation({
    mutationFn: ({ data }: { data: CreateRecipeTaskDto; status: string }) =>
      client.api.recipeTaskControllerAddTask(data).then(res => res.data),
    onSuccess: (_, { status }) => {
      qc.invalidateQueries({
        queryKey: [
          "board-view-task",
          {
            ...queryKey,
            statuses: [status],
          },
        ],
      })

      toast({
        status: "success",
        title: "Submitted! Your task is running",
        duration: 5000,
      })
    },
    onError: (err: any) => {
      if (
        err?.message.startsWith("Guest user does not have permission") ||
        err?.message.startsWith("Your subscription has ended")
      ) {
        setErrorState({
          isOpen: true,
          message: err.message,
        })
        return
      }
      if (err?.error === "Bad Request") {
        toast({ title: "Cannot Complete Request", message: [err.message], status: "error" })
      } else {
        toast({ title: "Error", message: [err.message], status: "error" })
      }
    },
  })

  const { mutate: mutateDelete, isPending: isLoadingDeleteTask } = useDeleteTaskMutation({
    onSuccess: (_, {}) => {
      toast({
        status: "success",
        title: "Task deleted",
        message: ["The task has been deleted successfully"],
      })
    },
    onError: (err: any) => {
      if (
        err?.message.startsWith("Guest user does not have permission") ||
        err?.message.startsWith("Your subscription has ended")
      ) {
        setErrorState({
          isOpen: true,
          message: err.message,
        })
        return
      }
    },
  })

  const { mutate: mutateCancel, isPending: isLoadingCancel } = useCancelTaskMutation({
    onSuccess: () => {
      toast({
        status: "success",
        title: "Task Canceled",
        message: ["Task has been canceled permanently"],
      })

      qc.invalidateQueries({
        queryKey: [
          "board-view-task",
          {
            ...queryKey,
            statuses: ["CANCELED"],
          },
        ],
      })
    },
  })

  const { mutate: mutateRestart, isPending: isLoadingRestart } = useMutation({
    mutationFn: ({ taskId }: { taskId: string; status: string }) => client.api.recipeTaskControllerRestartTask(taskId),
    onSuccess: (_, { status }) => {
      toast({
        status: "success",
        title: "Task restarted",
        message: ["The task has been restarted successfully"],
      })

      qc.invalidateQueries({
        queryKey: [
          "board-view-task",
          {
            ...queryKey,
            statuses: [status],
          },
        ],
      })

      qc.invalidateQueries({
        queryKey: [
          "board-view-task",
          {
            ...queryKey,
            statuses: ["QUEUED"],
          },
        ],
      })
    },
    onError: (err: any) => {
      if (
        err?.message.startsWith("Guest user does not have permission") ||
        err?.message.startsWith("Your subscription has ended")
      ) {
        setErrorState({
          isOpen: true,
          message: err.message,
        })
        return
      }
    },
  })

  return {
    mutateCancel,
    isLoadingCancel,
    mutateRestart,
    mutateCreateTask,
    isLoadingCreate,
    isLoadingRestart,
    mutateReorder,
    isLoadingReorderTask,
    mutateDelete,
    isLoadingDeleteTask,
  }
}

export default useMutateRowScheduler
