import { RecipeInputType, RecipeTaskStatus, SDModel } from "@/api/sdk"
import BottomSheet from "@/components/BottomSheet"
import IconButton from "@/components/IconButton"
import TextCopy from "@/components/TextCopy"
import { ArrowMoveUpRightIcon, ShareIcon8 } from "@/components/shared/icons"
import { useToast } from "@/hooks"
import useCustomRouter from "@/hooks/useCustomRouter"
import { googleAnalytics } from "@/lib/gtag"
import { useAuth } from "@/providers/authContext"
import { useCreateTaskMutation } from "@/queries"
import { useManagementErrorsStore } from "@/stores"
import { cn } from "@/utils/cn"
import classNames from "classnames"
import { RecipeTaskChainParams } from "../WorkflowsChaining"
import {
  formatValueParams,
  getModelName,
  humanizeInputParam,
  replaceHumanizePromptParam,
} from "../WorkflowsChaining/ChainItem"
import { ImageLoadedComponent, WorkflowPublishType, formatInputKey } from "./WorkFlowDetailStep"

interface MobileDetailSheetProps {
  selectedStep: RecipeTaskChainParams | null
  setSelectedStep: (step: RecipeTaskChainParams | null) => void
  workflow: WorkflowPublishType
  mode: "MACRO" | "RECIPE"
  models: SDModel[]
  itemClassName?: string
}

const MobileDetailSheet = ({
  selectedStep,
  setSelectedStep,
  workflow,
  mode,
  models,
  itemClassName,
}: MobileDetailSheetProps) => {
  const { handleSignIn } = useAuth()
  const toast = useToast()
  const { openNewTab } = useCustomRouter()

  const setErrorState = useManagementErrorsStore(state => state.setErrorState)

  const { mutateAsync: mutateCreateTask, isPending: isLoadingCreate } = useCreateTaskMutation({
    onSuccess: data => {
      setTimeout(() => {
        openNewTab(`/workspace/macros/${data.id}?draft=true`)
      }, 1)
    },
    onError: (err: any) => {
      if (
        err?.message.startsWith("Guest user does not have permission") ||
        err?.message.startsWith("Your subscription has ended")
      ) {
        setErrorState({
          isOpen: true,
          message: err.message,
        })
        return
      }

      if (err?.error === "Bad Request") {
        toast({ title: "Cannot Complete Request", message: [err.message], status: "error" })
      } else {
        toast({ title: "Error", message: [err.message], status: "error" })
      }
    },
  })

  const handleSendToNewMacro = async () => {
    const isSignedIn = await handleSignIn()

    if (!isSignedIn || !selectedStep) return

    const param = selectedStep

    const params = Object.entries(param.params).map(([key, value]) => {
      if (
        param.recipeInputStep?.find(step => step.key === key)?.type === RecipeInputType.Image &&
        value.includes("$$prev")
      ) {
        return {
          [key]: "",
        }
      }

      return {
        [key]: value,
      }
    })

    const newParams = params.reduce((acc, curr) => {
      return {
        ...acc,
        ...curr,
      }
    }, {})

    const newChainSendNew = {
      id: new Date().getTime().toString(),
      params: newParams,
      recipeId: param.recipeId,
      recipeInputStep: param.recipeInputStep,
      recipeName: param.recipeName,
    }

    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        action: "Send Task to New Macro",
        recipe_id: param.recipeId,
        recipe_name: param.recipeName,
        macro_id: workflow.id,
        ...newParams,
      },
    })

    mutateCreateTask({
      recipeId: "recipe-to-recipe",
      params: [newChainSendNew],
      name: "New Macro",
      status: RecipeTaskStatus.DRAFT,
    })
  }

  return (
    <BottomSheet containerClassName="flex md:hidden" isOpen={!!selectedStep} onClose={() => setSelectedStep(null)}>
      {selectedStep && (
        <div key={selectedStep?.id ?? ""} className="overflow-auto h-full">
          {mode === "MACRO" && (
            <div className="flex items-center overflow-hidden mb-2">
              <p className="text-sm font-semibold flex-1">
                STEP {selectedStep?.id} : {selectedStep?.recipeName}
              </p>
            </div>
          )}
          {workflow.creator?.uid !== "anonymous" && (
            <div
              className={classNames("mt-2 flex items-center space-x-2", {
                hidden: !workflow.capabilities.canUpdate || mode === "RECIPE",
              })}
            >
              <div className="flex items-center bg-atherGray-800 p-1 rounded-lg font-semibold">
                <TextCopy
                  value={JSON.stringify(selectedStep, null, 2)}
                  icon={<ShareIcon8 className="text-atherGray-300" width={16} height={16} />}
                />
                <span className="text-xs ml-2">Copy step</span>
              </div>
              <IconButton
                isLoading={isLoadingCreate}
                className={classNames("p-1 min-h-0")}
                colorScheme="secondary"
                onClick={async e => {
                  e.preventDefault()
                  e.stopPropagation()
                  handleSendToNewMacro()
                }}
              >
                <ArrowMoveUpRightIcon width={16} height={16} />
                <span className="text-xs ml-1">Send to New Macro</span>
              </IconButton>
            </div>
          )}

          <div className={cn("flex flex-col md:flex-row bg-atherGray-900 py-4 rounded-lg", itemClassName)}>
            <div
              className={classNames("flex-1", {
                hidden: !workflow.capabilities.canUpdate,
                "md:ml-0":
                  workflow.outputType?.some(type => type === "text") && selectedStep.fullDataImages?.length === 0,
              })}
            >
              <div className="mb-2">
                {Object.entries(selectedStep.params).map(([key, value]) => {
                  if (
                    key === "prompt" ||
                    key === "negative_prompt" ||
                    selectedStep.recipeInputStep?.find(i => i.key === key)?.type === "text"
                  ) {
                    if (typeof value === "object") return null

                    return (
                      <div key={key} className="w-full mb-2">
                        <div className="mb-1 flex items-center">
                          <p className="text-left text-atherGray-500 uppercase font-semibold text-xs flex-1">
                            {formatInputKey(key)}:
                          </p>
                          <TextCopy className="ml-1" value={value} />
                        </div>
                        <div className="flex flex-wrap gap-1 bg-atherGray-950 p-2 rounded-lg">
                          {value &&
                            value.split(",").map((item, index) => {
                              if (item.trim())
                                return (
                                  <span
                                    key={index}
                                    className="text-atherGray-300 flex items-center text-sm border border-atherGray-700 py-0.5 px-2 font-normal rounded-lg"
                                  >
                                    {replaceHumanizePromptParam(item, workflow.params as RecipeTaskChainParams[])}
                                  </span>
                                )
                            })}
                        </div>
                      </div>
                    )
                  }
                })}
              </div>
              <div className="grid grid-cols-[repeat(auto-fill,minmax(15rem,_1fr))] w-full gap-2">
                {Object.entries(selectedStep.params).map(([key, value]) => {
                  if (key.includes("image") && !value.toString().includes("$$prev")) {
                    return null
                  }

                  if (
                    key === "prompt" ||
                    key === "negative_prompt" ||
                    selectedStep.recipeInputStep?.find(i => i.key === key)?.type === "text"
                  ) {
                    return null
                  }

                  if (
                    key === "model_hash" ||
                    key === "modelHash" ||
                    selectedStep.recipeInputStep?.find(i => i.key === key)?.type === "model"
                  ) {
                    return (
                      <div key={key} className="bg-atherGray-950 px-2 py-1 flex items-center rounded-lg">
                        <div className="flex items-center font-normal text-atherGray-300 text-xs w-full">
                          <p className="text-atherGray-500 whitespace-nowrap uppercase font-semibold text-xs">
                            {formatInputKey(key)}:
                          </p>{" "}
                          <div className="flex-1 text-sm ml-2 flex justify-end overflow-hidden">
                            <p className="">
                              {getModelName(
                                models,
                                selectedStep.params.modelHash || selectedStep.params.model_hash || value,
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    )
                  }

                  if (key.endsWith("_wildcard")) return null

                  if (selectedStep.params?.["styleName"] && key === "style") {
                    return null
                  }

                  return (
                    <div key={key} className="bg-atherGray-950 px-2 py-1 flex items-center rounded-lg">
                      <div className="flex items-center font-normal text-atherGray-300 text-xs w-full">
                        <p className="text-atherGray-500 whitespace-nowrap uppercase font-semibold text-xs">
                          {formatInputKey(key, selectedStep)}:
                        </p>{" "}
                        <div className="flex-1 text-sm ml-2 flex justify-end overflow-hidden">
                          <p className="">
                            {value?.toString().includes("$$prev")
                              ? humanizeInputParam(value, workflow.params as RecipeTaskChainParams[])
                              : formatValueParams(key, value)}
                          </p>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
              <div className="mt-2 grid grid-cols-[repeat(auto-fill,minmax(8.5rem,_1fr))] gap-2">
                {Object.entries(selectedStep.params).map(([key, value]) => {
                  if (
                    (key.includes("referenceImage") || key.includes("reference_image")) &&
                    !value.toString().includes("$$prev")
                  ) {
                    return (
                      <div key={key} className="">
                        <p className="text-left text-atherGray-500 uppercase font-semibold text-xs mb-1">
                          {formatInputKey(key)}:
                        </p>
                        <div className="w-full">
                          <ImageLoadedComponent className="rounded-none max-h-[10rem]" key={key} url={value} />
                        </div>
                      </div>
                    )
                  }

                  if (key === "image" && !value.toString().includes("$$prev")) {
                    return (
                      <div key={key} className="w-full">
                        <p className="text-left text-atherGray-500 uppercase font-semibold text-xs mb-1">
                          {formatInputKey(key)}:
                        </p>
                        <div className="w-full">
                          <ImageLoadedComponent className="rounded-none max-h-[10rem]" key={key} url={value} />
                        </div>
                      </div>
                    )
                  }
                })}
              </div>
              {workflow.outputText && (
                <div className="mt-2 flex gap-2 items-center">
                  <p className="font-semibold text-sm">{workflow.outputText}</p>
                  <TextCopy value={workflow.outputText} />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </BottomSheet>
  )
}

export default MobileDetailSheet
