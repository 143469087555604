import { cn } from "@/lib/utils"
import { useAuth } from "@/providers/authContext"
import { AnimatePresence, motion } from "framer-motion"
import { twMerge } from "tailwind-merge"

const ProgressbarTask = ({
  className,
  progress = 0,
  isLoading = false,
  descriptionClassName,
  description,
}: {
  className?: string
  progress?: number
  isLoading?: boolean
  description?: React.ReactNode
  descriptionClassName?: string
}) => {
  const { user } = useAuth()

  if (!user) return null

  return (
    <motion.div
      initial={{ height: 0, opacity: 0 }}
      animate={{ height: "auto", opacity: 1 }}
      exit={{ height: 0, opacity: 0 }}
      className={twMerge("overflow-hidden", className)}
      key={isLoading ? "loading" : "progress"}
    >
      <div className="relative h-1 overflow-hidden rounded-lg bg-atherGray-800">
        {!isLoading ? (
          <AnimatePresence>
            <motion.div
              transition={{ duration: 1, ease: "easeInOut", type: "tween" }}
              animate={{ width: `${progress}%` }}
              initial={{ width: 0 }}
              style={{
                height: "100%",
                backgroundColor: "#5E17EB",
                backgroundSize: "1rem 1rem",
              }}
            />
          </AnimatePresence>
        ) : (
          <motion.div
            transition={{ duration: 1.5, repeat: Infinity, ease: "linear" }}
            animate={{
              transform: ["translateX(-25%)", "translateX(125%)", "translateX(-25%)"],
            }}
            className="w-1/2 h-full absolute"
            style={{
              backgroundColor: "#5E17EB",
              backgroundSize: "1rem 1rem",
            }}
          />
        )}
      </div>
      {description && (
        <div className={cn("mt-1 text-xs text-atherGray-500", descriptionClassName)}>
          {isLoading ? "Loading..." : description}
        </div>
      )}
    </motion.div>
  )
}

export default ProgressbarTask
