import client from "@/api/client"
import {
  PinnedItemType,
  PinnedItems,
  RecentStyleItem,
  RenameSDStyleDto,
  SDStyleDetail,
  SDStyleRoleCapabilities,
  SearchSDStyleResponse,
  UpdateSDStyleDto,
} from "@/api/sdk"
import { useRecentStylesInfiniteQuery } from "@/components/Workspace/RecentV2/RecentStyles"
import { useExploreStyleDetailQuery } from "@/queries/explore"
import { actionMiddleware } from "@/queries/middlewares/actionMiddleware"
import { useWorkspacePinInfiniteQuery } from "@/queries/workspace"
import { InfiniteData, QueryClient } from "@tanstack/react-query"
import { createMutation } from "react-query-kit"
import { useWorkspaceStyleDetailQuery, useWorkspaceStyleInfiniteQuery } from "./useGetStyleInfiniteQuery"

export interface StyleMutationVariables {
  styleId: string
  userUid?: string
  capabilities?: {
    canShare?: boolean
    canUpdate?: boolean
  }
}

export interface UseUpdateStyleMutationVariables extends StyleMutationVariables {
  data: UpdateSDStyleDto
}

export const useUpdateStyleMutation = createMutation({
  mutationFn: async (props: UseUpdateStyleMutationVariables) => {
    return client.api.sdStyleControllerSaveStyle(props.styleId, props.data).then(res => res.data)
  },
  use: [
    actionMiddleware({
      onSuccess: ({ queryClient, variables, data: responseData }) => {
        // Update style list
        const styleListKey = useWorkspaceStyleInfiniteQuery.getKey()
        const styleListQueriesDataEntries = queryClient.getQueriesData<InfiniteData<SearchSDStyleResponse, number>>({
          queryKey: styleListKey,
        })
        styleListQueriesDataEntries?.forEach(([key, data]) => {
          if (!data) return

          const updatedData = {
            ...data,
            pages: data.pages.map(page => {
              return {
                ...page,
                styles: page.data.map(style => {
                  if (style.id === variables.styleId) {
                    return {
                      ...style,
                      name: variables.data.name,
                    }
                  }

                  return style
                }),
              }
            }),
          }
          queryClient.setQueryData(key, updatedData)
        })

        const styleDetailKey = useWorkspaceStyleDetailQuery.getKey()
        const styleDetailKeyQueriesDataEntries = queryClient.getQueriesData<SDStyleDetail>({
          queryKey: styleDetailKey,
        })
        styleDetailKeyQueriesDataEntries?.forEach(([key, data]) => {
          if (!data) return

          const updatedData = {
            ...data,
            ...variables.data,
            ...responseData,
          }
          queryClient.setQueryData(key, updatedData)
        })
      },
    }),
  ],
})

export interface UseRenameStyleMutationVariables extends StyleMutationVariables {
  data: RenameSDStyleDto
}

export const useRenameStyleMutation = createMutation({
  mutationFn: async (props: UseRenameStyleMutationVariables) => {
    return client.api.sdStyleControllerRenameStyle(props.styleId, props.data).then(res => res.data)
  },
  use: [
    actionMiddleware({
      onSuccess: ({ queryClient, variables }) => {
        // Update style list
        const styleListKey = useWorkspaceStyleInfiniteQuery.getKey()
        const styleListQueriesDataEntries = queryClient.getQueriesData<InfiniteData<SearchSDStyleResponse, number>>({
          queryKey: styleListKey,
        })

        styleListQueriesDataEntries?.forEach(([key, data]) => {
          if (!data) return

          const updatedData = {
            ...data,
            pages: data.pages.map(page => {
              return {
                ...page,
                styles: page.data.map(style => {
                  if (style.id === variables.styleId) {
                    return {
                      ...style,
                      name: variables.data.name,
                    }
                  }

                  return style
                }),
              }
            }),
          }
          queryClient.setQueryData(key, updatedData)
        })

        // update style detail
        const styleDetailKey = useWorkspaceStyleDetailQuery.getKey({
          styleId: variables.styleId,
        })
        const styleDetailKeyQueriesDataEntries = queryClient.getQueriesData<SDStyleDetail>({
          queryKey: styleDetailKey,
        })
        styleDetailKeyQueriesDataEntries?.forEach(([key, data]) => {
          if (!data) return

          const updatedData = {
            ...data,
            ...variables.data,
          }
          queryClient.setQueryData(key, updatedData)
        })
      },
    }),
  ],
})

export const useDuplicateStyleMutation = createMutation({
  mutationFn: async (props: StyleMutationVariables) => {
    return client.api.sdStyleControllerDuplicateStyle(props.styleId).then(res => res.data)
  },
  use: [
    actionMiddleware({
      onSuccess: ({ queryClient }) => {
        // Update style list
        const styleListKey = useWorkspaceStyleInfiniteQuery.getKey()
        const styleListQueriesDataEntries = queryClient.getQueriesData<InfiniteData<SearchSDStyleResponse, number>>({
          queryKey: styleListKey,
        })

        styleListQueriesDataEntries?.forEach(([key, data]) => {
          if (key[1]?.["mode"] === "owned") {
            queryClient.invalidateQueries({ queryKey: key })
          }
        })
      },
    }),
  ],
})

const handleUpdateLibrarySuccess = (props: {
  queryClient: QueryClient
  variables: StyleMutationVariables
  data: SDStyleRoleCapabilities
}) => {
  const { queryClient, variables, data: capabilities } = props

  // Update style list
  const styleListKey = useWorkspaceStyleInfiniteQuery.getKey()
  const styleListQueriesDataEntries = queryClient.getQueriesData<InfiniteData<SearchSDStyleResponse, number>>({
    queryKey: styleListKey,
  })
  styleListQueriesDataEntries?.forEach(([key, data]) => {
    if (key[1]?.["mode"] === "owned") {
      queryClient.invalidateQueries({ queryKey: key })
    }
    if (key[1]?.["mode"] !== "shared") {
      return
    }
    if (!data) return

    const updatedData = {
      ...data,
      pages: data.pages.map(page => {
        return {
          ...page,
          data: page.data.map(style => {
            if (style.id === variables.styleId) {
              return {
                ...style,
                capabilities: {
                  ...capabilities,
                  canUpdate:
                    variables.capabilities?.canUpdate !== undefined
                      ? variables.capabilities?.canUpdate
                      : capabilities.canUpdate,
                  canShare:
                    variables.capabilities?.canShare !== undefined
                      ? variables.capabilities?.canShare
                      : capabilities.canShare,
                },
              }
            }

            return style
          }),
        }
      }),
    }
    queryClient.setQueryData(key, updatedData)
  })

  // update style detail
  const styleDetailKey = useWorkspaceStyleDetailQuery.getKey({
    styleId: variables.styleId,
  })
  const styleDetailKeyQueriesDataEntries = queryClient.getQueriesData<SDStyleDetail>({
    queryKey: styleDetailKey,
  })
  styleDetailKeyQueriesDataEntries?.forEach(([key, data]) => {
    if (!data) return

    const updatedData = {
      ...data,
      capabilities: {
        ...capabilities,
        canUpdate:
          variables.capabilities?.canUpdate !== undefined ? variables.capabilities?.canUpdate : capabilities.canUpdate,
        canShare:
          variables.capabilities?.canShare !== undefined ? variables.capabilities?.canShare : capabilities.canShare,
      },
    }
    queryClient.setQueryData(key, updatedData)
  })

  // update style detail explore
  const styleDetailExploreKey = useExploreStyleDetailQuery.getKey({
    styleId: variables.styleId,
    userUid: variables.userUid,
  })
  const styleDetailExploreKeyQueriesDataEntries = queryClient.getQueriesData<SDStyleDetail>({
    queryKey: styleDetailExploreKey,
  })
  styleDetailExploreKeyQueriesDataEntries?.forEach(([key, data]) => {
    if (!data) return

    const updatedData = {
      ...data,
      capabilities,
    }
    queryClient.setQueryData(key, updatedData)
  })

  // update recent style list

  const styleRecentListKey = useRecentStylesInfiniteQuery.getKey()
  const styleRecentListQueriesDataEntries = queryClient.getQueriesData<InfiniteData<RecentStyleItem[], number>>({
    queryKey: styleRecentListKey,
  })
  if (styleRecentListQueriesDataEntries) {
    styleRecentListQueriesDataEntries?.forEach(([key, data]) => {
      if (!data) return

      const updatedData = {
        ...data,
        pages: data.pages.map(page => {
          return [
            ...page,
            {
              ...page,
              capabilities,
            },
          ]
        }),
      }
      queryClient.setQueryData(key, updatedData)
    })
  }

  // update pinned style list
  const pinnedStyleListKey = useWorkspacePinInfiniteQuery.getKey({ type: PinnedItemType.STYLE })
  const pinnedStyleListQueriesDataEntries = queryClient.getQueriesData<InfiniteData<PinnedItems[], number>>({
    queryKey: pinnedStyleListKey,
  })

  if (pinnedStyleListQueriesDataEntries) {
    pinnedStyleListQueriesDataEntries?.forEach(([key, data]) => {
      if (!data) return

      const updatedData = {
        ...data,
        pages: data.pages.map(page => {
          return [
            ...page,
            {
              ...page,
              capabilities,
            },
          ]
        }),
      }
      queryClient.setQueryData(key, updatedData)
    })
  }
}

export const useAddStyleToLibraryMutation = createMutation({
  mutationKey: ["add-style-to-library"],
  mutationFn: async (props: StyleMutationVariables) => {
    return client.api.sdStyleControllerAddStyleToLibrary(props.styleId).then(res => res.data)
  },
  use: [
    actionMiddleware({
      onSuccess: handleUpdateLibrarySuccess,
    }),
  ],
})

export const useRemoveStyleFromLibraryMutation = createMutation({
  mutationKey: ["remove-style-from-library"],
  mutationFn: async (props: StyleMutationVariables) => {
    return client.api.sdStyleControllerRemoveStyleFromLibrary(props.styleId).then(res => res.data)
  },
  use: [
    actionMiddleware({
      onSuccess: handleUpdateLibrarySuccess,
    }),
  ],
})
