import client from "@/api/client"
import {
  ExploreSDStyleDetail,
  GetExploreSDStylesResponse,
  PinnedItemType,
  PinnedItems,
  RecentStyleItem,
  SDStyleDetail,
  SearchSDStyleResponse,
} from "@/api/sdk"
import { actionMiddleware } from "@/queries/middlewares/actionMiddleware"
import { InfiniteData, useQueryClient } from "@tanstack/react-query"
import { createMutation } from "react-query-kit"
import { useWorkspaceStyleDetailQuery, useWorkspaceStyleInfiniteQuery } from "./useGetStyleInfiniteQuery"
import { useRecentStylesInfiniteQuery } from "@/components/Workspace/RecentV2/RecentStyles"
import { useWorkspacePinInfiniteQuery } from "@/queries/workspace"
import { useExploreStyleDetailQuery, useExploreStylesInfiniteQuery } from "@/queries/explore"

export const useFavoriteStyleMutation = createMutation({
  mutationFn: ({ id }: { id: string; userUid?: string }) => client.api.sdStyleControllerToggleFavoriteStyle(id),
  use: [
    actionMiddleware({
      onMutate: ({ queryClient, variables }) => {
        // Update style explore list

        const exploreStyleListKey = useExploreStylesInfiniteQuery.getKey()

        const exploreStyleListQueriesDataEntries = queryClient.getQueriesData<
          InfiniteData<GetExploreSDStylesResponse, number>
        >({ queryKey: exploreStyleListKey })

        if (exploreStyleListQueriesDataEntries) {
          exploreStyleListQueriesDataEntries.forEach(([key, styleListData]) => {
            if (styleListData) {
              const updatedData = {
                ...styleListData,
                pages: styleListData.pages.map(page => {
                  return {
                    ...page,
                    styles: page.styles.map(style => {
                      if (style.id === variables.id) {
                        return {
                          ...style,
                          favoritedByUser: !style.favoritedByUser,
                          metric: {
                            ...style.metric,
                            favoriteCount: style.favoritedByUser
                              ? (style.metric?.favoriteCount ?? 0) - 1
                              : (style.metric?.favoriteCount ?? 0) + 1,
                          },
                        }
                      }
                      return style
                    }),
                  }
                }),
              }

              queryClient.setQueryData(key, updatedData)
            }
          })
        }

        // Update style workspace list
        const workspaceStyleListKey = useWorkspaceStyleInfiniteQuery.getKey()

        const workspaceStyleListQueriesDataEntries = queryClient.getQueriesData<
          InfiniteData<SearchSDStyleResponse, number>
        >({
          queryKey: workspaceStyleListKey,
        })

        if (workspaceStyleListQueriesDataEntries) {
          workspaceStyleListQueriesDataEntries.forEach(([key, styleListData]) => {
            if (styleListData) {
              const updatedData = {
                ...styleListData,
                pages: styleListData.pages.map(page => {
                  return {
                    ...page,
                    styles: page.data.map(style => {
                      if (style.id === variables.id) {
                        return {
                          ...style,
                          favoritedByUser: !style.favoritedByUser,
                          metric: {
                            ...style.metric,
                            favoriteCount: style.favoritedByUser
                              ? (style.metric?.favoriteCount ?? 0) - 1
                              : (style.metric?.favoriteCount ?? 0) + 1,
                          },
                        }
                      }
                      return style
                    }),
                  }
                }),
              }

              queryClient.setQueryData(key, updatedData)
            }
          })
        }

        // Update style recent list

        const styleRecentListKey = useRecentStylesInfiniteQuery.getKey()

        const styleRecentListQueriesDataEntries = queryClient.getQueriesData<InfiniteData<RecentStyleItem[], number>>({
          queryKey: styleRecentListKey,
        })

        if (styleRecentListQueriesDataEntries) {
          styleRecentListQueriesDataEntries.forEach(([key, styleRecentListData]) => {
            if (styleRecentListData) {
              const updatedData = {
                ...styleRecentListData,
                pages: styleRecentListData.pages.map(page => {
                  return [
                    ...page.map(item => {
                      if (item.style.id === variables.id) {
                        return {
                          ...item,
                          style: {
                            ...item.style,
                            favoritedByUser: !item.style.favoritedByUser,
                            metric: {
                              ...item.style.metric,
                              favoriteCount: item.style.favoritedByUser
                                ? (item.style.metric?.favoriteCount ?? 0) - 1
                                : (item.style.metric?.favoriteCount ?? 0) + 1,
                            },
                          },
                        }
                      }

                      return item
                    }),
                  ]
                }),
              }

              queryClient.setQueryData(key, updatedData)
            }
          })
        }

        //update pinned style
        const stylePinnedListKey = useWorkspacePinInfiniteQuery.getKey({
          type: PinnedItemType.STYLE,
        })

        const stylePinnedListQueriesDataEntries = queryClient.getQueriesData<InfiniteData<PinnedItems[], number>>({
          queryKey: stylePinnedListKey,
        })

        if (stylePinnedListQueriesDataEntries) {
          stylePinnedListQueriesDataEntries.forEach(([key, stylePinnedListData]) => {
            if (stylePinnedListData) {
              const updatedData = {
                ...stylePinnedListData,
                pages: stylePinnedListData.pages.map(page => {
                  return [
                    ...page.map(item => {
                      if (item.style?.id === variables.id) {
                        return {
                          ...item,
                          style: {
                            ...item.style,
                            favoritedByUser: !item.style.favoritedByUser,
                            metric: {
                              ...item.style.metric,
                              favoriteCount: item.style.favoritedByUser
                                ? (item.style.metric?.favoriteCount ?? 0) - 1
                                : (item.style.metric?.favoriteCount ?? 0) + 1,
                            },
                          },
                        }
                      }

                      return item
                    }),
                  ]
                }),
              }

              queryClient.setQueryData(key, updatedData)
            }
          })
        }

        // Update style detail

        const exploreStyleDetailKey = useExploreStyleDetailQuery.getKey({
          styleId: variables.id,
          userUid: variables.userUid,
        })

        const StyleDetailQueriesDataEntries = queryClient.getQueriesData<ExploreSDStyleDetail>({
          queryKey: exploreStyleDetailKey,
        })

        if (StyleDetailQueriesDataEntries) {
          StyleDetailQueriesDataEntries.forEach(([key, styleDetailData]) => {
            if (styleDetailData) {
              const updatedData = {
                ...styleDetailData,
                ...styleDetailData,
                favoritedByUser: !styleDetailData.favoritedByUser,
                metric: {
                  ...styleDetailData.metric,
                  favoriteCount: styleDetailData.favoritedByUser
                    ? (styleDetailData.metric?.favoriteCount ?? 0) - 1
                    : (styleDetailData.metric?.favoriteCount ?? 0) + 1,
                },
              }

              queryClient.setQueryData(key, updatedData)
            }
          })
        }

        const workspaceStyleDetailKey = useWorkspaceStyleDetailQuery.getKey({
          styleId: variables.id,
        })

        const workspaceStyleDetailQueriesDataEntries = queryClient.getQueriesData<SDStyleDetail>({
          queryKey: workspaceStyleDetailKey,
        })

        if (workspaceStyleDetailQueriesDataEntries) {
          workspaceStyleDetailQueriesDataEntries.forEach(([key, styleDetailData]) => {
            if (styleDetailData) {
              const updatedData = {
                ...styleDetailData,
                ...styleDetailData,
                favoritedByUser: !styleDetailData.favoritedByUser,
                metric: {
                  ...styleDetailData.metric,
                  favoriteCount: styleDetailData.favoritedByUser
                    ? (styleDetailData.metric?.favoriteCount ?? 0) - 1
                    : (styleDetailData.metric?.favoriteCount ?? 0) + 1,
                },
              }

              queryClient.setQueryData(key, updatedData)
            }
          })
        }
      },
    }),
    useMutationNext => {
      return (options, queryClient) => {
        const qc = useQueryClient(queryClient)

        return useMutationNext({
          ...options,
          onSettled: (_, __, {}) => {},
        })
      }
    },
  ],
})
