import { RecentSDWorkflowItem } from "@/api/sdk"
import React, { memo } from "react"
import RecentAccordion from "../RecentAccordion"
import ComfyUIRecipeCard from "@/components/Tools/ComfyUIRecipe/ComfyUIRecipeCard"

interface RecentComfyUIItemProps {
  name: string
  recent: RecentSDWorkflowItem[]
  containerWidth: number
  layout: "grid" | "list"
}

const RecentComfyUIItem = ({ name, containerWidth, recent, layout }: RecentComfyUIItemProps) => {
  if (recent.length === 0) return null
  return (
    <RecentAccordion name={name} layout={layout}>
      {recent?.map(item => {
        return (
          <ComfyUIRecipeCard
            accessedAt={item.accessedAt}
            layout={layout}
            key={item.sdWorkflow.id}
            workflow={item.sdWorkflow}
            href={`/workspace/tools/comfyui/${item.sdWorkflow.id}`}
          />
        )
      })}
    </RecentAccordion>
  )
}

export default memo(RecentComfyUIItem)
