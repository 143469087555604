import { PinnedItemType, SDWorkflowDetail } from "@/api/sdk"
import IconButton from "@/components/IconButton"
import Tooltip from "@/components/Tooltip"
import ContextMenuZone from "@/components/Workspace/FoldersV2/ContextMenuZone"
import MultiModeActions from "@/components/Workspace/FoldersV2/TabFolder/MultiModeActions"
import { DeleteIcon, PinIcon, RefreshIcon, TickIcon, UnPinIcon } from "@/components/shared/icons"
import { useToast } from "@/hooks"
import { googleAnalytics } from "@/lib/gtag"
import { usePinMutation } from "@/queries"
import { useDeleteItemStore, useRecoverItemStore } from "@/stores"
import { AnimatePresence, motion } from "framer-motion"
import { Fragment, useCallback, useMemo } from "react"

interface MultiComfyUISelectBarProps {
  selectedItems: (SDWorkflowDetail & { pinned?: boolean })[]
  offsetLeft?: number
  selectionMode?: boolean
  children?: React.ReactNode
  clear: () => void
  view?: "owned" | "shared" | "draft"
}

const MultiComfyUISelectBar = ({
  selectedItems,
  offsetLeft = 0,
  clear,
  view,
  children,
  selectionMode,
}: MultiComfyUISelectBarProps) => {
  const toast = useToast()
  const setDeletingItem = useDeleteItemStore(s => s.setDeletingItem)
  const setRecoverItem = useRecoverItemStore(state => state.setRecoverItem)

  const { mutate: mutatePin, isPending: isPendingPin } = usePinMutation({
    onSuccess: () => {
      toast({
        status: "success",
        title: `ComfyUI ${selectedItems[0].pinned ? "unpinned" : "pinned"}`,
      })
      clear()
    },
  })

  const handlePinComfyUI = useCallback(() => {
    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        action: "Multi ComfyUI Pin",
        ...selectedItems.reduce((acc, item, index) => {
          acc[`comfyui_id_${index}`] = item.id
          return acc
        }, {}),
      },
    })

    mutatePin({
      ids: selectedItems.map(item => item.id),
      isPinned: !selectedItems[0].pinned,
      type: PinnedItemType.SD_WORKFLOW,
    })
  }, [mutatePin, selectedItems])

  const handleRecoverComfyUI = useCallback(() => {
    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        action: "Multi ComfyUI Recover",
        ...selectedItems.reduce((acc, item, index) => {
          acc[`comfyui_id_${index}`] = item.id
          return acc
        }, {}),
      },
    })

    setRecoverItem({
      ids: selectedItems.map(item => item.id),
      deletedAt: selectedItems.find(item => item.deletedAt)?.deletedAt ?? "",
      type: "comfyui-workflow",
      onClose: clear,
    })
  }, [clear, selectedItems, setRecoverItem])

  const handleDeleteComfyUI = useCallback(() => {
    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        action: "Multi ComfyUI Delete",
        ...selectedItems.reduce((acc, item, index) => {
          acc[`comfyui_id_${index}`] = item.id
          return acc
        }, {}),
      },
    })

    setDeletingItem({
      type: "comfyui-workflow",
      ids: selectedItems.filter(item => item.capabilities?.canDelete).map(item => item.id),
      id: "",
      deletedAt: selectedItems.find(item => item.id)?.deletedAt ?? "",
      onClose: clear,
    })
  }, [clear, selectedItems, setDeletingItem])

  const renderComfyUIActions = useMemo(() => {
    return (
      <MultiModeActions
        onPin={{
          fn: handlePinComfyUI,
          enabled: !selectedItems.some(item => !item.capabilities?.canView) && selectedItems.length > 0,
          visible: !selectedItems.find(item => item.deletedAt)?.deletedAt,
          title: `${selectedItems[0]?.pinned ? "Unpin" : "Pin"}`,
          icon: selectedItems[0]?.pinned ? <UnPinIcon width={20} height={20} /> : <PinIcon width={20} height={20} />,
        }}
        onRecover={{
          fn: handleRecoverComfyUI,
          enabled: !selectedItems.some(item => !item.capabilities?.canRecover) && selectedItems.length > 0,
          visible: !!selectedItems.find(item => item.deletedAt)?.deletedAt,
          title: `Recover`,
        }}
        onDelete={{
          fn: handleDeleteComfyUI,
          enabled: !selectedItems.some(item => !item.capabilities?.canDelete) && selectedItems.length > 0,
          visible: true,
          title: `Delete`,
        }}
      />
    )
  }, [selectedItems, handleDeleteComfyUI, handlePinComfyUI, handleRecoverComfyUI])

  return (
    <Fragment>
      <AnimatePresence>
        {selectedItems.length > 0 && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed -translate-x-1/2 top-[3rem] md:top-32 z-20"
            style={{
              left: `calc(50% + ${offsetLeft}px)`,
            }}
          >
            <div className="rounded-2xl bg-atherGray-850 py-2 px-2 md:px-4 text-xs font-semibold text-atherGray-300 h-12 flex items-center space-x-4">
              <div className="pl-2 flex items-center">
                {selectedItems.length} <span className="hidden md:inline-block ml-2">selected</span>
                <span className="inline-block ml-2 md:hidden">
                  <TickIcon />
                </span>
              </div>
              <hr className="w-[1px] h-6 border border-atherGray-800" />
              {/* <button>Select all</button> */}
              <div className="flex items-center space-x-2">
                {!selectedItems.find(item => item.deletedAt)?.deletedAt && (
                  <Tooltip
                    trigger={
                      <IconButton
                        className="w-8 h-8 p-0 bg-atherGray-850 hover:bg-atherGray-700 active:bg-atherGray-800 text-atherGray-300 hover:text-atherGray-0"
                        colorScheme="transparent"
                        isLoading={isPendingPin}
                        onClick={() => {
                          handlePinComfyUI()
                        }}
                      >
                        {!selectedItems[0].pinned ? (
                          <PinIcon width={16} height={16} />
                        ) : (
                          <UnPinIcon width={16} height={16} />
                        )}
                      </IconButton>
                    }
                  >
                    {selectedItems[0].pinned ? "Unpin" : "Pin"}
                  </Tooltip>
                )}

                {selectedItems.find(item => item.deletedAt)?.deletedAt && (
                  <Tooltip
                    trigger={
                      <IconButton
                        disabled={selectedItems.some(i => !i.capabilities?.canRecover)}
                        className="w-8 h-8 p-0 bg-atherGray-850 hover:bg-atherGray-700 active:bg-atherGray-800 text-atherGray-300 hover:text-atherGray-0"
                        colorScheme="transparent"
                        onClick={() => {
                          handleRecoverComfyUI()
                        }}
                      >
                        <RefreshIcon className="rotate-180" width={16} height={16} />
                      </IconButton>
                    }
                  >
                    Recover
                  </Tooltip>
                )}

                {view !== "shared" && (
                  <Tooltip
                    trigger={
                      <IconButton
                        className="w-8 h-8 p-0 hover:bg-atherGray-700 active:bg-atherGray-800 text-red-500"
                        colorScheme="transparent"
                        disabled={selectedItems.some(item => !item.capabilities?.canDelete)}
                        onClick={() => {
                          handleDeleteComfyUI()
                        }}
                      >
                        <DeleteIcon width={16} height={16} />
                      </IconButton>
                    }
                  >
                    Delete
                  </Tooltip>
                )}
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      {children && (
        <ContextMenuZone
          isDisabled={!selectionMode}
          className="flex-1 flex flex-col"
          contextMenu={renderComfyUIActions}
        >
          {children}
        </ContextMenuZone>
      )}
    </Fragment>
  )
}

export default MultiComfyUISelectBar
