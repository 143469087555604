import { IconProps } from "."

export const RemoveSquareIcon = (props: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
      <path
        d="M12.334 9L5.66732 9"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.08398 9.00065C1.08398 5.2687 1.08398 3.40272 2.24335 2.24335C3.40272 1.08398 5.2687 1.08398 9.00065 1.08398C12.7326 1.08398 14.5986 1.08398 15.7579 2.24335C16.9173 3.40272 16.9173 5.2687 16.9173 9.00065C16.9173 12.7326 16.9173 14.5986 15.7579 15.7579C14.5986 16.9173 12.7326 16.9173 9.00065 16.9173C5.2687 16.9173 3.40272 16.9173 2.24335 15.7579C1.08398 14.5986 1.08398 12.7326 1.08398 9.00065Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </svg>
  )
}
