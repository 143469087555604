import { UploadProgress } from "@/stores/useUploadProgressStore"
import { multipartUpload } from "./useUploadFileMutation"
import type { UploadFile } from "@/api/sdk"
import client from "@/api/client"

export type UploadModelFilesArgs = {
  files: File[]
  onProgress?: (p: UploadProgress) => void
  onError?: (e: Error) => void
}

export const MODEL_CHUNK_SIZE = 512 * 1024 * 1024 // 512MB

export const uploadModelFiles = async ({ files, onProgress }: UploadModelFilesArgs) => {
  return multipartUpload<UploadFile>({
    files,
    chunkSize: MODEL_CHUNK_SIZE,
    onProgress: p => onProgress?.(p),
    async onInitiateUpload(infos) {
      return client.api
        .sdModelUploadControllerInitializeMultipartUpload({
          files: infos,
        })
        .then(res => res.data)
    },
    async onCompleteUpload(request) {
      await client.api.sdModelUploadControllerCompleteMultipartUpload([request])
    },
    async onAbortUpload(request) {
      await client.api.sdModelUploadControllerAbortMultipartUpload([request])
    },
  })
}
