import {
  EntityType,
  FileAssociatedResource,
  GetModelsResponse,
  SDBaseModel,
  SDStyleDetail,
  SDStyleTag,
  StyleImage,
  UpdateSDStyleDto,
  WildcardDetail,
} from "@/api/sdk"
import { useWildcardListMutate } from "@/components/Explore/ImageDetail/ImageActionButtons"
import StyleDetailModal from "@/components/Explore/StyleDetail"
import LoadingLogo from "@/components/LoadingLogo"
import NotFoundUI from "@/components/NotFound"
import RequestModal from "@/components/Workspace/ImageDetailWorkspace/RequestModal"
import { useLoraQuery } from "@/components/Workspace/Recipes/RecipeDetail/FormDetail/FormDetailLora"
import ListRecipes from "@/components/Workspace/Recipes/RecipeDetail/ListRecipes"
import { useDebounce, useScreen, useToast } from "@/hooks"
import useCustomRouter from "@/hooks/useCustomRouter"
import { useClearUrlQuery } from "@/hooks/useQuery"
import { googleAnalytics } from "@/lib/gtag"
import { useAuth } from "@/providers/authContext"
import { uploadFiles, useCreateTaskMutation, useTrackingViewMutation } from "@/queries"
import { useWorkspaceStyleDetailQuery } from "@/queries/tools/style/useGetStyleInfiniteQuery"
import { useDuplicateStyleMutation, useUpdateStyleMutation } from "@/queries/tools/style/useUpdateStyleMutation"
import { useGetRecipeDetailQuery } from "@/queries/workspace/recipe"
import { useManagementErrorsStore } from "@/stores"
import { handleConvertHEICtoJPG } from "@/utils/convert"
import { mapSeries } from "@/utils/promise"
import { resizeImage } from "@/utils/resize-image"
import { useQueryClient } from "@tanstack/react-query"
import { motion } from "framer-motion"
import { FC, useCallback, useEffect, useMemo, useState } from "react"
import { useForm } from "react-hook-form"
import SettingStyle from "./SettingStyle"
import StyleContainer from "./StyleContainer"
import StyleImagesUploaded from "./StyleImagesUploaded"
import { handlePasteParamsRecipe, RecipeCreateType, recipeParamsWithWildcard, validateRecipeParams } from "@/utils/task"
import { CheckableRecipeInput } from "@/components/Workspace/Recipes/RecipeDetail"

export type StyleDetailProps = {
  styleId: string
}

export const TRY_STYLE_RECIPE = "try-style"

export const uploadStyleFiles = async (files: Blob[], onError?: (err: Error) => void) => {
  const resized = await mapSeries(files, async file => {
    let newFile = files[0]

    if (newFile.type === "image/heic" || newFile.name.endsWith(".heic")) {
      newFile = (await handleConvertHEICtoJPG(newFile)) as File
    }

    return resizeImage(newFile, 512)
  })

  return uploadFiles({ files: resized, resourceType: FileAssociatedResource.STYLE, onError })
}

const StyleDetail: FC<StyleDetailProps> = ({ styleId }) => {
  const toast = useToast()
  const router = useCustomRouter()
  const { mutate: mutateTrackingView } = useTrackingViewMutation()

  const {
    data: styleRes,
    isLoading,
    error,
    isError,
    refetch,
    isSuccess,
  } = useWorkspaceStyleDetailQuery({
    variables: { styleId },
  })

  useEffect(() => {
    if (isSuccess) {
      mutateTrackingView({
        entityId: style?.id ?? styleId,
        entityType: EntityType.STYLE,
      })

      googleAnalytics.handleCategoryEvent({
        action: "click",
        params: {
          action: "View style detail",
          style_id: styleId ?? "",
          style_name: style?.name ?? "",
        },
      })
    }
  }, [isSuccess])

  const { data: recipe } = useGetRecipeDetailQuery({ variables: { recipeId: TRY_STYLE_RECIPE } })

  const { mutate: mutateUpdateStyle, isPending: isSaving } = useUpdateStyleMutation({
    onSettled(updated, error, dto) {
      if (!updated) return

      setStyle(prev => ({
        ...prev!,
        name: updated.name ?? "",
        images: updated.images,
        thumbnailUrl: updated.thumbnailUrl,
        description: updated.description ?? "",
      }))
      setImages(updated.images)
      setNameInput(updated.name ?? "")
      setDescription(updated.description ?? "")
    },
  })

  const { mutate: duplicateStyle, isPending: isDuplicating } = useDuplicateStyleMutation({
    onSuccess: async duplicated => {
      setTimeout(() => {
        router.openNewTab(`/workspace/tools/styles/${duplicated.id}`)
      }, 1)

      toast({
        status: "success",
        title: "Success!",
        message: ["Style is duplicated successfully!"],
        duration: 5000,
      })
    },
  })

  const [style, setStyle] = useState<SDStyleDetail & { thumbnailId?: string }>()
  const [images, setImages] = useState<StyleImage[]>([])
  const [nameInput, setNameInput] = useState<string>("New Style")
  const clearUrl = useClearUrlQuery()
  const [tags, setTags] = useState<SDStyleTag[]>([])
  const [description, setDescription] = useState<string>("")
  const isDraft = useMemo(() => style?.isDraft ?? true, [style])
  const isCreate = isDraft
  const statusErrorCode = Number((error as any)?.statusCode)

  useEffect(() => {
    if (styleRes) {
      setStyle(styleRes)
      setImages([...styleRes.images])
      setNameInput(styleRes.name ?? "")
      setTags(styleRes.tags ?? [])
      setDescription(styleRes.description ?? "")
    }
  }, [styleRes])

  const updateStyle = useCallback(
    (data: UpdateSDStyleDto) => {
      if (!style) return

      googleAnalytics.handleCategoryEvent({
        action: "click",
        params: {
          action: "Update style",
          style_id: style.id ?? "",
          style_name: style?.name ?? "",
        },
      })

      mutateUpdateStyle({
        styleId: style.id,
        data: {
          description,
          thumbnailId: style.thumbnailId,
          name: nameInput || style.name,
          ...data,
        },
      })
    },
    [style, nameInput, description, mutateUpdateStyle],
  )

  const handleSave = async () => {
    if (!style) return

    clearUrl(["draft"])

    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        action: "Save style",
        style_id: styleId ?? "",
        style_name: style?.name ?? "",
      },
    })

    updateStyle({
      name: nameInput || style.name,
      thumbnailId: style.thumbnailId,
      images,
      isDraft: false,
      description: description,
    })
  }

  const qc = useQueryClient()
  const form = useForm<RecipeCreateType>({ mode: "onSubmit" })

  const { width } = useScreen()
  const { user } = useAuth()
  const [isOpenSideBar, setIsOpenSidebar] = useState(true)
  const [recipeInputs, setRecipeInputs] = useState<CheckableRecipeInput[]>([])
  const [isSave, setIsSave] = useState(false)

  useEffect(() => {
    if (!recipe) return

    const defaultValues = recipe.steps
      ?.map(step =>
        step.inputs.reduce((acc, curr) => {
          acc[curr.key] = curr.value

          return acc
        }, {}),
      )
      .reduce((acc, curr) => ({ ...acc, ...curr }), {})

    handlePasteParamsRecipe(defaultValues, form.reset, setRecipeInputs, recipe)
  }, [recipe, form.reset])

  const setErrorState = useManagementErrorsStore(state => state.setErrorState)

  const { mutate: createTask, isPending: isCreatingTask } = useCreateTaskMutation({
    onSuccess: () => {
      qc.invalidateQueries({ queryKey: ["recipe-ongoing", { recipeIds: [TRY_STYLE_RECIPE] }] })

      if (width < 768) {
        setIsOpenSidebar(false)
      }
    },
    onError: (err: any) => {
      if (
        err?.message.startsWith("Guest user does not have permission") ||
        err?.message.startsWith("Your subscription has ended")
      ) {
        setErrorState({
          isOpen: true,
          message: err.message,
        })
        return
      }

      if (err?.error === "Bad Request") {
        toast({ title: "Cannot Complete Request", message: [err.message], status: "error" })
      } else {
        toast({ title: "Error", message: [err.message], status: "error" })
      }
    },
  })

  const { mutateAsync: mutateWildcardsRes, isPending: isLoadingWildcards } = useWildcardListMutate()

  const handleGenerate = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (!style) return

    const lorasKey = useLoraQuery.getKey({ baseModel: SDBaseModel.Sd1X })
    const loras = qc.getQueryData<GetModelsResponse>(lorasKey)
    const res = await validateRecipeParams({
      form,
      recipeInputs,
      loras,
    })

    if (!res) return
    const { params } = res

    const hasWildcardField = recipeInputs.some(input => input.allowWildcard)

    let newParams = params

    if (hasWildcardField) {
      newParams = await recipeParamsWithWildcard(newParams, recipeInputs)
    }

    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        action: "Generate style",
        style_id: styleId ?? "",
        style_name: style?.name ?? "",
      },
    })

    return createTask({
      recipeId: TRY_STYLE_RECIPE,
      name: params["prompt"] || `Try style ${style.name ?? style?.id}`,
      params: {
        ...newParams,
        style: style.id,
        styleData: JSON.stringify(images),
        batch_size: 4,
      },
    })
  }

  const handleRunAgain = async (params: Record<string, any>) => {
    if (!style) return

    // read wildcard data
    // process wildcard prompt for only fields that are in params
    const hasWildcardField = recipeInputs.some(input => input.allowWildcard)

    let newParams = params

    if (hasWildcardField) {
      newParams = await recipeParamsWithWildcard(newParams, recipeInputs)
    }

    return createTask({
      recipeId: TRY_STYLE_RECIPE,
      name: params["prompt"] || `Try style ${style.name ?? style?.id}`,
      params: {
        ...newParams,
        style: style.id,
        batch_size: 4,
      },
    })
  }

  const disabledInput = !style?.capabilities?.canUpdate && !isCreate
  const isOpenRequest = useDebounce(!isLoading && !!styleRes && !styleRes.capabilities?.canView, 500)

  const handleClickEdit = () => {
    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        action: "Edit style",
        style_id: styleId ?? "",
        style_name: style?.name ?? "",
      },
    })

    // setEditing(true)
  }

  useEffect(() => {
    if (!isSave) return

    const timer = setTimeout(() => {
      updateStyle({
        images,
        description,
      })
      setIsSave(false)
    }, 2500)

    return () => clearTimeout(timer)
  }, [images, updateStyle, isSave, description])

  if (isLoading)
    return (
      <div className="flex items-center justify-center flex-1 w-full text-gray-600">
        <LoadingLogo />
      </div>
    )

  if (isError || !styleRes) {
    if (statusErrorCode === 403)
      return (
        <RequestModal
          onRefetch={refetch}
          isModal
          dataRequest={{
            entityId: styleId ?? "",
            entityType: EntityType.STYLE,
          }}
          isOpenRequest={true}
          onCloseRequestModal={() => router.push("/workspace/tools/styles")}
        />
      )

    return <NotFoundUI description="Style" redirectTo="/workspace/tools/styles" />
  }

  if (styleRes.creator.uid !== user?.uid) {
    return <StyleDetailModal filters={{ name: "styleDetailWorkspace" }} styleId={styleId} />
  }

  if (!style) return null

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} className="relative flex flex-1 w-full">
      <SettingStyle
        handleGenerate={handleGenerate}
        description={description}
        disabledInput={disabledInput}
        isDraft={isDraft}
        onSave={() => setIsSave(true)}
        form={form}
        images={images}
        isCreatingTask={isCreatingTask || isLoadingWildcards}
        isOpenSideBar={isOpenSideBar}
        recipe={recipe}
        setIsOpenSidebar={setIsOpenSidebar}
        recipeInputs={recipeInputs}
        setDescription={setDescription}
        setRecipeInputs={setRecipeInputs}
        setTags={setTags}
        tags={tags}
        style={style}
        updateStyle={updateStyle}
      />
      <div className="flex-1 flex flex-col">
        <StyleContainer
          inputName={nameInput}
          isSaving={isSaving}
          onDuplicateStyle={duplicateStyle}
          onEdit={handleClickEdit}
          onInputNameChange={setNameInput}
          onSave={handleSave}
          style={style}
          isDraft={isDraft}
          isDuplicating={isDuplicating}
        />
        <StyleImagesUploaded
          onSave={() => setIsSave(true)}
          setImages={setImages}
          updateStyle={updateStyle}
          onCloseSidebar={() => setIsOpenSidebar(false)}
          width={width}
          disabledInput={disabledInput}
          images={images}
          style={style}
        />
        <ListRecipes
          hiddenActions={["copy-recipe", "send-to-new-macro"]}
          recipeInputs={recipeInputs}
          isLoadingCreateTask={isCreatingTask}
          onCreateTask={({ params }) => handleRunAgain(params)}
          setRecipeInputs={setRecipeInputs}
          setValues={params => handlePasteParamsRecipe(params, form.reset, setRecipeInputs, recipe)}
          sendRecipe={TRY_STYLE_RECIPE}
          styleId={style.id}
        />
      </div>
      <RequestModal
        isModal
        onRefetch={refetch}
        dataRequest={{
          entityId: styleId ?? "",
          entityType: EntityType.STYLE,
          workspace: style.workspace,
        }}
        isOpenRequest={isOpenRequest}
        onCloseRequestModal={() => router.push("/workspace/tools/styles")}
      />
    </motion.div>
  )
}

export default StyleDetail
