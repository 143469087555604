import { useState } from "react"
import ContextMenu from "./ContextMenu"

interface ContextMenuZoneProps {
  className?: string
  children: React.ReactNode
  isDisabled?: boolean
  contextMenu: React.ReactNode
}

const ContextMenuZone = ({ className, children, contextMenu, isDisabled }: ContextMenuZoneProps) => {
  const [cursorPosition, setCursorPosition] = useState<{ x: number; y: number; isBottom: boolean } | null>(null)

  const handleContextMenu = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (isDisabled) return

    e.preventDefault()
    e.stopPropagation()

    const menuWidth = 224 // 12 rem
    const menuHeight = 146

    const isBottom = e.clientY + menuHeight + 16 < window.innerHeight

    if (!!cursorPosition) {
      setCursorPosition(null)
      return
    }

    setCursorPosition({ x: e.clientX - menuWidth, y: e.clientY, isBottom })
  }

  return (
    <div className={className} onContextMenu={handleContextMenu}>
      {children}
      <ContextMenu onClose={() => setCursorPosition(null)} position={cursorPosition}>
        {contextMenu}
      </ContextMenu>
    </div>
  )
}

export default ContextMenuZone
