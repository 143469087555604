import * as RadixPopover from "@radix-ui/react-popover"
import { AnimatePresence, motion } from "framer-motion"

type TriggerFn = (options: { isOpen?: boolean }) => React.ReactNode

interface PopoverProps {
  trigger: React.ReactNode | TriggerFn
  children?: React.ReactNode
  isOpen?: boolean
  setIsOpen?: (isOpen: boolean) => void
  className?: string
  align?: "center" | "start" | "end" | undefined
  focusOnOpen?: boolean
}

const Popover = ({
  trigger,
  children,
  isOpen,
  setIsOpen,
  className,
  align = "center",
  focusOnOpen = true,
}: PopoverProps) => {
  return (
    <RadixPopover.Root modal={false} open={isOpen} onOpenChange={setIsOpen}>
      <RadixPopover.PopoverTrigger
        asChild
        onClick={e => {
          const anchorElement = (e.target as HTMLElement).closest("a")
          if (anchorElement) {
            e.stopPropagation()
            e.preventDefault()
            setIsOpen?.(!isOpen)
          }
        }}
      >
        {typeof trigger === "function" ? trigger({ isOpen }) : trigger}
      </RadixPopover.PopoverTrigger>
      <AnimatePresence>
        {isOpen && (
          <RadixPopover.PopoverPortal forceMount>
            <RadixPopover.PopoverContent
              onOpenAutoFocus={e => {
                if (!focusOnOpen) {
                  e.preventDefault()
                }
              }}
              forceMount
              className="z-[99] text-atherGray-0"
              sideOffset={8}
              align={align}
            >
              <motion.div
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.9 }}
                transition={{ type: "spring", stiffness: 500, damping: 30, duration: 0.3, ease: "easeInOut" }}
                className={className}
              >
                {children}
              </motion.div>
            </RadixPopover.PopoverContent>
          </RadixPopover.PopoverPortal>
        )}
      </AnimatePresence>
    </RadixPopover.Root>
  )
}

export default Popover
