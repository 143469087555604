import { IconProps } from "."

export const CommentIcon = (props: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M5.66406 9.66699H10.3307M5.66406 6.33366H7.9974"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.44577 13.9273C12.2344 13.742 14.4558 11.4891 14.6385 8.66091C14.6743 8.10744 14.6743 7.53428 14.6385 6.98081C14.4558 4.15258 12.2344 1.89975 9.44577 1.71438C8.49438 1.65113 7.50108 1.65126 6.55163 1.71438C3.76296 1.89975 1.54164 4.15258 1.35886 6.98081C1.32309 7.53428 1.32309 8.10744 1.35886 8.66091C1.42543 9.69098 1.88099 10.6447 2.4173 11.4501C2.7287 12.0139 2.52319 12.7176 2.19884 13.3322C1.96497 13.7754 1.84804 13.997 1.94193 14.1571C2.03582 14.3171 2.24554 14.3222 2.66498 14.3324C3.49448 14.3526 4.05382 14.1175 4.49782 13.7901C4.74963 13.6044 4.87554 13.5115 4.96232 13.5009C5.0491 13.4902 5.21988 13.5605 5.56137 13.7012C5.8683 13.8276 6.22467 13.9056 6.55163 13.9273C7.50108 13.9905 8.49438 13.9906 9.44577 13.9273Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  )
}
