import Tooltip from "@/components/Tooltip"
import { currency } from "@/utils"
import { ReactNode } from "react"
import { twMerge } from "tailwind-merge"

interface IconValueProps {
  icon: ReactNode
  value: number
  className?: string
  title?: string
  isTooltip?: boolean
}

const IconValue = ({ icon, value, className, title, isTooltip }: IconValueProps) => {
  const renderBody = () => {
    return (
      <div title={isTooltip ? undefined : title} className={twMerge("flex items-center space-x-1", className)}>
        {icon}
        <span>{currency(value, undefined, { isConvert: true })}</span>
      </div>
    )
  }

  if (isTooltip) {
    return <Tooltip trigger={renderBody()}>{title}</Tooltip>
  }

  return renderBody()
}

export default IconValue
