import client from "@/api/client"
import { MoveImagesToFolderDto } from "@/api/sdk"
import { useMutation, useQueryClient } from "@tanstack/react-query"

export const useMoveImages = () => {
  const qc = useQueryClient()

  const mutation = useMutation({
    mutationFn: (input: MoveImagesToFolderDto) => client.api.imageControllerMoveImagesToFolder(input),
    onSuccess: () => {
      qc.invalidateQueries({ queryKey: ["images"] })
    },
  })

  return mutation
}
