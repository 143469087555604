import client from "@/api/client"
import { WorkflowDetail } from "@/api/sdk"
import { createAuthenticatedQuery } from "@/queries/createAuthenticatedQuery"
import { enableQueryMiddleware } from "@/queries/middlewares/enableQueryMiddleware"
import { createMutation, createQuery } from "react-query-kit"

export const useWorkspaceWorkflowDetailQuery = createAuthenticatedQuery<WorkflowDetail, { workflowId: string }, Error>({
  primaryKey: "workspace-workflow-detail",
  queryFn: ({ queryKey: [_primaryKey, variables] }) =>
    client.api.workflowControllerGetWorkflowById(variables.workflowId).then(res => res.data),
  use: [enableQueryMiddleware(i => !!i.workflowId)],
})

export const useWorkspaceWorkflowDetailNotAuthQuery = createQuery<WorkflowDetail, { workflowId: string }, Error>({
  primaryKey: "workspace-workflow-detail-not-auth",
  queryFn: ({ queryKey: [_primaryKey, variables] }) =>
    client.api.workflowControllerGetWorkflowById(variables.workflowId).then(res => res.data),
  use: [enableQueryMiddleware(i => !!i.workflowId)],
})

export const useWorkspaceWorkflowDetailMutation = createMutation({
  mutationFn: (workflowId: string) => client.api.workflowControllerGetWorkflowById(workflowId).then(res => res.data),
})
