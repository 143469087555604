import { IconProps } from "."

export const TickIcon = (props: IconProps) => {
  return (
    <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g id="elements">
        <path
          id="Vector 6663"
          d="M1.33301 6.6665C1.33301 6.6665 2.33301 6.6665 3.66634 8.99984C3.66634 8.99984 7.37222 2.88873 10.6663 1.6665"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  )
}
