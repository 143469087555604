import client from "@/api/client"
import { CreatePromptDto, GetPromptsResult, UpdatePromptDto } from "@/api/sdk"
import { createMutation } from "react-query-kit"
import { actionMiddleware } from "@/queries/middlewares/actionMiddleware"
import { useWorkspacePromptsInfiniteQuery } from "./useWorkspacePromptsInfiniteQuery"
import { InfiniteData } from "@tanstack/react-query"

export const useAddPromptMutation = createMutation({
  mutationFn: async (data: CreatePromptDto) => {
    return client.api.promptLibraryControllerCreatePrompt(data).then(res => res.data)
  },
  use: [
    actionMiddleware({
      onSuccess: ({ queryClient }) => {
        const promptKeyList = useWorkspacePromptsInfiniteQuery.getKey()

        queryClient.invalidateQueries({
          queryKey: promptKeyList,
        })
      },
    }),
  ],
})

export const useUpdatePromptMutation = createMutation({
  mutationFn: async ({ data, promptId }: { data: UpdatePromptDto; promptId: string }) => {
    return client.api.promptLibraryControllerUpdatePrompt(promptId, data).then(res => res.data)
  },
  use: [
    actionMiddleware({
      onSuccess: ({ queryClient, variables: { promptId, data: editedData } }) => {
        const promptKeyList = useWorkspacePromptsInfiniteQuery.getKey()
        const promptListQueriesDataEntries = queryClient.getQueriesData<InfiniteData<GetPromptsResult, number>>({
          queryKey: promptKeyList,
        })

        promptListQueriesDataEntries?.forEach(([key, data]) => {
          if (!data) return

          const updatedData = {
            ...data,
            pages: data.pages.map(page => {
              const updatedPage = {
                ...page,
                prompts: page.prompts.map(item => {
                  if (promptId === item.id) {
                    return {
                      ...item,
                      ...editedData,
                    }
                  }
                  return item
                }),
              }
              updatedPage.total = updatedPage.prompts.length
              return updatedPage
            }),
          }

          queryClient.setQueryData(key, updatedData)
        })
      },
    }),
  ],
})
