import { RecipeItem } from "@/api/sdk"
import { useCallback, useReducer } from "react"

type MultiWorkflowsSelectState = {
  selectionMode: boolean
  selectedItems: RecipeItem[]
}

type TurnOnSelectionModeAction = {
  type: "toggle"
  selectionMode?: boolean
}

type AddSelectionAction = {
  type: "select"
  item: RecipeItem
}

type SelectAllAction = {
  type: "selectAll"
  items: RecipeItem[]
}

type RemoveSelectionAction = {
  type: "remove"
  id: string
}
type ClearSelectionAction = {
  type: "clear"
}

type MultiItemsSelectAction =
  | TurnOnSelectionModeAction
  | AddSelectionAction
  | SelectAllAction
  | RemoveSelectionAction
  | ClearSelectionAction

const reducer = (state: MultiWorkflowsSelectState, action: MultiItemsSelectAction) => {
  switch (action.type) {
    case "toggle":
      return {
        selectionMode: action.selectionMode !== undefined ? action.selectionMode : !state.selectionMode,
        selectedItems: [],
      }

    case "select":
      const selectedItems = state.selectedItems.some(item => item.id === action.item.id)
        ? state.selectedItems.filter(item => item.id !== action.item.id)
        : [...state.selectedItems, action.item]

      return {
        ...state,
        selectedItems,
      }

    case "selectAll":
      return {
        ...state,
        selectedItems: action.items,
      }
    case "clear": {
      return {
        ...state,
        selectedItems: [],
      }
    }

    default:
      return state
  }
}

export const useMultiRecipesSelect = () => {
  const [state, dispatch] = useReducer(reducer, { selectionMode: false, selectedItems: [] })

  const toggleSelectionMode = useCallback(
    (selectionMode?: boolean) => dispatch({ type: "toggle", selectionMode }),
    [dispatch],
  )

  const addSelection = useCallback((item: RecipeItem) => dispatch({ type: "select", item }), [dispatch])

  const selectAll = useCallback((items: RecipeItem[]) => dispatch({ type: "selectAll", items }), [dispatch])

  const clear = useCallback(() => dispatch({ type: "clear" }), [dispatch])

  return {
    selectionMode: state.selectionMode,
    selectedItems: state.selectedItems,
    toggleSelectionMode,
    addSelection,
    selectAll,
    clear,
  }
}
