import { cn } from "@/utils/cn"
import ModalPortalExplore from "../ModalsPortal/ModalPortalExplore"
import CommonNavbar from "../common/CommonNavbar"
import MobileNavigation from "../common/MobileNavigation"
import { withLazyProviders } from "./with-lazy-providers"
import GalleryHeader from "../Explore/Gallery/GalleryHeader"
import { montserrat, mysteryQuest } from "@/pages/_app"

export const menuCommonPages = [
  {
    name: "Explore",
    path: "/explore",
  },
  {
    name: "Workspace",
    path: "/workspace",
  },
  {
    name: "My Creations",
    path: "/my-creations",
  },
  {
    name: "Earn $WOW",
    path: "/quests",
  },
  {
    name: "Events",
    path: "/events",
  },
]

const CommonLayout = ({
  children,
  hiddenMenu,
  hiddenSidebar,
  isExplore,
}: {
  children: React.ReactNode
  hiddenSidebar?: boolean
  hiddenMenu?: boolean
  isExplore?: boolean
}) => {
  return (
    <main id="layout" className={`relative block min-h-screen w-full dark:bg-black dark:text-atherGray-0`}>
      <div id="layout-inside" className="flex-col min-h-screen flex relative w-full">
        <CommonNavbar
          navItems={menuCommonPages}
          containerClassName={cn("", {
            "hidden md:hidden": hiddenMenu,
          })}
        />
        {isExplore && <GalleryHeader />}
        <div className={"flex-1 flex pb-[5.15rem] md:pb-0"}>
          {children}
          <MobileNavigation />
        </div>
      </div>
      <ModalPortalExplore />
    </main>
  )
}

export default withLazyProviders(CommonLayout)
