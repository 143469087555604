import { RecipeFilterMode, RecipeSortOptions, RecipeType } from "@/api/sdk"
import CheckboxComponent from "@/components/Checkbox"
import UserCreated from "@/components/Explore/Gallery/UserCreated"
import IconButton from "@/components/IconButton"
import ImageWebpComponent from "@/components/ImageWebpComponent"
import Popover from "@/components/Popover"
import { CheckCircleIcon, FilterIcon, SortIcon } from "@/components/shared/icons"
import { useDebounce } from "@/hooks"
import { useClearUrlQuery, useReplaceUrlQuery } from "@/hooks/useQuery"
import { googleAnalytics } from "@/lib/gtag"
import { useAuth } from "@/providers/authContext"
import { useGetRecipesInfiniteQuery } from "@/queries/workspace/recipe"
import { useWorkspaceSearchHistory } from "@/stores"
import { cn } from "@/utils/cn"
import classNames from "classnames"
import Link from "next/link"
import useCustomRouter from "@/hooks/useCustomRouter"
import { useEffect, useState } from "react"
import { tabDataRecipesWithIcon } from "."
import SearchBar from "../FoldersV2/SearchBar"
import { filterDiscoverAtOptions } from "../Workflows/WorkflowTabActions"
import TrashDeleteAllButton from "../FoldersV2/TrashDeleteAllButton"

interface RecipesTabActionsProps {
  sort: [RecipeSortOptions]
  setSort: (sort: [RecipeSortOptions]) => void
  className?: string
  onConfirmSearch?: (search: string) => void
  categoryId?: number
  onRemoveHistory?: (item: string) => void
  category: string
  tabQuery: string
  itemLength?: number
  filter: {
    shared?: string[]
  } | null
  setFilter: React.Dispatch<React.SetStateAction<{ shared?: string[] } | null>>
  setCategory: (category: string) => void
  mode: RecipeFilterMode | undefined
  toggleSelectionMode: (value: boolean) => void
  selectionMode: boolean
}

export const recipeSortOptions: { sortBy: RecipeSortOptions; label: string }[] = [
  {
    sortBy: RecipeSortOptions.NEWEST,
    label: "Newest",
  },
  {
    sortBy: RecipeSortOptions.OLDEST,
    label: "Oldest",
  },
  {
    sortBy: RecipeSortOptions.A_TO_Z,
    label: "Name A - Z",
  },
  {
    sortBy: RecipeSortOptions.Z_TO_A,
    label: "Name Z - A",
  },
]

const RecipesTabActions = ({
  sort: [sortBy],
  setSort,
  mode,
  category,
  itemLength,
  setCategory,
  className,
  tabQuery,
  toggleSelectionMode,
  filter,
  setFilter,
  selectionMode,
  categoryId,
  onRemoveHistory,
  onConfirmSearch,
}: RecipesTabActionsProps) => {
  const { user } = useAuth()

  const router = useCustomRouter()
  const { query } = router

  const history = useWorkspaceSearchHistory(s =>
    s.history.filter(item => item.uid === user?.uid && item.location === "workspace/recipes"),
  )

  const [search, setSearch] = useState("")
  const debouncedSearch = useDebounce(search, 350)

  const [sortDropdown, setSortDropdown] = useState(false)
  const [filterDropdown, setFilterDropdown] = useState(false)
  const replaceUrl = useReplaceUrlQuery()
  const clearUrl = useClearUrlQuery()

  useEffect(() => {
    if (tabQuery) {
      toggleSelectionMode(false)
      setCategory(tabQuery === "protogaia-recipes" ? "All" : "Basics")
      setFilter(null)
      setSort([RecipeSortOptions.NEWEST])
      setSearch("")
    }
  }, [tabQuery])

  const {
    data,
    flattenData: previewResult,
    isLoading,
    total,
  } = useGetRecipesInfiniteQuery({
    variables: {
      searchTerm: debouncedSearch,
      types: [RecipeType.Normal, RecipeType.Describe],
      categoryId: mode === RecipeFilterMode.Public || !mode ? categoryId : undefined,
      take: 5,
      sortBy,
      mode,
    },
    enabled: !!debouncedSearch && !!user?.uid,
  })

  const buildPreviewResult = (search: string) => {
    if (isLoading) {
      return (
        <div className="p-1">
          <p className="text-atherGray-300 text-sm">Searching...</p>
        </div>
      )
    }

    if (!isLoading && previewResult?.length === 0) {
      return (
        <div className="p-1">
          <p className="text-atherGray-300 text-sm">No results found</p>
        </div>
      )
    }
    if (previewResult && previewResult.length > 0) {
      const resultLeft = total - previewResult.length

      return (
        <div
          className="flex flex-col space-y-2  overflow-y-auto overflow-x-hidden max-h-[30vh]"
          onMouseEnter={e => {
            e.stopPropagation()
            e.preventDefault()
          }}
        >
          {previewResult?.map(recipe => (
            <Link
              key={recipe.id}
              className="flex w-full rounded-2xl items-center hover:bg-atherGray-850 p-1 px-2 cursor-pointer"
              href={`/workspace/tools/recipes/${recipe.id}`}
            >
              <ImageWebpComponent
                className="w-12 h-12 rounded-lg object-cover"
                src={recipe?.thumbnail}
                alt={recipe.name}
              />
              <div className="ml-2">
                <p className="text-sm line-clamp-1 font-semibold mb-1">{recipe.name}</p>
                <div className="mt-auto">
                  <UserCreated
                    username={recipe.creator?.username ?? "GAIA"}
                    size="xs"
                    name={recipe.creator?.name ?? "GAIA"}
                    picture={recipe.creator?.picture}
                  />
                </div>
              </div>
            </Link>
          ))}
          {resultLeft > 0 && (
            <button
              className="text-xs font-semibold text-atherGray-300 hover:text-atherGray-0 hover:underline text-left"
              onClick={() => onConfirmSearch?.(search)}
            >
              ...and {resultLeft} other {resultLeft > 1 ? "results" : "result"}
            </button>
          )}
        </div>
      )
    }

    return null
  }

  const handleSetSort = (newSortBy: RecipeSortOptions) => {
    if (newSortBy === sortBy) return

    googleAnalytics.event({
      action: "click",
      category: "sort",
      label: `sort_recipes`,
      params: {
        sort: newSortBy,
      },
    })

    setSort([newSortBy])
    setSortDropdown(false)
  }

  const handleSetFilter = (value: string) => {
    if (category === value) {
      clearUrl("category")

      setCategory("All")
      return
    }

    googleAnalytics.event({
      action: "click",
      category: "sort",
      label: `sort_recipes`,
      params: {
        category: category,
      },
    })

    setCategory(value)

    replaceUrl({
      category: value,
    })

    setFilterDropdown(false)
  }

  return (
    <div className={cn("flex flex-1 justify-end md:space-x-2", className)}>
      <SearchBar
        search={search}
        setSearch={setSearch}
        inputClassName="md:w-80"
        onConfirmSearch={onConfirmSearch}
        history={history}
        previewResultBuilder={buildPreviewResult}
        onClear={() => {
          onConfirmSearch?.("")
        }}
        onClearSearchHistory={item => onRemoveHistory?.(item.text)}
      />
      <IconButton
        colorScheme="transparent"
        className={cn(
          "rounded-2xl p-2 px-3 hover:bg-atherGray-850 flex items-center text-atherGray-300 transition-all",
          {
            "bg-atherGray-850 text-atherGray-0": selectionMode,
          },
        )}
        onClick={() => {
          googleAnalytics.event({
            action: "click",
            category: "filter",
            label: `workflow_multi_select`,
          })
          toggleSelectionMode(!selectionMode)
        }}
      >
        <CheckCircleIcon width={20} height={20} checked={selectionMode} />
        <span className="font-normal ml-1 text-sm hidden md:block">Multi-select</span>
      </IconButton>
      {mode === RecipeFilterMode.Trash && <TrashDeleteAllButton type="recipe" disabled={itemLength === 0} />}
      <Popover
        isOpen={filterDropdown}
        setIsOpen={setFilterDropdown}
        trigger={
          <IconButton
            colorScheme="transparent"
            className={cn("rounded-2xl px-2 hover:bg-atherGray-85", {
              "bg-atherGray-850": filterDropdown,
              "border border-atherPurple-500":
                (query.tab === "protogaia-recipes" && category !== "All") || !!filter?.shared?.length,
              hidden: mode === RecipeFilterMode.Trash,
            })}
          >
            <FilterIcon />
          </IconButton>
        }
        align="end"
      >
        <div className="p-3 rounded-2xl bg-atherGray-900 border border-atherGray-800 flex min-w-40 flex-col space-y-2">
          {mode === RecipeFilterMode.Public ? (
            tabDataRecipesWithIcon
              .filter(i => i.id !== "my-recipes")
              .map(option => (
                <IconButton
                  onClick={() => handleSetFilter(option.id)}
                  key={option.id}
                  className={classNames(
                    "flex items-center text-left hover:bg-atherGray-850 py-2 justify-start px-4 active:bg-atherGray-800 bg-transparent w-full cursor-pointer rounded-xl",
                    {
                      "bg-atherGray-850 text-atherGray-0": category === option.id,
                      "text-atherGray-300": category !== option.id,
                    },
                  )}
                >
                  {option.title}
                </IconButton>
              ))
          ) : (
            <div className="flex flex-col">
              <div className="flex w-full items-center justify-between mb-2">
                <p className="text-xs text-atherGray-100 font-semibold">Filter</p>
              </div>
              <div className={"flex items-center space-x-4 text-sm"}>
                {filterDiscoverAtOptions.map(option => (
                  <CheckboxComponent
                    key={option.value}
                    label={option.display}
                    checked={filter?.shared?.includes(option.value) ?? false}
                    onChange={value => {
                      setFilter(prev => {
                        if (prev?.shared?.includes(option.value)) {
                          return {
                            shared: prev.shared?.filter(i => i !== option.value),
                          }
                        }
                        return {
                          shared: [...(prev?.shared ?? []), option.value],
                        }
                      })
                    }}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      </Popover>
      <Popover
        isOpen={sortDropdown}
        setIsOpen={setSortDropdown}
        trigger={
          <IconButton
            colorScheme="transparent"
            className={cn("rounded-2xl px-2 hover:bg-atherGray-85", {
              "bg-atherGray-850": sortDropdown,
              "border border-atherPurple-500": sortBy !== RecipeSortOptions.NEWEST,
            })}
          >
            <SortIcon />
          </IconButton>
        }
        align="end"
      >
        <div className="p-3 rounded-2xl bg-atherGray-900 border border-atherGray-800 flex w-40 flex-col space-y-2">
          {recipeSortOptions.map(option => (
            <IconButton
              onClick={() => handleSetSort(option.sortBy)}
              key={option.label}
              className={classNames(
                "flex items-center text-left hover:bg-atherGray-850 py-2 justify-start px-4 active:bg-atherGray-800 bg-transparent w-full cursor-pointer rounded-xl",
                {
                  "bg-atherGray-850 text-atherGray-0": sortBy === option.sortBy,
                  "text-atherGray-300": sortBy !== option.sortBy,
                },
              )}
            >
              {option.label}
            </IconButton>
          ))}
        </div>
      </Popover>
    </div>
  )
}

export default RecipesTabActions
