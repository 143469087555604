import { IconProps } from "."

export const PlaneIcon = (props: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M4.59961 9.92298C9.67225 0.468695 15.9886 0.359674 18.5127 1.48695C19.64 4.01105 19.531 10.3274 10.0767 15.4C9.97863 14.8364 9.43337 13.3599 8.03655 11.9631C6.63973 10.5663 5.16325 10.021 4.59961 9.92298Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.3496 14.95C13.1881 15.85 13.3844 17.4638 13.6391 19C13.6391 19 17.4897 16.1535 15.0266 12.25"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.04996 8.72694C4.14996 6.88844 2.53615 6.69215 1 6.43746C1 6.43746 3.8465 2.58682 7.74997 5.04993"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.01808 12.9113C3.50625 13.4231 2.63613 14.9074 3.25033 16.75C5.09293 17.3642 6.57725 16.4941 7.08909 15.9823"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.3008 6.45007C15.3008 5.48357 14.5173 4.70007 13.5508 4.70007C12.5843 4.70007 11.8008 5.48357 11.8008 6.45007C11.8008 7.41657 12.5843 8.20007 13.5508 8.20007C14.5173 8.20007 15.3008 7.41657 15.3008 6.45007Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </svg>
  )
}
