import client from "@/api/client"
import { actionMiddleware } from "@/queries/middlewares/actionMiddleware"
import { createMutation } from "react-query-kit"
import {
  PinnedItemType,
  PinnedItems,
  RecentImageItem,
  RecipeTaskWithFullImages,
  RecipeType,
  SearchImagesResponse,
} from "@/api/sdk"
import { InfiniteData } from "@tanstack/react-query"
import { useWorkspaceImagesInfiniteQuery } from "../useWorkspaceImagesInfiniteQuery"
import { useRecentImagesInfiniteQuery } from "@/components/Workspace/RecentV2/RecentImages"
import { useWorkspacePinInfiniteQuery } from "../useWorkspacePinInfiniteQuery"
import { useWorkspaceCreationImagesInfiniteQuery } from "./useWorkspaceCreationImagesInfiniteQuery"
import { useGetHistoryTaskInfiniteQuery } from "@/queries/task/useGetTaskInfiniteQuery"

export const useDeleteImageMutation = createMutation({
  mutationFn: async ({
    ids,
    deletedAt,
  }: {
    ids: string[]
    folderId?: string
    recipeId?: string
    deletedAt: string | null
    recipeType?: RecipeType
  }) => {
    if (deletedAt) {
      return await client.api.imageControllerFullyDeleteImage({ imageIds: ids }).then(res => res.data)
    }

    return await client.api.imageControllerSoftDeleteImage({ imageIds: ids }).then(res => res.data)
  },
  use: [
    actionMiddleware({
      onSuccess: ({ queryClient, variables: { ids, recipeId, folderId, recipeType } }) => {
        const workspaceImagesKey = useWorkspaceImagesInfiniteQuery.getKey()
        const workspaceImagesQueriesDataEntries = queryClient.getQueriesData<
          InfiniteData<SearchImagesResponse, number>
        >({
          queryKey: workspaceImagesKey,
        })
        if (workspaceImagesQueriesDataEntries) {
          workspaceImagesQueriesDataEntries.forEach(([key, parentSubFoldersData]) => {
            if (parentSubFoldersData) {
              const updatedData = {
                ...parentSubFoldersData,
                pages: parentSubFoldersData.pages.map(page => {
                  return {
                    ...page,
                    total: page.total - ids.length,
                    images: page.images.filter(image => !ids.includes(image.id)),
                  }
                }),
              }
              queryClient.setQueryData(key, updatedData)
            }
          })
        }

        //Update task history
        const taskHistoryKey = useGetHistoryTaskInfiniteQuery.getKey()

        const taskHistoryQueriesDataEntries = queryClient.getQueriesData<
          InfiniteData<RecipeTaskWithFullImages[], number>
        >({
          queryKey: taskHistoryKey,
        })

        if (taskHistoryQueriesDataEntries) {
          taskHistoryQueriesDataEntries.forEach(([key, taskHistoryData]) => {
            if (taskHistoryData) {
              const updatedData = {
                ...taskHistoryData,
                pages: taskHistoryData.pages.map(page => {
                  return page.map(task => {
                    return {
                      ...task,
                      fullDataImages: task.fullDataImages?.filter(image => !ids.includes(image.id)),
                    }
                  })
                }),
              }

              queryClient.setQueryData(key, updatedData)
            }
          })
        }

        // Update images creation
        const imagesCreationKey = useWorkspaceCreationImagesInfiniteQuery.getKey()

        const imagesCreationQueriesDataEntries = queryClient.getQueriesData<InfiniteData<SearchImagesResponse, number>>(
          {
            queryKey: imagesCreationKey,
          },
        )

        if (imagesCreationQueriesDataEntries) {
          imagesCreationQueriesDataEntries.forEach(([key, imagesCreationData]) => {
            if (imagesCreationData) {
              const updatedData = {
                ...imagesCreationData,
                pages: imagesCreationData.pages.map(page => {
                  return {
                    ...page,
                    total: page.total - ids.length,
                    images: page.images.filter(image => !ids.includes(image.id)),
                  }
                }),
              }

              queryClient.setQueryData(key, updatedData)
            }
          })
        }

        // Update images recent list
        const imagesRecentListKey = useRecentImagesInfiniteQuery.getKey()

        const imagesRecentListQueriesDataEntries = queryClient.getQueriesData<InfiniteData<RecentImageItem[], number>>({
          queryKey: imagesRecentListKey,
        })

        if (imagesRecentListQueriesDataEntries) {
          imagesRecentListQueriesDataEntries.forEach(([key, imagesRecentListData]) => {
            if (imagesRecentListData) {
              const updatedData = {
                ...imagesRecentListData,
                pages: imagesRecentListData.pages.map(page => {
                  return [...page.filter(images => !ids.includes(images.image.id))]
                }),
              }

              queryClient.setQueryData(key, updatedData)
            }
          })
        }

        // Update images pinned list
        const imagePinnedListKey = useWorkspacePinInfiniteQuery.getKey({
          type: PinnedItemType.IMAGE,
        })

        const imagePinnedListQueriesDataEntries = queryClient.getQueriesData<InfiniteData<PinnedItems[], number>>({
          queryKey: imagePinnedListKey,
        })

        if (imagePinnedListQueriesDataEntries) {
          imagePinnedListQueriesDataEntries.forEach(([key, imagePinnedListData]) => {
            if (imagePinnedListData) {
              const updatedData = {
                ...imagePinnedListData,
                pages: imagePinnedListData.pages.map(page => {
                  return [...page.filter(item => !ids.includes(item.image?.id ?? ""))]
                }),
              }

              queryClient.setQueryData(key, updatedData)
            }
          })
        }
      },
    }),
  ],
})
