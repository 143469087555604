import { IconProps } from "."

export const WOWOutlineIcon = (props: IconProps) => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_4587_20702)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23 12C23 17.799 18.299 22.5 12.5 22.5C6.70101 22.5 2 17.799 2 12C2 6.20101 6.70101 1.5 12.5 1.5C18.299 1.5 23 6.20101 23 12ZM24.5 12C24.5 18.6274 19.1274 24 12.5 24C5.87258 24 0.5 18.6274 0.5 12C0.5 5.37258 5.87258 0 12.5 0C19.1274 0 24.5 5.37258 24.5 12ZM16.4277 16.8L19.1205 8.60693H17.2978L15.3811 14.4123L13.4996 8.60693H11.7357L9.79543 14.3655L7.93747 8.60693H5.96191L8.66655 16.8H10.7009L12.5706 11.2521L14.3815 16.8H16.4277Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_4587_20702">
          <rect width="24" height="24" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  )
}
