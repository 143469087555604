import client from "@/api/client"
import { actionMiddleware } from "@/queries/middlewares/actionMiddleware"
import { createMutation } from "react-query-kit"
import {
  ExploreRecipeItem,
  GetExploreRecipesResult,
  GetRecipesResult,
  PinnedItemType,
  PinnedItems,
  RecentRecipeItem,
} from "@/api/sdk"
import { InfiniteData } from "@tanstack/react-query"
import { useExploreDetailRecipeQuery, useExploreRecipesInfiniteQuery } from "@/queries/explore"
import { useWorkspacePinInfiniteQuery } from "../useWorkspacePinInfiniteQuery"
import { useGetRecipesInfiniteQuery } from "./getRecipeQueries"
import { useRecentRecipesInfiniteQuery } from "@/components/Workspace/RecentV2/RecentRecipes"

export const useFavoriteRecipeMutation = createMutation({
  mutationFn: async ({ id }: { id: string; userUid?: string }) => {
    return await client.api.recipeControllerToggleFavoriteRecipe(id).then(res => res.data)
  },
  use: [
    actionMiddleware({
      onMutate: ({ queryClient, variables }) => {
        // Update list workspace recipes
        const workspaceRecipesKey = useGetRecipesInfiniteQuery.getKey()

        const workspaceRecipesQueriesDataEntries = queryClient.getQueriesData<InfiniteData<GetRecipesResult, number>>({
          queryKey: workspaceRecipesKey,
        })

        if (workspaceRecipesQueriesDataEntries) {
          workspaceRecipesQueriesDataEntries.forEach(([key, recipesData]) => {
            if (recipesData) {
              const updatedData = {
                ...recipesData,
                pages: recipesData.pages.map(page => {
                  return {
                    ...page,
                    recipes: page.recipes.map(recipe => {
                      if (variables.id === recipe.id) {
                        return {
                          ...recipe,
                          favoritedByUser: !recipe.favoritedByUser,
                          metric: {
                            ...recipe.metric,
                            favoriteCount: recipe.favoritedByUser
                              ? (recipe.metric?.favoriteCount ?? 0) - 1
                              : (recipe.metric?.favoriteCount ?? 0) + 1,
                          },
                        }
                      }
                      return recipe
                    }),
                  }
                }),
              }

              queryClient.setQueryData(key, updatedData)
            }
          })
        }

        const exploreRecipeDetailKey = useExploreDetailRecipeQuery.getKey({
          recipeId: variables.id,
          userUid: variables.userUid,
        })

        const exploreRecipeDetailData = queryClient.getQueryData<ExploreRecipeItem>(exploreRecipeDetailKey)

        if (exploreRecipeDetailData) {
          queryClient.setQueryData(exploreRecipeDetailKey, {
            ...exploreRecipeDetailData,
            favoritedByUser: !exploreRecipeDetailData.favoritedByUser,
            metric: {
              id: exploreRecipeDetailData.metric?.id ?? 0,
              favoriteCount: exploreRecipeDetailData.favoritedByUser
                ? (exploreRecipeDetailData.metric?.favoriteCount ?? 0) - 1
                : (exploreRecipeDetailData.metric?.favoriteCount ?? 0) + 1,
            },
          })
        }

        const exploreRecipesKey = useExploreRecipesInfiniteQuery.getKey()

        const exploreRecipesQueriesDataEntries = queryClient.getQueriesData<
          InfiniteData<GetExploreRecipesResult, number>
        >({
          queryKey: exploreRecipesKey,
        })

        if (exploreRecipesQueriesDataEntries) {
          exploreRecipesQueriesDataEntries.forEach(([key, recipeData]) => {
            if (recipeData) {
              const updatedData = {
                ...recipeData,
                pages: recipeData.pages.map(page => {
                  return {
                    ...page,
                    recipes: page.recipes.map(recipe => {
                      if (variables.id === recipe.id) {
                        return {
                          ...recipe,
                          favoritedByUser: !recipe.favoritedByUser,
                          metric: {
                            ...recipe.metric,
                            favoriteCount: recipe.favoritedByUser
                              ? (recipe.metric?.favoriteCount ?? 0) - 1
                              : (recipe.metric?.favoriteCount ?? 0) + 1,
                          },
                        }
                      }
                      return recipe
                    }),
                  }
                }),
              }
              queryClient.setQueryData(key, updatedData)
            }
          })
        }

        // Update recipes recent list
        const recipesRecentListKey = useRecentRecipesInfiniteQuery.getKey()

        const recipesRecentListQueriesDataEntries = queryClient.getQueriesData<
          InfiniteData<RecentRecipeItem[], number>
        >({
          queryKey: recipesRecentListKey,
        })

        if (recipesRecentListQueriesDataEntries) {
          recipesRecentListQueriesDataEntries.forEach(([key, recipesRecent]) => {
            if (recipesRecent) {
              const updatedData = {
                ...recipesRecent,
                pages: recipesRecent.pages.map(page => {
                  return page.map(recent => {
                    if (variables.id === recent.recipe.id) {
                      return {
                        ...recent,
                        recipe: {
                          ...recent.recipe,
                          favoritedByUser: !recent.recipe.favoritedByUser,
                          metric: {
                            ...recent.recipe.metric,
                            favoriteCount: recent.recipe.favoritedByUser
                              ? (recent.recipe.metric?.favoriteCount ?? 0) - 1
                              : (recent.recipe.metric?.favoriteCount ?? 0) + 1,
                          },
                        },
                      }
                    }
                    return recent
                  })
                }),
              }

              queryClient.setQueryData(key, updatedData)
            }
          })
        }

        //update pinned recipes
        const recipesPinnedListKey = useWorkspacePinInfiniteQuery.getKey({
          type: PinnedItemType.RECIPE,
        })

        const reicpesPinnedListQueriesDataEntries = queryClient.getQueriesData<InfiniteData<PinnedItems[], number>>({
          queryKey: recipesPinnedListKey,
        })

        if (reicpesPinnedListQueriesDataEntries) {
          reicpesPinnedListQueriesDataEntries.forEach(([key, recipePinnedListData]) => {
            if (recipePinnedListData) {
              const updatedData = {
                ...recipePinnedListData,
                pages: recipePinnedListData.pages.map(page => {
                  return [
                    ...page.map(item => {
                      if (item.recipe?.id === variables.id) {
                        return {
                          ...item,
                          recipe: {
                            ...item.recipe,
                            favoritedByUser: !item.recipe.favoritedByUser,
                            metric: {
                              ...item.recipe.metric,
                              favoriteCount: item.recipe.favoritedByUser
                                ? (item.recipe.metric?.favoriteCount ?? 0) - 1
                                : (item.recipe.metric?.favoriteCount ?? 0) + 1,
                            },
                          },
                        }
                      }

                      return item
                    }),
                  ]
                }),
              }

              queryClient.setQueryData(key, updatedData)
            }
          })
        }
      },
      onError: ({ queryClient, variables }) => {
        const exploreRecipesKey = useExploreRecipesInfiniteQuery.getKey()
        const workspaceRecipesKey = useGetRecipesInfiniteQuery.getKey()
        const recipesRecentListKey = useRecentRecipesInfiniteQuery.getKey()

        queryClient.invalidateQueries({ queryKey: exploreRecipesKey })
        queryClient.invalidateQueries({ queryKey: workspaceRecipesKey })
        queryClient.invalidateQueries({ queryKey: recipesRecentListKey })
      },
    }),
  ],
})
