import { IconProps } from "."

export const LeaveIcon = (props: IconProps) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12.5 14.6875C12.4387 16.2308 11.1526 17.5412 9.4297 17.499C9.02887 17.4892 8.53344 17.3495 7.5426 17.07C5.15801 16.3974 3.08796 15.267 2.5913 12.7346C2.5 12.2691 2.5 11.7453 2.5 10.6977L2.5 9.30229C2.5 8.25468 2.5 7.73087 2.5913 7.26538C3.08796 4.73304 5.15801 3.60263 7.5426 2.93002C8.53345 2.65054 9.02887 2.5108 9.4297 2.50099C11.1526 2.45884 12.4387 3.76923 12.5 5.31251"
        stroke={props.color ? props.color : "#C8C4B5"}
        strokeWidth={props.stroke ? props.stroke : "1.5"}
        strokeLinecap="round"
      />
      <path
        d="M17.4987 10.0003H8.33203M17.4987 10.0003C17.4987 9.4168 15.8368 8.3266 15.4154 7.91699M17.4987 10.0003C17.4987 10.5838 15.8368 11.6741 15.4154 12.0837"
        stroke={props.color ? props.color : "#C8C4B5"}
        strokeWidth={props.stroke ? props.stroke : "1.5"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
