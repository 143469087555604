import Tooltip from "@/components/Tooltip"
import { InfoIcon } from "@/components/shared/icons"
import { cn } from "@/lib/utils"
import React, { FC } from "react"

type FormDetailTooltipProps = {
  name: string
  tooltip?: string
  className?: string
}

const FormDetailTooltip: FC<FormDetailTooltipProps> = props => {
  const { name, tooltip, className } = props

  return (
    <div className={cn("flex items-center mb-2", className)}>
      <p className="font-semibold text-atherGray-300 text-sm">{name}</p>
      {tooltip && (
        <div className="ml-1">
          <Tooltip
            trigger={
              <div>
                <InfoIcon className="text-atherGray-600" width={14} height={14} />
              </div>
            }
          >
            {tooltip}
          </Tooltip>
        </div>
      )}
    </div>
  )
}

export default FormDetailTooltip
