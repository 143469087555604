import client from "@/api/client"
import { actionMiddleware } from "@/queries/middlewares/actionMiddleware"
import { createMutation } from "react-query-kit"
import { useWorkspaceRoomsInfiniteQuery } from "./useWorkspaceRoomsInfiniteQuery"
import { GetChatRoomsResult } from "@/api/sdk"
import { InfiniteData } from "@tanstack/react-query"

export const useReadChatRoomMutation = createMutation({
  mutationFn: async ({ roomId }: { roomId: string }) => {
    return await client.api.chatRoomControllerResetChatRoomReadState(roomId).then(res => res.data)
  },
  use: [
    actionMiddleware({
      onSuccess: ({ queryClient, variables }) => {
        const roomsKey = useWorkspaceRoomsInfiniteQuery.getKey()

        const roomsEntities = queryClient.getQueriesData<InfiniteData<GetChatRoomsResult, number>>({
          queryKey: roomsKey,
        })

        roomsEntities?.forEach(([key, roomsEntitiesListData]) => {
          const updatedPinnedMessageEntitiesListData = {
            ...roomsEntitiesListData,
            pages: roomsEntitiesListData?.pages.map(page => ({
              ...page,
              rooms: page.rooms.map(room => {
                if (room.id === variables.roomId) {
                  return {
                    ...room,
                    unreadCount: 0,
                  }
                }

                return room
              }),
            })),
          }

          queryClient.setQueryData(key, updatedPinnedMessageEntitiesListData)
        })
      },
    }),
  ],
})
