import { IconProps } from "."

export const BookmarkIcon = (props: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 16" fill="none" {...props}>
      <path
        d="M1.66797 11.9874V6.47185C1.66797 4.04961 1.66797 2.83848 2.44902 2.08599C3.23007 1.3335 4.48714 1.3335 7.0013 1.3335C9.51546 1.3335 10.7725 1.3335 11.5536 2.08599C12.3346 2.83848 12.3346 4.04961 12.3346 6.47185V11.9874C12.3346 13.5246 12.3346 14.2932 11.8194 14.5683C10.8217 15.1011 8.9501 13.3236 8.0613 12.7884C7.54583 12.478 7.2881 12.3229 7.0013 12.3229C6.71451 12.3229 6.45677 12.478 5.9413 12.7884C5.0525 13.3236 3.18095 15.1011 2.1832 14.5683C1.66797 14.2932 1.66797 13.5246 1.66797 11.9874Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
