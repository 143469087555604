import client from "@/api/client"
import { createAuthenticatedQuery } from "../createAuthenticatedQuery"
import { WorkspacePlanItem } from "@/api/sdk"
import { enableQueryMiddleware } from "../middlewares/enableQueryMiddleware"

export const useGetUserPlanQuery = createAuthenticatedQuery<WorkspacePlanItem, { workspaceId: string }, Error>({
  primaryKey: "user-plan",
  queryFn: ({ queryKey: [_primaryKey, variables] }) =>
    client.api.workspaceControllerGetWorkspacePlan(variables.workspaceId).then(res => res.data),
  use: [enableQueryMiddleware(i => !!i.workspaceId)],
})
