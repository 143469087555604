import { EntityType, GetCommentsResult } from "@/api/sdk"
import UserBadge from "@/components/UserBadge"
import { useToast } from "@/hooks"
import { googleAnalytics } from "@/lib/gtag"
import { useAuth } from "@/providers/authContext"
import { useAddCommentMutation } from "@/queries"
import { useMutateTipWowPoints } from "@/queries/useTipWowPoints"
import { InfiniteData, QueryKey, useQueryClient } from "@tanstack/react-query"
import { $getRoot, EditorState, LexicalEditor } from "lexical"
import { memo, useRef, useState } from "react"
import LexicalCustomEditor, { EMPTY_CONTENT_LEXICAL } from "../../../../LexicalEditor/LexicalCustomEditor"
import { MentionDataV2 } from "../../../../LexicalEditor/MentionPlugin"
import { $isBeautifulMentionNode } from "../../../../LexicalEditor/plugin/nodes/MentionNode"
import useComment from "./useComment"

interface AddCommentProps {
  itemId: string
  mentions: MentionDataV2[]
  queryCommentsImageKey: QueryKey
  onAddCommentSucessFully?: () => void
  setIsFocus: (isFocus: boolean) => void
  onSearchMention: (search: string) => void
  itemOwnerUid?: string
  disabled?: boolean
  isMentionsLoading: boolean
  typeComment: EntityType
  resetParams?: ReturnType<typeof useComment>["functions"]["resetParams"]
  isCommentFromNoti: boolean
  nodeId?: string | null
  placeholder?: string
}

export const wowTippingRegex = /(\d+)\s([W,w][O,o][W,w])/g

const AddComment = ({
  itemId,
  mentions,
  queryCommentsImageKey,
  onAddCommentSucessFully,
  setIsFocus,
  disabled,
  isMentionsLoading,
  placeholder,
  nodeId,
  itemOwnerUid,
  typeComment,
  onSearchMention,
  resetParams,
  isCommentFromNoti,
}: AddCommentProps) => {
  const { userInfoQuery } = useAuth()
  const [editorState, setEditorState] = useState<EditorState | undefined>()
  const editorRef = useRef<LexicalEditor>(null)
  const qc = useQueryClient()
  const toast = useToast()

  const { mutate: mutateTip } = useMutateTipWowPoints({
    onSuccess: data => {
      googleAnalytics.handleCategoryEvent({
        action: "click",
        params: {
          action: "Send Tip",
          profile_uid: data.recipient.uid,
          profile_name: data.recipient.name,
          wow_value: data.amount,
        },
      })

      toast({
        title: "Tip successfully",
        status: "success",
        message: [`Your have successfully tipped ${data.amount} $WOW to ${data.recipient.name}`],
      })
    },
    onError: (err: any) => {
      toast({
        title: "Tip failed",
        status: "error",
        message: [err?.message ?? "An error occurred"],
      })
    },
  })

  const { mutate: mutateComment } = useAddCommentMutation({
    onMutate: async ({ data: { id, formatValue, uidMentions, value, nodeId } }) => {
      await qc.cancelQueries({ queryKey: queryCommentsImageKey })

      const previousComments = qc.getQueryData(queryCommentsImageKey) as InfiniteData<GetCommentsResult> | undefined
      if (previousComments) {
        qc.setQueryData(queryCommentsImageKey, {
          ...previousComments,
          pages: [
            {
              comments: [
                {
                  id,
                  value,
                  nodeId,
                  formatValue,
                  mentions: mentions.filter(i => uidMentions?.includes(i.uid)),
                  commenter: {
                    name: userInfoQuery?.data?.name ?? "",
                    picture: userInfoQuery?.data?.picture ?? "",
                    ...userInfoQuery?.data,
                  },
                  createdAt: new Date().toISOString(),
                  updatedAt: new Date().toISOString(),
                  likeCount: 0,
                  childCommentsCount: 0,
                  isRead: false,
                  isLiked: false,
                  parentCommentId: null,
                  likedBy: [],
                },
              ],
              total: previousComments.pages[0].total + 1,
            },
            ...previousComments.pages,
          ],
        })
      }

      // clear lexical editor
      const initialEmpty = editorRef.current?.parseEditorState(EMPTY_CONTENT_LEXICAL)
      if (!initialEmpty) return

      editorRef.current?.setEditorState(initialEmpty)
      setEditorState(initialEmpty)

      setTimeout(() => {
        editorRef.current?.focus()
      }, 150)

      return { previousComments }
    },
    onSuccess: (data, { data: { id, formatValue, uidMentions, value } }) => {
      onAddCommentSucessFully?.()
      const previousComments = qc.getQueryData(queryCommentsImageKey) as InfiniteData<GetCommentsResult> | undefined
      if (previousComments) {
        qc.setQueryData(queryCommentsImageKey, {
          ...previousComments,
          pages: previousComments.pages.map(page => ({
            ...page,
            comments: page.comments.map(comment => {
              if (comment.id === id) {
                return {
                  ...comment,
                  id: data.id,
                }
              }
              return comment
            }),
          })),
        })
      }

      if (isCommentFromNoti) {
        resetParams?.()
        qc.resetQueries({ queryKey: queryCommentsImageKey })
      } else {
        qc.invalidateQueries({ queryKey: queryCommentsImageKey })
      }

      const tipMatches = value.match(wowTippingRegex)

      if (itemOwnerUid && tipMatches && userInfoQuery?.data?.uid !== itemOwnerUid) {
        const amount = parseInt(tipMatches[0])

        if (!Number.isInteger(amount)) return

        if (!amount || amount < 1) {
          toast({
            title: "Tip failed",
            status: "error",
            message: ["Minimum tipping is 1 $WOW"],
          })
          return
        } else {
          mutateTip({
            data: {
              amount,
              recipientUid: itemOwnerUid,
              metadata: {
                entityId: itemId,
                entityType: typeComment,
              },
            },
          })
        }
      }
    },
    onError: (_, __, context) => {
      if (context?.previousComments) {
        qc.setQueryData(queryCommentsImageKey, context.previousComments)
      }
    },
  })

  const onAddComment = (data?: EditorState) => {
    const JSONEditorState = JSON.stringify(data?.toJSON())

    const getMentionsAdded = data?.read(() =>
      [...data?._nodeMap.values()].reduce((obj, value) => {
        if (!$isBeautifulMentionNode(value)) return obj
        const valueMention = value.__data as MentionDataV2

        if (obj.map(item => item.uid).includes(valueMention.uid)) return obj
        obj.push(valueMention)
        return obj
      }, [] as MentionDataV2[]),
    )

    const getText = data?.read(() => $getRoot()?.getTextContent())

    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        action: "Add Comment",
        [`${typeComment.toLowerCase()}_id`]: itemId,
        comment_detail: getText ?? "",
      },
    })

    mutateComment({
      data: {
        id: new Date().getTime().toString(),
        formatValue: JSONEditorState ?? "",
        parentCommentId: null,
        uidMentions: getMentionsAdded?.map(i => i.uid) ?? [],
        value: getText ?? "",
        nodeId: nodeId ? parseInt(nodeId) : undefined,
      },
      itemId,
      typeComment,
    })
  }

  return (
    <div className="flex items-start w-full py-2">
      {userInfoQuery?.data ? (
        <UserBadge
          isDisabledInfo
          className="mr-2"
          picture={userInfoQuery.data?.picture}
          name={userInfoQuery.data.name + " (me)"}
          email={userInfoQuery.data.email}
          size="sm"
        />
      ) : null}
      <div className="flex-1">
        <LexicalCustomEditor
          editorKey="create-comment"
          readOnly={disabled}
          placeholder={placeholder}
          mentions={mentions}
          setIsFocus={setIsFocus}
          isMentionsLoading={isMentionsLoading}
          onSearchMention={onSearchMention}
          editorState={editorState}
          editorRef={editorRef}
          setEditorState={setEditorState}
          onAddComment={onAddComment}
        >
          <></>
        </LexicalCustomEditor>
      </div>
    </div>
  )
}

export default memo(AddComment)
