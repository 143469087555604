import { useAuth } from "@/providers/authContext"
import { QueryKey, useInfiniteQuery } from "@tanstack/react-query"

export const useAuthenticatedInfinityQuery = (queryKey: QueryKey, queryFn: any, options: any) => {
  const { tokenFormat } = useAuth()

  const enabled = options?.enabled ?? true

  const query = useInfiniteQuery<any, any, any, any, any>({
    queryKey,
    queryFn,
    ...options,
    enabled: !!tokenFormat && enabled,
  })

  return query
}
