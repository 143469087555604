import { useAuth } from "@/providers/authContext"
import { css } from "@emotion/css"
import React, { useMemo, useState } from "react"
import AddComment from "../../ImageDetail/TabImage/CommenstImage/AddComment"
import { GetMentionableUsersResult, EntityType, WorkflowCreator } from "@/api/sdk"
import client from "@/api/client"
import { enableQueryMiddleware } from "@/queries/middlewares/enableQueryMiddleware"
import useComment from "../../ImageDetail/TabImage/CommenstImage/useComment"
import SignInRequiredButton from "../../SignInRequiredButton"
import ViewComment from "../../ImageDetail/TabImage/CommenstImage/ViewComment"
import { createAuthenticatedQuery } from "@/queries/createAuthenticatedQuery"
import { cn } from "@/lib/utils"

interface CommentsWorkflowProps {
  workflow: {
    id: string
    creator: WorkflowCreator
  }
  type: EntityType
  className?: string
}

export const useExploreWorkflowMentions = createAuthenticatedQuery<
  GetMentionableUsersResult,
  { workflowId: string; searchQuery: string },
  Error
>({
  primaryKey: "users-mentions-workflow-explore",
  queryFn: ({ queryKey: [_primaryKey, variables] }) =>
    client.api
      .exploreWorkflowControllerGetExploreWorkflowMentionableUsers(variables.workflowId, {
        search: variables.searchQuery,
        skip: 0,
        take: 12,
      })
      .then(res => res.data),
  refetchOnWindowFocus: false,
  use: [enableQueryMiddleware(v => !!v.workflowId)],
})

export const useWorkspaceWorkflowMentions = createAuthenticatedQuery<
  GetMentionableUsersResult,
  { workflowId: string; searchQuery: string },
  Error
>({
  primaryKey: "users-mentions-workflow-workspace",
  queryFn: ({ queryKey: [_primaryKey, variables] }) =>
    client.api
      .workflowControllerGetWorkflowMentionableUsers(variables.workflowId, {
        search: variables.searchQuery,
        skip: 0,
        take: 12,
      })
      .then(res => res.data),
  refetchOnWindowFocus: false,
  use: [enableQueryMiddleware(v => !!v.workflowId)],
})

const CommentsWorkflow = ({ workflow, type, className }: CommentsWorkflowProps) => {
  const { user } = useAuth()
  const [searchMention, setSearchMention] = useState("")

  const { data: workflowMentions, isFetching: isExploreMentionsLoading } = useExploreWorkflowMentions({
    variables: {
      workflowId: workflow.id,
      searchQuery: searchMention,
    },
    enabled: type === EntityType.EXPLORE_WORKFLOW && !!user,
  })

  const { data: workflowMentionsWorkspace, isFetching: isMentionsLoading } = useWorkspaceWorkflowMentions({
    variables: {
      workflowId: workflow.id,
      searchQuery: searchMention,
    },
    enabled: type === EntityType.WORKFLOW && !!user,
  })

  const mentions = useMemo(() => {
    return (
      (type === EntityType.EXPLORE_WORKFLOW ? workflowMentions?.users : workflowMentionsWorkspace?.users)
        ?.filter(u => user?.uid !== u.uid)
        .map(us => ({
          ...us,
          picture: us.picture ?? "",
          name: us.name ?? "",
          uid: us.uid,
          label: `${us.name}`,
          value: `${us.name}`,
          itemValue: `${us.name}`,
          email: us.email ?? "",
        })) ?? []
    )
  }, [workflowMentions, workflowMentionsWorkspace, type, user?.uid])

  const {
    states: {
      hasNextPage,
      hasPreviousPage,
      isLoading,
      isFetchingNextPage,
      isFetchingPreviousPage,
      isCommentsSuccess,
      isCommentFromNoti,
    },
    functions: { fetchNextPage, fetchPreviousPage, refetchComments, resetParams },
    contants: { childTakePage, baseChildParam, queryCommentsImageKey, childTotalPage },
    data: { mappedComments },
    paginations: { nextBaseChildParam, prevBaseChildParam, nextBaseParentParam, prevBaseParentParam },
  } = useComment(workflow.id, type)

  if (!user && type === EntityType.WORKFLOW) return null

  return (
    <div className={cn("w-full flex flex-col flex-1 min-h-[20rem] items-center p-4", className)}>
      <div
        className={css({
          flex: 1,
          display: "flex",
          flexDirection: "column",
          position: "relative",
          padding: "0 0.25rem",
          width: "100%",
          maxWidth: "56rem",
        })}
      >
        {user ? (
          <AddComment
            isMentionsLoading={isMentionsLoading || isExploreMentionsLoading}
            itemOwnerUid={workflow.creator?.uid}
            setIsFocus={() => {}}
            queryCommentsImageKey={queryCommentsImageKey}
            mentions={mentions}
            onSearchMention={setSearchMention}
            itemId={workflow.id}
            resetParams={resetParams}
            typeComment={type}
            isCommentFromNoti={isCommentFromNoti}
          />
        ) : (
          <div className="w-full mb-2">
            <SignInRequiredButton
              earlyAccessModal
              className="bg-atherGray-800"
              text={
                <p className="text-xs font-normal text-atherGray-300">
                  You must <span className="font-semibold text-atherGray-0">Sign In</span> to add a comment
                </p>
              }
            />
          </div>
        )}
        <ViewComment
          isMentionsLoading={isMentionsLoading || isExploreMentionsLoading}
          itemOwnerUid={workflow.creator?.uid}
          setSearchMention={setSearchMention}
          setIsFocus={() => {}}
          queryCommentsImageKey={queryCommentsImageKey}
          isCommentsSuccess={isCommentsSuccess}
          refetchComments={refetchComments}
          mappedComments={mappedComments}
          hasNextPage={hasNextPage}
          hasPreviousPage={hasPreviousPage}
          fetchNextPage={fetchNextPage}
          fetchPreviousPage={fetchPreviousPage}
          isFetchingNextPage={isFetchingNextPage}
          isFetchingPreviousPage={isFetchingPreviousPage}
          typeComment={type}
          mentions={mentions}
          image={{
            id: workflow.id,
            canComment: !!user,
          }}
          prevBaseParentParam={prevBaseParentParam}
          nextBaseParentParam={nextBaseParentParam}
          baseChildParam={baseChildParam}
          nextBaseChildParam={nextBaseChildParam}
          prevBaseChildParam={prevBaseChildParam}
          childTakePage={childTakePage}
          childTotalPage={childTotalPage}
        />
      </div>
    </div>
  )
}

export default CommentsWorkflow
