import ImageWebpComponent from "@/components/ImageWebpComponent"
import Input from "@/components/Input"
import { ChevronDownIcon, XIcon } from "@/components/shared/icons"
import { googleAnalytics } from "@/lib/gtag"
import { cn } from "@/utils/cn"
import classNames from "classnames"
import { motion } from "framer-motion"
import groupBy from "lodash/groupBy"
import React from "react"

interface RecipeSelectProps {
  isOpen: boolean
  onClose: () => void
  search: string
  setSearch: (search: string) => void
  mappedData?: {
    id: string
    name: string
    thumbnailUrl: string
    category?: string
  }[]
  values?: string[]
  onSelectValues?: (values: string[]) => void
  title: string
  descriptionNode?: React.ReactNode
  rtl?: boolean
}

const DrawerSelectFilter = ({
  onClose,
  search,
  onSelectValues,
  isOpen,
  values,
  setSearch,
  mappedData,
  descriptionNode,
  title,
}: RecipeSelectProps) => {
  if (!isOpen) return null

  const mappedDataByCategory = groupBy(mappedData ?? [], item => item.category)

  return (
    <motion.div
      initial={{ opacity: 0, zIndex: -1 }}
      animate={{ opacity: 1, zIndex: 1 }}
      exit={{ opacity: 0, zIndex: -1 }}
      transition={{
        ease: "easeInOut",
        duration: 0.2,
      }}
      className={cn(
        "fixed top-0 height-full-screen right-0 md:right-[20rem] w-80 bg-atherGray-900 flex flex-col overflow-hidden py-4 z-[26]",
      )}
    >
      <div className="flex flex-col mb-4 px-4">
        <div onClick={onClose} className="cursor-pointer flex items-center">
          <h3 className="font-semibold text-xl capitalize">{title}</h3>
          <div className="w-4 ml-auto">
            <ChevronDownIcon className="-rotate-90" />
          </div>
        </div>
        {descriptionNode}
      </div>
      <div className="px-4 pb-4">
        <Input
          className="bg-atherGray-850 text-sm"
          value={search}
          onChange={e => setSearch(e.target.value)}
          placeholder="Type to search ..."
        />
      </div>
      <div className="w-full overflow-auto p-4 pt-0">
        {Object.keys(mappedDataByCategory)
          .sort()
          .map((category, idx) => (
            <div key={category.toString()}>
              {category !== "undefined" && (
                <p
                  className={classNames("font-semibold uppercase text-atherGray-500 text-sm pb-1", { "pt-4": idx > 0 })}
                >
                  {category}
                </p>
              )}
              <div className="grid grid-cols-[repeat(auto-fill,minmax(8rem,1fr))] gap-2">
                {mappedDataByCategory[category]
                  ?.filter(i => i.name.toLowerCase().includes(search.toLowerCase()) || search === "")
                  ?.map(item => (
                    <div
                      className={classNames("cursor-pointer relative rounded-lg")}
                      key={item.id}
                      onClick={() => {
                        googleAnalytics.event({
                          action: "click",
                          category: "category",
                          label: `select_${title}`,
                          params: {
                            id: item.id,
                          },
                        })
                        if (values?.includes(item.id)) {
                          onSelectValues?.(values.filter(value => value !== item.id))
                          return
                        }

                        onSelectValues?.([...(values ?? []), item.id])
                      }}
                    >
                      <div
                        className={classNames("border-[2px] rounded-lg pt-[100%] relative", {
                          "border-atherPurple-500": values?.includes(item.id),
                          "border-atherGray-850": !values?.includes(item.id),
                        })}
                      >
                        <ImageWebpComponent
                          src={item.thumbnailUrl}
                          alt=""
                          className={classNames("object-cover h-full w-full block rounded-lg absolute top-0 left-0")}
                        />
                      </div>
                      <p className={classNames("text-xs font-semibold mt-1 text-atherGray-100")}>{item.name}</p>
                      {values?.includes(item.id) && (
                        <div
                          onClick={() => {
                            onSelectValues?.(values.filter(value => value !== item.id))

                            googleAnalytics.event({
                              action: "click",
                              category: "category_selected",
                              label: `select_${title}`,
                              params: {
                                id: item.id,
                                name: item.name,
                                category: item.category ?? "",
                              },
                            })
                          }}
                          className="absolute top-1 right-1 z-[1]"
                        >
                          <XIcon className="w-3 h-3 text-red-500" />
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            </div>
          ))}
      </div>
    </motion.div>
  )
}

export default DrawerSelectFilter
