import { PromptItem as PromptItemType } from "@/api/sdk"
import IconButton from "@/components/IconButton"
import { TickIcon } from "@/components/shared/icons"
import { googleAnalytics } from "@/lib/gtag"
import { cn } from "@/utils/cn"
import { memo } from "react"
import PromptActionButtons from "./PromptActionButtons"
import Link from "next/link"
import TextCopy from "@/components/TextCopy"
import ImageWebpComponent from "@/components/ImageWebpComponent"
import AspectRatio from "@/components/AspectRatio"
import classNames from "classnames"
import { useInView } from "react-intersection-observer"
import { useTempState } from "@/hooks"
import { AnimatePresence, motion } from "framer-motion"

export interface PromptItemProps {
  prompt: PromptItemType
  onClick?: () => void
  isChecked?: boolean
  onCheck?: () => void
  selectionMode?: boolean
  layout?: "grid" | "list"
  className?: string
  hiddenMenu?: boolean
  accessedAt?: string
  onRef?: (ref: HTMLDivElement | null) => void
  filters?: Record<string, any>
  isCopy?: boolean
}

const PromptItem = ({
  prompt: promptData,
  layout = "grid",
  className,
  isCopy,
  onRef,
  filters,
  isChecked,
  onCheck,
  hiddenMenu,
  selectionMode,
  onClick,
  accessedAt,
}: PromptItemProps) => {
  const { ref, inView } = useInView({
    threshold: 0,
    rootMargin: "800px 0px 800px 0px",
  })

  const [isCopying, setIsCopying] = useTempState(false, 500)

  const handleCopy = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.stopPropagation()

    setIsCopying(true)

    navigator.clipboard.writeText(promptData.prompt)

    googleAnalytics.event({
      action: "click",
      category: "prompt_item",
      label: "prompt_copied",
      params: {
        prompt_item_id: promptData.id,
        prompt_item_name: promptData.name ?? promptData.id,
      },
    })
  }

  return (
    <div
      data-id={promptData.id}
      className={cn("group cursor-pointer relative shrink", className)}
      ref={r => {
        if (onRef) {
          onRef(r)
        }
        ref(r)
      }}
    >
      <Link
        shallow
        scroll={false}
        href={{
          query: {
            promptId: promptData.id,
          },
        }}
        onClick={
          selectionMode || onClick || isCopy
            ? e => {
                e.preventDefault()
                if (selectionMode) {
                  onCheck?.()

                  return
                }

                if (isCopy) {
                  handleCopy(e)
                  return
                }

                onClick?.()
              }
            : undefined
        }
      >
        {layout === "grid" ? (
          <div className="flex-1 flex-col flex h-full">
            <div className="">
              <div className="relative">
                <AspectRatio
                  ratio={197 / 96}
                  className={classNames(
                    "relative select-none overflow-hidden rounded-xl transform-gpu flex items-center justify-center shadow-md shadow-blackAlpha-500",
                    {
                      "opacity-50 group-hover:opacity-100 transition-opacity duration-150": selectionMode,
                    },
                  )}
                >
                  {inView && (
                    <ImageWebpComponent
                      className="object-cover h-full w-full"
                      src={promptData.thumbnailUrl ?? "https://cdn.protogaia.com/hO9MdTi80f_ogT_528G90.w400.webp"}
                    />
                  )}
                </AspectRatio>
                {selectionMode && (
                  <div
                    className={classNames(
                      "absolute w-full h-full top-0 left-0 border-[2px] pointer-events-none rounded-xl",
                      {
                        "border-atherPurple-500": isChecked,
                        "border-[transparent]": !isChecked,
                        "z-0": !selectionMode,
                        "z-[1]": selectionMode,
                      },
                    )}
                  />
                )}
                {inView && (
                  <div
                    className={classNames(
                      "absolute bottom-0 left-0 transition-all pointer-events-none hidden lg:block group-hover:bg-blackAlpha-400 group-hover:h-full group-hover:w-full",
                      {
                        "h-full w-full bg-blackAlpha-600": selectionMode,
                      },
                    )}
                  />
                )}
                <AnimatePresence initial={false} mode="popLayout">
                  {isCopying && (
                    <motion.span
                      className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                    >
                      <TickIcon key="btn-check" className="text-green-400" width={24} height={24} />
                    </motion.span>
                  )}
                </AnimatePresence>
                {!hiddenMenu &&
                  (selectionMode ? (
                    <IconButton
                      onClick={e => {
                        e.stopPropagation()
                        e.preventDefault()
                        googleAnalytics.event({
                          action: "click",
                          category: "prompt_item",
                          label: "prompt_checked",
                          params: {
                            prompt_item_id: promptData.id,
                            prompt_item_name: promptData.name ?? promptData.id,
                          },
                        })
                        onCheck?.()
                      }}
                      className={cn(
                        "shadow-sm absolute top-2 right-2 shadow-blackAlpha-500 bg-atherGray-700 p-0 w-7 h-7 min-h-0 rounded-full",
                        {
                          "text-white bg-atherPurple-500 hover:bg-atherPurple-400": isChecked,
                          "text-atherGray-300 hover:bg-atherGray-600": !isChecked,
                        },
                      )}
                    >
                      <TickIcon />
                    </IconButton>
                  ) : (
                    <PromptActionButtons layout="grid" promptData={promptData} />
                  ))}
              </div>

              <div className="flex items-start p-2">
                <p className="line-clamp-3 text-xs flex-1" title={promptData.prompt}>
                  {promptData.prompt}
                </p>
                <TextCopy className="text-atherGray-300" width={14} height={14} value={promptData.prompt} />
              </div>
            </div>
          </div>
        ) : null}
      </Link>
    </div>
  )
}

export default memo(PromptItem)
