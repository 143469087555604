import { RecipeItem, RecipeItemTag, SDWorkflowDetail } from "@/api/sdk"
import { Radio } from "@/components/Alpha/View/GalleryFilter"
import AspectRatio from "@/components/AspectRatio"
import IconButton from "@/components/IconButton"
import ImageUpload from "@/components/ImageUpload"
import { ImageViewWithDelete } from "@/components/ImageUpload/ImageView"
import Input from "@/components/Input"
import Modal from "@/components/Modal"
import { UploadIcon, XIcon } from "@/components/shared/icons"
import { useToast } from "@/hooks"
import classNames from "classnames"
import React, { useEffect, useRef, useState } from "react"
import { Controller, FormProvider, useForm, useWatch } from "react-hook-form"
import { uploadComfyUIWorkflowFiles } from "./ComfyUIRecipeInfo"
import { useGetRecipeCategories } from "@/queries/workspace/recipe"
import { AnimatePresence } from "framer-motion"
import AddTagInput from "@/components/Explore/Gallery/GalleryFeatures/AddTagInput"
import Selector from "@/components/Selector"

type PublishRecipeModalProps = {
  workflow?: SDWorkflowDetail
  isOpen: boolean
  defaultValue: PublishRecipeFormData
  onPublish: (values: PublishRecipeFormData) => Promise<void>
  onClose: () => void
  lastRecipe?: RecipeItem
}

export type PublishRecipeFormData = {
  override: boolean
  name: string
  description: string
  thumbnailUrl: string
  category: string
  thumbnailId: string
  tags: RecipeItemTag[]
}

const PublishRecipeModal = (props: PublishRecipeModalProps) => {
  const { workflow, isOpen, defaultValue, onPublish, onClose, lastRecipe } = props

  const form = useForm<PublishRecipeFormData>()
  const { register, setValue } = form
  const toast = useToast()
  const [isUploading, setIsUploading] = useState(false)
  const [file, setFile] = useState<Blob | null>(null)
  const [preview, setPreview] = useState<string | null>(null)
  const [tags, setTags] = useState<RecipeItemTag[]>([])
  const { data: tabDataCategories } = useGetRecipeCategories()

  const fileInputThumbnailRef = useRef<HTMLInputElement>(null)

  const values = useWatch({
    control: form.control,
    defaultValue,
  })

  const [isSubmitting, setSubmitting] = useState(false)

  const handleUploadThumbnail = async (file: Blob) => {
    if (!workflow) return

    if (file.size > 10 * 1000 * 1000) {
      toast({
        status: "error",
        title: "File size too large",
        message: ["Input image exceeds 10MB, please scale down the image and try again"],
      })
      return
    }
    setIsUploading(true)

    const [uploaded] = await uploadComfyUIWorkflowFiles([file])

    setIsUploading(false)

    return uploaded.url
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const valid = await form.trigger()
    if (!valid) return

    const values = form.getValues()

    setSubmitting(true)

    let thumbnailUrl = file ? undefined : preview ?? undefined

    if (!thumbnailUrl && !file) {
      form.setError("thumbnailUrl", {
        message: "Thumbnail is required",
      })

      setSubmitting(false)
      return
    }

    if (file) {
      const result = await handleUploadThumbnail(file).finally(() => {
        setIsUploading(false)
      })

      thumbnailUrl = result ?? values.thumbnailUrl ?? ""
    }

    const newValues = {
      ...values,
      thumbnailUrl: thumbnailUrl ?? "",
      tags,
    }

    return onPublish(newValues).finally(() => {
      setSubmitting(false)
    })
  }

  useEffect(() => {
    form.reset(defaultValue)
    setPreview(defaultValue.thumbnailUrl)
    setTags(defaultValue.tags ?? [])
  }, [defaultValue])

  return (
    <Modal title="Publish recipe" className="overflow-visible max-w-sm" isOpen={isOpen} onClose={onClose}>
      <form onSubmit={handleSubmit} className="flex flex-col gap-4">
        <FormProvider {...form}>
          {lastRecipe && (
            <div className="flex flex-col space-y-2">
              <Radio
                className="w-full"
                name="Publish as a new Recipe"
                value="new"
                checked={!values.override}
                onChange={() => setValue("override", false)}
              />
              <Radio
                className="w-full"
                name="Update current published recipe"
                value="override"
                checked={!!values.override}
                onChange={() => setValue("override", true)}
              />
            </div>
          )}
          <div className="flex flex-col gap-1">
            <label className="block font-semibold text-atherGray-300 text-sm">Thumbnail</label>
            <ImageUpload
              isLoading={isUploading}
              fileInputRef={fileInputThumbnailRef}
              upload={async files => {
                setFile(files[0])
                setPreview(URL.createObjectURL(files[0]))
              }}
              className={classNames("w-full", {
                "border-transparent": !!preview,
              })}
            >
              {preview ? (
                <ImageViewWithDelete
                  className="w-full"
                  url={preview}
                  alt="Recipe Image"
                  onDelete={() => {
                    setFile(null)
                    URL.revokeObjectURL(preview)

                    setPreview(null)
                  }}
                  preferThumbnail
                />
              ) : (
                <AspectRatio ratio={197 / 96}>
                  <div className="flex flex-col items-center justify-center h-full">
                    <div className="p-2 mb-2 rounded-full bg-atherPurple-500">
                      <UploadIcon />
                    </div>
                    <p className="text-atherGray-300 text-center">
                      Upload images or Drag & Drop <br />
                      (max 2048px and less than 10MB)
                    </p>
                  </div>
                </AspectRatio>
              )}
            </ImageUpload>
            {form.formState.errors.thumbnailUrl?.message && (
              <p className="text-red-500 text-xs mt-1">{form.formState.errors.thumbnailUrl?.message}</p>
            )}
          </div>

          <div className="flex flex-col gap-1">
            <label className="block font-semibold text-atherGray-300 text-sm">Recipe name</label>
            <Controller
              name="name"
              rules={{ required: { value: true, message: "This field is required" } }}
              render={({ field, fieldState }) => (
                <>
                  <Input className="rounded-md px-2 text-atherGray-100" placeholder="Recipe name" {...field} />
                  {fieldState.error && <p className="text-red-500 text-xs">{fieldState.error.message}</p>}
                </>
              )}
            ></Controller>
          </div>
          <div className="flex flex-col gap-1">
            <Controller
              name="category"
              rules={{ required: { value: true, message: "This field is required" } }}
              render={({ field, fieldState }) => (
                <>
                  <Selector
                    placement="bottom"
                    className="bg-atherGray-800 h-7 pl-2 py-1 text-atherGray-100"
                    listClassName="bg-atherGray-700"
                    value={
                      tabDataCategories?.categories.find(i => i.id.toString() === field.value)?.name ??
                      "Select category"
                    }
                    options={
                      tabDataCategories?.categories
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map(c => ({ id: c.id.toString(), name: c.name })) ?? []
                    }
                    onSelect={v => field.onChange(v.id)}
                  />
                  {fieldState.error && <p className="text-red-500 text-xs">{fieldState.error.message}</p>}
                </>
              )}
            ></Controller>
          </div>
          <div className="flex flex-col gap-1">
            <p className="font-semibold text-whiteAlpha-900 text-sm pb-1 mt-2">Tags</p>
            <div className={classNames("rounded-md flex bg-atherGray-800 flex-wrap w-full min-h-[2.25rem] p-2 gap-1")}>
              {tags?.map(tag => (
                <div key={tag.id} className="flex items-center px-2 py-0.5 border rounded-lg border-atherGray-700">
                  <p className="mr-1 text-atherGray-300 text-sm">{tag.name}</p>
                  <button
                    className={classNames("flex items-center cursor-pointer justify-center")}
                    type="button"
                    onClick={() => {
                      setTags(tags => tags.filter(t => t.id !== tag.id))
                    }}
                  >
                    <XIcon width={10} height={10} className="text-atherGray-300" />
                  </button>
                </div>
              ))}
              <AnimatePresence mode="wait">
                {workflow?.capabilities?.canUpdate && (
                  <AddTagInput
                    className="bg-atherGray-700"
                    tags={tags}
                    setTags={setTags as any}
                    // onTag={tag => {
                    //   onAddTagSuccess(tag as Tag)
                    // }}
                  />
                )}
              </AnimatePresence>
            </div>
          </div>
          <div className="flex flex-col gap-1">
            <label className="block font-semibold text-atherGray-300 text-sm">Description</label>
            <div className="relative overflow-hidden rounded-md py-1 bg-atherGray-800">
              <textarea
                {...register("description")}
                className="bg-transparent w-full pr-8 h-[5rem] py-1 px-2 text-sm"
                placeholder={"Describe your recipe"}
              />
            </div>
          </div>

          <div className="flex justify-end mt-6 space-x-2">
            <IconButton colorScheme="secondary" className="text-xs" onClick={onClose} type="button">
              Cancel
            </IconButton>
            <IconButton className="text-xs" type="submit" isLoading={isSubmitting}>
              Confirm
            </IconButton>
          </div>
        </FormProvider>
      </form>
    </Modal>
  )
}

export default PublishRecipeModal
