import { IconProps } from "."

export const ThumbUpIcon = (props: IconProps) => {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M1.83398 7.99984C1.83398 7.26346 2.43094 6.6665 3.16732 6.6665C4.27189 6.6665 5.16732 7.56193 5.16732 8.6665L5.16732 11.3332C5.16732 12.4377 4.27189 13.3332 3.16732 13.3332C2.43094 13.3332 1.83398 12.7362 1.83398 11.9998L1.83398 7.99984Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.8205 4.87084L10.6429 5.44423C10.4975 5.91407 10.4247 6.149 10.4807 6.33453C10.5259 6.48463 10.6253 6.61401 10.7606 6.69914C10.928 6.80436 11.1811 6.80436 11.6874 6.80436H11.9567C13.6701 6.80436 14.5268 6.80436 14.9314 7.31153C14.9777 7.3695 15.0188 7.43113 15.0544 7.49578C15.3656 8.06143 15.0117 8.82342 14.304 10.3474C13.6544 11.7459 13.3297 12.4452 12.7267 12.8568C12.6683 12.8966 12.6083 12.9342 12.5468 12.9694C11.912 13.3333 11.1254 13.3333 9.55224 13.3333H9.21103C7.30511 13.3333 6.35215 13.3333 5.76006 12.7596C5.16797 12.186 5.16797 11.2626 5.16797 9.41595V8.76689C5.16797 7.79642 5.16797 7.31119 5.3402 6.86706C5.51242 6.42294 5.8422 6.05776 6.50177 5.32741L9.22938 2.30704C9.29779 2.23129 9.332 2.19341 9.36215 2.16717C9.64366 1.92219 10.0781 1.94976 10.3242 2.22823C10.3506 2.25806 10.3794 2.29994 10.437 2.38369C10.5272 2.5147 10.5723 2.5802 10.6115 2.6451C10.9632 3.22609 11.0696 3.91624 10.9085 4.57143C10.8905 4.64461 10.8671 4.72006 10.8205 4.87084Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
