import client from "@/api/client"
import { ImageOriginFilterOptions, ImageSimpleDetail, ImageSortByColumn, SearchImagesResponse } from "@/api/sdk"
import { createAuthenticatedInfiniteQuery } from "@/queries/createAuthenticatedQuery"
import { createInfiniteQuery } from "react-query-kit"

export const useWorkspaceCreationImagesInfiniteQuery = createAuthenticatedInfiniteQuery<
  SearchImagesResponse,
  {
    ownerUid?: string
    searchQuery?: string
    sortBy?: ImageSortByColumn
    sortOrder?: "ASC" | "DESC"
    recipeTaskIds?: string[]
    recipeIds?: string[]
    take?: number
  },
  Error,
  ImageSimpleDetail
>({
  primaryKey: "infinite-workspace-creation-images",
  queryFn: ({ queryKey: [_primaryKey, variables], pageParam = 0 }) =>
    client.api
      .imageControllerSearchImages({
        skip: pageParam * (variables.take || 20),
        take: variables.take || 20,
        origin: ImageOriginFilterOptions.Generated,
        sortBy: variables.sortBy || ImageSortByColumn.CreatedAt,
        sortOrder: variables.sortOrder || "DESC",
        ownerUids: variables.ownerUid ? [variables.ownerUid] : undefined,
        onlyDiscoverable: false,
        onlyShared: false,
        recipeIds: variables.recipeIds,
        searchQuery: variables.searchQuery,
        recipeTaskIds: variables.recipeTaskIds,
      })
      .then(res => res.data),
  getNextPageParam: (lastPage, pages) => (lastPage && lastPage.images.length < 20 ? undefined : pages.length),
  initialPageParam: 0,
  flattenFn: data => data.images,
  getTotalFn: data => data.total,
})

export const useWorkspaceCreationImagesNotAuthInfiniteQuery = createInfiniteQuery<
  SearchImagesResponse,
  {
    ownerUid?: string
    searchQuery?: string
    sortBy?: ImageSortByColumn
    recipeTaskIds?: string[]
  },
  Error
>({
  primaryKey: "infinite-workspace-creation-images-not-auth",
  queryFn: ({ queryKey: [_primaryKey, variables], pageParam = 0 }) =>
    client.api
      .imageControllerPublicSearchImages({
        skip: pageParam * 20,
        take: 20,
        sortBy: variables.sortBy || ImageSortByColumn.CreatedAt,
        sortOrder: "DESC",
        ownerUids: variables.ownerUid ? [variables.ownerUid] : undefined,
        onlyDiscoverable: false,
        onlyShared: false,
        searchQuery: variables.searchQuery,
        recipeTaskIds: variables.recipeTaskIds,
      })
      .then(res => res.data),
  getNextPageParam: (lastPage, pages) => (lastPage && lastPage.images.length < 20 ? undefined : pages.length),
  initialPageParam: 0,
})
