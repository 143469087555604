import {
  ContentModerationRating,
  ExploreWorkflowItem,
  RecipeTaskStatus,
  WorkflowItem as WorkflowItemType,
} from "@/api/sdk"
import Skeleton from "@/components/Skeleton"
import { useAuth } from "@/providers/authContext"
import { QueryKey } from "@tanstack/react-query"
import format from "date-fns/format"
import { useState } from "react"
import ActionButtonsWorkflow from "../../Workflows/WorkflowItem/ActionButtonsWorkflow"
import ImageWebpComponent from "@/components/ImageWebpComponent"
import { PinIcon } from "@/components/shared/icons"
import { useInView } from "react-intersection-observer"
import useCustomRouter from "@/hooks/useCustomRouter"
import { googleAnalytics } from "@/lib/gtag"
import UserCreated from "@/components/Explore/Gallery/UserCreated"
import classNames from "classnames"

interface WorkflowItemListProps {
  workflow:
    | WorkflowItemType
    | (ExploreWorkflowItem & { discoverableAt?: string; status?: RecipeTaskStatus; pinned?: boolean })
  accessedAt?: string
  onClick?: () => void
  hiddenMenu?: boolean
  queryKey?: QueryKey
  href?: string
}

const WorkflowItemList = ({ workflow, accessedAt, onClick, queryKey, hiddenMenu, href }: WorkflowItemListProps) => {
  const [imageLoaded, setImageLoaded] = useState(false)
  const { user } = useAuth()
  const { ref, inView } = useInView({
    threshold: 0,
    rootMargin: "800px 0px 800px 0px",
  })

  const { image } = workflow

  const [isShow, setIsShow] = useState(
    image?.moderationRating === ContentModerationRating.HardcoreNsfw ||
      image?.moderationRating === ContentModerationRating.SemiNsfw,
  )

  const router = useCustomRouter()

  const handleClick = () => {
    googleAnalytics.event({
      action: "click",
      category: "macro_item",
      label: `view_macro_detail`,
      params: {
        macro_id: workflow.id,
        macro_name: workflow.name,
        pathname: window.location.pathname,
      },
    })

    if (onClick) {
      onClick()

      return
    }
    router.push(href ?? `/workspace/macros/${workflow.id}`, undefined, { shallow: true })
  }

  return (
    <div className="w-full flex items-center text-sm text-atherPrimary-100 bg-atherGray-900 rounded-lg" ref={ref}>
      <div className="flex-1 md:flex-none md:w-[30%] py-2 pr-0 pl-2 md:pr-2 cursor-pointer" onClick={handleClick}>
        <div className="flex items-center overflow-hidden">
          <Skeleton
            className="mr-2 h-[3rem] w-[3rem] flex items-center overflow-hidden justify-center bg-atherGray-950"
            isLoaded={imageLoaded && (!workflow.image?.blurHash || !isShow)}
            blurHash={image?.blurHash ?? undefined}
          >
            {inView && (
              <ImageWebpComponent
                src={image?.url}
                alt={workflow.name}
                className={classNames("block max-h-full object-contain", {
                  "blur-sm": !workflow.image?.blurHash && isShow,
                })}
                onLoad={() => setImageLoaded(true)}
              />
            )}
          </Skeleton>
          <div className="overflow-hidden flex flex-col flex-1">
            <p className="font-semibold flex-1 line-clamp-1">{workflow.name}</p>
            <div className="block md:hidden truncate text-atherGray-500">
              Opened - {accessedAt ? format(new Date(accessedAt), "HH:mm dd/MM/yyyy") : ""}
            </div>
          </div>
        </div>
      </div>
      <div className="hidden md:block md:w-[15%] p-2 whitespace-nowrap truncate">Macro</div>
      <div className="hidden md:block md:w-[15%] p-2 whitespace-nowrap truncate">
        {accessedAt ? format(new Date(accessedAt), "HH:mm dd/MM/yyyy") : ""}
      </div>
      <div className="hidden md:block md:w-[15%] p-2">
        <UserCreated
          size="sm"
          picture={workflow.creator.picture}
          name={workflow.creator.name}
          isMe={user?.uid === workflow.creator.uid}
        />
      </div>
      <div
        className="hidden md:block md:w-[20%] p-2 overflow-hidden cursor-pointer"
        onClick={e => {
          e.stopPropagation()
        }}
      ></div>
      <div className="md:w-[5%] pr-2 md:p-2 flex justify-end">
        {workflow.pinned !== undefined && workflow.pinned && (
          <div className="p-0.5">
            <PinIcon width={16} height={16} />
          </div>
        )}
        {!hiddenMenu &&
          workflow.status &&
          workflow.status !== RecipeTaskStatus.QUEUED &&
          workflow.status !== RecipeTaskStatus.RUNNING && (
            <ActionButtonsWorkflow
              layout="list"
              workflow={workflow as WorkflowItemType}
              queryKey={queryKey}
              className="bg-transparent hover:bg-transparent"
            />
          )}
      </div>
    </div>
  )
}

export default WorkflowItemList
