import { useCallback, useRef } from "react"
import _throttle from "lodash/throttle"
import { useSelectionContainer } from "./select-drag/useSelectionContainer"
import { Box } from "@/utils/select-drag/types"
import { boxesIntersect } from "@/utils/select-drag/boxes"

export function useDragSelection<T>(
  items: (T & { id: string })[],
  selectAll: (items: (T & { id: string })[]) => void,
  isEnabled: boolean,
) {
  const itemRefs = useRef<Record<string, HTMLDivElement | null>>({})

  const throttleScrollDown = useCallback(
    _throttle(
      () =>
        window.scrollBy({
          top: 20,
        }),
      5,
    ),
    [],
  )

  const throttleScrollUp = useCallback(
    _throttle(
      () =>
        window.scrollBy({
          top: -20,
        }),
      5,
    ),
    [],
  )

  const { DragSelection, dragDirection } = useSelectionContainer({
    onSelectionChange: box => {
      const scrollAwareBox: Box = {
        ...box,
        top: box.top,
        left: box.left,
      }

      const dragSelectedItems = Object.entries(itemRefs.current)
        .filter(([_, ref]) => !!ref && boxesIntersect(scrollAwareBox, ref.getBoundingClientRect()))
        .map(([key]) => key)

      selectAll(items?.filter(image => dragSelectedItems.includes(image.id)) ?? [])

      if (dragDirection > 0 && box.top + box.height > window.innerHeight - 200) {
        throttleScrollDown()
      } else if (dragDirection < 0 && box.top < 200) {
        throttleScrollUp()
      }
    },

    selectionProps: {
      style: {
        border: "2px dashed purple",
        borderRadius: 4,
        backgroundColor: "#5C14EB",
        opacity: 0.5,
        zIndex: 2,
      },
    },
    isEnabled,
  })

  return { DragSelection, itemRefs, throttleScrollDown, throttleScrollUp, dragDirection }
}
