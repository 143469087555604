import client from "@/api/client"
import { SearchModelDto, SearchModelResults, SDModelItem } from "@/api/sdk"
import { createAuthenticatedInfiniteQuery } from "@/queries/createAuthenticatedQuery"

export const useWorkspaceModelsInfiniteQuery = createAuthenticatedInfiniteQuery<
  SearchModelResults,
  SearchModelDto,
  Error,
  SDModelItem
>({
  primaryKey: "sdModel-infinity",
  queryFn: ({ queryKey: [_primaryKey, variables], pageParam = 0 }) =>
    client.api
      .sdModelControllerSearchModels({
        skip: pageParam * (variables.take ?? 20),
        take: variables.take ?? 20,
        searchTerm: variables.searchTerm,
        mode: variables.mode,
        ids: variables.ids,
        baseModel: variables.baseModel,
        variants: variables.variants,
        sortBy: variables.sortBy,
        isPinned: variables.isPinned,
        creatorUid: variables.creatorUid,
        baseModels: variables.baseModels?.length ? variables.baseModels : undefined,
      })
      .then(res => res.data),
  flattenFn: data => data.data,
  getTotalFn: data => data.total,
  getNextPageParam: (lastPage, pages) => (lastPage && lastPage?.data.length < 20 ? undefined : pages.length),
  initialPageParam: 0,
  refetchOnWindowFocus: false,
})
