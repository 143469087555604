import { useState, useLayoutEffect, useId, forwardRef } from "react"
import { createPortal } from "react-dom"
import React from "react"

if (typeof document === "undefined") {
  React.useLayoutEffect = React.useEffect
}

function createWrapperAndAppendToBody(wrapperId) {
  const wrapperElement = document.createElement("div")
  wrapperElement.setAttribute("id", wrapperId)
  document.body.appendChild(wrapperElement)
  return wrapperElement
}

const ReactPortal = forwardRef<{ children: React.ReactNode; wrapperId?: string }, any>(
  ({ children, wrapperId = "react-portal-wrapper" }, ref) => {
    const [wrapperElement, setWrapperElement] = useState<HTMLDivElement | null>(null)
    const key = useId()

    useLayoutEffect(() => {
      let element = document.getElementById(wrapperId) as HTMLDivElement
      let systemCreated = false
      // if element is not found with wrapperId or wrapperId is not provided,
      // create and append to body
      if (!element) {
        systemCreated = true
        element = createWrapperAndAppendToBody(wrapperId)
      }
      setWrapperElement(element)

      return () => {
        // delete the programatically created element
        if (systemCreated && element.parentNode) {
          element.parentNode.removeChild(element)
        }
      }
    }, [wrapperId])

    // wrapperElement state will be null on very first render.
    if (wrapperElement === null) return null

    return createPortal(children, wrapperElement, key)
  },
)

export default ReactPortal
