import { useAuth } from "@/providers/authContext"
import { useBrowserRouter } from "@/providers/BrowserRouterProvider"
import { useExploreDetailImageQuery } from "@/queries"
import { useHasClientHistory } from "@/stores/ClientHistoryStore"
import useCustomRouter from "@/hooks/useCustomRouter"
import { useMemo, useEffect, useContext, createContext, useState } from "react"
import { QS } from "@/utils/qs"
import { useCallbackOnHotKeys } from "@/hooks"
import { QueryKey } from "@tanstack/react-query"
import { createQuery } from "react-query-kit"
import client from "@/api/client"
import { enableQueryMiddleware } from "@/queries/middlewares/enableQueryMiddleware"
import { ArticleDetail } from "@/api/sdk"

type ArticleDetailState = {
  article?: ArticleDetail
  isMod?: boolean
  isOwner?: boolean
  active?: boolean
  isSuccess: boolean
  isLoading: boolean
  toggleInfo: () => void
  close: () => void
  queryDetailKey: QueryKey
  isError: boolean
  filters: {
    historyPath?: string
  }
}

export const useArticleDetailQuery = createQuery<ArticleDetail, { articleId: string; userUid?: string }, Error>({
  primaryKey: "article",
  queryFn: ({ queryKey: [_primaryKey, variables] }) =>
    client.api.articleControllerGetArticleById(variables.articleId).then(res => res.data),

  use: [enableQueryMiddleware(v => !!v.articleId)],
})

const ArticleDetailContext = createContext<ArticleDetailState | null>(null)
export const useArticleDetailContext = () => {
  const context = useContext(ArticleDetailContext)
  if (!context) throw new Error("useArticleDetailContext not found in tree")
  return context
}

export function ArticleDetailProvider({
  children,
  articleId,
  filters,
}: {
  children: React.ReactElement
  articleId: string
  filters: {
    historyPath?: string
  }
}) {
  const router = useCustomRouter()
  const browserRouter = useBrowserRouter()
  const active = browserRouter.query.active
  const hasHistory = useHasClientHistory()
  const { user: currentUser, loading } = useAuth()
  const [isErrorLoaded, setIsErrorLoaded] = useState(false)
  const { historyPath } = filters

  const {
    data: article,
    isLoading: isLoadingArticle,
    isError,
    isSuccess,
  } = useArticleDetailQuery({
    variables: {
      articleId,
      userUid: currentUser?.uid,
    },
    refetchOnWindowFocus: !isErrorLoaded,
    enabled: !loading,
  })

  const queryDetailKey = useExploreDetailImageQuery.getKey({
    articleId,
    userUid: currentUser?.uid,
  })

  useEffect(() => {
    if (isError) {
      setIsErrorLoaded(true)
    }
  }, [isError])

  const close = () => {
    const modalChild = document.getElementsByClassName("modal-content-child")

    if (modalChild.length > 0) return

    if (hasHistory) browserRouter.back()
    else {
      const [, queryString] = browserRouter.asPath.split("?")
      const { active, ...query } = QS.parse(queryString) as any
      if (active) browserRouter.replace({ query: browserRouter.query }, { query })
      else {
        const returnUrl = getReturnUrl({
          historyPath,
        })

        router.push(returnUrl, undefined, { shallow: true })
      }
    }
  }

  useCallbackOnHotKeys("esc", close, !!articleId)

  // #region [info toggle]
  const toggleInfo = () => {
    const [, queryString] = browserRouter.asPath.split("?")
    const { active, ...query } = QS.parse(queryString) as any

    browserRouter.push({ query: { ...browserRouter.query, active: !active } }, { query: { ...query, active: !active } })
  }

  const shareUrl = useMemo(() => {
    const [pathname, queryString] = browserRouter.asPath.split("?")
    const { active, ...query } = QS.parse(queryString)
    return Object.keys(query).length > 0 ? `${pathname}?${QS.stringify(query)}` : pathname
  }, [browserRouter])

  const isOwner = currentUser?.uid === article?.creator.uid

  return (
    <ArticleDetailContext.Provider
      value={{
        article,
        isLoading: isLoadingArticle,
        active,
        toggleInfo,
        isSuccess,
        close,
        queryDetailKey,
        isError,
        filters,
        isOwner,
      }}
    >
      {children}
    </ArticleDetailContext.Provider>
  )
}
const getReturnUrl = ({ historyPath }: { historyPath?: string }) => {
  if (historyPath) {
    return historyPath
  }

  return "/explore/articles"
}
