import { useCallback, useEffect, useRef, useState } from "react"

export const useDetectScrollBottom = () => {
  const listInnerRef = useRef<HTMLDivElement>(null)
  const [isEnd, setIsEnd] = useState(false)

  useEffect(() => {
    if (listInnerRef.current) {
      const { scrollHeight, clientHeight } = listInnerRef.current

      if (scrollHeight === clientHeight) {
        setIsEnd(true)
      } else {
        setIsEnd(false)
      }
    }
  }, [])

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current

      if (scrollHeight - scrollTop - 24 <= clientHeight) {
        setIsEnd(true)
      } else {
        setIsEnd(false)
      }
    }
  }

  const scrollToBottom = useCallback(() => {
    if (listInnerRef.current) {
      const { scrollHeight } = listInnerRef.current
      listInnerRef.current?.scroll({ top: scrollHeight, behavior: "smooth" })
    }
  }, [listInnerRef])

  return { onScroll, isEnd, listInnerRef, scrollToBottom }
}
export default useDetectScrollBottom
