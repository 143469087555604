import client from "@/api/client"
import { ExploreWorkflowDetail } from "@/api/sdk"
import { createMutation, createQuery } from "react-query-kit"
import { enableQueryMiddleware } from "../middlewares/enableQueryMiddleware"

export interface ExploreWorkflowDetailQueryVariables {
  workflowId: string
  userUid?: string
}

export const useExploreWorkflowDetailQuery = createQuery<
  ExploreWorkflowDetail,
  ExploreWorkflowDetailQueryVariables,
  Error
>({
  primaryKey: "explore-workflow-detail",
  queryFn: ({ queryKey: [_primaryKey, variables] }) =>
    client.api.exploreWorkflowControllerGetExploreWorkflowById(variables.workflowId).then(res => res.data),
  use: [enableQueryMiddleware(variables => !!variables.workflowId)],
})

export const useGetExploreDetailWorkflowMutation = createMutation({
  mutationFn: (workflowId: string) =>
    client.api.exploreWorkflowControllerGetExploreWorkflowById(workflowId).then(res => res.data),
})
