import { cn } from "@/utils/cn"
import { motion } from "framer-motion"
import { forwardRef } from "react"
import { SpinnerIcon } from "./shared/icons"
import Link from "next/link"

export type ColorSchemeButton =
  | "primary"
  | "secondary"
  | "transparent"
  | "third"
  | "telegram"
  | "red"
  | "fourth"
  | "yellow"

interface IconButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string
  children?: React.ReactNode
  isLoading?: boolean
  colorScheme?: ColorSchemeButton
  leftIcon?: React.ReactNode
  href?: string
  target?: string
  loadingClassName?: string
}

const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    {
      children,
      className,
      isLoading,
      disabled,
      loadingClassName,
      colorScheme = "primary",
      leftIcon,
      target,
      href,
      title = "",
      ...rest
    },
    ref,
  ) => {
    const colorSchemeClass =
      colorScheme === "primary"
        ? "bg-atherPurple-500 hover:bg-atherPurple-400 active:bg-atherPurple-600 disabled:bg-atherPurple-700"
        : colorScheme === "secondary"
          ? "bg-atherGray-800 hover:bg-atherGray-700 active:bg-atherGray-800 disabled:bg-atherGray-700"
          : colorScheme === "third"
            ? "bg-atherGray-850 hover:bg-atherGray-800 active:bg-atherGray-850 disabled:bg-atherGray-800"
            : colorScheme === "fourth"
              ? "bg-atherGray-700 hover:bg-atherGray-600 active:bg-atherGray-700 disabled:bg-atherGray-600"
              : colorScheme === "red"
                ? "bg-red-500 hover:bg-red-400 active:bg-red-600 disabled:bg-red-700"
                : colorScheme === "yellow"
                  ? "bg-yellow-500 hover:bg-yellow-400 active:bg-yellow-600 disabled:bg-yellow-700"
                  : colorScheme === "telegram"
                    ? "bg-telegramBlue hover:bg-telegramBlue active:bg-telegramBlue disabled:bg-atherPurple-700"
                    : "bg-transparent hover:bg-transparent active:bg-transparent"

    if (href) {
      return (
        <Link
          title={title}
          target={target}
          className={cn(
            `flex gap-1 items-center justify-center text-atherGray-0 px-3 min-h-[2rem] rounded-lg font-semibold relative overflow-hidden select-none text-sm`,
            colorSchemeClass,
            {
              "pointer-events-none opacity-60 cursor-not-allowed ": isLoading,
            },
            className,
            {
              "!cursor-not-allowed text-atherGray-300 opacity-50": disabled,
            },
          )}
          href={href}
        >
          {children}
        </Link>
      )
    }

    return (
      <button
        type="button"
        className={cn(
          `flex gap-1 items-center justify-center text-atherGray-0 px-3 min-h-[2rem] rounded-lg font-semibold relative overflow-hidden select-none text-sm`,
          colorSchemeClass,
          {
            "pointer-events-none opacity-60 cursor-not-allowed ": isLoading,
          },
          className,
          {
            "!cursor-not-allowed text-atherGray-300 opacity-50": disabled,
          },
        )}
        title={title}
        {...rest}
        disabled={disabled}
        ref={ref}
      >
        {leftIcon && <span className="flex items-center justify-center w-5 h-5">{leftIcon}</span>}
        {children}
        {isLoading && (
          <div
            className={cn(
              "absolute w-full bottom-0 p-0 flex justify-center items-center top-0 left-0 z-[1] overflow-hidden",
              colorSchemeClass,
              className,
              loadingClassName,
            )}
          >
            <motion.div animate={{ rotate: 360 }} transition={{ duration: 1, repeat: Infinity, ease: "linear" }}>
              <SpinnerIcon width={16} height={16} />
            </motion.div>
          </div>
        )}
      </button>
    )
  },
)

export default IconButton
