import { IconProps } from "."

export const RocketIcon = (props: IconProps) => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8.5 10.1667L12.6232 6.04344C13.7481 4.91858 14.3105 4.35614 14.9312 3.90314C16.2047 2.9737 17.6818 2.36187 19.2395 2.11858C19.9988 2 20.7942 2 22.385 2C22.4681 2 22.5 2.03812 22.5 2.11504C22.5 3.70584 22.5 4.50125 22.3814 5.26046C22.1381 6.81818 21.5263 8.29527 20.5969 9.56878C20.1439 10.1895 19.5814 10.7519 18.4566 11.8768L14.3333 16"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.841 8.09838C9.13808 8.09838 6.99778 7.73765 5.4043 8.39769C4.23671 8.88132 3.37754 10.0012 2.5 10.8787L5.80597 12.2955C6.68208 12.671 6.14651 13.7766 6.00147 14.5018C5.83985 15.3099 5.84886 15.3397 6.43158 15.9224L8.57758 18.0684C9.1603 18.6511 9.19008 18.6602 9.99816 18.4985C10.7234 18.3535 11.829 17.8179 12.2044 18.694L13.6213 22C14.4988 21.1225 15.6187 20.2633 16.1023 19.0957C16.7624 17.5022 16.4016 15.3619 16.4016 13.659"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M19.394 6.16669C19.6869 5.8738 19.6869 5.39893 19.394 5.10603C19.1011 4.81314 18.6262 4.81314 18.3333 5.10603L19.394 6.16669ZM16.4697 6.96967C16.1768 7.26256 16.1768 7.73744 16.4697 8.03033C16.7626 8.32322 17.2374 8.32322 17.5303 8.03033L16.4697 6.96967ZM18.3333 5.10603L16.4697 6.96967L17.5303 8.03033L19.394 6.16669L18.3333 5.10603ZM14.4778 4.74967C16.262 4.80259 17.5307 5.34191 18.3673 6.19922C19.2066 7.05944 19.7002 8.33139 19.7503 10.0222L21.2497 9.97776C21.1917 8.02218 20.6114 6.35127 19.4409 5.15166C18.2675 3.94915 16.5904 3.31167 14.5222 3.25033L14.4778 4.74967Z"
        fill="currentColor"
      />
      <path d="M12.5 20L11.5 21" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4.5 12L3.5 13" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
