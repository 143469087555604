import client from "@/api/client"
import { actionMiddleware } from "@/queries/middlewares/actionMiddleware"
import { createMutation } from "react-query-kit"
import { RecentImageItem, SearchImagesResponse } from "@/api/sdk"
import { InfiniteData } from "@tanstack/react-query"
import { useWorkspaceImagesInfiniteQuery } from "../useWorkspaceImagesInfiniteQuery"
import { useRecentImagesInfiniteQuery } from "@/components/Workspace/RecentV2/RecentImages"

export const useRecoverImageMutation = createMutation({
  mutationFn: async ({ ids }: { ids: string[]; folderId?: string }) => {
    return await client.api.imageControllerRecoverImages({ imageIds: ids }).then(res => res.data)
  },
  use: [
    actionMiddleware({
      onSuccess: ({ queryClient, variables }) => {
        const workspaceImagesKey = useWorkspaceImagesInfiniteQuery.getKey()
        const workspaceImagesQueriesDataEntries = queryClient.getQueriesData<
          InfiniteData<SearchImagesResponse, number>
        >({
          queryKey: workspaceImagesKey,
        })
        if (workspaceImagesQueriesDataEntries) {
          workspaceImagesQueriesDataEntries.forEach(([key, parentSubFoldersData]) => {
            if (parentSubFoldersData) {
              const updatedData = {
                ...parentSubFoldersData,
                pages: parentSubFoldersData.pages.map(page => {
                  return {
                    ...page,
                    total: page.total - variables.ids.length,
                    images: page.images.filter(image => !variables.ids.includes(image.id)),
                  }
                }),
              }
              queryClient.setQueryData(key, updatedData)
            }
          })
        }

        // Update images recent list
        const imagesRecentListKey = useRecentImagesInfiniteQuery.getKey()

        const imagesRecentListQueriesDataEntries = queryClient.getQueriesData<InfiniteData<RecentImageItem[], number>>({
          queryKey: imagesRecentListKey,
        })

        if (imagesRecentListQueriesDataEntries) {
          imagesRecentListQueriesDataEntries.forEach(([key, imagesRecentListData]) => {
            if (imagesRecentListData) {
              const updatedData = {
                ...imagesRecentListData,
                pages: imagesRecentListData.pages.map(page => {
                  return [...page.filter(images => !variables.ids.includes(images.image.id))]
                }),
              }

              queryClient.setQueryData(key, updatedData)
            }
          })
        }
      },
    }),
  ],
})
