import { IconProps } from "."

export const BuildingIcon = (props: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 20 22" fill="none" {...props}>
      <path
        d="M10 1H4C1.518 1 1 1.518 1 4V21H13V4C13 1.518 12.482 1 10 1Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M16 7H13V21H19V10C19 7.518 18.482 7 16 7Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M6 5L8 5M6 8L8 8M6 11L8 11"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.5 21V17C9.5 16.0572 9.5 15.5858 9.20711 15.2929C8.91421 15 8.44281 15 7.5 15H6.5C5.55719 15 5.08579 15 4.79289 15.2929C4.5 15.5858 4.5 16.0572 4.5 17V21"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  )
}
