import { create } from "zustand"

export interface UploadProgress {
  current: number
  currentFile: number
  total: number
  totalFile: number
  percent: number
  enable: boolean
}

interface UploadProgressStore {
  progress: UploadProgress
  setUploadProgress: (p: UploadProgress) => void
}

export const useUploadProgressStore = create<UploadProgressStore>(set => ({
  progress: { current: 0, total: 0, percent: 0, currentFile: 0, enable: false, totalFile: 0 },
  setUploadProgress: p => set({ progress: p }),
}))
