import client from "@/api/client"
import { CreateFreeRecipeTaskDto, CreateRecipeTaskDto } from "@/api/sdk"
import { createMutation } from "react-query-kit"

export const useCreateTaskMutation = createMutation({
  mutationFn: (data: CreateRecipeTaskDto) => client.api.recipeTaskControllerAddTask(data).then(res => res.data),
})

export const useCreateTaskNotAuthMutation = createMutation({
  mutationFn: (data: CreateFreeRecipeTaskDto) => client.api.recipeTaskControllerAddFreeTask(data).then(res => res.data),
})
