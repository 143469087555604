import { IconProps } from "."

export const ArrowSquareLeftIcon = (props: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none" {...props}>
      <path
        d="M1.5 11C1.5 6.52166 1.5 4.28249 2.89124 2.89124C4.28249 1.5 6.52166 1.5 11 1.5C15.4783 1.5 17.7175 1.5 19.1088 2.89124C20.5 4.28249 20.5 6.52166 20.5 11C20.5 15.4783 20.5 17.7175 19.1088 19.1088C17.7175 20.5 15.4783 20.5 11 20.5C6.52166 20.5 4.28249 20.5 2.89124 19.1088C1.5 17.7175 1.5 15.4783 1.5 11Z"
        fill="#5E17EB"
        stroke="#5E17EB"
        strokeWidth="1.5"
      />
      <path
        d="M6.75 7C6.75 6.58579 6.41421 6.25 6 6.25C5.58579 6.25 5.25 6.58579 5.25 7L6.75 7ZM5.25 15C5.25 15.4142 5.58579 15.75 6 15.75C6.41421 15.75 6.75 15.4142 6.75 15H5.25ZM16 11.75C16.4142 11.75 16.75 11.4142 16.75 11C16.75 10.5858 16.4142 10.25 16 10.25V11.75ZM12.0227 9.03781C12.3198 8.74911 12.3265 8.27428 12.0378 7.97726C11.7491 7.68023 11.2743 7.67349 10.9773 7.96219L12.0227 9.03781ZM10.9773 14.0378C11.2743 14.3265 11.7491 14.3198 12.0378 14.0227C12.3265 13.7257 12.3198 13.2509 12.0227 12.9622L10.9773 14.0378ZM5.25 7L5.25 15H6.75L6.75 7L5.25 7ZM9 11.75H16V10.25H9V11.75ZM9.75 11C9.75 11.0832 9.7233 11.1116 9.74795 11.0656C9.76941 11.0255 9.81319 10.9601 9.89049 10.8694C10.0456 10.6873 10.274 10.4738 10.5452 10.2439C10.8132 10.0169 11.0975 9.79493 11.3593 9.58933C11.604 9.3971 11.8612 9.19487 12.0227 9.03781L10.9773 7.96219C10.886 8.05089 10.7042 8.19651 10.4328 8.40971C10.1784 8.60952 9.86934 8.85056 9.57551 9.09954C9.28495 9.34574 8.98316 9.62131 8.74854 9.89679C8.63096 10.0348 8.51499 10.1904 8.42541 10.3578C8.33902 10.5193 8.25 10.7417 8.25 11H9.75ZM12.0227 12.9622C11.8612 12.8051 11.604 12.6029 11.3593 12.4107C11.0975 12.2051 10.8132 11.9831 10.5452 11.7561C10.274 11.5262 10.0456 11.3127 9.89049 11.1306C9.81319 11.0399 9.76941 10.9745 9.74795 10.9344C9.7233 10.8884 9.75 10.9168 9.75 11H8.25C8.25 11.2583 8.33902 11.4807 8.42541 11.6422C8.51499 11.8096 8.63096 11.9652 8.74854 12.1032C8.98316 12.3787 9.28495 12.6543 9.57551 12.9005C9.86934 13.1494 10.1784 13.3905 10.4328 13.5903C10.7042 13.8035 10.886 13.9491 10.9773 14.0378L12.0227 12.9622Z"
        fill="#EFE8FD"
      />
    </svg>
  )
}
