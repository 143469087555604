import client from "@/api/client"
import { LeaderboardItem } from "@/api/sdk"
import { createQuery } from "react-query-kit"

export const useLeaderboardsQuery = createQuery<LeaderboardItem[], { skip: number; take: number }, Error>({
  primaryKey: "leaderboards",
  queryFn: ({ queryKey: [_primaryKey, variables] }) =>
    client.api
      .leaderboardControllerGetLeaderboards({ skip: variables.skip, take: variables.take })
      .then(res => res.data),
})
