import { useOutsideClick } from "@/hooks"
import React, { useRef } from "react"
import { RemoveScroll } from "react-remove-scroll"
import { AnimatePresence, motion } from "framer-motion"
import IconButton from "./IconButton"
import { XIcon } from "./shared/icons"
import { cn } from "@/utils/cn"

interface BottomSheetProps {
  isOpen: boolean
  onClose: () => void
  children?: React.ReactNode
  className?: string
  containerClassName?: string
}

const BottomSheet = ({ isOpen, onClose, children, className, containerClassName }: BottomSheetProps) => {
  const ref = useRef<HTMLDivElement>(null)

  useOutsideClick({
    ref,
    enabled: isOpen,
    handler: () => onClose(),
  })

  return (
    <AnimatePresence>
      {isOpen && (
        <RemoveScroll
          style={{
            animationDelay: "0s",
          }}
          removeScrollBar
        >
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={e => {
              e.stopPropagation()
            }}
            className={cn(
              "fixed bottom-0 left-0 w-full flex items-end height-full-screen bg-blackAlpha-900 backdrop-blur-sm z-[99] overflow-hidden",
              containerClassName,
            )}
          >
            <motion.div
              initial={{ translateY: "100%" }}
              animate={{ translateY: "0%" }}
              exit={{ translateY: "100%" }}
              transition={{ type: "tween", damping: 20, stiffness: 300 }}
              ref={ref}
              className={cn("w-full h-5/6 rounded-t-2xl p-4 relative bg-atherGray-900 overflow-hidden", className)}
            >
              {children}
            </motion.div>
            <IconButton
              colorScheme="transparent"
              className="p-2 min-h-0 absolute  top-[calc(100%-80%-2rem)] -translate-y-full right-2"
            >
              <XIcon className="" width={14} height={14} />
            </IconButton>
          </motion.div>
        </RemoveScroll>
      )}
    </AnimatePresence>
  )
}

export default BottomSheet
