import { IconProps } from "."

export const SmileIcon = (props: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <circle
        cx="7.9987"
        cy="7.99967"
        r="6.66667"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.33203 10C5.94011 10.8096 6.90825 11.3333 7.9987 11.3333C9.08914 11.3333 10.0573 10.8096 10.6654 10"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.33801 6L5.33203 6M10.6654 6L10.6594 6"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
