import client from "@/api/client"
import { ExploreSDWorkflowDetail } from "@/api/sdk"
import { createQuery } from "react-query-kit"
import { enableQueryMiddleware } from "../middlewares/enableQueryMiddleware"

export interface ExploreComfyUIDetailQueryVariables {
  sdWorkflowId: string
  userUid?: string
}

export const useExploreDetailComfyUIQuery = createQuery<
  ExploreSDWorkflowDetail,
  ExploreComfyUIDetailQueryVariables,
  Error
>({
  primaryKey: "explore-detail-comfyui",
  queryFn: ({ queryKey: [_primaryKey, variables] }) =>
    client.api.exploreSdWorkflowControllerGetExploreSdWorkflowById(variables.sdWorkflowId).then(res => res.data),
  use: [enableQueryMiddleware(variables => !!variables.sdWorkflowId)],
})
