import React, { useRef } from "react"
import { FormProvider, UseFormReturn } from "react-hook-form"
import ImageUpload from "@/components/ImageUpload"
import { Controller } from "react-hook-form"
import Textarea from "@/components/Textarea"
import IconButton from "@/components/IconButton"
import AspectRatio from "@/components/AspectRatio"
import classNames from "classnames"
import { ImageViewWithDelete } from "@/components/ImageUpload/ImageView"
import { UploadIcon } from "@/components/shared/icons"

interface PromptFormProps {
  form: UseFormReturn<{ thumbnailId: string; description?: string; prompt: string }>
  defaultPromptData: any
  file: File | null
  setFile: (file: File | null) => void
  preview: string | null
  setPreview: (preview: string | null) => void
  isUploading: boolean
  isAdding: boolean
  isEditing: boolean
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void
  onClose: () => void
}

const PromptForm: React.FC<PromptFormProps> = ({
  form,
  defaultPromptData,
  setFile,
  preview,
  setPreview,
  isUploading,
  isAdding,
  isEditing,
  onSubmit,
  onClose,
}) => {
  const fileInputThumbnailRef = useRef<HTMLInputElement>(null)

  return (
    <form onSubmit={onSubmit} className="flex flex-col gap-4">
      <FormProvider {...form}>
        <div className="flex flex-col gap-1">
          <label className="block font-semibold text-atherGray-300 text-sm">Thumbnail</label>
          <AspectRatio ratio={375 / 182}>
            <ImageUpload
              isLoading={isUploading}
              fileInputRef={fileInputThumbnailRef}
              upload={async files => {
                setFile(files[0] as File)
                setPreview(URL.createObjectURL(files[0]))
              }}
              className={classNames("w-full h-full", {
                "border-transparent": !!preview,
              })}
            >
              {preview ? (
                <ImageViewWithDelete
                  className="w-full h-full"
                  imgClassName="object-cover w-full h-full"
                  url={preview}
                  alt="Recipe Image"
                  onDelete={() => {
                    setFile(null)
                    URL.revokeObjectURL(preview)

                    setPreview(null)
                  }}
                  preferThumbnail
                />
              ) : (
                <div className="flex flex-col items-center justify-center h-full">
                  <div className="p-2 mb-2 rounded-full bg-atherPurple-500">
                    <UploadIcon />
                  </div>
                  <p className="text-atherGray-300 text-center">
                    Upload images or Drag & Drop <br />
                    (max 2048px and less than 10MB)
                  </p>
                </div>
              )}
            </ImageUpload>
          </AspectRatio>
          {form.formState.errors.thumbnailId?.message && (
            <p className="text-red-500 text-xs mt-1">{form.formState.errors.thumbnailId?.message}</p>
          )}
        </div>
        <div className="flex flex-col gap-1">
          <label className="block font-semibold text-atherGray-300 text-sm">Prompt</label>
          <Controller
            name="prompt"
            rules={{ required: { value: true, message: "This field is required" } }}
            render={({ field, fieldState }) => (
              <>
                <div className="relative overflow-hidden rounded-md py-1 bg-atherGray-800">
                  <Textarea
                    className="bg-transparent w-full pr-8 h-[5rem] py-1 px-2 text-sm text-atherGray-100"
                    placeholder={"Describe your prompt"}
                    {...field}
                  />
                </div>
                {fieldState.error && <p className="text-red-500 text-xs">{fieldState.error.message}</p>}
              </>
            )}
          ></Controller>
        </div>
      </FormProvider>

      <div className="flex justify-end mt-6 space-x-2">
        <IconButton colorScheme="secondary" className="text-xs" onClick={onClose}>
          Cancel
        </IconButton>
        <IconButton isLoading={isAdding || isEditing} className="text-xs" type="submit">
          {defaultPromptData && defaultPromptData.promptId ? "Update" : "Add"}
        </IconButton>
      </div>
    </form>
  )
}

export default PromptForm
