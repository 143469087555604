import client from "@/api/client"
import { googleAnalytics } from "@/lib/gtag"
import { useBrowserRouter } from "@/providers/BrowserRouterProvider"
import { useAuth } from "@/providers/authContext"
import { useReferralCodeMutation } from "@/queries"
import { useTempTaskStoreV2 } from "@/stores"
import { useTempTaskStore } from "@/stores/useTempTaskStore"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { User, UserCredential, getAdditionalUserInfo } from "firebase/auth"
import useCustomRouter from "@/hooks/useCustomRouter"

const useReferralUser = (onClose: () => void) => {
  const { user, userInfoQuery } = useAuth()
  const qc = useQueryClient()
  const router = useCustomRouter()
  const [tempTask, setTempTask] = useTempTaskStore(state => [state.tempTask, state.setTempTask])
  const [tempTaskV2, setTempTaskV2] = useTempTaskStoreV2(state => [state.tempTask, state.setTempTask])
  const { asPath } = useBrowserRouter()

  const { mutate: mutateAccess } = useMutation({
    mutationFn: async (_: User) => client.api.subscriptionControllerSubscribe(),
    onSuccess: (_, user) => {
      userInfoQuery?.refetch()
    },
    onError: (error: any, user) => {
      userInfoQuery?.refetch()

      if (error?.response?.data?.message === "Your email is already registered") {
      }
    },
  })

  const { mutate: mutateReferralCode } = useReferralCodeMutation()

  const { mutate: mutateTransferTask } = useMutation({
    mutationFn: (taskIds: string[]) =>
      client.api.recipeTaskControllerTransferAnonymousTaskOwner({
        taskIds,
      }),
    onSuccess: () => {
      if (tempTask && tempTask.length > 0) {
        const imageId = asPath.includes("/images/")

        setTimeout(() => {
          setTempTask(
            tempTask.map(task => ({
              ...task,
              isTransfer: true,
            })),
          )

          if (imageId) {
            qc.invalidateQueries({ queryKey: ["workspace-detail-image"] })
          }

          qc.invalidateQueries({ queryKey: ["recipe-ongoing"] })
          qc.invalidateQueries({
            queryKey: ["infinite-workspace-creation-images"],
          })
        }, 150)
      }

      if (tempTaskV2 && tempTaskV2.length > 0) {
        setTimeout(() => {
          setTempTaskV2(
            tempTaskV2.map(task => ({
              ...task,
              isTransfer: true,
            })),
          )

          qc.invalidateQueries({ queryKey: ["workspace-detail-image", { userUid: user?.uid }] })
          qc.invalidateQueries({ queryKey: ["recipe-ongoing"] })
          qc.invalidateQueries({ queryKey: ["recipe-history"] })
        }, 150)
      }
    },
  })

  const { mutate: handleSignInSuccess, isPending: isPendingSignIn } = useMutation({
    mutationFn: async (props: {
      credentials: UserCredential
      provider: "google" | "solana" | "evm" | "email" | "farcaster" | "telegram"
    }) => {
      const { credentials: userCredentials, provider } = props

      const additionalUserInfo = getAdditionalUserInfo(userCredentials)
      const { user: data } = userCredentials

      googleAnalytics.event({
        action: "click",
        category: "sign_in",
        label: `sign_in_with_${provider}`,
        params: {
          user_id: data.uid,
          user_email: data.email,
        },
      })

      if (tempTask && tempTask.length > 0 && tempTask.some(task => !task.isTransfer)) {
        mutateTransferTask(tempTask.filter(i => !i.isTransfer).map(task => task.id))
      }

      if (tempTaskV2 && tempTaskV2.length > 0 && tempTaskV2.some(task => !task.isTransfer)) {
        mutateTransferTask(tempTaskV2.filter(i => !i.isTransfer).map(task => task.id))
      }

      mutateAccess(data)
      if (typeof router.query.next === "string") {
        const query = router.query.next.split("?")[1]

        if (query) {
          const parsed = new URLSearchParams(query)
          const referralCode = parsed.get("referralCode")

          if (referralCode) {
            googleAnalytics.event({
              action: "click",
              category: "sign_in",
              label: "sign_in_with_referral",
              params: {
                referree_uid: data.uid,
                referrer_uid: referralCode,
              },
            })

            mutateReferralCode({ refCode: referralCode })
          }
        }
      }

      // if (referralCode && additionalUserInfo?.isNewUser) {
      //   googleAnalytics.event({
      //     action: "click",
      //     category: "sign_in",
      //     label: "sign_in_with_referral",
      //     params: {
      //       referree_uid: data.uid,
      //       referrer_uid: referralCode,
      //     },
      //   })

      //   mutateReferral(data.uid)
      // }

      localStorage.removeItem("add-to-home-screen")

      onClose()
    },
  })

  return {
    handleSignInSuccess,
    isPendingSignIn,
  }
}

export default useReferralUser
