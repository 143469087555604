import client from "@/api/client"
import { ChatRoomMemberItem, ChatRoomRole, GetRoomMembersResult } from "@/api/sdk"
import { createAuthenticatedInfiniteQuery } from "@/queries/createAuthenticatedQuery"
import { enableInfiniteQueryMiddleware } from "@/queries/middlewares/enableQueryMiddleware"

export const useWorkspaceRoomMemberInfiniteQuery = createAuthenticatedInfiniteQuery<
  GetRoomMembersResult,
  { roomId: string; searchTerm?: string; take?: number; role?: ChatRoomRole },
  Error,
  ChatRoomMemberItem
>({
  primaryKey: "infinite-workspace-members-room",
  queryFn: ({ queryKey: [_primaryKey, variables], pageParam = 0 }) =>
    client.api
      .chatRoomControllerGetRoomMembers(variables.roomId, {
        skip: pageParam * (variables.take ?? 50),
        searchTerm: variables.searchTerm ? variables.searchTerm : undefined,
        take: variables.take ?? 50,
        role: variables.role,
      })
      .then(res => res.data),
  getNextPageParam: (lastPage, pages) => (lastPage && lastPage.roomMembers.length < 50 ? undefined : pages.length),
  initialPageParam: 0,
  flattenFn: data => data.roomMembers,
  use: [enableInfiniteQueryMiddleware(v => !!v.roomId)],
})
