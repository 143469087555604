import { IconProps } from "."

export const ArrowExpandIcon = (props: IconProps) => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M14.7109 10.3529L21.2109 3.85294M21.6121 8.3529L21.7058 4.37638C21.7172 3.8949 21.7228 3.65415 21.5752 3.50353C21.4275 3.35291 21.1857 3.35291 20.7023 3.35291H16.711"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.7109 10.353L4.2109 3.85298M8.71094 3.35291H4.71094C4.23953 3.35291 4.00383 3.35291 3.85738 3.49935C3.71094 3.6458 3.71094 3.8815 3.71094 4.35291V8.35291"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.7109 14.353L4.21092 20.853M3.71094 16.353V20.353C3.71094 20.8244 3.71094 21.0601 3.85738 21.2065C4.00383 21.353 4.23953 21.353 4.71094 21.353H8.71094"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.7109 14.353L21.2109 20.853M16.711 21.353H20.711C21.1824 21.353 21.4181 21.353 21.5645 21.2065C21.711 21.0601 21.711 20.8244 21.711 20.353V16.353"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
