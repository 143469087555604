import { cn } from "@/lib/utils"
import React, { forwardRef } from "react"

interface WorkspaceContainerProps {
  children: React.ReactNode
  className?: string
}

const WorkspaceContainer = forwardRef<HTMLDivElement, WorkspaceContainerProps>(({ children, className }, ref) => {
  return (
    <div
      ref={ref}
      className={cn("flex flex-col max-w-7xl 3xl:max-w-[100rem] 4xl:max-w-[120rem] mx-auto w-full relative", className)}
    >
      {children}
    </div>
  )
})

export default WorkspaceContainer
