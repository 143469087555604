import client from "@/api/client"
import { actionMiddleware } from "@/queries/middlewares/actionMiddleware"
import { createMutation } from "react-query-kit"
import { useQuestInfiniteQuery } from "./useQuestInfiniteQuery"
import { AddReferralV2Dto } from "@/api/sdk"

export const useReferralCodeMutation = createMutation({
  mutationFn: async (data: AddReferralV2Dto) => {
    return await client.api.referralControllerAddReferralV2(data).then(res => res.data)
  },
  use: [
    actionMiddleware({
      onSuccess: ({ queryClient, variables }) => {
        const listKey = useQuestInfiniteQuery.getKey()

        queryClient.invalidateQueries({ queryKey: listKey })
      },
    }),
  ],
})
