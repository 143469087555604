import { ArticleCategory, PickTypeClass } from "@/api/sdk"
import IconButton from "@/components/IconButton"
import Input from "@/components/Input"
import Toggle from "@/components/Toggle"
import { SearchIcon, XIcon } from "@/components/shared/icons/"
import { useDebounce } from "@/hooks"
import { googleAnalytics } from "@/lib/gtag"
import { useAuth } from "@/providers/authContext"
import { useSearchTagInfiniteQuery } from "@/queries"
import { capitalize } from "@/utils"
import classNames from "classnames"
import useCustomRouter from "@/hooks/useCustomRouter"
import React, { useMemo, useRef, useState } from "react"
import { Radio } from "@/components/Alpha/View/GalleryFilter"

interface UserSearchProps {
  type?: "macros" | "styles" | "recipes" | "comfyui" | "articles" | "images"
  disabledFilterCategory?: boolean
  tempFilter?: {
    sortBy?: string
    tags?: PickTypeClass[]
    category?: string[]
    createdBy?: string
    bookmarked?: boolean
  }
  setTempFilter?: React.Dispatch<
    React.SetStateAction<{
      sortBy?: string
      tags?: PickTypeClass[]
      category?: string[]
      bookmarked?: boolean
      createdBy?: string
    }>
  >
}

const TagSearch = ({ tempFilter, setTempFilter, disabledFilterCategory, type }: UserSearchProps) => {
  const [search, setSearch] = useState("")
  const debouncedSearch = useDebounce(search, 350)
  const { user } = useAuth()
  const router = useCustomRouter()

  const { data, fetchNextPage, hasNextPage, isFetching } = useSearchTagInfiniteQuery({
    variables: {
      searchQuery: debouncedSearch,
      isDiscoverable: true,
      isInUse: true,
    },
  })

  const mappedTags = useMemo(() => data?.pages?.flatMap(i => i.tags) ?? [], [data])

  const inputRef = useRef<HTMLInputElement>(null)

  const mappedAllFilterSearch = useMemo(() => {
    return debouncedSearch
      ? mappedTags
      : [...(tempFilter?.tags ?? []), ...mappedTags.filter(i => !tempFilter?.tags?.map(i => i.id).includes(i.id))]
  }, [mappedTags, debouncedSearch, tempFilter?.tags])

  const mappedCategory = useMemo(() => {
    if (type === "articles") {
      return Object.values(ArticleCategory)
    }

    const recipeCategory = type === "recipes" ? ["By GAIA", "By Community"] : []

    return [...recipeCategory]
  }, [type])

  const mappedCreatedBy = useMemo(() => {
    if (type === "articles") return []

    if (user) {
      return ["All", "Me", "Workspace"]
    }

    return []
  }, [user, type])

  return (
    <div className="w-full text-sm">
      {user && type === "articles" && tempFilter?.bookmarked !== undefined && (
        <div className="flex items-center mb-4">
          <h3 className="flex-1 text-xs font-semibold">Bookmarked Articles</h3>
          <Toggle
            sizeToggle="sm"
            name={"Bookmarked"}
            checked={tempFilter.bookmarked}
            onChange={e => {
              e.stopPropagation()

              setTempFilter?.(prev => ({ ...prev, bookmarked: !tempFilter.bookmarked }))

              googleAnalytics.handleCategoryEvent({
                action: "click",
                params: {
                  action: "Bookmarked",
                  tab_name: router.pathname.split("/")[2],
                },
              })
            }}
          />
        </div>
      )}
      <div
        className={classNames("mb-4", {
          hidden: mappedCreatedBy.length === 0 || disabledFilterCategory,
        })}
      >
        <div className="flex items-center">
          <h3 className="flex-1 mb-2 font-semibold">Created by</h3>
        </div>
        <div className="space-y-2 text-sm">
          {mappedCreatedBy.map(c => (
            <Radio
              key={c}
              value={c}
              checked={tempFilter?.createdBy === c}
              onChange={() => {
                googleAnalytics.handleCategoryEvent({
                  action: "click",
                  params: {
                    action: "Sort by",
                    tab_name: router.pathname.split("/")[2],
                    created_by: c,
                  },
                })
                setTempFilter?.(prev => ({ ...prev, createdBy: c }))
              }}
              name={c}
            />
          ))}
        </div>
        <div className="mt-4">
          <div className="border-b border-atherGray-800" />
        </div>
      </div>
      <div
        className={classNames("mb-4", {
          hidden: mappedCategory.length === 0 || disabledFilterCategory,
        })}
      >
        <div className="flex items-center">
          <h3 className="flex-1 mb-2 font-semibold">Categories</h3>
          <p
            onClick={() => {
              setTempFilter?.(prev => ({ ...prev, category: undefined }))

              googleAnalytics.handleCategoryEvent({
                action: "click",
                params: {
                  action: "Clear Filter",
                  tab_name: router.pathname.split("/")[2],
                },
              })
            }}
            className={classNames("cursor-pointer text-xs text-red-500 font-semibold", {
              hidden: !tempFilter?.category || tempFilter.category?.length === 0,
            })}
          >
            Clear
          </p>
        </div>
        <div className="flex flex-wrap -ml-1">
          {mappedCategory.map(c => (
            <div
              key={c}
              className="flex items-center m-1 cursor-pointer"
              onClick={() => {
                googleAnalytics.handleCategoryEvent({
                  action: "click",
                  params: {
                    action: "Filter by",
                    tab_name: router.pathname.split("/")[2],
                    category: c,
                  },
                })

                if (tempFilter?.category?.includes(c)) {
                  setTempFilter?.(prev => ({
                    ...prev,
                    category: prev.category?.filter(i => i !== c),
                  }))

                  return
                }

                setTempFilter?.(prev => ({
                  ...prev,
                  category: prev.category ? [...prev.category, c] : [c],
                }))
              }}
            >
              <IconButton
                title={c}
                colorScheme="secondary"
                className={classNames(
                  "text-xs bg-atherGray-900 min-h-0 border-atherGray-800 border rounded-2xl py-0.5 px-2 font-normal text-atherGray-300",
                  {
                    "bg-atherPurple-500 hover:bg-atherPurple-500": tempFilter?.category?.includes(c),
                    // "opacity-50 bg-atherPurple-500 hover:opacity-100 hover:bg-atherPurple-500":
                    //   type === "articles" && !tempFilter?.category?.includes(c),
                  },
                )}
              >
                {capitalize(c.toLowerCase())}
              </IconButton>
            </div>
          ))}
        </div>
        <div className="mt-4">
          <div className="border-b border-atherGray-800" />
        </div>
      </div>
      <div className={classNames("mb-2")}>
        <div className="">
          <div className="flex items-center justify-between mb-2">
            <h3 className="font-semibold text-atherGray-0">All Tags</h3>
            <p
              onClick={() => {
                setTempFilter?.(prev => ({ ...prev, tags: undefined }))

                googleAnalytics.handleCategoryEvent({
                  action: "click",
                  params: {
                    action: "Clear Filter",
                    tab_name: router.pathname.split("/")[2],
                  },
                })
              }}
              className={classNames("cursor-pointer text-xs text-red-500 font-semibold", {
                hidden: !tempFilter?.tags?.length,
              })}
            >
              Clear
            </p>
          </div>
          <div className="h-10 mb-3 md:relative rounded-2xl w-full">
            <Input
              ref={inputRef}
              className={classNames("bg-atherGray-850")}
              value={search}
              onChange={e => setSearch(e.target.value)}
              placeholder="Search tag"
              rightIcon={
                <span
                  className={classNames("text-atherGray-300", {
                    "cursor-pointer": search.length > 0,
                  })}
                >
                  {search.length > 0 ? <XIcon onClick={() => setSearch("")} /> : <SearchIcon />}
                </span>
              }
            />
          </div>
        </div>
      </div>

      <div className="overflow-y-auto -ml-1 flex flex-wrap overflow-x-hidden max-h-[14rem] lg:max-h-[16rem] 2xl:max-h-[20rem]">
        {mappedAllFilterSearch.map(tag => (
          <div
            key={tag.name}
            className="flex items-center m-0.5 cursor-pointer"
            onClick={() => {
              if (tempFilter?.tags?.map(i => i.id).includes(tag.id)) {
                setTempFilter?.(prev => ({
                  ...prev,
                  tags: prev.tags?.filter(i => i.id !== tag.id),
                }))

                return
              }

              googleAnalytics.handleCategoryEvent({
                action: "click",
                params: {
                  action: "Filter Tags",
                  tab_name: router.pathname.split("/")[2],
                  tag: tag.name,
                  tag_id: tag.id,
                },
              })

              setTempFilter?.(prev => ({
                ...prev,
                tags: prev.tags ? [...prev.tags, tag] : [tag],
              }))
            }}
          >
            <IconButton
              colorScheme="secondary"
              title={tag.name}
              className={classNames(
                "text-xs bg-atherGray-900 min-h-0 border-atherGray-800 border rounded-2xl py-0.5 px-2 font-normal",
                {
                  "bg-atherPurple-500 hover:bg-atherPurple-500 text-atherGray-0": tempFilter?.tags
                    ?.map(i => i.id)
                    .includes(tag.id),
                  "text-atherGray-300": !tempFilter?.tags?.map(i => i.id).includes(tag.id),
                },
              )}
            >
              {capitalize(tag.name.toLowerCase())}
            </IconButton>
          </div>
        ))}
        {hasNextPage &&
          (isFetching ? (
            <div className="w-full p-2 text-center text-atherGray-400">Loading more...</div>
          ) : (
            <button
              onClick={() => {
                googleAnalytics.handleCategoryEvent({
                  action: "click",
                  params: {
                    action: "Load More Tags",
                    tab_name: router.pathname.split("/")[2],
                  },
                })

                fetchNextPage()
              }}
              className="w-full pt-2 text-center text-atherGray-300"
            >
              Load More
            </button>
          ))}
      </div>
    </div>
  )
}

export default TagSearch
