import React from "react"
import UserCreated from "../Gallery/UserCreated"
import { css } from "@emotion/css"
import { EntityType, ExploreSDStyleDetail, SDStyleDetail } from "@/api/sdk"
import { motion } from "framer-motion"
import StyleActionButtons from "./StyleActionButtons"
import TabStyle from "./TabStyle"

interface StyleDetailInfoProps {
  style: (ExploreSDStyleDetail & { createdAt?: string }) | SDStyleDetail
  typeComment: EntityType
  onClose: () => void
  onTagFilter?: (tag: number) => void
}

const StyleDetailInfo = ({ style, onClose, onTagFilter, typeComment }: StyleDetailInfoProps) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3, delay: 0.45 }}
      className="w-full lg:w-[40rem] md:border-l border-atherGray-800 flex flex-col overflow-hidden px-4 pt-4 pb-2"
    >
      <div className="flex flex-col flex-1 overflow-hidden">
        <div
          className={css({
            flex: 1,
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
            position: "relative",
          })}
        >
          <div className="flex flex-col flex-1 overflow-hidden">
            <div className="flex items-center mb-4">
              <StyleActionButtons typeComment={typeComment} style={style} />
            </div>
            <div className="mb-6 overflow-hidden">
              <div className="overflow-hidden flex-1 mb-2">
                <p className="truncate w-full text-base md:text-xl font-semibold">Style: {style.name}</p>
              </div>
              <UserCreated
                username={style.creator.username}
                name={style.creator.name}
                picture={style.creator.picture}
                createdAt={style.discoverableAt ?? style.createdAt}
                size="sm"
              />
            </div>
            <TabStyle onTagFilter={onTagFilter} onClose={onClose} style={style} typeComment={typeComment} />
          </div>
        </div>
      </div>
    </motion.div>
  )
}

export default StyleDetailInfo
