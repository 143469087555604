import { create } from "zustand"
import { devtools, persist } from "zustand/middleware"

type TaskType = "macro" | "recipe" | "style" | "comfyui"

interface TempTaskStore {
  tempTask:
    | {
        id: string
        type: TaskType
        isTransfer?: boolean
      }[]
    | null
  setTempTask: (tempTask: { id: string; type: TaskType }[]) => void
}

export const useTempTaskStoreV2 = create<TempTaskStore>()(
  devtools(
    persist(
      set => ({
        tempTask: null,
        setTempTask: tempTask =>
          set(() => {
            return { tempTask }
          }),
      }),
      {
        name: "temp-task-storage-v2",
      },
    ),
  ),
)
