import { WorkspaceDetail } from "@/api/sdk"
import { useAuth } from "@/providers/authContext"
import { useUserWorkspaceDetailQuery } from "@/queries"
import { createContext, useContext } from "react"
import { QueryHookResult } from "react-query-kit"

export type WorkspaceContext = {
  currentWorkspaceQuery: QueryHookResult<WorkspaceDetail, Error>
}

const WorkspaceContext = createContext<WorkspaceContext | null>(null)
export const useWorkspaceContext = () => {
  const context = useContext(WorkspaceContext)
  if (!context) throw new Error("missing WorkspaceContext")
  return context
}

export function WorkspaceProvider({ children }: { children: React.ReactNode }) {
  const { userInfoQuery } = useAuth()

  const currentWorkspaceQuery = useUserWorkspaceDetailQuery({
    variables: {
      workspaceId: userInfoQuery?.data?.currentWorkspace.id ?? "",
    },
  })

  return (
    <WorkspaceContext.Provider
      value={{
        currentWorkspaceQuery,
      }}
    >
      {children}
    </WorkspaceContext.Provider>
  )
}
