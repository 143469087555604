import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { CLEAR_HISTORY_COMMAND, EditorState, LexicalEditor } from "lexical"
import { useCallback, useEffect, useRef } from "react"
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin"
import { autoSelection, clearEditorState } from "./utils/mention"

export const checkJSON = (str: string) => {
  try {
    const result = JSON.parse(str)
    return typeof result === "object"
  } catch (e) {
    return false
  }
}

const DefaultInitValuePlugin = ({
  initValue,
  onChange,
  triggerResetInitValue,
  autoFocus = true,
  setTriggerResetInitValue,
}: {
  initValue?: string
  autoFocus?: boolean
  onChange: (editorState: EditorState, editor: LexicalEditor, tags: Set<string>) => void
  triggerResetInitValue?: boolean
  setTriggerResetInitValue?: (value: boolean) => void
}) => {
  const [editor] = useLexicalComposerContext()
  const isFirstRender = useRef(true)

  const updateDefaultInitValue = useCallback(() => {
    if (!initValue) return

    editor.update(() => {
      isFirstRender.current = false

      clearEditorState()

      if (!checkJSON(initValue)) {
        const formatInitValue = `{"root":{"children":[{"children":[{"detail":0,"format":0,"mode":"normal","style":"","text":"${initValue}","type":"text","version":1}],"direction":"ltr","format":"","indent":0,"type":"paragraph","version":1}],"direction":"ltr","format":"","indent":0,"type":"root","version":1}}`
        const editorState = editor.parseEditorState(formatInitValue)

        editor.setEditorState(editorState)
        editor.dispatchCommand(CLEAR_HISTORY_COMMAND, undefined)
        setTriggerResetInitValue?.(false)
        autoSelection(autoFocus)
        return
      }

      const editorState = editor.parseEditorState(initValue)

      editor.setEditorState(editorState)
      editor.dispatchCommand(CLEAR_HISTORY_COMMAND, undefined)
      setTriggerResetInitValue?.(false)
      autoSelection(autoFocus)
    })
  }, [editor, initValue, autoFocus, setTriggerResetInitValue])

  useEffect(() => {
    if (!isFirstRender.current) return
    updateDefaultInitValue()
  }, [updateDefaultInitValue])

  useEffect(() => {
    if (triggerResetInitValue && !isFirstRender.current) {
      updateDefaultInitValue()
    }
  }, [triggerResetInitValue, updateDefaultInitValue])

  return <OnChangePlugin onChange={onChange} />
}

export default DefaultInitValuePlugin
