import { IconProps } from "."

export const UserSettingIcon = (props: IconProps) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_3458_36735)">
        <path
          d="M9.58464 11.6767C7.87912 11.5973 6.15409 12.0056 4.64928 12.9016C3.47031 13.6037 0.379106 15.0371 2.26185 16.8308C3.18156 17.707 4.20588 18.3337 5.49369 18.3337H10.0013"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.918 5.41699C12.918 7.48806 11.239 9.16699 9.16797 9.16699C7.0969 9.16699 5.41797 7.48806 5.41797 5.41699C5.41797 3.34592 7.0969 1.66699 9.16797 1.66699C11.239 1.66699 12.918 3.34592 12.918 5.41699Z"
          stroke="currentColor"
          strokeWidth="1.5"
        />
        <path
          d="M15.0013 17.2626V18.334M15.0013 17.2626C14.0373 17.2626 13.188 16.7891 12.6899 16.0701M15.0013 17.2626C15.9653 17.2626 16.8146 16.7891 17.3127 16.0701M15.0013 11.9054C15.9654 11.9054 16.8147 12.379 17.3128 13.098M15.0013 11.9054C14.0372 11.9054 13.1879 12.379 12.6898 13.098M15.0013 11.9054V10.834M18.3346 12.4411L17.3128 13.098M11.6683 16.7268L12.6899 16.0701M11.668 12.4411L12.6898 13.098M18.3343 16.7268L17.3127 16.0701M17.3128 13.098C17.6074 13.5232 17.7791 14.0343 17.7791 14.584C17.7791 15.1338 17.6073 15.6449 17.3127 16.0701M12.6898 13.098C12.3952 13.5232 12.2235 14.0343 12.2235 14.584C12.2235 15.1338 12.3953 15.6449 12.6899 16.0701"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3458_36735">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
