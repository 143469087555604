import classNames from "classnames"
import { CancelCircleIcon, CheckCircleIcon, WarningIcon } from "./shared/icons"

const Toast = ({
  status = "success",
  title,
  message,
  containerStyle,
}: {
  status?: "success" | "error" | "warning"
  message?: string[]
  title: string
  containerStyle?: string
}) => {
  const renderIcon = () => {
    if (status === "error") {
      return <CancelCircleIcon className="text-red-400" width={20} height={20} />
    }

    if (status === "warning") {
      return <WarningIcon className="text-yellow-400" width={20} height={20} />
    }

    return <CheckCircleIcon className="text-atherSuccess-100" width={20} height={20} />
  }

  return (
    <div className={classNames("w-full md:max-w-[400px]", containerStyle)}>
      <div
        className={classNames("absolute left-0 top-0 h-full w-[4px]", {
          "bg-atherSuccess-100": status === "success",
          "bg-red-400": status === "error",
          "bg-yellow-400": status === "warning",
        })}
      />

      <div className="flex items-center">
        <div className="mr-1">{renderIcon()}</div>
        <p
          className={classNames("font-semibold text-xs md:text-sm", {
            "text-atherSuccess-100": status === "success",
            "text-red-400": status === "error",
            "text-yellow-400": status === "warning",
          })}
        >
          {title}
        </p>
      </div>
      <div className="mt-1">
        {message?.map((m, index) => (
          <p key={index} className="text-xs text-whiteAlpha-800 md:text-sm">
            {m}
          </p>
        ))}
      </div>
    </div>
  )
}

export default Toast
