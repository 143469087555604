import client from "@/api/client"
import { actionMiddleware } from "@/queries/middlewares/actionMiddleware"
import { createMutation } from "react-query-kit"
import { ArticleDetail, GetArticlesResult } from "@/api/sdk"
import { useArticleDetailQuery } from "@/components/Explore/ArticleDetail/ArticleDetailProvider"
import { useExploreArticlesInfiniteQuery } from "./useExploreArticlesInfiniteQuery"
import { InfiniteData } from "@tanstack/react-query"

export const useExploreBookmarkArticleMutation = createMutation({
  mutationFn: async ({ id }: { id: string; userUid?: string }) => {
    return await client.api.articleControllerToggleFavoriteArticle(id).then(res => res.data)
  },
  use: [
    actionMiddleware({
      onMutate: ({ queryClient, variables }) => {
        const articleExploreKey = useArticleDetailQuery.getKey({
          articleId: variables.id,
          userUid: variables.userUid,
        })

        const articleExploreData = queryClient.getQueryData<ArticleDetail>(articleExploreKey)

        if (articleExploreData) {
          queryClient.setQueryData(articleExploreKey, {
            ...articleExploreData,
            favoritedByUser: !articleExploreData.favoritedByUser,
            metric: {
              ...articleExploreData.metric,
              favoriteCount: articleExploreData.favoritedByUser
                ? articleExploreData.metric?.favoriteCount - 1
                : articleExploreData.metric?.favoriteCount + 1,
            },
          })
        }

        const articlesInfiniteKey = useExploreArticlesInfiniteQuery.getKey()

        const exploreArticlesQueriesDataEntries = queryClient.getQueriesData<InfiniteData<GetArticlesResult, number>>({
          queryKey: articlesInfiniteKey,
        })

        if (exploreArticlesQueriesDataEntries) {
          exploreArticlesQueriesDataEntries.forEach(([key, data]) => {
            if (data) {
              const updatedData = {
                ...data,
                pages: data.pages.map(page => {
                  return {
                    ...page,
                    articles: page.articles.map(article => {
                      if (article.id === variables.id) {
                        return {
                          ...article,
                          favoritedByUser: !article.favoritedByUser,
                          metric: {
                            ...article.metric,
                            favoriteCount: article.favoritedByUser
                              ? article.metric?.favoriteCount - 1
                              : article.metric?.favoriteCount + 1,
                          },
                        }
                      }
                      return article
                    }),
                  }
                }),
              }
              queryClient.setQueryData(key, updatedData)
            }
          })
        }
      },
      onError: ({ queryClient, variables }) => {},
    }),
  ],
})
