import { IconProps } from "."

export const ImageRemoveIcon = (props: IconProps) => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M11.8334 3C7.35503 3 5.11586 3 3.72462 4.39124C2.33337 5.78249 2.33337 8.02166 2.33337 12.5C2.33337 16.9783 2.33337 19.2175 3.72462 20.6088C5.11586 22 7.35503 22 11.8334 22C16.3117 22 18.5509 22 19.9421 20.6088C21.3334 19.2175 21.3334 16.9783 21.3334 12.5C21.3334 12.1715 21.3334 11.855 21.3328 11.55"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path d="M5.33337 21C9.54336 16.2487 14.2746 9.9475 21.3334 14.6734" stroke="currentColor" strokeWidth="1.5" />
      <path d="M22.3334 6H14.3334" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  )
}
