import client from "@/api/client"
import { CommentsOrderOption, EntityType, GetCommentsResult } from "@/api/sdk"
import { useAuthenticatedQuery, useToast } from "@/hooks"
import useCustomRouter from "@/hooks/useCustomRouter"
import { useEffect, useRef } from "react"
import { useCommentDetailQuery } from "@/queries"

const useComment = (entityId: string, typeComment: EntityType) => {
  const toast = useToast()
  const router = useCustomRouter()
  const flagValue = useRef(true)
  const { notificationId } = router.query

  useEffect(() => {
    if (entityId && !notificationId) {
      updateParentParam(0)
      updateChildParam(undefined)
    }
  }, [entityId, notificationId])

  // New code:
  const {
    data: dataIndex,
    isFetched: isFetchedGetIndex,
    isSuccess: isSuccessGetIndex,
  } = useAuthenticatedQuery(
    ["get-comment-index", notificationId, typeComment],
    async () => {
      return client.api
        .commentControllerGetCommentIndexes({
          entityId: entityId,
          entityType: typeComment,
          notificationId: notificationId as string,
          order: CommentsOrderOption.ASC,
        })
        .then(res => res.data)
    },
    {
      enabled: !!notificationId && !!entityId,
      staleTime: Infinity,
    },
  )

  useEffect(() => {
    if (isSuccessGetIndex && dataIndex) {
      updateParentParam(dataIndex.indexParent)
      updateChildParam(dataIndex.indexChild)
    }
  }, [isSuccessGetIndex])

  const parentTakePage = 10
  const childTakePage = 5
  const parentTotalPage = useRef(999)
  const childTotalPage = useRef(999)
  const isCommentFromNoti = flagValue.current && !!dataIndex

  const baseParentParam = dataIndex?.indexParent ? Math.floor(dataIndex?.indexParent / parentTakePage) : null
  const prevBaseParentParam = useRef<number | null>(null)
  const nextBaseParentParam = useRef<number | null>(null)

  const baseChildParam = dataIndex?.indexChild ? Math.floor(dataIndex?.indexChild / childTakePage) : null
  const prevBaseChildParam = useRef<number | null>(null)
  const nextBaseChildParam = useRef<number | null>(null)

  const updateParentParam = (base: number) => {
    const calBase = Math.floor(base / parentTakePage)
    prevBaseParentParam.current = Math.max(-1, calBase - 1)
    nextBaseParentParam.current = Math.min(parentTotalPage.current, calBase + 1)
  }
  const updateChildParam = (base?: number) => {
    if (!base) return
    const calBase = Math.floor(base / childTakePage)
    prevBaseChildParam.current = Math.max(-1, calBase - 1)
    nextBaseChildParam.current = Math.min(childTotalPage.current, calBase + 1)
  }

  // ===========================

  const primaryKey =
    typeComment === EntityType.ARTICLE
      ? "infinite-comment-articles"
      : typeComment === EntityType.INSPIRATION_IMAGE
        ? "infinite-explore-comment-images"
        : typeComment === EntityType.IMAGE
          ? "infinite-comment-images"
          : typeComment === EntityType.EXPLORE_STYLE
            ? "infinite-explore-comment-styles"
            : typeComment === EntityType.EXPLORE_WORKFLOW
              ? "infinite-explore-comment-workflows"
              : typeComment === EntityType.EXPLORE_RECIPE
                ? "infinite-explore-comment-recipes"
                : typeComment === EntityType.EXPLORE_SD_WORKFLOW
                  ? "infinite-explore-comment-sd-workflows"
                  : typeComment === EntityType.STYLE
                    ? "infinite-comment-styles"
                    : typeComment === EntityType.WORKFLOW
                      ? "infinite-comment-workflows"
                      : typeComment === EntityType.SD_WORKFLOW
                        ? "infinite-comment-sd-workflows"
                        : typeComment === EntityType.SD_MODEL
                          ? "infinite-comment-sd-models"
                          : "infinite-comment"

  const hook = useCommentDetailQuery({
    primaryKey,
    getNextPageParam: (lastPage, allPages) => {
      if (isCommentFromNoti) {
        if (nextBaseParentParam.current !== null && nextBaseParentParam.current < parentTotalPage.current) {
          return nextBaseParentParam.current
        }
        return undefined
      } else {
        if (lastPage.comments.length < parentTakePage) {
          return undefined
        }
        return allPages.length
      }
    },
    getPreviousPageParam: () => {
      if (isCommentFromNoti) {
        if (prevBaseParentParam.current !== null && prevBaseParentParam.current >= 0) return prevBaseParentParam.current
        return undefined
      } else {
        return undefined
      }
    },
  })

  const queryCommentsImageKey = hook.getKey({
    entityId: entityId,
    typeComment,
    parentTakePage,
    pageParam: flagValue.current ? baseParentParam ?? 0 : 0,
    notificationId: notificationId as string | undefined,
  })

  const {
    data: comments,
    hasNextPage,
    hasPreviousPage,
    fetchNextPage,
    fetchPreviousPage,
    isLoading,
    isSuccess: isCommentsSuccess,
    refetch: refetchComments,
    isFetchingNextPage,
    isFetchingPreviousPage,
    isError,
  } = hook({
    variables: {
      entityId: entityId,
      typeComment,
      parentTakePage,
      pageParam: flagValue.current ? baseParentParam ?? 0 : 0,
      notificationId: notificationId as string | undefined,
    },
    enabled: !!entityId,
  })

  useEffect(() => {
    if (isCommentsSuccess && comments) {
      const lastPage = comments.pages[comments.pages.length - 1]
      if (lastPage) {
        const cal = Math.ceil((lastPage.totalParentComment ?? 0) / parentTakePage)

        parentTotalPage.current = cal
      }
    }
  }, [isCommentsSuccess, comments])

  useEffect(() => {
    if (isError && notificationId) {
      toast({
        status: "error",
        title: "This comment has been deleted or replaced",
      })
    }
  }, [isError])

  const mappedComments = comments?.pages?.reduce(
    (acc: GetCommentsResult, curr) => ({
      total: curr.total,
      totalParentComment: curr.totalParentComment,
      comments: [...acc.comments, ...curr.comments],
    }),
    { comments: [], total: 0, totalParentComment: 0 },
  )

  const resetParams = () => {
    flagValue.current = false
  }

  return {
    states: {
      hasNextPage,
      hasPreviousPage,
      isLoading,
      isFetchingNextPage,
      isFetchingPreviousPage,
      isCommentsSuccess,
      isCommentFromNoti,
    },
    functions: {
      fetchNextPage,
      fetchPreviousPage,
      refetchComments,
      updateParentParam,
      resetParams,
    },
    contants: {
      parentTotalPage,
      childTotalPage,
      childTakePage,
      baseChildParam,
      queryCommentsImageKey,
    },
    data: {
      mappedComments,
    },
    paginations: {
      nextBaseChildParam,
      prevBaseChildParam,
      nextBaseParentParam,
      prevBaseParentParam,
    },
  }
}

export default useComment
