import { IconProps } from "."

export const FileImportIcon = (props: IconProps) => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M15.5 16.5C14.9943 16.9915 13 18.2998 13 19M15.5 21.5C14.9943 21.0085 13 19.7002 13 19M13 19L21 19"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 22.5H10.7273C7.46607 22.5 5.83546 22.5 4.70307 21.7022C4.37862 21.4736 4.09058 21.2025 3.8477 20.8971C3 19.8313 3 18.2966 3 15.2273V12.6818C3 9.71865 3 8.23706 3.46894 7.05375C4.22281 5.15142 5.81714 3.65088 7.83836 2.94135C9.09563 2.5 10.6698 2.5 13.8182 2.5C15.6173 2.5 16.5168 2.5 17.2352 2.7522C18.3902 3.15765 19.3012 4.0151 19.732 5.10214C20 5.77832 20 6.62494 20 8.31818V13.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 12.5C3 10.6591 4.49238 9.16667 6.33333 9.16667C6.99912 9.16667 7.78404 9.28333 8.43137 9.10988C9.00652 8.95576 9.45576 8.50652 9.60988 7.93136C9.78333 7.28404 9.66667 6.49912 9.66667 5.83333C9.66667 3.99238 11.1591 2.5 13 2.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
