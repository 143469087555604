import { create } from "zustand"

interface CreateFolderV2Store {
  parentFolder: { id: string } | null
  setParentFolder: (parentFolder: { id: string } | null) => void
}

export const useCreateFolderV2Store = create<CreateFolderV2Store>(set => ({
  parentFolder: null,
  setParentFolder: parentFolder => set({ parentFolder }),
}))
