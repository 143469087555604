import { IconProps } from "."

export const LinkIcon = (props: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none" {...props}>
      <path d="M5.33398 8.66634L8.66732 5.33301" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
      <path
        d="M10.2309 8.7397L11.9706 7C13.3431 5.62742 13.3431 3.40202 11.9706 2.02944C10.598 0.656854 8.37258 0.656854 7 2.02944L5.2603 3.76913M8.7397 10.2309L7 11.9706C5.62742 13.3431 3.40202 13.3431 2.02944 11.9706C0.656854 10.598 0.656854 8.37258 2.02944 7L3.76913 5.2603"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  )
}
