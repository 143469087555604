import { createMutation } from "react-query-kit"
import { UploadProgress } from "@/stores/useUploadProgressStore"
import { multipartUpload } from "./useUploadFileMutation"
import type { Image } from "@/api/sdk"
import client from "@/api/client"

export const useUploadImageMutation = createMutation({
  mutationFn: async ({
    folderId,
    files,
    callback,
  }: {
    folderId?: string
    files: File[]
    callback: (p: UploadProgress) => void
  }) => {
    return multipartUpload<Image>({
      files,
      onProgress: callback,
      async onInitiateUpload(infos) {
        return client.api
          .imageControllerInitializeMultipartUpload({
            folderId: folderId || undefined,
            files: infos,
          })
          .then(res => res.data)
      },
      async onCompleteUpload(request) {
        await client.api.imageControllerCompleteMultipartUpload([request])
      },
      async onAbortUpload(request) {
        await client.api.imageControllerAbortMultipartUpload([request])
      },
    })
  },
})
