import UserCreated from "@/components/Explore/Gallery/UserCreated"
import LoadingIcon from "@/components/LoadingIcon"
import classNames from "classnames"

const LoadingComment = ({
  picture,
  name,
  content,
  isReplyComment = false,
}: {
  picture: string
  name: string
  content: string
  isReplyComment?: boolean
}) => {
  return (
    <div className="flex items-start w-full rounded-md opacity-60">
      <div className={classNames("flex items-center  mr-2 rounded-full", isReplyComment ? "w-10 h-10" : "w-8 h-8")}>
        <UserCreated
          picture={picture}
          name={name}
          hiddenName
          className="w-full h-full"
          imageClassName="w-full h-full"
        />
      </div>
      <div
        className={classNames(
          "h-full py-2 pl-2 pr-4 rounded-md bg-atherGray-800 w-auto",
          isReplyComment ? "min-h-[66px]" : "min-h-[64px]",
        )}
      >
        <div className="flex text-sm">
          <p>
            <span className="mr-1 font-semibold text-white avatar-name">{name} (me)</span>
            <div
              style={{
                display: "inline-block  ",
              }}
              dangerouslySetInnerHTML={{ __html: `${content}` }}
            />
          </p>
        </div>

        <div className="flex items-center w-full ">
          <LoadingIcon width={20} height={20} />
        </div>
      </div>
    </div>
  )
}

export default LoadingComment
