import { formatDistanceToNowStrict, toDate } from "date-fns"
import React, { memo } from "react"
import { twMerge } from "tailwind-merge"

interface DateDistanceToNowProps {
  date: string
  className?: string
  isShort?: boolean
}

const DateDistanceToNow = ({ date = new Date().toISOString(), className, isShort }: DateDistanceToNowProps) => {
  const formatDistanceToNow = (date: string) => {
    if (isShort) {
      if (
        formatDistanceToNowStrict(toDate(new Date(date)), {
          addSuffix: false,
        })
          .replace("seconds", "s")
          .replace("second", "s") === "0 s"
      )
        return "Now"

      return formatDistanceToNowStrict(toDate(new Date(date)), {
        addSuffix: false,
      })
        .replace("hours", "h")
        .replace("hour", "h")
        .replace("minutes", "m")
        .replace("minute", "m")
        .replace("days", "d")
        .replace("day", "d")
        .replace("months", "mo")
        .replace("month", "mo")
        .replace("years", "y")
        .replace("year", "y")
        .replace("seconds", "s")
        .replace("second", "s")
    }

    return formatDistanceToNowStrict(toDate(new Date(date)), {
      addSuffix: true,
    })
  }

  return <span className={twMerge("text-xs text-atherGray-400", className)}>{formatDistanceToNow(date)}</span>
}

export default memo(DateDistanceToNow)
