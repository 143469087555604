import { ContentModerationRating, ExploreRecipeItem } from "@/api/sdk"
import AspectRatio from "@/components/AspectRatio"
import ImageWebpComponent from "@/components/ImageWebpComponent"
import { cdnUrl } from "@/constants"
import { cn } from "@/lib/utils"
import { RoutedDialogLink } from "@/providers/RoutedDialogProvider"
import useCustomRouter from "@/hooks/useCustomRouter"
import { useInView } from "react-intersection-observer"
import UserCreated from "../UserCreated"
import RecipeReaction from "./RecipeReaction"
import { useState } from "react"
import classNames from "classnames"
import NsfwBlurImage from "@/components/NsfwBlurImage"

interface RecipeCardProps {
  recipe: ExploreRecipeItem
  href?: string
  className?: string
  typeRedirectRouter?: "push" | "replace"
  hiddenReaction?: boolean
}

const RecipeItemCard = ({ recipe, href, className, typeRedirectRouter, hiddenReaction }: RecipeCardProps) => {
  const { thumbnail, name, id, description } = recipe
  const { ref, inView } = useInView({
    threshold: 0,
    rootMargin: "800px 0px 800px 0px",
  })
  const router = useCustomRouter()
  const historyPath = router.asPath
  const [isShow, setIsShow] = useState(
    recipe.thumbnailModerationRating === ContentModerationRating.HardcoreNsfw ||
      recipe.thumbnailModerationRating === ContentModerationRating.SemiNsfw,
  )

  return (
    <div ref={ref} className={cn("group h-full cursor-pointer shrink relative recipe-item", className)}>
      <div className="w-full h-full flex flex-col">
        <RoutedDialogLink
          className="flex flex-col flex-1"
          name="recipeDetailExplore"
          type={typeRedirectRouter}
          state={{
            recipeId: recipe.id,
            filters: {
              historyPath,
            },
          }}
        >
          <div className="relative group overflow-hidden">
            <AspectRatio
              ratio={255 / 120}
              className={
                "relative select-none overflow-hidden rounded-xl transform-gpu flex items-center justify-center shadow-md shadow-blackAlpha-500"
              }
            >
              {inView && (
                <ImageWebpComponent
                  src={thumbnail}
                  alt={name}
                  className={classNames("block object-cover w-full h-full", {
                    "blur-2xl": isShow,
                  })}
                  style={{
                    objectPosition: "0% 70%",
                    objectFit: "cover",
                  }}
                />
              )}
            </AspectRatio>

            {inView &&
              (recipe.thumbnailModerationRating === ContentModerationRating.HardcoreNsfw ||
                recipe.thumbnailModerationRating === ContentModerationRating.SemiNsfw) && (
                <NsfwBlurImage
                  isShow={isShow}
                  moderationRating={recipe.thumbnailModerationRating}
                  onToggleShow={setIsShow}
                  gaEvent={{
                    tab_name: "comfyui",
                    params: {
                      action: "Toggle Image NSFW",
                      recipe_id: recipe.id,
                      recipe_name: recipe.name ?? "",
                    },
                  }}
                />
              )}

            {!hiddenReaction && inView && <RecipeReaction showHoverAttributes className="p-2 z-[1]" recipe={recipe} />}
          </div>
          <div className="p-2 flex flex-col overflow-hidden">
            <p className="font-semibold line-clamp-1 text-sm">{name}</p>
            <p className="text-atherGray-300 text-sm line-clamp-1">{description}</p>
          </div>
        </RoutedDialogLink>
        <div className="mt-auto px-2">
          <UserCreated
            size="xs"
            username={recipe.creator?.username ?? "GAIA"}
            name={recipe.creator?.name ?? "GAIA"}
            picture={recipe.creator?.picture ?? `https://${cdnUrl}/resources/default-avatar.png`}
          />
        </div>
        {inView && (
          <div className="absolute bottom-0 left-0 transition-all pointer-events-none hidden lg:block group-hover:bg-blackAlpha-400 group-hover:h-full group-hover:w-full" />
        )}
      </div>
    </div>
  )
}

export default RecipeItemCard
