import {
  ImageSimpleDetail,
  RecipeInputField,
  RecipeOutputType,
  RecipeTaskStatus,
  SDModel,
  WorkflowTagItem,
} from "@/api/sdk"
import UserCreated from "@/components/Explore/Gallery/UserCreated"
import ImageWebpComponent from "@/components/ImageWebpComponent"
import { cn } from "@/utils/cn"
import classNames from "classnames"
import { memo, useEffect, useMemo, useState } from "react"
import { RecipeTaskChainParams } from "../WorkflowsChaining"
import { getStepSizes } from "../../Recipes/utils"
import WorkflowInfo from "../WorkflowsChaining/WorkflowInfo"
import MobileDetailSheet from "./MobileDetailSheet"
import ResultImages from "./ResultImages"
import WorkflowParams from "./WorkflowParams"
import WorkflowStepHeader from "./WorkflowStepHeader"
import { MultiselectImagesProps } from "../../Recipes/RecipeDetail/ListRecipes"
import ProgressbarTask from "../../Recipes/RecipeDetail/ProgressbarTask"
import { motion } from "framer-motion"
import { TaskProgressSocketEvent } from "@/providers/ProgressingProvider"
import WorkspaceContainer from "../../WorkspaceContainer"
import TextCopy from "@/components/TextCopy"
import Link from "next/link"
import { clientUrl } from "@/constants"

export type WorkflowPublishType = {
  fullDataImages?: ImageSimpleDetail[]
  capabilities: {
    canUpdate: boolean
  }
  description?: string
  tags?: WorkflowTagItem[]
  id: string
  status: RecipeTaskStatus
  executionDuration?: number
  outputTree?: Record<string, any>
  params?:
    | {
        id: string
        recipeName: string
        recipeId: string
        params: object
        [key: string]: any
      }[]
    | Record<string, any>[]
  creator?: {
    uid: string
    username?: string
    name?: string
    picture?: string
  }
  outputText?: string
  isHtml?: boolean
  outputType?: RecipeOutputType[]
  createdAt?: string
}

interface WorkFlowDetailStepProps extends MultiselectImagesProps {
  workflow: WorkflowPublishType
  refetchHistory: () => void
  models: SDModel[]
  hiddenSelect?: boolean
  recipeId?: string
  containerWidth?: number
  isExplore?: boolean
  isDisabledInView?: boolean
  selectedStepProp?: RecipeTaskChainParams | null
  mode?: "MACRO" | "RECIPE"
  className?: string
  itemClassName?: string
  progressData?: TaskProgressSocketEvent | null
}

export const ImageLoadedComponent = ({ url, className }: { url: string; className?: string }) => {
  return (
    <div
      className={cn(
        "w-full bg-atherGray-950 rounded-2xl transform-gpu h-[19rem] overflow-hidden flex items-center justify-center border border-[rgba(255,255,255,0)] shadow-sm shadow-whiteAlpha-200",
        className,
      )}
    >
      <ImageWebpComponent src={url} className="w-full h-full object-contain object-center" />
    </div>
  )
}

export const formatInputKey = (key: string, chain?: RecipeTaskChainParams & { id?: string }): string => {
  const exceptions = {
    image: "inputImage",
    model: "trainingModel",
    model_hash: "trainingModel",
    modelHash: "trainingModel",
    batch_size: "imagesNo.",
    batchSize: "magesNo.",
    lora: "lora",
    seedModeFixed: "seedMode",
    seed: "seedNo.",
    shapeReferenceImage: "shapeRef.Image",
    shapePrecisionLevel: "shapePrecisionLv.",
    creativeIntensity: "creativeIntensity",
    denosingStrength: "creativeIntensity",
    referenceImage: "ref.Image",
    referenceIntensity: "ref.Intensity",
    steps: "samplingSteps",
    styleName: "Style",
  }

  // convert camelCase and snake_case to normal text
  return (exceptions[key] ?? chain?.recipeInputStep?.find(i => i.key === key)?.name ?? key)
    .replace(/([A-Z])/g, " $1")
    .replace(/_/g, " ")
    .toLowerCase()
    .replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
}

type TaskStepsType = {
  fullDataImages: ImageSimpleDetail[]
  recipeId: string
  params: Record<string, any>
  folderId?: string | undefined
  name?: string | undefined
  status?: RecipeTaskStatus | undefined
  basedRecipeTaskId?: string | undefined
  recipeName: string
  recipeInputStep?: RecipeInputField[] | undefined
  id?: string | undefined
}[]

export const mappedTaskSteps = (
  params: RecipeTaskChainParams[],
  fullDataImages: ImageSimpleDetail[],
  outputTree?: Record<
    string,
    {
      texts?: string[]
      images?: string[]
      recipeId: string
    }
  >,
): TaskStepsType => {
  const newFullDataImages = [...(fullDataImages ?? [])].sort((a, b) =>
    new Date(a.createdAt).getTime() < new Date(b.createdAt).getTime() ? -1 : 1,
  )

  let resultSteps: TaskStepsType = []

  if (outputTree) {
    resultSteps = Object.keys(outputTree).map((step, index) => {
      const output = outputTree[step]

      const images =
        output.images?.map((id, i) => {
          const image = newFullDataImages.find(i => i.id === id)

          return {
            id,
            ...image,
          }
        }) ?? []

      const texts =
        output.texts?.map((text, i) => {
          return {
            id: `${step}-${i}`,
            outputText: text,
          }
        }) ?? []

      return {
        id: step,
        fullDataImages: [...images, ...texts] as ImageSimpleDetail[],
        ...params[index],
      }
    })
  }

  const stepSizes = getStepSizes(params)

  let totalSize = 0

  const mappedSteps = stepSizes.map((stepSize, step) => {
    const result = {
      ...params[step],
      fullDataImages:
        stepSizes.length <= 1 ? newFullDataImages : newFullDataImages.slice(totalSize, totalSize + stepSize),
    }
    totalSize += stepSize

    return result
  })

  return [
    ...resultSteps,
    ...mappedSteps.slice(resultSteps?.length ?? 0).map((step, index) => {
      return {
        id: step.id ?? `step-${index}`,
        fullDataImages: step.fullDataImages,
        recipeId: step.recipeId,
        params: step.params,
        recipeName: step.recipeName,
        recipeInputStep: step.recipeInputStep,
      }
    }),
  ]
}

export const convertInfinityToZero = (value: number) => (isNaN(value) || !isFinite(value) ? 0 : value)

const WorkFlowDetailStep = ({
  workflow,
  models,
  mode = "MACRO",
  refetchHistory,
  hiddenSelect,
  isDisabledInView,
  selectedStepProp,
  recipeId,
  containerWidth,
  itemClassName,
  className,
  selectionMode,
  onCheck,
  isExplore,
  selectedItems,
  progressData,
}: WorkFlowDetailStepProps) => {
  const [selectedStep, setSelectedStep] = useState<RecipeTaskChainParams | null>(null)

  useEffect(() => {
    if (selectedStepProp) {
      setSelectedStep(selectedStepProp)
      return
    }

    setSelectedStep(null)
  }, [selectedStepProp])

  const mapImageToStep = useMemo(() => {
    if (!workflow.fullDataImages) return []

    const newFullDataImages = mappedTaskSteps(
      workflow.params as RecipeTaskChainParams[],
      workflow.fullDataImages,
      workflow.outputTree,
    )

    return newFullDataImages
  }, [workflow])

  let stepCompleted = ((progressData?.node ?? 1) - 1) / (progressData?.total_nodes ?? 1)
  const totalCurrentStepCompleted = stepCompleted * (1 / (progressData?.total_steps ?? 1))
  const totalPreviousStepsCompleted = ((progressData?.step ?? 1) - 1) / (progressData?.total_steps ?? 1)

  let totalStepCompleted = totalPreviousStepsCompleted + totalCurrentStepCompleted

  stepCompleted = Math.round(stepCompleted * 100)
  totalStepCompleted = Math.round(totalStepCompleted * 100)

  return (
    <div className="flex flex-col w-full">
      {!hiddenSelect && mode === "MACRO" && (
        <div className="w-full bg-atherGray-900">
          <WorkspaceContainer className="px-4 md:px-6">
            <WorkflowInfo
              progressData={progressData}
              workflowState={workflow}
              onHandleAutoSaveDraft={() => {}}
              mode="published"
            />
          </WorkspaceContainer>
        </div>
      )}
      <WorkspaceContainer className={cn("p-4 md:p-6", className)}>
        <div className="w-full mb-4 flex flex-col lg:flex-row">
          <div
            className={classNames("flex-1 w-full flex flex-col space-y-4", {
              "items-center": !workflow.capabilities.canUpdate,
            })}
          >
            {mapImageToStep.map((param, index) => (
              <div
                key={index}
                className={cn("flex flex-col items-center w-full max-w-[24rem]", {
                  "max-w-full": workflow.capabilities.canUpdate,
                })}
              >
                {mode === "MACRO" && (
                  <WorkflowStepHeader
                    index={index}
                    param={param}
                    setSelectedStep={setSelectedStep}
                    workflow={workflow}
                  />
                )}
                <div className="flex flex-col w-full lg:flex-row">
                  <motion.div
                    layout
                    className={cn(
                      "flex-1 flex flex-col bg-atherGray-900 w-full p-4 rounded-lg overflow-hidden",
                      itemClassName,
                    )}
                  >
                    {(workflow.status === RecipeTaskStatus.QUEUED || workflow.status === RecipeTaskStatus.RUNNING) &&
                      (mode === "MACRO"
                        ? index + 1 === progressData?.step && workflow.id === progressData?.task_id
                        : true) && (
                        <ProgressbarTask
                          isLoading={!progressData || workflow.id !== progressData?.task_id}
                          className="mb-4"
                          progress={convertInfinityToZero(mode === "RECIPE" ? totalStepCompleted : stepCompleted)}
                          description={`Generating ${convertInfinityToZero(
                            mode === "RECIPE" ? totalStepCompleted : stepCompleted,
                          )}%`}
                        />
                      )}
                    {workflow.outputText && mode === "RECIPE" && (
                      <div className="text-atherGray-300 text-sm mb-4">
                        {workflow.isHtml && workflow.outputType?.includes(RecipeOutputType.Animated) ? (
                          <>
                            <div className="flex items-center justify-between mb-1">
                              <p className="uppercase text-xs font-semibold mr-2">URL: </p>
                              <TextCopy value={`${clientUrl}/workspace/tools/recipes/${recipeId}/${workflow.id}`} />
                            </div>
                            <Link href={`/workspace/tools/recipes/${recipeId}/${workflow.id}`}>
                              {`${clientUrl}/workspace/tools/recipes/${recipeId}/${workflow.id}`}
                            </Link>
                          </>
                        ) : (
                          <>
                            <div className="flex items-center justify-between mb-1">
                              <p className="uppercase text-xs font-semibold mr-2">Output text: </p>
                              <TextCopy value={workflow.outputText} />
                            </div>
                            <p>{workflow.outputText}</p>
                          </>
                        )}
                      </div>
                    )}
                    <ResultImages
                      isExplore={isExplore}
                      recipeId={recipeId}
                      mode={mode}
                      selectionMode={selectionMode}
                      onCheck={onCheck}
                      selectedItems={selectedItems}
                      images={workflow.fullDataImages}
                      containerWidth={containerWidth}
                      hiddenSelect={hiddenSelect}
                      isDisabledInView={isDisabledInView}
                      fullDataImages={param.fullDataImages}
                      refetchHistory={refetchHistory}
                      workflow={workflow}
                    />
                    <WorkflowParams models={models} param={param} workflow={workflow} />
                  </motion.div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </WorkspaceContainer>
      <MobileDetailSheet
        mode={mode}
        models={models}
        itemClassName={itemClassName}
        selectedStep={selectedStep}
        setSelectedStep={setSelectedStep}
        workflow={workflow}
      />
    </div>
  )
}

export default memo(WorkFlowDetailStep)
