import useCustomRouter from "@/hooks/useCustomRouter"

export const useReplaceUrlQuery = () => {
  const router = useCustomRouter()

  const replaceUrl = (query: Record<string, string>) => {
    // get current url query
    const currentQuery = new URLSearchParams(window.location.search)
    // update query
    Object.entries(query).forEach(([key, value]) => {
      if (!value) {
        currentQuery.delete(key)
      } else {
        currentQuery.set(key, value)
      }
    })

    const queryString = currentQuery.toString()
    const newUrl = `${window.location.origin}${window.location.pathname}${queryString ? `?${queryString}` : ""}`
    // update url
    router.replace(newUrl, undefined, {
      shallow: true,
    })
  }

  return replaceUrl
}

export const useClearUrlQuery = () => {
  const router = useCustomRouter()

  const clearUrl = (key: string | string[]) => {
    // get current url query
    const currentQuery = new URLSearchParams(window.location.search)
    // update query
    if (Array.isArray(key)) {
      key.forEach(k => currentQuery.delete(k))
    } else {
      currentQuery.delete(key)
    }

    const queryString = currentQuery.toString()
    const newUrl = `${window.location.origin}${window.location.pathname}${queryString ? `?${queryString}` : ""}`

    // update url
    router.replace(newUrl, undefined, {
      shallow: true,
    })
  }

  return clearUrl
}
