import client from "@/api/client"
import { createMutation } from "react-query-kit"
import { actionMiddleware } from "../../middlewares/actionMiddleware"
import { useFolderInfoQuery } from "./useFolderInfoQuery"

export interface UseUnshareFolderMutation {
  folderId: string
  userIds: string[]
}

/**
 * Unshare a folder from a list of users
 * @param folderId The folder to unshare
 * @param userIds The users to unshare the folder from
 */
export const useUnshareFolderMutation = createMutation({
  mutationFn: ({ folderId, userIds }: UseUnshareFolderMutation) =>
    client.api.folderControllerUnshareFolder(folderId, {
      userIds,
    }),
  use: [
    actionMiddleware({
      onSettled: ({ queryClient, variables: { folderId } }) => {
        queryClient.invalidateQueries({
          queryKey: useFolderInfoQuery.getKey({ folderId }),
        })
      },
    }),
  ],
})
