import LoadingIcon from "@/components/LoadingIcon"
import Modal from "@/components/Modal"
import classNames from "classnames"

interface SwitchModeModalProps {
  isOpen: boolean
  onClose: () => void
  isLoading: boolean
  mutateToggle: any
}

const SwitchModeModal = ({ isOpen, onClose, isLoading, mutateToggle }: SwitchModeModalProps) => {
  return (
    <Modal className="max-w-md" title="Switch to private account" isOpen={isOpen} onClose={onClose}>
      <div className="flex flex-col w-full ">
        <p className="text-sm font-[400] mb-6 leading-[115%] text-atherGray-300">
          Only your followers will be able to see your Images, Articles, Macros, Styles
        </p>
        <div className="flex justify-end w-full space-x-2 ">
          <div
            onClick={onClose}
            className="min-w-[80px] py-2 bg-atherGray-800 rounded-xl cursor-pointer flex justify-center items-center"
          >
            <p className="font-semibold">Cancel</p>
          </div>
          <div
            onClick={() => mutateToggle()}
            className={classNames(
              "min-w-[200px] py-2 bg-[#5E17EB] rounded-xl cursor-pointer flex justify-center items-center",
              {
                "pointer-events-none opacity-60": isLoading,
              },
            )}
          >
            {isLoading ? (
              <LoadingIcon width={20} height={20} />
            ) : (
              <p className="font-semibold">Switch to private profile</p>
            )}
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default SwitchModeModal
