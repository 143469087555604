import { css } from "@emotion/css"
import classNames from "classnames"
import { FC, useRef } from "react"
import ReactSlider from "react-slider"
import FormDetailTooltip from "./FormDetailTooltip"
import { FormDetailComponentProps } from "./types"
import { isNullOrUndefined } from "@/utils/null-or-undefined"

const FormDetailNumberComponent: FC<FormDetailComponentProps & { isRenderValueTop?: boolean }> = props => {
  const { input, value, onChange, isRenderValueTop, maxNumberOfImages } = props
  const inputRef = useRef<HTMLInputElement>(null)

  const isMax =
    ["numberOfImage", "number_of_images", "batchSize", "batch_size"].includes(input.key) && !!maxNumberOfImages

  const min = isNullOrUndefined(input.min) ? undefined : parseFloat(input.min.toString())
  const max = isNullOrUndefined(input.max) ? undefined : isMax ? maxNumberOfImages : parseFloat(input.max.toString())
  const step = isNullOrUndefined(input.step) ? undefined : parseFloat(input.step.toString())
  const inputValue = parseFloat(parseFloat(value ?? min).toFixed(3))

  return (
    <div>
      <FormDetailTooltip className="mb-0" name={input.name} tooltip={input.tooltip}></FormDetailTooltip>
      <p className="mb-2 text-sm text-atherGray-400">{input.description}</p>
      <div
        className={css({
          marginTop: "1rem",
          position: "relative",
          maxWidth: "24rem",
          ".track-slider-0": {
            backgroundColor: "#5E17EB",
          },
          ".track-slider-1": {
            backgroundColor: "#E2E8F0",
          },
        })}
      >
        <div className={classNames("relative items-center flex w-full")}>
          <ReactSlider
            value={inputValue}
            onChange={v => {
              onChange({
                key: input.key,
                value: v,
                clearTriggerKey: input.clearTriggerKey,
              })
            }}
            min={min}
            max={max}
            step={step}
            className="h-1 horizontal-slider w-full"
            thumbClassName="h-4 w-4 bg-white top-1/2 -translate-y-1/2 rounded-full cursor-pointer"
            trackClassName="h-1 track-slider"
            renderThumb={({key, ...props}, state) => (
              <div key={key} {...props}>
                {isRenderValueTop && (
                  <span className="absolute bottom-full left-1/2 -translate-x-1/2 -translate-y-1 bg-atherGray-100 text-atherGray-950 px-1 font-semibold rounded-[4px] pointer-events-none text-xs">
                    {value ?? 0}
                  </span>
                )}
                <div className="bg-atherPurple-500 w-2.5 h-2.5 absolute top-1/2 left-1/2 -translate-x-[50%] -translate-y-[50%] rounded-full thumb-tracker" />
              </div>
            )}
          />
          {!isRenderValueTop && (
            <div className="py-0.5 w-[4rem] flex justify-center bg-atherGray-800 ml-2 rounded-md items-center">
              <input
                ref={inputRef}
                className="bg-transparent w-full text-center !text-xs !font-normal"
                value={inputValue}
                type="number"
                min={min}
                max={max}
                step={step}
                onClick={e => inputRef.current?.select()}
                onChange={e => {
                  const valueFormat = Math.max(input.min, Math.min(input.max, Number(e.target.value)))

                  onChange({ key: input.key, value: valueFormat as any, clearTriggerKey: input.clearTriggerKey })
                }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default FormDetailNumberComponent
