import client from "@/api/client"
import { WildcardDetail } from "@/api/sdk"
import { createAuthenticatedQuery } from "@/queries/createAuthenticatedQuery"
import { enableQueryMiddleware } from "@/queries/middlewares/enableQueryMiddleware"

export const useWorkspaceWildcardDetailQuery = createAuthenticatedQuery<WildcardDetail, { wildcardId: string }, Error>({
  primaryKey: "wildcard-detail",
  queryFn: ({ queryKey: [_primaryKey, variables] }) =>
    client.api.wildcardControllerGetWildcard(variables.wildcardId!).then(res => res.data),
  use: [enableQueryMiddleware(variables => !!variables.wildcardId)],
})
