import React, { FC, useEffect, useMemo, useRef, useState } from "react"
import { FormDetailComponentProps } from "./types"
import classNames from "classnames"
import { ChevronDownIcon, XIcon } from "@/components/shared/icons"
import { AnimatePresence } from "framer-motion"
import DrawerSelect from "./DrawerSelect"
import { useDebounce } from "@/hooks"

export type SelectItemWithThumbnail = {
  id: string
  name: string
  thumbnailUrl?: string
  category?: string
}

export type FormDetailThumbnailSelectProps = FormDetailComponentProps & {
  placeholder?: string
  clearable?: boolean
  drawerClassName?: string
  data: SelectItemWithThumbnail[]
  isLoadingData?: boolean
  modalOpened?: boolean
  renderSelector?: RenderSelectorFn
  isFetching?: boolean
  hasNextPage?: boolean
  fetchNextPage?: () => void
}

export type RenderSelectorFn = typeof renderSelectorAsSelect

export type RenderThumbnailSelectorProps = Omit<FormDetailThumbnailSelectProps, "renderSelector"> & {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

export const renderSelectorAsSelect = (props: RenderThumbnailSelectorProps) => {
  const { input, value, clearable, rtl, disabled, placeholder, onChange, isOpen, setIsOpen, data } = props

  return (
    <div
      className={classNames(
        "bg-atherGray-800 p-2 text-sm flex-1 rounded-lg flex items-center overflow-hidden cursor-pointer border text-atherGray-300",
        {
          "border-atherGray-800": !isOpen,
          "border-atherPurple-500": isOpen,
          "text-atherGray-400": disabled,
        },
      )}
      onClick={() => !disabled && setIsOpen(!isOpen)}
    >
      {rtl && <ChevronDownIcon className="rotate-90 w-3 h-3  mr-1" />}
      <p className="line-clamp-1 flex-1">{data.find(i => i.id === value)?.name ?? placeholder ?? "Select"}</p>
      {clearable && value && (
        <XIcon
          width={16}
          height={16}
          className="mx-1 hover:text-red-400"
          onClick={() =>
            onChange({
              key: input.key,
              value: "",
              clearTriggerKey: input.clearTriggerKey,
              checked: false,
            })
          }
        />
      )}
      {!rtl && <ChevronDownIcon className="-rotate-90 w-3 h-3 ml-1" />}
    </div>
  )
}

const FormDetailThumbnailSelectComponent: FC<FormDetailThumbnailSelectProps> = props => {
  const {
    input,
    value,
    data,
    disabled,
    isLoadingData,
    onChange,
    renderSelector = renderSelectorAsSelect,
    error,
    modalOpened,
    drawerClassName,
    recipeInput,
    hasNextPage,
    fetchNextPage,
    isFetching,
  } = props
  const [isOpen, setIsOpen] = useState(false)
  const [search, setSearch] = useState("")
  const searchDebounce = useDebounce(search, 350)

  const checked = recipeInput?.isChecked ?? false

  const models = useMemo(
    () =>
      data.filter(i =>
        i.name
          ? i.name.toLowerCase().includes(searchDebounce.toLowerCase())
          : i.id.toLowerCase().includes(searchDebounce.toLowerCase()),
      ) ?? [],
    [data, searchDebounce],
  )

  const item = useMemo(() => data.find(i => i.id === value), [data, value])

  useEffect(() => {
    if (isLoadingData) return
    if (!data || !recipeInput) return
    if (!checked || !input) return
    if (item) return

    const timer = setTimeout(() => {
      onChange({
        key: input.key,
        value: "",
        clearTriggerKey: input.clearTriggerKey,
        checked: false,
      })
    }, 200)

    return () => clearTimeout(timer)
  }, [item, checked, isLoadingData, input, data, recipeInput])

  return (
    <div className="">
      <div className="max-w-[24rem] flex">
        <div className="flex flex-col flex-1 mb-2">
          <p className="text-sm text-atherGray-300 font-semibold">{input.name}</p>
          <p className="text-sm text-atherGray-400">{input.description}</p>
        </div>
        <AnimatePresence mode="wait">
          {isOpen && (
            <DrawerSelect
              isFetching={isFetching}
              hasNextPage={hasNextPage}
              fetchNextPage={fetchNextPage}
              isOpen={isOpen}
              className={drawerClassName}
              mappedData={models.map(m => ({ ...m, thumbnailUrl: m.thumbnailUrl ?? "" }))}
              onClose={() => setIsOpen(false)}
              search={search}
              setSearch={setSearch}
              title={input.name}
              inputType={input.type}
              value={value}
              setSelected={v =>
                onChange({
                  key: input.key,
                  value: v || "",
                  clearTriggerKey: input.clearTriggerKey,
                  checked: !!v,
                })
              }
            />
          )}
        </AnimatePresence>
      </div>
      {renderSelector({ ...props, isOpen, setIsOpen })}
      {error && <span className="mt-1 text-red-500 text-xs">{error}</span>}
    </div>
  )
}

export default FormDetailThumbnailSelectComponent
