import IconButton from "@/components/IconButton"
import { MessageMultiIcon } from "@/components/shared/icons"
import { googleAnalytics } from "@/lib/gtag"
import useCustomRouter from "@/hooks/useCustomRouter"

const RoomButton = () => {
  const router = useCustomRouter()
  const isActive = router.pathname.includes("/rooms")

  return (
    <div className="">
      <IconButton
        onClick={() => {
          googleAnalytics.event({
            action: "click",
            category: "navbar",
            label: `room_button`,
            value: 1,
          })

          router.push("/rooms", undefined, { shallow: true })
        }}
        title="Rooms"
        className="relative overflow-visible px-2"
        colorScheme={isActive ? "primary" : "transparent"}
      >
        <MessageMultiIcon width={20} height={20} className="text-atherGray-300" />
      </IconButton>
    </div>
  )
}

export default RoomButton
