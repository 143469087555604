import _debounce from "lodash/debounce"
import { useEffect, useState } from "react"

function useContainerWidth(ref: React.RefObject<HTMLElement>, enabled?: boolean) {
  const [width, setWidth] = useState(0)

  useEffect(() => {
    if (!enabled) return

    const element = ref.current
    if (!element) {
      return
    }

    function handleResize(entries) {
      for (let entry of entries) {
        if (entry.target === element) {
          if (width > 0) {
            _debounce(() => {
              setWidth(entry.target.clientWidth)
            }, 350)()
          } else {
            setWidth(entry.target.clientWidth)
          }
          break
        }
      }
    }

    const observer = new ResizeObserver(handleResize)

    // Start observing
    observer.observe(element)

    // Cleanup
    return () => {
      observer.unobserve(element)
      observer.disconnect()
    }
  }, [ref, enabled])

  return width
}

export default useContainerWidth
