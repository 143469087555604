import { useEffect, useRef, useState } from "react"

const useLoaded = () => {
  const loadedRef = useRef(false)
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    const onPageLoaded = () => {
      setLoaded(true)
      loadedRef.current = true
    }

    if (document.readyState === "complete") {
      onPageLoaded()
    } else {
      window.addEventListener("load", onPageLoaded)
      return () => {
        window.removeEventListener("load", onPageLoaded)
      }
    }
  }, [])
  return {
    loaded,
    loadedRef,
  }
}

export default useLoaded
