import { IconProps } from "."

export const MuteAlertIcon = (props: IconProps) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_6501_162390)">
        <path
          d="M11.6641 11.8857C8.87672 12.6066 5.49039 12.4567 2.9521 11.4361C2.17606 11.1241 1.54224 10.498 1.68401 9.59601C1.77114 9.04166 2.20195 8.58006 2.52113 8.12931C2.93921 7.53166 2.98075 6.87979 2.98081 6.18625C2.98081 5.29399 3.22973 4.38497 3.66406 3.66663"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5 2.33331C5.84072 1.7061 6.89264 1.33331 8.03408 1.33331C10.7898 1.33331 13.0237 3.50605 13.0237 6.18625C13.0237 6.87979 13.065 7.53166 13.4809 8.12931C13.7983 8.58007 14.2268 9.04166 14.3135 9.59601C14.4056 10.1853 14.1685 10.6569 13.7777 11"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M14.6654 14.6666L1.33203 1.33331" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
        <path
          d="M6 14C6.53076 14.4146 7.23165 14.6667 8 14.6667C8.76835 14.6667 9.46924 14.4146 10 14"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_6501_162390">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
