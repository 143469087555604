import React from "react"
import { ArrowDownIcon } from "@/components/shared/icons"
import classNames from "classnames"
import { RecipeTaskWithFullImages } from "@/api/sdk"

interface AccordionHeaderProps {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  isRenaming: boolean
  input: string
  setInput: (input: string) => void
  task: RecipeTaskWithFullImages
  handleRename: () => void
  mutateRename: () => void
  inputRef: React.RefObject<HTMLInputElement>
}

export const AccordionHeader: React.FC<AccordionHeaderProps> = ({
  isOpen,
  setIsOpen,
  isRenaming,
  input,
  setInput,
  task,
  handleRename,
  mutateRename,
  inputRef,
}) => {
  return (
    <div className="flex items-start flex-1">
      <div className="mr-2 cursor-pointer pt-0.5 " onClick={() => setIsOpen(!isOpen)}>
        <ArrowDownIcon
          style={{
            transform: isOpen ? "rotate(0deg)" : "rotate(-90deg)",
          }}
          width={16}
          height={16}
          className="text-gray-400"
        />
      </div>
      <div className="flex flex-col flex-1 w-full overflow-hidden lg:items-end lg:w-auto lg:flex-row">
        {isRenaming ? (
          <input
            autoFocus
            readOnly={!isRenaming}
            className={classNames(
              "text-sm lg:text-base font-semibold bg-transparent w-full lg:w-auto flex-1 border-b pb-1 focus:outline-none",
              {
                "border-atherGray-600": isRenaming,
                "border-transparent pointer-events-none lg:pointer-events-auto": !isRenaming,
              },
            )}
            ref={inputRef}
            value={input}
            onChange={e => setInput(e.target.value)}
            onKeyDown={e => {
              if (e.key === "Enter") {
                e.preventDefault()
                mutateRename()
              }
            }}
          />
        ) : (
          <div
            className="overflow-hidden"
            onDoubleClick={e => {
              e.stopPropagation()
              handleRename()
            }}
          >
            <p className="text-sm font-semibold line-clamp-1 w-full">{task.name ?? task.id}</p>
          </div>
        )}
        <p
          className={classNames("w-full lg:w-auto whitespace-nowrap text-xs md:ml-1 md:pb-0.5 text-atherGray-400", {
            hidden: isRenaming,
          })}
        >
          (
          {task.fullDataImages && task.fullDataImages?.length > 1
            ? `${task.fullDataImages?.length ?? 0} images`
            : `${task.fullDataImages?.length ?? 0} image`}
          )
        </p>
      </div>
    </div>
  )
}
