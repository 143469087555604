import client from "@/api/client"
import { actionMiddleware } from "@/queries/middlewares/actionMiddleware"
import { createMutation } from "react-query-kit"
import { useGetRecipesInfiniteQuery } from "./getRecipeQueries"
import { InfiniteData } from "@tanstack/react-query"
import { GetRecipesResult, PinnedItemType, PinnedItems, RecentRecipeItem, UpdateRecipeDto } from "@/api/sdk"
import { useRecentRecipesInfiniteQuery } from "@/components/Workspace/RecentV2/RecentRecipes"
import { useWorkspacePinInfiniteQuery } from "../useWorkspacePinInfiniteQuery"

export type UpdateRecipeMutationVariables = {
  id: string
  values: UpdateRecipeDto & { thumbnail?: string | null }
}

export const useUpdateRecipeMutation = createMutation({
  mutationFn: async ({ id, values: { thumbnail, ...rest } }: UpdateRecipeMutationVariables) => {
    return client.api.recipeControllerUpdateRecipe(id, rest).then(res => res.data)
  },
  use: [
    actionMiddleware({
      onSuccess: ({ queryClient, variables: { id, values } }) => {
        const infKey = useGetRecipesInfiniteQuery.getKey()
        const infQueriesDataEntries = queryClient.getQueriesData<InfiniteData<GetRecipesResult, number>>({
          queryKey: infKey,
        })

        infQueriesDataEntries?.forEach(([key, data]) => {
          if (!data) return

          const updatedData = {
            ...data,
            pages: data.pages.map(page => {
              const updatedPage = {
                ...page,
                recipes: page.recipes.map(recipe => {
                  if (recipe.id === id) {
                    return {
                      ...recipe,
                      ...values,
                    }
                  }
                  return recipe
                }),
              }
              return updatedPage
            }),
          }
          queryClient.setQueryData(key, updatedData)
        })

        // Update recipes recent list
        const recipesRecentListKey = useRecentRecipesInfiniteQuery.getKey()

        const recipesRecentListQueriesDataEntries = queryClient.getQueriesData<
          InfiniteData<RecentRecipeItem[], number>
        >({
          queryKey: recipesRecentListKey,
        })

        if (recipesRecentListQueriesDataEntries) {
          recipesRecentListQueriesDataEntries.forEach(([key, recipesRecentListData]) => {
            if (recipesRecentListData) {
              const updatedData = {
                ...recipesRecentListData,
                pages: recipesRecentListData.pages.map(page => {
                  return page.map(item => {
                    if (id === item.recipe.id) {
                      return {
                        ...item,
                        recipe: {
                          ...item.recipe,
                          ...values,
                        },
                      }
                    }
                    return item
                  })
                }),
              }

              queryClient.setQueryData(key, updatedData)
            }
          })
        }

        // Update pinned items
        const recipesPinnedListKey = useWorkspacePinInfiniteQuery.getKey({
          type: PinnedItemType.RECIPE,
        })

        const recipesPinnedListQueriesDataEntries = queryClient.getQueriesData<InfiniteData<PinnedItems[], number>>({
          queryKey: recipesPinnedListKey,
        })

        if (recipesPinnedListQueriesDataEntries) {
          recipesPinnedListQueriesDataEntries.forEach(([key, recipePinnedListData]) => {
            if (recipePinnedListData) {
              const updatedData = {
                ...recipePinnedListData,
                pages: recipePinnedListData.pages.map(page => {
                  return page.map(item => {
                    if (id === item.recipe?.id) {
                      return {
                        ...item,
                        recipe: {
                          ...item.recipe,
                          ...values,
                        },
                      }
                    }
                    return item
                  })
                }),
              }

              queryClient.setQueryData(key, updatedData)
            }
          })
        }
      },
    }),
  ],
})
