import { IconProps } from "."

export const ArrowBoldLeftIcon = (props: IconProps) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M20.8136 9.88L18.9336 8L10.9336 16L18.9336 24L20.8136 22.12L14.7069 16L20.8136 9.88Z"
        fill="currentColor"
      />
    </svg>
  )
}
