import client from "@/api/client"
import { ExploreImageSimpleDetail, GetInspirationBoardImagesResult, SortOptions } from "@/api/sdk"
import { createAuthenticatedInfiniteQuery } from "../createAuthenticatedQuery"

export interface UseExploreImagesInfiniteQueryVariables {
  onlyMine?: boolean
  onlyFavorite?: boolean
  sortBy?: SortOptions
  tagIds?: number[]
  searchTerm?: string
  creatorUid?: string
  userUid?: string
  take?: number
  smart?: boolean
  onlyCurrentWorkspace?: boolean
  sdWorkflowId?: string
  recipeIds?: string[]
}

export const useExploreImagesInfiniteQuery = createAuthenticatedInfiniteQuery<
  GetInspirationBoardImagesResult,
  UseExploreImagesInfiniteQueryVariables,
  Error,
  ExploreImageSimpleDetail
>({
  auth: false,
  primaryKey: "infinite-explore-images",
  queryFn: ({
    queryKey: [
      _primaryKey,
      {
        onlyMine,
        onlyFavorite,
        sortBy,
        tagIds,
        searchTerm,
        creatorUid,
        recipeIds,
        sdWorkflowId,
        take,
        smart,
        onlyCurrentWorkspace,
      },
    ],
    pageParam = 0,
  }) =>
    client.api
      .imageControllerGetInspirationBoardImages({
        skip: pageParam * 50,
        take: take ?? 50,
        onlyMine,
        onlyFavorite,
        sortBy,
        tagIds,
        searchTerm,
        creatorUid,
        recipeIds,
        smart,
        sdWorkflowId,
        onlyCurrentWorkspace,
      })
      .then(res => res.data),
  getNextPageParam: (lastPage, pages) => (lastPage && lastPage.images.length < 50 ? undefined : pages.length),
  initialPageParam: 0,
  flattenFn: data => data.images,
})
