import { IconProps } from "."

export const FlowSquareIcon = (props: IconProps) => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M21.5 6.5C21.5 8.433 19.933 10 18 10C16.067 10 14.5 8.433 14.5 6.5C14.5 4.567 16.067 3 18 3C19.933 3 21.5 4.567 21.5 6.5Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M3.5 6.5C3.5 4.85008 3.5 4.02513 4.01256 3.51256C4.52513 3 5.35008 3 7 3C8.64992 3 9.47487 3 9.98744 3.51256C10.5 4.02513 10.5 4.85008 10.5 6.5C10.5 8.14992 10.5 8.97487 9.98744 9.48744C9.47487 10 8.64992 10 7 10C5.35008 10 4.52513 10 4.01256 9.48744C3.5 8.97487 3.5 8.14992 3.5 6.5Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M3.5 17.5C3.5 15.8501 3.5 15.0251 4.01256 14.5126C4.52513 14 5.35008 14 7 14C8.64992 14 9.47487 14 9.98744 14.5126C10.5 15.0251 10.5 15.8501 10.5 17.5C10.5 19.1499 10.5 19.9749 9.98744 20.4874C9.47487 21 8.64992 21 7 21C5.35008 21 4.52513 21 4.01256 20.4874C3.5 19.9749 3.5 19.1499 3.5 17.5Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M14.5 17.5C14.5 15.8501 14.5 15.0251 15.0126 14.5126C15.5251 14 16.3501 14 18 14C19.6499 14 20.4749 14 20.9874 14.5126C21.5 15.0251 21.5 15.8501 21.5 17.5C21.5 19.1499 21.5 19.9749 20.9874 20.4874C20.4749 21 19.6499 21 18 21C16.3501 21 15.5251 21 15.0126 20.4874C14.5 19.9749 14.5 19.1499 14.5 17.5Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M18 10V14M14.5 17.5H10.5M10.5 6.5H14.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
