import { IconProps } from "."

export const LayerMaskIcon = (props: IconProps) => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M22.2813 12.353C22.2813 17.8759 17.8041 22.353 12.2813 22.353C6.7584 22.353 2.28125 17.8759 2.28125 12.353C2.28125 6.83018 6.7584 2.35303 12.2813 2.35303C17.8041 2.35303 22.2813 6.83018 22.2813 12.353Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path d="M5.28125 20.353L19.2812 5.35303" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round" />
      <path
        d="M16.2812 9.35303L22.2812 14.2058M12.6941 12.759L19.6414 18.7164M8.28125 16.0202L15.2812 21.853"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  )
}
