import { create } from "zustand"

interface MoveFolderItemStore {
  type: "folder"
  action?: "move" | "copy"
  folders: {
    id: string
    name: string
    parentFolderId: string | null
  }[]
  parentFolderId?: string
  onClose?: () => void
}

interface MoveImageItemStore {
  type: "image"
  action?: "move" | "copy"
  images: {
    id: string
    name: string
    parentFolderId: string | null
  }[]
  parentFolderId?: string
  onClose?: () => void
}

interface MoveItemV2Store {
  movingItem: MoveFolderItemStore | MoveImageItemStore | null
  setMovingItem: (movingItem: MoveFolderItemStore | MoveImageItemStore | null) => void
}

export const useMoveItemV2Store = create<MoveItemV2Store>(set => ({
  movingItem: null,
  setMovingItem: movingItem => set({ movingItem }),
}))
