import client from "@/api/client"
import {
  ExploreWorkflowDetail,
  GetExploreWorkflowsResult,
  GetWorkflowsResult,
  PinnedItemType,
  PinnedItems,
  RecentWorkflowItem,
  SharingRole,
} from "@/api/sdk"
import { InfiniteData, useQueryClient } from "@tanstack/react-query"
import { createMutation } from "react-query-kit"
import { actionMiddleware } from "../middlewares/actionMiddleware"
import { useWorkspaceWorkflowInfiniteQuery } from "../workspace/useWorkspaceWorkflowInfiniteQuery"
import { useRecentWorkflowInfiniteQuery } from "@/components/Workspace/RecentV2/RecentWorkflow"
import { useExploreWorkflowDetailQuery, useExploreWorkflowsInfiniteQuery } from "../explore"
import { useWorkspaceWorkflowDetailQuery } from "../workspace/workflow"
import { useWorkspacePinInfiniteQuery } from "../workspace"

export const useFavoriteTaskMutation = createMutation({
  mutationFn: ({ id }: { id: string; userUid?: string }) => client.api.workflowControllerToggleFavoriteWorkflow(id),
  use: [
    actionMiddleware({
      onMutate: ({ queryClient, variables }) => {
        // Update workflow explore list

        const exploreWorkflowListKey = useExploreWorkflowsInfiniteQuery.getKey()

        const exploreWorkflowQueriesDataEntries = queryClient.getQueriesData<
          InfiniteData<GetExploreWorkflowsResult, number>
        >({
          queryKey: exploreWorkflowListKey,
        })

        if (exploreWorkflowQueriesDataEntries) {
          exploreWorkflowQueriesDataEntries.forEach(([key, parentSubFoldersData]) => {
            if (parentSubFoldersData) {
              const updatedData = {
                ...parentSubFoldersData,
                pages: parentSubFoldersData.pages.map(page => {
                  return {
                    ...page,
                    workflows: page.workflows.map(workflow => {
                      if (workflow.id === variables.id) {
                        return {
                          ...workflow,
                          favoritedByUser: !workflow.favoritedByUser,
                          metric: {
                            ...workflow.metric,
                            favoriteCount: workflow.favoritedByUser
                              ? (workflow.metric?.favoriteCount ?? 0) - 1
                              : (workflow.metric?.favoriteCount ?? 0) + 1,
                          },
                        }
                      }
                      return workflow
                    }),
                  }
                }),
              }
              queryClient.setQueryData(key, updatedData)
            }
          })
        }

        // Update workflow workspace list
        const workspaceWorkflowListKey = useWorkspaceWorkflowInfiniteQuery.getKey()

        const workspaceWorkflowListQueriesDataEntries = queryClient.getQueriesData<
          InfiniteData<GetWorkflowsResult, number>
        >({
          queryKey: workspaceWorkflowListKey,
        })

        if (workspaceWorkflowListQueriesDataEntries) {
          workspaceWorkflowListQueriesDataEntries.forEach(([key, workflowListData]) => {
            if (workflowListData) {
              const updatedData = {
                ...workflowListData,
                pages: workflowListData.pages.map(page => {
                  return {
                    ...page,
                    workflows: page.workflows.map(workflow => {
                      if (workflow.id === variables.id) {
                        return {
                          ...workflow,
                          favoritedByUser: !workflow.favoritedByUser,
                          metric: {
                            ...workflow.metric,
                            favoriteCount: workflow.favoritedByUser
                              ? (workflow.metric?.favoriteCount ?? 0) - 1
                              : (workflow.metric?.favoriteCount ?? 0) + 1,
                          },
                        }
                      }
                      return workflow
                    }),
                  }
                }),
              }

              queryClient.setQueryData(key, updatedData)
            }
          })
        }

        // Update workflow recent list
        const workflowRecentListKey = useRecentWorkflowInfiniteQuery.getKey()

        const workflowRecentListQueriesDataEntries = queryClient.getQueriesData<
          InfiniteData<RecentWorkflowItem[], number>
        >({
          queryKey: workflowRecentListKey,
        })

        if (workflowRecentListQueriesDataEntries) {
          workflowRecentListQueriesDataEntries.forEach(([key, workflowRecentListData]) => {
            if (workflowRecentListData) {
              const updatedData = {
                ...workflowRecentListData,
                pages: workflowRecentListData.pages.map(page => {
                  return [
                    ...page.map(item => {
                      if (item.workflow.id === variables.id) {
                        return {
                          ...item,
                          workflow: {
                            ...item.workflow,
                            favoritedByUser: !item.workflow.favoritedByUser,
                            metric: {
                              ...item.workflow.metric,
                              favoriteCount: item.workflow.favoritedByUser
                                ? (item.workflow.metric?.favoriteCount ?? 0) - 1
                                : (item.workflow.metric?.favoriteCount ?? 0) + 1,
                            },
                          },
                        }
                      }

                      return item
                    }),
                  ]
                }),
              }

              queryClient.setQueryData(key, updatedData)
            }
          })
        }

        //update pinned workflow
        const workflowPinnedListKey = useWorkspacePinInfiniteQuery.getKey({
          type: PinnedItemType.WORKFLOW,
        })

        const workflowPinnedListQueriesDataEntries = queryClient.getQueriesData<InfiniteData<PinnedItems[], number>>({
          queryKey: workflowPinnedListKey,
        })

        if (workflowPinnedListQueriesDataEntries) {
          workflowPinnedListQueriesDataEntries.forEach(([key, workflowPinnedListData]) => {
            if (workflowPinnedListData) {
              const updatedData = {
                ...workflowPinnedListData,
                pages: workflowPinnedListData.pages.map(page => {
                  return [
                    ...page.map(item => {
                      if (item.workflow?.id === variables.id) {
                        return {
                          ...item,
                          workflow: {
                            ...item.workflow,
                            favoritedByUser: !item.workflow.favoritedByUser,
                            metric: {
                              ...item.workflow.metric,
                              favoriteCount: item.workflow.favoritedByUser
                                ? (item.workflow.metric?.favoriteCount ?? 0) - 1
                                : (item.workflow.metric?.favoriteCount ?? 0) + 1,
                            },
                          },
                        }
                      }

                      return item
                    }),
                  ]
                }),
              }

              queryClient.setQueryData(key, updatedData)
            }
          })
        }

        //update detail workflow
        const detailExploreWorkflowKey = useExploreWorkflowDetailQuery.getKey({
          workflowId: variables.id ?? "",
          userUid: variables.userUid,
        })

        const detailExploreWorkflowQueriesDataEntries = queryClient.getQueriesData<ExploreWorkflowDetail>({
          queryKey: detailExploreWorkflowKey,
        })

        if (detailExploreWorkflowQueriesDataEntries) {
          detailExploreWorkflowQueriesDataEntries.forEach(([key, detailWorkflowData]) => {
            if (detailWorkflowData) {
              const updatedData = {
                ...detailWorkflowData,
                favoritedByUser: !detailWorkflowData.favoritedByUser,
                metric: {
                  ...detailWorkflowData.metric,
                  favoriteCount: detailWorkflowData.favoritedByUser
                    ? (detailWorkflowData.metric?.favoriteCount ?? 0) - 1
                    : (detailWorkflowData.metric?.favoriteCount ?? 0) + 1,
                },
              }

              queryClient.setQueryData(key, updatedData)
            }
          })
        }

        const detailWorkspaceWorkflowKey = useWorkspaceWorkflowDetailQuery.getKey({
          workflowId: variables.id ?? "",
        })

        const detailWorkspaceWorkflowQueriesDataEntries = queryClient.getQueriesData<ExploreWorkflowDetail>({
          queryKey: detailWorkspaceWorkflowKey,
        })

        if (detailWorkspaceWorkflowQueriesDataEntries) {
          detailWorkspaceWorkflowQueriesDataEntries.forEach(([key, detailWorkspaceWorkflowData]) => {
            if (detailWorkspaceWorkflowData) {
              const updatedData = {
                ...detailWorkspaceWorkflowData,
                favoritedByUser: !detailWorkspaceWorkflowData.favoritedByUser,
                metric: {
                  ...detailWorkspaceWorkflowData.metric,
                  favoriteCount: detailWorkspaceWorkflowData.favoritedByUser
                    ? (detailWorkspaceWorkflowData.metric?.favoriteCount ?? 0) - 1
                    : (detailWorkspaceWorkflowData.metric?.favoriteCount ?? 0) + 1,
                },
              }

              queryClient.setQueryData(key, updatedData)
            }
          })
        }
      },
    }),
    useMutationNext => {
      return (options, queryClient) => {
        const qc = useQueryClient(queryClient)

        return useMutationNext({
          ...options,
          onSettled: (_, __, {}) => {},
        })
      }
    },
  ],
})
