import client from "@/api/client"
import { actionMiddleware } from "@/queries/middlewares/actionMiddleware"
import { createMutation } from "react-query-kit"
import {
  ExploreImageFullDetail,
  GetInspirationBoardImagesResult,
  GetInspirationBoardSimilarImagesResult,
  GetSimilarImagesResult,
  ImageFullDetail,
  PinnedItemType,
  PinnedItems,
  RecentImageItem,
  SearchImagesResponse,
} from "@/api/sdk"
import { InfiniteData } from "@tanstack/react-query"
import { useWorkspaceImagesInfiniteQuery } from "../useWorkspaceImagesInfiniteQuery"
import { useRecentImagesInfiniteQuery } from "@/components/Workspace/RecentV2/RecentImages"
import {
  useExploreDetailImageQuery,
  useExploreImagesInfiniteQuery,
  useExploreImagesSimilarInfiniteQuery,
} from "@/queries/explore"
import { useWorkspaceDetailImageQuery } from "../useWorkspaceDetailImageQuery"
import { useWorkspaceImagesSimilarInfiniteQuery } from "./useWorkspaceImagesSimilarInfiniteQuery"
import { useWorkspacePinInfiniteQuery } from "../useWorkspacePinInfiniteQuery"
import { useWorkspaceCreationImagesInfiniteQuery } from "./useWorkspaceCreationImagesInfiniteQuery"

export const useFavoriteImageMutation = createMutation({
  mutationFn: async ({ id }: { id: string; folderId?: string; userUid?: string }) => {
    return await client.api.imageControllerFavorImage(id).then(res => res.data)
  },
  use: [
    actionMiddleware({
      onMutate: ({ queryClient, variables }) => {
        // Update images creation
        const imagesCreationKey = useWorkspaceCreationImagesInfiniteQuery.getKey()

        const imagesCreationQueriesDataEntries = queryClient.getQueriesData<InfiniteData<SearchImagesResponse, number>>(
          {
            queryKey: imagesCreationKey,
          },
        )

        if (imagesCreationQueriesDataEntries) {
          imagesCreationQueriesDataEntries.forEach(([key, imagesCreationData]) => {
            if (imagesCreationData) {
              const updatedData = {
                ...imagesCreationData,
                pages: imagesCreationData.pages.map(page => {
                  return {
                    ...page,
                    images: page.images.map(image => {
                      if (variables.id === image.id) {
                        return {
                          ...image,
                          favoritedByUser: !image.favoritedByUser,
                          metric: {
                            ...image.metric,
                            favoriteCount: image.favoritedByUser
                              ? (image.metric?.favoriteCount ?? 0) - 1
                              : (image.metric?.favoriteCount ?? 0) + 1,
                          },
                        }
                      }
                      return image
                    }),
                  }
                }),
              }

              queryClient.setQueryData(key, updatedData)
            }
          })
        }

        const imageExploreKey = useExploreDetailImageQuery.getKey({
          imageId: variables.id,
          userUid: variables.userUid,
        })

        const imageExploreData = queryClient.getQueryData<ExploreImageFullDetail>(imageExploreKey)

        if (imageExploreData) {
          queryClient.setQueryData(imageExploreKey, {
            ...imageExploreData,
            favoritedByUser: !imageExploreData.favoritedByUser,
            metric: {
              ...imageExploreData.metric,
              favoriteCount: imageExploreData.favoritedByUser
                ? (imageExploreData.metric?.favoriteCount ?? 0) - 1
                : (imageExploreData.metric?.favoriteCount ?? 0) + 1,
            },
          })
        }

        const imageWorkspaceData = useWorkspaceDetailImageQuery.getKey({
          imageId: variables.id,
          userUid: variables.userUid,
        })

        const imageWorkspaceDataData = queryClient.getQueryData<ImageFullDetail>(imageWorkspaceData)

        if (imageWorkspaceDataData) {
          queryClient.setQueryData(imageWorkspaceData, {
            ...imageWorkspaceDataData,
            favoritedByUser: !imageWorkspaceDataData.favoritedByUser,
            metric: {
              ...imageWorkspaceDataData.metric,
              id: imageWorkspaceDataData.metric?.id ?? 0,
              heartCount: imageWorkspaceDataData.metric?.heartCount ?? 0,
              downloadCount: imageWorkspaceDataData.metric?.downloadCount ?? 0,
              commentCount: imageWorkspaceDataData.metric?.commentCount ?? 0,
              shareCount: imageWorkspaceDataData.metric?.shareCount ?? 0,
              viewCount: imageWorkspaceDataData.metric?.viewCount ?? 0,
              favoriteCount: imageWorkspaceDataData.favoritedByUser
                ? (imageWorkspaceDataData.metric?.favoriteCount ?? 0) - 1
                : (imageWorkspaceDataData.metric?.favoriteCount ?? 0) + 1,
            },
          })
        }

        const exploreImagesKey = useExploreImagesInfiniteQuery.getKey()

        const exploreImagesQueriesDataEntries = queryClient.getQueriesData<
          InfiniteData<GetInspirationBoardImagesResult, number>
        >({
          queryKey: exploreImagesKey,
        })

        if (exploreImagesQueriesDataEntries) {
          exploreImagesQueriesDataEntries.forEach(([key, parentSubFoldersData]) => {
            if (parentSubFoldersData) {
              const updatedData = {
                ...parentSubFoldersData,
                pages: parentSubFoldersData.pages.map(page => {
                  return {
                    ...page,
                    images: page.images.map(image => {
                      if (variables.id === image.id) {
                        return {
                          ...image,
                          favoritedByUser: !image.favoritedByUser,
                          metric: {
                            ...image.metric,
                            favoriteCount: image.favoritedByUser
                              ? (image.metric?.favoriteCount ?? 0) - 1
                              : (image.metric?.favoriteCount ?? 0) + 1,
                          },
                        }
                      }
                      return image
                    }),
                  }
                }),
              }
              queryClient.setQueryData(key, updatedData)
            }
          })
        }

        const exploreSimilarImagesKey = useExploreImagesSimilarInfiniteQuery.getKey({
          id: variables.folderId,
        })

        const exploreSimilarImagesQueriesDataEntries = queryClient.getQueriesData<
          InfiniteData<GetInspirationBoardSimilarImagesResult, number>
        >({ queryKey: exploreSimilarImagesKey })

        if (exploreSimilarImagesQueriesDataEntries) {
          exploreSimilarImagesQueriesDataEntries.forEach(([key, parentSubFoldersData]) => {
            if (parentSubFoldersData) {
              const updatedData = {
                ...parentSubFoldersData,
                pages: parentSubFoldersData.pages.map(page => {
                  return {
                    ...page,
                    images: page.images.map(image => {
                      if (variables.id === image.id) {
                        return {
                          ...image,
                          favoritedByUser: !image.favoritedByUser,
                          metric: {
                            ...image.metric,
                            favoriteCount: image.favoritedByUser
                              ? (image.metric?.favoriteCount ?? 0) - 1
                              : (image.metric?.favoriteCount ?? 0) + 1,
                          },
                        }
                      }
                      return image
                    }),
                  }
                }),
              }
              queryClient.setQueryData(key, updatedData)
            }
          })
        }

        const workspaceSimilarImagesKey = useWorkspaceImagesSimilarInfiniteQuery.getKey({
          id: variables.folderId,
        })

        const workspaceSimilarImagesQueriesDataEntries = queryClient.getQueriesData<
          InfiniteData<GetSimilarImagesResult, number>
        >({
          queryKey: workspaceSimilarImagesKey,
        })

        if (workspaceSimilarImagesQueriesDataEntries) {
          workspaceSimilarImagesQueriesDataEntries.forEach(([key, parentSubFoldersData]) => {
            if (parentSubFoldersData) {
              const updatedData = {
                ...parentSubFoldersData,
                pages: parentSubFoldersData.pages.map(page => {
                  return {
                    ...page,
                    images: page.images.map(image => {
                      if (variables.id === image.id) {
                        return {
                          ...image,
                          favoritedByUser: !image.favoritedByUser,
                          metric: {
                            ...image.metric,
                            favoriteCount: image.favoritedByUser
                              ? (image.metric?.favoriteCount ?? 0) - 1
                              : (image.metric?.favoriteCount ?? 0) + 1,
                          },
                        }
                      }
                      return image
                    }),
                  }
                }),
              }
              queryClient.setQueryData(key, updatedData)
            }
          })
        }

        const workspaceImagesKey = useWorkspaceImagesInfiniteQuery.getKey()

        const workspaceImagesQueriesDataEntries = queryClient.getQueriesData<
          InfiniteData<SearchImagesResponse, number>
        >({
          queryKey: workspaceImagesKey,
        })

        if (workspaceImagesQueriesDataEntries) {
          workspaceImagesQueriesDataEntries.forEach(([key, parentSubFoldersData]) => {
            if (parentSubFoldersData) {
              const updatedData = {
                ...parentSubFoldersData,
                pages: parentSubFoldersData.pages.map(page => {
                  return {
                    ...page,
                    images: page.images.map(image => {
                      if (variables.id === image.id) {
                        return {
                          ...image,
                          favoritedByUser: !image.favoritedByUser,
                          metric: {
                            ...image.metric,
                            favoriteCount: image.favoritedByUser
                              ? (image.metric?.favoriteCount ?? 0) - 1
                              : (image.metric?.favoriteCount ?? 0) + 1,
                          },
                        }
                      }
                      return image
                    }),
                  }
                }),
              }
              queryClient.setQueryData(key, updatedData)
            }
          })
        }

        // Update images recent list
        const imagesRecentListKey = useRecentImagesInfiniteQuery.getKey()

        const imagesRecentListQueriesDataEntries = queryClient.getQueriesData<InfiniteData<RecentImageItem[], number>>({
          queryKey: imagesRecentListKey,
        })

        if (imagesRecentListQueriesDataEntries) {
          imagesRecentListQueriesDataEntries.forEach(([key, imagesRecentListData]) => {
            if (imagesRecentListData) {
              const updatedData = {
                ...imagesRecentListData,
                pages: imagesRecentListData.pages.map(page => {
                  return page.map(recent => {
                    if (variables.id === recent.image.id) {
                      return {
                        ...recent,
                        image: {
                          ...recent.image,
                          favoritedByUser: !recent.image.favoritedByUser,
                          metric: {
                            ...recent.image.metric,
                            favoriteCount: recent.image.favoritedByUser
                              ? (recent.image.metric?.favoriteCount ?? 0) - 1
                              : (recent.image.metric?.favoriteCount ?? 0) + 1,
                          },
                        },
                      }
                    }
                    return recent
                  })
                }),
              }

              queryClient.setQueryData(key, updatedData)
            }
          })
        }

        //update pinned image
        const imagePinnedListKey = useWorkspacePinInfiniteQuery.getKey({
          type: PinnedItemType.IMAGE,
        })

        const imagePinnedListQueriesDataEntries = queryClient.getQueriesData<InfiniteData<PinnedItems[], number>>({
          queryKey: imagePinnedListKey,
        })

        if (imagePinnedListQueriesDataEntries) {
          imagePinnedListQueriesDataEntries.forEach(([key, imagePinnedListData]) => {
            if (imagePinnedListData) {
              const updatedData = {
                ...imagePinnedListData,
                pages: imagePinnedListData.pages.map(page => {
                  return [
                    ...page.map(item => {
                      if (item.image?.id === variables.id) {
                        return {
                          ...item,
                          image: {
                            ...item.image,
                            favoritedByUser: !item.image.favoritedByUser,
                            metric: {
                              ...item.image.metric,
                              favoriteCount: item.image.favoritedByUser
                                ? (item.image.metric?.favoriteCount ?? 0) - 1
                                : (item.image.metric?.favoriteCount ?? 0) + 1,
                            },
                          },
                        }
                      }

                      return item
                    }),
                  ]
                }),
              }

              queryClient.setQueryData(key, updatedData)
            }
          })
        }
      },
      onError: ({ queryClient, variables }) => {
        const exploreImagesKey = useExploreImagesInfiniteQuery.getKey()
        const workspaceImagesKey = useWorkspaceImagesInfiniteQuery.getKey()
        const imagesRecentListKey = useRecentImagesInfiniteQuery.getKey()

        queryClient.invalidateQueries({ queryKey: exploreImagesKey })
        queryClient.invalidateQueries({ queryKey: workspaceImagesKey })
        queryClient.invalidateQueries({ queryKey: imagesRecentListKey })
      },
    }),
  ],
})
