import client from "@/api/client"
import { TagSDWorkflowDto } from "@/api/sdk"
import { actionMiddleware } from "@/queries/middlewares/actionMiddleware"
import { createMutation } from "react-query-kit"

export interface UseTagsSDWorkflowsMutationVariables extends TagSDWorkflowDto {}

export const useTagsSDWorkflowsMutation = createMutation({
  mutationFn: (input: UseTagsSDWorkflowsMutationVariables) => client.api.sdWorkflowControllerTagSdWorkflows(input),
  use: [
    actionMiddleware({
      onSuccess({ queryClient, variables }) {},
    }),
  ],
})

export const useUnTagsSDWorkflowsMutation = createMutation({
  mutationFn: (input: UseTagsSDWorkflowsMutationVariables) => client.api.sdWorkflowControllerUntagSdWorkflows(input),
  use: [
    actionMiddleware({
      onSuccess({ queryClient, variables }) {},
    }),
  ],
})
