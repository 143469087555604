import { IconProps } from "."

export const BookIcon4 = (props: IconProps) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M6.25 4.16669H13.3333C14.0237 4.16669 14.5833 4.72633 14.5833 5.41669V6.66669"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.1044 17.357L15.5741 16.8267V16.8267L16.1044 17.357ZM15.4141 2.41669C15.8283 2.41669 16.1641 2.0809 16.1641 1.66669C16.1641 1.25247 15.8283 0.916687 15.4141 0.916687V2.41669ZM16.3307 8.33335V11.6667H17.8307V8.33335H16.3307ZM10.4141 17.5834H9.58073V19.0834H10.4141V17.5834ZM3.66406 11.6667V4.16669H2.16406V11.6667H3.66406ZM9.58073 17.5834C7.98818 17.5834 6.86921 17.5818 6.02309 17.468C5.19855 17.3571 4.74628 17.1523 4.4207 16.8267L3.36004 17.8874C4.01078 18.5381 4.83234 18.8214 5.82322 18.9546C6.79252 19.0849 8.03058 19.0834 9.58073 19.0834V17.5834ZM2.16406 11.6667C2.16406 13.2168 2.16247 14.4549 2.29279 15.4242C2.42601 16.4151 2.70931 17.2366 3.36004 17.8874L4.4207 16.8267C4.09512 16.5011 3.89027 16.0489 3.77941 15.2243C3.66566 14.3782 3.66406 13.2592 3.66406 11.6667H2.16406ZM16.3307 11.6667C16.3307 13.2592 16.3291 14.3782 16.2154 15.2243C16.1045 16.0489 15.8997 16.5011 15.5741 16.8267L16.6347 17.8874C17.2855 17.2366 17.5688 16.4151 17.702 15.4242C17.8323 14.4549 17.8307 13.2168 17.8307 11.6667H16.3307ZM10.4141 19.0834C11.9642 19.0834 13.2023 19.0849 14.1716 18.9546C15.1625 18.8214 15.984 18.5381 16.6347 17.8874L15.5741 16.8267C15.2485 17.1523 14.7962 17.3571 13.9717 17.468C13.1256 17.5818 12.0066 17.5834 10.4141 17.5834V19.0834ZM15.4141 0.916687H5.41406V2.41669H15.4141V0.916687ZM5.41406 7.41669H15.4141V5.91669H5.41406V7.41669ZM2.16406 4.16669C2.16406 5.96161 3.61914 7.41669 5.41406 7.41669V5.91669C4.44756 5.91669 3.66406 5.13319 3.66406 4.16669H2.16406ZM5.41406 0.916687C3.61914 0.916687 2.16406 2.37176 2.16406 4.16669H3.66406C3.66406 3.20019 4.44756 2.41669 5.41406 2.41669V0.916687ZM17.8307 8.33335C17.8307 6.99867 16.7488 5.91669 15.4141 5.91669V7.41669C15.9203 7.41669 16.3307 7.82709 16.3307 8.33335H17.8307Z"
        fill="currentColor"
      />
      <path
        d="M12.5 10.8334L7.5 10.8334"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 14.1667L7.5 14.1667"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
