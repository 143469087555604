import { css } from "@emotion/css"
import React, { useEffect, useRef } from "react"
import { twMerge } from "tailwind-merge"

interface ScrollHorizontalContainerProps {
  children: React.ReactNode
  className?: string
  containerClassName?: string
  valueKey?: string
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void
  layoutRef?: React.RefObject<HTMLDivElement>
}
const ScrollHorizontalContainer = ({
  children,
  className,
  layoutRef,
  onClick,
  containerClassName,
  valueKey,
}: ScrollHorizontalContainerProps) => {
  let isDragging = false
  let startX
  let scrollLeft

  const ref = useRef<HTMLDivElement>(null)

  const handleMouseDown = e => {
    if (!ref.current) return
    e.preventDefault()
    startX = e.pageX - ref.current.offsetLeft
    scrollLeft = ref.current.scrollLeft
    ref.current.classList.remove("grabbing")

    isDragging = true
  }

  const handleMouseUp = e => {
    isDragging = false
  }

  const handleMouseMove = e => {
    if (!isDragging) return
    if (!ref.current) return
    e.preventDefault()
    ref.current.classList.add("grabbing")

    const x = e.pageX - ref.current.offsetLeft
    const walk = (x - startX) * 1.5
    ref.current.scrollLeft = scrollLeft - walk
  }

  useEffect(() => {
    if (!!valueKey) {
      const id = document.getElementById(valueKey)

      if (id) {
        id.scrollIntoView({
          behavior: "smooth",
          inline: "center",
          block: "nearest",
        })
      }
    }
  }, [valueKey])

  return (
    <div onClick={onClick} className={twMerge("w-full flex justify-center overflow-hidden", containerClassName)}>
      <div className="w-full relative overflow-hidden">
        <div
          className={css({
            overflow: "hidden",
            maxWidth: "100%",
          })}
        >
          <div
            ref={layoutRef ?? ref}
            onMouseLeave={() => (isDragging = false)}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onMouseMove={handleMouseMove}
            className={twMerge("details-image-similar flex w-full h-full pb-2 scrollbar-hover-x", className)}
            style={{
              overflowX: "auto",
              overflowY: "hidden",
            }}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ScrollHorizontalContainer
