import { useEffect } from "react"

export interface KeyCombination {
  key: string
  ctrlKey?: boolean
}

export const useCallbackOnKeys = (keyCombinations: KeyCombination[], callback: () => void, enabled: boolean) => {
  useEffect(() => {
    if (!enabled) {
      return
    }

    const handleKeyDown = (event: KeyboardEvent) => {
      const keyPressed = keyCombinations.some(
        combination =>
          event.key === combination.key &&
          (combination.ctrlKey === undefined ||
            event.ctrlKey === combination.ctrlKey ||
            event.metaKey === combination.ctrlKey)
      )

      if (keyPressed) {
        event.preventDefault()

        callback()
      }
    }

    document.addEventListener("keydown", handleKeyDown)
    return () => {
      document.removeEventListener("keydown", handleKeyDown)
    }
  }, [keyCombinations, callback, enabled])
}
