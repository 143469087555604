import { create } from "zustand"

type RenameFolderItem = {
  type: "folder"
  id: string
  name: string
  parentFolderId: string | null
}

type RenameWorkflowItem = {
  type: "macro"
  id: string
  name: string
}

type RenameStyleItem = {
  type: "style"
  id: string
  name: string
}

type RenameWildcardItem = {
  type: "wildcard"
  id: string
  name: string
}

type RenameComfyUIItem = {
  type: "comfyui"
  id: string
  name: string
}

type RenameItem =
  | RenameFolderItem
  | RenameWorkflowItem
  | RenameStyleItem
  | RenameWildcardItem
  | RenameComfyUIItem
  | null

interface RenameStore {
  renamingItem: RenameItem
  setRenamingItem: (renamingFolder: RenameItem) => void
}

export const useRenameItemStore = create<RenameStore>(set => ({
  renamingItem: null,
  setRenamingItem: renamingFolder => set({ renamingItem: renamingFolder }),
}))
