import client from "@/api/client"
import {
  GetRecipesResult,
  GetSubFoldersResponse,
  GetWorkflowsResult,
  PinnedItemType,
  PinnedItems,
  RecentFolderItem,
  RecentImageItem,
  RecentModelItem,
  RecentRecipeItem,
  RecentSDWorkflowItem,
  RecentStyleItem,
  RecentWildcardItem,
  RecentWorkflowItem,
  RecipeType,
  SearchImagesResponse,
  SearchModelResults,
  SearchSDStyleResponse,
  SearchSDWorkflowResponse,
  SearchWildcardResponse,
} from "@/api/sdk"
import { InfiniteData, useQueryClient } from "@tanstack/react-query"
import { createMutation } from "react-query-kit"
import { actionMiddleware } from "../middlewares/actionMiddleware"
import { useWorkspaceWorkflowInfiniteQuery } from "./useWorkspaceWorkflowInfiniteQuery"
import { useRecentWorkflowInfiniteQuery } from "@/components/Workspace/RecentV2/RecentWorkflow"
import { useWorkspaceImagesInfiniteQuery } from "./useWorkspaceImagesInfiniteQuery"
import { useRecentImagesInfiniteQuery } from "@/components/Workspace/RecentV2/RecentImages"
import { useFolderInfoQuery, useSubFoldersInfiniteQuery } from "./folder"
import { useRecentFolderInfiniteQuery } from "@/components/Workspace/RecentV2/RecentFolders"
import { useWorkspacePinInfiniteQuery } from "./useWorkspacePinInfiniteQuery"
import { useWorkspaceWorkflowDetailQuery } from "./workflow"
import { useWorkspaceStyleDetailQuery, useWorkspaceStyleInfiniteQuery } from "../tools/style/useGetStyleInfiniteQuery"
import { useRecentStylesInfiniteQuery } from "@/components/Workspace/RecentV2/RecentStyles"
import { useGetRecipesInfiniteQuery } from "./recipe"
import { useWorkspaceWildcardsInfiniteQuery } from "../tools/wildcard"
import { useRecentWildcardsInfiniteQuery } from "@/components/Workspace/RecentV2/RecentWildcards"
import {
  useComfyUiWorkflowDetailQuery,
  useComfyUiWorkflowInfiniteQuery,
} from "../tools/comfyui-recipe/getComfyUiRecipeQueries"
import { useRecentRecipesInfiniteQuery } from "@/components/Workspace/RecentV2/RecentRecipes"
import { useRecentComfyUIInfiniteQuery } from "@/components/Workspace/RecentV2/RecentComfyUI"
import { useWorkspaceModelDetailQuery, useWorkspaceModelsInfiniteQuery } from "../tools"
import { useRecentModelsInfiniteQuery } from "@/components/Workspace/RecentV2/RecentModels"

export interface UsePinMutationVariables {
  ids: string[]
  type: PinnedItemType
  isPinned: boolean
  folderId?: string
}

export const usePinMutation = createMutation({
  mutationFn: ({ ids, type, isPinned }: UsePinMutationVariables) => {
    if (isPinned) {
      return client.api.pinControllerPinItems({
        itemIds: ids,
        type,
      })
    }

    return client.api.pinControllerUnpinItems({
      itemIds: ids,
      type,
    })
  },
  use: [
    actionMiddleware({
      onSuccess: ({ queryClient, variables }) => {
        const workspacePinnedListKey = useWorkspacePinInfiniteQuery.getKey({
          type: variables.type,
        })

        const workspacePinnedListQueriesDataEntries = queryClient.getQueriesData<InfiniteData<PinnedItems[], number>>({
          queryKey: workspacePinnedListKey,
        })

        if (workspacePinnedListQueriesDataEntries) {
          workspacePinnedListQueriesDataEntries.forEach(([key, workspacePinnedListData]) => {
            if (workspacePinnedListData) {
              const updatedData = {
                ...workspacePinnedListData,
                pages: workspacePinnedListData.pages.map(page => {
                  return page.filter(
                    item =>
                      !variables.ids.includes(
                        item.folder?.id ||
                          item.workflow?.id ||
                          item.image?.id ||
                          item.recipe?.id ||
                          item.style?.id ||
                          item.wildcard?.id ||
                          item.sdWorkflow?.id ||
                          item.sdModel?.id ||
                          "",
                      ),
                  )
                }),
              }

              queryClient.setQueryData(key, updatedData)
            }
          })
        }

        if (variables.type === PinnedItemType.WORKFLOW) {
          const workflowListKey = useWorkspaceWorkflowInfiniteQuery.getKey()

          const workflowListQueriesDataEntries = queryClient.getQueriesData<InfiniteData<GetWorkflowsResult, number>>({
            queryKey: workflowListKey,
          })

          if (workflowListQueriesDataEntries) {
            workflowListQueriesDataEntries.forEach(([key, workflowListData]) => {
              if (workflowListData) {
                const updatedData = {
                  ...workflowListData,
                  pages: workflowListData.pages.map(page => {
                    return {
                      ...page,
                      workflows: page.workflows.map(workflow => {
                        if (variables.ids.includes(workflow.id)) {
                          return {
                            ...workflow,
                            pinned: variables.isPinned,
                          }
                        }

                        return workflow
                      }),
                    }
                  }),
                }

                queryClient.setQueryData(key, updatedData)
              }
            })
          }

          // Update workflow recent list

          const workflowRecentListKey = useRecentWorkflowInfiniteQuery.getKey()

          const workflowRecentListQueriesDataEntries = queryClient.getQueriesData<
            InfiniteData<RecentWorkflowItem[], number>
          >({
            queryKey: workflowRecentListKey,
          })

          if (workflowRecentListQueriesDataEntries) {
            workflowRecentListQueriesDataEntries.forEach(([key, workflowRecentListData]) => {
              if (workflowRecentListData) {
                const updatedData = {
                  ...workflowRecentListData,
                  pages: workflowRecentListData.pages.map(page => {
                    return [
                      ...page.map(item => {
                        if (variables.ids.includes(item.workflow.id)) {
                          return {
                            ...item,
                            workflow: {
                              ...item.workflow,
                              pinned: variables.isPinned,
                            },
                          }
                        }

                        return item
                      }),
                    ]
                  }),
                }

                queryClient.setQueryData(key, updatedData)
              }
            })
          }
        }
        if (variables.type === PinnedItemType.IMAGE) {
          if (variables.folderId) {
            const workspaceImagesKey = useWorkspaceImagesInfiniteQuery.getKey({
              folderId: variables.folderId,
            })
            const workspaceImagesQueriesDataEntries = queryClient.getQueriesData<
              InfiniteData<SearchImagesResponse, number>
            >({
              queryKey: workspaceImagesKey,
            })
            if (workspaceImagesQueriesDataEntries) {
              workspaceImagesQueriesDataEntries.forEach(([key, parentSubFoldersData]) => {
                if (parentSubFoldersData) {
                  const updatedData = {
                    ...parentSubFoldersData,
                    pages: parentSubFoldersData.pages.map(page => {
                      return {
                        ...page,
                        images: page.images.map(image => {
                          if (variables.ids.includes(image.id)) {
                            return {
                              ...image,
                              pinned: variables.isPinned,
                            }
                          }
                          return image
                        }),
                      }
                    }),
                  }
                  queryClient.setQueryData(key, updatedData)
                }
              })
            }

            // Update images recent list
            const imagesRecentListKey = useRecentImagesInfiniteQuery.getKey()

            const imagesRecentListQueriesDataEntries = queryClient.getQueriesData<
              InfiniteData<RecentImageItem[], number>
            >({
              queryKey: imagesRecentListKey,
            })

            if (imagesRecentListQueriesDataEntries) {
              imagesRecentListQueriesDataEntries.forEach(([key, imagesRecentListData]) => {
                if (imagesRecentListData) {
                  const updatedData = {
                    ...imagesRecentListData,
                    pages: imagesRecentListData.pages.map(page => {
                      return page.map(recent => {
                        if (variables.ids.includes(recent.image.id)) {
                          return {
                            ...recent,
                            image: {
                              ...recent.image,
                              pinned: variables.isPinned,
                            },
                          }
                        }
                        return recent
                      })
                    }),
                  }

                  queryClient.setQueryData(key, updatedData)
                }
              })
            }
          }
        }

        if (variables.type === PinnedItemType.FOLDER) {
          const parentSubFoldersKey = useSubFoldersInfiniteQuery.getKey({
            folderId: variables.folderId,
          })

          const parentSubFoldersQueriesDataEntries = queryClient.getQueriesData<
            InfiniteData<GetSubFoldersResponse, number>
          >({
            queryKey: parentSubFoldersKey,
          })

          if (parentSubFoldersQueriesDataEntries) {
            parentSubFoldersQueriesDataEntries.forEach(([key, parentSubFoldersData]) => {
              if (parentSubFoldersData) {
                const updatedData = {
                  ...parentSubFoldersData,
                  pages: parentSubFoldersData.pages.map(page => {
                    return {
                      ...page,
                      folders: page.folders.map(folder => {
                        if (variables.ids.includes(folder.id)) {
                          return {
                            ...folder,
                            pinned: variables.isPinned,
                          }
                        }

                        return folder
                      }),
                    }
                  }),
                }

                queryClient.setQueryData(key, updatedData)
              }
            })
          }

          // set query recent folder name
          const recentFoldersKey = useRecentFolderInfiniteQuery.getKey()

          const recentFoldersQueriesDataEntries = queryClient.getQueriesData<InfiniteData<RecentFolderItem[], number>>({
            queryKey: recentFoldersKey,
          })

          if (recentFoldersQueriesDataEntries) {
            recentFoldersQueriesDataEntries.forEach(([key, recentFoldersData]) => {
              if (recentFoldersData) {
                const updatedData = {
                  ...recentFoldersData,
                  pages: recentFoldersData.pages.map(page => {
                    return [
                      ...page.map(item => {
                        if (variables.ids.includes(item.folder.id)) {
                          return {
                            ...item,
                            folder: {
                              ...item.folder,
                              pinned: variables.isPinned,
                            },
                          }
                        }

                        return item
                      }),
                    ]
                  }),
                }
                queryClient.setQueryData(key, updatedData)
              }
            })
          }

          if (variables.folderId) {
            const folderInfoKey = useFolderInfoQuery.getKey({ folderId: variables.folderId })
            const folderInfoData = queryClient.getQueryData(folderInfoKey)

            if (folderInfoData) {
              queryClient.setQueryData(folderInfoKey, {
                ...folderInfoData,
                pinned: variables.isPinned,
              })
            }
          }
        }

        if (variables.type === PinnedItemType.RECIPE) {
          const recipeListKey = useGetRecipesInfiniteQuery.getKey()

          const recipeListQueriesDataEntries = queryClient.getQueriesData<InfiniteData<GetRecipesResult, number>>({
            queryKey: recipeListKey,
          })

          if (recipeListQueriesDataEntries) {
            recipeListQueriesDataEntries.forEach(([key, recipeListData]) => {
              if (recipeListData) {
                const updatedData = {
                  ...recipeListData,

                  pages: recipeListData.pages.map(page => {
                    return {
                      ...page,
                      recipes: page.recipes.map(recipe => {
                        if (variables.ids.includes(recipe.id)) {
                          return {
                            ...recipe,
                            pinned: variables.isPinned,
                          }
                        }

                        return recipe
                      }),
                    }
                  }),
                }

                queryClient.setQueryData(key, updatedData)
              }
            })
          }

          // Update recipes recent list
          const recipesRecentListKey = useRecentRecipesInfiniteQuery.getKey()

          const recipesRecentListQueriesDataEntries = queryClient.getQueriesData<
            InfiniteData<RecentRecipeItem[], number>
          >({
            queryKey: recipesRecentListKey,
          })

          if (recipesRecentListQueriesDataEntries) {
            recipesRecentListQueriesDataEntries.forEach(([key, recipesRecentListData]) => {
              if (recipesRecentListData) {
                const updatedData = {
                  ...recipesRecentListData,
                  pages: recipesRecentListData.pages.map(page => {
                    return [
                      ...page.map(item => {
                        if (variables.ids.includes(item.recipe.id)) {
                          return {
                            ...item,
                            recipe: {
                              ...item.recipe,
                              pinned: variables.isPinned,
                            },
                          }
                        }

                        return item
                      }),
                    ]
                  }),
                }

                queryClient.setQueryData(key, updatedData)
              }
            })
          }
        }

        // styles
        if (variables.type === PinnedItemType.STYLE) {
          const stylesListKey = useWorkspaceStyleInfiniteQuery.getKey()

          const stylesListQueriesDataEntries = queryClient.getQueriesData<InfiniteData<SearchSDStyleResponse, number>>({
            queryKey: stylesListKey,
          })

          if (stylesListQueriesDataEntries) {
            stylesListQueriesDataEntries.forEach(([key, stylesListData]) => {
              if (stylesListData) {
                const updatedData = {
                  ...stylesListData,
                  pages: stylesListData.pages.map(page => {
                    return {
                      ...page,
                      data: page.data.map(style => {
                        if (variables.ids.includes(style.id)) {
                          return {
                            ...style,
                            pinned: variables.isPinned,
                          }
                        }

                        return style
                      }),
                    }
                  }),
                }

                queryClient.setQueryData(key, updatedData)
              }
            })
          }

          // Update styles recent list
          const stylesRecentListKey = useRecentStylesInfiniteQuery.getKey()

          const stylesRecentListQueriesDataEntries = queryClient.getQueriesData<
            InfiniteData<RecentStyleItem[], number>
          >({
            queryKey: stylesRecentListKey,
          })

          if (stylesRecentListQueriesDataEntries) {
            stylesRecentListQueriesDataEntries.forEach(([key, stylesRecentListData]) => {
              if (stylesRecentListData) {
                const updatedData = {
                  ...stylesRecentListData,
                  pages: stylesRecentListData.pages.map(page => {
                    return [
                      ...page.map(item => {
                        if (variables.ids.includes(item.style.id)) {
                          return {
                            ...item,
                            style: {
                              ...item.style,
                              pinned: variables.isPinned,
                            },
                          }
                        }

                        return item
                      }),
                    ]
                  }),
                }

                queryClient.setQueryData(key, updatedData)
              }
            })
          }
        }

        // wildcards
        if (variables.type === PinnedItemType.WILDCARD) {
          const wildcardsListKey = useWorkspaceWildcardsInfiniteQuery.getKey()

          const wildcardsListQueriesDataEntries = queryClient.getQueriesData<
            InfiniteData<SearchWildcardResponse, number>
          >({
            queryKey: wildcardsListKey,
          })

          if (wildcardsListQueriesDataEntries) {
            wildcardsListQueriesDataEntries.forEach(([key, wildcardsListData]) => {
              if (wildcardsListData) {
                const updatedData = {
                  ...wildcardsListData,
                  pages: wildcardsListData.pages.map(page => {
                    return {
                      ...page,
                      wildcards: page.wildcards.map(wildcard => {
                        if (variables.ids.includes(wildcard.id)) {
                          return {
                            ...wildcard,
                            pinned: variables.isPinned,
                          }
                        }

                        return wildcard
                      }),
                    }
                  }),
                }

                queryClient.setQueryData(key, updatedData)
              }
            })
          }

          // Update wildcard recent list
          const wildcardsRecentListKey = useRecentWildcardsInfiniteQuery.getKey()

          const wildcardsRecentListQueriesDataEntries = queryClient.getQueriesData<
            InfiniteData<RecentWildcardItem[], number>
          >({
            queryKey: wildcardsRecentListKey,
          })

          if (wildcardsRecentListQueriesDataEntries) {
            wildcardsRecentListQueriesDataEntries.forEach(([key, wildcardsRecentListData]) => {
              if (wildcardsRecentListData) {
                const updatedData = {
                  ...wildcardsRecentListData,
                  pages: wildcardsRecentListData.pages.map(page => {
                    return [
                      ...page.map(item => {
                        if (variables.ids.includes(item.wildcard.id)) {
                          return {
                            ...item,
                            wildcard: {
                              ...item.wildcard,
                              pinned: variables.isPinned,
                            },
                          }
                        }

                        return item
                      }),
                    ]
                  }),
                }

                queryClient.setQueryData(key, updatedData)
              }
            })
          }
        }

        if (variables.type === PinnedItemType.SD_WORKFLOW) {
          const comfyuiListkey = useComfyUiWorkflowInfiniteQuery.getKey()

          const comfyuiListQueriesDataEntries = queryClient.getQueriesData<
            InfiniteData<SearchSDWorkflowResponse, number>
          >({
            queryKey: comfyuiListkey,
          })

          if (comfyuiListQueriesDataEntries) {
            comfyuiListQueriesDataEntries.forEach(([key, comfyuiListData]) => {
              if (comfyuiListData) {
                const updatedData = {
                  ...comfyuiListData,

                  pages: comfyuiListData.pages.map(page => {
                    return {
                      ...page,
                      workflows: page.workflows.map(workflow => {
                        if (variables.ids.includes(workflow.id)) {
                          return {
                            ...workflow,
                            pinned: variables.isPinned,
                          }
                        }

                        return workflow
                      }),
                    }
                  }),
                }

                queryClient.setQueryData(key, updatedData)
              }
            })
          }

          // Update comfyui recent list
          const comfyuiRecentListKey = useRecentComfyUIInfiniteQuery.getKey()

          const comfyuiRecentListQueriesDataEntries = queryClient.getQueriesData<
            InfiniteData<RecentSDWorkflowItem[], number>
          >({
            queryKey: comfyuiRecentListKey,
          })

          if (comfyuiRecentListQueriesDataEntries) {
            comfyuiRecentListQueriesDataEntries.forEach(([key, comfyuiRecentListData]) => {
              if (comfyuiRecentListData) {
                const updatedData = {
                  ...comfyuiRecentListData,
                  pages: comfyuiRecentListData.pages.map(page => {
                    return [
                      ...page.map(item => {
                        if (variables.ids.includes(item.sdWorkflow.id)) {
                          return {
                            ...item,
                            sdWorkflow: {
                              ...item.sdWorkflow,
                              pinned: variables.isPinned,
                            },
                          }
                        }

                        return item
                      }),
                    ]
                  }),
                }

                queryClient.setQueryData(key, updatedData)
              }
            })
          }
        }

        if (variables.type === PinnedItemType.MODEL) {
          const modelsListKey = useWorkspaceModelsInfiniteQuery.getKey()

          const modelsListQueriesDataEntries = queryClient.getQueriesData<InfiniteData<SearchModelResults, number>>({
            queryKey: modelsListKey,
          })

          if (modelsListQueriesDataEntries) {
            modelsListQueriesDataEntries.forEach(([key, modelsListData]) => {
              if (modelsListData) {
                const updatedData = {
                  ...modelsListData,
                  pages: modelsListData.pages.map(page => {
                    return {
                      ...page,
                      data: page.data.map(model => {
                        if (variables.ids.includes(model.id)) {
                          return {
                            ...model,
                            pinned: variables.isPinned,
                          }
                        }

                        return model
                      }),
                    }
                  }),
                }

                queryClient.setQueryData(key, updatedData)
              }
            })
          }

          // Update models recent list
          const modelsRecentListKey = useRecentModelsInfiniteQuery.getKey()

          const modelsRecentListQueriesDataEntries = queryClient.getQueriesData<
            InfiniteData<RecentModelItem[], number>
          >({
            queryKey: modelsRecentListKey,
          })

          if (modelsRecentListQueriesDataEntries) {
            modelsRecentListQueriesDataEntries.forEach(([key, modelsRecentListData]) => {
              if (modelsRecentListData) {
                const updatedData = {
                  ...modelsRecentListData,
                  pages: modelsRecentListData.pages.map(page => {
                    return [
                      ...page.map(item => {
                        if (variables.ids.includes(item.model.id)) {
                          return {
                            ...item,
                            model: {
                              ...item.model,
                              pinned: variables.isPinned,
                            },
                          }
                        }

                        return item
                      }),
                    ]
                  }),
                }

                queryClient.setQueryData(key, updatedData)
              }
            })
          }
        }
      },
    }),
    useMutationNext => {
      return (options, queryClient) => {
        const qc = useQueryClient(queryClient)

        return useMutationNext({
          ...options,
          onSettled: (_, __, { ids, type }) => {
            if (type === PinnedItemType.WORKFLOW) {
              const key = useWorkspaceWorkflowDetailQuery.getKey({ workflowId: ids[0] })
              qc.invalidateQueries({ queryKey: key })
            }

            if (type === PinnedItemType.STYLE) {
              const styleKey = useWorkspaceStyleDetailQuery.getKey({ styleId: ids[0] })
              qc.invalidateQueries({ queryKey: styleKey })
            }

            if (type === PinnedItemType.SD_WORKFLOW) {
              const comfyuiKey = useComfyUiWorkflowDetailQuery.getKey({ workflowId: ids[0] })
              qc.invalidateQueries({ queryKey: comfyuiKey })
            }

            if (type === PinnedItemType.MODEL) {
              const modelKey = useWorkspaceModelDetailQuery.getKey({ modelId: ids[0] })
              qc.invalidateQueries({ queryKey: modelKey })
            }
          },
        })
      }
    },
  ],
})
