import { IconProps } from "."

export const PasteIcon3 = (props: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M7.40016 2C4.96705 2.00438 3.69295 2.06411 2.87871 2.87836C2 3.75706 2 5.17132 2 7.99983C2 10.8283 2 12.2426 2.8787 13.1213C3.75741 14 5.17166 14 8.00017 14C10.8287 14 12.2429 14 13.1216 13.1213C13.9359 12.3071 13.9956 11.033 14 8.59984"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.68385 9.33429L8.68217 8.66795C8.67587 6.17691 8.67272 4.9314 9.3021 4.05578C9.51125 3.7648 9.76596 3.50945 10.0564 3.29956C10.9304 2.66797 12.1759 2.66797 14.667 2.66797M8.68385 9.33429C8.57544 9.33878 8.46636 9.30003 8.36921 9.21802C7.7649 8.70793 6.66699 7.38206 6.66699 7.38206M8.68385 9.33429C8.78107 9.33026 8.87776 9.29146 8.96487 9.21791C9.56906 8.70772 10.667 7.38206 10.667 7.38206"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default PasteIcon3
