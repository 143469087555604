import client from "@/api/client"
import { CreatorProfileItem } from "@/api/sdk"
import { googleAnalytics } from "@/lib/gtag"
import { useAuth } from "@/providers/authContext"
import { enableQueryMiddleware } from "@/queries/middlewares/enableQueryMiddleware"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import classNames from "classnames"
import { AnimatePresence, motion } from "framer-motion"
import useCustomRouter from "@/hooks/useCustomRouter"
import { useState } from "react"
import { createQuery } from "react-query-kit"
import LoadingLogo from "../LoadingLogo"
import ModalPortalExplore from "../ModalsPortal/ModalPortalExplore"
import BannerProfileUser from "../Profile/BannerProfileUser"
import ProfilePages from "../Profile/ProfilePages"
import ProfileUser from "../Profile/ProfileUser"
import ErrorProfile from "../Profile/shared/ErrorProfile"
import PrivateProfile from "../Profile/shared/PrivateProfile"
import SwitchModeModal from "../Profile/shared/SwitchModeModal"
import CommonNavbar from "../common/CommonNavbar"
import MobileNavigation from "../common/MobileNavigation"
import { menuCommonPages } from "./CommonLayout"
import { withLazyProviders } from "./with-lazy-providers"

export const useGetProfileByName = createQuery<CreatorProfileItem, { username: string }, Error>({
  primaryKey: "get-user-profiles",
  queryFn: ({ queryKey: [_primaryKey, variables] }) =>
    client.api.creatorProfileControllerGetCreatorProfileByUsername(variables.username).then(res => res.data),

  use: [enableQueryMiddleware(v => !!v.username)],
})

const ProfileLayout = ({ children }: { children: React.ReactNode }) => {
  const { userInfoQuery } = useAuth()
  const router = useCustomRouter()

  const { username } = router.query
  const isMe = userInfoQuery?.data?.username === (username as string)
  const qc = useQueryClient()

  const [modalState, setModalState] = useState({
    switchAccount: false,
  })

  const {
    data: dataUserProfile,
    isLoading: isLoadingUserProfile,
    error: errorProfile,
  } = useGetProfileByName({
    variables: {
      username: username as string,
    },
  })

  const { mutate, isPending } = useMutation({
    mutationFn: () => client.api.creatorProfileControllerToggleCreatorProfile(),
    onSuccess: () => {
      qc.invalidateQueries({ queryKey: ["get-user-profiles"] })
      setModalState({
        ...modalState,
        switchAccount: false,
      })
      googleAnalytics.event({
        action: "click",
        category: "profile_user_account",
        label: `toggle: user privacy mode`,
        params: {
          user_username: username?.toString() ?? "",
        },
        value: 1,
      })
    },
  })

  return (
    <main id="layout" className="relative flex flex-col min-h-screen dark:bg-black dark:text-atherGray-0">
      <SwitchModeModal
        isOpen={modalState.switchAccount}
        onClose={() =>
          setModalState({
            ...modalState,
            switchAccount: false,
          })
        }
        isLoading={isPending}
        mutateToggle={mutate}
      />
      <div
        className={classNames("flex flex-col w-full flex-1 pb-[5.15rem] md:pb-0", {
          "blur-sm": Object.values(modalState).some(value => value === true),
        })}
      >
        <CommonNavbar navItems={menuCommonPages} />
        {errorProfile !== null ? (
          <ErrorProfile message={(errorProfile as Error)?.message ?? ""} />
        ) : (
          <>
            <div className="relative flex flex-col items-center w-full pt-0 md:pt-4 pb-6 min-h-[15rem]">
              <BannerProfileUser isMe={isMe} dataUserProfile={dataUserProfile} />
              <div className="w-full md:px-4 flex flex-col items-center justify-center">
                <ProfileUser
                  isMe={isMe}
                  setModalState={v =>
                    setModalState({
                      ...modalState,
                      switchAccount: v,
                    })
                  }
                  modalState={modalState.switchAccount}
                  dataUserProfile={dataUserProfile}
                  isLoadingUserProfile={isLoadingUserProfile}
                  mutateToggle={mutate}
                  isLoadingMutateToggle={isPending}
                  pointBalance={userInfoQuery?.data?.wowPoints}
                />
                <div className="flex flex-col flex-1 w-full mt-2 md:mt-4">
                  <ProfilePages isMe={isMe} isPrivate={false} />
                  {!dataUserProfile?.isPrivate || isMe ? <>{children}</> : <PrivateProfile />}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <ModalPortalExplore />
      <MobileNavigation />
      <AnimatePresence initial={false} mode="wait">
        {isLoadingUserProfile ? (
          <motion.div
            key="loading"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { delay: 1 } }}
            exit={{ opacity: 0, transition: { duration: 0.3, delay: 1 } }}
            className="fixed top-0 left-0 w-full h-full z-[99] flex items-center justify-center bg-atherGray-900"
          >
            <LoadingLogo />
          </motion.div>
        ) : null}
      </AnimatePresence>
    </main>
  )
}

export default withLazyProviders(ProfileLayout)
