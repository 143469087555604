import client from "@/api/client"
import { SearchWildcardDto, SearchWildcardResponse, WildcardDetail } from "@/api/sdk"
import { createAuthenticatedInfiniteQuery } from "@/queries/createAuthenticatedQuery"

export const useWorkspaceWildcardsInfiniteQuery = createAuthenticatedInfiniteQuery<
  SearchWildcardResponse,
  SearchWildcardDto,
  Error,
  WildcardDetail
>({
  primaryKey: "wildcard-list",
  queryFn: ({ queryKey: [_primaryKey, variables], pageParam = 0 }) =>
    client.api
      .wildcardControllerList({
        skip: pageParam * (variables.take ?? 20),
        take: variables.take ?? 20,
        searchTerm: variables.searchTerm,
        ids: variables.ids,
        isPinned: variables.isPinned,
        sortBy: variables.sortBy,
        mode: variables.mode,
        includePublic: variables.includePublic,
      })
      .then(res => res.data),
  flattenFn: data => data.wildcards,
  getTotalFn: data => data.total,
  getNextPageParam: (lastPage, pages) => (lastPage && lastPage?.wildcards.length < 20 ? undefined : pages.length),
  initialPageParam: 0,
  refetchOnWindowFocus: false,
})
