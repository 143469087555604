import client from "@/api/client"
import { ComfyRoomConnectedUser } from "@/api/sdk"
import { createQuery } from "react-query-kit"
import { enableQueryMiddleware } from "./middlewares/enableQueryMiddleware"

export const useUsersConnectComfyQuery = createQuery<ComfyRoomConnectedUser[], { workflowId: string }, Error>({
  primaryKey: "users-connect-comfy",
  queryFn: ({ queryKey: [_primaryKey, variables] }) =>
    client.api.comfyUiControllerGetConnectedUsers(variables.workflowId).then(res => res.data),

  use: [enableQueryMiddleware(v => !!v.workflowId)],
})
