import { RecipeItem } from "@/api/sdk"
import ImageWebpComponent from "@/components/ImageWebpComponent"
import Skeleton from "@/components/Skeleton"
import { cdnUrl } from "@/constants"
import { googleAnalytics } from "@/lib/gtag"
import { useAuth } from "@/providers/authContext"
import format from "date-fns/format"
import useCustomRouter from "@/hooks/useCustomRouter"
import { useState } from "react"
import { useInView } from "react-intersection-observer"
import ActionButtonsRecipe from "../../Recipes/ActionButtonsRecipe"
import UserCreated from "@/components/Explore/Gallery/UserCreated"
import classNames from "classnames"

interface RecipeItemListListProps {
  recipe: RecipeItem
  accessedAt?: string
  onClick?: () => void
  href?: string
}

const RecipeItemList = ({ recipe, accessedAt, onClick, href }: RecipeItemListListProps) => {
  const [imageLoaded, setImageLoaded] = useState(false)
  const { user } = useAuth()
  const { ref, inView } = useInView({
    threshold: 0,
    rootMargin: "800px 0px 800px 0px",
  })

  const router = useCustomRouter()

  const handleClick = () => {
    googleAnalytics.event({
      action: "click",
      category: "recipe_item",
      label: `view_recipe_detail`,
      params: {
        recipe_id: recipe.id,
        recipe_name: recipe.name,
        pathname: window.location.pathname,
      },
    })

    router.push(`/workspace/tools/recipes/${recipe.id}`, undefined, { shallow: true })
  }

  return (
    <div
      onClick={handleClick}
      className="w-full flex items-center text-sm text-atherPrimary-100 bg-atherGray-900 rounded-lg"
      ref={ref}
    >
      <div className="flex-1 md:flex-none md:w-[30%] py-2 pr-0 pl-2 md:pr-2 cursor-pointer">
        <div className="flex items-center overflow-hidden">
          <Skeleton
            className="mr-2  h-[3rem] w-[3rem] flex items-center overflow-hidden justify-center bg-atherGray-900"
            isLoaded={imageLoaded}
          >
            {inView && (
              <ImageWebpComponent
                src={recipe.thumbnail}
                alt={recipe.name}
                className={classNames("block max-h-full object-contain", {})}
                onLoad={() => setImageLoaded(true)}
              />
            )}
          </Skeleton>
          <div className="overflow-hidden flex flex-col flex-1">
            <p className="font-semibold flex-1 line-clamp-1">{recipe.name}</p>
            <div className="block md:hidden truncate text-atherGray-500">
              Opened - {accessedAt ? format(new Date(accessedAt), "HH:mm dd/MM/yyyy") : ""}
            </div>
          </div>
        </div>
      </div>
      <div className="hidden md:block md:w-[15%] p-2 whitespace-nowrap truncate">Recipe</div>
      <div className="hidden md:block md:w-[15%] p-2 whitespace-nowrap truncate">
        {accessedAt ? format(new Date(accessedAt), "HH:mm dd/MM/yyyy") : ""}
      </div>
      <div className="hidden md:block md:w-[15%] p-2">
        <UserCreated
          size="sm"
          picture={recipe.creator?.picture}
          name={recipe.creator?.name}
          isMe={user?.uid === recipe.creator?.uid}
        />
      </div>
      <div
        className="hidden md:block md:w-[20%] p-2 overflow-hidden cursor-pointer"
        onClick={e => {
          e.stopPropagation()
        }}
      ></div>
      <div className="md:w-[5%] mr-2 md:p-2 flex justify-end items-center">
        <ActionButtonsRecipe layout="list" recipe={recipe} menuClassName="bg-transparent hover:bg-transparent" />
      </div>
    </div>
  )
}

export default RecipeItemList
