import { SDWorkflowFilterMode, SDWorkflowSortOption } from "@/api/sdk"
import IconButton from "@/components/IconButton"
import Popover from "@/components/Popover"
import { FilterIcon, SortIcon } from "@/components/shared/icons"
import { cn } from "@/utils/cn"
import classNames from "classnames"
import { useState } from "react"
import SearchBar from "../../Workspace/FoldersV2/SearchBar"
import { filterDiscoverAtOptions } from "@/components/Workspace/Workflows/WorkflowTabActions"
import CheckboxComponent from "@/components/Checkbox"
import { sdWorkflowSortOptions } from "./ComfyUITabActions"

interface ComfyUITabActionsSlimProps {
  mode: SDWorkflowFilterMode
  sort: SDWorkflowSortOption
  setSort: (sort: SDWorkflowSortOption) => void
  className?: string
  filter: {
    shared?: string[]
  } | null
  setFilter: React.Dispatch<React.SetStateAction<{ shared?: string[] } | null>>
  search: string
  setSearch: React.Dispatch<React.SetStateAction<string>>
}

const ComfyUITabActionsSlim = ({
  mode,
  sort: sortBy,
  setSort,
  className,
  filter,
  setFilter,
  search,
  setSearch,
}: ComfyUITabActionsSlimProps) => {
  const [filterDropdown, setFilterDropdown] = useState(false)

  const [sortDropdown, setSortDropdown] = useState(false)

  const handleSetSort = (newSortBy: SDWorkflowSortOption) => {
    if (newSortBy === sortBy) return

    setSort(newSortBy)
    setSortDropdown(false)
  }

  return (
    <div className={cn("flex flex-1 justify-end md:space-x-2", className)}>
      <SearchBar
        search={search}
        setSearch={setSearch}
        inputClassName="w-full"
        className="w-full"
        containerClassName="w-full"
        onConfirmSearch={() => {}}
        onClear={() => setSearch("")}
      />
      <Popover
        isOpen={filterDropdown}
        setIsOpen={setFilterDropdown}
        trigger={
          <IconButton
            colorScheme="transparent"
            className={cn("rounded-2xl px-2 hover:bg-atherGray-85", {
              "bg-atherGray-850": filterDropdown,
              "border border-atherPurple-500": !!filter?.shared?.length,
              hidden: mode === SDWorkflowFilterMode.Trash,
            })}
          >
            <FilterIcon />
          </IconButton>
        }
        align="end"
      >
        <div className="p-3 rounded-2xl bg-atherGray-900 border border-atherGray-800 flex min-w-40 flex-col space-y-2">
          <div className="flex flex-col">
            <div className="flex w-full items-center justify-between mb-2">
              <p className="text-xs text-atherGray-100 font-semibold">Filter</p>
            </div>
            <div className={"flex items-center space-x-4 text-sm"}>
              {filterDiscoverAtOptions.map(option => (
                <CheckboxComponent
                  key={option.value}
                  label={option.display}
                  checked={filter?.shared?.includes(option.value) ?? false}
                  onChange={value => {
                    setFilter(prev => {
                      if (prev?.shared?.includes(option.value)) {
                        return {
                          shared: prev.shared?.filter(i => i !== option.value),
                        }
                      }
                      return {
                        shared: [...(prev?.shared ?? []), option.value],
                      }
                    })
                  }}
                />
              ))}
            </div>
          </div>
        </div>
      </Popover>
      <Popover
        isOpen={sortDropdown}
        setIsOpen={setSortDropdown}
        trigger={
          <IconButton
            colorScheme="transparent"
            className={cn("rounded-2xl px-2 hover:bg-atherGray-85", {
              "bg-atherGray-850": sortDropdown,
              "border border-atherPurple-500": sortBy !== SDWorkflowSortOption.NEWEST,
            })}
          >
            <SortIcon />
          </IconButton>
        }
        align="end"
        className="origin-top-right"
      >
        <div className="p-3 rounded-2xl bg-atherGray-900 border border-atherGray-800 flex w-40 flex-col space-y-2">
          {sdWorkflowSortOptions.map(option => (
            <IconButton
              onClick={() => handleSetSort(option.sortBy)}
              key={option.label}
              className={classNames(
                "flex items-center text-left hover:bg-atherGray-850 py-2 justify-start px-4 active:bg-atherGray-800 bg-transparent w-full cursor-pointer rounded-xl",
                {
                  "bg-atherGray-850 text-atherGray-0": sortBy === option.sortBy,
                  "text-atherGray-300": sortBy !== option.sortBy,
                },
              )}
            >
              {option.label}
            </IconButton>
          ))}
        </div>
      </Popover>
    </div>
  )
}

export default ComfyUITabActionsSlim
