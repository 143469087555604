import client from "@/api/client"
import {
  GetWorkspaceMembersResult,
  SortOrder,
  WorkspaceMemberItem,
  WorkspaceMembersSortByOptions,
  WorkspaceRole,
} from "@/api/sdk"
import { createAuthenticatedInfiniteQuery } from "../createAuthenticatedQuery"
import { enableInfiniteQueryMiddleware } from "../middlewares/enableQueryMiddleware"
import { createMutation } from "react-query-kit"

export const useWorkspaceMembersInfiniteQuery = createAuthenticatedInfiniteQuery<
  GetWorkspaceMembersResult,
  {
    workspaceId: string
    searchTerm?: string
    roles?: WorkspaceRole[]
    take?: number
    sortBy?: WorkspaceMembersSortByOptions
    sortOrder?: SortOrder
    emails?: string[]
  },
  Error,
  WorkspaceMemberItem
>({
  primaryKey: "members-list-workspace",
  initialPageParam: 0,
  queryFn: ({ pageParam = 0, queryKey: [_, { workspaceId, ...variables }] }) =>
    client.api
      .workspaceControllerGetWorkspaceMembers(workspaceId, {
        skip: pageParam * 20,
        take: variables.take ?? 20,
        ...variables,
      })
      .then(res => res.data),
  getNextPageParam: (lastPage, pages) => (lastPage && lastPage.members.length < 20 ? undefined : pages.length),
  flattenFn: data => data.members,
  getTotalFn: data => data.total,
  use: [enableInfiniteQueryMiddleware(i => !!i.workspaceId)],
})

export const useWorkspaceMembersMutate = createMutation({
  mutationFn: async (variables: { workspaceId: string; take?: number; emails?: string[] }) =>
    client.api
      .workspaceControllerGetWorkspaceMembers(variables.workspaceId, {
        emails: variables.emails,
        take: variables.take,
        skip: 0,
      })
      .then(res => res.data),
})
