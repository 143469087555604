import { googleAnalytics } from "@/lib/gtag"
import classNames from "classnames"
import useCustomRouter from "@/hooks/useCustomRouter"
import TabsList from "../Workspace/FoldersV2/TabsList"
import { BookmarkIcon1, FavoriteIcon, ImageSparkIcon } from "../shared/icons"

const tabs = [
  {
    title: "Creation",
    id: "creation",
    icon: ImageSparkIcon,
  },
  {
    title: "Favorited",
    id: "favorited",
    icon: FavoriteIcon,
  },
  {
    title: "Bookmarked",
    id: "bookmarked",
    icon: BookmarkIcon1,
  },
]

interface ProfilePageProps {
  isPrivate?: boolean
  isMe?: boolean
}

const ProfilePages = ({ isPrivate = true, isMe }: ProfilePageProps) => {
  const router = useCustomRouter()
  const { username } = router.query

  const page = router.pathname.split("/")[3] ?? "creation"

  return (
    <div className="flex flex-col items-center px-4 md:px-4">
      <div className="flex flex-col items-center flex-1 w-full">
        <div className="w-full">
          <TabsList
            className={classNames("m-auto", {
              "pointer-events-none opacity-50": isPrivate,
            })}
            tabQuery={page}
            tabsData={tabs.filter(i => isMe || (i.id !== "favorited" && i.id !== "bookmarked"))}
            onChange={tab => {
              router.replace(`/profile/${username}/${tab}`, undefined, { shallow: true, scroll: false })

              googleAnalytics.handleCategoryEvent({
                action: "click",
                params: {
                  action: "Change profile page",
                  page_name: tab,
                },
              })
            }}
          />
        </div>
        <div className="flex-1"></div>
      </div>
    </div>
  )
}

export default ProfilePages
