import { RecipeFilterMode, RecipeItem, RecipeSortOptions, RecipeType } from "@/api/sdk"
import Input from "@/components/Input"
import { ChevronDownIcon } from "@/components/shared/icons"
import { useDebounce, useOutsideClick } from "@/hooks"
import { useGetRecipesInfiniteQuery, useGetRecipesNotAuthInfiniteQuery } from "@/queries/workspace/recipe"
import classNames from "classnames"
import { AnimatePresence, motion } from "framer-motion"
import React, { useEffect, useMemo, useRef, useState } from "react"
import InfiniteScroll from "react-infinite-scroller"
import TabsList from "../../FoldersV2/TabsList"
import { useAuth } from "@/providers/authContext"
import ReactPortal from "@/components/ReactPortal"
import { cn } from "@/lib/utils"
import { SIMPLE_GENERATE_RECIPE_ID } from "@/components/Explore/Gallery/GalleryHeader/GalleryGeneratedImage"

interface RecipeSelectProps {
  onClose: () => void
  isOpen: boolean
  selectedRecipe?: RecipeItem | { id: string; isPublic: boolean }
  setSelectedRecipe: (recipe: RecipeItem, params?: Record<string, any>) => void
  isLayoutOutside?: boolean
  className?: string
  disabledOutsideClick?: boolean
  title?: React.ReactNode
}

const tabs: { id: RecipeFilterMode; title: string }[] = [
  {
    title: "GAIA Recipes",
    id: RecipeFilterMode.Public,
  },
  {
    title: "My Recipes",
    id: RecipeFilterMode.Owned,
  },
]

export const defaultRecipeIds = [
  "image-generator-simple",
  "image-generator",
  "image-generator-sdxl",
  "character-design-concept",
  "text-to-gif",
]

export const recipeNotAuth = [
  "upscaler",
  "details-enhancer",
  "remix",
  "miss-universe",
  "90s-style-transformer",
  "logo-art",
  "anime-style-transformer",
  "qrcode-art",
  "commercial-photoshoot",
  "spookifyme",
]

const RecipeSelect = ({
  selectedRecipe,
  className,
  title,
  disabledOutsideClick,
  onClose,
  setSelectedRecipe,
  isOpen,
  isLayoutOutside = true,
}: RecipeSelectProps) => {
  const [search, setSearch] = useState("")
  const [mode, setMode] = useState(RecipeFilterMode.Public)
  const scrollRef = useRef<HTMLDivElement>(null)
  const { user, loading } = useAuth()
  const [enabled, setEnabled] = useState(false)

  useEffect(() => {
    if (isOpen) {
      setEnabled(true)
    }
  }, [isOpen])

  const variables = useMemo(() => {
    return {
      searchTerm: search,
      types: [RecipeType.Normal, RecipeType.Describe],
      mode,
      recipeIds: user ? undefined : recipeNotAuth,
    }
  }, [search, mode, user])

  const searchDebounce = useDebounce(variables, 350)

  const {
    flattenData: flattenDataAuth = [],
    isFetching: isFetchingAuth,
    hasNextPage: hasNextPageAuth,
    fetchNextPage: fetchNextPageAuth,
  } = useGetRecipesInfiniteQuery({
    variables: searchDebounce,
    enabled: !!user && !loading && enabled,
  })

  useEffect(() => {
    if (selectedRecipe) {
      if (selectedRecipe.isPublic) {
        setMode(RecipeFilterMode.Public)
      } else {
        setMode(RecipeFilterMode.Owned)
      }
    }
  }, [selectedRecipe])

  const {
    data: dataNotAuth,
    isFetching: isFetchingNotAuth,
    hasNextPage: hasNextPageNotAuth,
    fetchNextPage: fetchNextPageNotAuth,
  } = useGetRecipesNotAuthInfiniteQuery({
    variables: searchDebounce,
    enabled: !user && !loading && enabled,
  })

  const { data: defaultRecipes } = useGetRecipesNotAuthInfiniteQuery({
    variables: {
      take: 10,
      mode: RecipeFilterMode.Public,
      sortBy: RecipeSortOptions.OLDEST,
      recipeIds: defaultRecipeIds,
    },
    enabled: !loading && enabled,
  })

  const defaultRecipesFlatten = useMemo(() => {
    return mode === "public"
      ? defaultRecipes?.pages
          .flatMap(page => page.recipes)
          .sort((a, b) => {
            if (a.id === SIMPLE_GENERATE_RECIPE_ID) return -1
            if (b.id === SIMPLE_GENERATE_RECIPE_ID) return 1
            return 0
          }) ?? []
      : []
  }, [defaultRecipes, mode])

  const flattenData = useMemo(() => {
    return user ? flattenDataAuth : dataNotAuth?.pages.flatMap(page => page.recipes) ?? []
  }, [user, flattenDataAuth, dataNotAuth])

  const mappedRecipes = useMemo(() => {
    if (search !== "") return flattenData

    return [...defaultRecipesFlatten, ...flattenData.filter(i => !defaultRecipeIds.includes(i.id))]
  }, [defaultRecipesFlatten, flattenData, search])

  const isFetching = user ? isFetchingAuth : isFetchingNotAuth
  const hasNextPage = user ? hasNextPageAuth : hasNextPageNotAuth
  const fetchNextPage = user ? fetchNextPageAuth : fetchNextPageNotAuth

  const ref = useRef(null)

  useOutsideClick({
    ref: ref,
    enabled: isOpen && !disabledOutsideClick,
    handler: () => {
      if (disabledOutsideClick) return
      onClose()
    },
  })

  const renderUI = (children: React.ReactNode) => {
    if (isLayoutOutside) {
      return <ReactPortal wrapperId="layout-inside">{children}</ReactPortal>
    }

    return children
  }

  return (
    <>
      {renderUI(
        <AnimatePresence>
          {isOpen && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{
                ease: "easeInOut",
                duration: 0.2,
              }}
              className={cn(
                "fixed top-0 md:top-[4.5rem] pb-[5.15rem] pt-4 md:pb-4 height-full-screen w-[88vw] md:w-80 md:h-[calc((var(--vh,1vh)*100)-4.5rem)] left-0 md:left-[20rem] bg-atherGray-900 flex flex-col overflow-hidden z-[25] md:z-[2]",
                className,
              )}
            >
              <div ref={ref} className="flex-1 w-full overflow-hidden flex flex-col">
                {title || (
                  <div className="flex px-4 mb-2">
                    <div onClick={onClose} className="cursor-pointer flex items-center">
                      <div className="w-4 mr-1">
                        <ChevronDownIcon className="rotate-90" />
                      </div>
                      <h3 className="font-semibold text-xl">Recipes</h3>
                    </div>
                  </div>
                )}
                <div className="px-4 mb-4">
                  <TabsList
                    tabQuery={mode}
                    tabsData={tabs.filter(tab => (!user ? tab.id !== RecipeFilterMode.Owned : true))}
                    tabClassName="flex-1 justify-center text-sm"
                    onChange={tab => setMode(tab as any)}
                  />
                </div>
                <div className="px-4 mb-4">
                  <Input
                    className="bg-atherGray-850 text-sm"
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                    placeholder="Find recipe ..."
                  />
                </div>
                <div ref={scrollRef} className="flex-1 overflow-auto">
                  <InfiniteScroll
                    loadMore={() => fetchNextPage()}
                    hasMore={!!hasNextPage && !isFetching}
                    useWindow={false}
                    getScrollParent={() => scrollRef.current!}
                    threshold={200}
                    className="w-full"
                  >
                    <div className="grid grid-cols-2 gap-3 px-4">
                      {mappedRecipes.map(recipe => (
                        <div
                          className="cursor-pointer"
                          key={recipe.id}
                          onClick={() => {
                            setSelectedRecipe(recipe)
                            onClose()
                          }}
                        >
                          <div className="relative">
                            <img
                              src={recipe.thumbnail}
                              alt=""
                              style={{
                                objectPosition: "0% 70%",
                              }}
                              className="object-cover rounded-lg h-[4rem] w-full"
                            />
                            {selectedRecipe?.id === recipe.id && (
                              <div className="absolute top-0 left-0 w-full h-full border-[2px] pointer-events-none rounded-lg border-atherPurple-500" />
                            )}
                          </div>
                          <p className={classNames("text-xs font-semibold mt-1 text-atherGray-100")}>{recipe.name}</p>
                        </div>
                      ))}
                    </div>
                  </InfiniteScroll>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>,
      )}
    </>
  )
}

export default RecipeSelect
