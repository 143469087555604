import { PinnedItemType, SDWorkflowDetail } from "@/api/sdk"
import IconButton from "@/components/IconButton"
import {
  PinIcon,
  ThreeDotHorizontalIcon,
  OpenNewTabIcon,
  UnPinIcon,
  DeleteIcon,
  RefreshIcon,
  EditIcon,
  FlowSquareIcon,
  LayerAddIcon,
  ShareIcon8,
} from "@/components/shared/icons"
import { cn } from "@/utils/cn"
import classNames from "classnames"
import React, { Fragment, useState } from "react"
import Popover from "@/components/Popover"
import { usePinMutation } from "@/queries"
import { useToast } from "@/hooks"
import { useDeleteItemStore, useRecoverItemStore, useRenameItemStore } from "@/stores"
import { googleAnalytics } from "@/lib/gtag"
import { useAuth } from "@/providers/authContext"
import useModalStore from "@/lib/store"
import FolderActionRow from "@/components/Workspace/FolderActions/FolderActionRow"
import { useDuplicateComfyUiWorkflowMutation } from "@/queries/tools/comfyui-recipe/updateComfyUiRecipeMutations"
import useCustomRouter from "@/hooks/useCustomRouter"

interface ActionButtonsRecipeProps {
  recipe: SDWorkflowDetail & { pinned?: boolean }
  menuClassName?: string
  layout?: "grid" | "list"
}

const ActionButtonsComfyUI = ({ recipe, layout = "grid", menuClassName }: ActionButtonsRecipeProps) => {
  const { pinned, id } = recipe
  const { user } = useAuth()
  const [isOpen, setIsOpen] = useState(false)
  const toast = useToast()
  const setDeletingItem = useDeleteItemStore(state => state.setDeletingItem)
  const setSharingData = useModalStore(state => state.setSharingData)
  const setRecoverItem = useRecoverItemStore(state => state.setRecoverItem)
  const setRename = useRenameItemStore(state => state.setRenamingItem)
  const isDisabled = user?.uid !== recipe.creator?.uid
  const { openNewTab } = useCustomRouter()

  const { mutate: mutatePin, isPending: isPendingPin } = usePinMutation({
    onSuccess: () => {
      toast({
        status: "success",
        title: pinned ? "ComfyUI unpinned" : "ComfyUI pinned",
      })
    },
  })

  const handlePin = () => {
    googleAnalytics.event({
      action: "click",
      category: "comfyui_item",
      label: `pin-item`,
      params: {
        comfyui_id: recipe.id,
        comfyui_name: recipe.name,
        pathname: window.location.pathname,
      },
    })

    mutatePin({
      ids: [id],
      type: PinnedItemType.SD_WORKFLOW,
      isPinned: !pinned,
    })
  }

  const handleNewTab = () => {
    googleAnalytics.event({
      action: "click",
      category: "comfyui_item",
      label: `open-in-new-tab`,
      params: {
        comfyui_id: recipe.id,
        comfyui_name: recipe.name,
        pathname: window.location.pathname,
      },
    })
    setTimeout(() => {
      openNewTab(`/workspace/tools/comfyui/${recipe.id}`)
    }, 150)
  }

  const handleDelete = () => {
    googleAnalytics.event({
      action: "click",
      category: "comfyui_item",
      label: `delete-item`,
      params: {
        comfyui_id: recipe.id,
        comfyui_name: recipe.name,
        pathname: window.location.pathname,
      },
    })

    setDeletingItem({
      type: "comfyui-workflow",
      id: recipe.id,
      name: recipe.name,
      deletedAt: recipe.deletedAt,
    })
  }

  const handleShare = () => {
    googleAnalytics.event({
      action: "click",
      category: "comfyui_item",
      label: `share_comfyui`,
      params: {
        comfyui_id: recipe.id,
        comfyui_name: recipe.name,
        pathname: window.location.pathname,
      },
    })

    setSharingData({
      id: recipe.id,
      name: recipe.name ?? recipe.id,
      type: "comfyui-workflow",
      workspaceId: recipe.workspace?.id,
      creator: {
        ...recipe.creator,
        uid: recipe.creator?.uid ?? "",
      },
      link: `${window.location.origin}/workspace/tools/comfyui/${recipe.id}`,
    })
  }

  const handleRecovery = () => {
    googleAnalytics.event({
      action: "click",
      category: "comfyui_item",
      label: "recover-comfyui",
      params: {
        comfyui_id: recipe.id,
        comfyui_name: recipe.name ?? recipe.id,
        pathname: window.location.pathname,
      },
    })

    setRecoverItem({
      ids: [recipe.id],
      type: "comfyui-workflow",
      deletedAt: recipe.deletedAt,
    })
  }

  const handleRename = () => {
    googleAnalytics.event({
      action: "click",
      category: "comfyui_item",
      label: "rename_comfyui",
      params: {
        comfyui_id: recipe.id,
        comfyui_name: recipe.name ?? recipe.id,
        pathname: window.location.pathname,
      },
    })

    setRename({
      type: "comfyui",
      id: recipe.id,
      name: recipe.name || "My ComfyUI",
    })
  }

  const { mutate: mutateDuplicate, isPending: isDuplicating } = useDuplicateComfyUiWorkflowMutation({
    onSuccess(data) {
      setTimeout(() => {
        openNewTab(`/workspace/tools/comfyui/${data.id}`)
      }, 1)
    },
    onError() {
      toast({
        status: "error",
        title: "Error",
        message: ["Something went wrong"],
      })
    },
  })

  const handleDuplicate = () => {
    if (!recipe) return
    googleAnalytics.event({
      action: "click",
      category: "comfyui_item",
      label: `duplicate_comfyui_workflow`,
      params: {
        comfyui_id: recipe.id,
        comfyui_name: recipe.name ?? name,
        pathname: window.location.pathname,
      },
    })
    mutateDuplicate({ workflowId: recipe.id })
  }

  const actions = [
    {
      label: isDisabled ? "Try this comfyUI" : "Duplicate",
      icon: isDisabled ? <FlowSquareIcon width={16} height={16} /> : <LayerAddIcon width={16} height={16} />,
      onClick: handleDuplicate,
      visible: recipe.capabilities?.canView && !recipe.deletedAt,
      enabled: recipe.capabilities?.canView,
      isLoading: isDuplicating,
    },
    {
      label: "Rename",
      icon: <EditIcon width={16} height={16} />,
      onClick: handleRename,
      visible: recipe.capabilities?.canUpdate && !recipe.deletedAt && !isDisabled,
      enabled: recipe.capabilities?.canUpdate,
    },
    {
      label: "Share",
      icon: <ShareIcon8 width={16} height={16} />,
      onClick: handleShare,
      visible: !recipe.deletedAt && !recipe.isDraft && recipe.capabilities?.canShare,
      enabled: recipe.capabilities?.canShare,
    },
    {
      label: recipe.pinned ? "Unpin" : "Pin",
      icon: recipe.pinned ? <UnPinIcon width={16} height={16} /> : <PinIcon width={16} height={16} />,
      onClick: handlePin,
      visible: !recipe.deletedAt,
      enabled: recipe.capabilities?.canView,
    },
    {
      label: "Open in new tab",
      icon: <OpenNewTabIcon width={16} height={16} />,
      onClick: handleNewTab,
      visible: !recipe.deletedAt,
      enabled: recipe.capabilities?.canView,
    },
    {
      label: "Recover",
      icon: <RefreshIcon className="rotate-180" width={16} height={16} />,
      onClick: handleRecovery,
      visible: !!recipe.deletedAt,
      enabled: recipe.capabilities?.canRecover,
    },
    {
      label: "Delete",
      icon: <DeleteIcon className="text-red-500" width={16} height={16} />,
      onClick: handleDelete,
      visible: recipe.capabilities?.canDelete,
      enabled: recipe.capabilities?.canDelete,
    },
  ]

  return (
    <Fragment>
      <div
        className={cn(
          "transition-opacity duration-300",
          {
            "opacity-100 lg:opacity-100": isOpen,
            "absolute top-1.5 right-1.5 opacity-100 lg:opacity-0 group-hover:opacity-100": layout === "grid",
          },
          menuClassName,
        )}
      >
        <Popover
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          trigger={
            <IconButton
              onClick={e => {
                e.stopPropagation()
              }}
              colorScheme="secondary"
              className={classNames("bg-blackAlpha-700 px-2 min-h-[1.5rem]", {
                "bg-transparent hover:bg-transparent": menuClassName?.includes("bg-transparent"),
              })}
            >
              <ThreeDotHorizontalIcon width={14} height={14} />
            </IconButton>
          }
          align="end"
        >
          <div
            className={
              "text-sm w-[12.5rem] text-atherGray-300 bg-atherGray-900 border border-atherGray-850 p-2 rounded-2xl space-y-1"
            }
          >
            {actions
              .filter(a => a.visible)
              .map(action => (
                <FolderActionRow
                  key={action.label}
                  iconLeft={action.icon}
                  onClick={e => {
                    e.stopPropagation()
                    action.onClick()
                    setIsOpen(false)
                  }}
                  isDisabled={!action.enabled}
                >
                  {action.label}
                </FolderActionRow>
              ))}
          </div>
        </Popover>
      </div>
    </Fragment>
  )
}

export default ActionButtonsComfyUI
