import * as React from "react"

interface AspectRatioProps extends React.ComponentProps<"div"> {
  ratio?: number
  containerStyle?: React.CSSProperties
}

const AspectRatio = React.forwardRef<HTMLDivElement, AspectRatioProps>((props, forwardedRef) => {
  const { ratio = 1 / 1, style, containerStyle, ...aspectRatioProps } = props
  return (
    <div
      style={{
        // ensures inner element is contained
        position: "relative",
        // ensures padding bottom trick maths works
        width: "100%",
        paddingBottom: `${100 / ratio}%`,
        ...containerStyle,
      }}
      data-radix-aspect-ratio-wrapper=""
    >
      <div
        {...aspectRatioProps}
        ref={forwardedRef}
        style={{
          ...style,
          // ensures children expand in ratio
          position: "absolute",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        }}
      />
    </div>
  )
})

export default AspectRatio
