import { IconProps } from "."

export const ScienceIcon = (props: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="22" viewBox="0 0 18 22" fill="none" {...props}>
      <path
        d="M11.5405 1V3.48622C11.5405 5.23417 11.5405 6.10814 11.7545 6.94715C11.9685 7.78616 12.3879 8.55654 13.2267 10.0973L14.3633 12.1852C16.5008 16.1115 17.5696 18.0747 16.6928 19.53L16.6792 19.5522C15.7896 21 13.5264 21 9 21C4.47357 21 2.21036 21 1.3208 19.5522L1.30725 19.53C0.430447 18.0747 1.49918 16.1115 3.63666 12.1852L4.7733 10.0973C5.61209 8.55654 6.03149 7.78616 6.24548 6.94715C6.45947 6.10814 6.45947 5.23417 6.45947 3.48622V1"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M6 15.002L6.00868 14.9996"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 17.002L12.0087 16.9996"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M5 1L13 1" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M4.5 10.5563C5.5 9.40291 7.09941 10.2343 9 11.3182C11.5 12.7439 13 11.65 13.5 10.6152"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  )
}
