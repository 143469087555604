import { RecipeTask } from "@/api/sdk"
import { TaskProgressSocketEvent } from "@/providers/ProgressingProvider"
import { Reorder } from "framer-motion"
import React from "react"
import InfiniteScroll from "react-infinite-scroller"
import GridItem from "./GridItem"
import { googleAnalytics } from "@/lib/gtag"

interface RowListItemProps {
  scrollRef: React.MutableRefObject<HTMLDivElement | null>
  orderTasks: RecipeTask[]
  setOrderTasks: (value: RecipeTask[]) => void
  fetchNextPage: () => void
  hasNextPage: boolean
  isFetching: boolean
  tasksProgress?: TaskProgressSocketEvent[]
  isLoading: boolean
  mappedData?: { list: RecipeTask[] }
  setSelectedRecipe: (value: RecipeTask) => void
  statuses?: string[]
  onDelete: ({ ids }: { ids: string[] }) => void
  onCancel: (id: string) => void
  onCreate: ({
    data,
    status,
  }: {
    data: {
      folderId?: string
      recipeId: string
      params: Record<string, any>
    }
    status: string
  }) => void
  onRestart: ({ taskId, status }: { taskId: string; status: string }) => void
  onReorder: ({
    data,
    status,
  }: {
    data: { taskId: string; position: "BEFORE" | "AFTER"; targetTaskId: string }
    status: string
  }) => void
}

const RowListItem = ({
  scrollRef,
  orderTasks,
  mappedData,
  setOrderTasks,
  onCancel,
  onCreate,
  onDelete,
  onRestart,
  onReorder,
  fetchNextPage,
  hasNextPage,
  isFetching,
  setSelectedRecipe,
  tasksProgress,
  statuses,
  isLoading,
}: RowListItemProps) => {
  return (
    <Reorder.Group
      ref={scrollRef}
      as="div"
      axis="y"
      layoutScroll
      values={orderTasks}
      onReorder={setOrderTasks}
      className="max-h-[calc(100%-2.5rem)] flex w-full overflow-x-hidden overflow-y-auto p-1 scrollbar_table"
      style={{
        WebkitOverflowScrolling: "touch",
      }}
    >
      <InfiniteScroll
        getScrollParent={() => scrollRef.current}
        useWindow={false}
        style={{
          display: "flex",
          flexDirection: "column",
          maxWidth: "100%",
          position: "relative",
          width: "100%",
        }}
        loader={
          <div key="loader" className="flex items-center justify-center py-4">
            Loading...
          </div>
        }
        loadMore={() => fetchNextPage()}
        hasMore={hasNextPage && !isFetching}
      >
        <div className="space-y-2 w-full">
          {orderTasks.map(i => (
            <GridItem
              key={i.id}
              recipe={i}
              progressData={tasksProgress?.find(p => p.task_id === i.id) as TaskProgressSocketEvent | undefined}
              isLoading={isLoading}
              onView={() => setSelectedRecipe(i)}
              onCancel={() => {
                onCancel(i.id)
              }}
              onCreate={() => {
                onCreate({
                  data: {
                    folderId: i.folderId,
                    recipeId: i.recipeId,
                    params: i.params,
                  },
                  status: i.status,
                })
              }}
              onRestart={() => {
                onRestart({ taskId: i.id, status: i.status })
              }}
              onDelete={() => {
                onDelete({
                  ids: [i.id],
                })
              }}
              isDraggable={statuses?.includes("QUEUED")}
              onDragEnd={() => {
                if (!statuses?.includes("QUEUED")) return
                if (!mappedData?.list) return

                const beforeId = mappedData.list.findIndex(i => i.id === i.id)
                const afterId = orderTasks.findIndex(i => i.id === i.id)

                if (beforeId === afterId) return

                if (afterId === 0) {
                  googleAnalytics.event({
                    action: "drag_and_drop",
                    category: "agent_scheduler",
                    label: `reorder_taskId_${i.id}__targetTaskId_${orderTasks[1].id}__position="BEFORE"`,
                    value: 1,
                  })

                  onReorder({
                    data: {
                      taskId: i.id,
                      position: "BEFORE",
                      targetTaskId: orderTasks[1].id,
                    },
                    status: i.status,
                  })
                } else {
                  googleAnalytics.event({
                    action: "drag_and_drop",
                    category: "agent_scheduler",
                    label: `reorder_taskId_${i.id}__targetTaskId_${orderTasks[afterId - 1].id}__position="AFTER"`,
                    value: 1,
                  })
                  onReorder({
                    data: { taskId: i.id, position: "AFTER", targetTaskId: orderTasks[afterId - 1].id },
                    status: i.status,
                  })
                }
              }}
            />
          ))}
        </div>
      </InfiniteScroll>
    </Reorder.Group>
  )
}

export default RowListItem
