const billionsOfWildcards = 'https://cdn.protogaia.com/stable-diffusion/wildcards/billions-of-wildcard.json'

const wildcardDict = {}

function getWildcardList() {
  return Object.keys(wildcardDict).map(x => `__${x}__`)
}

function wildcardNormalize(x) {
  return x.replace("\\", "/").toLowerCase()
}

function readWildcard(k, v) {
  if (Array.isArray(v)) {
    k = wildcardNormalize(k)
    wildcardDict[k] = v
  } else if (typeof v === "object" && v !== null) {
    for (let [k2, v2] of Object.entries(v)) {
      let newKey = `${k}/${k2}`
      newKey = wildcardNormalize(newKey)
      readWildcard(newKey, v2)
    }
  }
}

export async function readWildcardDict() {
  if (Object.keys(wildcardDict).length > 0) {
    return getWildcardList()
  }

  return fetch(billionsOfWildcards).then(async res => {
    const yaml = await res.json()
    for (let [k, v] of Object.entries(yaml)) {
      readWildcard(k, v)
    }

    return getWildcardList()
  })
}

let wildcards_list = [];
async function load_wildcards() {
  wildcards_list = await readWildcardDict()
}

load_wildcards();

export function get_wildcards_list() {
  return wildcards_list;
}

function randomChoices(elements, weights, k) {
  let cumulativeWeights = []
  let total = 0

  for (let weight of weights) {
    total += weight
    cumulativeWeights.push(total)
  }

  let selections = []

  for (let i = 0; i < k; i++) {
    let randomValue = Math.random() * total
    let index = cumulativeWeights.findIndex(weight => weight > randomValue)
    selections.push(elements[index])
  }

  return selections
}

function isNumber(str) {
  return !isNaN(parseFloat(str)) && isFinite(str)
}

/**
 * @param {string} string 
 * @returns {[string, boolean]}
 */
function replaceOptions(string) {
  let replacementsFound = false

  /**
 * @param {RegExpExecArray | null} match 
 * @returns {string}
 */
  function replaceOption(match) {
    let options = match[1].split("|")

    let multiSelectPattern = options[0].split("$$")
    let selectRange = []
    let selectSep = " "
    let rangePattern = /^(\d+)(-(\d+))?/
    let rangePattern2 = /^-(\d+)/

    if (multiSelectPattern.length > 1) {
      let r = options[0].match(rangePattern)

      let a = "",
        b = ""
      if (r === null) {
        r = options[0].match(rangePattern2)
        a = "1"
        b = r[1].trim()
      } else {
        a = r[1].trim()
        b = r[3].trim()
      }

      if (r !== null) {
        if (b !== null && isNumber(a) && isNumber(b)) {
          // PATTERN: num1-num2
          selectRange = [parseInt(a), parseInt(b)]
        } else if (isNumber(a)) {
          // PATTERN: num
          let x = parseInt(a)
          selectRange = [x, x]
        }

        if (selectRange.length && multiSelectPattern.length === 2) {
          // PATTERN: count$$
          options[0] = multiSelectPattern[1]
        } else if (selectRange.length && multiSelectPattern.length === 3) {
          // PATTERN: count$$ sep $$
          selectSep = multiSelectPattern[1]
          options[0] = multiSelectPattern[2]
        }
      }
    }

    let adjustedProbabilities = []
    let totalProb = 0

    for (let option of options) {
      let parts = option.split("::", 2)
      let configValue
      if (parts.length === 2 && isNumber(parts[0].trim())) {
        configValue = parseFloat(parts[0].trim())
      } else {
        configValue = 1 // Default value if no configuration is provided
      }

      adjustedProbabilities.push(configValue)
      totalProb += configValue
    }

    let normalizedProbabilities = adjustedProbabilities.map(prob => prob / totalProb)

    let selectCount
    if (selectRange.length === 0) {
      selectCount = 1
    } else {
      selectCount = Math.floor(Math.random() * (selectRange[1] - selectRange[0] + 1)) + selectRange[0]
    }

    let selectedItems
    if (selectCount > options.length) {
      selectedItems = options
    } else {
      selectedItems = randomChoices(options, normalizedProbabilities, selectCount)
      selectedItems = [...new Set(selectedItems)]

      let tryCount = 0
      while (selectedItems.length < selectCount && tryCount < 10) {
        let remainingCount = selectCount - selectedItems.length
        let additionalItems = randomChoices(options, normalizedProbabilities, remainingCount)
        selectedItems = [...new Set([...selectedItems, ...additionalItems])]
        tryCount += 1
      }
    }

    let selectedItems2 = selectedItems.map(x => x.replace(/^\s*[0-9.]+::/, "", 1))
    let replacement = selectedItems2.join(selectSep)
    if (replacement.includes("::")) {
      // pass
    }

    replacementsFound = true
    return replacement
  }

  let pattern = /{([^{}]*?)}/
  let match = pattern.exec(string)
  let replacedString = match ? string.replace(pattern, replaceOption(match)) : string

  return [replacedString, replacementsFound]
}

function replaceWildcard(string) {
  let pattern = /__([\w.\-/*\\]+)__/g
  let matches = string.matchAll(pattern)
  matches = [...matches].map(x => x[1])
  let replacementsFound = false

  if (!matches || matches.length === 0) return [string, replacementsFound]

  for (let match of matches) {
    let keyword = match.toLowerCase()
    keyword = wildcardNormalize(keyword)

    if (keyword in wildcardDict) {
      let replacement = wildcardDict[keyword][Math.floor(Math.random() * wildcardDict[keyword].length)]
      replacementsFound = true
      string = string.replace(`__${match}__`, replacement.replace(/\$/g, "$$$$"), 1)
    } else if (keyword.includes("*")) {
      let subPattern = new RegExp("^" + keyword.replaceAll("*", ".*"))
      let totalPatterns = []
      let found = false
      for (let [k, v] of Object.entries(wildcardDict)) {
        if (subPattern.test(k)) {
          totalPatterns = totalPatterns.concat(v)
          found = true
        }
      }

      if (found) {
        let replacement = totalPatterns[Math.floor(Math.random() * totalPatterns.length)]
        replacementsFound = true
        string = string.replace(`__${match}__`, replacement.replace(/\$/g, "$$$$"), 1)
      }
    } else if (!keyword.includes("/")) {
      let stringFallback = string.replace(`__${match}__`, `__*/${match}__`, 1)
      let [newString, newReplacementsFound] = replaceWildcard(stringFallback)
      string = newString
      replacementsFound = newReplacementsFound
    }
  }

  return [string, replacementsFound]
}

/**
 * @param {string} text 
 * @returns {string}
 */
export function processWildcard(text) {
  let replaceDepth = 100
  let stopUnwrap = false
  while (!stopUnwrap && replaceDepth > 1) {
    replaceDepth -= 1 // prevent infinite loop

    // pass1: replace options
    let [pass1, isReplaced1] = replaceOptions(text)

    while (isReplaced1) {
      ;[pass1, isReplaced1] = replaceOptions(pass1)
    }

    // pass2: replace wildcards
    let [newText, isReplaced2] = replaceWildcard(pass1)
    text = newText
    stopUnwrap = !isReplaced1 && !isReplaced2
  }

  return text
}
