import { ExploreSDStyleDetail, SDStyleDetail } from "@/api/sdk"
import React, { Fragment, useLayoutEffect, useRef } from "react"
import ImageAttribute from "../../ImageDetail/TabImage/MetadataImage/ImageAttribute"

interface MetadataStyleProps {
  style: ExploreSDStyleDetail | SDStyleDetail
  onTagFilter?: (tag: number) => void
}

const MetadataStyle = ({ style, onTagFilter }: MetadataStyleProps) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null)

  useLayoutEffect(() => {
    // Reset height - important to shrink on delete
    if (!textareaRef.current) return
    textareaRef.current.style.height = "inherit"
    // Set height
    textareaRef.current.style.height = `${Math.max(textareaRef.current.scrollHeight, 60)}px`
  }, [style?.description])

  return (
    <div>
      <p className="text-xs font-semibold text-atherGray-500 uppercase mb-1">Tag</p>
      {style.tags.length > 0 ? (
        <div className="flex flex-wrap gap-1 text-atherGray-300">
          {style.tags.map(tag => (
            <ImageAttribute searchQuery={""} value={tag.name} key={tag.id} hiddenCopy />
          ))}
        </div>
      ) : (
        <p className="text-xs italic text-atherGray-500">No tags</p>
      )}
      <p className="text-xs font-semibold text-atherGray-500 uppercase mt-4">Note</p>
      {style.description && style.description?.length > 0 ? (
        <Fragment>
          <textarea
            ref={textareaRef}
            disabled
            readOnly
            value={style.description}
            className="w-full text-sm text-atherGray-100 bg-atherGray-900 rounded-lg h-full resize-none placeholder:text-atherGray-600"
          >
            {style.description}
          </textarea>
        </Fragment>
      ) : (
        <p className="text-xs italic text-atherGray-500 mt-1">No note</p>
      )}
    </div>
  )
}

export default MetadataStyle
