import { convertImage } from "./convert"

export const resizeImage = async (file: File | Blob, max = 1024): Promise<File> => {
  let newFile = file

  if (newFile.type === "image/webp") {
    newFile = await convertImage(newFile, "image/jpeg")
  }

  return new Promise((resolve, reject) => {
    if (!["image/jpeg", "image/png", "image/webp", "image/heic"].some(v => v === newFile.type)) {
      reject("Invalid file type")
      window.alert("Invalid file type")
      return
    }

    const img = new Image()
    const canvas = document.createElement("canvas")
    const context = canvas.getContext("2d")

    img.onload = () => {
      let originalWidth = img.width
      let originalHeight = img.height
      let scale = Math.min(max / originalWidth, max / originalHeight)

      let newWidth = originalWidth * scale
      let newHeight = originalHeight * scale

      if (scale > 1) {
        newWidth = originalWidth
        newHeight = originalHeight
      }

      canvas.width = newWidth
      canvas.height = newHeight
      context?.drawImage(img, 0, 0, originalWidth, originalHeight, 0, 0, newWidth, newHeight)

      canvas.toBlob(
        function (blob) {
          if (blob) {
            const resizedFile = new File([blob], `${newFile.name}`, { type: newFile.type, lastModified: Date.now() })
            resolve(resizedFile)
          }
        },
        newFile.type,
        1,
      )
    }

    img.onerror = reject
    img.src = URL.createObjectURL(newFile)
  })
}

export const createImage = (url: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image()

    image.addEventListener("load", () => resolve(image))
    image.addEventListener("error", error => reject(error))
    image.setAttribute("crossOrigin", "anonymous") // needed to avoid cross-origin issues on CodeSandbox
    image.src = url
  })

export function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180
}

/**
 * Returns the new bounding area of a rotated rectangle.
 */
export function rotateSize(width, height, rotation) {
  const rotRad = getRadianAngle(rotation)

  return {
    width: Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height: Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  }
}

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 */
export default async function getCroppedImg(
  imageSrc,
  pixelCrop,
  rotation = 0,
  flip = { horizontal: false, vertical: false },
): Promise<{
  url: string
  file: File
} | null> {
  const image = await createImage(imageSrc)
  const canvas = document.createElement("canvas")
  const ctx = canvas.getContext("2d")

  if (!ctx) {
    return null
  }

  const rotRad = getRadianAngle(rotation)

  // calculate bounding box of the rotated image
  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(image.width, image.height, rotation)

  // set canvas size to match the bounding box
  canvas.width = bBoxWidth
  canvas.height = bBoxHeight

  // translate canvas context to a central location to allow rotating and flipping around the center
  ctx.translate(bBoxWidth / 2, bBoxHeight / 2)
  ctx.rotate(rotRad)
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1)
  ctx.translate(-image.width / 2, -image.height / 2)

  // draw rotated image
  ctx.drawImage(image, 0, 0)

  const croppedCanvas = document.createElement("canvas")

  const croppedCtx = croppedCanvas.getContext("2d")

  if (!croppedCtx) {
    return null
  }

  // Set the size of the cropped canvas
  croppedCanvas.width = pixelCrop.width
  croppedCanvas.height = pixelCrop.height

  // Draw the cropped image onto the new canvas
  croppedCtx.drawImage(
    canvas,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height,
  )

  // As Base64 string
  // return croppedCanvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise((resolve, reject) => {
    croppedCanvas.toBlob(file => {
      if (file) {
        const resizedFile = new File([file], `upload-image-${new Date().getTime()}`, {
          type: file.type,
          lastModified: Date.now(),
        })

        resolve({
          url: URL.createObjectURL(file),
          file: resizedFile,
        })
      }
    }, "image/heic")
  })
}
