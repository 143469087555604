import { IconProps } from "."

export const NewsIcon = (props: IconProps) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8.75 6.66602H15.4167M8.75 9.99935H10.8333M15.4167 9.99935H13.3333M8.75 13.3327H10.8333M15.4167 13.3327H13.3333"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.83073 6.25H4.9974C3.42605 6.25 2.64037 6.25 2.15222 6.73816C1.66406 7.22631 1.66406 8.01198 1.66406 9.58333V15C1.66406 16.1506 2.5968 17.0833 3.7474 17.0833C4.89799 17.0833 5.83073 16.1506 5.83073 15V6.25Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3333 2.91602H9.16667C8.39169 2.91602 8.0042 2.91602 7.68629 3.0012C6.82356 3.23237 6.14969 3.90624 5.91852 4.76897C5.83333 5.08688 5.83333 5.47437 5.83333 6.24935V14.9993C5.83333 16.1499 4.90059 17.0827 3.75 17.0827H13.3333C15.6904 17.0827 16.8689 17.0827 17.6011 16.3505C18.3333 15.6182 18.3333 14.4397 18.3333 12.0827V7.91602C18.3333 5.55899 18.3333 4.38048 17.6011 3.64825C16.8689 2.91602 15.6904 2.91602 13.3333 2.91602Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
