import { FC, ReactNode, useState } from "react"
import IconButton from "../IconButton"
import classNames from "classnames"
import { DeleteIcon } from "../shared/icons"
import ImageWebpComponent from "../ImageWebpComponent"

export type ImageViewProps = {
  url: string
  alt?: string
  className?: string
  imgClassName?: string
  preferThumbnail?: boolean
  onClick?: () => void | Promise<void>
  children?: ReactNode
}

export const ImageView: FC<ImageViewProps> = ({ url, alt, className, imgClassName, children, onClick }) => {
  const [imageRatio, setImageRatio] = useState(1)

  return (
    <div className={classNames("relative h-[10rem] flex justify-center", className)}>
      {children}
      <ImageWebpComponent
        onClick={onClick}
        src={url}
        alt={alt || "Image"}
        className={classNames(
          "h-full object-contain",
          {
            "w-full": imageRatio >= 1,
          },
          imgClassName,
        )}
        onLoad={e => setImageRatio(e.currentTarget.naturalWidth / e.currentTarget.naturalHeight)}
      />
    </div>
  )
}

export type ImageViewWithDeleteProps = ImageViewProps & {
  onDelete: () => void | Promise<void>
  onClick?: () => void | Promise<void>
  disabled?: boolean
}

export const ImageViewWithDelete: FC<ImageViewWithDeleteProps> = ({ onDelete, disabled, ...props }) => {
  const [deleting, setDeleting] = useState(false)

  const handleDelete = async () => {
    setDeleting(true)
    Promise.resolve(onDelete()).finally(() => {
      setDeleting(false)
    })
  }

  return (
    <ImageView {...props}>
      <div className="absolute bottom-2 right-2">
        <IconButton
          onClick={e => {
            e.stopPropagation()
            handleDelete()
          }}
          colorScheme="secondary"
          className="flex items-center p-2"
          disabled={disabled}
          isLoading={deleting}
        >
          <DeleteIcon className="text-red-500" width={16} height={16} />
        </IconButton>
      </div>
    </ImageView>
  )
}

export default ImageView
