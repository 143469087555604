import { IconProps } from "."

export const AlbumIcon = (props: IconProps) => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M6 18.328C6.1287 19.6364 6.41956 20.5171 7.07691 21.1745C8.25596 22.3535 10.1536 22.3535 13.9489 22.3535C17.7442 22.3535 19.6419 22.3535 20.8209 21.1745C22 19.9954 22 18.0978 22 14.3024C22 10.5071 22 8.60948 20.8209 7.43043C20.1636 6.77308 19.2829 6.48221 17.9745 6.35352"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M2 10.3535C2 6.58228 2 4.69666 3.17157 3.52509C4.34315 2.35352 6.22876 2.35352 10 2.35352C13.7712 2.35352 15.6569 2.35352 16.8284 3.52509C18 4.69666 18 6.58228 18 10.3535C18 14.1248 18 16.0104 16.8284 17.1819C15.6569 18.3535 13.7712 18.3535 10 18.3535C6.22876 18.3535 4.34315 18.3535 3.17157 17.1819C2 16.0104 2 14.1248 2 10.3535Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M2 11.472C2.61902 11.3934 3.24484 11.3545 3.87171 11.3559C6.52365 11.3068 9.11064 12.0298 11.1711 13.3959C13.082 14.6629 14.4247 16.4065 15 18.3535"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M12.9998 7.35352H13.0088"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
