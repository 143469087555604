import classNames from "classnames"
import { forwardRef } from "react"
import { BeautifulMentionsMenuItemProps, BeautifulMentionsMenuProps } from "./plugin/types/BeautifulMentionsPluginProps"
import { BeautifulMentionsPlugin } from "./plugin/BeautifulMentionsPlugin"
import { motion } from "framer-motion"
import UserCreated from "../Explore/Gallery/UserCreated"

export interface MentionDataV2 extends BeautifulMentionsMenuItemProps {
  value: string
  uid: string
  email?: string
  name: string
  picture: string
}

const CustomMenu = ({ open, loading, searchDelay, children, ...props }: BeautifulMentionsMenuProps) => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{
      duration: 0.2,
      ease: "easeInOut",
      delay: searchDelay / 1000 || 0.2,
    }}
    {...props}
  >
    <ul className="m-0 p-2 bg-atherGray-800 overflow-y-auto overflow-x-hidden max-h-[15rem] w-[16rem] rounded-lg text-atherGray-0">
      {children}
    </ul>
  </motion.div>
)

const CustomMenuItem = forwardRef<HTMLLIElement, BeautifulMentionsMenuItemProps>(
  ({ uid, email, name, picture, selected, ...props }, ref) => (
    <li
      ref={ref}
      className={classNames("flex items-center p-2 rounded-lg overflow-hidden cursor-pointer", {
        "bg-atherGray-700": selected,
      })}
      {...props}
    >
      <UserCreated picture={picture} name={name} size="sm" email={email} />
    </li>
  ),
)

const MentionPlugin = ({
  mentionItems,
  isMentionsLoading,
  placement = "auto",
  parent,
  onSearchMention,
}: {
  mentionItems: MentionDataV2[]
  onSearchMention?: (value: string) => void
  parent?: HTMLDivElement
  placement?: "top" | "bottom" | "auto"
  isMentionsLoading?: boolean
}) => {
  return (
    <BeautifulMentionsPlugin
      triggers={["@"]}
      placement={placement}
      allowSpaces
      searchDelay={250}
      menuItemLimit={20}
      isLoading={isMentionsLoading}
      creatable={false}
      parent={parent}
      onSearch={async (trigger, query) => {
        onSearchMention?.(query ?? "")

        return mentionItems
      }}
      menuComponent={CustomMenu}
      menuItemComponent={CustomMenuItem}
    />
  )
}

export default MentionPlugin
