import LoadingIcon from "@/components/LoadingIcon"
import NotFoundItems from "@/components/Workspace/Pinned/NotFoundItems"
import useCustomRouter from "@/hooks/useCustomRouter"
import { motion } from "framer-motion"
import { memo, useEffect, useRef, useState } from "react"
import InfiniteScroll from "react-infinite-scroller"
import { useWorkspacePrompt } from "../../Prompts"
import PromptItem from "../../Prompts/PromptItem"
import ErrorUI from "@/components/ErrorUI"

interface ComfyUIPromptLibraryProps {
  sdWorkflowId?: string
  isOpen?: boolean
}

const ComfyUIPromptLibrary = ({ sdWorkflowId, isOpen }: ComfyUIPromptLibraryProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const { openNewTab } = useCustomRouter()
  const [isFetched, setIsFetched] = useState(false)

  const { prompts, isLoading, isFetching, fetchNextPage, hasNextPage, isError } = useWorkspacePrompt(
    "",
    undefined,
    !!sdWorkflowId && isFetched,
  )

  useEffect(() => {
    if (isOpen) {
      setIsFetched(true)
    }
  }, [isOpen])

  const renderBody = () => {
    if (isLoading)
      return (
        <div className="flex items-center justify-center w-full flex-1 text-gray-600">
          <LoadingIcon width={20} height={20} />
        </div>
      )

    if (isError) return <ErrorUI />

    if (prompts.length === 0) return <NotFoundItems />

    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.35, delay: 0.35 }}
        className={"relative flex-1 list-recipes"}
      >
        <InfiniteScroll
          loadMore={() => fetchNextPage()}
          hasMore={!!hasNextPage && !isFetching}
          useWindow={false}
          getScrollParent={() => ref.current!}
          threshold={600}
        >
          <div className="w-full gap-2 grid grid-cols-[repeat(auto-fill,minmax(8rem,1fr))]">
            {prompts.map((promptData, idx) => (
              <PromptItem isCopy hiddenMenu key={promptData.id} className={`prompt-${idx + 1}`} prompt={promptData} />
            ))}
          </div>
        </InfiniteScroll>
      </motion.div>
    )
  }

  return (
    <div className="flex-1 flex flex-col overflow-hidden w-full pt-4">
      <div className="px-4">
        <p className="font-semibold text-base text-atherGray-100">Prompt Library</p>
      </div>
      <div className="flex-1 overflow-auto flex flex-col p-4" ref={ref}>
        {renderBody()}
      </div>
    </div>
  )
}

export default memo(ComfyUIPromptLibrary)
