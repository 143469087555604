import { IconProps } from "."

export const QuoteIcon = (props: IconProps) => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M9.33203 11.1667C9.33203 9.90959 9.33203 9.28105 9.72256 8.89052C10.1131 8.5 10.7416 8.5 11.9987 8.5C13.2558 8.5 13.8843 8.5 14.2748 8.89052C14.6654 9.28105 14.6654 9.90959 14.6654 11.1667C14.6654 12.4237 14.6654 13.0523 14.2748 13.4428C13.8843 13.8333 13.2558 13.8333 11.9987 13.8333C10.7416 13.8333 10.1131 13.8333 9.72256 13.4428C9.33203 13.0523 9.33203 12.4237 9.33203 11.1667Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M9.33203 11.167V8.40876C9.33203 5.96412 11.0092 3.89089 13.332 3.16699"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M1.33203 11.1667C1.33203 9.90959 1.33203 9.28105 1.72256 8.89052C2.11308 8.5 2.74162 8.5 3.9987 8.5C5.25578 8.5 5.88432 8.5 6.27484 8.89052C6.66536 9.28105 6.66536 9.90959 6.66536 11.1667C6.66536 12.4237 6.66536 13.0523 6.27484 13.4428C5.88432 13.8333 5.25578 13.8333 3.9987 13.8333C2.74162 13.8333 2.11308 13.8333 1.72256 13.4428C1.33203 13.0523 1.33203 12.4237 1.33203 11.1667Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M1.33203 11.167V8.40876C1.33203 5.96412 3.00917 3.89089 5.33203 3.16699"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  )
}
