import { IconProps } from "."

export const PrivacyIcon = (props: IconProps) => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M11 1C16.5237 1 21 5.47778 21 11C21 16.5222 16.5237 21 11 21"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 20.5C6.81163 20.0953 5.69532 19.5107 4.72302 18.7462M4.72302 3.25385C5.69532 2.50059 6.81163 1.90473 8 1.5M1 9.24612C1.21607 8.08813 1.66019 6.96386 2.29638 5.94078M1 12.7539C1.21607 13.9119 1.66019 15.0361 2.29638 16.0592"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 15.5C9.07262 13.302 12.9051 13.1986 15 15.5M13.2179 8.75C13.2179 9.99264 12.2215 11 10.9925 11C9.76342 11 8.76708 9.99264 8.76708 8.75C8.76708 7.50736 9.76342 6.5 10.9925 6.5C12.2215 6.5 13.2179 7.50736 13.2179 8.75Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  )
}
