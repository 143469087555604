import client from "@/api/client"
import { CreatorProfileItem, CreatorProfileSortOptions, GetCreatorProfilesResult } from "@/api/sdk"
import { createAuthenticatedInfiniteQuery } from "../createAuthenticatedQuery"

export interface UseExploreProfilesInfiniteQueryVariables {
  sortBy?: CreatorProfileSortOptions
  searchTerm?: string
  userUid?: string
}

export const useExploreProfilesInfiniteQuery = createAuthenticatedInfiniteQuery<
  GetCreatorProfilesResult,
  UseExploreProfilesInfiniteQueryVariables,
  Error,
  CreatorProfileItem
>({
  auth: false,
  primaryKey: "infinite-explore-profiles",
  queryFn: ({ queryKey: [_primaryKey, { sortBy, searchTerm }], pageParam = 0 }) =>
    client.api
      .creatorProfileControllerGetCreatorProfiles({
        skip: pageParam * 20,
        take: 20,
        sortOptions: sortBy,
        searchTerm,
      })
      .then(res => res.data),
  getNextPageParam: (lastPage, pages) => (lastPage && lastPage.profiles.length < 20 ? undefined : pages.length),
  initialPageParam: 0,
  flattenFn: data => data.profiles,
})
