import client from "@/api/client"
import { ImageFullDetail } from "@/api/sdk"
import { createQuery } from "react-query-kit"

export interface WorkspaceImageDetailQueryVariables {
  imageId: string
  userUid?: string
}

export const useWorkspaceDetailImageQuery = createQuery<ImageFullDetail, WorkspaceImageDetailQueryVariables, Error>({
  primaryKey: "workspace-detail-image",
  queryFn: ({ queryKey: [_primaryKey, variables] }) =>
    client.api.imageControllerGetImageDetail(variables.imageId!).then(res => res.data),
  use: [
    useQueryNext => {
      return options => {
        const enabled = !!options.variables?.imageId ? options.enabled : false

        return useQueryNext({
          enabled,
          ...options,
        })
      }
    },
  ],
})
