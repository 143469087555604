import client from "@/api/client"
import { actionMiddleware } from "@/queries/middlewares/actionMiddleware"
import { createMutation } from "react-query-kit"
import { ArticleDetail, ArticleReaction, GetArticlesResult, ToggleArticleReactionDto } from "@/api/sdk"
import { useArticleDetailQuery } from "@/components/Explore/ArticleDetail/ArticleDetailProvider"
import { useExploreArticlesInfiniteQuery } from "./useExploreArticlesInfiniteQuery"
import { InfiniteData } from "@tanstack/react-query"

export const useExploreReactionArticleMutation = createMutation({
  mutationFn: async ({ id, data }: { id: string; data: ToggleArticleReactionDto; userUid?: string }) => {
    return await client.api.articleControllerToggleArticleReaction(id, data).then(res => res.data)
  },
  use: [
    actionMiddleware({
      onMutate: ({
        queryClient,
        variables: {
          data: { reaction },
          ...variables
        },
      }) => {
        const articleExploreKey = useArticleDetailQuery.getKey({
          articleId: variables.id,
          userUid: variables.userUid,
        })

        const articleExploreData = queryClient.getQueryData<ArticleDetail>(articleExploreKey)

        if (articleExploreData) {
          queryClient.setQueryData(articleExploreKey, {
            ...articleExploreData,
            metric: {
              ...articleExploreData.metric,
              [`${reaction.toLowerCase()}Count`]: articleExploreData.userReactions?.find(i => i.reaction === reaction)
                ? articleExploreData.metric?.[`${reaction.toLowerCase()}Count`] - 1
                : articleExploreData.metric?.[`${reaction.toLowerCase()}Count`] + 1,
            },
            userReactions: articleExploreData.userReactions?.find(i => i.reaction === reaction)
              ? articleExploreData.userReactions?.filter(i => i.reaction !== reaction)
              : [
                  ...(articleExploreData.userReactions ?? []),
                  {
                    reaction,
                  } as ArticleReaction,
                ],
          })
        }

        const articlesInfiniteKey = useExploreArticlesInfiniteQuery.getKey()

        const exploreArticlesQueriesDataEntries = queryClient.getQueriesData<InfiniteData<GetArticlesResult, number>>({
          queryKey: articlesInfiniteKey,
        })

        if (exploreArticlesQueriesDataEntries) {
          exploreArticlesQueriesDataEntries.forEach(([key, data]) => {
            if (data) {
              const updatedData = {
                ...data,
                pages: data.pages.map(page => {
                  return {
                    ...page,
                    articles: page.articles.map(article => {
                      if (article.id === variables.id) {
                        return {
                          ...article,
                          metric: {
                            ...article.metric,
                            [`${reaction.toLowerCase()}Count`]: article.userReactions?.find(
                              i => i.reaction === reaction,
                            )
                              ? article.metric?.[`${reaction.toLowerCase()}Count`] - 1
                              : article.metric?.[`${reaction.toLowerCase()}Count`] + 1,
                          },
                          userReactions: article.userReactions?.find(i => i.reaction === reaction)
                            ? article.userReactions?.filter(i => i.reaction !== reaction)
                            : [
                                ...(article.userReactions ?? []),
                                {
                                  reaction,
                                } as ArticleReaction,
                              ],
                        }
                      }
                      return article
                    }),
                  }
                }),
              }
              queryClient.setQueryData(key, updatedData)
            }
          })
        }
      },
      onError: ({ queryClient, variables }) => {},
    }),
  ],
})
