import client from "@/api/client"
import { actionMiddleware } from "@/queries/middlewares/actionMiddleware"
import { createMutation } from "react-query-kit"

export const useDeleteMessageMutation = createMutation({
  mutationFn: async ({ roomId, messageId }: { roomId: string; messageId: string }) => {
    return await client.api.chatRoomControllerDeleteRoomMessage(messageId, roomId).then(res => res.data)
  },
  use: [
    actionMiddleware({
      onSuccess: ({ queryClient, variables }) => {},
    }),
  ],
})
