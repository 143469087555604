import { IconProps } from "."

export const DownloadIcon1 = (props: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 16 14" fill="none" {...props}>
      <path
        d="M12.1654 5C13.5372 5.04907 14.702 6.07794 14.6645 7.45624C14.6558 7.7769 14.5316 8.17325 14.2831 8.96592C13.6852 10.8736 12.6804 12.5296 10.4295 12.927C10.0157 13 9.5501 13 8.61889 13H7.37851C6.4473 13 5.9817 13 5.56793 12.927C3.31696 12.5296 2.31215 10.8736 1.71427 8.96592C1.46584 8.17324 1.34163 7.7769 1.33291 7.45624C1.29545 6.07794 2.46023 5.04907 3.83204 5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M7.9987 8.33333L7.9987 1M7.9987 8.33333C7.53188 8.33333 6.65972 7.0038 6.33203 6.66667M7.9987 8.33333C8.46552 8.33333 9.33768 7.0038 9.66536 6.66667"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
