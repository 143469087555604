import { googleAnalytics } from "@/lib/gtag"
import { cn } from "@/utils/cn"
import Link from "next/link"
import useCustomRouter from "@/hooks/useCustomRouter"
import { useMemo } from "react"

interface MenuItemProps {
  title: string
  path?: string
  icon: React.ReactNode
  className?: string
  pathRedirect?: string
  isPro?: boolean
  level?: number
  onSelected?: (path: string) => void
}

const MenuItem = ({ title, path, icon, className, pathRedirect, isPro, level = 1, onSelected }: MenuItemProps) => {
  const router = useCustomRouter()

  const isActive = useMemo(() => {
    return !!router.query.folderId ? false : router.pathname === path
  }, [path, router.pathname, router.query.folderId])

  return (
    <Link
      href={pathRedirect ?? path ?? "#"}
      className={cn(
        "px-4 py-2 cursor-pointer w-full flex items-center rounded-2xl select-none group",
        "lg:hover:bg-atherGray-850",
        "text-atherGray-300",
        {
          "text-atherGray-0 bg-atherGray-850": path && isActive,
          "pointer-events-none": !path,
        },
        className,
      )}
      style={{
        paddingLeft: `${level}rem`,
      }}
      onClick={e => {
        if (!path) return

        e.preventDefault()

        googleAnalytics.event({
          action: "click",
          category: "sidebar",
          label: `menu_folder_${path}`,
          value: 1,
        })
        router.push(pathRedirect ?? path, undefined, {
          shallow: true,
          scroll: true,
        })
        if (onSelected) {
          onSelected(path)
        }
      }}
    >
      <div
        className={cn("mr-2 w-5 h-5 flex justify-center items-center text-atherGray-500 group-hover:text-atherGray-0", {
          "text-atherGray-0": path && isActive,
        })}
      >
        {icon}
      </div>
      <p className={cn("font-semibold group-hover:text-atherGray-0 text-sm")}>{title}</p>
      {isPro && (
        <span className="bg-[#D2AF53] text-[#090218] rounded-[4px] text-[0.65rem] py-0.5 px-1 font-semibold ml-2">
          PRO
        </span>
      )}
    </Link>
  )
}

export default MenuItem
