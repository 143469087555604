import TagSearchImage from "@/components/Workspace/Gallery/TagSearchImage"
import { cn } from "@/utils/cn"
import { useState } from "react"

type TagType = {
  name: string
  id: number
}

const AddTagInput = ({
  tags,
  setTags,
  onTag,
  disabled,
  className,
  itemClassName,
  onFocus,
}: {
  className?: string
  onTag?: (tag: TagType) => void
  itemClassName?: string
  disabled?: boolean
  tags: TagType[]
  setTags: React.Dispatch<React.SetStateAction<TagType[]>>
  onFocus?: (v: boolean) => void
}) => {
  const [value, setValue] = useState("")

  return (
    <div
      className={cn(
        "border border-atherGray-800 hover:bg-atherGray-600 px-1.5 py-0.5 active:bg-atherGray-700 relative rounded-lg cursor-pointer text-sm flex items-center",
        className,
        { "pointer-events-none opacity-50": disabled },
      )}
    >
      <TagSearchImage
        className={cn("flex items-center", itemClassName)}
        disabled={disabled}
        selectedTags={tags}
        value={value}
        onChange={v => setValue(v)}
        onSubmit={value => {
          setTags(prev => [...prev, value])

          setTimeout(() => {
            onTag?.(value)
          }, 0)
        }}
        setIsFocus={focus => onFocus?.(focus)}
      />
    </div>
  )
}

export default AddTagInput
