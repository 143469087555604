import client from "@/api/client"
import { SharingPlatform } from "@/api/sdk"
import { useTempState } from "@/hooks"
import { googleAnalytics } from "@/lib/gtag"
import useModalStore from "@/lib/store"
import { useAuth } from "@/providers/authContext"
import { useIncreaseSocialQuestMutation } from "@/queries"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import classNames from "classnames"
import { AnimatePresence, motion } from "framer-motion"
import useCustomRouter from "@/hooks/useCustomRouter"
import { Fragment, useEffect, useMemo, useState } from "react"
import { MdCheck, MdOutlineFileCopy } from "react-icons/md"
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  FacebookMessengerShareButton,
  RedditShareButton,
} from "react-share"
import Modal from "../Modal"
import { cdnPublicFolderUrl } from "@/constants"

export const SocialAlphaButtons = ({
  imageId,
  isShareProfile,
  urlImageDetails,
  ownerId,
  mutateShareSocial,
  isNotCheckAuth,
}: {
  imageId?: string
  urlImageDetails: string
  isShareProfile?: boolean
  isNotCheckAuth?: boolean
  ownerId?: string
  mutateShareSocial: (data: { platform: SharingPlatform; imageId: string }) => void
}) => {
  const { user } = useAuth()
  const [sharingPlatform, setSharingPlaform] = useState<SharingPlatform | null>(null)

  const handleSocialEvent = (social: SharingPlatform) => {
    googleAnalytics.event({
      action: "click",
      category: "modal",
      label: `share_social`,
      params: {
        image_id: imageId ?? "",
        share_public_link: urlImageDetails,
        platform: social,
      },
    })
    handleCloseWindowShare(social)
  }

  const handleCloseWindowShare = (platform: SharingPlatform) => {
    if (!isNotCheckAuth && (!user || user.uid !== ownerId)) return

    setSharingPlaform(platform)
  }

  useEffect(() => {
    if (!sharingPlatform) return

    const timeout = setTimeout(() => {
      mutateShareSocial({
        platform: sharingPlatform,
        imageId: imageId ?? "",
      })
      setSharingPlaform(null)
    }, 7000)

    return () => {
      clearTimeout(timeout)
    }
  }, [sharingPlatform])

  return (
    <Fragment>
      <FacebookShareButton onClick={e => handleSocialEvent(SharingPlatform.FACEBOOK)} url={urlImageDetails}>
        <img className="h-6" src={`${cdnPublicFolderUrl}/images/alpha/facebook.png`} alt="FB" />
      </FacebookShareButton>
      <TwitterShareButton onClick={e => handleSocialEvent(SharingPlatform.TWITTER)} url={urlImageDetails}>
        <img className="h-6" src={`${cdnPublicFolderUrl}/images/alpha/twitter.png`} alt="TW" />
      </TwitterShareButton>
      <LinkedinShareButton
        onClick={e => handleSocialEvent(SharingPlatform.LINKEDIN)}
        url={urlImageDetails}
        className="bg-red-400 p-4"
      >
        <img className="h-6" src={`${cdnPublicFolderUrl}/images/alpha/li.png`} alt="LI" />
      </LinkedinShareButton>
      {isShareProfile && (
        <>
          <FacebookMessengerShareButton appId="" url={urlImageDetails}>
            <img className="h-6" src={`${cdnPublicFolderUrl}/images/alpha/fb-messenger.png`} alt="LI" />
          </FacebookMessengerShareButton>
          <RedditShareButton url={urlImageDetails}>
            <img className="h-6" src={`${cdnPublicFolderUrl}/images/alpha/reddit.png`} alt="LI" />
          </RedditShareButton>
        </>
      )}
    </Fragment>
  )
}

const ShareSocialModal = () => {
  const [sharingData, setSharingData] = useModalStore(state => [state.sharingData, state.setSharingData])
  const [isCopying, setIsCopying] = useTempState(false, 500)
  const [count, setCount] = useState(0)
  const { user } = useAuth()
  const qc = useQueryClient()
  const router = useCustomRouter()

  const urlImageDetails = useMemo(() => {
    if (typeof window !== "undefined") {
      return sharingData?.link ?? `${window.location.href}`
    }
    return ""
  }, [sharingData])

  const [link, setLink] = useState("")

  const { mutate: mutateCreateLink, isPending: isLoading } = useMutation({
    mutationFn: () => client.api.shortUrlControllerCreateShortUrl({ longUrl: urlImageDetails }).then(res => res.data),
    onSuccess: data => {
      const linkData =
        `${
          sharingData?.shortLink
            ? `${window.location.origin}${sharingData.shortLink}/${data?.short}`
            : `${window.location.origin}/r/${data?.short}`
        }` ?? urlImageDetails
      setLink(`${linkData}`)
    },
    mutationKey: ["create_short_url", sharingData?.id],
  })

  const { mutate: mutateIncreaseSocialQuest } = useIncreaseSocialQuestMutation()

  const { mutate: mutateShareSocial } = useMutation({
    mutationFn: ({ platform, imageId }: { platform: SharingPlatform; imageId: string }) =>
      client.api.generationTrackerControllerIncreaseGenerationBySharing({
        imageId: imageId ?? "",
        platform,
      }),
    onSuccess: ({}, { imageId, platform }) => {
      mutateIncreaseSocialQuest({ imageId, platform })
      qc.invalidateQueries({ queryKey: ["user-info"] })
    },
  })

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!sharingData) return
      mutateCreateLink()
    }, 250)

    return () => {
      clearTimeout(timeout)
    }
  }, [sharingData])

  const handleCopy = () => {
    setIsCopying(true)
    setCount(prev => prev + 1)

    googleAnalytics.event({
      action: "click",
      category: "share_image",
      label: "share_link_image",
      params: {
        share_public_link: link,
      },
    })

    navigator.clipboard.writeText(link)
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!user || user.uid !== sharingData?.creator?.uid || count > 3) return

      mutateShareSocial({ platform: SharingPlatform.LINK_COPY, imageId: sharingData?.id ?? "" })
    }, 15000)

    return () => {
      clearTimeout(timer)
    }
  }, [count])

  const handleClose = () => {
    setSharingData(null)
    setCount(0)
    sharingData?.onClose?.()
  }

  return (
    <Modal
      title={"Share to socials"}
      className="overflow-visible max-w-md text-[#EAE4D4] modal-content-child"
      isOpen={!!sharingData}
      onClose={handleClose}
    >
      <p className="text-sm mb-2 text-[#858175]">URL</p>
      <div
        className={classNames("border border-[#313131] rounded-md p-2 flex items-center overflow-hidden mb-4", {
          "opacity-50 pointer-events-none": isLoading,
        })}
      >
        <p
          className="truncate flex-1 mr-4 text-[#EAE4D4] select-none cursor-pointer"
          onClick={e => {
            e.stopPropagation()
            e.preventDefault()
            handleCopy()
          }}
        >
          {link}
        </p>
        <AnimatePresence initial={false} mode="popLayout">
          {!isCopying ? (
            <motion.button
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              key="btn-copy"
              onClick={e => {
                e.stopPropagation()
                e.preventDefault()
                handleCopy()
              }}
            >
              <MdOutlineFileCopy className="fill-atherGray-400" />
            </motion.button>
          ) : (
            <motion.button initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
              <MdCheck key="btn-check" className="fill-green-500" />
            </motion.button>
          )}
        </AnimatePresence>
      </div>
      <div className="mb-4">
        <p className="text-sm text-[#858175] mb-2">Share via</p>
        <div
          className={classNames("flex space-x-4", {
            "opacity-50 pointer-events-none": isLoading,
          })}
        >
          <SocialAlphaButtons
            mutateShareSocial={mutateShareSocial}
            ownerId={sharingData?.creator?.uid}
            imageId={sharingData?.id}
            urlImageDetails={link}
          />
        </div>
      </div>
      <div>
        <p className="text-sm text-[#858175] mb-2">Preview</p>
        <div className="bg-black h-[13.5rem] overflow-hidden rounded-md">
          <img src={sharingData?.url} alt={sharingData?.name} className="object-contain w-full h-full" />
        </div>
        {router.pathname.includes("/explore/macros/") && (
          <p className="text-xs text-atherGray-400 mt-4">
            Note: It will allow other users to see your entire macro including inputs and outputs as well as allow them
            to use your macro. Privacy and royalty/commercial terms will apply soon.
          </p>
        )}
      </div>
    </Modal>
  )
}

export default ShareSocialModal
