import Toggle from "@/components/Toggle"
import Tooltip from "@/components/Tooltip"
import { InfoIcon } from "@/components/shared/icons"
import classNames from "classnames"
import React from "react"
import { twMerge } from "tailwind-merge"
import FormDetailTooltip from "./FormDetail/FormDetailTooltip"

interface OptionalItemProps {
  children: React.ReactNode
  enabled?: boolean
  title?: string
  name?: string
  isChecked?: boolean
  description?: string
  isReverse?: boolean
  onChecked?: (e?: any) => void
  tooltip?: string
  nameClassName?: string
  toggleClassName?: string
}

const OptionalItem = ({
  children,
  enabled,
  title,
  isChecked,
  isReverse,
  description,
  onChecked,
  tooltip,
  name,
  nameClassName,
  toggleClassName,
}: OptionalItemProps) => {
  const showContent = isReverse ? !isChecked : isChecked

  return (
    <div>
      {!enabled && (
        <div
          className={classNames({
            "mb-6": enabled && (title || description),
          })}
        >
          {name && <FormDetailTooltip name={name} tooltip={tooltip} className="mb-0"></FormDetailTooltip>}
          {description && <p className="text-sm text-atherGray-400">{description}</p>}
        </div>
      )}
      {enabled && (
        <div className="mb-2">
          <div className="flex items-center">
            <div className="flex items-center flex-1 mr-10">
              <p className={twMerge("font-semibold text-sm text-atherGray-300 line-clamp-1", nameClassName)}>
                {name || title}
              </p>
              {tooltip && (
                <div className="mx-1">
                  <Tooltip
                    trigger={
                      <div>
                        <InfoIcon className="text-atherGray-600" width={14} height={14} />
                      </div>
                    }
                  >
                    {tooltip}
                  </Tooltip>
                </div>
              )}
            </div>
            <Toggle
              boxClassName={toggleClassName}
              sizeToggle="sm"
              name={name}
              checked={isChecked}
              onChange={e => {
                e.stopPropagation()
                onChecked?.(e)
              }}
            />
          </div>
          {description && <p className="text-sm text-atherGray-400">{description}</p>}
        </div>
      )}

      {(!enabled || showContent) && children}
    </div>
  )
}

export default OptionalItem
