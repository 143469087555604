import { IconProps } from "."

export const PhoneOff2Icon = (props: IconProps) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M36.2331 41.9337L36.7626 42.4649L36.2331 41.9337ZM38.7411 38.0687C38.8048 37.6594 38.5247 37.2759 38.1155 37.2122C37.7062 37.1484 37.3227 37.4285 37.2589 37.8377L38.7411 38.0687ZM10.9027 11.0654C10.9388 10.6528 10.6336 10.289 10.2209 10.2529C9.80831 10.2167 9.44453 10.522 9.40841 10.9346L10.9027 11.0654ZM10.75 29.8905V17.7967H9.25V29.8905H10.75ZM24.1529 43.25C20.7959 43.25 18.3533 43.2484 16.4877 42.9984C14.6435 42.7512 13.4758 42.2735 12.6022 41.4026L11.5431 42.4649C12.7421 43.6602 14.2787 44.2157 16.2885 44.4851C18.2769 44.7516 20.8382 44.75 24.1529 44.75V43.25ZM9.25 29.8905C9.25 33.1949 9.2484 35.7488 9.51581 37.7317C9.78616 39.7364 10.3438 41.2692 11.5431 42.4649L12.6022 41.4026C11.7288 40.5319 11.2502 39.3688 11.0024 37.5313C10.7516 35.6719 10.75 33.2374 10.75 29.8905H9.25ZM24.1529 44.75C27.4676 44.75 30.0289 44.7516 32.0173 44.4851C34.0271 44.2157 35.5636 43.6602 36.7626 42.4649L35.7036 41.4026C34.83 42.2735 33.6623 42.7512 31.818 42.9984C29.9524 43.2484 27.5099 43.25 24.1529 43.25V44.75ZM37.2589 37.8377C37.0001 39.4991 36.5279 40.5808 35.7036 41.4026L36.7626 42.4649C37.8933 41.3377 38.4541 39.9102 38.7411 38.0687L37.2589 37.8377ZM10.75 17.7967C10.75 14.9785 10.7507 12.8025 10.9027 11.0654L9.40841 10.9346C9.24935 12.7519 9.25 15.0037 9.25 17.7967H10.75Z"
        fill="currentColor"
      />
      <path
        d="M37.2499 30C37.2499 30.4142 37.5857 30.75 37.9999 30.75C38.4141 30.75 38.7499 30.4142 38.7499 30H37.2499ZM13.7309 4.10691C13.3442 4.25555 13.1513 4.68946 13.2999 5.07609C13.4486 5.46272 13.8825 5.65565 14.2691 5.50701L13.7309 4.10691ZM23.9999 4.75C27.321 4.75 29.7363 4.75159 31.5809 4.99959C33.4039 5.24469 34.5568 5.71803 35.4193 6.58058L36.48 5.51992C35.2923 4.33222 33.7701 3.78043 31.7808 3.51297C29.813 3.24841 27.2786 3.25 23.9999 3.25V4.75ZM38.7499 18C38.7499 14.7214 38.7515 12.187 38.487 10.2192C38.2195 8.2298 37.6677 6.70763 36.48 5.51992L35.4193 6.58058C36.2819 7.44313 36.7552 8.596 37.0003 10.419C37.2483 12.2636 37.2499 14.679 37.2499 18H38.7499ZM37.2499 18V30H38.7499V18H37.2499ZM23.9999 3.25C21.5899 3.25 19.5884 3.24968 17.9272 3.3523C16.268 3.45478 14.8871 3.66241 13.7309 4.10691L14.2691 5.50701C15.2119 5.14455 16.4127 4.9487 18.0196 4.84944C19.6244 4.75032 21.5734 4.75 23.9999 4.75V3.25Z"
        fill="currentColor"
      />
      <path d="M24 38H24.018" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M22 10H26" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4 4L44 44" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
