import {
  GetModelsResponse,
  RecipeInputType,
  RecipeItem,
  SDStyleFilterMode,
  SearchSDStyleResponse,
  WildcardDetail,
  WildcardFilterMode,
  WorkflowDetail,
} from "@/api/sdk"
import { processWildcard, readWildcardDict } from "@/components/ComfyUI/extensions/wildcard"
import { $isBeautifulMentionNode } from "@/components/LexicalEditor/plugin/nodes/MentionNode"
import { WildcardDataV2 } from "@/components/LexicalEditor/WildcardItemsPlugin"
import { CheckableRecipeInput } from "@/components/Workspace/Recipes/RecipeDetail"
import { $getRoot, EditorState } from "lexical"
import _random from "lodash/random"
import _omitBy from "lodash/omitBy"
import _isNil from "lodash/isNil"
import { UseFormReturn } from "react-hook-form"
import { RecipeTaskChainParams } from "@/components/Workspace/Workflows/WorkflowsChaining"
import client from "@/api/client"
import { useWorkspaceStyleInfiniteQuery } from "@/queries/tools/style/useGetStyleInfiniteQuery"
import { InfiniteData, QueryClient } from "@tanstack/react-query"
import _uniqBy from "lodash/uniqBy"

export type RecipeCreateType = {
  name: string
  description?: string
  tags?: { id: number; name: string }[]
  folderId?: string
  [key: string]: any
}

export type RecipeParams = {
  folderId?: string
  params: Record<string, any>
}

export type WildcardPrompt = {
  value?: string
  wildcards: { id: string; name: string; wildcards: string[]; isPublic?: boolean }[]
}

export function findWildcardIds(text: string) {
  const regex = /(?<=|^|\()(?:__)((?:(?!__)[^\s\.,\?\$\|#{}\(\)\^\[\]\\!%'"~=<>:;]){1,75})/g

  const matches: { value: string; index: number }[] = []
  let match
  regex.lastIndex = 0
  while ((match = regex.exec(text)) !== null) {
    matches.push({
      value: match[0].replace("__", ""),
      index: match.index,
    })
  }
  return matches
}

export const randomWildcardPublic = async (
  params: Record<string, any>,
  recipeInputs: {
    key: string
    allowWildcard?: boolean
  }[],
) => {
  // read wildcard data
  // process wildcard prompt for only fields that are in params
  const hasWildcardField = recipeInputs.some(input => input.allowWildcard)
  if (hasWildcardField) {
    await readWildcardDict()
    Object.keys(params).forEach(key => {
      if (params[key] && typeof params[key] === "string") {
        // check if key needs to be processed as wildcard
        const allowWildcard = recipeInputs.find(input => input.key === key)?.allowWildcard || false
        if (allowWildcard) {
          const newValue = processWildcard(params[key])

          if (newValue !== params[key]) {
            const oldValue = (params[`${key}_wildcard`]?.value || params[key]) ?? ""

            params[key] = newValue
            params[`${key}_wildcard`] = {
              value: oldValue,
              wildcards: params[`${key}_wildcard`]?.wildcards || [],
            }
          }
        }
      }
    })
  }

  return params
}

export const validateRecipeParams = async (options: {
  form: UseFormReturn<RecipeCreateType, any>
  recipeInputs: CheckableRecipeInput[]
  loras?: GetModelsResponse
}): Promise<RecipeParams | undefined> => {
  const { form, recipeInputs, loras } = options
  const { folderId, recipeId, ...rest } = form.getValues()

  await form.trigger()

  const mappedKeyStringNull = Object.keys(rest).filter(key => typeof rest[key] === "string" && rest[key].trim() === "")
  const promptWildcards = Object.keys(rest).filter(key => key.endsWith("_wildcard")) // prompt_wildcard

  let isTrigger = true
  for (const key of mappedKeyStringNull) {
    const input = recipeInputs?.find(input => input.key === key)
    if (!input) continue

    const dependInput = input.depends ? recipeInputs?.find(i => i.key === input.depends) : undefined
    if (!input.optional && (!dependInput || dependInput.isChecked)) {
      form.setError(key, {
        message: "This field is required",
      })

      const inputElement = document.getElementById(key) as HTMLDivElement

      if (inputElement) {
        inputElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        })
      }

      isTrigger = false
    }
  }

  if (!isTrigger) {
    return
  }

  form.clearErrors()

  const mappedParams = recipeInputs.map(param => ({
    optional: param.optional,
    key: param.key,
    depend: param.depends,
    name: param.name,
    value: typeof rest[param.key] === "string" ? (rest[param.key] as string).trim() : rest[param.key],
  })) as { key: string; value: string; optional?: boolean; depend?: string }[]

  const checkDependValue = mappedParams.map(param => {
    if (param.depend) {
      const dependValue = mappedParams.find(i => i.key === param.depend)?.value
      if (!dependValue) {
        return {
          ...param,
          value: "",
        }
      }
    }

    return param
  })

  const params = {
    ...checkDependValue
      .filter(param => (param.optional ? !!param.value : param.depend ? !!param.value : true))
      .reduce((acc, curr) => {
        acc[curr.key] = curr.value
        return acc
      }, {}),
    ...promptWildcards.reduce((acc, curr) => {
      acc[curr] = rest[curr]
      return acc
    }, {}),
  }

  // remove keys that should not be sent to the backend
  recipeInputs.forEach(input => {
    const value = params[input.key]

    if (value) {
      if (input?.removeKeys && Array.isArray(input.removeKeys)) {
        input.removeKeys.forEach(key => delete params[key])
      }
    }
  })

  // auto add trigger words to prompt if user selected lora model
  if (params["prompt"] && params["lora"]) {
    try {
      const selectedLoras = JSON.parse(params["lora"])

      if (selectedLoras.length) {
        selectedLoras.forEach((lora: { name: string; strength: number }) => {
          const loraModel = loras?.models.find(m => m.fileName === lora.name)
          if (loraModel) {
            const triggerWords = loraModel.defaultSettings ? loraModel.defaultSettings["triggerWords"] : null

            if (triggerWords && params["prompt"].indexOf(triggerWords) === -1) {
              params["prompt"] = `${params["prompt"]}, ${triggerWords}`
            }
          }
        })
      }
    } catch (e) {}
  }

  // read wildcard data
  // process wildcard prompt for only fields that are in params
  const newParams = await randomWildcardPublic(params, recipeInputs)

  return { params: newParams, folderId }
}

export const handlePasteParamsRecipe = (
  params: Record<string, any>,
  reset: (newParams: Record<string, any>) => void,
  onNewRecipeInputs: (newRecipeInputs: CheckableRecipeInput[]) => void,
  recipe?: RecipeItem,
  clear?: (params: Record<string, any>) => void,
) => {
  if (!recipe) return

  const defaultValues = recipe.steps
    ?.map(step =>
      step.inputs.reduce((acc, curr) => {
        acc[curr.key] =
          curr.type === RecipeInputType.Image && params[curr.key]?.toString().includes("$$prev")
            ? ""
            : curr.type === RecipeInputType.Image
              ? params["image"] || params[curr.key]
              : params[curr.key] || curr.value
        return acc
      }, {}),
    )
    .reduce((acc, curr) => ({ ...acc, ...curr }), {})

  const promptWildcards = Object.keys(params).filter(key => key.endsWith("_wildcard")) // prompt_wildcard

  const newParams = {
    ...defaultValues,
    ...promptWildcards.reduce((acc, curr) => {
      acc[curr] = params[curr]
      return acc
    }, {}),
  }

  Object.keys(newParams).forEach(key => {
    if (newParams[`${key}_wildcard`]) {
      newParams[key] = newParams[`${key}_wildcard`].value
    }
  })

  reset?.(newParams)

  const newRecipeInputs = recipe.steps
    ?.map(step => step.inputs)
    .flat()
    .map(i => ({
      ...i,
      isChecked: !!defaultValues[i.key],
      prompt_wildcard: i.allowWildcard ? newParams[`${i.key}_wildcard`] : undefined,
    }))

  clear?.(newParams)

  onNewRecipeInputs(newRecipeInputs)
}

export const getWildcardsFromLexicalInput = (data?: EditorState) => {
  const JSONEditorState = JSON.stringify(data?.toJSON())

  const wildcards = data?.read(() =>
    [...data?._nodeMap.entries()].reduce((obj, [_, value]) => {
      if (!$isBeautifulMentionNode(value)) return obj
      const valueMention = { ...value.__data, value: value.__value } as WildcardDataV2

      if (
        obj.map(item => item.id).includes(valueMention.uid) ||
        (value.__trigger === "$$" && valueMention.id.includes("$$prev."))
      )
        return obj

      return [...obj, valueMention]
    }, [] as WildcardDataV2[]),
  )

  const text = data?.read(() => $getRoot()?.getTextContent())

  return {
    JSONEditorState,
    wildcards,
    text,
  }
}

export const convertRandomPromptWildcard = (
  prompt: string,
  editorState?: EditorState,
  prompt_wildcard?: WildcardPrompt,
) => {
  const dataWildcards = getWildcardsFromLexicalInput(editorState)

  let newPrompt = prompt_wildcard?.value ?? prompt
  let wildcardData = {
    value: prompt_wildcard?.value ?? dataWildcards.text ?? prompt,
    wildcards:
      prompt_wildcard?.wildcards ??
      (dataWildcards.wildcards?.map(i => ({
        id: i.id,
        name: i.name,
        wildcards: i.wildcards,
        isPublic: i.isPublic,
      })) ||
        []),
  }

  wildcardData.wildcards?.forEach(wildcard => {
    const promptRandom = wildcard.isPublic
      ? `__${wildcard.name}__`
      : wildcard.wildcards[_random(wildcard.wildcards.length - 1)]

    if (prompt_wildcard) {
      newPrompt = newPrompt.replace(`__${wildcard.id}`, promptRandom) ?? prompt

      wildcardData = {
        ...wildcardData,
        wildcards: wildcardData.wildcards.map(i => (i.id === wildcard.id ? { ...i, wildcards: [promptRandom] } : i)),
      }
    } else {
      newPrompt = newPrompt?.replace(`__${wildcard.id}`, promptRandom)
      wildcardData = {
        ...wildcardData,
        wildcards: wildcardData.wildcards.map(i => (i.id === wildcard.id ? { ...i, wildcards: [promptRandom] } : i)),
      }
    }
  })

  return {
    newPrompt,
    wildcardData,
  }
}

export const getNewParamsWithPromptWildcard = (
  params: Record<string, any>,
  recipeHasPrompt: boolean,
  recipeInputs: {
    key: string
    allowWildcard?: boolean
    editorState?: EditorState
  }[],
  wildcardsRes?: WildcardDetail[],
): Record<string, any> => {
  // handle prompt wildcard
  let newParams = { ...params }

  // Get Prompt Keys
  const promptKeys = Object.keys(newParams)
    .filter(key => recipeInputs.find(i => i.key === key)?.allowWildcard)
    .filter(key => !key.includes("_wildcard"))

  // Run a loop by promptKeys, replace wildcard

  promptKeys.forEach(key => {
    let newPrompt = params[key]
    const wildcardKey = `${key}_wildcard`
    let wildcardPrompt: WildcardPrompt | null = null
    const wildcard = params[wildcardKey]
    // Get wildcard details in prompt by their id
    const wildcardDetails = wildcardsRes?.filter(wildcardDetail =>
      params[wildcardKey] ? params[wildcardKey]?.wildcards?.map(j => j.id).includes(wildcardDetail.id) : wildcardDetail,
    )

    const wildcards =
      wildcardDetails &&
      wildcardDetails.map(wildcard => ({
        id: wildcard.id,
        name: wildcard.name ?? "",
        wildcards: wildcard.wildcards,
        isPublic: wildcard.isPublic,
      }))

    const editorState = recipeInputs.find(i => i.key === key)?.editorState
    const allowWildcard = recipeInputs.find(i => i.key === key)?.allowWildcard

    if (recipeHasPrompt && allowWildcard) {
      const result = convertRandomPromptWildcard(
        newPrompt,
        editorState,
        wildcardsRes && !editorState
          ? {
              value: wildcard?.value ?? params[key]?.value,
              wildcards: [...(wildcards ?? []), ...(params[wildcardKey]?.wildcards ?? [])].filter(
                (v, i, a) => a.findIndex(t => t.id === v.id) === i,
              ),
            }
          : undefined,
      )

      newPrompt = result.newPrompt
      wildcardPrompt = result.wildcardData

      newParams = {
        ...newParams,
        [key]: newPrompt,
        [wildcardKey]: wildcardPrompt,
      }
    }
  })

  return _omitBy(newParams, _isNil)
}

export const handleGetPrevTask = (chains: RecipeTaskChainParams[], index: number): Record<string, any> | undefined => {
  const prevChainIndex = index > 0 ? index - 1 : 0
  const prevStepParams = index ? chains[prevChainIndex].params : undefined

  const firstStepParams = chains[0].params

  const prevStepValueIndex = prevStepParams
    ? prevStepParams?.["image"]?.include?.("$$prev")
      ? prevStepParams["image"]?.split(".")
      : undefined
    : undefined

  const prevStepImage = prevStepParams
    ? `$$prev.${prevStepValueIndex ? parseInt(prevStepValueIndex[1]) + 1 : prevChainIndex}`
    : undefined

  return {
    prompt_wildcard: firstStepParams?.prompt_wildcard,
    prompt: firstStepParams?.prompt_wildcard ? firstStepParams?.prompt_wildcard?.value : firstStepParams?.prompt,
    modelHash: firstStepParams?.modelHash || firstStepParams?.model_hash,
    model_hash: firstStepParams?.model_hash || firstStepParams?.modelHash,
    image: prevStepImage,
  }
}

export const getWildcardKeys = (params: Record<string, any>): string[] => {
  return Object.keys(params).filter(key => key.includes("_wildcard"))
}

export const getWildcardIdsByWorkflowParams = (params: Record<string, any>): string[] => {
  const wildcardKeys = getWildcardKeys(params)

  if (wildcardKeys.length === 0) {
    return []
  }

  const ids = wildcardKeys.reduce((prev, curr) => {
    if (params[curr] === undefined) return []

    prev = prev.concat(params[curr].wildcards.map(wildcard => wildcard.id))
    return prev ?? []
  }, [])

  // return unique ids
  return ids.filter((o, i, a) => a.findIndex(o2 => o2 === o) === i)
}

export const getWildcardIdsByWorkflow = (workflow: WorkflowDetail): string[] => {
  return workflow.params.reduce((prev: string[], curr) => {
    const ids = getWildcardIdsByWorkflowParams(curr.params)
    return prev.concat(ids)
  }, [])
}

export const runAgainWorkflowParams = async (workflow: WorkflowDetail) => {
  await readWildcardDict()

  const wildcardIds = getWildcardIdsByWorkflow(workflow)

  let wildcardsRes = [] as WildcardDetail[]
  if (wildcardIds.length > 0) {
    wildcardsRes = await client.api
      .wildcardControllerList({
        take: 100,
        mode: WildcardFilterMode.Owned,
        ids: wildcardIds,
        includePublic: true,
        searchTerm: "",
      })
      .then(res => res.data.wildcards)
  }

  const mappedParams = workflow.params.map((param, index) => {
    let newParams = param.params

    const hasWildcardField = param.recipeInputStep.some(input => input.allowWildcard)

    if (hasWildcardField) {
      // handle prompt wildcard
      newParams = getNewParamsWithPromptWildcard(newParams, hasWildcardField, param.recipeInputStep, wildcardsRes)

      Object.keys(newParams).forEach(key => {
        if (newParams[key] && typeof newParams[key] === "string") {
          // check if key needs to be processed as wildcard
          const allowWildcard = param.recipeInputStep?.find(input => input.key === key)?.allowWildcard || false

          if (allowWildcard) {
            // newParams = getNewParamsWithPromptWildcard(
            //   newParams,
            //   allowWildcard,
            //   getWildcardKeys(newParams).map(key => ({ key })),
            //   wildcardsRes,
            // )
            const newValue = processWildcard(newParams[key])

            if (newValue !== newParams[key]) {
              const oldValue = newParams[`${key}_wildcard`]?.value || newParams[key]

              newParams[key] = newValue
              newParams[`${key}_wildcard`] = {
                value: oldValue,
                wildcards: newParams[`${key}_wildcard`]?.wildcards || [],
              }
            }
          }
        }
      })
    }

    return {
      ...param,
      params: {
        ...param.params,
        ...newParams,
      },
    }
  })

  return mappedParams
}

export const recipeParamsWithWildcard = async (params: Record<string, any>, recipeInputs: CheckableRecipeInput[]) => {
  let newParams = params
  const wildcardIds = getWildcardIdsByWorkflowParams(newParams)

  let wildcardsRes = [] as WildcardDetail[]
  if (wildcardIds.length > 0) {
    wildcardsRes = await client.api
      .wildcardControllerList({
        take: 100,
        mode: WildcardFilterMode.Owned,
        ids: wildcardIds,
        includePublic: true,
        searchTerm: "",
      })
      .then(res => res.data.wildcards)
  }

  newParams = getNewParamsWithPromptWildcard(newParams, true, recipeInputs, wildcardsRes)
  newParams = await randomWildcardPublic(newParams, recipeInputs)

  return newParams
}

export const getStyleParamWithQueryClient = async (styleId: string, qc: QueryClient) => {
  const styles = await client.api
    .sdStyleControllerList({
      skip: 0,
      take: 1,
      ids: [styleId],
      mode: SDStyleFilterMode.Owned,
    })
    .then(res => res.data.data)

  const findStyle = styles.find(i => i.id === styleId)

  const stylesKey = useWorkspaceStyleInfiniteQuery.getKey()
  const styleListQueriesDataEntries = qc.getQueriesData<InfiniteData<SearchSDStyleResponse, number>>({
    queryKey: stylesKey,
  })

  if (findStyle && styleListQueriesDataEntries) {
    styleListQueriesDataEntries.forEach(([key, styleListData]) => {
      if (styleListData) {
        const updatedData = {
          ...styleListData,
          pages: styleListData.pages.map(page => {
            return {
              ...page,
              styles: page.data.map(style => {
                if (style.id === findStyle.id) {
                  return {
                    ...style,
                    ...findStyle,
                  }
                }

                return style
              }),
            }
          }),
        }

        qc.setQueryData(key, updatedData)
      }
    })
  }

  return findStyle
}
