import { IconProps } from "."

export const WarningIcon = (props: IconProps) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_3721_197299)">
        <path
          d="M3.54772 6.45561C5.15684 3.60832 5.9614 2.18467 7.06543 1.8182C7.67279 1.61659 8.32704 1.61659 8.93441 1.8182C10.0384 2.18467 10.843 3.60832 12.4521 6.45561C14.0612 9.3029 14.8658 10.7266 14.6244 11.8866C14.4917 12.5247 14.1645 13.1036 13.6899 13.5401C12.8273 14.3337 11.2182 14.3337 7.99992 14.3337C4.78168 14.3337 3.17257 14.3337 2.30989 13.5401C1.8353 13.1036 1.50818 12.5247 1.3754 11.8866C1.13405 10.7266 1.93861 9.3029 3.54772 6.45561Z"
          stroke="currentColor"
          strokeWidth="1.5"
        />
        <path
          d="M8.1613 11.3333V8.66667C8.1613 8.3524 8.1613 8.19526 8.06366 8.09763C7.96603 8 7.8089 8 7.49463 8"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.9945 6H8.00049"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3721_197299">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
