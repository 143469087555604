import { IconProps } from "."

export const Member = (props: IconProps) => {
  return (
    <svg width="22" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M18.6161 18H19.1063C20.2561 18 21.1707 17.4761 21.9919 16.7436C24.078 14.8826 19.1741 13 17.5 13M15.5 3.06877C15.7271 3.02373 15.9629 3 16.2048 3C18.0247 3 19.5 4.34315 19.5 6C19.5 7.65685 18.0247 9 16.2048 9C15.9629 9 15.7271 8.97627 15.5 8.93123"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M4.48131 14.1112C3.30234 14.743 0.211137 16.0331 2.09388 17.6474C3.01359 18.436 4.03791 19 5.32572 19H12.6743C13.9621 19 14.9864 18.436 15.9061 17.6474C17.7889 16.0331 14.6977 14.743 13.5187 14.1112C10.754 12.6296 7.24599 12.6296 4.48131 14.1112Z"
        stroke="white"
        strokeWidth="1.5"
      />
      <path
        d="M13 5.5C13 7.70914 11.2091 9.5 9 9.5C6.79086 9.5 5 7.70914 5 5.5C5 3.29086 6.79086 1.5 9 1.5C11.2091 1.5 13 3.29086 13 5.5Z"
        stroke="white"
        strokeWidth="1.5"
      />
    </svg>
  )
}
