import { RecipeItem } from "@/api/sdk"
import LoadingLogo from "@/components/LoadingLogo"
import React, { Fragment } from "react"
import NotFoundItems from "../Pinned/NotFoundItems"
import { motion } from "framer-motion"
import InfiniteScroll from "react-infinite-scroller"
import RecipeCard from "./RecipeCard"
import _throttle from "lodash/throttle"
import GridBreakpointColsContainer from "@/components/GridBreakpointColsContainer"
import { useDragSelection } from "@/hooks"
import ErrorUI from "@/components/ErrorUI"

interface RecipeListProps {
  selectionMode: boolean
  selectedItems: RecipeItem[]
  addSelection: (recipe: RecipeItem) => void
  isLoadingCategory: boolean
  isError: boolean
  isLoading: boolean
  recipes: RecipeItem[]
  fetchNextPage: () => void
  isFetching: boolean
  hasNextPage: boolean
  selectAll: (recipes: RecipeItem[]) => void
}

const RecipeList = ({
  addSelection,
  isLoading,
  recipes,
  isError,
  isLoadingCategory,
  selectedItems,
  selectionMode,
  selectAll,
  fetchNextPage,
  hasNextPage,
  isFetching,
}: RecipeListProps) => {
  const { DragSelection, itemRefs } = useDragSelection(recipes, selectAll, selectionMode)

  if (isLoadingCategory || isLoading)
    return (
      <div className="flex items-center justify-center w-full flex-1 text-gray-600">
        <LoadingLogo />
      </div>
    )

  if (isError) return <ErrorUI />

  if (recipes.length === 0) return <NotFoundItems />

  return (
    <Fragment>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.35, delay: 0.35 }}
        className={"relative flex-1 list-recipes"}
      >
        <InfiniteScroll
          loadMore={() => fetchNextPage()}
          hasMore={!!hasNextPage && !isFetching}
          useWindow={true}
          threshold={600}
        >
          <GridBreakpointColsContainer>
            {recipes.map((recipe, idx) => (
              <RecipeCard
                onRef={ref => (itemRefs.current[recipe.id] = ref)}
                selectionMode={selectionMode}
                onCheck={() => addSelection(recipe)}
                isChecked={selectedItems.some(item => item.id === recipe.id)}
                className={`recipe-${idx + 1}`}
                recipe={recipe}
                key={recipe.id}
              />
            ))}
          </GridBreakpointColsContainer>
        </InfiniteScroll>
      </motion.div>
      <DragSelection />
    </Fragment>
  )
}

export default RecipeList
