import { IconProps } from "."

export const SchoolBellIcon = (props: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14" fill="none" {...props}>
      <path
        d="M4.52137 6.18744L4.46698 6.26693C3.94369 7.03175 3.07669 7.48905 2.14999 7.48905H2.08667C1.56505 7.48905 1.30382 8.11972 1.67266 8.48856L6.01144 12.8273C6.38028 13.1962 7.01095 12.935 7.01095 12.4133V12.35C7.01095 11.4233 7.46826 10.5563 8.23307 10.033L8.31256 9.97863C9.72201 9.01427 9.90861 7.00657 8.70102 5.79898C7.49343 4.59139 5.48573 4.77799 4.52137 6.18744Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.1667 10.333C12.0454 10.9894 11.7292 11.608 11.2153 12.104C10.7316 12.5709 10.1354 12.8687 9.5 12.9997"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M4.16667 2.33301C3.52562 2.46994 2.92419 2.78118 2.43618 3.26919C1.94817 3.7572 1.63694 4.35862 1.5 4.99967"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M8.16797 4.86825L10.7608 1.58402C11.3303 0.86265 12.4017 0.799844 13.0516 1.44973C13.7015 2.09961 13.6387 3.17102 12.9173 3.74052L9.63305 6.33333"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.83464 11.8761C4.2246 12.4862 3.23553 12.4862 2.6255 11.8761C2.01546 11.2661 2.01546 10.277 2.6255 9.66699"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </svg>
  )
}
