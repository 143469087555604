import { KeyboardIcon } from "@/components/shared/icons"
import { useShortcutKeyboardStore } from "@/stores"
import React from "react"

const ShortcutKeyboard = () => {
  const setIsOpen = useShortcutKeyboardStore(state => state.setIsOpen)

  return (
    <div className="text-xs flex items-center justify-end cursor-pointer w-full" onClick={() => setIsOpen(true)}>
      <KeyboardIcon width={14} height={14} />
      <p className="mx-2 font-semibold text-atherGray-300">Shortcut</p>
      <span className="border border-atherGray-800 text-atherGray-500 rounded-lg py-0.5 px-1 font-semibold">⇧Q</span>
    </div>
  )
}

export default ShortcutKeyboard
