import React from "react"
import DateDistanceToNow from "@/components/DateDistanceToNow"
import TextCopy from "@/components/TextCopy"
import PromptDetailActionButtons from "./PromptDetailActionButtons"
import { PromptItem } from "@/api/sdk"
import AspectRatio from "@/components/AspectRatio"
import ImageWebpComponent from "@/components/ImageWebpComponent"

interface PromptDetailViewProps {
  promptDetail: PromptItem
  onClose: () => void
  onOpenUpdate: () => void
}

const PromptDetailView: React.FC<PromptDetailViewProps> = ({ promptDetail, onClose, onOpenUpdate }) => {
  return (
    <div>
      <div className="flex items-center mb-2">
        <p className="text-xs text-atherGray-400">
          Created <DateDistanceToNow date={promptDetail?.createdAt} />
        </p>

        <PromptDetailActionButtons onClose={onClose} promptData={promptDetail} onOpenUpdate={onOpenUpdate} />
      </div>
      <AspectRatio ratio={375 / 182}>
        <ImageWebpComponent
          className="object-cover h-full w-full rounded-xl"
          src={promptDetail.thumbnailUrl ?? "https://cdn.protogaia.com/hO9MdTi80f_ogT_528G90.w400.webp"}
        />
      </AspectRatio>
      <div className="flex-1 flex flex-col mt-4">
        <div className="mb-2 flex items-center justify-between">
          <p className="text-xs text-atherGray-500 font-semibold">Prompt</p>
          <TextCopy width={16} height={16} value={promptDetail.prompt} />
        </div>
        <div className="flex-1 bg-atherGray-950 w-full rounded-lg p-2 min-h-[4.5rem]">
          <div className="flex flex-wrap gap-1 break-words ">
            {promptDetail.prompt.split(",").map((i, idx) => (
              <span
                className="flex items-center border text-atherGray-300 border-atherGray-700 rounded-md py-0.5 px-1 text-sm"
                key={idx}
              >
                {i}
                <span className="ml-1 w-3.5 flex items-center justify-center">
                  <TextCopy value={i} width={14} height={14} />
                </span>
              </span>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PromptDetailView
