import client from "@/api/client"
import { GetSimilarImagesResult, ImageSimpleDetail } from "@/api/sdk"
import { createAuthenticatedInfiniteQuery } from "@/queries/createAuthenticatedQuery"
import { enableInfiniteQueryMiddleware } from "@/queries/middlewares/enableQueryMiddleware"

export const useWorkspaceImagesSimilarInfiniteQuery = createAuthenticatedInfiniteQuery<
  GetSimilarImagesResult,
  { id: string },
  Error,
  ImageSimpleDetail
>({
  primaryKey: "infinite-workspace-similar-images",
  queryFn: ({ queryKey: [_primaryKey, variables], pageParam = 0 }) =>
    client.api
      .imageControllerGetSimilarImages(variables.id, {
        skip: pageParam * 20,
        take: 20,
      })
      .then(res => res.data),
  getNextPageParam: (lastPage, pages) => (lastPage && lastPage.images.length < 20 ? undefined : pages.length),
  initialPageParam: 0,
  flattenFn: data => data.images,
  use: [enableInfiniteQueryMiddleware(v => !!v.id)],
})
