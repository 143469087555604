import { useToast } from "@/hooks"
import { useMutateTipWowPoints } from "@/queries/useTipWowPoints"
import { currency } from "@/utils"
import Link from "next/link"
import { ChangeEvent, useEffect, useState } from "react"
import IconButton from "../IconButton"
import Input from "../Input"
import Modal from "../Modal"
import { WOWIcon } from "../shared/icons"
import { googleAnalytics } from "@/lib/gtag"

interface TipModalProps {
  isOpen: boolean
  onClose: () => void
  pointBalance?: string
  recipientUid?: string
  recipientName?: string
}

const TipModal = ({ isOpen, onClose, pointBalance = "0", recipientUid, recipientName }: TipModalProps) => {
  const [amount, setAmount] = useState<number | undefined>(undefined)
  const handleSetAmount = (value: number) => {
    setAmount(value)
  }
  const toast = useToast()

  const { mutate: mutateTip, isPending: isTipping } = useMutateTipWowPoints({
    onSuccess: data => {
      setAmount(undefined)
      onClose?.()
      toast({
        title: "Tip successfully",
        status: "success",
        message: [`Your have successfully tipped ${data.amount} $WOW to ${data.recipient.name}`],
      })
    },
    onError: (err: any) => {
      toast({
        title: "Tip failed",
        status: "error",
        message: [err?.message ?? "An error occurred"],
      })
    },
  })

  useEffect(() => {
    if (!isOpen && amount !== undefined) {
      setAmount(undefined)
    }
  }, [isOpen])

  return (
    <Modal title={"Tip"} isOpen={isOpen} onClose={onClose} bodyClassName="overflow-hidden" className="max-w-md">
      <div className="flex">
        <div className="flex items-center justify-center text-base text-atherPrimary-100 ">
          <span>Available $WOW:&nbsp;</span>
          <span className="mr-1">
            <WOWIcon width={20} height={20} />
          </span>
          <span className="font-medium text-atherGray-0">{currency(parseInt(pointBalance))}</span>
        </div>
      </div>
      <div className="mt-4 text-atherGray-300">How much $WOW do you want to tip?</div>
      <Input
        className="rounded-lg"
        type="number"
        rightIcon={<WOWIcon width={20} height={20} />}
        placeholder="0"
        min={0}
        onBlur={() => {
          if (amount !== undefined && amount < 1) {
            handleSetAmount(1)
          }
        }}
        value={amount ? parseInt(amount.toString()) : undefined}
        onChange={e => {
          handleSetAmount(parseInt(e.target.value))
        }}
      />
      <div className="flex w-full gap-1">
        {[100, 200, 500, 1000].map(exampleValue => (
          <IconButton
            onClick={() => setAmount(exampleValue)}
            key={exampleValue}
            value={exampleValue}
            className="w-12 h-8 mt-2 bg-transparent border border-atherGray-800"
          >
            {exampleValue}
          </IconButton>
        ))}
      </div>

      <div className="mt-4">
        <Link className="underline text-atherPurple-400 font-semibold" href={"/quests"}>
          How to get WOW POINT?
        </Link>
      </div>
      <div className="flex justify-end gap-2 mt-2">
        <IconButton onClick={onClose} colorScheme="secondary">
          Cancel
        </IconButton>
        <IconButton
          isLoading={isTipping}
          onClick={async e => {
            e.preventDefault()
            if (!recipientUid) {
              return
            }
            if (!amount || amount < 1) {
              toast({
                title: "Tip failed",
                status: "error",
                message: ["Minimum tipping is 1 $WOW"],
              })
              return
            }

            googleAnalytics.handleCategoryEvent({
              action: "click",
              params: {
                action: "Send Tip",
                profile_uid: recipientUid,
                profile_name: recipientName ?? "",
                wow_value: amount,
              },
            })

            mutateTip({ data: { amount, recipientUid } })
          }}
        >
          Tip
        </IconButton>
      </div>
    </Modal>
  )
}

export default TipModal
