import { IconProps } from "."

export const CommentAddIcon = (props: IconProps) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_7000_17140)">
        <path
          d="M11.668 4.99999H18.3346M15.0013 1.66666L15.0013 8.33332"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.08365 16.25C4.00022 16.1434 3.1886 15.818 2.64428 15.2737C1.66797 14.2974 1.66797 12.726 1.66797 9.58333V9.16667C1.66797 6.02397 1.66797 4.45262 2.64428 3.47631C3.62059 2.5 5.19194 2.5 8.33464 2.5H9.58464M5.41797 15C5.24675 15.8349 4.48271 17.6388 5.26438 18.2209C5.67297 18.5182 6.32404 18.2007 7.62619 17.5657C8.54007 17.1201 9.46482 16.6091 10.4635 16.3792C10.8289 16.2959 11.2009 16.2604 11.668 16.25C14.8107 16.25 16.382 16.25 17.3583 15.2737C18.2905 14.3415 18.3326 12.8669 18.3345 10"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_7000_17140">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default CommentAddIcon
