import { ContentModerationRating, ExploreImageSimpleDetail, ImageGenerationSource, ImageSimpleDetail } from "@/api/sdk"
import AppLogo from "@/components/Alpha/AppLogo"
import IconButton from "@/components/IconButton"
import ImageWebpComponent from "@/components/ImageWebpComponent"
import Skeleton from "@/components/Skeleton"
import { TickIcon } from "@/components/shared/icons"
import useContainerWidth from "@/hooks/useContainerWidth"
import { googleAnalytics } from "@/lib/gtag"
import { cn } from "@/utils/cn"
import { watermark } from "@/utils/watermark"
import classNames from "classnames"
import { AnimatePresence, motion } from "framer-motion"
import { Fragment, useEffect, useRef, useState } from "react"
import { useInView } from "react-intersection-observer"
import ImageActionButtons from "./ImageActionButtons"
import NsfwBlurImage from "@/components/NsfwBlurImage"

interface GalleryImageProps {
  onClick?: () => void
  image: ImageSimpleDetail | (ExploreImageSimpleDetail & { isDiscoverable?: boolean })
  isDisabledInView?: boolean
  containerWidth?: number
  stretch?: boolean
  hiddenMenu?: boolean
  showHoverAttributes?: boolean
  hiddenSelect?: boolean
  hiddenReaction?: boolean
  refetchImages?: () => void
  selectionMode?: boolean
  hiddenTypeImage?: boolean
  isChecked?: boolean
  isUpload?: boolean
  parentFolderId?: string
  className?: string
  onCheck?: () => void
  imgClassName?: string
  promptMode?: boolean
  hiddenSelectPrompt?: boolean
  isExplore?: boolean
}

const ImageInView = ({
  onClick,
  image,
  className,
  hiddenTypeImage,
  isDisabledInView,
  containerWidth,
  hiddenReaction,
  isUpload,
  imgClassName,
  parentFolderId,
  hiddenSelect,
  hiddenSelectPrompt,
  isExplore,
  hiddenMenu,
  stretch,
  isChecked,
  onCheck,
  selectionMode,
  refetchImages,
  showHoverAttributes = true,
}: GalleryImageProps) => {
  const [imageLoaded, setImageLoaded] = useState(false)
  const [isShow, setIsShow] = useState(
    image.moderationRating === ContentModerationRating.HardcoreNsfw ||
      (isExplore && image.moderationRating === ContentModerationRating.SemiNsfw) ||
      false,
  )

  const { url, name: alt, blurHash, width: imageWidth, height: imageHeight } = image
  const imageItemRef = useRef<HTMLDivElement>(null)
  const { ref, inView } = useInView({
    threshold: 0,
    rootMargin: "800px 0px 800px 0px",
  })

  const [src, setSrc] = useState(url)
  const [imageSize, setImageSize] = useState(0)
  const widthCard = useContainerWidth(imageItemRef, !!image)

  useEffect(() => {
    if (url && image.owner) {
      image.owner.uid === "anonymous"
        ? watermark(url).then(newUrl => {
            setSrc(newUrl)
          })
        : setSrc(url)
    }
  }, [image.owner, url])

  useEffect(() => {
    if (isDisabledInView) return
    if (containerWidth && imageWidth && imageHeight && widthCard) {
      const height = (imageHeight / imageWidth) * (widthCard || 250)

      setImageSize(height)
    }
  }, [containerWidth, imageWidth, imageHeight, widthCard, isDisabledInView])

  const handleClick = e => {
    if (onClick && !isShow) {
      e.preventDefault()
      e.stopPropagation()
      onClick()
    }
    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        tab_name: "image",
        action: "View Image Detail",
        image_id: image.id,
        image_name: image.name,
      },
    })
  }

  return (
    <div
      ref={imageItemRef}
      className={classNames(
        "image-item relative overflow-hidden rounded-2xl transform-gpu bg-atherGray-950",
        className,
      )}
      style={{
        minHeight: "10rem",
        height: stretch ? "100%" : isDisabledInView ? "auto" : imageSize,
      }}
      onClick={e => {
        handleClick(e)
      }}
    >
      {selectionMode && (
        <div
          className={cn(
            "absolute w-full h-full top-0 left-0 border-[2px] pointer-events-none rounded-2xl",
            {
              "border-atherPurple-500": isChecked,
              "border-[transparent]": !isChecked,
              "z-0": !selectionMode,
              "z-[1]": selectionMode,
            },
            className,
          )}
        />
      )}

      <div
        ref={ref}
        className="flex items-center justify-center w-full flex-col overflow-hidden"
        style={{
          minHeight: "10rem",
          height: stretch ? "100%" : isDisabledInView ? "auto" : imageSize,
        }}
      >
        <Skeleton
          className="group  h-full w-full flex items-center justify-center"
          isLoaded={imageLoaded && (!blurHash || !isShow)}
          blurHash={blurHash ?? undefined}
        >
          <AnimatePresence mode="wait">
            {(inView || isDisabledInView) && (
              <Fragment>
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className={classNames("h-full w-full select-none flex flex-col items-center justify-center", {
                    "cursor-default pointer-events-none select-none": hiddenSelect,
                  })}
                >
                  <ImageWebpComponent
                    src={src}
                    alt={image.name ?? ""}
                    className={cn(
                      "block object-contain w-full h-full",
                      {
                        "blur-2xl": !blurHash && isShow,
                      },
                      imgClassName,
                    )}
                    onLoad={() => setImageLoaded(true)}
                  />
                </motion.div>
                {!hiddenSelect && (
                  <div
                    className={classNames(
                      "absolute bottom-0 left-0 transition-all pointer-events-none hidden lg:block group-hover:bg-blackAlpha-400 group-hover:h-full group-hover:w-full",
                      {
                        "h-full w-full bg-blackAlpha-600": selectionMode,
                      },
                    )}
                  />
                )}
                {image.generationSource !== ImageGenerationSource.None && (
                  <AppLogo disabled textLogo className="absolute z-[1] bottom-1 right-0 w-[8rem] p-2 opacity-50" />
                )}
              </Fragment>
            )}
          </AnimatePresence>
        </Skeleton>

        {(inView || isDisabledInView) && (
          <>
            {(!hiddenMenu || selectionMode) &&
              (selectionMode ? (
                <IconButton
                  onClick={e => {
                    e.stopPropagation()
                    e.preventDefault()

                    googleAnalytics.handleCategoryEvent({
                      action: "click",
                      params: {
                        tab_name: "image",
                        action: "Select Image",
                        image_id: image.id,
                        image_name: image.name,
                      },
                    })

                    onCheck?.()
                  }}
                  className={cn(
                    "absolute top-2 right-2 shadow-sm shadow-blackAlpha-500 bg-atherGray-700 p-0 w-7 h-7 min-h-0 rounded-full",
                    {
                      "text-white bg-atherPurple-500 hover:bg-atherPurple-400": isChecked,
                      "text-atherGray-300 hover:bg-atherGray-600": !isChecked,
                    },
                  )}
                >
                  <TickIcon />
                </IconButton>
              ) : (
                <ImageActionButtons
                  isShow={isShow}
                  parentFolderId={parentFolderId}
                  image={
                    {
                      ...image,
                      url: src,
                    } as ImageSimpleDetail
                  }
                  refetchImages={refetchImages}
                />
              ))}

            {(image.workflow ||
              image.recipe ||
              image.isDiscoverable ||
              (!isExplore && image.moderationRating === ContentModerationRating.SemiNsfw)) && (
              <div
                title={image.workflow?.name || image.recipe?.name}
                className={classNames("absolute flex items-center top-2 left-0", {
                  "pointer-events-none": hiddenReaction,
                })}
              >
                {image.isDiscoverable && (
                  <span className="font-semibold bg-atherPurple-500 px-1.5 py-0.5 rounded-r-lg text-[0.65rem]">E</span>
                )}
                {image.moderationRating === ContentModerationRating.SemiNsfw && !isExplore && (
                  <span className="font-semibold bg-red-500 px-1 py-0.5 ml-1 rounded-md text-[0.65rem]">sNSFW</span>
                )}
              </div>
            )}

            {inView &&
              (image.moderationRating === ContentModerationRating.HardcoreNsfw ||
                (isExplore && image.moderationRating === ContentModerationRating.SemiNsfw)) && (
                <NsfwBlurImage
                  isShow={isShow}
                  moderationRating={image.moderationRating}
                  onToggleShow={setIsShow}
                  gaEvent={{
                    tab_name: "image",
                    params: {
                      image_id: image.id,
                      image_name: image.name,
                    },
                  }}
                />
              )}

            {isUpload && (
              <div className="absolute bottom-0 left-0 z-[1] p-2 overflow-hidden">
                <p className="line-clamp-1 break-all text-xs">{image.name}</p>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default ImageInView
