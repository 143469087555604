import { ListRecipeTasksResponse, RecipeTaskWithFullImages } from "@/api/sdk"
import useClientStore from "@/lib/clientStore"
import { cn } from "@/lib/utils"
import { useAuth } from "@/providers/authContext"
import { useGetOngoingTaskQuery } from "@/queries/task/useGetTaskInfiniteQuery"
import { useDrawerNotification, useTempTaskStoreV2 } from "@/stores"
import classNames from "classnames"
import useCustomRouter from "@/hooks/useCustomRouter"
import { memo, useCallback, useEffect, useMemo } from "react"
import IconButton from "../IconButton"
import LoadingIcon from "../LoadingIcon"
import ReactPortal from "../ReactPortal"
import {
  CalendarFavoriteIcon,
  ImageSparkIcon,
  MenuIcon,
  MessageMultiIcon,
  RocketIcon,
  WOWIcon,
  WOWOutlineIcon,
} from "../shared/icons"
import { useScreen } from "@/hooks"
import { useQueryClient } from "@tanstack/react-query"
import { RecipeSocketData } from "../Workspace/Recipes/RecipeDetail/ListRecipes"

const MobileNavigation = ({ className, isWrapperLayout = true }: { className?: string; isWrapperLayout?: boolean }) => {
  const router = useCustomRouter()
  const [isMenuOpen, setIsMenuOpen] = useClientStore(state => [state.isMenuOpen, state.setIsMenuOpen])
  const tempTask = useTempTaskStoreV2(state => state.tempTask)
  const pathname = router.pathname
  const { user, loading, socketConnected, socketRef } = useAuth()
  const { width } = useScreen()
  const isMobile = useMemo(() => width < 768, [width])
  const [isOpenNotification, setIsOpenNotification] = useDrawerNotification(state => [state.isOpen, state.setIsOpen])
  const qc = useQueryClient()

  const handleClick = useCallback(
    async (path?: string) => {
      if (path === "/notification") {
        setIsMenuOpen(false)
        setIsOpenNotification(!isOpenNotification)

        return
      }

      if (path) {
        setIsMenuOpen(false)
        if (isOpenNotification) {
          setIsOpenNotification(false)
        }
        router.push(path, undefined, { shallow: true })

        return
      }

      if (isOpenNotification) {
        setIsOpenNotification(false)
      }

      setIsMenuOpen(!isMenuOpen)
    },
    [isOpenNotification, setIsMenuOpen, setIsOpenNotification, router, isMenuOpen],
  )

  const { data: onGoingRecipes, isLoading: isLoadingOnGoing } = useGetOngoingTaskQuery({
    variables: {
      taskIds: user ? undefined : tempTask?.map(t => t.id),
    },
    enabled: !loading && isMobile,
  })

  const mappedOngoingRecipes = useMemo(
    () =>
      [...(onGoingRecipes?.running ?? []), ...(onGoingRecipes?.queued ?? [])]
        .sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
        .filter(i => i.recipeType !== "turbo") as RecipeTaskWithFullImages[],
    [onGoingRecipes],
  )

  const isLoading = useMemo(
    () => !loading && (mappedOngoingRecipes.length > 0 || isLoadingOnGoing),
    [mappedOngoingRecipes, isLoadingOnGoing, loading],
  )

  const refreshProgress = useCallback(async () => {
    qc.invalidateQueries({
      queryKey: [
        "board-view-task",
        {
          statuses: ["RUNNING"],
        },
      ],
    })
    qc.invalidateQueries({
      queryKey: ["infinite-workspace-workflows"],
    })
  }, [qc])

  const refetchOnGoing = useCallback(async () => {
    const onGoingKey = useGetOngoingTaskQuery.getKey()

    await qc.cancelQueries({ queryKey: onGoingKey })

    qc.invalidateQueries({ queryKey: onGoingKey })
  }, [])

  useEffect(() => {
    if (!isMobile) return

    if (!socketConnected || !socketRef.current) return
    const socket = socketRef.current

    const onStartTask = (data: RecipeSocketData) => {
      if (data) {
        setTimeout(() => {
          qc.invalidateQueries({
            queryKey: [
              "board-view-task",
              {
                statuses: ["QUEUED"],
              },
            ],
          })

          refreshProgress()
        }, 500)
      }
    }

    const onCompleteTask = (data: RecipeSocketData) => {
      const onGoingKey = useGetOngoingTaskQuery.getKey()

      const ongoingTasksEntities = qc.getQueriesData<ListRecipeTasksResponse>({ queryKey: onGoingKey })

      if (data) {
        if (!mappedOngoingRecipes.map(i => i.id).includes(data.id)) return

        if (data.resultImages.length > 0) {
          qc.invalidateQueries({
            queryKey: [
              "board-view-task",
              {
                statuses: ["COMPLETED"],
              },
            ],
          })
        }

        setTimeout(() => {
          ongoingTasksEntities?.forEach(([key, list]) => {
            if (list) {
              const updateData = {
                ...list,
                queued: list.queued.filter(i => i.id !== data.id),
                running: list.running.filter(i => i.id !== data.id),
              }

              qc.setQueryData(key, updateData)
            }
          })

          qc.invalidateQueries({
            queryKey: [
              "board-view-task",
              {
                statuses: ["CANCELED"],
              },
            ],
          })
          qc.invalidateQueries({
            queryKey: [
              "board-view-task",
              {
                statuses: ["FAILED"],
              },
            ],
          })

          qc.invalidateQueries({
            queryKey: ["infinite-workspace-creation-images"],
          })

          qc.invalidateQueries({
            queryKey: ["infinite-workspace-creation-images-not-auth"],
          })

          refetchOnGoing()
          refreshProgress()
        }, 250)
      }
    }

    socket.on("recipe-task-start", async (data: RecipeSocketData) => {
      onStartTask(data)
    })

    socket.on("recipe-task-complete", async (data: RecipeSocketData) => {
      onCompleteTask(data)
    })

    return () => {
      socket?.off("recipe-task-start", onStartTask)
      socket?.off("recipe-task-complete", onCompleteTask)
    }
  }, [socketConnected, refreshProgress, socketRef, qc, refetchOnGoing, mappedOngoingRecipes.length, isMobile])

  if (!isMobile) {
    return null
  }

  const wrapperLayout = (children: React.ReactNode) => {
    if (!isWrapperLayout) return children

    return <ReactPortal wrapperId="layout">{children}</ReactPortal>
  }

  return (
    <>
      {wrapperLayout(
        <div
          className={cn(
            "w-full bg-atherGray-900 h-[5.15rem] transition-transform ease-in-out duration-300 flex md:hidden flex-col z-[25] fixed bottom-0 inset-x-0",
            className,
          )}
          style={{
            backgroundImage: "linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0))",
          }}
        >
          <div className="px-4 flex-1 bg-atherGray-900">
            <div className="flex items-center gap-1.5 h-16">
              <IconButton
                onClick={() => handleClick("/quests")}
                className={classNames("min-h-0 p-0  pt-4 grow flex-col text-atherGray-500", {
                  "text-atherGray-0": pathname.includes("/quests") && !isMenuOpen && !isOpenNotification,
                })}
                colorScheme="transparent"
              >
                {pathname.includes("/quests") && !isMenuOpen && !isOpenNotification ? (
                  <WOWIcon width={20} height={20} />
                ) : (
                  <WOWOutlineIcon width={20} height={20} />
                )}
                <span className="text-[0.6rem]">Earn $WOW</span>
              </IconButton>
              <IconButton
                onClick={() => handleClick("/explore")}
                className={classNames("min-h-0 p-0  pt-4 grow flex-col text-atherGray-500", {
                  "text-atherGray-0": pathname.includes("/explore") && !isMenuOpen && !isOpenNotification,
                })}
                colorScheme="transparent"
              >
                <RocketIcon width={20} height={20} />
                <span className="text-[0.6rem]">Explore</span>
              </IconButton>
              <IconButton
                onClick={() => handleClick("/my-creations")}
                colorScheme="transparent"
                title="Task tracker"
                className={classNames("min-h-0 p-0  pt-4 grow flex-col text-atherGray-500", {
                  "text-atherGray-0": pathname.includes("/my-creations") && !isMenuOpen && !isOpenNotification,
                })}
              >
                <div className="relative w-5 h-5">
                  <ImageSparkIcon className="absolute inset-0" width={20} height={20} />
                  {isLoading && (
                    <div
                      className={
                        "absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 flex w-10 h-10 justify-center items-center pointer-events-none overflow-hidden"
                      }
                    >
                      <LoadingIcon key="loading-task" color="#7828FB" width={36} height={36} />
                    </div>
                  )}
                </div>
                <span className="text-[0.6rem]">My Creations</span>
              </IconButton>
              <IconButton
                onClick={() => handleClick("/events")}
                className={classNames("min-h-0 p-0  pt-4 grow flex-col text-atherGray-500", {
                  "text-atherGray-0": pathname.includes("/events") && !isMenuOpen && !isOpenNotification,
                })}
                colorScheme="transparent"
              >
                <CalendarFavoriteIcon width={20} height={20} />
                <span className="text-[0.6rem]">Events</span>
              </IconButton>
              <IconButton
                onClick={() => handleClick()}
                className={classNames("min-h-0 p-0  pt-4 grow flex-col text-atherGray-500", {
                  "text-atherGray-0": isMenuOpen,
                })}
                colorScheme="transparent"
              >
                <MenuIcon width={20} height={20} />
                <span className="text-[0.6rem]">Menu</span>
              </IconButton>
            </div>
          </div>
        </div>,
      )}
    </>
  )
}

export default memo(MobileNavigation)
