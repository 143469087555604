import React from "react"
import { twMerge } from "tailwind-merge"
import classNames from "classnames"
import { useEarlyAccessStore, useSignInStore } from "@/stores"
import { googleAnalytics } from "@/lib/gtag"

const SignInRequiredButton = ({
  text = "Sign In",
  earlyAccessModal,
  className,
  onClick,
}: {
  className?: string
  text?: React.ReactNode
  earlyAccessModal?: boolean
  onClick?: () => void
}) => {
  const setSignInModal = useSignInStore(state => state.setSignInModal)
  const setEarlyAccess = useEarlyAccessStore(state => state.setEarlyAccess)

  return (
    <button
      type="button"
      onClick={() => {
        if (earlyAccessModal) {
          setEarlyAccess(true)
        } else {
          setSignInModal({
            signIn: true,
          })
        }
        googleAnalytics.handleCategoryEvent({
          action: "click",
          params: {
            action: earlyAccessModal ? "Early Access" : "Sign In",
          },
        })

        onClick?.()
      }}
      className={twMerge(classNames("relative flex items-center w-full justify-center p-3 rounded-lg"), className)}
    >
      {text}
    </button>
  )
}

export default SignInRequiredButton
