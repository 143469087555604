import { IconProps } from "."

export const FileIcon = (props: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 20 22" fill="none" {...props}>
      <path d="M14 16L7 16" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14 12L11 12" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M18.5 13C18.5 16.7712 18.5 18.6569 17.2552 19.8284C16.0104 21 14.0069 21 10 21H9.22727C5.96607 21 4.33546 21 3.20307 20.2022C2.87862 19.9736 2.59058 19.7025 2.3477 19.3971C1.5 18.3313 1.5 16.7966 1.5 13.7273V11.1818C1.5 8.21865 1.5 6.73706 1.96894 5.55375C2.72281 3.65142 4.31714 2.15088 6.33836 1.44135C7.59563 1 9.16981 1 12.3182 1C14.1173 1 15.0168 1 15.7352 1.2522C16.8902 1.65765 17.8012 2.5151 18.232 3.60214C18.5 4.27832 18.5 5.12494 18.5 6.81818V13Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M1.5 11C1.5 9.15905 2.99238 7.66667 4.83333 7.66667C5.49912 7.66667 6.28404 7.78333 6.93137 7.60988C7.50652 7.45576 7.95576 7.00652 8.10988 6.43136C8.28333 5.78404 8.16667 4.99912 8.16667 4.33333C8.16667 2.49238 9.65905 1 11.5 1"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
