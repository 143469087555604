import { clientUrl, cdnUrl } from "@/constants"
import Head from "next/head"

interface MetadataProps {
  title?: string
  description?: string
  previewImage?: string
  url?: string
  type?: string
  alt?: string
  siteName?: string
  twitterHandle?: string
}

export function Metadata({ title, description, previewImage, url, type, alt, siteName, twitterHandle }: MetadataProps) {
  const image = previewImage || `https://${cdnUrl}/resources/preview-image-v3.png`
  const finalUrl = url || clientUrl
  const finalType = type || "website"
  const finalAlt = alt || description
  const finalSiteName = siteName || "GAIA"

  const addMetaTag = (name: string, content?: string) => (
    <meta key={name} {...(name.startsWith("og:") ? { property: name } : { name })} content={content} />
  )

  return (
    <Head>
      <title>{title}</title>
      {addMetaTag("description", description)}

      {/* Open Graph / Facebook */}
      {addMetaTag("og:type", finalType)}
      {addMetaTag("og:url", finalUrl)}
      {addMetaTag("og:title", title)}
      {addMetaTag("og:description", description)}
      {addMetaTag("og:image", image)}
      {addMetaTag("og:image:alt", finalAlt)}
      {addMetaTag("og:site_name", finalSiteName)}

      {/* Twitter */}
      {addMetaTag("twitter:card", "summary_large_image")}
      {addMetaTag("twitter:url", finalUrl)}
      {addMetaTag("twitter:title", title)}
      {addMetaTag("twitter:description", description)}
      {addMetaTag("twitter:image", image)}
      {addMetaTag("twitter:image:alt", finalAlt)}
      {twitterHandle && addMetaTag("twitter:site", `@${twitterHandle}`)}
      {twitterHandle && addMetaTag("twitter:creator", `@${twitterHandle}`)}

      {/* Additional SEO tags */}
      {addMetaTag("robots", "index, follow")}
      {addMetaTag("author", finalSiteName)}
      <link rel="canonical" href={finalUrl} />

      {/* Schema.org markup for Google */}
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": finalType,
          name: title,
          description: description,
          image: image,
          url: finalUrl,
        })}
      </script>
    </Head>
  )
}
