import client from "@/api/client"
import IconButton from "@/components/IconButton"
import ImageUpload from "@/components/ImageUpload"
import { ImageViewWithDelete } from "@/components/ImageUpload/ImageView"
import { ComputerIcon, DeleteIcon, FolderUploadIcon, UploadIcon } from "@/components/shared/icons"
import { INPUT_IMAGE_UPLOAD_SIZE_LIMIT } from "@/constants/file"
import { useToast } from "@/hooks"
import { cn } from "@/lib/utils"
import { useAuth } from "@/providers/authContext"
import { useManagementErrorsStore } from "@/stores"
import { bytesToSize } from "@/utils/bytes-to-size"
import { handleConvertHEICtoJPG } from "@/utils/convert"
import generateCaptchaToken from "@/utils/generateCaptchaToken"
import { resizeImage } from "@/utils/resize-image"
import dynamic from "next/dynamic"
import { forwardRef, useEffect, useRef, useState } from "react"

const UploadImageFromFolderModal = dynamic(() => import("@/components/ImageUpload/UploadImageFromFolderModal"), {
  ssr: false,
})

export const uploadRecipeImage = async (file: Blob, maxSize = 2048) => {
  let newFile = file

  if (newFile.type === "image/heic" || newFile.name.endsWith(".heic")) {
    newFile = await handleConvertHEICtoJPG(newFile)
  }

  newFile = await resizeImage(newFile, maxSize)

  return client.api
    .recipeTaskControllerUpload({ files: [newFile as File] })
    .then(res => res.data)
    .then(data => {
      return data.images[0].url
    })
}

export const uploadRecipeImageNotAuth = async (file: Blob, maxSize = 2048) => {
  let newFile = file

  if (newFile.type === "image/heic" || newFile.name.endsWith(".heic")) {
    newFile = await handleConvertHEICtoJPG(newFile)
  }

  newFile = await resizeImage(newFile, maxSize)

  const captchaToken = await generateCaptchaToken()

  return client.api
    .recipeTaskControllerUploadImagesForFreeTask({ files: [newFile as File], captchaToken })
    .then(res => res.data)
    .then(data => {
      return data.images[0].url
    })
}

interface UploadRecipeImageProps {
  onChange: (value: string) => void
  onOpenModal?: (value: boolean) => void
  value: string
  valuePrev?: string
  error?: string
  description?: string
  title?: string
  className?: string
  listButtonClassName?: string
  buttonsClassName?: string
  uploadImageClassName?: string
  maxSize?: number
  uploadContent?: React.ReactNode
}

type UploadImageModalType = "upload" | "folder" | "prev"

const UploadRecipeImage = forwardRef<HTMLInputElement, UploadRecipeImageProps>(
  (
    {
      value,
      onChange,
      error,
      description,
      title,
      className,
      valuePrev,
      buttonsClassName,
      onOpenModal,
      listButtonClassName,
      uploadImageClassName,
      maxSize = 2048,
      uploadContent,
    },
    ref,
  ) => {
    const toast = useToast()
    const [isOpen, setIsOpen] = useState<UploadImageModalType | null>(null)
    const fileInputRef = useRef<HTMLInputElement>(null)
    const [isUploading, setIsUploading] = useState(false)
    const setErrorState = useManagementErrorsStore(state => state.setErrorState)
    const { user } = useAuth()

    const handleUpload = async (files: Blob[]) => {
      const file = files[0]

      if (file.size > INPUT_IMAGE_UPLOAD_SIZE_LIMIT) {
        toast({
          status: "error",
          title: "File size too large",
          message: [
            `Input image exceeds ${bytesToSize(INPUT_IMAGE_UPLOAD_SIZE_LIMIT)}, please scale down the image and try again`,
          ],
        })
        return
      }
      setIsUploading(true)

      return (user ? uploadRecipeImage(file, maxSize) : uploadRecipeImageNotAuth(file, maxSize))
        .then(onChange)
        .finally(() => {
          setIsUploading(false)
        })
        .catch(err => {
          if (
            err?.message.startsWith("Guest user does not have permission") ||
            err?.message.startsWith("Your subscription has ended")
          ) {
            setErrorState({
              isOpen: true,
              message: err.message,
            })
            return
          }
        })
    }

    useEffect(() => {
      if (!onOpenModal) return

      onOpenModal(isOpen === "folder")
    }, [isOpen, onOpenModal])

    return (
      <div className={cn("max-w-[24rem]", className)} ref={ref}>
        {title && <p className="font-semibold text-sm">{title}</p>}
        <p className="mb-2 text-sm text-atherGray-400">{description}</p>
        <div className={cn("mb-4 space-y-2", listButtonClassName)}>
          <IconButton
            className={cn("text-xs w-full justify-start font-normal", buttonsClassName)}
            colorScheme="secondary"
            onClick={() => {
              setIsOpen("folder")
            }}
          >
            <FolderUploadIcon className="mr-2 text-atherGray-500" />
            From GAIA folder
          </IconButton>
          <IconButton
            colorScheme="secondary"
            className={cn("text-xs w-full justify-start font-normal", buttonsClassName)}
            onClick={() => {
              fileInputRef.current?.click()
            }}
          >
            <ComputerIcon className="mr-2 text-atherGray-500" />
            From your device
          </IconButton>
        </div>
        <ImageUpload
          fileInputRef={fileInputRef}
          upload={handleUpload}
          className={cn({ "border-transparent": value }, uploadImageClassName)}
          isLoading={isUploading}
        >
          {value ? (
            valuePrev ? (
              <div className="flex flex-col items-center justify-center h-full relative p-4">
                {valuePrev}
                <div
                  onClick={e => {
                    e.stopPropagation()
                    onChange("")
                  }}
                  className="absolute top-2 right-2 cursor-pointer"
                >
                  <DeleteIcon className="text-red-500" width={16} height={16} />
                </div>
              </div>
            ) : (
              <ImageViewWithDelete
                className={cn("w-full", uploadImageClassName)}
                url={value}
                alt="Recipe Image"
                onDelete={() => onChange("")}
              />
            )
          ) : (
            <div className="flex flex-col items-center justify-center h-full relative">
              <div className="p-2 mb-2 rounded-full bg-atherPurple-500">
                <UploadIcon />
              </div>
              <p className="text-atherGray-300 text-center text-xs">
                {uploadContent ? (
                  uploadContent
                ) : (
                  <>
                    Upload images or Drag & Drop <br />
                    (up to 2048px and {bytesToSize(INPUT_IMAGE_UPLOAD_SIZE_LIMIT)})
                  </>
                )}
              </p>
            </div>
          )}
        </ImageUpload>
        {error && <p className="text-xs text-red-500 mt-2">{error}</p>}
        <UploadImageFromFolderModal isOpen={isOpen === "folder"} onClose={() => setIsOpen(null)} onChange={onChange} />
      </div>
    )
  },
)

export default UploadRecipeImage
