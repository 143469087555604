import LoadingIcon from "@/components/LoadingIcon"
import Toggle from "@/components/Toggle"
import { cn } from "@/lib/utils"
import classNames from "classnames"
import useCustomRouter from "@/hooks/useCustomRouter"
import { ReactElement, useEffect, useState } from "react"

interface TogglePublicProps {
  className?: string
  isPublic: boolean
  mutateToggle: any
  isLoadingMutateToggle: boolean
  setModalState: (v: boolean) => void
  rightAction?: ReactElement
  boxClassName?: string
  text?: string
}

const TogglePublic = ({
  className,
  isPublic,
  mutateToggle,
  text = "Public Profile",
  isLoadingMutateToggle,
  setModalState,
  rightAction,
  boxClassName,
}: TogglePublicProps) => {
  const router = useCustomRouter()
  const [value, setValue] = useState(false)
  useEffect(() => {
    if (isPublic !== undefined) setValue(isPublic)
  }, [isPublic])

  return (
    <div className={cn("space-x-2 flex", className)}>
      <div
        className={cn(
          "flex items-center min-w-[180px] justify-center space-x-2 rounded-2xl border-[2px] py-[5px] px-3 border-atherGray-850",
          {
            "border-atherPurple-500": value,
          },
          boxClassName,
        )}
      >
        {isLoadingMutateToggle ? (
          <LoadingIcon width={20} height={20} />
        ) : (
          <>
            <Toggle
              sizeToggle="sm"
              checked={value}
              onChange={v => {
                // if (v.target.checked) {
                //   mutateToggle()
                //   setValue(v.target.checked)
                // } else {
                //   setModalState(true)
                // }

                mutateToggle()
              }}
            />
            <p className="text-atherPrimary-100">{text}</p>
          </>
        )}
      </div>
      {rightAction && rightAction}
    </div>
  )
}

export default TogglePublic
