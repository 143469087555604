import { IconProps } from "."

export const ListViewIcon = (props: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none" {...props}>
      <path
        d="M1 10.4C1 9.24173 1.24173 9 2.4 9H19.6C20.7583 9 21 9.24173 21 10.4V11.6C21 12.7583 20.7583 13 19.6 13H2.4C1.24173 13 1 12.7583 1 11.6V10.4Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M1 2.4C1 1.24173 1.24173 1 2.4 1H19.6C20.7583 1 21 1.24173 21 2.4V3.6C21 4.75827 20.7583 5 19.6 5H2.4C1.24173 5 1 4.75827 1 3.6V2.4Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M1 18.4C1 17.2417 1.24173 17 2.4 17H19.6C20.7583 17 21 17.2417 21 18.4V19.6C21 20.7583 20.7583 21 19.6 21H2.4C1.24173 21 1 20.7583 1 19.6V18.4Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  )
}
