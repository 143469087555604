import { IconProps } from "."

export const PaintIcon = (props: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M1.89089 18.8727L1 19L1.12727 18.1091C1.32086 16.754 1.41765 16.0764 1.71832 15.4751C2.01899 14.8738 2.50296 14.3898 3.47091 13.4218L14.9827 1.91009C15.4062 1.48654 15.618 1.27476 15.8464 1.16155C16.2811 0.946151 16.7914 0.946151 17.2261 1.16155C17.4546 1.27476 17.6663 1.48654 18.0899 1.91009C18.5135 2.33365 18.7252 2.54543 18.8385 2.77389C19.0539 3.20856 19.0539 3.71889 18.8385 4.15356C18.7252 4.38201 18.5135 4.59379 18.0899 5.01735L6.57816 16.5291C5.61022 17.497 5.12625 17.981 4.52491 18.2817C3.92357 18.5823 3.246 18.6791 1.89089 18.8727Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 13L7 16M6.5 10.5L9.5 13.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
