import client from "@/api/client"
import { actionMiddleware } from "@/queries/middlewares/actionMiddleware"
import { createMutation } from "react-query-kit"
import { useWorkspaceRoomsInfiniteQuery } from "./useWorkspaceRoomsInfiniteQuery"
import { UpdateRoomMemberDto } from "@/api/sdk"

export const useUpdateMemberRoleMutation = createMutation({
  mutationFn: async ({ roomMemberId, data }: { roomMemberId: string; data: UpdateRoomMemberDto; userUid?: string }) => {
    return await client.api.chatRoomControllerUpdateRoomMember(roomMemberId, data).then(res => res.data)
  },
  use: [
    actionMiddleware({
      onSuccess: ({ queryClient, variables }) => {
        // const workspaceRoomsKey = useWorkspaceRoomsInfiniteQuery.getKey()
        // queryClient.invalidateQueries({ queryKey: workspaceRoomsKey })
      },
    }),
  ],
})
