import client from "@/api/client"
import { ExploreImageSimpleDetail, GetInspirationBoardSimilarImagesResult } from "@/api/sdk"
import { createAuthenticatedInfiniteQuery } from "../createAuthenticatedQuery"
import { enableInfiniteQueryMiddleware } from "../middlewares/enableQueryMiddleware"

export const useExploreImagesSimilarInfiniteQuery = createAuthenticatedInfiniteQuery<
  GetInspirationBoardSimilarImagesResult,
  { id: string; userUid?: string },
  Error,
  ExploreImageSimpleDetail
>({
  auth: false,
  primaryKey: "infinite-explore-similar-images",
  queryFn: ({ queryKey: [_primaryKey, variables], pageParam = 0 }) =>
    client.api
      .imageControllerGetInspirationBoardSimilarImages(variables.id, {
        skip: pageParam * 50,
        take: 50,
      })
      .then(res => res.data),
  getNextPageParam: (lastPage, pages) => (lastPage && lastPage.images.length < 50 ? undefined : pages.length),
  initialPageParam: 0,
  flattenFn: data => data.images,
  use: [enableInfiniteQueryMiddleware(v => !!v.id)],
})
