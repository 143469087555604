import { create } from "zustand"

type UserChanged = {
  userId: string
  oldEmail: string
  newEmail: string
}

interface UserChangedStore {
  userChanged: UserChanged | null
  setUserChanged: (userChanged: UserChanged | null) => void
}

export const useUserChangedStore = create<UserChangedStore>(set => ({
  userChanged: null,
  setUserChanged: userChanged => set({ userChanged }),
}))
