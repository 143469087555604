import Toast from "@/components/Toast"
import { CSSProperties } from "@emotion/serialize"
import { ReactElement, useCallback } from "react"
import { ToastPosition, toast } from "react-toastify"

type ToastOptions = {
  status?: "success" | "error" | "warning"
  title: string
  message?: string[]
  duration?: number
  className?: string
  closeButton?: boolean
  containerStyle?: string
  element?: ReactElement
  style?: CSSProperties
}

export const useToast = (
  {
    defaultDuration,
    position,
  }: {
    defaultDuration?: number
    closeButton?: boolean
    position?: ToastPosition
    style?: CSSProperties
  } = { defaultDuration: 3500, closeButton: true },
) => {
  return useCallback(
    (options: ToastOptions) => {
      const {
        status = "success",
        title,
        message,
        className,
        duration,
        closeButton,
        containerStyle,
        element,
        style,
      } = options
      const id = [title, message].join("|")
      if (!toast.isActive(id)) {
        setTimeout(
          () =>
            toast(
              element ?? <Toast status={status} title={title} message={message} containerStyle={containerStyle} />,
              {
                toastId: id,
                className: className,
                autoClose: duration ?? defaultDuration,
                closeButton: closeButton,
                position: position || "top-center",
                style: (style ?? {}) as any,
              },
            ),
          100,
        )
      }
    },
    [defaultDuration],
  )
}

export default useToast
