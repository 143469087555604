import { SpinnerIcon } from "@/components/shared/icons"
import { cn } from "@/utils/cn"
import { motion } from "framer-motion"
import { ReactNode } from "react"

export interface FolderActionRowProps {
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  className?: string
  children: ReactNode
  isLoading?: boolean
  iconLeft?: ReactNode
  isDisabled?: boolean
}

const FolderActionRow = ({ onClick, children, className, isLoading, iconLeft, isDisabled }: FolderActionRowProps) => {
  return (
    <button
      className={cn(
        "py-2 px-4 relative cursor-pointer rounded-2xl flex items-center w-full text-left font-semibold text-atherGray-300",
        { "hover:bg-atherGray-850 hover:text-atherGray-0 group": !isDisabled },
        { "opacity-50 cursor-not-allowed": isDisabled },
        className,
      )}
      disabled={isDisabled}
      onClick={onClick}
    >
      {iconLeft && (
        <div
          className={cn("relative mr-2 text-atherGray-500", {
            "group-hover:text-atherGray-0": !isDisabled,
          })}
        >
          {isLoading ? (
            <div className={cn("w-full p-0 flex justify-center items-center z-[1] overflow-hidden", className)}>
              <motion.div animate={{ rotate: 360 }} transition={{ duration: 1, repeat: Infinity, ease: "linear" }}>
                <SpinnerIcon width={20} height={20} />
              </motion.div>
            </div>
          ) : (
            iconLeft
          )}
        </div>
      )}
      {children}
    </button>
  )
}

export default FolderActionRow
