import { IconProps } from "."

export const BackLogIcon = (props: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path d="M9 3.5L19 3.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
      <path
        d="M3.4 14.8926C4.46667 15.607 5 15.9642 5 16.5C5 17.0358 4.46667 17.393 3.4 18.1074C2.33333 18.8218 1.8 19.1789 1.4 18.9111C1 18.6432 1 17.9288 1 16.5C1 15.0712 1 14.3568 1.4 14.0889C1.8 13.8211 2.33333 14.1782 3.4 14.8926Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M3.4 1.89263C4.46667 2.60702 5 2.96421 5 3.5C5 4.03579 4.46667 4.39298 3.4 5.10737C2.33333 5.82176 1.8 6.17895 1.4 5.91105C1 5.64316 1 4.92877 1 3.5C1 2.07123 1 1.35684 1.4 1.08895C1.8 0.82105 2.33333 1.17824 3.4 1.89263Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path d="M9 10L19 10" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M9 16.5L19 16.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  )
}
