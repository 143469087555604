import { IconProps } from "."

export const DashboardSquare = (props: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none" {...props}>
      <path
        d="M15 4C15 3.06812 15 2.60218 15.1522 2.23463C15.3552 1.74458 15.7446 1.35523 16.2346 1.15224C16.6022 1 17.0681 1 18 1C18.9319 1 19.3978 1 19.7654 1.15224C20.2554 1.35523 20.6448 1.74458 20.8478 2.23463C21 2.60218 21 3.06812 21 4V8C21 8.93188 21 9.39782 20.8478 9.76537C20.6448 10.2554 20.2554 10.6448 19.7654 10.8478C19.3978 11 18.9319 11 18 11C17.0681 11 16.6022 11 16.2346 10.8478C15.7446 10.6448 15.3552 10.2554 15.1522 9.76537C15 9.39782 15 8.93188 15 8V4Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M15 18C15 17.0681 15 16.6022 15.1522 16.2346C15.3552 15.7446 15.7446 15.3552 16.2346 15.1522C16.6022 15 17.0681 15 18 15C18.9319 15 19.3978 15 19.7654 15.1522C20.2554 15.3552 20.6448 15.7446 20.8478 16.2346C21 16.6022 21 17.0681 21 18C21 18.9319 21 19.3978 20.8478 19.7654C20.6448 20.2554 20.2554 20.6448 19.7654 20.8478C19.3978 21 18.9319 21 18 21C17.0681 21 16.6022 21 16.2346 20.8478C15.7446 20.6448 15.3552 20.2554 15.1522 19.7654C15 19.3978 15 18.9319 15 18Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M1 15C1 13.1144 1 12.1716 1.58579 11.5858C2.17157 11 3.11438 11 5 11H7C8.88562 11 9.82843 11 10.4142 11.5858C11 12.1716 11 13.1144 11 15V17C11 18.8856 11 19.8284 10.4142 20.4142C9.82843 21 8.88562 21 7 21H5C3.11438 21 2.17157 21 1.58579 20.4142C1 19.8284 1 18.8856 1 17V15Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M1 4C1 3.06812 1 2.60218 1.15224 2.23463C1.35523 1.74458 1.74458 1.35523 2.23463 1.15224C2.60218 1 3.06812 1 4 1H8C8.93188 1 9.39782 1 9.76537 1.15224C10.2554 1.35523 10.6448 1.74458 10.8478 2.23463C11 2.60218 11 3.06812 11 4C11 4.93188 11 5.39782 10.8478 5.76537C10.6448 6.25542 10.2554 6.64477 9.76537 6.84776C9.39782 7 8.93188 7 8 7H4C3.06812 7 2.60218 7 2.23463 6.84776C1.74458 6.64477 1.35523 6.25542 1.15224 5.76537C1 5.39782 1 4.93188 1 4Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </svg>
  )
}
