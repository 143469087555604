import { IconProps } from "."

export const PinCodeIcon = (props: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="18" viewBox="0 0 22 18" fill="none" {...props}>
      <path
        d="M3.07407 4.92857C4.23724 3.24613 4.81883 2.40491 5.65298 1.95245C6.48714 1.5 7.45645 1.5 9.39506 1.5H14.0864C16.8607 1.5 18.2478 1.5 19.2442 2.22227C19.6756 2.53495 20.046 2.93674 20.3342 3.4047C21 4.48566 21 5.99044 21 9C21 12.0096 21 13.5143 20.3342 14.5953C20.046 15.0633 19.6756 15.465 19.2442 15.7777C18.2478 16.5 16.8607 16.5 14.0864 16.5H9.39506C7.45645 16.5 6.48714 16.5 5.65298 16.0475C4.81883 15.5951 4.23724 14.7539 3.07407 13.0714L2.92593 12.8571C1.64198 11 1 10.0714 1 9C1 7.92857 1.64198 7 2.92593 5.14286L3.07407 4.92857Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path d="M7.99981 9H8.00879" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11.9998 9H12.0088" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M15.9998 9H16.0088" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
