import client from "@/api/client"
import { GetUserQuestsResult, QuestFrequency, QuestItem, QuestStatus } from "@/api/sdk"
import { createAuthenticatedInfiniteQuery } from "@/queries/createAuthenticatedQuery"

export const useQuestInfiniteQuery = createAuthenticatedInfiniteQuery<
  GetUserQuestsResult,
  {
    status?: QuestStatus
    frequency?: QuestFrequency
    userUid?: string
  },
  Error,
  QuestItem
>({
  auth: false,
  primaryKey: "infinite-quests",
  queryFn: ({ queryKey: [_primaryKey, variables], pageParam = 0 }) =>
    client.api
      .questControllerGetQuestByUser({
        skip: pageParam * 40,
        take: 40,
        frequency: variables?.frequency,
        status: variables?.status,
      })
      .then(res => res.data),
  getNextPageParam: (lastPage, pages) => (lastPage && lastPage.quests.length < 40 ? undefined : pages.length),
  initialPageParam: 0,
  flattenFn: data => data.quests,
})
