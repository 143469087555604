import client from "@/api/client"
import { actionMiddleware } from "@/queries/middlewares/actionMiddleware"
import { createMutation } from "react-query-kit"

export const useJoinRoomMutation = createMutation({
  mutationFn: async (roomId: string) => {
    return await client.api
      .chatRoomControllerJoinChatRoom(roomId, {
        roomId,
      })
      .then(res => res.data)
  },
  use: [
    actionMiddleware({
      // onSuccess: ({ queryClient, variables }) => {
      //   const workspaceRoomsKey = useWorkspaceRoomsInfiniteQuery.getKey()
      //   queryClient.invalidateQueries({ queryKey: workspaceRoomsKey })
      // },
    }),
  ],
})

export const useJoinCodeRoomMutation = createMutation({
  mutationFn: async (joinCode: string) => {
    return await client.api.chatRoomControllerJoinChatRoomByJoinCode(joinCode).then(res => res.data)
  },
  use: [
    actionMiddleware({
      // onSuccess: ({ queryClient, variables }) => {
      //   const workspaceRoomsKey = useWorkspaceRoomsInfiniteQuery.getKey()
      //   queryClient.invalidateQueries({ queryKey: workspaceRoomsKey })
      // },
    }),
  ],
})
