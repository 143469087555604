import { useCallback, useState } from "react"

export const useImageDrop = <T extends HTMLElement>(
  onDrop: (files: File[]) => void,
  isImageSource?: React.MutableRefObject<boolean>,
  { enabled = true }: { enabled?: boolean } = {},
) => {
  const [isOver, setIsOver] = useState(false)

  const handleDragOver = useCallback(
    (e: React.DragEvent<T>) => {
      e.preventDefault()
      if (isImageSource?.current) return

      const { types } = e.dataTransfer
      if ((types.includes("Files") || types.includes("text/html")) && enabled) {
        setIsOver(true)
      }
    },
    [enabled, isImageSource],
  )

  const handleDragLeave = useCallback(() => {
    setIsOver(false)
  }, [])

  const handleDrop = useCallback(
    async (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault()
      setIsOver(false)

      const { types, files } = e.dataTransfer

      if (types.includes("Files")) {
        if (isImageSource?.current) {
          isImageSource.current = false
          return
        }

        const imageFiles = Array.from(files).filter(file => file.type.startsWith("image/"))
        if (imageFiles.length > 0 && enabled) {
          onDrop(imageFiles)
        }
      } else if (types.includes("text/html")) {
        const html = e.dataTransfer.getData("text/html")
        const img = new DOMParser().parseFromString(html, "text/html").querySelector("img")
        let src = img?.dataset.originalSrc ?? img?.src

        if (src) {
          try {
            const response = await fetch(src)
            const blob = await response.blob()
            const file = new File([blob], "image.jpg", { type: "image/jpeg" })
            onDrop([file])
          } catch (error) {
            console.error(error)
          }
        }
      }
    },
    [onDrop, enabled, isImageSource],
  )

  return {
    isOver,
    bind: {
      onDragOver: handleDragOver,
      onDragLeave: handleDragLeave,
      onDrop: handleDrop,
    },
  }
}
