import client from "@/api/client"
import { InfiniteData, useQueryClient } from "@tanstack/react-query"
import { createMutation } from "react-query-kit"
import { actionMiddleware } from "../middlewares/actionMiddleware"
import { useWorkspaceWorkflowInfiniteQuery } from "../workspace"
import { GetWorkflowsResult, RecentWorkflowItem } from "@/api/sdk"
import { useRecentWorkflowInfiniteQuery } from "@/components/Workspace/RecentV2/RecentWorkflow"
import { useWorkspaceWorkflowDetailQuery } from "../workspace/workflow"

export const useRecoverTaskMutation = createMutation({
  mutationFn: (taskIds: string[]) =>
    client.api.recipeTaskControllerRecoverTasks({
      recipeTaskIds: taskIds,
    }),
  use: [
    actionMiddleware({
      onSuccess: ({ queryClient, variables: taskId }) => {
        // Update workflow list
        const workflowListKey = useWorkspaceWorkflowInfiniteQuery.getKey()

        const workflowListQueriesDataEntries = queryClient.getQueriesData<InfiniteData<GetWorkflowsResult, number>>({
          queryKey: workflowListKey,
        })

        if (workflowListQueriesDataEntries) {
          workflowListQueriesDataEntries.forEach(([key, workflowListData]) => {
            if (workflowListData) {
              const updatedData = {
                ...workflowListData,
                pages: workflowListData.pages.map(page => {
                  return {
                    ...page,
                    total: page.total - taskId.length,
                    workflows: page.workflows.filter(workflow => !taskId.includes(workflow.id)),
                  }
                }),
              }

              queryClient.setQueryData(key, updatedData)
            }
          })
        }

        // Update workflow recent list

        const workflowRecentListKey = useRecentWorkflowInfiniteQuery.getKey()

        const workflowRecentListQueriesDataEntries = queryClient.getQueriesData<
          InfiniteData<RecentWorkflowItem[], number>
        >({
          queryKey: workflowRecentListKey,
        })

        if (workflowRecentListQueriesDataEntries) {
          workflowRecentListQueriesDataEntries.forEach(([key, workflowRecentListData]) => {
            if (workflowRecentListData) {
              const updatedData = {
                ...workflowRecentListData,
                pages: workflowRecentListData.pages.map(page => {
                  return [...page.filter(workflow => !taskId.includes(workflow.workflow.id))]
                }),
              }

              queryClient.setQueryData(key, updatedData)
            }
          })
        }
      },
    }),
    useMutationNext => {
      return (options, queryClient) => {
        const qc = useQueryClient(queryClient)

        return useMutationNext({
          ...options,
          onSettled: (_, __, taskIds) => {
            qc.invalidateQueries({
              queryKey: useWorkspaceWorkflowDetailQuery.getKey({
                workflowId: taskIds[0] ?? "",
              }),
            })
          },
        })
      }
    },
  ],
})
