import { create } from "zustand"
import { devtools, persist } from "zustand/middleware"

interface TempUploadStore {
  tempUploadImages:
    | {
        recipeId: string
        url: string
      }[]
    | null
  setTempUploadImages: (tempUploadImages: { recipeId: string; url: string }[] | null) => void
}

export const useTempUploadImagesStore = create<TempUploadStore>()(
  devtools(
    persist(
      set => ({
        tempUploadImages: null,
        setTempUploadImages: tempUploadImages =>
          set(() => {
            return { tempUploadImages }
          }),
      }),
      {
        name: "temp-upload-images-storage",
      }
    )
  )
)
