import { useTempState } from "@/hooks"
import { googleAnalytics } from "@/lib/gtag"
import { AnimatePresence, motion } from "framer-motion"
import React from "react"
import { twMerge } from "tailwind-merge"
import { CopyIcon, TickIcon } from "./shared/icons"
import classNames from "classnames"

interface TextCopyProps {
  value: string
  className?: string
  onClick?: () => void
  disabled?: boolean
  onChange?: (value: string) => void
  icon?: React.ReactNode
  width?: number
  height?: number
  children?: React.ReactNode
}

const TextCopy = ({ value, className, onClick, icon, width = 14, height = 14, disabled, children }: TextCopyProps) => {
  const [isCopying, setIsCopying] = useTempState(false, 500)
  const handleCopy = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()
    e.preventDefault()
    setIsCopying(true)

    googleAnalytics.event({
      action: "click",
      category: "copy",
      label: "copy_text",
      params: {
        copy_value: value,
        pathname: window.location.pathname,
      },
    })

    if (onClick) {
      onClick()
      return
    }
    navigator.clipboard.writeText(value)
  }

  return (
    <button
      type="button"
      disabled={disabled}
      onClick={e => handleCopy(e)}
      className={twMerge("flex items-center", className)}
    >
      <AnimatePresence initial={false} mode="popLayout">
        {!isCopying ? (
          <motion.span
            className={classNames({
              "!opacity-50 cursor-not-allowed": disabled,
            })}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            key="btn-copy"
          >
            {icon ?? <CopyIcon width={width} height={height} />}
          </motion.span>
        ) : (
          <motion.span initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <TickIcon key="btn-check" className="text-green-500" width={width} height={height} />
          </motion.span>
        )}
      </AnimatePresence>
      {children}
    </button>
  )
}

export default TextCopy
