import { IconProps } from "."

export const PaintBoardIcon = (props: IconProps) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M29.3307 16.0013C29.3307 8.63751 23.3612 2.66797 15.9974 2.66797C8.6336 2.66797 2.66406 8.63751 2.66406 16.0013C2.66406 23.3651 8.6336 29.3346 15.9974 29.3346C17.1197 29.3346 18.664 29.4897 18.6641 28.0013C18.6641 27.1893 18.2417 26.5628 17.8222 25.9405C17.2083 25.03 16.6005 24.1284 17.3307 22.668C18.2196 20.8902 19.7011 20.8902 21.9727 20.8902C23.1085 20.8902 24.4419 20.8902 25.9974 20.668C28.7987 20.2678 29.3307 18.5458 29.3307 16.0013Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M9.32812 20.0039L9.33969 20.0008"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="12.6641" cy="11.3359" r="2" stroke="currentColor" strokeWidth="1.5" />
      <circle cx="22" cy="12.668" r="2" stroke="currentColor" strokeWidth="1.5" />
    </svg>
  )
}
