import React, { Fragment } from "react"
import IconButton from "@/components/IconButton"
import Popover from "@/components/Popover"
import Tooltip from "@/components/Tooltip"
import { ThreeDotHorizontalIcon, ArrowDownIcon } from "@/components/shared/icons"
import classNames from "classnames"
import FolderActionRow from "../../../FolderActions/FolderActionRow"
import { useScreen } from "@/hooks"

interface ActionItem {
  title: string
  icon: React.ReactNode
  onClick: () => void
  visible: boolean | null
  disabled?: boolean
  isLoading?: boolean
  isDropdown?: boolean
}

interface AccordionActionsProps {
  actions: ActionItem[]
  isActive: boolean
  setIsActive: React.Dispatch<React.SetStateAction<boolean>>
  isActive1: boolean
  setIsActive1: React.Dispatch<React.SetStateAction<boolean>>
  onSelectedStep: () => void
}

const ActionPopover: React.FC<{
  actions: ActionItem[]
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  isMobile?: boolean
}> = ({ actions, isOpen, setIsOpen, isMobile }) => (
  <Popover
    isOpen={isOpen}
    setIsOpen={setIsOpen}
    className="p-0 bg-transparent"
    trigger={
      <div
        className={classNames("p-1.5 rounded-md min-h-0", {
          "bg-atherGray-850": isMobile,
          "border border-atherGray-800": !isMobile,
          "step-other-actions": isMobile,
        })}
      >
        <ThreeDotHorizontalIcon width={isMobile ? 12 : 16} height={isMobile ? 12 : 16} />
      </div>
    }
  >
    <div className="text-sm w-[15rem] text-atherGray-300 bg-atherGray-900 border border-atherGray-850 p-2 rounded-2xl space-y-1">
      {actions.map(action => (
        <FolderActionRow
          key={action.title}
          isLoading={action.isLoading}
          onClick={e => {
            e.stopPropagation()
            action.onClick()
            setIsOpen(false)
          }}
          iconLeft={action.icon}
        >
          {action.title}
        </FolderActionRow>
      ))}
    </div>
  </Popover>
)

export const AccordionActions: React.FC<AccordionActionsProps> = ({
  actions,
  isActive,
  setIsActive,
  isActive1,
  setIsActive1,
  onSelectedStep,
}) => {
  const { width } = useScreen()
  const isMobile = width < 768

  const visibleActions = actions.filter(a => a.visible)
  const dropdownActions = visibleActions.filter(a => a.isDropdown)
  const regularActions = visibleActions.filter(a => !a.isDropdown)

  return (
    <Fragment>
      <div className={"items-center justify-end flex-none hidden mt-2 md:flex lg:mt-0 lg:ml-2"}>
        <div className={classNames("flex items-center space-x-2 step-other-actions")}>
          {regularActions.map((action, index) => (
            <Tooltip
              key={index}
              trigger={
                <IconButton
                  isLoading={action.isLoading}
                  onClick={e => {
                    e.stopPropagation()
                    if (action.disabled) return
                    action.onClick()
                  }}
                  colorScheme="transparent"
                  key={index}
                  className={classNames("min-h-0 p-2 border border-atherGray-800", {
                    "!cursor-default": action.disabled,
                  })}
                >
                  {action.icon}
                </IconButton>
              }
            >
              {action.title}
            </Tooltip>
          ))}
          {dropdownActions.length > 0 && (
            <ActionPopover actions={dropdownActions} isOpen={isActive1} setIsOpen={setIsActive1} />
          )}
        </div>
      </div>
      <div className="flex items-center justify-end flex-none mt-2 space-x-1 md:hidden lg:mt-0 lg:ml-2">
        <ActionPopover actions={visibleActions} isOpen={isActive} setIsOpen={setIsActive} isMobile />
        <IconButton
          onClick={onSelectedStep}
          className={classNames("p-1.5 rounded-md min-h-0 bg-atherGray-850 flex md:hidden", {
            "step-generated-settings": isMobile,
          })}
          colorScheme="secondary"
        >
          <ArrowDownIcon width={12} height={12} className="-rotate-90" />
        </IconButton>
      </div>
    </Fragment>
  )
}
