import { IconProps } from "."

export const ImageSparkIcon = (props: IconProps) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.66667 2C4.68111 2 3.18833 2 2.26083 2.9275C1.33334 3.85499 1.33334 5.34777 1.33334 8.33333C1.33334 11.3189 1.33334 12.8117 2.26083 13.7392C3.18833 14.6667 4.68111 14.6667 7.66667 14.6667C10.6522 14.6667 12.145 14.6667 13.0725 13.7392C14 12.8117 14 11.3189 14 8.33333V8"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M1.33334 9.42367C1.74601 9.36373 2.16323 9.33411 2.58115 9.33516C4.34911 9.2978 6.07376 9.84867 7.44739 10.8895C8.72134 11.8548 9.61649 13.1833 10 14.6667"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M14 11.2641C13.2164 10.8672 12.4058 10.6658 11.5908 10.6667C10.3563 10.6618 9.13435 11.1155 8 12"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M11.3333 1.33337L11.5899 2.47222C11.8075 3.43821 12.5618 4.19257 13.5278 4.41017L14.6667 4.66671L13.5278 4.92325C12.5618 5.14085 11.8075 5.89521 11.5899 6.86119L11.3333 8.00004L11.0768 6.86119C10.8592 5.89521 10.1048 5.14085 9.13885 4.92325L8 4.66671L9.13885 4.41017C10.1048 4.19257 10.8592 3.43821 11.0768 2.47222L11.3333 1.33337Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  )
}
