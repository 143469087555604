import client from "@/api/client"
import { CopyImagesDto, MoveImagesToFolderDto, SearchImagesResponse } from "@/api/sdk"
import { actionMiddleware } from "@/queries/middlewares/actionMiddleware"
import { InfiniteData } from "@tanstack/react-query"
import { createMutation } from "react-query-kit"
import { useWorkspaceImagesInfiniteQuery } from "../useWorkspaceImagesInfiniteQuery"

export interface UseCopyImageToFolderMutationVaribles extends CopyImagesDto {
  oldParentId: string
}

export const useCopyImageToFolderMutation = createMutation({
  mutationFn: (input: UseCopyImageToFolderMutationVaribles) => client.api.imageControllerCopyImages(input),
  use: [
    actionMiddleware({
      onSuccess({ queryClient, variables: { imageIds, oldParentId, toFolderId } }) {
        // const imageQueryKey = useWorkspaceImagesInfiniteQuery.getKey({ folderId: oldParentId })
        // const imageQueryDataEntries = queryClient.getQueriesData<InfiniteData<SearchImagesResponse, number>>({
        //   queryKey: imageQueryKey,
        // })

        // // loop through each image entry and remove the moved images from the list
        // imageQueryDataEntries.forEach(imageQueryDataEntry => {
        //   const key = imageQueryDataEntry[0]
        //   const data = imageQueryDataEntry[1]

        //   if (data) {
        //     const newData = data.pages.map(page => ({
        //       images: page.images.filter(image => !imageIds.includes(image.id)),
        //       total: page.total - imageIds.length,
        //     }))

        //     queryClient.setQueryData<InfiniteData<SearchImagesResponse, number>>(key, {
        //       pages: newData,
        //       pageParams: data.pageParams,
        //     })
        //   }
        // })

        if (oldParentId === toFolderId) {
          queryClient.invalidateQueries({
            queryKey: useWorkspaceImagesInfiniteQuery.getKey({ folderId: oldParentId }),
          })
        }
      },
      onError({ queryClient, variables: { oldParentId } }) {
        queryClient.invalidateQueries({
          queryKey: useWorkspaceImagesInfiniteQuery.getKey({ folderId: oldParentId }),
        })
      },
    }),
  ],
})
