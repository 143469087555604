import { IconProps } from "."

export const DressIcon = (props: IconProps) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M20.0026 5.33594L16.0026 8.0026L12.0026 5.33594C11.2216 6.01515 9.43021 7.06052 9.34058 8.1968C9.30172 8.68944 9.50771 8.95881 9.9197 9.49757C10.8181 10.6725 12.0026 11.3627 12.0026 13.3359L20.0026 13.3359C20.0026 11.3627 21.1871 10.6725 22.0855 9.49757C22.4975 8.95881 22.7035 8.68944 22.6646 8.1968C22.575 7.06052 20.7836 6.01515 20.0026 5.33594Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 5.33464V2.66797"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 5.33464V2.66797"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.6641 13.3359L19.3307 13.3359"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24 25.3359C26.6667 25.3359 28 22.4389 28 22.4389C24.0016 19.8391 21.7302 16.758 20.5693 14.5487C20.2454 13.9323 20.0835 13.6241 19.845 13.48C19.6065 13.3359 19.2905 13.3359 18.6586 13.3359L13.3414 13.3359C12.7095 13.3359 12.3935 13.3359 12.155 13.48C11.9165 13.6241 11.7546 13.9323 11.4307 14.5487C10.2698 16.758 7.99841 19.8391 4 22.4389C4 22.4389 5.33333 25.3359 8 25.3359"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.274 18.668C18.7268 19.7299 20.6941 22.2296 22.8549 24.4158C23.651 25.2212 24.049 25.6239 23.9952 26.3075C23.9414 26.991 23.6084 27.2187 22.9423 27.6741C21.4274 28.7099 19.1133 29.3346 16 29.3346C12.8867 29.3346 10.5726 28.7099 9.05771 27.6741C8.39164 27.2187 8.0586 26.991 8.00481 26.3075C7.95103 25.6239 8.34905 25.2212 9.14509 24.4158C11.3059 22.2296 13.2732 19.7299 13.726 18.668"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
