import client from "@/api/client"
import { CreateRecipeTaskDto, FolderDetail, RecipeTaskStatus } from "@/api/sdk"
import IconButton from "@/components/IconButton"
import ReactPortal from "@/components/ReactPortal"
import { defaultPrompt, defaultWorkflow } from "@/components/Tools/ComfyUIRecipe/default-workflow"
import FormDetailNumberComponent from "@/components/Workspace/Recipes/RecipeDetail/FormDetail/FormDetailNumber"
import FormDetailRatioComponent from "@/components/Workspace/Recipes/RecipeDetail/FormDetail/FormDetailRatio"
import FormDetailSelectComponent from "@/components/Workspace/Recipes/RecipeDetail/FormDetail/FormDetailSelect"
import { initWorkflowChain } from "@/components/Workspace/Workflows/WorkflowsChaining"
import DrawerSelectFolder from "@/components/Workspace/Workflows/WorkflowsChaining/DrawerSelectFolder"
import {
  ArrowDownIcon,
  ArrowSquareLeftIcon,
  BookMarkIcon2,
  SendIcon,
  SettingIcon5,
  XIcon,
} from "@/components/shared/icons"
import { useCallbackOnHotKeys, useToast } from "@/hooks"
import useAutoSizeTextArea from "@/hooks/useAutoSizeTextArea"
import { googleAnalytics } from "@/lib/gtag"
import { cn } from "@/lib/utils"
import { useBrowserRouter } from "@/providers/BrowserRouterProvider"
import { useAuth } from "@/providers/authContext"
import { useCreateNewStyleMutation, useCreateTaskNotAuthMutation } from "@/queries"
import {
  useManagementErrorsStore,
  useImageParamStore,
  usePromptInputStore,
  useSignInStore,
  useTempTaskStoreV2,
} from "@/stores"
import generateCaptchaToken from "@/utils/generateCaptchaToken"
import { chunkString } from "@/utils/parser"
import { css } from "@emotion/css"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import classNames from "classnames"
import { AnimatePresence, motion } from "framer-motion"
import useCustomRouter from "@/hooks/useCustomRouter"
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { isMobileOnly } from "react-device-detect"
import { FormProvider, useForm, useWatch } from "react-hook-form"
import { createMutation } from "react-query-kit"
import { ExploreGalleryTabType } from "../GalleryInspiration"
import { exploreTabs } from "./GalleryTab"
import ShortcutSuggest from "./ShortcutSuggest"
import { useGetOngoingTaskQuery } from "@/queries/task/useGetTaskInfiniteQuery"

export const recipeOneClickStepInputs = [
  {
    key: "prompt",
    name: "Prompt",
    type: "text",
    value: "evening sunset scenery blue sky nature, glass bottle with a galaxy in it",
    options: [],
    tooltip: "",
    comfyKey: "2.inputs.text_positive",
    examples: [
      "masterpiece, high quality, details",
      "Full length oil painting of gorgeous woman as a translucent arctic fox spirit| detailed face| wearing white dress| straight white hair| mythical| elegant| hyperrealism| highly detailed| intricate detailed| volumetric lighting| Ukiyo-E| by Anne Stokes and Noriyoshi Ohrai",
      "style of Gabriel Isak, portrait of 1girl, best quality, contemporary, melancholy, surreal, Swedish",
    ],
    description: "Describe what you want to see",
    allowWildcard: true,
  },
  {
    key: "promptStyle",
    type: "select",
    value: "base",
    options: [
      {
        value: "base",
        display: "Base",
      },
      {
        value: "enhance",
        display: "Enhance",
      },
      {
        value: "anime",
        display: "Anime",
      },
      {
        value: "photographic",
        display: "Photographic",
      },
      {
        value: "cinematic",
        display: "Cinematic",
      },
      {
        value: "analog film",
        display: "Analog Film",
      },
      {
        value: "digital art",
        display: "Digital Art",
      },
      {
        value: "fantasy art",
        display: "Fantasy Art",
      },
      {
        value: "line art",
        display: "Line Art",
      },
      {
        value: "pixel art",
        display: "Pixel Art",
      },
      {
        value: "artstyle-watercolor",
        display: "Watercolor",
      },
      {
        value: "comic book",
        display: "Comic Book",
      },
      {
        value: "neonpunk",
        display: "Neonpunk",
      },
      {
        value: "3d-model",
        display: "3D Model",
      },
      {
        value: "misc-fairy tale",
        display: "Fairy Tale",
      },
      {
        value: "misc-gothic",
        display: "Gothic",
      },
      {
        value: "photo-long exposure",
        display: "Long Exposure",
      },
      {
        value: "photo-tilt-shift",
        display: "Tilt-Shift",
      },
      {
        value: "lowpoly",
        display: "Lowpoly",
      },
      {
        value: "origami",
        display: "Origami",
      },
      {
        value: "craft clay",
        display: "Craft Clay",
      },
      {
        value: "game-minecraft",
        display: "Minecraft",
      },
    ],
    comfyKey: "2.inputs.style",
  },
  {
    key: "aspectRatio",
    type: "radio",
    value: "1:1",
    options: [
      {
        value: "1:1",
        display: "1:1",
      },
      {
        value: "3:2",
        display: "3:2",
      },
      {
        value: "2:3",
        display: "2:3",
      },
      {
        value: "16:9",
        display: "16:9",
      },
      {
        value: "9:16",
        display: "9:16",
      },
    ],
    tooltip: "Different aspect ratios may affect the content of the generated images!",
    comfyKey: "3.inputs.aspect_ratio",
    description: "",
  },
  {
    key: "numberOfImages",
    type: "radio",
    value: 1,
    min: 1,
    max: 4,
    options: [
      {
        value: 1,
        display: "1",
      },
      {
        value: 2,
        display: "2",
      },
      {
        value: 4,
        display: "4",
      },
    ],
    comfyKey: "1.inputs.batch_size",
  },
]

type GeneratedImageType = {
  prompt: string
  promptStyle: string
  aspectRatio: string
  numberOfImages: number
  folderId?: string
}

export const SIMPLE_GENERATE_RECIPE_ID = "image-generator-simple"

export const useAskAgentMutation = createMutation({
  mutationFn: (data: CreateRecipeTaskDto) => {
    const prompt = data.params.prompt

    if (!prompt?.toLowerCase()?.includes("@gaia")) {
      return client.api.recipeTaskControllerAddTask(data).then(res => res.data)
    }

    return client.api
      .agentControllerCommand({
        prompt,
        data,
      })
      .then(res => res.data[0])
  },
})

const GalleryGeneratedImage = ({
  onFocus,
  className,
  tab,
  onHidden,
  isMobile,
  isExplore = true,
}: {
  onFocus?: (focus: boolean) => void
  className?: string
  tab?: ExploreGalleryTabType
  isMobile?: boolean
  isExplore?: boolean
  onHidden?: () => void
}) => {
  const [isOpenSetting, setIsOpenSetting] = useState(false)
  const setSignInModal = useSignInStore(state => state.setSignInModal)
  const { tempTask, setTempTask } = useTempTaskStoreV2()
  const { user, handleSignIn } = useAuth()
  const [folder, setFolder] = useState<FolderDetail | undefined>()
  const router = useCustomRouter()
  const [isFocus, setIsFocus] = useState(false)
  const setErrorState = useManagementErrorsStore(state => state.setErrorState)
  const { params, setParams } = useImageParamStore()
  const setPromptInput = usePromptInputStore(state => state.setPromptInput)

  const form = useForm<GeneratedImageType>({
    defaultValues: {
      prompt: "",
      promptStyle: "base",
      aspectRatio: "1:1",
      numberOfImages: 4,
    },
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = form
  const toast = useToast()
  const qc = useQueryClient()
  const {
    prompt: promptValue,
    aspectRatio,
    numberOfImages,
    promptStyle,
  } = useWatch({
    control: form.control,
  })

  const refetchOnGoing = useCallback(async () => {
    const onGoingKey = useGetOngoingTaskQuery.getKey()

    await qc.cancelQueries({ queryKey: onGoingKey })

    qc.invalidateQueries({ queryKey: onGoingKey })
  }, [])

  const { mutate: mutateCreateTask, isPending: isLoadingCreateAuth } = useAskAgentMutation({
    onSuccess: data => {
      refetchOnGoing()

      if (data.recipeId === "recipe-to-recipe") {
        setTimeout(() => router.push(`/workspace/macros/${data.id}`), 1)
        return
      }

      const { prompt, ...params } = data.params as Record<string, any>
      const splitPrompt = chunkString(prompt ?? "", 100)
      const promptParams = Object.fromEntries((splitPrompt ?? []).map((item, index) => [`prompt_${index + 1}`, item]))

      googleAnalytics.handleCategoryEvent({
        action: "click",
        params: {
          action: "Submit Task Completed",
          tab_name: router.pathname.split("/")[2],
          task_id: data.id,
          recipe_id: data.recipeId,
          ...params,
          ...promptParams,
        },
      })

      form.setValue("prompt", "")

      toast({
        status: "success",
        title: "Submitted! Your task is running",
        duration: 5000,
      })
    },
    onError: (err: any) => {
      if (
        err?.message.startsWith("Guest user does not have permission") ||
        err?.message.startsWith("Your subscription has ended")
      ) {
        setErrorState({
          isOpen: true,
          message: err.message,
        })
        return
      }

      if (err?.error === "Bad Request") {
        toast({ title: "Cannot Complete Request", message: [err.message], status: "error" })
      } else {
        toast({ title: "Error", message: [err.message], status: "error" })
      }
    },
  })

  const { mutate: mutateCreateTaskNotAuth, isPending: isLoadingCreateNotAuth } = useCreateTaskNotAuthMutation({
    onSuccess: data => {
      refetchOnGoing()
      if (data.recipeId === "recipe-to-recipe") {
        if (data) {
          setTempTask([...(tempTask || []), { id: data.id, type: "macro" }])
        }

        setTimeout(() => router.push(`/workspace/macros/${data.id}`), 1)

        return
      }

      googleAnalytics.handleCategoryEvent({
        action: "click",
        params: {
          action: "Submit Task Completed",
          tab_name: router.pathname.split("/")[2],
          task_id: data.id,
          recipe_id: data.recipeId,
        },
      })
      form.setValue("prompt", "")

      if (data) {
        setTempTask([...(tempTask || []), { id: data.id, type: "recipe" }])
      }

      toast({
        status: "success",
        title: "Submitted! Your task is running",
        duration: 5000,
      })
    },
    onError: (err: any) => {
      if (err?.error === "Bad Request") {
        toast({ title: "Cannot Complete Request", message: [err.message], status: "error" })
      } else {
        toast({ title: "Error", message: [err.message], status: "error" })
      }
    },
  })

  const { mutate: createStyle, isPending: isCreatingStyle } = useCreateNewStyleMutation({
    onSuccess(created) {
      router.push(`/workspace/tools/styles/${created.id}`)
    },
    onError: (err: any) => {
      if (
        err?.message.startsWith("Guest user does not have permission") ||
        err?.message.startsWith("Your subscription has ended")
      ) {
        setErrorState({
          isOpen: true,
          message: err.message,
        })
        return
      }

      toast({ title: "Error", message: [err.message], status: "error" })
    },
  })

  const { mutate: createWorkflow, isPending: isCreatingComfyUI } = useMutation({
    mutationFn: async () => {
      return client.api
        .sdWorkflowControllerCreateWorkflow({
          name: "My Recipe",
          workflow: defaultWorkflow,
          prompt: defaultPrompt,
          thumbnailFileId: null,
        })
        .then(res => res.data)
    },
    onSuccess(created) {
      router.push(`/workspace/tools/comfyui/${created.id}`)
    },
  })

  const onSubmit = async (data: GeneratedImageType) => {
    const { prompt: promptData, folderId, ...params } = data

    if (!promptData) {
      toast({
        status: "error",
        title: "Prompt is required",
        message: ["Please enter a prompt to generate images"],
      })
      return
    }

    const splitPrompt = chunkString(promptData, 100)
    const promptParams = Object.fromEntries((splitPrompt ?? []).map((item, index) => [`prompt_${index + 1}`, item]))

    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        action: "Submit Task",
        tab_name: router.pathname.split("/")[2],
        ...params,
        ...promptParams,
      },
    })

    setIsOpenSetting(false)

    if (!user) {
      if (tempTask && tempTask?.length >= 5) {
        toast({
          status: "error",
          title: "Limit reached",
          message: ["Please sign in to generate more"],
        })

        setSignInModal({
          signIn: true,
        })

        return
      }

      const captchaToken = await generateCaptchaToken()

      mutateCreateTaskNotAuth({
        recipeId: SIMPLE_GENERATE_RECIPE_ID,
        params: {
          ...params,
          prompt: promptData,
        },
        captchaToken,
      })
      return
    }

    mutateCreateTask({
      recipeId: SIMPLE_GENERATE_RECIPE_ID,
      params: data,
    })
  }

  const textareaRef = useRef<HTMLTextAreaElement | null>(null)
  const { asPath } = useBrowserRouter()

  useEffect(() => {
    if (!params) return

    const handlePastePrompt = async () => {
      await form.setValue("prompt", params.prompt)
      await form.setValue("promptStyle", params.promptStyle ?? "base")

      if (!isFocus && !asPath.includes("/images/")) {
        textareaRef.current?.focus()
      }

      setTimeout(() => {
        if (asPath.includes("/images/") && isFocus) {
          textareaRef.current?.setSelectionRange(prompt.length, prompt.length)
        }

        setParams(null)
      }, 50)
    }
    const timer = setTimeout(() => {
      handlePastePrompt()
    }, 150)

    return () => {
      clearTimeout(timer)
    }
  }, [params, isFocus])

  useAutoSizeTextArea(textareaRef.current, promptValue ?? "", isOpenSetting)

  const handleCreateWorkflow = async () => {
    if (!user) {
      if (tempTask && tempTask?.length >= 5) {
        toast({
          status: "error",
          title: "Limit reached",
          message: ["Please sign in to generate more"],
        })

        setSignInModal({
          signIn: true,
        })

        return
      }

      googleAnalytics.handleCategoryEvent({
        action: "click",
        params: {
          action: "Create New Macro",
          tab_name: router.pathname.split("/")[2],
        },
      })

      const captchaToken = await generateCaptchaToken()

      mutateCreateTaskNotAuth({
        captchaToken,
        status: RecipeTaskStatus.DRAFT,
        name: "New Macro",
        recipeId: "recipe-to-recipe",
        params: [initWorkflowChain],
      })
      return
    }

    mutateCreateTask({
      status: RecipeTaskStatus.DRAFT,
      name: "New Macro",
      recipeId: "recipe-to-recipe",
      params: [initWorkflowChain],
    })
  }

  useCallbackOnHotKeys(
    "esc",
    () => {
      const modalChild = document.getElementsByClassName("modal-content-child")

      if (modalChild.length > 0) return

      setIsOpenSetting(false)
    },
    isOpenSetting,
  )
  useCallbackOnHotKeys(
    "enter",
    () => {
      handleSubmit(onSubmit)()
    },
    isOpenSetting,
  )

  const options = useMemo(
    () =>
      recipeOneClickStepInputs
        .find(i => i.key === "promptStyle")
        ?.options.sort((a, b) => a.display.localeCompare(b.display)) ?? [],
    [],
  )

  const index = useMemo(() => options.findIndex(i => i.value === promptStyle), [promptStyle, options])

  useCallbackOnHotKeys(
    "arrowdown",
    () => {
      if (index >= options.length - 1) return

      const nextValue = options[index + 1]?.value as string

      form.setValue("promptStyle", nextValue)
    },
    isOpenSetting,
  )

  useCallbackOnHotKeys(
    "arrowup",
    () => {
      if (index === 0) return

      const nextValue = options[index - 1]?.value as string

      form.setValue("promptStyle", nextValue)
    },
    isOpenSetting,
  )

  useCallbackOnHotKeys(
    "arrowright",
    () => {
      const options = recipeOneClickStepInputs.find(i => i.key === "aspectRatio")?.options ?? []

      const index = options.findIndex(i => i.value === aspectRatio)

      if (index >= options.length - 1) return

      const nextValue = options[index + 1]?.value as string

      form.setValue("aspectRatio", nextValue)
    },
    isOpenSetting,
  )

  useCallbackOnHotKeys(
    "arrowleft",
    () => {
      const options = recipeOneClickStepInputs.find(i => i.key === "aspectRatio")?.options ?? []

      const index = options.findIndex(i => i.value === aspectRatio)

      if (index === 0) return

      const nextValue = options[index - 1]?.value as string

      form.setValue("aspectRatio", nextValue)
    },
    isOpenSetting,
  )

  useCallbackOnHotKeys(
    "1",
    () => {
      form.setValue("numberOfImages", 1)
    },
    isOpenSetting,
  )

  useCallbackOnHotKeys(
    "2",
    () => {
      form.setValue("numberOfImages", 2)
    },
    isOpenSetting,
  )

  useCallbackOnHotKeys(
    "3",
    () => {
      form.setValue("numberOfImages", 3)
    },
    isOpenSetting,
  )

  useCallbackOnHotKeys(
    "4",
    () => {
      form.setValue("numberOfImages", 4)
    },
    isOpenSetting,
  )

  useCallbackOnHotKeys(
    "d",
    () => {
      if (!user) return
      setIsOpenSetting(true)
    },
    !isOpenSetting && !isMobile,
  )

  useCallbackOnHotKeys(
    "p",
    () => {
      textareaRef.current?.focus()
      textareaRef.current?.setSelectionRange(promptValue?.length ?? 0, promptValue?.length ?? 0)
    },
    true,
  )

  const renderInput = () => {
    return (
      <div className="max-h-[65vh] flex lg:max-h-[20rem] w-full scrollbar-hover">
        <textarea
          className="rounded-none bg-transparent p-0 text-atherGray-0 placeholder:text-atherGray-500 text-sm resize-none w-full"
          placeholder={tab !== "images" && tab !== "recipes" ? "" : "Imagine..."}
          value={tab !== "images" && tab !== "recipes" ? "" : promptValue}
          disabled={isLoadingCreateAuth || isLoadingCreateNotAuth || (tab !== "images" && tab !== "recipes")}
          {...register("prompt")}
          ref={e => {
            register("prompt").ref(e)
            textareaRef.current = e
          }}
          onFocus={() => {
            setIsFocus(true)
            onFocus?.(true)
          }}
          onBlur={() => {
            setIsFocus(false)
            onFocus?.(false)
          }}
          style={{
            height: "24px",
          }}
          onKeyDown={e => {
            if (e.key === "Enter") {
              e.preventDefault()
              handleSubmit(onSubmit)()
            }
            if (e.key === "Escape") {
              e.preventDefault()
              setIsFocus(false)
              onFocus?.(false)
              textareaRef.current?.blur()
            }
          }}
        />
      </div>
    )
  }

  return (
    <div
      key={isOpenSetting ? "textarea" : "textarea-hidden"}
      className={cn("w-full relative rounded-xl bg-atherGray-800 min-h-12 flex items-center", className)}
    >
      <FormProvider {...form}>
        <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
          <div className="overflow-auto max-h-[40rem] flex items-start space-x-3 p-2">
            {user && (
              <>
                {renderInput()}
                <div className="flex items-center space-x-1">
                  {tab === "images" || tab === "recipes" ? (
                    <>
                      {promptValue && (
                        <IconButton
                          onClick={() => {
                            form.setValue("prompt", "")
                            textareaRef.current?.focus()
                          }}
                          title="Remove prompt"
                          className="text-atherGray-300 p-0.5"
                          colorScheme="transparent"
                        >
                          <XIcon width={16} height={16} />
                        </IconButton>
                      )}
                      <IconButton
                        disabled={!promptValue}
                        title="Generate image"
                        type="submit"
                        isLoading={isLoadingCreateAuth || isLoadingCreateNotAuth}
                        className={classNames(
                          "min-h-0 bg-atherGray-850 rounded-none text-atherGray-300 p-0 flex lg:hidden",
                        )}
                        colorScheme="transparent"
                      >
                        <SendIcon width={24} height={24} />
                      </IconButton>
                      <IconButton
                        title="Settings"
                        onClick={() => setIsOpenSetting(!isOpenSetting)}
                        isLoading={isLoadingCreateAuth || isLoadingCreateNotAuth}
                        className={classNames("min-h-0 bg-atherGray-850 text-atherGray-300 rounded-none p-0", {
                          "text-atherPurple-400": isOpenSetting,
                        })}
                        colorScheme="transparent"
                      >
                        <SettingIcon5 width={24} height={24} />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          setPromptInput({
                            modalType: "PromptsLibraryModal",
                            onChange: (value: string) => {
                              form.setValue("prompt", value)
                            },
                          })
                        }}
                        title="Prompts Library"
                        className={classNames(
                          "min-h-0 bg-atherGray-850 rounded-none text-atherGray-300 p-0 hidden md:flex",
                        )}
                        colorScheme="transparent"
                      >
                        <BookMarkIcon2 width={24} height={24} />
                      </IconButton>
                      {isExplore && (
                        <IconButton
                          onClick={() => {
                            onHidden?.()
                          }}
                          title="Hidden Generate"
                          className={classNames("min-h-0 rotate-180 text-atherGray-300 p-0 hidden md:flex")}
                        >
                          <ArrowSquareLeftIcon width={24} height={24} />
                        </IconButton>
                      )}
                    </>
                  ) : tab === "macros" ? (
                    <IconButton
                      title="Create New Macro"
                      isLoading={isLoadingCreateAuth || isLoadingCreateNotAuth}
                      className="whitespace-nowrap"
                      onClick={e => {
                        e.stopPropagation()
                        handleCreateWorkflow()
                      }}
                    >
                      Create New Macro
                    </IconButton>
                  ) : tab === "styles" ? (
                    <IconButton
                      title="Create New Style"
                      isLoading={isCreatingStyle}
                      className="whitespace-nowrap"
                      onClick={async e => {
                        e.stopPropagation()

                        const isSignIn = await handleSignIn()

                        if (!isSignIn) {
                          return
                        }

                        googleAnalytics.handleCategoryEvent({
                          action: "click",
                          params: {
                            action: "Create New Style",
                            tab_name: router.pathname.split("/")[2],
                          },
                        })

                        createStyle({
                          images: [],
                          isDraft: true,
                        })
                      }}
                    >
                      Create New Style
                    </IconButton>
                  ) : tab === "comfyui" ? (
                    <IconButton
                      title="Create New ComfyUI"
                      isLoading={isCreatingComfyUI}
                      className="whitespace-nowrap"
                      onClick={async e => {
                        e.stopPropagation()

                        const isSignIn = await handleSignIn()

                        if (!isSignIn) {
                          return
                        }

                        googleAnalytics.handleCategoryEvent({
                          action: "click",
                          params: {
                            action: "Create New ComfyUI",
                            tab_name: router.pathname.split("/")[2],
                          },
                        })

                        createWorkflow()
                      }}
                    >
                      Create New ComfyUI
                    </IconButton>
                  ) : (
                    ""
                  )}
                </div>
              </>
            )}
          </div>

          <ReactPortal wrapperId="layout">
            <AnimatePresence>
              {isOpenSetting && (
                <motion.div
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 50 }}
                  transition={{
                    ease: "easeInOut",
                    duration: 0.25,
                    delay: 0.1,
                  }}
                  style={{
                    WebkitOverflowScrolling: "touch",
                  }}
                  className="fixed top-0 left-0 overflow-auto z-[52] w-full flex flex-col bg-blackAlpha-600 backdrop-blur-md modal md:pt-[5rem] md:px-4 md:pb-[1rem] height-full-screen"
                  onClick={() => setIsOpenSetting(false)}
                >
                  <div className="flex-1 flex items-end justify-center">
                    <div
                      onClick={e => e.stopPropagation()}
                      className="bg-black md:bg-atherGray-850 md:rounded-2xl flex flex-col w-full max-w-[85rem] 2xl:max-w-[105rem] 3xl:max-w-[135rem] 5xl:max-w-[155rem] "
                    >
                      <div className="w-full md:flex items-start bg-atherGray-850 rounded-2xl p-4 hidden">
                        <div className="flex items-center space-x-1 h-12 md:min-w-[10.5rem]">
                          {exploreTabs.find(item => item.name?.toLowerCase() === tab?.toLowerCase())?.icon ??
                            exploreTabs[0].icon}
                          <p className="text-xl font-semibold capitalize">{tab}</p>
                        </div>
                        <div className="flex-1 flex">
                          <div className="mx-2 hidden lg:flex justify-center items-center">
                            <div className="h-4/5 w-[1px] bg-atherGray-800" />
                          </div>
                          <div className="flex-1 overflow-auto max-h-[40rem] flex items-start space-x-3 p-2">
                            {renderInput()}
                            <div className="flex items-center space-x-1">
                              {promptValue && (
                                <IconButton
                                  onClick={() => {
                                    form.setValue("prompt", "")
                                    textareaRef.current?.focus()
                                  }}
                                  title="Remove prompt"
                                  className="text-atherGray-300 p-1"
                                  colorScheme="transparent"
                                >
                                  <XIcon width={16} height={16} />
                                </IconButton>
                              )}
                              <IconButton
                                onClick={() => {
                                  setPromptInput({
                                    modalType: "PromptsLibraryModal",
                                    onChange: (value: string) => {
                                      form.setValue("prompt", value)
                                    },
                                  })
                                }}
                                title="Prompts Library"
                                className="text-atherGray-300 p-1"
                                colorScheme="transparent"
                              >
                                <BookMarkIcon2 width={24} height={24} />
                              </IconButton>
                              <IconButton
                                disabled={!promptValue}
                                title="Generate image"
                                type="submit"
                                isLoading={isLoadingCreateAuth || isLoadingCreateNotAuth}
                                className={classNames(
                                  "min-h-0 bg-atherGray-850 rounded-none text-atherGray-300 p-0 flex lg:hidden",
                                )}
                                colorScheme="transparent"
                              >
                                <SendIcon width={24} height={24} />
                              </IconButton>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="md:px-4">
                        <hr className="border-atherGray-800" />
                      </div>
                      <div className="w-full p-4 grid gap-2 md:gap-4 grid-cols-[repeat(auto-fill,minmax(20rem,_1fr))]">
                        <div className="flex flex-col md:min-h-[7rem] py-4 px-2 md:px-4 border-b md:border border-atherGray-800 md:rounded-2xl">
                          <p className="font-semibold text-atherGray-300 text-sm mb-1">Prompt Style</p>
                          <div className="mt-auto flex items-center">
                            <div className="flex-1">
                              <FormDetailSelectComponent
                                input={recipeOneClickStepInputs.find(i => i.key === "promptStyle") as any}
                                value={form.watch("promptStyle")}
                                error={errors.promptStyle}
                                register={register}
                                onChange={data => form.setValue("promptStyle", data.value as any)}
                                rightIcon={<ArrowDownIcon width={12} height={12} />}
                                listClassName="bg-atherGray-900 rounded-2xl  border border-atherGray-800 text-sm max-h-[20rem]"
                                itemClassName="py-2 px-2 hover:bg-atherGray-850 rounded-2xl"
                                listContentClassName="px-2"
                                inputClassName="p-4"
                              />
                            </div>
                            <IconButton
                              onClick={() => {
                                setPromptInput({
                                  modalType: "PromptsLibraryModal",
                                  onChange: (value: string) => {
                                    form.setValue("prompt", value)
                                  },
                                })
                              }}
                              title="Prompts Library"
                              className="text-atherGray-300 p-1 flex md:hidden ml-2"
                              colorScheme="transparent"
                            >
                              <BookMarkIcon2 width={24} height={24} />
                            </IconButton>
                          </div>
                        </div>
                        <div className="flex flex-col md:min-h-[7rem] py-4 px-2 md:px-4 border-b md:border border-atherGray-800 md:rounded-2xl">
                          <p className="font-semibold text-atherGray-300 text-sm mb-1">Aspect Ratio</p>
                          <div className="mt-auto">
                            <div className="flex items-end w-full justify-between">
                              <FormDetailRatioComponent
                                layout="vertical"
                                buttonsColorActive="bg-atherGray-100"
                                input={recipeOneClickStepInputs.find(i => i.key === "aspectRatio") as any}
                                value={form.watch("aspectRatio")}
                                error={errors.aspectRatio}
                                buttonsClassName="min-h-0 min-w-0"
                                register={register}
                                onChange={data => form.setValue("aspectRatio", data.value as any)}
                              />
                              <p className="ml-2 text-atherGray-300 text-sm">{aspectRatio}</p>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-col min-h-[7rem] py-4 px-2 md:px-4 border-b md:border border-atherGray-800 md:rounded-2xl">
                          <p className="font-semibold text-atherGray-300 text-sm mb-1">Number of images</p>
                          <div
                            className={css({
                              marginTop: "auto",
                              ".track-slider-0": {
                                backgroundColor: "#EAE4D4!important",
                              },
                              ".track-slider-1": {
                                backgroundColor: "#323230!important",
                              },
                              ".thumb-tracker": {
                                backgroundColor: "#EAE4D4",
                              },
                            })}
                          >
                            <FormDetailNumberComponent
                              isRenderValueTop
                              input={recipeOneClickStepInputs.find(i => i.key === "numberOfImages") as any}
                              value={form.watch("numberOfImages")?.toString()}
                              error={errors.numberOfImages}
                              register={register}
                              onChange={data => form.setValue("numberOfImages", parseInt(data.value as any))}
                            />
                          </div>
                        </div>
                        <div
                          className={classNames(
                            "flex flex-col md:min-h-[7rem] py-4 px-2 md:px-4 border-b md:border border-atherGray-800 md:rounded-2xl",
                            {
                              hidden: !user,
                            },
                          )}
                        >
                          <p className="font-semibold text-atherGray-300 text-sm mb-1">Save the output in</p>
                          <div id="folderId" className="relative mt-auto text-atherGray-300">
                            <DrawerSelectFolder
                              isRemoveNewFolder
                              rightIcon={<ArrowDownIcon width={12} height={12} />}
                              className="absolute md:left-0 top-auto bottom-full translate-y-[-0.375rem] md:bottom-auto min-h-[10rem] md:top-full md:translate-y-1.5 h-auto md:h-auto max-h-[20rem] w-full border border-atherGray-800 rounded-2xl"
                              selected={folder}
                              setSelected={v => {
                                setFolder(v)
                                form.setValue("folderId", v?.id)
                              }}
                              placeHolder="Recipe Images"
                            />
                            {errors.folderId?.message && (
                              <span className="mt-1 text-xs text-red-500">{errors.folderId?.message}</span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="hidden md:flex items-center justify-between space-x-2 p-4">
                        <ShortcutSuggest />
                        <div className="flex space-x-2">
                          <IconButton colorScheme="secondary" title="Cancel" onClick={() => setIsOpenSetting(false)}>
                            Cancel
                          </IconButton>
                          <IconButton title="Generate image" onClick={handleSubmit(onSubmit)}>
                            Generate
                          </IconButton>
                        </div>
                      </div>
                      <div className="flex md:hidden flex-col items-center justify-center space-y-4 px-4 pb-[5.15rem] md:pb-0">
                        <IconButton title="Generate image" className="w-full py-2.5" onClick={handleSubmit(onSubmit)}>
                          Apply
                        </IconButton>
                        <IconButton colorScheme="transparent" title="Cancel" onClick={() => setIsOpenSetting(false)}>
                          <XIcon width={16} height={16} className="text-atherGray-300" />
                        </IconButton>
                      </div>
                    </div>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </ReactPortal>
        </form>
      </FormProvider>
    </div>
  )
}

export default memo(GalleryGeneratedImage)
