import ImageWebpComponent from "@/components/ImageWebpComponent"
import { cdnPublicFolderUrl } from "@/constants"

export const WOWIcon = (props: { width?: number; height?: number; className?: string }) => {
  const width = props.width ?? 24
  const height = props.height ?? 24

  return (
    <ImageWebpComponent
      className={props.className}
      src={`${cdnPublicFolderUrl}/images/wow.png`}
      width={width}
      height={height}
      alt="wow"
      {...props}
    />
  )
}
