import client from "@/api/client"
import { FolderDetail, SubfolderView } from "@/api/sdk"
import { enableQueryMiddleware } from "@/queries/middlewares/enableQueryMiddleware"
import { createAuthenticatedQuery } from "../../createAuthenticatedQuery"

export interface UseFolderInfoQueryVariables {
  folderId?: string
  view?: SubfolderView
}

export const useFolderInfoQuery = createAuthenticatedQuery<FolderDetail, UseFolderInfoQueryVariables, Error>({
  primaryKey: "folder-info",
  queryFn: ({ queryKey: [_primaryKey, variables] }) =>
    client.api.folderControllerGetFolderInfo(variables.folderId!).then(res => res.data),
  use: [enableQueryMiddleware(v => !!v.folderId)],
})
