import client from "@/api/client"
import { RecentFolderItem as RecentFolderItemType } from "@/api/sdk"
import LoadingLogo from "@/components/LoadingLogo"
import { createAuthenticatedInfiniteQuery } from "@/queries/createAuthenticatedQuery"
import { useGridStore } from "@/stores/useGirdStore"
import classNames from "classnames"
import differenceInDays from "date-fns/differenceInDays"
import endOfDay from "date-fns/endOfDay"
import startOfDay from "date-fns/startOfDay"
import { motion } from "framer-motion"
import useCustomRouter from "@/hooks/useCustomRouter"
import { memo, useEffect, useMemo, useRef, useState } from "react"
import InfiniteScroll from "react-infinite-scroller"
import RecentFilter from "../RecentFilter"
import { formatFilterDate } from "../RecentV2"
import RecentFolderItem from "./RecentFolderItem"
import ErrorUI from "@/components/ErrorUI"

interface UseRecentInfiniteQueryVariables {
  fromDate?: Date
  toDate?: Date
  take?: number
}

export const useRecentFolderInfiniteQuery = createAuthenticatedInfiniteQuery<
  RecentFolderItemType[],
  UseRecentInfiniteQueryVariables,
  Error,
  RecentFolderItemType
>({
  primaryKey: "recent-folders-infinite",
  queryFn: ({ pageParam = 0, queryKey: [_primaryKey, variables] }) =>
    client.api
      .recentAccessControllerGetRecentFolder({
        fromDate: variables.fromDate ? startOfDay(variables?.fromDate).toISOString() : undefined,
        toDate: variables?.toDate ? endOfDay(variables?.toDate).toISOString() : undefined,
        skip: pageParam * (variables.take ?? 20),
        take: variables.take ?? 20,
      })
      .then(res => res.data),
  getNextPageParam: (lastPage, pages) => (lastPage && lastPage.length < 20 ? undefined : pages.length),
  initialPageParam: 0,
  refetchOnWindowFocus: false,
  retry: false,
  flattenFn: item => item,
})

const RecentFolder = () => {
  const [filter, setFilter] = useState<{ toDate?: Date; type: string; fromDate?: Date } | undefined>()
  const [layout, setLayout] = useGridStore(state => [state.layout, state.setLayout])

  const {
    hasNextPage,
    fetchNextPage,
    isLoading,
    isError,
    isFetching,
    flattenData = [],
  } = useRecentFolderInfiniteQuery({
    variables: {
      fromDate: filter?.fromDate,
      toDate: filter?.toDate,
    },
  })

  const formatMappedRecentFolder = useMemo(() => {
    const recentFoldersToday = flattenData.filter(
      folder => differenceInDays(new Date(), new Date(folder.accessedAt)) <= 1,
    )

    const recentFoldersOneWeek = flattenData.filter(
      folder =>
        differenceInDays(new Date(), new Date(folder.accessedAt)) <= 7 &&
        differenceInDays(new Date(), new Date(folder.accessedAt)) > 1,
    )

    const recentFoldersOneMonth = flattenData.filter(
      folder =>
        differenceInDays(new Date(), new Date(folder.accessedAt)) > 7 &&
        differenceInDays(new Date(), new Date(folder.accessedAt)) <= 30,
    )

    const recentFoldersOlder = flattenData.filter(
      folder => differenceInDays(new Date(), new Date(folder.accessedAt)) > 30,
    )

    return {
      recentFoldersToday,
      recentFoldersOneWeek,
      recentFoldersOneMonth,
      recentFoldersOlder,
    }
  }, [flattenData])

  const containerRef = useRef<HTMLDivElement>(null)
  const router = useCustomRouter()

  useEffect(() => {
    if (router.isReady) {
      const filter = router.query.filter

      if (filter) {
        const filterDateString = filter as string

        const filterDate = formatFilterDate(filterDateString)
        setFilter({
          ...filterDate,
          type: filterDateString,
        })
      } else {
      }
    }
  }, [router.isReady, router.query.filter])

  const renderBody = () => {
    if (isLoading)
      return (
        <div className="flex items-center justify-center w-full flex-1 text-gray-600">
          <LoadingLogo />
        </div>
      )

    if (isError) return <ErrorUI />

    return (
      <InfiniteScroll
        loadMore={() => fetchNextPage()}
        hasMore={!!hasNextPage && !isFetching}
        useWindow={true}
        threshold={600}
        style={{
          display: "flex",
          flexDirection: "column",
          maxWidth: "100%",
          position: "relative",
        }}
        loader={
          <div key="loader" className="flex items-center justify-center py-4">
            Loading...
          </div>
        }
      >
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.35, delay: 0.45 }}
          className={classNames("relative flex flex-1 flex-col")}
        >
          {layout === "list" && (
            <div className="hidden mb-2 w-full items-center md:flex text-xs uppercase font-semibold text-atherGray-500">
              <p className="w-[30%] p-2 text-left">Name</p>
              <p className="w-[15%] p-2 text-left">Category</p>
              <p className="w-[15%] p-2 text-left">Activity</p>
              <p className="w-[15%] p-2 text-left">Creator</p>
              <p className="w-[20%] p-2 text-left">Location</p>
              <p className="w-[5%] p-2 text-left"></p>
            </div>
          )}
          <RecentFolderItem layout={layout} name="Today" recent={formatMappedRecentFolder.recentFoldersToday} />
          <RecentFolderItem layout={layout} name="Last 7 days" recent={formatMappedRecentFolder.recentFoldersOneWeek} />
          <RecentFolderItem
            layout={layout}
            name="Last 30 days"
            recent={formatMappedRecentFolder.recentFoldersOneMonth}
          />
          <RecentFolderItem layout={layout} name="Older" recent={formatMappedRecentFolder.recentFoldersOlder} />
        </motion.div>
      </InfiniteScroll>
    )
  }

  return (
    <div className="flex-1 flex flex-col">
      <RecentFilter filter={filter} layout={layout} setFilter={setFilter} setLayout={setLayout} />
      <div ref={containerRef} className="relative flex flex-col w-full flex-1">
        {renderBody()}
      </div>
    </div>
  )
}

export default memo(RecentFolder)
