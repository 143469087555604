import { RecipeType, SDBaseModel, WorkflowSortOptions, WorkflowViewOptions } from "@/api/sdk"
import { Radio } from "@/components/Alpha/View/GalleryFilter"
import IconButton from "@/components/IconButton"
import ImageWebpComponent from "@/components/ImageWebpComponent"
import ReactPortal from "@/components/ReactPortal"
import SelectDateRange from "@/components/SelectDateRange"

import { ArrowDownIcon, CheckCircleIcon, FilterIcon, XIcon } from "@/components/shared/icons"
import { useDebounce } from "@/hooks"
import { useAuth } from "@/providers/authContext"
import { useWorkspaceWorkflowInfiniteQuery } from "@/queries"
import { useWorkspaceSearchHistory } from "@/stores"
import { cn } from "@/utils/cn"
import { AnimatePresence, motion } from "framer-motion"
import Link from "next/link"
import { Fragment, useCallback, useEffect, useMemo, useState } from "react"
import SearchBar from "../FoldersV2/SearchBar"
import { googleAnalytics } from "@/lib/gtag"
import UserCreated from "@/components/Explore/Gallery/UserCreated"
import DrawerSelectFilter from "./DrawerSelectFilter"
import { useModelQuery } from "../Recipes/RecipeDetail/FormDetail/FormDetailModel"
import { useStylesQuery } from "../Recipes/RecipeDetail/FormDetail/FormDetailStyle"
import { useLoraQuery } from "../Recipes/RecipeDetail/FormDetail/FormDetailLora"
import CheckboxComponent from "@/components/Checkbox"
import { useGetRecipesQuery } from "@/queries/workspace/recipe"
import TrashDeleteAllButton from "../FoldersV2/TrashDeleteAllButton"

interface WorkflowTabActionsProps {
  filter: {
    sort: WorkflowSortOptions | null
    shared: string[] | null
    startDate: Date | null
    endDate: Date | null
    modelHashes?: string[]
    recipeIds?: string[]
    styleIds?: string[]
    loraHashes?: string[]
  }
  setFilter: React.Dispatch<
    React.SetStateAction<{
      sort: WorkflowSortOptions | null
      shared: string[] | null
      startDate: Date | null
      endDate: Date | null
      modelHashes?: string[]
      recipeIds?: string[]
      styleIds?: string[]
      loraHashes?: string[]
    }>
  >
  className?: string
  onConfirmSearch?: (search: string) => void
  parentFolderId?: string
  selectionMode: boolean
  toggleSelectionMode: (boolean) => void
  view: WorkflowViewOptions
  onRemoveHistory?: (item: string) => void
  basePath: string
  tabQuery?: string
  itemLength?: number
}

const ListFilterSelected = ({
  values,
  onRemove,
}: {
  values: { id: string; name: string }[]
  onRemove: (id: string) => void
}) => {
  return (
    <div className="flex flex-wrap gap-1 mt-2">
      {values.map(value => (
        <span
          className="flex items-center text-xs border border-atherGray-700 py-0.5 px-1.5 rounded-lg text-atherGray-300"
          key={value.id}
        >
          {value.name}
          <XIcon
            onClick={() => {
              onRemove(value.id)
            }}
            className="text-red-500 ml-1 cursor-pointer"
            width={10}
            height={10}
          />
        </span>
      ))}
    </div>
  )
}

export const workflowSortOptions: { sortBy: WorkflowSortOptions; label: string }[] = [
  {
    sortBy: WorkflowSortOptions.NEWEST,
    label: "Newest",
  },
  {
    sortBy: WorkflowSortOptions.OLDEST,
    label: "Oldest",
  },
]

export const filterDiscoverAtOptions = [
  {
    display: "Explore",
    value: "explore",
  },
  {
    display: "Workspace",
    value: "workspace",
  },
]

const WorkflowTabActions = ({
  className,
  itemLength,
  onConfirmSearch,
  view,
  tabQuery,
  selectionMode,
  toggleSelectionMode,
  basePath,
  onRemoveHistory,
  filter,
  setFilter,
}: WorkflowTabActionsProps) => {
  const { user } = useAuth()

  const history = useWorkspaceSearchHistory(s =>
    s.history.filter(item => item.uid === user?.uid && item.location === basePath),
  )

  const [search, setSearch] = useState("")
  const [searchFilter, setSearchFilter] = useState("")

  const debouncedSearch = useDebounce(search, 350)
  const [isOpenFilter, setIsOpenFilter] = useState<"recipes" | "styles" | "loras" | "models" | null>(null)

  const [filterDropdown, setFilterDropdown] = useState(false)
  const [filterTemp, setFilterTemp] = useState<{
    sort: WorkflowSortOptions
    shared: string[] | null
    startDate: Date | null
    endDate: Date | null
    loraHashes?: string[]
    modelHashes?: string[]
    recipeIds?: string[]
    styleIds?: string[]
  }>({
    sort: WorkflowSortOptions.NEWEST,
    shared: null,
    startDate: null,
    endDate: null,
    loraHashes: undefined,
    modelHashes: undefined,
    recipeIds: undefined,
    styleIds: undefined,
  })

  const {
    flattenData: previewResult,
    total,
    isLoading,
  } = useWorkspaceWorkflowInfiniteQuery({
    variables: {
      search: debouncedSearch,
      view,
    },
    enabled: !!debouncedSearch && !!user?.uid,
  })

  useEffect(() => {
    if (tabQuery) {
      toggleSelectionMode(false)
      setSearch("")
      setFilter({
        startDate: null,
        shared: null,
        endDate: null,
        sort: null,
        loraHashes: undefined,
        modelHashes: undefined,
        recipeIds: undefined,
        styleIds: undefined,
      })
    }
  }, [tabQuery])

  const buildPreviewResult = (search: string) => {
    if (isLoading) {
      return (
        <div className="p-1">
          <p className="text-atherGray-300 text-sm">Searching...</p>
        </div>
      )
    }

    if (!isLoading && previewResult?.length === 0) {
      return (
        <div className="p-1">
          <p className="text-atherGray-300 text-sm">No results found</p>
        </div>
      )
    }
    if (previewResult && previewResult.length > 0) {
      const resultLeft = total - previewResult.length

      return (
        <div
          className="flex flex-col space-y-2 overflow-auto max-h-[30vh]"
          onMouseEnter={e => {
            e.stopPropagation()
            e.preventDefault()
          }}
        >
          {previewResult?.map(workflow => (
            <Link
              key={workflow.id}
              className="flex w-full rounded-2xl items-center hover:bg-atherGray-850 p-1 px-2 cursor-pointer"
              href={`/workspace/macros/${workflow.id}`}
            >
              <div className="w-12 h-12 bg-atherGray-900">
                <ImageWebpComponent
                  className="w-12 h-12 rounded-lg object-cover"
                  src={workflow.image?.url}
                  alt={workflow.name}
                />
              </div>
              <div className="ml-2 overflow-hidden flex-1">
                <p className="text-sm truncate font-semibold mb-1">{workflow.name}</p>
                <UserCreated name={workflow.creator?.name ?? ""} picture={workflow.creator?.picture ?? ""} size="xs" />
              </div>
            </Link>
          ))}
          {resultLeft > 0 && (
            <button
              className="text-xs font-semibold text-atherGray-300 hover:text-atherGray-0 hover:underline text-left"
              onClick={() => onConfirmSearch?.(search)}
            >
              ...and {resultLeft} other {resultLeft > 1 ? "results" : "result"}
            </button>
          )}
        </div>
      )
    }

    return null
  }

  const handleSetSort = (newSortBy: WorkflowSortOptions) => {
    if (newSortBy === filter.sort) return

    setFilterTemp(prev => ({ ...prev, sort: newSortBy }))
  }

  const { data: recipes } = useGetRecipesQuery({
    variables: { types: [RecipeType.Normal, RecipeType.Describe] },
  })
  const { data: models } = useModelQuery({
    variables: {
      baseModel: SDBaseModel.All,
    },
  })
  const { data: styles } = useStylesQuery()
  const { data: loras } = useLoraQuery()

  const mappedRecipes = useMemo(
    () =>
      recipes?.recipes?.map(recipe => ({
        name: recipe.name,
        thumbnailUrl: recipe.thumbnail,
        id: recipe.id,
      })) ?? [],
    [recipes],
  )

  const mappedModels = useMemo(
    () =>
      models?.models?.map(model => ({
        name: model.name,
        thumbnailUrl: model.thumbnailUrl,
        id: model.id,
      })) ?? [],
    [models],
  )

  const mappedStyles = useMemo(
    () =>
      styles?.data?.map(style => ({
        name: style.name ?? style.id.slice(0, 10),
        thumbnailUrl: style.thumbnailUrl ?? "",
        id: style.id,
      })) ?? [],
    [styles],
  )

  const mappedLoras = useMemo(
    () =>
      loras?.models?.map(lora => ({
        name: lora.name,
        thumbnailUrl: lora.thumbnailUrl,
        id: lora.id,
      })) ?? [],
    [loras],
  )

  const mappedData = useMemo(() => {
    if (isOpenFilter === "recipes") return mappedRecipes
    if (isOpenFilter === "models") return mappedModels
    if (isOpenFilter === "styles") return mappedStyles
    if (isOpenFilter === "loras") return mappedLoras
    return []
  }, [isOpenFilter, mappedLoras, mappedRecipes, mappedModels, mappedStyles])

  const handleSetValuesFilter = useCallback(
    (values: string[]) => {
      if (isOpenFilter === "recipes") {
        setFilterTemp(prev => ({ ...prev, recipeIds: values }))
      }
      if (isOpenFilter === "models") {
        setFilterTemp(prev => ({ ...prev, modelHashes: values }))
      }
      if (isOpenFilter === "styles") {
        setFilterTemp(prev => ({ ...prev, styleIds: values }))
      }
      if (isOpenFilter === "loras") {
        setFilterTemp(prev => ({ ...prev, loraHashes: values }))
      }
    },
    [isOpenFilter],
  )

  const valueFilter = useMemo(() => {
    if (isOpenFilter === "recipes") return filterTemp.recipeIds
    if (isOpenFilter === "models") return filterTemp.modelHashes
    if (isOpenFilter === "styles") return filterTemp.styleIds
    if (isOpenFilter === "loras") return filterTemp.loraHashes
    return []
  }, [isOpenFilter, filterTemp])

  return (
    <Fragment>
      <div className={cn("flex flex-1 justify-end md:space-x-2", className)}>
        <SearchBar
          search={search}
          setSearch={setSearch}
          inputClassName="md:w-80"
          onConfirmSearch={onConfirmSearch}
          history={history}
          previewResultBuilder={buildPreviewResult}
          onClear={() => {
            onConfirmSearch?.("")
          }}
          onClearSearchHistory={item => onRemoveHistory?.(item.text)}
        />
        <IconButton
          colorScheme="transparent"
          className={cn(
            "rounded-2xl p-2 px-3 hover:bg-atherGray-850 flex items-center text-atherGray-300 transition-all",
            {
              "bg-atherGray-850 text-atherGray-0": selectionMode,
            },
          )}
          onClick={() => {
            googleAnalytics.event({
              action: "click",
              category: "filter",
              label: `workflow_multi_select`,
            })
            toggleSelectionMode(!selectionMode)
          }}
        >
          <CheckCircleIcon width={20} height={20} checked={selectionMode} />
          <span className="font-normal ml-1 text-sm hidden md:block">Multi-select</span>
        </IconButton>
        {view === WorkflowViewOptions.TRASH && <TrashDeleteAllButton type="macro" disabled={itemLength === 0} />}
        <IconButton
          colorScheme="transparent"
          className={cn("rounded-2xl border border-transparent px-2 hover:bg-atherGray-85", {
            "bg-atherGray-850": filterDropdown,
            "border border-atherPurple-500": Object.values(filter).some(v => !!v && v !== WorkflowSortOptions.NEWEST),
          })}
          onClick={() => setFilterDropdown(prev => !prev)}
        >
          <FilterIcon />
        </IconButton>
      </div>
      <ReactPortal>
        <AnimatePresence mode="wait">
          {filterDropdown && (
            <Fragment>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{
                  ease: "easeInOut",
                  duration: 0.2,
                }}
                className="fixed top-0 height-full-screen right-0 text-atherGray-0 w-80 border border-atherGray-800 bg-atherGray-900 flex flex-col overflow-hidden z-[26]"
              >
                <div className="rounded-2xl overflow-auto flex w-full flex-1 flex-col space-y-4 p-4">
                  {view === WorkflowViewOptions.MY_IMAGES && tabQuery !== "drafts" && (
                    <Fragment>
                      <div className="flex flex-col">
                        <div className="flex w-full items-center justify-between mb-2">
                          <p className="text-xs text-atherGray-100 font-semibold">Filter</p>
                        </div>
                        <div className={"flex items-center space-x-4 text-sm"}>
                          {filterDiscoverAtOptions.map(option => (
                            <CheckboxComponent
                              key={option.value}
                              label={option.display}
                              checked={filterTemp?.shared?.includes(option.value) ?? false}
                              onChange={value => {
                                setFilterTemp(prev => {
                                  if (value) {
                                    return {
                                      ...prev,
                                      shared: [...(prev.shared ?? []), option.value],
                                    }
                                  }

                                  return {
                                    ...prev,
                                    shared: prev.shared?.filter(s => s !== option.value) ?? null,
                                  }
                                })
                              }}
                            />
                          ))}
                        </div>
                      </div>
                      <hr className="border-atherGray-800" />
                    </Fragment>
                  )}
                  <div>
                    <p className="text-xs text-atherGray-100 font-semibold mb-2">Sort</p>
                    <div className="text-sm flex items-center space-x-4">
                      {workflowSortOptions.map(option => (
                        <Radio
                          checked={filterTemp.sort === option.sortBy}
                          key={option.label}
                          name={option.label}
                          onChange={v => {
                            handleSetSort(option.sortBy)
                          }}
                          value={option.sortBy}
                        />
                      ))}
                    </div>
                  </div>
                  <hr className="border-atherGray-800" />
                  <div>
                    <p className="text-xs text-atherGray-100 font-semibold mb-2">Time Range</p>
                    <SelectDateRange
                      value={{
                        startDate: filterTemp.startDate,
                        endDate: filterTemp.endDate,
                      }}
                      onSelect={v => setFilterTemp(prev => ({ ...prev, startDate: v.startDate, endDate: v.endDate }))}
                    />
                  </div>
                  <hr className="border-atherGray-800" />
                  <div>
                    <p className="text-xs text-atherGray-100 font-semibold mb-2">Models</p>
                    <div
                      onClick={() =>
                        setIsOpenFilter(prev => {
                          if (prev === "models") return null

                          return "models"
                        })
                      }
                      className="bg-atherGray-800 p-2 rounded-lg cursor-pointer text-sm flex items-center justify-between"
                    >
                      Select models
                      <ArrowDownIcon className="-rotate-90 text-atherGray-300" width={12} height={12} />
                    </div>
                    <ListFilterSelected
                      values={
                        filterTemp.modelHashes?.map(id => ({
                          id,
                          name: mappedModels?.find(lora => lora.id === id)?.name ?? "",
                        })) ?? []
                      }
                      onRemove={id => {
                        setFilterTemp(prev => ({ ...prev, modelHashes: prev.modelHashes?.filter(i => i !== id) }))
                      }}
                    />
                  </div>
                  <hr className="border-atherGray-800" />
                  <div>
                    <p className="text-xs text-atherGray-100 font-semibold mb-2">Recipes</p>
                    <div
                      onClick={() =>
                        setIsOpenFilter(prev => {
                          if (prev === "recipes") return null

                          return "recipes"
                        })
                      }
                      className="bg-atherGray-800 p-2 rounded-lg cursor-pointer text-sm flex items-center justify-between"
                    >
                      Select recipes
                      <ArrowDownIcon className="-rotate-90 text-atherGray-300" width={12} height={12} />
                    </div>
                    <ListFilterSelected
                      values={
                        filterTemp.recipeIds?.map(id => ({
                          id,
                          name: mappedRecipes.find(recipe => recipe.id === id)?.name ?? "",
                        })) ?? []
                      }
                      onRemove={id => {
                        setFilterTemp(prev => ({ ...prev, recipeIds: prev.recipeIds?.filter(i => i !== id) }))
                      }}
                    />
                  </div>
                  <hr className="border-atherGray-800" />
                  <div>
                    <p className="text-xs text-atherGray-100 font-semibold mb-2">Loras</p>
                    <div
                      onClick={() =>
                        setIsOpenFilter(prev => {
                          if (prev === "loras") return null

                          return "loras"
                        })
                      }
                      className="bg-atherGray-800 p-2 rounded-lg cursor-pointer text-sm flex items-center justify-between"
                    >
                      Select loras
                      <ArrowDownIcon className="-rotate-90 text-atherGray-300" width={12} height={12} />
                    </div>
                    <ListFilterSelected
                      values={
                        filterTemp.loraHashes?.map(id => ({
                          id,
                          name: mappedLoras?.find(lora => lora.id === id)?.name ?? "",
                        })) ?? []
                      }
                      onRemove={id => {
                        setFilterTemp(prev => ({ ...prev, loraHashes: prev.loraHashes?.filter(i => i !== id) }))
                      }}
                    />
                  </div>
                  <hr className="border-atherGray-800" />
                  <div>
                    <p className="text-xs text-atherGray-100 font-semibold mb-2">Styles</p>
                    <div
                      onClick={() =>
                        setIsOpenFilter(prev => {
                          if (prev === "styles") return null

                          return "styles"
                        })
                      }
                      className="bg-atherGray-800 p-2 rounded-lg cursor-pointer text-sm flex items-center justify-between"
                    >
                      Select styles
                      <ArrowDownIcon className="-rotate-90 text-atherGray-300" width={12} height={12} />
                    </div>
                    <ListFilterSelected
                      values={
                        filterTemp.styleIds?.map(id => ({
                          id,
                          name: mappedStyles?.find(lora => lora.id === id)?.name ?? "",
                        })) ?? []
                      }
                      onRemove={id => {
                        setFilterTemp(prev => ({ ...prev, styleIds: prev.styleIds?.filter(i => i !== id) }))
                      }}
                    />
                  </div>
                  <hr className="border-atherGray-800" />
                </div>
                <div className="flex items-center space-x-2 justify-center p-4">
                  <IconButton
                    colorScheme="secondary"
                    className="w-full"
                    onClick={() => {
                      const initial = {
                        sort: WorkflowSortOptions.NEWEST,
                        startDate: null,
                        endDate: null,
                        shared: null,
                        loraHashes: undefined,
                        modelHashes: undefined,
                        recipeIds: undefined,
                        styleIds: undefined,
                      }

                      googleAnalytics.event({
                        action: "click",
                        category: "filter",
                        label: `clear_workflow_filter`,
                      })

                      setFilterTemp(initial)
                      setFilter(initial)
                      setFilterDropdown(false)
                      setIsOpenFilter(null)
                    }}
                  >
                    Clear All
                  </IconButton>
                  <IconButton
                    className="w-full"
                    onClick={() => {
                      googleAnalytics.event({
                        action: "click",
                        category: "filter",
                        label: `workflow_filter`,
                        params: {
                          sort: filterTemp.sort,
                          startDate: filterTemp.startDate?.toISOString() ?? "",
                          endDate: filterTemp.endDate?.toISOString() ?? "",
                          shared: filterTemp.shared?.join(",") ?? "All",
                          loraHashes: filterTemp.loraHashes?.join(",") ?? "",
                          modelHashes: filterTemp.modelHashes?.join(",") ?? "",
                          recipeIds: filterTemp.recipeIds?.join(",") ?? "",
                          styleIds: filterTemp.styleIds?.join(",") ?? "",
                        },
                      })

                      setFilter({
                        sort: filterTemp.sort,
                        shared: filterTemp.shared,
                        startDate: filterTemp.startDate,
                        endDate: filterTemp.endDate,
                        loraHashes: filterTemp.loraHashes,
                        modelHashes: filterTemp.modelHashes,
                        recipeIds: filterTemp.recipeIds,
                        styleIds: filterTemp.styleIds,
                      })
                      setFilterDropdown(false)
                    }}
                  >
                    Apply
                  </IconButton>
                </div>
                <DrawerSelectFilter
                  mappedData={mappedData}
                  key={isOpenFilter}
                  isOpen={!!isOpenFilter}
                  onClose={() => setIsOpenFilter(null)}
                  search={searchFilter}
                  values={valueFilter}
                  setSearch={setSearchFilter}
                  onSelectValues={values => {
                    handleSetValuesFilter(values)
                  }}
                  title={isOpenFilter ?? ""}
                />
              </motion.div>

              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{
                  ease: "easeInOut",
                  duration: 0.2,
                }}
                className="fixed top-0 height-full-screen left-0 w-full bg-blackAlpha-700 md:backdrop-blur-md z-[25]"
                onClick={() => setFilterDropdown(false)}
              />
            </Fragment>
          )}
        </AnimatePresence>
      </ReactPortal>
    </Fragment>
  )
}

export default WorkflowTabActions
