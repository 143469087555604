import { IconProps } from "."

export const CloseIcon = (props: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="currentColor" {...props}>
      <g id="elements">
        <path
          id="Vector"
          d="M15 1.5L1 15.5M1 1.5L15 15.5"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  )
}
