import { cn } from "@/utils/cn"
import { DetailedHTMLProps, InputHTMLAttributes, forwardRef } from "react"

export interface InputProps extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  containerClassName?: string
  rightIcon?: React.ReactNode
  leftIcon?: React.ReactNode
  labelClassName?: string
  label?: string
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ containerClassName, rightIcon, leftIcon, className, label, labelClassName, ...props }, ref) => {
    return (
      <div className={cn("relative", containerClassName)}>
        {label && <label className={cn("text-atherGray-300 text-sm mb-1 block", labelClassName)}>{label}</label>}
        <input
          className={cn(
            "bg-atherGray-800 rounded-2xl h-10 px-4 w-full text-atherGray-300 disabled:text-atherGray-400 disabled:placeholder:text-atherGray-500 placeholder:text-atherGray-400",
            {
              "pr-12": !!rightIcon,
              "pl-12": !!leftIcon,
            },
            className,
          )}
          {...props}
          ref={ref}
        />
        {leftIcon && <div className="absolute left-4 top-1/2 transform -translate-y-1/2">{leftIcon}</div>}
        {rightIcon && <div className="absolute right-4 top-1/2 transform -translate-y-1/2">{rightIcon}</div>}
      </div>
    )
  },
)

export default Input
