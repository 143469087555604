import client from "@/api/client"
import { ExploreRecipeItem, GetRecipesResult, PinnedItemType, PinnedItems, RecentRecipeItem } from "@/api/sdk"
import { InfiniteData } from "@tanstack/react-query"
import { createMutation } from "react-query-kit"
import { actionMiddleware } from "@/queries/middlewares/actionMiddleware"
import { useExploreDetailRecipeQuery } from "@/queries/explore"
import { useGetRecipesInfiniteQuery } from "./getRecipeQueries"
import { useRecentRecipesInfiniteQuery } from "@/components/Workspace/RecentV2/RecentRecipes"
import { useWorkspacePinInfiniteQuery } from "../useWorkspacePinInfiniteQuery"

interface RemoveStylesMutationVariables {
  ids: string[]
  userUid?: string
}

export const useAddRecipesMutation = createMutation({
  mutationFn: async ({ ids }: RemoveStylesMutationVariables) => {
    return client.api
      .recipeControllerAddRecipesToLibrary({
        recipeIds: ids,
      })
      .then(res => res.data)
  },
  use: [
    actionMiddleware({
      onSuccess: ({ queryClient, variables: { ids, userUid } }) => {
        // Update recipe list
        const workspaceRecipesListKey = useGetRecipesInfiniteQuery.getKey()
        const workspaceRecipesListQueriesDataEntries = queryClient.getQueriesData<
          InfiniteData<GetRecipesResult, number>
        >({
          queryKey: workspaceRecipesListKey,
        })

        workspaceRecipesListQueriesDataEntries?.forEach(([key, data]) => {
          if (!data) return

          const updatedData = {
            ...data,
            pages: data.pages.map(page => {
              const updatedPage = {
                ...page,
                recipes: page.recipes.map(recipes => {
                  if (ids.includes(recipes.id)) {
                    return {
                      ...recipes,
                      capabilities: {
                        ...recipes.capabilities,
                        canAddToLibrary: false,
                        canRemoveFromLibrary: true,
                      },
                    }
                  }
                  return recipes
                }),
              }
              return updatedPage
            }),
          }

          queryClient.setQueryData(key, updatedData)
        })

        // update recipe recent list
        const recentListRecipes = useRecentRecipesInfiniteQuery.getKey()

        const recentListQueriesDataEntries = queryClient.getQueriesData<InfiniteData<RecentRecipeItem[], number>>({
          queryKey: recentListRecipes,
        })

        if (recentListQueriesDataEntries) {
          recentListQueriesDataEntries.forEach(([key, data]) => {
            if (!data) return

            const updatedData = {
              ...data,
              pages: data.pages.map(page => {
                return [
                  ...page.map(item => {
                    if (ids.includes(item.recipe.id)) {
                      return {
                        ...item,
                        recipe: {
                          ...item.recipe,
                          capabilities: {
                            ...item.recipe.capabilities,
                            canAddToLibrary: false,
                            canRemoveFromLibrary: true,
                          },
                        },
                      }
                    }
                    return item
                  }),
                ]
              }),
            }

            queryClient.setQueryData(key, updatedData)
          })
        }

        const queryDetailKey = useExploreDetailRecipeQuery.getKey({
          recipeId: ids[0] ?? "",
          userUid,
        })

        // Update recipe pinned list
        const recipePinnedListKey = useWorkspacePinInfiniteQuery.getKey({
          type: PinnedItemType.RECIPE,
        })

        const recipePinnedListQueriesDataEntries = queryClient.getQueriesData<InfiniteData<PinnedItems[], number>>({
          queryKey: recipePinnedListKey,
        })

        if (recipePinnedListQueriesDataEntries) {
          recipePinnedListQueriesDataEntries.forEach(([key, pinnedListData]) => {
            if (pinnedListData) {
              const updatedData = {
                ...pinnedListData,
                pages: pinnedListData.pages.map(page => {
                  return [
                    ...page.map(item => {
                      if (ids.includes(item.recipe?.id ?? "")) {
                        return {
                          ...item,
                          recipe: {
                            ...item.recipe,
                            capabilities: {
                              ...item.recipe?.capabilities,
                              canAddToLibrary: false,
                              canRemoveFromLibrary: true,
                            },
                          },
                        }
                      }

                      return item
                    }),
                  ]
                }),
              }

              queryClient.setQueryData(key, updatedData)
            }
          })
        }

        // Update recipe detail explore
        const recipeDetailQueryData = queryClient.getQueryData<ExploreRecipeItem>(queryDetailKey)

        if (recipeDetailQueryData) {
          queryClient.setQueryData(queryDetailKey, {
            ...recipeDetailQueryData,
            capabilities: {
              ...recipeDetailQueryData.capabilities,
              canAddToLibrary: false,
              canRemoveFromLibrary: true,
            },
          })
        }
      },
    }),
  ],
})
