import { IconProps } from "."

export const ShareIcon = (props: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="11" viewBox="0 0 14 11" fill="none" {...props}>
      <path
        d="M11.7945 3.79445L10.4843 2.47965C9.54029 1.53232 9.17025 1.02271 8.65908 1.20287C8.02165 1.42751 8.23149 2.84494 8.23149 3.32346C7.24045 3.32346 6.21011 3.23538 5.23323 3.41891C2.00839 4.02475 1 6.64387 1 9.50033C1.91273 8.85379 2.82456 8.16501 3.92155 7.86536C5.29091 7.49131 6.82026 7.66981 8.23149 7.66981C8.23149 8.1483 8.02165 9.5658 8.65908 9.7904C9.23833 9.99451 9.54029 9.46095 10.4843 8.51362L11.7945 7.19882C12.5982 6.39235 13 5.98911 13 5.49663C13 5.00416 12.5982 4.60092 11.7945 3.79445Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
