import { IconProps } from "."

export const CameraIcon = (props: IconProps) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_2819_15240)">
        <path
          d="M11.333 4C12.8967 4 13.3452 4 13.8839 4.41329C14.0225 4.51969 14.1466 4.64381 14.253 4.78248C14.6663 5.32109 14.6663 6.10295 14.6663 7.66667V10.6667C14.6663 12.5523 14.6663 13.4951 14.0806 14.0809C13.4948 14.6667 12.552 14.6667 10.6663 14.6667H5.33301C3.44739 14.6667 2.50458 14.6667 1.91879 14.0809C1.33301 13.4951 1.33301 12.5523 1.33301 10.6667V7.66667C1.33301 6.10295 1.33301 5.32109 1.7463 4.78248C1.8527 4.64381 1.97682 4.51969 2.11548 4.41329C2.6541 4 3.10262 4 4.66634 4"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M11.3327 4.66683L10.7421 3.19045C10.4873 2.55347 10.2656 1.8309 9.61045 1.50653C9.26095 1.3335 8.84041 1.3335 7.99935 1.3335C7.15828 1.3335 6.73775 1.3335 6.38825 1.50653C5.73309 1.8309 5.51136 2.55347 5.25657 3.19045L4.66602 4.66683"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.3327 9.33333C10.3327 10.622 9.28801 11.6667 7.99935 11.6667C6.71068 11.6667 5.66602 10.622 5.66602 9.33333C5.66602 8.04467 6.71068 7 7.99935 7C9.28801 7 10.3327 8.04467 10.3327 9.33333Z"
          stroke="currentColor"
          strokeWidth="1.5"
        />
        <path
          d="M7.99987 4H8.00586"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2819_15240">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
