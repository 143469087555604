import { FileAssociatedResource, SDStyleTag, SDWorkflowDetail, Tag } from "@/api/sdk"
import AspectRatio from "@/components/AspectRatio"
import AddTagInput from "@/components/Explore/Gallery/GalleryFeatures/AddTagInput"
import ImageUpload from "@/components/ImageUpload"
import { ImageViewWithDelete } from "@/components/ImageUpload/ImageView"
import Input from "@/components/Input"
import { UploadIcon, XIcon } from "@/components/shared/icons"
import Textarea from "@/components/Textarea"
import { useToast } from "@/hooks"
import { googleAnalytics } from "@/lib/gtag"
import Postmate from "@/lib/postmate"
import { cn } from "@/lib/utils"
import { uploadFiles } from "@/queries"
import { handleConvertHEICtoJPG } from "@/utils/convert"
import { mapSeries } from "@/utils/promise"
import { resizeImage } from "@/utils/resize-image"
import classNames from "classnames"
import { AnimatePresence } from "framer-motion"
import { FC, HTMLAttributes, useRef } from "react"

type ComfyUIRecipeInfoProps = HTMLAttributes<HTMLDivElement> & {
  isEditting: boolean
  workflow?: SDWorkflowDetail
  name?: string
  description?: string
  file: File | null
  setFile: (file: File | null) => void
  onDescriptionChanged: (desc: string) => void
  onNameChanged: (name: string) => void
  tags: Tag[]
  setTags: (tags: Tag[]) => void
  preview: string | null
  isUploading: boolean
  setPreview: React.Dispatch<React.SetStateAction<string | null>>
  postmate?: Postmate.ParentAPI
}

export const uploadComfyUIWorkflowFiles = async (files: Blob[], onError?: (err: Error) => void) => {
  const resized = await mapSeries(files, async file => {
    let newFile = files[0]

    if (newFile.type === "image/heic" || newFile.name.endsWith(".heic")) {
      newFile = (await handleConvertHEICtoJPG(newFile)) as File
    }

    return resizeImage(newFile, 1024)
  })

  return uploadFiles({
    files: resized,
    resourceType: FileAssociatedResource.SD_WORKFLOW,
    onError,
  })
}

const ComfyUIRecipeInfo: FC<ComfyUIRecipeInfoProps> = props => {
  const {
    workflow,
    name,
    isEditting,
    className,
    onNameChanged,
    description,
    file,
    setFile,
    onDescriptionChanged,
    postmate,
    tags,
    setTags,
    preview,
    isUploading,
    setPreview,
    ...divProps
  } = props

  const toast = useToast()
  const fileInputThumbnailRef = useRef<HTMLInputElement>(null)
  const disabledInput = !isEditting

  const handleRemoveThumbnail = async () => {
    if (!workflow) return

    if (file && preview) {
      URL.revokeObjectURL(preview)
    }

    setPreview(null)
    setFile(null)
  }

  const handleUploadThumbnail = async (file: Blob) => {
    if (!workflow || !postmate) return

    if (file.size > 10 * 1000 * 1000) {
      toast({
        status: "error",
        title: "File size too large",
        message: ["Input image exceeds 10MB, please scale down the image and try again"],
      })
      return
    }

    setFile(file as File)

    setPreview(URL.createObjectURL(file))

    // const [uploaded] = await uploadComfyUIWorkflowFiles([file])
    // await updateWorkflow({
    //   workflowId: workflow.id,
    //   data: {
    //     thumbnailFileId: uploaded.id,
    //   },
    // })
    //   .then(data => {
    //     setPreview(data.thumbnailUrl)
    //     toast({
    //       status: "success",
    //       title: "Success!",
    //       message: ["Thumbnail is updated successfully!"],
    //       duration: 5000,
    //     })
    //   })
    //   .finally(() => {
    //     setIsUploading(false)
    //   })
  }

  // const { mutate: mutateUntags } = useUnTagsSDWorkflowsMutation()
  // const qc = useQueryClient()
  // const key = useComfyUiWorkflowDetailQuery.getKey({
  //   workflowId: workflow?.id ?? "",
  // })

  const onAddTagSuccess = (tag: Tag) => {
    if (!workflow) return

    // mutateTags({
    //   tagIds: [tag.id],
    //   sdWorkflowIds: [workflow.id],
    // })

    // const prevTags = qc.getQueryData(key) as SDWorkflowDetail | undefined

    // if (prevTags) {
    //   qc.setQueryData(key, {
    //     ...prevTags,
    //     tags: [...(prevTags.tags ?? []), tag],
    //   })
    // }

    googleAnalytics.event({
      action: "click",
      category: "comfy-detail",
      label: `add-tag`,
      params: {
        comfy_id: workflow.id,
        tag_id: tag.id,
        tag_name: tag.name,
      },
    })
  }

  const handleDeleteTag = (tag: SDStyleTag) => {
    if (!workflow) return
    // mutateUntags({
    //   tagIds: [tag.id],
    //   sdWorkflowIds: [workflow.id],
    // })

    // const prevTags = qc.getQueryData(key) as SDWorkflowDetail | undefined

    // if (prevTags) {
    //   qc.setQueryData(key, {
    //     ...prevTags,
    //     tags: prevTags.tags?.filter(t => t.id !== tag.id),
    //   })
    // }

    setTags(tags.filter(t => t.id !== tag.id))
  }

  const isFirstRender = useRef(true)

  // useEffect(() => {
  //   if (!workflow || isFirstRender.current) return

  //   const timer = setTimeout(() => {
  //     if (description === workflow.description) return
  //     updateWorkflow({
  //       workflowId: workflow.id,
  //       data: {
  //         description,
  //       },
  //     })
  //   }, 2500)

  //   return () => clearTimeout(timer)
  // }, [description, isFirstRender.current])

  if (!workflow) return null

  return (
    <div {...divProps} className={cn("flex-0 flex flex-col space-y-4 p-4", className)}>
      <div>
        <p className="font-semibold text-base text-atherGray-100 mb-2">ComfyUI Preview</p>
        <div className="flex items-start">
          <p className="text-xs text-atherGray-300">Please update ComfyUI preview before saving and sharing</p>
        </div>
      </div>
      <div>
        <p className="text-whiteAlpha-900 text-sm pb-1">ComfyUI name</p>
        <Input
          disabled={disabledInput}
          value={name}
          onChange={e => onNameChanged(e.target.value)}
          className={classNames("rounded-md p-2 text-atherGray-300 text-sm", {
            "bg-atherGray-900 border border-atherGray-850": !disabledInput,
            "bg-atherGray-800": disabledInput,
          })}
        />
      </div>
      <div className="flex flex-col gap-1">
        <p className="text-whiteAlpha-900 text-sm">Thumbnail</p>
        <AspectRatio ratio={286 / 160}>
          <ImageUpload
            isLoading={isUploading}
            fileInputRef={fileInputThumbnailRef}
            upload={files => handleUploadThumbnail(files[0])}
            className={classNames("h-full", {
              "border-transparent": !!preview,
              "!cursor-not-allowed": disabledInput,
            })}
            disabled={disabledInput}
          >
            {preview ? (
              <ImageViewWithDelete
                className={classNames("w-full h-full", {
                  "cursor-default": disabledInput,
                })}
                imgClassName="object-cover w-full"
                url={preview}
                alt="Recipe Image"
                onDelete={handleRemoveThumbnail}
                preferThumbnail
                disabled={disabledInput}
              />
            ) : (
              <div
                onDrop={async e => {
                  e.preventDefault()
                  const html = e.dataTransfer.getData("text/html")

                  const src = new DOMParser().parseFromString(html, "text/html").querySelector("img")?.src
                  if (!src) return

                  const file = await fetch(src).then(res => res.blob())
                  if (file) {
                    handleUploadThumbnail(file)
                  }
                }}
                className="flex flex-col items-center justify-center h-full"
              >
                <div
                  className={classNames("p-2 mb-2 rounded-full", {
                    "bg-atherPurple-500": !disabledInput,
                    "bg-atherGray-850": disabledInput,
                  })}
                >
                  <UploadIcon />
                </div>
                <p className="text-atherGray-300 text-center">
                  Upload images or Drag & Drop <br />
                  (max 2048px and less than 10MB)
                </p>
              </div>
            )}
          </ImageUpload>
        </AspectRatio>
      </div>

      <div className="">
        <p className="text-whiteAlpha-900 text-sm pb-1">Tags</p>
        <div
          className={classNames("rounded-md flex flex-wrap w-full min-h-[2.25rem] p-2 gap-1", {
            "bg-atherGray-900 border border-atherGray-850": !disabledInput,
            "cursor-not-allowed bg-atherGray-850": disabledInput,
          })}
        >
          {tags?.map(tag => (
            <div key={tag.id} className="flex items-center px-2 py-0.5 border rounded-lg border-atherGray-800">
              <p className="mr-1 text-atherGray-300 text-sm">{tag.name}</p>
              <button
                className={classNames("flex items-center justify-center", {
                  "cursor-not-allowed": disabledInput,
                  "cursor-pointer": !disabledInput,
                })}
                type="button"
                disabled={disabledInput}
                onClick={() => {
                  handleDeleteTag(tag)
                }}
              >
                <XIcon width={10} height={10} className="text-atherGray-300" />
              </button>
            </div>
          ))}
          <AnimatePresence mode="wait">
            {workflow?.capabilities?.canUpdate && (
              <AddTagInput
                className="bg-atherGray-700"
                tags={tags}
                setTags={setTags as any}
                onTag={tag => {
                  onAddTagSuccess(tag as Tag)
                }}
                disabled={disabledInput}
              />
            )}
          </AnimatePresence>
        </div>
      </div>

      <div className="">
        <p className="text-whiteAlpha-900 text-sm pb-1">Description</p>
        <div
          className={classNames("relative overflow-hidden rounded-md p-1", {
            "bg-atherGray-900 border border-atherGray-850": !disabledInput,
            "bg-atherGray-800": disabledInput,
          })}
        >
          <Textarea
            value={description ?? ""}
            onChange={e => {
              isFirstRender.current = false
              onDescriptionChanged(e.target.value)
            }}
            className={classNames("bg-transparent w-full pr-8 min-h-[5rem] py-1 px-1.5 text-sm", {
              "cursor-not-allowed": disabledInput,
            })}
            placeholder={"Describe your recipe"}
            disabled={disabledInput}
          />
        </div>
      </div>

      {/* <div className="flex flex-col gap-1">
        <p className="font-semibold text-whiteAlpha-900 text-sm">Examples</p>
        <p className="text-atherGray-300 text-xs">You can upload upto 10 example images</p>
        <ImageUpload
          fileInputRef={fileInputThumbnailRef}
          upload={files => Promise.resolve()}
          className={classNames({
            "border-transparent": !!workflow.thumbnailUrl,
            "!cursor-not-allowed": disabledInput,
          })}
          disabled={disabledInput}
        >
          <div className="h-full flex flex-col items-center justify-center">
            <div
              className={classNames("p-2 mb-2 rounded-full", {
                "bg-atherPurple-500": !disabledInput,
                "bg-atherGray-800": disabledInput,
              })}
            >
              <UploadIcon />
            </div>
            <p className="text-atherGray-300 text-center">
              Upload images or Drag & Drop <br />
              (max 2048px and less than 10MB)
            </p>
          </div>
        </ImageUpload>
      </div>
      <div className="grid grid-cols-2 gap-2">
        <AspectRatio ratio={2 / 3} className="relative">
          <ImageViewWithDelete
            className="overflow-hidden flex-shrink-0 h-auto w-auto bg-atherGray-950 border-dashed border border-atherGray-700 rounded-lg"
            url={"https://cdn-artventure.sipher.gg/qiPn1AYKBcgRsNEphefcH.jpeg"}
            onDelete={() => {}}
            preferThumbnail
            disabled={disabledInput}
          />
        </AspectRatio>

        <AspectRatio ratio={2 / 3} className="relative">
          <ImageViewWithDelete
            className="overflow-hidden flex-shrink-0 h-auto w-auto bg-atherGray-950 border-dashed border border-atherGray-700 rounded-lg"
            url={"https://cdn-artventure.sipher.gg/qiPn1AYKBcgRsNEphefcH.jpeg"}
            onDelete={() => {}}
            preferThumbnail
            disabled={disabledInput}
          />
        </AspectRatio>
      </div> */}
    </div>
  )
}

export default ComfyUIRecipeInfo
