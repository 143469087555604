import { cdnPublicFolderUrl, imageExtensionRegex } from "@/constants"
import { cn } from "@/utils/cn"
import { forwardRef, useEffect, useRef, useState } from "react"

interface ImageWebpComponentProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  src?: string
  typeResolution?: "w400" | "w800" | "none"
}

const ImageWebpComponent = forwardRef<HTMLImageElement, ImageWebpComponentProps>(
  ({ src, className, style, onClick, typeResolution = "w400", ...rest }, ref) => {
    const [errorState, setErrorState] = useState(0)
    const prevSrc = useRef<string | undefined>(src)

    const getImageUrl = (src: string | undefined, errorState: number): string => {
      if (errorState === 2 || !src) return `${cdnPublicFolderUrl}/not-found-image.png`
      if (errorState === 1 || src.startsWith('blob:') || typeResolution === "none") return src
      if (src.endsWith(`.${typeResolution}.webp`)) return src
      return `${src.replace(imageExtensionRegex, "")}.${typeResolution}.webp`
    }

    const url = getImageUrl(src, errorState)

    useEffect(() => {
      if (prevSrc.current !== src) {
        setErrorState(0)
        prevSrc.current = src
      }
    }, [src])

    const handleError = () => setErrorState(prev => prev + 1)

    const handleClick = (e: React.MouseEvent<HTMLImageElement>) => {
      if (onClick) {
        e.stopPropagation()
        onClick(e)
      }
    }

    const imageClassName = cn(
      "block",
      {
        "!object-contain": !src || errorState > 0,
        "cursor-pointer": !!onClick,
        "max-w-[5rem]": !src || errorState > 1,
      },
      className,
    )

    const imageStyle = {
      ...style,
      objectPosition: !src || errorState > 0 ? "center" : style?.objectPosition,
    }

    return (
      <img
        ref={ref}
        src={url}
        data-original-src={src}
        loading="lazy"
        onError={handleError}
        onClick={handleClick}
        alt={rest.alt ?? ""}
        className={imageClassName}
        style={imageStyle}
        {...rest}
      />
    )
  },
)

export default ImageWebpComponent
