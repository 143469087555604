"use client"

import { useCallbackOnHotKeys } from "@/hooks"
import { AnimatePresence, motion } from "framer-motion"
import React, { ReactNode, useRef } from "react"
import { RemoveScroll } from "react-remove-scroll"
import ReactPortal from "./ReactPortal"
import { CloseIcon } from "./shared/icons"
import { cn } from "@/lib/utils"

interface ModalProps {
  isOpen: boolean
  onClose: () => void
  title?: string | ReactNode
  children: React.ReactNode
  className?: string
  showCloseButton?: boolean
  containerClassName?: string
  titleClassName?: string
  closeOnOutsideClick?: boolean
  closeOnEscape?: boolean
  closeButtonClassName?: string
  bodyClassName?: string
  scrollRef?: React.RefObject<HTMLDivElement>
  modalRef?: React.RefObject<HTMLDivElement>
  backdropClassName?: string
  isDisabledRemoveScroll?: boolean
}

const variants = {
  backdrop: {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  },
  modal: {
    hidden: { y: "50%", opacity: 0 },
    visible: { y: "0", opacity: 1 },
  },
}

const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  title,
  children,
  backdropClassName,
  className,
  isDisabledRemoveScroll = false,
  titleClassName,
  showCloseButton = true,
  closeOnOutsideClick = false,
  closeOnEscape = true,
  containerClassName,
  bodyClassName,
  closeButtonClassName,
  scrollRef,
  modalRef: externalModalRef,
}) => {
  const internalModalRef = useRef<HTMLDivElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)

  const modalRef = externalModalRef ?? internalModalRef
  const ref = scrollRef ?? containerRef

  useCallbackOnHotKeys("esc", () => closeOnEscape && onClose(), isOpen)

  const renderTitle = () => {
    if (typeof title === "string") {
      return <h2 className="overflow-hidden text-base font-semibold truncate">{title}</h2>
    }
    return title
  }

  return (
    <AnimatePresence mode="popLayout">
      {isOpen && (
        <ReactPortal wrapperId="layout">
          <RemoveScroll style={{ animationDelay: "0s" }} removeScrollBar enabled={!isDisabledRemoveScroll}>
            <motion.div
              variants={variants.backdrop}
              transition={{ duration: 0.25 }}
              initial="hidden"
              animate="visible"
              exit="hidden"
              ref={ref}
              className={cn(
                "fixed inset-0 z-50 flex w-full height-full-screen overflow-y-auto overflow-x-hidden justify-center p-4 modal",
                containerClassName,
              )}
            >
              <div
                className={cn("bg-blackAlpha-700 inset-0 fixed w-full h-full backdrop-blur-md", backdropClassName)}
                onClick={() => !closeOnOutsideClick && onClose()}
              />
              <motion.div
                className={cn(
                  "w-full max-w-xl p-4 my-auto bg-atherGray-900 rounded-2xl relative flex flex-col overflow-hidden",
                  className,
                )}
                variants={variants.modal}
                initial="hidden"
                animate="visible"
                transition={{ duration: 0.25, type: "spring", stiffness: 500, damping: 50 }}
                ref={modalRef}
                onClick={e => e.stopPropagation()}
              >
                {(!!title || showCloseButton) && (
                  <div
                    className={cn(
                      "flex items-center justify-between overflow-hidden",
                      { "mb-2": !!title },
                      titleClassName,
                    )}
                  >
                    {!!title ? renderTitle() : <div />}
                    {showCloseButton && (
                      <button
                        type="button"
                        className={cn(
                          "text-whiteAlpha-900 z-100 rounded-md p-1 hover:bg-atherGray-700 active:bg-atherGray-900",
                          closeButtonClassName,
                        )}
                        onClick={onClose}
                      >
                        <CloseIcon height={16} width={16} />
                      </button>
                    )}
                  </div>
                )}
                <div
                  className={cn("flex flex-col flex-1 overflow-visible text-sm pt-2", bodyClassName)}
                  style={{ WebkitOverflowScrolling: "touch" }}
                >
                  {children}
                </div>
              </motion.div>
            </motion.div>
          </RemoveScroll>
        </ReactPortal>
      )}
    </AnimatePresence>
  )
}

export default Modal
