import client from "@/api/client"
import {
  GetWorkflowsResult,
  RecentWorkflowItem,
  UpdateFreeRecipeTaskDto,
  UpdateRecipeTaskDto,
  WorkflowDetail,
} from "@/api/sdk"
import { useRecentWorkflowInfiniteQuery } from "@/components/Workspace/RecentV2/RecentWorkflow"
import { InfiniteData } from "@tanstack/react-query"
import { createMutation } from "react-query-kit"
import { actionMiddleware } from "../middlewares/actionMiddleware"
import { useWorkspaceWorkflowInfiniteQuery } from "../workspace/useWorkspaceWorkflowInfiniteQuery"
import { useWorkspaceWorkflowDetailNotAuthQuery, useWorkspaceWorkflowDetailQuery } from "../workspace/workflow"

export interface UseUpdateTaskMutationVariables {
  taskId: string
  data: UpdateRecipeTaskDto
}

export interface UseUpdateTaskNotAuthMutationVariables {
  taskId: string
  data: UpdateFreeRecipeTaskDto
}

export const useUpdateTaskMutation = createMutation({
  mutationFn: ({ taskId, data }: UseUpdateTaskMutationVariables) =>
    client.api.recipeTaskControllerUpdateTask(taskId, data).then(res => res.data),
  use: [
    actionMiddleware({
      onSuccess: ({ queryClient, variables }) => {
        // Update workflow list
        const workflowListKey = useWorkspaceWorkflowInfiniteQuery.getKey()

        const workflowListQueriesDataEntries = queryClient.getQueriesData<InfiniteData<GetWorkflowsResult, number>>({
          queryKey: workflowListKey,
        })

        if (workflowListQueriesDataEntries) {
          workflowListQueriesDataEntries.forEach(([key, workflowListData]) => {
            if (workflowListData) {
              const updatedData = {
                ...workflowListData,
                pages: workflowListData.pages.map(page => {
                  return {
                    ...page,
                    workflows: page.workflows.map(workflow => {
                      if (workflow.id === variables.taskId) {
                        return {
                          ...workflow,
                          name: variables.data.name,
                        }
                      }

                      return workflow
                    }),
                  }
                }),
              }

              queryClient.setQueryData(key, updatedData)
            }
          })
        }

        // Update workflow recent list

        const workflowRecentListKey = useRecentWorkflowInfiniteQuery.getKey()

        const workflowRecentListQueriesDataEntries = queryClient.getQueriesData<
          InfiniteData<RecentWorkflowItem[], number>
        >({
          queryKey: workflowRecentListKey,
        })

        if (workflowRecentListQueriesDataEntries) {
          workflowRecentListQueriesDataEntries.forEach(([key, workflowRecentListData]) => {
            if (workflowRecentListData) {
              const updatedData = {
                ...workflowRecentListData,
                pages: workflowRecentListData.pages.map(page => {
                  return [
                    ...page.map(item => {
                      if (item.workflow.id === variables.taskId) {
                        return {
                          ...item,
                          workflow: {
                            ...item.workflow,
                            name: variables.data.name,
                          },
                        }
                      }

                      return item
                    }),
                  ]
                }),
              }

              queryClient.setQueryData(key, updatedData)
            }
          })
        }

        // Update workflow detail
        const workflowDetailKey = useWorkspaceWorkflowDetailQuery.getKey({ workflowId: variables.taskId })

        const workflowDetailData = queryClient.getQueryData<WorkflowDetail>(workflowDetailKey)

        if (workflowDetailData) {
          queryClient.setQueryData(workflowDetailKey, {
            ...workflowDetailData,
            name: variables.data.name ?? workflowDetailData.name,
            status: variables.data.status ?? workflowDetailData.status,
          })
        }
      },
      onSettled: ({ queryClient, variables: { taskId } }) => {
        queryClient.invalidateQueries({
          queryKey: useWorkspaceWorkflowDetailQuery.getKey({
            workflowId: taskId ?? "",
          }),
        })
      },
    }),
  ],
})

export const useUpdateTaskNotAuthMutation = createMutation({
  mutationFn: ({ taskId, data }: UseUpdateTaskNotAuthMutationVariables) =>
    client.api.recipeTaskControllerUpdateFreeTask(taskId, data).then(res => res.data),
  use: [
    actionMiddleware({
      onSuccess: ({ queryClient, variables }) => {
        // Update workflow detail not auth
        const workflowDetailNotAuthKey = useWorkspaceWorkflowDetailNotAuthQuery.getKey({ workflowId: variables.taskId })

        const workflowDetailNotAuthData = queryClient.getQueryData<WorkflowDetail>(workflowDetailNotAuthKey)

        if (workflowDetailNotAuthData) {
          queryClient.setQueryData(workflowDetailNotAuthKey, {
            ...workflowDetailNotAuthData,
            status: variables.data.status ?? workflowDetailNotAuthData.status,
          })
        }
      },
      onSettled: ({ queryClient, variables: { taskId } }) => {
        queryClient.invalidateQueries({
          queryKey: useWorkspaceWorkflowDetailNotAuthQuery.getKey({
            workflowId: taskId ?? "",
          }),
        })
      },
    }),
  ],
})
