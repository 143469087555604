import { RecipeInputType, RecipeStep } from "@/api/sdk"
import _camelCase from "lodash/camelCase"
import { forwardRef, useCallback, useImperativeHandle } from "react"
import { useFieldArray } from "react-hook-form"
import { GraphField } from "../../../ComfyUI/utils/graph"
import IconButton from "../../../IconButton"
import { EyeIcon, UploadIcon5 } from "../../../shared/icons"
import ComfyUIWorkflowSetting, { DropItem, RecipeForm, RecipeInputFieldName } from "./ComfyUIWorkflowSetting"
import AddInputPopover from "./AddInputPopover"
import { useDrop } from "react-dnd"
import classNames from "classnames"

export type ComfyUIWorkflowSettingsProps = {
  graphInputs: GraphField[]
  onSelect: (comfyKey: string) => void
  onPublish: () => void
  form: RecipeForm
  isDraft?: boolean
  onPreview?: () => void
}

export type ComfyUIWorkflowSettingsRef = {
  setSettings: (steps: RecipeStep[]) => void
  validateSettings: (shouldTrigger?: boolean) => Promise<RecipeStep[] | undefined>
}

const ComfyUIWorkflowSettings = forwardRef<ComfyUIWorkflowSettingsRef, ComfyUIWorkflowSettingsProps>((props, ref) => {
  const { graphInputs: graphInputs, onSelect, onPublish, form, onPreview } = props

  const { fields, append, remove, move, insert } = useFieldArray({
    control: form.control,
    name: "inputs",
  })

  const {
    fields: advancedFields,
    append: appendAdvanced,
    remove: removeAdvanced,
    move: moveAdvanced,
    insert: insertAdvanced,
  } = useFieldArray({
    control: form.control,
    name: "advancedInputs",
  })

  const handleAddInput = useCallback(
    (prefix: RecipeInputFieldName, type: RecipeInputType, comfyKey?: string) => {
      const input = { type, comfyKey: comfyKey ?? "", name: "", key: "", isNew: true }
      switch (type) {
        case RecipeInputType.Prompt:
          input.name = "Prompt"
          break
        case RecipeInputType.Model:
          input.name = "Model"
          break
        case RecipeInputType.Style:
          input.name = "Style"
          break
        case RecipeInputType.Image:
          input.name = "Input Image"
          break
        case RecipeInputType.Lora:
        case RecipeInputType.LoraSimple:
          input.name = "Lora"
          break
        case RecipeInputType.Ratio:
          input.name = "Aspect Ratio"
          break
      }

      if (prefix === "inputs") {
        append(input)
      } else {
        appendAdvanced(input)
      }
    },
    [append, appendAdvanced],
  )

  const handleSwap = useCallback(
    (src: DropItem, dst: DropItem) => {
      if (src.fieldPrefix === dst.fieldPrefix) {
        if (src.fieldPrefix === "inputs") {
          move(src.index, dst.index)
        } else {
          moveAdvanced(src.index, dst.index)
        }
      } else {
        const values = form.getValues()
        const item = values[src.fieldPrefix][src.index]

        // remove from src
        if (src.fieldPrefix === "inputs") {
          remove(src.index)
        } else {
          removeAdvanced(src.index)
        }

        // append to dst
        if (dst.fieldPrefix === "inputs") {
          insert(dst.index, item)
        } else {
          insertAdvanced(dst.index, item)
        }
      }
    },
    [move, moveAdvanced, append, appendAdvanced, remove, removeAdvanced],
  )

  const handleSetSettings = useCallback(
    (steps: RecipeStep[]) => {
      const inputs = steps[0]?.inputs ?? []
      const advancedInputs = steps.slice(1).flatMap(s => s.inputs)

      const values = form.getValues()
      if (JSON.stringify(values.inputs) !== JSON.stringify(inputs)) {
        form.setValue("inputs", inputs)
      }
      if (JSON.stringify(values.advancedInputs) !== JSON.stringify(advancedInputs)) {
        form.setValue("advancedInputs", advancedInputs)
      }
    },
    [form],
  )

  const handleValidateSettings = useCallback(
    async (shouldTrigger = true) => {
      if (shouldTrigger) {
        const valid = await form.trigger(undefined, { shouldFocus: true })
        if (!valid) return
      }

      const step = form.getValues()

      step.inputs.forEach(input => {
        input.key = _camelCase(input.name)
        delete input.isNew
      })
      step.advancedInputs.forEach(input => {
        input.key = _camelCase(input.name)
        delete input.isNew
      })

      const steps = [
        {
          index: 1,
          name: "Inputs",
          inputs: step.inputs,
          advancedInputs: undefined,
        },
      ]
      if (step.advancedInputs.length > 0) {
        steps.push({
          index: 2,
          name: "Advanced Parameters",
          inputs: step.advancedInputs,
          advancedInputs: undefined,
        })
      }

      return steps
    },
    [form],
  )

  const handleSave = async () => {
    const valid = await form.trigger()
    if (!valid) return

    const step = form.getValues()
    step.inputs.forEach(input => {
      if (input.type === RecipeInputType.Style) {
        input.weightComfyKey = input.comfyKey.replace("data", "weight")
      }

      input.key = _camelCase(input.name)
    })

    // onSave([step])

    return [step]
  }

  const [{ isOver: isOverInputs }, dropRefInputs] = useDrop(
    () => ({
      accept: "comfyUiWorkflowInput",
      drop: (item: DropItem) => {
        handleSwap(item, { index: 0, fieldPrefix: "inputs" })
      },
      collect: monitor => ({
        isOver: !!monitor.isOver(),
      }),
    }),
    [handleSwap],
  )

  const [{ isOver: isOverAdvanced }, dropRefAdvanced] = useDrop(
    () => ({
      accept: "comfyUiWorkflowInput",
      drop: (item: DropItem) => {
        handleSwap(item, { index: 0, fieldPrefix: "advancedInputs" })
      },
      collect: monitor => ({
        isOver: !!monitor.isOver(),
      }),
    }),
    [handleSwap],
  )

  useImperativeHandle(ref, () => {
    return {
      setSettings: handleSetSettings,
      validateSettings: handleValidateSettings,
    }
  }, [handleSetSettings, handleValidateSettings])

  return (
    <>
      <div className="p-4">
        <p className="font-semibold mb-2">Convert to Recipe</p>
        <div className="flex items-center space-x-4 max-w-[36rem]">
          <IconButton
            disabled={fields.length === 0 && advancedFields.length === 0}
            onClick={onPreview}
            className="w-full"
            colorScheme="secondary"
          >
            <EyeIcon width={20} height={20} />
            <span className="ml-1">Preview</span>
          </IconButton>
          <IconButton
            disabled={fields.length === 0}
            onClick={async () => {
              const result = await handleSave()

              if (result && result?.length === 0) return

              onPublish()
            }}
            className="w-full"
          >
            <UploadIcon5 width={20} height={20} />
            <span className="ml-1">Publish</span>
          </IconButton>
        </div>
      </div>

      <div className="flex-1 overflow-auto flex flex-col w-full">
        <div className="flex flex-col gap-1 p-4 border-t border-atherGray-800">
          <label className="font-semibold text-sm">Basic Inputs</label>
          <p className="text-atherGray-300 text-sm">
            Put the most basic inputs at the top section. Require at least one.
          </p>
          <div className="pt-4 flex">
            <AddInputPopover onAddInput={type => handleAddInput("inputs", type)} />
          </div>
        </div>
        <fieldset className="w-full flex flex-col px-4">
          {fields.length === 0 && (
            <div
              ref={ref => {
                dropRefInputs(ref)
              }}
              className={classNames("h-4", {
                "bg-atherGray-800 py-4 mb-4": isOverInputs,
              })}
            ></div>
          )}
          {fields.map((field, index) => (
            <ComfyUIWorkflowSetting
              key={field.id}
              field={field}
              fieldPrefix="inputs"
              graphInputs={graphInputs}
              form={form}
              index={index}
              onSelect={onSelect}
              onRemove={() => remove(index)}
              onSwap={handleSwap}
            />
          ))}
        </fieldset>
        <div className="flex flex-col gap-1 p-4 border-t border-atherGray-800">
          <label className="font-semibold text-sm">Advanced Inputs</label>
          <p className="text-atherGray-300 text-sm">More advanced inputs that can left default.</p>
          <div className="pt-4 flex">
            <AddInputPopover onAddInput={type => handleAddInput("advancedInputs", type)} />
          </div>
        </div>
        <fieldset className="w-full flex flex-col px-4">
          {advancedFields.length === 0 && (
            <div
              ref={ref => {
                dropRefAdvanced(ref)
              }}
              className={classNames("h-4", {
                "bg-atherGray-800 py-4 mb-4": isOverAdvanced,
              })}
            ></div>
          )}
          {advancedFields.map((field, index) => (
            <ComfyUIWorkflowSetting
              key={field.id}
              field={field}
              fieldPrefix="advancedInputs"
              graphInputs={graphInputs}
              form={form}
              index={index}
              onSelect={onSelect}
              onRemove={() => removeAdvanced(index)}
              onSwap={handleSwap}
            />
          ))}
        </fieldset>
      </div>
    </>
  )
})

export default ComfyUIWorkflowSettings
