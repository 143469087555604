import useCustomRouter from "@/hooks/useCustomRouter"

export const useQueryState = (key: string, defaultValue: string) => {
  const router = useCustomRouter()

  // Function to update the query parameter
  const setValue = (newValue: string) => {
    const newQuery = {
      ...router.query,
      [key]: newValue,
    }

    router.replace(
      {
        pathname: router.pathname,
        query: newQuery,
      },
      undefined,
      { shallow: true },
    )
  }

  // Get the value directly from router.query
  const value = router.query[key] ?? defaultValue

  return [value, setValue]
}
