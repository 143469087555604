import client from "@/api/client"
import IconButton from "@/components/IconButton"
import { AlertIcon } from "@/components/shared/icons"
import { useAuthenticatedQuery } from "@/hooks"
import { googleAnalytics } from "@/lib/gtag"
import { useDrawerNotification } from "@/stores"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { toast } from "react-toastify"

const NotificationBell = ({ onClick }: { onClick?: () => void }) => {
  const qc = useQueryClient()
  const [isOpen, setIsOpen] = useDrawerNotification(state => [state.isOpen, state.setIsOpen])

  const { data: newNotifCount = 0 } = useAuthenticatedQuery(["new-notifications-count"], () =>
    client.api.notificationControllerGetNewNotificationsCount().then(res => res.data),
  )

  const { mutate: clearNotificationCount } = useMutation({
    mutationFn: () => client.api.notificationControllerClearNewNotificationsCount(),
    onSuccess: () => {
      qc.setQueriesData({ queryKey: ["new-notifications-count"] }, 0)
    },
  })

  return (
    <div className="">
      <IconButton
        onClick={() => {
          onClick?.()
          setIsOpen(!isOpen)

          googleAnalytics.event({
            action: "click",
            category: "navbar",
            label: `toggle_notification_drawer`,
            value: 1,
          })

          if (!isOpen) {
            toast.dismiss()

            qc.invalidateQueries({
              queryKey: ["notifications"],
            })

            if (newNotifCount > 0) clearNotificationCount()
          }
        }}
        title="Notification"
        className="relative overflow-visible px-2"
        colorScheme="transparent"
      >
        <AlertIcon width={20} height={20} className="text-atherGray-300" />
        {newNotifCount > 0 && (
          <div className="absolute min-w-[1.25rem] h-5 right-0 top-0 font-semibold bg-atherPurple-500 text-xs  rounded-full flex justify-center items-center">
            {newNotifCount > 9 ? "9+" : newNotifCount}
          </div>
        )}
      </IconButton>
    </div>
  )
}

export default NotificationBell
