import { cdnPublicFolderUrl } from "@/constants"
import useCustomRouter from "@/hooks/useCustomRouter"
import IconButton from "../IconButton"
import { SearchIcon } from "../shared/icons"

interface NotFoundUIProps {
  description?: string
  redirectTo?: string
  titleAction?: string
  onClick?: () => void
}

const NotFoundUI = ({ description = "Explore", redirectTo = "/explore", titleAction, onClick }: NotFoundUIProps) => {
  const router = useCustomRouter()

  return (
    <div className="flex items-center justify-center flex-1 w-full px-0 md:px-6 bg-black">
      <div className="relative w-full max-w-[40rem] flex flex-col items-center">
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-3/4">
          <img src={`${cdnPublicFolderUrl}/not-found.webp`} alt="Not Found" width={165} height={247} />
        </div>
        <div className="w-full flex flex-col items-center z-[1]">
          <SearchIcon className="w-10 h-10 mb-2" />
          <h3 className="text-2xl font-semibold mb-4">{`<Page> not found`}</h3>
          <p className="text-sm text-atherGray-300 text-center mb-4">
            Sorry, we couldn’t find this page.
            <br /> But don’t worry, you can find plenty of other things on{" "}
            <span className="text-atherGray-100 font-semibold">{description}</span>
          </p>
          <IconButton
            onClick={() => {
              if (onClick) {
                onClick()
                return
              }

              router.push(redirectTo)
            }}
            className="bg-atherPurple-500 hover:bg-atherPurple-500 active:bg-atherPurple-500 text-[#EFE8FD] px-4 mb-2"
          >
            Back to {titleAction || description}
          </IconButton>
        </div>
      </div>
    </div>
  )
}

export default NotFoundUI
