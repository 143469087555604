import { IconProps } from "."

export const CircleLockIcon = (props: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M3.33301 9.99967C3.33301 7.42235 5.42235 5.33301 7.99967 5.33301C10.577 5.33301 12.6663 7.42235 12.6663 9.99967C12.6663 12.577 10.577 14.6663 7.99967 14.6663C5.42235 14.6663 3.33301 12.577 3.33301 9.99967Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M11 6.33301V4.33301C11 2.67615 9.65685 1.33301 8 1.33301C6.34315 1.33301 5 2.67615 5 4.33301V6.33301"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path d="M8 10H8.00599" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
