import IconButton from "@/components/IconButton"
import Popover from "@/components/Popover"
import { InfoIcon, XIcon } from "@/components/shared/icons"
import classNames from "classnames"
import React, { useState } from "react"

export const shortCutItems = [
  {
    id: 1,
    description: "Focus on Prompting",
    hotkey: "P",
    fullActions: true,
  },
  {
    id: 2,
    description: "Drop Setting bar",
    hotkey: "D",
    fullActions: true,
  },
  {
    id: 3,
    description: "Change prompt style",
    hotkey: "↓↑",

    fullActions: true,
  },
  {
    id: 4,
    description: "Change ratio",
    hotkey: "←→",
    fullActions: false,
  },
  {
    id: 5,
    description: "Generate",
    hotkey: "ENTER",
    fullActions: true,
  },
  {
    id: 6,
    description: "Close drop down",
    hotkey: "ESC",
    fullActions: true,
  },
]

const ShortcutSuggest = () => {
  const [isOpenShortCutMenu, setOpenShortCutMenu] = useState<boolean>(false)

  return (
    <div>
      <Popover
        align="start"
        isOpen={isOpenShortCutMenu}
        setIsOpen={setOpenShortCutMenu}
        trigger={
          <div className="flex">
            <div className="text-atherGray-300 relative font-semibold flex items-center">
              Shortcut
              <span className="text-atherGray-800 ml-1">
                <InfoIcon width={16} height={16} />
              </span>
            </div>
          </div>
        }
      >
        <div
          className={
            "text-sm w-[20rem] text-atherGray-300 bg-atherGray-900 border relative border-atherGray-850 p-2 rounded-2xl space-y-1"
          }
        >
          <div className="px-2 text-atherGray-0">
            <div className="flex justify-between">
              <h1 className="font-semibold text-base mb-2">Shortcut</h1>
              <IconButton
                onClick={e => {
                  e.stopPropagation()
                  setOpenShortCutMenu(false)
                }}
                colorScheme="transparent"
                className="p-2 min-h-0 absolute top-1 right-1"
              >
                <XIcon width={16} height={16} />
              </IconButton>
            </div>
            <div className="flex flex-col divide-y divide-atherGray-800">
              {shortCutItems.map(item => (
                <div key={item.id} className="flex justify-between items-center py-1">
                  <p className="text-sm">{item.description}</p>
                  <div className={classNames("text-base")}>{item.hotkey}</div>
                </div>
              ))}
            </div>
            <div className="p-2 text-atherGray-300 text-xs">
              *If you are focusing on the prompt box, and open the setting bar.{" "}
              <span className="font-semibold">Press ESC twice to close the drop down</span>
            </div>
          </div>
        </div>
      </Popover>
    </div>
  )
}

export default ShortcutSuggest
