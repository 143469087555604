import { PromptSortOptions } from "@/api/sdk"
import UserCreated from "@/components/Explore/Gallery/UserCreated"
import IconButton from "@/components/IconButton"
import Popover from "@/components/Popover"
import { CheckCircleIcon, SortIcon } from "@/components/shared/icons"
import { useDebounce } from "@/hooks"
import { googleAnalytics } from "@/lib/gtag"
import { useAuth } from "@/providers/authContext"
import { useWorkspacePromptsInfiniteQuery } from "@/queries"
import { useWorkspaceSearchHistory } from "@/stores"
import { cn } from "@/utils/cn"
import classNames from "classnames"
import Link from "next/link"
import { useState } from "react"
import SearchBar from "../../Workspace/FoldersV2/SearchBar"

interface PromptsTabActionsProps {
  sort?: PromptSortOptions
  setSort: (sort: PromptSortOptions) => void
  className?: string
  onConfirmSearch?: (search: string) => void
  onRemoveHistory?: (text: string) => void
  selectionMode: boolean
  toggleSelectionMode: (v: boolean) => void
}

export const promptSortOptions: { sortBy: PromptSortOptions; label: string }[] = [
  {
    sortBy: PromptSortOptions.NEWEST,
    label: "Newest",
  },
  {
    sortBy: PromptSortOptions.OLDEST,
    label: "Oldest",
  },
  {
    sortBy: PromptSortOptions.A_TO_Z,
    label: "Name A - Z",
  },
  {
    sortBy: PromptSortOptions.Z_TO_A,
    label: "Name Z - A",
  },
]

const PromptsTabActions = ({
  sort: sortBy,
  setSort,
  className,
  onConfirmSearch,
  onRemoveHistory,
  selectionMode,
  toggleSelectionMode,
}: PromptsTabActionsProps) => {
  const { user } = useAuth()

  const history = useWorkspaceSearchHistory(s =>
    s.history.filter(item => item.uid === user?.uid && item.location === "tools/prompts"),
  )

  const [search, setSearch] = useState("")
  const debouncedSearch = useDebounce(search, 350)

  const [sortDropdown, setSortDropdown] = useState(false)

  const {
    flattenData: previewResult,
    isLoading,
    total,
  } = useWorkspacePromptsInfiniteQuery({
    variables: {
      take: 5,
      searchTerm: debouncedSearch,
      sortBy,
    },
    enabled: !!debouncedSearch && !!user?.uid,
  })

  const buildPreviewResult = (search: string) => {
    if (isLoading) {
      return (
        <div className="p-1">
          <p className="text-atherGray-300 text-sm">Searching...</p>
        </div>
      )
    }

    if (!isLoading && previewResult?.length === 0) {
      return (
        <div className="p-1">
          <p className="text-atherGray-300 text-sm">No results found</p>
        </div>
      )
    }
    if (previewResult && previewResult.length > 0) {
      const resultLeft = total - previewResult.length

      return (
        <div
          className="flex flex-col space-y-2  overflow-y-auto overflow-x-hidden max-h-[55vh]"
          onMouseEnter={e => {
            e.stopPropagation()
            e.preventDefault()
          }}
        >
          {previewResult?.map(prompt => (
            <Link
              key={prompt.id}
              className="flex w-full rounded-2xl items-center hover:bg-atherGray-850 p-1 px-2 cursor-pointer"
              href={`/workspace/tools/prompts?promptId=${prompt.id}`}
            >
              <div className="overflow-hidden flex-1">
                <p className="text-sm line-clamp-1 font-semibold mb-1">{prompt.name}</p>
              </div>
            </Link>
          ))}
          {resultLeft > 0 && (
            <button
              className="text-xs font-semibold text-atherGray-300 hover:text-atherGray-0 hover:underline text-left"
              onClick={() => onConfirmSearch?.(search)}
            >
              ...and {resultLeft} other {resultLeft > 1 ? "results" : "result"}
            </button>
          )}
        </div>
      )
    }

    return null
  }

  const handleSetSort = (newSortBy: PromptSortOptions) => {
    if (newSortBy === sortBy) return

    setSort(newSortBy)
    setSortDropdown(false)
  }

  return (
    <div className={cn("flex flex-1 justify-end md:space-x-2", className)}>
      <SearchBar
        search={search}
        setSearch={setSearch}
        inputClassName="md:w-80"
        onConfirmSearch={onConfirmSearch}
        onClear={() => onConfirmSearch?.("")}
        history={history}
        previewResultBuilder={buildPreviewResult}
        onClearSearchHistory={value => onRemoveHistory?.(value.text)}
      />
      <IconButton
        colorScheme="transparent"
        className={cn(
          "rounded-2xl p-2 px-3 hover:bg-atherGray-850 flex items-center text-atherGray-300 transition-all",
          {
            "bg-atherGray-850 text-atherGray-0": selectionMode,
          },
        )}
        onClick={() => {
          googleAnalytics.event({
            action: "click",
            category: "filter",
            label: `styles_multi_select`,
          })
          toggleSelectionMode(!selectionMode)
        }}
      >
        <CheckCircleIcon width={20} height={20} checked={selectionMode} />
        <span className="font-normal ml-1 text-sm hidden md:block">Multi-select</span>
      </IconButton>
      <Popover
        isOpen={sortDropdown}
        setIsOpen={setSortDropdown}
        trigger={
          <IconButton
            colorScheme="transparent"
            className={cn("rounded-2xl px-2 hover:bg-atherGray-85", {
              "bg-atherGray-850": sortDropdown,
              "border border-atherPurple-500": sortBy && sortBy !== PromptSortOptions.NEWEST,
            })}
          >
            <SortIcon />
          </IconButton>
        }
        align="end"
        className="origin-top-right"
      >
        <div className="p-3 rounded-2xl bg-atherGray-900 border border-atherGray-800 flex w-40 flex-col space-y-2">
          {promptSortOptions.map(option => (
            <IconButton
              onClick={() => handleSetSort(option.sortBy)}
              key={option.label}
              className={classNames(
                "flex items-center text-left hover:bg-atherGray-850 py-2 justify-start px-4 active:bg-atherGray-800 bg-transparent w-full cursor-pointer rounded-xl",
                {
                  "bg-atherGray-850 text-atherGray-0": sortBy === option.sortBy,
                  "text-atherGray-300": sortBy !== option.sortBy,
                },
              )}
            >
              {option.label}
            </IconButton>
          ))}
        </div>
      </Popover>
    </div>
  )
}

export default PromptsTabActions
