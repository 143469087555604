import DateDistanceToNow from "@/components/DateDistanceToNow"
import { cdnUrl } from "@/constants"
import { googleAnalytics } from "@/lib/gtag"
import { cn } from "@/utils/cn"
import classNames from "classnames"
import Link from "next/link"
import { useState } from "react"
import { twMerge } from "tailwind-merge"

interface UserCreatedProps {
  picture?: string
  name?: string
  email?: string
  createdAt?: string
  className?: string
  nameClassName?: string
  description?: string
  descriptionClassName?: string
  createdAtClassName?: string
  username?: string
  size?: "sm" | "md" | "xs" | "xss"
  isMe?: boolean
  hiddenName?: boolean
  imageClassName?: string
  children?: React.ReactNode
}

const UserCreated = ({
  picture = `https://${cdnUrl}/resources/default-avatar.png`,
  name,
  createdAt,
  nameClassName,
  imageClassName,
  email,
  className,
  description,
  createdAtClassName,
  descriptionClassName,
  children,
  size = "sm",
  hiddenName,
  isMe,
  username,
}: UserCreatedProps) => {
  const [isError, setIsError] = useState(false)
  const sizeImg = size === "xss" ? "w-4 h-4" : size === "xs" ? "w-5 h-5" : size === "sm" ? "w-8 h-8" : "w-10 h-10"
  const sizeFont =
    size === "xss" ? "text-[0.65rem]" : size === "xs" ? "text-xs" : size === "sm" ? "text-sm" : "text-base"

  const renderBody = () => {
    if (picture === `https://${cdnUrl}/resources/default-avatar.png`) {
      return (
        <img
          className={cn("object-cover rounded-full overflow-hidden", sizeImg, imageClassName)}
          src={picture}
          alt={name ?? ""}
          onError={() => setIsError(true)}
        />
      )
    }

    return picture && !isError ? (
      <img
        className={cn("object-cover rounded-full overflow-hidden", sizeImg, imageClassName)}
        src={picture}
        alt={name ?? ""}
        onError={() => setIsError(true)}
      />
    ) : (
      <div
        className={cn("bg-atherGray-100 p-2 flex items-center justify-center rounded-full", sizeImg, imageClassName)}
      >
        <span
          className={classNames("text-black font-semibold", {
            "text-xs": size === "xss",
            "text-sm": size === "xs",
            "text-base": size === "sm",
          })}
        >
          {name && name[0].toUpperCase()}
        </span>
      </div>
    )
  }

  return (
    <div className={twMerge("flex items-center", className)}>
      {username ? (
        <Link
          onClick={e => {
            e.stopPropagation()

            googleAnalytics.handleCategoryEvent({
              action: "click",
              params: {
                action: "View profile",
                profile_username: username,
              },
            })

            if (username === "GAIA") {
              e.preventDefault()
            }
          }}
          href={`/profile/${username}`}
          shallow
          className="block"
        >
          {renderBody()}
        </Link>
      ) : (
        renderBody()
      )}
      {name && !hiddenName && (
        <div
          className={classNames("flex flex-col flex-1 overflow-hidden", {
            "ml-2": size !== "xss" && size !== "xs",
            "ml-1": size === "xs" || size === "xss",
          })}
        >
          <p className={cn("text-sm truncate", sizeFont, nameClassName)}>{isMe ? `${name} (me)` : name}</p>
          {description && (
            <p className={cn("text-xs text-atherGray-100 truncate", descriptionClassName)}>{description}</p>
          )}
          {createdAt && (
            <DateDistanceToNow
              className={cn("text-atherGray-500 text-xs truncate", createdAtClassName)}
              date={createdAt}
            />
          )}
          {children}
        </div>
      )}
    </div>
  )
}

export default UserCreated
