import classNames from "classnames"
import { memo } from "react"
import { twMerge } from "tailwind-merge"
import { googleAnalytics } from "@/lib/gtag"
import { DeleteIcon, FlowIcon, FlowSquareIcon } from "@/components/shared/icons/"
import TextCopy from "@/components/TextCopy"
import { formatValueParams } from "@/components/Workspace/Workflows/WorkflowsChaining/ChainItem"

export interface ImageAttributeProps {
  label?: string
  value: string | null
  className?: string
  onDeleteTag?: () => void
  onClick?: () => void
  onUpdateSearch?: (value: string) => void
  hiddenCopy?: boolean
  isEdit?: boolean
  searchQuery: string
  size?: number
  disableCursor?: boolean
}

const PromptItem = ({
  paragraph,
  index,
  label,
  size = 14,
  value,
  onUpdateSearch,
  hiddenCopy,
  disableCursor,
}: {
  paragraph: string
  index: number
  label?: string
  value: string
  size?: number
  onUpdateSearch?: (value: string) => void
  hiddenCopy?: boolean
  disableCursor?: boolean
}) => {
  return (
    <div
      onClick={e => {
        e.preventDefault()
        e.stopPropagation()

        googleAnalytics.handleCategoryEvent({
          action: "click",
          params: {
            action: "Copy Prompt",
          },
        })

        if (hiddenCopy && !onUpdateSearch) {
          navigator.clipboard.writeText(paragraph ?? "")
          return
        }

        onUpdateSearch && onUpdateSearch(paragraph)
      }}
      className={classNames("border border-atherGray-800 px-2 py-1.5 rounded-lg flex items-center relative", {
        "cursor-default": disableCursor,
        "cursor-pointer hover:border-atherGray-700": !disableCursor,
      })}
      key={index}
    >
      {paragraph}
      {!hiddenCopy && <TextCopy className="ml-1" value={paragraph} />}
    </div>
  )
}

const ImageAttribute = ({
  label,
  value,
  className,
  onDeleteTag: onDelete,
  onClick,
  onUpdateSearch,
  hiddenCopy = false,
  isEdit = false,
  size = 14,
  searchQuery,
  disableCursor,
}: ImageAttributeProps) => {
  const handleSelectTag = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation()

    if (disableCursor) return

    if (hiddenCopy && !onClick) {
      googleAnalytics.handleCategoryEvent({
        action: "click",
        params: {
          action: "Copy attribute",
        },
      })

      navigator.clipboard.writeText(`${label}: ${value}`)

      return
    }

    onClick && onClick()
  }

  const handleStartDelete = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!onDelete) return
    e.preventDefault && e.preventDefault()
  }

  const handleDelete = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()

    onDelete && onDelete()
  }

  if (!value) return null

  const parsePrompt = (paragraph: string) => {
    const formatParagraph = paragraph.split(",")

    return (
      <div className="flex flex-wrap gap-1 p-1 rounded-lg bg-atherGray-950">
        {formatParagraph
          .filter(i => i)
          .map((paragraph, index) => (
            <PromptItem
              key={index}
              paragraph={paragraph}
              size={size}
              index={index}
              label={label}
              value={value}
              onUpdateSearch={onUpdateSearch}
              hiddenCopy={hiddenCopy}
              disableCursor={disableCursor}
            />
          ))}
      </div>
    )
  }
  return (
    <div
      className={twMerge(
        classNames(
          " bg-atherGray-900 rounded-lg flex items-center max-w-full overflow-hidden relative",
          {
            "cursor-pointer hover:border-atherGray-700": !disableCursor,
            "cursor-default": disableCursor,
            "px-2 py-1.5 border border-atherGray-800": label !== "Prompt" && label !== "Negative Prompt",
            "bg-atherPurple-500": label === "Macro" || label === "ComfyUI",
            "border border-atherPurple-500 hover:border-atherPurple-500": label === "Recipe",
          },
          className,
        ),
      )}
      onClick={handleSelectTag}
      onContextMenu={handleStartDelete}
    >
      {label === "Macro" && <FlowIcon className="mr-1 w-3 h-3" />}
      {label === "ComfyUI" && <FlowSquareIcon className="mr-1 w-3 h-3" />}
      <div className="text-xs flex-1 overflow-hidden">
        {label !== "Prompt" && label !== "Negative Prompt" && label ? `${label}: ` : ""}
        {label === "Prompt" || label === "Negative Prompt" ? parsePrompt(value) : formatValueParams(label ?? "", value)}
      </div>
      {!hiddenCopy &&
        label !== "Macro" &&
        label !== "ComfyUI" &&
        label !== "Recipe" &&
        label !== "Prompt" &&
        label !== "Negative Prompt" &&
        !label?.includes("Step ") && <TextCopy className="ml-1" value={value} />}
      {isEdit && (
        <div className="flex overflow-hidden">
          <button type="button" onClick={handleDelete}>
            <DeleteIcon className="text-red-500 w-3 h-3  ml-1" />
          </button>
        </div>
      )}
    </div>
  )
}

export default memo(ImageAttribute)
