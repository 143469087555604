import { RecentRecipeItem as RecentRecipeItemType } from "@/api/sdk"
import React, { memo } from "react"
import RecentAccordion from "../RecentAccordion"
import RecipeCard from "../../Recipes/RecipeCard"

interface RecentRecipeItemProps {
  name: string
  recent: RecentRecipeItemType[]
  layout: "grid" | "list"
}

const RecentRecipeItem = ({ name, recent, layout }: RecentRecipeItemProps) => {
  if (recent.length === 0) return null
  return (
    <RecentAccordion name={name} layout={layout}>
      {recent?.map(recipe => {
        return (
          <div key={recipe.id}>
            <RecipeCard recipe={recipe.recipe} accessedAt={recipe.accessedAt} layout={layout} />
          </div>
        )
      })}
    </RecentAccordion>
  )
}

export default memo(RecentRecipeItem)
