import { cdnPublicFolderUrl } from "@/constants"

const logoImage = `${cdnPublicFolderUrl}/watermark.png`

export const watermark = async (url: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    const canvas = document.createElement("canvas")
    const context = canvas.getContext("2d")
    const logo = new Image()

    let logoLoaded = false
    let imgLoaded = false

    img.onload = () => {
      imgLoaded = true
      drawImages()
    }

    logo.onload = () => {
      logoLoaded = true
      drawImages()
    }

    const drawImages = () => {
      if (!context) {
        return
      }

      if (logoLoaded && imgLoaded) {
        canvas.width = img.width
        canvas.height = img.height

        // Draw original image
        context.drawImage(img, 0, 0, img.width, img.height)

        // Find the smallest dimension of img
        const smallestDimension = Math.min(img.width, img.height) * 0.06

        const scaleFactor = smallestDimension / Math.min(logo.width, logo.height)

        // Calculate new dimensions for logo
        const newWidth = logo.width * scaleFactor
        const newHeight = logo.height * scaleFactor
        const padding = 24 * scaleFactor

        context.drawImage(logo, img.width - newWidth - padding, img.height - newHeight - padding, newWidth, newHeight)

        resolve(canvas.toDataURL())
      }
    }
    // To prevent tainted canvas
    img.crossOrigin = "anonymous"
    logo.crossOrigin = "anonymous"

    img.onerror = reject
    img.src = url
    logo.src = logoImage
  })
}
