import { cdnPublicFolderUrl } from "@/constants"
import { googleAnalytics } from "@/lib/gtag"
import classNames from "classnames"
import Link from "next/link"
import { twMerge } from "tailwind-merge"

interface AppLogoProps {
  className?: string
  href?: string
  size?: number
  textLogo?: boolean
  disabled?: boolean
}

const AppLogo = ({ className, href, size = 0.6, textLogo, disabled }: AppLogoProps) => {
  const handleLogoClick = () => {
    googleAnalytics.event({
      action: "click",
      category: "sidebar_alpha",
      label: "logo",
      value: 1,
    })
  }

  return (
    <div
      onClick={handleLogoClick}
      className={twMerge(classNames("flex items-center w-full justify-start px-6 cursor-pointer space-x-2", className))}
    >
      <Link
        onClick={e => {
          if (disabled) {
            e.preventDefault()
          }
        }}
        href={href ?? "/"}
        target="_parent"
        className="relative p-1 rounded-full"
      >
        <img
          src={textLogo ? `${cdnPublicFolderUrl}/text-logo.png` : `${cdnPublicFolderUrl}/logo.png`}
          alt="GAIA Logo"
          width={158 * size}
          height={48 * size}
          className="z-10 block"
        />
      </Link>
    </div>
  )
}

export default AppLogo
