import { IconProps } from "."

export const OutlinedInfoIcon = (props: IconProps) => {
  return (
    <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g id="elements">
        <path
          id="Ellipse 1334"
          d="M21.9287 11C21.9287 5.47715 17.2904 1 11.5688 1C5.84725 1 1.20898 5.47715 1.20898 11C1.20898 16.5228 5.84725 21 11.5688 21C17.2904 21 21.9287 16.5228 21.9287 11Z"
          stroke="currentColor"
          strokeWidth="1.5"
        />
        <path
          id="Vector 2612"
          d="M11.8202 16V11C11.8202 10.5286 11.8202 10.2929 11.6684 10.1464C11.5167 10 11.2725 10 10.7842 10"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector"
          d="M11.5603 7H11.5697"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  )
}
