import SignInRequiredButton from "@/components/Explore/SignInRequiredButton"
import IconButton from "@/components/IconButton"
import { useWalletOwned } from "@/components/Setting/Profile/WalletInfo"
import TextCopy from "@/components/TextCopy"
import {
  ArrowDownIcon,
  AwardIcon,
  HelpCircleIcon,
  LeaveIcon,
  NewsIcon,
  PlusIcon,
  TickIcon,
  UserSettingIcon,
  UserSquareIcon,
} from "@/components/shared/icons"
import { cdnUrl } from "@/constants"
import useClientStore from "@/lib/clientStore"
import { googleAnalytics } from "@/lib/gtag"
import { useAuth } from "@/providers/authContext"
import { warpNameLength } from "@/utils/parser"
import { useWallet } from "@solana/wallet-adapter-react"
import classNames from "classnames"
import { AnimatePresence, motion } from "framer-motion"
import Link from "next/link"
import { HTMLAttributeAnchorTarget, ReactElement, useEffect, useRef, useState } from "react"
import UserBadge from "../../UserBadge"
import CreditsInfo from "./CreditsInfo"
import PointsInfo from "./PointsInfo"
import useTelegramInitData from "@/hooks/telegram/useTelegramInitData"
import _isEmpty from "lodash/isEmpty"
interface UserBadgeProps {
  isDisabledInfo?: boolean
  onSelectMenu?: (menu: string) => void
}
interface ItemUserInfoProps {
  href: string
  labelGA: string
  icon?: ReactElement
  title: string
  target?: HTMLAttributeAnchorTarget
  onClick?: (e: any) => void
}

const ItemUserInfo = ({ href, labelGA, icon, title, target = "_self", onClick }: ItemUserInfoProps) => {
  return (
    <Link
      className="flex items-center w-full px-4 py-3 space-x-3 hover:bg-atherGray-850 hover:opacity-100 rounded-2xl opacity-60"
      onClick={e => {
        googleAnalytics.event({
          action: "click",
          category: "navbar",
          label: labelGA,
          value: 1,
        })
        onClick?.(e)
      }}
      href={href}
      target={target ?? "_self"}
    >
      <div className="scale-125">{icon && icon}</div>
      <p className="text-sm font-semibold">{title}</p>
    </Link>
  )
}

const UserInfoMobile = ({ isDisabledInfo = true, onSelectMenu }: UserBadgeProps) => {
  const { logout } = useAuth()
  const { userInfoQuery, user } = useAuth()
  const [isOpen, setIsOpen] = useState(false)
  const wallet = useWallet()
  const { data: walletOwned } = useWalletOwned()
  const publicKey = wallet.publicKey?.toString()
  const setIsMenuOpen = useClientStore(state => state.setIsMenuOpen)
  const telegramInitData = useTelegramInitData()

  const triggerRef = useRef<HTMLDivElement>(null)
  const [triggerRefWidth, setTriggerRefWidth] = useState(0)

  useEffect(() => {
    if (triggerRef.current) {
      setTriggerRefWidth(triggerRef.current.getBoundingClientRect().width)
    }
  }, [isOpen])

  if (!userInfoQuery?.data || !user)
    return (
      <div className="mt-3 bg-atherGray-900 p-2 rounded-2xl">
        {!telegramInitData || _isEmpty(telegramInitData) ? (
          <SignInRequiredButton
            text="Let’s Imagine"
            onClick={() => {
              setIsMenuOpen(false)
            }}
            className="bg-atherPurple-500 font-semibold py-2 rounded-xl"
          />
        ) : null}
        <div className="mt-2">
          <ItemUserInfo
            onClick={() => setIsMenuOpen(false)}
            icon={<AwardIcon width={20} height={20} />}
            labelGA={"/leaderboard"}
            title="Leaderboard"
            href={"/leaderboard"}
          />
          <ItemUserInfo
            onClick={() => setIsMenuOpen(false)}
            icon={<NewsIcon width={20} height={20} />}
            labelGA={"/updates"}
            title="Updates"
            href={"/updates"}
          />
          <ItemUserInfo
            onClick={() => setIsMenuOpen(false)}
            icon={<HelpCircleIcon width={20} height={20} />}
            labelGA={"/tips-and-tricks"}
            title="Help"
            href={"/tips-and-tricks"}
          />
          <ItemUserInfo
            icon={
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_3458_61914)">
                  <path
                    d="M3.14932 9.95229C2.3593 8.57475 1.97784 7.44989 1.74783 6.30967C1.40765 4.62331 2.18614 2.976 3.47579 1.92489C4.02084 1.48064 4.64566 1.63242 4.96797 2.21066L5.69562 3.51608C6.27238 4.5508 6.56075 5.06815 6.50356 5.61665C6.44636 6.16515 6.05744 6.61188 5.27961 7.50533L3.14932 9.95229ZM3.14932 9.95229C4.74839 12.7406 7.25783 15.2514 10.0493 16.8523M10.0493 16.8523C11.4269 17.6423 12.5517 18.0238 13.692 18.2538C15.3783 18.594 17.0256 17.8155 18.0767 16.5258C18.521 15.9808 18.3692 15.356 17.791 15.0337L16.4855 14.306C15.4508 13.7293 14.9335 13.4409 14.385 13.4981C13.8365 13.5553 13.3898 13.9442 12.4963 14.722L10.0493 16.8523Z"
                    stroke="#FFF8EE"
                    strokeWidth="1.5"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M11.668 5.69354C12.854 6.19719 13.8045 7.14763 14.3081 8.33366M12.213 1.66699C15.1607 2.51762 17.4839 4.84076 18.3346 7.78835"
                    stroke="#FFF8EE"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_3458_61914">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            }
            labelGA={`mailto:hello@${cdnUrl}`}
            title="Contact us"
            href={`mailto:hello@${cdnUrl}`}
            target={"_blank"}
          />
        </div>
      </div>
    )

  return (
    <div ref={triggerRef} className="relative">
      <IconButton
        title="User Info"
        className={classNames(
          "w-full bg-atherGray-900 hover:bg-atherGray-900 active:bg-atherGray-900 p-2 rounded-2xl",
          {
            "rounded-b-none": isOpen,
          },
        )}
        colorScheme="transparent"
        onClick={() => {
          googleAnalytics.event({
            action: "click",
            category: "navbar",
            label: "user_info",
            value: 1,
          })
          setIsOpen(prev => !prev)
        }}
      >
        <div className="flex items-center flex-1">
          <UserBadge
            isDisabledInfo={isDisabledInfo}
            picture={userInfoQuery.data.picture}
            name={userInfoQuery.data.name}
            email={userInfoQuery.data.email}
            size="md"
          />
          <p className="ml-2 font-semibold">{userInfoQuery.data.name}</p>
        </div>
        <span
          title="User Info"
          className={classNames("p-2 min-h-0 rounded-full", {
            "bg-atherGray-700": isOpen,
          })}
        >
          <ArrowDownIcon width={16} height={16} />
        </span>
      </IconButton>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ height: 0 }}
            animate={{ height: "auto" }}
            exit={{ height: 0 }}
            className="w-full overflow-hidden rounded-b-2xl"
          >
            <div
              className="py-2 text-sm bg-atherGray-900 overflow-auto max-h-[40rem]"
              style={{
                WebkitOverflowScrolling: "touch",
              }}
            >
              <div className="px-2 mb-2">
                <hr className="border-atherGray-800" />
              </div>
              <div className="px-2">
                <p className="text-atherGray-500 px-1 text-xs">Currencies</p>
                <div className="flex items-center justify-between overflow-hidden">
                  <CreditsInfo isNotFormat className="w-full pr-0" isButton />
                </div>
                <div className="flex items-center justify-between">
                  <div className="overflow-hidden">
                    <PointsInfo isNotFormat className="w-auto" />
                  </div>
                  <Link
                    onClick={() => setIsMenuOpen(false)}
                    href="/quests"
                    shallow
                    className="p-2 bg-atherPurple-500 rounded-xl"
                  >
                    <PlusIcon width={14} height={14} />
                  </Link>
                </div>
              </div>
              {walletOwned && walletOwned?.wallets.length > 0 && (
                <div className="px-2 mt-2">
                  <hr className="border-atherGray-800" />
                  <div className="mt-2  ">
                    <p className="text-atherGray-500 px-1 text-xs">
                      Connect Wallet{walletOwned && walletOwned?.wallets.length > 1 ? "s" : ""}
                    </p>
                    <div>
                      {walletOwned?.wallets?.map(item => (
                        <div className="p-2 flex items-center justify-between" key={item.id}>
                          <div className="flex items-center">
                            <p className="text-sm font-semibold text-atherGray-300">{warpNameLength(item.id, 6, 4)}</p>
                          </div>
                          <div className="flex items-center space-x-2">
                            <div>
                              {publicKey === item.id && (
                                <TickIcon className="text-atherGray-300" width={20} height={20} />
                              )}
                            </div>
                            <TextCopy value={item.id} className="" width={20} height={20} />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
              <div className="px-2 my-4">
                <hr className="border-atherGray-800" />
              </div>
              <div className="flex flex-col items-start space-y-0">
                <ItemUserInfo
                  onClick={() => {
                    setIsOpen(false)
                    setIsMenuOpen(false)
                  }}
                  icon={<UserSquareIcon width={20} height={20} />}
                  labelGA={`/profile/${userInfoQuery.data.username}`}
                  title="My Profile"
                  href={`/profile/${userInfoQuery.data.username}`}
                />
                <ItemUserInfo
                  onClick={e => {
                    e.preventDefault()
                    setIsOpen(false)
                    onSelectMenu?.("account")
                    // setIsMenuOpen(false)
                  }}
                  icon={<UserSettingIcon width={20} height={20} />}
                  labelGA={"/settings/account"}
                  title="My Account"
                  href={"/settings/account"}
                />
                <ItemUserInfo
                  icon={<AwardIcon width={20} height={20} />}
                  labelGA={`leaderboard`}
                  title="Leaderboard"
                  href={"/leaderboard"}
                  onClick={() => {
                    setIsOpen(false)
                  }}
                />
                <ItemUserInfo
                  onClick={() => {
                    setIsOpen(false)
                    setIsMenuOpen(false)
                  }}
                  icon={<NewsIcon width={20} height={20} />}
                  labelGA={"/updates"}
                  title="Updates"
                  href={"/updates"}
                />
                <ItemUserInfo
                  onClick={() => {
                    setIsOpen(false)
                    setIsMenuOpen(false)
                  }}
                  icon={<HelpCircleIcon width={20} height={20} />}
                  labelGA={"/tips-and-tricks"}
                  title="Help"
                  href={"/tips-and-tricks"}
                />
                <ItemUserInfo
                  onClick={() => {
                    setIsMenuOpen(false)
                    setIsOpen(true)
                  }}
                  icon={
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M12.5 2.08398V3.33398C12.5 4.5125 12.5 5.10175 12.8661 5.46787C13.2322 5.83398 13.8215 5.83398 15 5.83398H16.25"
                        stroke="#FFF8EE"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M3.33203 13.3337V6.66699C3.33203 4.30997 3.33203 3.13146 4.06426 2.39923C4.7965 1.66699 5.97501 1.66699 8.33203 1.66699H11.8083C12.149 1.66699 12.3193 1.66699 12.4724 1.73043C12.6256 1.79386 12.746 1.91429 12.9869 2.15515L16.1772 5.3455C16.4181 5.58636 16.5385 5.70679 16.6019 5.85993C16.6654 6.01308 16.6654 6.18339 16.6654 6.52402V13.3337C16.6654 15.6907 16.6654 16.8692 15.9331 17.6014C15.2009 18.3337 14.0224 18.3337 11.6654 18.3337H8.33203C5.97501 18.3337 4.7965 18.3337 4.06426 17.6014C3.33203 16.8692 3.33203 15.6907 3.33203 13.3337Z"
                        stroke="#FFF8EE"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M6.66406 9.16699H13.3307M6.66406 11.667H13.3307M6.66406 14.167H10.1398"
                        stroke="#FFF8EE"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  }
                  labelGA={"/terms-of-service"}
                  title="Terms & Conditions"
                  href={"/terms-of-service"}
                />

                <ItemUserInfo
                  icon={
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_3458_61914)">
                        <path
                          d="M3.14932 9.95229C2.3593 8.57475 1.97784 7.44989 1.74783 6.30967C1.40765 4.62331 2.18614 2.976 3.47579 1.92489C4.02084 1.48064 4.64566 1.63242 4.96797 2.21066L5.69562 3.51608C6.27238 4.5508 6.56075 5.06815 6.50356 5.61665C6.44636 6.16515 6.05744 6.61188 5.27961 7.50533L3.14932 9.95229ZM3.14932 9.95229C4.74839 12.7406 7.25783 15.2514 10.0493 16.8523M10.0493 16.8523C11.4269 17.6423 12.5517 18.0238 13.692 18.2538C15.3783 18.594 17.0256 17.8155 18.0767 16.5258C18.521 15.9808 18.3692 15.356 17.791 15.0337L16.4855 14.306C15.4508 13.7293 14.9335 13.4409 14.385 13.4981C13.8365 13.5553 13.3898 13.9442 12.4963 14.722L10.0493 16.8523Z"
                          stroke="#FFF8EE"
                          strokeWidth="1.5"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M11.668 5.69354C12.854 6.19719 13.8045 7.14763 14.3081 8.33366M12.213 1.66699C15.1607 2.51762 17.4839 4.84076 18.3346 7.78835"
                          stroke="#FFF8EE"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_3458_61914">
                          <rect width="20" height="20" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  }
                  labelGA={`mailto:hello@${cdnUrl}`}
                  title="Contact us"
                  href={`mailto:hello@${cdnUrl}`}
                  target={"_blank"}
                />
                {!telegramInitData || _isEmpty(telegramInitData) ? (
                  <ItemUserInfo
                    icon={<LeaveIcon color="#DD3A38" />}
                    labelGA={"logout"}
                    title="Log out"
                    href={""}
                    onClick={async () => {
                      await wallet.disconnect()
                      logout()
                      setIsOpen(true)
                      setIsMenuOpen(false)
                    }}
                  />
                ) : null}
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default UserInfoMobile
