import { EntityType, FeedbackRating, SDWorkflowDetail } from "@/api/sdk"
import BackButton from "@/components/BackButton"
import IconButton from "@/components/IconButton"
import Popover from "@/components/Popover"
import { ArrowDownIcon, ArrowLeftRoundIcon, HomeIcon, ThumbUpFillIcon, ThumbUpIcon } from "@/components/shared/icons"
import { useAuth } from "@/providers/authContext"
import { useFeedbackTaskStore } from "@/stores"
import { cn } from "@/utils/cn"
import classNames from "classnames"
import React, { memo, useMemo, useState, forwardRef, useImperativeHandle } from "react"
import UsersConnect from "./UsersConnect"
import FeedbackPopup from "@/components/Workspace/Feedback/FeedbackPopup"
import TurboModeSwitch from "@/components/Workspace/TurboMode/TurboModeSwitch"
import useCustomRouter from "@/hooks/useCustomRouter"
import NotificationBell from "@/components/NotificationDrawer/NotificationBell"
import { NotificationDrawer } from "@/components/NotificationDrawer"
import ComfyUISlim from "../ComfyUISlim"
import dynamic from "next/dynamic"
import { isMobile } from "react-device-detect"
import { useCanvasClick } from "./use-canvas-click"

const CreateComfyUIModal = dynamic(() => import("../CreateComfyUIModal"), { ssr: false })

interface ComfyUIWorkflowTopbarProps {
  workflow?: SDWorkflowDetail & { pinned?: boolean }
  hasPendingSave?: boolean
  isCompletedTask: boolean
  name: string | null
  handleGenerate: () => void
  isGenerating: boolean
  isLoading?: boolean
}

export interface ComfyUIWorkflowTopbarRef {
  open: () => void
  close: () => void
  onNewWorkflow: () => void
}

const ComfyUIWorkflowTopbar = forwardRef<ComfyUIWorkflowTopbarRef, ComfyUIWorkflowTopbarProps>((props, ref) => {
  const {
    workflow,
    name,
    handleGenerate,
    isCompletedTask,
    isLoading,
    isGenerating,
    hasPendingSave,
  } = props

  const [isOpen, setIsOpen] = useState(false)
  const router = useCustomRouter()
  const { user } = useAuth()

  const [modal, setModal] = useState<string | null>(null)

  const isOwner = workflow?.creator?.uid === user?.uid

  const handleBack = (pathname?: string) => {
    if (pathname) {
      router.push(pathname)
      return
    }

    router.push(workflow?.isDraft ? "/workspace/tools/comfyui?tab=drafts" : "/workspace/tools/comfyui")
  }

  useCanvasClick(() => {
    setIsOpen(false)
  })

  const workflowId = useMemo(() => workflow?.id, [workflow?.id])
  const { feedbackTasks, setFeedbackTasks } = useFeedbackTaskStore()

  const renderWorkflowName = () => {
    if (isMobile) return null

    if (isLoading) {
      return <p className="font-semibold text-center w-full md:w-auto">Loading...</p>
    }

    return (
      <Popover
        className="w-full overflow-hidden"
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        trigger={
          <button
            disabled={isMobile}
            type="button"
            className={classNames("overflow-hidden max-w-[40rem] flex items-center text-atherGray-100")}
          >
            {hasPendingSave && <span className="text-2xl mr-1 text-atherPurple-300">*</span>}
            <p className="font-semibold flex-1 line-clamp-1 text-center w-full md:w-auto">{name}</p>
            <span className={classNames("ml-2 text-atherGray-500")}>
              <ArrowDownIcon />
            </span>
          </button>
        }
      >
        <div
          className={
            "text-sm h-[70vh] w-[90vw] max-h-[40rem] max-w-[64rem] flex flex-col overflow-hidden bg-atherGray-850 rounded-2xl"
          }
        >
          <ComfyUISlim
            workflow={workflow}
            onSelect={() => setIsOpen(false)}
            onOpenCreateModal={() => setModal("CREATE")}
          />
        </div>
      </Popover>
    )
  }

  useImperativeHandle(ref, () => ({
    open: () => {
      setIsOpen(true)
    },
    close: () => {
      setIsOpen(false)
    },
    onNewWorkflow: () => {
      setModal("CREATE")
    },
  }))

  if (!workflow) return null

  return (
    <div className={cn("border-b border-atherGray-800 bg-atherGray-900", "z-[1] w-full h-[3rem]")}>
      <div className="flex items-center w-full justify-between px-4 h-full">
        <div className="flex items-center md:mr-2">
          <BackButton
            className="bg-transparent p-2 min-h-0 hover:bg-transparent"
            icon={<ArrowLeftRoundIcon className="text-atherGray-300" width={20} height={20} />}
            label=""
            onClick={() => handleBack()}
          />
          <BackButton
            className="bg-transparent p-2 ml-2 min-h-0 hover:bg-transparent"
            icon={<HomeIcon className="text-atherGray-300" width={20} height={20} />}
            label=""
            onClick={() => handleBack("/workspace")}
          />
          {!isMobile && (
            <div className="flex items-center flex-1 md:flex-0 t ml-2 space-x-2 relative">
              {workflow && !workflow.isDraft && isOwner && (
                <IconButton
                  title={"Rating"}
                  colorScheme="transparent"
                  className={classNames("p-1.5 min-h-0 text-atherGray-300", {
                    "!cursor-default": !!workflow.userFeedback,
                  })}
                  onClick={e => {
                    e.stopPropagation()

                    if (workflow.userFeedback) return

                    if (modal === "FEEDBACK") {
                      setModal(null)
                    } else {
                      setModal("FEEDBACK")
                    }
                  }}
                >
                  {workflow.userFeedback ? (
                    <ThumbUpFillIcon
                      width={20}
                      height={20}
                      className={classNames({
                        "text-red-500 rotate-180": workflow.userFeedback?.rating === FeedbackRating.NotGood,
                        "text-atherPurple-500": workflow.userFeedback?.rating === FeedbackRating.Good,
                      })}
                    />
                  ) : (
                    <ThumbUpIcon width={20} height={20} />
                  )}
                </IconButton>
              )}
              {modal === "FEEDBACK" &&
                workflow &&
                !workflow.isDraft &&
                user &&
                !feedbackTasks?.includes(EntityType.SD_WORKFLOW) && (
                  <FeedbackPopup
                    debounceIsOpen={modal === "FEEDBACK" && !workflow.userFeedback}
                    isModal
                    onClose={() => setModal(null)}
                    onSuccess={() => setFeedbackTasks([...(feedbackTasks ?? []), EntityType.SD_WORKFLOW])}
                    className="fixed top-[4rem] right-4 z-[9]"
                    entityId={workflow.id}
                    entityType={EntityType.SD_WORKFLOW}
                  />
                )}
              {isCompletedTask && workflow && !workflow.isDraft && user && (
                <FeedbackPopup
                  debounceIsOpen={!workflow.userFeedback}
                  className="fixed top-[4rem] right-4 z-[9]"
                  entityId={workflow.id}
                  entityType={EntityType.WORKFLOW}
                />
              )}
              {user && <TurboModeSwitch />}
            </div>
          )}
        </div>
        <div className="justify-center flex overflow-hidden flex-1">{renderWorkflowName()}</div>
        <div className="flex space-x-2 items-center justify-end md:ml-2">
          <div>
            <UsersConnect
              workflowId={workflowId}
            />
          </div>
          <NotificationBell />
          <IconButton
            isLoading={isGenerating}
            onClick={handleGenerate}
            className="font-semibold text-xs hidden md:flex"
          >
            Generate
          </IconButton>
        </div>
      </div>
      <NotificationDrawer />
      <CreateComfyUIModal
        isOpen={modal === "CREATE"}
        onClose={() => setModal(null)}
        onSelect={() => {
          setModal(null)
        }}
      />
    </div>
  )
})

export default memo(ComfyUIWorkflowTopbar) as typeof ComfyUIWorkflowTopbar
