import { InspirationBoardSearchEntity } from "@/api/sdk"
import {
  ArticleIcon,
  ColorPickerIcon,
  FlowIcon,
  FlowSquareIcon,
  ImageIcon,
  ScienceIcon,
  UserSquareIcon,
} from "@/components/shared/icons"
import { googleAnalytics } from "@/lib/gtag"
import classNames from "classnames"
import useCustomRouter from "@/hooks/useCustomRouter"
import React from "react"
import { ExploreGalleryTabType } from "../GalleryInspiration"
import ScrollHorizontalContainer from "@/components/Alpha/ScrollHorizontalContainer"

export const exploreTabs = [
  {
    name: "Images",
    icon: <ImageIcon width={24} height={24} />,
    type: InspirationBoardSearchEntity.IMAGE,
  },
  {
    name: "Recipes",
    icon: <ScienceIcon width={24} height={24} />,
    type: InspirationBoardSearchEntity.RECIPE,
  },
  {
    name: "Macros",
    icon: <FlowIcon width={24} height={24} />,
    type: InspirationBoardSearchEntity.WORKFLOW,
  },
  {
    name: "ComfyUI",
    icon: <FlowSquareIcon width={24} height={24} />,
    type: InspirationBoardSearchEntity.SD_WORKFLOW,
  },
  {
    name: "Styles",
    icon: <ColorPickerIcon width={24} height={24} />,
    type: InspirationBoardSearchEntity.STYLE,
  },

  {
    name: "Articles",
    icon: <ArticleIcon width={24} height={24} />,
    type: InspirationBoardSearchEntity.ARTICLE,
  },
  {
    name: "Creators",
    icon: <UserSquareIcon width={24} height={24} />,
    type: InspirationBoardSearchEntity.CREATOR_PROFILE,
  },
]

const GalleryTab = ({ tab }: { tab: ExploreGalleryTabType; isSearch?: boolean }) => {
  const router = useCustomRouter()
  const { search, prompt } = router.query

  return (
    <div className="flex items-center justify-center overflow-hidden w-full">
      <ScrollHorizontalContainer containerClassName="md:w-auto" className="md:space-x-6 lg:space-x-8" valueKey={tab}>
        {exploreTabs.map(item => (
          <div
            key={item.name}
            id={item.name.toLowerCase()}
            className={classNames(
              "p-2 flex cursor-pointer flex-col items-center rounded-xl space-y-2 lg:hover:text-atherGray-0",
              {
                "text-atherGray-0": item.name.toLowerCase() === tab.toLowerCase(),
                "text-atherGray-500": item.name.toLowerCase() !== tab.toLowerCase(),
              },
            )}
            onClick={() => {
              const url = `/explore/${item.name.toLowerCase()}${search ? `?search=${search}${prompt ? `&prompt=${prompt}` : ""}` : prompt ? `?prompt=${prompt}` : ""}`

              router.push(url, undefined, {
                shallow: true,
                scroll: false,
              })

              googleAnalytics.handleCategoryEvent({
                action: "click",
                params: {
                  action: "Change tab",
                  tab_name: router.pathname.split("/")[2],
                  new_tab_name: item.name.toLowerCase(),
                },
              })
            }}
          >
            {item.icon}
            <p className="font-semibold text-xs">{item.name}</p>
          </div>
        ))}
      </ScrollHorizontalContainer>
    </div>
  )
}

export default GalleryTab
