import client from "@/api/client"
import {
  PinnedItemType,
  PinnedItems,
  PublishRecipeDto,
  RecentSDWorkflowItem,
  RenameSDWorkflowDto,
  SDWorkflow,
  SDWorkflowDetail,
  SearchSDStyleResponse,
  SearchSDWorkflowResponse,
  UpdateSDWorkflowDto,
} from "@/api/sdk"
import { InfiniteData, QueryClient } from "@tanstack/react-query"
import { createMutation } from "react-query-kit"
import { actionMiddleware } from "@/queries/middlewares/actionMiddleware"
import {
  useComfyUiWorkflowDetailQuery,
  useComfyUiWorkflowInfiniteQuery,
  useComfyUiWorkflowListQuery,
} from "./getComfyUiRecipeQueries"
import { useRecentComfyUIInfiniteQuery } from "@/components/Workspace/RecentV2/RecentComfyUI"
import { useWorkspacePinInfiniteQuery } from "@/queries/workspace"

export type ComfyUiRecipeMutationVariables = {
  workflowId: string
}

export type ComfyUiRecipeDetailMutationVariables<T = Record<string, any>> = {
  workflowId: string
  data: T
}

export type UseUpdateWorkflowMutationVariables = ComfyUiRecipeDetailMutationVariables<UpdateSDWorkflowDto>

export type UseRenameWorkflowMutationVariables = ComfyUiRecipeDetailMutationVariables<RenameSDWorkflowDto>

export type UsePublishWorkflowMutationVariables = ComfyUiRecipeDetailMutationVariables<PublishRecipeDto>

const handleUpdateWorkflowSuccess = (props: {
  queryClient: QueryClient
  variables: UseRenameWorkflowMutationVariables
  data: SDWorkflow
}) => {
  const { queryClient, variables, data: updated } = props

  const infKey = useComfyUiWorkflowInfiniteQuery.getKey()
  const infQueriesDataEntries = queryClient.getQueriesData<InfiniteData<SearchSDWorkflowResponse, number>>({
    queryKey: infKey,
  })
  infQueriesDataEntries?.forEach(([key, data]) => {
    if (!data) return

    const updatedData = {
      ...data,
      pages: data.pages.map(page => {
        return {
          ...page,
          workflows: page.workflows.map(workflow => {
            if (workflow.id === variables.workflowId) {
              return {
                ...workflow,
                name: variables.data.name,
              }
            }
            return workflow
          }),
        }
      }),
    }
    queryClient.setQueryData(key, updatedData)
  })

  const listKey = useComfyUiWorkflowListQuery.getKey()
  const listQueriesDataEntries = queryClient.getQueriesData<SearchSDWorkflowResponse>({
    queryKey: listKey,
  })
  listQueriesDataEntries?.forEach(([key, data]) => {
    if (!data) return

    const updatedData = {
      ...data,
      workflows: data.workflows.map(workflow => {
        if (workflow.id === variables.workflowId) {
          return {
            ...workflow,
            name: variables.data.name,
          }
        }
        return workflow
      }),
    }
    queryClient.setQueryData(key, updatedData)
  })

  const detailKey = useComfyUiWorkflowDetailQuery.getKey({
    workflowId: variables.workflowId,
  })
  const detailKeyQueriesDataEntries = queryClient.getQueriesData<SDWorkflowDetail>({
    queryKey: detailKey,
  })
  detailKeyQueriesDataEntries?.forEach(([key, data]) => {
    if (!data) return

    const updatedData: SDWorkflowDetail = {
      ...data,
      ...variables.data,
      thumbnailUrl: updated.thumbnailUrl,
      isDraft: updated.isDraft,
    }
    queryClient.setQueryData(key, updatedData)
  })

  // Update comfyui recent list
  const comfyuiRecentListKey = useRecentComfyUIInfiniteQuery.getKey()

  const comfyuiRecentListQueriesDataEntries = queryClient.getQueriesData<InfiniteData<RecentSDWorkflowItem[], number>>({
    queryKey: comfyuiRecentListKey,
  })

  if (comfyuiRecentListQueriesDataEntries) {
    comfyuiRecentListQueriesDataEntries.forEach(([key, recentListData]) => {
      if (recentListData) {
        const updatedData = {
          ...recentListData,
          pages: recentListData.pages.map(page => {
            return [
              ...page.map(item => {
                if (item.sdWorkflow.id === variables.workflowId) {
                  return {
                    ...item,
                    sdWorkflow: {
                      ...item.sdWorkflow,
                      ...variables.data,
                    },
                  }
                }

                return item
              }),
            ]
          }),
        }

        queryClient.setQueryData(key, updatedData)
      }
    })
  }

  //update pinned comfyui
  const comfyuiPinnedListKey = useWorkspacePinInfiniteQuery.getKey({
    type: PinnedItemType.SD_WORKFLOW,
  })

  const comyuiPinnedListQueriesDataEntries = queryClient.getQueriesData<InfiniteData<PinnedItems[], number>>({
    queryKey: comfyuiPinnedListKey,
  })

  if (comyuiPinnedListQueriesDataEntries) {
    comyuiPinnedListQueriesDataEntries.forEach(([key, pinnedListData]) => {
      if (pinnedListData) {
        const updatedData = {
          ...pinnedListData,
          pages: pinnedListData.pages.map(page => {
            return [
              ...page.map(item => {
                if (item.sdWorkflow?.id === variables.workflowId) {
                  return {
                    ...item,
                    sdWorkflow: {
                      ...item.sdWorkflow,
                      ...variables.data,
                    },
                  }
                }

                return item
              }),
            ]
          }),
        }

        queryClient.setQueryData(key, updatedData)
      }
    })
  }
}

export const useCreateComfyUiWortkflowMutation = createMutation({
  mutationFn: async (props: UpdateSDWorkflowDto) => {
    return client.api.sdWorkflowControllerCreateWorkflow(props).then(res => res.data)
  },
  use: [
    actionMiddleware({
      onSuccess: ({ queryClient }) => {
        // Update list
        const listKey = useComfyUiWorkflowInfiniteQuery.getKey()
        const listQueriesDataEntries = queryClient.getQueriesData<InfiniteData<SearchSDStyleResponse, number>>({
          queryKey: listKey,
        })

        listQueriesDataEntries?.forEach(([key]) => {
          queryClient.invalidateQueries({ queryKey: key })
        })
      },
    }),
  ],
})

export const useUpdateComfyUiWorkflowMutation = createMutation({
  mutationFn: async (props: UseUpdateWorkflowMutationVariables) => {
    return client.api.sdWorkflowControllerSaveWorkflow(props.workflowId, props.data).then(res => res.data)
  },
  use: [
    actionMiddleware({
      onSuccess: handleUpdateWorkflowSuccess,
    }),
  ],
})

export const useRenameComfyUiWorkflowMutation = createMutation({
  mutationFn: async (props: UseRenameWorkflowMutationVariables) => {
    return client.api.sdWorkflowControllerRenameWorkflow(props.workflowId, props.data).then(res => res.data)
  },
  use: [
    actionMiddleware({
      onSuccess: handleUpdateWorkflowSuccess,
    }),
  ],
})

export const useDuplicateComfyUiWorkflowMutation = createMutation({
  mutationFn: async (props: ComfyUiRecipeMutationVariables) => {
    return client.api.sdWorkflowControllerDuplicateWorkflow(props.workflowId).then(res => res.data)
  },
  use: [
    actionMiddleware({
      onSuccess: ({ queryClient }) => {
        // Update style list
        const infKey = useComfyUiWorkflowInfiniteQuery.getKey()
        const infQueriesDataEntries = queryClient.getQueriesData<InfiniteData<SearchSDStyleResponse, number>>({
          queryKey: infKey,
        })
        infQueriesDataEntries?.forEach(([key, data]) => {
          if (key[1]?.["mode"] === "owned") {
            queryClient.invalidateQueries({ queryKey: key })
          }
        })

        const listKey = useComfyUiWorkflowListQuery.getKey()
        const listQueriesDataEntries = queryClient.getQueriesData<SearchSDWorkflowResponse>({
          queryKey: listKey,
        })
        listQueriesDataEntries?.forEach(([key]) => {
          queryClient.invalidateQueries({ queryKey: key })
        })
      },
    }),
  ],
})

export const usePublishComfyUiWorkflowMutation = createMutation({
  mutationFn: async (props: UsePublishWorkflowMutationVariables) => {
    return client.api.sdWorkflowControllerPublishRecipe(props.workflowId, props.data).then(res => res.data)
  },
  use: [
    actionMiddleware({
      onSuccess: ({ queryClient, data: updated, variables }) => {
        const detailKey = useComfyUiWorkflowDetailQuery.getKey({
          workflowId: variables.workflowId,
        })
        const detailKeyQueriesDataEntries = queryClient.getQueriesData<SDWorkflowDetail>({
          queryKey: detailKey,
        })
        detailKeyQueriesDataEntries?.forEach(([key, data]) => {
          if (!data) return

          const updatedData: SDWorkflowDetail = {
            ...data,
            lastRecipeId: updated.id,
          }
          queryClient.setQueryData(key, updatedData)
        })
      },
    }),
  ],
})
