import { IconProps } from "."

export const LayerAddIcon = (props: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M7.5 12.5C7.5 10.143 7.5 8.96447 8.23223 8.23223C8.96447 7.5 10.143 7.5 12.5 7.5L13.3333 7.5C15.6904 7.5 16.8689 7.5 17.6011 8.23223C18.3333 8.96447 18.3333 10.143 18.3333 12.5V13.3333C18.3333 15.6904 18.3333 16.8689 17.6011 17.6011C16.8689 18.3333 15.6904 18.3333 13.3333 18.3333H12.5C10.143 18.3333 8.96447 18.3333 8.23223 17.6011C7.5 16.8689 7.5 15.6904 7.5 13.3333L7.5 12.5Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1665 7.50033C14.1646 5.03608 14.1273 3.75967 13.41 2.88568C13.2715 2.7169 13.1167 2.56214 12.9479 2.42362C12.026 1.66699 10.6562 1.66699 7.91663 1.66699C5.17706 1.66699 3.80727 1.66699 2.88532 2.42362C2.71654 2.56214 2.56177 2.7169 2.42326 2.88568C1.66663 3.80764 1.66663 5.17742 1.66663 7.91699C1.66663 10.6566 1.66663 12.0263 2.42326 12.9483C2.56177 13.1171 2.71653 13.2718 2.88531 13.4104C3.7593 14.1276 5.03572 14.1649 7.49996 14.1669"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.9999 12.9173L10.8333 12.9173M12.9166 10.834V15.0007"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  )
}
