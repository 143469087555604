import { PinnedItemType, RecipeFilterMode, RecipeItem } from "@/api/sdk"
import IconButton from "@/components/IconButton"
import Tooltip from "@/components/Tooltip"
import {
  AddSquareIcon,
  DeleteIcon,
  PinIcon,
  RefreshIcon,
  RemoveSquareIcon,
  TickIcon,
  UnPinIcon,
} from "@/components/shared/icons"
import { useToast } from "@/hooks"
import { useAuth } from "@/providers/authContext"
import { googleAnalytics } from "@/lib/gtag"
import { usePinMutation } from "@/queries"
import { useAddRecipesMutation, useRemoveRecipesMutation } from "@/queries/workspace/recipe"
import { useDeleteItemStore, useRecoverItemStore } from "@/stores"
import { AnimatePresence, motion } from "framer-motion"
import { Fragment, useCallback, useMemo } from "react"
import ContextMenuZone from "../FoldersV2/ContextMenuZone"
import MultiModeActions from "../FoldersV2/TabFolder/MultiModeActions"

interface MultiRecipesSelectBarProps {
  selectedItems: RecipeItem[]
  offsetLeft?: number
  parentFolderId?: string
  selectionMode?: boolean
  children?: React.ReactNode
  clear: () => void
  view?: RecipeFilterMode
}

const MultiRecipesSelectBar = ({
  parentFolderId,
  selectedItems,
  children,
  selectionMode,
  offsetLeft = 0,
  view,
  clear,
}: MultiRecipesSelectBarProps) => {
  const toast = useToast()
  const setDeletingItem = useDeleteItemStore(s => s.setDeletingItem)
  const setRecoverItem = useRecoverItemStore(state => state.setRecoverItem)
  const { user } = useAuth()
  const isDisabled = useMemo(() => selectedItems.some(item => item.creator?.uid === user?.uid), [selectedItems, user])

  const { mutate: mutatePin, isPending: isPendingPin } = usePinMutation({
    onSuccess: () => {
      toast({
        status: "success",
        title: `Recipes ${selectedItems[0].pinned ? "unpinned" : "pinned"}`,
      })
      clear()
    },
  })

  const { mutate: removeFromLibrary, isPending: isRemovingStyle } = useRemoveRecipesMutation({
    onSuccess() {
      toast({
        status: "success",
        title: "Remove from my recipes successfully!",
      })

      clear()
    },
    onError: (err, variables, context) => {
      toast({
        status: "error",
        title: "Remove from my recipes failed!",
      })
    },
  })

  const { mutate: addFromLibrary, isPending: isAddStyle } = useAddRecipesMutation({
    onSuccess() {
      toast({
        status: "success",
        title: "Add from my recipes successfully!",
      })
      clear()
    },
    onError: (err, variables, context) => {
      toast({
        status: "error",
        title: "Add from my recipes failed!",
      })
    },
  })

  const handlePinRecipe = useCallback(() => {
    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        action: "Multi Recipe Pin",
        ...selectedItems.reduce((acc, item, index) => {
          acc[`recipe_id_${index}`] = item.id
          return acc
        }, {}),
      },
    })

    mutatePin({
      ids: selectedItems.map(item => item.id),
      isPinned: !selectedItems[0].pinned,
      type: PinnedItemType.RECIPE,
    })
  }, [selectedItems, mutatePin])

  const handleAddRecipe = useCallback(() => {
    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        action: "Multi Recipe Add to My Recipes",
        ...selectedItems.reduce((acc, item, index) => {
          acc[`recipe_id_${index}`] = item.id
          return acc
        }, {}),
      },
    })

    addFromLibrary({
      ids: selectedItems.map(item => item.id),
    })
  }, [addFromLibrary, selectedItems])

  const handleRemoveRecipe = useCallback(() => {
    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        action: "Multi Recipe Remove from My Recipes",
        ...selectedItems.reduce((acc, item, index) => {
          acc[`recipe_id_${index}`] = item.id
          return acc
        }, {}),
      },
    })

    removeFromLibrary({
      ids: selectedItems.map(item => item.id),
      view,
    })
  }, [removeFromLibrary, selectedItems, view])

  const handleRecoverRecipe = useCallback(() => {
    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        action: "Multi Recipe Recover",
        ...selectedItems.reduce((acc, item, index) => {
          acc[`recipe_id_${index}`] = item.id
          return acc
        }, {}),
      },
    })

    setRecoverItem({
      ids: selectedItems.map(item => item.id),
      deletedAt: selectedItems.find(item => item.deletedAt)?.deletedAt ?? "",
      type: "recipe",
      onClose: clear,
    })
  }, [clear, selectedItems, setRecoverItem])

  const handleDeleteRecipe = useCallback(() => {
    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        action: "Multi Recipe Delete",
        ...selectedItems.reduce((acc, item, index) => {
          acc[`recipe_id_${index}`] = item.id
          return acc
        }, {}),
      },
    })
    setDeletingItem({
      type: "recipe",
      folderId: parentFolderId,
      ids: selectedItems.map(item => item.id),
      id: selectedItems.find(item => item.id)?.id ?? "",
      deletedAt: selectedItems.find(item => item.id)?.deletedAt ?? "",
      onClose: clear,
    })
  }, [clear, parentFolderId, selectedItems, setDeletingItem])

  const renderRecipeActions = useMemo(() => {
    return (
      <MultiModeActions
        onAdd={{
          fn: handleAddRecipe,
          enabled: !selectedItems.some(item => !item.capabilities?.canAddToLibrary) && selectedItems.length > 0,
          visible: !selectedItems.find(item => item.deletedAt)?.deletedAt && !selectedItems[0]?.isPublic && !isDisabled,
          title: `Add to My Recipes`,
          icon: <AddSquareIcon width={20} height={20} />,
        }}
        onRemove={{
          fn: handleRemoveRecipe,
          enabled: !selectedItems.some(item => !item.capabilities?.canRemoveFromLibrary) && selectedItems.length > 0,
          visible: !selectedItems.find(item => item.deletedAt)?.deletedAt && !selectedItems[0]?.isPublic,
          title: `Remove from My Recipes`,
          icon: <RemoveSquareIcon width={20} height={20} />,
        }}
        onPin={{
          fn: handlePinRecipe,
          enabled: selectedItems.length > 0,
          visible: !selectedItems.find(item => item.deletedAt)?.deletedAt,
          title: `${selectedItems[0]?.pinned ? "Unpin" : "Pin"}`,
          icon: selectedItems[0]?.pinned ? <UnPinIcon width={20} height={20} /> : <PinIcon width={20} height={20} />,
        }}
        onRecover={{
          fn: handleRecoverRecipe,
          enabled: !selectedItems.some(item => !item.capabilities?.canRecover) && selectedItems.length > 0,
          visible: !!selectedItems.find(item => item.deletedAt)?.deletedAt,
          title: `Recover`,
        }}
        onDelete={{
          fn: handleDeleteRecipe,
          enabled: !selectedItems.some(item => !item.capabilities?.canDelete) && selectedItems.length > 0,
          visible: !selectedItems[0]?.isPublic,
          title: `Delete`,
        }}
      />
    )
  }, [
    handleAddRecipe,
    handleDeleteRecipe,
    handlePinRecipe,
    handleRecoverRecipe,
    selectedItems,
    handleRemoveRecipe,
    isDisabled,
  ])

  return (
    <Fragment>
      <AnimatePresence>
        {selectedItems.length > 0 && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed -translate-x-1/2 top-[3rem] md:top-32 z-20"
            style={{
              left: `calc(50% + ${offsetLeft}px)`,
            }}
          >
            <div className="rounded-2xl bg-atherGray-850 py-2 px-2 md:px-4 text-xs font-semibold text-atherGray-300 h-12 flex items-center space-x-4">
              <div className="pl-2 flex items-center">
                {selectedItems.length} <span className="hidden md:inline-block ml-2">selected</span>
                <span className="inline-block ml-2 md:hidden">
                  <TickIcon />
                </span>
              </div>
              <hr className="w-[1px] h-6 border border-atherGray-800" />
              {/* <button>Select all</button> */}
              <div className="flex items-center space-x-2">
                {!selectedItems.find(item => item.deletedAt)?.deletedAt && (
                  <Tooltip
                    trigger={
                      <IconButton
                        className="w-8 h-8 p-0 bg-atherGray-850 hover:bg-atherGray-700 active:bg-atherGray-800 text-atherGray-300 hover:text-atherGray-0"
                        colorScheme="transparent"
                        isLoading={isPendingPin}
                        onClick={() => {
                          handlePinRecipe()
                        }}
                      >
                        {!selectedItems[0].pinned ? (
                          <PinIcon width={16} height={16} />
                        ) : (
                          <UnPinIcon width={16} height={16} />
                        )}
                      </IconButton>
                    }
                  >
                    {selectedItems[0].pinned ? "Unpin" : "Pin"}
                  </Tooltip>
                )}

                {selectedItems.some(i => !i.isPublic && !i.deletedAt) && !isDisabled && (
                  <Tooltip
                    trigger={
                      <IconButton
                        className="w-8 h-8 p-0 bg-atherGray-850 hover:bg-atherGray-700 active:bg-atherGray-800 text-atherGray-300 hover:text-atherGray-0"
                        colorScheme="transparent"
                        disabled={selectedItems.some(item => !item.capabilities?.canAddToLibrary)}
                        isLoading={isAddStyle}
                        onClick={() => {
                          handleAddRecipe()
                        }}
                      >
                        <AddSquareIcon width={16} height={16} />
                      </IconButton>
                    }
                  >
                    Add to My Recipes
                  </Tooltip>
                )}
                {selectedItems.some(i => !i.isPublic && !i.deletedAt) && (
                  <Tooltip
                    trigger={
                      <IconButton
                        className="w-8 h-8 p-0 hover:bg-atherGray-700 active:bg-atherGray-800 text-red-500 bg-atherGray-850"
                        colorScheme="transparent"
                        disabled={selectedItems.some(item => !item.capabilities?.canRemoveFromLibrary)}
                        isLoading={isRemovingStyle}
                        onClick={() => {
                          handleRemoveRecipe()
                        }}
                      >
                        <RemoveSquareIcon width={16} height={16} />
                      </IconButton>
                    }
                  >
                    Remove from my recipes
                  </Tooltip>
                )}

                {selectedItems.some(i => !i.isPublic && i.deletedAt) && (
                  <Tooltip
                    trigger={
                      <IconButton
                        disabled={selectedItems.some(i => !i.capabilities?.canRecover)}
                        className="w-8 h-8 p-0 bg-atherGray-850 hover:bg-atherGray-700 active:bg-atherGray-800 text-atherGray-300 hover:text-atherGray-0"
                        colorScheme="transparent"
                        onClick={() => {
                          handleRecoverRecipe()
                        }}
                      >
                        <RefreshIcon className="rotate-180" width={16} height={16} />
                      </IconButton>
                    }
                  >
                    Recover
                  </Tooltip>
                )}
                {selectedItems.some(i => !i.isPublic) && view !== RecipeFilterMode.Shared && (
                  <Tooltip
                    trigger={
                      <IconButton
                        disabled={selectedItems.some(item => !item.capabilities?.canDelete)}
                        className="w-8 h-8 p-0 hover:bg-atherGray-700 active:bg-atherGray-800 text-red-500"
                        colorScheme="transparent"
                        onClick={() => {
                          handleDeleteRecipe()
                        }}
                      >
                        <DeleteIcon width={16} height={16} />
                      </IconButton>
                    }
                  >
                    Delete
                  </Tooltip>
                )}
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      {children && (
        <ContextMenuZone isDisabled={!selectionMode} className="flex-1 flex flex-col" contextMenu={renderRecipeActions}>
          {children}
        </ContextMenuZone>
      )}
    </Fragment>
  )
}

export default MultiRecipesSelectBar
