export const handleConvertHEICtoJPG = async (file: Blob | File): Promise<Blob> => {
  const convert = require("heic-convert/browser")

  if (!file.type.startsWith("image/") && !file.name.endsWith(".heic")) {
    throw new Error("File is not an image.")
  }
  if (file.type !== "image/heic" && !file.name.endsWith(".heic")) {
    return file
  }

  try {
    const outputBuffer = await convert({
      buffer: new Uint8Array(await file.arrayBuffer()), // the HEIC file buffer
      format: "JPEG", // output format
      quality: 1, // the jpeg compression quality, between 0 and 1
    })

    return new Blob([outputBuffer], { type: "image/jpeg" })
  } catch (error) {
    throw new Error("HEIC image is not supported.")
  }
}

export const convertImage = async (file: Blob | File, format: string): Promise<Blob> => {
  return new Promise((resolve, reject) => {
    if (!["image/jpeg", "image/png", "image/webp", "image/heic"].some(v => v === file.type)) {
      reject("File is not an image")
      return
    }

    const reader = new FileReader()

    reader.onload = async e => {
      const img = new Image()
      img.onload = () => {
        const canvas = document.createElement("canvas")
        const ctx = canvas.getContext("2d")

        if (!ctx) {
          reject("Cannot convert image")
          return
        }

        canvas.width = img.width
        canvas.height = img.height
        ctx.drawImage(img, 0, 0)

        canvas.toBlob(
          blob => {
            if (!blob) {
              reject("Cannot convert image")
              return
            }

            resolve(blob)
          },
          format,
          1,
        )
      }

      img.src = e.target?.result as string
    }

    reader.onerror = error => reject(error)
    reader.readAsDataURL(file)
  })
}
