import React, { cloneElement, ReactElement } from "react"
import { Controller, useForm, useFormContext } from "react-hook-form"

interface FieldWithControllerProps {
  name: string
  children: ReactElement
}

const FieldWithController = ({ name, children }: FieldWithControllerProps) => {
  const { control } = useFormContext()
  return (
    <Controller
      control={control}
      name={name}
      key={name}
      render={({ field, fieldState }) => cloneElement(children, { ...field, fieldState })}
    />
  )
}

export default FieldWithController
