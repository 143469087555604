import IconButton from "@/components/IconButton"
import { SendIcon, TickIcon, XIcon } from "@/components/shared/icons"
import { cn } from "@/lib/utils"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { ContentEditable } from "@lexical/react/LexicalContentEditable"
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary"
import { PlainTextPlugin } from "@lexical/react/LexicalPlainTextPlugin"
import classNames from "classnames"
import { LexicalEditor } from "lexical"
import React, { useEffect, useState } from "react"

interface CommentPluginProps {
  editorRef?: React.MutableRefObject<LexicalEditor | null>
  readOnly?: boolean
  isDisabled?: boolean
  placeholder?: string
  sizeIcon?: number
  submit: (e: any) => void
  clear?: (e: any) => void
  editMode?: boolean
  editorKey?: string
  inputClassName?: string
}
const CommentPlugin = ({
  editorRef,
  readOnly,
  sizeIcon = 16,
  editMode,
  isDisabled,
  clear,
  submit,
  placeholder,
  editorKey,
  inputClassName,
}: CommentPluginProps) => {
  const [editor] = useLexicalComposerContext()
  const [isEditable, setIsEditable] = useState(() => editor.isEditable())

  useEffect(() => {
    if (!editor || readOnly === undefined) return
    editor.update(() => {
      setIsEditable(!readOnly)
      if (!readOnly) {
        setTimeout(() => editorRef?.current?.focus(), 250)
      }
    })
  }, [readOnly, editor, editorRef])

  return (
    <div
      className={cn("border rounded-lg flex w-full items-center", {
        [`border-atherGray-800 py-1 px-2 cursor-text ${inputClassName}`]: readOnly === undefined || isEditable,
        [`cursor-not-allowed py-1 px-2 opacity-60 border-atherGray-800 ${inputClassName}`]:
          readOnly && editorKey === "create-comment",
        "border-transparent p-0": readOnly !== undefined && !isEditable && editorKey !== "create-comment",
      })}
      onClick={() => {
        editorRef?.current?.focus()
      }}
    >
      <div className="flex w-full h-full items-end">
        <div
          className={classNames("flex-1 flex items-center w-full overflow-hidden relative", {
            "min-h-[2rem]": !readOnly,
            "min-h-auto": readOnly,
          })}
        >
          <PlainTextPlugin
            contentEditable={<ContentEditable className="flex-1 lexical-editor" />}
            placeholder={
              placeholder ? (
                <p className="absolute top-1/2 -translate-y-1/2 left-0 pointer-events-none text-atherGray-500">
                  {placeholder}
                </p>
              ) : null
            }
            ErrorBoundary={LexicalErrorBoundary}
          />
        </div>
        {isEditable && (
          <>
            {editMode ? (
              <>
                <IconButton
                  onClick={e => submit(e)}
                  className="ml-2 p-0 bg-transparent hover:bg-transparent active:bg-transparent"
                >
                  <TickIcon
                    width={sizeIcon * 0.8}
                    height={sizeIcon * 0.8}
                    className={classNames("", {
                      "text-atherSuccess-300": !isDisabled,
                      "text-atherGray-500": isDisabled,
                    })}
                  />
                </IconButton>
                <IconButton
                  onClick={e => {
                    clear?.(e)
                  }}
                  className="ml-2 p-0 bg-transparent hover:bg-transparent active:bg-transparent"
                >
                  <XIcon width={sizeIcon * 0.8} height={sizeIcon * 0.8} className={"text-red-500"} />
                </IconButton>
              </>
            ) : (
              <IconButton
                onClick={e => submit(e)}
                className="ml-2 p-0 bg-transparent hover:bg-transparent active:bg-transparent"
              >
                <SendIcon
                  width={sizeIcon}
                  height={sizeIcon}
                  className={classNames("", {
                    "text-[#A670FF]": !isDisabled,
                    "text-atherGray-500": isDisabled,
                  })}
                />
              </IconButton>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default CommentPlugin
