import { IconProps } from "."

export const FavoriteIcon = (props: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 16 14" fill="none" {...props}>
      <path
        d="M12.9737 1.66277C11.186 0.566152 9.62562 1.00808 8.68827 1.71201L8.68826 1.71201C8.30393 2.00064 8.11176 2.14495 7.9987 2.14495C7.88563 2.14495 7.69347 2.00064 7.30913 1.71201C6.37178 1.00808 4.81142 0.566152 3.02366 1.66277C0.67741 3.10196 0.146512 7.8499 5.55838 11.8556C6.58918 12.6185 7.10457 13 7.9987 13C8.89282 13 9.40822 12.6185 10.439 11.8556C15.8509 7.8499 15.32 3.10196 12.9737 1.66277Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  )
}
