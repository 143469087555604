import useTelegramInitData from "./telegram/useTelegramInitData"
import _isEmpty from "lodash/isEmpty"
import { Url } from "next/dist/shared/lib/router/router"
import { useRouter } from "next/router"
import { useCallback } from "react"

const useCustomRouter = () => {
  const router = useRouter()
  const telegramInitData = useTelegramInitData()

  const openNewTab = useCallback(
    (
      url: Url | string,
      as?: Url | undefined,
      options?:
        | {
            shallow?: boolean | undefined
            scroll?: boolean | undefined
          }
        | undefined,
    ) => {
      if ((typeof url === "string" && !telegramInitData) || _isEmpty(telegramInitData)) {
        const newUrl = url as string

        window.open(newUrl, "_blank")
      } else {
        router.push(url, as, options)
      }
    },
    [router, telegramInitData],
  )

  return {
    ...router,
    openNewTab,
  }
}

export default useCustomRouter
