import { RecipeTaskWithFullImages, RecipeType } from "@/api/sdk"
import IconButton from "@/components/IconButton"
import Tooltip from "@/components/Tooltip"
import { DeleteIcon } from "@/components/shared/icons"
import { googleAnalytics } from "@/lib/gtag"
import { useDeleteItemStore } from "@/stores"
import { AnimatePresence, motion } from "framer-motion"

interface MultiTasksSelectBarProps {
  selectedItems: RecipeTaskWithFullImages[]
  clear: () => void
}

const MultiTasksSelectBar = ({ selectedItems, clear }: MultiTasksSelectBarProps) => {
  const setDeletingItem = useDeleteItemStore(s => s.setDeletingItem)

  return (
    <AnimatePresence>
      {selectedItems.length > 0 && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="absolute -translate-x-1/2 left-1/2 top-[3rem] z-20"
        >
          <div className="rounded-2xl bg-atherGray-850 py-2 px-4 text-xs font-semibold text-atherGray-300 h-12 flex items-center space-x-4">
            <span className="whitespace-nowrap">{selectedItems.length} selected</span>
            <hr className="w-[1px] h-6 border border-atherGray-800" />
            {/* <button>Select all</button> */}
            {/* <hr className="w-[1px] h-6 border border-atherGray-800" /> */}
            <div className="flex items-center space-x-2">
              <Tooltip
                trigger={
                  <IconButton
                    className="w-8 h-8 p-0 hover:bg-atherGray-700 active:bg-atherGray-800 text-red-500"
                    colorScheme="transparent"
                    onClick={() => {
                      googleAnalytics.handleCategoryEvent({
                        action: "click",
                        params: {
                          action: "Multi Task Delete",
                          ...selectedItems.reduce((acc, item, index) => {
                            acc[`task_id_${index}`] = item.id
                            return acc
                          }, {}),
                        },
                      })

                      setDeletingItem({
                        type: "task",
                        ids: selectedItems.map(item => item.id),
                        id: selectedItems.find(item => item.id)?.id ?? "",
                        deletedAt: selectedItems.find(item => item.deletedAt)?.deletedAt ?? "",
                        recipeParams: {
                          recipeType: RecipeType.Inpaint,
                        },
                        onClose: clear,
                      })
                    }}
                  >
                    <DeleteIcon width={16} height={16} />
                  </IconButton>
                }
              >
                Delete
              </Tooltip>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default MultiTasksSelectBar
