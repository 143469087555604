import client from "@/api/client"
import { PinnedItemType, PinnedItems, RecentSDWorkflowItem, SearchSDWorkflowResponse } from "@/api/sdk"
import { InfiniteData, useQueryClient } from "@tanstack/react-query"
import { createMutation } from "react-query-kit"
import { actionMiddleware } from "../../middlewares/actionMiddleware"
import { useComfyUiWorkflowInfiniteQuery } from "./getComfyUiRecipeQueries"
import { useRecentComfyUIInfiniteQuery } from "@/components/Workspace/RecentV2/RecentComfyUI"
import { useWorkspacePinInfiniteQuery } from "@/queries/workspace"

export interface PublicComfyUIMutationVariables {
  ids: string[]
  isDiscoverable: boolean
}

export const usePublicComfyUIMutation = createMutation({
  mutationFn: ({ ids, isDiscoverable }: PublicComfyUIMutationVariables) =>
    client.api.sdWorkflowControllerUpdateDiscoverySdWorkflow({
      isDiscoverable,
      workflowIds: ids,
    }),
  use: [
    actionMiddleware({
      onSuccess: ({ queryClient, variables }) => {
        // Update comfyui list
        const comfyuiListKey = useComfyUiWorkflowInfiniteQuery.getKey()

        const comfyuiListQueriesDataEntries = queryClient.getQueriesData<
          InfiniteData<SearchSDWorkflowResponse, number>
        >({
          queryKey: comfyuiListKey,
        })

        if (comfyuiListQueriesDataEntries) {
          comfyuiListQueriesDataEntries.forEach(([key, recipeListData]) => {
            if (recipeListData) {
              const updatedData = {
                ...recipeListData,
                pages: recipeListData.pages.map(page => {
                  return {
                    ...page,
                    workflows: page.workflows.map(workflows => {
                      if (variables.ids.includes(workflows.id)) {
                        return {
                          ...workflows,
                          discoverableAt: variables.isDiscoverable ? new Date() : null,
                        }
                      }

                      return workflows
                    }),
                  }
                }),
              }

              queryClient.setQueryData(key, updatedData)
            }
          })
        }

        // update comfyui recent
        const comfyuiRecentKey = useRecentComfyUIInfiniteQuery.getKey()

        const comfyuiRecentQueriesDataEntries = queryClient.getQueriesData<
          InfiniteData<RecentSDWorkflowItem[], number>
        >({
          queryKey: comfyuiRecentKey,
        })

        if (comfyuiRecentQueriesDataEntries) {
          comfyuiRecentQueriesDataEntries.forEach(([key, comfyuiListData]) => {
            if (comfyuiListData) {
              const updatedData = {
                ...comfyuiListData,
                pages: comfyuiListData.pages.map(page => {
                  return [
                    ...page.map(item => {
                      if (variables.ids.includes(item.sdWorkflow.id)) {
                        return {
                          ...item,
                          sdWorkflow: {
                            ...item.sdWorkflow,
                            discoverableAt: variables.isDiscoverable ? new Date() : null,
                          },
                        }
                      }

                      return item
                    }),
                  ]
                }),
              }

              queryClient.setQueryData(key, updatedData)
            }
          })
        }

        // update comfyui pinned
        const comfyuiPinnedKey = useWorkspacePinInfiniteQuery.getKey({
          type: PinnedItemType.SD_WORKFLOW,
        })

        const comfyuiPinnedQueriesDataEntries = queryClient.getQueriesData<InfiniteData<PinnedItems[], number>>({
          queryKey: comfyuiPinnedKey,
        })

        if (comfyuiPinnedQueriesDataEntries) {
          comfyuiPinnedQueriesDataEntries.forEach(([key, pinnedListData]) => {
            if (pinnedListData) {
              const updatedData = {
                ...pinnedListData,
                pages: pinnedListData.pages.map(page => {
                  return [
                    ...page.map(item => {
                      if (variables.ids.includes(item.sdWorkflow?.id ?? "")) {
                        return {
                          ...item,
                          sdWorkflow: {
                            ...item.sdWorkflow,
                            discoverableAt: variables.isDiscoverable ? new Date() : null,
                          },
                        }
                      }

                      return item
                    }),
                  ]
                }),
              }

              queryClient.setQueryData(key, updatedData)
            }
          })
        }
      },
    }),
    useMutationNext => {
      return (options, queryClient) => {
        const qc = useQueryClient(queryClient)

        return useMutationNext({
          ...options,
          onSettled: (_, __, {}) => {},
        })
      }
    },
  ],
})
