import { IconProps } from "."

export const PencilIcon = (props: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none" {...props}>
      <path
        d="M1 14.6654V9.12828C1 8.08451 1 7.56263 1.14303 7.07049C1.28605 6.57835 1.56392 6.14411 2.11966 5.27564L3.8906 2.50816C4.39234 1.72407 4.64321 1.33203 5 1.33203C5.35679 1.33203 5.60766 1.72407 6.1094 2.50816L7.88034 5.27564C8.43608 6.14411 8.71395 6.57835 8.85697 7.07049C9 7.56263 9 8.08451 9 9.12828V14.6654"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.66602 7.33203C2.08703 7.5474 2.65848 7.98082 3.18608 7.99809C3.86522 8.02033 4.32391 7.42352 4.99935 7.42352C5.67479 7.42352 6.13348 8.02033 6.81262 7.99809C7.34022 7.98082 7.91167 7.5474 8.33268 7.33203"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M5 8L5 14.6667" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M3.66602 3.33203H6.33268"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
