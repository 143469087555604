import { create } from "zustand"

interface DeleteItemStore {
  referralModal: boolean
  setReferralModal: (item: DeleteItemStore["referralModal"]) => void
}

export const useReferralStore = create<DeleteItemStore>(set => ({
  referralModal: false,
  setReferralModal: referralModal => set({ referralModal }),
}))
