import { useEffect } from "react"
import isHotkey from "is-hotkey"

export const useCallbackOnHotKeys = (key: string, callback: (event: KeyboardEvent) => void, enabled: boolean) => {
  useEffect(() => {
    if (!enabled) {
      return
    }

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.target instanceof HTMLInputElement || event.target instanceof HTMLTextAreaElement) {
        return
      }
      if (
        (event.target as any)?.className === "ql-editor" ||
        (event.target as any)?.className?.includes("lexical-editor") ||
        (event.target as any)?.nodeName === "W3M-MODAL"
      ) {
        return
      }

      if (isHotkey(key, event)) {
        event.preventDefault()

        callback(event)
      }
    }

    document.addEventListener("keydown", handleKeyDown)

    return () => {
      document.removeEventListener("keydown", handleKeyDown)
    }
  }, [key, callback, enabled])
}

export const useCallbackKeyUpOnHotKeys = (key: string, callback: (event: KeyboardEvent) => void, enabled: boolean) => {
  useEffect(() => {
    if (!enabled) {
      return
    }

    const handkeyUp = (event: KeyboardEvent) => {
      if (event.target instanceof HTMLInputElement || event.target instanceof HTMLTextAreaElement) {
        return
      }

      if (
        (event.target as any).className === "ql-editor" ||
        (event.target as any).className.includes("lexical-editor")
      ) {
        return
      }

      if (isHotkey(key, event)) {
        event.preventDefault()

        callback(event)
      }
    }

    document.addEventListener("keyup", handkeyUp)

    return () => {
      document.removeEventListener("keyup", handkeyUp)
    }
  }, [key, callback, enabled])
}
