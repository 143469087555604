import {
  ThreeDotHorizontalIcon,
  RefreshIcon,
  ScienceIcon,
  EyeIcon,
  DownloadIcon1,
  XIcon,
  DeleteIcon,
} from "@/components/shared/icons"
import { googleAnalytics } from "@/lib/gtag"
import classNames from "classnames"
import React, { useState } from "react"
import FolderActionRow from "../../FolderActions/FolderActionRow"
import { RecipeTask } from "@/api/sdk"
import { useDownloadImages } from "@/hooks/useDownloadImages"
import { useRecipeImageStore } from "@/stores"
import client from "@/api/client"
import Popover from "@/components/Popover"

interface ActionButtonsTaskProps {
  recipe: RecipeTask
  onDelete: () => void
  isLoading: boolean
  onRestart: () => void
  onCancel: () => void
  onCreate: () => void
  onView: () => void
}

const ActionButtonsTask = ({
  recipe,
  isLoading,
  onCancel,
  onCreate,
  onDelete,
  onRestart,
  onView,
}: ActionButtonsTaskProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const downloadImages = useDownloadImages()
  const [isDownloading, setIsDownloading] = useState(false)
  const setRecipeImage = useRecipeImageStore(s => s.setRecipeImage)

  const handleDownloadImages = async () => {
    if (!recipe.images || recipe.images.length === 0) return

    setIsDownloading(true)

    await downloadImages(
      recipe.images.map(i => i.url ?? ""),
      recipe.images.map(i => i.name ?? i.id),
      `${"arventure_task"}_${recipe.id}`,
    ).finally(async () => {
      setIsDownloading(false)
      setIsOpen(false)
      try {
        if (!recipe.images) return

        await client.api.imageControllerDownloadImage({
          imageIds: recipe.images.map(image => image.id),
        })
      } catch (error) {}
    })
  }

  const handleSendRecipe = () => {
    googleAnalytics.event({
      action: "click",
      category: "agent_scheduler",
      label: "recreate",
      params: {
        recipeId: recipe.recipeId,
        taskId: recipe.id,
        status: recipe.status,
      },
    })

    setRecipeImage({
      id: recipe.id,
      isModal: true,
      params: {
        image: recipe.images?.[0]?.url ?? "",
        prompt: recipe.prompt,
        negative_prompt: recipe.params?.["negative_prompt"],
      },
    })

    setIsOpen(false)
  }

  return (
    <Popover
      isOpen={isOpen}
      align="start"
      setIsOpen={setIsOpen}
      trigger={
        <div
          className={classNames(
            "flex items-center select-none p-0 justify-center h-[1.5rem] w-[1.5rem] rounded-full cursor-pointer",
          )}
        >
          <ThreeDotHorizontalIcon width={16} height={16} />
        </div>
      }
    >
      <div
        className={classNames(
          "text-sm text-white w-44 bg-atherGray-900 border border-atherGray-850 p-2 rounded-2xl space-y-1",
        )}
      >
        {(recipe.status === "CANCELED" || recipe.status === "COMPLETED") && (
          <FolderActionRow
            onClick={e => {
              e.stopPropagation()
              googleAnalytics.event({
                action: "click",
                category: "agent_scheduler",
                label: "run_again",
                params: {
                  recipeId: recipe.recipeId,
                  taskId: recipe.id,
                  status: recipe.status,
                },
              })

              setIsOpen(false)

              if (recipe.status === "COMPLETED") {
                onCreate()
                return
              }
              onRestart()
            }}
            className={"flex items-center"}
          >
            <RefreshIcon className="mr-2" width={16} height={16} />
            Run Again
          </FolderActionRow>
        )}
        {recipe.status === "COMPLETED" && (
          <FolderActionRow
            onClick={e => {
              e.stopPropagation()
              handleSendRecipe()
            }}
            className={"flex items-center"}
          >
            <ScienceIcon className="mr-2" width={16} height={16} />
            Recipe
          </FolderActionRow>
        )}
        <FolderActionRow
          onClick={e => {
            e.stopPropagation()
            googleAnalytics.event({
              action: "click",
              category: "agent_scheduler",
              label: "view_details",
              params: {
                recipeId: recipe.recipeId,
                taskId: recipe.id,
                status: recipe.status,
              },
            })

            onView()
            setIsOpen(false)
          }}
          className={"flex items-center "}
        >
          <EyeIcon className="mr-2" width={16} height={16} />
          View Details
        </FolderActionRow>
        {recipe.status === "COMPLETED" && recipe.images && recipe.images?.length > 0 && (
          <FolderActionRow
            isLoading={isDownloading}
            onClick={async e => {
              e.stopPropagation()
              googleAnalytics.event({
                action: "click",
                category: "agent_scheduler",
                label: "download_images",
                params: {
                  recipeId: recipe.recipeId,
                  taskId: recipe.id,
                  status: recipe.status,
                  images_ids: recipe.images?.map(i => i.id).join(", ") ?? "",
                  images_url: recipe.images?.map(i => i.url).join(", ") ?? "",
                },
              })
              await handleDownloadImages()
            }}
            className={"flex items-center"}
          >
            <DownloadIcon1 className="mr-2" width={16} height={16} />
            Download
          </FolderActionRow>
        )}
        {(recipe.status === "QUEUED" || recipe.status === "RUNNING") && (
          <FolderActionRow
            isLoading={isLoading}
            onClick={e => {
              e.stopPropagation()
              googleAnalytics.event({
                action: "click",
                category: "agent_scheduler",
                label: "cancel",
                params: {
                  recipeId: recipe.recipeId,
                  taskId: recipe.id,
                  status: recipe.status,
                },
              })
              onCancel()
              setIsOpen(false)
            }}
            className={"flex items-center"}
          >
            <XIcon className=" text-red-500 mr-2" width={16} height={16} />
            Cancel
          </FolderActionRow>
        )}
        {recipe.status !== "QUEUED" && recipe.status !== "RUNNING" && (
          <FolderActionRow
            onClick={e => {
              e.stopPropagation()
              googleAnalytics.event({
                action: "click",
                category: "agent_scheduler",
                label: "remove",
                params: {
                  recipeId: recipe.recipeId,
                  taskId: recipe.id,
                  status: recipe.status,
                },
              })
              onDelete()
              setIsOpen(false)
            }}
            className={"flex items-center"}
          >
            <DeleteIcon className=" text-red-500 mr-2" width={16} height={16} />
            Remove
          </FolderActionRow>
        )}
      </div>
    </Popover>
  )
}

export default ActionButtonsTask
