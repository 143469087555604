import client from "@/api/client"
import { SearchWildcardResponse } from "@/api/sdk"
import { InfiniteData } from "@tanstack/react-query"
import { createMutation } from "react-query-kit"
import { actionMiddleware } from "@/queries/middlewares/actionMiddleware"
import { useWorkspaceWildcardsInfiniteQuery } from "@/queries/workspace"

interface RemoveStylesMutationVariables {
  ids: string[]
  view?: "owned" | "shared" | "draft"
}

export const useRemoveWildcardsMutation = createMutation({
  mutationFn: async ({ ids }: RemoveStylesMutationVariables) => {
    return client.api
      .wildcardControllerRemoveWildcardsFromLibrary({
        wildcardIds: ids,
      })
      .then(res => res.data)
  },
  use: [
    actionMiddleware({
      onSuccess: ({ queryClient, variables: { ids, view } }) => {
        // Update wildcard list
        const wildcardListKey = useWorkspaceWildcardsInfiniteQuery.getKey()
        const wildcardListQueriesDataEntries = queryClient.getQueriesData<InfiniteData<SearchWildcardResponse, number>>(
          {
            queryKey: wildcardListKey,
          },
        )

        wildcardListQueriesDataEntries?.forEach(([key, data]) => {
          if (!data) return
          let updatedData

          updatedData = {
            ...data,
            pages: data.pages.map(page => {
              const updatedPage = {
                ...page,
                wildcards: page.wildcards.map(style => {
                  if (ids.includes(style.id)) {
                    return {
                      ...style,
                      capabilities: {
                        ...style.capabilities,
                        canAddToLibrary: true,
                        canRemoveFromLibrary: false,
                      },
                    }
                  }
                  return style
                }),
              }
              updatedPage.total = updatedPage.wildcards.length
              return updatedPage
            }),
          }

          if (view !== "shared") {
            updatedData = {
              ...data,
              pages: data.pages.map(page => {
                const updatedPage = {
                  ...page,
                  wildcards: page.wildcards.filter(style => {
                    return !ids.includes(style.id)
                  }),
                }
                updatedPage.total = updatedPage.wildcards.length
                return updatedPage
              }),
            }
          }

          queryClient.setQueryData(key, updatedData)
        })
      },
    }),
  ],
})
