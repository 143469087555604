import { IconProps } from "."

export const WorkoutStretchingIcon = (props: IconProps) => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M16.4277 5.85352C16.4277 6.68194 15.7562 7.35352 14.9277 7.35352C14.0993 7.35352 13.4277 6.68194 13.4277 5.85352C13.4277 5.02509 14.0993 4.35352 14.9277 4.35352C15.7562 4.35352 16.4277 5.02509 16.4277 5.85352Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M14.7879 15.3535L15.7317 14.8075C16.8063 14.1858 17.3436 13.8749 17.4163 13.4319C17.4276 13.3627 17.4305 13.2926 17.4251 13.2229C17.3899 12.7764 16.8801 12.4324 15.8606 11.7442L11.1536 8.56711C9.30491 7.31928 8.87957 5.04465 10.1787 3.35352"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.1536 8.56711C8.65362 11.0671 7.42773 17.9859 7.42773 21.3539M11.1536 8.56711C9.30491 7.31928 8.87957 5.04465 10.1787 3.35352M11.1536 8.56711L13.8002 10.3535M14.7879 15.3535L15.7317 14.8075C16.8063 14.1858 17.3436 13.8749 17.4163 13.4319C17.4276 13.3627 17.4305 13.2926 17.4251 13.2229C17.3899 12.7764 16.8801 12.4324 15.8606 11.7442L13.8002 10.3535M15.4279 21.3539C14.4545 20.2182 13.4535 18.6535 12.4779 17.2114C11.7944 16.2009 11.4526 15.6957 11.4122 15.1668M13.8002 10.3535C12.9975 11.3926 12.4441 12.3742 12.0303 13.2477C11.5914 14.1744 11.3719 14.6378 11.4122 15.1668M11.4122 15.1668L8.42773 14.3535"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
