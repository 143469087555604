import client from "@/api/client"
import { actionMiddleware } from "@/queries/middlewares/actionMiddleware"
import { createMutation } from "react-query-kit"
import { GetChatRoomsResult, UpdateChatRoomDto } from "@/api/sdk"
import { useWorkspaceRoomsInfiniteQuery } from "./useWorkspaceRoomsInfiniteQuery"
import { InfiniteData } from "@tanstack/react-query"
import { useWorkspaceDetailRoomQuery } from "./useWorkspaceDetailRoomQuery"

export const useUpdateRoomMutation = createMutation({
  mutationFn: async ({ roomId, data }: { roomId: string; data: UpdateChatRoomDto }) => {
    return await client.api.chatRoomControllerUpdateChatRoom(roomId, data).then(res => res.data)
  },
  use: [
    actionMiddleware({
      onSuccess: ({ queryClient, variables }) => {
        const workspaceRoomsKey = useWorkspaceRoomsInfiniteQuery.getKey()

        const roomsEntities = queryClient.getQueriesData<InfiniteData<GetChatRoomsResult, number>>({
          queryKey: workspaceRoomsKey,
        })

        roomsEntities?.forEach(([key, entitiesListData]) => {
          if (entitiesListData) {
            const updatedEntities = {
              ...entitiesListData,
              pages: entitiesListData.pages.map(page => ({
                ...page,
                rooms: page.rooms.map(entity =>
                  entity.id === variables.roomId ? { ...entity, ...variables.data } : entity,
                ),
              })),
            }

            queryClient.setQueryData(key, updatedEntities)
          }
        })

        const detailRoomKey = useWorkspaceDetailRoomQuery.getKey({
          roomId: variables.roomId,
        })

        queryClient.invalidateQueries({ queryKey: detailRoomKey })
      },
    }),
  ],
})
