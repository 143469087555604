import client from "@/api/client"
import { FolderDetail, FoldersSortByColumn, GetSubFoldersResponse, SubfolderView } from "@/api/sdk"
import { createAuthenticatedInfiniteQuery, createAuthenticatedQuery } from "../../createAuthenticatedQuery"

export interface GetSubFoldersQueryVariables {
  folderId?: string
  search?: string
  sortBy?: FoldersSortByColumn
  sortOrder?: string
  ownerUids?: string[]
  canUpload?: boolean
  view?: SubfolderView
  take?: number
  isPinned?: boolean
}

export const useSubFoldersInfiniteQuery = createAuthenticatedInfiniteQuery<
  GetSubFoldersResponse,
  GetSubFoldersQueryVariables,
  Error,
  FolderDetail
>({
  primaryKey: "sub-folders-infinite",
  queryFn: ({ pageParam = 0, queryKey: [_primaryKey, variables] }) =>
    client.api
      .folderControllerGetSubFolders({
        skip: pageParam * 20,
        take: variables.take ?? 20,
        folderId: variables.folderId,
        search: variables.search,
        sortBy: variables.sortBy,
        sortOrder: variables.sortOrder,
        ownerUids: variables.ownerUids,
        canUpload: variables.canUpload,
        view: variables.view,
        isPinned: variables.isPinned,
      })
      .then(res => res.data),
  getNextPageParam: (lastPage, pages) => (lastPage && lastPage.folders.length < 20 ? undefined : pages.length),
  initialPageParam: 0,
  refetchOnWindowFocus: false,
  flattenFn: data => data.folders,
  getTotalFn: data => data.total,
})

export const useSubFoldersQuery = createAuthenticatedQuery<GetSubFoldersResponse, GetSubFoldersQueryVariables, Error>({
  primaryKey: "preview-sub-folders",
  queryFn: ({ queryKey: [_primaryKey, variables] }) =>
    client.api
      .folderControllerGetSubFolders({
        skip: 0,
        take: 8,
        folderId: variables.folderId,
        search: variables.search,
        sortBy: variables.sortBy,
        sortOrder: variables.sortOrder,
        ownerUids: variables.ownerUids,
        view: variables.view,
      })
      .then(res => res.data),
})
