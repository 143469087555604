import { IconProps } from "."

export const SendIcon = (props: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M14.0331 2.03561C12.5811 0.471901 1.65896 4.30246 1.66797 5.70099C1.6782 7.2869 5.93336 7.7748 7.11278 8.1057C7.82203 8.30468 8.01198 8.50863 8.17553 9.25237C8.91619 12.6206 9.28805 14.296 10.1356 14.3334C11.4865 14.3931 15.4502 3.56166 14.0331 2.03561Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M7.66797 8.33333L10.0013 6"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
