export enum ComfyUINodeBaseInputType {
  INT = "INT",
  FLOAT = "FLOAT",
  STRING = "STRING",
  BOOLEAN = "BOOLEAN",
  MODEL = "MODEL",
}

export type ComfyUINodeInputType = ComfyUINodeBaseInputType | string

export type ComfyUINodeInputOptions = Record<string, any> & {
  default?: any
  min?: number
  max?: number
  step?: number
  multiline?: boolean
  dynamicPrompts?: boolean
}

export type ComfyUINodeInput =
  | [ComfyUINodeBaseInputType]
  | [ComfyUINodeBaseInputType, ComfyUINodeInputOptions]
  | [string[]]
  | [string[], { default?: string }]

export type ComfyUINodeInputs = {
  required?: {
    [k: string]: ComfyUINodeInput
  }
  optional?: {
    [k: string]: ComfyUINodeInput
  }
  hidden?: {
    [k: string]: ComfyUINodeInput
  }
}

export type ComfyUINodeInfo = {
  name: string

  display_name: string

  category: string

  input: ComfyUINodeInputs

  output: ComfyUINodeInputType[]

  output_is_list: boolean[]

  output_name: string[]

  output_node: boolean
}

export type ComfyUINodeInfos = {
  [k: string]: ComfyUINodeInfo
}

type ComfyUIGraphLink = [number, number, number, number, number, ComfyUINodeInputType]

type ComfyUIGraphNodeInput = {
  name: string
  type: ComfyUINodeInputType
  link?: number
  slot_index: number
  widget?: any
}

type ComfyUIGraphNodeOutput = {
  name: number
  type: ComfyUINodeInputType
  links?: number[]
  slot_index: number
}

export type ComfyUIGraphNode = {
  id: number
  type: string
  mode: number
  inputs?: ComfyUIGraphNodeInput[]
  outputs?: ComfyUIGraphNodeOutput[]
  widgets_values: any[]
}

export interface IComfyUIGraph {
  nodes: ComfyUIGraphNode[]
  links: ComfyUIGraphLink[]
}

export type ComfyUIGraphReroute = {
  id: number
  parentId: number
  pos: [number, number]
  linkIds: number[]
}

export type ComfyUIGraphExtra = {
  ds?: {
    scale: number
    offset: [number, number]
  }
  reroutes?: Array<ComfyUIGraphReroute>
}

export type ComfyUIGraphGroup = {
  title: string
  bounding: [number, number, number, number]
  color?: string
  font_size?: number
  locked?: boolean
}

export type ComfyUIGraph = {
  nodes: ComfyUIGraphNode[]
  links: ComfyUIGraphLink[]
  groups: ComfyUIGraphGroup[]
  reroutes?: ComfyUIGraphReroute[]
  extra?: ComfyUIGraphExtra
  last_link_id: number
  last_node_id: number
}
