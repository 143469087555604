import client from "@/api/client"
import { SharingPlatform } from "@/api/sdk"
import { actionMiddleware } from "@/queries/middlewares/actionMiddleware"
import { createMutation } from "react-query-kit"

export const useIncreaseSocialQuestMutation = createMutation({
  mutationFn: async (variables: { imageId: string; platform: SharingPlatform }) => {
    return await client.api
      .questControllerIncreaseSocialQuestClaimable({ imageId: variables.imageId, platform: variables.platform })
      .then(res => res.data)
  },
  use: [
    actionMiddleware({
      onSuccess: ({ queryClient, variables }) => {},
    }),
  ],
})
