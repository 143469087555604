import { IconProps } from "."

interface CursorIconProps extends IconProps {
  color?: string
}

export const CursorIcon = ({ color = "transparent", ...props }: CursorIconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.08116 6.3907L13.0743 25.3897C13.4183 26.2073 14.5775 26.2055 14.919 25.3869L16.7513 20.994L20.8787 25.1214C22.0503 26.293 23.9498 26.293 25.1214 25.1214C26.2929 23.9498 26.2929 22.0503 25.1214 20.8787L20.9939 16.7513L25.3868 14.919C26.2055 14.5775 26.2073 13.4183 25.3897 13.0743L6.39071 5.08117C5.56459 4.7336 4.7336 5.56458 5.08116 6.3907Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0546 17.4689L22.2929 23.7072C22.6835 24.0977 23.3166 24.0977 23.7072 23.7072C24.0977 23.3166 24.0977 22.6835 23.7072 22.293L17.4688 16.0546L22.4137 13.9921L7.87574 7.87573L13.9921 22.4137L16.0546 17.4689ZM25.3868 14.919C26.2055 14.5775 26.2073 13.4183 25.3897 13.0743L6.39071 5.08117C5.56459 4.7336 4.7336 5.56458 5.08116 6.3907L13.0743 25.3897C13.4183 26.2073 14.5775 26.2055 14.919 25.3869L16.7513 20.994L20.8787 25.1214C22.0503 26.293 23.9498 26.293 25.1214 25.1214C26.2929 23.9498 26.2929 22.0503 25.1214 20.8787L20.9939 16.7513L25.3868 14.919Z"
        fill="currentColor"
      />
    </svg>
  )
}
