import client from "@/api/client"
import { SearchModelResults } from "@/api/sdk"
import { InfiniteData } from "@tanstack/react-query"
import { createMutation } from "react-query-kit"
import { actionMiddleware } from "@/queries/middlewares/actionMiddleware"
import { useWorkspaceModelsInfiniteQuery } from "./useWorkspaceModelsInfiniteQuery"

interface RemoveStylesMutationVariables {
  ids: string[]
  view?: "owned" | "shared" | "draft"
}

export const useRemoveModelsMutation = createMutation({
  mutationFn: async ({ ids }: RemoveStylesMutationVariables) => {
    return client.api
      .sdModelControllerRemoveSdModelsFromLibrary({
        modelIds: ids,
      })
      .then(res => res.data)
  },
  use: [
    actionMiddleware({
      onSuccess: ({ queryClient, variables: { ids, view } }) => {
        // Update model list
        const modelListKey = useWorkspaceModelsInfiniteQuery.getKey()
        const modelListQueriesDataEntries = queryClient.getQueriesData<InfiniteData<SearchModelResults, number>>({
          queryKey: modelListKey,
        })

        modelListQueriesDataEntries?.forEach(([key, data]) => {
          if (!data) return
          let updatedData

          updatedData = {
            ...data,
            pages: data.pages.map(page => {
              const updatedPage = {
                ...page,
                data: page.data.map(model => {
                  if (ids.includes(model.id)) {
                    return {
                      ...model,
                      capabilities: {
                        ...model.capabilities,
                        canAddToLibrary: true,
                        canRemoveFromLibrary: false,
                      },
                    }
                  }
                  return model
                }),
              }
              updatedPage.total = updatedPage.data.length
              return updatedPage
            }),
          }

          if (view !== "shared") {
            updatedData = {
              ...data,
              pages: data.pages.map(page => {
                const updatedPage = {
                  ...page,
                  data: page.data.filter(style => {
                    return !ids.includes(style.id)
                  }),
                }
                updatedPage.total = updatedPage.data.length
                return updatedPage
              }),
            }
          }

          queryClient.setQueryData(key, updatedData)
        })
      },
    }),
  ],
})
