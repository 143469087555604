import client from "@/api/client"
import { CancelSubscriptionDto, PurchaseCreditPackageDto, UserDetail } from "@/api/sdk"
import { createMutation } from "react-query-kit"
import { actionMiddleware } from "../middlewares/actionMiddleware"

export const useUnsubscribePlanMutate = createMutation({
  mutationFn: ({ data }: { data: CancelSubscriptionDto; userUid: string }) =>
    client.api.paymentControllerCancelSubscription(data).then(res => res.data),

  use: [
    actionMiddleware({
      onMutate: ({ queryClient, variables: { data, userUid } }) => {
        const userKey = ["user-info", userUid]

        const userQueryData = queryClient.getQueryData<UserDetail>(userKey)

        if (userQueryData) {
          queryClient.setQueryData<UserDetail>(userKey, {
            ...userQueryData,
            ...data,
          })
        }
      },
    }),
  ],
})
