import { PinnedItemType, SDStyleDetail } from "@/api/sdk"
import Popover from "@/components/Popover"
import {
  AddSquareIcon,
  ArrowDownIcon,
  DeleteIcon,
  EditIcon1,
  LayerAddIcon,
  PinIcon,
  RemoveSquareIcon,
  ShareIcon8,
  UnPinIcon,
} from "@/components/shared/icons"
import FolderActionRow from "@/components/Workspace/FolderActions/FolderActionRow"
import { useToast } from "@/hooks"
import { googleAnalytics } from "@/lib/gtag"
import useModalStore from "@/lib/store"
import { useAuth } from "@/providers/authContext"
import { usePinMutation } from "@/queries"
import {
  useAddStyleToLibraryMutation,
  useRemoveStyleFromLibraryMutation,
  useRenameStyleMutation,
} from "@/queries/tools/style/useUpdateStyleMutation"
import { useDeleteItemStore } from "@/stores"
import useCustomRouter from "@/hooks/useCustomRouter"
import React, { Fragment, useState } from "react"

interface StyleContainerActionsProps {
  style: SDStyleDetail
  isDraft?: boolean
  onInputNameChange: (name: string) => void
  onDuplicateStyle: ({ styleId }: { styleId: string }) => void
  inputName: string
  isDuplicating?: boolean
}

const StyleContainerActions = ({
  style,
  isDraft,
  onInputNameChange,
  inputName,
  onDuplicateStyle,
  isDuplicating,
}: StyleContainerActionsProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const toast = useToast()
  const { user } = useAuth()
  const setSharingData = useModalStore(state => state.setSharingData)
  const setDeletingItem = useDeleteItemStore(state => state.setDeletingItem)
  const router = useCustomRouter()

  const isDisabled = style?.creator?.uid !== user?.uid

  const { mutate: mutateRenameStyle } = useRenameStyleMutation({
    onSettled() {
      setIsOpen(false)
    },
    onError() {
      toast({
        status: "error",
        title: "Error",
        message: ["Something went wrong"],
      })
    },
  })

  const handleRename = () => {
    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        action: "Rename Style",
        style_id: style.id,
        style_name: style.name ?? "",
      },
    })

    setIsEdit(prev => !prev)

    setIsOpen(false)

    if (!inputName) return onInputNameChange(style?.name ?? "")

    if (!style || style.name === inputName) return

    return mutateRenameStyle({ styleId: style.id, data: { name: inputName } })
  }

  const handleShare = () => {
    if (!style) return

    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        action: "Share Style",
        style_id: style.id,
        style_name: style.name ?? "",
      },
    })

    setSharingData({
      id: style.id,
      name: style.name ?? style.id,
      link: `${window.location.origin}/workspace/tools/styles/${style.id}`,
      type: "style",
      creator: style.creator,
      workspaceId: style.workspace.id,
      url: "",
    })
  }

  const handleDelete = () => {
    if (!style) return

    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        action: "Delete Style",
        style_id: style.id,
        style_name: style.name ?? "",
      },
    })

    setDeletingItem({
      id: style.id,
      type: "style",
      deletedAt: style.deletedAt,
      onClose: () => {
        router.push(isDraft ? "/workspace/tools/styles?tab=drafts" : "/workspace/tools/styles")
      },
    })
  }

  const { mutate: addToLibrary, isPending: isAddingToLibrary } = useAddStyleToLibraryMutation({
    onSuccess() {
      googleAnalytics.handleCategoryEvent({
        action: "click",
        params: {
          action: "Add Style to Library",
          style_id: style.id,
          style_name: style.name ?? "",
        },
      })

      toast({
        status: "success",
        title: "Style added",
        message: ["Style has been added to your library"],
      })
    },
  })

  const { mutate: removeFromLibrary, isPending: isRemovingFromLibrary } = useRemoveStyleFromLibraryMutation({
    onSuccess() {
      googleAnalytics.handleCategoryEvent({
        action: "click",
        params: {
          action: "Remove Style from Library",
          style_id: style.id,
          style_name: style.name ?? "",
        },
      })
      toast({
        status: "success",
        title: "Style removed",
        message: ["Style has been removed from your library"],
      })
    },
  })

  const { mutate: mutatePinTask, isPending: isPendingPinTask } = usePinMutation({
    onSuccess: () => {
      toast({
        status: "success",
        title: `Style ${style?.pinned ? "unpinned" : "pinned"}`,
      })
    },
  })

  const handlePin = () => {
    if (!style) return

    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        action: style?.pinned ? "Unpin Style" : "Pin Style",
        style_id: style.id,
        style_name: style.name ?? "",
      },
    })

    mutatePinTask({ ids: [style?.id], type: PinnedItemType.STYLE, isPinned: !style.pinned })
  }

  const actions = [
    {
      label: "Share",
      icon: <ShareIcon8 width={16} height={16} />,
      onClick: handleShare,
      visible: style?.capabilities?.canShare && style?.isDraft === false,
      enabled: style?.capabilities?.canShare,
    },
    {
      label: style?.pinned ? "Unpin" : "Pin",
      icon: style?.pinned ? <UnPinIcon width={16} height={16} /> : <PinIcon width={16} height={16} />,
      onClick: handlePin,
      visible: style?.capabilities?.canView && !style?.deletedAt,
      enabled: style?.capabilities?.canView,
    },
    {
      label: "Rename",
      icon: <EditIcon1 width={16} height={16} />,
      onClick: handleRename,
      visible: !isDisabled,
      enabled: style?.capabilities?.canUpdate,
    },
    {
      label: "Add to My Styles",
      icon: <AddSquareIcon width={16} height={16} />,
      onClick: () => addToLibrary({ styleId: style.id }),
      visible: style.capabilities?.canAddToLibrary && !style.deletedAt,
      enabled: !isRemovingFromLibrary,
      isLoading: isAddingToLibrary,
    },
    {
      label: "Remove from My Styles",
      icon: <RemoveSquareIcon width={16} height={16} />,
      onClick: () => removeFromLibrary({ styleId: style.id }),
      visible: style.capabilities?.canRemoveFromLibrary && !style.deletedAt,
      enabled: !isAddingToLibrary,
      isLoading: isRemovingFromLibrary,
    },
    {
      label: "Delete",
      icon: <DeleteIcon className="text-red-500" width={16} height={16} />,
      onClick: handleDelete,
      visible: !isDisabled,
      enabled: style?.capabilities?.canDelete,
      isLoading: false,
    },
  ]

  const actionsMobile = [
    {
      label: "Duplicate",
      icon: <LayerAddIcon width={16} height={16} />,
      onClick: () => onDuplicateStyle({ styleId: style.id }),
      visible: style.capabilities?.canUpdate && !style.deletedAt && !isDraft,
      enabled: !isDuplicating,
      isLoading: isDuplicating,
    },
    // {
    //   label: isEditing === false ? "Edit" : "Update",
    //   icon: <EditIcon width={16} height={16} />,
    //   onClick: isEditing === false ? onEdit : onSave,
    //   visible: style.capabilities?.canUpdate && !style.deletedAt && !isDraft,
    //   enabled: !isSaving,
    //   isLoading: isSaving,
    // },
  ]

  if (!style) return null

  return (
    <Fragment>
      {isEdit ? (
        <input
          autoFocus
          maxLength={100}
          className="bg-transparent w-full md:text-center max-w-[40rem] font-semibold"
          value={inputName}
          onChange={e => onInputNameChange(e.target.value)}
          onKeyDown={e => {
            if (e.key === "Enter") {
              handleRename()
            }
          }}
          onBlur={() => handleRename()}
        />
      ) : (
        <Popover
          className="w-full overflow-hidden"
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          trigger={
            <button type="button" className="overflow-hidden px-4 max-w-[40rem] flex items-center cursor-pointer">
              <p className="font-semibold flex-1 truncate  w-full md:w-auto">{inputName}</p>
              <span className="ml-2 text-atherGray-500">
                <ArrowDownIcon />
              </span>
            </button>
          }
        >
          <div className={"text-sm w-[16rem] bg-atherGray-900 border border-atherGray-850 p-2 rounded-2xl space-y-1"}>
            <div className="block md:hidden space-y-1">
              {actionsMobile
                .filter(a => a.visible)
                .map(action => (
                  <FolderActionRow
                    key={action.label}
                    isLoading={action.isLoading}
                    iconLeft={action.icon}
                    onClick={e => {
                      e.stopPropagation()
                      action.onClick()
                      setIsOpen(false)
                    }}
                    isDisabled={!action.enabled}
                  >
                    {action.label}
                  </FolderActionRow>
                ))}
            </div>
            {actions
              .filter(a => a.visible)
              .map(action => (
                <FolderActionRow
                  key={action.label}
                  isLoading={action.isLoading}
                  iconLeft={action.icon}
                  onClick={e => {
                    e.stopPropagation()
                    action.onClick()
                    setIsOpen(false)
                  }}
                  isDisabled={!action.enabled}
                >
                  {action.label}
                </FolderActionRow>
              ))}
          </div>
        </Popover>
      )}
    </Fragment>
  )
}

export default StyleContainerActions
