import { RecentWorkflowItem as RecentWorkflowItemType, RecipeTaskStatus } from "@/api/sdk"
import React, { memo } from "react"
import WorkflowItem from "../../Workflows/WorkflowItem"
import RecentAccordion from "../RecentAccordion"
import Link from "next/link"
import useCustomRouter from "@/hooks/useCustomRouter"

interface RecentWorkflowItemProps {
  name: string
  recent: RecentWorkflowItemType[]
  containerWidth: number
  layout: "grid" | "list"
}

const RecentWorkflowItem = ({ name, containerWidth, recent, layout }: RecentWorkflowItemProps) => {
  const router = useCustomRouter()

  if (recent.length === 0) return null
  return (
    <RecentAccordion name={name} layout={layout}>
      {recent?.map(item => {
        return (
          <div key={item.id} className={layout === "grid" ? "h-[16rem]" : ""}>
            <Link
              href={`/workspace/macros/${item.workflow?.id}${
                item.workflow?.status === RecipeTaskStatus.DRAFT ? "?draft=true" : ""
              }`}
              shallow
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()
              }}
            >
              <WorkflowItem
                containerWidth={containerWidth}
                accessedAt={item.accessedAt}
                layout={layout}
                onSelect={() =>
                  router.push(
                    `/workspace/macros/${item.workflow?.id}${
                      item.workflow?.status === RecipeTaskStatus.DRAFT ? "?draft=true" : ""
                    }`,
                    undefined,
                    { shallow: true },
                  )
                }
                stretch
                className="h-full"
                data={item.workflow}
              />
            </Link>
          </div>
        )
      })}
    </RecentAccordion>
  )
}

export default memo(RecentWorkflowItem)
