import { IconProps } from "."

export const CameraTripodIcon = (props: IconProps) => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_1804_22670)">
        <path
          d="M2.71289 10.3535C2.71289 6.84687 2.71289 5.09354 3.62085 3.91344C3.78707 3.6974 3.97278 3.4993 4.17532 3.322C5.28167 2.35352 6.92541 2.35352 10.2129 2.35352H15.2129C18.5004 2.35352 20.1441 2.35352 21.2505 3.322C21.453 3.4993 21.6387 3.6974 21.8049 3.91344C22.7129 5.09354 22.7129 6.84687 22.7129 10.3535C22.7129 13.8602 22.7129 15.6135 21.8049 16.7936C21.6387 17.0096 21.453 17.2077 21.2505 17.385C20.1441 18.3535 18.5004 18.3535 15.2129 18.3535H10.2129C6.92541 18.3535 5.28167 18.3535 4.17532 17.385C3.97278 17.2077 3.78707 17.0096 3.62085 16.7936C2.71289 15.6135 2.71289 13.8602 2.71289 10.3535Z"
          stroke="currentColor"
          strokeWidth="1.5"
        />
        <path
          d="M17.7129 22.3535L13.5462 18.3535M7.71289 22.3535L11.8796 18.3535"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.7129 18.3535L12.7129 23.3535"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle cx="12.7129" cy="9.85352" r="3" stroke="currentColor" strokeWidth="1.5" />
      </g>
      <defs>
        <clipPath id="clip0_1804_22670">
          <rect width="24" height="24" fill="white" transform="translate(0.712891 0.353516)" />
        </clipPath>
      </defs>
    </svg>
  )
}
