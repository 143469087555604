import client from "@/api/client"
import { ArticleCategory, ArticleDetail, ArticleStatus, GetArticlesResult, SortOptions } from "@/api/sdk"
import { createAuthenticatedInfiniteQuery } from "../createAuthenticatedQuery"

export interface UseExploreArticlesInfiniteQueryVariables {
  sortBy?: SortOptions
  tagIds?: number[]
  searchTerm?: string
  category?: ArticleCategory[]
  onlyFavorite?: boolean
  onlyMine?: boolean
  creatorUid?: string
  take?: number
  statuses?: ArticleStatus[]
  userUid?: string
}

export const useExploreArticlesInfiniteQuery = createAuthenticatedInfiniteQuery<
  GetArticlesResult,
  UseExploreArticlesInfiniteQueryVariables,
  Error,
  ArticleDetail
>({
  auth: false,
  primaryKey: "infinite-explore-articles",
  queryFn: ({
    pageParam = 0,
    queryKey: [
      _primaryKey,
      { category, creatorUid, onlyFavorite, onlyMine, searchTerm, sortBy, tagIds, take, statuses },
    ],
  }) =>
    client.api
      .articleControllerGetArticles({
        skip: pageParam * (take ?? 50),
        take: take ?? 50,
        category: category ? (category as ArticleCategory[]) : undefined,
        tagIds,
        sortBy: sortBy as SortOptions,
        onlyFavorite,
        statuses,
        searchTerm,
        onlyMine,
        creatorUid,
      })
      .then(res => res.data),
  getNextPageParam: (lastPage, pages) => (lastPage && lastPage.articles.length < 50 ? undefined : pages.length),
  initialPageParam: 0,
  flattenFn: data => data.articles,
})
