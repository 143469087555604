import client from "@/api/client"
import { actionMiddleware } from "@/queries/middlewares/actionMiddleware"
import { createMutation } from "react-query-kit"
import { AddRoomRecipesDto, CreateChatRoomDto } from "@/api/sdk"
import { useWorkspaceRoomsInfiniteQuery } from "./useWorkspaceRoomsInfiniteQuery"

export const useCreateRoomMutation = createMutation({
  mutationFn: async (data: CreateChatRoomDto) => {
    return await client.api.chatRoomControllerCreateChatRoom(data).then(res => res.data)
  },
  use: [
    actionMiddleware({
      onSuccess: ({ queryClient, variables }) => {
        const workspaceRoomsKey = useWorkspaceRoomsInfiniteQuery.getKey()

        queryClient.invalidateQueries({ queryKey: workspaceRoomsKey })
      },
    }),
  ],
})

export const useCreateRoomRecipesMutation = createMutation({
  mutationFn: async ({ roomId, data }: { roomId: string; data: AddRoomRecipesDto }) => {
    return await client.api.chatRoomControllerAddRoomRecipes(roomId, data).then(res => res.data)
  },
  use: [
    actionMiddleware({
      onSuccess: ({ queryClient, variables }) => {
        // const workspaceRoomsKey = useWorkspaceRoomsInfiniteQuery.getKey()
        // queryClient.invalidateQueries({ queryKey: workspaceRoomsKey })
      },
    }),
  ],
})

export const useCanUserCreateRoomMutation = createMutation({
  mutationFn: async () => {
    return await client.api.chatRoomControllerCanUserCreateRoom().then(res => res.data)
  },
})
