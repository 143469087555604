import { CreatorProfileItem } from "@/api/sdk"
import React from "react"
import UploadProfileImageCover from "./UploadProfileImageCover"

interface BannerProfileUserProps {
  dataUserProfile?: CreatorProfileItem
  isMe: boolean
}

const BannerProfileUser = ({ dataUserProfile, isMe }: BannerProfileUserProps) => {
  return (
    <div className="w-full h-full max-h-[17.5rem] flex items-center justify-center relative overflow-hidden transform-gpu">
      <div className="w-full">
        <UploadProfileImageCover
          className="h-full"
          ratio={72 / 15}
          url={dataUserProfile?.coverImageUrl ?? ""}
          positionY={dataUserProfile?.coverImageY}
          disabled={!isMe}
        />
      </div>
    </div>
  )
}

export default BannerProfileUser
