import Input from "@/components/Input"
import LoadingIcon from "@/components/LoadingIcon"
import Popover from "@/components/Popover"
import { CloseIcon, SearchIcon, XIcon } from "@/components/shared/icons"
import { useCallbackOnHotKeys, useScreen } from "@/hooks"
import { SearchHistoryItem } from "@/stores"
import { cn } from "@/utils/cn"
import { AnimatePresence, motion } from "framer-motion"
import useCustomRouter from "@/hooks/useCustomRouter"
import { Fragment, ReactNode, useEffect, useMemo, useRef, useState } from "react"

interface SearchBarProps {
  className?: string
  onConfirmSearch?: (search: string) => void
  history?: SearchHistoryItem[]
  onClearSearchHistory?: (item: SearchHistoryItem) => void
  previewResultBuilder?: (search: string) => ReactNode | null
  search: string
  onFocus?: (focus: boolean) => void
  setSearch: (search: string) => void
  onClear?: () => void
  placeholder?: string
  disabledSearchQuery?: boolean
  mobileClassName?: string
  disabledHotkey?: boolean
  title?: string
  isMobileMode?: boolean
  containerClassName?: string
  inputClassName?: string
  hiddenClearInput?: boolean
  sizeIcon?: number
  isLoading?: boolean
  onClick?: () => void
}

const SearchBar = ({
  inputClassName,
  onConfirmSearch,
  disabledHotkey,
  sizeIcon = 20,
  hiddenClearInput,
  history,
  onClick,
  isLoading,
  onClearSearchHistory,
  previewResultBuilder,
  containerClassName,
  disabledSearchQuery,
  mobileClassName,
  title = "Results",
  search,
  onFocus,
  isMobileMode = true,
  placeholder,
  className,
  setSearch,
  onClear,
}: SearchBarProps) => {
  const formRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const router = useCustomRouter()
  const { width } = useScreen()
  const isMobile = width < 768
  const [formWidth, setFormWidth] = useState(0)
  const [isFocused, setIsFocused] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (formRef.current && isOpen) {
      setFormWidth(formRef.current.offsetWidth)
    }
  }, [isOpen])

  useEffect(() => {
    if (router.isReady && !disabledSearchQuery) {
      setSearch((router.query.search as string) ?? "")
    }
  }, [router.isReady, disabledSearchQuery])

  const handleConfirmSearch = () => {
    onConfirmSearch?.(search)
    // blur input
    inputRef.current?.blur()
  }

  useCallbackOnHotKeys(
    "f",
    () => {
      if (disabledHotkey) return
      if (document.getElementsByClassName("modal").length > 0) return

      setIsOpen(true)
      inputRef.current?.focus()
    },
    !isFocused,
  )

  const previewResult = useMemo(() => previewResultBuilder?.(search), [search, previewResultBuilder])

  const hasResults = !!previewResult || (history && history.length > 0)

  return (
    <Fragment>
      {isMobile && isMobileMode ? (
        <div className={containerClassName} onClick={onClick}>
          <Popover
            focusOnOpen={false}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            trigger={
              <div className="flex items-center p-2">
                <SearchIcon width={sizeIcon} height={sizeIcon} />
              </div>
            }
          >
            <div className={cn("relative rounded-xl bg-atherGray-900", mobileClassName)}>
              <div ref={formRef} className={cn("flex items-center", className)}>
                <Input
                  containerClassName="w-full"
                  ref={inputRef}
                  className={cn("bg-transparent rounded-lg", inputClassName)}
                  onKeyDown={e => {
                    if (e.key === "Enter") {
                      e.preventDefault()
                      e.stopPropagation()
                      handleConfirmSearch()
                    }
                    if (e.key === "Escape") {
                      e.preventDefault()
                      e.stopPropagation()
                      setIsOpen(false)
                      inputRef.current?.blur()
                    }
                  }}
                  value={search}
                  onChange={e => {
                    setSearch(e.target.value)
                  }}
                  onFocus={() => {
                    setIsFocused(true)
                  }}
                  onBlur={() => {
                    setIsFocused(false)
                  }}
                  placeholder={placeholder ?? "Search"}
                  rightIcon={
                    search ? (
                      hiddenClearInput ? null : (
                        <XIcon
                          width={12}
                          height={12}
                          onClick={e => {
                            e.preventDefault()
                            e.stopPropagation()
                            setSearch("")
                            onClear?.()
                            setIsOpen(false)
                          }}
                        />
                      )
                    ) : (
                      <AnimatePresence initial={false} mode="popLayout">
                        {isLoading ? (
                          <motion.span
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            key="loading"
                          >
                            <LoadingIcon width={sizeIcon} height={sizeIcon} />
                          </motion.span>
                        ) : (
                          <motion.span
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            key="search"
                          >
                            <SearchIcon
                              width={sizeIcon}
                              height={sizeIcon}
                              onClick={e => {
                                e.preventDefault()
                                e.stopPropagation()
                                handleConfirmSearch()
                              }}
                            />
                          </motion.span>
                        )}
                      </AnimatePresence>
                    )
                  }
                />
              </div>
              {hasResults && (
                <div className="p-2  border-atherGray-850 flex w-full flex-col">
                  {!!previewResult && (
                    <div>
                      {title && <h3 className="text-xs text-atherGray-100 font-semibold mb-2 px-1 pt-1">{title}</h3>}
                      <div>{previewResult}</div>
                    </div>
                  )}
                  {!!previewResult && history && history.length > 0 && <hr className="border-atherGray-800 my-2" />}
                  {history && history.length > 0 && (
                    <div>
                      <h3 className="text-xs text-atherGray-100 font-semibold mb-2 px-1 pt-1">Recent searches</h3>
                      <div className="space-y-1">
                        {history?.map(item => (
                          <div
                            onClick={() => {
                              setSearch(item.text)
                              onConfirmSearch?.(item.text ?? "")
                              setIsOpen(false)
                            }}
                            key={`${item.location}-${item.text}`}
                            className="flex w-full justify-between items-center hover:bg-atherGray-850 cursor-pointer p-1 px-2 rounded-xl "
                          >
                            <p className="flex-1 truncate text-sm text-atherGray-300">{item.text}</p>
                            <button
                              type="button"
                              className="text-atherGray-500 ml-4"
                              onClick={e => {
                                e.stopPropagation()
                                onClearSearchHistory?.(item)
                              }}
                            >
                              <CloseIcon width={12} height={12} />
                            </button>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </Popover>
        </div>
      ) : (
        <div className={containerClassName} onClick={onClick}>
          <Popover
            focusOnOpen={false}
            isOpen={isOpen && hasResults}
            setIsOpen={setIsOpen}
            trigger={
              <div ref={formRef} className={cn("flex items-center", className)}>
                <Input
                  ref={inputRef}
                  containerClassName={className}
                  onFocus={() => {
                    setIsFocused(true)
                    onFocus?.(true)
                  }}
                  onBlur={() => {
                    setIsFocused(false)
                    onFocus?.(false)
                  }}
                  className={cn("bg-atherGray-900 pr-16", inputClassName)}
                  onKeyDown={e => {
                    if (e.key === "Enter") {
                      e.preventDefault()
                      e.stopPropagation()
                      handleConfirmSearch()
                    }
                  }}
                  value={search}
                  onChange={e => {
                    setSearch(e.target.value)
                  }}
                  placeholder={placeholder ?? "Search"}
                  rightIcon={
                    <div className="flex">
                      <div className="text-xs mr-1 flex items-center justify-center">
                        {search ? (
                          hiddenClearInput ? null : (
                            <span className="px-1">
                              <XIcon
                                width={12}
                                height={12}
                                onClick={e => {
                                  e.preventDefault()
                                  e.stopPropagation()
                                  setSearch("")
                                  onClear?.()
                                  setIsOpen(false)
                                }}
                              />
                            </span>
                          )
                        ) : disabledHotkey || isFocused ? null : (
                          <span className="bg-atherGray-800 border font-semibold border-atherGray-700 px-1">F</span>
                        )}
                      </div>
                      <AnimatePresence initial={false} mode="wait">
                        {isLoading ? (
                          <motion.span
                            initial={{ opacity: 0.5 }}
                            animate={{ opacity: 1 }}
                            exit={{
                              opacity: 1,
                              transition: {
                                delay: 0.4,
                              },
                            }}
                            key="loading"
                          >
                            <LoadingIcon width={sizeIcon} height={sizeIcon} />
                          </motion.span>
                        ) : (
                          <motion.span initial={{ opacity: 0 }} animate={{ opacity: 1 }} key="search">
                            <SearchIcon
                              width={sizeIcon}
                              height={sizeIcon}
                              onClick={e => {
                                e.preventDefault()
                                e.stopPropagation()
                                handleConfirmSearch()
                              }}
                            />
                          </motion.span>
                        )}
                      </AnimatePresence>
                    </div>
                  }
                />
              </div>
            }
          >
            <div
              className="p-2 rounded-2xl bg-atherGray-900 border border-atherGray-850 flex w-full flex-col"
              style={{
                width: formWidth,
              }}
            >
              {!!previewResult && (
                <div>
                  {title && <h3 className="text-xs text-atherGray-100 font-semibold mb-2 px-1 pt-1">{title}</h3>}
                  <div
                    onClick={() => {
                      setIsOpen(false)
                    }}
                  >
                    {previewResult}
                  </div>
                </div>
              )}
              {!!previewResult && history && history.length > 0 && <hr className="border-atherGray-800 my-2" />}
              {history && history.length > 0 && (
                <div>
                  <h3 className="text-xs text-atherGray-100 font-semibold mb-2 px-1 pt-1">Recent searches</h3>
                  <div className="space-y-1">
                    {history?.map(item => (
                      <div
                        onClick={() => {
                          setSearch(item.text)
                          onConfirmSearch?.(item.text ?? "")
                          setIsOpen(false)
                        }}
                        key={`${item.location}-${item.text}`}
                        className="flex w-full justify-between items-center hover:bg-atherGray-850 cursor-pointer p-1 px-2 rounded-xl "
                      >
                        <p className="flex-1 truncate text-sm text-atherGray-300">{item.text}</p>
                        <button
                          type="button"
                          className="text-atherGray-500 ml-4"
                          onClick={e => {
                            e.stopPropagation()
                            onClearSearchHistory?.(item)
                          }}
                        >
                          <CloseIcon width={12} height={12} />
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </Popover>
        </div>
      )}
    </Fragment>
  )
}

export default SearchBar
