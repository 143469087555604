import { imageExtensionRegex } from "@/constants"

const downloadImages = async (imageUrls: string[], names: string[], zipName: string) => {
  if (imageUrls.length === 1) {
    try {
      const type = imageUrls[0].match(imageExtensionRegex)?.[0] ?? ""

      const response = await fetch(imageUrls[0])

      if (!response.ok) {
        throw new Error("Failed to download image")
      }
      const blob = await response.blob()
      const url = URL.createObjectURL(blob)

      const link = document.createElement("a")
      link.href = url
      link.download = `${names[0]}${type}` // Replace with the desired file name and extension
      link.style.display = "none"
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      URL.revokeObjectURL(url)
    } catch (error) {
      console.error("Error downloading image:", error)
    }

    return
  }

  const JSZip = (await import("jszip")).default
  const zip = new JSZip()

  const type = imageUrls.map(imageUrl => imageUrl.match(imageExtensionRegex)?.[0] ?? "")

  // Fetch all images and add them to the zip
  const addImagesToZip = imageUrls.map(async (imageUrl, index) => {
    try {
      const response = await fetch(imageUrl)

      if (!response.ok) {
        throw new Error("Failed to download image")
      }

      const blob = await response.blob()
      const name = `${names[index]}${type[index]}` // Replace with the desired file name and extension
      zip.file(name, blob, { binary: true })
    } catch (error) {
      console.error("Error downloading image:", error)
    }
  })

  await Promise.all(addImagesToZip)

  // Generate and download the zip file
  try {
    const content = await zip.generateAsync({ type: "blob" })
    const url = URL.createObjectURL(content)

    const link = document.createElement("a")
    link.href = url
    link.download = `${zipName}.zip`
    link.style.display = "none"
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    URL.revokeObjectURL(url)
  } catch (error) {
    console.error("Error generating zip file:", error)
  }
}

export const useDownloadImages = () => {
  return downloadImages
}
