import { IconProps } from "."

export const NoteIcon = (props: IconProps) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M17.1693 9.35672L23.5332 11.0523M15.8051 14.4191L18.9871 15.2669M15.9634 23.9565L17.2362 24.2957C20.8362 25.2548 22.6361 25.7344 24.0542 24.9203C25.4722 24.1062 25.9545 22.3164 26.9191 18.7368L28.2832 13.6744C29.2479 10.0947 29.7302 8.30488 28.9115 6.89485C28.0928 5.48482 26.2928 5.00524 22.6928 4.04607L21.4201 3.70695C17.8201 2.74779 16.0201 2.26821 14.6021 3.08229C13.1841 3.89637 12.7018 5.6862 11.7372 9.26585L10.373 14.3282C9.4084 17.9079 8.92609 19.6977 9.74479 21.1078C10.5635 22.5178 12.3635 22.9974 15.9634 23.9565Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M15.9974 27.931L14.7276 28.2768C11.1361 29.2548 9.34035 29.7437 7.92565 28.9137C6.51095 28.0836 6.02978 26.2587 5.06744 22.6088L3.70649 17.4471C2.74415 13.7973 2.26298 11.9723 3.07975 10.5347C3.78629 9.29102 5.33073 9.3363 7.33073 9.33614"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  )
}
