import { create } from "zustand"

interface DrawerNotificationStore {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

export const useDrawerNotification = create<DrawerNotificationStore>(set => ({
  isOpen: false,
  setIsOpen: isOpen => set({ isOpen }),
}))
