import { RecentFolderItem as RecentFolderItemType } from "@/api/sdk"
import { memo } from "react"
import FolderItem from "../../Folder/FolderItem"
import RecentAccordion from "../RecentAccordion"

interface RecentImageItemProps {
  name: string
  recent: RecentFolderItemType[]
  layout: "grid" | "list"
}

const RecentFolderItem = ({ name, recent, layout }: RecentImageItemProps) => {
  if (recent.length === 0) return null
  return (
    <RecentAccordion name={name} layout={layout}>
      {recent?.map(item => {
        return (
          <div key={item.id}>
            <FolderItem
              accessedAt={item.accessedAt}
              href={`/workspace/folders?folderId=${item.folder.id}`}
              layout={layout}
              folder={item.folder}
            />
          </div>
        )
      })}
    </RecentAccordion>
  )
}

export default memo(RecentFolderItem)
