import client from "@/api/client"
import { actionMiddleware } from "@/queries/middlewares/actionMiddleware"
import { createMutation } from "react-query-kit"
import { useGetRecipesInfiniteQuery } from "./getRecipeQueries"
import { InfiniteData } from "@tanstack/react-query"
import { GetRecipesResult, PinnedItemType, PinnedItems, RecentRecipeItem } from "@/api/sdk"
import { useRecentRecipesInfiniteQuery } from "@/components/Workspace/RecentV2/RecentRecipes"
import { useWorkspacePinInfiniteQuery } from "../useWorkspacePinInfiniteQuery"

export type DeleteRecipeMutationVariables = {
  ids: string[]
  deletedAt?: string | null
}

export const useDeleteRecipeMutation = createMutation({
  mutationFn: async ({ ids, deletedAt }: DeleteRecipeMutationVariables) => {
    if (deletedAt) {
      return client.api
        .recipeControllerFullyDeleteRecipes({
          recipeIds: ids,
        })
        .then(res => res.data)
    }

    return client.api
      .recipeControllerSoftDeleteRecipes({
        recipeIds: ids,
      })
      .then(res => res.data)
  },
  use: [
    actionMiddleware({
      onSuccess: ({ queryClient, variables: { ids } }) => {
        const infKey = useGetRecipesInfiniteQuery.getKey()
        const infQueriesDataEntries = queryClient.getQueriesData<InfiniteData<GetRecipesResult, number>>({
          queryKey: infKey,
        })

        infQueriesDataEntries?.forEach(([key, data]) => {
          if (!data) return

          const updatedData = {
            ...data,
            pages: data.pages.map(page => {
              const updatedPage = {
                ...page,
                recipes: page.recipes.filter(style => {
                  return !ids.includes(style.id)
                }),
              }
              updatedPage.total = updatedPage.recipes.length
              return updatedPage
            }),
          }
          queryClient.setQueryData(key, updatedData)
        })

        // Update recipes recent list
        const recentListKey = useRecentRecipesInfiniteQuery.getKey()

        const recentListQueriesDataEntries = queryClient.getQueriesData<InfiniteData<RecentRecipeItem[], number>>({
          queryKey: recentListKey,
        })

        if (recentListQueriesDataEntries) {
          recentListQueriesDataEntries.forEach(([key, recipesRecentListData]) => {
            if (recipesRecentListData) {
              const updatedData = {
                ...recipesRecentListData,
                pages: recipesRecentListData.pages.map(page => {
                  return [...page.filter(recipes => !ids.includes(recipes.recipe.id))]
                }),
              }

              queryClient.setQueryData(key, updatedData)
            }
          })
        }

        // Update recipes pinned list
        const recipesPinnedListKey = useWorkspacePinInfiniteQuery.getKey({
          type: PinnedItemType.RECIPE,
        })

        const recipesPinnedListQueriesDataEntries = queryClient.getQueriesData<InfiniteData<PinnedItems[], number>>({
          queryKey: recipesPinnedListKey,
        })

        if (recipesPinnedListQueriesDataEntries) {
          recipesPinnedListQueriesDataEntries.forEach(([key, recipePinnedListData]) => {
            if (recipePinnedListData) {
              const updatedData = {
                ...recipePinnedListData,
                pages: recipePinnedListData.pages.map(page => {
                  return [...page.filter(item => !ids.includes(item.recipe?.id ?? ""))]
                }),
              }

              queryClient.setQueryData(key, updatedData)
            }
          })
        }
      },
    }),
  ],
})
