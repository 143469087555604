import { create } from "zustand"
import { devtools, persist } from "zustand/middleware"

interface LatestTaskParamsType {
  latestRecipesParams:
    | {
        recipeId: string
        folderId?: string
        params: Record<string, any>
      }[]
    | null
  setLatestRecipesParams: (
    latestRecipesParams: { recipeId: string; folderId?: string; params: Record<string, any> }[],
  ) => void
}

export const useLatestRecipesParams = create<LatestTaskParamsType>()(
  devtools(
    persist(
      set => ({
        latestRecipesParams: null,
        setLatestRecipesParams: latestRecipesParams =>
          set(() => {
            return { latestRecipesParams }
          }),
      }),
      {
        name: "latest-recipes-params-storage",
      },
    ),
  ),
)
