import { CommentsOrderOption, EntityType, GetCommentsResult } from "@/api/sdk"
import { useCommentDetailQuery } from "@/queries"
import useCustomRouter from "@/hooks/useCustomRouter"
import { MutableRefObject, useEffect, useRef, useState } from "react"

interface CommentReply {
  itemId: string
  selectedCommentId?: string
  totalReplyComment: number
  baseChildParam: number | null
  prevBaseChildParam: MutableRefObject<number | null>
  nextBaseChildParam: MutableRefObject<number | null>
  childTakePage: number
  childTotalPage: MutableRefObject<number>
  setIsSuccessChild?: React.Dispatch<React.SetStateAction<boolean>>
  typeComment: EntityType
}

const useCommentReply = ({
  itemId,
  selectedCommentId,
  setIsSuccessChild,
  totalReplyComment,
  baseChildParam,
  nextBaseChildParam,
  typeComment,
  prevBaseChildParam,
  childTakePage,
  childTotalPage,
}: CommentReply) => {
  const [upComingComment, setUpComingComment] = useState<string | undefined>(undefined)
  const router = useCustomRouter()

  const isReplyFromNoti = !!baseChildParam

  const primaryKey =
    typeComment === EntityType.ARTICLE
      ? "infinite-comment-child-articles"
      : typeComment === EntityType.INSPIRATION_IMAGE
        ? "infinite-explore-comment-child-images"
        : typeComment === EntityType.IMAGE
          ? "infinite-comment-child-images"
          : typeComment === EntityType.EXPLORE_STYLE
            ? "infinite-explore-comment-child-styles"
            : typeComment === EntityType.EXPLORE_RECIPE
              ? "infinite-explore-comment-child-recipes"
              : typeComment === EntityType.EXPLORE_WORKFLOW
                ? "infinite-explore-comment-child-workflows"
                : typeComment === EntityType.EXPLORE_SD_WORKFLOW
                  ? "infinite-explore-comment-child-sd-workflows"
                  : typeComment === EntityType.STYLE
                    ? "infinite-comment-child-styles"
                    : typeComment === EntityType.WORKFLOW
                      ? "infinite-comment-child-workflows"
                      : typeComment === EntityType.SD_WORKFLOW
                        ? "infinite-comment-child-sd-workflows"
                        : typeComment === EntityType.SD_MODEL
                          ? "infinite-comment-child-sd-models"
                          : "infinite-comment-child"

  const hook = useCommentDetailQuery({
    primaryKey,
    getNextPageParam: (lastPage, allPages) => {
      if (isReplyFromNoti) {
        if (nextBaseChildParam.current !== null && nextBaseChildParam.current < childTotalPage.current) {
          return nextBaseChildParam.current
        }
        return undefined
      } else {
        if (lastPage.comments.length < childTakePage) {
          return undefined
        }
        return allPages.length
      }
    },
    getPreviousPageParam: () => {
      if (isReplyFromNoti) {
        if (prevBaseChildParam.current !== null && prevBaseChildParam.current >= 0) return prevBaseChildParam.current
        return undefined
      } else {
        return undefined
      }
    },
  })

  const {
    data: relyComments,
    hasNextPage,
    hasPreviousPage,
    fetchNextPage,
    fetchPreviousPage,
    isLoading,
    isSuccess: isCommentsSuccess,
    refetch: refetchChildComments,
    isFetchingNextPage,
    isFetchingPreviousPage,
    isError,
  } = hook({
    variables: {
      entityId: itemId,
      typeComment,
      parentTakePage: childTakePage,
      pageParam: baseChildParam ?? 0,
      parentCommentId: selectedCommentId,
      order: selectedCommentId ? CommentsOrderOption.ASC : CommentsOrderOption.DESC,
    },
    enabled: !!itemId && !!selectedCommentId,
  })

  const queryCommentsChildImageKey = hook.getKey({
    entityId: itemId,
    typeComment,
    parentTakePage: childTakePage,
    pageParam: baseChildParam ?? 0,
    parentCommentId: selectedCommentId,
    order: selectedCommentId ? CommentsOrderOption.ASC : CommentsOrderOption.DESC,
  })

  useEffect(() => {
    if (isCommentsSuccess) {
      setIsSuccessChild?.(true)
      setUpComingComment(undefined)
      const lastPage = relyComments?.pages[relyComments?.pages.length - 1]
      if (lastPage) {
        const cal = Math.ceil((lastPage.totalParentComment ?? 0) / childTakePage)
        childTotalPage.current = cal
      }
    }
  }, [isCommentsSuccess, relyComments?.pages])

  const mappedChildComment = relyComments?.pages?.reduce(
    (acc: GetCommentsResult, curr) => ({
      total: curr.total,
      comments: [...acc.comments, ...curr.comments],
      totalParentComment: curr.totalParentComment,
    }),
    { comments: [], total: 0, totalParentComment: 0 },
  ) ?? {
    comments: [],
    total: 0,
  }

  return {
    upComingComment,
    setUpComingComment,
    queryCommentsChildImageKey,
    mappedChildComment,
    hasNextPage,
    hasPreviousPage,
    fetchNextPage,
    fetchPreviousPage,
    isLoading,
    isCommentsSuccess,
    isFetchingNextPage,
    isFetchingPreviousPage,
    refetchChildComments,
  }
}

export default useCommentReply
