import { WildcardDetail } from "@/api/sdk"
import { useAuth } from "@/providers/authContext"
import format from "date-fns/format"
import { PinIcon } from "@/components/shared/icons"
import { googleAnalytics } from "@/lib/gtag"
import WildcardActionButtons from "@/components/Tools/Wildcards/WildcardItem/WildcardActionButtons"
import UserCreated from "@/components/Explore/Gallery/UserCreated"

interface StyleItemListProps {
  wildcard: WildcardDetail
  accessedAt?: string
  onClick?: () => void
  hiddenMenu?: boolean
}

const WildcardItemList = ({ wildcard, accessedAt, onClick, hiddenMenu }: StyleItemListProps) => {
  const { user } = useAuth()

  const handleClick = () => {
    googleAnalytics.event({
      action: "click",
      category: "wildcard_item",
      label: `view_wildcard_detail`,
      params: {
        wildcard_id: wildcard.id,
        wildcard_name: wildcard.name ?? "",
        pathname: window.location.pathname,
      },
    })

    if (onClick) {
      onClick()

      return
    }
  }

  return (
    <div className="w-full flex items-center text-sm text-atherPrimary-100 bg-atherGray-900 rounded-lg">
      <div className="flex-1 md:flex-none md:w-[30%] py-2 pr-0 pl-2 md:pr-2 cursor-pointer" onClick={handleClick}>
        <div className="flex items-center overflow-hidden">
          <div className="overflow-hidden flex flex-col flex-1">
            <p className="font-semibold flex-1 line-clamp-1">{wildcard.name}</p>
            <div className="block md:hidden truncate text-atherGray-500">
              Opened - {accessedAt ? format(new Date(accessedAt), "HH:mm dd/MM/yyyy") : ""}
            </div>
          </div>
        </div>
      </div>
      <div className="hidden md:block md:w-[15%] p-2 whitespace-nowrap truncate">Wildcard</div>
      <div className="hidden md:block md:w-[15%] p-2 whitespace-nowrap truncate">
        {accessedAt ? format(new Date(accessedAt), "HH:mm dd/MM/yyyy") : ""}
      </div>
      <div className="hidden md:block md:w-[15%] p-2">
        <UserCreated
          size="sm"
          picture={wildcard.creator.picture}
          name={wildcard.creator.name}
          isMe={user?.uid === wildcard.creator.uid}
        />
      </div>
      <div
        className="hidden md:block md:w-[20%] p-2 overflow-hidden cursor-pointer"
        onClick={e => {
          e.stopPropagation()
        }}
      ></div>
      <div className="md:w-[5%] pr-2 md:p-2 flex justify-end">
        {wildcard.pinned !== undefined && wildcard.pinned && (
          <div className="p-0.5">
            <PinIcon width={16} height={16} />
          </div>
        )}
        {!hiddenMenu && (
          <WildcardActionButtons layout="list" wildcard={wildcard} className="bg-transparent hover:bg-transparent" />
        )}
      </div>
    </div>
  )
}

export default WildcardItemList
