import { UploadProgress } from "@/stores/useUploadProgressStore"
import { useImageDrop } from "./useImageDrop"
import { useImageUpload } from "./useImageUpload"
import { handleConvertHEICtoJPG } from "@/utils/convert"
import { mapWithConcurrency } from "@/utils/promise"

export const useImageDropAndUpload = (
  destinationFolderId: string | null,
  isImageSource?: React.MutableRefObject<boolean>,
  setProgress?: (p: UploadProgress) => void,
  {
    enabled = true,
    onSuccess,
    onMutate,
    onError,
  }: {
    enabled?: boolean
    onMutate?: () => void
    onSuccess?: () => void
    onError?: (error: any) => void
  } = {},
) => {
  const { mutate: mutateUploadFile } = useImageUpload({
    onSuccess,
    onMutate,
    onError,
  })

  const { isOver: isDroppingImage, bind } = useImageDrop(
    async files => {
      const updatedFiles = await mapWithConcurrency(
        files,
        async file => {
          if (file.type === "image/heic") {
            const blob = await handleConvertHEICtoJPG(file)
            return blob.arrayBuffer().then(buffer => new File([buffer], "image.jpeg", { type: "image/jpeg" }))
          }
          return file
        },
        { concurrency: 3 },
      )

      mutateUploadFile({
        folderId: destinationFolderId!,
        files: updatedFiles,
        callback: v => (setProgress ? setProgress({ ...v }) : {}),
      })
    },
    isImageSource,
    {
      enabled,
    },
  )

  return {
    isDroppingImage,
    bind,
  }
}
