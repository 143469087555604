import { EntityType, SDStyleDetail } from "@/api/sdk"
import CommentsStyle from "@/components/Explore/StyleDetail/TabStyle/CommentsStyle"
import IconButton from "@/components/IconButton"
import { CommentIcon, XIcon } from "@/components/shared/icons"
import { useCommentDetailQuery, useReadCommentMutation } from "@/queries"
import { cn } from "@/utils/cn"
import classNames from "classnames"
import { AnimatePresence, motion } from "framer-motion"
import useCustomRouter from "@/hooks/useCustomRouter"
import React, { Fragment, useEffect, useRef, useState } from "react"
import StyleContainerActions from "./StyleContainerActions"
import WorkspaceContainer from "@/components/Workspace/WorkspaceContainer"

interface StyleContainerProps {
  children?: React.ReactNode
  isSaving: boolean
  inputName: string
  onInputNameChange: (name: string) => void
  style: SDStyleDetail
  isDraft?: boolean
  isDuplicating?: boolean
  onDuplicateStyle: ({ styleId }: { styleId: string }) => void
  onEdit: () => void
  onSave: () => void
}

const StyleContainer = ({
  children,
  style,
  isDuplicating,
  isSaving,
  onDuplicateStyle,
  onEdit,
  onSave,
  isDraft,
  inputName,
  onInputNameChange,
}: StyleContainerProps) => {
  const router = useCustomRouter()

  const [isOpenComment, setIsOpenComment] = useState(false)

  useEffect(() => {
    if (router.query.notificationId && router.isReady) {
      setIsOpenComment(true)
    }
  }, [router.query.notificationId, router.isReady])

  const hook = useCommentDetailQuery({
    primaryKey: "infinite-comment-styles",
    getNextPageParam: (lastPage, allPages) => {
      return undefined
    },
    getPreviousPageParam: () => {
      return undefined
    },
  })
  const isMutated = useRef(false)

  const { data: comments, refetch: refetchComments } = hook({
    variables: {
      entityId: style?.id ?? "",
      typeComment: EntityType.STYLE,
      parentTakePage: 10,
      pageParam: 0,
    },
    enabled: !!style,
  })

  const { mutate: mutateReadComment } = useReadCommentMutation({
    onSuccess: () => {
      refetchComments()
      isMutated.current = true
    },
  })

  return (
    <Fragment>
      <div
        className={cn(
          "bg-atherGray-900 shadow-lg ease-in-out duration-300 transition-transform px-2 md:px-4",
          "",
          "sticky overflow-hidden z-[1] top-0 md:top-[4.5rem] left-0 w-full h-auto md:h-[3rem]",
        )}
      >
        <WorkspaceContainer className="flex-1 py-2 flex-row items-center justify-between">
          <div className="overflow-hidden flex-1 flex">
            <StyleContainerActions
              inputName={inputName}
              onDuplicateStyle={onDuplicateStyle}
              onInputNameChange={onInputNameChange}
              style={style}
              isDraft={isDraft}
              isDuplicating={isDuplicating}
            />
          </div>
          <div className="flex space-x-2 items-center justify-center md:justify-end">
            {!isDraft && (
              <IconButton
                onClick={() => {
                  setIsOpenComment(prev => !prev)

                  if (isMutated.current) return

                  isMutated.current = true

                  mutateReadComment({
                    entityId: style?.id ?? "",
                    entityType: EntityType.STYLE,
                  })
                }}
                className={classNames("p-1 min-h-0 relative", {
                  "bg-atherPurple-500 hover:bg-atherPurple-500": isOpenComment,
                })}
                colorScheme="transparent"
              >
                <CommentIcon className="text-atherGray-300" width={20} height={20} />
                {!isOpenComment && comments?.pages?.[0]?.comments.some(comment => !comment.isRead) && (
                  <div className="absolute top-0 right-0 translate-y-1 w-2.5 h-2.5 rounded-full bg-atherPurple-500"></div>
                )}
              </IconButton>
            )}
            {style.capabilities?.canUpdate && (
              <Fragment>
                {isDraft && (
                  <IconButton isLoading={isSaving} className="font-semibold text-xs" onClick={onSave}>
                    Create Style
                  </IconButton>
                )}

                {!isDraft && (
                  <IconButton
                    isLoading={isDuplicating}
                    className="font-semibold text-xs hidden md:flex"
                    onClick={() => onDuplicateStyle({ styleId: style.id })}
                  >
                    Duplicate <span className="hidden md:inline-block">Style</span>
                  </IconButton>
                )}
              </Fragment>
            )}
          </div>
        </WorkspaceContainer>
      </div>
      {children}
      <AnimatePresence>
        {isOpenComment && (
          <motion.div
            className="fixed top-0 md:top-[3rem] height-full-screen items-end md:h-[calc((var(--vh,1vh)*100-3rem))] overflow-hidden flex flex-col right-0 w-full md:w-auto z-[24]"
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 100 }}
            transition={{
              type: "tween",
              duration: 0.2,
            }}
          >
            <div
              className="absolute top-0 w-full left-0 h-full bg-blackAlpha-800 backdrop-blur-sm"
              onClick={() => setIsOpenComment(false)}
            />
            <div className="w-80 z-[1] flex-1 flex flex-col overflow-hidden bg-atherGray-900 border-l p-4 border-atherGray-800">
              <div className="flex items-center justify-between mb-2">
                <p className="text-sm font-semibold">Comments</p>
                <button type="button" onClick={() => setIsOpenComment(false)}>
                  <XIcon className="text-atherGray-300" width={16} height={16} />
                </button>
              </div>
              <div className="flex-1 overflow-auto">
                <CommentsStyle style={style} typeComment={EntityType.STYLE} />
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </Fragment>
  )
}

export default StyleContainer
