import client from "@/api/client"
import { TipDto } from "@/api/sdk"
import { createMutation } from "react-query-kit"
import { actionMiddleware } from "./middlewares/actionMiddleware"

export const useMutateTipWowPoints = createMutation({
  mutationFn: ({ data }: { data: TipDto }) => client.api.tippingControllerTipWowPoint(data).then(res => res.data),
  use: [
    actionMiddleware({
      onSuccess: ({ queryClient }) => {
        queryClient.invalidateQueries({ queryKey: ["user-info"] })
      },
    }),
  ],
})
