import client from "@/api/client"
import { GetPromptsResult, PromptItem, PromptSortOptions } from "@/api/sdk"
import { createAuthenticatedInfiniteQuery } from "@/queries/createAuthenticatedQuery"

export const useWorkspacePromptsInfiniteQuery = createAuthenticatedInfiniteQuery<
  GetPromptsResult,
  {
    searchTerm: string
    sortBy: PromptSortOptions | undefined
    take?: number
  },
  Error,
  PromptItem
>({
  primaryKey: "prompt-list",
  queryFn: ({ queryKey: [_primaryKey, variables], pageParam = 0 }) =>
    client.api
      .promptLibraryControllerGetPrompts({
        skip: pageParam * (variables.take ?? 20),
        take: variables.take ?? 20,
        searchTerm: variables.searchTerm,
        sortBy: variables.sortBy,
      })
      .then(res => res.data),
  flattenFn: data => data.prompts,
  getTotalFn: data => data.total,
  getNextPageParam: (lastPage, pages) => (lastPage && lastPage?.prompts.length < 20 ? undefined : pages.length),
  initialPageParam: 0,
  refetchOnWindowFocus: false,
})
