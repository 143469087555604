import { cdnPublicFolderUrl } from "@/constants"
import React from "react"

interface ErrorUIProps {
  errorMessage?: string
  errorImage?: string
  children?: React.ReactNode
}

const ErrorUI = ({ errorMessage = "Error", children, errorImage }: ErrorUIProps) => {
  return (
    <div className="flex items-center flex-col justify-center flex-1 w-full">
      <img className="mb-2 w-[6rem]" src={errorImage || `${cdnPublicFolderUrl}/warning.png`} alt="error" />
      <p className="text-atherGray-300">{errorMessage}</p>
      {children}
    </div>
  )
}

export default ErrorUI
