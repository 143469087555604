import { IconProps } from "."

export const KeyboardIcon = (props: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none" {...props}>
      <path
        d="M13.5 6H8.5C5.21252 6 3.56878 6 2.46243 6.90796C2.25989 7.07418 2.07418 7.25989 1.90796 7.46243C1 8.56878 1 10.2125 1 13.5C1 16.7875 1 18.4312 1.90796 19.5376C2.07418 19.7401 2.25989 19.9258 2.46243 20.092C3.56878 21 5.21252 21 8.5 21H13.5C16.7875 21 18.4312 21 19.5376 20.092C19.7401 19.9258 19.9258 19.7401 20.092 19.5376C21 18.4312 21 16.7875 21 13.5C21 10.2125 21 8.56878 20.092 7.46243C19.9258 7.25989 19.7401 7.07418 19.5376 6.90796C18.4312 6 16.7875 6 13.5 6Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M11 6V4C11 3.44772 11.4477 3 12 3C12.5523 3 13 2.55228 13 2V1"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M6 11L7 11" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10.5 11L11.5 11" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M15 11L16 11" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6 16L16 16" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
