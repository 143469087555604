import dynamic from "next/dynamic"
import { RecipeFilterMode, RecipeItem, RecipeSortOptions, RecipeType } from "@/api/sdk"
import ScrollHorizontalContainer from "@/components/Alpha/ScrollHorizontalContainer"
import { BASICS_RECIPE_ID } from "@/components/Explore/Gallery/GalleryRecipes"
import IconButton from "@/components/IconButton"
import {
  DressIcon,
  Facebook01Icon,
  ImageSparkIcon,
  MilkCoconutIcon,
  NoteIcon,
  VideoIcon,
  PaintBoardIcon,
  ScienceIcon,
  SwordIcon,
} from "@/components/shared/icons"
import { StartFaceIcon } from "@/components/shared/icons/StartFaceIcon"
import { useReplaceUrlQuery } from "@/hooks/useQuery"
import _throttle from "lodash/throttle"
import useClientStore from "@/lib/clientStore"
import { googleAnalytics } from "@/lib/gtag"
import { useAuth } from "@/providers/authContext"
import { useGetRecipeCategories, useGetRecipesInfiniteQuery } from "@/queries/workspace/recipe/getRecipeQueries"
import { useWorkspaceSearchHistory } from "@/stores"
import classNames from "classnames"
import { motion } from "framer-motion"
import useCustomRouter from "@/hooks/useCustomRouter"
import { useEffect, useMemo, useRef, useState } from "react"
import TabsList from "../FoldersV2/TabsList"
import MultiRecipesSelectBar from "./MultiRecipesSelectBar"
import RecipesTabActions from "./RecipesTabActions"
import { useMultiRecipesSelect } from "./useMultiRecipesSelect"
import { useDebounce } from "@/hooks"
import RecipeList from "./RecipeList"
import WorkspaceContainer from "../WorkspaceContainer"

const RecipesTutorial = dynamic(() => import("@/components/TutorialsInstructions/RecipesTutorial"))

export const tabDataRecipesWithIcon = [
  {
    id: "All",
    title: "All",
  },
  {
    id: "Basics",
    title: "Basics",
    icon: PaintBoardIcon,
  },
  {
    id: "Flex on Social",
    title: "Flex on Social",
    icon: Facebook01Icon,
  },
  {
    id: "Magic Edit",
    title: "Magic Edit",
    icon: ImageSparkIcon,
  },

  {
    id: "Game Assets",
    icon: SwordIcon,
    title: "Game Assets",
  },
  {
    id: "Commercial",
    title: "Commercial",
    icon: MilkCoconutIcon,
  },
  {
    id: "Stickers",
    title: "Stickers",
    icon: StartFaceIcon,
  },
  {
    id: "Fashion Show",
    title: "Fashion Show",
    icon: DressIcon,
  },
  {
    id: "my-recipes",
    title: "My Recipes",
    icon: ScienceIcon,
  },
  {
    id: "Gif",
    title: "Gif",
    icon: VideoIcon,
  },
  {
    id: "Others",
    title: "Others",
    icon: NoteIcon,
  },
]

export const tabsRecipes = [
  {
    id: "recommended",
    title: "Recommended",
  },
  {
    id: "protogaia-recipes",
    title: "GAIA Recipes",
  },
  {
    id: "my-recipes",
    title: "My Recipes",
  },
  {
    id: "shared-with-me",
    title: "Shared with me",
  },
]

export const useWorkspaceRecipes = (
  currentMode: RecipeFilterMode | undefined,
  categoryId: number | undefined,
  searchTerm: string,
  sortBy?: RecipeSortOptions,
  filter?: {
    shared?: string[]
    types?: RecipeType[]
    pinned?: boolean
  } | null,
  onlyMine?: boolean,
  enabled?: boolean,
) => {
  const variables = useMemo(() => {
    return {
      types: filter?.types ?? [RecipeType.Normal, RecipeType.Describe],
      categoryId,
      searchTerm,
      sortBy,
      mode: currentMode,
      onlyMine,
      onlyDiscoverable: filter?.shared?.includes("explore") ? true : undefined,
      onlyShared: filter?.shared?.includes("workspace") ? true : undefined,
      isPinned: filter?.pinned ? true : undefined,
    }
  }, [categoryId, currentMode, searchTerm, sortBy, filter, onlyMine])

  const debounceVariables = useDebounce(variables, 50)

  const { flattenData: recipes = [], ...query } = useGetRecipesInfiniteQuery({
    variables: debounceVariables,
    enabled: enabled !== undefined ? enabled : true,
  })

  return {
    recipes,
    ...query,
  }
}

const Recipes = () => {
  const router = useCustomRouter()

  const { data: tabDataCategories, isLoading: isLoadingCategory } = useGetRecipeCategories()
  const isOpenSidebar = useClientStore(state => state.isOpenSidebar)

  const tabQuery =
    (router.query.tab as string) && tabsRecipes.some(tab => tab.id === router.query.tab)
      ? (router.query.tab as string)
      : tabsRecipes[0].id
  const [category, setCategory] = useState<string>("Basics")

  const categoryId =
    tabDataCategories?.categories.find(tab => tab.name.toLowerCase() === category.toLowerCase())?.id ??
    (tabQuery === "recommended" ? BASICS_RECIPE_ID : undefined)

  const [filter, setFilter] = useState<{
    shared?: string[]
  } | null>(null)

  useEffect(() => {
    if (router.isReady && router.query.category && tabDataCategories && tabDataCategories?.categories.length > 0) {
      const category = tabDataCategories?.categories.find(tab => tab.name === router.query.category)?.name ?? "All"

      setCategory(category)
    }
  }, [router.isReady, tabDataCategories])

  const searchTerm = (router.query.search as string | undefined) ?? ""
  const [addHistory, removeHistory] = useWorkspaceSearchHistory(s => [s.addHistory, s.removeHistory])
  const { user } = useAuth()
  const replaceUrl = useReplaceUrlQuery()

  const [[sortBy], setSort] = useState<[RecipeSortOptions]>([RecipeSortOptions.NEWEST])

  const mode =
    tabQuery === "my-recipes"
      ? RecipeFilterMode.Owned
      : tabQuery === "protogaia-recipes"
        ? RecipeFilterMode.Public
        : tabQuery === "shared-with-me"
          ? RecipeFilterMode.Shared
          : undefined

  const { recipes, total, isLoading, isFetching, isSuccess, isError, hasNextPage, fetchNextPage } = useWorkspaceRecipes(
    mode,
    mode === RecipeFilterMode.Public || !mode ? categoryId : undefined,
    searchTerm,
    sortBy,
    {
      pinned: true,
      shared: filter?.shared,
    },
  )

  const handleConfirmSearch = (search: string) => {
    router.push({ query: { ...router.query, search } }, undefined, { shallow: true })

    googleAnalytics.event({
      action: "click",
      category: "search",
      label: `search_recipes`,
      params: {
        search,
        tab: tabQuery,
      },
    })

    if (search) {
      addHistory({
        uid: user?.uid ?? "",
        location: "workspace/tools/recipes",
        text: search,
      })
    }
  }

  const handleRemoveHistory = (text: string) => {
    googleAnalytics.event({
      action: "click",
      category: "remove history",
      label: `remove_history_recipes`,
      params: {
        text,
        tab: tabQuery,
      },
    })

    removeHistory({
      uid: user?.uid ?? "",
      location: "workspace/tools/recipes",
      text: text,
    })
  }

  const { addSelection, clear, selectAll, selectedItems, selectionMode, toggleSelectionMode } = useMultiRecipesSelect()

  const handleToggleSelectionMode = (toggle?: boolean) => {
    toggleSelectionMode(toggle)
    googleAnalytics.event({
      action: "click",
      category: "recipes",
      label: "toggle_multi_select",
      params: {
        type: "recipes",
        value: selectionMode ? "false" : "true",
      },
    })
  }

  const handleAddSelection = (data: RecipeItem) => {
    addSelection(data)
    googleAnalytics.event({
      action: "click",
      category: "recipes",
      label: "select_recipe",
      params: {
        recipe_id: data.id,
      },
    })
  }

  const containerRef = useRef<HTMLDivElement>(null)
  const mainContainerRef = useRef<HTMLDivElement>(null)
  const [containerLeft, setContainerLeft] = useState(0)

  useEffect(() => {
    const containerObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        setContainerLeft(entry.target.getBoundingClientRect().x)
      }
    })

    containerObserver.observe(mainContainerRef.current!)

    return () => {
      containerObserver.disconnect()
    }
  }, [])

  return (
    <div className="flex-1 p-4 px-6 flex flex-col relative w-full" ref={mainContainerRef}>
      <WorkspaceContainer className="flex-1">
        <h3 className="text-xl font-semibold mb-4">Recipes</h3>
        <div className="overflow-hidden">
          <TabsList
            tabQuery={tabQuery}
            tabsData={tabsRecipes}
            onChange={tab => {
              router.push({ pathname: "/workspace/tools/recipes", query: { tab } }, undefined, { shallow: true })
            }}
          />
        </div>
        <div className="flex-1 mt-2 relative w-full flex flex-col" ref={containerRef}>
          <MultiRecipesSelectBar
            selectionMode={selectionMode}
            view={mode}
            clear={clear}
            selectedItems={selectedItems}
            offsetLeft={containerLeft / 2}
          >
            <div className="flex-1 flex-col flex w-full">
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className={classNames(
                  "flex items-center py-2 sticky top-0 md:top-[4.25rem] w-full inset-x-0 bg-black z-[2] mb-2",
                  {
                    hidden: tabQuery === "recommended",
                  },
                )}
              >
                <p className="text-sm text-atherGray-300 mr-4">
                  {total} {total > 1 ? "results" : "result"}
                </p>
                <RecipesTabActions
                  tabQuery={tabQuery}
                  filter={filter}
                  setFilter={setFilter}
                  toggleSelectionMode={handleToggleSelectionMode}
                  selectionMode={selectionMode}
                  mode={mode}
                  category={category}
                  setCategory={setCategory}
                  onRemoveHistory={handleRemoveHistory}
                  categoryId={categoryId}
                  sort={[sortBy]}
                  setSort={setSort}
                  onConfirmSearch={handleConfirmSearch}
                />
              </motion.div>
              <div>
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  className={classNames("flex items-center w-full py-2 overflow-hidden mb-2", {
                    hidden: tabQuery !== "recommended",
                  })}
                >
                  <div className="flex justify-center w-full max-w-[100rem]">
                    <div className="relative overflow-hidden flex justify-center py-4">
                      <div
                        className={classNames("overflow-hidden w-full", {
                          "max-w-full": !isOpenSidebar,
                          "lg:max-w-[calc(100vw-24rem)]": isOpenSidebar,
                        })}
                      >
                        <ScrollHorizontalContainer className="flex h-full pb-4 space-x-2">
                          {tabDataRecipesWithIcon
                            .filter(i => i.id !== "All" && i.id !== "my-recipes")
                            .map((tab, index) => (
                              <IconButton
                                key={index}
                                colorScheme="transparent"
                                onClick={() => {
                                  if (tab.id === category) {
                                    return
                                  }

                                  replaceUrl({
                                    category: tab.id,
                                  })

                                  setCategory(tab.id)
                                }}
                                className="flex items-center flex-col cursor-pointer shrink-0 group min-w-[7rem]"
                              >
                                <div
                                  className={classNames(
                                    "bg-atherGray-850 group-hover:bg-atherPurple-500  w-16 h-16 flex items-center justify-center rounded-3xl",
                                    {
                                      "bg-atherPurple-500": tab.id === category,
                                    },
                                  )}
                                >
                                  {tab.icon && <tab.icon width={24} height={24} />}
                                </div>
                                <p className="text-sm font-semibold mt-2">{tab.title}</p>
                              </IconButton>
                            ))}
                        </ScrollHorizontalContainer>
                      </div>
                    </div>
                  </div>
                </motion.div>
              </div>
              <RecipeList
                addSelection={handleAddSelection}
                fetchNextPage={fetchNextPage}
                hasNextPage={hasNextPage}
                isError={isError}
                isFetching={isFetching}
                isLoading={isLoading}
                isLoadingCategory={isLoadingCategory}
                recipes={recipes}
                selectAll={selectAll}
                selectedItems={selectedItems}
                selectionMode={selectionMode}
              />
            </div>
          </MultiRecipesSelectBar>
        </div>
      </WorkspaceContainer>
      {recipes.length > 0 && <RecipesTutorial />}
    </div>
  )
}

export default Recipes
