import React from "react"
import { WorkflowPublishType } from "./WorkFlowDetailStep"
import { RecipeTaskChainParams } from "../WorkflowsChaining"
import Tooltip from "@/components/Tooltip"
import classNames from "classnames"
import IconButton from "@/components/IconButton"
import TextCopy from "@/components/TextCopy"
import { ShareIcon3, ArrowDownIcon, ArrowMoveUpRightIcon, ShareIcon8 } from "@/components/shared/icons"
import { useCreateTaskMutation } from "@/queries"
import { RecipeInputType, RecipeTaskStatus } from "@/api/sdk"
import { useToast } from "@/hooks"
import { useManagementErrorsStore } from "@/stores"
import { useAuth } from "@/providers/authContext"
import { googleAnalytics } from "@/lib/gtag"
import useCustomRouter from "@/hooks/useCustomRouter"

interface WorkflowStepHeaderProps {
  workflow: WorkflowPublishType
  param: RecipeTaskChainParams
  index: number
  setSelectedStep: (step: RecipeTaskChainParams | null) => void
}

const WorkflowStepHeader = ({ index, param, setSelectedStep, workflow }: WorkflowStepHeaderProps) => {
  const toast = useToast()
  const { handleSignIn } = useAuth()
  const { openNewTab } = useCustomRouter()

  const setErrorState = useManagementErrorsStore(state => state.setErrorState)

  const { mutateAsync: mutateCreateTask, isPending: isLoadingCreate } = useCreateTaskMutation({
    onSuccess: data => {
      openNewTab(`/workspace/macros/${data.id}?draft=true`)
    },
    onError: (err: any) => {
      if (
        err?.message.startsWith("Guest user does not have permission") ||
        err?.message.startsWith("Your subscription has ended")
      ) {
        setErrorState({
          isOpen: true,
          message: err.message,
        })
        return
      }

      if (err?.error === "Bad Request") {
        toast({ title: "Cannot Complete Request", message: [err.message], status: "error" })
      } else {
        toast({ title: "Error", message: [err.message], status: "error" })
      }
    },
  })

  const handleSendToNewMacro = async () => {
    const isSignedIn = await handleSignIn()

    if (!isSignedIn) return

    const params = Object.entries(param.params).map(([key, value]) => {
      if (
        param.recipeInputStep?.find(step => step.key === key)?.type === RecipeInputType.Image &&
        value.includes("$$prev")
      ) {
        return {
          [key]: "",
        }
      }

      return {
        [key]: value,
      }
    })

    const newParams = params.reduce((acc, curr) => {
      return {
        ...acc,
        ...curr,
      }
    }, {})

    const newChainSendNew = {
      id: new Date().getTime().toString(),
      params: newParams,
      recipeId: param.recipeId,
      recipeInputStep: param.recipeInputStep,
      recipeName: param.recipeName,
    }

    googleAnalytics.event({
      action: "click",
      category: "macro-detail",
      label: "send-to-new-macro",
      params: {
        macro_id: workflow.id,
        recipe_id: param.recipeId,
        recipe_name: param.recipeName,
        pathname: window.location.pathname,
        ...newParams,
      },
    })

    mutateCreateTask({
      recipeId: "recipe-to-recipe",
      params: [newChainSendNew],
      name: "New Macro",
      status: RecipeTaskStatus.DRAFT,
    })
  }

  return (
    <div className="flex items-center overflow-hidden w-full mb-2">
      <div className="flex-col md:flex-row flex-1 flex items-center">
        <p className="text-sm font-semibold line-clamp-1">
          STEP {index + 1} : {param.recipeName}
        </p>
        {workflow.executionDuration ? (
          <p className={"ml-0 lg:ml-4 w-full lg:w-auto whitespace-nowrap text-xs text-atherGray-400"}>
            (Running time: {(workflow.executionDuration / 1000).toFixed(1)}s)
          </p>
        ) : null}
      </div>
      <div className="flex items-center">
        {workflow.creator?.uid !== "anonymous" && (
          <div className="hidden md:flex space-x-1">
            <IconButton
              isLoading={isLoadingCreate}
              className={classNames(
                "p-1 min-h-0 border border-atherGray-800 hover:bg-black text-atherGray-300 bg-black",
                {
                  hidden: !workflow.capabilities.canUpdate,
                },
              )}
              colorScheme="transparent"
              onClick={async e => {
                e.preventDefault()
                e.stopPropagation()
                handleSendToNewMacro()
              }}
            >
              <ArrowMoveUpRightIcon width={16} height={16} />
              <span className="text-xs ml-1">Send to New Macro</span>
            </IconButton>
            <Tooltip
              trigger={
                <div
                  className={classNames(
                    "p-1 rounded-lg border border-atherGray-800 hover:bg-black text-atherGray-300 bg-black",
                    {
                      hidden: !workflow.capabilities.canUpdate,
                    },
                  )}
                  onClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                  }}
                >
                  <TextCopy
                    value={JSON.stringify(param, null, 2)}
                    icon={<ShareIcon8 className="text-atherGray-300" width={16} height={16} />}
                  />
                </div>
              }
            >
              Copy step
            </Tooltip>
          </div>
        )}

        <IconButton
          onClick={() =>
            setSelectedStep({
              ...param,
              id: (index + 1).toString(),
            })
          }
          className={classNames("p-1.5 rounded-md min-h-0 bg-atherGray-850 flex md:hidden", {
            hidden: !workflow.capabilities.canUpdate,
          })}
          colorScheme="secondary"
        >
          <ArrowDownIcon width={12} height={12} className="-rotate-90" />
        </IconButton>
      </div>
    </div>
  )
}

export default WorkflowStepHeader
