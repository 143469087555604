import { EntityType, FolderDetail, RecipeStep, SDWorkflow, Tag } from "@/api/sdk"
import ClientOnly from "@/components/ClientOnly"
import LoadingLogo from "@/components/LoadingLogo"
import NotFoundUI from "@/components/NotFound"
import { PhoneOff2Icon } from "@/components/shared/icons"
import RequestModal from "@/components/Workspace/ImageDetailWorkspace/RequestModal"
import { CheckableRecipeInput } from "@/components/Workspace/Recipes/RecipeDetail"
import { useDebounce, useToast } from "@/hooks"
import useCustomRouter from "@/hooks/useCustomRouter"
import { googleAnalytics } from "@/lib/gtag"
import Postmate from "@/lib/postmate"
import { useTrackingViewMutation } from "@/queries"
import { useGetOngoingTaskQuery } from "@/queries/task/useGetTaskInfiniteQuery"
import { useAddTaskMutation, useTagsSDWorkflowsMutation } from "@/queries/tools/comfyui-recipe"
import { useComfyUiWorkflowDetailQuery } from "@/queries/tools/comfyui-recipe/getComfyUiRecipeQueries"
import {
  usePublishComfyUiWorkflowMutation,
  useUpdateComfyUiWorkflowMutation,
} from "@/queries/tools/comfyui-recipe/updateComfyUiRecipeMutations"
import { useComfyUiNodeInfosQuery } from "@/queries/tools/comfyui-recipe/useComfyUiNodeInfosQuery"
import { useAddTagRecipesMutation, useGetRecipeDetailQuery } from "@/queries/workspace/recipe"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { AnimatePresence, motion } from "framer-motion"
import Router from "next/router"
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { isMobileOnly } from "react-device-detect"
import { useForm } from "react-hook-form"
import { useBeforeUnload } from "react-use"
import { ComfyUIGraph, ComfyUINodeInfos } from "../../../ComfyUI/types/node"
import { GraphField, extractGraphInputs } from "../../../ComfyUI/utils/graph"
import { TurboModeProvder, useTurboMode } from "../../../Workspace/TurboMode/useTurboMode"
import ComfyUIActions from "./ComfyUIActions"
import { uploadComfyUIWorkflowFiles } from "./ComfyUIRecipeInfo"
import ComfyUIWorkflowTopbar, { ComfyUIWorkflowTopbarRef } from "./ComfyUITopbar"
import { ComfyUIWorkflowSettingsRef } from "./ComfyUIWorkflowSettings"
import { ComfyUIWorkflowStepsRef } from "./ComfyUIWorkflowSteps"
import LeftBarOption from "./LeftbarOption"
import PublishRecipeModal, { PublishRecipeFormData } from "./PublishRecipeModal"
import client from "@/api/client"
import { useManagementErrorsStore } from "@/stores"
import { isEqual, isEqualWith, sortBy } from "lodash"
import { useCanvasClickStore } from "./use-canvas-click"
import { defaultPrompt, defaultWorkflow } from "../default-workflow"

export type ComfyUIProps = {
  workflowId: string
  nodeInfos?: ComfyUINodeInfos
  isLoadingInfoNode?: boolean
  isReadOnly?: boolean
  isModal?: boolean
}

type AutoSaveSDWorkflowDto = Partial<SDWorkflow & { thumbnailFile: File; tags: Tag[]; synced?: boolean }>

Postmate.debug = process.env.NEXT_PUBLIC_ENV !== "production"

export type ModeComfy =
  | "info"
  | "convert-recipe"
  | "comment"
  | "tasks-history"
  | "task-tracker"
  | "publish-recipe"
  | "prompt-library"

export type SettingTab = "add-inputs" | "preview" | "published"

export const COMFY_RECIPE_ID = "comfyui"

const compareObject = (objA: any, objB: any) => {
  if (objA === objB) return true
  if (!objA || !objB) return false
  if (typeof objA !== "object" || typeof objB !== "object") return false

  return isEqual(JSON.stringify(sortBy(Object.entries(objA), 0)), JSON.stringify(sortBy(Object.entries(objB), 0)))
}

const compareArray = (arrA: any[], arrB: any[]) => {
  if (arrA === arrB) return true
  if (!arrA || !arrB) return false
  if (!Array.isArray(arrA) || !Array.isArray(arrB)) return false
  if (arrA.length !== arrB.length) return false

  const sortedA = sortBy(arrA, "id").map(n => JSON.stringify(sortBy(Object.entries(n), 0)))
  const sortedB = sortBy(arrB, "id").map(n => JSON.stringify(sortBy(Object.entries(n), 0)))
  return isEqual(sortedA, sortedB)
}

const isGraphChanged = (prev?: ComfyUIGraph, curr?: ComfyUIGraph) => {
  if (prev === curr) return false
  if (!prev || !curr) return true

  if (
    !isEqualWith(prev.nodes, curr.nodes, (arrA, arrB) => {
      if (Array.isArray(arrA) && Array.isArray(arrB)) {
        return compareArray(arrA, arrB)
      }
    })
  ) {
    return true
  }

  if (!compareObject(prev.extra?.reroutes, curr.extra?.reroutes)) {
    return true
  }

  for (const key of ["links", "groups", "reroutes"]) {
    if (!compareArray(prev[key], curr[key])) {
      return true
    }
  }

  return false
}

const ComfyUI: FC<ComfyUIProps> = ({ workflowId, nodeInfos, isReadOnly, isModal, isLoadingInfoNode }) => {
  const router = useCustomRouter()
  const toast = useToast()
  const qc = useQueryClient()

  const [isReady, setIsReady] = useState(false)
  const [isEditing, setEditting] = useState(false)
  // const [isLeftbarOpen, setLeftbarOpen] = useState(false)
  const [isPublishModalOpen, setPublishModalOpen] = useState(false)
  const [isCompletedTask, setIsCompletedTask] = useState(false)
  const [isTaskOnGoing, setIsTaskOnGoing] = useState(false)
  const [isGenerating, setIsGenerating] = useState(false)

  const postmateInitiated = useRef(false)
  const frameContainer = useRef<HTMLDivElement>(null)
  const [postmate, setPostmate] = useState<Postmate.ParentAPI>()
  const postmateRef = useRef(postmate)

  const [name, _setName] = useState<string | null>(null)
  const [description, _setDescription] = useState<string>()
  const [tags, _setTags] = useState<Tag[]>([])
  const [file, _setFile] = useState<File | null>(null)
  const [graphInputs, setGraphInputs] = useState<GraphField[]>([])
  const [steps, setSteps] = useState<RecipeStep[]>([])
  const settingsRef = useRef<ComfyUIWorkflowSettingsRef>(null)
  const recipeFormRef = useRef<ComfyUIWorkflowStepsRef>(null)
  const [folder, setFolder] = useState<FolderDetail>()
  // const [canvasClick, setCanvasClick] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [mode, setMode] = useState<ModeComfy | null>(null)
  const [tab, setTab] = useState<SettingTab>("add-inputs")
  const settingsForm = useForm<RecipeStep>({ reValidateMode: "onBlur" })
  const topbarRef = useRef<ComfyUIWorkflowTopbarRef>(null)
  const { triggerCallbacks } = useCanvasClickStore()
  const isLeftbarOpen = !!mode

  const {
    data: workflow,
    isSuccess,
    isLoading,
    error,
    isError,
    refetch: refetchWorkflowDetail,
  } = useComfyUiWorkflowDetailQuery({
    variables: { workflowId },
    enabled: !!workflowId && !!nodeInfos,
  })

  const isOpenRequest = useDebounce(!isLoading && !!workflow && !workflow.capabilities?.canView, 500)
  const statusErrorCode = Number((error as any)?.statusCode)

  const { data: lastRecipe } = useGetRecipeDetailQuery({
    variables: { recipeId: workflow?.lastRecipeId ?? "" },
    enabled: !!workflow,
  })

  const publishFormData = useMemo<PublishRecipeFormData>(
    () => ({
      override: false,
      name: lastRecipe?.name || workflow?.name || "My Recipe",
      description: lastRecipe?.description || workflow?.description || "",
      thumbnailUrl: lastRecipe?.thumbnail || workflow?.thumbnailUrl || "",
      thumbnailId: lastRecipe?.thumbnail || workflow?.thumbnailFileId || "",
      tags: lastRecipe?.tags || [],
      category: lastRecipe?.categories?.[0]?.id.toString() || "",
    }),
    [workflow, lastRecipe],
  )

  const { isReady: isTurboSessionReady, queueTask: queueTurboTask } = useTurboMode()

  const { mutateAsync: updateWorkflow } = useUpdateComfyUiWorkflowMutation({
    onSuccess(data) {
      if (data.isDraft) return

      toast({
        status: "success",
        title: "Success!",
        message: ["ComfyUi workflow is updated successfully!"],
        duration: 5000,
      })
    },
  })

  const { mutateAsync: autoUpdateWorkflow, isPending: isUpdatingWorkflow } = useUpdateComfyUiWorkflowMutation()

  const { mutateAsync: createTask, isPending: isCreatingTask } = useAddTaskMutation({
    onMutate() {
      postmateRef.current?.call("isCreatingTask", isCreatingTask)
    },
    onSuccess: ({ id, params }, variables) => {
      const recipeOngoingKey = useGetOngoingTaskQuery.getKey({
        recipeIds: [COMFY_RECIPE_ID],
        sdWorkflowId: variables.sdWorkflowId,
      })
      qc.invalidateQueries({ queryKey: recipeOngoingKey })

      googleAnalytics.handleCategoryEvent({
        action: "click",
        params: {
          action: "Submit Generated Task Completed",
          comfyui_id: variables.sdWorkflowId,
          comfyui_name: workflow?.name || "",
          task_id: id,
        },
      })

      toast({
        status: "success",
        title: "Submitted! Your task is running",
        duration: 5000,
      })
    },
    onError: error => {
      setIsGenerating(false)
      toast({
        status: "error",
        title: "Error",
        message: [error.message],
      })
    },
  })

  const { mutateAsync: publishRecipe } = usePublishComfyUiWorkflowMutation({
    onSuccess: data => {
      toast({
        status: "success",
        title: "Recipe published!",
        message: [`Recipe "${data.name}" is published successfully.`],
      })
    },
    onError: error => {
      toast({
        status: "error",
        title: "Error",
        message: [error.message],
      })
    },
  })

  const { mutate: addTags } = useAddTagRecipesMutation()

  const handleSelectComfyUINode = useCallback((comfyKey: string) => {
    if (!comfyKey || !postmateRef.current) return

    const [id, _, widget] = comfyKey.split(".")

    return postmateRef.current.call("selectWidget", { id, widget })
  }, [])

  const [hasPendingSave, setPendingSave] = useState(false)
  const autoSaveData = useRef<AutoSaveSDWorkflowDto>({})
  const autoSaveTimer = useRef<NodeJS.Timeout>()

  const handleAutoSave = async () => {
    if (!postmateRef.current) return

    const { id, name, description, thumbnailFile, tags } = autoSaveData.current
    if (!id) return

    const { workflow: graph, output: prompt } = await postmateRef.current.get("validatePrompt")

    const newSteps = await settingsRef.current?.validateSettings(false)

    let thumbnailFileId: string | undefined = undefined
    if (thumbnailFile) {
      setIsUploading(true)
      const [uploaded] = await uploadComfyUIWorkflowFiles([thumbnailFile])

      if (uploaded) {
        thumbnailFileId = uploaded.id
        autoSaveData.current.thumbnailFile = undefined
      } else {
        toast({
          status: "error",
          title: "Error",
          message: ["Failed to upload thumbnail image."],
        })
        setIsUploading(false)
        return
      }

      setFile(null)
      setIsUploading(false)
    }

    await autoUpdateWorkflow({
      workflowId: id,
      data: {
        name,
        description,
        thumbnailFileId,
        workflow: graph,
        steps: newSteps,
        prompt,
      },
    }).then(async data => {
      if (tags) {
        await mutateTags({
          sdWorkflowIds: [data.id],
          tagIds: tags.map(tag => tag.id),
        })
      }
      setPendingSave(false)
      refetchWorkflowDetail()
    })
  }

  const scheduleAutoSave = (data: AutoSaveSDWorkflowDto, timeout = 300000) => {
    Object.assign(autoSaveData.current, data)
    clearTimeout(autoSaveTimer.current)
    setPendingSave(true)
    autoSaveTimer.current = setTimeout(() => {
      handleAutoSave()
    }, timeout)
  }

  const setName = (name: string | null) => {
    _setName(prev => {
      if (name && prev !== name) {
        scheduleAutoSave({ name })
      }

      return name
    })
  }

  const setDescription = (desc?: string) => {
    _setDescription(prev => {
      if (prev !== desc) {
        scheduleAutoSave({ description: desc })
      }

      return desc
    })
  }

  const setTags = (tags: Tag[] | ((prev: Tag[]) => Tag[])) => {
    _setTags(prev => {
      const newTags = typeof tags === "function" ? tags(prev) : tags
      if (JSON.stringify(prev) !== JSON.stringify(newTags)) {
        scheduleAutoSave({ tags: newTags })
        return newTags
      }

      return prev
    })
  }

  const setFile = (file: File | null) => {
    _setFile(file)
    if (file) {
      scheduleAutoSave({ thumbnailFile: file })
    }
  }

  const handleGraphChanged = (graph: ComfyUIGraph) => {
    if (!nodeInfos || !isReady) return

    const inputs = extractGraphInputs(graph, nodeInfos)
    setGraphInputs(prev => {
      const changed = JSON.stringify(prev) !== JSON.stringify(inputs)
      return changed ? inputs : prev
    })
    if (isGraphChanged(graph, autoSaveData.current.workflow as any)) {
      scheduleAutoSave({ workflow: graph })
    }
  }

  const handleSwitchTab = async (value: SettingTab) => {
    if (tab === value) return

    if (value === "preview") {
      const steps = await settingsRef.current?.validateSettings()
      if (!steps) {
        const errorClassName = document.getElementsByClassName(" error-message")

        if (errorClassName.length > 0) {
          const errorElement = errorClassName[0] as HTMLElement
          errorElement.scrollIntoView({ behavior: "smooth" })
        }

        return
      }

      setSteps(steps)
    } else {
      // settingsRef.current?.setSettings(steps ?? [])
    }

    setTab(value)
  }

  const [preview, setPreview] = useState<string | null>(null)

  const { mutateAsync: mutateTags } = useTagsSDWorkflowsMutation()

  const handleSave = async () => {
    if (!workflow || !postmateRef.current) return
    clearTimeout(autoSaveTimer.current)

    const toSave = await postmateRef.current.get("validatePrompt")

    const newSteps = await settingsRef.current?.validateSettings()

    if (!newSteps) {
      handleSwitchTab("add-inputs")

      toast({
        status: "error",
        title: "Error",
        message: ["Please add at least one input."],
      })
      return
    }

    let thumbnailFileId = workflow.thumbnailFileId

    if (file) {
      setIsUploading(true)
      const [uploaded] = await uploadComfyUIWorkflowFiles([file])

      if (uploaded) {
        thumbnailFileId = uploaded.id
      } else {
        toast({
          status: "error",
          title: "Error",
          message: ["Failed to upload thumbnail image."],
        })
        setIsUploading(false)
        return
      }

      setFile(null)
      setIsUploading(false)
    }

    await updateWorkflow({
      workflowId: workflow.id,
      data: {
        name: name || workflow.name || "New comfyUI",
        description,
        thumbnailFileId,
        workflow: toSave.workflow,
        steps: newSteps,
        isDraft: false,
        prompt: toSave.output,
      },
    }).then(async data => {
      await mutateTags({
        sdWorkflowIds: [data.id],
        tagIds: tags.map(tag => tag.id),
      })
      Object.assign(autoSaveData.current, data)
      setPendingSave(false)
      refetchWorkflowDetail()
    })
  }

  const setErrorState = useManagementErrorsStore(state => state.setErrorState)
  const { mutateAsync: createWorkflow } = useMutation({
    mutationFn: async ({
      name,
      workflow,
      prompt,
    }: {
      name: string
      workflow: Record<string, any>
      prompt: Record<string, any>
    }) => {
      return client.api
        .sdWorkflowControllerCreateWorkflow({
          name,
          workflow,
          prompt,
          thumbnailFileId: null,
        })
        .then(res => res.data)
    },
    onSuccess(created) {
      router.push(`/workspace/tools/comfyui/${created.id}`)
    },
    onError: (err: any) => {
      if (
        err?.message.startsWith("Guest user does not have permission") ||
        err?.message.startsWith("Your subscription has ended")
      ) {
        setErrorState({
          isOpen: true,
          message: err.message,
        })
        return
      }
      toast({
        status: "error",
        title: "Error",
        message: ["Failed to create ComfyUI."],
      })
    },
  })

  const handleCreateNewWorkflow = async () => {
    if (!postmateRef.current) return

    setPendingSave(false)
    createWorkflow({
      name: "My Workflow",
      workflow: defaultWorkflow,
      prompt: defaultPrompt,
    })
  }

  const handleSaveAs = async (name: string) => {
    if (!postmateRef.current) return

    const toSave = await postmateRef.current.get("validatePrompt")

    setPendingSave(false)
    createWorkflow({
      name,
      workflow: toSave.workflow,
      prompt: toSave.output,
    })
  }

  const { mutateAsync: interruptCurrentTask } = useMutation({
    mutationFn: async () => {
      return client.api.comfyUiControllerInterruptRunningTasks()
    },
    onSuccess(created) {
      toast({
        status: "success",
        title: "Success",
        message: ["Task interrupted."],
      })
    },
    onError: (err: any) => {
      toast({
        status: "error",
        title: "Error",
        message: ["Failed to interrupt task."],
      })
    },
  })

  const { mutateAsync: clearPendingTasks } = useMutation({
    mutationFn: async () => {
      return client.api.comfyUiControllerClearPendingTasks()
    },
    onSuccess() {
      toast({
        status: "success",
        title: "Success",
        message: ["Pending tasks cleared."],
      })
    },
    onError: () => {
      toast({
        status: "error",
        title: "Error",
        message: ["Failed to clear pending tasks."],
      })
    },
  })

  const handleDownloadGraph = async () => {
    if (!workflow || !postmate) return

    const { workflow: graph } = await postmate.get("validatePrompt")

    const jsonGraph = JSON.stringify(graph, null, 2)

    const blob = new Blob([jsonGraph], { type: "text/plain" })
    const url = URL.createObjectURL(blob)
    const a = document.createElement("a")
    a.href = url
    a.download = `${name ?? "comfy-ui"}.json`
    a.click()
  }

  const handleGenerateRef = useRef<() => Promise<void>>()
  handleGenerateRef.current = async () => {
    if (!postmateRef.current) return

    const { workflow, output: prompt } = await postmateRef.current.get("validatePrompt")

    let t: any

    setIsGenerating(true)

    const queueTask = isTurboSessionReady ? queueTurboTask : createTask
    return queueTask({
      params: { prompt, workflow },
      recipeId: COMFY_RECIPE_ID,
      sdWorkflowId: workflowId,
    })
      .then(t => {
        if (!t) return

        const recipeOngoingKey = useGetOngoingTaskQuery.getKey({
          recipeIds: [COMFY_RECIPE_ID],
          sdWorkflowId: workflowId,
        })
        qc.invalidateQueries({ queryKey: recipeOngoingKey })

        toast({
          status: "success",
          title: "Submitted! Your task is running",
          duration: 5000,
        })

        googleAnalytics.handleCategoryEvent({
          action: "click",
          params: {
            action: "Submit Generated Task",
            comfyui_id: workflowId,
            comfyui_name: workflow?.name,
          },
        })

        postmateRef.current!.call("onPrompt", { id: t.id, workflow })
      })
      .finally(() => {
        setIsGenerating(false)
      })
  }

  const handleCancelConfig = () => {
    setMode(null)
    // reset steps
    if (workflow) {
      setSteps(workflow.steps)
      settingsRef.current?.setSettings(workflow.steps)
    }
  }

  const recipeInputsRef = useRef<CheckableRecipeInput[]>([])
  const handleRecipeInputChange = useCallback((inputs: CheckableRecipeInput[]) => {
    recipeInputsRef.current = inputs
  }, [])

  const handleOpenPublishModal = async () => {
    const steps = await settingsRef.current?.validateSettings(true)
    if (!steps) return

    setPublishModalOpen(true)
  }

  const handlePublishRecipe = async (values: PublishRecipeFormData) => {
    if (!workflow || !postmate) return

    const steps = await settingsRef.current?.validateSettings()
    if (!steps) return

    // set default recipe value
    steps.forEach(step => {
      step.inputs.forEach(input => {
        const value = recipeInputsRef.current.find(i => i.key === input.key)
        if (value) {
          input.value = value.value
        }
      })
    })

    const { tags: recipeTags, category, ...other } = values

    return publishRecipe({
      workflowId: workflow.id,
      data: {
        ...other,
        categoryIds: [parseInt(category)],
        steps,
      },
    }).then(data => {
      refetchWorkflowDetail()
      addTags({
        recipeIds: [data.id],
        tagIds: recipeTags.map(tag => tag.id),
      })
      // setMode(null)
      // setLeftbarOpen(false)
      setPublishModalOpen(false)
    })
  }

  const handleDropFile = (file: File) => {
    if (isReadOnly || !isEditing || !postmate) return

    //read file to json
    const reader = new FileReader()
    reader.onload = async () => {
      try {
        const graph = JSON.parse(reader.result as string)

        postmate.call("loadGraph", graph)
        handleGraphChanged(graph)
      } catch (error) {
        toast({
          status: "error",
          title: "Error",
          message: ["Invalid JSON file."],
        })
      }
    }

    reader.readAsText(file)
  }

  const { mutate: mutateTrackingView } = useTrackingViewMutation()
  const [selectedNodes, setSelectedNodes] = useState<string[]>([])
  const [scale, setScale] = useState(1)

  const { watch } = settingsForm
  const lastSettingsRef = useRef<any>()
  useEffect(() => {
    const subscription = watch(value => {
      if (JSON.stringify(lastSettingsRef.current) !== JSON.stringify(value)) {
        lastSettingsRef.current = value
        scheduleAutoSave({})
      }
    })
    return () => subscription.unsubscribe()
  }, [watch])

  useEffect(() => {
    if (router.isReady && router.query.notificationId) {
      setMode("comment")
    }
  }, [router.isReady])

  useEffect(() => {
    if (postmateRef.current && isModal && isReadOnly) {
      postmateRef.current.call("setIsModal", true)
      postmateRef.current.call("setReadOnly", true)
    }
  }, [isModal, isReadOnly])

  useEffect(() => {
    if (!postmate) return

    postmate.on("ready", () => {
      setIsReady(true)
    })

    postmate.on("openWorkflow", () => {
      topbarRef.current?.open()
    })

    postmate.on("newWorkflow", handleCreateNewWorkflow)

    postmate.on("browseTemplates", () => {
      topbarRef.current?.onNewWorkflow()
    })

    postmate.on("saveWorkflowAs", handleSaveAs)

    postmate.on("graphChanged", handleGraphChanged)

    postmate.on("onSelectionChange", (nodeIds: string[]) => {
      setSelectedNodes(nodeIds)
    })

    postmate.on("canvasClick", event => {
      if (event === "click") {
        triggerCallbacks()
      }
    })

    postmate.on("onScaleChange", (scale: number) => {
      setScale(scale)
    })

    postmate.on("onDropFile", (file: File) => {
      handleDropFile(file)
    })

    postmate.on("queuePrompt", () => handleGenerateRef.current?.())

    postmate.on("interrupt", interruptCurrentTask)

    postmate.on("clearPendingTasks", clearPendingTasks)

    postmate.on("toggleSidebarTab", ({ tab, opened }: { tab: string; opened: boolean }) => {
      if (tab === "queue") {
        setMode(curr => {
          setSettingsOpen(curr !== "tasks-history")
          return curr === "tasks-history" ? null : "tasks-history"
        })
      }
      if (tab === "node-library") {
        setLibraryOpen(opened)
      }
    })
  }, [postmate])

  useEffect(() => {
    if (!postmate) return

    mutateTrackingView({
      entityId: workflowId,
      entityType: EntityType.SD_WORKFLOW,
    })

    googleAnalytics.event({
      action: "view",
      category: "comfyui_detail",
      label: "view_workspace_comfyui_detail",
      params: {
        comfyui_id: workflowId,
        comfyui_name: workflow?.name ?? "",
        pathname: window.location.pathname,
      },
    })

    const recipeOngoingKey = useGetOngoingTaskQuery.getKey({
      recipeIds: [COMFY_RECIPE_ID],
      sdWorkflowId: workflowId,
    })

    const onTaskFailed = () => {
      setTimeout(() => {
        qc.invalidateQueries({ queryKey: recipeOngoingKey })
      }, 1000)
    }

    postmate.on("onValidationFailed", onTaskFailed)
    postmate.on("onExecutionFailed", onTaskFailed)

    return () => {
      console.log("on workflowId changed old", workflowId)
      postmate.off("onValidationFailed", onTaskFailed)
      postmate.off("onExecutionFailed", onTaskFailed)
    }
  }, [postmate, workflowId])

  useEffect(() => {
    if (!workflow) return

    if (workflow.id !== autoSaveData.current?.id) {
      const steps = workflow.steps ?? []
      const inputs = steps.flatMap(s => s.inputs)
      lastSettingsRef.current = { inputs }
      settingsRef.current?.setSettings(steps)

      setSteps(steps)
      setPreview(workflow.thumbnailUrl)
      _setTags(workflow.tags ?? [])
      _setName(workflow.name)
      _setDescription(workflow.description)
      Object.assign(autoSaveData.current, workflow)
      autoSaveData.current.synced = false
    }

    if (!postmate) return

    if (isModal && isReadOnly) {
      postmate.call("setIsModal", true)
      postmate.call("setReadOnly", true)
    } else if (workflow.capabilities?.canUpdate) {
      setEditting(true)
      postmate.call("setReadOnly", false)
    } else {
      setEditting(false)
      postmate.call("setReadOnly", true)
    }

    if (
      isGraphChanged(workflow.workflow as any, autoSaveData.current?.workflow as any) ||
      autoSaveData.current?.synced === false
    ) {
      localStorage.setItem("workflow", JSON.stringify(workflow.workflow))
      postmate.call("loadGraph", workflow.workflow)
      autoSaveData.current.workflow = workflow.workflow
      autoSaveData.current.synced = true
    }

    postmate.on("saveWorkflow", handleSave)

    return () => {
      postmate.off("saveWorkflow", handleSave)
    }
  }, [postmate, workflow])

  useEffect(() => {
    if (postmateInitiated.current || isMobileOnly) return
    postmateInitiated.current = true

    const pm = new Postmate({
      container: frameContainer.current,
      url: "/comfyui/frame?embed=true",
      name: "comfyui",
      classListArray: ["absolute", "top-0", "left-0", "w-full", "h-full", "border-none", "outline-none", "m-0", "p-0"],
      maxHandshakeRequests: Infinity,
    })
    pm.then(postmate => {
      postmateRef.current = postmate
      setPostmate(postmate)
      setIsReady(true)
    })
  }, [])

  useBeforeUnload(isEditing && hasPendingSave, "Leave site? Changes you made may not be saved.")

  useEffect(() => {
    const handler = () => {
      if (isEditing && hasPendingSave && !window.confirm("Leave page? Changes you made may not be saved.")) {
        throw "Route Canceled"
      }
    }

    Router.events.on("beforeHistoryChange", handler)
    return () => {
      Router.events.off("beforeHistoryChange", handler)
    }
  }, [isEditing, hasPendingSave])

  const [isSettingsOpen, setSettingsOpen] = useState(false)
  const [isLibraryOpen, setLibraryOpen] = useState(false)

  const handleToggleSettings = () => {
    if (isLibraryOpen) {
      setLibraryOpen(false)
      postmate?.call("toggleNodeLibrary", false)
    }

    setMode(null)
    setSettingsOpen(curr => {
      postmate?.call("toggleSettings", !curr)
      return !curr
    })
  }

  const handleToggleNodeLibrary = () => {
    if (isSettingsOpen) {
      setSettingsOpen(false)
      postmate?.call("toggleSettings", false)
    }

    setMode(null)
    setLibraryOpen(curr => {
      postmate?.call("toggleNodeLibrary", !curr)
      return !curr
    })
  }

  const handleToggleAction = (changeMode?: ModeComfy) => {
    if (isSettingsOpen) {
      setSettingsOpen(false)
      postmate?.call("toggleSettings", false)
    }

    if (isLibraryOpen) {
      setLibraryOpen(false)
      postmate?.call("toggleNodeLibrary", false)
    }

    if (changeMode && mode !== changeMode) {
      setMode(changeMode)
    } else {
      setMode(null)
    }
  }

  const handleConfigRecipe = async () => {
    if (isSettingsOpen) {
      setSettingsOpen(false)
      postmate?.call("toggleSettings")
    }

    if (isLibraryOpen) {
      setLibraryOpen(false)
      postmate?.call("toggleNodeLibrary")
    }

    if (mode !== "convert-recipe") {
      setMode("convert-recipe")
      handleSwitchTab("add-inputs")
    } else {
      handleCancelConfig()
    }
  }

  const renderBodyComfyUI = () => {
    if (isLoading || isLoadingInfoNode) {
      return (
        <div className="fixed z-[1] top-0 left-0 h-full bg-atherGray-950 w-full flex items-center justify-center">
          <LoadingLogo />
        </div>
      )
    }

    if (isError || !workflow || !nodeInfos) {
      if (statusErrorCode === 403)
        return (
          <RequestModal
            onRefetch={refetchWorkflowDetail}
            dataRequest={{
              entityId: workflowId,
              entityType: EntityType.SD_WORKFLOW,
            }}
            isOpenRequest={true}
            onCloseRequestModal={() => router.push("/workspace/tools/comfyui")}
          />
        )

      return (
        <div className="z-[1] fixed top-0 left-0 h-full bg-atherGray-900 w-full flex flex-col items-center justify-center">
          <NotFoundUI description="ComfyUI" redirectTo={"/workspace/tools/comfyui"} />
        </div>
      )
    }
  }

  return (
    <>
      {!isModal && !isReadOnly && (
        <ComfyUIWorkflowTopbar
          ref={topbarRef}
          isLoading={isLoading}
          isGenerating={isGenerating}
          workflow={workflow}
          name={name}
          isCompletedTask={isCompletedTask}
          hasPendingSave={hasPendingSave}
          handleGenerate={() => handleGenerateRef.current?.()}
        />
      )}
      <div className="flex flex-1 flex-col w-full relative h-[calc((var(--vh,1vh)*100-3rem))] overflow-hidden text-atherGray-100">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="flex flex-1 w-full items-stretch overflow-hidden"
        >
          <div className="hidden md:flex flex-col border-r border-atherGray-800 bg-atherGray-900 w-14">
            <ComfyUIActions
              workflow={workflow}
              mode={mode}
              onSave={handleSave}
              isReady={isReady}
              hasPendingSave={hasPendingSave}
              isSettingsOpen={isSettingsOpen}
              isLibraryOpen={isLibraryOpen}
              isTaskOnGoing={isTaskOnGoing}
              onToggleTasks={() => handleToggleAction("tasks-history")}
              onToggleTaskTracker={() => handleToggleAction("task-tracker")}
              onTogglePublish={() => handleToggleAction("publish-recipe")}
              onTogglePromptLibrary={() => handleToggleAction("prompt-library")}
              onToggleSettings={handleToggleSettings}
              onToggleNodeLibrary={handleToggleNodeLibrary}
              onToggleConvertRecipe={handleConfigRecipe}
              onTogglePreview={() => handleToggleAction("info")}
              onToggleComments={() => handleToggleAction("comment")}
              onDownloadGraph={handleDownloadGraph}
              isAutoSaving={isUpdatingWorkflow}
            />
            <LeftBarOption
              isLeftbarOpen={isLeftbarOpen}
              handleToggleAction={() => handleToggleAction()}
              mode={mode}
              tab={tab}
              selectedNodes={selectedNodes}
              handleSwitchTab={handleSwitchTab}
              handleSelectComfyUINode={handleSelectComfyUINode}
              workflow={workflow}
              isReady={isReady}
              isEditing={isEditing}
              isUploading={isUploading}
              preview={preview}
              setPreview={setPreview}
              file={file}
              setFile={setFile}
              postmate={postmate}
              tags={tags}
              setTags={setTags}
              name={name}
              description={description}
              setDescription={setDescription}
              setIsTaskOnGoing={setIsTaskOnGoing}
              setIsCompletedTask={setIsCompletedTask}
              handleGenerate={() => handleGenerateRef.current?.()}
              isCreatingTask={isCreatingTask}
              folder={folder}
              setFolder={setFolder}
              workflowId={workflowId}
              setName={setName}
              settingsRef={settingsRef}
              settingsForm={settingsForm as any}
              handleOpenPublishModal={handleOpenPublishModal}
              graphInputs={graphInputs}
              steps={steps}
              handleConfigRecipe={handleConfigRecipe}
              handleRecipeInputChange={handleRecipeInputChange}
              recipeFormRef={recipeFormRef}
            />
          </div>

          {isMobileOnly ? (
            <AnimatePresence>
              {!isLeftbarOpen && (
                <div className="relative flex-1 flex flex-col items-center px-2 bg-black select-none justify-center w-full text-gray-600">
                  <PhoneOff2Icon className="text-atherGray-300" />
                  <h2 className="text-2xl my-2 font-semibold text-atherGray-0">Oops!!</h2>
                  <p className={"text-base text-atherGray-300 text-center"}>
                    This feature is not available on Mobile devices.
                    <br />
                    To continue, please try accessing via PC again. Thank you!
                  </p>
                </div>
              )}
            </AnimatePresence>
          ) : (
            <div className="relative flex-1 flex flex-col items-center justify-between" ref={frameContainer}></div>
          )}

          {renderBodyComfyUI()}
        </motion.div>
      </div>

      <PublishRecipeModal
        workflow={workflow}
        lastRecipe={lastRecipe}
        isOpen={isPublishModalOpen}
        onClose={() => setPublishModalOpen(false)}
        defaultValue={publishFormData}
        onPublish={handlePublishRecipe}
      />

      {workflow && (
        <RequestModal
          onRefetch={refetchWorkflowDetail}
          dataRequest={{
            entityId: workflow.id,
            entityType: EntityType.SD_WORKFLOW,
            workspace: workflow.workspace,
          }}
          isOpenRequest={isOpenRequest}
          onCloseRequestModal={() => router.push("/workspace/tools/comfyui")}
        />
      )}
    </>
  )
}

export type ComfyUIRecipeBuilderProps = {
  workflowId: string
  className?: string
  isReadOnly?: boolean
  isModal?: boolean
}

const ComfyUIRecipeBuilder: FC<ComfyUIRecipeBuilderProps> = ({ workflowId, className, isReadOnly, isModal }) => {
  const { data: nodeInfos, isLoading } = useComfyUiNodeInfosQuery()

  return (
    <TurboModeProvder>
      <ClientOnly>
        <ComfyUI
          isLoadingInfoNode={isLoading}
          workflowId={workflowId}
          nodeInfos={nodeInfos}
          isReadOnly={isReadOnly}
          isModal={isModal}
        />
      </ClientOnly>
    </TurboModeProvder>
  )
}

export { ComfyUIRecipeBuilder }
