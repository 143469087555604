import { ArrowDownIcon } from "@/components/shared/icons"
import { cn } from "@/utils/cn"
import { AnimatePresence, motion } from "framer-motion"
import useCustomRouter from "@/hooks/useCustomRouter"
import { useEffect, useState } from "react"
interface MenuItemParentProps {
  title: string
  icon: React.ReactNode
  className?: string
  children?: React.ReactNode
  values?: string[]
  isPro?: boolean
}

const MenuItemParent = ({ title, icon, className, children, values, isPro }: MenuItemParentProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const router = useCustomRouter()

  useEffect(() => {
    if (values?.includes(router.pathname)) {
      setIsOpen(true)
    }
  }, [values])

  return (
    <div>
      <div
        className={cn(
          "px-4 py-2 cursor-pointer w-full flex items-center hover:bg-atherGray-850 rounded-2xl select-none text-atherGray-300 group",
          className,
        )}
        onClick={() => setIsOpen(prev => !prev)}
      >
        <div className="flex items-center">
          <div className="mr-2 w-5 h-5 flex justify-center items-center text-atherGray-500 group-hover:text-atherGray-0">
            {icon}
          </div>
          <p className={cn("font-semibold text-atherGray-300 group-hover:text-atherGray-0 text-sm")}>{title}</p>
          {isPro && (
            <span className="bg-[#D2AF53] text-[#090218] rounded-[4px] text-[0.65rem] py-0.5 px-1 font-semibold ml-2">
              PRO
            </span>
          )}
        </div>
        <button
          className={cn("ml-auto transition-transform ease-linear", {
            "transform rotate-180": isOpen,
          })}
        >
          <ArrowDownIcon />
        </button>
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            className="overflow-hidden"
          >
            <div className="pt-2 space-y-2">{children}</div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default MenuItemParent
