import { IconProps } from "."

export const ImageUploadIcon = (props: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M4.16699 17.4987C7.67531 13.5392 11.618 8.28828 17.5003 12.2266"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M11.667 2.50193C11.2752 2.5 10.0259 2.5 9.58366 2.5C5.85171 2.5 3.98573 2.5 2.82636 3.65937C1.66699 4.81874 1.66699 6.68471 1.66699 10.4167C1.66699 14.1486 1.66699 16.0146 2.82636 17.174C3.98573 18.3333 5.85171 18.3333 9.58366 18.3333C13.3156 18.3333 15.1816 18.3333 16.341 17.174C17.4564 16.0586 17.4987 14.2892 17.5003 10.8333"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M14.167 6.2487C14.5766 6.67011 15.6668 8.33203 16.2503 8.33203M18.3337 6.2487C17.9241 6.67011 16.8338 8.33203 16.2503 8.33203M16.2503 8.33203V1.66536"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
