import ImageWebpComponent from "@/components/ImageWebpComponent"
import Input from "@/components/Input"
import ReactPortal from "@/components/ReactPortal"
import { ChevronDownIcon, PlusIcon, XIcon } from "@/components/shared/icons"
import { useOutsideClick } from "@/hooks"
import { googleAnalytics } from "@/lib/gtag"
import { useAuth } from "@/providers/authContext"
import { useCreateNewStyleStore } from "@/stores"
import { cn } from "@/utils/cn"
import classNames from "classnames"
import { motion } from "framer-motion"
import groupBy from "lodash/groupBy"
import React, { useRef } from "react"
import InfiniteScroll from "react-infinite-scroller"

interface RecipeSelectProps {
  onClose: () => void
  search: string
  setSearch: (search: string) => void
  mappedData?: {
    id: string
    name: string
    thumbnailUrl: string
    category?: string
  }[]
  value?: string
  className?: string
  values?: string[]
  setSelected: (selected?: string) => void
  title: string
  descriptionNode?: React.ReactNode
  multiple?: boolean
  inputType?: string
  isOpen: boolean
  rtl?: boolean
  isFetching?: boolean
  hasNextPage?: boolean
  fetchNextPage?: () => void
}

const CreateStyleComponent = () => {
  const { handleSignIn } = useAuth()
  const setIsOpen = useCreateNewStyleStore(state => state.setIsOpen)

  return (
    <div className="flex flex-col items-center">
      <div
        onClick={async () => {
          const isSignIn = await handleSignIn()

          if (!isSignIn) return
          setIsOpen(true)
        }}
        className={classNames(
          "border-[2px] cursor-pointer rounded-lg bg-atherGray-950 border-atherGray-800 pt-[100%] relative w-full border-dashed flex items-center justify-center",
        )}
      >
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-atherPurple-500 p-2 rounded-full">
          <PlusIcon className="text-atherGray-0" width={16} height={16} />
        </div>
      </div>
      <p className={"text-xs font-semibold mt-1 truncate text-atherGray-100"}>Create new style</p>
    </div>
  )
}

const DrawerSelect = ({
  onClose,
  search,
  value,
  isOpen,
  values,
  inputType,
  className,
  setSearch,
  setSelected,
  mappedData,
  descriptionNode,
  title,
  multiple,
  rtl,
  fetchNextPage,
  hasNextPage,
  isFetching,
}: RecipeSelectProps) => {
  const mappedDataByCategory = groupBy(mappedData ?? [], item => item.category)
  const ref = useRef<HTMLDivElement>(null)
  const scrollRef = useRef<HTMLDivElement>(null)

  useOutsideClick({
    ref,
    enabled: isOpen,
    handler: () => {
      setTimeout(() => {
        onClose()
      }, 50)
    },
  })

  return (
    <ReactPortal wrapperId={"layout-inside"}>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{
          ease: "easeInOut",
          duration: 0.2,
        }}
        className={cn(
          "fixed top-0 md:top-[4.5rem] pb-[5.15rem] pt-4 md:pb-4 height-full-screen z-[25] md:z-[2] md:h-[calc((var(--vh,1vh)*100)-4.5rem)] left-0 md:left-[20rem] w-[88vw] md:w-80 bg-atherGray-900 flex flex-col overflow-hidden",
          {
            "left-0 md:left-[20rem]": !rtl,
            "right-0 md:right-[20rem]": rtl,
          },
          className,
        )}
      >
        <div ref={ref} className="flex flex-1 flex-col overflow-hidden">
          <div className="flex flex-col mb-4 px-4">
            <div onClick={onClose} className="cursor-pointer flex items-center">
              {!rtl && (
                <div className="w-4 mr-1">
                  <ChevronDownIcon className="rotate-90" />
                </div>
              )}
              <h3 className="font-semibold text-xl">{title}</h3>
              {rtl && (
                <div className="w-4 ml-auto">
                  <ChevronDownIcon className="-rotate-90" />
                </div>
              )}
            </div>
            {descriptionNode}
          </div>
          <div className="px-4 pb-4">
            <Input
              className="bg-atherGray-850 text-sm"
              value={search}
              onChange={e => setSearch(e.target.value)}
              placeholder="Type to search ..."
            />
          </div>
          <div className="w-full overflow-auto flex-1 p-4 pt-0" ref={scrollRef}>
            <InfiniteScroll
              loadMore={() => fetchNextPage?.()}
              hasMore={!!hasNextPage && !isFetching}
              threshold={200}
              getScrollParent={() => scrollRef.current}
              useWindow={false}
            >
              {Object.keys(mappedDataByCategory).length === 0 && (
                <div className="grid grid-cols-2 gap-2">{inputType === "style" ? <CreateStyleComponent /> : null}</div>
              )}
              {Object.keys(mappedDataByCategory)
                .sort()
                .map((category, idx) => (
                  <div key={category.toString()}>
                    {category !== "undefined" && (
                      <p
                        className={classNames("font-semibold uppercase text-atherGray-500 text-sm pb-1", {
                          "pt-4": idx > 0,
                        })}
                      >
                        {category}
                      </p>
                    )}
                    <div className="grid grid-cols-2 gap-2">
                      {inputType === "style" ? <CreateStyleComponent /> : null}
                      {mappedDataByCategory[category]?.map(item => (
                        <div
                          className={classNames("cursor-pointer relative rounded-lg")}
                          key={item.id}
                          onClick={() => {
                            setSelected(item.id)

                            googleAnalytics.event({
                              action: "click",
                              category: "category",
                              label: `select_${title}`,
                              params: {
                                id: item.id,
                              },
                            })

                            if (multiple) return
                            onClose()
                          }}
                        >
                          <div
                            className={classNames("border-[2px] rounded-lg pt-[100%] relative w-full", {
                              "border-atherPurple-500":
                                (multiple && values?.includes(item.id)) || (!multiple && value === item.id),
                              "border-atherGray-850":
                                (multiple && !values?.includes(item.id)) || (!multiple && value !== item.id),
                            })}
                          >
                            <div className="w-full h-full flex flex-col items-center justify-center rounded-lg absolute top-0 left-0">
                              <ImageWebpComponent
                                src={item.thumbnailUrl}
                                alt=""
                                className={classNames("object-cover !h-full w-full block")}
                              />
                            </div>
                          </div>
                          <p className={classNames("text-xs font-semibold mt-1 truncate text-atherGray-100")}>
                            {item.name}
                          </p>
                          {values?.includes(item.id) && (
                            <div
                              onClick={() => {
                                setSelected(item.id)

                                googleAnalytics.event({
                                  action: "click",
                                  category: "category_selected",
                                  label: `select_${title}`,
                                  params: {
                                    id: item.id,
                                    name: item.name,
                                    category: item.category ?? "",
                                  },
                                })

                                if (multiple) return
                                onClose()
                              }}
                              className="absolute top-1 right-1 z-[1]"
                            >
                              <XIcon className="w-3 h-3 text-red-500" />
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
            </InfiniteScroll>
          </div>
        </div>
      </motion.div>
    </ReactPortal>
  )
}

export default DrawerSelect
