import { IconProps } from "."

export const FolderOpenIcon = (props: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="21" viewBox="0 0 22 21" fill="none" {...props}>
      <path
        d="M10.5704 20H9.50703C5.49678 20 3.49165 20 2.24583 18.4542C1 16.9083 1 14.4204 1 9.44444V6.21895C1 4.30157 1 3.34287 1.32354 2.62351C1.55416 2.11072 1.89517 1.68761 2.30843 1.40145C2.88819 1 3.66083 1 5.20611 1C6.19611 1 6.69111 1 7.12443 1.20162C8.11377 1.66196 8.52173 2.77711 8.96816 3.88497L9.50703 5.22222M6.00413 5.22222H14.5487C16.3409 5.22222 17.2369 5.22222 17.8806 5.7559C18.1593 5.98694 18.3986 6.28381 18.5848 6.62958C18.8747 7.16804 18.9692 7.84883 19 8.91667"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M3.5956 11.5755C4.02693 10.4264 4.24259 9.85178 4.66347 9.49097C5.34287 8.90853 6.31965 9.00615 7.15669 9.00615H16.2524C18.7292 9.00615 19.9676 9.00615 20.5856 9.80308C21.6464 11.1711 20.3949 13.3902 19.8908 14.7332C18.9871 17.141 18.5352 18.3449 17.6352 19.0791C16.2638 20.1979 14.269 19.9835 12.6139 19.9835H8.93724C5.3942 19.9835 3.62269 19.9835 2.70143 18.8866C1.00075 16.8617 2.83072 13.6133 3.5956 11.5755Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  )
}
