import { ContentModerationRating, SDStyleDetail } from "@/api/sdk"
import Skeleton from "@/components/Skeleton"
import useContainerWidth from "@/hooks/useContainerWidth"
import { googleAnalytics } from "@/lib/gtag"
import { QueryKey } from "@tanstack/react-query"
import classNames from "classnames"
import { AnimatePresence, motion } from "framer-motion"
import { useEffect, useRef, useState } from "react"
import { AddSquareIcon, TickIcon } from "@/components/shared/icons"
import ImageWebpComponent from "@/components/ImageWebpComponent"
import IconButton from "@/components/IconButton"
import { cn } from "@/utils/cn"
import { useInView } from "react-intersection-observer"
import StyleCardActionButtons from "./StyleCardActionButtons"
import StyleReaction from "./StyleReaction"
import NsfwBlurImage from "@/components/NsfwBlurImage"

interface GalleryImageProps {
  onClick?: () => void
  style: SDStyleDetail
  isDisabledInView?: boolean
  containerWidth?: number
  stretch?: boolean
  showHoverAttributes?: boolean
  href: string
  layout?: "grid" | "list"
  queryKey?: QueryKey
  hiddenMenu?: boolean
  isExplore?: boolean
  selectionMode?: boolean
  isChecked?: boolean
  onCheck?: () => void
}

const ImageInView = ({
  onClick,
  style,
  hiddenMenu,
  isDisabledInView,
  containerWidth,
  isExplore,
  queryKey,
  layout = "grid",
  stretch,
  href,
  selectionMode,
  onCheck,
  isChecked,
}: GalleryImageProps) => {
  const [imageLoaded, setImageLoaded] = useState(false)
  const imageItemRef = useRef<HTMLDivElement | null>(null)
  const { ref, inView } = useInView({
    threshold: 0,
    rootMargin: "800px 0px 800px 0px",
  })
  const [imageSize, setImageSize] = useState(0)
  const [isShow, setIsShow] = useState(
    style.thumbnailModerationRating === ContentModerationRating.HardcoreNsfw ||
      style.thumbnailModerationRating === ContentModerationRating.SemiNsfw,
  )
  const widthCard = useContainerWidth(imageItemRef, !!style)

  useEffect(() => {
    if (isDisabledInView) return
    if (containerWidth && style.thumbnailWidth && style.thumbnailHeight && widthCard) {
      const height = (style.thumbnailHeight / style.thumbnailWidth) * (widthCard || 250)

      setImageSize(height)
    }
  }, [containerWidth, style, widthCard, isDisabledInView])

  const handleClick = () => {
    googleAnalytics.event({
      action: "click",
      category: "style_item",
      label: `view_style_detail`,
      params: {
        style_id: style.id,
        style_name: style.name ?? "",
        pathname: window.location.pathname,
      },
    })
  }

  return (
    <div
      ref={r => {
        imageItemRef.current = r
        ref(r)
      }}
      className={classNames(
        "image-item relative overflow-hidden rounded-2xl transform-gpu bg-atherGray-900 shadow-sm shadow-whiteAlpha-200",
      )}
      style={{
        minHeight: "10rem",
        height: stretch ? "100%" : isDisabledInView ? "auto" : imageSize,
      }}
      onClick={e => {
        handleClick()
      }}
    >
      {selectionMode && (
        <div
          className={classNames("absolute w-full h-full top-0 left-0 border-[2px] pointer-events-none rounded-2xl", {
            "border-atherPurple-500": isChecked,
            "border-[transparent]": !isChecked,
            "z-0": !selectionMode,
            "z-[1]": selectionMode,
          })}
        />
      )}
      <AnimatePresence mode="wait">
        {(inView || isDisabledInView) && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.25 }}
            className="flex items-center justify-center w-full flex-col overflow-hidden"
            style={{
              minHeight: "10rem",
              height: stretch ? "100%" : isDisabledInView ? "auto" : imageSize,
            }}
          >
            <Skeleton
              className="group rounded-2xl h-full w-full flex items-center justify-center"
              isLoaded={imageLoaded}
            >
              <div className={classNames("h-full select-none flex items-center justify-center")}>
                <ImageWebpComponent
                  src={style?.thumbnailUrl ?? ""}
                  alt={style.name ?? ""}
                  className={classNames("block object-contain w-full h-full", {
                    "blur-2xl": !imageLoaded || isShow,
                  })}
                  onLoad={() => setImageLoaded(true)}
                />
              </div>
              {selectionMode && (
                <div
                  className={classNames(
                    "absolute bottom-0 left-0 transition-all pointer-events-none hidden lg:block group-hover:bg-blackAlpha-400 group-hover:h-full group-hover:w-full",
                    {
                      "h-full w-full bg-blackAlpha-600": selectionMode,
                    },
                  )}
                />
              )}
            </Skeleton>

            {/* top and bottom linear */}
            {!hiddenMenu && (
              <div
                className="h-12 absolute top-0 left-0 w-full pointer-events-none"
                style={{
                  background: "linear-gradient(180deg, rgba(0, 0, 0, 0.70) 0%, rgba(0, 0, 0, 0.00) 100%)",
                }}
              />
            )}

            {/* action */}
            {!style.deletedAt && (
              <>
                <div
                  className="h-12 absolute bottom-0 left-0 w-full pointer-events-none"
                  style={{
                    background: "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.70) 100%)",
                  }}
                />
                <StyleReaction
                  className="absolute bottom-2 left-2 hover:bg-transparent active:bg-transparent"
                  style={style}
                />
              </>
            )}

            {!hiddenMenu &&
              (selectionMode ? (
                <IconButton
                  onClick={e => {
                    e.stopPropagation()
                    e.preventDefault()
                    onCheck?.()
                  }}
                  className={cn(
                    "absolute top-2 right-2 shadow-sm shadow-blackAlpha-500 bg-atherGray-700 p-0 w-7 h-7 min-h-0 rounded-full",
                    {
                      "text-white bg-atherPurple-500 hover:bg-atherPurple-400": isChecked,
                      "text-atherGray-300 hover:bg-atherGray-600": !isChecked,
                    },
                  )}
                >
                  <TickIcon />
                </IconButton>
              ) : (
                <StyleCardActionButtons style={style} layout={layout} />
              ))}

            {!hiddenMenu && (style.discoverableAt || style.capabilities?.canRemoveFromLibrary) && (
              <div className="absolute flex items-center top-2 left-0">
                {style.discoverableAt && (
                  <span className="font-semibold bg-atherPurple-500 px-1.5 py-0.5 rounded-r-lg text-[0.65rem]">E</span>
                )}
                {style.capabilities?.canRemoveFromLibrary && (
                  <span className="ml-1">
                    <AddSquareIcon width={16} height={16} />
                  </span>
                )}
                {!isShow &&
                  inView &&
                  (style?.thumbnailModerationRating === ContentModerationRating.HardcoreNsfw ||
                    style?.thumbnailModerationRating === ContentModerationRating.SemiNsfw) && (
                    <NsfwBlurImage
                      buttonCloseClassName="relative top-auto left-auto ml-1 px-1 py-0.5 min-h-5"
                      isShow={isShow}
                      size={14}
                      moderationRating={style.thumbnailModerationRating}
                      onToggleShow={setIsShow}
                      gaEvent={{
                        tab_name: "style",
                        params: {
                          style_id: style.id,
                          style_name: style.name ?? "",
                        },
                      }}
                    />
                  )}
              </div>
            )}

            {inView &&
              (isExplore || isShow) &&
              (style.thumbnailModerationRating === ContentModerationRating.HardcoreNsfw ||
                style.thumbnailModerationRating === ContentModerationRating.SemiNsfw) && (
                <NsfwBlurImage
                  isShow={isShow}
                  moderationRating={style.thumbnailModerationRating}
                  onToggleShow={setIsShow}
                  gaEvent={{
                    tab_name: "style",
                    params: {
                      style_id: style.id,
                      style_name: style.name ?? "",
                    },
                  }}
                />
              )}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default ImageInView
