import client from "@/api/client"
import { createAuthenticatedQuery } from "../createAuthenticatedQuery"
import { GetOrderHistoriesResponse } from "@/api/sdk"
import { enableQueryMiddleware } from "../middlewares/enableQueryMiddleware"

export const useGetBillingsQuery = createAuthenticatedQuery<GetOrderHistoriesResponse, { workspaceId: string }, Error>({
  primaryKey: "billing-payments",
  queryFn: ({ queryKey: [_primaryKey, variables] }) =>
    client.api
      .billingControllerGetOrderHistories({
        workspaceId: variables.workspaceId,
      })
      .then(res => res.data),
  use: [enableQueryMiddleware(i => !!i.workspaceId)],
})
