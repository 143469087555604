import { RecipeType } from "@/api/sdk"
import { ModalShareType } from "@/lib/store"
import { QueryKey } from "@tanstack/react-query"
import { create } from "zustand"

interface DeleteItemStore {
  deletingItem: {
    type: ModalShareType
    id: string
    folderId?: string
    deletedAt: string | null
    isRedirect?: boolean
    onClose?: () => void
    ids?: string[]
    queryKey?: QueryKey
    parentFolderId?: string
    recipeParams?: {
      recipeId?: string
      roomId?: string
      recipeType?: RecipeType
    }
    [key: string]: any
  } | null

  setDeletingItem: (item: DeleteItemStore["deletingItem"]) => void
}

export const useDeleteItemStore = create<DeleteItemStore>(set => ({
  deletingItem: null,
  setDeletingItem: deletingItem => set({ deletingItem }),
}))
