const generateCaptchaToken = async (): Promise<string> => {
  return await new Promise(resolve => {
    grecaptcha.enterprise.ready(async () => {
      const token = await grecaptcha.enterprise.execute(process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY as string, {
        action: "GENERATE_IMAGE",
      })
      resolve(token)
    })
  })
}

export default generateCaptchaToken
