import { IconProps } from "."

export const UnfoldMoreSharpIcon = (props: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 16" fill="none" {...props}>
      <path
        d="M1 10L6.35982 14.4665C6.66592 14.7216 6.81898 14.8491 7 14.8491C7.18102 14.8491 7.33408 14.7216 7.64018 14.4665L13 10"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 6L6.35982 1.53349C6.66592 1.2784 6.81898 1.15085 7 1.15085C7.18102 1.15085 7.33408 1.2784 7.64018 1.53349L13 6"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
