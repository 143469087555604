/* eslint-disable camelcase */

import _snakeCase from "lodash/snakeCase"

export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_TRACKING_ID ?? "G-DDJ8HWE4XJ"

declare global {
  interface Window {
    gtag: any
  }
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
const pageView = (url: string): void => {
  window.gtag("event", "page_view", {
    page_path: url,
  })
}

type GTagEvent = {
  action: string
  category?: string
  label?: string
  value?: number
  params?: Record<Exclude<string, "">, string | number | null>
}

const config = (data: Record<string, any>): void => {
  window.gtag("config", GA_TRACKING_ID, {
    ...data,
    send_page_view: false,
  })
}

const setProperty = (data: Record<string, any>): void => {
  window.gtag("set", "user_properties", data)
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
const event = ({ action, category, label = "", value = 1, params }: GTagEvent): void => {
  window.gtag("event", action, {
    event_category: category,
    event_label: label,
    value,
    ...params,
  })
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
const handleCategoryEvent = ({ action, label = "", value = 1, params }: GTagEvent): void => {
  const category = window.location.pathname.includes("/explore")
    ? "explore"
    : window.location.pathname.includes("/my-creation")
      ? "my_creations"
      : window.location.pathname.includes("/landing")
        ? "landing_page"
        : window.location.pathname.includes("/workspace")
          ? "workspace"
          : window.location.pathname.includes("/quests")
            ? "earn_wow"
            : window.location.pathname.includes("/profile")
              ? "profile"
              : window.location.pathname.includes("/settings/account")
                ? "account_settings"
                : window.location.pathname.includes("/rooms")
                  ? "room"
                  : window.location.pathname.includes("/events")
                    ? "events"
                    : "other"

  const page_id = window.location.pathname.includes("/explore")
    ? "explore"
    : window.location.pathname.includes("/my-creation")
      ? "my-creations"
      : window.location.pathname.includes("/landing")
        ? "landing"
        : window.location.pathname.includes("/workspace")
          ? "workspace"
          : window.location.pathname.includes("/quests")
            ? "quests"
            : window.location.pathname.includes("/profile")
              ? "profile"
              : window.location.pathname.includes("/settings")
                ? "account"
                : window.location.pathname.includes("/rooms")
                  ? "rooms"
                  : window.location.pathname.includes("/events")
                    ? "events"
                    : "other"

  window.gtag("event", action, {
    event_category: category,
    event_label: label || _snakeCase(params?.action?.toString() ?? "") || `${category}_click`,
    page_id: `${window.location.hostname}/${page_id}`,
    sub_page_id: window.location.pathname,
    value,
    ...params,
  })
}

export const googleAnalytics = {
  pageView,
  event,
  config,
  handleCategoryEvent,
  setProperty,
}
