import { IconProps } from "."

export const CropIcon = (props: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none" {...props}>
      <path
        d="M3 1V3M21 19H19M15.5 19H9C6.17157 19 4.75736 19 3.87868 18.1213C3 17.2426 3 15.8284 3 13V6.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 21L19 11C19 7.22877 19 5.34315 17.8284 4.17158C16.6569 3 14.7712 3 11 3L1 3"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
