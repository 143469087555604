import client from "@/api/client"
import { CreateSDModelDto, SearchModelResults, UpdateSDModelDto } from "@/api/sdk"
import { InfiniteData } from "@tanstack/react-query"
import { createMutation } from "react-query-kit"
import { actionMiddleware } from "@/queries/middlewares/actionMiddleware"
import { useWorkspaceModelsInfiniteQuery } from "./useWorkspaceModelsInfiniteQuery"
import { useRecentModelsInfiniteQuery } from "@/components/Workspace/RecentV2/RecentModels"

interface RemoveStylesMutationVariables {
  ids: string[]
}

export const useAddModelsMutation = createMutation({
  mutationFn: async ({ ids }: RemoveStylesMutationVariables) => {
    return client.api
      .sdModelControllerAddSdModelsToLibrary({
        modelIds: ids,
      })
      .then(res => res.data)
  },
  use: [
    actionMiddleware({
      onSuccess: ({ queryClient, variables: { ids } }) => {
        // Update model list
        const modelListKey = useWorkspaceModelsInfiniteQuery.getKey()
        const modelListQueriesDataEntries = queryClient.getQueriesData<InfiniteData<SearchModelResults, number>>({
          queryKey: modelListKey,
        })

        modelListQueriesDataEntries?.forEach(([key, data]) => {
          if (!data) return

          const updatedData = {
            ...data,
            pages: data.pages.map(page => {
              const updatedPage = {
                ...page,
                data: page.data.map(model => {
                  if (ids.includes(model.id)) {
                    return {
                      ...model,
                      capabilities: {
                        ...model.capabilities,
                        canAddToLibrary: false,
                        canRemoveFromLibrary: true,
                      },
                    }
                  }
                  return model
                }),
              }
              updatedPage.total = updatedPage.data.length
              return updatedPage
            }),
          }

          queryClient.setQueryData(key, updatedData)
        })
      },
    }),
  ],
})

export const useCreateSdModelMutation = createMutation({
  mutationFn: async (data: CreateSDModelDto) => {
    return client.api.sdModelControllerCreateModel(data).then(res => res.data)
  },
  use: [
    actionMiddleware({
      onSuccess: ({ queryClient, variables }) => {
        queryClient.invalidateQueries({ queryKey: useWorkspaceModelsInfiniteQuery.getKey() })
      },
    }),
  ],
})

export const useUpdateSdModelMutation = createMutation({
  mutationFn: async (data: UpdateSDModelDto & { id: string }) => {
    return client.api.sdModelControllerUpdateModel(data.id, data).then(res => res.data)
  },
  use: [
    actionMiddleware({
      onSuccess: ({ queryClient, variables }) => {
        queryClient.invalidateQueries({ queryKey: useWorkspaceModelsInfiniteQuery.getKey() })
        queryClient.invalidateQueries({ queryKey: useRecentModelsInfiniteQuery.getKey() })
      },
    }),
  ],
})
