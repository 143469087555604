import client from "@/api/client"
import { createMutation } from "react-query-kit"
import { actionMiddleware } from "../middlewares/actionMiddleware"
import { useWorkspaceImagesInfiniteQuery } from "./useWorkspaceImagesInfiniteQuery"
import { InfiniteData } from "@tanstack/react-query"
import {
  GetRecipesResult,
  GetSubFoldersResponse,
  GetWorkflowsResult,
  SearchImagesResponse,
  SearchModelResults,
  SearchModelTrainingResults,
  SearchSDStyleResponse,
  SearchSDWorkflowResponse,
  SearchWildcardResponse,
} from "@/api/sdk"
import { useGetRecipesInfiniteQuery } from "./recipe"
import { useComfyUiWorkflowInfiniteQuery } from "../tools/comfyui-recipe/getComfyUiRecipeQueries"
import { useSubFoldersInfiniteQuery } from "./folder"
import { useWorkspaceStyleInfiniteQuery } from "../tools/style/useGetStyleInfiniteQuery"
import { useWorkspaceWorkflowInfiniteQuery } from "./useWorkspaceWorkflowInfiniteQuery"
import { useWorkspaceModelsInfiniteQuery, useWorkspaceWildcardsInfiniteQuery } from "../tools"
import { useWorkspaceModelTrainingInfiniteQuery } from "../tools/model/useWorkspaceModelTrainingInfiniteQuery"

export const useTrashDeleteAllImageMutation = createMutation({
  mutationFn: () => client.api.imageControllerFullyDeleteImages(),
  use: [
    actionMiddleware({
      onSuccess: ({ queryClient, variables }) => {
        const key = useWorkspaceImagesInfiniteQuery.getKey()
        const entities = queryClient.getQueriesData<InfiniteData<SearchImagesResponse, number>>({ queryKey: key })

        entities?.forEach(([key, listDataEntity]) => {
          if (listDataEntity) {
            const updatedData = {
              ...listDataEntity,
              pages: listDataEntity.pages.map(page => ({
                ...page,
                images: [],
                total: 0,
              })),
            }
            queryClient.setQueryData(key, updatedData)
          }
        })
      },
    }),
  ],
})

export const useTrashDeleteAllRecipeMutation = createMutation({
  mutationFn: () => client.api.recipeControllerFullyDeleteAllRecipes(),
  use: [
    actionMiddleware({
      onSuccess: ({ queryClient, variables }) => {
        const key = useGetRecipesInfiniteQuery.getKey()
        const entities = queryClient.getQueriesData<InfiniteData<GetRecipesResult, number>>({ queryKey: key })

        entities?.forEach(([key, listDataEntity]) => {
          if (listDataEntity) {
            const updatedData = {
              ...listDataEntity,
              pages: listDataEntity.pages.map(page => ({
                ...page,
                recipes: [],
                total: 0,
              })),
            }
            queryClient.setQueryData(key, updatedData)
          }
        })
      },
    }),
  ],
})

export const useTrashDeleteAllComfyUIMutation = createMutation({
  mutationFn: () => client.api.sdWorkflowControllerFullyDeleteAllSdWorkflows(),
  use: [
    actionMiddleware({
      onSuccess: ({ queryClient, variables }) => {
        const key = useComfyUiWorkflowInfiniteQuery.getKey()
        const entities = queryClient.getQueriesData<InfiniteData<SearchSDWorkflowResponse, number>>({ queryKey: key })

        entities?.forEach(([key, listDataEntity]) => {
          if (listDataEntity) {
            const updatedData = {
              ...listDataEntity,
              pages: listDataEntity.pages.map(page => ({
                ...page,
                workflows: [],
                total: 0,
              })),
            }
            queryClient.setQueryData(key, updatedData)
          }
        })
      },
    }),
  ],
})

export const useTrashDeleteAllFolderMutation = createMutation({
  mutationFn: () => client.api.folderControllerFullyDeleteAllFolders(),
  use: [
    actionMiddleware({
      onSuccess: ({ queryClient, variables }) => {
        const key = useSubFoldersInfiniteQuery.getKey()
        const entities = queryClient.getQueriesData<InfiniteData<GetSubFoldersResponse, number>>({ queryKey: key })

        entities?.forEach(([key, listDataEntity]) => {
          if (listDataEntity) {
            const updatedData = {
              ...listDataEntity,
              pages: listDataEntity.pages.map(page => ({
                ...page,
                folders: [],
                total: 0,
              })),
            }
            queryClient.setQueryData(key, updatedData)
          }
        })
      },
    }),
  ],
})

export const useTrashDeleteAllStyleMutation = createMutation({
  mutationFn: () => client.api.sdStyleControllerFullyDeleteAllStyles(),
  use: [
    actionMiddleware({
      onSuccess: ({ queryClient, variables }) => {
        const key = useWorkspaceStyleInfiniteQuery.getKey()
        const entities = queryClient.getQueriesData<InfiniteData<SearchSDStyleResponse, number>>({ queryKey: key })

        entities?.forEach(([key, listDataEntity]) => {
          if (listDataEntity) {
            const updatedData = {
              ...listDataEntity,
              pages: listDataEntity.pages.map(page => ({
                ...page,
                styles: [],
                total: 0,
              })),
            }
            queryClient.setQueryData(key, updatedData)
          }
        })
      },
    }),
  ],
})

export const useTrashDeleteAllMacroMutation = createMutation({
  mutationFn: () => client.api.workflowControllerFullyDeleteAllWorkflows(),
  use: [
    actionMiddleware({
      onSuccess: ({ queryClient, variables }) => {
        const key = useWorkspaceWorkflowInfiniteQuery.getKey()
        const entities = queryClient.getQueriesData<InfiniteData<GetWorkflowsResult, number>>({ queryKey: key })

        entities?.forEach(([key, listDataEntity]) => {
          if (listDataEntity) {
            const updatedData = {
              ...listDataEntity,
              pages: listDataEntity.pages.map(page => ({
                ...page,
                workflows: [],
                total: 0,
              })),
            }
            queryClient.setQueryData(key, updatedData)
          }
        })
      },
    }),
  ],
})

export const useTrashDeleteAllWildcardMutation = createMutation({
  mutationFn: () => client.api.wildcardControllerFullyDeleteAllWildcards(),
  use: [
    actionMiddleware({
      onSuccess: ({ queryClient, variables }) => {
        const key = useWorkspaceWildcardsInfiniteQuery.getKey()
        const entities = queryClient.getQueriesData<InfiniteData<SearchWildcardResponse, number>>({ queryKey: key })

        entities?.forEach(([key, listDataEntity]) => {
          if (listDataEntity) {
            const updatedData = {
              ...listDataEntity,
              pages: listDataEntity.pages.map(page => ({
                ...page,
                wildcards: [],
                total: 0,
              })),
            }
            queryClient.setQueryData(key, updatedData)
          }
        })
      },
    }),
  ],
})

export const useTrashDeleteAllModelMutation = createMutation({
  mutationFn: () => client.api.sdModelControllerFullyDeleteAllModels(),
  use: [
    actionMiddleware({
      onSuccess: ({ queryClient }) => {
        const key = useWorkspaceModelsInfiniteQuery.getKey()
        const entities = queryClient.getQueriesData<InfiniteData<SearchModelResults, number>>({ queryKey: key })

        entities?.forEach(([key, listDataEntity]) => {
          if (listDataEntity) {
            const updatedData = {
              ...listDataEntity,
              pages: listDataEntity.pages.map(page => ({
                ...page,
                data: [],
                total: 0,
              })),
            }
            queryClient.setQueryData(key, updatedData)
          }
        })
      },
    }),
  ],
})

export const useTrashDeleteAllModelTrainingMutation = createMutation({
  mutationFn: () => client.api.sdModelTrainingControllerFullyDeleteAllModels(),
  use: [
    actionMiddleware({
      onSuccess: ({ queryClient }) => {
        const key = useWorkspaceModelTrainingInfiniteQuery.getKey()
        const entities = queryClient.getQueriesData<InfiniteData<SearchModelTrainingResults, number>>({ queryKey: key })

        entities?.forEach(([key, listDataEntity]) => {
          if (listDataEntity) {
            const updatedData = {
              ...listDataEntity,
              pages: listDataEntity.pages.map(page => ({
                ...page,
                data: [],
                total: 0,
              })),
            }
            queryClient.setQueryData(key, updatedData)
          }
        })
      },
    }),
  ],
})
