import { useEffect, useRef, useState } from "react"

const AttachDragTo = ({
  imageUrl,
  onChange,
  value,
}: {
  imageUrl: string
  onChange: (value: string) => void
  value?: string
}) => {
  const isDraggingRef = useRef(false)

  const [bgPosition, setBgPosition] = useState(0)
  const [imageLoaded, setImageLoaded] = useState(false)
  const containerRef = useRef<HTMLDivElement | null>(null)
  const imageContainerRef = useRef<HTMLDivElement | null>(null)

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
    if (!isDraggingRef.current) return

    const containerRect = containerRef.current!.getBoundingClientRect()
    const imageContainerRect = imageContainerRef.current!.getBoundingClientRect()

    const newTop = bgPosition + (e.movementY / containerRect.height) * 100

    // minTop = 100% - imageContainerRect.height / containerRect.height * 100%
    const minTop = 100 - (imageContainerRect.height / containerRect.height) * 100
    const maxTop = 0

    setBgPosition(Math.min(Math.max(newTop, minTop), maxTop))
  }

  const handleMouseDown = () => {
    isDraggingRef.current = true
  }

  const handleMouseUp = () => {
    isDraggingRef.current = false

    if (Number(value) === bgPosition) return
    onChange(`${bgPosition}`)
  }

  // set imageLoaded to false when imageUrl changes
  useEffect(() => {
    setImageLoaded(false)
  }, [imageUrl])

  // set bgPosition to value when value changes
  useEffect(() => {
    setBgPosition(Number(value))
  }, [value])

  // when imageUrl is available, set the bgPosition to center
  useEffect(() => {
    if (imageUrl && imageLoaded) {
      if (value) return

      const containerRect = containerRef.current!.getBoundingClientRect()
      const imageContainerRect = imageContainerRef.current!.getBoundingClientRect()
      const minTop = 100 - (imageContainerRect.height / containerRect.height) * 100
      const maxTop = 0
      const newTop = (minTop + maxTop) / 2
      setBgPosition(newTop)
      onChange(`${newTop}`)
    }
  }, [imageUrl, imageLoaded, value])

  return (
    <div className="w-full h-full relative" ref={containerRef}>
      <div
        className="absolute w-full top-0 cursor-grab"
        style={{ top: `${bgPosition}%` }}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
        ref={imageContainerRef}
      >
        <img
          src={imageUrl}
          alt="Cover Image"
          className="object-cover w-full"
          draggable={false}
          onLoad={() => setImageLoaded(true)}
        />
      </div>
    </div>
  )
}

export default AttachDragTo
