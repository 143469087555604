import { IconProps } from "."

export const EraserClearIcon = (props: IconProps) => {
  return (
    <svg width="33" height="26" viewBox="0 0 33 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8.73792 9.78021L4.84693 13.7574C3.67722 14.953 3.09236 15.5508 3.01263 16.2802C2.99579 16.4343 2.99579 16.5899 3.01263 16.744C3.09236 17.4733 3.67722 18.0711 4.84693 19.2668L4.99601 19.4191C5.62049 20.0575 5.93274 20.3766 6.30638 20.5911C6.5236 20.7157 6.75482 20.8134 6.99505 20.882C7.40827 21 7.85149 21 8.73792 21C9.62436 21 10.0676 21 10.4808 20.882C10.721 20.8134 10.9522 20.7157 11.1695 20.5911C11.5431 20.3766 11.8554 20.0575 12.4798 19.4191L15.3239 16.5121M8.73792 9.78021L12.3199 6.12313C13.7065 4.70754 14.3997 3.99974 15.2627 4C16.1256 4.00026 16.8185 4.70846 18.2042 6.12487L18.9473 6.8845C20.3159 8.28342 21.0002 8.98288 21 9.85008C20.9997 10.7173 20.315 11.4163 18.9456 12.8144L15.3239 16.5121M8.73792 9.78021L15.3239 16.5121"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M10 24L21 24" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M27 1L27.3848 2.70827C27.7112 4.15725 28.8427 5.28879 30.2917 5.61519L32 6L30.2917 6.38481C28.8427 6.71121 27.7112 7.84275 27.3848 9.29173L27 11L26.6152 9.29173C26.2888 7.84275 25.1573 6.71121 23.7083 6.38481L22 6L23.7083 5.61519C25.1572 5.28879 26.2888 4.15725 26.6152 2.70827L27 1Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  )
}
