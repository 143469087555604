import {
  ContentModerationRating,
  ExploreWorkflowItem,
  RecipeTaskStatus,
  WorkflowItem as WorkflowItemType,
} from "@/api/sdk"
import AspectRatio from "@/components/AspectRatio"
import IconButton from "@/components/IconButton"
import ImageWebpComponent from "@/components/ImageWebpComponent"
import LoadingIcon from "@/components/LoadingIcon"
import Skeleton from "@/components/Skeleton"
import { FlowIcon, TickIcon } from "@/components/shared/icons"
import useContainerWidth from "@/hooks/useContainerWidth"
import { googleAnalytics } from "@/lib/gtag"
import { cn } from "@/utils/cn"
import { QueryKey } from "@tanstack/react-query"
import classNames from "classnames"
import { AnimatePresence, motion } from "framer-motion"
import { Fragment, MouseEvent, useEffect, useRef, useState } from "react"
import { useInView } from "react-intersection-observer"
import { colorStatusRecipeTask } from "../../Backlog/RowScheduler"
import ActionButtonsWorkflow from "./ActionButtonsWorkflow"
import WorkflowReaction from "./WorkflowReaction"
import NsfwBlurImage from "@/components/NsfwBlurImage"

interface GalleryImageProps {
  onClick?: () => void
  workflow:
    | WorkflowItemType
    | (ExploreWorkflowItem & {
        discoverableAt?: string
        status?: RecipeTaskStatus
        pinned?: boolean
        deletedAt?: string | null
      })
  isDisabledInView?: boolean
  containerWidth?: number
  stretch?: boolean
  showHoverAttributes?: boolean
  href?: string
  hiddenReaction?: boolean
  queryKey?: QueryKey
  hiddenMenu?: boolean
  selectionMode?: boolean
  isChecked?: boolean
  isExplore?: boolean
  onCheck?: () => void
}

const ImageInView = ({
  onClick,
  workflow,
  hiddenMenu,
  isDisabledInView,
  containerWidth,
  hiddenReaction,
  queryKey,
  stretch,
  isExplore,
  href,
  selectionMode,
  onCheck,
  isChecked,
}: GalleryImageProps) => {
  const [imageLoaded, setImageLoaded] = useState(false)
  const { image } = workflow
  const imageItemRef = useRef<HTMLDivElement | null>(null)
  const { ref, inView } = useInView({
    threshold: 0,
    rootMargin: "800px 0px 800px 0px",
  })
  const [imageSize, setImageSize] = useState(0)
  const widthCard = useContainerWidth(imageItemRef, !!workflow)
  const [isShow, setIsShow] = useState(
    image?.moderationRating === ContentModerationRating.HardcoreNsfw ||
      image?.moderationRating === ContentModerationRating.SemiNsfw,
  )

  useEffect(() => {
    if (isDisabledInView) return
    if (containerWidth && image?.width && image.height && widthCard) {
      const height = (image.height / image.width) * (widthCard || 250)

      setImageSize(height)
    }
  }, [containerWidth, image, widthCard, isDisabledInView])

  const handleClick = (e: MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>) => {
    googleAnalytics.event({
      action: "click",
      category: "macro_item",
      label: `view_macro_detail`,
      params: {
        macro_id: workflow.id,
        macro_name: workflow.name,
        pathname: window.location.pathname,
      },
    })

    if (!onClick) return

    e.preventDefault()
    e.stopPropagation()

    if (selectionMode) {
      onCheck?.()
      return
    }

    onClick()
  }

  return (
    <div
      ref={r => {
        imageItemRef.current = r
        ref(r)
      }}
      className={classNames(
        "image-item relative overflow-hidden rounded-2xl transform-gpu bg-atherGray-900 shadow-sm shadow-whiteAlpha-200",
      )}
      style={{
        minHeight: "10rem",
        height: stretch
          ? "100%"
          : isDisabledInView ||
              (workflow.status !== RecipeTaskStatus.COMPLETED && workflow.status !== RecipeTaskStatus.DELETED)
            ? "auto"
            : imageSize,
      }}
      onClick={e => {
        e.preventDefault()
        googleAnalytics.event({
          action: "click",
          category: "macro_item",
          label: "image_view",
          params: {
            macro_id: workflow.id,
            macro_name: workflow.name,
          },
        })

        if (selectionMode) {
          onCheck?.()
          return
        }
        onClick?.()
      }}
    >
      {selectionMode && (
        <div
          className={classNames("absolute w-full h-full top-0 left-0 border-[2px] pointer-events-none rounded-2xl", {
            "border-atherPurple-500": isChecked,
            "border-[transparent]": !isChecked,
            "z-0": !selectionMode,
            "z-[1]": selectionMode,
          })}
        />
      )}
      <AnimatePresence mode="wait">
        {(inView || isDisabledInView) && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.25 }}
            className="flex items-center justify-center w-full flex-col overflow-hidden"
            style={{
              minHeight: "10rem",
              height: stretch
                ? "100%"
                : isDisabledInView ||
                    (workflow.status !== RecipeTaskStatus.COMPLETED && workflow.status !== RecipeTaskStatus.DELETED)
                  ? "auto"
                  : imageSize,
            }}
          >
            {workflow.status !== RecipeTaskStatus.COMPLETED && workflow.status !== RecipeTaskStatus.DELETED ? (
              stretch ? (
                <AspectRatio ratio={1 / 1} className="w-full h-full">
                  <div className="flex h-full w-full items-center justify-center">
                    <FlowIcon className="text-atherGray-800" />
                  </div>
                </AspectRatio>
              ) : (
                <div className="flex h-full w-full items-center justify-center">
                  <FlowIcon className="text-atherGray-800" />
                </div>
              )
            ) : workflow.image ? (
              <Skeleton
                className="group rounded-2xl h-full w-full flex items-center justify-center"
                isLoaded={imageLoaded}
                // blurHash={blurHash ?? undefined}
              >
                <div className={classNames("h-full select-none block")}>
                  <ImageWebpComponent
                    src={image?.url}
                    alt={workflow.name ?? ""}
                    className={classNames("block object-contain w-full h-full", {
                      "blur-2xl": !imageLoaded || isShow,
                    })}
                    onLoad={() => setImageLoaded(true)}
                  />
                </div>
                <div
                  className={classNames(
                    "absolute bottom-0 left-0 transition-all pointer-events-none hidden lg:block group-hover:bg-blackAlpha-400 group-hover:h-full group-hover:w-full",
                    {
                      "h-full w-full bg-blackAlpha-600": selectionMode,
                    },
                  )}
                />
              </Skeleton>
            ) : (
              <AspectRatio ratio={1 / 1} className="w-full h-full">
                <div className="flex h-full w-full items-center justify-center">
                  <FlowIcon className="text-atherGray-800" />
                </div>
              </AspectRatio>
            )}

            {/* top and bottom linear */}

            {!hiddenMenu && (
              <div
                className="h-14 absolute top-0 left-0 w-full pointer-events-none"
                style={{
                  background: "linear-gradient(180deg, rgba(0, 0, 0, 0.70) 0%, rgba(0, 0, 0, 0.00) 100%)",
                }}
              />
            )}

            {/* action */}
            {!hiddenReaction && !workflow.deletedAt && (
              <Fragment>
                <div
                  className="h-[3.25rem] absolute bottom-0 left-0 w-full pointer-events-none"
                  style={{
                    background: "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.70) 100%)",
                  }}
                />
                <WorkflowReaction
                  className="absolute bottom-2 left-2 hover:bg-transparent active:bg-transparent"
                  workflow={workflow}
                />
              </Fragment>
            )}

            {!hiddenMenu &&
              workflow.status &&
              workflow.status !== RecipeTaskStatus.QUEUED &&
              workflow.status !== RecipeTaskStatus.RUNNING &&
              (selectionMode ? (
                <IconButton
                  onClick={e => {
                    e.stopPropagation()
                    e.preventDefault()
                    onCheck?.()
                  }}
                  className={cn(
                    "absolute top-2 right-2 shadow-sm shadow-blackAlpha-500 bg-atherGray-700 p-0 w-7 h-7 min-h-0 rounded-full",
                    {
                      "text-white bg-atherPurple-500 hover:bg-atherPurple-400": isChecked,
                      "text-atherGray-300 hover:bg-atherGray-600": !isChecked,
                    },
                  )}
                >
                  <TickIcon />
                </IconButton>
              ) : (
                <ActionButtonsWorkflow workflow={workflow as WorkflowItemType} queryKey={queryKey} />
              ))}

            {(isExplore || isShow) &&
              inView &&
              (image?.moderationRating === ContentModerationRating.HardcoreNsfw ||
                image?.moderationRating === ContentModerationRating.SemiNsfw) && (
                <NsfwBlurImage
                  isShow={isShow}
                  moderationRating={image.moderationRating}
                  onToggleShow={setIsShow}
                  gaEvent={{
                    tab_name: "image",
                    params: {
                      macro_id: workflow.id,
                      macro_name: workflow.name ?? "",
                    },
                  }}
                />
              )}

            {!hiddenMenu && (workflow.status || workflow.discoverableAt) && (
              <div title={workflow.status} className="absolute flex items-center top-2 left-0">
                {workflow.discoverableAt && (
                  <span className="font-semibold bg-atherPurple-500 px-1.5 py-0.5 rounded-r-lg text-[0.65rem]">E</span>
                )}
                {workflow.status && (
                  <div
                    className="flex items-center line-clamp-1 py-0.5 px-1 ml-1 rounded-lg text-xs"
                    style={{
                      backgroundColor:
                        workflow.status === RecipeTaskStatus.RUNNING
                          ? "#323230"
                          : colorStatusRecipeTask[workflow.status?.toUpperCase()],
                    }}
                  >
                    {workflow.status === RecipeTaskStatus.RUNNING && (
                      <LoadingIcon className="mr-1" width={20} height={20} />
                    )}
                    <span className=" capitalize">
                      {workflow.status === RecipeTaskStatus.RUNNING ? "Generating" : workflow.status.toLowerCase()}
                    </span>
                  </div>
                )}
                {!isShow &&
                  inView &&
                  (image?.moderationRating === ContentModerationRating.HardcoreNsfw ||
                    image?.moderationRating === ContentModerationRating.SemiNsfw) && (
                    <NsfwBlurImage
                      buttonCloseClassName="relative top-auto left-auto ml-1 px-1 py-0.5 min-h-5"
                      isShow={isShow}
                      size={14}
                      moderationRating={image.moderationRating}
                      onToggleShow={setIsShow}
                      gaEvent={{
                        tab_name: "image",
                        params: {
                          macro_id: workflow.id,
                          macro_name: workflow.name ?? "",
                        },
                      }}
                    />
                  )}
              </div>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default ImageInView
