import client from "@/api/client"
import { WorkspaceDetail } from "@/api/sdk"
import { createAuthenticatedQuery } from "../createAuthenticatedQuery"
import { enableQueryMiddleware } from "../middlewares/enableQueryMiddleware"

export const useUserWorkspaceDetailQuery = createAuthenticatedQuery<WorkspaceDetail, { workspaceId: string }, Error>({
  primaryKey: "user-workspace",
  queryFn: ({ queryKey: [_, variables] }) => {
    return client.api.workspaceControllerGetWorkspace(variables.workspaceId).then(res => res.data)
  },

  use: [enableQueryMiddleware(i => !!i.workspaceId)],
})
