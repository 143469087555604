import LoadingIcon from "@/components/LoadingIcon"
import Toggle from "@/components/Toggle"
import { googleAnalytics } from "@/lib/gtag"
import { cn } from "@/utils/cn"
import { FC, HTMLAttributes, useState } from "react"
import { TURBO_ENABLED, useTurboMode } from "./useTurboMode"

export type TurboModeSwitchProps = HTMLAttributes<HTMLDivElement> & {}

const TurboModeSwitch: FC<TurboModeSwitchProps> = props => {
  const { enabled, setEnabled, session, isReady, isWaiting, createSession, terminateSession } = useTurboMode()

  const [isLoading, setLoading] = useState(false)

  const handleToggle = async () => {
    const next = !(enabled && !!session)
    setEnabled(next)
    setLoading(true)

    return Promise.resolve(next ? createSession() : terminateSession()).finally(() => {
      setLoading(false)
    })
  }

  if (!TURBO_ENABLED) return null

  return (
    <div
      {...props}
      className={cn(
        "flex items-center rounded-lg border-[2px] p-1",
        {
          "border-green-500": isReady,
          "border-yellow-500": isWaiting,
          "border-red-500": enabled && !session,
          "border-atherGray-850": !enabled,
        },
        props.className,
      )}
    >
      {isLoading || isWaiting ? (
        <LoadingIcon width={16} height={16} />
      ) : (
        <Toggle
          labelClassName="text-atherGray-300 text-xs"
          sizeToggle="sm"
          name={"Public"}
          checked={enabled && !!session}
          onChange={e => {
            googleAnalytics.event({
              action: "click",
              category: "recipes",
              label: `${enabled ? "disable" : "enable"} turbo mode`,
            })

            e.stopPropagation()
            handleToggle()
          }}
        />
      )}
      <p className="text-xs ml-2 flex items-center font-semibold">Faster</p>
    </div>
  )
}

export default TurboModeSwitch
