import IconButton from "@/components/IconButton"
import Modal from "@/components/Modal"
import { DeleteIcon } from "@/components/shared/icons"
import { useToast } from "@/hooks"
import { ModalShareType } from "@/lib/store"
import { cn } from "@/lib/utils"
import {
  useTrashDeleteAllComfyUIMutation,
  useTrashDeleteAllFolderMutation,
  useTrashDeleteAllImageMutation,
  useTrashDeleteAllMacroMutation,
  useTrashDeleteAllModelMutation,
  useTrashDeleteAllModelTrainingMutation,
  useTrashDeleteAllRecipeMutation,
  useTrashDeleteAllStyleMutation,
  useTrashDeleteAllWildcardMutation,
} from "@/queries"
import React, { Fragment, useCallback, useState } from "react"

interface TrashDeleteAllButtonProps {
  type: ModalShareType
  className?: string
  disabled?: boolean
}

const TrashDeleteAllButton = ({ type, className, disabled }: TrashDeleteAllButtonProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const toast = useToast()

  const handleToast = useCallback(
    (status: "success" | "error", message?: string) => {
      if (status === "error") {
        toast({
          title: "Error",
          status: "error",
          message: [message ?? `Failed to delete all ${type} in the trash.`],
        })

        return
      }

      toast({
        title: "Deleted All successfully",
        status: "success",
        message: [`All ${type} in the trash have been deleted.`],
      })
    },
    [type, toast],
  )

  const { mutate: mutateTrashDeleteImage, isPending: isMutatingDeleteImage } = useTrashDeleteAllImageMutation({
    onSuccess: () => {
      handleToast("success")
      setIsOpen(false)
    },
    onError: error => {
      handleToast("error", error.message)
    },
  })

  const { mutate: mutateTrashDeleteRecipe, isPending: isMutatingDeleteRecipe } = useTrashDeleteAllRecipeMutation({
    onSuccess: () => {
      handleToast("success")
      setIsOpen(false)
    },
    onError: error => {
      handleToast("error", error.message)
    },
  })

  const { mutate: mutateTrashDeleteComfyUI, isPending: isMutatingDeleteComfyUI } = useTrashDeleteAllComfyUIMutation({
    onSuccess: () => {
      handleToast("success")
      setIsOpen(false)
    },
    onError: error => {
      handleToast("error", error.message)
    },
  })

  const { mutate: mutateTrashDeleteMacro, isPending: isMutatingDeleteMacro } = useTrashDeleteAllMacroMutation({
    onSuccess: () => {
      handleToast("success")
      setIsOpen(false)
    },
    onError: error => {
      handleToast("error", error.message)
    },
  })

  const { mutate: mutateTrashDeleteStyle, isPending: isMutatingDeleteStyle } = useTrashDeleteAllStyleMutation({
    onSuccess: () => {
      handleToast("success")
      setIsOpen(false)
    },
    onError: error => {
      handleToast("error", error.message)
    },
  })

  const { mutate: mutateTrashDeleteWildcard, isPending: isMutatingDeleteWildcard } = useTrashDeleteAllWildcardMutation({
    onSuccess: () => {
      handleToast("success")
      setIsOpen(false)
    },
    onError: error => {
      handleToast("error", error.message)
    },
  })

  const { mutate: mutateTrashDeleteFolder, isPending: isMutatingDeleteFolder } = useTrashDeleteAllFolderMutation({
    onSuccess: () => {
      handleToast("success")
      setIsOpen(false)
    },
    onError: error => {
      handleToast("error", error.message)
    },
  })

  const { mutate: mutateTrashDeleteModel, isPending: isMutatingDeleteModel } = useTrashDeleteAllModelMutation({
    onSuccess: () => {
      handleToast("success")
      setIsOpen(false)
    },
    onError: error => {
      handleToast("error", error.message)
    },
  })

  const { mutate: mutateTrashDeleteModelTraining, isPending: isMutatingDeleteModelTraining } =
    useTrashDeleteAllModelTrainingMutation({
      onSuccess: () => {
        handleToast("success")
        setIsOpen(false)
      },
      onError: error => {
        handleToast("error", error.message)
      },
    })

  const isLoading =
    isMutatingDeleteImage ||
    isMutatingDeleteRecipe ||
    isMutatingDeleteComfyUI ||
    isMutatingDeleteMacro ||
    isMutatingDeleteStyle ||
    isMutatingDeleteWildcard ||
    isMutatingDeleteFolder ||
    isMutatingDeleteModel ||
    isMutatingDeleteModelTraining

  const handleSubmit = () => {
    switch (type) {
      case "image":
        mutateTrashDeleteImage({})
        break
      case "recipe":
        mutateTrashDeleteRecipe({})
        break
      case "comfyui-workflow":
        mutateTrashDeleteComfyUI({})
        break
      case "macro":
        mutateTrashDeleteMacro({})
        break
      case "style":
        mutateTrashDeleteStyle({})
        break
      case "wildcard":
        mutateTrashDeleteWildcard({})
        break
      case "folder":
        mutateTrashDeleteFolder({})
        break
      case "model":
        mutateTrashDeleteModel({})
        break
      case "model training":
        mutateTrashDeleteModelTraining({})
        break

      default:
        return
    }
  }

  return (
    <Fragment>
      <IconButton
        colorScheme="transparent"
        isLoading={isLoading}
        disabled={disabled}
        title="Delete All"
        onClick={() => setIsOpen(true)}
        className={cn(
          "rounded-2xl p-2 px-3 hover:bg-atherGray-850 flex items-center text-atherGray-300 transition-all",
          className,
        )}
      >
        <DeleteIcon width={20} height={20} className="text-red-500" />
        <span className="font-normal ml-1 text-sm hidden md:block">Delete All</span>
      </IconButton>
      <Modal title="Delete All" isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <div>
          <p className="text-sm text-atherGray-300">
            Are you sure you want to delete all{" "}
            <span className="font-semibold text-atherGray-100">{type === "comfyui-workflow" ? "ComfyUI" : type}</span>{" "}
            in the trash? This action cannot be undone.
          </p>
        </div>
        <div className="flex items-center justify-end mt-6 space-x-2">
          <IconButton onClick={() => setIsOpen(false)} colorScheme="secondary" className="px-4 font-semibold">
            Cancel
          </IconButton>
          <IconButton isLoading={isLoading} onClick={handleSubmit} className="px-4 font-semibold">
            Confirm
          </IconButton>
        </div>
      </Modal>
    </Fragment>
  )
}

export default TrashDeleteAllButton
