import { IconProps } from "."

export const FlowSquareIcon9 = (props: IconProps) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M2.5 15.834C2.5 17.0125 2.5 17.6018 2.86612 17.9679C3.23223 18.334 3.82149 18.334 5 18.334C6.17851 18.334 6.76777 18.334 7.13388 17.9679C7.5 17.6018 7.5 17.0125 7.5 15.834C7.5 14.6555 7.5 14.0662 7.13388 13.7001C6.76777 13.334 6.17851 13.334 5 13.334C3.82149 13.334 3.23223 13.334 2.86612 13.7001C2.5 14.0662 2.5 14.6555 2.5 15.834Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M2.5 4.16797C2.5 5.34648 2.5 5.93574 2.86612 6.30185C3.23223 6.66797 3.82149 6.66797 5 6.66797C6.17851 6.66797 6.76777 6.66797 7.13388 6.30185C7.5 5.93574 7.5 5.34648 7.5 4.16797C7.5 2.98946 7.5 2.4002 7.13388 2.03409C6.76777 1.66797 6.17851 1.66797 5 1.66797C3.82149 1.66797 3.23223 1.66797 2.86612 2.03409C2.5 2.4002 2.5 2.98946 2.5 4.16797Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M12.5 4.16797C12.5 5.34648 12.5 5.93574 12.8661 6.30185C13.2322 6.66797 13.8215 6.66797 15 6.66797C16.1785 6.66797 16.7678 6.66797 17.1339 6.30185C17.5 5.93574 17.5 5.34648 17.5 4.16797C17.5 2.98946 17.5 2.4002 17.1339 2.03409C16.7678 1.66797 16.1785 1.66797 15 1.66797C13.8215 1.66797 13.2322 1.66797 12.8661 2.03409C12.5 2.4002 12.5 2.98946 12.5 4.16797Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M5 6.66732V13.334"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 10.0013H11.6667C13.238 10.0013 14.0237 10.0013 14.5118 9.51315C15 9.02499 15 8.23932 15 6.66797"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
