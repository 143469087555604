import client from "@/api/client"
import { RecentSDWorkflowItem } from "@/api/sdk"
import LoadingLogo from "@/components/LoadingLogo"
import useContainerWidth from "@/hooks/useContainerWidth"
import { createAuthenticatedInfiniteQuery } from "@/queries/createAuthenticatedQuery"
import { useGridStore } from "@/stores/useGirdStore"
import classNames from "classnames"
import differenceInDays from "date-fns/differenceInDays"
import endOfDay from "date-fns/endOfDay"
import startOfDay from "date-fns/startOfDay"
import { motion } from "framer-motion"
import useCustomRouter from "@/hooks/useCustomRouter"
import { memo, useEffect, useMemo, useRef, useState } from "react"
import InfiniteScroll from "react-infinite-scroller"
import RecentFilter from "../RecentFilter"
import { formatFilterDate } from "../RecentV2"
import RecentComfyUIItem from "./RecentComfyUIItem"
import ErrorUI from "@/components/ErrorUI"

interface UseRecentInfiniteQueryVariables {
  fromDate?: Date
  toDate?: Date
  take?: number
}

export const useRecentComfyUIInfiniteQuery = createAuthenticatedInfiniteQuery<
  RecentSDWorkflowItem[],
  UseRecentInfiniteQueryVariables,
  Error,
  RecentSDWorkflowItem
>({
  primaryKey: "recent-comfyui-infinite",
  queryFn: ({ pageParam = 0, queryKey: [_primaryKey, variables] }) =>
    client.api
      .recentAccessControllerGetRecentSdWorkflows({
        fromDate: variables.fromDate ? startOfDay(variables?.fromDate).toISOString() : undefined,
        toDate: variables?.toDate ? endOfDay(variables?.toDate).toISOString() : undefined,
        skip: pageParam * (variables.take ?? 20),
        take: variables.take ?? 20,
      })
      .then(res => res.data),
  getNextPageParam: (lastPage, pages) => (lastPage && lastPage.length < 20 ? undefined : pages.length),
  initialPageParam: 0,
  flattenFn: res => res,
  refetchOnWindowFocus: false,
  retry: false,
})

const RecentComfyUI = () => {
  const [filter, setFilter] = useState<{ toDate?: Date; type: string; fromDate?: Date } | undefined>()
  const [layout, setLayout] = useGridStore(state => [state.layout, state.setLayout])

  const {
    flattenData = [],
    hasNextPage,
    fetchNextPage,
    isLoading,
    isError,
    isFetching,
  } = useRecentComfyUIInfiniteQuery({
    variables: {
      fromDate: filter?.fromDate,
      toDate: filter?.toDate,
    },
  })

  const formatMappedRecentImages = useMemo(() => {
    const recentImagesToday = flattenData.filter(image => differenceInDays(new Date(), new Date(image.accessedAt)) <= 1)

    const recentImagesOneWeek = flattenData.filter(
      image =>
        differenceInDays(new Date(), new Date(image.accessedAt)) <= 7 &&
        differenceInDays(new Date(), new Date(image.accessedAt)) > 1,
    )

    const recentImagesOneMonth = flattenData.filter(
      image =>
        differenceInDays(new Date(), new Date(image.accessedAt)) > 7 &&
        differenceInDays(new Date(), new Date(image.accessedAt)) <= 30,
    )

    const recentImagesOlder = flattenData.filter(image => differenceInDays(new Date(), new Date(image.accessedAt)) > 30)

    return {
      recentImagesToday,
      recentImagesOneWeek,
      recentImagesOneMonth,
      recentImagesOlder,
    }
  }, [flattenData])

  const containerRef = useRef<HTMLDivElement>(null)
  const containerWidth = useContainerWidth(containerRef, !isLoading)
  const router = useCustomRouter()

  useEffect(() => {
    if (router.isReady) {
      const filter = router.query.filter

      if (filter) {
        const filterDateString = filter as string

        const filterDate = formatFilterDate(filterDateString)

        setFilter({
          ...filterDate,
          type: filterDateString,
        })
      } else {
      }
    }
  }, [router.isReady, router.query.filter])

  const renderBody = () => {
    if (isLoading)
      return (
        <div className="flex items-center justify-center w-full flex-1 text-gray-600">
          <LoadingLogo />
        </div>
      )

    if (isError) return <ErrorUI />

    return (
      <InfiniteScroll
        loadMore={() => fetchNextPage()}
        hasMore={!!hasNextPage && !isFetching}
        useWindow={true}
        threshold={600}
        style={{
          display: "flex",
          flexDirection: "column",
          maxWidth: "100%",
          position: "relative",
        }}
        loader={
          <div key="loader" className="flex items-center justify-center py-4">
            Loading...
          </div>
        }
      >
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.35, delay: 0.45 }}
          className={classNames("relative flex flex-1 flex-col")}
        >
          {layout === "list" && (
            <div className="hidden mb-2 w-full items-center md:flex text-xs uppercase font-semibold text-atherGray-500">
              <p className="w-[30%] p-2 text-left">Name</p>
              <p className="w-[15%] p-2 text-left">Category</p>
              <p className="w-[15%] p-2 text-left">Activity</p>
              <p className="w-[15%] p-2 text-left">Creator</p>
              <p className="w-[20%] p-2 text-left">Location</p>
              <p className="w-[5%] p-2 text-left"></p>
            </div>
          )}
          <RecentComfyUIItem
            layout={layout}
            containerWidth={containerWidth}
            name="Today"
            recent={formatMappedRecentImages.recentImagesToday}
          />
          <RecentComfyUIItem
            layout={layout}
            containerWidth={containerWidth}
            name="Last 7 days"
            recent={formatMappedRecentImages.recentImagesOneWeek}
          />
          <RecentComfyUIItem
            layout={layout}
            containerWidth={containerWidth}
            name="Last 30 days"
            recent={formatMappedRecentImages.recentImagesOneMonth}
          />
          <RecentComfyUIItem
            layout={layout}
            containerWidth={containerWidth}
            name="Older"
            recent={formatMappedRecentImages.recentImagesOlder}
          />
        </motion.div>
      </InfiniteScroll>
    )
  }

  return (
    <div className="flex-1 flex flex-col">
      <RecentFilter filter={filter} layout={layout} setFilter={setFilter} setLayout={setLayout} />
      <div ref={containerRef} className="relative flex flex-col w-full flex-1">
        {renderBody()}
      </div>
    </div>
  )
}

export default memo(RecentComfyUI)
