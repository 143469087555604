import { EntityType, FeedbackRating, PinnedItemType, RecipeItem, RecipeTaskStatus, WorkflowDetail } from "@/api/sdk"
import BackButton from "@/components/BackButton"
import CommentsWorkflow from "@/components/Explore/WorkflowDetail/TabOther/CommentsWorkflow"
import IconButton from "@/components/IconButton"
import Popover from "@/components/Popover"
import Tooltip from "@/components/Tooltip"
import {
  ArrowDownIcon,
  CommentIcon,
  DeleteIcon,
  EditIcon,
  PinIcon,
  RefreshIcon,
  ShareIcon8,
  ThumbUpFillIcon,
  ThumbUpIcon,
  UnPinIcon,
  XIcon,
} from "@/components/shared/icons"
import { useScreen, useToast } from "@/hooks"
import { useAuth } from "@/providers/authContext"
import { googleAnalytics } from "@/lib/gtag"
import useModalStore from "@/lib/store"
import { useBrowserRouter } from "@/providers/BrowserRouterProvider"
import {
  useCancelTaskMutation,
  useCommentDetailQuery,
  usePinMutation,
  useReadCommentMutation,
  useUpdateTaskMutation,
} from "@/queries"
import { useDeleteItemStore, useFeedbackTaskStore, useRecoverItemStore, useSignInStore } from "@/stores"
import { getHasClientHistory } from "@/stores/ClientHistoryStore"
import { cn } from "@/utils/cn"
import classNames from "classnames"
import { AnimatePresence, motion } from "framer-motion"
import React, { Fragment, useEffect, useRef, useState } from "react"
import { RecipeTaskChainParams } from "."
import FolderActionRow from "../../FolderActions/FolderActionRow"
import SignInRequiredButton from "@/components/Explore/SignInRequiredButton"
import FeedbackPopup from "../../Feedback/FeedbackPopup"
import useCustomRouter from "@/hooks/useCustomRouter"
import { runAgainWorkflowParams } from "@/utils/task"
import WorkspaceContainer from "../../WorkspaceContainer"

interface WorkflowContainerProps {
  children?: React.ReactNode
  isLoadingGenerate: boolean
  isCompletedTask: boolean
  chains?: RecipeTaskChainParams[]
  recipes: RecipeItem[]
  inputName: string
  onInputNameChange: (name: string) => void
  onCreateTask: ({
    folderId,
    recipeId,
    params,
  }: {
    folderId: string
    recipeId: string
    params: Record<string, any>
  }) => void
  sendRecipe: string
  workflowState?: WorkflowDetail
}

const WorkflowContainer = ({
  children,
  onCreateTask,
  workflowState,
  recipes,
  sendRecipe,
  isCompletedTask,
  isLoadingGenerate,
  chains,
  inputName,
  onInputNameChange,
}: WorkflowContainerProps) => {
  const [isEdit, setIsEdit] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const router = useCustomRouter()
  const toast = useToast()
  const setSharingData = useModalStore(state => state.setSharingData)
  const { user } = useAuth()
  const setDeletingItem = useDeleteItemStore(state => state.setDeletingItem)
  const setSignInModal = useSignInStore(state => state.setSignInModal)
  const [isSave, setIsSave] = useState(false)
  const setRecoverItem = useRecoverItemStore(state => state.setRecoverItem)
  const { width } = useScreen()
  const isMobile = width < 768
  const [modal, setModal] = useState<"COMMENT" | "FEEDBACK" | null>(null)

  useEffect(() => {
    if (isLoadingGenerate) {
      setIsSave(true)
    }

    const timeout = setTimeout(() => {
      setIsSave(false)
    }, 1000)

    return () => clearTimeout(timeout)
  }, [isLoadingGenerate])

  const isDisabled = workflowState?.creator?.uid !== user?.uid

  const handleShare = () => {
    if (!workflowState) return

    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        action: "Share Macro",
        macro_id: workflowState.id,
        macro_name: workflowState.name,
        macro_status: workflowState.status,
      },
    })

    setSharingData({
      id: workflowState.id,
      name: workflowState.name ?? workflowState.id,
      type: "macro",
      workspaceId: workflowState.workspace?.id,
      creator: workflowState.creator,
      link: `${window.location.origin}/workspace/macros/${workflowState.id}`,
    })

    setIsOpen(false)
  }

  const handleRunAgain = async () => {
    if (!workflowState) return

    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        action: "Run again Macro",
        macro_id: workflowState.id,
        macro_name: workflowState.name,
        macro_status: workflowState.status,
      },
    })

    setIsOpen(false)

    for (let i = 0; i < workflowState.params.length; i++) {
      const param = workflowState.params[i]

      if (
        !recipes
          .filter(r => !r.deletedAt)
          .map(i => i.id)
          .includes(param.recipeId)
      ) {
        toast({
          status: "error",
          title: "Can not run workflow!",
          message: [`Recipe of step ${i + 1} is not found.`],
        })
        return
      }
    }

    const mappedParams = await runAgainWorkflowParams(workflowState)

    onCreateTask({
      folderId: workflowState.folder.id,
      recipeId: sendRecipe,
      params: mappedParams,
    })
  }

  const { mutate: mutateRename } = useUpdateTaskMutation({
    onError: (err, _, context) => {
      toast({
        status: "error",
        title: "Error",
        message: ["Something went wrong"],
      })
    },
  })
  const handleDelete = () => {
    if (!workflowState) return

    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        action: "Delete Macro",
        macro_id: workflowState.id,
        macro_name: workflowState.name,
        macro_status: workflowState.status,
      },
    })

    setIsOpen(false)

    setDeletingItem({
      id: workflowState.id,
      name: workflowState.name,
      deletedAt: workflowState.deletedAt,
      type: "macro",
      onClose: () => router.push("/workspace/macros"),
    })
  }

  const handleRecover = () => {
    setIsOpen(false)

    if (!workflowState) return

    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        action: "Recover Macro",
        macro_id: workflowState.id,
        macro_name: workflowState.name,
        macro_status: workflowState.status,
      },
    })

    setRecoverItem({
      ids: [workflowState?.id],
      deletedAt: workflowState?.deletedAt ?? "",
      type: "macro",
      onClose: () => window.location.reload(),
    })
  }

  const handleRename = () => {
    setIsEdit(prev => !prev)

    setIsOpen(false)

    if (!inputName) return onInputNameChange(workflowState?.name ?? "")

    if (!workflowState || workflowState.name === inputName) return

    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        action: "Rename Macro",
        macro_id: workflowState.id,
        macro_name: workflowState.name,
        macro_status: workflowState.status,
      },
    })

    const newName = inputName ? inputName : "New Macro"

    mutateRename({
      taskId: workflowState.id,
      data: {
        name: newName,
      },
    })
  }

  const handleSendRecipe = () => {
    if (!user) {
      setSignInModal({
        signIn: true,
      })
      return
    }
    if (!workflowState) return

    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        action: "Duplicate Macro",
        macro_id: workflowState.id,
        macro_name: workflowState.name,
        macro_status: workflowState.status,
      },
    })

    setTimeout(() => router.openNewTab(`/workspace/macros/new-macro?originId=${workflowState.id}`), 100)
  }

  const { mutate: mutateCancel, isPending: isCancelMutating } = useCancelTaskMutation({
    onSuccess: () => {
      toast({
        status: "success",
        title: "Macro canceled",
        message: ["The macro has been canceled successfully"],
      })
    },
  })

  const handleCancel = () => {
    if (!workflowState) return

    mutateCancel(workflowState?.id ?? "")
  }

  const { mutate: mutatePinTask, isPending: isPendingPinTask } = usePinMutation({
    onSuccess: () => {
      toast({
        status: "success",
        title: `Macro ${workflowState?.pinned ? "unpinned" : "pinned"}`,
      })
    },
  })

  const handlePin = () => {
    if (!workflowState) return

    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        action: `${workflowState.pinned ? "Unpin" : "Pin"} Macro`,
        macro_id: workflowState.id,
        macro_name: workflowState.name,
        macro_status: workflowState.status,
      },
    })

    mutatePinTask({ ids: [workflowState?.id], type: PinnedItemType.WORKFLOW, isPinned: !workflowState.pinned })
  }

  const actions = [
    {
      label: "Run again",
      icon: <RefreshIcon width={16} height={16} />,
      onClick: handleRunAgain,
      // visible: !isDisabled && workflowState?.status !== RecipeTaskStatus.DRAFT && !workflowState?.deletedAt,
      enabled: workflowState?.capabilities?.canUpdate,
      isLoading: isLoadingGenerate,
    },
    {
      label: "Rename",
      icon: <EditIcon width={16} height={16} />,
      onClick: handleRename,
      visible: !isDisabled && !workflowState?.deletedAt,
      enabled: workflowState?.capabilities?.canUpdate,
    },
    {
      label: "Share",
      icon: <ShareIcon8 width={16} height={16} />,
      onClick: handleShare,
      visible:
        workflowState?.capabilities?.canShare &&
        workflowState?.status !== RecipeTaskStatus.DRAFT &&
        !workflowState?.deletedAt,
      enabled: workflowState?.capabilities?.canShare,
    },
    {
      label: workflowState?.pinned ? "Unpin" : "Pin",
      icon: workflowState?.pinned ? <UnPinIcon width={16} height={16} /> : <PinIcon width={16} height={16} />,
      onClick: handlePin,
      visible: workflowState?.capabilities.canView && !workflowState?.deletedAt,
      enabled: workflowState?.capabilities?.canView,
    },
    {
      label: "Recover",
      icon: <RefreshIcon className="rotate-180" width={16} height={16} />,
      onClick: handleRecover,
      visible: !isDisabled && workflowState?.deletedAt,
      enabled: workflowState?.capabilities?.canRecover,
    },
    {
      label: "Delete",
      icon: <DeleteIcon className="text-red-500" width={16} height={16} />,
      onClick: handleDelete,
      visible: !isDisabled,
      enabled: workflowState?.capabilities?.canDelete,
    },
  ]

  const { back } = useBrowserRouter()

  const handleBack = () => {
    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        action: "Back",
      },
    })

    const hasHistory = getHasClientHistory()

    if (hasHistory) {
      back()
      return
    }

    if (router.query.shared) {
      router.push("/workspace/macros?tab=shared-with-me")
      return
    }

    if (router.query.draft || workflowState?.status === RecipeTaskStatus.DRAFT) {
      router.push("/workspace/macros?tab=drafts")
      return
    }

    router.push("/workspace/macros")
  }

  useEffect(() => {
    if (router.query.notificationId && router.isReady) {
      setModal("COMMENT")
    }
  }, [router.query.notificationId, router.isReady])

  const renderWorkflowName = () => {
    return (
      workflowState &&
      user &&
      (isEdit ? (
        <input
          autoFocus
          maxLength={100}
          className="bg-transparent w-full max-w-[40rem] text-center font-semibold"
          value={inputName}
          onChange={e => onInputNameChange(e.target.value)}
          onKeyDown={e => {
            if (e.key === "Enter") {
              handleRename()
            }
          }}
          onBlur={() => handleRename()}
        />
      ) : (
        <Popover
          className="w-full overflow-hidden"
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          trigger={
            <button
              type="button"
              className="overflow-hidden max-w-[40rem] flex items-center cursor-pointer workflow-header-actions"
            >
              <p className="font-semibold flex-1 truncate text-center w-full md:w-auto">{inputName}</p>
              <span className="ml-2 text-atherGray-500">
                <ArrowDownIcon />
              </span>
            </button>
          }
        >
          <div className={"text-sm w-[12rem] bg-atherGray-900 border border-atherGray-850 p-2 rounded-2xl space-y-1"}>
            {actions
              .filter(a => a.visible)
              .map(action => (
                <FolderActionRow
                  key={action.label}
                  isLoading={action.isLoading}
                  iconLeft={action.icon}
                  onClick={e => {
                    e.stopPropagation()
                    action.onClick()
                    setIsOpen(false)
                  }}
                  isDisabled={!action.enabled}
                >
                  {action.label}
                </FolderActionRow>
              ))}
          </div>
        </Popover>
      ))
    )
  }

  const hook = useCommentDetailQuery({
    primaryKey: "infinite-comment-workflows",
    getNextPageParam: (lastPage, allPages) => {
      return undefined
    },
    getPreviousPageParam: () => {
      return undefined
    },
  })

  const isMutated = useRef(false)

  const { data: comments, refetch: refetchComments } = hook({
    variables: {
      entityId: workflowState?.id ?? "",
      typeComment: EntityType.WORKFLOW,
      parentTakePage: 10,
      pageParam: 0,
    },
    enabled: !!workflowState,
  })

  const { mutate: mutateReadComment } = useReadCommentMutation({
    onSuccess: () => {
      refetchComments()
      isMutated.current = false
    },
  })

  const { feedbackTasks, setFeedbackTasks } = useFeedbackTaskStore()

  return (
    <Fragment>
      <div
        className={cn(
          "bg-atherGray-900 ease-in-out shadow-lg duration-300 transition-transform px-2 md:px-4",
          "",
          "sticky overflow-hidden z-[1] top-0 md:top-[4.5rem] left-0 w-full h-auto md:h-[3rem]",
        )}
      >
        <WorkspaceContainer className="flex-row items-center justify-between py-2 md:py-0  md:h-full">
          {workflowState?.status !== RecipeTaskStatus.DRAFT && (
            <div
              className="flex items-center space-x-2 mr-2"
              style={{
                flexBasis: "25%",
              }}
            >
              <BackButton
                className="rounded-lg text-sm py-1.5 px-3 min-h-0"
                label="Back"
                onClick={() => handleBack()}
              />
            </div>
          )}

          {!isMobile && (
            <div
              style={{
                flexBasis: "50%",
              }}
              className={classNames("hidden flex-1 md:flex overflow-hidden mx-4", {
                "justify-center": workflowState?.status !== RecipeTaskStatus.DRAFT,
              })}
            >
              {renderWorkflowName()}
            </div>
          )}
          <div
            style={{
              flexBasis: "25%",
            }}
            className="flex space-x-2 items-center justify-center md:justify-end"
          >
            {workflowState ? (
              workflowState.status === RecipeTaskStatus.RUNNING || workflowState.status === RecipeTaskStatus.QUEUED ? (
                <p className="text-xs font-semibold">Generating ...</p>
              ) : (
                workflowState.capabilities.canUpdate &&
                workflowState.status !== RecipeTaskStatus.DRAFT &&
                !workflowState.deletedAt && (
                  <>
                    {workflowState?.status === RecipeTaskStatus.COMPLETED && user && (
                      <IconButton
                        onClick={() => {
                          if (modal === "COMMENT") {
                            setModal(null)
                          } else {
                            setModal("COMMENT")
                          }

                          if (isMutated.current) return

                          isMutated.current = true

                          mutateReadComment({
                            entityId: workflowState?.id ?? "",
                            entityType: EntityType.WORKFLOW,
                          })
                        }}
                        className={classNames("p-1 min-h-0 relative", {
                          "bg-atherPurple-500 hover:bg-atherPurple-500": modal === "COMMENT",
                        })}
                        colorScheme="transparent"
                      >
                        <CommentIcon className="text-atherGray-300" width={20} height={20} />
                        {(!modal || modal !== "COMMENT") &&
                          comments?.pages?.[0]?.comments.some(comment => !comment.isRead) && (
                            <div className="absolute top-0 right-0 translate-y-1 w-2.5 h-2.5 rounded-full bg-atherPurple-500"></div>
                          )}
                      </IconButton>
                    )}
                    {workflowState.status === RecipeTaskStatus.COMPLETED &&
                      workflowState.capabilities.canUpdate &&
                      !isDisabled && (
                        <Tooltip
                          trigger={
                            <IconButton
                              colorScheme="transparent"
                              className={classNames("p-1 min-h-0", {
                                "!cursor-default": !!workflowState.userFeedback,
                              })}
                              onClick={e => {
                                e.stopPropagation()

                                if (workflowState.userFeedback) return

                                if (modal === "FEEDBACK") {
                                  setModal(null)
                                } else {
                                  setModal("FEEDBACK")
                                }
                              }}
                            >
                              {workflowState.userFeedback ? (
                                <ThumbUpFillIcon
                                  width={20}
                                  height={20}
                                  className={classNames({
                                    "text-red-500 rotate-180":
                                      workflowState.userFeedback?.rating === FeedbackRating.NotGood,
                                    "text-atherPurple-500": workflowState.userFeedback?.rating === FeedbackRating.Good,
                                  })}
                                />
                              ) : (
                                <ThumbUpIcon width={20} height={20} className="text-atherGray-300" />
                              )}
                            </IconButton>
                          }
                        >
                          Rating
                        </Tooltip>
                      )}
                    {workflowState.status === RecipeTaskStatus.COMPLETED &&
                      workflowState.capabilities.canUpdate &&
                      !isDisabled && (
                        <Tooltip
                          trigger={
                            <IconButton
                              isLoading={isLoadingGenerate}
                              colorScheme="transparent"
                              className="p-1 min-h-0"
                              onClick={e => {
                                e.stopPropagation()
                                handleRunAgain()
                              }}
                            >
                              <RefreshIcon className="text-atherGray-300" width={20} height={20} />
                            </IconButton>
                          }
                        >
                          Run Again
                        </Tooltip>
                      )}

                    {user && (
                      <IconButton
                        className="font-semibold text-xs"
                        onClick={e => {
                          e.stopPropagation()
                          handleSendRecipe()
                        }}
                      >
                        {user ? (
                          <>
                            Duplicate <span className="hidden md:inline-block"> Macro</span>
                          </>
                        ) : (
                          "Try This Macro"
                        )}
                      </IconButton>
                    )}
                  </>
                )
              )
            ) : null}

            {workflowState ? (
              workflowState.status === RecipeTaskStatus.COMPLETED ? (
                workflowState.capabilities.canShare &&
                user && (
                  <IconButton
                    colorScheme="secondary"
                    className="font-semibold text-xs hidden md:flex p-1.5 min-h-0"
                    onClick={e => {
                      e.stopPropagation()
                      handleShare()
                    }}
                  >
                    <ShareIcon8 width={20} height={20} />
                  </IconButton>
                )
              ) : workflowState.status === RecipeTaskStatus.QUEUED ||
                workflowState.status === RecipeTaskStatus.RUNNING ? (
                workflowState.capabilities.canUpdate && (
                  <IconButton
                    colorScheme="secondary"
                    className="font-semibold text-xs"
                    isLoading={isCancelMutating}
                    onClick={e => {
                      e.stopPropagation()
                      handleCancel()
                    }}
                  >
                    Cancel
                  </IconButton>
                )
              ) : workflowState.status === RecipeTaskStatus.DRAFT ? (
                <Fragment>
                  <IconButton
                    isLoading={isLoadingGenerate && !isSave}
                    className="font-semibold text-xs workflow-generate"
                    type="submit"
                    disabled={
                      chains?.length === 0 || chains?.some(chain => chain.recipeId === "add-a-recipe") || isSave
                    }
                  >
                    {isSave ? "Saving..." : "Generate"}
                  </IconButton>
                </Fragment>
              ) : null
            ) : null}
            {!user && (
              <SignInRequiredButton
                className="w-[5rem] rounded-lg bg-atherPurple-500 p-2 h-full text-xs font-semibold text-[#EFE8FD]"
                text="Sign In"
              />
            )}
          </div>
        </WorkspaceContainer>

        {isMobile && <div className="flex justify-center md:hidden p-2 overflow-hidden">{renderWorkflowName()}</div>}
      </div>
      {children}
      <AnimatePresence>
        {modal === "COMMENT" && workflowState && user && (
          <motion.div
            className="fixed top-0 md:top-[3rem] height-full-screen items-end md:h-[calc((var(--vh,1vh)*100-3rem))] overflow-hidden flex flex-col right-0 w-full md:w-auto z-[24]"
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 100 }}
            transition={{
              type: "tween",
              duration: 0.2,
            }}
          >
            <div
              className="absolute top-0 w-full left-0 h-full bg-blackAlpha-800 backdrop-blur-sm"
              onClick={() => setModal(null)}
            />
            <div className="w-80 z-[1] flex-1 flex flex-col overflow-hidden bg-atherGray-900 border-l p-4 border-atherGray-800">
              <div className="flex items-center justify-between mb-2">
                <p className="text-sm font-semibold">Comments</p>
                <button type="button" onClick={() => setModal(null)}>
                  <XIcon className="text-atherGray-300" width={16} height={16} />
                </button>
              </div>
              <div className="flex-1 overflow-auto">
                <CommentsWorkflow className="p-0" workflow={workflowState} type={EntityType.WORKFLOW} />
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      {isCompletedTask &&
        workflowState &&
        workflowState.status === RecipeTaskStatus.COMPLETED &&
        user &&
        !feedbackTasks?.includes(EntityType.WORKFLOW) && (
          <FeedbackPopup
            debounceIsOpen={!workflowState.userFeedback}
            className="fixed top-[4rem] right-4 z-[9]"
            entityId={workflowState.id}
            entityType={EntityType.WORKFLOW}
            onSuccess={() => setFeedbackTasks([...(feedbackTasks ?? []), EntityType.WORKFLOW])}
          />
        )}

      {modal === "FEEDBACK" && workflowState && workflowState.status === RecipeTaskStatus.COMPLETED && user && (
        <FeedbackPopup
          debounceIsOpen={modal === "FEEDBACK" && !workflowState.userFeedback}
          isModal
          onClose={() => setModal(null)}
          className="fixed top-[4rem] right-4 z-[9]"
          entityId={workflowState.id}
          entityType={EntityType.WORKFLOW}
        />
      )}
    </Fragment>
  )
}

export default WorkflowContainer
