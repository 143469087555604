import { EntityType } from "@/api/sdk"
import LoadingLogo from "@/components/LoadingLogo"
import Modal from "@/components/Modal"
import NotFoundUI from "@/components/NotFound"
import RequestModal from "@/components/Workspace/ImageDetailWorkspace/RequestModal"
import { CloseIcon } from "@/components/shared/icons"
import { useDebounce } from "@/hooks"
import { useAuth } from "@/providers/authContext"
import { googleAnalytics } from "@/lib/gtag"
import { useBrowserRouter } from "@/providers/BrowserRouterProvider"
import { useExploreStyleDetailQuery, useTrackingViewMutation } from "@/queries"
import { useWorkspaceStyleDetailQuery } from "@/queries/tools/style/useGetStyleInfiniteQuery"
import { useHasClientHistory } from "@/stores/ClientHistoryStore"
import { QS } from "@/utils/qs"
import useCustomRouter from "@/hooks/useCustomRouter"
import { Fragment, useEffect, useRef, useState } from "react"
import ImageDetailView from "../ImageDetail/ImageDetailView"
import StyleDetailInfo from "./StyleDetailInfo"

interface StyleDetailProps {
  styleId: string
  filters: {
    historyPath?: string
    name: "styleDetailExplore" | "styleDetailWorkspace"
  }
}

export const StyleDetail = ({ styleId, filters }: StyleDetailProps) => {
  const [isErrorLoaded, setIsErrorLoaded] = useState(false)
  const router = useCustomRouter()
  const { user, loading } = useAuth()
  const browserRouter = useBrowserRouter()
  const hasHistory = useHasClientHistory()
  const ref = useRef<HTMLDivElement>(null)
  const { mutate: mutateTrackingView } = useTrackingViewMutation()

  const {
    data: exploreStyle,
    isLoading: isLoadingExploreStyle,
    isError: isErrorExploreStyle,
    isSuccess: isSuccessExploreStyle,
    refetch: refetchExploreStyle,
  } = useExploreStyleDetailQuery({
    variables: {
      styleId: styleId ?? "",
      userUid: user?.uid,
    },
    refetchOnWindowFocus: !isErrorLoaded,
    enabled: filters.name === "styleDetailExplore" && !loading,
  })

  const {
    data: workspaceStyle,
    isLoading: isLoadingWorkspaceStyle,
    isError: isErrorWorkspaceStyle,
    isSuccess: isSuccessWorkspaceStyle,
    refetch: refetchWorkspaceStyle,
    error,
  } = useWorkspaceStyleDetailQuery({
    variables: {
      styleId: styleId ?? "",
    },
    refetchOnWindowFocus: !isErrorLoaded,
    enabled: filters.name === "styleDetailWorkspace",
  })

  const statusErrorCode = Number((error as any)?.statusCode)

  const style = filters.name === "styleDetailExplore" ? exploreStyle : workspaceStyle
  const isLoadingStyle = filters.name === "styleDetailExplore" ? isLoadingExploreStyle : isLoadingWorkspaceStyle
  const isError = filters.name === "styleDetailExplore" ? isErrorExploreStyle : isErrorWorkspaceStyle
  const isSuccess = filters.name === "styleDetailExplore" ? isSuccessExploreStyle : isSuccessWorkspaceStyle
  const typeComment = filters.name === "styleDetailExplore" ? EntityType.EXPLORE_STYLE : EntityType.STYLE

  const isOpenRequest = useDebounce(
    !isLoadingWorkspaceStyle &&
      !!workspaceStyle &&
      !workspaceStyle.capabilities?.canView &&
      typeComment === EntityType.STYLE,
    500,
  )

  const refetch = filters.name === "styleDetailExplore" ? refetchExploreStyle : refetchWorkspaceStyle

  useEffect(() => {
    if (isSuccess) {
      mutateTrackingView({
        entityId: style?.id ?? styleId,
        entityType: filters.name === "styleDetailExplore" ? EntityType.EXPLORE_STYLE : EntityType.STYLE,
      })
      googleAnalytics.event({
        action: "view",
        category: "style_detail",
        label: filters.name === "styleDetailExplore" ? "view_explore_style_detail" : "view_workspace_style_detail",
        params: {
          style_id: styleId ?? "",
          style_name: style?.name ?? "",
          pathname: window.location.pathname,
        },
      })
    }
  }, [isSuccess])

  useEffect(() => {
    if (isError) {
      setIsErrorLoaded(true)
    }
  }, [isError])

  const onClose = () => {
    const modalChild = document.getElementsByClassName("modal-content-child")

    if (modalChild.length > 0) return
    if (hasHistory) browserRouter.back()
    else {
      const [, queryString] = browserRouter.asPath.split("?")
      const { active, ...query } = QS.parse(queryString) as any
      if (active) browserRouter.replace({ query: browserRouter.query }, { query })
      else {
        const returnUrl =
          filters?.historyPath ??
          (filters.name === "styleDetailWorkspace" ? "/workspace/tools/styles" : "/explore/styles")

        router.push(returnUrl, undefined, { shallow: true })
      }
    }
  }

  const handleTagFilter = (tag: number) => {
    if (filters?.name === "styleDetailWorkspace") return

    router.push(
      {
        pathname: "/explore/styles",
        query: {
          ...router.query,
          tags: tag,
        },
      },
      undefined,
      { shallow: true },
    )

    googleAnalytics.event({
      action: "click",
      category: "style_detail",
      label: "filter_style_tag",
      params: {
        style_id: styleId ?? "",
        style_name: style?.name ?? "",
        tag_id: tag,
        pathname: window.location.pathname,
      },
    })
  }

  const renderBody = () => {
    if (isLoadingStyle)
      return (
        <div className="flex items-center justify-center flex-1 w-full text-gray-600">
          <LoadingLogo />
        </div>
      )

    if (isError || !style) {
      if (statusErrorCode === 403)
        return (
          <RequestModal
            onRefetch={refetch}
            isModal
            dataRequest={{
              entityId: styleId,
              entityType: EntityType.STYLE,
            }}
            isOpenRequest={true}
            onCloseRequestModal={onClose}
          />
        )

      return (
        <NotFoundUI
          description="Style"
          redirectTo={filters.name === "styleDetailExplore" ? "/explore/styles" : "/workspace/tools/styles"}
        />
      )
    }

    return (
      <div className="flex flex-col flex-1">
        <div className="flex justify-center items-center w-full md:p-6 flex-1">
          <div
            style={{
              boxShadow: "0px 25px 50px -12px rgba(0, 0, 0, 0.25)",
            }}
            className="relative flex flex-col w-full bg-atherGray-900 lg:rounded-2xl lg:flex-row max-w-[75rem] 4xl:max-w-[90rem] max-h-full min-h-0 md:h-[calc(90vh+1rem)] lg:max-h-[61rem] md:min-h-[41rem] overflow-hidden"
          >
            <ImageDetailView
              isExplore={filters.name === "styleDetailExplore"}
              image={{
                name: style?.name ?? "",
                url: style?.thumbnailUrl ?? "",
                moderationRating: style.thumbnailModerationRating,
                blurHash: "",
              }}
            />
            <StyleDetailInfo typeComment={typeComment} style={style} onClose={onClose} onTagFilter={handleTagFilter} />
          </div>
        </div>
      </div>
    )
  }

  return (
    <Fragment>
      <Modal
        modalRef={ref}
        showCloseButton={false}
        isOpen={!!styleId}
        className="relative w-full max-w-full min-h-full p-0 my-0 overflow-hidden rounded-none bg-black"
        bodyClassName="overflow-hidden p-0"
        containerClassName={"overflow-hidden p-0"}
        onClose={onClose}
      >
        <div className="flex flex-col flex-1 w-full overflow-hidden">
          <div className="flex flex-col flex-1 w-full overflow-hidden">
            <div className={"flex flex-1 w-full overflow-x-hidden overflow-y-auto"}>{renderBody()}</div>
          </div>
          <button
            type="button"
            className={
              "text-whiteAlpha-900 z-100 fixed top-4 right-4 3xl:top-8 3xl:right-8 rounded-md p-1 bg-atherGray-700 hover:bg-atherGray-600 active:bg-atherGray-600 z-[1]"
            }
            onClick={onClose}
          >
            <CloseIcon height={16} width={16} />
          </button>
        </div>
      </Modal>
      {workspaceStyle && typeComment === EntityType.STYLE && (
        <RequestModal
          onRefetch={refetch}
          isModal
          dataRequest={{
            entityId: workspaceStyle.id,
            entityType: EntityType.STYLE,
            workspace: workspaceStyle.workspace,
            discoverableAt: workspaceStyle?.discoverableAt,
          }}
          isOpenRequest={isOpenRequest}
          onCloseRequestModal={onClose}
        />
      )}
    </Fragment>
  )
}
