import client from "@/api/client"
import { SDModelItem } from "@/api/sdk"
import { createAuthenticatedQuery } from "@/queries/createAuthenticatedQuery"
import { enableQueryMiddleware } from "@/queries/middlewares/enableQueryMiddleware"

export const useWorkspaceModelDetailQuery = createAuthenticatedQuery<SDModelItem, { modelId: string }, Error>({
  primaryKey: "model-detail",
  queryFn: ({ queryKey: [_primaryKey, variables] }) =>
    client.api.sdModelControllerGetModelById(variables.modelId).then(res => res.data),
  use: [enableQueryMiddleware(variables => !!variables.modelId)],
})
