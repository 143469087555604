import { create } from "zustand"

type ImageParamStoreType = {
  params: Record<string, any> | null
  setParams: (params: Record<string, any> | null) => void
}

export const useImageParamStore = create<ImageParamStoreType>(set => ({
  params: null,
  setParams: params => set({ params }),
}))
