import { IconProps } from "."

export const DashboardCircleSetting = (props: IconProps) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_4287_35817)">
        <circle cx="5.20573" cy="5.20866" r="3.54167" stroke="currentColor" strokeWidth="1.5" />
        <path
          d="M14.9948 7.79827V8.75065M14.9948 7.79827C14.1513 7.79827 13.4082 7.37738 12.9723 6.73829M14.9948 7.79827C15.8383 7.79827 16.5814 7.37738 17.0173 6.73829M14.9948 3.03637C15.8383 3.03637 16.5815 3.45732 17.0174 4.09648M14.9948 3.03637C14.1512 3.03637 13.4081 3.45732 12.9722 4.09648M14.9948 3.03637V2.08398M17.9115 3.51256L17.0174 4.09648M12.0784 7.32208L12.9723 6.73829M12.0781 3.51256L12.9722 4.09648M17.9111 7.32208L17.0173 6.73829M17.0174 4.09648C17.2751 4.47444 17.4253 4.92869 17.4253 5.41732C17.4253 5.90601 17.2751 6.36031 17.0173 6.73829M12.9722 4.09648C12.7145 4.47444 12.5642 4.92869 12.5642 5.41732C12.5642 5.90601 12.7145 6.36031 12.9723 6.73829"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <circle cx="14.7917" cy="14.7917" r="3.54167" stroke="currentColor" strokeWidth="1.5" />
        <ellipse cx="5.20573" cy="14.7917" rx="3.54167" ry="3.54167" stroke="currentColor" strokeWidth="1.5" />
      </g>
      <defs>
        <clipPath id="clip0_4287_35817">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
