import { create } from "zustand"

interface ExpandViewImageStore {
  expandViewImage: { url: string; id?: string; name?: string } | null
  setExpandViewImage: (expandViewImage: { url: string; id?: string; name?: string } | null) => void
}

export const useExpandViewImage = create<ExpandViewImageStore>(set => ({
  expandViewImage: null,
  setExpandViewImage: expandViewImage => set({ expandViewImage }),
}))
