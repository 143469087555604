import client from "@/api/client"
import { CreateRecipeTaskDto } from "@/api/sdk"
import { useToast } from "@/hooks"
import { useAuth } from "@/providers/authContext"
import { googleAnalytics } from "@/lib/gtag"
import { useStateOnRunningTaskRecipesStore } from "@/stores/useOnRunningTaskRecipesStore"
import { useTempTaskStore } from "@/stores/useTempTaskStore"
import generateCaptchaToken from "@/utils/generateCaptchaToken"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import useCustomRouter from "@/hooks/useCustomRouter"
import { isMobileOnly } from "react-device-detect"

const useCreateTask = ({ recipeId }: { recipeId: string }) => {
  const qc = useQueryClient()
  const { user } = useAuth()
  const { tempTask, setTempTask } = useTempTaskStore()
  const [_, setState] = useStateOnRunningTaskRecipesStore()
  const router = useCustomRouter()

  const toast = useToast({
    position: !isMobileOnly ? "top-center" : "bottom-center",
  })

  const mutate = useMutation({
    mutationFn: async (data: CreateRecipeTaskDto) => {
      googleAnalytics.event({
        action: "click",
        category: "recipe_alpha",
        label: "recipe_alpha_generate",
        params: {
          recipe_id: recipeId,
          batch_size: 2,
          ...data.params,
        },
      })

      if (!user) {
        if (tempTask && tempTask.length >= 3) {
          toast({
            status: "error",
            title: "You have reached the limit of free tasks.",
            message: ["Sign-In to remove watermark and get 20 FREE credits!"],
          })

          router.replace(
            {
              query: {
                signIn: true,
              },
            },
            undefined,
            {
              shallow: true,
            },
          )

          return null
        }

        const captchaToken = await generateCaptchaToken()
        const result = await client.api
          .recipeTaskControllerAddFreeTask({
            ...data,
            params: {
              ...data.params,
              batch_size: "2",
            },
            captchaToken,
          })
          .then(res => res.data)
        setTempTask([...(tempTask || []), { id: result.id }])
        return result
      }
      return await client.api
        .recipeTaskControllerAddTask({
          ...data,
          params: {
            ...data.params,
            batch_size: "2",
          },
        })
        .then(res => res.data)
    },
    onSettled: () => {
      qc.invalidateQueries({ queryKey: ["user-info", user?.uid] })
    },
    onSuccess: data => {
      if (!data) return

      setState(prev => ({
        ...prev,
        [recipeId]: {
          runningTasks: Array.from({ length: data.params["batch_size"] ?? 1 }, (_, i) => ({
            url: "",
            id: `${i}`,
            recipeTaskId: data.id,
          })),
        },
      }))

      qc.invalidateQueries({ queryKey: ["recipe-ongoing", user?.uid, recipeId] })

      toast({
        status: "success",
        title: "Transforming your photo!",
        duration: 5000,
      })
    },
  })

  return mutate
}

export default useCreateTask
