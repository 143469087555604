import { useEffect } from "react"

// Updates the height of a <textarea> when the value changes.
const useAutoSizeTextArea = (textAreaRef: HTMLTextAreaElement | null, value: string, trigger?: any) => {
  useEffect(() => {
    const handleHeightChange = () => {
      if (textAreaRef) {
        // We need to reset the height momentarily to get the correct scrollHeight for the textarea
        textAreaRef.style.height = "24px"
        const scrollHeight = textAreaRef.scrollHeight

        // We then set the height directly, outside of the render loop
        // Trying to set this with state or a ref will product an incorrect value.
        textAreaRef.style.height = Math.max(scrollHeight, 24) + "px"
      }
    }

    handleHeightChange()

    window.addEventListener("resize", handleHeightChange)

    return () => {
      window.removeEventListener("resize", handleHeightChange)
    }
  }, [value, textAreaRef, trigger])
}

export default useAutoSizeTextArea
