import IconButton from "@/components/IconButton"
import Selector from "@/components/Selector"
import classNames from "classnames"
import { FC } from "react"
import FormDetailTooltip from "./FormDetailTooltip"
import { FormDetailComponentProps } from "./types"
import { cn } from "@/lib/utils"

const FormDetailSelectComponent: FC<
  FormDetailComponentProps & {
    rightIcon?: React.ReactNode
    listClassName?: string
    listContentClassName?: string
    inputClassName?: string
    itemClassName?: string
  }
> = props => {
  const { input, value, onChange, rightIcon, listClassName, itemClassName, listContentClassName, inputClassName } =
    props

  const isThumbnail = input.options?.find(m => m.value)?.thumbnail

  return (
    <div>
      <div className="max-w-[24rem]">
        {isThumbnail ? (
          <>
            <FormDetailTooltip className="mb-0" name={input.name} tooltip={input.tooltip}></FormDetailTooltip>
            <p className="mb-2 text-sm text-atherGray-400">{input.description}</p>
            <div className="w-full grid grid-cols-2 gap-2 pr-1 max-h-[20rem] overflow-auto">
              {input?.options?.map(i => (
                <IconButton
                  onClick={() => {
                    onChange({
                      key: input.key,
                      value: i.value,
                      clearTriggerKey: input.clearTriggerKey,
                    })
                  }}
                  key={i.value}
                  type="button"
                  className={classNames(
                    "w-full text-sm p-0 font-semibold bg-atherGray-700 border-[2px] rounded-2xl border-transparent hover:bg-atherGray-600 active:bg-atherGray-600",
                    {
                      "bg-atherPurple-500 hover:bg-atherPurple-500": value === i.value,
                    },
                  )}
                >
                  <img src={i.thumbnail} alt="" />
                  <div
                    className="absolute bottom-0 left-0 p-2 w-full text-left text-xs"
                    style={{
                      background: "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.70) 100%)",
                    }}
                  >
                    {i.display}
                  </div>
                </IconButton>
              ))}
            </div>
          </>
        ) : (
          <Selector
            searchable
            rightIcon={rightIcon}
            listContentClassName={listContentClassName}
            titleClassName="font-normal text-atherGray-300"
            listClassName={cn("bg-atherGray-800 border border-atherGray-800 text-sm", listClassName)}
            className="bg-atherGray-800 border border-atherGray-800 text-sm"
            inputClassName={inputClassName}
            itemClassName={itemClassName}
            value={input?.options?.find(m => m.value === value)?.display ?? ""}
            title={input.name}
            tooltip={input.tooltip}
            options={
              input.options
                ?.map(m => ({ id: m.value as string, name: m.display }))
                .sort((a, b) => a.name.localeCompare(b.name)) ?? []
            }
            onSelect={v => {
              onChange({
                key: input.key,
                value: v.id,
                clearTriggerKey: input.clearTriggerKey,
              })
            }}
          />
        )}
      </div>
    </div>
  )
}

export default FormDetailSelectComponent
