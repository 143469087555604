import { useAuth } from "@/providers/authContext"
import React from "react"

const LimitUserNotAuth = () => {
  const { user } = useAuth()

  if (user) return null

  return (
    <div className="flex items-center justify-center mt-2">
      <div>
        <hr className="border-t-[2px] border-atherGray-800 mb-2" />
        <p className="text-atherGray-500 text-center text-sm font-semibold">You need to sign in to view more.</p>
      </div>
    </div>
  )
}

export default LimitUserNotAuth
