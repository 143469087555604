import client from "@/api/client"
import { GetCommentsResult, UpdateCommentDto } from "@/api/sdk"
import { useAuth } from "@/providers/authContext"
import { googleAnalytics } from "@/lib/gtag"
import { InfiniteData, QueryKey, useMutation, useQueryClient } from "@tanstack/react-query"

type optionsType = {
  onMutate?: () => void
  onError?: () => void
}

const useDeleteCommentImage = (queryCommentsImageKey: QueryKey, options?: optionsType) => {
  const qc = useQueryClient()

  const mutation = useMutation({
    mutationFn: (commentId: string) => client.api.commentControllerDeleteComment(commentId),
    onMutate: async commentId => {
      options?.onMutate?.()
      await qc.cancelQueries({ queryKey: queryCommentsImageKey })

      googleAnalytics.event({
        action: "click",
        category: "image_detail",
        label: `delete_comment_commentId_${commentId}`,
        value: 1,
      })

      const previousComments = qc.getQueryData(queryCommentsImageKey) as InfiniteData<GetCommentsResult> | undefined

      if (previousComments) {
        qc.setQueryData(queryCommentsImageKey, {
          ...previousComments,
          pages: previousComments.pages.map(page => ({
            ...page,
            comments: page.comments.filter(c => c.id !== commentId),
          })),
        })
      }

      return { previousComments }
    },
    onSettled: () => {
      // qc.invalidateQueries(queryCommentsImageKey)
    },
    onError: (err, variables, context) => {
      if (context?.previousComments) {
        qc.setQueryData(queryCommentsImageKey, context.previousComments)
      }
      options?.onError?.()
    },
  })

  return mutation
}

const useLikeCommentImage = (queryCommentsImageKey: QueryKey, options?: optionsType) => {
  const qc = useQueryClient()
  const { user, userInfoQuery, handleSignIn } = useAuth()

  const mutation = useMutation({
    mutationFn: async (commentId: string) => {
      const isSignIn = await handleSignIn()

      if (!isSignIn) return null

      return await client.api.commentControllerLikeComment(commentId)
    },
    onMutate: async commentId => {
      if (!user) {
        return null
      }

      options?.onMutate?.()
      await qc.cancelQueries({ queryKey: queryCommentsImageKey })

      googleAnalytics.event({
        action: "click",
        category: "image_detail",
        label: `like_comment_imageId_${commentId}`,
        value: 1,
      })

      const previousComments = qc.getQueryData(queryCommentsImageKey) as InfiniteData<GetCommentsResult> | undefined

      if (previousComments) {
        qc.setQueryData(queryCommentsImageKey, {
          ...previousComments,
          pages: previousComments.pages.map(page => ({
            ...page,
            comments: page.comments.map(c => {
              if (c.id === commentId) {
                return {
                  ...c,
                  likeCount: c.likedBy.find(i => i.uid === userInfoQuery?.data?.uid)
                    ? c.likeCount - 1
                    : c.likeCount + 1,
                  likedBy: c.likedBy.find(i => i.uid === userInfoQuery?.data?.uid)
                    ? c.likedBy?.filter(i => i.uid !== userInfoQuery?.data?.uid) ?? []
                    : [
                        ...(c.likedBy ?? []),
                        {
                          uid: userInfoQuery?.data?.uid ?? "",
                          email: userInfoQuery?.data?.email ?? "",
                          name: userInfoQuery?.data?.name ?? "",
                          picture: userInfoQuery?.data?.picture ?? "",
                        },
                      ],
                }
              }
              return c
            }),
          })),
        })
      }

      return { previousComments }
    },
    onSettled: () => {
      if (!user) {
        return null
      }

      // qc.invalidateQueries(queryCommentsImageKey)
    },
    onError: (err, variables, context) => {
      if (context?.previousComments) {
        qc.setQueryData(queryCommentsImageKey, context.previousComments)
      }
      options?.onError?.()
    },
  })

  return mutation
}

const useEditCommentImage = (queryCommentsImageKey: QueryKey, options?: optionsType) => {
  const qc = useQueryClient()

  return useMutation({
    mutationFn: ({ commentId, data }: { commentId: string; data: UpdateCommentDto }) =>
      client.api.commentControllerUpdateComment(commentId, data),
    onMutate: async ({ commentId, data }) => {
      options?.onMutate?.()
      await qc.cancelQueries({ queryKey: queryCommentsImageKey })

      googleAnalytics.event({
        action: "click",
        category: "image_detail",
        label: `edit_comment_commentId_${commentId}`,
        value: 1,
      })

      const previousComments = qc.getQueryData(queryCommentsImageKey) as InfiniteData<GetCommentsResult> | undefined

      if (previousComments) {
        qc.setQueryData(queryCommentsImageKey, {
          ...previousComments,
          pages: previousComments.pages.map(page => ({
            ...page,
            comments: page.comments.map(c => {
              if (c.id === commentId) {
                return {
                  ...c,
                  value: data.value,
                  formatValue: data.formatValue,
                }
              }
              return c
            }),
          })),
        })
      }
      return { previousComments }
    },
    onSettled: () => {
      // qc.invalidateQueries(queryCommentsImageKey)
    },
    onError: (err, variables, context) => {
      if (context?.previousComments) {
        qc.setQueryData(queryCommentsImageKey, context.previousComments)
      }
      options?.onError?.()
    },
  })
}

export { useDeleteCommentImage, useEditCommentImage, useLikeCommentImage }
