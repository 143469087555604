import client from "@/api/client"
import { GetWorkspacesResponse, WorkspaceWithRole } from "@/api/sdk"
import { createAuthenticatedInfiniteQuery } from "../createAuthenticatedQuery"

export const useUserWorkspacesInfiniteQuery = createAuthenticatedInfiniteQuery<
  GetWorkspacesResponse,
  void,
  Error,
  WorkspaceWithRole
>({
  primaryKey: "user-workspaces",
  initialPageParam: 0,
  queryFn: ({ pageParam = 0 }) =>
    client.api
      .workspaceControllerGetWorkspaces({
        skip: pageParam * 20,
        take: 20,
      })
      .then(res => res.data),
  getNextPageParam: (lastPage, pages) => (lastPage && lastPage.workspaces.length < 20 ? undefined : pages.length),
  flattenFn: data => data.workspaces,
})
