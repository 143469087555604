import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { useEffect } from "react"

const EditorReadOnlyPlugin = ({ readOnly }: { readOnly?: boolean }) => {
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    editor.update(() => {
      editor.setEditable(true)

      if (!editor || readOnly === undefined) return

      editor.setEditable(!readOnly)
    })
  }, [editor, readOnly])

  return null
}

export default EditorReadOnlyPlugin
