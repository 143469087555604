import _sortBy from "lodash/sortBy"
import {
  ComfyUIGraph,
  ComfyUIGraphExtra,
  ComfyUIGraphGroup,
  ComfyUIGraphNode,
  ComfyUINodeBaseInputType,
  ComfyUINodeInfos,
  ComfyUINodeInput,
  IComfyUIGraph,
} from "../types/node"

export type GraphField = {
  id: string
  nodeId: number
  nodeName: string
  nodeType: string
  field: string
  type: string
  value: any
  values?: string[]
}

const supportedTypeMap = {
  STRING: "string",
  INT: "number",
  FLOAT: "number",
  BOOLEAN: "boolean",
}

const specialNodeTypeMap = {
  LoadImage: { image: "image" },
  LoadImageMask: { image: "image" },
  LoadImageFromUrl: { image: "image" },
  LoadImageAsMaskFromUrl: { image: "image" },
  LoadVideoFromUrl: { url: "image" },
  AV_LoraListLoader: { data: "lora" },
  AV_LoraListStacker: { data: "lora" },
  GAIA_LoraListLoader: { data: "lora" },
  AV_StyleApply: { data: "style" },
  GAIA_ApplyStyle: { data: "style" },
  AspectRatioSelector: { aspect_ratio: "ratio" },
  SDXLAspectRatioSelector: { aspect_ratio: "ratio" },
  GAIA_AspectRatio: { aspect_ratio: "ratio" },
  TextSwitchCase: {
    condition: (node: ComfyUIGraphNode, field: string, input: ComfyUINodeInput) => {
      const value: string = node.widgets_values["switch_cases"] ?? ""
      const delimiter: string = node.widgets_values["delimiter"] ?? ""

      const cases = delimiter
        ? value
            .split("\n")
            .filter(line => line.includes(delimiter))
            .map(line => line.split(delimiter)[0])
        : []

      input[0] = cases
      return "select"
    },
  },
}

const isValidWidget = (input: ComfyUINodeInput) => {
  return typeof input[0] === "string" ? !!supportedTypeMap[input[0]] : Array.isArray(input[0])
}

const getWidgetValue = (widgets_values: Record<string, any> | any[], index: number, key: string) => {
  return Array.isArray(widgets_values) ? widgets_values[index] : widgets_values[key]
}

const getDataType = (node: ComfyUIGraphNode, field: string, input: ComfyUINodeInput) => {
  if (field === "ckpt_name") return "model"
  if (/lora(_[\d]+)?_name(_?[\d]+)?|default_lora/.test(field)) return "lora-simple"
  if (specialNodeTypeMap[node.type]?.[field]) {
    if (typeof specialNodeTypeMap[node.type][field] === "function") {
      return specialNodeTypeMap[node.type][field](node, field, input)
    }
    return specialNodeTypeMap[node.type][field]
  }
  if (Array.isArray(input[0])) return "select"
  if (input[0] === ComfyUINodeBaseInputType.STRING && input[1]?.multiline) return "string-multiline"

  return supportedTypeMap[input[0]]
}

export const extractGraphNodeInputs = (group: IComfyUIGraph, nodeInfos: ComfyUINodeInfos) => {
  const graphFields: GraphField[] = []

  group.nodes.forEach(node => {
    let info = nodeInfos[node.type]
    let widgetIdx = 0

    if (node.type === "Reroute") return

    if (!info) {
      return console.warn("Missing info for node", node.type)
    }

    const inputs = [...Object.entries(info.input.required ?? {}), ...Object.entries(info.input.optional ?? {})]
    inputs.forEach(([field, input]) => {
      // not a widget, skip
      if (!isValidWidget(input)) return

      // widget converted to input, next
      const isInput = node.inputs?.find(i => i.name === field)
      if (isInput) {
        widgetIdx++
        if (field === "seed" || field === "noise_seed") {
          widgetIdx++
        }
        return
      }

      const type = getDataType(node, field, input)
      const value = getWidgetValue(node.widgets_values, widgetIdx, field)
      if (Array.isArray(input[0]) || type === "select") {
        const values = Array.isArray(input[0]) ? input[0] : [value]
        graphFields.push({
          id: `${node.id}.inputs.${field}`,
          nodeId: node.id,
          nodeType: node.type,
          nodeName: info.display_name,
          field,
          type,
          value,
          values,
        })
      } else if (typeof input[0] === "string" && supportedTypeMap[input[0]]) {
        graphFields.push({
          id: `${node.id}.inputs.${field}`,
          nodeId: node.id,
          nodeType: node.type,
          nodeName: info.display_name,
          field,
          type,
          value,
        })
      } else {
        return
      }

      widgetIdx++
      if (field === "seed" || field === "noise_seed") {
        widgetIdx++
      }
    })
  })

  return graphFields
}

export const extractGraphInputs = (graph: ComfyUIGraph, nodeInfos: ComfyUINodeInfos) => {
  const inputs = extractGraphNodeInputs(graph, nodeInfos)

  return _sortBy(
    inputs,
    v => v.nodeId,
    v => v.field,
  )
}

const replaceInput = (availableVariants: string[], modelsMap: Record<string, any>, inputs?: Record<string, any>) => {
  if (!inputs) return

  Object.keys(inputs).forEach(key => {
    const input = inputs[key]
    if (!Array.isArray(input) || !Array.isArray(input[0])) {
      return
    }

    for (const variant of availableVariants) {
      const idx = input[0].indexOf(variant)
      if (idx >= 0) {
        input[0].splice(idx, 1, ...modelsMap[variant])
        // break
      }
    }
  })
}
const replaceOutput = (availableVariants: string[], modelsMap: Record<string, any>, output?: Array<any>) => {
  if (!output) return

  output.forEach(out => {
    if (!Array.isArray(out)) {
      return
    }

    for (const variant of availableVariants) {
      if (out[out.length - 1] == variant) {
        out.pop()
        out.push(...modelsMap[variant])
        break
      }
    }
  })
}
const replaceHidden = (availableVariants: string[], modelsMap: Record<string, any>, inputs?: Record<string, any>) => {
  if (!inputs) return

  Object.keys(inputs).forEach(key => {
    const input = inputs[key]
    if (!Array.isArray(input)) return

    replaceOutput(availableVariants, modelsMap, Object.values(input[0]))
  })
}

export const populateNodeModels = (
  availableVariants: string[],
  modelsMap: Record<string, any>,
  nodeInfos: ComfyUINodeInfos,
) => {
  Object.keys(nodeInfos).forEach(key => {
    const node = nodeInfos[key]
    if (!node.input && !node.output) {
      return
    }

    replaceInput(availableVariants, modelsMap, node.input.required)
    replaceInput(availableVariants, modelsMap, node.input.optional)
    replaceHidden(availableVariants, modelsMap, node.input.hidden)
    replaceOutput(availableVariants, modelsMap, node.output)
  })
}
