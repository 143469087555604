import { useAuth } from "@/providers/authContext"
import { InfiniteQueryHook, Middleware, QueryHook } from "react-query-kit"

export const authMiddleware: Middleware<
  InfiniteQueryHook<any, any, any, number> | QueryHook<any, any, any>
> = useQueryNext => {
  return opts => {
    const { tokenFormat, loading } = useAuth()
    const enabled = !!tokenFormat && !loading ? opts.enabled : false

    const query = useQueryNext({
      ...opts,
      enabled,
    })

    return query
  }
}
