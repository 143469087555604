import client from "@/api/client"
import { User } from "@/api/sdk"
import { createAuthenticatedInfiniteQuery } from "./createAuthenticatedQuery"

export interface SearchUsersInfiniteQueryVariables {
  search: string
}

export const useSearchUsersInfiniteQuery = createAuthenticatedInfiniteQuery<
  User[],
  SearchUsersInfiniteQueryVariables,
  Error,
  User
>({
  primaryKey: "users",
  queryFn: ({ pageParam = 0, queryKey: [_primaryKey, variables] }) =>
    client.api
      .userControllerSearchUser({ search: variables.search, skip: pageParam * 10, take: 10 })
      .then(res => res.data),
  flattenFn: users => users,
  getNextPageParam: (lastPage, pages) => (lastPage && lastPage.length < 10 ? undefined : pages.length),
  initialPageParam: 0,
  retry: false,
})
