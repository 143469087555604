import { create } from "zustand"
import { devtools, persist } from "zustand/middleware"

interface TempTaskStore {
  tempTask:
    | {
        id: string
        isTransfer?: boolean
      }[]
    | null
  setTempTask: (tempTask: { id: string }[]) => void
}

export const useTempTaskStore = create<TempTaskStore>()(
  devtools(
    persist(
      set => ({
        tempTask: null,
        setTempTask: tempTask =>
          set(() => {
            return { tempTask }
          }),
      }),
      {
        name: "temp-task-storage",
      }
    )
  )
)
