import { PromptItem } from "@/api/sdk"
import IconButton from "@/components/IconButton"
import Tooltip from "@/components/Tooltip"
import { DeleteIcon, EditIcon, ScienceIcon } from "@/components/shared/icons"
import { googleAnalytics } from "@/lib/gtag"
import { useDeleteItemStore, useRecipeImageStore } from "@/stores"
import classNames from "classnames"

interface PromptDetailActionButtonsProps {
  promptData: PromptItem
  onOpenUpdate?: () => void
  onClose: () => void
  refetch?: () => void
}

const PromptDetailActionButtons = ({ promptData, onOpenUpdate, onClose, refetch }: PromptDetailActionButtonsProps) => {
  const setDeleteItem = useDeleteItemStore(state => state.setDeletingItem)
  const setRecipeImage = useRecipeImageStore(state => state.setRecipeImage)

  const handleRecipe = async () => {
    googleAnalytics.event({
      action: "click",
      category: "prompt_detail",
      label: `send_to_recipe`,
      params: {
        prompt_id: promptData.id,
        prompt_name: promptData.name ?? promptData.id,
        pathname: window.location.pathname,
      },
    })

    const params = {
      prompt: `${promptData.prompt}`,
    }

    setRecipeImage({
      id: promptData.id,
      type: "wildcard",
      isModal: true,
      params: {
        ...params,
      },
    })
  }

  const actionItems = [
    {
      title: "Send to recipe",
      icon: <ScienceIcon className="text-atherGray-300" width={16} height={16} />,
      onClick: () => {
        handleRecipe()
      },
      visible: !!promptData,
      enabled: !!promptData,
    },
    {
      title: "Edit",
      icon: <EditIcon className="text-atherGray-300" width={16} height={16} />,
      onClick: () => {
        onOpenUpdate?.()
      },
      visible: !!promptData,
    },
    {
      title: "Delete",
      icon: <DeleteIcon className="text-red-500" width={16} height={16} />,
      dropdown: true,
      onClick: () => {
        googleAnalytics.event({
          action: "click",
          category: "prompt_detail",
          label: "delete_prompt",
          params: {
            prompt_id: promptData.id,
            prompt_name: promptData.name ?? promptData.id,
            pathname: window.location.pathname,
          },
        })

        setDeleteItem({
          id: promptData.id,
          type: "prompt",
          deletedAt: null,
          onClose,
        })
      },
      visible: !!promptData,
    },
  ]

  return (
    <div className="flex-1 justify-end flex">
      <div className="flex items-center gap-2 md:gap-1">
        {actionItems
          .filter(item => item.visible)
          .map((item, index) => (
            <Tooltip
              key={index}
              trigger={
                <IconButton
                  colorScheme={item.icon ? "secondary" : "primary"}
                  className={classNames("flex justify-start p-1 md:p-2", {
                    "bg-transparent": item.icon,
                  })}
                  title={item.title}
                  onClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    item.onClick()
                  }}
                >
                  {item.icon && <div className="w-4 h-4">{item.icon}</div>}
                </IconButton>
              }
            >
              {item.title}
            </Tooltip>
          ))}
      </div>
    </div>
  )
}

export default PromptDetailActionButtons
