import React, { FC, Fragment, useEffect, useState } from "react"
import FieldWithController from "../../Recipes/RecipeDetail/FieldWithController"
import { FormDetailComponentProps } from "../../Recipes/RecipeDetail/FormDetail/types"
import OptionalItem from "../../Recipes/RecipeDetail/OptionalItem"
import UploadRecipeImage from "../../Recipes/RecipeDetail/UploadRecipeImage"
import IconButton from "@/components/IconButton"
import SelectPrevTaskImageModal from "../../../ModalsPortal/PromptInput/SelectPrevTaskImageModal"
import useCustomRouter from "@/hooks/useCustomRouter"
import { ImageUploadIcon } from "@/components/shared/icons"

const ChainingFormDetailImageComponent: FC<FormDetailComponentProps> = props => {
  const { input, recipeInput, onChange, value, error, chains } = props

  const router = useCustomRouter()

  const { index } = router.query
  const [isOpen, setIsOpen] = useState(false)

  const isChecked = recipeInput?.isChecked ?? false

  return (
    <Fragment>
      <OptionalItem
        isChecked={isChecked}
        onChecked={() => {
          onChange({
            key: input.key,
            value: "",
            checked: !isChecked,
            clearTriggerKey: input.clearTriggerKey,
          })
        }}
        title={input.title}
        name={input.name}
        description={input.description}
        tooltip={input.tooltip}
        enabled={input.optional ?? false}
      >
        <div className="pt-1">
          {chains?.some(chain => chain.recipeId !== "add-a-recipe") && index !== "0" && (
            <IconButton
              className="text-xs mb-2 w-full justify-start font-normal"
              colorScheme="secondary"
              onClick={e => {
                e.stopPropagation()
                setIsOpen(true)
              }}
            >
              <ImageUploadIcon className="mr-2 text-atherGray-500" />
              From previous step
            </IconButton>
          )}
          <FieldWithController name={input.key}>
            <UploadRecipeImage
              valuePrev={
                value?.includes("$$prev.")
                  ? `Step ${Number(value?.replace("$$prev.", "")) + 1} : ${
                      chains?.[Number(value?.replace("$$prev.", ""))]?.recipeName
                    }`
                  : undefined
              }
              className="mt-2"
              error={error}
              value={value}
              onChange={v => {
                onChange({ key: input.key, value: v, clearTriggerKey: input.clearTriggerKey })
              }}
            />
          </FieldWithController>
        </div>
      </OptionalItem>
      <SelectPrevTaskImageModal
        value={value}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        chains={chains}
        index={index as string}
        onChange={v => {
          onChange({
            key: input.key,
            value: v,
            checked: isChecked,
            clearTriggerKey: input.clearTriggerKey,
          })
          setIsOpen(false)
        }}
      />
    </Fragment>
  )
}

export default ChainingFormDetailImageComponent
