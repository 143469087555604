import client from "@/api/client"
import { PinnedItemType, PinnedItems, RecentSDWorkflowItem, SearchSDWorkflowResponse } from "@/api/sdk"
import { actionMiddleware } from "@/queries/middlewares/actionMiddleware"
import { InfiniteData } from "@tanstack/react-query"
import { createMutation } from "react-query-kit"
import { useComfyUiWorkflowInfiniteQuery } from "./getComfyUiRecipeQueries"
import { useRecentComfyUIInfiniteQuery } from "@/components/Workspace/RecentV2/RecentComfyUI"
import { useWorkspacePinInfiniteQuery } from "@/queries/workspace"

export type DeleteComfyUiRecipeMutationVariables = {
  ids: string[]
  deletedAt?: string | null
}

export const useDeleteComfyUiWorkflowMutation = createMutation({
  mutationFn: async ({ ids, deletedAt }: DeleteComfyUiRecipeMutationVariables) => {
    if (deletedAt) {
      return client.api
        .sdWorkflowControllerFullyDeleteWorkflows({
          workflowIds: ids,
        })
        .then(res => res.data)
    }

    return client.api
      .sdWorkflowControllerSoftDeleteWorkflows({
        workflowIds: ids,
      })
      .then(res => res.data)
  },
  use: [
    actionMiddleware({
      onSuccess: ({ queryClient, variables: { ids } }) => {
        const infKey = useComfyUiWorkflowInfiniteQuery.getKey()
        const infQueriesDataEntries = queryClient.getQueriesData<InfiniteData<SearchSDWorkflowResponse, number>>({
          queryKey: infKey,
        })

        infQueriesDataEntries?.forEach(([key, data]) => {
          if (!data) return

          const updatedData = {
            ...data,
            pages: data.pages.map(page => {
              const updatedPage = {
                ...page,
                workflows: page.workflows.filter(style => {
                  return !ids.includes(style.id)
                }),
              }
              updatedPage.total = updatedPage.workflows.length
              return updatedPage
            }),
          }
          queryClient.setQueryData(key, updatedData)
        })

        // Update comfyui recent list
        const comfyuiRecentKey = useRecentComfyUIInfiniteQuery.getKey()

        const comfyuisRecentListQueriesDataEntries = queryClient.getQueriesData<
          InfiniteData<RecentSDWorkflowItem[], number>
        >({
          queryKey: comfyuiRecentKey,
        })

        if (comfyuisRecentListQueriesDataEntries) {
          comfyuisRecentListQueriesDataEntries.forEach(([key, comfyuisRecentListData]) => {
            if (comfyuisRecentListData) {
              const updatedData = {
                ...comfyuisRecentListData,
                pages: comfyuisRecentListData.pages.map(page => {
                  return [...page.filter(recent => !ids.includes(recent.sdWorkflow.id))]
                }),
              }

              queryClient.setQueryData(key, updatedData)
            }
          })
        }

        // Update comfyui pinned list
        const comfyuiPinnedListKey = useWorkspacePinInfiniteQuery.getKey({
          type: PinnedItemType.SD_WORKFLOW,
        })

        const comfyuiPinnedListQueriesDataEntries = queryClient.getQueriesData<InfiniteData<PinnedItems[], number>>({
          queryKey: comfyuiPinnedListKey,
        })

        if (comfyuiPinnedListQueriesDataEntries) {
          comfyuiPinnedListQueriesDataEntries.forEach(([key, comfyuiPinnedListData]) => {
            if (comfyuiPinnedListData) {
              const updatedData = {
                ...comfyuiPinnedListData,
                pages: comfyuiPinnedListData.pages.map(page => {
                  return [...page.filter(item => !ids.includes(item.sdWorkflow?.id ?? ""))]
                }),
              }

              queryClient.setQueryData(key, updatedData)
            }
          })
        }
      },
    }),
  ],
})
