import { IconProps } from "."

export const CopyIcon = (props: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M6 10C6 8.11438 6 7.17157 6.58579 6.58579C7.17157 6 8.11438 6 10 6L10.6667 6C12.5523 6 13.4951 6 14.0809 6.58579C14.6667 7.17157 14.6667 8.11438 14.6667 10V10.6667C14.6667 12.5523 14.6667 13.4951 14.0809 14.0809C13.4951 14.6667 12.5523 14.6667 10.6667 14.6667H10C8.11438 14.6667 7.17157 14.6667 6.58579 14.0809C6 13.4951 6 12.5523 6 10.6667L6 10Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.3319 5.99967C11.3304 4.02828 11.3005 3.00715 10.7267 2.30796C10.6159 2.17294 10.4921 2.04913 10.3571 1.93831C9.61951 1.33301 8.52369 1.33301 6.33203 1.33301C4.14038 1.33301 3.04455 1.33301 2.30698 1.93831C2.17196 2.04912 2.04815 2.17293 1.93734 2.30796C1.33203 3.04553 1.33203 4.14135 1.33203 6.33301C1.33203 8.52466 1.33203 9.62049 1.93734 10.3581C2.04815 10.4931 2.17196 10.6169 2.30698 10.7277C3.00617 11.3015 4.0273 11.3314 5.9987 11.3329"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
