import { IconProps } from "."

export const FolderTransferIcon = (props: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="22" viewBox="0 0 23 22" fill="none" {...props}>
      <path
        d="M6.33398 10H6.95898C8.71456 10 9.59235 10 10.2229 9.57867C10.4959 9.39628 10.7303 9.1619 10.9127 8.88893C11.334 8.25837 11.334 7.38058 11.334 5.625C11.334 4.57165 11.334 4.04498 11.0812 3.66664C10.9718 3.50286 10.8311 3.36223 10.6673 3.2528C10.289 3 9.76233 3 8.70898 3H7.04792C6.90351 3 6.83131 3 6.76499 2.99026C6.51673 2.95381 6.29473 2.81737 6.15159 2.61327C6.11336 2.55875 6.08133 2.49469 6.01727 2.36656C5.75488 1.84179 5.5151 1.31356 4.93361 1.0955C4.67893 1 4.38799 1 3.80612 1C2.89788 1 2.44377 1 2.10302 1.19016C1.86012 1.32571 1.65969 1.52613 1.52414 1.76903C1.33398 2.10978 1.33398 2.5639 1.33398 3.47214V5C1.33398 7.35702 1.33398 8.53553 2.06622 9.26777C2.79845 10 3.97696 10 6.33398 10Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M16.334 21H15.709C13.9534 21 13.0756 21 12.4451 20.5787C12.1721 20.3963 11.9377 20.1619 11.7553 19.8889C11.334 19.2584 11.334 18.3806 11.334 16.625C11.334 15.5717 11.334 15.045 11.5868 14.6666C11.6962 14.5029 11.8368 14.3622 12.0006 14.2528C12.379 14 12.9056 14 13.959 14H15.6201C15.7645 14 15.8367 14 15.903 13.9903C16.1512 13.9538 16.3732 13.8174 16.5164 13.6133C16.5546 13.5587 16.5866 13.4947 16.6507 13.3666C16.9131 12.8418 17.1529 12.3136 17.7344 12.0955C17.989 12 18.28 12 18.8618 12C19.7701 12 20.2242 12 20.565 12.1902C20.8079 12.3257 21.0083 12.5261 21.1438 12.769C21.334 13.1098 21.334 13.5639 21.334 14.4721V16C21.334 18.357 21.334 19.5355 20.6018 20.2678C19.8695 21 18.691 21 16.334 21Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M18.6698 8H21.334C20.834 4 18.334 1.5 15.334 1"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.99815 14L1.33398 14C1.83398 18 4.33398 20.5 7.33398 21"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
