import { EntityType } from "@/api/sdk"
import { create } from "zustand"
import { devtools, persist } from "zustand/middleware"

interface TempTaskStore {
  feedbackTasks: EntityType[] | null
  setFeedbackTasks: (feedbackTasks: EntityType[] | null) => void
}

export const useFeedbackTaskStore = create<TempTaskStore>()(
  devtools(
    persist(
      set => ({
        feedbackTasks: null,
        setFeedbackTasks: feedbackTasks =>
          set(() => {
            return { feedbackTasks }
          }),
      }),
      {
        name: "feedback-task-storage",
      },
    ),
  ),
)
