import { IconProps } from "."

export const CalendarFavoriteIcon = (props: IconProps) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M14.8119 11.3748L15.4719 12.7056C15.5618 12.8908 15.8018 13.0685 16.0043 13.1025L17.2004 13.3029C17.9653 13.4314 18.1453 13.991 17.5941 14.5429L16.6642 15.4805C16.5067 15.6393 16.4205 15.9455 16.4692 16.1648L16.7355 17.3254C16.9454 18.2441 16.4617 18.5995 15.6556 18.1194L14.5345 17.4502C14.332 17.3292 13.9983 17.3292 13.792 17.4502L12.6709 18.1194C11.8685 18.5995 11.3811 18.2403 11.591 17.3254L11.8573 16.1648C11.906 15.9455 11.8198 15.6393 11.6623 15.4805L10.7324 14.5429C10.1849 13.991 10.3612 13.4314 11.1261 13.3029L12.3222 13.1025C12.5209 13.0685 12.7609 12.8908 12.8509 12.7056L13.5108 11.3748C13.8708 10.6527 14.4557 10.6527 14.8119 11.3748Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 1.66663V3.33329M5 1.66663V3.33329"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.9152 9.58333C17.9118 6.36137 17.8543 4.69002 16.8719 3.62803C15.8285 2.5 14.1491 2.5 10.7904 2.5H9.20703C5.84827 2.5 4.1689 2.5 3.12546 3.62803C2.08203 4.75607 2.08203 6.57161 2.08203 10.2027V10.6306C2.08203 14.2617 2.08203 16.0773 3.12546 17.2053C4.12031 18.2808 5.69327 18.3309 8.7487 18.3332"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.5 6.66663H17.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
