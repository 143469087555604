import { create } from "zustand"

interface TriggerMobileWebModal {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  onTrigger: () => void
}

export const isStandalone = (): boolean => {
  let displayMode = "browser"
  const mqStandAlone = "(display-mode: standalone)"

  if ((navigator as any).standalone || window.matchMedia(mqStandAlone).matches) {
    displayMode = "standalone"
  }

  return displayMode === "standalone"
}

export const useTriggerMobileWebModalStore = create<TriggerMobileWebModal>(set => ({
  isOpen: false,
  setIsOpen: isOpen => set({ isOpen }),
  onTrigger: () =>
    set(() => {
      let isOpen = false

      if (isStandalone()) {
        isOpen = false
      } else {
        isOpen = true
      }

      return { isOpen }
    }),
}))
