import { EntityType, ExploreSDStyleDetail, SDStyleDetail } from "@/api/sdk"
import IconButton from "@/components/IconButton"

import StyleReaction from "@/components/Tools/Style/StyleItem/StyleReaction"
import { OpenNewTabIcon, ShareIcon8, ThreeDotHorizontalIcon } from "@/components/shared/icons"
import { useToast } from "@/hooks"
import useCustomRouter from "@/hooks/useCustomRouter"
import { googleAnalytics } from "@/lib/gtag"
import useModalStore from "@/lib/store"
import { useAuth } from "@/providers/authContext"
import {
  useAddStyleToLibraryMutation,
  useDuplicateStyleMutation,
  useRemoveStyleFromLibraryMutation,
} from "@/queries/tools/style/useUpdateStyleMutation"
import { useRecipeImageStore } from "@/stores"
import { useState } from "react"
import { SIMPLE_GENERATE_RECIPE_ID } from "../Gallery/GalleryHeader/GalleryGeneratedImage"
import Popover from "@/components/Popover"

interface StyleActionButtonsProps {
  style:
    | (ExploreSDStyleDetail & {
        capabilities: {
          canUpdate?: boolean
          canShare?: boolean
        }
      })
    | SDStyleDetail
  typeComment: EntityType
}

const StyleActionButtons = ({ style, typeComment }: StyleActionButtonsProps) => {
  const { user, handleSignIn } = useAuth()
  const [isOpen, setIsOpen] = useState(false)

  const isDisabled = user?.uid !== style.creator?.uid

  const setSharingData = useModalStore(state => state.setSharingData)
  const setRecipeImage = useRecipeImageStore(state => state.setRecipeImage)

  const toast = useToast()

  const router = useCustomRouter()

  const handleNewTab = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()

    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        action: "Open in new tab",
        style_id: style.id,
        style_name: style.name ?? "",
      },
    })

    setTimeout(() => {
      router.openNewTab(`${window.location.href}?redirect=${router.asPath}`)
    }, 150)
  }

  const { mutateAsync: mutateAddStyle, isPending: isAdding } = useAddStyleToLibraryMutation({
    onSuccess: () => {
      if (typeComment === EntityType.STYLE) {
        toast({
          status: "success",
          title: "Style added",
          message: ["Style has been added to your library"],
        })
      }
    },
  })
  const { mutate: mutateRemoveStyle, isPending: isRemoving } = useRemoveStyleFromLibraryMutation({
    onSuccess: () => {
      toast({
        status: "success",
        title: "Style removed",
        message: ["Style has been removed from your library"],
      })
    },
  })

  const { mutate: duplicateStyle, isPending: isDuplicating } = useDuplicateStyleMutation({
    onSuccess: data => {
      setTimeout(() => {
        router.openNewTab(`/workspace/tools/styles/${data.id}`)
      }, 1)
    },
    onError: error => {
      toast({
        status: "error",
        title: "Error",
        message: ["Failed to duplicate style"],
      })
    },
  })

  const isMutating = isAdding || isRemoving

  const addStyle = async () => {
    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        action: style.capabilities?.canAddToLibrary ? "Add to My Styles" : "Remove from My Styles",
        style_id: style.id,
        style_name: style.name ?? "",
      },
    })

    // const isSignedIn = await handleSignIn()

    // if (!isSignedIn) return

    if (style?.capabilities?.canAddToLibrary) {
      mutateAddStyle({
        styleId: style.id,
        userUid: user?.uid,
        capabilities: {
          canUpdate: style.capabilities?.canUpdate,
          canShare: style.capabilities?.canShare,
        },
      })
    }

    if (style?.capabilities?.canRemoveFromLibrary) {
      mutateRemoveStyle({
        styleId: style.id,
        userUid: user?.uid,
        capabilities: {
          canUpdate: style.capabilities?.canUpdate,
          canShare: style.capabilities?.canShare,
        },
      })
    }
  }

  const actionItems = [
    {
      title: "Share",
      icon: <ShareIcon8 className="text-atherGray-300" width={16} height={16} />,
      onClick: () => {
        googleAnalytics.handleCategoryEvent({
          action: "click",
          params: {
            action: "Share Style",
            style_id: style.id,
            style_name: style.name ?? "",
          },
        })

        setSharingData({
          type: "style",
          id: style.id,
          name: style.name ?? "",
          creator: style.creator,
          link: `${window.location.origin}/explore/styles/${style.id}`,
          url: style.thumbnailUrl ?? "",
        })
      },
      visible: !style.deletedAt,
    },
    {
      title: "Open in new tab",
      icon: <OpenNewTabIcon className="text-atherGray-300" width={16} height={16} />,
      onClick: handleNewTab,
      visible: true,
    },
  ]

  return (
    <div className="flex items-center w-full">
      <div className="flex text-sm space-x-2 flex-1">
        <StyleReaction
          className="text-atherGray-300"
          style={{
            ...style,
            favoritedByUser: style.favoritedByUser ?? false,
          }}
        />

        <Popover
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          align="start"
          trigger={
            <IconButton
              title="More"
              className="flex items-center py-1 px-2 h-full text-atherGray-300 bg-transparent"
              colorScheme="secondary"
            >
              <ThreeDotHorizontalIcon />
            </IconButton>
          }
        >
          <div className="py-2 w-full bg-atherGray-850 border border-atherGray-800 text-whiteAlpha-900 overflow-hidden rounded-2xl p-0 text-sm shadow-md">
            {actionItems
              .filter(item => item.visible)
              .map((item, index) => (
                <IconButton
                  key={index}
                  colorScheme="secondary"
                  className="flex justify-start w-full rounded-none p-2 bg-transparent"
                  title={item.title}
                  onClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    item.onClick(e)
                    setIsOpen(false)
                  }}
                >
                  <div className="w-4 h-4">{item.icon}</div>
                  <span className="ml-2">{item.title}</span>
                </IconButton>
              ))}
          </div>
        </Popover>
      </div>
      <div className="flex items-center space-x-2">
        {!style.isDraft && !style.deletedAt && (!!style.discoverableAt || style.capabilities?.canUpdate) && (
          <IconButton
            isLoading={isDuplicating || isMutating}
            onClick={async () => {
              const isSignIn = await handleSignIn()

              if (!isSignIn) return

              googleAnalytics.handleCategoryEvent({
                action: "click",
                params: {
                  action: typeComment === EntityType.EXPLORE_STYLE ? "Try this style" : "Duplicate",
                  style_id: style.id,
                  style_name: style.name ?? "",
                },
              })

              if (typeComment === EntityType.EXPLORE_STYLE) {
                if (style.capabilities?.canRemoveFromLibrary) {
                  setRecipeImage({
                    id: SIMPLE_GENERATE_RECIPE_ID,
                    isModal: false,
                    params: {
                      style: style.id,
                    },
                  })

                  setTimeout(() => {
                    router.push(`/workspace/tools/recipes/image-generator-simple`)
                  }, 50)

                  return
                }

                await mutateAddStyle(
                  {
                    styleId: style.id,
                    userUid: user?.uid,
                  },
                  {
                    onSuccess: () => {
                      setRecipeImage({
                        id: SIMPLE_GENERATE_RECIPE_ID,
                        isModal: false,
                        params: {
                          style: style.id,
                        },
                      })
                      setTimeout(() => {
                        router.push(`/workspace/tools/recipes/image-generator-simple`)
                      }, 50)
                    },
                  },
                )

                return
              }

              duplicateStyle({
                styleId: style.id,
              })
            }}
          >
            {typeComment === EntityType.EXPLORE_STYLE ? "Try this style" : "Duplicate"}
          </IconButton>
        )}
        {isDisabled && typeComment === EntityType.STYLE && (
          <div>
            <IconButton isLoading={isMutating} onClick={() => addStyle()}>
              {style.capabilities?.canAddToLibrary || !user ? "Add to My Styles" : "Remove from My Styles"}
            </IconButton>
          </div>
        )}
      </div>
    </div>
  )
}

export default StyleActionButtons
