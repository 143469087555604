import React, { Fragment, useMemo } from "react"
import classNames from "classnames"
import { useExpandViewImage } from "@/stores"
import { useAuth } from "@/providers/authContext"
import ImageItem from "@/components/Explore/Gallery/ImageItem"
import { RecipeTaskStatus, RecipeTaskWithFullImages } from "@/api/sdk"
import ImageLoadingItem from "@/components/Workspace/Workflows/ImageLoadingItem"
import { cdnPublicFolderUrl } from "@/constants"

interface ResultImagesProps {
  workflow: RecipeTaskWithFullImages
  fullDataImages: RecipeTaskWithFullImages["fullDataImages"]
  hiddenSelect?: boolean
  href?: string
  refetchHistory: () => void
  containerWidth?: number
  children?: React.ReactNode
  isDisabledInView?: boolean
}

const ResultImages = ({
  workflow,
  fullDataImages = [],
  children,
  containerWidth,
  hiddenSelect,
  isDisabledInView,
  refetchHistory,
  href,
}: ResultImagesProps) => {
  const setExpandViewImage = useExpandViewImage(state => state.setExpandViewImage)
  const { user } = useAuth()

  const isExpand = useMemo(() => user?.uid !== workflow.creator?.uid, [user?.uid, workflow.creator?.uid])

  return (
    <Fragment>
      <div
        className={classNames("grid gap-2 w-full relative", {
          "grid-cols-[repeat(auto-fill,minmax(12rem,_1fr))]": fullDataImages.length > 0,
          hidden: workflow.outputType?.some(type => type === "text") && fullDataImages.length === 0,
        })}
      >
        {fullDataImages.length > 0 ? (
          workflow.status !== RecipeTaskStatus.FAILED &&
          fullDataImages?.map((img, idx) => (
            <div key={img.id ?? idx} className={classNames("w-full")}>
              {workflow.status === RecipeTaskStatus.QUEUED || workflow.status === RecipeTaskStatus.RUNNING ? (
                <ImageLoadingItem
                  className="rounded-2xl"
                  image={img}
                  height={fullDataImages.length > 1 ? "12rem" : "12rem"}
                />
              ) : (
                <ImageItem
                  hiddenReaction
                  hiddenMenu
                  hiddenSelectPrompt
                  hiddenTypeImage
                  isDisabledInView={isDisabledInView}
                  refetchImages={refetchHistory}
                  containerWidth={containerWidth}
                  data={img}
                  onSelect={
                    isExpand || hiddenSelect
                      ? () => {
                          setExpandViewImage({
                            id: img.id,
                            url: img.url,
                            name: img.name ?? img.id,
                          })
                        }
                      : undefined
                  }
                />
              )}
            </div>
          ))
        ) : (
          <div className="h-[15rem] flex items-center justify-center bg-atherGray-950 rounded-2xl">
            <img src={`${cdnPublicFolderUrl}/not-found-image.png`} alt="" className="max-h-[15rem]" />
          </div>
        )}
        {children}
      </div>
    </Fragment>
  )
}

export default ResultImages
