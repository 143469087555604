import { EntityType } from "@/api/sdk"
import { FeedbackRating } from "@/api/sdk"
import IconButton from "@/components/IconButton"
import { CloseIcon, CommentIcon, ThumbUpIcon } from "@/components/shared/icons"
import { useDebounce, useToast } from "@/hooks"
import { googleAnalytics } from "@/lib/gtag"
import { cn } from "@/lib/utils"
import { useAuth } from "@/providers/authContext"
import { useFeedbackMutation } from "@/queries"
import { useFeedbackTaskStore } from "@/stores"
import classNames from "classnames"
import { AnimatePresence, motion } from "framer-motion"
import React, { useEffect, useState } from "react"

interface FeedbackPopupProps {
  className?: string
  entityId: string
  entityType: EntityType
  onSuccess?: () => void
  onClose?: () => void
  isModal?: boolean
  debounceIsOpen?: boolean
}

const FeedbackPopup = ({
  className,
  entityId,
  entityType,
  onSuccess,
  isModal,
  debounceIsOpen,
  onClose,
}: FeedbackPopupProps) => {
  const toast = useToast()
  const { user } = useAuth()
  const [isOpen, setIsOpen] = useState(isModal)
  const [isSuccess, setIsSuccess] = useState(false)
  const isOpenDebounce = useDebounce(debounceIsOpen, 3500)
  const { feedbackTasks, setFeedbackTasks } = useFeedbackTaskStore()

  const { mutate: mutateFeedBack, isPending: isMutatingFeedback } = useFeedbackMutation({
    onSuccess: () => {
      if (isModal && !onClose) {
        toast({
          status: "success",
          title: "Thank you for your feedback!",
          message: ["We're improving to make your experience better."],
        })
      } else {
        setIsSuccess(true)
      }

      onSuccess?.()
    },
  })

  const [values, setValues] = useState<{
    rating: FeedbackRating | null
    comment: string
  }>({
    rating: null,
    comment: "",
  })

  const handleSubmit = () => {
    if (!values.rating) {
      toast({
        status: "error",
        title: "Please select a rating",
      })
      return
    }

    googleAnalytics.event({
      action: "click",
      category: "feedback",
      label: `rating_${values.rating}_${entityType}`,
      params: {
        entity_id: entityId,
        entity_type: entityType,
        rating: values.rating,
        user_id: user?.uid ?? "",
      },
    })

    mutateFeedBack({
      entityId,
      entityType,
      feedback: values.comment,
      rating: values.rating,
      userUid: user?.uid,
    })
  }

  useEffect(() => {
    const idsString = localStorage.getItem("feedback-popup") || ""

    const ids = idsString.split(",")

    if (!ids.includes(entityId)) {
      setIsOpen(true)
    }
  }, [entityId])

  const handleClose = () => {
    if (onClose) {
      onClose()
      return
    }

    const idsString = localStorage.getItem("feedback-popup") || ""

    const ids = idsString.split(",")

    const newIds = [...ids, entityId]

    const newIdsString = newIds.join(",")

    localStorage.setItem("feedback-popup", newIdsString)

    setFeedbackTasks([...(feedbackTasks ?? []), entityType])

    setIsOpen(false)
    setIsSuccess(false)
  }

  const renderBody = () => {
    if (isSuccess && (!isModal || onClose)) {
      return (
        <p className="text-atherGray-300">
          Thanks for your feedback! <br />
          We&apos;re improving to make your experience better.
        </p>
      )
    }

    return (
      <div>
        <div className="mb-4 text-atherGray-300 mr-8">
          <p>
            Rate our generative{" "}
            {entityType === EntityType.RECIPE_TASK
              ? "task"
              : entityType === EntityType.SD_WORKFLOW
                ? "comfyUI"
                : entityType === EntityType.WORKFLOW
                  ? "macro"
                  : "image"}
            !
          </p>
        </div>

        <div className="space-x-2 flex items-center mb-4">
          <IconButton
            onClick={() => setValues({ ...values, rating: FeedbackRating.Good })}
            className={classNames("w-full text-xs border border-atherGray-700 rounded-xl", {
              "bg-atherPurple-500 hover:bg-atherPurple-500": values.rating === FeedbackRating.Good,
            })}
            colorScheme="transparent"
          >
            <ThumbUpIcon />
            Good
          </IconButton>
          <IconButton
            onClick={() => setValues({ ...values, rating: FeedbackRating.NotGood })}
            className={classNames("w-full text-xs border border-atherGray-700 rounded-xl", {
              "bg-red-500 hover:bg-red-500": values.rating === FeedbackRating.NotGood,
            })}
            colorScheme="transparent"
          >
            <ThumbUpIcon className="rotate-180" />
            Not Good
          </IconButton>
        </div>
        <hr className="border-atherGray-700 mb-4" />
        <div className="flex items-center space-x-1 mb-2">
          <CommentIcon className="text-atherGray-300" />
          <p className="text-atherGray-300">What do you like to improve?</p>
        </div>
        <div className="p-2 bg-atherGray-700 rounded-2xl">
          <textarea
            onChange={e => setValues({ ...values, comment: e.target.value })}
            placeholder="Leave your thoughts"
            className="w-full h-[6rem] resize-none bg-transparent"
          />
        </div>
        <div className="flex justify-end mt-4">
          <IconButton isLoading={isMutatingFeedback} onClick={() => handleSubmit()}>
            Submit
          </IconButton>
        </div>
      </div>
    )
  }

  return (
    <AnimatePresence mode="wait">
      {isOpen && isOpenDebounce && (
        <motion.div
          key={isSuccess ? "success" : "feedback"}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className={cn("bg-atherGray-800 rounded-2xl p-4 text-sm w-[17.5rem] relative", className)}
        >
          {(!isModal || onClose) && (
            <button
              type="button"
              className={
                "text-whiteAlpha-900 z-100 rounded-md p-1 hover:bg-atherGray-700 active:bg-atherGray-900 absolute top-2 right-2"
              }
              onClick={handleClose}
            >
              <CloseIcon height={16} width={16} />
            </button>
          )}
          {renderBody()}
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default FeedbackPopup
