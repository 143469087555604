import { RecentModelItem as RecentModelItemType } from "@/api/sdk"
import { memo } from "react"
import RecentAccordion from "../RecentAccordion"
import useCustomRouter from "@/hooks/useCustomRouter"
import { useAuth } from "@/providers/authContext"
import ModelItem from "@/components/Tools/Model/ModelItem"

interface RecentModelItemProps {
  name: string
  recent: RecentModelItemType[]
  containerWidth: number
  layout: "grid" | "list" 
}

const RecentModelItem = ({ name, containerWidth, recent, layout }: RecentModelItemProps) => {
  const router = useCustomRouter()
  const { user } = useAuth()

  if (recent.length === 0) return null

  return (
    <RecentAccordion name={name} layout={layout}>
      {recent.map(item => {
        return (
          <div key={item.id} className={layout === "grid" ? "h-[16rem]" : ""}>
            <ModelItem
              accessedAt={item.accessedAt}
              layout={layout}
              model={item.model}
            />
          </div>
        )
      })}
    </RecentAccordion>
  )
}

export default memo(RecentModelItem)
