import { cn } from "@/utils/cn"
import React, { forwardRef } from "react"

interface GridBreakpointColsContainerProps {
  children: React.ReactNode
  className?: string
}

const GridBreakpointColsContainer = forwardRef<HTMLDivElement, GridBreakpointColsContainerProps>(
  ({ children, className }, ref) => {
    return (
      <div ref={ref} className={cn("w-full gap-4 grid grid-cols-[repeat(auto-fill,minmax(18rem,1fr))]", className)}>
        {children}
      </div>
    )
  },
)

export default GridBreakpointColsContainer
