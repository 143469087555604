import client from "@/api/client"
import { actionMiddleware } from "@/queries/middlewares/actionMiddleware"
import { createMutation } from "react-query-kit"
import { useGetRecipesInfiniteQuery } from "./getRecipeQueries"
import { InfiniteData } from "@tanstack/react-query"
import { GetRecipesResult } from "@/api/sdk"

export type RecoverRecipeMutationVariables = {
  ids: string[]
}

export const useRecoverRecipeMutation = createMutation({
  mutationFn: async ({ ids }: RecoverRecipeMutationVariables) => {
    return client.api
      .recipeControllerRecoverRecipes({
        recipeIds: ids,
      })
      .then(res => res.data)
  },
  use: [
    actionMiddleware({
      onSuccess: ({ queryClient, variables: { ids } }) => {
        const infKey = useGetRecipesInfiniteQuery.getKey()
        const infQueriesDataEntries = queryClient.getQueriesData<InfiniteData<GetRecipesResult, number>>({
          queryKey: infKey,
        })

        infQueriesDataEntries?.forEach(([key, data]) => {
          if (!data) return

          const updatedData = {
            ...data,
            pages: data.pages.map(page => {
              const updatedPage = {
                ...page,
                recipes: page.recipes.filter(style => {
                  return !ids.includes(style.id)
                }),
              }
              updatedPage.total = updatedPage.recipes.length
              return updatedPage
            }),
          }
          queryClient.setQueryData(key, updatedData)
        })
      },
    }),
  ],
})
