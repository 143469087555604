import client from "@/api/client"
import { PinnedItemType, PinnedItems, RecentWildcardItem, SearchWildcardResponse } from "@/api/sdk"
import { InfiniteData } from "@tanstack/react-query"
import { createMutation } from "react-query-kit"
import { actionMiddleware } from "@/queries/middlewares/actionMiddleware"
import { useWorkspacePinInfiniteQuery, useWorkspaceWildcardsInfiniteQuery } from "@/queries/workspace"
import { useRecentWildcardsInfiniteQuery } from "@/components/Workspace/RecentV2/RecentWildcards"

interface DeleteStyleMutationVariables {
  ids: string[]
  deleteAt: string | null
}

export const useDeleteWildcardMutation = createMutation({
  mutationFn: async ({ ids, deleteAt }: DeleteStyleMutationVariables) => {
    if (deleteAt) {
      return client.api.wildcardControllerFullyDeleteWildcards({
        wildcardIds: ids,
      })
    }

    return client.api
      .wildcardControllerSoftDeleteWildcards({
        wildcardIds: ids,
      })
      .then(res => res.data)
  },
  use: [
    actionMiddleware({
      onSuccess: ({ queryClient, variables: { ids } }) => {
        // Update wildcard list
        const wildcardListKey = useWorkspaceWildcardsInfiniteQuery.getKey()
        const wildcardListQueriesDataEntries = queryClient.getQueriesData<InfiniteData<SearchWildcardResponse, number>>(
          {
            queryKey: wildcardListKey,
          },
        )

        wildcardListQueriesDataEntries?.forEach(([key, data]) => {
          if (!data) return

          const updatedData = {
            ...data,
            pages: data.pages.map(page => {
              const updatedPage = {
                ...page,
                wildcards: page.wildcards.filter(style => {
                  return !ids.includes(style.id)
                }),
              }
              updatedPage.total = updatedPage.wildcards.length
              return updatedPage
            }),
          }
          queryClient.setQueryData(key, updatedData)
        })

        // Update wildcard recent list
        const wildcardRecentKey = useRecentWildcardsInfiniteQuery.getKey()

        const wildcardsRecentListQueriesDataEntries = queryClient.getQueriesData<
          InfiniteData<RecentWildcardItem[], number>
        >({
          queryKey: wildcardRecentKey,
        })

        if (wildcardsRecentListQueriesDataEntries) {
          wildcardsRecentListQueriesDataEntries.forEach(([key, wildcardsRecentListData]) => {
            if (wildcardsRecentListData) {
              const updatedData = {
                ...wildcardsRecentListData,
                pages: wildcardsRecentListData.pages.map(page => {
                  return [...page.filter(recent => !ids.includes(recent.wildcard.id))]
                }),
              }

              queryClient.setQueryData(key, updatedData)
            }
          })
        }

        // Update wildcard pinned list
        const wildcardPinnedListKey = useWorkspacePinInfiniteQuery.getKey({
          type: PinnedItemType.WILDCARD,
        })

        const wildcardPinnedListQueriesDataEntries = queryClient.getQueriesData<InfiniteData<PinnedItems[], number>>({
          queryKey: wildcardPinnedListKey,
        })

        if (wildcardPinnedListQueriesDataEntries) {
          wildcardPinnedListQueriesDataEntries.forEach(([key, wildcardPinnedListData]) => {
            if (wildcardPinnedListData) {
              const updatedData = {
                ...wildcardPinnedListData,
                pages: wildcardPinnedListData.pages.map(page => {
                  return [...page.filter(item => !ids.includes(item.wildcard?.id ?? ""))]
                }),
              }

              queryClient.setQueryData(key, updatedData)
            }
          })
        }
      },
    }),
  ],
})
