import client from "@/api/client"
import {
  SearchModelTrainingDto,
  SearchModelTrainingResults,
  SDModelTrainingItem,
  SDModelTrainingFilterMode,
} from "@/api/sdk"
import { createAuthenticatedInfiniteQuery } from "@/queries/createAuthenticatedQuery"

export const useWorkspaceModelTrainingInfiniteQuery = createAuthenticatedInfiniteQuery<
  SearchModelTrainingResults,
  SearchModelTrainingDto,
  Error,
  SDModelTrainingItem
>({
  primaryKey: "sdModelTraining-infinity",
  queryFn: ({ queryKey: [_primaryKey, variables], pageParam = 0 }) =>
    client.api
      .sdModelTrainingControllerSearch({
        mode: variables.mode ?? SDModelTrainingFilterMode.Owned,
        skip: pageParam * (variables.take ?? 20),
        take: variables.take ?? 20,
        searchTerm: variables.searchTerm,
        ids: variables.ids,
        statuses: variables.statuses,
        sortBy: variables.sortBy,
        baseModels: variables.baseModels?.length ? variables.baseModels : undefined,
      })
      .then(res => res.data),
  flattenFn: data => data.data,
  getTotalFn: data => data.total,
  getNextPageParam: (lastPage, pages) => (lastPage && lastPage?.data.length < 20 ? undefined : pages.length),
  initialPageParam: 0,
  refetchOnWindowFocus: false,
})
