import { PinnedItemType, RecipeFilterMode, RecipeItem } from "@/api/sdk"
import IconButton from "@/components/IconButton"
import {
  PinIcon,
  ThreeDotHorizontalIcon,
  OpenNewTabIcon,
  UnPinIcon,
  DeleteIcon,
  EditIcon,
  AddSquareIcon,
  RemoveSquareIcon,
  RefreshIcon,
  ShareIcon3,
  ShareIcon8,
} from "@/components/shared/icons"
import { cn } from "@/utils/cn"
import classNames from "classnames"
import React, { Fragment, useState } from "react"
import FolderActionRow from "../FolderActions/FolderActionRow"
import Popover from "@/components/Popover"
import { usePinMutation } from "@/queries"
import { useToast } from "@/hooks"
import { useDeleteItemStore, useRecoverItemStore, useUpdateRecipeStore } from "@/stores"
import { googleAnalytics } from "@/lib/gtag"
import { useAuth } from "@/providers/authContext"
import useModalStore from "@/lib/store"
import { useAddRecipesMutation, useRemoveRecipesMutation } from "@/queries/workspace/recipe"
import useCustomRouter from "@/hooks/useCustomRouter"

interface ActionButtonsRecipeProps {
  recipe: RecipeItem
  menuClassName?: string
  layout?: "grid" | "list"
}

const ActionButtonsRecipe = ({ recipe, layout = "grid", menuClassName }: ActionButtonsRecipeProps) => {
  const { pinned, id, categories } = recipe
  const { user } = useAuth()
  const [isOpen, setIsOpen] = useState(false)
  const toast = useToast()
  const { openNewTab } = useCustomRouter()
  const setDeletingItem = useDeleteItemStore(state => state.setDeletingItem)
  const setUpdateRecipe = useUpdateRecipeStore(state => state.setIsOpen)
  const setSharingData = useModalStore(state => state.setSharingData)
  const setRecoverItem = useRecoverItemStore(state => state.setRecoverItem)

  const isDisabled = user?.uid !== recipe.creator?.uid

  const { mutate: mutatePin, isPending: isPendingPin } = usePinMutation({
    onSuccess: () => {
      toast({
        status: "success",
        title: pinned ? "Recipe unpinned" : "Recipe pinned",
      })
    },
  })

  const handlePin = () => {
    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        action: pinned ? "Unpin Recipe" : "Pin Recipe",
        recipe_id: recipe.id,
        recipe_name: recipe.name,
      },
    })

    mutatePin({
      ids: [id],
      type: PinnedItemType.RECIPE,
      isPinned: !pinned,
      folderId: categories.find(i => i.id)?.id?.toString() ?? "",
    })
  }

  const handleNewTab = () => {
    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        action: "Open Recipe in New Tab",
        recipe_id: recipe.id,
        recipe_name: recipe.name,
      },
    })

    setTimeout(() => {
      openNewTab(`/workspace/tools/recipes/${recipe.id}`)
    }, 150)
  }

  const handleDelete = () => {
    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        action: "Delete Recipe",
        recipe_id: recipe.id,
        recipe_name: recipe.name,
      },
    })

    setDeletingItem({
      type: "recipe",
      id: recipe.id,
      name: recipe.name,
      deletedAt: recipe.deletedAt,
    })
  }

  const handleEdit = () => {
    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        action: "Edit Recipe",
        recipe_id: recipe.id,
        recipe_name: recipe.name,
      },
    })

    setUpdateRecipe({
      recipeId: recipe.id,
      data: recipe,
    })
  }

  const handleShare = () => {
    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        action: "Share Recipe",
        recipe_id: recipe.id,
        recipe_name: recipe.name,
      },
    })

    setSharingData({
      id: recipe.id,
      name: recipe.name ?? recipe.id,
      type: "recipe",
      workspaceId: recipe.workspace?.id,
      creator: {
        ...recipe.creator,
        uid: recipe.creator?.uid ?? "",
      },
      link: `${window.location.origin}/workspace/tools/recipes/${recipe.id}`,
    })
  }

  const handleRecovery = () => {
    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        action: "Recover Recipe",
        recipe_id: recipe.id,
        recipe_name: recipe.name,
      },
    })

    setRecoverItem({
      ids: [recipe.id],
      deletedAt: recipe.deletedAt ?? null,
      type: "recipe",
    })
  }

  const { mutate: addToLibrary, isPending: isAdding } = useAddRecipesMutation()
  const { mutate: removeFromLibrary, isPending: isRemoving } = useRemoveRecipesMutation()
  const router = useCustomRouter()

  const view = router.query.tab as string

  const isMutating = isAdding || isRemoving

  const actions = [
    {
      label: "Edit",
      icon: <EditIcon width={16} height={16} />,
      onClick: handleEdit,
      visible: !recipe.deletedAt && !recipe.isPublic && !isDisabled,
      enabled: recipe.capabilities?.canUpdate && !recipe.deletedAt,
    },
    {
      label: "Share",
      icon: <ShareIcon8 width={16} height={16} />,
      onClick: handleShare,
      visible: !recipe.deletedAt && !recipe.isPublic,
      enabled: recipe.capabilities?.canShare,
    },
    {
      label: "Add to My Recipes",
      icon: <AddSquareIcon width={16} height={16} />,
      onClick: () => addToLibrary({ ids: [recipe.id], userUid: user?.uid }),
      visible: recipe.capabilities?.canAddToLibrary && !recipe.deletedAt && !recipe.isPublic,
      enabled: !isMutating,
      isLoading: isAdding,
    },
    {
      label: "Remove from My Recipes",
      icon: <RemoveSquareIcon width={16} height={16} />,
      onClick: () =>
        removeFromLibrary({
          ids: [recipe.id],
          userUid: user?.uid,
          view:
            view === "shared-with-me"
              ? RecipeFilterMode.Shared
              : view === "my-recipes"
                ? RecipeFilterMode.Owned
                : RecipeFilterMode.Public,
        }),
      visible: recipe.capabilities?.canRemoveFromLibrary && !recipe.deletedAt && !recipe.isPublic,
      enabled: !isMutating,
      isLoading: isRemoving,
    },
    {
      label: recipe.pinned ? "Unpin" : "Pin",
      icon: recipe.pinned ? <UnPinIcon width={16} height={16} /> : <PinIcon width={16} height={16} />,
      onClick: handlePin,
      visible: !recipe.deletedAt,
      enabled: true,
    },
    {
      label: "Open in new tab",
      icon: <OpenNewTabIcon width={16} height={16} />,
      onClick: handleNewTab,
      visible: !recipe.deletedAt,
      enabled: true,
    },
    {
      label: "Recover",
      icon: <RefreshIcon className="rotate-180" width={16} height={16} />,
      onClick: handleRecovery,
      enabled: recipe.capabilities?.canRecover,
      visible: !!recipe.deletedAt,
    },
    {
      label: "Delete",
      icon: <DeleteIcon className="text-red-500" width={16} height={16} />,
      onClick: handleDelete,
      visible: !recipe.isPublic,
      enabled: recipe.capabilities?.canDelete,
    },
  ]

  return (
    <Fragment>
      <div
        className={cn(
          "transition-opacity duration-300",
          {
            "opacity-100 lg:opacity-100": isOpen,
            "absolute top-1.5 right-1.5 opacity-100 lg:opacity-0 group-hover:opacity-100": layout === "grid",
          },
          menuClassName,
        )}
      >
        <Popover
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          trigger={
            <IconButton
              onClick={e => {
                e.stopPropagation()
              }}
              colorScheme="secondary"
              className={classNames("bg-blackAlpha-700 px-2 min-h-[1.5rem]", {
                "bg-transparent hover:bg-transparent": menuClassName?.includes("bg-transparent"),
              })}
            >
              <ThreeDotHorizontalIcon width={14} height={14} />
            </IconButton>
          }
          align="end"
        >
          <div
            className={
              "text-sm w-[12.5rem] text-atherGray-300 bg-atherGray-900 border border-atherGray-850 p-2 rounded-2xl space-y-1"
            }
          >
            {actions
              .filter(a => a.visible)
              .map(action => (
                <FolderActionRow
                  key={action.label}
                  iconLeft={action.icon}
                  onClick={e => {
                    e.stopPropagation()
                    action.onClick()
                    setIsOpen(false)
                  }}
                  isDisabled={!action.enabled}
                >
                  {action.label}
                </FolderActionRow>
              ))}
          </div>
        </Popover>
      </div>
    </Fragment>
  )
}

export default ActionButtonsRecipe
