import { WildcardDetail } from "@/api/sdk"
import UserCreated from "@/components/Explore/Gallery/UserCreated"
import IconButton from "@/components/IconButton"
import WildcardItemList from "@/components/Workspace/RecentV2/RecentWildcards/WildcardItemList"
import { PinIcon, TickIcon } from "@/components/shared/icons"
import { googleAnalytics } from "@/lib/gtag"
import { RoutedDialogLink } from "@/providers/RoutedDialogProvider"
import { useAuth } from "@/providers/authContext"
import { cn } from "@/utils/cn"
import useCustomRouter from "@/hooks/useCustomRouter"
import { memo, useMemo, useRef } from "react"
import WildcardActionButtons from "./WildcardActionButtons"

export interface WildcardItemProps {
  wildcard: WildcardDetail
  onClick?: () => void
  isChecked?: boolean
  onCheck?: () => void
  selectionMode?: boolean
  layout?: "grid" | "list"
  className?: string
  wildcards?: WildcardDetail[]
  accessedAt?: string
  onRef?: (ref: HTMLDivElement | null) => void
  filters?: Record<string, any>
}

const WildcardItem = ({
  wildcard,
  layout = "grid",
  className,
  wildcards,
  onRef,
  filters,
  isChecked,
  onCheck,
  selectionMode,
  onClick,
  accessedAt,
}: WildcardItemProps) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const router = useCustomRouter()
  const { user } = useAuth()
  const isMe = useMemo(() => user?.uid === wildcard.creator?.uid, [user, wildcard.creator])

  return (
    <div
      key={wildcard.id}
      data-id={wildcard.id}
      className={cn("group cursor-pointer relative", className)}
      ref={onRef || containerRef}
    >
      <RoutedDialogLink
        name="wildcardDetailWorkspace"
        state={{
          wildcardId: wildcard.id,
          wildcards,
          filters: {
            ...filters,
            historyPath: router.asPath,
          },
        }}
        onClick={
          selectionMode || onClick
            ? () => {
                if (selectionMode) {
                  onCheck?.()

                  return
                }
                onClick?.()
              }
            : undefined
        }
      >
        {layout === "grid" ? (
          <div className="flex-1 flex-col flex h-full bg-atherGray-850 p-4 rounded-2xl">
            <div className="">
              <div className="flex items-center overflow-hidden mb-2">
                <p className="flex-1 truncate font-semibold break-all mr-2 text-sm">{wildcard.name ?? wildcard.id}</p>
                {selectionMode ? (
                  <IconButton
                    onClick={e => {
                      e.stopPropagation()
                      e.preventDefault()
                      googleAnalytics.event({
                        action: "click",
                        category: "wildcard_item",
                        label: "wildcard_checked",
                        params: {
                          wildcard_id: wildcard.id,
                          wildcard_name: wildcard.name ?? wildcard.id,
                        },
                      })
                      onCheck?.()
                    }}
                    className={cn("shadow-sm shadow-blackAlpha-500 bg-atherGray-700 p-0 w-7 h-7 min-h-0 rounded-full", {
                      "text-white bg-atherPurple-500 hover:bg-atherPurple-400": isChecked,
                      "text-atherGray-300 hover:bg-atherGray-600": !isChecked,
                    })}
                  >
                    <TickIcon />
                  </IconButton>
                ) : (
                  <div className="flex items-center space-x-1">
                    {wildcard.pinned !== undefined && wildcard.pinned && (
                      <div>
                        <PinIcon width={16} height={16} />
                      </div>
                    )}
                    <WildcardActionButtons wildcard={wildcard} />
                  </div>
                )}
              </div>
              <div className="flex flex-wrap gap-1 mb-4">
                {wildcard.wildcards.slice(0, 2).map((wildcard, idx) => (
                  <span
                    className="border border-atherGray-700 rounded-md py-0.5 px-1 text-sm break-all truncate max-w-[4.5rem]"
                    key={idx}
                  >
                    {wildcard}
                  </span>
                ))}
                {wildcard.wildcards.length > 2 && (
                  <span className="border border-atherGray-700 text-atherGray-500 rounded-md py-0.5 px-1 text-sm">
                    +{wildcard.wildcards.length - 2} more
                  </span>
                )}
              </div>
              <UserCreated
                className="text-atherGray-300"
                size="xs"
                isMe={isMe}
                name={wildcard.creator?.name ?? "GAIA"}
                picture={wildcard.creator?.picture}
              />
            </div>
          </div>
        ) : (
          <WildcardItemList wildcard={wildcard} accessedAt={accessedAt} />
        )}
      </RoutedDialogLink>
    </div>
  )
}

export default memo(WildcardItem)
