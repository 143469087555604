import { ContentModerationRating, ImageSimpleDetail } from "@/api/sdk"
import ImageActionButtons from "@/components/Explore/Gallery/ImageItem/ImageActionButtons"
import ImageWebpComponent from "@/components/ImageWebpComponent"

import Skeleton from "@/components/Skeleton"
import { useAuth } from "@/providers/authContext"
import { googleAnalytics } from "@/lib/gtag"
import format from "date-fns/format"
import useCustomRouter from "@/hooks/useCustomRouter"
import React, { useState } from "react"
import { useInView } from "react-intersection-observer"
import FolderIcon from "../../Folder/FolderIcon"
import UserCreated from "@/components/Explore/Gallery/UserCreated"
import classNames from "classnames"

interface ImageItemListProps {
  image: ImageSimpleDetail
  accessedAt?: string
  onClick?: () => void
  refetchImages?: () => void
  parentFolderId?: string
}

const ImageItemList = ({ image, accessedAt, onClick, refetchImages, parentFolderId }: ImageItemListProps) => {
  const [imageLoaded, setImageLoaded] = useState(false)
  const { user } = useAuth()
  const router = useCustomRouter()
  const { ref, inView } = useInView({
    threshold: 0,
    rootMargin: "800px 0px 800px 0px",
  })
  const [isShow, setIsShow] = useState(
    image.moderationRating === ContentModerationRating.HardcoreNsfw ||
      image.moderationRating === ContentModerationRating.SemiNsfw,
  )

  const handleClick = () => {
    googleAnalytics.event({
      action: "click",
      category: "image_item",
      label: `view_image_detail`,
      params: {
        image_id: image.id,
        image_name: image.name,
        pathname: window.location.pathname,
      },
    })

    if (onClick) {
      onClick()

      return
    }
  }

  return (
    <div className="w-full flex items-center text-sm text-atherPrimary-100 bg-atherGray-900 rounded-lg" ref={ref}>
      <div className="flex-1 md:flex-none md:w-[30%] py-2 pr-0 pl-2 md:pr-2 cursor-pointer" onClick={handleClick}>
        <div className="flex items-center overflow-hidden justify-center">
          <Skeleton
            className="mr-2  h-[3rem] w-[3rem] flex items-center overflow-hidden justify-center bg-atherGray-900"
            isLoaded={imageLoaded && (!image.blurHash || !isShow)}
            blurHash={image.blurHash ?? undefined}
          >
            {inView && (
              <ImageWebpComponent
                src={image.url}
                alt={image.name}
                className={classNames("block max-h-full object-contain", {
                  "blur-sm": !image.blurHash && isShow,
                })}
                onLoad={() => setImageLoaded(true)}
              />
            )}
          </Skeleton>
          <div className="overflow-hidden flex flex-col flex-1">
            <p className="font-semibold flex-1 line-clamp-1">{image.name ?? image.id}</p>
            <div className="block md:hidden truncate text-atherGray-500">
              Opened - {accessedAt ? format(new Date(accessedAt), "HH:mm dd/MM/yyyy") : ""}
            </div>
          </div>
        </div>
      </div>
      <div className="hidden md:block md:w-[15%] p-2 whitespace-nowrap truncate">Image</div>
      <div className="hidden md:block md:w-[15%] p-2 whitespace-nowrap truncate">
        {accessedAt ? format(new Date(accessedAt), "HH:mm dd/MM/yyyy") : ""}
      </div>
      <div className="hidden md:block md:w-[15%] p-2">
        <UserCreated
          size="sm"
          picture={image.owner?.picture}
          name={image.owner?.name}
          isMe={user?.uid === image.owner?.uid}
        />
      </div>
      <div
        className="hidden md:block md:w-[20%] p-2 overflow-hidden cursor-pointer"
        onClick={e => {
          e.stopPropagation()
          e.preventDefault()
          if (!image.folder) return

          router.push(
            `/workspace/${user?.uid === image.owner?.uid ? "folders" : "shared-with-me"}?folderId=${image.folder.id}`,
            undefined,
            { shallow: true },
          )
        }}
      >
        {image.folder && (
          <div className="flex items-center overflow-hidden">
            <div className="mr-2">
              <FolderIcon width={24} height={24} draggable={false} />
            </div>
            <p className="flex-1 truncate">{image.folder?.name}</p>
          </div>
        )}
      </div>
      <div className="md:w-[5%] pr-2 md:p-2 flex justify-end">
        <ImageActionButtons
          layout={"list"}
          parentFolderId={parentFolderId}
          className="justify-end"
          menuClassName="opacity-100 md:opacity-100 bg-transparent relative top-auto right-auto"
          image={image as ImageSimpleDetail}
        />
      </div>
    </div>
  )
}

export default ImageItemList
