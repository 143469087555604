import { cdnPublicFolderUrl } from "@/constants"

const NotFoundItems = ({ title = "No items found" }: { title?: string }) => {
  return (
    <div className="flex flex-1 w-full justify-center items-center">
      <div className="flex flex-col items-center">
        <img
          src={`${cdnPublicFolderUrl}/images/folders/empty-folder.png`}
          alt="No folders"
          loading="lazy"
          width={300}
          height={200}
        />
        <span className="text-atherGray-300 font-normal mt-4">{title}</span>
      </div>
    </div>
  )
}

export default NotFoundItems
