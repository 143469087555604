import { ContentModerationRating, SDModelItem, SDModelStatus } from "@/api/sdk"
import UserCreated from "@/components/Explore/Gallery/UserCreated"
import IconButton from "@/components/IconButton"
import ImageWebpComponent from "@/components/ImageWebpComponent"
import { FlowSquareIcon, ModelIcon, PinIcon, TickIcon } from "@/components/shared/icons"
import { cn } from "@/lib/utils"
import classNames from "classnames"
import { useInView } from "react-intersection-observer"
import ModelActionButtons from "./ModelActionButtons"
import { useState } from "react"
import NsfwBlurImage from "@/components/NsfwBlurImage"
import AspectRatio from "@/components/AspectRatio"
import { modelVariants } from "../CreateModelModal"
import { RoutedDialogLink } from "@/providers/RoutedDialogProvider"
import useCustomRouter from "@/hooks/useCustomRouter"
import ModelItemList from "@/components/Workspace/RecentV2/RecentModels/ModelItemList"

interface ModelItemProps {
  model: SDModelItem & { pinned?: boolean }
  accessedAt?: string
  selectionMode?: boolean
  isChecked?: boolean
  hiddenMenu?: boolean
  onClick?: () => void
  onCheck?: () => void
  layout?: "grid" | "list"
  onRef?: (ref: HTMLDivElement | null) => void
  filters?: Record<string, any>
  href?: string
  showPinned?: boolean
  typeRedirectRouter?: "push" | "replace"
}

export const ModelItem = ({
  model,
  onClick,
  selectionMode,
  layout = "grid",
  onRef,
  isChecked,
  onCheck,
  hiddenMenu,
  accessedAt,
  filters,
  href,
  showPinned = true,
  typeRedirectRouter,
}: ModelItemProps) => {
  const router = useCustomRouter()
  const { thumbnailUrl, name, description, thumbnailModerationRating, status, variant } = model
  const [isShow, setIsShow] = useState(
    thumbnailModerationRating === ContentModerationRating.HardcoreNsfw ||
      thumbnailModerationRating === ContentModerationRating.SemiNsfw,
  )

  const { ref, inView } = useInView({
    threshold: 0,
    rootMargin: "800px 0px 800px 0px",
  })

  const getStatusBadgeColor = (status: SDModelStatus) => {
    switch (status) {
      case SDModelStatus.PROCESSING:
        return "bg-atherPurple-500"
      case SDModelStatus.DRAFT:
      case SDModelStatus.QUEUED:
        return "bg-atherGray-500"
      default:
        return "bg-atherOrange-500"
    }
  }

  return (
    <div
      ref={r => {
        ref(r)
        onRef?.(r)
      }}
      className={"group cursor-pointer shrink relative model-item"}
    >
      <RoutedDialogLink
        name="modelDetailWorkspace"
        type={typeRedirectRouter}
        state={{
          modelId: model.id,
          filters: {
            historyPath: router.asPath,
            name: "modelDetailWorkspace",
          },
        }}
        onClick={
          selectionMode || onClick
            ? () => {
                if (selectionMode) {
                  onCheck?.()

                  return
                }
                onClick?.()
              }
            : undefined
        }
      >
        {layout === "grid" ? (
          <div className="w-full h-full">
            <div className="relative group overflow-hidden rounded-2xl transform-gpu bg-atherGray-900 shadow-sm shadow-whiteAlpha-200">
              {selectionMode && (
                <div
                  className={classNames(
                    "absolute w-full h-full top-0 left-0 border-[2px] pointer-events-none rounded-2xl",
                    {
                      "border-atherPurple-500": isChecked,
                      "border-[transparent]": !isChecked,
                      "z-0": !selectionMode,
                      "z-[1]": selectionMode,
                    },
                  )}
                />
              )}

              <AspectRatio
                ratio={197 / 96}
                className={"relative select-none overflow-hidden flex items-center justify-center"}
              >
                {inView &&
                  (thumbnailUrl ? (
                    <ImageWebpComponent
                      src={thumbnailUrl}
                      alt={name}
                      className={classNames("h-full w-full rounded-xl object-cover", {
                        "blur-2xl": isShow,
                      })}
                    />
                  ) : (
                    <div className="flex h-full w-full items-center justify-center">
                      <FlowSquareIcon className="text-atherGray-800" />
                    </div>
                  ))}
              </AspectRatio>

              {inView &&
                isShow &&
                (thumbnailModerationRating === ContentModerationRating.HardcoreNsfw ||
                  thumbnailModerationRating === ContentModerationRating.SemiNsfw) && (
                  <NsfwBlurImage
                    isShow={isShow}
                    moderationRating={thumbnailModerationRating}
                    onToggleShow={setIsShow}
                    gaEvent={{
                      tab_name: "sd_models",
                      params: {
                        action: "Toggle Image NSFW",
                        model_id: model.id,
                        model_name: model.name ?? "",
                      },
                    }}
                  />
                )}

              {inView && (
                <>
                  <div
                    className={classNames(
                      "absolute bottom-0 left-0 transition-all pointer-events-none hidden lg:block group-hover:bg-blackAlpha-400 group-hover:h-full group-hover:w-full",
                      {
                        "h-full w-full bg-blackAlpha-600": selectionMode,
                      },
                    )}
                  />
                  {/* Add status badge if status is not completed */}
                  {status && status !== SDModelStatus.COMPLETED && (
                    <div className="absolute top-2 left-2 z-[1]">
                      <span
                        className={`flex font-semibold ${getStatusBadgeColor(status)} px-1.5 py-0.5 rounded-lg text-[0.65rem] text-white uppercase`}
                      >
                        {status === SDModelStatus.PROCESSING ? "TRAINING" : status}
                      </span>
                    </div>
                  )}
                  {status === SDModelStatus.COMPLETED && (
                    <div className="absolute top-2 left-2 z-[1]">
                      <span className="flex items-center font-semibold bg-atherPurple-500 px-1.5 py-0.5 rounded-lg text-[0.65rem] text-white uppercase">
                        <ModelIcon className="w-3 h-3 mr-1" />
                        {modelVariants.find(v => v.id === variant)?.name ?? "Checkpoint"}
                      </span>
                    </div>
                  )}
                  {/* {!hiddenMenu && model.discoverableAt && (
                  <div className="absolute flex items-center top-2 left-0 z-[1]">
                    {model.discoverableAt && (
                      <span className="font-semibold bg-atherPurple-500 px-1.5 py-0.5 rounded-r-lg text-[0.65rem]">
                        E
                      </span>
                    )}
                    {!isShow &&
                      inView &&
                      (thumbnailModerationRating === ContentModerationRating.HardcoreNsfw ||
                        thumbnailModerationRating === ContentModerationRating.SemiNsfw) && (
                        <NsfwBlurImage
                          buttonCloseClassName="relative top-auto left-auto ml-1 px-1 py-0.5 min-h-5"
                          isShow={isShow}
                          size={14}
                          moderationRating={thumbnailModerationRating}
                          onToggleShow={setIsShow}
                          gaEvent={{
                            tab_name: "sd_models",
                            params: {
                              action: "Toggle Image NSFW",
                              model_id: model.id,
                              model_name: model.name ?? "",
                            },
                          }}
                        />
                      )}
                  </div>
                )} */}
                </>
              )}
            </div>
            <div className="p-2 flex flex-col flex-1 overflow-hidden">
              <div className="flex overflow-hidden mb-1">
                <p className="flex-1 line-clamp-1 font-semibold break-all text-sm">{model.name}</p>
                {showPinned && model.pinned !== undefined && model.pinned && (
                  <div className="p-0.5">
                    <PinIcon width={16} height={16} />
                  </div>
                )}
              </div>
              <p className="text-atherGray-300 text-sm line-clamp-1 mb-2">{description}</p>
              <div className="mt-auto">
                <UserCreated size="xs" name={model.creator?.name} picture={model.creator?.picture} />
              </div>
            </div>

            {!hiddenMenu &&
              (selectionMode ? (
                <IconButton
                  onClick={e => {
                    e.stopPropagation()
                    e.preventDefault()
                    onCheck?.()
                  }}
                  className={cn(
                    "absolute top-2 right-2 shadow-sm shadow-blackAlpha-500 bg-atherGray-700 p-0 w-7 h-7 min-h-0 rounded-full",
                    {
                      "text-white bg-atherPurple-500 hover:bg-atherPurple-400": isChecked,
                      "text-atherGray-300 hover:bg-atherGray-600": !isChecked,
                    },
                  )}
                >
                  <TickIcon />
                </IconButton>
              ) : (
                <ModelActionButtons model={model} layout="grid" />
              ))}
          </div>
        ) : (
          <ModelItemList model={model} accessedAt={accessedAt} hiddenMenu={hiddenMenu} />
        )}
      </RoutedDialogLink>
    </div>
  )
}
