import { IconProps } from "."

export const RefreshIcon = (props: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M16.6747 1.66602V4.27618C16.6747 4.52106 16.3685 4.63192 16.2117 4.44379C14.6858 2.73893 12.4684 1.66602 10.0003 1.66602C5.39795 1.66602 1.66699 5.39698 1.66699 9.99935C1.66699 14.6017 5.39795 18.3327 10.0003 18.3327C14.6027 18.3327 18.3337 14.6017 18.3337 9.99935"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
