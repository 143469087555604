import client from "@/api/client"
import { InviteRoomMembersDto, UpdateRoomInviteDto } from "@/api/sdk"
import { actionMiddleware } from "@/queries/middlewares/actionMiddleware"
import { createMutation } from "react-query-kit"

export const useAddMemberMutation = createMutation({
  mutationFn: async ({ roomId, data }: { roomId: string; data: InviteRoomMembersDto }) => {
    return await client.api.chatRoomControllerInviteRoomMembers(roomId, data).then(res => res.data)
  },
  use: [
    actionMiddleware({
      onSuccess: ({ queryClient, variables }) => {},
    }),
  ],
})

export const useAcceptInviteMutation = createMutation({
  mutationFn: async ({ data, inviteId }: { inviteId: string; data: UpdateRoomInviteDto }) => {
    return await client.api.chatRoomControllerUpdateRoomInvite(inviteId, data).then(res => res.data)
  },
  use: [
    actionMiddleware({
      onSuccess: ({ queryClient, variables }) => {},
    }),
  ],
})
