import { cn } from "@/lib/utils"
import * as RadixTooltip from "@radix-ui/react-tooltip"
import { motion } from "framer-motion"

interface TooltipProps {
  trigger: React.ReactNode
  children?: React.ReactNode
  className?: string
  containerClassName?: string
  align?: "center" | "start" | "end" | undefined
  open?: boolean
  side?: "top" | "right" | "bottom" | "left"
  setOpen?: (open: boolean) => void
  disableHoverableContent?: boolean
}

const Tooltip = ({
  trigger,
  children,
  disableHoverableContent,
  className,
  side,
  align = "center",
  containerClassName,
  open,
  setOpen,
}: TooltipProps) => {
  return (
    <RadixTooltip.Root
      disableHoverableContent={disableHoverableContent}
      open={open}
      onOpenChange={setOpen}
      delayDuration={250}
    >
      <RadixTooltip.TooltipTrigger asChild>{trigger}</RadixTooltip.TooltipTrigger>
      <RadixTooltip.TooltipPortal>
        <RadixTooltip.TooltipContent
          className={cn(
            "z-[999] text-atherGray-0 max-w-[16rem] bg-atherGray-850 border border-atherGray-800 text-sm p-1.5 rounded-lg",
            containerClassName,
          )}
          sideOffset={8}
          side={side}
          align={align}
        >
          <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.9 }}
            transition={{ type: "spring", stiffness: 500, damping: 30, duration: 0.3 }}
            className={cn("text-xs", className)}
          >
            {children}
          </motion.div>
        </RadixTooltip.TooltipContent>
      </RadixTooltip.TooltipPortal>
    </RadixTooltip.Root>
  )
}

export default Tooltip
