import { create } from "zustand"

type ManagementStore = {
  isOpen: boolean
  message: string
}

interface ManagementErrorsStoreType {
  errorState: ManagementStore | null
  setErrorState: (value: ManagementStore | null) => void
}

export const useManagementErrorsStore = create<ManagementErrorsStoreType>(set => ({
  errorState: null,
  setErrorState: errorState => set({ errorState: errorState }),
}))

//"Guest user does not have permission"
// "Your subscription has ended. Please subscribe to access features"
