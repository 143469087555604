import dynamic from "next/dynamic"
import { type FC } from "react"

const LazyProvider = dynamic(async () => (await import("./LazyProviders")).default)

export function withLazyProviders<P>(Component: FC<P>) {
  return (props: P & {}) => (
    <LazyProvider>
      <Component {...props} />
    </LazyProvider>
  )
}
