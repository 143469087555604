import { IconProps } from "."

export const ThumbUpFillIcon = (props: IconProps) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M1.33398 7.99999C1.33398 7.26361 1.93094 6.66666 2.66732 6.66666C3.77189 6.66666 4.66732 7.56209 4.66732 8.66666L4.66732 11.3333C4.66732 12.4379 3.77189 13.3333 2.66732 13.3333C1.93094 13.3333 1.33398 12.7364 1.33398 12L1.33398 7.99999Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.3205 4.87084L10.1429 5.44423C9.99746 5.91407 9.92472 6.14899 9.98065 6.33453C10.0259 6.48463 10.1253 6.61401 10.2606 6.69914C10.428 6.80436 10.6811 6.80436 11.1874 6.80436H11.4567C13.1701 6.80436 14.0268 6.80436 14.4314 7.31153C14.4777 7.3695 14.5188 7.43113 14.5544 7.49578C14.8656 8.06143 14.5117 8.82342 13.804 10.3474C13.1544 11.7459 12.8297 12.4452 12.2267 12.8568C12.1683 12.8966 12.1083 12.9342 12.0468 12.9694C11.412 13.3333 10.6254 13.3333 9.05224 13.3333H8.71103C6.80511 13.3333 5.85215 13.3333 5.26006 12.7596C4.66797 12.186 4.66797 11.2626 4.66797 9.41595V8.76689C4.66797 7.79642 4.66797 7.31119 4.8402 6.86706C5.01242 6.42294 5.3422 6.05776 6.00177 5.32741L8.72938 2.30704C8.79779 2.23129 8.832 2.19341 8.86215 2.16717C9.14366 1.92219 9.57812 1.94976 9.82424 2.22823C9.85061 2.25806 9.87942 2.29994 9.93704 2.38369C10.0272 2.5147 10.0723 2.5802 10.1115 2.6451C10.4632 3.22609 10.5696 3.91624 10.4085 4.57143C10.3905 4.64461 10.3671 4.72006 10.3205 4.87084Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
