import client from "@/api/client"
import { TagWorkflowsDto } from "@/api/sdk"
import { actionMiddleware } from "@/queries/middlewares/actionMiddleware"
import { createMutation } from "react-query-kit"

export interface UseTagsWorkflowMutationVariables extends TagWorkflowsDto {}

export const useTagsWorkflowMutation = createMutation({
  mutationFn: (input: UseTagsWorkflowMutationVariables) => client.api.workflowControllerTagWorkflows(input),
  use: [
    actionMiddleware({
      onSuccess({ queryClient, variables }) {},
    }),
  ],
})

export const useUnTagsWorkflowMutation = createMutation({
  mutationFn: (input: UseTagsWorkflowMutationVariables) => client.api.workflowControllerUntagWorkflows(input),
  use: [
    actionMiddleware({
      onSuccess({ queryClient, variables }) {},
    }),
  ],
})
