import { ContentModerationRating, ExploreImageSimpleDetail, ImageGenerationSource, ImageSimpleDetail } from "@/api/sdk"
import ImageItemList from "@/components/Workspace/RecentV2/RecentImages/ImageItemList"
import { RoutedDialogLink } from "@/providers/RoutedDialogProvider"
import { cn } from "@/utils/cn"
import classNames from "classnames"
import useCustomRouter from "@/hooks/useCustomRouter"
import { memo, useRef } from "react"
import ImageInView from "./ImageInView"
import ImageReaction from "./ImageReaction"

export interface GalleryItemProps {
  data: ImageSimpleDetail | ExploreImageSimpleDetail
  onSelect?: () => void
  containerWidth?: number
  className?: string
  showHoverAttributes?: boolean
  stretch?: boolean
  isDisabledInView?: boolean
  hiddenSelect?: boolean
  hiddenSelectPrompt?: boolean
  hiddenReaction?: boolean
  layout?: "grid" | "list"
  hiddenTypeImage?: boolean
  hiddenMenu?: boolean
  refetchImages?: () => void
  isExplore?: boolean
  accessedAt?: string
  selectionMode?: boolean
  isChecked?: boolean
  onCheck?: () => void
  imgClassName?: string
  parentFolderId?: string
  isUpload?: boolean
  typeRedirectRouter?: "push" | "replace"
  onRef?: (ref: HTMLDivElement | null) => void
  images?: ImageSimpleDetail[]
  fetchNextPage?: () => void
  classNameItem?: string
  filters?: Record<string, any>
}

const ImageItem = ({
  data: image,
  onSelect,
  containerWidth,
  refetchImages,
  isExplore,
  className,
  isDisabledInView,
  hiddenMenu,
  hiddenSelectPrompt,
  images,
  imgClassName,
  classNameItem,
  showHoverAttributes,
  selectionMode,
  isChecked,
  onCheck,
  isUpload,
  hiddenTypeImage,
  onRef,
  filters,
  accessedAt,
  parentFolderId,
  stretch,
  hiddenSelect,
  hiddenReaction,
  layout = "grid",
  typeRedirectRouter,
}: GalleryItemProps) => {
  const galleryItemRef = useRef<HTMLDivElement>(null)
  const router = useCustomRouter()
  const historyPath = router.asPath

  return (
    <div
      key={image.id}
      data-id={image.id}
      className={cn("group shrink relative gallery-item", className)}
      ref={ref => {
        onRef?.(ref) || galleryItemRef.current
      }}
    >
      <RoutedDialogLink
        className={classNames({
          "pointer-events-none": hiddenSelect,
        })}
        name="imageDetailWorkspace"
        type={typeRedirectRouter}
        state={{
          imageId: image.id,
          images,
          filters: {
            historyPath,
            parentFolderId,
            ...filters,
          },
        }}
        onClick={
          selectionMode || onSelect || hiddenSelect
            ? () => {
                if (selectionMode) {
                  onCheck?.()
                  return
                }
              }
            : undefined
        }
      >
        {layout === "grid" ? (
          <ImageInView
            isExplore={isExplore}
            className={classNameItem}
            hiddenSelectPrompt={hiddenSelectPrompt}
            imgClassName={imgClassName}
            parentFolderId={parentFolderId}
            refetchImages={refetchImages}
            isUpload={isUpload}
            hiddenSelect={hiddenSelect}
            containerWidth={containerWidth}
            selectionMode={selectionMode}
            isChecked={isChecked}
            onCheck={onCheck}
            hiddenTypeImage={hiddenTypeImage}
            onClick={onSelect}
            hiddenMenu={hiddenMenu}
            isDisabledInView={isDisabledInView}
            showHoverAttributes={showHoverAttributes}
            image={{
              ...image,
              generationSource: ImageGenerationSource.None,
            }}
            hiddenReaction={hiddenReaction}
            stretch={stretch}
          />
        ) : (
          <ImageItemList
            parentFolderId={parentFolderId}
            image={image as ImageSimpleDetail}
            refetchImages={refetchImages}
            onClick={onSelect}
            accessedAt={accessedAt}
          />
        )}
      </RoutedDialogLink>
      {layout === "grid" && (!hiddenReaction || !hiddenSelectPrompt) && !selectionMode && (
        <ImageReaction
          refetchImages={refetchImages}
          showHoverAttributes={showHoverAttributes}
          hiddenSelectPrompt={hiddenSelectPrompt}
          hiddenReaction={hiddenReaction}
          hiddenName={true}
          className="md:p-2"
          parentFolderId={parentFolderId}
          image={image}
        />
      )}
    </div>
  )
}

export default memo(ImageItem)
