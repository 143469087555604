import { IconProps } from "."

interface InfoIconProps extends IconProps {
  border?: string
  strokeColor?: string
}

export const InfoIcon = ({ border = "currentColor", strokeColor = "#C8C4B5", ...props }: InfoIconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none" {...props}>
      <path
        d="M11.332 6C11.332 3.23858 9.09346 1 6.33203 1C3.57061 1 1.33203 3.23858 1.33203 6C1.33203 8.76142 3.57061 11 6.33203 11C9.09346 11 11.332 8.76142 11.332 6Z"
        fill="currentColor"
        stroke={border}
      />
      <path
        d="M6.45312 8.5V6C6.45312 5.7643 6.45312 5.64645 6.3799 5.57322C6.30668 5.5 6.18883 5.5 5.95312 5.5"
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M6.32803 4H6.33252" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
