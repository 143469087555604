import { LockIcon } from "@/components/shared/icons"

const PrivateProfile = () => {
  return (
    <div className="flex items-center justify-center flex-1 min-h-[600px]">
      <div className="flex flex-col items-center space-y-2">
        <LockIcon width={55} height={55} />
        <p className="font-semibold text-[#EAE4D4]">This profile is private</p>
      </div>
    </div>
  )
}

export default PrivateProfile
