import { ExploreImageSimpleDetail, ImageFolder, ImageRecipeTask, ImageSimpleDetail } from "@/api/sdk"
import classNames from "classnames"

import IconButton from "@/components/IconButton"
import { FavoriteFillIcon, FavoriteIcon } from "@/components/shared/icons/"
import { googleAnalytics } from "@/lib/gtag"
import { useAuth } from "@/providers/authContext"
import { useFavoriteImageMutation } from "@/queries"
import { useSignInStore } from "@/stores"
import { twMerge } from "tailwind-merge"
import ButtonCopyPrompt from "../../ImageDetail/TabImage/MetadataImage/ButtonCopyPrompt"
import UserCreated from "../UserCreated"

interface ImageActionButtonsProps {
  image:
    | (ImageSimpleDetail & { prompt?: string | null })
    | (ExploreImageSimpleDetail & {
        folder?: ImageFolder | null
        deletedAt?: string | null
        recipeTask?: ImageRecipeTask | null
      })
  className?: string
  style?: React.CSSProperties
  showHoverAttributes?: boolean
  hiddenName?: boolean
  hiddenReaction?: boolean
  hiddenSelectPrompt?: boolean
  refetchImages?: () => void
  parentFolderId?: string
}

const ImageReaction = ({
  image,
  refetchImages,
  hiddenName,
  className,
  style,
  hiddenSelectPrompt,
  parentFolderId,
  showHoverAttributes = true,
  hiddenReaction,
}: ImageActionButtonsProps) => {
  const { user, handleSignIn } = useAuth()

  const { mutate: mutateReaction } = useFavoriteImageMutation({
    onSuccess: () => {
      refetchImages?.()
    },
  })

  const handleReaction = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()

    const isSignIn = await handleSignIn()

    if (!isSignIn) return

    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        tab_name: "image",
        action: "Favorite",
        image_id: image.id,
        image_name: image.name,
      },
    })

    mutateReaction({
      id: image.id,
      folderId: parentFolderId ?? image.folder?.id,
      userUid: user?.uid,
    })
  }

  if (image?.deletedAt) return null

  if (!showHoverAttributes)
    return (
      <IconButton
        onClick={e => {
          handleReaction(e)
        }}
        className={twMerge("flex items-center space-x-1 p-1 text-sm bg-transparent", className)}
        colorScheme="secondary"
      >
        {!image.favoritedByUser ? (
          <FavoriteIcon className="text-atherGray-300" width={16} height={16} />
        ) : (
          <FavoriteFillIcon className="text-atherGray-300" width={16} height={16} />
        )}
        <p className="text-atherGray-300">{Math.max(image.metric?.favoriteCount || 0, 0)}</p>
      </IconButton>
    )

  return (
    <div>
      <div
        className={twMerge(
          classNames("flex flex-col absolute bottom-0 items-start min-h-[4rem] left-0 w-full pointer-events-none"),
          className,
        )}
        style={{
          background: "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.7) 100%)",
          ...style,
        }}
      ></div>

      {(!hiddenReaction || !hiddenSelectPrompt) && (
        <div className="absolute bottom-0 md:bottom-2 justify-between left-2 space-x-1 flex items-center w-full">
          <div className="flex items-center space-x-1">
            {!hiddenReaction && (
              <IconButton
                onClick={e => {
                  e.stopPropagation()
                  e.preventDefault()
                  handleReaction(e)
                }}
                className={twMerge("flex items-center p-1 text-sm bg-transparent", className)}
                colorScheme="transparent"
              >
                {!image.favoritedByUser ? (
                  <FavoriteIcon className="text-atherGray-300" width={20} height={20} />
                ) : (
                  <FavoriteFillIcon className="text-atherGray-300" width={20} height={20} />
                )}
                <p className="text-atherGray-300">{Math.max(image.metric?.favoriteCount || 0, 0)}</p>
              </IconButton>
            )}
            {image.prompt && !hiddenSelectPrompt && user && (
              <ButtonCopyPrompt prompt={image.prompt} taskId={image.recipeTask?.id} className="p-1 min-[2rem]" />
            )}
          </div>

          {!hiddenReaction && (
            <div className="px-2 pr-4 hidden lg:flex justify-end z-[1] transition-all opacity-0 lg:group-hover:opacity-100">
              <div className="max-w-[8rem]">
                <UserCreated
                  username={image.owner?.username}
                  size="xs"
                  name={image.owner?.name}
                  picture={image.owner?.picture}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default ImageReaction
