import { ExploreWorkflowItem, RecipeTaskStatus, WorkflowItem as WorkflowItemType } from "@/api/sdk"
import UserCreated from "@/components/Explore/Gallery/UserCreated"
import { PinIcon } from "@/components/shared/icons"
import { cn } from "@/utils/cn"
import { QueryKey } from "@tanstack/react-query"
import { memo, useRef } from "react"
import WorkflowItemList from "../../RecentV2/RecentWorkflow/WorkflowItemList"
import ImageInView from "./ImageInView"

export interface GalleryItemProps {
  data:
    | WorkflowItemType
    | (ExploreWorkflowItem & { discoverableAt?: string; status?: RecipeTaskStatus; pinned?: boolean })
  onSelect?: () => void
  containerWidth?: number
  className?: string
  showHoverAttributes?: boolean
  isDisabledInView?: boolean
  hiddenReaction?: boolean
  href?: string
  hiddenCreator?: boolean
  refetchImages?: () => void
  queryKey?: QueryKey
  isExplore?: boolean
  stretch?: boolean
  layout?: "grid" | "list"
  accessedAt?: string
  hiddenMenu?: boolean
  selectionMode?: boolean
  isChecked?: boolean
  onCheck?: () => void
  onRef?: (ref: HTMLDivElement | null) => void
}

const WorkflowItem = ({
  data: workflow,
  onSelect,
  containerWidth,
  refetchImages,
  className,
  isExplore,
  isDisabledInView,
  hiddenMenu,
  stretch,
  queryKey,
  showHoverAttributes,
  onRef,
  layout = "grid",
  href,
  hiddenCreator,
  accessedAt,
  isChecked,
  onCheck,
  selectionMode,
}: GalleryItemProps) => {
  const galleryItemRef = useRef<HTMLDivElement>(null)

  if (!workflow) return null

  return (
    <div
      key={workflow.id}
      data-id={workflow.id}
      className={cn("group cursor-pointer relative flex flex-col", className)}
      ref={onRef || galleryItemRef}
    >
      {layout === "grid" ? (
        <div className="flex-1 flex-col flex h-full">
          <ImageInView
            stretch={stretch}
            hiddenMenu={hiddenMenu}
            isExplore={isExplore}
            containerWidth={containerWidth}
            onClick={onSelect}
            isChecked={isChecked}
            onCheck={onCheck}
            selectionMode={selectionMode}
            isDisabledInView={isDisabledInView}
            showHoverAttributes={showHoverAttributes}
            workflow={workflow}
            href={href}
            queryKey={queryKey}
          />
          <div className="p-2">
            <div className="flex overflow-hidden">
              <p className="flex-1 line-clamp-1 font-semibold break-all text-sm">{workflow.name}</p>
              {workflow.pinned !== undefined && workflow.pinned && (
                <div className="p-0.5">
                  <PinIcon width={16} height={16} />
                </div>
              )}
            </div>
            {!hiddenCreator && (
              <UserCreated className="mt-1" size="xs" name={workflow.creator.name} picture={workflow.creator.picture} />
            )}
          </div>
        </div>
      ) : (
        <WorkflowItemList
          workflow={workflow}
          href={href}
          onClick={onSelect}
          hiddenMenu={hiddenMenu}
          queryKey={queryKey}
          accessedAt={accessedAt}
        />
      )}
    </div>
  )
}

export default memo(WorkflowItem)
