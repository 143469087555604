import { IconProps } from "."

export const EarthIcon = (props: IconProps) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle
        cx="10"
        cy="10"
        r="10"
        transform="matrix(-1 0 0 1 22 2)"
        stroke="#D5D0C1"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M4.85711 4.85714C5.79184 4.91214 6.27477 5.36999 7.10493 6.24716C8.60437 7.83152 10.1038 7.96372 11.1034 7.4356C12.6029 6.64343 11.3428 5.3603 13.1027 4.66298C14.2497 4.20851 14.4096 2.9718 13.7712 2"
        stroke="#D5D0C1"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M21.2856 13.4287C19.1428 13.4287 18.7626 15.2098 16.9999 14.8573C13.4285 14.143 12.4165 14.9415 12.4165 16.6445C12.4165 18.3476 12.4165 18.3476 11.6738 19.6249C11.1906 20.4557 11.0218 21.2865 11.9848 22.0001"
        stroke="#D5D0C1"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  )
}
