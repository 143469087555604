import { IconProps } from "."

export const LinkIcon2 = (props: IconProps) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M7.9165 12.0834L12.0832 7.91675" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
      <path
        d="M14.0386 12.1746L16.2132 10C17.9289 8.28427 17.9289 5.50252 16.2132 3.7868C14.4975 2.07107 11.7157 2.07107 10 3.7868L7.82538 5.96142M12.1746 14.0386L10 16.2132C8.28427 17.9289 5.50252 17.9289 3.7868 16.2132C2.07107 14.4975 2.07107 11.7157 3.7868 10L5.96142 7.82538"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  )
}
