import { FolderDetail, PinnedItemType } from "@/api/sdk"
import {
  DeleteIcon,
  EditIcon,
  FolderMoveIcon,
  PinIcon,
  RefreshIcon,
  ShareIcon3,
  ShareIcon8,
  UnPinIcon,
} from "@/components/shared/icons"
import { googleAnalytics } from "@/lib/gtag"
import useModalStore from "@/lib/store"
import { useDeleteItemStore, useMoveItemV2Store, useRecoverItemStore, useRenameItemStore } from "@/stores"
import classNames from "classnames"
import FolderActionRow from "../FolderActions/FolderActionRow"
import { usePinMutation } from "@/queries"
import { useToast } from "@/hooks"

interface GalleryFolderMenuProps {
  onClose: () => void
  refetchFolders?: () => void
  folderInfo?: FolderDetail
}

const GalleryFolderMenu = ({ onClose, folderInfo, refetchFolders }: GalleryFolderMenuProps) => {
  const setSharingData = useModalStore(s => s.setSharingData)
  const setRenamingFolder = useRenameItemStore(s => s.setRenamingItem)
  const setDeletingItem = useDeleteItemStore(s => s.setDeletingItem)
  const setMovingItem = useMoveItemV2Store(s => s.setMovingItem)
  const toast = useToast()
  const setRecoverItem = useRecoverItemStore(state => state.setRecoverItem)

  const { mutate: mutatePin } = usePinMutation({
    onSuccess: () => {
      toast({
        status: "success",
        title: folderInfo?.pinned ? "Folder Unpinned" : "Folder Pinned",
      })
    },
  })

  const actions = folderInfo
    ? [
        {
          label: "Rename",
          icon: <EditIcon className="text-atherGray-500 group-hover:text-atherGray-0" width={16} height={16} />,
          handler: () => {
            googleAnalytics.event({
              action: "click",
              category: "menu_folder",
              label: `rename_folder_${folderInfo.id}_${folderInfo.name}`,
              value: 1,
            })

            setRenamingFolder({
              type: "folder",
              id: folderInfo.id,
              name: folderInfo.name,
              parentFolderId: folderInfo.parentFolder?.id ?? null,
            })
          },
          enabled: folderInfo.capabilities?.canUpdate,
          visible: !folderInfo.deletedAt,
        },
        {
          label: "Share",
          icon: <ShareIcon8 className="text-atherGray-500 group-hover:text-atherGray-0" width={16} height={16} />,
          handler: () => {
            googleAnalytics.event({
              action: "click",
              category: "menu_folder",
              label: `share_folder_${folderInfo.id}_${folderInfo.name}`,
              value: 1,
            })
            setSharingData({
              ...folderInfo,
              type: "folder",
              link: `${window.location.origin}/workspace/folders?folderId=${folderInfo?.id}`,
              workspaceId: folderInfo.workspace?.id,
            })
          },
          enabled: folderInfo.capabilities?.canShare,
          visible: !folderInfo.deletedAt,
        },
        {
          label: folderInfo.pinned ? "Unpin" : "Pin",
          icon: folderInfo.pinned ? (
            <UnPinIcon className="text-atherGray-500 group-hover:text-atherGray-0" width={16} height={16} />
          ) : (
            <PinIcon className="text-atherGray-500 group-hover:text-atherGray-0" width={16} height={16} />
          ),
          handler: () => {
            googleAnalytics.event({
              action: "click",
              category: "menu_folder",
              label: `pin_folder_${folderInfo.id}_${folderInfo.name}`,
              value: 1,
            })
            mutatePin({
              ids: [folderInfo.id],
              isPinned: !folderInfo.pinned,
              type: PinnedItemType.FOLDER,
              folderId: folderInfo.parentFolder?.id,
            })
          },
          enabled: folderInfo.capabilities?.canView,
          visible: !folderInfo.deletedAt,
        },
        {
          label: "Move",
          icon: <FolderMoveIcon className="text-atherGray-500 group-hover:text-atherGray-0" width={16} height={16} />,
          handler: () => {
            googleAnalytics.event({
              action: "click",
              category: "menu_folder",
              label: `move_folder_${folderInfo.id}_${folderInfo.name}`,
              value: 1,
            })
            setMovingItem({
              type: "folder",
              parentFolderId: folderInfo.parentFolder?.id,
              folders: [
                {
                  id: folderInfo.id,
                  name: folderInfo.name,
                  parentFolderId: folderInfo.parentFolder!.id,
                },
              ],
            })
          },
          enabled: folderInfo.capabilities?.canShare,
          visible: !folderInfo.deletedAt,
        },
        {
          label: "Recover",
          icon: <RefreshIcon className="rotate-180" width={16} height={16} />,
          handler: () => {
            googleAnalytics.event({
              action: "click",
              category: "menu_folder",
              label: `delete_${folderInfo.id}_${folderInfo.name}`,
              value: 1,
            })
            setRecoverItem({
              ids: [folderInfo.id],
              type: "folder",
              deletedAt: folderInfo.deletedAt ?? null,
              parentFolderId: folderInfo.parentFolder?.id,
            })
          },
          enabled: folderInfo.capabilities?.canRecover,
          visible: !!folderInfo.deletedAt,
        },
        {
          label: "Delete",
          icon: <DeleteIcon className="text-red-500" width={16} height={16} />,
          handler: () => {
            googleAnalytics.event({
              action: "click",
              category: "menu_folder",
              label: `delete_${folderInfo.id}_${folderInfo.name}`,
              value: 1,
            })
            setDeletingItem({
              type: "folder",
              id: folderInfo.id,
              folderId: folderInfo.parentFolder?.id,
              parentFolderId: folderInfo.parentFolder?.id,
              deletedAt: folderInfo.deletedAt ?? null,
              // isRedirect: folderId === folderInfo.parentFolder?.id,
              onClose: refetchFolders ? refetchFolders : undefined,
            })
          },
          enabled: folderInfo.capabilities?.canDelete,
          visible: true,
        },
      ]
    : []

  return (
    <div className={classNames("text-sm w-56 bg-atherGray-900 border border-atherGray-850 p-2 rounded-2xl space-y-1")}>
      {actions
        .filter(a => a.visible)
        .map(action => (
          <FolderActionRow
            key={action.label}
            className=""
            onClick={e => {
              e.stopPropagation()
              action.handler()
              onClose()
            }}
            iconLeft={action.icon}
            isDisabled={!action.enabled}
          >
            {action.label}
          </FolderActionRow>
        ))}
    </div>
  )
}

export default GalleryFolderMenu
