import client from "@/api/client"
import { actionMiddleware } from "@/queries/middlewares/actionMiddleware"
import { createMutation } from "react-query-kit"
import { ChatRoomItem, GetChatRoomsResult } from "@/api/sdk"
import { InfiniteData } from "@tanstack/react-query"
import { useWorkspaceRoomsInfiniteQuery } from "./useWorkspaceRoomsInfiniteQuery"
import { useWorkspaceDetailRoomQuery } from "./useWorkspaceDetailRoomQuery"

export const usePinRoomMutation = createMutation({
  mutationFn: async ({ roomId }: { roomId: string }) => {
    return await client.api.chatRoomControllerPinRoom(roomId).then(res => res.data)
  },
  use: [
    actionMiddleware({
      onSuccess: ({ queryClient, variables }) => {
        const pinnedRoomsKey = useWorkspaceRoomsInfiniteQuery.getKey()

        const pinnedRoomsEntities = queryClient.getQueriesData<InfiniteData<GetChatRoomsResult, number>>({
          queryKey: pinnedRoomsKey,
        })

        pinnedRoomsEntities?.forEach(([key, pinnedRoomsEntitiesListData]) => {
          const updatedPinnedMessageEntitiesListData = {
            ...pinnedRoomsEntitiesListData,
            pages: pinnedRoomsEntitiesListData?.pages.map(page => ({
              ...page,
              rooms: page.rooms.map(room => {
                if (room.id === variables.roomId) {
                  return {
                    ...room,
                    pinned: true,
                  }
                }

                return room
              }),
            })),
          }

          queryClient.setQueryData(key, updatedPinnedMessageEntitiesListData)
        })

        const detailRoomKey = useWorkspaceDetailRoomQuery.getKey({
          roomId: variables.roomId,
        })

        const detailRoomEntity = queryClient.getQueryData<ChatRoomItem>(detailRoomKey)

        if (detailRoomEntity) {
          const updatedDetailRoomEntity = {
            ...detailRoomEntity,
            pinned: true,
          }

          queryClient.setQueryData(detailRoomKey, updatedDetailRoomEntity)
        }

        queryClient.invalidateQueries({ queryKey: detailRoomKey })

        // queryClient.invalidateQueries({ queryKey: pinnedMessagesKey })
      },
    }),
  ],
})

export const useUnPinRoomMutation = createMutation({
  mutationFn: async ({ roomId }: { roomId: string }) => {
    return await client.api.chatRoomControllerUnpinRoom(roomId).then(res => res.data)
  },
  use: [
    actionMiddleware({
      onSuccess: ({ queryClient, variables }) => {
        const pinnedRoomsKey = useWorkspaceRoomsInfiniteQuery.getKey()

        const pinnedRoomsEntities = queryClient.getQueriesData<InfiniteData<GetChatRoomsResult, number>>({
          queryKey: pinnedRoomsKey,
        })

        pinnedRoomsEntities?.forEach(([key, pinnedRoomsEntitiesListData]) => {
          const updatedPinnedMessageEntitiesListData = {
            ...pinnedRoomsEntitiesListData,
            pages: pinnedRoomsEntitiesListData?.pages.map(page => ({
              ...page,
              rooms: page.rooms.map(room => {
                if (room.id === variables.roomId) {
                  return {
                    ...room,
                    pinned: false,
                  }
                }

                return room
              }),
            })),
          }

          queryClient.setQueryData(key, updatedPinnedMessageEntitiesListData)
        })

        const detailRoomKey = useWorkspaceDetailRoomQuery.getKey({
          roomId: variables.roomId,
        })
        const detailRoomEntity = queryClient.getQueryData<ChatRoomItem>(detailRoomKey)

        if (detailRoomEntity) {
          const updatedDetailRoomEntity = {
            ...detailRoomEntity,
            pinned: false,
          }

          queryClient.setQueryData(detailRoomKey, updatedDetailRoomEntity)
        }

        // queryClient.invalidateQueries({ queryKey: pinnedMessagesKey })
      },
    }),
  ],
})
