import { IconProps } from "."

export const FlowIcon = (props: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none" {...props}>
      <path d="M11 12L16 16.5M11 12L6 16.5M11 12V7" stroke="currentColor" strokeWidth="1.5" />
      <path
        d="M17.1915 15.4456C17.7081 15.1485 17.9664 15 18.25 15C18.5336 15 18.7919 15.1485 19.3085 15.4456L19.9415 15.8097C20.4581 16.1068 20.7164 16.2553 20.8582 16.5C21 16.7447 21 17.0418 21 17.6359V18.3641C21 18.9582 21 19.2553 20.8582 19.5C20.7164 19.7447 20.4581 19.8932 19.9415 20.1903L19.3085 20.5544C18.7919 20.8515 18.5336 21 18.25 21C17.9664 21 17.7081 20.8515 17.1915 20.5544L16.5585 20.1903C16.0419 19.8932 15.7836 19.7447 15.6418 19.5C15.5 19.2553 15.5 18.9582 15.5 18.3641V17.6359C15.5 17.0418 15.5 16.7447 15.6418 16.5C15.7836 16.2553 16.0419 16.1068 16.5585 15.8097L17.1915 15.4456Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M2.69152 15.4456C3.2081 15.1485 3.46638 15 3.75 15C4.03362 15 4.2919 15.1485 4.80848 15.4456L5.44152 15.8097C5.9581 16.1068 6.21638 16.2553 6.35819 16.5C6.5 16.7447 6.5 17.0418 6.5 17.6359V18.3641C6.5 18.9582 6.5 19.2553 6.35819 19.5C6.21638 19.7447 5.9581 19.8932 5.44152 20.1903L4.80848 20.5544C4.2919 20.8515 4.03362 21 3.75 21C3.46638 21 3.2081 20.8515 2.69152 20.5544L2.05848 20.1903C1.5419 19.8932 1.28362 19.7447 1.14181 19.5C1 19.2553 1 18.9582 1 18.3641V17.6359C1 17.0418 1 16.7447 1.14181 16.5C1.28362 16.2553 1.5419 16.1068 2.05848 15.8097L2.69152 15.4456Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M9.94152 1.44563C10.4581 1.14854 10.7164 1 11 1C11.2836 1 11.5419 1.14854 12.0585 1.44563L12.6915 1.8097C13.2081 2.10679 13.4664 2.25533 13.6082 2.5C13.75 2.74467 13.75 3.04175 13.75 3.63593V4.36407C13.75 4.95825 13.75 5.25533 13.6082 5.5C13.4664 5.74467 13.2081 5.89321 12.6915 6.1903L12.0585 6.55437C11.5419 6.85146 11.2836 7 11 7C10.7164 7 10.4581 6.85146 9.94152 6.55437L9.30848 6.1903C8.7919 5.89321 8.53362 5.74467 8.39181 5.5C8.25 5.25533 8.25 4.95825 8.25 4.36407V3.63593C8.25 3.04175 8.25 2.74467 8.39181 2.5C8.53362 2.25533 8.7919 2.10679 9.30848 1.8097L9.94152 1.44563Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </svg>
  )
}
