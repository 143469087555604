import { IconProps } from "."

export const StartFaceIcon = (props: IconProps) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M29.3307 15.9974C29.3307 23.3612 23.3612 29.3307 15.9974 29.3307C8.6336 29.3307 2.66406 23.3612 2.66406 15.9974M11.3307 3.50354C12.7832 2.96079 14.3556 2.66406 15.9974 2.66406C17.6392 2.66406 19.2116 2.96079 20.6641 3.50354"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0192 7.24173L10.7231 8.66121C10.8191 8.85881 11.0751 9.04834 11.291 9.08464L12.5669 9.29836C13.3828 9.43547 13.5748 10.0323 12.9869 10.6211L11.995 11.6211C11.827 11.7905 11.735 12.1172 11.787 12.3511L12.071 13.5891C12.2949 14.569 11.779 14.9481 10.9191 14.4359L9.72321 13.7221C9.50724 13.5931 9.15128 13.5931 8.9313 13.7221L7.73543 14.4359C6.87953 14.9481 6.35958 14.565 6.58356 13.5891L6.86753 12.3511C6.91952 12.1172 6.82753 11.7905 6.65955 11.6211L5.66766 10.6211C5.08372 10.0323 5.2717 9.43547 6.08761 9.29836L7.36347 9.08464C7.57545 9.04834 7.83142 8.85881 7.92741 8.66121L8.63133 7.24173C9.01529 6.47151 9.63922 6.47151 10.0192 7.24173Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.3525 7.24173L24.0564 8.66121C24.1524 8.85881 24.4084 9.04834 24.6244 9.08464L25.9002 9.29836C26.7161 9.43547 26.9081 10.0323 26.3202 10.6211L25.3283 11.6211C25.1603 11.7905 25.0683 12.1172 25.1203 12.3511L25.4043 13.5891C25.6283 14.569 25.1123 14.9481 24.2524 14.4359L23.0565 13.7221C22.8406 13.5931 22.4846 13.5931 22.2646 13.7221L21.0688 14.4359C20.2129 14.9481 19.6929 14.565 19.9169 13.5891L20.2009 12.3511C20.2529 12.1172 20.1609 11.7905 19.9929 11.6211L19.001 10.6211C18.4171 10.0323 18.605 9.43547 19.4209 9.29836L20.6968 9.08464C20.9088 9.04834 21.1648 8.85881 21.2607 8.66121L21.9647 7.24173C22.3486 6.47151 22.9726 6.47151 23.3525 7.24173Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6641 20C11.8802 21.6193 13.8165 22.6667 15.9974 22.6667C18.1783 22.6667 20.1146 21.6193 21.3307 20"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
