import { IconProps } from "."

export const EraserIcon = (props: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 20 22" fill="none" {...props}>
      <path
        d="M6.73792 6.78021L2.84693 10.7574C1.67722 11.953 1.09236 12.5508 1.01263 13.2802C0.995788 13.4343 0.995789 13.5899 1.01263 13.744C1.09236 14.4733 1.67722 15.0711 2.84693 16.2668L2.99601 16.4191C3.62049 17.0575 3.93274 17.3766 4.30638 17.5911C4.5236 17.7157 4.75482 17.8134 4.99505 17.882C5.40827 18 5.85149 18 6.73792 18C7.62436 18 8.06758 18 8.4808 17.882C8.72103 17.8134 8.95225 17.7157 9.16947 17.5911C9.54311 17.3766 9.85537 17.0575 10.4798 16.4191L13.3239 13.5121M6.73792 6.78021L10.3199 3.12313C11.7065 1.70754 12.3997 0.999744 13.2627 1C14.1256 1.00026 14.8185 1.70846 16.2042 3.12487L16.9473 3.8845C18.3159 5.28342 19.0002 5.98288 19 6.85008C18.9997 7.71728 18.315 8.41633 16.9456 9.81444L13.3239 13.5121M6.73792 6.78021L13.3239 13.5121"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M8 21L19 21" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
