import { useEffect, useState } from "react"

export const useTempState = <T>(baseValue: T, delay = 3000) => {
  const [state, setState] = useState<T>(baseValue)

  useEffect(() => {
    // auto turn off width selection after 5 seconds
    let timeout: NodeJS.Timeout
    timeout = setTimeout(() => setState(baseValue), delay)

    return () => clearTimeout(timeout)
  }, [state, baseValue, delay])

  return [state, setState] as const
}
