import client from "@/api/client"
import { CreatorProfileItem, FollowerDetail, GetUserFollowingsResult } from "@/api/sdk"
import { useAuth } from "@/providers/authContext"
import { useMutateFollowUser } from "@/queries"
import { createAuthenticatedInfiniteQuery } from "@/queries/createAuthenticatedQuery"
import { enableInfiniteQueryMiddleware } from "@/queries/middlewares/enableQueryMiddleware"
import Link from "next/link"
import { useEffect, useRef, useState } from "react"
import InfiniteScroll from "react-infinite-scroller"
import IconButton from "../IconButton"
import Modal from "../Modal"
import TabsList from "../Workspace/FoldersV2/TabsList"
import { googleAnalytics } from "@/lib/gtag"

interface FollowerModalProps {
  isOpen: boolean
  onClose: () => void
  defaultTab: string | null
  dataUserProfile?: CreatorProfileItem
}

const tabs = [
  {
    id: "followers",
    title: "Followers",
  },
  {
    id: "following",
    title: "Following",
  },
]

export const useProfileFollowingInfiniteQuery = createAuthenticatedInfiniteQuery<
  GetUserFollowingsResult,
  { userUid: string },
  Error,
  FollowerDetail
>({
  primaryKey: "infinite-profile-following",
  queryFn: ({ queryKey: [_primaryKey, variables], pageParam = 0 }) =>
    client.api
      .followControllerGetUserFollowingsByUser(variables.userUid, {
        skip: pageParam * 20,
        take: 20,
      })
      .then(res => res.data),
  getNextPageParam: (lastPage, pages) => (lastPage && lastPage.followers.length < 20 ? undefined : pages.length),
  initialPageParam: 0,
  getTotalFn: data => data.total,
  flattenFn: data => data.followers,
  use: [enableInfiniteQueryMiddleware(v => !!v.userUid)],
})

export const useProfileFollowersInfiniteQuery = createAuthenticatedInfiniteQuery<
  GetUserFollowingsResult,
  { userUid: string },
  Error,
  FollowerDetail
>({
  primaryKey: "infinite-profile-followers",
  queryFn: ({ queryKey: [_primaryKey, variables], pageParam = 0 }) =>
    client.api
      .followControllerGetUserFollowersByUser(variables.userUid, {
        skip: pageParam * 20,
        take: 20,
      })
      .then(res => res.data),
  getNextPageParam: (lastPage, pages) => (lastPage && lastPage.followers.length < 20 ? undefined : pages.length),
  initialPageParam: 0,
  getTotalFn: data => data.total,
  flattenFn: data => data.followers,
  use: [enableInfiniteQueryMiddleware(v => !!v.userUid)],
})

const FollowerItem = ({ profile, onClose }) => {
  const { user, handleSignIn } = useAuth()
  const { mutate: mutataFollow, isPending } = useMutateFollowUser()

  return (
    <div>
      <div className="flex items-center">
        <div className="w-8 h-8">
          <img src={profile.picture} alt="" className="rounded-full" />
        </div>
        <div className="ml-2 flex-1">
          <Link
            onClick={() => {
              onClose?.()
            }}
            href={`/profile/${profile.username}`}
          >
            <p className="text-base">{profile.name}</p>
          </Link>
        </div>
        {user?.uid === profile.uid ? null : (
          <IconButton
            isLoading={isPending}
            onClick={async e => {
              e.stopPropagation()

              const isSignIn = await handleSignIn()

              if (!isSignIn) return

              googleAnalytics.handleCategoryEvent({
                action: "click",
                params: {
                  action: profile.isFollowed ? "Unfollow Profile" : "Follow Profile",
                  profile_username: profile.username,
                  profile_id: profile.uid,
                },
              })

              mutataFollow({ data: { followingUid: profile.uid }, userUid: user?.uid ?? "" })
            }}
            className="text-xs"
            colorScheme={profile.isFollowed ? "secondary" : "primary"}
          >
            {profile.isFollowed ? "Unfollow" : "Follow"}
          </IconButton>
        )}
      </div>
    </div>
  )
}

const FollowerModal = ({ isOpen, onClose, defaultTab, dataUserProfile }: FollowerModalProps) => {
  const [tab, setTab] = useState(defaultTab ?? "followers")
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (defaultTab) {
      setTab(defaultTab)
    }
  }, [defaultTab])

  const {
    flattenData: profileFollowing = [],
    fetchNextPage: fetchNextPageFollowing,
    hasNextPage: hasNextPageFollowing,
    isFetching: isFetchingFollowing,
    refetch: refetchFollowing,
    isSuccess: isFollowingSuccess,
  } = useProfileFollowingInfiniteQuery({ variables: { userUid: dataUserProfile?.uid || "" } })

  const {
    flattenData: profileFollowers = [],
    fetchNextPage: fetchNextPageFollowers,
    hasNextPage: hasNextPageFollowers,
    isFetching: isFetchingFollowers,
    refetch: refetchFollowers,
    isSuccess: isFollowersSuccess,
  } = useProfileFollowersInfiniteQuery({ variables: { userUid: dataUserProfile?.uid || "" } })

  const fetchNextPage = tab === "followers" ? fetchNextPageFollowers : fetchNextPageFollowing
  const hasNextPage = tab === "followers" ? hasNextPageFollowers : hasNextPageFollowing
  const isFetching = tab === "followers" ? isFetchingFollowers : isFetchingFollowing
  const flattenData = tab === "followers" ? profileFollowers : profileFollowing

  useEffect(() => {
    if (isFetchingFollowers || isFetchingFollowing) return

    if (isOpen && (isFollowingSuccess || isFollowersSuccess)) {
      // Reset the data when the modal is closed
      refetchFollowing()
      refetchFollowers()
    }
  }, [isOpen])

  const renderBody = () => {
    if (flattenData.length === 0) {
      return (
        <div className="flex items-center flex-1 justify-center p-4 text-atherGray-500">
          No {tab === "followers" ? "followers" : "following"} yet
        </div>
      )
    }

    return (
      <InfiniteScroll
        loadMore={() => fetchNextPage()}
        hasMore={!!hasNextPage && !isFetching}
        useWindow={false}
        getScrollParent={() => ref.current!}
        threshold={800}
        style={{
          display: "flex",
          flexDirection: "column",
          maxWidth: "100%",
          position: "relative",
        }}
        loader={
          <div key="loader" className="flex items-center justify-center py-4">
            Loading...
          </div>
        }
      >
        <div className="flex flex-col space-y-4">
          {flattenData.map((profile, index) => (
            <FollowerItem key={index} profile={profile} onClose={onClose} />
          ))}
        </div>
      </InfiniteScroll>
    )
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} bodyClassName="overflow-hidden" className="max-w-md">
      <TabsList tabsData={tabs} tabQuery={tab} onChange={setTab} />
      <div className="overflow-auto h-[20rem] mt-4 flex flex-col" ref={ref}>
        {renderBody()}
      </div>
    </Modal>
  )
}

export default FollowerModal
