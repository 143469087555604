import { useAuth } from "@/providers/authContext"
import { AnimatePresence, motion } from "framer-motion"
import { twMerge } from "tailwind-merge"
import { useWorkspaceContext } from "@/providers/WorkspaceProvider"
import { useGetUserPlanQuery } from "@/queries"

const convertBytesToGB = (bytes: number) => {
  return bytes / 1024 / 1024 / 1024
}

const ProgressbarImagesStorage = () => {
  const { userInfoQuery } = useAuth()

  //byte to GB
  const currentSize = convertBytesToGB(userInfoQuery?.data?.storageSize ?? 0)
  const totalSize = convertBytesToGB(parseInt(userInfoQuery?.data?.subscription.cloudStorage ?? "0"))
  const progress = (currentSize / totalSize) * 100
  const description = `${currentSize.toFixed(2)} GB / ${totalSize.toFixed(2)} GB`

  return (
    <div className="w-full">
      <p className="mb-1 text-[0.65rem] text-atherGray-500">Images storage</p>
      <AnimatePresence>
        <motion.div
          initial={{ height: 0, opacity: 0 }}
          animate={{ height: "auto", opacity: 1 }}
          exit={{ height: 0, opacity: 0 }}
          className={"overflow-hidden w-full"}
        >
          <div className="relative h-1 overflow-hidden rounded-lg bg-atherGray-800">
            <motion.div
              transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
              className="bg-atherPurple-400"
              style={{
                transition: "width 0.6s ease-in-out",
                width: `${progress}%`,
                height: "100%",
                backgroundSize: "1rem 1rem",
              }}
            />
          </div>
          {description && <p className="mt-1 text-[0.65rem] text-atherGray-500">{description}</p>}
        </motion.div>
      </AnimatePresence>
    </div>
  )
}

const ProgressbarModelStorage = () => {
  const {
    currentWorkspaceQuery: { data: currentWorkspaceDetail },
  } = useWorkspaceContext()

  const { data: userPlan } = useGetUserPlanQuery({
    variables: {
      workspaceId: currentWorkspaceDetail?.id ?? "",
    },
  })

  //byte to GB
  const currentSize = convertBytesToGB(userPlan?.usedModelStorageSize ?? 0)
  const totalSize = convertBytesToGB(userPlan?.currentModelStorageSubscription?.maxModelStorage ?? 0)
  const progress = totalSize ? (currentSize / totalSize) * 100 : 0
  const description = `${currentSize.toFixed(2)} GB / ${totalSize.toFixed(2)} GB`

  return (
    <div className="w-full">
      <p className="mb-1 text-[0.65rem] text-atherGray-500">Model storage</p>
      <AnimatePresence>
        <motion.div
          initial={{ height: 0, opacity: 0 }}
          animate={{ height: "auto", opacity: 1 }}
          exit={{ height: 0, opacity: 0 }}
          className={"overflow-hidden w-full"}
        >
          <div className="relative h-1 overflow-hidden rounded-lg bg-atherGray-800">
            <motion.div
              className="bg-atherOrange-400"
              transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
              style={{
                transition: "width 0.6s ease-in-out",
                width: `${progress}%`,
                height: "100%",
                backgroundSize: "1rem 1rem",
              }}
            />
          </div>
          {description && <p className="mt-1 text-[0.65rem] text-atherGray-500">{description}</p>}
        </motion.div>
      </AnimatePresence>
    </div>
  )
}

const ProgressbarStorage = ({ className }: { className?: string }) => {
  return (
    <div className={twMerge("flex justify-between items-center mt-4", className)}>
      <ProgressbarModelStorage />
      <div className="h-[1rem] w-[1px] mx-2 bg-atherGray-800" />
      <ProgressbarImagesStorage />
    </div>
  )
}

export default ProgressbarStorage
