import { IconProps } from "."

export const ArrowUpRightSharpIcon = (props: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M1.66797 8.00008C1.66797 5.01452 1.66797 3.52174 2.59546 2.59424C3.52296 1.66675 5.01574 1.66675 8.0013 1.66675C10.9869 1.66675 12.4796 1.66675 13.4071 2.59424C14.3346 3.52174 14.3346 5.01452 14.3346 8.00008C14.3346 10.9856 14.3346 12.4784 13.4071 13.4059C12.4796 14.3334 10.9869 14.3334 8.0013 14.3334C5.01574 14.3334 3.52296 14.3334 2.59546 13.4059C1.66797 12.4784 1.66797 10.9856 1.66797 8.00008Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M9.99872 8.5603V6M9.99872 6L7.3571 6.19772M9.99872 6L6 10"
        stroke="#EFE8FD"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
