import { RecipeCategoryItem, RecipeItemTag, UpdateRecipeDto } from "@/api/sdk"
import { create } from "zustand"

type IsOpenType = {
  recipeId: string | null
  data: UpdateRecipeDto & { tags: RecipeItemTag[]; categories: RecipeCategoryItem[]; thumbnail?: string }
  refetch?: () => void
}

interface UpdateRecipeProps {
  isOpen: IsOpenType | null
  setIsOpen: (params: IsOpenType | null) => void
  onClose: () => void
}

export const useUpdateRecipeStore = create<UpdateRecipeProps>(set => ({
  isOpen: null,
  setIsOpen: isOpen =>
    set({
      isOpen,
    }),
  onClose: () => set({ isOpen: null }),
}))
