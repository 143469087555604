import { PromptItem as PromptItemType, PromptSortOptions } from "@/api/sdk"
import IconButton from "@/components/IconButton"
import NotFoundItems from "@/components/Workspace/Pinned/NotFoundItems"
import { PlusIcon } from "@/components/shared/icons"
import { useDebounce, useDragSelection, useToast } from "@/hooks"
import { useAuth } from "@/providers/authContext"
import { useWorkspacePromptsInfiniteQuery } from "@/queries"
import { useWorkspaceSearchHistory } from "@/stores"
import { motion } from "framer-motion"
import useCustomRouter from "@/hooks/useCustomRouter"
import { useEffect, useMemo, useRef, useState } from "react"
import InfiniteScroll from "react-infinite-scroller"
import LoadingLogo from "../../LoadingLogo"
import MultiPromptsSelectBar from "./MultiPromptsSelectBar"
import PromptItem from "./PromptItem"
import PromptsTabActions from "./PromptsTabActions"
import _throttle from "lodash/throttle"
import { useMultiPromptsSelect } from "./useMultiPromptsSelect"
import DetailPromptModal from "./DetailPromptModal"
import WorkspaceContainer from "@/components/Workspace/WorkspaceContainer"
import GridBreakpointColsContainer from "@/components/GridBreakpointColsContainer"
import { googleAnalytics } from "@/lib/gtag"
import ErrorUI from "@/components/ErrorUI"

export const useWorkspacePrompt = (searchTerm: string, sortBy: PromptSortOptions | undefined, enabled?: boolean) => {
  const variables = useMemo(() => {
    return { searchTerm, sortBy }
  }, [searchTerm, sortBy])

  const debounceVariables = useDebounce(variables, 50)

  const { flattenData: prompts = [], ...query } = useWorkspacePromptsInfiniteQuery({
    variables: debounceVariables,
    enabled: enabled === undefined ? true : enabled,
  })

  return {
    prompts,
    ...query,
  }
}

const Prompts = () => {
  const router = useCustomRouter()
  const toast = useToast()

  const searchQuery = (router.query.search as string | undefined) ?? ""
  const [addHistory, removeHistory] = useWorkspaceSearchHistory(s => [s.addHistory, s.removeHistory])
  const { user } = useAuth()

  const [modal, setModal] = useState<"Add" | "Update" | null>(null)

  const [sortBy, setSort] = useState<PromptSortOptions | undefined>(undefined)
  const { prompts, isLoading, isFetching, isSuccess, isError, hasNextPage, fetchNextPage, data } = useWorkspacePrompt(
    searchQuery,
    sortBy,
  )

  const total = data?.pages?.[0]?.total ?? 0

  const { addSelection, clear, selectedItems, selectionMode, toggleSelectionMode, selectAll } = useMultiPromptsSelect()
  const { DragSelection, itemRefs } = useDragSelection(prompts, selectAll, selectionMode)

  const handleToggleSelectionMode = () => {
    toggleSelectionMode()
    googleAnalytics.event({
      action: "click",
      category: "prompts",
      label: "toggle_multi_select",
      params: {
        type: "prompts",
        value: selectionMode ? "false" : "true",
      },
    })
  }

  const handleAddSelection = (data: PromptItemType) => {
    addSelection(data)
    googleAnalytics.event({
      action: "click",
      category: "prompts",
      label: "select_prompt",
      params: {
        prompt_id: data.id,
      },
    })
  }

  const handleConfirmSearch = (search: string) => {
    router.push({ query: { ...router.query, search } }, undefined, { shallow: true })

    if (search) {
      addHistory({
        uid: user?.uid ?? "",
        location: "tools/wildcards",
        text: search,
      })
    }
  }

  const handleRemoveSearchHistory = (text: string) => {
    removeHistory({
      location: "tools/wildcards",
      text,
      uid: user?.uid ?? "",
    })
  }

  const containerRef = useRef<HTMLDivElement>(null)

  const mainContainerRef = useRef<HTMLDivElement>(null)
  const [containerLeft, setContainerLeft] = useState(0)

  useEffect(() => {
    const containerObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        setContainerLeft(entry.target.getBoundingClientRect().x)
      }
    })

    containerObserver.observe(mainContainerRef.current!)

    return () => {
      containerObserver.disconnect()
    }
  }, [])

  const renderBody = () => {
    if (isLoading)
      return (
        <div className="flex items-center justify-center w-full flex-1 text-gray-600">
          <LoadingLogo />
        </div>
      )

    if (isError) return <ErrorUI />

    if (prompts?.length === 0) return <NotFoundItems />

    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.35, delay: 0.35 }}
        className={"relative flex-1"}
      >
        <InfiniteScroll
          loadMore={() => fetchNextPage()}
          hasMore={!!hasNextPage && !isFetching}
          useWindow={true}
          threshold={800}
          style={{
            display: "flex",
            flexDirection: "column",
            maxWidth: "100%",
            position: "relative",
          }}
          loader={
            <div key="loader" className="flex items-center justify-center py-4">
              Loading...
            </div>
          }
        >
          <GridBreakpointColsContainer>
            {prompts?.map(prompt => (
              <PromptItem
                key={prompt.id}
                prompt={prompt}
                onRef={ref => (itemRefs.current[prompt.id] = ref)}
                selectionMode={selectionMode}
                isChecked={selectedItems.some(item => item.id === prompt.id)}
                onCheck={() => {
                  handleAddSelection(prompt)
                }}
              />
            ))}
          </GridBreakpointColsContainer>
        </InfiniteScroll>
      </motion.div>
    )
  }

  return (
    <div className="flex-1 p-4 px-6 flex flex-col relative w-full" ref={mainContainerRef}>
      <WorkspaceContainer className="flex-1">
        <div className="flex w-full justify-between items-center mb-4">
          <h3 className="text-xl font-semibold">Prompts Library</h3>
          <IconButton onClick={() => setModal("Add")} leftIcon={<PlusIcon height={16} width={16} />}>
            Add Prompt
          </IconButton>
        </div>
        <div className="flex-1 flex flex-col w-full">
          <div className="flex-1 mt-2 relative w-full flex flex-col" ref={containerRef}>
            <MultiPromptsSelectBar
              clear={clear}
              selectionMode={selectionMode}
              selectedItems={selectedItems}
              offsetLeft={containerLeft / 2}
            >
              <div className="flex-1 flex-col flex">
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  className="flex items-center py-2 sticky top-0 md:top-[4.25rem] left-0 bg-black z-[2] mb-2"
                >
                  <p className="text-sm text-atherGray-300 mr-4">
                    {total} {total > 1 ? "results" : "result"}
                  </p>
                  <PromptsTabActions
                    sort={sortBy}
                    setSort={setSort}
                    onConfirmSearch={handleConfirmSearch}
                    onRemoveHistory={handleRemoveSearchHistory}
                    selectionMode={selectionMode}
                    toggleSelectionMode={handleToggleSelectionMode}
                  />
                </motion.div>
                {renderBody()}
                <DragSelection />
              </div>
            </MultiPromptsSelectBar>
          </div>
        </div>
      </WorkspaceContainer>

      <DetailPromptModal isOpen={modal === "Add"} onClose={() => setModal(null)} />
    </div>
  )
}

export default Prompts
