import client from "@/api/client"
import { ExploreSDWorkflowItem, GetExploreSDWorkflowsResult, SortOptions } from "@/api/sdk"
import { createAuthenticatedInfiniteQuery } from "../createAuthenticatedQuery"

export interface UseExploreComfyUIInfiniteQueryVariables {
  sortBy?: SortOptions
  searchTerm?: string
  creatorUid?: string
  onlyFavorite?: boolean
  onlyCurrentWorkspace?: boolean
  tagIds?: number[]
  userUid?: string
  ids?: string[]
}

export const useExploreComfyUIInfiniteQuery = createAuthenticatedInfiniteQuery<
  GetExploreSDWorkflowsResult,
  UseExploreComfyUIInfiniteQueryVariables,
  Error,
  ExploreSDWorkflowItem
>({
  auth: false,
  primaryKey: "infinite-explore-comfyui",
  queryFn: ({ queryKey: [_primaryKey, variables], pageParam = 0 }) =>
    client.api
      .exploreSdWorkflowControllerGetExploreSdWorkflows({
        skip: pageParam * 50,
        take: 50,
        sortBy: variables.sortBy,
        ids: variables.ids,
        searchTerm: variables.searchTerm,
        creatorUid: variables.creatorUid,
        onlyFavorite: variables.onlyFavorite,
        tagIds: variables.tagIds,
        onlyCurrentWorkspace: variables.onlyCurrentWorkspace,
      })
      .then(res => res.data),
  getNextPageParam: (lastPage, pages) => (lastPage && lastPage.sdWorkflows.length < 50 ? undefined : pages.length),
  initialPageParam: 0,
  flattenFn: data => data.sdWorkflows,
})
