import { useAuth } from "@/providers/authContext"
import { QueryFunction, QueryKey, useQuery, UseQueryOptions } from "@tanstack/react-query"

export const useAuthenticatedQuery = <TQueryFnData = unknown, TError = unknown, TData = TQueryFnData>(
  queryKey: QueryKey,
  queryFn: QueryFunction<TQueryFnData, QueryKey>,
  options?: Omit<UseQueryOptions<TQueryFnData, TError, TData, QueryKey>, "queryKey" | "queryFn"> | undefined,
) => {
  const { tokenFormat } = useAuth()
  const enabled = options?.enabled ?? true

  const query = useQuery<TQueryFnData, TError, TData, QueryKey>({
    queryKey,
    queryFn,
    ...options,
    enabled: !!tokenFormat && enabled,
  })

  return query
}
