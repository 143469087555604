import { useOutsideClickSimple } from "@/hooks"
import classNames from "classnames"
import { AnimatePresence, motion } from "framer-motion"
import { useRef } from "react"

export interface FolderActionsProps {
  onClose: () => void
  position: { x: number; y: number; isBottom: boolean } | null
  children?: React.ReactNode
}

const ContextMenu = ({ onClose, position, children }: FolderActionsProps) => {
  const popupRef = useRef<HTMLDivElement>(null)

  useOutsideClickSimple(popupRef, onClose)

  return (
    <div ref={popupRef}>
      <AnimatePresence initial={false} mode="sync">
        {!!position && (
          <motion.div
            className={classNames("overflow-hidden z-[50]", {
              "origin-bottom-right": !position.isBottom,
              "origin-top-right": position.isBottom,
            })}
            style={{
              position: "fixed",
              top: position.isBottom ? position.y ?? 0 : undefined,
              bottom: !position.isBottom ? window.innerHeight - (position.y ?? 0) : undefined,
              left: position.x ?? 0,
            }}
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.9 }}
            transition={{ type: "spring", stiffness: 500, damping: 30, duration: 0.3 }}
            key={`${position.x}-${position.y}`}
            onClick={onClose}
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default ContextMenu
