import React, { memo } from "react"
import ImageItem from "@/components/Explore/Gallery/ImageItem"
import RecentAccordion from "../RecentAccordion"
import { QueryKey } from "@tanstack/react-query"
import { RecentImageItem as RecentImageItemType } from "@/api/sdk"

interface RecentImageItemProps {
  name: string
  recent: RecentImageItemType[]
  containerWidth: number
  layout: "grid" | "list"
  queryKey: QueryKey
}

const RecentImageItem = ({ name, containerWidth, recent, layout }: RecentImageItemProps) => {
  if (recent.length === 0) return null
  return (
    <RecentAccordion name={name} layout={layout}>
      {recent?.map(image => {
        return (
          <div key={image.id} className={layout === "grid" ? "h-[12rem]" : ""}>
            <ImageItem
              layout={layout}
              stretch
              hiddenSelectPrompt
              parentFolderId={image.image.id}
              className="h-full"
              accessedAt={image.accessedAt}
              data={image.image}
              containerWidth={containerWidth}
            />
          </div>
        )
      })}
    </RecentAccordion>
  )
}

export default memo(RecentImageItem)
