import client from "@/api/client"
import {
  GetCreatorProfilesResult,
  GetUserFollowersResult,
  GetUserFollowingsResult,
  ToggleFollowUserDto,
} from "@/api/sdk"
import { useProfileFollowersInfiniteQuery, useProfileFollowingInfiniteQuery } from "@/components/Profile/FollowerModal"
import { InfiniteData } from "@tanstack/react-query"
import { createMutation } from "react-query-kit"
import { useExploreProfilesInfiniteQuery } from "./explore"
import { actionMiddleware } from "./middlewares/actionMiddleware"

export const useMutateFollowUser = createMutation({
  mutationFn: ({ data }: { data: ToggleFollowUserDto; userUid: string }) =>
    client.api.followControllerToggleFollowUser(data),

  use: [
    actionMiddleware({
      onSuccess: ({ queryClient, variables: { data, userUid } }) => {
        queryClient.invalidateQueries({ queryKey: ["get-user-profiles"] })

        const listKey = useExploreProfilesInfiniteQuery.getKey()

        const listQueriesDataEntries = queryClient.getQueriesData<InfiniteData<GetCreatorProfilesResult, number>>({
          queryKey: listKey,
        })

        if (listQueriesDataEntries) {
          listQueriesDataEntries.forEach(([key, entry]) => {
            if (entry) {
              const updatedData = {
                ...entry,
                pages: entry.pages.map(page => ({
                  ...page,
                  profiles: page.profiles.map(profile => {
                    if (profile.uid === data.followingUid) {
                      return {
                        ...profile,
                        metric: {
                          ...profile.metric,
                          followerCount: profile.metric!.followerCount + (!profile.isFollowed ? 1 : -1),
                        },
                        isFollowed: !profile.isFollowed,
                      }
                    }
                    return profile
                  }),
                })),
              }

              queryClient.setQueryData(key, updatedData)
            }
          })
        }

        const listFollowingKey = useProfileFollowingInfiniteQuery.getKey()

        const listFollowingQueriesDataEntries = queryClient.getQueriesData<
          InfiniteData<GetUserFollowingsResult, number>
        >({
          queryKey: listFollowingKey,
        })

        if (listFollowingQueriesDataEntries) {
          listFollowingQueriesDataEntries.forEach(([key, entry]) => {
            if (entry) {
              const updatedData = {
                ...entry,
                pages: entry.pages.map(page => ({
                  ...page,
                  followers: page.followers.map(profile => {
                    if (profile.uid === data.followingUid) {
                      return {
                        ...profile,
                        isFollowed: !profile.isFollowed,
                      }
                    }
                    return profile
                  }),
                })),
              }

              queryClient.setQueryData(key, updatedData)
            }
          })
        }

        const listFollowerkey = useProfileFollowersInfiniteQuery.getKey()

        const listFollowerQueriesDataEntries = queryClient.getQueriesData<InfiniteData<GetUserFollowersResult, number>>(
          {
            queryKey: listFollowerkey,
          },
        )

        if (listFollowerQueriesDataEntries) {
          listFollowerQueriesDataEntries.forEach(([key, entry]) => {
            if (entry) {
              const updatedData = {
                ...entry,
                pages: entry.pages.map(page => ({
                  ...page,
                  followers: page.followers.map(profile => {
                    if (profile.uid === data.followingUid) {
                      return {
                        ...profile,
                        isFollowed: !profile.isFollowed,
                      }
                    }
                    return profile
                  }),
                })),
              }

              queryClient.setQueryData(key, updatedData)
            }
          })
        }
      },
    }),
  ],
})
