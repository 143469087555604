import client from "@/api/client"
import { RecipeItem, RoomRecipeType } from "@/api/sdk"
import { createAuthenticatedInfiniteQuery } from "@/queries/createAuthenticatedQuery"

export const useGetRoomRecipesInfiniteQuery = createAuthenticatedInfiniteQuery<
  RecipeItem[],
  {
    roomId: string
    take?: number
    type?: RoomRecipeType
    searchTerm?: string
  },
  Error,
  RecipeItem
>({
  primaryKey: "room-recipes-infinite",
  queryFn: ({ queryKey: [_primaryKey, variables], pageParam = 0 }) =>
    client.api
      .chatRoomControllerGetRoomRecipes(variables.roomId, {
        skip: pageParam * (variables.take ?? 20),
        take: variables.take ?? 20,
        searchTerm: variables.searchTerm ? variables.searchTerm : undefined,
        type: variables.type,
      })
      .then(res => res.data),
  flattenFn: data => data,
  getNextPageParam: (lastPage, pages) => (lastPage && lastPage?.length < 20 ? undefined : pages.length),
  initialPageParam: 0,
  refetchOnWindowFocus: false,
})
