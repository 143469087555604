const ErrorProfile = ({ message }: { message: string }) => {
  return (
    <div className="flex items-center justify-center flex-1 min-h-[600px]">
      <div className="flex flex-col items-center space-y-2">
        <p className="font-semibold text-red-500">{message}</p>
      </div>
    </div>
  )
}

export default ErrorProfile
