import { RecipeItem, SDStyleDetail, SDStyleTag, StyleImage, UpdateSDStyleDto } from "@/api/sdk"
import IconButton from "@/components/IconButton"
import { ChevronDownIcon, ArrowSquareLeftIcon, ArrowLeftRoundIcon, ShareIcon8 } from "@/components/shared/icons"
import { cn } from "@/utils/cn"
import classNames from "classnames"
import { AnimatePresence, motion } from "framer-motion"
import React, { useState } from "react"
import { FormProvider, UseFormReturn } from "react-hook-form"
import { RemoveScroll } from "react-remove-scroll"
import { useScreen } from "@/hooks"
import dynamic from "next/dynamic"
import { CheckableRecipeInput } from "@/components/Workspace/Recipes/RecipeDetail"
import { googleAnalytics } from "@/lib/gtag"
import BackButton from "@/components/BackButton"
import { useBrowserRouter } from "@/providers/BrowserRouterProvider"
import useCustomRouter from "@/hooks/useCustomRouter"
import { getHasClientHistory } from "@/stores/ClientHistoryStore"
import useModalStore from "@/lib/store"
import StyleFormInput from "./StyleFormInput"
import { RecipeCreateType } from "@/utils/task"

const DynamicFormRecipe = dynamic(() => import("@/components/Workspace/Recipes/RecipeDetail/FormDetail"), {
  ssr: false,
})

interface SettingStyleProps {
  isOpenSideBar: boolean
  updateStyle: (data: UpdateSDStyleDto) => void
  setIsOpenSidebar: React.Dispatch<React.SetStateAction<boolean>>
  description: string
  style: SDStyleDetail
  disabledInput: boolean
  tags: SDStyleTag[]
  setDescription: (description: string) => void
  setTags: React.Dispatch<React.SetStateAction<SDStyleTag[]>>
  handleGenerate: (e: React.FormEvent<HTMLFormElement>) => void
  form: UseFormReturn<RecipeCreateType, any, undefined>
  recipeInputs: CheckableRecipeInput[]
  recipe?: RecipeItem
  setRecipeInputs: React.Dispatch<React.SetStateAction<CheckableRecipeInput[]>>
  isCreatingTask: boolean
  images: StyleImage[]
  isDraft: boolean
  onSave?: () => void
}

const SettingStyle = ({
  isOpenSideBar,
  updateStyle,
  description,
  style,
  disabledInput,
  tags,
  setDescription,
  setIsOpenSidebar,
  setTags,
  isDraft,
  handleGenerate,
  onSave,
  form,
  images,
  recipeInputs,
  recipe,
  setRecipeInputs,
  isCreatingTask,
}: SettingStyleProps) => {
  const { width } = useScreen()
  const [isTestingStyle, setTestingStyle] = useState(false)
  const router = useCustomRouter()
  const setSharingData = useModalStore(state => state.setSharingData)

  const { back } = useBrowserRouter()

  const handleBack = () => {
    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        action: "Back",
      },
    })

    const hasHistory = getHasClientHistory()

    if (hasHistory) {
      back()
      return
    }

    if (router.query.shared) {
      router.push("/workspace/tools/styles?tab=shared-with-me")
      return
    }

    if (isDraft || router.query.draft) {
      router.push("/workspace/tools/styles?tab=drafts")
      return
    }

    router.push("/workspace/tools/styles")
  }

  const handleShare = () => {
    if (!style) return

    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        action: "Share Style",
        style_id: style.id,
        style_name: style.name ?? "",
      },
    })

    setSharingData({
      id: style.id,
      name: style.name ?? style.id,
      link: `${window.location.origin}/workspace/tools/styles/${style.id}`,
      type: "style",
      creator: style.creator,
      workspaceId: style.workspace.id,
      url: "",
    })
  }

  return (
    <>
      <motion.div
        className={cn(
          "border-l border-r border-atherGray-800 bg-atherGray-900",
          "fixed top-[0] lg:sticky md:top-[4.5rem] left-0 overflow-hidden flex flex-col z-[24] md:z-[2] pb-[5.15rem] md:pb-0 height-full-screen md:h-[calc((var(--vh,1vh)*100)-4.5rem-1px)]",
          "transition-all duration-300 ease-in-out",
          {
            "w-0": !isOpenSideBar,
            "w-[88vw] md:w-80": isOpenSideBar,
          },
        )}
      >
        <div className="flex flex-col w-[88vw] md:w-80 bg-atherGray-900 overflow-hidden h-full">
          <RemoveScroll
            enabled={width < 768 && isOpenSideBar}
            style={{
              animationDelay: "0s",
              height: "100%",
              overflow: "auto",
              flex: 1,
              display: "flex",
              flexDirection: "column",
            }}
            removeScrollBar
          >
            <div className="flex flex-col p-4 border-b border-atherGray-800">
              <div className="w-full mb-4 flex items-center space-x-2">
                <BackButton
                  className="rounded-lg text-sm py-1.5 px-3 min-h-0"
                  icon={<ArrowLeftRoundIcon className="text-atherGray-300" width={16} height={16} />}
                  label="Back"
                  onClick={() => handleBack()}
                />
                {!isDraft && (
                  <IconButton
                    colorScheme="secondary"
                    className="font-semibold text-xs p-2"
                    onClick={e => {
                      e.stopPropagation()
                      handleShare()
                    }}
                  >
                    <ShareIcon8 width={16} height={16} />
                  </IconButton>
                )}
              </div>
              <StyleFormInput
                description={description}
                setTags={setTags}
                setDescription={setDescription}
                tags={tags}
                onSave={onSave}
                updateStyle={updateStyle}
                images={images}
                style={style}
                disabledInput={disabledInput}
              />
            </div>
            <FormProvider {...form}>
              <form className="flex flex-col" onSubmit={handleGenerate}>
                {style?.capabilities?.canUpdate && (
                  <div className="flex-1 pb-6">
                    <div
                      className="mt-4 mb-2 mx-4 p-[2px] rounded-lg bg-gradient-to-r from-[#eb31a1] to-[#9917d6] cursor-pointer"
                      onClick={() => setTestingStyle(curr => !curr)}
                    >
                      <div className="flex gap-2 items-center rounded-lg p-[calc(.5rem-1px)] bg-atherGray-900">
                        <p className="text-sm font-semibold">Test this style</p>
                        <ChevronDownIcon
                          className={cn("w-3 h-3 text-atherGray-300 ml-auto", {
                            "-rotate-90": !isTestingStyle,
                            "rotate-270": isTestingStyle,
                          })}
                        />
                      </div>
                    </div>

                    <motion.div
                      initial={{ opacity: 0.5 }}
                      animate={
                        isTestingStyle
                          ? { height: "auto", opacity: 1, display: "block" }
                          : { height: 0, opacity: 0, transitionEnd: { display: "none" } }
                      }
                      className="px-4 pt-4"
                    >
                      <DynamicFormRecipe
                        recipeInputs={recipeInputs}
                        setRecipeInputs={setRecipeInputs}
                        form={form}
                        recipe={recipe}
                        hideOutputFolder
                      />
                    </motion.div>
                  </div>
                )}

                {isTestingStyle && (
                  <div className="flex-0 p-4 mt-auto border-t border-atherGray-700">
                    <IconButton
                      type="submit"
                      className="bg-atherPurple-500 hover:bg-atherPurple-500 active:bg-atherPurple-500 w-full px-6 py-2"
                      isLoading={isCreatingTask}
                      disabled={!images.length}
                    >
                      <p className="font-semibold">Test this style</p>
                    </IconButton>
                  </div>
                )}
              </form>
            </FormProvider>
          </RemoveScroll>
        </div>
      </motion.div>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        key={isOpenSideBar ? "open" : "close"}
        transition={{ duration: 0.5, delay: 0.15 }}
        onClick={e => {
          e.stopPropagation()
          setIsOpenSidebar(prev => !prev)
        }}
        className={classNames("fixed flex top-[4.75rem] z-[24] cursor-pointer rounded-md", {
          "rotate-180 left-2": !isOpenSideBar,
          "left-[88vw] md:left-80 lg:left-[18.25rem]": isOpenSideBar,
        })}
      >
        <ArrowSquareLeftIcon width={24} height={24} />
      </motion.div>
      <AnimatePresence mode="wait">
        {isOpenSideBar && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              transition: { duration: 0.2 },
            }}
            className={classNames("fixed lg:hidden inset-0 bg-blackAlpha-800 z-[1]")}
            onClick={() => setIsOpenSidebar(false)}
          />
        )}
      </AnimatePresence>
    </>
  )
}

export default SettingStyle
