import { cn } from "@/utils/cn"
import IconButton, { ColorSchemeButton } from "./IconButton"
import { ArrowLeftRoundIcon } from "./shared/icons/"
import { googleAnalytics } from "@/lib/gtag"

interface BackButtonProps {
  onClick?: () => void
  className?: string
  label?: string
  icon?: React.ReactNode
  sizeIcon?: number
  colorScheme?: ColorSchemeButton
}

const BackButton = ({
  onClick,
  className,
  label = "Back",
  icon,
  sizeIcon = 16,
  colorScheme = "secondary",
}: BackButtonProps) => {
  return (
    <IconButton
      colorScheme={colorScheme}
      leftIcon={icon ?? <ArrowLeftRoundIcon width={sizeIcon} height={sizeIcon} />}
      onClick={() => {
        googleAnalytics.event({
          action: "click",
          category: "back",
          label: "back-button",
          params: {
            pathname: window.location.pathname,
          },
        })
        onClick?.()
      }}
      className={cn("flex items-center z-[1] rounded-full text-sm", className)}
    >
      {label && <p className="hidden md:block">{label}</p>}
    </IconButton>
  )
}

export default BackButton
