import { SDStyleDetail } from "@/api/sdk"
import UserCreated from "@/components/Explore/Gallery/UserCreated"
import StyleItemList from "@/components/Workspace/RecentV2/RecentStyles/StyleItemList"
import { PinIcon } from "@/components/shared/icons"
import { RoutedDialogLink } from "@/providers/RoutedDialogProvider"
import { cn } from "@/utils/cn"
import useCustomRouter from "@/hooks/useCustomRouter"
import { memo, useRef } from "react"
import ImageInView from "./ImageInView"

export interface StyleCardProps {
  style: SDStyleDetail
  hideActions?: boolean
  onClick?: () => void
  containerWidth?: number
  stretch?: boolean
  isChecked?: boolean
  onCheck?: () => void
  selectionMode?: boolean
  isDisabledInView?: boolean
  layout?: "grid" | "list"
  className?: string
  accessedAt?: string
  href?: string
  typeRedirectRouter?: "push" | "replace"
  name?: "styleDetailExplore" | "styleDetailWorkspace"
  onRef?: (ref: HTMLDivElement | null) => void
}

const StyleItem = ({
  style,
  hideActions,
  containerWidth,
  layout = "grid",
  className,
  name = "styleDetailWorkspace",
  stretch,
  onRef,
  isChecked,
  onCheck,
  selectionMode,
  typeRedirectRouter,
  isDisabledInView,
  onClick,
  href,
  accessedAt,
}: StyleCardProps) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const router = useCustomRouter()
  return (
    <div
      key={style.id}
      data-id={style.id}
      className={cn("group cursor-pointer relative", className)}
      ref={onRef || containerRef}
    >
      <RoutedDialogLink
        name={name}
        type={typeRedirectRouter}
        state={{
          styleId: style.id,
          filters: {
            historyPath: router.asPath,
            name,
          },
        }}
        onClick={
          selectionMode || onClick
            ? () => {
                if (selectionMode) {
                  onCheck?.()

                  return
                }
                onClick?.()
              }
            : undefined
        }
      >
        {layout === "grid" ? (
          <div className="flex-1 flex-col flex">
            <ImageInView
              style={style}
              layout={layout}
              stretch={stretch}
              isExplore={name === "styleDetailExplore"}
              hiddenMenu={hideActions}
              containerWidth={containerWidth}
              onClick={onClick}
              isChecked={isChecked}
              onCheck={onCheck}
              selectionMode={selectionMode}
              isDisabledInView={isDisabledInView}
              href={href ?? `/workspace/tools/styles/${style.id}`}
            />
            <div className="p-2">
              <div className="flex overflow-hidden">
                <p className="flex-1 line-clamp-1 font-semibold break-all text-sm">{style.name}</p>
                {style.pinned !== undefined && style.pinned && (
                  <div className="p-0.5">
                    <PinIcon width={16} height={16} />
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <StyleItemList
            style={style}
            href={href ?? `/workspace/tools/styles/${style.id}`}
            onClick={onClick}
            hiddenMenu={hideActions}
            accessedAt={accessedAt}
          />
        )}
      </RoutedDialogLink>
      {style.creator && layout === "grid" && (
        <UserCreated
          size="xs"
          className="px-2"
          username={style.creator.username}
          name={style.creator.name}
          picture={style.creator.picture}
        />
      )}
    </div>
  )
}

export default memo(StyleItem)
