import { IconProps } from "."

export const SwordIcon = (props: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M1.66602 13C1.99059 13.0478 2.30315 13.1966 2.55296 13.4464C2.80277 13.6962 2.95156 14.0088 2.99935 14.3333"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M5.33268 10.668L2.66602 13.3346"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 11.0013L13.2358 5.2858C13.4492 5.10092 13.5983 4.8531 13.6618 4.57803L14.3333 1.66797L11.4233 2.33952C11.1482 2.403 10.9004 2.55214 10.7155 2.76547L5 10.0013"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M2.66602 8.66797H3.04706C3.40069 8.66797 3.73982 8.80844 3.98987 9.05849L6.94216 12.0108C7.19221 12.2608 7.33268 12.6 7.33268 12.9536V13.3346"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
