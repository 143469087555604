import { IconProps } from "."

export const FolderAddIcon = (props: IconProps) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M14.9993 11.25V17.9167M18.3327 14.5833L11.666 14.5833"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M5.83268 5.41634H13.9577C15.7133 5.41634 16.591 5.41634 17.2216 5.83767C17.4946 6.02006 17.729 6.25444 17.9114 6.52742C18.2983 7.1065 18.3296 8.06981 18.3322 9.58301M9.99935 5.41634L9.47149 4.36061C9.03417 3.48599 8.63454 2.6056 7.6654 2.24218C7.24093 2.08301 6.75603 2.08301 5.78624 2.08301C4.27252 2.08301 3.51565 2.08301 2.94773 2.39994C2.5429 2.62586 2.20887 2.9599 1.98295 3.36473C1.66602 3.93264 1.66602 4.68951 1.66602 6.20323V8.74967C1.66602 12.678 1.66602 14.6422 2.8864 15.8626C4.01726 16.9935 5.78679 17.0764 9.16602 17.0825"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  )
}
