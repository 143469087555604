import client from "@/api/client"
import { SearchSDStyleResponse } from "@/api/sdk"
import { actionMiddleware } from "@/queries/middlewares/actionMiddleware"
import { InfiniteData } from "@tanstack/react-query"
import { createMutation } from "react-query-kit"
import { useWorkspaceStyleInfiniteQuery } from "./useGetStyleInfiniteQuery"

interface RecoverStyleMutationVariables {
  ids: string[]
}

export const useRecoverStyleMutation = createMutation({
  mutationFn: async ({ ids }: RecoverStyleMutationVariables) => {
    return client.api
      .sdStyleControllerRecoverStyles({
        styleIds: ids,
      })
      .then(res => res.data)
  },
  use: [
    actionMiddleware({
      onSuccess: ({ queryClient, variables }) => {
        // Update style list
        const styleListKey = useWorkspaceStyleInfiniteQuery.getKey()
        const styleListQueriesDataEntries = queryClient.getQueriesData<InfiniteData<SearchSDStyleResponse, number>>({
          queryKey: styleListKey,
        })

        styleListQueriesDataEntries?.forEach(([key, data]) => {
          if (!data) return

          const updatedData = {
            ...data,
            pages: data.pages.map(page => {
              const updatedPage = {
                ...page,
                data: page.data.filter(style => {
                  return !variables.ids.includes(style.id)
                }),
              }
              updatedPage.total = updatedPage.data.length
              return updatedPage
            }),
          }
          queryClient.setQueryData(key, updatedData)
        })
      },
    }),
  ],
})
