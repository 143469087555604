import { Fragment } from "react"
import SendRecipeModal from "./SendRecipeModal"
import ShareSocialModal from "./ShareSocialModal"
import ShortcutKeyBoardModal from "./ShortcutKeyBoardModal"
import ShareProfileModal from "./ShareProfileModal"
import dynamic from "next/dynamic"

const FullViewImageScreen = dynamic(async () => import("./FullViewImageScreen"))
const SignInModalV2 = dynamic(() => import("./SignInModalV2"))
const SignUpModalV2 = dynamic(() => import("./SignUpModalV2"))
const LinkWalletAccountModal = dynamic(async () => (await import("./LinkWalletAccountModal")).default)
const LinkWalletAccountModalEvm = dynamic(async () => (await import("./LinkWalletAccountModalEvm")).default)
const EarlyAccessModal = dynamic(async () => import("./EarlyAccessModal"))
const PermissionGuestModal = dynamic(async () => import("./PermissionGuestModal"))
const AddToHomeScreenModal = dynamic(async () => import("./AddToHomeScreenModal"))
const ForceUserChangedModal = dynamic(async () => import("./ForceUserChangedModal"))
const PromptsLibraryModal = dynamic(async () => import("./PromptInput/PromptsLibraryModal"))
const ReactivateAccountModal = dynamic(async () => import("./ReactivateAccountModal"))
const ReferralModalV2 = dynamic(async () => import("./ReferralModalV2"))

const ModalPortalExplore = () => {
  return (
    <Fragment>
      <PromptsLibraryModal className="z-[53]" />
      <ShareSocialModal />
      <ShareProfileModal />
      <SendRecipeModal />
      <FullViewImageScreen />
      <SignInModalV2 />
      <SignUpModalV2 />
      <ReactivateAccountModal />
      <LinkWalletAccountModal />
      <LinkWalletAccountModalEvm />
      <ReferralModalV2 />
      <PermissionGuestModal />
      <ShortcutKeyBoardModal />
      <EarlyAccessModal />
      <ForceUserChangedModal />
      <AddToHomeScreenModal />
    </Fragment>
  )
}

export default ModalPortalExplore
