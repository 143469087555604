import { IconProps } from "."

export const FolderUploadIcon = (props: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18" fill="none" {...props}>
      <path
        d="M5.83366 4.41732H13.9587C15.7142 4.41732 16.592 4.41732 17.2226 4.83864C17.4956 5.02104 17.7299 5.25542 17.9123 5.52839C18.2986 6.10643 18.3307 6.71716 18.3334 8.16732M10.0003 4.41732L9.47246 3.36159C9.03515 2.48696 8.63551 1.60658 7.66637 1.24316C7.24191 1.08398 6.75701 1.08398 5.78722 1.08398C4.27349 1.08398 3.51663 1.08398 2.94871 1.40091C2.54388 1.62683 2.20984 1.96087 1.98392 2.3657C1.66699 2.93362 1.66699 3.69048 1.66699 5.20421V7.75065C1.66699 11.679 1.66699 13.6432 2.88738 14.8636C4.01824 15.9945 5.78777 16.0774 9.16699 16.0835H10.8337"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M15.4173 10.2513L15.4173 16.918M15.4173 10.2513C14.8338 10.2513 13.7436 11.9132 13.334 12.3346M15.4173 10.2513C16.0008 10.2513 17.091 11.9132 17.5007 12.3346"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
