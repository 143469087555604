import { IconProps } from "."

export const CrownIcon = (props: IconProps) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M2.93183 8.58796C2.60844 7.69297 2.44675 7.24547 2.5157 6.95871C2.59111 6.64506 2.81416 6.40054 3.09927 6.31897C3.35994 6.24439 3.76544 6.42461 4.57644 6.78506C5.29378 7.10389 5.65245 7.2633 5.98943 7.25443C6.36047 7.24466 6.7174 7.09587 7.00133 6.83259C7.25921 6.59348 7.43218 6.21244 7.77811 5.45037L8.5405 3.77088C9.17733 2.36796 9.49575 1.6665 10 1.6665C10.5042 1.6665 10.8227 2.36796 11.4595 3.77088L12.2219 5.45037C12.5678 6.21244 12.7408 6.59348 12.9987 6.83259C13.2826 7.09587 13.6395 7.24466 14.0106 7.25443C14.3476 7.2633 14.7062 7.10389 15.4236 6.78506C16.2346 6.42461 16.6401 6.24439 16.9007 6.31897C17.1858 6.40054 17.4089 6.64506 17.4843 6.95871C17.5533 7.24547 17.3916 7.69297 17.0682 8.58796L15.6781 12.435C15.0835 14.0806 14.7862 14.9035 14.164 15.3683C13.5419 15.8332 12.7378 15.8332 11.1298 15.8332H8.8702C7.26216 15.8332 6.45814 15.8332 5.83596 15.3683C5.21378 14.9035 4.91647 14.0806 4.32185 12.435L2.93183 8.58796Z"
        stroke="#EFE8FD"
        strokeWidth="1.5"
      />
      <path d="M10 11.6665H10.0075" stroke="#EFE8FD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.83398 18.333H14.1673" stroke="#EFE8FD" strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  )
}
