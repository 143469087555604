import { useCallback, useState } from "react"

type FileDropOptions = {
  onDrop: (files: File[]) => void
  onFilter?: (files: File[]) => File[]
  isImageSource?: React.MutableRefObject<boolean>
  enabled?: boolean
}

export const useFileDrop = <T extends HTMLElement>(props: FileDropOptions) => {
  const { onDrop, onFilter, enabled } = props

  const [isOver, setIsOver] = useState(false)

  const handleDragOver = useCallback((e: React.DragEvent<T>) => {
    e.preventDefault()
    if (e.dataTransfer.types.includes("Files") && enabled) {
      setIsOver(true)
    }
  }, [])

  const handleDragLeave = useCallback((e: React.DragEvent<T>) => {
    setIsOver(false)
  }, [])

  const handleDrop = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      // return if the drop event is not over an image
      if (!e.dataTransfer.types.includes("Files")) return

      e.preventDefault()
      setIsOver(false)

      // filter out files that are not images
      const files = Array.from(e.dataTransfer.files)
      const filtered = onFilter ? onFilter(files) : files
      if (filtered.length > 0 && enabled) {
        onDrop(filtered)
      }
    },
    [onDrop],
  )

  return {
    isOver,
    bind: {
      onDragOver: handleDragOver,
      onDragLeave: handleDragLeave,
      onDrop: handleDrop,
    },
  }
}
