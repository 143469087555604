import { IconProps } from "."

export const ZoomInIcon = (props: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none" {...props}>
      <path d="M16.5 16.5L21 21" stroke="#C8C4B5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19C14.9706 19 19 14.9706 19 10Z"
        stroke="#C8C4B5"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M6.5 10L13.5 10M10 6.5V13.5"
        stroke="#C8C4B5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
