import { RecipeInputType, RecipeTaskStatus, SDModel } from "@/api/sdk"
import IconButton from "@/components/IconButton"
import {
  ArrowDownIcon,
  ArrowMoveUpRightIcon,
  DeleteIcon,
  LayerAddIcon,
  MenuIcon,
  ShareIcon3,
  ShareIcon8,
} from "@/components/shared/icons"
import { useRaisedShadow } from "@/hooks/useRaisedShadow"
import classNames from "classnames"
import { PanInfo, Reorder, useDragControls, useMotionValue } from "framer-motion"
import isHotkey from "is-hotkey"
import React, { Fragment, memo, useState } from "react"
import { RecipeTaskChainParams } from "."
import { formatInputKey } from "../WorkflowDetailStep/WorkFlowDetailStep"
import Tooltip from "@/components/Tooltip"
import TextCopy from "@/components/TextCopy"
import { useCreateTaskMutation } from "@/queries"
import { useDebounce, useToast } from "@/hooks"
import { googleAnalytics } from "@/lib/gtag"
import Accordion from "../../FoldersV2/Accordion"
import { useManagementErrorsStore } from "@/stores"
import { useAuth } from "@/providers/authContext"
import useCustomRouter from "@/hooks/useCustomRouter"

interface ChainItemProps {
  index: number
  isActive: boolean
  chain: RecipeTaskChainParams & { id?: string }
  chains: (RecipeTaskChainParams & { id?: string })[]
  models: SDModel[]
  onEdit: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  onRemove: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  isDeleted?: boolean
  onDuplicate: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  onDragEnd?: (event: MouseEvent | TouchEvent | PointerEvent, info: PanInfo) => void
  isDisabledRemove?: boolean
  isLoading?: boolean
  stylesNotFound?: string[]
}

export const getModelName = (models: SDModel[], modelHash?: string) => {
  if (!modelHash) return ""

  return models.find(model => model.id === modelHash)?.name
}

export const humanizeInputParam = (value: string, chains: RecipeTaskChainParams[]): string => {
  if (!value.toString().includes("$$prev")) {
    return value
  }
  // Support old workflow that doesn't include step number
  if (value === "$$prev") {
    return `previous step`
  }

  const stepNumber = parseInt(value.split(".")[1])
  if (isNaN(stepNumber)) {
    return value
  }

  const recipeStepName = chains[stepNumber]?.recipeName

  if (stepNumber + 1 > chains.length - 1) {
    return `Step ${stepNumber + 1} - Error step`
  }

  return `Step ${stepNumber + 1} - ${recipeStepName ?? "Error step"}`
}

export const replaceHumanizePromptParam = (value: string, chains: RecipeTaskChainParams[]): string => {
  if (!value.toString().includes("$$prev")) {
    return value
  }

  let newValue = value

  newValue.split(" ").forEach((item, index) => {
    if (item.includes("$$prev.")) {
      const stepNumber = parseInt(item.split(".")[1])
      if (isNaN(stepNumber)) {
        return
      }

      const recipeStepName = chains[stepNumber]?.recipeName

      if (stepNumber + 1 > chains.length - 1) {
        newValue = newValue.replace(item, `(Step ${stepNumber + 1} - Error step)`)
      } else {
        newValue = newValue.replace(item, `(Step ${stepNumber + 1} - ${recipeStepName ?? "Error step"})`)
      }
    }
  })

  return newValue
}

export const formatValueParams = (key: string, value: string): string => {
  if (key === "lora" || key === "Lora") {
    return `${JSON.parse(value)
      .map(
        (item: { name: string; strength: string; displayName: string }) =>
          `${item.displayName || item.name}: ${item.strength}`,
      )
      .join(", ")}`
  }

  if (key === "seedModeFixed") {
    return value ? "Fixed" : "Random"
  }

  if (typeof value === "boolean") {
    return value ? "Enabled" : "Disabled"
  }

  if (key === "shapeControl") {
    if (value === "openpose") return "Pose"

    return value
  }

  return value
}

const ChainItem = ({
  index,
  isActive,
  chain,
  onDuplicate,
  isDeleted = false,
  chains,
  onEdit,
  stylesNotFound,
  onRemove,
  isDisabledRemove,
  models,
  onDragEnd,
  isLoading,
}: ChainItemProps) => {
  const isDefaultStep = chain.recipeId === "add-a-recipe"
  const y = useMotionValue(0)
  const boxShadow = useRaisedShadow(y)
  const dragControls = useDragControls()
  const [isOpen, setIsOpen] = useState(true)
  const setErrorState = useManagementErrorsStore(state => state.setErrorState)
  const isDeletedDebounce = useDebounce(isDeleted, 250)
  const { user } = useAuth()
  const { openNewTab } = useCustomRouter()
  const toast = useToast()

  const { mutateAsync: mutateCreateTask, isPending: isLoadingCreate } = useCreateTaskMutation({
    onSuccess: data => {
      setTimeout(() => {
        openNewTab(`/workspace/macros/${data.id}?draft=true`)
      }, 1)
    },
    onError: (err: any) => {
      if (
        err?.message.startsWith("Guest user does not have permission") ||
        err?.message.startsWith("Your subscription has ended")
      ) {
        setErrorState({
          isOpen: true,
          message: err.message,
        })
        return
      }

      if (err?.error === "Bad Request") {
        toast({ title: "Cannot Complete Request", message: [err.message], status: "error" })
      } else {
        toast({ title: "Error", message: [err.message], status: "error" })
      }
    },
  })

  const handleSendToNewMacro = async () => {
    const param = chain

    const params = Object.entries(param.params).map(([key, value]) => {
      if (
        param.recipeInputStep?.find(step => step.key === key)?.type === RecipeInputType.Image &&
        value.includes("$$prev")
      ) {
        return {
          [key]: "",
        }
      }

      return {
        [key]: value,
      }
    })

    const newParams = params.reduce((acc, curr) => {
      return {
        ...acc,
        ...curr,
      }
    }, {})

    const newChainSendNew = {
      id: new Date().getTime().toString(),
      params: newParams,
      recipeId: param.recipeId,
      recipeInputStep: param.recipeInputStep,
      recipeName: param.recipeName,
    }

    googleAnalytics.event({
      action: "click",
      category: "macro-detail",
      label: "send-to-new-macro",
      params: {
        recipe_id: chain.recipeId,
        recipe_name: chain.recipeName,
        ...newParams,
      },
    })

    mutateCreateTask({
      recipeId: "recipe-to-recipe",
      params: [newChainSendNew],
      name: "New Macro",
      status: RecipeTaskStatus.DRAFT,
    })
  }

  const getImagesParams = Object.entries(chain.params)
    .map(([key, value]) => {
      return chain.recipeInputStep?.find(i => i.key === key)?.type === RecipeInputType.Image &&
        !value?.includes("$$prev")
        ? value
        : null
    })
    .filter(i => !!i)

  const renderUI = () => {
    return (
      <div className="w-full relative">
        <div className="flex items-center mb-2">
          <div
            title="Drag to reorder"
            style={{ touchAction: "none" }}
            className={classNames("flex items-center flex-1 select-none", {
              "cursor-not-allowed": !isLoading && isDeletedDebounce,
              "cursor-pointer": !isDeleted,
            })}
            onClick={e => e.preventDefault()}
            onPointerDown={e => {
              if (isDeleted) return

              dragControls.start(e)
            }}
          >
            <MenuIcon className={classNames("text-atherGray-500")} />
            <p className={classNames("ml-2 text-sm uppercase font-semibold")}>Step {index + 1}</p>
          </div>

          <div
            className={classNames("flex items-center space-x-1", {
              "workflow-step-actions": !isDefaultStep,
            })}
          >
            {!isDeletedDebounce && user && (
              <Fragment>
                <IconButton
                  isLoading={isLoadingCreate}
                  className={classNames(
                    "p-1 min-h-0 border border-atherGray-800 hover:bg-black text-atherGray-300 bg-black",
                    {
                      hidden: isDefaultStep,
                    },
                  )}
                  colorScheme="transparent"
                  onClick={e => {
                    e.preventDefault()
                    e.stopPropagation()

                    handleSendToNewMacro()
                  }}
                >
                  <ArrowMoveUpRightIcon width={16} height={16} />
                  <span className="text-xs ml-1">Send to New Macro</span>
                </IconButton>
                <Tooltip
                  trigger={
                    <IconButton
                      colorScheme="transparent"
                      className={classNames(
                        "p-1 border min-h-0 border-atherGray-800 rounded-lg hover:bg-black text-atherGray-300 bg-black",
                        {
                          hidden: isDefaultStep,
                        },
                      )}
                      onClick={e => {
                        onDuplicate(e)
                      }}
                    >
                      <LayerAddIcon className="text-atherGray-300" width={16} height={16} />
                    </IconButton>
                  }
                >
                  Duplicate step
                </Tooltip>
                <Tooltip
                  trigger={
                    <div
                      className={classNames(
                        "p-1 border min-h-0 border-atherGray-800 rounded-lg hover:bg-black text-atherGray-300 bg-black",
                        {
                          hidden: isDefaultStep,
                        },
                      )}
                      onClick={e => {
                        e.preventDefault()
                        e.stopPropagation()
                      }}
                    >
                      <TextCopy
                        value={JSON.stringify(chain, null, 2)}
                        icon={<ShareIcon8 className="text-atherGray-300" width={16} height={16} />}
                      />
                    </div>
                  }
                >
                  Copy step
                </Tooltip>
              </Fragment>
            )}
            <Tooltip
              trigger={
                <IconButton
                  colorScheme="transparent"
                  className={classNames(
                    "p-1 border min-h-0 border-atherGray-800 rounded-lg hover:bg-black text-atherGray-300 bg-black",
                    {
                      hidden: isDisabledRemove,
                    },
                  )}
                  onClick={e => {
                    onRemove(e)
                  }}
                >
                  <DeleteIcon className="text-red-500" width={16} height={16} />
                </IconButton>
              }
            >
              Remove step
            </Tooltip>
          </div>
        </div>
        <IconButton
          disabled={isDeletedDebounce}
          colorScheme="transparent"
          className={classNames(
            "px-4 py-2 flex flex-col gap-2 relative  justify-start items-stretch border-[2px] w-full hover:bg-atherGray-850 active:bg-atherGray-850",
            {
              "bg-atherGray-900 border-atherPurple-500": isActive,
              "border-atherGray-900 bg-atherGray-900": !isActive,
              "bg-atherGray-950 border-atherGray-950": !isLoading && isDeletedDebounce,
            },
          )}
          onKeyDown={e => {
            if (isDeleted) return

            // handle copy ctr + c
            if (isHotkey("mod+c", e)) {
              e.preventDefault()
              e.stopPropagation()

              const copyText = JSON.stringify(chain, null, 2)

              navigator.clipboard.writeText(copyText)
            }
          }}
          onClick={e => {
            if (isDeleted) return

            onEdit(e)
          }}
        >
          <div className="w-full flex flex-col">
            <div
              className={classNames(
                "grid grid-cols-2 gap-2 md:grid-cols-[repeat(auto-fill,minmax(10rem,1fr))] xl:grid-cols-[repeat(auto-fill,minmax(15rem,1fr))]",
                {
                  "mb-4": getImagesParams?.length > 0,
                },
              )}
            >
              {getImagesParams?.map((image, index) => (
                <div
                  key={index}
                  className="w-full bg-atherGray-950 h-[12rem] overflow-hidden flex items-center justify-center"
                >
                  <img
                    src={image}
                    alt={index.toString()}
                    className="w-full max-h-[12rem] object-contain object-center"
                  />
                </div>
              ))}
            </div>
            <Accordion
              isOpen={isOpen}
              hiddenButton
              disabledTitleClick
              setIsOpen={setIsOpen}
              titleClassName={classNames({
                "text-atherGray-0": !isDeletedDebounce,
              })}
              title={isDefaultStep ? chain.recipeName : `Recipe: ${chain.recipeName}`}
              action={
                <div>
                  <ArrowDownIcon
                    width={14}
                    height={14}
                    className={classNames({
                      "rotate-90": !isOpen,
                    })}
                  />
                </div>
              }
            >
              {() => (
                <>
                  <div
                    className={classNames("", {
                      "mt-2": !isDefaultStep,
                    })}
                  >
                    {Object.entries(chain.params).map(([key, value]) => {
                      if (
                        key === "prompt" ||
                        key === "negative_prompt" ||
                        chain.recipeInputStep?.find(i => i.key === key)?.type === "text"
                      ) {
                        if (typeof value === "object") return null

                        return (
                          <div key={key} className="w-full mb-2">
                            <div className="mb-1 flex items-center">
                              <p className="text-left text-atherGray-500 uppercase font-semibold text-xs flex-1">
                                {formatInputKey(key)}:
                              </p>
                            </div>
                            <div className="flex flex-wrap gap-1 bg-atherGray-950 p-2 rounded-lg">
                              {value &&
                                value.split(",").map((item, index) => {
                                  if (item.trim())
                                    return (
                                      <span
                                        key={index}
                                        className="text-atherGray-300 flex items-center text-sm border border-atherGray-700 py-0.5 px-2 font-normal rounded-lg"
                                      >
                                        {replaceHumanizePromptParam(item, chains)}
                                      </span>
                                    )
                                })}
                            </div>
                          </div>
                        )
                      }
                    })}
                  </div>
                  <div
                    className={classNames("grid grid-cols-[repeat(auto-fill,minmax(15rem,_1fr))] w-full gap-2", {
                      "mt-2": !isDefaultStep,
                    })}
                  >
                    {Object.entries(chain.params).map(([key, value]) => {
                      if (
                        key === "prompt" ||
                        key === "negative_prompt" ||
                        chain.recipeInputStep?.find(i => i.key === key)?.type === "text"
                      )
                        return null
                      if (key.endsWith("_wildcard")) return null

                      if (
                        key === "model_hash" ||
                        key === "modelHash" ||
                        chain.recipeInputStep?.find(i => i.key === key)?.type === "model"
                      ) {
                        return (
                          <div key={key} className="bg-atherGray-950 px-2 py-1 flex items-center rounded-lg">
                            <div className="flex items-center font-normal text-atherGray-300 w-full text-xs line-clamp-1">
                              <p className="text-atherGray-500 whitespace-nowrap uppercase font-semibold text-xs">
                                {formatInputKey(key)}:
                              </p>
                              <div className="flex-1 text-sm ml-2 flex justify-end overflow-hidden">
                                <p
                                  className="line-clamp-1 break-words"
                                  title={getModelName(
                                    models,
                                    chain.params.modelHash || chain.params.model_hash || value,
                                  )}
                                >
                                  {getModelName(models, chain.params.modelHash || chain.params.model_hash || value)}
                                </p>
                              </div>
                            </div>
                          </div>
                        )
                      }

                      if (value === "") return null

                      return (
                        <div key={key} className="bg-atherGray-950 px-2 py-1 flex items-center rounded-lg">
                          <div className="flex items-center font-normal text-atherGray-300 w-full text-xs line-clamp-1">
                            <p className="text-atherGray-500 whitespace-nowrap uppercase font-semibold text-xs">
                              {formatInputKey(key, chain)}:
                            </p>{" "}
                            <div className="flex-1 text-sm ml-2 flex justify-end overflow-hidden">
                              <p
                                className={classNames("line-clamp-1 break-words", {
                                  "text-red-500": key === "style" ? stylesNotFound?.includes(value) : false,
                                })}
                                title={
                                  value?.toString().includes("$$prev")
                                    ? humanizeInputParam(value, chains)
                                    : formatValueParams(key, value)
                                }
                              >
                                {value?.toString().includes("$$prev")
                                  ? humanizeInputParam(value, chains)
                                  : formatValueParams(key, value)}
                              </p>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </>
              )}
            </Accordion>

            {isDeletedDebounce && (
              <div className="absolute z-[1] top-0 left-0 w-full h-full backdrop-blur-sm flex items-center justify-center">
                <p className="text-red-500 font-normal text-sm">
                  This recipe has been deleted. Please remove this step
                </p>
              </div>
            )}
          </div>
        </IconButton>
      </div>
    )
  }

  return (
    <Reorder.Item
      as="div"
      id={chain.id}
      value={chain}
      dragListener={false}
      dragControls={dragControls}
      style={{ y, boxShadow }}
      onDragEnd={onDragEnd}
      className={"w-full shadow-md"}
    >
      {renderUI()}
    </Reorder.Item>
  )
}

export default memo(ChainItem)
