import { create } from "zustand"

interface EarlyAccessStore {
  earlyAccess: boolean
  setEarlyAccess: (earlyAccess: boolean) => void
}

export const useEarlyAccessStore = create<EarlyAccessStore>(set => ({
  earlyAccess: false,
  setEarlyAccess: earlyAccess => set({ earlyAccess }),
}))
