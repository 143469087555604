import React from "react"
import Modal from "../Modal"
import { useShortcutKeyboardStore } from "@/stores"

const ShortcutKeyBoardModal = () => {
  const { isOpen, setIsOpen } = useShortcutKeyboardStore()

  return (
    <Modal className="max-w-md" title="Shortcut" isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <p className="text-atherGray-300 font-semibold">Keyboard commands</p>
      <div className="text-base">
        <div className="flex items-center justify-between border-b border-atherGray-800 py-4">
          <p>Seach bar</p>
          <span className="font-semibold px-1 text-atherGray-300 bg-atherGray-800 min-w-[1.5rem] h-6 flex items-center justify-center  border border-atherGray-700">
            F
          </span>
        </div>
        <div className="flex items-center justify-between border-b border-atherGray-800 py-4">
          <p>Workspace</p>
          <span className="font-semibold px-1 text-atherGray-300 bg-atherGray-800 min-w-[1.5rem] h-6 flex items-center justify-center  border border-atherGray-700">
            Shift W
          </span>
        </div>
        <div className="flex items-center justify-between border-b border-atherGray-800 py-4">
          <p>Create New Macro</p>
          <span className="font-semibold px-1 text-atherGray-300 bg-atherGray-800 min-w-[1.5rem] h-6 flex items-center justify-center  border border-atherGray-700">
            Shift M
          </span>
        </div>
        <div className="flex items-center justify-between border-b border-atherGray-800 py-4">
          <p>Create New Style</p>
          <span className="font-semibold px-1 text-atherGray-300 bg-atherGray-800 min-w-[1.5rem] h-6 flex items-center justify-center  border border-atherGray-700">
            Shift S
          </span>
        </div>
        <div className="flex items-center justify-between border-b border-atherGray-800 py-4">
          <p>Create New ComfyUI</p>
          <span className="font-semibold px-1 text-atherGray-300 bg-atherGray-800 min-w-[1.5rem] h-6 flex items-center justify-center  border border-atherGray-700">
            Shift C
          </span>
        </div>
        <div className="flex items-center justify-between pt-4">
          <p>Shortcut Popup</p>
          <span className="font-semibold px-1 text-atherGray-300 bg-atherGray-800 min-w-[1.5rem] h-6 flex items-center justify-center  border border-atherGray-700">
            Shift Q
          </span>
        </div>
      </div>
    </Modal>
  )
}

export default ShortcutKeyBoardModal
