import React from "react"
import Accordion from "../FoldersV2/Accordion"
import { cn } from "@/utils/cn"
import GridBreakpointColsContainer from "@/components/GridBreakpointColsContainer"

interface RecentAccordionProps {
  name: string
  children: React.ReactNode
  layout: "grid" | "list"
  gridClassName?: string
  listClassName?: string
}

const RecentAccordion = ({ name, children, layout, listClassName, gridClassName }: RecentAccordionProps) => {
  return (
    <div className="overflow-hidden mb-4">
      <Accordion defaultOpen title={name}>
        {() => (
          <div className="overflow-hidden">
            {layout === "grid" ? (
              <GridBreakpointColsContainer className={gridClassName}>{children}</GridBreakpointColsContainer>
            ) : (
              <div className={cn("flex flex-col space-y-1", listClassName)}>{children}</div>
            )}
          </div>
        )}
      </Accordion>
    </div>
  )
}

export default RecentAccordion
