import { useAuth } from "@/providers/authContext"
import { googleAnalytics } from "@/lib/gtag"
import { cn } from "@/utils/cn"
import classNames from "classnames"
import useCustomRouter from "@/hooks/useCustomRouter"
import { Fragment, ReactElement } from "react"
import UserBadge from "../UserBadge"
import { WorkspaceImage } from "../Workspace/Sidebar/WorkspaceSelector"
import {
  Alert2Icon,
  ArrowDownIcon,
  BillingIcon,
  Info,
  LockIcon,
  Member,
  PlaneIcon,
  PrivacyIcon,
  ProfileIcon,
  ShareWorkspace,
} from "../shared/icons"
import { useSetting } from "./SettingProvider"
import IconButton from "../IconButton"
import { SubscriptionStatus } from "@/api/sdk"

const Sections = ({
  title,
  lists,
  currentTab,
  setCurrentTab,
  className,
  isAbout = false,
  isMyspace = false,
}: {
  title?: string
  lists: {
    icon: ReactElement
    title: string
  }[]
  currentTab: string
  setCurrentTab: (v: string) => void
  className?: string
  isAbout?: boolean
  isMyspace?: boolean
}) => {
  const { userInfoQuery } = useAuth()
  const { currentWorkspaceDetail, userPlan } = useSetting()

  return (
    <div
      className={cn(
        "relative flex flex-col w-full pt-8 pb-4 mb-4 mr-10",
        {
          "pt-0": !title,
        },
        className,
      )}
    >
      {title && <p className="text-[12px] absolute top-0 left-0 text-[#7E7D77] font-semibold">{title}</p>}
      {isAbout && (
        <div className="mb-4">
          {userInfoQuery?.data && (
            <UserBadge
              picture={userInfoQuery.data.picture}
              name={userInfoQuery.data.name}
              email={userInfoQuery.data.email}
              size="md"
            />
          )}
        </div>
      )}

      {isMyspace && (
        <div className="flex items-center mb-5 ">
          <div
            title={currentWorkspaceDetail?.name ?? userInfoQuery?.data?.name ?? ""}
            className="flex-1 flex items-center overflow-hidden"
          >
            <WorkspaceImage
              name={currentWorkspaceDetail?.name ?? userInfoQuery?.data?.name ?? ""}
              photoUrl={currentWorkspaceDetail?.photoUrl ?? userInfoQuery?.data?.picture ?? ""}
            />
            <p className="font-semibold ml-2 truncate">
              {currentWorkspaceDetail?.name ?? userInfoQuery?.data?.name ?? ""}
            </p>
          </div>
          <div className="py-0.5 px-1 ml-1 rounded-md border">
            <p className="text-[rgba(255,255,255,0.9) text-[0.65rem] font-semibold">
              {currentWorkspaceDetail?.subscriptionPackageName ?? "No Plan"}
            </p>
          </div>
        </div>
      )}
      <div className="flex flex-col ">
        {lists.map((_i, _index) => {
          return (
            <div
              className={classNames(
                "flex items-center w-full pl-4 mb-2 cursor-pointer px-2 py-[10px] rounded-[20px] text-red-100",
                {
                  "bg-[#262624]": currentTab === _i.title,
                },
              )}
              key={_i.title + _index}
              onClick={() => {
                setCurrentTab(_i.title)

                googleAnalytics.handleCategoryEvent({
                  action: "click",
                  params: {
                    action: "Change tab",
                    profile_username: userInfoQuery?.data?.username ?? "",
                    profile_id: userInfoQuery?.data?.uid ?? "",
                    is_private: userInfoQuery?.data?.isPrivate ? "true" : "false",
                    tab_name: _i.title.toLowerCase(),
                    user_name: userInfoQuery?.data?.name ?? "",
                    user_email: userInfoQuery?.data?.email ?? "",
                    user_about: userInfoQuery?.data?.description ?? "",
                  },
                })
              }}
            >
              <div className="flex items-center flex-1">
                <div
                  className={classNames("mr-3 opacity-60", {
                    "!opacity-100": currentTab === _i.title,
                  })}
                >
                  {_i.icon}
                </div>
                <p
                  className={classNames("text-[#FFF8EE] font-semibold opacity-60", {
                    "!opacity-100": currentTab === _i.title,
                  })}
                >
                  {_i.title}
                </p>
              </div>
              {_i.title === "Plans" &&
                (userPlan?.currentSubscription.status === SubscriptionStatus.PastDue ? (
                  <p className="flex items-center pr-2 ml-2">
                    <Alert2Icon className="mr-1" width={16} height={16} />
                    <span className="flex-1 text-sm font-semibold text-[#FFF8EE]">Renew</span>
                  </p>
                ) : null)}
            </div>
          )
        })}
      </div>
    </div>
  )
}

const SettingSidebar = ({
  className,
  onClose,
  isMobile,
  onBack,
}: {
  className?: string
  onClose?: () => void
  onBack?: () => void
  isMobile?: boolean
}) => {
  const { currentTab, findMyWorkspace } = useSetting()
  const router = useCustomRouter()

  return (
    <div
      className={cn(
        "w-[19.5rem] flex p-4 md:p-2 flex-col sticky left-0 top-[4rem] md:py-6 lg:pt-12 scrollbar-hover",
        className,
      )}
    >
      <div className="mb-5 flex items-center">
        {isMobile ? (
          <Fragment>
            <IconButton
              onClick={() => {
                onBack?.()
              }}
              colorScheme="transparent"
              className="p-1 min-h-0 rotate-90 mr-2"
            >
              <ArrowDownIcon />
            </IconButton>
            <p className="text-base text-[#FFF8EE] font-semibold flex-1 text-center pl-2">My Account</p>
          </Fragment>
        ) : (
          <p className="text-xl text-[#FFF8EE] font-semibold">My Account</p>
        )}
      </div>

      <Sections
        title={isMobile ? undefined : "PERSONAL"}
        currentTab={currentTab}
        setCurrentTab={v => {
          onClose?.()
          router.replace(
            {
              pathname: "/settings/account",
              query: {
                tab: v,
              },
            },
            undefined,
            {
              scroll: true,
              shallow: true,
            },
          )
        }}
        lists={[
          {
            title: "Your account",
            icon: <ProfileIcon width={20} height={20} />,
          },
          {
            title: "Privacy",
            icon: <PrivacyIcon width={20} height={20} />,
          },
          {
            title: "Security",
            icon: <LockIcon width={20} height={20} />,
          },
        ]}
        className="border-b-2 border-[#323230]"
        isAbout={!isMobile}
      />

      {findMyWorkspace && (
        <Sections
          title="MY WORKSPACE"
          currentTab={currentTab}
          setCurrentTab={v => {
            onClose?.()
            router.replace(
              {
                pathname: "/settings/account",
                query: { tab: v },
              },
              undefined,
              {
                shallow: true,
                scroll: true,
              },
            )
          }}
          isMyspace
          lists={[
            {
              title: "Info",
              icon: <Info width={20} height={20} />,
            },
            {
              title: "Members",
              icon: <Member width={20} height={20} />,
            },
            {
              title: "Plans",
              icon: <PlaneIcon width={20} height={20} />,
            },
            {
              title: "Billing",
              icon: <BillingIcon width={20} height={20} />,
            },
          ]}
          className="border-b-2 border-[#323230]"
        />
      )}

      <Sections
        title={isMobile ? undefined : "SHARED WORKSPACES"}
        currentTab={currentTab}
        setCurrentTab={v => {
          onClose?.()
          router.replace(
            {
              pathname: "/settings/account",
              query: {
                tab: v,
              },
            },
            undefined,
            {
              shallow: true,
              scroll: true,
            },
          )
        }}
        lists={[
          {
            title: "Shared workspaces",
            icon: <ShareWorkspace width={20} height={20} />,
          },
          ...(!findMyWorkspace
            ? [
                {
                  title: "Plans",
                  icon: <PlaneIcon width={20} height={20} />,
                },
              ]
            : []),
        ]}
      />
    </div>
  )
}

export default SettingSidebar
