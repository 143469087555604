import { NotificationWithStatus } from "@/api/sdk"
import { useCallback } from "react"
import { toast } from "react-toastify"
import NotificationItem from "./NotificationItem"

type NotificationToastOptions = {
  notification: NotificationWithStatus
  duration?: number
}

export const usePushNotificationToast = (
  {
    defaultDuration,
  }: {
    defaultDuration?: number
  } = { defaultDuration: 3500 },
) => {
  return useCallback(
    (options: NotificationToastOptions) => {
      const { notification, duration = defaultDuration } = options

      if (!toast.isActive(notification.id)) {
        setTimeout(() => {
          // push notification service worker

          toast(
            <NotificationItem
              notification={notification}
              isToast={true}
              onClose={() => {
                toast.dismiss(notification.id)
              }}
            />,
            {
              toastId: notification.id,
              autoClose: duration,
              position: "top-right",
            },
          )
        }, 100)
      }
    },
    [defaultDuration],
  )
}

export default usePushNotificationToast
