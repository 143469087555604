import { RecentWildcardItem as RecentWildcardItemType } from "@/api/sdk"
import React, { memo } from "react"
import RecentAccordion from "../RecentAccordion"
import WildcardItem from "@/components/Tools/Wildcards/WildcardItem"

interface RecentWildcardItemProps {
  name: string
  recent: RecentWildcardItemType[]
  containerWidth: number
  layout: "grid" | "list"
}

const RecentWildcardItem = ({ name, recent, layout }: RecentWildcardItemProps) => {
  if (recent.length === 0) return null
  return (
    <RecentAccordion name={name} layout={layout}>
      {recent?.map(item => {
        return (
          <div key={item.id} className={layout === "grid" ? "" : ""}>
            <WildcardItem accessedAt={item.accessedAt} layout={layout} wildcard={item.wildcard} />
          </div>
        )
      })}
    </RecentAccordion>
  )
}

export default memo(RecentWildcardItem)
