import client from "@/api/client"
import { PickTypeClass } from "@/api/sdk"
import Popover from "@/components/Popover"
import { PlusIcon2 } from "@/components/shared/icons"
import { useDebounce } from "@/hooks"
import { useSearchTagInfiniteQuery } from "@/queries"
import { cn } from "@/utils/cn"
import { useMutation } from "@tanstack/react-query"
import classNames from "classnames"
import { FormEvent, useRef, useState } from "react"
import InfiniteScroll from "react-infinite-scroller"
import { twMerge } from "tailwind-merge"

export interface TagsSearchProps {
  selectedTags: { id: number; name: string }[]
  onSubmit: (value: PickTypeClass) => void
  value: string
  onChange: (value: string) => void
  setIsFocus?: (isFocus: boolean) => void
  className?: string
  disabled?: boolean
}

const TagSearchImage = ({
  value,
  onChange,
  selectedTags,
  onSubmit,
  setIsFocus,
  className,
  disabled,
}: TagsSearchProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [hoveringItem, setHoveringItem] = useState<number | null>(null)

  const scrollRef = useRef<HTMLDivElement>(null)
  const popupRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)

  const itemRefs = useRef<Record<number, HTMLDivElement | null>>({})

  const setRef = (id: number) => (node: HTMLDivElement | null) => {
    itemRefs.current[id] = node
  }
  const debouncedSearch = useDebounce(value, 350)

  const {
    flattenData = [],
    fetchNextPage,
    hasNextPage,
    isFetching,
  } = useSearchTagInfiniteQuery({
    variables: {
      searchQuery: debouncedSearch,
      isInUse: true,
    },
  })

  const { mutate: mutateCreateTag } = useMutation({
    mutationFn: (tagName: string) => {
      return client.api.tagsControllerCreateTag({ tag: tagName })
    },
    onSuccess: result => {
      onSubmit({
        id: result.data.id,
        name: result.data.name,
      })
      onChange("")
      setIsOpen(false)
    },
  })

  const handleSelectTag = (tag: PickTypeClass) => {
    onSubmit(tag)
    onChange("")
    setIsOpen(false)
    setIsFocus?.(false)
  }

  const filteredTags = flattenData.filter(tag => !selectedTags.map(t => t.name).includes(tag.name))

  const handleSubmitTag = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    e.stopPropagation()
    if (!value) return
    mutateCreateTag(value)
    setIsFocus?.(false)
  }

  return (
    <div ref={popupRef}>
      <div>
        <Popover
          isOpen={isOpen}
          setIsOpen={open => {
            setIsOpen(open)
            setIsFocus?.(open)
          }}
          align="start"
          trigger={
            <button
              disabled={disabled}
              type="button"
              onClick={() => {
                setIsOpen(prev => !prev)
              }}
              className={cn("rounded-md flex items-center", className)}
            >
              Add tag
              <PlusIcon2 width={12} height={12} className="ml-1" />
            </button>
          }
        >
          <div className="-translate-x-2 rounded-lg shadow-lg bg-atherGray-800 border border-atherGray-700 overflow-hidden w-[12rem] child-modal">
            <form className="h-full" onSubmit={handleSubmitTag}>
              <div
                className={classNames("flex", {
                  "border-b border-atherGray-600": filteredTags.length > 0,
                })}
              >
                <input
                  className={"bg-transparent w-full py-2 pl-2 outline-none text-atherGray-0 text-sm"}
                  autoFocus
                  value={value}
                  maxLength={25}
                  onChange={e => onChange(e.target.value)}
                  ref={inputRef}
                />
                <button type="submit">
                  <PlusIcon2 width={14} height={14} className="mx-2" />
                </button>
              </div>
            </form>
            <div className="overflow-y-auto overflow-x-hidden max-h-[14rem] p-1" ref={scrollRef}>
              {filteredTags.length > 0 && (
                <InfiniteScroll
                  pageStart={0}
                  getScrollParent={() => scrollRef.current}
                  useWindow={false}
                  loadMore={() => fetchNextPage()}
                  hasMore={!!hasNextPage && !isFetching}
                  style={{ display: "flex", flexDirection: "column", overflow: "hidden" }}
                  loader={
                    <div key="loader" className="flex items-center justify-center py-4">
                      Loading...
                    </div>
                  }
                >
                  <div className={classNames("flex overflow-hidden flex-wrap")}>
                    {filteredTags.map(tag => (
                      <div
                        key={tag.id}
                        className="cursor-pointer flex max-w-full p-1"
                        onClick={() => handleSelectTag(tag)}
                        onMouseOver={() => setHoveringItem(tag.id)}
                        onMouseLeave={() => setHoveringItem(null)}
                        ref={setRef(tag.id)}
                      >
                        <p
                          className={twMerge(
                            classNames(
                              "border border-atherGray-700 cursor-pointer rounded-lg px-2 w-full py-1 text-sm",
                              {
                                "bg-atherGray-600": hoveringItem === tag.id,
                              },
                            ),
                          )}
                          style={{
                            hyphens: "auto",
                          }}
                        >
                          {tag.name}
                        </p>
                      </div>
                    ))}
                  </div>
                </InfiniteScroll>
              )}
            </div>
          </div>
        </Popover>
      </div>
    </div>
  )
}

export default TagSearchImage
