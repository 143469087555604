import { clientUrl } from "@/constants"
import { useTempState } from "@/hooks"
import { googleAnalytics } from "@/lib/gtag"
import { useAuth } from "@/providers/authContext"
import { useShareProfileStore } from "@/stores"
import { useQueryClient } from "@tanstack/react-query"
import classNames from "classnames"
import { AnimatePresence, motion } from "framer-motion"
import useCustomRouter from "@/hooks/useCustomRouter"
import { useMemo, useState } from "react"
import { MdCheck, MdOutlineFileCopy } from "react-icons/md"
import Modal from "../Modal"
import { SocialAlphaButtons } from "./ShareSocialModal"

const ShareProfileModal = () => {
  const [shareProfileModal, setShareProfileModal] = useShareProfileStore(state => [
    state.shareProfileModal,
    state.setShareProfileModal,
  ])
  const [isCopying, setIsCopying] = useTempState(false, 500)
  const [count, setCount] = useState(0)
  const { userInfoQuery } = useAuth()
  const qc = useQueryClient()
  const router = useCustomRouter()
  const { query } = router

  const link = useMemo(() => `${clientUrl}/profile/${query?.username}`, [query?.username])

  const handleCopy = () => {
    setIsCopying(true)
    setCount(prev => prev + 1)

    googleAnalytics.event({
      action: "click",
      category: "share_image",
      label: "share_link_image",
      params: {
        share_public_link: link,
      },
    })

    navigator.clipboard.writeText(link)
  }

  const handleClose = () => {
    setShareProfileModal(false)
  }

  return (
    <Modal
      title={"Share profile"}
      className="overflow-visible max-w-md text-[#EAE4D4] modal-content-child"
      isOpen={shareProfileModal}
      onClose={handleClose}
    >
      <p className="text-sm mb-2 text-[#858175]">Share URL</p>
      <div className={classNames("border border-[#313131] rounded-md p-2 flex items-center overflow-hidden mb-4")}>
        <p
          className="truncate flex-1 mr-4 text-[#EAE4D4] select-none cursor-pointer"
          onClick={e => {
            e.stopPropagation()
            e.preventDefault()
            handleCopy()
          }}
        >
          {link}
        </p>
        <AnimatePresence initial={false} mode="popLayout">
          {!isCopying ? (
            <motion.button
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              key="btn-copy"
              onClick={e => {
                e.stopPropagation()
                e.preventDefault()
                handleCopy()
              }}
            >
              <MdOutlineFileCopy className="fill-atherGray-400" />
            </motion.button>
          ) : (
            <motion.button initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
              <MdCheck key="btn-check" className="fill-green-500" />
            </motion.button>
          )}
        </AnimatePresence>
      </div>
      <div className="mb-4">
        <p className="text-sm text-[#858175] mb-2">Share social</p>
        <div className={classNames("flex space-x-4")}>
          <SocialAlphaButtons isShareProfile mutateShareSocial={() => {}} urlImageDetails={link} />
        </div>
      </div>
    </Modal>
  )
}

export default ShareProfileModal
