import { ArrowDownIcon } from "@/components/shared/icons"
import { useOutsideClick } from "@/hooks"
import { cn } from "@/lib/utils"
import { AnimatePresence, motion } from "framer-motion"
import React, { memo, useRef, useState } from "react"
import { useCanvasClick } from "../use-canvas-click"

interface SelectNodesProps {
  grapNodes: { id: string; type: string }[]
  value: string | null
  onChange: (value: string | null) => void
  className?: string
}

const SelectNodes = ({ grapNodes, value, onChange, className }: SelectNodesProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const ref = useRef(null)

  useCanvasClick(() => {
    setIsOpen(false)
  })

  const handleSelect = (id: string) => {
    onChange(id)
    setIsOpen(false)
  }

  useOutsideClick({
    ref,
    enabled: isOpen,
    handler: () => setIsOpen(false),
  })

  return (
    <div className={cn("relative", className)} ref={ref}>
      <div
        className="cursor-pointer text-atherGray-300 flex items-center text-sm"
        onClick={() => setIsOpen(prev => !prev)}
      >
        <p className="mr-2">{value ? `Node #${value}` : "Select Node"}</p>
        <ArrowDownIcon className="text-atherGray-300" width={12} height={12} />
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div className="absolute top-full left-0 w-full shadow-sm py-2 bg-atherGray-850 rounded-lg z-[1]">
            <div className="flex flex-col px-2 overflow-auto max-h-[22rem]">
              {grapNodes
                .sort((a, b) => Number(a.id) - Number(b.id))
                .map(node => (
                  <div
                    className="p-2 rounded-lg text-sm cursor-pointer hover:bg-atherGray-800"
                    key={node.id}
                    onClick={() => handleSelect(node.id)}
                  >
                    #{node.id}. {node.type}
                  </div>
                ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default memo(SelectNodes)
