import React, { useMemo } from "react"
import { WorkflowPublishType } from "./WorkFlowDetailStep"
import { RecipeTaskChainParams } from "../WorkflowsChaining"
import classNames from "classnames"
import { useExpandViewImage } from "@/stores"
import { useAuth } from "@/providers/authContext"
import ImageLoadingItem from "../ImageLoadingItem"
import ImageItem from "@/components/Explore/Gallery/ImageItem"
import { ImageSimpleDetail, RecipeTaskStatus } from "@/api/sdk"
import { useCallbackOnHotKeys } from "@/hooks"
import { MultiselectImagesProps } from "../../Recipes/RecipeDetail/ListRecipes"
import TextCopy from "@/components/TextCopy"
import { cdnPublicFolderUrl } from "@/constants"
import AspectRatio from "@/components/AspectRatio"

interface ResultImagesProps extends MultiselectImagesProps {
  workflow: WorkflowPublishType
  fullDataImages: (ImageSimpleDetail & { outputText?: string })[]
  hiddenSelect?: boolean
  refetchHistory: () => void
  isExplore?: boolean
  containerWidth?: number
  isDisabledInView?: boolean
  images?: ImageSimpleDetail[]
  selectionMode?: boolean
  recipeId?: string
  mode: "MACRO" | "RECIPE"
}

const ResultImages = ({
  workflow,
  fullDataImages = [],
  containerWidth,
  selectionMode,
  onCheck,
  mode,
  selectedItems,
  recipeId,
  isExplore,
  hiddenSelect,
  images,
  isDisabledInView,
  refetchHistory,
}: ResultImagesProps) => {
  const { expandViewImage, setExpandViewImage } = useExpandViewImage()
  const { user } = useAuth()

  const isExpand = useMemo(() => {
    if (workflow.creator?.uid === "anonymous") return false

    return user?.uid !== workflow.creator?.uid
  }, [user?.uid, workflow.creator?.uid])

  useCallbackOnHotKeys(
    "arrowright",
    () => {
      if (images) {
        const idx = images.findIndex(img => img.id === expandViewImage?.id)
        if (idx !== -1 && idx < images.length - 1) {
          setExpandViewImage({
            id: images[idx + 1].id,
            url: images[idx + 1].url,
            name: images[idx + 1].name ?? images[idx + 1].id,
          })
        }
      }
    },
    !!expandViewImage && isExpand,
  )

  useCallbackOnHotKeys(
    "arrowleft",
    () => {
      if (images) {
        const idx = images.findIndex(img => img.id === expandViewImage?.id)
        if (idx !== -1 && idx > 0) {
          setExpandViewImage({
            id: images[idx - 1].id,
            url: images[idx - 1].url,
            name: images[idx - 1].name ?? images[idx - 1].id,
          })
        }
      }
    },
    !!expandViewImage && isExpand,
  )

  return (
    <div>
      {fullDataImages.length > 0 && mode === "MACRO" ? (
        <div className="flex flex-col space-y-2">
          {fullDataImages.map(
            (i, idx) =>
              i.outputText && (
                <div key={i.id} className="text-atherGray-300 text-sm">
                  <div className="flex items-center justify-between mb-1">
                    <p className="uppercase text-xs font-semibold mr-2">Output text{idx === 0 ? ":" : `${idx + 1}:`}</p>
                    <TextCopy value={i.outputText} />
                  </div>
                  <p>{i.outputText}</p>
                </div>
              ),
          )}
        </div>
      ) : null}
      <div
        className={classNames(
          "grid gap-2 w-full grid-cols-[repeat(auto-fill,minmax(10rem,_1fr))] step-generated-images",
          {
            hidden: workflow.outputText && fullDataImages.length === 0,
          },
        )}
      >
        {fullDataImages.length > 0 ? (
          workflow.status !== RecipeTaskStatus.FAILED &&
          fullDataImages?.map((img, idx) => (
            <div key={img.id ?? idx} className={classNames("w-full")}>
              {workflow.status === RecipeTaskStatus.QUEUED ||
              (workflow.status === RecipeTaskStatus.RUNNING && !img.url) ? (
                <AspectRatio>
                  <ImageLoadingItem image={img} height={"100%"} />
                </AspectRatio>
              ) : (
                <AspectRatio>
                  <ImageItem
                    selectionMode={selectionMode}
                    isChecked={selectedItems?.some(item => item.id === img.id)}
                    onCheck={() => onCheck?.(img)}
                    classNameItem="rounded-none"
                    className={classNames("h-full")}
                    isExplore={isExplore}
                    hiddenReaction
                    hiddenTypeImage
                    filters={{
                      recipeId,
                    }}
                    stretch
                    hiddenSelectPrompt
                    hiddenMenu
                    isDisabledInView={isDisabledInView}
                    refetchImages={refetchHistory}
                    containerWidth={containerWidth}
                    data={img}
                    images={images}
                    onSelect={
                      isExpand || hiddenSelect
                        ? () => {
                            setExpandViewImage({
                              id: img.id,
                              url: img.url,
                              name: img.name ?? img.id,
                            })
                          }
                        : undefined
                    }
                  />
                </AspectRatio>
              )}
            </div>
          ))
        ) : (
          <div className="h-[10rem] flex items-center justify-center bg-atherGray-950">
            <img src={`${cdnPublicFolderUrl}/not-found-image.png`} alt="" className="max-h-[6rem]" />
          </div>
        )}
      </div>
    </div>
  )
}

export default ResultImages
