import client from "@/api/client"
import { ChatMessage } from "@/api/sdk"
import { createAuthenticatedInfiniteQuery } from "@/queries/createAuthenticatedQuery"
import { enableInfiniteQueryMiddleware } from "@/queries/middlewares/enableQueryMiddleware"

export const useWorkspaceRoomMessagesInfiniteQuery = createAuthenticatedInfiniteQuery<
  ChatMessage[],
  { roomId: string },
  Error,
  ChatMessage
>({
  primaryKey: "infinite-workspace-messages-room",
  queryFn: ({ queryKey: [_primaryKey, variables], pageParam = 0 }) =>
    client.api
      .chatRoomControllerGetMessagesByRoom(variables.roomId, {
        skip: pageParam * 50,
        take: 50,
        roomId: variables.roomId,
      })
      .then(res => res.data),
  getNextPageParam: (lastPage, pages) => (lastPage && lastPage.length < 50 ? undefined : pages.length),
  initialPageParam: 0,
  flattenFn: data => data,
  use: [enableInfiniteQueryMiddleware(v => !!v.roomId)],
})
