import { useAuth } from "@/providers/authContext"
import { InfiniteData, QueryClient } from "@tanstack/react-query"
import {
  CreateInfiniteQueryOptions,
  CreateQueryOptions,
  DefinedInfiniteQueryHookOptions,
  DefinedInfiniteQueryHookResult,
  InfiniteQueryHook,
  InfiniteQueryHookOptions,
  InfiniteQueryHookResult,
  createInfiniteQuery,
  createQuery,
} from "react-query-kit"
import { authMiddleware } from "./middlewares/authMiddleware"

export const createAuthenticatedQuery = <TFnData, TVariables = void, TError = Error>(
  options: CreateQueryOptions<TFnData, TVariables, TError>,
) => {
  return createQuery({
    ...options,
    use: [
      ...Array.from(options.use || []),
      useQueryNext => {
        return options => {
          const { tokenFormat, loading } = useAuth()
          const enabled = !loading ? (!!tokenFormat ? options.enabled : false) : false

          return useQueryNext({
            ...options,
            enabled,
          })
        }
      },
    ],
  })
}

export interface CreateAuthenticatedInfiniteQueryOptions<TFnData, TVariables = void, TError = Error, TItem = unknown>
  extends CreateInfiniteQueryOptions<TFnData, TVariables, TError> {
  flattenFn?: (data: TFnData) => TItem[]
  getTotalFn?: (data: TFnData) => number
  auth?: boolean
}

export interface AuthencatedInfiniteQueryHook<TFnData, TItem, TVariables, TError>
  extends InfiniteQueryHook<TFnData, TVariables, TError, number> {
  <TData = InfiniteData<TFnData, number>>(
    options: DefinedInfiniteQueryHookOptions<TFnData, TError, TData, TVariables, number>,
    queryClient?: QueryClient,
  ): DefinedInfiniteQueryHookResult<TData, TError> & {
    flattenData?: TItem[]
    total: number
    length: number
  }
  <TData = InfiniteData<TFnData, number>>(
    options?: InfiniteQueryHookOptions<TFnData, TError, TData, TVariables, number>,
    queryClient?: QueryClient,
  ): InfiniteQueryHookResult<TData, TError> & {
    flattenData?: TItem[]
    total: number
    length: number
  }
}

export const createAuthenticatedInfiniteQuery = <TFnData, TVariables = void, TError = Error, TItem = unknown>(
  options: CreateAuthenticatedInfiniteQueryOptions<TFnData, TVariables, TError, TItem>,
): AuthencatedInfiniteQueryHook<TFnData, TItem, TVariables, TError> => {
  const requiredAuth = options.auth ?? true

  const query = createInfiniteQuery({
    ...options,
    use: [
      ...Array.from(options.use || []),
      useQueryNext => {
        return opts => {
          const query = useQueryNext(opts) as InfiniteQueryHookResult<InfiniteData<TFnData>, TError>
          return {
            ...query,
            flattenData: options.flattenFn ? query.data?.pages.flatMap(options.flattenFn) : [],
            length: options.flattenFn ? query.data?.pages.flatMap(options.flattenFn).length : 0,
            total: options.getTotalFn && query.data?.pages[0] ? options.getTotalFn(query.data.pages[0]) : 0,
          }
        }
      },
      ...(requiredAuth ? [authMiddleware] : []),
    ],
  })

  return query as AuthencatedInfiniteQueryHook<TFnData, TItem, TVariables, TError>
}
