import client from "@/api/client"
import { createAuthenticatedQuery } from "../createAuthenticatedQuery"
import { SubscriptionPackageItem } from "@/api/sdk"

export const useGetSubscriptionDetailQuery = createAuthenticatedQuery<
  SubscriptionPackageItem,
  { subscriptionId: string },
  Error
>({
  primaryKey: "subscription-payment-detail",
  queryFn: ({ queryKey: [_primaryKey, variables] }) =>
    client.api.paymentControllerGetSubscriptionPackageById(variables.subscriptionId).then(res => res.data),
})
