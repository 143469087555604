import ScrollHorizontalContainer from "@/components/Alpha/ScrollHorizontalContainer"
import { IconProps } from "@/components/shared/icons"
import { googleAnalytics } from "@/lib/gtag"
import { cn } from "@/utils/cn"
import useCustomRouter from "@/hooks/useCustomRouter"
import { useEffect, useRef, useState } from "react"

interface TabsListProps {
  tabQuery: string
  tabsData: {
    title: string
    id: string
    count?: number
    icon?: (props: IconProps) => JSX.Element
  }[]
  onChange?: (tab: string) => void
  tabClassName?: string
  className?: string
  hiddenBorder?: boolean
  marginLeft?: string
}

const TabsList = ({
  tabQuery,
  tabsData,
  tabClassName,
  onChange,
  className,
  marginLeft = "1.5rem",
  hiddenBorder,
}: TabsListProps) => {
  const router = useCustomRouter()

  const [[indicatorLeft, indicatorWidth], setIndicatorPosition] = useState([0, 0])

  const handleChangeTab = (tab: string) => {
    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        action: "Change Tab",
        tab_name: tab,
      },
    })

    if (onChange) {
      onChange(tab)
      return
    }

    router.push({ query: { ...router.query, tab } }, undefined, { shallow: true })
  }

  useEffect(() => {
    const handleBorderChange = () => {
      const tabList = tabListRef.current!
      const selectedTab = tabRefs.current[tabQuery]!

      if (!tabList || !selectedTab) return

      const tabListRect = tabList.getBoundingClientRect()
      const selectedTabRect = selectedTab?.getBoundingClientRect()

      const indicatorLeft = selectedTabRect.left - tabListRect.left - tabList.scrollLeft
      const indicatorWidth = selectedTabRect.width

      setIndicatorPosition([indicatorLeft, indicatorWidth])
    }

    setTimeout(() => {
      handleBorderChange()
    }, 0)
  }, [tabQuery, tabsData])

  const tabListRef = useRef<HTMLDivElement>(null)
  const tabRefs = useRef<Partial<Record<string, HTMLDivElement>>>({})

  return (
    <div className="relative overflow-hidden" role="tablist">
      <ScrollHorizontalContainer valueKey={tabsData.find(tab => tab.id === tabQuery)?.id} className="pt-0 pb-1 z-[1]">
        <div className={cn("flex items-center relative py-2", className)} ref={tabListRef}>
          {tabsData.map((tab, index) => (
            <div
              key={tab.id}
              role="tab"
              id={tab.id}
              style={{
                marginLeft: marginLeft,
              }}
              className={cn(
                "font-semibold text-atherGray-500 cursor-pointer flex items-center whitespace-nowrap",
                {
                  "text-white": tab.id === tabQuery,
                  "!ml-0": index === 0,
                },
                tabClassName,
              )}
              onClick={() => handleChangeTab(tab.id)}
              ref={ref => {
                tabRefs.current[tab.id] = ref as HTMLDivElement
              }}
            >
              {tab.icon && <tab.icon className="mr-1" width={16} height={16} />}
              {tab.title}
              {(tab.count ?? 0) > 0 && <> ({tab.count})</>}
            </div>
          ))}
          <div
            className="absolute bg-atherPurple-500 h-1 bottom-0 transition-all duration-300"
            style={{ left: indicatorLeft, width: indicatorWidth }}
          />
        </div>
      </ScrollHorizontalContainer>
      {!hiddenBorder && <hr className="border-b border-atherGray-700 absolute bottom-1 w-full inset-x-0" />}
    </div>
  )
}

export default TabsList
