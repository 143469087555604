import client from "@/api/client"
import { actionMiddleware } from "@/queries/middlewares/actionMiddleware"
import { createMutation } from "react-query-kit"
import { PinnedItemType, PinnedItems, RecentImageItem, SearchImagesResponse } from "@/api/sdk"
import { InfiniteData } from "@tanstack/react-query"
import { useWorkspaceImagesInfiniteQuery } from "../useWorkspaceImagesInfiniteQuery"
import { useRecentImagesInfiniteQuery } from "@/components/Workspace/RecentV2/RecentImages"
import { useWorkspacePinInfiniteQuery } from "../useWorkspacePinInfiniteQuery"
import { useWorkspaceCreationImagesInfiniteQuery } from "./useWorkspaceCreationImagesInfiniteQuery"

export const useRenameImageMutation = createMutation({
  mutationFn: async ({ name, id }: { name: string; id: string; folderId?: string }) => {
    return await client.api
      .imageControllerRenameImage(id, {
        name,
      })
      .then(res => res.data)
  },
  use: [
    actionMiddleware({
      onSuccess: ({ queryClient, variables }) => {
        const workspaceImagesKey = useWorkspaceImagesInfiniteQuery.getKey()
        const workspaceImagesQueriesDataEntries = queryClient.getQueriesData<
          InfiniteData<SearchImagesResponse, number>
        >({
          queryKey: workspaceImagesKey,
        })
        if (workspaceImagesQueriesDataEntries) {
          workspaceImagesQueriesDataEntries.forEach(([key, parentSubFoldersData]) => {
            if (parentSubFoldersData) {
              const updatedData = {
                ...parentSubFoldersData,
                pages: parentSubFoldersData.pages.map(page => {
                  return {
                    ...page,
                    images: page.images.map(image => {
                      if (image.id === variables.id) {
                        return {
                          ...image,
                          name: variables.name,
                        }
                      }
                      return image
                    }),
                  }
                }),
              }
              queryClient.setQueryData(key, updatedData)
            }
          })
        }

        // Update images recent list
        const imagesRecentListKey = useRecentImagesInfiniteQuery.getKey()

        const imagesRecentListQueriesDataEntries = queryClient.getQueriesData<InfiniteData<RecentImageItem[], number>>({
          queryKey: imagesRecentListKey,
        })

        if (imagesRecentListQueriesDataEntries) {
          imagesRecentListQueriesDataEntries.forEach(([key, imagesRecentListData]) => {
            if (imagesRecentListData) {
              const updatedData = {
                ...imagesRecentListData,
                pages: imagesRecentListData.pages.map(page => {
                  return page.map(image => {
                    if (image.image.id === variables.id) {
                      return {
                        ...image,
                        image: {
                          ...image.image,
                          name: variables.name,
                        },
                      }
                    }
                    return image
                  })
                }),
              }

              queryClient.setQueryData(key, updatedData)
            }
          })
        }

        // Update pinned items
        const imagePinnedListKey = useWorkspacePinInfiniteQuery.getKey({
          type: PinnedItemType.IMAGE,
        })

        const imagePinnedListQueriesDataEntries = queryClient.getQueriesData<InfiniteData<PinnedItems[], number>>({
          queryKey: imagePinnedListKey,
        })

        if (imagePinnedListQueriesDataEntries) {
          imagePinnedListQueriesDataEntries.forEach(([key, imagePinnedListData]) => {
            if (imagePinnedListData) {
              const updatedData = {
                ...imagePinnedListData,
                pages: imagePinnedListData.pages.map(page => {
                  return page.map(image => {
                    if (variables.id === image.image?.id) {
                      return {
                        ...image,
                        image: {
                          ...image.image,
                          name: variables.name,
                        },
                      }
                    }
                    return image
                  })
                }),
              }

              queryClient.setQueryData(key, updatedData)
            }
          })
        }

        // Update images creation
        const imagesCreationKey = useWorkspaceCreationImagesInfiniteQuery.getKey()

        const imagesCreationQueriesDataEntries = queryClient.getQueriesData<InfiniteData<SearchImagesResponse, number>>(
          {
            queryKey: imagesCreationKey,
          },
        )

        if (imagesCreationQueriesDataEntries) {
          imagesCreationQueriesDataEntries.forEach(([key, imagesCreationData]) => {
            if (imagesCreationData) {
              const updatedData = {
                ...imagesCreationData,
                pages: imagesCreationData.pages.map(page => {
                  return {
                    ...page,
                    images: page.images.map(image => {
                      if (variables.id === image.id) {
                        return {
                          ...image,
                          name: variables.name,
                        }
                      }
                      return image
                    }),
                  }
                }),
              }

              queryClient.setQueryData(key, updatedData)
            }
          })
        }
      },
    }),
  ],
})
