import client from "@/api/client"
import { GetRecipeCategoriesResult, GetRecipesResult, RecipeItem, SearchRecipesDto } from "@/api/sdk"
import { createAuthenticatedInfiniteQuery, createAuthenticatedQuery } from "@/queries/createAuthenticatedQuery"
import { enableQueryMiddleware } from "@/queries/middlewares/enableQueryMiddleware"
import { createInfiniteQuery, createMutation, createQuery } from "react-query-kit"

export const useGetRecipesInfiniteQuery = createAuthenticatedInfiniteQuery<
  GetRecipesResult,
  SearchRecipesDto,
  Error,
  RecipeItem
>({
  primaryKey: "recipes-infinite",
  queryFn: ({ queryKey: [_primaryKey, variables], pageParam = 0 }) =>
    client.api
      .recipeControllerSearchRecipes({
        skip: pageParam * (variables.take ?? 20),
        take: variables.take ?? 20,
        searchTerm: variables.searchTerm,
        onlyDiscoverable: variables.onlyDiscoverable,
        onlyShared: variables.onlyShared,
        sortBy: variables.sortBy,
        mode: variables.mode,
        types: variables.types,
        isPinned: variables.isPinned,
        baseModel: variables.baseModel,
        categoryId: variables.categoryId,
        sdWorkflowId: variables.sdWorkflowId,
        recipeIds: variables.recipeIds,
        canUseImage: variables.canUseImage,
        canUseWildcard: variables.canUseWildcard,
        canUseLora: variables.canUseLora,
        onlyMine: variables.onlyMine,
      })
      .then(res => res.data),
  flattenFn: data => data.recipes,
  getTotalFn: data => data.total,
  getNextPageParam: (lastPage, pages) => (lastPage && lastPage?.recipes.length < 20 ? undefined : pages.length),
  initialPageParam: 0,
  refetchOnWindowFocus: false,
})

export const useGetRecipesNotAuthInfiniteQuery = createInfiniteQuery<GetRecipesResult, SearchRecipesDto, Error>({
  primaryKey: "recipes-infinite-not-auth",
  queryFn: ({ queryKey: [_primaryKey, variables], pageParam = 0 }) =>
    client.api
      .recipeControllerSearchRecipes({
        skip: pageParam * (variables.take ?? 20),
        take: variables.take ?? 20,
        searchTerm: variables.searchTerm,
        sortBy: variables.sortBy,
        mode: variables.mode,
        types: variables.types,
        baseModel: variables.baseModel,
        categoryId: variables.categoryId,
        sdWorkflowId: variables.sdWorkflowId,
        recipeIds: variables.recipeIds,
        canUseImage: variables.canUseImage,
        canUseWildcard: variables.canUseWildcard,
        canUseLora: variables.canUseLora,
      })
      .then(res => res.data),
  getNextPageParam: (lastPage, pages) => (lastPage && lastPage?.recipes.length < 20 ? undefined : pages.length),
  initialPageParam: 0,
  refetchOnWindowFocus: false,
})

export const useGetRecipesQuery = createQuery<GetRecipesResult, SearchRecipesDto & { userUid?: string }, Error>({
  primaryKey: "recipes-list",
  queryFn: ({ queryKey: [_primaryKey, variables] }) =>
    client.api
      .recipeControllerSearchRecipes({
        searchTerm: variables.searchTerm,
        take: variables.take ?? 20,
        sortBy: variables.sortBy,
        mode: variables.mode,
        types: variables.types,
        baseModel: variables.baseModel,
        recipeIds: variables.recipeIds,
        categoryId: variables.categoryId,
      })
      .then(res => res.data),
})

export const useGetRecipesMutation = createMutation({
  mutationFn: (variables: SearchRecipesDto) =>
    client.api
      .recipeControllerSearchRecipes({
        searchTerm: variables.searchTerm,
        take: variables.take ?? 20,
        sortBy: variables.sortBy,
        mode: variables.mode,
        types: variables.types,
        baseModel: variables.baseModel,
        recipeIds: variables.recipeIds,
        categoryId: variables.categoryId,
      })
      .then(res => res.data.recipes),
})

export const useGetRecipeDetailQuery = createAuthenticatedQuery<RecipeItem, { recipeId: string }, Error>({
  primaryKey: "recipe-detail",
  queryFn: ({ queryKey: [_primaryKey, variables] }) =>
    client.api.recipeControllerGetById(variables.recipeId).then(res => res.data),
  refetchOnWindowFocus: false,
  use: [enableQueryMiddleware(v => !!v.recipeId)],
})

export const useGetRoomRecipeDetailQuery = createAuthenticatedQuery<
  RecipeItem,
  { recipeId: string; roomId: string },
  Error
>({
  primaryKey: "room-recipe-detail",
  queryFn: ({ queryKey: [_primaryKey, variables] }) =>
    client.api.chatRoomControllerGetRoomRecipe(variables.recipeId, variables.roomId).then(res => res.data),
  refetchOnWindowFocus: false,
  use: [enableQueryMiddleware(v => !!v.recipeId)],
})

export const useGetRecipeDetailNotAuthQuery = createQuery<RecipeItem, { recipeId: string }, Error>({
  primaryKey: "recipe-detail-not-auth",
  queryFn: ({ queryKey: [_primaryKey, variables] }) =>
    client.api.recipeControllerGetById(variables.recipeId).then(res => res.data),
  refetchOnWindowFocus: false,
  use: [enableQueryMiddleware(v => !!v.recipeId)],
})

export const useGetRecipeCategories = createQuery<GetRecipeCategoriesResult, unknown, Error>({
  primaryKey: "get-recipe-categories",
  refetchOnWindowFocus: false,
  queryFn: ({ queryKey: [_primaryKey] }) => client.api.recipeControllerGetRecipeCategories().then(res => res.data),
})
