import client from "@/api/client"
import { GetTagsResponse, PickTypeClass } from "@/api/sdk"
import { createAuthenticatedInfiniteQuery } from "./createAuthenticatedQuery"

export interface SearchTagInfiniteQuerVariables {
  tagIds?: number[]
  isInUse?: boolean
  isDiscoverable?: boolean
  searchQuery?: string
}

export const useSearchTagInfiniteQuery = createAuthenticatedInfiniteQuery<
  GetTagsResponse,
  SearchTagInfiniteQuerVariables,
  Error,
  PickTypeClass
>({
  primaryKey: "tags",
  queryFn: ({ pageParam = 0, queryKey: [_primaryKey, variables] }) =>
    client.api
      .tagsControllerGetTags({
        tagIds: variables.tagIds,
        skip: pageParam * 20,
        take: 20,
        isInUse: variables.isInUse,
        isDiscoverable: variables.isDiscoverable,
        searchQuery: variables.searchQuery,
      })
      .then(res => res.data),
  getNextPageParam: (lastPage, pages) => (lastPage && lastPage.tags.length < 20 ? undefined : pages.length),
  initialPageParam: 0,
  flattenFn: res => res.tags,
  getTotalFn: res => res.count,
  retry: false,
  auth: false,
})
