import client from "@/api/client"
import {
  ContentModerationRating,
  ImageFullDetail,
  ImageSimpleDetail,
  PinnedItemType,
  RecipeItem,
  RecipeType,
  WorkflowDetail,
} from "@/api/sdk"
import { useDownloadImage, useToast } from "@/hooks"
import { useMutation } from "@tanstack/react-query"
import classNames from "classnames"
import { useState } from "react"

import { COMFY_RECIPE_ID } from "@/components/Tools/ComfyUIRecipe/ComfyUIRecipeBuilder/ComfyUIDetail"
import { TRY_STYLE_RECIPE } from "@/components/Tools/Style/StyleDetail/StyleDetail"
import FolderActionRow from "@/components/Workspace/FolderActions/FolderActionRow"
import { useWorkflowDetailMutate } from "@/components/Workspace/Workflows/WorkflowItem/ActionButtonsWorkflow"
import { RecipeTaskChainParams } from "@/components/Workspace/Workflows/WorkflowsChaining"
import {
  ArrowExpandIcon,
  DeleteIcon,
  DownloadIcon1,
  FolderMoveIcon,
  OpenNewTabIcon,
  PinIcon,
  RefreshIcon,
  ScienceIcon,
  ShareIcon8,
  ThreeDotHorizontalIcon,
  UnPinIcon,
} from "@/components/shared/icons"
import { googleAnalytics } from "@/lib/gtag"
import useModalStore from "@/lib/store"
import { useAuth } from "@/providers/authContext"
import { useCreateTaskMutation, useGetTaskRunInfo, usePinMutation } from "@/queries"
import { useAddTaskMutation } from "@/queries/tools/comfyui-recipe"
import { useGetRecipesMutation } from "@/queries/workspace/recipe"
import {
  useDeleteItemStore,
  useExpandViewImage,
  useManagementErrorsStore,
  useMoveItemV2Store,
  useRecipeImageStore,
  useRecoverItemStore,
} from "@/stores"
import { cn } from "@/utils/cn"
import useCustomRouter from "@/hooks/useCustomRouter"
import { createMutation } from "react-query-kit"
import { twMerge } from "tailwind-merge"
import IconButton from "../../../IconButton"
import { useRecipeDetailMutate, useTaskDetailMutate, useWildcardListMutate } from "../../ImageDetail/ImageActionButtons"
import { recipeParamsWithWildcard, runAgainWorkflowParams } from "@/utils/task"
import Popover from "@/components/Popover"
interface ImageActionButtonsProps {
  image: ImageSimpleDetail & { onCheck?: () => void }
  refetchImages?: () => void
  isChecked?: boolean
  onSelect?: () => void
  className?: string
  isError?: boolean
  menuClassName?: string
  layout?: "grid" | "list"
  parentFolderId?: string
  isShow?: boolean
}

export const useImageDetailMutate = createMutation<ImageFullDetail, { imageId: string }, Error>({
  mutationFn: ({ imageId }) => client.api.imageControllerGetImageDetail(imageId).then(res => res.data),
})

const ImageActionButtons = ({
  image,
  refetchImages,
  isChecked,
  layout = "grid",
  parentFolderId,
  isShow,
  menuClassName,
  onSelect,
  className,
  isError,
}: ImageActionButtonsProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const setExpandViewImage = useExpandViewImage(state => state.setExpandViewImage)
  const setDeletingItem = useDeleteItemStore(s => s.setDeletingItem)
  const setMovingItem = useMoveItemV2Store(s => s.setMovingItem)
  const [isDownloadingImage, setIsDownloadingImage] = useState(false)
  const setSharingData = useModalStore(state => state.setSharingData)
  const toast = useToast()
  const setRecoverItem = useRecoverItemStore(state => state.setRecoverItem)
  const setRecipeImage = useRecipeImageStore(state => state.setRecipeImage)
  const setErrorState = useManagementErrorsStore(state => state.setErrorState)
  const [tempWorkflow, setTempWorkflow] = useState<WorkflowDetail | null>(null)
  const { user, handleSignIn } = useAuth()
  const isDisabled = user?.uid !== image.owner?.uid
  const { openNewTab } = useCustomRouter()
  const router = useCustomRouter()

  const { mutate: mutatePinTask } = usePinMutation({
    onSuccess: () => {
      toast({
        status: "success",
        title: image.pinned ? "Image Unpinned" : "Image Pinned",
      })
    },
  })

  const { mutateAsync: mutateWildcardsRes, isPending: isLoadingGetWildcardList } = useWildcardListMutate()
  const { mutateAsync: mutateGetTask, isPending: isLoadingGetTask } = useGetTaskRunInfo({
    onError: error => {
      toast({
        status: "error",
        title: "Error",
        message: [error.message],
      })
    },
  })
  const { mutateAsync: mutateCreateTask, isPending: isPendingCreate } = useCreateTaskMutation({
    onSuccess: ({ id, recipeId }) => {
      setIsOpen(false)

      toast({
        status: "success",
        title: "Submitted! Your task is running",
        duration: 5000,
      })

      if (recipeId === "recipe-to-recipe") {
        setTimeout(() => {
          openNewTab(`/workspace/macros/${id}`)
        }, 1)
        return
      }

      setTimeout(() => {
        openNewTab(`/workspace/tools/recipes/${recipeId}`)
      }, 1)
    },
    onError: (err: any) => {
      if (
        err?.message.startsWith("Guest user does not have permission") ||
        err?.message.startsWith("Your subscription has ended")
      ) {
        setErrorState({
          isOpen: true,
          message: err.message,
        })
        return
      }

      if (err?.error === "Bad Request") {
        toast({ title: "Cannot Complete Request", message: [err.message], status: "error" })
      } else {
        toast({ title: "Error", message: [err.message], status: "error" })
      }
    },
  })

  const { mutateAsync: createTask, isPending: isCreatingTask } = useAddTaskMutation({
    onSuccess: ({ id, sdWorkflowId }) => {
      setIsOpen(false)

      setTimeout(() => {
        openNewTab(`/workspace/tools/comfyui/${id}`)
      }, 1)
    },
    onError: (err: any) => {
      if (
        err?.message.startsWith("Guest user does not have permission") ||
        err?.message.startsWith("Your subscription has ended")
      ) {
        setErrorState({
          isOpen: true,
          message: err.message,
        })
        return
      }
    },
  })
  const { mutateAsync: mutateGetRecipeDetail, isPending: isLoadingGetReipe } = useRecipeDetailMutate({
    onError: error => {
      toast({
        status: "error",
        title: "Error",
        message: [error.message],
      })
    },
  })

  const handlePin = () => {
    mutatePinTask({
      ids: [image.id],
      isPinned: !image.pinned,
      type: PinnedItemType.IMAGE,
      folderId: parentFolderId ?? image.folder?.id,
    })
  }

  const handleRecovery = () => {
    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        tab_name: "image",
        action: "Recover Image",
        image_id: image.id,
        image_name: image.name,
      },
    })

    setRecoverItem({
      ids: [image.id],
      type: "image",
      deletedAt: image.deletedAt ?? null,
      parentFolderId: parentFolderId ?? image.folder?.id,
    })
  }

  const { mutate: mutateDownloadImage } = useMutation({
    mutationFn: () => client.api.imageControllerDownloadImage({ imageIds: [image.id] }),
  })

  const downloadImage = useDownloadImage(image.url!, image.name ?? image.id, () => {
    mutateDownloadImage()
  })

  const handleDownloadImage = async () => {
    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        tab_name: "image",
        action: "Download Image",
        image_id: image.id,
        image_name: image.name,
      },
    })

    if (!image.url) return

    setIsDownloadingImage(true)
    setIsOpen(false)

    downloadImage().finally(() => {
      setIsDownloadingImage(false)
    })
  }

  const handleExpandImage = () => {
    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        tab_name: "image",
        action: "Expand Image",
        image_id: image.id,
        image_name: image.name,
      },
    })

    setExpandViewImage({
      id: image.id,
      name: image.name ?? image.id,
      url: image.url ?? "",
    })
  }

  const handleDelete = () => {
    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        tab_name: "image",
        action: "Delete Image",
        image_id: image.id,
        image_name: image.name,
      },
    })

    setIsOpen(false)
    setDeletingItem({
      deletedAt: isError ? "image-error" : image.deletedAt ?? null,
      id: image.id,
      type: "image",
      folderId: parentFolderId ?? image.folder?.id,
      onClose: () => {
        refetchImages?.()
      },
    })
  }

  const handleNewTab = () => {
    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        tab_name: "image",
        action: "Open in new tab",
        image_id: image.id,
        image_name: image.name,
      },
    })
    setIsOpen(false)

    setTimeout(() => {
      openNewTab(`${window.location.origin}/workspace/images/${image.id}`)
    }, 150)
  }

  const handleShareImage = async () => {
    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        tab_name: "image",
        action: "Share Image",
        image_id: image.id,
        image_name: image.name,
      },
    })

    const isSignIn = await handleSignIn()

    if (!isSignIn) return
    setSharingData({
      id: image.id,
      link: `${window.location.origin}/workspace/images/${image.id}`,
      name: image.name ?? image.id,
      type: "image",
      creator: image.owner,
      folderId: parentFolderId ?? image.folder?.id,
      workspaceId: image.workspace?.id,
    })
  }

  const handleMoveImage = () => {
    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        tab_name: "image",
        action: "Move Image",
        image_id: image.id,
        image_name: image.name,
      },
    })

    setMovingItem({
      type: "image",
      parentFolderId: parentFolderId ?? image.folder?.id,
      images: [
        {
          id: image.id,
          name: image.name,
          parentFolderId: parentFolderId ?? image.folder?.id ?? null,
        },
      ],
    })
  }

  const { mutateAsync: mutateGetImageDetail, isPending: isLoadingGetImageDetail } = useImageDetailMutate()

  const handleRecipe = async () => {
    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        tab_name: "image",
        action: "Send to recipe",
        image_id: image.id,
        image_name: image.name,
      },
    })

    const isSignIn = await handleSignIn()

    if (!isSignIn) return

    const imageDetail = await mutateGetImageDetail({ imageId: image.id })

    const params = Object.values(imageDetail.metadata ?? {}).reduce((acc, value) => {
      acc[value.key] = value.value
      return acc
    }, {})

    const modelHash = {
      model_hash: params["modelHash"],
      modelHash: params["model_hash"],
    }

    setRecipeImage({
      id: imageDetail.recipe?.id ?? imageDetail.id,
      isModal: true,
      params: {
        ...modelHash,
        ...params,
        image: imageDetail.url,
        prompt: imageDetail.prompt,
        negative_prompt: imageDetail.negativePrompt,
      },
    })
  }

  const { mutateAsync: mutateGetWorkflow, isPending: isPendingGetWorkflow } = useWorkflowDetailMutate({
    onSuccess: data => {
      setTempWorkflow(data)

      handleRunAgainImageWithWorkflow(data)
    },
    onError: error => {
      toast({
        status: "error",
        title: "Error",
        message: [error.message],
      })
    },
  })
  const { mutateAsync: mutateGetRecipes, isPending: isMutatingRecipes } = useGetRecipesMutation()

  const handleRunAgainImageWithWorkflow = async (workflow: WorkflowDetail) => {
    if (!workflow || workflow.deletedAt) {
      toast({
        status: "error",
        title: "Macro is not available",
        message: ["This workflow is not available, please try another one."],
      })

      return
    }
    if (!workflow) return

    const recipes = await mutateGetRecipes({
      types: [RecipeType.Normal, RecipeType.Describe],
      recipeIds: workflow.params.map(i => i.recipeId),
      take: workflow.params.length,
    })

    for (let i = 0; i < workflow.params.length; i++) {
      const param = workflow.params[i]

      if (
        !recipes
          .filter(r => !r.deletedAt)
          .map(i => i.id)
          .includes(param.recipeId)
      ) {
        toast({
          status: "error",
          title: "Can not run workflow!",
          message: [`Recipe of step ${i + 1} is not found.`],
        })
        return
      }
    }

    const mappedParams = await runAgainWorkflowParams(workflow)

    mutateCreateTask({
      params: mappedParams as RecipeTaskChainParams[],
      recipeId: "recipe-to-recipe",
      folderId: workflow.folder.id,
      name: workflow.name,
    })
  }

  const handleRunAgainImageWithRecipe = async (recipe: RecipeItem, image: ImageFullDetail) => {
    if (!recipe || recipe.deletedAt) {
      toast({
        status: "error",
        title: "Recipe is not available",
        message: ["This recipe is not available, please try another one."],
      })

      return
    }

    const params = Object.values(image.metadata ?? {}).reduce((acc, value) => {
      acc[value.key] = value.value
      return acc
    }, {})

    const recipeInputs = recipe.steps?.map(step => step.inputs).flat()
    const hasWildcardField = recipeInputs.some(input => input.allowWildcard)

    let newParams = {
      ...params,
      prompt: image.prompt,
      negativePrompt: image.negativePrompt,
    } as Record<string, string>

    if (hasWildcardField) {
      newParams = await recipeParamsWithWildcard(newParams, recipeInputs)
    }

    if (image.recipe?.id === COMFY_RECIPE_ID) {
      const task = await mutateGetTask({ taskId: image.recipeTask?.id ?? "" })

      if (!task) return

      createTask({
        params: task.params as RecipeTaskChainParams,
        recipeId: COMFY_RECIPE_ID,
        sdWorkflowId: task.sdWorkflowId,
      })

      return
    }

    mutateCreateTask({
      folderId: image.folder?.id ?? "",
      recipeId: image.recipe?.id ?? "",
      params: newParams,
    })
  }

  const isMutating =
    isPendingCreate ||
    isPendingGetWorkflow ||
    isLoadingGetReipe ||
    isLoadingGetWildcardList ||
    isCreatingTask ||
    isLoadingGetTask ||
    isLoadingGetImageDetail ||
    isMutatingRecipes

  const handleRunAgain = async () => {
    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        tab_name: "image",
        action: "Run Again Image",
        image_id: image.id,
        image_name: image.name,
        image_workflow_id: image.workflow?.id ?? "",
        image_recipe_id: image.recipe?.id ?? "",
      },
    })

    const imageDetail = await mutateGetImageDetail({ imageId: image.id })

    if (imageDetail.workflow) {
      if (tempWorkflow) {
        handleRunAgainImageWithWorkflow(tempWorkflow)

        return
      }

      await mutateGetWorkflow({ workflowId: imageDetail.workflow.id })

      setIsOpen(false)

      return
    }

    if (imageDetail.recipe) {
      const recipe = await mutateGetRecipeDetail({ recipeId: imageDetail.recipe.id })

      handleRunAgainImageWithRecipe(recipe, imageDetail)

      setIsOpen(false)

      return
    }
  }

  const actions = [
    {
      label: "Run Again",
      onClick: handleRunAgain,
      icon: <RefreshIcon width={16} height={16} className={"text-atherGray-300"} />,
      isLoading: isMutating,
      enabled: !image.deletedAt,
      visible:
        !isDisabled &&
        (image.workflow || image.recipe) &&
        !image.deletedAt &&
        image.recipe?.id !== COMFY_RECIPE_ID &&
        image.recipe?.id !== TRY_STYLE_RECIPE &&
        image.moderationRating !== ContentModerationRating.HardcoreNsfw,
    },
    {
      label: "Send to recipe",
      icon: <ScienceIcon width={16} height={16} />,
      onClick: handleRecipe,
      visible: !image.deletedAt,
      enabled: image.capabilities?.canView,
    },
    {
      label: "Download",
      icon: <DownloadIcon1 width={16} height={16} />,
      onClick: handleDownloadImage,
      isLoading: isDownloadingImage,
      enabled: !!image.url,
      visible: true,
    },
    {
      label: "Expand",
      icon: <ArrowExpandIcon width={16} height={16} />,
      onClick: handleExpandImage,
      visible: true,
      enabled: true,
    },
    {
      label: "Share",
      icon: <ShareIcon8 width={16} height={16} />,
      onClick: handleShareImage,
      visible: !image.deletedAt,
      enabled: image.capabilities?.canShare,
    },
    {
      label: image.pinned ? "Unpin" : "Pin",
      icon: image.pinned ? <UnPinIcon width={16} height={16} /> : <PinIcon width={16} height={16} />,
      onClick: handlePin,
      visible: !image.deletedAt && image.owner?.uid !== "anonymous",
      enabled: image.capabilities?.canView,
    },
    {
      label: "Move",
      icon: <FolderMoveIcon width={16} height={16} />,
      onClick: handleMoveImage,
      visible: !image.deletedAt && image.owner?.uid !== "anonymous",
      enabled: image.capabilities?.canMove,
    },
    {
      label: "Open in new tab",
      icon: <OpenNewTabIcon width={16} height={16} />,
      onClick: handleNewTab,
      visible: true,
      enabled: true,
    },
    {
      label: "Recover",
      icon: <RefreshIcon width={16} height={16} />,
      onClick: handleRecovery,
      visible: image.deletedAt && image.owner?.uid !== "anonymous",
      enabled: image.capabilities?.canRecover,
    },
    {
      label: "Delete",
      icon: <DeleteIcon className="text-red-500" width={16} height={16} />,
      onClick: handleDelete,
      visible: image.owner?.uid !== "anonymous",
      enabled: image.capabilities?.canDelete,
    },
  ]

  return (
    <div draggable={false} className={twMerge(classNames("flex flex-row w-full items-center z-[1]"), className)}>
      {image.pinned !== undefined && image.pinned && (
        <div
          className={classNames("", {
            "opacity-0": isOpen,
            "relative top-auto right-auto opacity-100": layout === "list",
            "absolute top-2 right-12 lg:right-2 group-hover:opacity-0": layout === "grid",
          })}
        >
          <PinIcon width={16} height={16} />
        </div>
      )}
      <div
        className={cn(
          " transition-opacity duration-300",
          {
            "opacity-100 lg:opacity-100": isOpen,
            "absolute top-1.5 right-1.5 opacity-100 lg:opacity-0 group-hover:opacity-100": layout === "grid",
          },
          menuClassName,
        )}
      >
        <Popover
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          align="end"
          trigger={
            <IconButton
              colorScheme="secondary"
              className={classNames("bg-blackAlpha-700 px-2 min-h-[1.5rem]", {
                "bg-transparent hover:bg-transparent": menuClassName?.includes("bg-transparent"),
              })}
            >
              <ThreeDotHorizontalIcon width={14} height={14} />
            </IconButton>
          }
        >
          <div className="text-sm w-56 bg-atherGray-900 border border-atherGray-850 p-2 rounded-2xl space-y-1">
            {actions
              .filter(a => a.visible)
              .map(action => (
                <FolderActionRow
                  key={action.label}
                  isLoading={action.isLoading}
                  iconLeft={action.icon}
                  onClick={e => {
                    e.stopPropagation()
                    action.onClick()
                    if (action.label !== "Run Again") {
                      setIsOpen(false)
                    }
                  }}
                  isDisabled={!action.enabled}
                >
                  {action.label}
                </FolderActionRow>
              ))}
          </div>
        </Popover>
      </div>
    </div>
  )
}

export default ImageActionButtons
