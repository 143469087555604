import { IconProps } from "."

export const DownloadIcon = (props: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="13" viewBox="0 0 14 13" fill="none" {...props}>
      <path
        d="M7.0026 8.16667L7.0026 1.5M7.0026 8.16667C6.53579 8.16667 5.66362 6.83713 5.33594 6.5M7.0026 8.16667C7.46942 8.16667 8.34158 6.83713 8.66927 6.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.3346 9.5C12.3346 11.1547 11.9893 11.5 10.3346 11.5H3.66797C2.0133 11.5 1.66797 11.1547 1.66797 9.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
