import React, { FC, useMemo } from "react"
import { GetModelsResponse, SDBaseModel, SDModelVariant } from "@/api/sdk"
import client from "@/api/client"
import FormDetailThumbnailSelectComponent, {
  FormDetailThumbnailSelectProps,
  RenderSelectorFn,
  renderSelectorAsSelect,
} from "./FormDetailSelectWithThumbnail"
import ImageView from "@/components/ImageUpload/ImageView"
import { XIcon } from "@/components/shared/icons"
import { cn } from "@/lib/utils"
import { createQuery } from "react-query-kit"

export type FormDetailModelComponentProps = Omit<FormDetailThumbnailSelectProps, "data"> & {
  baseModel?: SDBaseModel
}

export type UseModelQueryType = {
  baseModel?: SDBaseModel
}

export const useModelQuery = createQuery<GetModelsResponse, UseModelQueryType, Error>({
  primaryKey: "list_models_support",
  queryFn: ({ queryKey: [_primaryKey, _variables] }) =>
    client.api
      .sdModelControllerList({
        baseModel: _variables.baseModel,
        variant: SDModelVariant.Normal,
      })
      .then(res => res.data),
})

export const renderSelector: RenderSelectorFn = renderProps => {
  const { value, isOpen, setIsOpen, data, input, onChange, disabled, error } = renderProps

  const item = data.find(i => i.id === value)

  return (
    <>
      {renderSelectorAsSelect(renderProps)}
      {item && (
        <div
          onClick={() => !disabled && setIsOpen(!isOpen)}
          className={cn("w-full flex h-12 items-center mt-2", {
            "cursor-pointer": !disabled,
          })}
        >
          <ImageView
            className="!h-12 w-12 rounded-lg overflow-hidden"
            imgClassName="w-12 object-cover"
            url={item.thumbnailUrl ?? ""}
          />
          <div className="pl-2 pr-0 flex-1 w-[calc(100%-3rem)]">
            <div className="flex gap-2 items-center">
              <p className="text-atherGray-300 text-xs flex-1 line-clamp-3" title={item.name}>
                {item.name}
              </p>
              <XIcon
                className={cn("flex-0 text-red-500 w-3 h-3", {
                  "cursor-pointer": !disabled,
                  "opacity-70": disabled,
                })}
                onClick={() =>
                  onChange({
                    key: input.key,
                    value: "",
                    clearTriggerKey: input.clearTriggerKey,
                    checked: false,
                  })
                }
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

const FormDetailModelComponent: FC<FormDetailModelComponentProps> = props => {
  const { baseModel, recipeInput, ...remainingProps } = props

  const others = { ...remainingProps, recipeInput }

  const { data, isLoading } = useModelQuery({
    variables: { baseModel: recipeInput?.baseModel ?? baseModel },
  })

  const initialData = useMemo(
    () => data?.models.map(m => ({ id: m.id, name: m.name, thumbnailUrl: m.thumbnailUrl, category: m.styles })) ?? [],
    [data],
  )

  return (
    <FormDetailThumbnailSelectComponent
      {...others}
      data={initialData}
      isLoadingData={isLoading}
      renderSelector={renderSelector}
    />
  )
}

export default FormDetailModelComponent
