import { IconProps } from "."

export const ETHIcon = (props: IconProps) => {
  return (
    <svg width="14" height="22" viewBox="0 0 14 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M7.43579 0.5V8.26125L13.9957 11.1925L7.43579 0.5Z" fill="white" fillOpacity="0.602" />
      <path d="M7.43575 0.5L0.875 11.1925L7.43575 8.26125V0.5Z" fill="white" />
      <path d="M7.43579 16.2221V21.4957L14 12.4141L7.43579 16.2221Z" fill="white" fillOpacity="0.602" />
      <path d="M7.43575 21.4957V16.2212L0.875 12.4141L7.43575 21.4957Z" fill="white" />
      <path d="M7.43579 15.0001L13.9957 11.1912L7.43579 8.26172V15.0001Z" fill="white" fillOpacity="0.2" />
      <path d="M0.875 11.1912L7.43575 15.0001V8.26172L0.875 11.1912Z" fill="white" fillOpacity="0.602" />
    </svg>
  )
}
