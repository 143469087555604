import client from "@/api/client"
import { TagSDStyleDto } from "@/api/sdk"
import { actionMiddleware } from "@/queries/middlewares/actionMiddleware"
import { createMutation } from "react-query-kit"

export interface UseTagsStyleMutationVariables extends TagSDStyleDto {}

export const useTagsStyleMutation = createMutation({
  mutationFn: (input: UseTagsStyleMutationVariables) => client.api.sdStyleControllerTagSdStyles(input),
  use: [
    actionMiddleware({
      onSuccess({ queryClient, variables }) {},
    }),
  ],
})

export const useUnTagsStyleMutation = createMutation({
  mutationFn: (input: UseTagsStyleMutationVariables) => client.api.sdStyleControllerUntagSdStyles(input),
  use: [
    actionMiddleware({
      onSuccess({ queryClient, variables }) {},
    }),
  ],
})
