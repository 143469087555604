import client from "@/api/client"
import { SearchWildcardResponse } from "@/api/sdk"
import { InfiniteData } from "@tanstack/react-query"
import { createMutation } from "react-query-kit"
import { actionMiddleware } from "@/queries/middlewares/actionMiddleware"
import { useWorkspaceWildcardsInfiniteQuery } from "@/queries/workspace"

interface DeleteStyleMutationVariables {
  ids: string[]
}

export const useRecoverWildcardMutation = createMutation({
  mutationFn: async ({ ids }: DeleteStyleMutationVariables) => {
    return client.api
      .wildcardControllerRecoverWildcards({
        wildcardIds: ids,
      })
      .then(res => res.data)
  },
  use: [
    actionMiddleware({
      onSuccess: ({ queryClient, variables: { ids } }) => {
        // Update wildcard list
        const wildcardListKey = useWorkspaceWildcardsInfiniteQuery.getKey()
        const wildcardListQueriesDataEntries = queryClient.getQueriesData<InfiniteData<SearchWildcardResponse, number>>(
          {
            queryKey: wildcardListKey,
          },
        )

        wildcardListQueriesDataEntries?.forEach(([key, data]) => {
          if (!data) return

          const updatedData = {
            ...data,
            pages: data.pages.map(page => {
              const updatedPage = {
                ...page,
                wildcards: page.wildcards.filter(style => {
                  return !ids.includes(style.id)
                }),
              }
              updatedPage.total = updatedPage.wildcards.length
              return updatedPage
            }),
          }
          queryClient.setQueryData(key, updatedData)
        })
      },
    }),
  ],
})
