import { IconProps } from "."

export const PotIcon2 = (props: IconProps) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M1.66406 8.33337H18.3307" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
      <path
        d="M3.32812 8.33337L3.74807 12.9527C3.94439 15.1123 4.04256 16.1922 4.75865 16.8461C5.47474 17.5 6.559 17.5 8.72753 17.5H11.2621C13.4306 17.5 14.5148 17.5 15.2309 16.8461C15.947 16.1922 16.0452 15.1123 16.2415 12.9527L16.6615 8.33337"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M3.32812 6.66667L7.49479 5.54129M7.49479 5.54129L12.4948 4.19084M7.49479 5.54129L7.66614 4.09317C7.7288 3.56353 8.10506 3.12572 8.61619 2.98766L10.2569 2.54451C10.7681 2.40646 11.3111 2.596 11.6281 3.02309L12.4948 4.19084M12.4948 4.19084L16.6615 3.06547"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  )
}
