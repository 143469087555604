import client from "@/api/client"
import { EntityType, GetNotificationsResponse, NotificationWithStatus } from "@/api/sdk"
import { useScreen } from "@/hooks"
import { createAuthenticatedInfiniteQuery } from "@/queries/createAuthenticatedQuery"
import { useDrawerNotification } from "@/stores"
import classNames from "classnames"
import { AnimatePresence, motion } from "framer-motion"
import { useRef } from "react"
import { isMobileOnly } from "react-device-detect"
import InfiniteScroll from "react-infinite-scroller"
import { RemoveScroll } from "react-remove-scroll"
import IconButton from "../IconButton"
import ReactPortal from "../ReactPortal"
import { ArrowUpDoubleIcon } from "../shared/icons"
import NotificationActions from "./NotificationActions"
import NotificationItem from "./NotificationItem"

export type NotificationResponseType = {
  folderId: string | null
  commentId: string | null
  commenterId: string | null
  recipeId: string | null
  imageId: string
  sdWorkflowId: string | null
  styleId: string | null
  parentCommentId: string | null
  roomId: string | null
  messageId: string | null
  senderId: string | null
  entityId: string | null
  wildcardId: string | null
  accessRequestId: string | null
  articleId: string | null
  entityType: EntityType
  workflowId: string | null
  workspaceInviteId: number | null
  chatRoomInviteId: string | null
  workspaceId: string | null
  sdStyleId: string | null
  sdModelId: string | null
}

export const useNotificationInfinityQuery = createAuthenticatedInfiniteQuery<
  GetNotificationsResponse,
  unknown,
  Error,
  NotificationWithStatus
>({
  primaryKey: "notifications",
  queryFn: ({ pageParam = 0, queryKey: [_primaryKey, variables] }) =>
    client.api
      .notificationControllerGetNotifications({
        skip: pageParam * 20,
        take: 20,
      })
      .then(res => res.data),
  getNextPageParam: (lastPage, pages) => (lastPage && lastPage.notifications.length < 20 ? undefined : pages.length),
  initialPageParam: 0,
  refetchOnWindowFocus: false,
  retry: false,
  flattenFn: ({ notifications }) => notifications,
})

export const NotificationDrawer = () => {
  const [isOpen, setIsOpen] = useDrawerNotification(state => [state.isOpen, state.setIsOpen])
  const { width } = useScreen()

  const animation = isMobileOnly
    ? {}
    : {
        initial: { x: "100%" },
        animate: { x: "0" },
        exit: { x: "100%" },
        transition: { duration: 0.25, type: "tween", ease: "easeInOut" },
      }

  const onClose = () => setIsOpen(false)

  const { flattenData, isLoading, hasNextPage, fetchNextPage } = useNotificationInfinityQuery()

  const scrollRef = useRef<HTMLDivElement>(null)

  return (
    <ReactPortal wrapperId="layout">
      <AnimatePresence mode="wait">
        {isOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
            }}
            exit={{ opacity: 0 }}
            className={classNames("fixed inset-0 bg-blackAlpha-700 z-50 hidden md:block")}
            onClick={() => onClose()}
          />
        )}
      </AnimatePresence>
      <AnimatePresence mode="wait">
        {isOpen && (
          <RemoveScroll
            style={{
              animationDelay: "0s",
            }}
            removeScrollBar
          >
            <motion.div
              {...animation}
              className="top-0 right-0 z-[20] md:z-50 fixed bg-atherGray-900 border-l h-[calc(var(--vh,1vh)*100)] overflow-hidden border-atherGray-800 flex flex-col w-full md:w-[28rem] max-w-full"
            >
              <div className="flex items-center justify-between h-16 px-4 py-2 border-b border-atherGray-700">
                <div className="flex items-center">
                  <div className="overflow-hidden hidden md:block">
                    <IconButton className="mr-2" colorScheme="transparent" onClick={onClose}>
                      <ArrowUpDoubleIcon className="rotate-90" />
                    </IconButton>
                  </div>
                  <h2 className="text-base font-semibold md:text-xl">Notifications</h2>
                </div>
                <NotificationActions />
              </div>
              <div
                className="flex-1 flex-col overflow-y-auto px-4 pt-2 pb-[5rem] md:px-6 md:py-4"
                ref={scrollRef}
                style={{
                  WebkitOverflowScrolling: "touch",
                }}
              >
                <InfiniteScroll
                  getScrollParent={() => scrollRef.current}
                  pageStart={0}
                  useWindow={false}
                  loadMore={() => fetchNextPage()}
                  hasMore={!!hasNextPage}
                >
                  {flattenData?.map(notification => (
                    <NotificationItem key={notification.id} notification={notification} onClose={onClose} />
                  ))}
                </InfiniteScroll>
                {flattenData?.length === 0 && !isLoading && (
                  <div className="flex items-center justify-center w-full h-full">
                    <p className="italic font-light text-whiteAlpha-700">You have no notifications</p>
                  </div>
                )}
                {flattenData?.length === 0 && isLoading && (
                  <div className="flex items-center justify-center w-full h-full">
                    <p className="italic font-light text-whiteAlpha-700">Loading your notifications</p>
                  </div>
                )}
              </div>
            </motion.div>
          </RemoveScroll>
        )}
      </AnimatePresence>
    </ReactPortal>
  )
}
