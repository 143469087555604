import { googleAnalytics } from "@/lib/gtag"
import { useUploadFileMutation, useUploadImageMutation, useWorkspaceImagesInfiniteQuery } from "@/queries"
import { useQueryClient } from "@tanstack/react-query"
import useToast from "./useToast"

export const useFileUpload = () => {
  const toast = useToast()

  const mutation = useUploadFileMutation({
    onMutate: ({ files }) => {
      files.forEach(element => {
        googleAnalytics.event({
          action: "upload_file",
          label: "Upload Files (KB)",
          category: "file",
          params: {
            file_size: `${(files.reduce((acc, file) => acc + file.size, 0) / 1024).toFixed(2)}`,
            file_name: element.name,
            file_type: element.type,
            file_lastModified: element.lastModified,
          },
        })
      })
    },
    onSuccess: data => {
      data.forEach(element => {
        googleAnalytics.event({
          action: "upload_file_success",
          label: "Upload File Success (KB)",
          category: "file",
          params: {
            file_id: element.id,
            file_name: element.name,
            file_owner_uid: element.ownerUid,
            file_size: `${(data.reduce((acc, file) => acc + (file.size || 0), 0) / 1024).toFixed(2)}`,
          },
        })
      })
    },
    onError: (err: any) => {
      if (err?.response?.data) {
        toast({
          title: "Error",
          message: [err?.response?.data?.message || "Something went wrong"],
          status: "error",
        })
        return
      }
      if (err?.message) {
        toast({
          title: "Error",
          message: [err?.message],
          status: "error",
        })
      }
    },
  })

  return mutation
}

export const useImageUpload = (options?: {
  onSuccess?: () => void
  onMutate?: () => void
  onError?: (error: any) => void
  onSettled?: () => void
}) => {
  const toast = useToast()
  const qc = useQueryClient()

  const mutation = useUploadImageMutation({
    onMutate: ({ files }) => {
      files.forEach(element => {
        googleAnalytics.event({
          action: "upload_img",
          label: "Upload Images (KB)",
          category: "image",
          params: {
            img_size: `${(files.reduce((acc, file) => acc + file.size, 0) / 1024).toFixed(2)}`,
            img_name: element.name,
            img_type: element.type,
            img_lastModified: element.lastModified,
          },
        })
      })

      options?.onMutate && options?.onMutate()
    },
    onSuccess: (data, { folderId }) => {
      data.forEach(element => {
        googleAnalytics.event({
          action: "upload_img_success",
          label: "Upload Images Success (KB)",
          category: "image",
          params: {
            img_id: element.id,
            img_name: element.name,
            img_owner_uid: element.ownerUid || element.owner.uid,
            img_size: `${(data.reduce((acc, file) => acc + (file.size || 0), 0) / 1024).toFixed(2)}`,
            img_width: element.width,
            img_height: element.height,
          },
        })
      })

      options?.onSuccess && options?.onSuccess()
      qc.invalidateQueries({ queryKey: useWorkspaceImagesInfiniteQuery.getKey({ folderId }) })
    },
    onError: (err: any) => {
      options?.onError && options?.onError(err)

      if (err?.response?.data) {
        toast({
          title: "Error",
          message: [err?.response?.data?.message || "Something went wrong"],
          status: "error",
        })
        return
      }
      if (err?.message) {
        toast({
          title: "Error",
          message: [err?.message],
          status: "error",
        })
      }
    },
  })

  return mutation
}
