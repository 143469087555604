import client from "@/api/client"
import { googleAnalytics } from "@/lib/gtag"
import { useMutation, useQueryClient } from "@tanstack/react-query"

export const useMoveFolders = () => {
  const qc = useQueryClient()

  const mutation = useMutation({
    mutationFn: async ({ newFolderId, folderIds }: { newFolderId: string; folderIds: string[] }) =>
      client.api.folderControllerMoveFolder({ folderIds, parentFolderId: newFolderId }),
    onMutate: ({ folderIds, newFolderId }) => {
      googleAnalytics.event({
        action: "click",
        category: "modal",
        label: `move_folders_${folderIds.join("_")}_to_folder_${newFolderId}`,
        value: 1,
      })
    },
    onSettled: () => {
      qc.invalidateQueries({ queryKey: ["search-folders"] })
      qc.invalidateQueries({ queryKey: ["sub-folders"] })
      qc.invalidateQueries({ queryKey: ["folders"] })
    },
  })

  return mutation
}
