import client from "@/api/client"
import { ExploreSDStyleDetail, GetExploreSDStylesResponse, SortOptions } from "@/api/sdk"
import { createAuthenticatedInfiniteQuery } from "../createAuthenticatedQuery"

export interface UseExploreStylesInfiniteQueryVariables {
  sortBy?: SortOptions
  searchTerm?: string
  creatorUid?: string
  onlyCurrentWorkspace?: boolean
  onlyFavorite?: boolean
  tagIds?: number[]
  userUid?: string
}

export const useExploreStylesInfiniteQuery = createAuthenticatedInfiniteQuery<
  GetExploreSDStylesResponse,
  UseExploreStylesInfiniteQueryVariables,
  Error,
  ExploreSDStyleDetail
>({
  auth: false,
  primaryKey: "infinite-explore-styles",
  queryFn: ({ queryKey: [_primaryKey, variables], pageParam = 0 }) =>
    client.api
      .exploreSdStyleControllerGetExploreSdStyles({
        skip: pageParam * 50,
        take: 50,
        sortBy: variables.sortBy,
        searchTerm: variables.searchTerm,
        creatorUid: variables.creatorUid,
        onlyCurrentWorkspace: variables.onlyCurrentWorkspace,
        onlyFavorite: variables.onlyFavorite,
        tagIds: variables.tagIds,
      })
      .then(res => res.data),
  getNextPageParam: (lastPage, pages) => (lastPage && lastPage.styles.length < 50 ? undefined : pages.length),
  initialPageParam: 0,
  flattenFn: data => data.styles,
})
