import client from "@/api/client"
import { SharingRole } from "@/api/sdk"
import { createMutation } from "react-query-kit"
import { actionMiddleware } from "../../middlewares/actionMiddleware"
import { useFolderInfoQuery } from "./useFolderInfoQuery"

/**
  const { mutate: mutateUpdateUserRoleFolder } = useMutation({
    mutationFn: ({ permissionId, role }: { permissionId: number; role: SharingRole }) =>
      client.api.folderControllerUpdateFolderPermissionRole(permissionId, {
        role: role.toLowerCase() as SharingRole,
      }),
    onSettled: () => {
      qc.invalidateQueries({ queryKey: ["folder-info", sharingData?.id] })
    },
  })
 */

export interface UpdateFolderRoleMutationVariables {
  folderId: string
  permissionId: number
  role: SharingRole
}

export const useUpdateFolderRoleMutation = createMutation({
  mutationFn: ({ permissionId, role }: UpdateFolderRoleMutationVariables) =>
    client.api.folderControllerUpdateFolderPermissionRole(permissionId, { role }),
  use: [
    actionMiddleware({
      onSettled: ({ queryClient, variables: { folderId } }) => {
        queryClient.invalidateQueries({
          queryKey: useFolderInfoQuery.getKey({ folderId }),
        })
      },
    }),
  ],
})
