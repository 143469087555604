import client from "@/api/client"
import { InfiniteData, useQueryClient } from "@tanstack/react-query"
import { createMutation } from "react-query-kit"
import { actionMiddleware } from "../middlewares/actionMiddleware"
import { useWorkspacePinInfiniteQuery, useWorkspaceWorkflowInfiniteQuery } from "../workspace"
import {
  GetWorkflowsResult,
  ListRecipeTasksResponse,
  ListRecipeTasksResponseForAgentScheduler,
  PinnedItemType,
  PinnedItems,
  RecentWorkflowItem,
  RecipeTaskItem,
  RecipeTaskWithFullImages,
  RecipeType,
} from "@/api/sdk"
import { useRecentWorkflowInfiniteQuery } from "@/components/Workspace/RecentV2/RecentWorkflow"
import { useWorkspaceWorkflowDetailQuery } from "../workspace/workflow"
import { useGetHistoryTaskInfiniteQuery } from "./useGetTaskInfiniteQuery"
import { useWorkspaceRoomRecipeTasksInfiniteQuery } from "../workspace/room"

export const useDeleteTaskMutation = createMutation({
  mutationFn: ({ ids }: { ids: string[]; recipeId?: string; type?: RecipeType; roomId?: string }) =>
    client.api.recipeTaskControllerFullyDeleteTasks({
      recipeTaskIds: ids,
    }),
  use: [
    actionMiddleware({
      onSuccess: ({ queryClient, variables: { ids, recipeId, type, roomId } }) => {
        const recipeHistoryKey = useGetHistoryTaskInfiniteQuery.getKey()

        const recipeHistoryKeyQueriesDataEntries = queryClient.getQueriesData<
          InfiniteData<RecipeTaskWithFullImages[], number>
        >({ queryKey: recipeHistoryKey })

        if (recipeHistoryKeyQueriesDataEntries) {
          recipeHistoryKeyQueriesDataEntries.forEach(([key, recipeHistoryData]) => {
            if (recipeHistoryData) {
              const updatedData = {
                ...recipeHistoryData,
                pages: recipeHistoryData.pages.map(page => {
                  return page.filter(task => !ids.includes(task.id))
                }),
              }

              queryClient.setQueryData(key, updatedData)
            }
          })
        }

        // Update workflow list
        const workflowListKey = useWorkspaceWorkflowInfiniteQuery.getKey()

        const workflowListQueriesDataEntries = queryClient.getQueriesData<InfiniteData<GetWorkflowsResult, number>>({
          queryKey: workflowListKey,
        })

        if (workflowListQueriesDataEntries) {
          workflowListQueriesDataEntries.forEach(([key, workflowListData]) => {
            if (workflowListData) {
              const updatedData = {
                ...workflowListData,
                pages: workflowListData.pages.map(page => {
                  return {
                    ...page,
                    total: page.total - 1,
                    workflows: page.workflows.filter(workflow => !ids.includes(workflow.id)),
                  }
                }),
              }

              queryClient.setQueryData(key, updatedData)
            }
          })
        }

        // Update workflow recent list

        const workflowRecentListKey = useRecentWorkflowInfiniteQuery.getKey()

        const workflowRecentListQueriesDataEntries = queryClient.getQueriesData<
          InfiniteData<RecentWorkflowItem[], number>
        >({
          queryKey: workflowRecentListKey,
        })

        if (workflowRecentListQueriesDataEntries) {
          workflowRecentListQueriesDataEntries.forEach(([key, workflowRecentListData]) => {
            if (workflowRecentListData) {
              const updatedData = {
                ...workflowRecentListData,
                pages: workflowRecentListData.pages.map(page => {
                  return [...page.filter(workflow => !ids.includes(workflow.workflow.id))]
                }),
              }

              queryClient.setQueryData(key, updatedData)
            }
          })
        }

        if (roomId) {
          const roomTasksKey = useWorkspaceRoomRecipeTasksInfiniteQuery.getKey()

          const roomTasksQueriesDataEntries = queryClient.getQueriesData<InfiniteData<RecipeTaskItem[], number>>({
            queryKey: roomTasksKey,
          })

          if (roomTasksQueriesDataEntries) {
            roomTasksQueriesDataEntries.forEach(([key, roomTasksData]) => {
              if (roomTasksData) {
                const updatedData = {
                  ...roomTasksData,
                  pages: roomTasksData.pages.map(page => {
                    return page.filter(task => !ids.includes(task.id))
                  }),
                }

                queryClient.setQueryData(key, updatedData)
              }
            })
          }
        }

        //update backlog
        const historyBacklogKey = ["history_task_list"]

        const historyBacklogQueriesDataEntries = queryClient.getQueriesData<
          InfiniteData<ListRecipeTasksResponse, number>
        >({
          queryKey: historyBacklogKey,
        })

        historyBacklogQueriesDataEntries?.forEach(([key, historyBacklogData]) => {
          if (historyBacklogData) {
            const updatedData = {
              ...historyBacklogData,
              pages: historyBacklogData.pages.map(page => {
                return {
                  ...page,
                  other: page.other.filter(task => !ids.includes(task.id)),
                }
              }),
            }

            queryClient.setQueryData(key, updatedData)
          }
        })

        const backlogKey = ["board-view-task"]

        const backlogQueriesDataEntries = queryClient.getQueriesData<
          InfiniteData<ListRecipeTasksResponseForAgentScheduler, number>
        >({
          queryKey: backlogKey,
        })

        backlogQueriesDataEntries?.forEach(([key, backlogData]) => {
          if (backlogData) {
            const updatedData = {
              ...backlogData,
              pages: backlogData.pages.map(page => {
                const canceled = page.canceled.list.filter(task => !ids.includes(task.id))
                const completed = page.completed.list.filter(task => !ids.includes(task.id))
                const queued = page.queued.list.filter(task => !ids.includes(task.id))
                const running = page.running.list.filter(task => !ids.includes(task.id))
                const failed = page.failed.list.filter(task => !ids.includes(task.id))

                return {
                  ...page,
                  canceled: {
                    list: canceled,
                    total: canceled.length,
                  },
                  completed: {
                    list: completed,
                    total: completed.length,
                  },
                  queued: {
                    list: queued,
                    total: queued.length,
                  },
                  running: {
                    list: running,
                    total: running.length,
                  },
                  failed: {
                    ...page.failed,
                    list: failed,
                    total: failed.length,
                  },
                }
              }),
            }

            queryClient.setQueryData(key, updatedData)
          }
        })
      },
    }),
    useMutationNext => {
      return (options, queryClient) => {
        const qc = useQueryClient(queryClient)

        return useMutationNext({
          ...options,
          onSettled: (_, __, { ids }) => {
            qc.invalidateQueries({
              queryKey: useWorkspaceWorkflowDetailQuery.getKey({
                workflowId: ids[0] ?? "",
              }),
            })
          },
        })
      }
    },
  ],
})

export const useDeleteWorkflowsMutation = createMutation({
  mutationFn: async ({ ids, deletedAt }: { ids: string[]; deletedAt: string | null }) => {
    if (deletedAt) {
      return await client.api.recipeTaskControllerFullyDeleteTasks({
        recipeTaskIds: ids,
      })
    }

    return await client.api.recipeTaskControllerDeleteTasks({
      recipeTaskIds: ids,
    })
  },
  use: [
    actionMiddleware({
      onSuccess: ({ queryClient, variables: { deletedAt, ids } }) => {
        // Update workflow list
        const workflowListKey = useWorkspaceWorkflowInfiniteQuery.getKey()

        const workflowListQueriesDataEntries = queryClient.getQueriesData<InfiniteData<GetWorkflowsResult, number>>({
          queryKey: workflowListKey,
        })

        if (workflowListQueriesDataEntries) {
          workflowListQueriesDataEntries.forEach(([key, workflowListData]) => {
            if (workflowListData) {
              const updatedData = {
                ...workflowListData,
                pages: workflowListData.pages.map(page => {
                  return {
                    ...page,
                    total: page.total - ids.length,
                    workflows: page.workflows.filter(workflow => !ids.includes(workflow.id)),
                  }
                }),
              }
              queryClient.setQueryData(key, updatedData)
            }
          })
        }

        // Update workflow recent list

        const workflowRecentListKey = useRecentWorkflowInfiniteQuery.getKey()

        const workflowRecentListQueriesDataEntries = queryClient.getQueriesData<
          InfiniteData<RecentWorkflowItem[], number>
        >({
          queryKey: workflowRecentListKey,
        })

        if (workflowRecentListQueriesDataEntries) {
          workflowRecentListQueriesDataEntries.forEach(([key, workflowRecentListData]) => {
            if (workflowRecentListData) {
              const updatedData = {
                ...workflowRecentListData,
                pages: workflowRecentListData.pages.map(page => {
                  return [...page.filter(workflow => !ids.includes(workflow.workflow.id))]
                }),
              }

              queryClient.setQueryData(key, updatedData)
            }
          })
        }

        // Update workflow pinned list
        const workflowPinnedListKey = useWorkspacePinInfiniteQuery.getKey({
          type: PinnedItemType.WORKFLOW,
        })

        const workflowPinnedListQueriesDataEntries = queryClient.getQueriesData<InfiniteData<PinnedItems[], number>>({
          queryKey: workflowPinnedListKey,
        })

        if (workflowPinnedListQueriesDataEntries) {
          workflowPinnedListQueriesDataEntries.forEach(([key, workflowPinnedListData]) => {
            if (workflowPinnedListData) {
              const updatedData = {
                ...workflowPinnedListData,
                pages: workflowPinnedListData.pages.map(page => {
                  return [...page.filter(item => !ids.includes(item.workflow?.id ?? ""))]
                }),
              }

              queryClient.setQueryData(key, updatedData)
            }
          })
        }
      },
    }),
    useMutationNext => {
      return (options, queryClient) => {
        const qc = useQueryClient(queryClient)

        return useMutationNext({
          ...options,
          onSettled: (_, __, taskIds) => {
            qc.invalidateQueries({
              queryKey: useWorkspaceWorkflowDetailQuery.getKey({
                workflowId: taskIds[0] ?? "",
              }),
            })
          },
        })
      }
    },
  ],
})
