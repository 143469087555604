import client from "@/api/client"
import { actionMiddleware } from "@/queries/middlewares/actionMiddleware"
import { createMutation } from "react-query-kit"
import { useSubFoldersInfiniteQuery } from "./useSubFoldersInfiniteQuery"
import { GetSubFoldersResponse, PinnedItemType, PinnedItems, RecentFolderItem } from "@/api/sdk"
import { InfiniteData } from "@tanstack/react-query"
import { customPromiseAllSettled } from "@/utils/promise"
import { useRecentFolderInfiniteQuery } from "@/components/Workspace/RecentV2/RecentFolders"
import { useWorkspacePinInfiniteQuery } from "../useWorkspacePinInfiniteQuery"

export const useDeleteFolderMutation = createMutation({
  mutationFn: async ({ ids, deletedAt }: { ids: string[]; parentFolderId?: string; deletedAt: string | null }) => {
    if (deletedAt) {
      return await customPromiseAllSettled(
        Promise.all(ids.map(folderId => client.api.folderControllerFullyDeleteFolder({ folderId }))),
      )
    }

    return await customPromiseAllSettled(
      Promise.all(ids.map(folderId => client.api.folderControllerSoftDeleteFolder({ folderId }))),
    )
  },
  use: [
    actionMiddleware({
      onSuccess: ({ queryClient, variables: { ids, parentFolderId } }) => {
        if (parentFolderId) {
          const parentSubFoldersKey = useSubFoldersInfiniteQuery.getKey({
            folderId: parentFolderId,
          })

          const parentSubFoldersQueriesDataEntries = queryClient.getQueriesData<
            InfiniteData<GetSubFoldersResponse, number>
          >({
            queryKey: parentSubFoldersKey,
          })
          if (parentSubFoldersQueriesDataEntries) {
            parentSubFoldersQueriesDataEntries.forEach(([key, parentSubFoldersData]) => {
              if (parentSubFoldersData) {
                const updatedData = {
                  ...parentSubFoldersData,
                  pages: parentSubFoldersData.pages.map(page => {
                    return {
                      ...page,
                      total: page.total - ids.length,
                      folders: page.folders.filter(folder => !ids.includes(folder.id)),
                    }
                  }),
                }

                queryClient.setQueryData(key, updatedData)
              }
            })
          }
        }

        // Update folder recent list
        const folderRecentListKey = useRecentFolderInfiniteQuery.getKey()

        const folderRecentListQueriesDataEntries = queryClient.getQueriesData<InfiniteData<RecentFolderItem[], number>>(
          {
            queryKey: folderRecentListKey,
          },
        )

        if (folderRecentListQueriesDataEntries) {
          folderRecentListQueriesDataEntries.forEach(([key, folderRecentListData]) => {
            if (folderRecentListData) {
              const updatedData = {
                ...folderRecentListData,
                pages: folderRecentListData.pages.map(page => {
                  return [...page.filter(item => !ids.includes(item.folder.id))]
                }),
              }

              queryClient.setQueryData(key, updatedData)
            }
          })
        }

        // Update folder pinned list
        const folderPinnedListKey = useWorkspacePinInfiniteQuery.getKey({
          type: PinnedItemType.FOLDER,
        })

        const folderPinnedListQueriesDataEntries = queryClient.getQueriesData<InfiniteData<PinnedItems[], number>>({
          queryKey: folderPinnedListKey,
        })

        if (folderPinnedListQueriesDataEntries) {
          folderPinnedListQueriesDataEntries.forEach(([key, folderPinnedListData]) => {
            if (folderPinnedListData) {
              const updatedData = {
                ...folderPinnedListData,
                pages: folderPinnedListData.pages.map(page => {
                  return [...page.filter(item => !ids.includes(item.folder?.id ?? ""))]
                }),
              }

              queryClient.setQueryData(key, updatedData)
            }
          })
        }
      },
    }),
  ],
})
