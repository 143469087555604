import { IconProps } from "."

export const CheckCircleIcon = (
  props: IconProps & { checked?: boolean; background?: string; checkBoxColor?: string },
) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={props.checked ? "#5C14EB" : props.background ?? "none"}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12Z"
        stroke={props.checked ? "#5C14EB" : "currentColor"}
        strokeWidth="1.5"
      />
      <path
        d="M8 12.75C8 12.75 9.6 13.6625 10.4 15C10.4 15 12.8 9.75 16 8"
        stroke={props.background ? props.checkBoxColor ?? "#7E7D77" : "currentColor"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
