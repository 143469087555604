import Input from "@/components/Input"
import { ImageIcon, ImageSearchIcon, ImageUploadIcon, SearchIcon, XIcon } from "@/components/shared/icons"
import { useCallbackOnHotKeys, useOutsideClick } from "@/hooks"
import { useReplaceUrlQuery } from "@/hooks/useQuery"
import useCustomRouter from "@/hooks/useCustomRouter"
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { chunkString } from "@/utils/parser"
import { googleAnalytics } from "@/lib/gtag"
import IconButton from "@/components/IconButton"
import { AnimatePresence, motion } from "framer-motion"
import classNames from "classnames"
import UploadRecipeImage from "@/components/Workspace/Recipes/RecipeDetail/UploadRecipeImage"
import { isCheckDomainUrl, isLinkUrlImage } from "@/utils"
import ImageView from "@/components/ImageUpload/ImageView"
import { isMobile } from "react-device-detect"

const GalleryActionsV2 = ({
  onFocus,
  onOpen,
}: {
  onFocus?: (focus: boolean) => void
  onOpen?: (focus: boolean) => void
}) => {
  const router = useCustomRouter()
  const replaceUrl = useReplaceUrlQuery()
  const [search, setSearch] = useState("")
  const [isOpen, setIsOpen] = useState(false)
  const { search: searchQuery } = router.query

  useEffect(() => {
    if (searchQuery) {
      setSearch(searchQuery as string)
    }
  }, [searchQuery])

  const handleConfirmSearch = useCallback(
    (search: string) => {
      // blur input
      inputRef.current?.blur()

      replaceUrl({
        search,
      })

      setSearch(search)

      if (search === "") {
        return
      }

      const splitSearch = chunkString(search, 100)
      const searchParams = Object.fromEntries((splitSearch ?? []).map((item, index) => [`search_${index}`, item]))

      googleAnalytics.handleCategoryEvent({
        action: "click",
        params: {
          action: "Search",
          tab_name: router.pathname.split("/")[2],
          ...searchParams,
        },
      })
    },
    [replaceUrl, router.pathname],
  )

  const inputRef = useRef<HTMLInputElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const [isFocus, setIsFocus] = useState(false)

  const isCheckedInternalImage = useMemo(() => {
    if (typeof searchQuery !== "string") return false

    const isCheckDomain = isCheckDomainUrl(searchQuery)
    const isCheckImage = isLinkUrlImage(searchQuery)

    return isCheckDomain && isCheckImage
  }, [searchQuery])

  useCallbackOnHotKeys(
    "f",
    () => {
      if (document.getElementsByClassName("modal").length > 0) return

      if (isMobile) {
        return
      } else {
        onFocus?.(true)

        setTimeout(() => {
          inputRef.current?.focus()
        }, 100)
      }
    },
    !isFocus,
  )

  useOutsideClick({
    ref: containerRef,
    handler: () => {
      setIsOpen(false)
    },
    enabled: isOpen,
  })

  return (
    <div className="relative flex-1" ref={containerRef}>
      <div
        className={classNames("flex items-start w-full border-[2px] p-2 border-atherGray-800 rounded-[24px]", {
          "border-b-transparent rounded-b-none": isOpen,
        })}
      >
        <span className="p-1 h-[2rem] flex items-center justify-center">
          <SearchIcon className="text-atherGray-300" width={20} height={20} />
        </span>

        {isCheckedInternalImage ? (
          <div className="flex-1">
            <div className="flex max-w-[6rem]">
              <ImageView
                alt=""
                className="h-[3rem]"
                url={(typeof searchQuery === "string" ? searchQuery : search) || search}
              />
            </div>
          </div>
        ) : (
          <Input
            ref={inputRef}
            value={search}
            placeholder="Search by text or image"
            onChange={e => setSearch(e.target.value)}
            onFocus={() => setIsFocus(true)}
            onBlur={() => setIsFocus(false)}
            onKeyDown={e => {
              if (e.key === "Enter") {
                handleConfirmSearch(search)
              }
            }}
            className="w-full bg-transparent px-2 placeholder:text-atherGray-500 h-[2rem]"
            containerClassName="flex-1"
          />
        )}

        <div className="flex items-center min-w-[3.5rem] justify-end">
          {search && (
            <IconButton onClick={() => handleConfirmSearch("")} colorScheme="transparent" className="p-1 h-[2rem]">
              <XIcon className="text-red-500" width={14} height={14} />
            </IconButton>
          )}
          <IconButton
            onClick={() => {
              setIsOpen(prev => !prev)
              onOpen?.(!isOpen)
            }}
            colorScheme="transparent"
            title="Search by Image"
            className={classNames("py-1 h-[2rem] rounded-2xl px-2", {
              "text-atherGray-0": isOpen,
              "text-atherGray-300": !isOpen,
            })}
          >
            <ImageSearchIcon width={20} height={20} />
          </IconButton>
        </div>

        <AnimatePresence mode="wait">
          {isOpen && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{
                duration: 0.2,
                ease: "easeInOut",
              }}
              className="absolute top-[calc(100%-2px)] overflow-hidden left-0 bg-black border-[2px] border-t-0  border-atherGray-800 shadow-lg p-2 rounded-b-[24px] w-full"
            >
              <UploadRecipeImage
                maxSize={400}
                uploadImageClassName="h-[10rem]"
                className="w-full max-w-full"
                listButtonClassName="hidden"
                value={""}
                onChange={v => {
                  handleConfirmSearch(v)
                  setIsOpen(false)
                }}
                uploadContent={
                  <>
                    Upload images or Drag & Drop <br />
                    (less than 10MB)
                  </>
                }
              />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  )
}

export default memo(GalleryActionsV2)
