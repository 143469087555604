import {
  AddSquareIcon,
  DeleteIcon,
  DownloadIcon1,
  FolderMoveIcon,
  FolderTransferIcon,
  PinIcon,
  RefreshIcon,
  RemoveSquareIcon,
} from "@/components/shared/icons"
import { cn } from "@/utils/cn"

interface MultiActions {
  fn: () => void
  enabled?: boolean
  visible?: boolean
  icon?: React.ReactNode
  title: string
}

interface MultiModeActionsProps {
  onAdd?: MultiActions
  onRemove?: MultiActions
  onCopy?: MultiActions
  onDownload?: MultiActions
  onMove?: MultiActions
  onPin?: MultiActions
  onDelete?: MultiActions
  onRecover?: MultiActions
}

const MultiModeActions = ({
  onMove,
  onDelete,
  onPin,
  onRecover,
  onDownload,
  onCopy,
  onRemove,
  onAdd,
}: MultiModeActionsProps) => {
  const options = [
    {
      icon: <AddSquareIcon width={20} height={20} />,
      handler: onAdd,
    },
    {
      icon: <RemoveSquareIcon width={20} height={20} />,
      handler: onRemove,
    },
    {
      icon: <FolderMoveIcon width={20} height={20} />,
      handler: onMove,
    },
    {
      icon: <FolderTransferIcon width={20} height={20} />,
      handler: onCopy,
    },
    {
      icon: <DownloadIcon1 width={20} height={20} />,
      handler: onDownload,
    },
    {
      icon: <PinIcon width={20} height={20} />,
      handler: onPin,
    },
    {
      icon: <RefreshIcon className="rotate-180" width={20} height={20} />,
      handler: onRecover,
    },
    {
      icon: <DeleteIcon className="text-red-500" width={20} height={20} />,
      handler: onDelete,
    },
  ]

  return (
    <div className="rounded-2xl p-2 bg-atherGray-900 border border-atherGray-850 text-atherGray-300 min-w-52 w-max space-y-1">
      {options
        .filter(opt => !!opt.handler?.fn && opt.handler?.visible !== false)
        .map(opt => (
          <button
            key={opt.handler?.title}
            type="button"
            onClick={opt.handler?.fn}
            className={cn("py-2.5 px-4 rounded-2xl flex items-center w-full font-semibold group ", {
              "hover:bg-atherGray-850 hover:text-atherGray-0": opt.handler?.enabled,
              "opacity-50 cursor-not-allowed text-atherGray-500 pointer-events-none": !opt.handler?.enabled,
            })}
          >
            <span className="text-atherGray-500 group-hover:text-atherGray-0">{opt.handler?.icon || opt.icon}</span>
            <span className="ml-2 text-sm">{opt.handler?.title}</span>
          </button>
        ))}
    </div>
  )
}

export default MultiModeActions
