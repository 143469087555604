type PromiseResult =
  | {
      status: "pending"
    }
  | {
      status: "fulfilled"
      value: any
    }
  | {
      status: "rejected"
      error: Error
    }

export function customPromiseAll(...promises: Array<Promise<any>>): Promise<void> {
  // We initialize an `fulfilled` boolean array of the size of `promises`
  const pending = promises.map(() => true)
  return new Promise((resolve, reject) => {
    promises.forEach((promise, index) => {
      promise
        .then(value => {
          // When a promise is resolved, we store this fact in our array
          pending[index] = false
          // If there are no pending promises, we can resolve our main promise
          if (!pending.find(isPending => isPending === true)) {
            resolve()
          }
        })
        .catch(reject) // Any failure in one of the promises will make the main promise rejected
    })
  })
}

export function customPromiseAllSettled(...promises: Array<Promise<any>>): Promise<PromiseResult[]> {
  // We initialize a `results` array of the size of `promises`
  const results: PromiseResult[] = promises.map(() => {
    return { status: "pending" }
  })

  return new Promise((resolve, reject) => {
    function resolveIfNothingPending(): void {
      // If there are no pending promises, we can resolve our main promise
      if (!results.find(result => result.status === "pending")) {
        resolve(results)
      }
    }

    promises.forEach((promise, index) => {
      promise
        .then(value => {
          // When a promise is resolved, we store the value and status
          results[index] = { value, status: "fulfilled" }
          resolveIfNothingPending()
        })
        .catch(error => {
          // When there's an error, we store it in the `results` array too
          results[index] = { error, status: "rejected" }
          resolveIfNothingPending()
        })
    })
  })
}

type AsyncMapper<T, U> = (item: T, index: number, array: T[]) => Promise<U>

export async function mapSeries<T, U>(array: T[], mapper: AsyncMapper<T, U>): Promise<U[]> {
  const results: U[] = []
  for (let i = 0; i < array.length; i++) {
    const result = await mapper(array[i], i, array)
    results.push(result)
  }
  return results
}

export async function mapWithConcurrency<T, U>(
  array: T[],
  mapper: AsyncMapper<T, U>,
  options?: { concurrency?: number },
): Promise<U[]> {
  const results: U[] = new Array(array.length)
  const executing: Promise<void>[] = []
  const concurrency = options?.concurrency ?? 0

  const enqueue = async (item: T, index: number) => {
    const result = await mapper(item, index, array)
    results[index] = result
  }

  for (let i = 0; i < array.length; i++) {
    const p = enqueue(array[i], i).then(() => {
      executing.splice(executing.indexOf(p), 1)
    })
    executing.push(p)
    if (concurrency && executing.length >= concurrency) {
      await Promise.race(executing)
    }
  }

  await Promise.all(executing)
  return results
}
