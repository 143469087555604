import client from "@/api/client"
import { InfiniteData, useQueryClient } from "@tanstack/react-query"
import { createMutation } from "react-query-kit"
import { useWorkspaceWorkflowDetailQuery } from "../workspace/workflow"
import { actionMiddleware } from "../middlewares/actionMiddleware"
import { useWorkspaceRoomRecipeTasksInfiniteQuery } from "../workspace/room"
import { ListRecipeTasksResponse, ListRecipeTasksResponseForAgentScheduler, RecipeTaskItem } from "@/api/sdk"
import { useGetOngoingTaskQuery } from "./useGetTaskInfiniteQuery"

export const useCancelTaskMutation = createMutation({
  mutationFn: (taskId: string) => client.api.recipeTaskControllerCancel(taskId),
  use: [
    actionMiddleware({
      onSuccess: ({ queryClient, variables: taskId }) => {
        const roomTasksKey = useWorkspaceRoomRecipeTasksInfiniteQuery.getKey()

        const roomTasksQueriesDataEntries = queryClient.getQueriesData<InfiniteData<RecipeTaskItem[], number>>({
          queryKey: roomTasksKey,
        })

        if (roomTasksQueriesDataEntries) {
          roomTasksQueriesDataEntries.forEach(([key, roomTasksData]) => {
            if (roomTasksData) {
              const updatedData = {
                ...roomTasksData,
                pages: roomTasksData.pages.map(page => {
                  return page.filter(task => taskId !== task.id)
                }),
              }

              queryClient.setQueryData(key, updatedData)
            }
          })
        }

        const onGoingTasksKey = useGetOngoingTaskQuery.getKey()

        const onGoingTasksQueriesDataEntries = queryClient.getQueriesData<ListRecipeTasksResponse>({
          queryKey: onGoingTasksKey,
        })

        if (onGoingTasksQueriesDataEntries) {
          onGoingTasksQueriesDataEntries.forEach(([key, onGoingTasksData]) => {
            if (onGoingTasksData) {
              const queued = onGoingTasksData.queued.filter(task => taskId !== task.id)
              const running = onGoingTasksData.running.filter(task => taskId !== task.id)

              const updatedData = {
                ...onGoingTasksData,
                queued,
                running,
              }

              queryClient.setQueryData(key, updatedData)
            }
          })
        }

        // Update backlog tasks

        const onGoingBacklogKey = ["ongoing_task_list"]

        const onGoingBacklogQueriesDataEntries = queryClient.getQueriesData<
          InfiniteData<ListRecipeTasksResponse, number>
        >({
          queryKey: onGoingBacklogKey,
        })

        onGoingBacklogQueriesDataEntries?.forEach(([key, onGoingBacklogData]) => {
          if (onGoingBacklogData) {
            const updatedData = {
              ...onGoingBacklogData,
              pages: onGoingBacklogData.pages.map(page => {
                const queued = page.queued.filter(task => taskId !== task.id)
                const running = page.running.filter(task => taskId !== task.id)

                return {
                  ...page,
                  queued,
                  running,
                }
              }),
            }

            queryClient.setQueryData(key, updatedData)
          }
        })

        const backlogKey = ["board-view-task"]

        const backlogQueriesDataEntries = queryClient.getQueriesData<
          InfiniteData<ListRecipeTasksResponseForAgentScheduler, number>
        >({
          queryKey: backlogKey,
        })
        backlogQueriesDataEntries?.forEach(([key, backlogData]) => {
          if (backlogData) {
            const updatedData = {
              ...backlogData,
              pages: backlogData.pages.map(page => {
                const queued = page.queued.list.filter(task => taskId !== task.id)
                const running = page.running.list.filter(task => taskId !== task.id)

                return {
                  ...page,
                  queued: {
                    list: queued,
                    total: queued.length,
                  },
                  running: {
                    list: running,
                    total: running.length,
                  },
                }
              }),
            }

            queryClient.setQueryData(key, updatedData)
          }
        })
      },
    }),
    useMutationNext => {
      return (options, queryClient) => {
        const qc = useQueryClient(queryClient)

        return useMutationNext({
          ...options,
          onSettled: (_, __, taskId) => {
            qc.invalidateQueries({
              queryKey: useWorkspaceWorkflowDetailQuery.getKey({
                workflowId: taskId ?? "",
              }),
            })
          },
        })
      }
    },
  ],
})
