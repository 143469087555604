import client from "@/api/client"
import { PromptItem } from "@/api/sdk"
import { createAuthenticatedQuery } from "@/queries/createAuthenticatedQuery"
import { enableQueryMiddleware } from "@/queries/middlewares/enableQueryMiddleware"

export const useWorkspacePromptDetailQuery = createAuthenticatedQuery<PromptItem, { promptId: string }, Error>({
  primaryKey: "prompt-detail",
  queryFn: ({ queryKey: [_primaryKey, variables] }) =>
    client.api.promptLibraryControllerGetPromptById(variables.promptId!).then(res => res.data),
  use: [enableQueryMiddleware(variables => !!variables.promptId)],
})
