import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { BLUR_COMMAND, COMMAND_PRIORITY_LOW, FOCUS_COMMAND } from "lexical"
import { useEffect } from "react"

const FocusPlugin = ({ onFocus }: { onFocus: (value: boolean) => void }) => {
  const [editor] = useLexicalComposerContext()

  useEffect(
    () =>
      editor.registerCommand(
        BLUR_COMMAND,
        () => {
          onFocus(false)
          return false
        },
        COMMAND_PRIORITY_LOW,
      ),
    [],
  )

  useEffect(
    () =>
      editor.registerCommand(
        FOCUS_COMMAND,
        () => {
          onFocus(true)
          return false
        },
        COMMAND_PRIORITY_LOW,
      ),
    [],
  )

  return null
}

export default FocusPlugin
