import client from "@/api/client"
import { PinnedItemType, PinnedItems } from "@/api/sdk"
import { createAuthenticatedInfiniteQuery } from "../createAuthenticatedQuery"

export interface UseWorkspacePinInfiniteQueryVariables {
  type: PinnedItemType
}

export const useWorkspacePinInfiniteQuery = createAuthenticatedInfiniteQuery<
  PinnedItems[],
  UseWorkspacePinInfiniteQueryVariables,
  Error,
  PinnedItems
>({
  primaryKey: "infinite-workspace-pin",
  queryFn: ({ queryKey: [_primaryKey, variables], pageParam = 0 }) =>
    client.api
      .pinControllerGetPinnedItems({
        skip: pageParam * 20,
        take: 20,
        type: variables.type,
      })
      .then(res => res.data),
  getNextPageParam: (lastPage, pages) => (lastPage && lastPage.length < 20 ? undefined : pages.length),
  flattenFn: pages => pages,
  initialPageParam: 0,
})
