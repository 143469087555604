import client from "@/api/client"
import { QuestFrequency, QuestStatus, WowPointHistoryItem } from "@/api/sdk"
import { createAuthenticatedInfiniteQuery } from "@/queries/createAuthenticatedQuery"

export const useHistoryQuestInfiniteQuery = createAuthenticatedInfiniteQuery<
  WowPointHistoryItem[],
  {
    status?: QuestStatus
    frequency?: QuestFrequency
  },
  Error,
  WowPointHistoryItem
>({
  primaryKey: "infinite-history-quests",
  queryFn: ({ queryKey: [_primaryKey, variables], pageParam = 0 }) =>
    client.api
      .wowPointControllerGetUserWowPointHistory({
        skip: pageParam * 20,
        take: 20,
      })
      .then(res => res.data),
  getNextPageParam: (lastPage, pages) => (lastPage && lastPage.length < 20 ? undefined : pages.length),
  initialPageParam: 0,
  flattenFn: data => data,
})
