import "react-date-range/dist/styles.css" // main css file
import "react-date-range/dist/theme/default.css" // theme css file

import { format } from "date-fns"
import React, { useState } from "react"
import { DateRange } from "react-date-range"
import Popover from "./Popover"
import { css } from "@emotion/css"

export type DateRangeType = {
  startDate: Date | null
  endDate: Date | null
}

interface DateRangeProps {
  value: DateRangeType
  onSelect: (value: DateRangeType) => void
}

const SelectDateRange = ({ value, onSelect }: DateRangeProps) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div>
      <Popover
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        focusOnOpen={false}
        trigger={
          <div className="bg-atherGray-800 p-2 rounded-lg text-sm">
            {value.startDate ? format(value.startDate, "dd/MM/yyyy") : "Start date"} -{" "}
            {value.endDate ? format(value.endDate, "dd/MM/yyyy") : "End date"}
          </div>
        }
        align="end"
        className="origin-top-right"
      >
        <div
          className={css({
            ".rdrCalendarWrapper": {
              backgroundColor: "#262624",
              borderRadius: "8px",
              overflow: "hidden",
            },
            ".rdrDateDisplayWrapper": {
              backgroundColor: "#262624",
            },
            ".rdrDateDisplayItem": {
              backgroundColor: "#323230",
            },
            ".rdrNextPrevButton": {
              backgroundColor: "#323230",
            },
            ".rdrPprevButton i": {
              borderColor: "transparent rgb(219 219 219) transparent transparent",
            },
            ".rdrNextButton i": {
              borderColor: "transparent transparent transparent rgb(219 219 219)",
            },
            ".rdrMonthAndYearPickers select": {
              color: "#E8E8E8",
            },
            ".rdrMonthAndYearPickers option": {
              color: "#191918",
            },
            ".rdrDayNumber span": { color: "#E8E8E8" },
            ".rdrDayPassive .rdrDayNumber span": {
              color: "#606060",
            },
            ".rdrDateDisplayItemActive input": {
              color: "#E8E8E8",
            },
          })}
        >
          <DateRange
            onChange={item => onSelect(item.selection as DateRangeType)}
            staticRanges={[]}
            inputRanges={[]}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            rangeColors={["#5C14EB"]}
            ranges={[
              {
                startDate: value.startDate ?? new Date(),
                endDate: value.endDate ?? new Date(),
                key: "selection",
              },
            ]}
          />
        </div>
      </Popover>
    </div>
  )
}

export default SelectDateRange
