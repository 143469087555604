import { IconProps } from "."

export const GlobalIcon = (props: IconProps) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_2425_70277)">
        <path
          d="M14.6663 8.00033C14.6663 4.31843 11.6816 1.33366 7.99967 1.33366C4.31778 1.33366 1.33301 4.31843 1.33301 8.00033C1.33301 11.6822 4.31778 14.667 7.99967 14.667C11.6816 14.667 14.6663 11.6822 14.6663 8.00033Z"
          stroke="currentColor"
          strokeWidth="1.5"
        />
        <path
          d="M13.3327 3.79949C12.7095 3.8444 11.9114 4.08565 11.3579 4.80201C10.3583 6.0959 9.35869 6.20387 8.69228 5.77257C7.69265 5.12563 8.53269 4.07774 7.35944 3.50826C6.59477 3.13711 6.48815 2.12713 6.91374 1.3335"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinejoin="round"
        />
        <path
          d="M1.33301 7.3335C1.84134 7.77491 2.55331 8.17896 3.39217 8.17896C5.1253 8.17896 5.47192 8.5101 5.47192 9.83469C5.47192 11.1593 5.47192 11.1593 5.81855 12.1527C6.04401 12.7989 6.12282 13.4451 5.67341 14.0002"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinejoin="round"
        />
        <path
          d="M14.6663 8.96855C14.0749 8.62771 13.333 8.48751 12.5819 9.02731C11.1448 10.0602 10.1539 9.20433 9.70763 10.0596C9.05067 11.3187 11.3968 11.7144 9.33301 14.667"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2425_70277">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
