import { PromptItem } from "@/api/sdk"
import IconButton from "@/components/IconButton"
import { useState } from "react"

import FolderActionRow from "@/components/Workspace/FolderActions/FolderActionRow"
import {
  DeleteIcon,
  MoreHorizontalIcon,
  ScienceIcon,
  ThreeDotHorizontalIcon,
  UploadIcon,
} from "@/components/shared/icons"
import { useToast } from "@/hooks"
import { useAuth } from "@/providers/authContext"
import { googleAnalytics } from "@/lib/gtag"

import { useDeleteItemStore, useRecipeImageStore } from "@/stores"
import { cn } from "@/utils/cn"
import useCustomRouter from "@/hooks/useCustomRouter"
import Popover from "@/components/Popover"

interface ActionButtonsProps {
  promptData: PromptItem
  className?: string
  layout?: "grid" | "list"
}

const PromptActionButtons = (props: ActionButtonsProps) => {
  const { promptData, className, layout } = props
  const router = useCustomRouter()

  const toast = useToast()
  const { user } = useAuth()
  const [isOpen, setIsOpen] = useState(false)
  const setDeleteItem = useDeleteItemStore(state => state.setDeletingItem)
  const setRecipeImage = useRecipeImageStore(state => state.setRecipeImage)

  const handleRecipe = async () => {
    googleAnalytics.event({
      action: "click",
      category: "prompt_item",
      label: `send_to_recipe`,
      params: {
        prompt_id: promptData.id,
        prompt_name: promptData.name ?? promptData.id,
        pathname: window.location.pathname,
      },
    })

    const params = {
      prompt: `${promptData.prompt}`,
    }

    setRecipeImage({
      id: promptData.id,
      type: "wildcard",
      isModal: true,
      params: {
        ...params,
      },
    })
  }

  const actions = [
    {
      label: "Send to recipe",
      icon: <ScienceIcon width={16} height={16} />,
      onClick: () => {
        handleRecipe()
      },
      visible: !!promptData,
      enabled: !!promptData,
    },
    {
      label: "Update",
      icon: <UploadIcon width={16} height={16} />,
      onClick: () => {
        router.push({
          query: {
            promptId: promptData.id,
            isUpdate: true,
          },
        })
      },
      visible: !!promptData,
      enabled: !!promptData,
    },
    {
      label: "Delete",
      icon: <DeleteIcon className="text-red-500" width={16} height={16} />,
      onClick: () => {
        googleAnalytics.event({
          action: "click",
          category: "prompt_item",
          label: "delete_prompt",
          params: {
            prompt_id: promptData.id,
            prompt_name: promptData.name ?? promptData.id,
            pathname: window.location.pathname,
          },
        })

        setDeleteItem({
          id: promptData.id,
          type: "prompt",
          deletedAt: null,
        })
      },
      visible: !!promptData,
      enabled: !!promptData,
    },
  ]

  return (
    <div
      className={cn(
        "transition-opacity duration-300",
        {
          "opacity-100 lg:opacity-100": isOpen,
          "absolute top-1.5 right-1.5 opacity-100 lg:opacity-0 group-hover:opacity-100": layout === "grid",
        },
        className,
      )}
    >
      <Popover
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        trigger={
          <IconButton colorScheme="secondary" className={"bg-blackAlpha-700 px-2 min-h-[1.5rem]"}>
            <ThreeDotHorizontalIcon width={14} height={14} />
          </IconButton>
        }
        align="end"
      >
        <div
          className={
            "text-sm w-[16rem] text-atherGray-300 bg-atherGray-900 border border-atherGray-850 p-2 rounded-2xl space-y-1"
          }
        >
          {actions
            .filter(a => a.visible)
            .map(action => (
              <FolderActionRow
                key={action.label}
                iconLeft={action.icon}
                onClick={e => {
                  e.stopPropagation()
                  action.onClick?.()
                  setIsOpen(false)
                }}
                isDisabled={!action.enabled}
              >
                {action.label}
              </FolderActionRow>
            ))}
        </div>
      </Popover>
    </div>
  )
}

export default PromptActionButtons
