import { IconProps } from "."

export const ArrowUpDoubleIcon = (props: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 16" fill="none" {...props}>
      <path
        d="M13 7.49995C13 7.49995 8.58107 1.50001 6.99995 1.5C5.41884 1.49999 1 7.5 1 7.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 14.5C13 14.5 8.58107 8.50001 6.99995 8.5C5.41884 8.49999 1 14.5 1 14.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
