import client from "@/api/client"
import { actionMiddleware } from "@/queries/middlewares/actionMiddleware"
import { createMutation } from "react-query-kit"
import { useWorkspacePinnedMessagesInfiniteQuery } from "./useWorkspacePinnedMessagesInfiniteQuery"
import { ChatMessage, ChatRoomItem } from "@/api/sdk"
import { InfiniteData } from "@tanstack/react-query"

export const usePinMessageMutation = createMutation({
  mutationFn: async ({ messageId, roomId }: { messageId: string; roomId: string; data?: ChatMessage }) => {
    return await client.api.chatRoomControllerPinMessage(messageId, roomId).then(res => res.data)
  },
  use: [
    actionMiddleware({
      onSuccess: ({ queryClient, variables }) => {
        const pinnedMessagesKey = useWorkspacePinnedMessagesInfiniteQuery.getKey({
          roomId: variables.roomId,
        })

        const pinnedMessageEntites = queryClient.getQueriesData<InfiniteData<ChatMessage[], number>>({
          queryKey: pinnedMessagesKey,
        })

        pinnedMessageEntites?.forEach(([key, pinnedMessageEntitiesListData]) => {
          const updatedPinnedMessageEntitiesListData = {
            ...pinnedMessageEntitiesListData,
            pages: pinnedMessageEntitiesListData?.pages.map(page => {
              return [variables.data, ...page]
            }),
          }

          queryClient.setQueryData(key, updatedPinnedMessageEntitiesListData)
        })

        // queryClient.invalidateQueries({ queryKey: pinnedMessagesKey })
      },
    }),
  ],
})

export const useUnPinMessageMutation = createMutation({
  mutationFn: async ({ messageId, roomId }: { messageId: string; roomId: string }) => {
    return await client.api.chatRoomControllerUnpinMessage(messageId, roomId).then(res => res.data)
  },
  use: [
    actionMiddleware({
      onSuccess: ({ queryClient, variables }) => {
        const pinnedMessagesKey = useWorkspacePinnedMessagesInfiniteQuery.getKey({
          roomId: variables.roomId,
        })

        const pinnedMessageEntites = queryClient.getQueriesData<InfiniteData<ChatMessage[], number>>({
          queryKey: pinnedMessagesKey,
        })

        pinnedMessageEntites?.forEach(([key, pinnedMessageEntitiesListData]) => {
          const updatedPinnedMessageEntitiesListData = {
            ...pinnedMessageEntitiesListData,
            pages: pinnedMessageEntitiesListData?.pages.map(page => {
              return page.filter(pinnedMessage => pinnedMessage.id !== variables.messageId)
            }),
          }

          console.log(updatedPinnedMessageEntitiesListData)

          queryClient.setQueryData(key, updatedPinnedMessageEntitiesListData)
        })

        // queryClient.invalidateQueries({ queryKey: pinnedMessagesKey })
      },
    }),
  ],
})
