import { IconProps } from "."

export const PinIcon = (props: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path d="M1 19L6 14" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M11.2585 16.8714C7.51516 16.0215 3.97844 12.4848 3.12853 8.7415C2.99399 8.14894 2.92672 7.85266 3.12161 7.37197C3.3165 6.89129 3.55457 6.74255 4.03071 6.44509C5.10705 5.77265 6.27254 5.55888 7.48209 5.66586C9.1793 5.81598 10.0279 5.89104 10.4512 5.67048C10.8746 5.44991 11.1622 4.93417 11.7376 3.90269L12.4664 2.59604C12.9465 1.73528 13.1866 1.3049 13.7513 1.10202C14.316 0.899132 14.6558 1.02199 15.3355 1.26771C16.9249 1.84236 18.1576 3.07505 18.7323 4.66449C18.978 5.34417 19.1009 5.68401 18.898 6.2487C18.6951 6.8134 18.2647 7.05346 17.4039 7.53358L16.0672 8.27919C15.0376 8.85343 14.5229 9.14056 14.3024 9.56802C14.0819 9.99549 14.162 10.8256 14.3221 12.4859C14.4399 13.7068 14.2369 14.88 13.5555 15.9697C13.2577 16.4458 13.1088 16.6839 12.6283 16.8786C12.1477 17.0733 11.8513 17.006 11.2585 16.8714Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
