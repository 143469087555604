import { IconProps } from "."

export const ShareIcon3 = (props: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none" {...props}>
      <path
        d="M6.40016 1C3.96705 1.00438 2.69295 1.06411 1.87871 1.87836C1 2.75706 1 4.17132 1 6.99983C1 9.82833 0.999999 11.2426 1.8787 12.1213C2.75741 13 4.17166 13 7.00017 13C9.82868 13 11.2429 13 12.1216 12.1213C12.9359 11.3071 12.9956 10.033 13 7.59984"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.9993 3.01686L12.333 3.01518C9.84195 3.00887 8.59643 3.00572 7.72082 3.6351C7.42984 3.84426 7.17449 4.09896 6.9646 4.38941C6.33301 5.26343 6.33301 6.50896 6.33301 9M12.9993 3.01686C13.0038 2.90844 12.9651 2.79937 12.8831 2.70222C12.373 2.09791 11.0471 1 11.0471 1M12.9993 3.01686C12.9953 3.11408 12.9565 3.21077 12.883 3.29788C12.3728 3.90207 11.0471 5 11.0471 5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ShareIcon3
