import { SDStyleDetail, StyleImage, UpdateSDStyleDto } from "@/api/sdk"
import { useToast } from "@/hooks"
import { googleAnalytics } from "@/lib/gtag"
import { css } from "@emotion/css"
import classNames from "classnames"
import React, { useEffect, useRef, useState } from "react"
import { uploadStyleFiles } from "./StyleDetail"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import client from "@/api/client"
import { useWorkspaceStyleDetailQuery } from "@/queries/tools/style/useGetStyleInfiniteQuery"
import { motion } from "framer-motion"
import ImageUpload from "@/components/ImageUpload"
import { ImageViewWithDelete } from "@/components/ImageUpload/ImageView"
import { DeleteIcon, ChevronDownIcon, UploadIcon } from "@/components/shared/icons"
import ReactSlider from "react-slider"
import WorkspaceContainer from "@/components/Workspace/WorkspaceContainer"

interface StyleImagesUploadedProps {
  style: SDStyleDetail
  disabledInput: boolean
  images: StyleImage[]
  setImages: React.Dispatch<React.SetStateAction<StyleImage[]>>
  updateStyle: (style: UpdateSDStyleDto) => void
  width: number
  onCloseSidebar?: () => void
  onSave?: () => void
}

const StyleImagesUploaded = ({
  disabledInput,
  images,
  onCloseSidebar,
  onSave,
  style,
  setImages,
  updateStyle,
  width,
}: StyleImagesUploadedProps) => {
  const toast = useToast()
  const qc = useQueryClient()
  const fileInputImagesRef = useRef<HTMLInputElement>(null)
  const [isOpenImageDetails, setOpenImageDetails] = useState(true)

  const { mutateAsync } = useMutation({
    mutationFn: ({ styleId, fileIds }: { styleId: string; fileIds: string[] }) =>
      client.api.sdStyleControllerAddStyleImages(styleId, { fileIds, weights: [] }).then(res => res.data),

    onSuccess: ({ images }) => {
      // update style detail
      const styleDetailKey = useWorkspaceStyleDetailQuery.getKey({
        styleId: style.id,
      })
      const styleDetailKeyQueriesDataEntries = qc.getQueriesData<SDStyleDetail>({
        queryKey: styleDetailKey,
      })
      styleDetailKeyQueriesDataEntries?.forEach(([key, data]) => {
        if (!data) return

        const updatedData = {
          ...data,
          images,
        }

        qc.setQueryData(key, updatedData)
      })
    },
  })

  const handleUploadImages = async (files: Blob[]) => {
    if (!style) return

    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        action: "Upload style images",
        style_id: style.id ?? "",
        style_name: style?.name ?? "",
      },
    })

    for (const file of files) {
      if (file.size > 10 * 1000 * 1000) {
        toast({
          status: "error",
          title: "File size too large",
          message: ["Input image exceeds 10MB, please scale down the image and try again"],
        })
        return
      }
    }

    // limit 8 images
    if (images.length + files.length > 8) {
      files = files.slice(0, 8 - images.length)
    }

    const uploaded = await uploadStyleFiles(files, () => {
      toast({
        status: "error",
        title: "Error",
        message: ["Failed to upload image"],
      })
    })
    const fileIds = uploaded.map(f => f.id)
    const updated = await mutateAsync({ styleId: style.id, fileIds })

    setImages(updated.images)
  }

  const handleDeleteImage = async (idx: number) => {
    if (!style) return

    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        action: "Delete style image",
        style_id: style.id ?? "",
        style_name: style?.name ?? "",
      },
    })

    const newImages = images.slice(0, idx).concat(images.slice(idx + 1))
    return updateStyle({
      images: newImages,
    })
  }

  const handleDeleteAllImages = async () => {
    if (!style) return

    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        action: "Delete all style images",
        style_id: style.id ?? "",
        style_name: style?.name ?? "",
      },
    })

    return updateStyle({
      images: [],
    })
  }

  const handleChangeWeight = (imageIdx: number, weight: number) => {
    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        action: "Update style weight",
        style_id: style.id ?? "",
        style_name: style?.name ?? "",
      },
    })

    onSave?.()

    setImages(prev => {
      const newImages = [...prev]
      newImages[imageIdx].weight = weight
      return newImages
    })
  }

  useEffect(() => {
    if (width && width < 768) {
      onCloseSidebar?.()
      setOpenImageDetails(false)
    }
    if (width && width > 768) {
      setOpenImageDetails(true)
    }
  }, [width])

  return (
    <div className="p-4 md:p-6 mb-4">
      {style.capabilities?.canUpdate && (
        <WorkspaceContainer
          className={classNames(
            "bg-atherGray-900 p-4 rounded-xl",
            css({
              ".track-slider-0": {
                backgroundColor: disabledInput ? "rgb(76,75,71)" : "#5E17EB",
              },
              ".track-slider-1": {
                backgroundColor: "#E2E8F0",
              },
            }),
          )}
        >
          <div className="flex items-stretch gap-2 pb-4">
            <div className="text-sm flex-1 flex gap-2 flex-col items-start lg:flex-row lg:items-center">
              <p className="font-semibold text-whiteAlpha-900">Style Images</p>
              <span className="text-sm text-atherGray-400">
                Upload upto 8 images to used as style reference. Use square images for best results.
              </span>
            </div>
            <div className="flex flex-col gap-4 items-end justify-center">
              <button
                className={classNames({
                  hidden: width < 768 || disabledInput || !images.length,
                })}
                onClick={handleDeleteAllImages}
              >
                <div className="flex items-center text-sm font-semibold">
                  <DeleteIcon className="text-red-500" /> &nbsp; Remove all
                </div>
              </button>
              <ChevronDownIcon
                className={classNames("md:hidden", {
                  "-rotate-90": !isOpenImageDetails,
                })}
                onClick={() => setOpenImageDetails(curr => !curr)}
              />
            </div>
          </div>

          <motion.div
            className="flex flex-wrap gap-4"
            initial={{ height: 0, opacity: 0.5 }}
            animate={{ height: isOpenImageDetails ? "auto" : 0, opacity: isOpenImageDetails ? 1 : 0 }}
          >
            <div className="w-full text-right">
              <button
                type="button"
                className={classNames({
                  hidden: width >= 768 || disabledInput || !images.length,
                })}
                onClick={handleDeleteAllImages}
              >
                <div className="flex items-center text-sm">
                  <DeleteIcon className="text-red-500" /> &nbsp; Remove all
                </div>
              </button>
            </div>

            {images.length < 8 && (
              <div className="w-full md:w-64 2xl:w72">
                <ImageUpload
                  fileInputRef={fileInputImagesRef}
                  multiple
                  upload={handleUploadImages}
                  disabled={disabledInput}
                  className={classNames({ "!cursor-not-allowed": disabledInput })}
                >
                  <div className="h-full flex flex-col items-center justify-center">
                    <div
                      className={classNames("p-2 mb-2 rounded-full", {
                        "bg-atherPurple-500": !disabledInput,
                        "bg-atherGray-800": disabledInput,
                      })}
                    >
                      <UploadIcon />
                    </div>
                    <p className="text-atherGray-300 text-center">
                      Upload images or Drag & Drop <br />
                      (max 2048px and less than 10MB)
                    </p>
                  </div>
                </ImageUpload>
              </div>
            )}
            {images.map((image, idx) => (
              <div key={image.url} className="flex flex-col w-full md:w-64 2xl:w72 mb-4">
                <ImageViewWithDelete
                  className="overflow-hidden flex-shrink-0 h-[10rem] w-full bg-atherGray-950 border-dashed border border-atherGray-700 rounded-lg"
                  url={image.url}
                  onDelete={() => handleDeleteImage(idx)}
                  preferThumbnail
                  disabled={disabledInput}
                />
                <div className="flex justify-between mt-1 mb-2">
                  <p className="text-xs text-atherGray-400">Low</p>
                  <p className="text-xs text-atherGray-400">Medium</p>
                  <p className="text-xs text-atherGray-400">High</p>
                </div>
                <div
                  className={css({
                    position: "relative",
                    width: "100%",
                    ".track-slider-0": {
                      backgroundColor: "#5E17EB",
                    },
                    ".track-slider-1": {
                      backgroundColor: "#E2E8F0",
                    },
                  })}
                >
                  <ReactSlider
                    value={image.weight}
                    onChange={v => handleChangeWeight(idx, v)}
                    min={0}
                    max={1}
                    step={0.1}
                    marks
                    className="h-1 horizontal-slider w-full"
                    thumbClassName="h-4 w-4 bg-white top-1/2 -translate-y-1/2 rounded-full cursor-pointer"
                    trackClassName="h-1 track-slider"
                    renderThumb={(props, _) => (
                      <div {...props}>
                        <div
                          className={classNames(
                            "w-2.5 h-2.5 absolute top-1/2 left-1/2 -translate-x-[50%] -translate-y-[50%] rounded-full",
                            {
                              "bg-atherPurple-500": !disabledInput,
                              "bg-atherGray-700": disabledInput,
                            },
                          )}
                        />
                      </div>
                    )}
                    disabled={disabledInput}
                  />
                </div>
              </div>
            ))}
          </motion.div>
        </WorkspaceContainer>
      )}
    </div>
  )
}

export default StyleImagesUploaded
