import { FolderDetail, UserDetail } from "@/api/sdk"
import IconButton from "@/components/IconButton"
import Popover from "@/components/Popover"
import GalleryFolderMenu from "@/components/Workspace/Gallery/GalleryFolderMenu"
import { MoreHorizontalIcon, PinIcon, TickIcon } from "@/components/shared/icons"
import { googleAnalytics } from "@/lib/gtag"
import { cn } from "@/utils/cn"
import classNames from "classnames"
import { Url } from "next/dist/shared/lib/router/router"
import Image from "next/image"
import useCustomRouter from "@/hooks/useCustomRouter"
import { RefObject, useState } from "react"
import { ConnectDragSource } from "react-dnd"
import FolderIcon from "../../Folder/FolderIcon"
import UserCreated from "@/components/Explore/Gallery/UserCreated"

interface FolderItemGridProps {
  folder: FolderDetail
  user: UserDetail | undefined
  onClick?: () => void
  selectionMode?: boolean
  dragRef?: ConnectDragSource
  imageRef: RefObject<HTMLImageElement>
  href: Url
  isChecked?: boolean
  onCheck?: (folderId: string) => void
  hiddenMenu?: boolean
}

const FolderItemGrid = ({
  folder,
  user,
  href,
  onClick,
  hiddenMenu,
  selectionMode,
  dragRef,
  imageRef,
  isChecked,
  onCheck,
}: FolderItemGridProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const router = useCustomRouter()

  return (
    <div
      ref={ref => {
        dragRef?.(ref)
      }}
      className="relative flex flex-col h-full p-4 cursor-pointer rounded-2xl bg-atherGray-800"
      onClick={() => {
        if (selectionMode) {
          onCheck?.(folder.id)

          googleAnalytics.event({
            action: "click",
            category: "folders",
            label: "folder_checked",
            params: {
              folder_id: folder.id,
              folder_name: folder.name,
              pathname: window.location.pathname,
            },
          })
        } else {
          googleAnalytics.event({
            action: "click",
            category: "folders",
            label: "view_folder_detail",
            params: {
              folder_id: folder.id,
              folder_name: folder.name,
              pathname: window.location.pathname,
            },
          })
          if (onClick) {
            onClick()
            return
          }
          router.push(href, undefined, {
            shallow: true,
          })
        }
      }}
    >
      <div
        className={classNames("absolute w-full h-full top-0 left-0 border-[2px] pointer-events-none rounded-2xl", {
          "border-atherPurple-500": isChecked,
          "border-[transparent]": !isChecked,
          "z-0": !selectionMode,
          "z-[1]": selectionMode,
        })}
      />

      <div className="flex items-center justify-between w-full mb-2 overflow-hidden">
        <div>
          <FolderIcon width={32} height={32} draggable={false} ref={imageRef} />
        </div>
        {!hiddenMenu &&
          (selectionMode ? (
            <IconButton
              onClick={e => {
                e.stopPropagation()
                if (selectionMode) {
                  googleAnalytics.event({
                    action: "click",
                    category: "gallery",
                    label: "folder_checked",
                    params: {
                      folder_id: folder.id,
                      folder_name: folder.name,
                    },
                  })

                  onCheck?.(folder.id)
                } else {
                  googleAnalytics.event({
                    action: "click",
                    category: "gallery",
                    label: "folder_view",
                    params: {
                      folder_id: folder.id,
                      folder_name: folder.name,
                    },
                  })
                  onClick?.()
                }
              }}
              className={cn("shadow-sm shadow-blackAlpha-500 bg-atherGray-700 p-0 w-7 h-7 min-h-0 rounded-full", {
                "text-white bg-atherPurple-500 hover:bg-atherPurple-400": isChecked,
                "text-atherGray-300 hover:bg-atherGray-600": !isChecked,
              })}
            >
              <TickIcon />
            </IconButton>
          ) : (
            <div className="flex items-center space-x-1">
              {folder.pinned !== undefined && folder.pinned && (
                <div className={classNames("", {})}>
                  <PinIcon width={16} height={16} />
                </div>
              )}
              <Popover
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                align="end"
                trigger={
                  <IconButton
                    colorScheme="transparent"
                    onClick={e => e.stopPropagation()}
                    className={cn("rounded-2xl p-0 w-7 h-7 min-h-0 hover:bg-atherGray-700 active:bg-atherGray-700", {
                      "bg-atherGray-700": isOpen,
                    })}
                  >
                    <MoreHorizontalIcon />
                  </IconButton>
                }
              >
                <GalleryFolderMenu folderInfo={folder} onClose={() => setIsOpen(false)} />
              </Popover>
            </div>
          ))}
      </div>
      <div>
        <div className="flex items-center mb-2 overflow-hidden">
          <p className="flex-1 text-sm font-semibold truncate">{folder.name}</p>
        </div>
        <UserCreated
          size="sm"
          picture={folder.creator.picture}
          name={folder.creator.name}
          isMe={user?.uid === folder.creator.uid}
        />
      </div>
    </div>
  )
}

export default FolderItemGrid
