import { css } from "@emotion/css"
import { LexicalEditor, EditorState, KEY_ENTER_COMMAND, $getRoot, $getSelection } from "lexical"
import React, { memo, useCallback, useRef } from "react"
import { useEffect } from "react"
import MentionPlugin, { MentionDataV2 } from "./MentionPlugin"
import CommentPlugin from "./plugin/CommentPlugin"
import DefaultInitValuePlugin from "./plugin/DefaultInitValuePlugin"
import EditorReadOnlyPlugin from "./plugin/EditorReadOnlyPlugin"
import EmojisPlugin from "./plugin/EmojiPlugin"
import FocusPlugin from "./plugin/FocusPlugin"
import { ZeroWidthPlugin, ZERO_WIDTH_CHARACTER } from "./plugin/ZeroWidthPlugin"
import { useBeautifulMentions } from "./plugin/hooks/useBeautifulMentions"
import { EmojiNode } from "./plugin/nodes/EmojiNode"
import { BeautifulMentionNode } from "./plugin/nodes/MentionNode"
import { ZeroWidthNode } from "./plugin/nodes/ZeroWidthNode"
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin"
import { AutoLinkPlugin } from "@lexical/react/LexicalAutoLinkPlugin"
import { AutoLinkNode, LinkNode } from "@lexical/link"
import { LexicalComposer } from "@lexical/react/LexicalComposer"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { EditorRefPlugin } from "@lexical/react/LexicalEditorRefPlugin"
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin"
import { BeautifulMentionsTheme } from "./plugin/components/BeautifulMentionComponent"

export const EMPTY_CONTENT_LEXICAL = `{"root":{"children":[{"children":[],"direction":null,"format":"","indent":0,"type":"paragraph","version":1}],"direction":null,"format":"","indent":0,"type":"root","version":1}}`

interface LexicalEditorProps {
  editorRef: React.MutableRefObject<LexicalEditor | null>
  editorState?: EditorState
  setEditorState: (value?: EditorState) => void
  initValue?: string
  onAddComment?: (data?: EditorState) => void
  setIsFocus?: (isFocus: boolean) => void
  mentions: MentionDataV2[]
  isMentionsLoading?: boolean
  readOnly?: boolean
  editorKey?: string
  children: JSX.Element | string
  placeholder?: string
  triggerInsertMention?: boolean
  onTriggerInsertMention?: (value: boolean) => void
  selectedMention?: MentionDataV2
  onSearchMention?: (search: string) => void
}

const theme = {
  link: "editor-link",
}

const beautifulMentionsTheme: BeautifulMentionsTheme = {
  // 👇 use the trigger name as the key
  "@": "text-[#9769F2] ...",
  // 👇 add the "Focused" suffix to style the focused mention
  "@Focused": "outline-none shadow-md ...",
  // 👇 use a configuration object if you need to apply different styles to trigger and value
  "due:": {
    trigger: "text-blue-400 ...",
    value: "text-orange-400 ...",
  },
}

const URL_MATCHER =
  /((https?:\/\/(www\.)?)|(www\.))[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/

const MATCHERS = [
  text => {
    const match = URL_MATCHER.exec(text)
    if (match === null) {
      return null
    }
    const fullMatch = match[0]
    return {
      index: match.index,
      length: fullMatch.length,
      text: fullMatch,
      url: fullMatch.startsWith("http") ? fullMatch : `https://${fullMatch}`,
      attributes: { rel: "noreferrer", target: "_blank" }, // Optional link attributes
    }
  },
]

const onError = error => {
  console.error(error)
}

const CommandKeyPlugin = ({ onEnter }: { onEnter: (e: KeyboardEvent) => boolean }) => {
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    return editor.registerCommand(
      KEY_ENTER_COMMAND,
      (event: KeyboardEvent) => {
        if (document.getElementById("plugin-mention") || document.getElementById("plugin-emoji")) return false

        if (event.key === "Enter" && event.shiftKey) {
          return false
        }

        if (event.key === "Enter" && event.ctrlKey) {
          return false
        }

        if (event.key === "Enter") {
          return onEnter(event)
        }
        return false
      },
      1,
    )
  }, [editor, onEnter])

  return null
}

const ChildrenPlugin = ({
  children,
  valueMention,
  triggerInsertMention = false,
  onTriggerInsertMention,
}: {
  children: JSX.Element | string
  valueMention?: MentionDataV2
  triggerInsertMention?: boolean
  onTriggerInsertMention?: (value: boolean) => void
}) => {
  const [editor] = useLexicalComposerContext()
  const { insertMention } = useBeautifulMentions()
  const isFirstRender = useRef(false)

  const handleInsertMention = useCallback(() => {
    if (!valueMention || !triggerInsertMention) return
    const { value, ...others } = valueMention

    editor.update(() => {
      insertMention({
        trigger: "@",
        value,
        data: others,
        focus: true,
      })
    })

    isFirstRender.current = true

    if (triggerInsertMention) {
      onTriggerInsertMention?.(false)
      isFirstRender.current = false
    }
  }, [valueMention, triggerInsertMention, insertMention, onTriggerInsertMention, editor])

  useEffect(() => {
    if (isFirstRender.current) return

    const timeout = setTimeout(() => {
      handleInsertMention()
    }, 100)

    return () => clearTimeout(timeout)
  }, [handleInsertMention])

  return <>{children}</>
}

const LexicalCustomEditor = ({
  setEditorState,
  readOnly,
  triggerInsertMention,
  isMentionsLoading,
  onTriggerInsertMention,
  editorKey,
  editorState,
  editorRef,
  initValue,
  placeholder,
  setIsFocus,
  children,
  selectedMention,
  onAddComment,
  onSearchMention,
  mentions,
}: LexicalEditorProps) => {
  const initialConfig = {
    namespace: `${editorKey}-editor` || "lexical-editor",
    editorState,
    editable: !readOnly,
    theme: {
      ...theme,
      beautifulMentions: beautifulMentionsTheme,
    },
    onError,
    nodes: [AutoLinkNode, LinkNode, EmojiNode, BeautifulMentionNode, ZeroWidthNode],
  }

  const getValueText = () => {
    if (!editorRef || !editorRef.current) return ""

    const JSONEditorState = JSON.stringify(editorRef.current.getEditorState().toJSON())

    const parsedEditorState = editorRef.current.parseEditorState(JSONEditorState)

    const text = parsedEditorState.read(() => $getRoot().getTextContent())

    return text
  }

  const submit = e => {
    e.preventDefault()
    e.stopPropagation()

    const editorState = editorRef?.current?.getEditorState()

    if (getValueText().length === 0) return

    onAddComment?.(editorState)
  }

  const onEnter = (event: KeyboardEvent): boolean => {
    event.preventDefault()

    submit(event as any)

    return true
  }

  const onChangeConvert = useCallback(
    (editorState: EditorState, editor: LexicalEditor) => {
      editor.update(() => {
        const selection = $getSelection()

        setEditorState(editorState)
      })
    },
    [setEditorState],
  )

  return (
    <div className="flex-1 relative text-sm items-center">
      <div
        className={css({
          width: "100%",
        })}
      >
        <div
          onKeyDown={e => {
            if (e.key === "Enter") {
              submit(e as any)
            }
          }}
          className="flex w-full flex-col relative"
          spellCheck="false"
        >
          <LexicalComposer key={editorKey} initialConfig={initialConfig}>
            <CommentPlugin
              submit={submit}
              placeholder={placeholder}
              isDisabled={getValueText().length === 0}
              editorRef={editorRef}
              editorKey={editorKey}
              readOnly={readOnly}
            />
            {/* <EmojiPickerPlugin /> */}
            <FocusPlugin
              onFocus={(value: boolean) => {
                setIsFocus?.(value)
                if (value) {
                  editorRef.current?.focus()
                }
              }}
            />
            <DefaultInitValuePlugin
              autoFocus={false}
              triggerResetInitValue={false}
              initValue={initValue}
              onChange={onChangeConvert}
            />
            <CommandKeyPlugin onEnter={onEnter} />
            <EditorRefPlugin editorRef={editorRef} />
            <EditorReadOnlyPlugin readOnly={readOnly} />
            <HistoryPlugin />
            <LinkPlugin />
            <ZeroWidthPlugin textContent={ZERO_WIDTH_CHARACTER} />
            <AutoLinkPlugin matchers={MATCHERS} />
            <MentionPlugin
              isMentionsLoading={isMentionsLoading}
              onSearchMention={onSearchMention}
              mentionItems={mentions}
            />
            <EmojisPlugin />
            <ChildrenPlugin
              valueMention={selectedMention}
              triggerInsertMention={triggerInsertMention}
              onTriggerInsertMention={onTriggerInsertMention}
            >
              {children}
            </ChildrenPlugin>
          </LexicalComposer>
        </div>
      </div>
    </div>
  )
}

export default memo(LexicalCustomEditor)
