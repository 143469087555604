import { IconProps } from "."

export const FileExportIcon = (props: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20" fill="none" {...props}>
      <path
        d="M14.4167 13.333C14.8381 13.7426 16.5 14.8328 16.5 15.4163M14.4167 17.4997C14.8381 17.0901 16.5 15.9999 16.5 15.4163M16.5 15.4163L9.83333 15.4163"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.16602 18.333H7.93874C5.22107 18.333 3.86224 18.333 2.91857 17.6681C2.6482 17.4777 2.40817 17.2517 2.20577 16.9973C1.49935 16.1091 1.49935 14.8302 1.49935 12.2724V10.1512C1.49935 7.68188 1.49935 6.44723 1.89013 5.46113C2.51836 3.87586 3.84696 2.62541 5.53132 2.03413C6.57904 1.66634 7.89086 1.66634 10.5145 1.66634C12.0137 1.66634 12.7633 1.66634 13.362 1.87651C14.3245 2.21438 15.0837 2.92892 15.4427 3.83479C15.666 4.39827 15.666 5.10379 15.666 6.51483V10.833"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.50065 10C1.50065 8.46588 2.7443 7.22222 4.27843 7.22222C4.83325 7.22222 5.48735 7.31944 6.02679 7.1749C6.50608 7.04647 6.88045 6.6721 7.00888 6.1928C7.15342 5.65336 7.05621 4.99926 7.05621 4.44444C7.05621 2.91032 8.29986 1.66667 9.83398 1.66667"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
