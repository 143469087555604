import { StableDiffusionMetadata } from "@/api/sdk"
import exifr from "exifr"
import _camelCase from "lodash/camelCase"

export const getMetadata = async (file: File): Promise<StableDiffusionMetadata> => {
  try {
    const exif = await exifr.parse(file)
    const automaticMetadata = parseAutomaticMetadata(exif.parameters)
    return { ...automaticMetadata, width: exif.ImageWidth, height: exif.ImageHeight }
  } catch (err) {
    return getImageDimensions(file)
  }
}

export const parseAutomaticMetadata = (parameters: string) => {
  const stableDiffusionMetadata = parseAutomaticParameters(parameters)

  return stableDiffusionMetadata
}

const parseAutomaticParameters = (value: string) => {
  const negativePromptIdxStart = value.indexOf("\nNegative prompt:")
  const promptEndIdx = negativePromptIdxStart > -1 ? negativePromptIdxStart : value.lastIndexOf("\n")
  const prompt = value.slice(0, promptEndIdx)
  const additionalInfoIdxStart = value.lastIndexOf("\n")
  const negativePrompt =
    negativePromptIdxStart > -1
      ? value.slice(negativePromptIdxStart + "Negative prompt:".length + 1, additionalInfoIdxStart + 1).trim()
      : ""

  const result = {
    prompt,
    negativePrompt,
    ...parseExtraMeta(value.slice(additionalInfoIdxStart + 1)),
  }
  return result
}

const parseExtraMeta = (input: string) => {
  const regex = /(?:([A-Za-z0-9_\s]+):\s((?:\d+(?:\.\d+)?|\{[^}]+\}|"[^"]+"|[^,]*)))(?:,\s|$)/g

  const result: Record<string, string | number | boolean> = {}

  let match
  while ((match = regex.exec(input))) {
    const key = _camelCase(match[1].trim())
    const value = match[2].trim()
    result[key] = value
  }

  return result
}

const getImageDimensions = async (file: File): Promise<{ width: number; height: number }> => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = function () {
      resolve({ width: img.width, height: img.height })
    }
    img.onerror = reject
    img.src = URL.createObjectURL(file)
  })
}

export const formatEmail = (email?: string): string => {
  if (!email) return ""
  const [username, domain] = email.split("@")
  if (username.length > 1) {
    const formattedUsername = `${username[0]}***${username[username.length - 1]}`
    return `${formattedUsername}@${domain}`
  }
  return email // Return original email if username part is too short to format
}

export const warpNameLength = (name: string, fromLength = 12, toLength = 10) => {
  const length = name.length
  if (length > fromLength + toLength) {
    return name.slice(0, fromLength) + "..." + name.slice(length - toLength, length)
  }
  return name
}

export const chunkString = (str: string, length: number) => {
  return typeof str === "string" ? str.match(new RegExp(`.{1,${length}}`, "g")) : null
}
