import { IconProps } from "."

export const ComputerIcon = (props: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M11.667 1.66602H8.33366C5.60097 1.66602 4.23462 1.66602 3.26608 2.3442C2.90775 2.5951 2.59608 2.90677 2.34517 3.2651C1.66699 4.23365 1.66699 5.59999 1.66699 8.33268C1.66699 11.0654 1.66699 12.4317 2.34517 13.4003C2.59608 13.7586 2.90775 14.0703 3.26608 14.3212C4.23462 14.9993 5.60097 14.9993 8.33366 14.9993H11.667C14.3997 14.9993 15.766 14.9993 16.7346 14.3212C17.0929 14.0703 17.4046 13.7586 17.6555 13.4003C18.3337 12.4317 18.3337 11.0654 18.3337 8.33268C18.3337 5.59999 18.3337 4.23365 17.6555 3.2651C17.4046 2.90677 17.0929 2.5951 16.7346 2.3442C15.766 1.66602 14.3997 1.66602 11.667 1.66602Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M9.16699 12.5H10.8337"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0837 18.3333L11.8207 17.9843C11.228 17.1974 11.0811 15.9953 11.456 15M7.91699 18.3333L8.17991 17.9843C8.7726 17.1974 8.91954 15.9953 8.54469 15"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path d="M5.83398 18.332H14.1673" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  )
}
