import { create } from "zustand"

type CopyStyleStoreType = {
  styleId: string | null
  setStyle: (styleId: string | null) => void
}

export const useCopyStyleStore = create<CopyStyleStoreType>(set => ({
  styleId: null,
  setStyle: styleId => set({ styleId }),
}))
