import HiddenFileInput from "@/components/HiddenFileInput"
import LoadingIcon from "@/components/LoadingIcon"
import { useImageDrop } from "@/hooks"
import { cn } from "@/lib/utils"
import { motion } from "framer-motion"
import React, { FC, HTMLAttributes, ReactNode, useState } from "react"

interface ImageUploadProps extends Omit<HTMLAttributes<HTMLDivElement>, "onChange"> {
  upload: (files: File[]) => Promise<void>
  multiple?: boolean
  disabled?: boolean
  children?: ReactNode
  fileInputRef: React.RefObject<HTMLInputElement>
  isLoading?: boolean
}

const ImageUpload: FC<ImageUploadProps> = ({
  children,
  multiple,
  isLoading,
  upload,
  disabled,
  fileInputRef,
  ...props
}) => {
  const [isUploading, setUploading] = useState(false)

  const triggerFileSelect = () => {
    if (isUploading || !fileInputRef) return

    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const handleUpload = async (files: File[]) => {
    setUploading(true)
    return upload(files)
      .catch(() => {})
      .finally(() => {
        setUploading(false)
      })
  }

  const { isOver: isDroppingImage, bind } = useImageDrop(
    async files => {
      if (files.length > 0) {
        handleUpload(files)
      }
    },
    undefined,
    {
      enabled: !disabled && !isUploading && !isLoading,
    },
  )

  return (
    <>
      <div
        {...props}
        className={cn(
          "relative bg-atherGray-950 overflow-hidden h-[10rem] border-dashed border border-atherGray-700 rounded-xl font-normal text-sm cursor-pointer",
          {
            "bg-atherGray-850": isDroppingImage,
          },
          props.className,
        )}
        onClick={triggerFileSelect}
        {...bind}
      >
        {children}
        {!disabled && isDroppingImage && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="absolute top-0 left-0 flex items-center justify-center w-full h-full bg-blackAlpha-800 rounded-xl pointer-events-none select-none"
          >
            <p className="text-atherOrange-500">Drop to upload</p>
          </motion.div>
        )}
        {(isUploading || isLoading) && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="absolute top-0 left-0 flex items-center justify-center w-full h-full bg-blackAlpha-800 rounded-xl"
          >
            <LoadingIcon width={20} height={20} />
          </motion.div>
        )}
      </div>
      <HiddenFileInput
        multiple={multiple}
        disabled={disabled}
        onUpload={files => {
          if (!fileInputRef) return

          if (files.length > 0) {
            handleUpload(files).then(() => {
              if (fileInputRef.current) {
                fileInputRef.current.value = ""
              }
            })
          }
        }}
        ref={fileInputRef}
      />
    </>
  )
}

export default ImageUpload
