import { useAuth } from "@/providers/authContext"
import { cn } from "@/lib/utils"
import { currency } from "@/utils"
import React from "react"
import { WOWIcon } from "@/components/shared/icons"
import useCustomRouter from "@/hooks/useCustomRouter"

const PointsInfo = ({ className, isNotFormat }: { className?: string; isNotFormat?: boolean }) => {
  const { userInfoQuery } = useAuth()
  const router = useCustomRouter()

  return (
    <div
      className={cn(
        "flex items-center space-x-1 bg-atherGray-900 rounded-full py-1.5 px-2 w-[100px] cursor-pointer",
        className,
      )}
      onClick={() => {
        router.push("/quests")
      }}
      title={`${currency(parseInt(userInfoQuery?.data?.wowPoints?.toString() ?? "0"), undefined, {
        isConvert: false,
      })}`}
    >
      <WOWIcon width={24} height={24} />
      <span className="text-base text-right font-semibold flex-1 truncate">
        {currency(parseInt(userInfoQuery?.data?.wowPoints?.toString() ?? "0"), undefined, {
          isConvert: isNotFormat ? false : true,
        })}
      </span>
    </div>
  )
}

export default PointsInfo
