import client from "@/api/client"
import { GetSubFoldersResponse, RecentFolderItem } from "@/api/sdk"
import { InfiniteData } from "@tanstack/react-query"
import { createMutation } from "react-query-kit"
import { actionMiddleware } from "../../middlewares/actionMiddleware"
import { useFolderInfoQuery } from "./useFolderInfoQuery"
import { useSubFoldersInfiniteQuery } from "./useSubFoldersInfiniteQuery"
import { useRecentFolderInfiniteQuery } from "@/components/Workspace/RecentV2/RecentFolders"

export interface UseRenameFolderMutationVariables {
  folderId: string
  newName: string
  parentFolderId?: string
}

export const useRenameFolderMutation = createMutation({
  mutationFn: ({ folderId, newName }: UseRenameFolderMutationVariables) =>
    client.api.folderControllerRenameFolder(folderId!, { newName }),
  use: [
    actionMiddleware({
      onSuccess: ({ queryClient, variables }) => {
        if (variables.parentFolderId) {
          const parentSubFoldersKey = useSubFoldersInfiniteQuery.getKey({
            folderId: variables.parentFolderId,
          })

          const parentSubFoldersQueriesDataEntries = queryClient.getQueriesData<
            InfiniteData<GetSubFoldersResponse, number>
          >({
            queryKey: parentSubFoldersKey,
          })

          if (parentSubFoldersQueriesDataEntries) {
            parentSubFoldersQueriesDataEntries.forEach(([key, parentSubFoldersData]) => {
              if (parentSubFoldersData) {
                const updatedData = {
                  ...parentSubFoldersData,
                  pages: parentSubFoldersData.pages.map(page => {
                    return {
                      ...page,
                      folders: page.folders.map(folder => {
                        if (folder.id === variables.folderId) {
                          return {
                            ...folder,
                            name: variables.newName,
                          }
                        }

                        return folder
                      }),
                    }
                  }),
                }

                queryClient.setQueryData(key, updatedData)
              }
            })
          }

          // set query recent folder name
          const recentFoldersKey = useRecentFolderInfiniteQuery.getKey()

          const recentFoldersQueriesDataEntries = queryClient.getQueriesData<InfiniteData<RecentFolderItem[], number>>({
            queryKey: recentFoldersKey,
          })

          if (recentFoldersQueriesDataEntries) {
            recentFoldersQueriesDataEntries.forEach(([key, recentFoldersData]) => {
              if (recentFoldersData) {
                const updatedData = {
                  ...recentFoldersData,
                  pages: recentFoldersData.pages.map(page => {
                    return [
                      ...page.map(item => {
                        if (item.folder.id === variables.folderId) {
                          return {
                            ...item,
                            folder: {
                              ...item.folder,
                              name: variables.newName,
                            },
                          }
                        }

                        return item
                      }),
                    ]
                  }),
                }
                queryClient.setQueryData(key, updatedData)
              }
            })
          }
        }

        const folderInfoKey = useFolderInfoQuery.getKey({ folderId: variables.folderId })
        const folderInfoData = queryClient.getQueryData(folderInfoKey)

        if (folderInfoData) {
          queryClient.setQueryData(folderInfoKey, {
            ...folderInfoData,
            name: variables.newName,
          })
        }
      },
      onError: ({ queryClient, variables }) => {
        // revalidate folder info
        queryClient.invalidateQueries({ queryKey: useFolderInfoQuery.getKey({ folderId: variables.folderId }) })
      },
    }),
  ],
})
