import client from "@/api/client"
import { useMutation, useQueryClient } from "@tanstack/react-query"

export const useMoveFolder = () => {
  const qc = useQueryClient()

  const mutation = useMutation({
    mutationFn: async ({ folderId, newParentId }: { folderId: string; newParentId: string | null }) =>
      newParentId &&
      (await client.api.folderControllerMoveFolder({ folderIds: [folderId], parentFolderId: newParentId })),
    onSettled: () => {
      qc.invalidateQueries({ queryKey: ["folders"] })
    },
  })

  return mutation
}
