import { RecipeItem } from "@/api/sdk"
import UserCreated from "@/components/Explore/Gallery/UserCreated"
import IconButton from "@/components/IconButton"
import ImageWebpComponent from "@/components/ImageWebpComponent"
import { AddSquareIcon, PinIcon, TickIcon } from "@/components/shared/icons"
import { googleAnalytics } from "@/lib/gtag"
import { cn } from "@/lib/utils"
import classNames from "classnames"
import Link from "next/link"
import { useInView } from "react-intersection-observer"
import { tabDataRecipesWithIcon } from "."
import AspectRatio from "../../AspectRatio"
import RecipeItemList from "../RecentV2/RecentRecipes/RecipeItemList"
import ActionButtonsRecipe from "./ActionButtonsRecipe"

interface RecipeCardProps {
  recipe: RecipeItem
  href?: string
  accessedAt?: string
  layout?: "grid" | "list"
  className?: string
  hiddenMenu?: boolean
  hiddenAttribute?: boolean
  isChecked?: boolean
  onCheck?: () => void
  selectionMode?: boolean
  onClick?: () => void
  imageClassName?: string
  onRef?: (ref: HTMLDivElement | null) => void
}

const RecipeCard = ({
  recipe,
  href,
  layout = "grid",
  accessedAt,
  className,
  isChecked,
  onCheck,
  onClick,
  onRef,
  selectionMode,
  hiddenMenu,
  hiddenAttribute,
}: RecipeCardProps) => {
  const { thumbnail, categories, name, id, description } = recipe
  const { ref, inView } = useInView({
    threshold: 0,
    rootMargin: "800px 0px 800px 0px",
  })

  const handleClick = e => {
    if (selectionMode) {
      e.preventDefault()
      e.stopPropagation()

      googleAnalytics.handleCategoryEvent({
        action: "click",
        params: {
          action: "Select recipe",
          recipe_id: recipe.id,
          recipe_name: recipe.name,
        },
      })

      onCheck?.()
      return
    }

    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        action: "View recipe detail",
        recipe_id: recipe.id,
        recipe_name: recipe.name,
      },
    })

    if (onClick) {
      e.preventDefault()
      e.stopPropagation()

      onClick()

      return
    }
  }

  const Icon =
    tabDataRecipesWithIcon.find(i => i.title.toLowerCase() === categories?.[0]?.name?.toLowerCase())?.icon ?? null

  return (
    <div
      ref={r => {
        if (onRef) {
          onRef(r)
        }
        ref(r)
      }}
      className={cn("group h-full cursor-pointer shrink relative recipe-item", className)}
    >
      {layout === "grid" ? (
        <div className="w-full h-full">
          <Link
            onClick={handleClick}
            href={`${href ? href : `/workspace/tools/recipes/${id}`}`}
            className="h-full flex flex-col"
            shallow
          >
            <div className="relative group overflow-hidden">
              <AspectRatio
                ratio={197 / 96}
                className={
                  "relative select-none overflow-hidden rounded-xl transform-gpu flex items-center justify-center shadow-md shadow-blackAlpha-500"
                }
              >
                {inView && (
                  <ImageWebpComponent
                    src={thumbnail}
                    alt={name}
                    className="w-full h-full object-cover"
                    style={{
                      objectPosition: "50% 60%",
                    }}
                  />
                )}
              </AspectRatio>
              {!hiddenMenu &&
                inView &&
                (recipe.discoverableAt || recipe.capabilities?.canRemoveFromLibrary || categories) && (
                  <div className="absolute flex items-center top-2 left-0">
                    {recipe.discoverableAt && (
                      <span className="font-semibold bg-atherPurple-500 px-1.5 py-0.5 rounded-r-lg text-[0.65rem]">
                        E
                      </span>
                    )}
                    {categories && categories.length > 0 && !categories.some(c => c.name === "Others") && (
                      <div className="bg-atherPurple-500 rounded-lg text-xs px-1 py-0.5 ml-1">
                        {categories?.map(i => (
                          <span key={i.id} className="text-xs text-white flex items-center">
                            {Icon && <Icon className="mr-1 inline-block" width={14} height={14} />}
                            {i.name}
                          </span>
                        ))}
                      </div>
                    )}
                    {recipe.capabilities?.canRemoveFromLibrary && (
                      <span className="ml-1">
                        <AddSquareIcon width={16} height={16} />
                      </span>
                    )}
                  </div>
                )}

              {selectionMode && (
                <div
                  className={classNames(
                    "absolute w-full h-full top-0 left-0 border-[2px] pointer-events-none rounded-xl",
                    {
                      "border-atherPurple-500": isChecked,
                      "border-[transparent]": !isChecked,
                      "z-0": !selectionMode,
                      "z-[1]": selectionMode,
                    },
                  )}
                />
              )}
              {inView && (
                <div
                  className={classNames(
                    "absolute bottom-0 left-0 transition-all pointer-events-none hidden lg:block group-hover:bg-blackAlpha-400 group-hover:h-full group-hover:w-full",
                    {
                      "h-full w-full bg-blackAlpha-600": selectionMode,
                    },
                  )}
                />
              )}
            </div>
            <div className="p-2 flex flex-col flex-1 overflow-hidden">
              <div className="flex overflow-hidden mb-1">
                <p className="flex-1 line-clamp-1 font-semibold break-all text-sm">{name}</p>
                {recipe.pinned !== undefined && recipe.pinned && (
                  <div className="p-0.5">
                    <PinIcon width={16} height={16} />
                  </div>
                )}
              </div>
              {!hiddenAttribute && (
                <>
                  <p className="text-atherGray-300 text-sm line-clamp-1 mb-2">{description}</p>

                  <div className="mt-auto">
                    <UserCreated size="xs" name={recipe.creator?.name ?? "GAIA"} picture={recipe.creator?.picture} />
                  </div>
                </>
              )}
            </div>
          </Link>

          {inView &&
            !hiddenMenu &&
            (selectionMode ? (
              <IconButton
                onClick={e => {
                  e.stopPropagation()
                  e.preventDefault()
                  onCheck?.()
                }}
                className={cn(
                  "absolute top-2 right-2 shadow-sm shadow-blackAlpha-500 bg-atherGray-700 p-0 w-7 h-7 min-h-0 rounded-full",
                  {
                    "text-white bg-atherPurple-500 hover:bg-atherPurple-400": isChecked,
                    "text-atherGray-300 hover:bg-atherGray-600": !isChecked,
                  },
                )}
              >
                <TickIcon />
              </IconButton>
            ) : (
              <ActionButtonsRecipe recipe={recipe} />
            ))}
        </div>
      ) : (
        <RecipeItemList recipe={recipe} accessedAt={accessedAt} href={href} />
      )}
    </div>
  )
}

export default RecipeCard
