import { IconProps } from "."

export const PaintBrushIcon = (props: IconProps) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.89089 24.8727L7 25L7.12727 24.1091C7.32086 22.754 7.41765 22.0764 7.71832 21.4751C8.01899 20.8738 8.50296 20.3898 9.47091 19.4218L20.9827 7.91009C21.4062 7.48654 21.618 7.27476 21.8464 7.16155C22.2811 6.94615 22.7914 6.94615 23.2261 7.16155C23.4546 7.27476 23.6663 7.48654 24.0899 7.91009C24.5135 8.33365 24.7252 8.54543 24.8385 8.77389C25.0539 9.20856 25.0539 9.71889 24.8385 10.1536C24.7252 10.382 24.5135 10.5938 24.0899 11.0173L12.5782 22.5291C11.6102 23.497 11.1262 23.981 10.5249 24.2817C9.92357 24.5823 9.246 24.6791 7.89089 24.8727Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 19L13 22M12.5 16.5L15.5 19.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25 15L25.3848 16.7083C25.7112 18.1572 26.8427 19.2888 28.2917 19.6152L30 20L28.2917 20.3848C26.8427 20.7112 25.7112 21.8427 25.3848 23.2917L25 25L24.6152 23.2917C24.2888 21.8427 23.1573 20.7112 21.7083 20.3848L20 20L21.7083 19.6152C23.1572 19.2888 24.2888 18.1573 24.6152 16.7083L25 15Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  )
}
