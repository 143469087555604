import client from "@/api/client"
import {
  ExploreSDWorkflowDetail,
  GetExploreSDWorkflowsResult,
  PinnedItemType,
  PinnedItems,
  RecentSDWorkflowItem,
  SearchSDWorkflowResponse,
} from "@/api/sdk"
import { actionMiddleware } from "@/queries/middlewares/actionMiddleware"
import { InfiniteData, useQueryClient } from "@tanstack/react-query"
import { createMutation } from "react-query-kit"
import { useWorkspacePinInfiniteQuery } from "@/queries/workspace"
import { useComfyUiWorkflowInfiniteQuery } from "./getComfyUiRecipeQueries"
import { useRecentComfyUIInfiniteQuery } from "@/components/Workspace/RecentV2/RecentComfyUI"
import { useExploreComfyUIInfiniteQuery, useExploreDetailComfyUIQuery } from "@/queries/explore"

export const useFavoriteComfyUIMutation = createMutation({
  mutationFn: ({ id }: { id: string; userUid?: string }) => client.api.sdWorkflowControllerToggleFavoriteSdWorkflow(id),
  use: [
    actionMiddleware({
      onMutate: ({ queryClient, variables }) => {
        // Update comfyui explore list
        const exploreComfyUIListKey = useExploreComfyUIInfiniteQuery.getKey()

        const exploreComfyuiListQueriesDataEntries = queryClient.getQueriesData<
          InfiniteData<GetExploreSDWorkflowsResult, number>
        >({ queryKey: exploreComfyUIListKey })

        if (exploreComfyuiListQueriesDataEntries) {
          exploreComfyuiListQueriesDataEntries.forEach(([key, listData]) => {
            if (listData) {
              const updatedData = {
                ...listData,
                pages: listData.pages.map(page => {
                  return {
                    ...page,
                    sdWorkflows: page.sdWorkflows.map(workflow => {
                      if (workflow.id === variables.id) {
                        return {
                          ...workflow,
                          favoritedByUser: !workflow.favoritedByUser,
                          metric: {
                            ...workflow.metric,
                            favoriteCount: workflow.favoritedByUser
                              ? (workflow.metric?.favoriteCount ?? 0) - 1
                              : (workflow.metric?.favoriteCount ?? 0) + 1,
                          },
                        }
                      }
                      return workflow
                    }),
                  }
                }),
              }

              queryClient.setQueryData(key, updatedData)
            }
          })
        }

        // Update comfyui workspace list
        const workspaceComfyUIListKey = useComfyUiWorkflowInfiniteQuery.getKey()

        const workspaceComfyUIListQueriesDataEntries = queryClient.getQueriesData<
          InfiniteData<SearchSDWorkflowResponse, number>
        >({
          queryKey: workspaceComfyUIListKey,
        })

        if (workspaceComfyUIListQueriesDataEntries) {
          workspaceComfyUIListQueriesDataEntries.forEach(([key, listData]) => {
            if (listData) {
              const updatedData = {
                ...listData,
                pages: listData.pages.map(page => {
                  return {
                    ...page,
                    workflows: page.workflows.map(workflow => {
                      if (workflow.id === variables.id) {
                        return {
                          ...workflow,
                          favoritedByUser: !workflow.favoritedByUser,
                          metric: {
                            ...workflow.metric,
                            favoriteCount: workflow.favoritedByUser
                              ? (workflow.metric?.favoriteCount ?? 0) - 1
                              : (workflow.metric?.favoriteCount ?? 0) + 1,
                          },
                        }
                      }
                      return workflow
                    }),
                  }
                }),
              }

              queryClient.setQueryData(key, updatedData)
            }
          })
        }

        // Update comfyui recent list
        const comfyuiRecentListKey = useRecentComfyUIInfiniteQuery.getKey()

        const comfyuiRecentListQueriesDataEntries = queryClient.getQueriesData<
          InfiniteData<RecentSDWorkflowItem[], number>
        >({
          queryKey: comfyuiRecentListKey,
        })

        if (comfyuiRecentListQueriesDataEntries) {
          comfyuiRecentListQueriesDataEntries.forEach(([key, recentListData]) => {
            if (recentListData) {
              const updatedData = {
                ...recentListData,
                pages: recentListData.pages.map(page => {
                  return [
                    ...page.map(item => {
                      if (item.sdWorkflow.id === variables.id) {
                        return {
                          ...item,
                          sdWorkflow: {
                            ...item.sdWorkflow,
                            favoritedByUser: !item.sdWorkflow.favoritedByUser,
                            metric: {
                              ...item.sdWorkflow.metric,
                              favoriteCount: item.sdWorkflow.favoritedByUser
                                ? (item.sdWorkflow.metric?.favoriteCount ?? 0) - 1
                                : (item.sdWorkflow.metric?.favoriteCount ?? 0) + 1,
                            },
                          },
                        }
                      }

                      return item
                    }),
                  ]
                }),
              }

              queryClient.setQueryData(key, updatedData)
            }
          })
        }

        //update pinned comfyui
        const comfyuiPinnedListKey = useWorkspacePinInfiniteQuery.getKey({
          type: PinnedItemType.SD_WORKFLOW,
        })

        const comyuiPinnedListQueriesDataEntries = queryClient.getQueriesData<InfiniteData<PinnedItems[], number>>({
          queryKey: comfyuiPinnedListKey,
        })

        if (comyuiPinnedListQueriesDataEntries) {
          comyuiPinnedListQueriesDataEntries.forEach(([key, pinnedListData]) => {
            if (pinnedListData) {
              const updatedData = {
                ...pinnedListData,
                pages: pinnedListData.pages.map(page => {
                  return [
                    ...page.map(item => {
                      if (item.sdWorkflow?.id === variables.id) {
                        return {
                          ...item,
                          sdWorkflow: {
                            ...item.sdWorkflow,
                            favoritedByUser: !item.sdWorkflow.favoritedByUser,
                            metric: {
                              ...item.sdWorkflow.metric,
                              favoriteCount: item.sdWorkflow.favoritedByUser
                                ? (item.sdWorkflow.metric?.favoriteCount ?? 0) - 1
                                : (item.sdWorkflow.metric?.favoriteCount ?? 0) + 1,
                            },
                          },
                        }
                      }

                      return item
                    }),
                  ]
                }),
              }

              queryClient.setQueryData(key, updatedData)
            }
          })
        }

        // Update comfyui detail

        const exploreComfyuiDetailKey = useExploreDetailComfyUIQuery.getKey({
          sdWorkflowId: variables.id,
          userUid: variables.userUid,
        })

        const comfyuiDetailQueriesDataEntries = queryClient.getQueriesData<ExploreSDWorkflowDetail>({
          queryKey: exploreComfyuiDetailKey,
        })

        if (comfyuiDetailQueriesDataEntries) {
          comfyuiDetailQueriesDataEntries.forEach(([key, comfyuiDetailData]) => {
            if (comfyuiDetailData) {
              const updatedData = {
                ...comfyuiDetailData,
                ...comfyuiDetailData,
                favoritedByUser: !comfyuiDetailData.favoritedByUser,
                metric: {
                  ...comfyuiDetailData.metric,
                  favoriteCount: comfyuiDetailData.favoritedByUser
                    ? (comfyuiDetailData.metric?.favoriteCount ?? 0) - 1
                    : (comfyuiDetailData.metric?.favoriteCount ?? 0) + 1,
                },
              }

              queryClient.setQueryData(key, updatedData)
            }
          })
        }
      },
    }),
    useMutationNext => {
      return (options, queryClient) => {
        const qc = useQueryClient(queryClient)

        return useMutationNext({
          ...options,
          onSettled: (_, __, {}) => {},
        })
      }
    },
  ],
})
