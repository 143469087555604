import { create } from "zustand"

export type ModalShareType =
  | "folder"
  | "image"
  | "article"
  | "macro"
  | "style"
  | "comfyui-workflow"
  | "recipe"
  | "task"
  | "wildcard"
  | "prompt"
  | "room"
  | "model"
  | "model training"

export type SharingDataType = {
  id: string
  name: string
  type: ModalShareType
  creator?: {
    uid: string
  }
  folderId?: string
  workspaceId?: string
  link: string | null
  url?: string
  [key: string]: any
}

interface ModalStore {
  sharingData: SharingDataType | null
  setSharingData: (sharingFolder: SharingDataType | null) => void
  uploadModal: {
    open: boolean
    isSelectedFolder?: boolean
  } | null
  setUploadModal: (
    uploadModal: {
      open: boolean
      isSelectedFolder?: boolean
    } | null,
  ) => void
}

const useModalStore = create<ModalStore>(set => ({
  sharingData: null,
  setSharingData: sharingFolder => set({ sharingData: sharingFolder }),
  uploadModal: null,
  setUploadModal: uploadModal => set({ uploadModal }),
}))

export default useModalStore
