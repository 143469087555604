import { InfiniteQueryHook, Middleware, QueryHook } from "react-query-kit"

export const enableQueryMiddleware = <TFnData = unknown, TVariables = unknown>(
  condition?: (variables: TVariables) => boolean,
): Middleware<QueryHook<TFnData, TVariables, Error>> => {
  return useQueryNext => {
    return options => {
      const enabled = condition ? (condition(options.variables!) ? options.enabled : false) : options.enabled

      return useQueryNext({
        enabled,
        ...options,
      })
    }
  }
}

export const enableInfiniteQueryMiddleware = <TFnData = unknown, TVariables = unknown>(
  condition?: (variables: TVariables) => boolean,
): Middleware<InfiniteQueryHook<TFnData, TVariables, Error>> => {
  return useQueryNext => {
    return options => {
      const enabled = condition ? (condition(options.variables!) ? options.enabled : false) : options.enabled

      return useQueryNext({
        enabled,
        ...options,
      })
    }
  }
}
