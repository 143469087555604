import client from "@/api/client"
import { CreateWildcardDto } from "@/api/sdk"
import { actionMiddleware } from "@/queries/middlewares/actionMiddleware"
import { createMutation } from "react-query-kit"
import { useWorkspaceWildcardsInfiniteQuery } from "./useWorkspaceWildcardsInfiniteQuery"

export const useCreateWildcardMutation = createMutation({
  mutationFn: (data: CreateWildcardDto) => client.api.wildcardControllerCreateWildcards(data).then(res => res.data),
  use: [
    actionMiddleware({
      onSuccess({ queryClient, variables }) {
        const listWildcardKey = useWorkspaceWildcardsInfiniteQuery.getKey()

        queryClient.invalidateQueries({ queryKey: listWildcardKey })
      },
    }),
  ],
})
