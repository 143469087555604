import { IconProps } from "."

export const ImageIcon = (props: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none" {...props}>
      <path
        d="M1.5 11C1.5 6.52166 1.5 4.28249 2.89124 2.89124C4.28249 1.5 6.52166 1.5 11 1.5C15.4783 1.5 17.7175 1.5 19.1088 2.89124C20.5 4.28249 20.5 6.52166 20.5 11C20.5 15.4783 20.5 17.7175 19.1088 19.1088C17.7175 20.5 15.4783 20.5 11 20.5C6.52166 20.5 4.28249 20.5 2.89124 19.1088C1.5 17.7175 1.5 15.4783 1.5 11Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <circle cx="15.5" cy="6.5" r="1.5" stroke="currentColor" strokeWidth="1.5" />
      <path
        d="M15 21C14.3805 18.7749 12.9345 16.7821 10.8765 15.3342C8.65761 13.7729 5.87163 12.9466 3.01569 13.0027C2.67658 13.0019 2.33776 13.0127 2 13.0351"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M12 17C13.7015 15.6733 15.5345 14.9928 17.3862 15.0001C18.4362 14.999 19.4812 15.2216 20.5 15.6617"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  )
}
