import client from "@/api/client"
import { ChatRoomItem, GetChatRoomsResult } from "@/api/sdk"
import { createAuthenticatedInfiniteQuery } from "@/queries/createAuthenticatedQuery"

export const useWorkspaceRoomsInfiniteQuery = createAuthenticatedInfiniteQuery<
  GetChatRoomsResult,
  { workspaceId?: string; isPrivate?: boolean; searchTerm?: string; isPinned?: boolean; take?: number },
  Error,
  ChatRoomItem
>({
  primaryKey: "infinite-workspace-rooms",
  queryFn: ({ queryKey: [_primaryKey, variables], pageParam = 0 }) =>
    client.api
      .chatRoomControllerGetChatRooms({
        skip: pageParam * (variables.take || 50),
        isPrivate: variables.isPrivate,
        take: variables.take || 50,
        isPinned: variables.isPinned,
        searchTerm: variables.searchTerm ? variables.searchTerm : undefined,
      })
      .then(res => res.data),
  getNextPageParam: (lastPage, pages) => (lastPage && lastPage.rooms.length < 50 ? undefined : pages.length),
  initialPageParam: 0,
  flattenFn: data => data.rooms,
})
