import { create } from "zustand"
import { devtools, persist } from "zustand/middleware"

interface SkipModalStore {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

export const useSkipModalMissStore = create<SkipModalStore>()(
  devtools(
    persist(
      set => ({
        isOpen: false,
        setIsOpen: isOpen => set({ isOpen })
      }),
      {
        name: "skip-all-popup-miss-universe"
      }
    )
  )
)
