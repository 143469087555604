import { IconProps } from "."

export const ArrowMoveUpRightIcon = (props: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none" {...props}>
      <path
        d="M1.66666 13V11.6154C1.66666 10.2814 1.66666 9.61445 1.76354 9.05637C2.29685 5.98432 4.9377 3.57496 8.30489 3.08839C8.9166 3 10.2045 3 11.6667 3"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.3333 1C10.7379 1.39322 12.3333 2.43982 12.3333 3C12.3333 3.56018 10.7379 4.60678 10.3333 5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ArrowMoveUpRightIcon
