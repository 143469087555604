import client from "@/api/client"
import { UpdatePaymentMethodDto, WorkspacePaymentInfoItem } from "@/api/sdk"
import { createMutation } from "react-query-kit"
import { createAuthenticatedQuery } from "../createAuthenticatedQuery"
import { enableQueryMiddleware } from "../middlewares/enableQueryMiddleware"

export const useGetPaymentInfoQuery = createAuthenticatedQuery<
  WorkspacePaymentInfoItem[],
  { workspaceId: string },
  Error
>({
  primaryKey: "payment-info",
  queryFn: ({ queryKey: [_primaryKey, variables] }) =>
    client.api.workspaceControllerGetPaymentInfo(variables.workspaceId).then(res => res.data),

  use: [enableQueryMiddleware(r => !!r.workspaceId)],
})

export const useUpdatePaymentMethodMutation = createMutation({
  mutationFn: ({ data, subscriptionId }: { subscriptionId: string; data: UpdatePaymentMethodDto }) =>
    client.api.paymentControllerUpdatePaymentMethod(subscriptionId, data).then(res => res.data),
})

export const useUpdateStripePaymentMutation = createMutation({
  mutationFn: ({ subscriptionId }: { subscriptionId: string }) =>
    client.api.paymentControllerUpdateStripePaymentMethod(subscriptionId).then(res => res.data),
})
