import IconButton from "@/components/IconButton"
import classNames from "classnames"
import { FC } from "react"
import OptionalItem from "../OptionalItem"
import { FormDetailComponentProps } from "./types"
import { cn } from "@/lib/utils"

const ratio = ["1:1", "3:2", "2:3", "16:9", "9:16"]

const FormDetailRatioComponent: FC<
  FormDetailComponentProps & {
    layout?: "horizontal" | "vertical"
    buttonsClassName?: string
    buttonsColorActive?: string
  }
> = props => {
  const {
    input,
    recipeInput,
    value,
    onChange,
    layout = "horizontal",
    buttonsClassName = "",
    buttonsColorActive = "bg-atherPurple-500",
  } = props

  const isChecked = recipeInput?.isChecked ?? false

  return (
    <OptionalItem
      isChecked={isChecked}
      onChecked={() => {
        onChange({
          key: input.key,
          value: "",
          clearTriggerKey: input.clearTriggerKey,
          checked: !isChecked,
        })
      }}
      title={input.title}
      description={input.description}
      name={input.name}
      tooltip={input.tooltip}
      enabled={input.optional ?? false}
    >
      <div className="w-full flex flex-wrap items-end gap-1 mt-2">
        {ratio
          ?.map(v => ({
            value: v,
            display: v,
            newValue: v.includes?.(":") ? v.split?.(":") : v.includes?.("x") ? v.split?.("x") : undefined,
          }))
          .map(i => {
            const widthRatio = Number(i.newValue?.[0]) ?? 1
            const heightRatio = Number(i.newValue?.[1]) ?? 1

            const width = widthRatio >= heightRatio ? (2 * widthRatio) / heightRatio : 2
            const height = heightRatio >= widthRatio ? (2 * heightRatio) / widthRatio : 2

            return (
              <IconButton
                onClick={() => {
                  onChange({
                    key: input.key,
                    value: i.value,
                    clearTriggerKey: input.clearTriggerKey,
                    checked: isChecked,
                  })
                }}
                type="button"
                colorScheme="transparent"
                key={i.value}
                className={cn(
                  "shrink p-0 flex items-center flex-col justify-end h-full min-h-[5rem] min-w-[3rem]",
                  buttonsClassName,
                )}
              >
                <div
                  className={classNames("rounded-md", {
                    [`${buttonsColorActive}`]: value?.toString() === i.value.toString(),
                    "bg-atherGray-800": value?.toString() !== i.value.toString(),
                  })}
                >
                  <div
                    className={classNames("flex items-end")}
                    style={{
                      width: `${width}rem`,
                      height: `${height}rem`,
                    }}
                  ></div>
                </div>
                {layout === "horizontal" && <p>{i.display}</p>}
              </IconButton>
            )
          })}
      </div>
    </OptionalItem>
  )
}

export default FormDetailRatioComponent
