import { SpinnerIcon } from "@/components/shared/icons"
import { cn } from "@/lib/utils"
import { motion } from "framer-motion"
import React, { Fragment } from "react"
import { twMerge } from "tailwind-merge"

interface ViewOtherCommentProps {
  isFetchingPage: boolean
  fetchPage: () => void
  length?: number
  totalLength?: number
  className?: string
  content: string
}

const ViewOtherComment = ({
  isFetchingPage,
  fetchPage,
  length,
  totalLength,
  className,
  content,
}: ViewOtherCommentProps) => {
  if (totalLength === length) return null

  return (
    <Fragment>
      {isFetchingPage ? (
        <div className={cn("flex items-center justify-center flex-1 w-full  text-gray-600", className)}>
          <motion.div animate={{ rotate: 360 }} transition={{ duration: 1, repeat: Infinity, ease: "linear" }}>
            <SpinnerIcon width={16} height={16} />
          </motion.div>
        </div>
      ) : (
        <div className={cn("w-full mt-4", className)}>
          <div
            className="flex justify-between font-semibold text-sm cursor-pointer text-atherGray-300"
            onClick={() => {
              fetchPage()
            }}
          >
            <p>{content}</p>
            {length && totalLength && (
              <p className="font-semibold">
                {length} of {totalLength}
              </p>
            )}
          </div>
        </div>
      )}
    </Fragment>
  )
}

export default ViewOtherComment
