import client from "@/api/client"
import {
  CommentsOrderOption,
  EntityType,
  GetCommentsResult,
  GetInspirationBoardImagesResult,
  SortOptions,
} from "@/api/sdk"
import { GetNextPageParamFunction, GetPreviousPageParamFunction } from "@tanstack/react-query"
import { createInfiniteQuery } from "react-query-kit"

export interface CommentDetailQueryInfiniteQueryVariables {
  typeComment: EntityType
  parentTakePage: number
  pageParam?: number
  entityId: string
  parentCommentId?: string
  notificationId?: string
  order?: CommentsOrderOption
  nodeIds?: number[]
}

export const useCommentDetailQuery = ({
  primaryKey,
  getNextPageParam,
  getPreviousPageParam,
}: {
  primaryKey: string
  getNextPageParam: GetNextPageParamFunction<number, GetCommentsResult>
  getPreviousPageParam?: GetPreviousPageParamFunction<number, GetCommentsResult> | undefined
}) => {
  const query = createInfiniteQuery<GetCommentsResult, CommentDetailQueryInfiniteQueryVariables, Error>({
    primaryKey,
    queryFn: ({ queryKey: [_primaryKey, variables], pageParam = variables.pageParam ?? 0 }) => {
      if (variables.typeComment === EntityType.ARTICLE) {
        return client.api
          .articleControllerGetArticleComment(variables.entityId, {
            skip: pageParam * variables.parentTakePage,
            take: variables.parentTakePage,
            order: variables.order,
            parentCommentId: variables.parentCommentId,
          })
          .then(res => res.data)
      }

      if (variables.typeComment === EntityType.INSPIRATION_IMAGE) {
        return client.api
          .imageControllerGetInspirationBoardImageComments(variables.entityId, {
            skip: pageParam * variables.parentTakePage,
            take: variables.parentTakePage,
            parentCommentId: variables.parentCommentId,
            order: variables.order,
          })
          .then(res => res.data)
      }

      if (variables.typeComment === EntityType.EXPLORE_STYLE) {
        return client.api
          .exploreSdStyleControllerGetExploreSdStyleComments(variables.entityId, {
            skip: pageParam * variables.parentTakePage,
            take: variables.parentTakePage,
            parentCommentId: variables.parentCommentId,
            order: variables.order,
          })
          .then(res => res.data)
      }

      if (variables.typeComment === EntityType.EXPLORE_WORKFLOW) {
        return client.api
          .exploreWorkflowControllerGetExploreWorkflowcomment(variables.entityId, {
            skip: pageParam * variables.parentTakePage,
            take: variables.parentTakePage,
            parentCommentId: variables.parentCommentId,
            order: variables.order,
          })
          .then(res => res.data)
      }

      if (variables.typeComment === EntityType.EXPLORE_RECIPE) {
        return client.api
          .exploreRecipeControllerGetExploreRecipeComments(variables.entityId, {
            skip: pageParam * variables.parentTakePage,
            take: variables.parentTakePage,
            parentCommentId: variables.parentCommentId,
            order: variables.order,
          })
          .then(res => res.data)
      }

      if (variables.typeComment === EntityType.EXPLORE_SD_WORKFLOW) {
        return client.api
          .exploreSdWorkflowControllerGetExploreSdWorkflowComments(variables.entityId, {
            skip: pageParam * variables.parentTakePage,
            take: variables.parentTakePage,
            parentCommentId: variables.parentCommentId,
            order: variables.order,
          })
          .then(res => res.data)
      }

      if (variables.typeComment === EntityType.STYLE) {
        return client.api
          .sdStyleControllerGetSdStyleComments(variables.entityId, {
            skip: pageParam * variables.parentTakePage,
            take: variables.parentTakePage,
            parentCommentId: variables.parentCommentId,
            order: variables.order,
          })
          .then(res => res.data)
      }

      if (variables.typeComment === EntityType.WORKFLOW) {
        return client.api
          .workflowControllerGetWorkflowComments(variables.entityId, {
            skip: pageParam * variables.parentTakePage,
            take: variables.parentTakePage,
            parentCommentId: variables.parentCommentId,
            order: variables.order,
          })
          .then(res => res.data)
      }

      if (variables.typeComment === EntityType.SD_WORKFLOW) {
        return client.api
          .sdWorkflowControllerGetComments(variables.entityId, {
            skip: pageParam * variables.parentTakePage,
            take: variables.parentTakePage,
            parentCommentId: variables.parentCommentId,
            order: variables.order,
            nodeIds: variables.nodeIds,
          })
          .then(res => res.data)
      }

      if (variables.typeComment === EntityType.SD_MODEL) {
        return client.api
          .sdModelControllerGetSdModelComments(variables.entityId, {
            skip: pageParam * variables.parentTakePage,
            take: variables.parentTakePage,
            order: variables.order,
            parentCommentId: variables.parentCommentId,
          })
          .then(res => res.data)
      }

      return client.api
        .commentControllerGetCommentsImage(variables.entityId, {
          skip: pageParam * variables.parentTakePage,
          take: variables.parentTakePage,
          order: variables.order,
          parentCommentId: variables.parentCommentId,
        })
        .then(res => res.data)
    },
    getNextPageParam,
    getPreviousPageParam,
    initialPageParam: 0,
  })

  return query
}
