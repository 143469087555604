import { IconProps } from "."

export const UserGroupIcon = (props: IconProps) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_6338_2653)">
        <path
          d="M13.8494 12C14.3489 12 14.7462 11.6856 15.103 11.2461C15.8333 10.3463 14.6342 9.62723 14.1769 9.27507C13.712 8.91708 13.1929 8.71428 12.6667 8.66667M12 7.33333C12.9205 7.33333 13.6667 6.58714 13.6667 5.66667C13.6667 4.74619 12.9205 4 12 4"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M2.14999 12C1.65045 12 1.25313 11.6856 0.896379 11.2461C0.166075 10.3463 1.36513 9.62723 1.82245 9.27507C2.28733 8.91708 2.8064 8.71428 3.33268 8.66667M3.66602 7.33333C2.74554 7.33333 1.99935 6.58714 1.99935 5.66667C1.99935 4.74619 2.74554 4 3.66602 4"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M5.38855 10.0742C4.70737 10.4954 2.92134 11.3554 4.00915 12.4317C4.54054 12.9574 5.13236 13.3334 5.87643 13.3334H10.1223C10.8663 13.3334 11.4582 12.9574 11.9895 12.4317C13.0774 11.3554 11.2913 10.4954 10.6101 10.0742C9.01278 9.08644 6.98592 9.08644 5.38855 10.0742Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.3327 5.00002C10.3327 6.28868 9.28801 7.33335 7.99935 7.33335C6.71068 7.33335 5.66602 6.28868 5.66602 5.00002C5.66602 3.71136 6.71068 2.66669 7.99935 2.66669C9.28801 2.66669 10.3327 3.71136 10.3327 5.00002Z"
          stroke="currentColor"
          strokeWidth="1.5"
        />
      </g>
      <defs>
        <clipPath id="clip0_6338_2653">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
