import { FolderDetail, SubfolderView } from "@/api/sdk"
import UserCreated from "@/components/Explore/Gallery/UserCreated"
import IconButton from "@/components/IconButton"
import Input from "@/components/Input"
import { ChevronDownIcon, FolderSelectIcon } from "@/components/shared/icons"
import { useDebounce, useOutsideClick } from "@/hooks"
import { cn } from "@/lib/utils"
import { useAuth } from "@/providers/authContext"
import { useRootFolderQuery, useSubFoldersInfiniteQuery } from "@/queries"
import { useCreateFolderV2Store } from "@/stores"
import classNames from "classnames"
import { AnimatePresence, motion } from "framer-motion"
import Image from "next/image"
import React, { useRef, useState } from "react"
import InfiniteScroll from "react-infinite-scroller"
import TabsList from "../../FoldersV2/TabsList"
import { cdnPublicFolderUrl } from "@/constants"
import FolderIcon from "../../Folder/FolderIcon"
import ErrorUI from "@/components/ErrorUI"

interface DrawerSelectFolderProps {
  selected?: FolderDetail
  setSelected: (selected?: FolderDetail) => void
  placeHolder?: string
  title?: string
  className?: string
  position?: "right" | "left"
  isRemoveNewFolder?: boolean
  rightIcon?: React.ReactNode
}

type SelectableTab = "my-folder" | "shared"

const tabList = [
  {
    id: "my-folder",
    title: "My Folder",
  },
  {
    id: "shared",
    title: "Shared",
  },
]

const DrawerSelectFolder = ({
  selected,
  isRemoveNewFolder,
  setSelected,
  placeHolder,
  rightIcon,
  title,
  className,
  position = "left",
}: DrawerSelectFolderProps) => {
  const [search, setSearch] = useState("")
  const [isOpen, setIsOpen] = useState(false)
  const ref = useRef(null)
  const { user } = useAuth()
  const [selectedTab, setTab] = useState<SelectableTab>("my-folder")
  const [selectedSubFolders, setSelectedSubFolders] = useState<string[] | undefined>()
  const searchDebounce = useDebounce(search, 350)
  const { parentFolder, setParentFolder } = useCreateFolderV2Store()
  const scrollRef = useRef<HTMLDivElement>(null)

  const onClose = () => {
    if (parentFolder) return
    setIsOpen(false)
  }

  useOutsideClick({
    ref,
    enabled: isOpen && !parentFolder,
    handler: onClose,
  })

  const { data: rootFolder } = useRootFolderQuery()

  const folderId = selectedSubFolders?.[selectedSubFolders?.length - 1] ?? rootFolder?.id

  const {
    fetchNextPage: fetchNextSubFolders,
    isFetching: isFetchingSubFolders,
    hasNextPage: hasNextSubFolders,
    flattenData: flattenSubFolders = [],
    isLoading: isLoadingSubFolders,
    isError: isErrorSubFolders,
  } = useSubFoldersInfiniteQuery({
    variables: {
      folderId,
      search: searchDebounce,
      view: selectedTab === "my-folder" ? SubfolderView.MyFolder : SubfolderView.SharedWithMe,
      canUpload: selectedTab === "shared",
    },
    enabled: !!folderId && !!user?.uid,
  })

  const renderBody = () => {
    if (isLoadingSubFolders) {
      return <div className="flex-1 text-xs items-center justify-center flex text-atherGray-500">Loading...</div>
    }

    if (isErrorSubFolders) {
      return <ErrorUI />
    }

    if (!flattenSubFolders || flattenSubFolders.length === 0) {
      return <div className="flex-1 text-xs items-center justify-center flex text-atherGray-500">No items found</div>
    }

    return (
      <InfiniteScroll
        loadMore={() => fetchNextSubFolders()}
        hasMore={!!hasNextSubFolders && !isFetchingSubFolders}
        useWindow={false}
        getScrollParent={() => scrollRef.current}
        threshold={400}
      >
        <div className="flex flex-col space-y-1 w-full">
          {flattenSubFolders?.map(item => (
            <div
              className={classNames("cursor-pointer flex items-center w-full p-1 border rounded-lg", {
                "border-transparent": selected?.id !== item.id,
                "border-atherPurple-500": selected?.id === item.id,
              })}
              key={item.id}
              onClick={() => {
                setSelected(item)
                setIsOpen(false)
              }}
            >
              <div>
                <FolderIcon width={48} height={48} draggable={false} />
              </div>
              <div className="ml-1 overflow-hidden flex-1">
                <p className={classNames("text-xs font-semibold text-atherGray-100 line-clamp-1 mb-1")}>{item.name}</p>
                {user?.uid !== item.creator?.uid && (
                  <UserCreated
                    size="xss"
                    className="text-atherGray-300"
                    name={item.creator?.name}
                    picture={item.creator?.picture}
                  />
                )}
              </div>
              <div
                onClick={e => {
                  e.stopPropagation()
                  setSelectedSubFolders(prev => [
                    ...(prev ?? []),
                    ...(prev?.[prev.length - 1] !== item.id ? [item.id] : []),
                  ])
                }}
                className="-rotate-90"
              >
                <ChevronDownIcon className="w-3 h-3" />
              </div>
            </div>
          ))}
        </div>
      </InfiniteScroll>
    )
  }

  return (
    <div ref={ref}>
      <div
        className={classNames(
          "bg-atherGray-800 p-2 text-sm rounded-lg flex items-center overflow-hidden cursor-pointer border",
          {
            "border-atherGray-800": !isOpen,
            "border-atherPurple-500": isOpen,
          },
        )}
        onClick={() => setIsOpen(prev => !prev)}
      >
        <div className="mr-2">
          <FolderSelectIcon />
        </div>
        <p className="text-sm flex-1 line-clamp-1">{selected ? selected?.name : placeHolder}</p>
        {rightIcon}
      </div>
      <AnimatePresence mode="wait">
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, zIndex: -1 }}
            animate={{ opacity: 1, zIndex: 1 }}
            exit={{ opacity: 0, zIndex: -1 }}
            transition={{
              ease: "easeInOut",
              duration: 0.2,
            }}
            className={cn(
              "fixed top-0 md:top-[4.5rem] pb-[5.15rem] pt-4 md:pb-4 height-full-screen md:h-[calc((var(--vh,1vh)*100)-4.5rem)] left-0 md:left-[20rem] z-[25] md:z-[2] w-[88vw] md:w-80 bg-atherGray-900 flex flex-col overflow-hidden",
              className,
            )}
          >
            {title && (
              <div className="flex mb-4 px-4">
                <div onClick={onClose} className="cursor-pointer w-full flex items-center">
                  {position === "left" && (
                    <div className="w-4 mr-1">
                      <ChevronDownIcon className="rotate-90" />
                    </div>
                  )}
                  <h3 className="font-semibold text-xl flex-1">{title}</h3>
                  {position === "right" && (
                    <div className="w-4 ml-1">
                      <ChevronDownIcon className="-rotate-90" />
                    </div>
                  )}
                </div>
              </div>
            )}
            <div className="px-4 pb-2">
              <Input
                className="bg-atherGray-850 text-sm"
                value={search}
                onChange={e => setSearch(e.target.value)}
                placeholder="Find folder ..."
              />
              <div className="flex items-center justify-between mt-2">
                <div>
                  {selectedSubFolders && selectedSubFolders?.length > 0 && (
                    <button
                      type="button"
                      onClick={() => {
                        setSelectedSubFolders(prev => prev?.slice(0, prev.length - 1))
                      }}
                      className="text-xs flex items-center"
                    >
                      <ChevronDownIcon className="w-2 h-2 mr-1 rotate-90" />
                      Back
                    </button>
                  )}
                </div>

                {!isRemoveNewFolder && (
                  <IconButton
                    onClick={() => {
                      if (!folderId) return
                      setParentFolder({ id: folderId })
                    }}
                    className="text-xs min-h-0 p-1.5 ml-1"
                  >
                    New Folder
                  </IconButton>
                )}
              </div>
              <div className="w-full">
                <TabsList tabQuery={selectedTab} tabsData={tabList} onChange={v => setTab(v as SelectableTab)} />
              </div>
            </div>
            <div ref={scrollRef} className="overflow-auto w-full pb-2 px-2 pt-0 flex-1 flex flex-col">
              {renderBody()}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default DrawerSelectFolder
