import { IconProps } from "."

export const QuillWriteIcon = (props: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none" {...props}>
      <path
        d="M9.55025 2C5.69782 2.00694 3.6805 2.10152 2.39128 3.39073C1 4.78202 1 7.02125 1 11.4997C1 15.9782 1 18.2174 2.39128 19.6087C3.78257 21 6.0218 21 10.5003 21C14.9787 21 17.218 21 18.6093 19.6087C19.8985 18.3195 19.9931 16.3022 20 12.4498"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0556 12C9.33222 2.86635 15.8023 0.275538 20.9805 1.16439C21.1896 4.19136 19.7085 5.32482 16.8879 5.84825C17.4326 6.41736 18.395 7.13354 18.2912 8.02879C18.2173 8.66586 17.7846 8.97843 16.9194 9.60358C15.0231 10.9736 12.8264 11.8375 10.0556 12Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 16C10 10.5 11.9604 8.63636 14 7"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
