import { ContentModerationRating } from "@/api/sdk"
import IconButton from "@/components/IconButton"
import ImageWebpComponent from "@/components/ImageWebpComponent"
import NsfwBlurImage from "@/components/NsfwBlurImage"
import Skeleton from "@/components/Skeleton"
import { ArrowExpandIcon } from "@/components/shared/icons"
import { imageExtensionRegex } from "@/constants"
import { googleAnalytics } from "@/lib/gtag"
import { useExpandViewImage } from "@/stores"
import classNames from "classnames"
import { useState } from "react"
import { useInView } from "react-intersection-observer"

interface ImageDetailViewProps {
  image: {
    url?: string
    blurHash: string | null
    name: string | null
    moderationRating?: ContentModerationRating
  }
  id?: string
  isExplore?: boolean
}

const ImageDetailView = ({ image, id, isExplore }: ImageDetailViewProps) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const setExpandViewImage = useExpandViewImage(state => state.setExpandViewImage)
  const { ref, inView } = useInView({
    threshold: 0,
    rootMargin: "800px 0px 800px 0px",
  })
  const [isShow, setIsShow] = useState(
    image.moderationRating === ContentModerationRating.HardcoreNsfw ||
      (isExplore && image.moderationRating === ContentModerationRating.SemiNsfw) ||
      false,
  )
  return (
    <div
      id={id}
      className="relative lg:flex-1 h-[23rem] lg:h-auto overflow-hidden bg-black flex items-center justify-center"
      ref={ref}
    >
      {inView && (
        <div
          className="absolute top-0 left-0 w-full h-full"
          style={{
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center center",
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.75),rgba(0, 0, 0, 0.75)),url(${image.url?.replace(
              imageExtensionRegex,
              "",
            )}.w800.webp)`,
            filter: "blur(28px)",
          }}
        />
      )}

      <Skeleton
        className="static flex items-center justify-center h-full rounded-none z-[1] w-full"
        isLoaded={isLoaded && (!image.blurHash || !isShow)}
        blurHash={image.blurHash ?? undefined}
      >
        {inView && (
          <>
            <ImageWebpComponent
              typeResolution="w800"
              src={image?.url}
              alt={image?.name ?? ""}
              className={classNames("object-contain block max-h-[23rem] md:max-h-full max-w-full", {
                "blur-2xl": !image.blurHash && isShow,
              })}
              onLoad={() => setIsLoaded(true)}
            />
            <IconButton
              colorScheme="secondary"
              className="absolute bottom-2 md:bottom-auto md:top-2 right-2 min-h-0 p-1.5 opacity-70 hover:opacity-100"
              onClick={e => {
                e.stopPropagation()

                googleAnalytics.handleCategoryEvent({
                  action: "click",
                  params: {
                    tab_name: "image",
                    action: "Expand Image",
                    image_id: id ?? "",
                    image_name: image.name,
                  },
                })

                setExpandViewImage({
                  url: image.url ?? "",
                })
              }}
              title="Expand"
            >
              <ArrowExpandIcon width={14} height={14} className="text-atherGray-300" />
            </IconButton>
          </>
        )}
      </Skeleton>
      {inView &&
        (image.moderationRating === ContentModerationRating.HardcoreNsfw ||
          (isExplore && image.moderationRating === ContentModerationRating.SemiNsfw)) && (
          <NsfwBlurImage
            size={14}
            buttonCloseClassName="absolute bottom-2 right-10 left-auto top-auto md:bottom-auto md:top-10 md:right-2 min-h-0 p-1.5"
            isShow={isShow}
            moderationRating={image.moderationRating}
            onToggleShow={setIsShow}
            gaEvent={{
              tab_name: "image",
              params: {
                action: "Toggle Image NSFW",
                image_id: id ?? "",
                image_name: image.name ?? "",
              },
            }}
          />
        )}
    </div>
  )
}

export default ImageDetailView
